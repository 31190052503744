import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import Menu from "material-ui/Menu";
import Popover from "material-ui/Popover";
import "./DropDown.css";

const maxHeight = 333;

class DropDown extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      open: false,
      anchorEl: null
    };
  }
  open = em => {
    this.setState({
      open: true,
      anchorEl: ReactDOM.findDOMNode(this.anchorEl)
    });
  };
  close = em => {
    this.setState({ open: false });
  };
  render() {
    const {
      placeholder,
      value,
      style,
      className,
      onChange,
      children
    } = this.props;
    const fullWidth = this.props.fullWidth ? { width: "100%" } : {};
    return (
      <Fragment>
        <FlatButton
          ref={anchorEl => (this.anchorEl = anchorEl)}
          className={`dropdown-button${className ? " " + className : ""}`}
          onClick={event => {
            // This prevents ghost click.
            event.preventDefault();
            this.setState({
              open: true,
              anchorEl: event.currentTarget
            });
          }}
          disabled={this.props.disabled}
          style={{ ...fullWidth, ...style }}
        >
          {value || placeholder}
          <i className="material-icons">arrow_drop_down</i>
        </FlatButton>
        <Popover
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          targetOrigin={{ horizontal: "left", vertical: "top" }}
          onRequestClose={ev => this.close()}
        >
          <div
            ref={scroller => (this.scroller = scroller)}
            className="scroll-y"
            style={{ maxHeight, minWidth: "169px" }}
          >
            <Menu
              className="dropdown-menu"
              onItemClick={(ev, menuItem, index) => {
                this.close();
                onChange && onChange(menuItem.props.value);
              }}
              disabled={this.props.disabled}
            >
              {children}
            </Menu>
            <div className="blocks fixed-bottom-right">
              <IconButton
                className="icon24"
                onClick={ev =>
                  this.scroller &&
                  this.scroller.scrollTo(0, this.scroller.scrollTop - maxHeight)
                }
              >
                <i className="material-icons">expand_less</i>
              </IconButton>
              <IconButton
                className="icon24"
                onClick={ev => {
                  this.scroller &&
                    this.scroller.scrollTo(
                      0,
                      this.scroller.scrollTop + maxHeight
                    );
                }}
              >
                <i className="material-icons">expand_more</i>
              </IconButton>
            </div>
          </div>
        </Popover>
      </Fragment>
    );
  }
}
export default DropDown;
