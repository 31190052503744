import { Checkbox } from "@mui/material";
import React from "react";

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <Checkbox ref={resolvedRef} {...rest}/>
      /** * /
      <input type="checkbox" ref={resolvedRef} {...rest} />
      /** */
    )
  }
);
