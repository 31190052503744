import React, {Component, Fragment} from "react";
import Button from '@mui/material/Button';
import {
    TextField,
} from "material-ui";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EngagementDisagreement from "../../lib/EngagementDisagreement";
import Link from '@mui/material/Link';
import Definition from "../../lib/Definition";

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const pickedReasonsList = [
    {key: 'companyWorkedAt', label: 'Employer - Work Experience', lookup: 'false'},
    {key: 'diversity', label: 'Diversity', lookup: 'false'},
    {key: 'education', label: 'Employer - Education', lookup: 'false'},
    {key: 'experienceLevel', label: 'Employer - Level match', lookup: 'false'},
    {key: 'employerSize', label: 'Candidate - Company Size', lookup: 'false'},
    {key: 'domainKnowledge', label: 'Domain Knowledge', lookup: 'false'},
    {key: 'mustHave', label: 'Must Haves', lookup: 'false'},
    {key: 'mustNotHave', label: 'Must NOT Haves', lookup: 'false'},
    {key: 'higherHiringBar', label: 'Higher Hiring Bar', lookup: 'false'},
    {key: 'level', label: 'Level', lookup: 'false'},

    {
        key: 'workLocationIds',
        label: 'Candidate - Location',
        model: 'candidate',
        type: 'definition',
        definition: {key: 'locationCandidate'},
        matchedTo: {key: 'location', model: 'job'}
    },
    {
        key: 'negativeSignals',
        label: 'Negative Signals',
        model: 'candidate',
        type: 'definition',
        matchedTo: {key: 'negativeSignals', model: 'job'}
    },
    {
        key: 'positiveSignals',
        label: 'Positive Signals',
        model: 'candidate',
        type: 'definition',
        matchedTo: {key: 'positiveSignals', model: 'job'}
    },
    {key: 'projectExperience', label: 'Project Experience', model: 'job'},
    {
        key: 'Compensation',
        label: 'Candidate - Compensation',
        model: 'candidate',
        matchedTo: {key: '_salaryRange', model: 'job'}
    },
    {
        key: 'technicalSkills',
        label: 'Candidate - Technical Stack',
        model: 'candidate',
        type: 'definition',
        matchedTo: {key: 'technicalSkills', model: 'job'}
    },

    {
        key: 'yearsOfExperience',
        label: 'Years of Experience',
        model: 'candidate',
        matchedTo: {key: '_yearsOfExperience', model: 'job'}
    },
    {
        key: 'visa',
        label: 'Employer - VISA support',
        model: 'candidate',
        type: 'definition',
        definition: {key: 'visaCandidate'},
        matchedTo: {key: 'visaTransfer', model: 'job', definition: {key: 'visa'}}
    },
    {key: 'other', label: 'OTHER', lookup: 'false'}
];

class CreateWithPopup extends Component {
    constructor(props) {
        super(...arguments);
        this.state = {
            ...this.props,
            disagreementReason: '',
            shouldTag: '',
            shouldNotTag: '',
            whyNoPrivateNote: '',
            whyNeedToReadCV: '',
            pickedReasons: [],
            fromAnnotatorList: Array.from(new Set(['bob@10by10.io', 'april@10by10.io', 'andy@10by10.io', 'thomas@10by10.io', this.props.fromAnnotator].filter(el => !!el)))
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.engagement) {
            if (Object(prevProps.engagement).updatedAt !== Object(this.props.engagement).updatedAt) {
                this.setState({engagement: this.props.engagement})
            }
        }

        if (prevProps.decision !== this.props.decision) {
            this.setState({decision: this.props.decision})
        }

        if (prevProps.matchDecision !== this.props.matchDecision) {
            this.setState({matchDecision: this.props.matchDecision})
        }

        if (!!prevProps.jobs && prevProps.jobs.map(job => job.id).join('-') !== this.props.jobs.map(job => job.id).join('-')) {
            this.setState({jobs: this.props.jobs})
        }

        if (prevState.employer !== this.props.employer) {
            this.setState({employer: this.props.employer})
        }

        if (prevState.open !== this.props.open) {
            this.setState({open: this.props.open})
        }

        if (prevState.candidate !== this.props.candidate) {
            this.setState({candidate: this.props.candidate})
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
            disagreementReason: '',
            shouldTag: '',
            shouldNotTag: '',
            whyNoPrivateNote: '',
            whyNeedToReadCV: '',
            pickedReasons: []
        });
        !!this.props.onClose && this.props.onClose();
    }

    openUp = () => {
        this.setState({open: true});
    }

    getStructure = (job) => {
        let {
            disagreementReason,
            shouldTag,
            shouldNotTag,
            whyNoPrivateNote,
            whyNeedToReadCV,
            pickedReasons,
            candidate
        } = this.state;

        const getVal = (obj, key) => {
            if (!obj || !key) {
                return 'not found';
            }
            return !!obj[key] ? obj[key] : 'not found';
        }

        const toDef = (val, key) => {
            let ret = val;

            if (!val || !key) {
                return 'not found';
            }

            if (Array.isArray(val)) {
                ret = val.map(id => Definition.getLabel(key, id))
            } else if (!!val) {
                ret = Definition.getLabel(key, val)
            }

            return ret;
        }

        let disagreementDetails = pickedReasons.filter(el => !!el).map(key => {
            let ref = pickedReasonsList.find(el => el.key === key);

            if (ref.lookup === 'false') {
                return {[key]: {candidate: 'n/a', job: 'n/a', label: ref.label}};
            } else {
                let matchedTo = Object(ref.matchedTo);

                let valCand = getVal(candidate, key);
                let valMatchedTo = getVal(job, matchedTo.key);

                if (ref.type === 'definition') {
                    let defKeyCand = Object(ref.definition).key || key;
                    let defKeyJob = Object(matchedTo.definition).key || defKeyCand;

                    valCand = toDef(valCand, defKeyCand);
                    valMatchedTo = toDef(valMatchedTo, defKeyJob);
                }
                return {[key]: {candidate: valCand, job: valMatchedTo}};
            }

        });

        let obj = {
            whyNoCategories: pickedReasons, // was disagreementReasons - from the selection List
            whyNoDetails: disagreementReason,
            shouldTag,
            shouldNotTag,
            whyNoPrivateNote,
            whyNeedToReadCV,
            whyNoFieldsValues: disagreementDetails,
        };

        return obj;
    }

    createDisagreement = () => {
        let {jobs, createWith} = this.props;
        let {candidate, pickedReasons, disagreementReason, shouldTag, shouldNotTag, whyNoPrivateNote, whyNeedToReadCV} = this.state;

        jobs.forEach(job => {
            if (!!createWith) {
                createWith({
                    job,
                    candidate,
                    pickedReasons,
                    disagreementReasonDetails: disagreementReason,
                    shouldTag,
                    shouldNotTag,
                    whyNoPrivateNote,
                    whyNeedToReadCV,
                }, {disagreementModel:this.getStructure(job), cb:this.handleClose})
            }else{
                alert('no creator method passed in')
            }
        })
    }

    updateDisagreement = (disagreement) => {
        let othersFeedbacks = [];

        othersFeedbacks.push(
            {
                agreement: '',
                decision: '',
                who: '',
                date: '',
                whyDisagree: '',
                reasons: ''
            }
        );

        if (Array.isArray(disagreement.othersFeedbacks)) {
            othersFeedbacks = [...disagreement.othersFeedbacks, ...othersFeedbacks]
        }

        EngagementDisagreement.update({...disagreement, othersFeedbacks}, () => {
            alert('added entry successfully');
        })
    }

    handlerCreateDisagreement = () => {
        this.createDisagreement();
    }

    handleSelectChanged = (key) => (e) => {
        this.setState({[key]: e.target.value})
    }

    render() {
        let {open, disagreementReason, pickedReasons, job,
            candidate, employer, shouldTag, shouldNotTag, whyNoPrivateNote, whyNeedToReadCV } = this.state;

        return (
            <Fragment>
                <Link onClick={e => this.openUp()} component="button" variant="body2">
                    {'Disagree'}
                </Link>

                <Dialog fullWidth={true} open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle
                        id="form-dialog-title">{`${Object(employer)._name} - ${Object(job)._jobTitle} - ${Object(candidate)._name}`}</DialogTitle>
                    <DialogContent>
                        <FormControl style={{minWidth: '100%', marginBottom: '20px'}}>
                            <InputLabel htmlFor="select-multiple-chip">Pick Reason From Options</InputLabel>
                            <Select
                                multiple
                                value={pickedReasons}
                                onChange={this.handleSelectChanged('pickedReasons')}
                                input={<Input id="select-multiple-chip"/>}
                                renderValue={selected => {
                                    let mappedSelected = selected.map(key => pickedReasonsList.find(el => el.key === key))
                                    return <div>
                                        {mappedSelected.map(obj => (
                                            <Chip key={obj.key} label={obj.label}/>
                                        ))}
                                    </div>
                                }}
                            >
                                {pickedReasonsList.map(obj => (
                                    <MenuItem key={obj.key} value={obj.key}>
                                        {obj.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            autoFocus
                            id="disagreementReason"
                            label="Disagreement Reason "
                            value={disagreementReason}
                            onChange={(event, disagreementReason) => {
                                this.setState({disagreementReason});
                            }}
                            placeholder={"Disagreement Reason Free Words"}
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            id="shouldTag"
                            label="Should Tag "
                            value={shouldTag}
                            onChange={(event, shouldTag) => {
                                this.setState({shouldTag});
                            }}
                            placeholder={"List the missing tags that should have been tagged"}
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            id="shouldNotTag"
                            label="Should Not Tag "
                            value={shouldNotTag}
                            onChange={(event, shouldNotTag) => {
                                this.setState({shouldNotTag});
                            }}
                            placeholder={"List the tags that was mistakenly tagged"}
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            id="whyNoPrivateNote"
                            label="Why No Private Note "
                            value={whyNoPrivateNote}
                            onChange={(event, whyNoPrivateNote) => {
                                this.setState({whyNoPrivateNote});
                            }}
                            placeholder={"Why No Private Note Free Words"}
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            id="whyNeedToReadCV"
                            label="why Need To Re-read CV "
                            value={whyNeedToReadCV}
                            onChange={(event, whyNeedToReadCV) => {
                                this.setState({whyNeedToReadCV});
                            }}
                            placeholder={"Why do you need to re-read CV again"}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handlerCreateDisagreement} color="primary">
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>

        );
    }
}

export default CreateWithPopup;
