import Grid from '@mui/material/Grid';
import { MenuItem, SelectField, TextField } from "material-ui";
import React, { Component, Fragment } from "react";
import Definition from "../../../../lib/Definition";
import Chips from "../../../Forms/Chips";
import JobTags from "../Components/JobTags";
import ChipElement from "../Elements/Chip";
import ComplexElement from "../Elements/Complex";
import OperatorElement from "../Elements/Operator";
import TextElement from "../Elements/Text";
import AndElement from "../Operators/And";
import NotElement from "../Operators/Not";
import OrElement from "../Operators/Or";
import styles from '../Styles.js';

const ElementComponents = {
  'chip': ChipElement,
  'text': TextElement,
  'complex': ComplexElement,
  'operator': OperatorElement,
  'and': AndElement,
  'or': OrElement,
  'not': NotElement,
};

const Operators = [
  { name: 'and' },
  { name: 'or' },
  { name: 'not' }
];

const CHIP_KEYS_BY_CATEGORY = ['technicalSkills', 'positiveSignals', 'negativeSignals', 'jobLevelGroup'];
const categoriesWithKeys = ['technicalSkills', 'positiveSignals', 'negativeSignals'];

class Complex extends Component {
  constructor() {
    super(...arguments);

    let primary = !!this.props.updateAble ? this.props.updateAble.args.find(el => !!el.preferred) : null;

    this.state = {
      number: !!this.props.updateAble ? this.props.updateAble.atleast : 1,
      operator: !!this.props.updateAble ? this.props.updateAble.type : 'or',
      chips: !!this.props.updateAble ? this.props.updateAble.args.map(arg => {
        let label = Definition.getLabel(arg.key, arg.value)
        if (arg.type === 'chipGroup') {
          label = Definition.getGroupObj(arg.key, arg.value).name;
        }
        if (arg.type === 'chipTag') {
          label = arg.value;
        }
        return { key: arg.key, id: arg.value, type: arg.type, label, selected: true };
      }) : [],
      specialInstruction: !!this.props.updateAble ? this.props.updateAble.specialInstruction : "",
      primary: !!primary ? { id: primary.value, key: primary.key } : '',
      category: !!this.props.updateAble ? this.props.updateAble.category : 'technicalSkills'
    }
    this._constJobTags = this.props.jobTags.map(tag => ({ id: tag.id, key: tag.key, label: tag.value })) || [];

  }

  finalStruct = () => {
    let { number, operator, chips, specialInstruction, primary } = this.state;

    let conditional = {};

    if (operator === 'or') {
      conditional.atleast = number;
    }

    return {
      ...conditional,
      type: operator,
      specialInstruction,
      // category,
      args: chips.map(obj => {
        let type = !!obj.type ? obj.type : 'chip';
        type = !!obj.key && !categoriesWithKeys.includes(obj.key) ? 'chipTag' : type;
        let conditional = {};

        if (operator === 'or') {
          conditional.preferred = ((obj.id === primary.id) && (obj.key === primary.key));
        }

        const toReturn = {
          type: type,
          key: obj.key,
          value: type !== 'chipTag' ? obj.id : obj.label,
          specialInstruction: '',
          ...conditional
        }
        if (type !== 'chipTag') {
          toReturn['category'] = obj.key;
        }

        return toReturn;
      })
    }
  }

  updateAttr = (key, value) => {
    let { onUpdate } = this.props;
    let updateObj = { [key]: value };
    // let state = {}

    if (key === 'category') {
      updateObj['chips'] = [];
      updateObj['primary'] = '';
    }

    if (key === 'operator' && value === 'not') {
      updateObj['chips'] = [];
      updateObj['primary'] = '';
    }

    if (key === 'operator' && value === 'or') {
      updateObj['number'] = 1;
    }

    this.setState(updateObj, () => {
      !!onUpdate && onUpdate(this.finalStruct())
    })
  }

  gridOnChange = (newData, filter) => {
    let { onUpdate } = this.props;
    let { operator } = this.state;

    const otherData = this.state.chips.filter(filter);
    let finalChips = [...newData, ...otherData];

    if (operator === 'not') {
      finalChips = !!newData.length ? [newData.pop()] : [];
    }

    this.setState({ chips: finalChips }, () => {
      !!onUpdate && onUpdate(this.finalStruct())
    });
  }

  getAdditionalGroups = (key) => {
    let itemsGroup = [];

    try {
      itemsGroup = Definition.getRawDef(key).groups.map(g => ({
        label: g.name,
        id: g.id,
        key: key,
        type: 'chipGroup'
      }))
    } catch (e) {

    }
    return itemsGroup;
  }

  render() {
    let { number, operator, chips, specialInstruction, primary } = this.state;
    let { onUpdate, editable, dialogClass } = this.props;
    const OperatorComponent = ElementComponents[operator];

    let items = [];
    items = CHIP_KEYS_BY_CATEGORY
      .map(cat => Definition.get(cat)
        .map(c => {
          c.key = (cat === 'jobLevelGroup') ? c.key : cat;
          return c;
        }));

    let grids = [
      {
        name: "technicalSkills",
        label: "Technical Skills",
        items: items[0],
        additionalItems: this.getAdditionalGroups("technicalSkills"),
        values: chips.filter(chip => chip.key === 'technicalSkills'),
        commonCase: true,
        onChange: (chips, chipsObjs) => {
          this.gridOnChange(chipsObjs, (item) => item.key !== 'technicalSkills')
        },
        withOutDialog: true
      },
      {
        name: "positiveSignals",
        label: "Positive Signals",
        items: items[1],
        additionalItems: this.getAdditionalGroups("positiveSignals"),
        values: chips.filter(chip => chip.key === 'positiveSignals'),
        commonCase: true,
        onChange: (chips, chipsObjs) => {
          this.gridOnChange(chipsObjs, (item) => item.key !== 'positiveSignals')
        },
        withOutDialog: true
      },
      {
        name: "negativeSignals",
        label: "Negative Signals",
        items: items[2],
        additionalItems: this.getAdditionalGroups("negativeSignals"),
        values: chips.filter(chip => chip.key === 'negativeSignals'),
        commonCase: true,
        onChange: (chips, chipsObjs) => {
          this.gridOnChange(chipsObjs, (item) => item.key !== 'negativeSignals')
        },
        withOutDialog: true
      },

    ]

    return <Fragment>
      <Grid item xs={12} sm={12} className="mr-10 mb-10 job-custom-label">
        <TextField
          value={specialInstruction}
          textareaStyle={styles.TextField.inputStyle}
          underlineStyle={styles.TextField.underlineStyle}
          style={styles.TextField}
          rows={2}
          rowsMax={2}
          multiLine={true}
          placeholder={"Write special instruction here"}
          fullWidth
          onChange={(event, text) => {
            this.updateAttr('specialInstruction', text)
          }}
        />
      </Grid>


      <Grid container>
        <SelectField
          name="level"
          required={true}
          value={operator}
          width={100}
          maxWidth={100}
          maxHeight={500}
          onChange={(event, index, number) => this.updateAttr('operator', number)}
          style={{ width: "80px" }}
        >
          {Operators.map((obj, id) => (
            <MenuItem key={id} value={obj.name} primaryText={obj.name} />
          ))}
        </SelectField>
        <Grid item xs={3} sm={3} className="mb-10">
          <OperatorComponent number={number} onUpdate={this.updateAttr} editable={editable} />
        </Grid>
        {!!chips && !!chips.length && (operator === 'or') && <Grid item xs={6} sm={6} className="mb-10">
          <p><strong>Pick Primary</strong></p>
          <Chips
            name="technicalSkills"
            items={chips.filter(chip => chip.type !== "chipGroup").map(chip => {
              return { label: Definition.getLabel(chip.key, chip.id), ...chip }
            })}
            label=""
            values={!!primary ? [primary] : []}
            commonCase={true}
            onChange={(chips, chipsObj) => {
              let singleElement = chips.length ? chipsObj.pop() : '';
              this.setState({ primary: singleElement }, () => {
                !!onUpdate && onUpdate(this.finalStruct())
              });
            }}
            hidePopupContent={() => {
              document.getElementsByClassName(dialogClass)[0].classList.add("removeClass");
            }}
            displayPopupContent={() => {
              document.getElementsByClassName(dialogClass)[0].classList.remove("removeClass");
            }}
          />
        </Grid>}
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={12} className="job-custom-label">
          <JobTags
            data={{
              items: this._constJobTags,
              additionalItems: items[3],
              values: chips.filter(chip => !categoriesWithKeys.includes(chip.key)),
              onChange: (chipsObjId, chipsObj) => {
                this.gridOnChange(chipsObj, (item) => categoriesWithKeys.includes(item.key))
              },
              dialogClass: dialogClass
            }}
          />
        </Grid>
      </Grid>

      <Grid container>
        {grids.map(grid => {
          return <Grid key={`grid-${grid.name}-complex`} item xs={12 / grids.length}
            className="job-custom-label">
            <Chips
              key={`grid-chips-${grid.name}-complex`}
              name={grid.name}
              label={grid.label}
              items={grid.items}
              additionalItems={grid.additionalItems}
              values={grid.values}
              commonCase={true}
              onChange={grid.onChange}
              withOutDialog={true}
            />
          </Grid>

        })}</Grid>
    </Fragment>
  }
}

export default Complex;
