import React, {Component, Fragment} from "react";

/* Lib files */
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from "moment";

import Engagement from "../../../../lib/Engagement";
import cleanHTML from "../../../../lib/tools/cleanHtml";
import Core from "../../../../lib/Core";

class SubmissionHistory extends Component{
    constructor() {
        super(...arguments);
        this.state = {
            candidateEngagements:[]
        }
    }

    fetchEngagements = () => {
        Engagement.getWhere({candidateId:this.props.candidate.id}, (res)=>{
            this.setState(
                {
                    candidateEngagements: res
                },
            );
        })
    }

    componentDidMount() {
        this.fetchEngagements();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!prevProps.candidate.id && prevProps.candidate.id !== this.props.candidate.id) {
            this.fetchEngagements();
        }
    }

    profiles = () => {
        let ret = {}
        let engagements = this.state.candidateEngagements;

        if(!Array.isArray(engagements)){
            return;
        }

        ret.no = ()=>engagements.filter(el=> /review/i.test(el.stage) && /e -/i.test(el.status));

        ret.yes = ()=>{
            let filtered = engagements.filter(el=> !/confirmation|submission|review/i.test(el.stage));
            let hash = {
                Screen: 1,
                Onsite: 2,
                Offer: 3,
                Guarantee: 4,
                Hire: 5,
                End: 6
            };

            filtered.sort((a,b)=> {
                return ((new Date(b.submitted)).getTime()*hash[b.stage]) - ((new Date(a.submitted)).getTime()*hash[a.stage]);
            });

            return filtered;
        }

        ret.active = () => {
            return engagements.filter(el => el.state === "Open");
        };

        ret.inactive = () => {
            return engagements.filter(el => el.state === "Closed");
        };

        ret.summary = () => {
            let onsite = engagements.filter(el=>/onsite|offer|guarantee|hire|end/i.test(el.stage));
            let screens = engagements.filter(el=> /screen/i.test(el.stage));
            let reviewed = [...ret.yes(),...ret.no()];
            let submitted = [...reviewed,...ret.mayBe()];
            let screenPercent, onSitesPercent = "0%";

            if(reviewed.length > 0){
                screenPercent = `${parseInt(((screens.length+onsite.length)*100)/reviewed.length)}%`;
                onSitesPercent = `${parseInt((onsite.length*100)/reviewed.length)}%`;
            }

            return <Fragment>
                <p>{submitted.length} submitted
                    | {reviewed.length} reviewed
                    | {screens.length} screens & after ({screenPercent})
                    | {onsite.length} onSites & after ({onSitesPercent})
                </p>
            </Fragment>
        }

        const printSingle = (eng)=>{
            let employer = eng.employer;
            let job = eng.job;
            let isActive = eng.state !== 'Closed';
            let isHired = !isActive && eng.stage === 'Guarantee';
            let finalLabel = "";
            let rejectionReasonDetails = "";
            if (!isActive) {
                if (isHired) {
                    finalLabel = "<strong><u>HIRED</u></strong>";
                    rejectionReasonDetails = "N/A"
                } else {
                    finalLabel = "<strong>REJECTED</strong>";
                    rejectionReasonDetails = [eng.rejectionReason, eng.rejectionReasonAdditionalInfo].filter(el=>!!el).join(' - ');
                }
            }
            return cleanHTML(
                '<p style="font-size:12px;"><strong>' + String(eng.stage) +'</strong> | '+ String(eng.status) + ' | ' +
                [
                    moment(eng.lastAction).format("MM/DD/YY"),
                    `<a href="${Core.getLink(`/employer/edit/${employer.id}`)}" target="_blank">${employer._name}</a>`,
                    job._roles.split(", ")[0]

                ]
                .filter(e => !!e && !!String(e).trim().length)
                .join(" | ") + ' | ' + finalLabel + '</p>' +
                '<p style="font-size:10px;">&emsp;' + rejectionReasonDetails + '</p>'
            );
        };

        ret.print = (array)=>{
            return array.map(el=>printSingle(el)).join('')
        }

        return ret;
    }

    render(){

        return  <Fragment>
        <Card className="match-new section-card">
            <div>
                <CardContent className="match-new card-content">
                    <h2 className="match-new section-header">SUBMISSION HISTORY</h2>
                    <hr />
                    {/* <Grid container spacing={8} style={{padding:'15px'}}>
                        <Grid item xs={12}> */}
                            <p><strong>Active ({this.profiles().active().length})</strong></p>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: this.profiles().print(this.profiles().active())
                                }}
                            />

                        {/* </Grid>
                        <Grid item xs={12}> */}
                            <p><strong>Inactive ({this.profiles().inactive().length})</strong></p>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: this.profiles().print(this.profiles().inactive())
                                }}
                            />

                        {/* </Grid>
                    </Grid> */}
                </CardContent>
            </div>
        </Card>
    </Fragment>
    }
}

export default SubmissionHistory;