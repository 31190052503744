import React from "react";

import Definition from "../../../../../lib/Definition";
import ChipGroup from "./ChipGroup";
import PositiveSignals from "./PositiveSignals";

const mapping = {
    "technicalSkills": "technicalSkills",   // candidate has array
    "positiveSignals": "positiveSignals",   // candidate has array
    "negativeSignals": "negativeSignals",   // candidate has array
    "experience":      "level",             // candidate has integer
    "technologyDomain":"technologyDomain"   // candidate has array
};

const result = (element, toMatchWith) => {
    let result;
    if(element.type==='chipGroup'){
        result = !!ChipGroup.result(element, toMatchWith) ? {group: 1} : {group: 0};
    }else{
        result = !!isChipMatched(element, toMatchWith) ? {group: 1} : {group: 0};
    }

    return finalDecision([result]);
}

const getLevelMappingWithExperience = (level, experience) => { // level : array , experience : int
    const levelLabel = Definition.getLabel("level", level);
    const experienceLabel = Definition.getLabels("experience", experience);

    return {
        label: levelLabel,
        labels: experienceLabel
    }
}

const isChipMatched = (chip, toMatchWith) => {
    let parsedToMatch = [];

    if(mapping[chip.key] === "level") {
        parsedToMatch = toMatchWith[mapping[chip.key]].map(val=>parseInt(val));
        const {label, labels} = getLevelMappingWithExperience(parsedToMatch, [parseInt(chip.value)]);
        if(!!label && !!labels.length && labels.includes(label)){
            return true;
        }
        return false;
    }else if (mapping[chip.key] === "positiveSignals"){
        if(!!chip.key){
            parsedToMatch = toMatchWith[mapping[chip.key]].map(val=>parseInt(val));
            return PositiveSignals.result(parseInt(chip.value), parsedToMatch);
        }
    }else if(!!chip.category && chip.category === "technicalSkills"){
        const additionalMappers = [
            ...toMatchWith[chip.category],
            ...toMatchWith._strongTechnicalSkills,
            ...toMatchWith.techSkillsInResume
        ];
        return !!additionalMappers.map(chip=>parseInt(chip)).includes(parseInt(chip.value));
    }
    else{
        if(!!chip.key){
            parsedToMatch = toMatchWith[mapping[chip.key]].map(val=>parseInt(val));
            return !!parsedToMatch.includes(parseInt(chip.value)) ? true : false;
        }
        return false;
    }
}

const finalDecision = (decision) => {
    let text = <span><i style={{fontSize: '20px'}} className="material-icons">cancel</i></span>;
    decision.forEach(dec => {
        if(dec.group > 0){
            text = <span><i style={{fontSize: '20px'}} className="material-icons">done</i></span>;
        }
    });
    return text;
}

const getDataSet = (chip, toMatchWith) => {
    return isChipMatched(chip, toMatchWith);
}

const getDecision = (chip, toMatchWith) => {
    let decision =!!isChipMatched(chip, toMatchWith) ? [{group: 1}] : [{group: 0}];

    return finalDecision(decision);
}

const Chip = {
    result,
    getDataSet,
    getDecision,
    finalDecision,
    isChipMatched
}

export default Chip;
