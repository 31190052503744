import React, { Component } from "react";

import { Dialog, FlatButton } from "material-ui";

class ConfirmDialog extends Component {
  constructor() {
    super(...arguments);
    this.state = { open: false };
  }
  open = ({ message, onConfirm }) => {
    this.setState({ open: true, message, onConfirm });
  };
  submit = id => {
    this.close();
    if (this.props.onConfirm instanceof Function) {
      this.props.onConfirm();
    }
    if (this.state.onConfirm instanceof Function) {
      this.state.onConfirm();
    }
  };
  close = ev => this.setState({ open: false });
  render() {
    const actions = [
      <FlatButton label="Cancel" primary={true} onClick={this.close} />,
      <FlatButton
        label={this.props.actionLabel}
        primary={true}
        onClick={this.submit}
      />
    ];
    return (
      <Dialog
        title={this.props.title}
        actions={actions}
        modal={true}
        contentStyle={{ width: 400 }}
        open={this.state.open}
      >
        {!!this.state.message && this.state.message}
        <br />
        {this.props.message}
      </Dialog>
    );
  }
}

export default ConfirmDialog;
