import _ from "lodash";
import { FlatButton } from "material-ui";
import moment from "moment";
import dig from "object-dig";
import React, { Fragment } from "react";
import { SOVREN_FILENAME_PREFIX, SOVREN_PARSER_URL } from "../lib/Constants";
import formatURL from "../lib/tools/formatURL";
import Base64 from "./base64";
import Core from "./Core";
import Definition, { STATE_ACTIVE } from "./Definition";
import Engagement from "./Engagement";
import Google from "./Google";
import Http from "./Http";
import Job from "./Job";
import {
  getCandidateModel,
  mapCandidate,
  mapCandidates,
  MODEL_NAME_CANDIDATE
} from "./models/candidate";
import { mapEngagements } from "./models/engagement";
import SovrenData from "./SovrenData";
import Streak from "./Streak";
import copyHTML from "./tools/copyHtml";
import getStateModel from "./tools/getStateModel";

const cache = {};

const commonQuery = {
  include: ["account", { engagements: [{ job: "employer" }, "candidate"] }],
};

const commonQuery2 = {
  include: [
    "account",
    { engagements: { job: "employer" } },
    {
      relation: "candidateStarreds",
      scope: {
        where: { accountId: Core.getUserId() },
      },
    },
  ],
};

const menus = [
  // Roles(dc)
  { label: "Roles", key: "roles", field: "_rolesKeys", multiple: true },
  // Technology(dc)
  {
    label: "Technology",
    key: "technicalSkills",
    field: "_technicalSkillsKeys",
    multiple: true,
  },
  // Visa(h)
  {
    label: "Visa",
    key: "visa",
    field: "_visa",
    // inputType: "radio",
    options: {
      "Citizen Only": false,
      // 'Green Card': false,
      "Citzen and Green Card Only": false,
      "Visa Support Unknown": false,
      "Will Sponsor New H1": false,
      "Will Transfer H1": false,
    },
    mappings: {
      "Citizen Only": ["Citizen", "Visa Status Unknown"],
      "Citzen and Green Card Only": [
        "Citizen",
        "Green Card",
        "Visa Status Unknown",
        "No Sponsorship Required",
      ],
      "Will Transfer H1": [
        "Citizen",
        "Green Card",
        "Needs TN (Canada-Mexico)",
        "Needs H1B1/E-3 (Chile-Singapore-Australia)",
        "Needs H1B Visa Transfer",
        "OPT/CPT/F-1",
        "H1-B with I140 Approved",
        "Visa Status Unknown",
        "No Sponsorship Required",
      ],
      "Will Sponsor New H1": [
        "Citizen",
        "Green Card",
        "Needs TN (Canada-Mexico)",
        "Needs H1B1/E-3 (Chile-Singapore-Australia)",
        "Needs H1B Visa Transfer",
        "Needs New Visa Sponsor",
        "OPT/CPT/F-1",
        "H1-B with I140 Approved",
        "L1",
        "H4 EAD",
        "J-1",
        "O-1",
        "Visa Status Unknown",
        "No Sponsorship Required",
      ],
      "Visa Support Unknown": [
        "Citizen",
        "Green Card",
        "Needs TN (Canada-Mexico)",
        "Needs H1B1/E-3 (Chile-Singapore-Australia)",
        "Needs H1B Visa Transfer",
        "Needs New Visa Sponsor",
        "OPT/CPT/F-1",
        "H1-B with I140 Approved",
        "L1",
        "H4 EAD",
        "J-1",
        "O-1",
        "Visa Status Unknown",
        "No Sponsorship Required",
      ],
    },
  },
  /* epic-3038-story-3083 - 2021-06-17 µ */
  /* epic-3038-story3330-m2 - 2021-07-01 µ */
  // Remote Options(s-mj)
  {
    label: "Remote Options",
    key: "inOfficeRemoteFlags",
    definitionKey: "inOfficeRemote",
    field: "_inOfficeRemoteFlagsKeys",
    prefix: "Remote",
    multiple: true,
  },
  /* This is needed for filtering results story-3083-M1-4 2021-06-17 µ */
  /* Moved to main menu epic-3038-story-3275 2021-06-30 µ */
  /* epic-3038(new locations)-story-3578 | 2021-07-29 Thu µ */
  // WFH Locations(s-mj)
  {
    label: "WFH Locations",
    key: "candidateLocations",
    definitionKey: "location",
    field: "_candidateLocations",
    prefix: "WFH",
    multiple: true,
  },
  /* This is needed for filtering results story-3083 2021-06-17 µ */
  // Office Locations(s-mj)
  {
    label: "Office Locations",
    matchingLabel: "In Office Locations",
    key: "officeLocations",
    definitionKey: "location",
    field: "_officeLocationsKeys",
    prefix: "Office",
    multiple: true,
  },
  // Stage(h)
  {
    label: "Stage",
    key: "stage",
    field: "_desiredStage",
    multiple: true,
    options: {
      "Late Stage Startup": false,
      Public: false,
      Seed: false,
      "Series A": false,
      "Series B": false,
      "Series C": false,
      "Series D+": false,
    },
    mappings: {
      "Late Stage Startup": ["Late Stage Startup", "Unknown"],
      Public: ["Public", "Unknown"],
      "Series A": ["Series A", "Unknown"],
      "Series B": ["Series B", "Unknown"],
      "Series C": ["Series C", "Unknown"],
      "Series D+": ["Series D+", "Unknown"],
    },
  },
];

const more = [
  // Platform Rating(dc)
  Core.isAdminOrCoordinator()
    ? {
      label: "Platform Rating",
      key: "platformRating",
      field: "_platformRating",
    }
    : {},
  // Diversity(dc)
  Core.isAdminOrCoordinator()
    ? {
      label: "Diversity",
      key: "diversity",
      field: "_diversity",
      prefix: "Diversity: ",
    }
    : {},

  /**
   * @todo
   * review to cleanup
   * ask to BB
   * 2021-08-03 Tue µ
   */
  // Core.isAdminOrCoordinator()
  // ? {
  //     label: "Introduced",
  //     key: "introduced",
  //     field: "_introduced",
  //     options: [{id:1,label:'InDraft'},{id:2, label:'Active'}]
  // }
  // : {},

  // State(dc)
  Core.isAdminOrCoordinator()
    ? {
      label: "State",
      key: "state",
      field: "_state",
    }
    : {},

  // With Engagements(h)
  Core.isAdminOrCoordinator()
    ? {
      label: "With Engagements",
      key: "withEngagements",
      field: "_withEngagements",
      options: [
        { id: 1, label: "Yes" },
        { id: 2, label: "No" },
      ],
    }
    : {},

  /**
   * @todo
   * review to cleanup
   * ask to BB
   * 2021-08-03 Tue µ
   */
  //  {
  //    label: "Desired Stage",
  //    key: "stage",
  //    field: "_desiredStage"
  //  }

  /** This is needed for filtering results story-3083 2021-06-17 µ */
  // Desired employment type(dc)
  {
    label: "Desired employment type",
    key: "desiredEmploymentType",
    field: "_desiredEmploymentTypes",
  },
];

/** @todo to review, what means this comment here? | 2021-08-03 Tue µ  */
// Needed so that the min salary shows up for recruiters

const listTabs = ["Name", "Introduced", "Recent", "Recruiter", "Starred"];
const listTabsWithSort = [
  { title: "Name", sort: { firstName: 1 } },
  { title: "Introduced", sort: { introduced: -1 } },
  { title: "Recent", sort: { updatedAt: -1 } },
  { title: "Recruiter", sort: "" },
  { title: "Starred", match: { candidateStarreds: { $ne: [] } } },
];
const listTabsRecruiters = ["Name", "Recent", "Starred"];
const listTab = Core.isAdminOrCoordinator() ? "Introduced" : "Name";

const Candidate = {
  name: MODEL_NAME_CANDIDATE,
  menus,
  more,
  listTabs,
  listTabsWithSort,
  listTabsRecruiters,
  listTab,
  columns: [
    {
      headers: [
        {
          label: "Candidate",
          key: "_infoCmp",
          sortKey: "_name",
          filter: false,
          allFilters: false,
          order: 18,
          collapsed: true,
        },
        {
          label: "Technical Skills",
          key: "_technicalSkills",
          multiple: true,
          order: 4,
          collapsed: true,
        },
        {
          label: "Engagements",
          key: "_engagementsCmp",
          sortKey: "_engagementsLength",
          reverseSort: true,
          filter: false,
          order: 7,
          collapsed: true,
          admin: true,
        },
      ],
      selected: 0,
      style: { minWidth: 256 },
    },
    {
      headers: [
        {
          label: "Roles",
          key: "_roles",
          order: 3,
          multiple: true,
          collapsed: false,
        },
        {
          label: "Positive Signals",
          key: "_positiveSignals",
          order: 15,
          multiple: true,
          collapsed: true,
          admin: true,
        },
      ],
      selected: 0,
    },
    {
      headers: [
        {
          label: "Recruiter",
          key: "_recruiterCmp",
          sortKey: "_recruiterName",
          order: 13,
          collapsed: true,
        },
        {
          label: "Negative Signals",
          key: "_negativeSignals",
          order: 16,
          multiple: true,
          collapsed: true,
          admin: true,
        },
      ],
      selected: 0,
    },
    {
      headers: [
        { label: "Visa", key: "_visa", order: 7, collapsed: false },
        {
          label: "Work Locations",
          key: "_officeLocations",
          multiple: true,
          order: 8,
          collapsed: false,
        },
      ],
      selected: 0,
    },
    {
      headers: [
        {
          label: "Introduced",
          key: "_introduced",
          sortKey: "introduced",
          reverseSort: true,
          collapsed: true,
          filter: false,
          allFilters: false,
        },
        {
          label: "Minimum Years of Experiences",
          key: "_years",
          sortKey: "yearsOfExperience",
          order: 6,
          collapsed: false,
        },
        {
          label: "Tags",
          key: "_tags",
          sortKey: "_tags",
          order: 16,
          collapsed: false,
          multiple: true,
        },
        {
          label: "Minimum Salary",
          key: "_minimumSalary",
          sortKey: "minimumSalary",
          order: 11,
          collapsed: true,
        },
      ],
      selected: 0,
    },
    {
      headers: [
        {
          label: "Starred",
          key: "_rowOptionsCmp",
          sortKey: "_starred",
          reverseSort: true,
          hint: false,
          collapsed: true,
        },
        {
          label: "State",
          key: "_state",
          visible: false,
          order: 1,
          collapsed: true,
          admin: true,
          acl: {
            defaultValues: ["Active", "active"],
            roles: ["SysAdmin"],
          },
        },
        {
          label: "Candidate Platform Rating",
          key: "_platformRating",
          visible: false,
          order: 2,
          collapsed: true,
          admin: true,
        },
        {
          label: "Level",
          key: "_level",
          visible: false,
          order: 5,
          collapsed: true,
        },
        {
          label: "Duplicate",
          key: "isDuplicate",
          visible: false,
          order: 6,
          collapsed: true,
        },
        {
          label: "Work from home partial week",
          key: "_workRemotly",
          visible: false,
          order: 10,
          collapsed: true,
        },
        {
          label: "Agency",
          key: "_companyName",
          visible: false,
          order: 12,
          collapsed: true,
          admin: true,
        },
        {
          label: "Diversity",
          key: "_diversity",
          visible: false,
          order: 14,
          collapsed: true,
          admin: true,
        },
        {
          label: "Latest Stage",
          key: "_latestStage",
          visible: false,
          order: 17,
          collapsed: true,
        },
      ],
      selected: 0,
      style: { width: 124, textAlign: "right" },
    },
  ],
  /**
   * If component true, it returns calculated platform rating label.
   * -  If candidate PR is different to calculated PR, it adds a red message.
   *
   * If component false, it returns the calculated PR ID.
   *
   * @param {Object} candidate Usually candidate state (memory data)
   * @param {Boolean} component Indicates if returns ID (false) or Component (true)
   */
  calculatePlatformRating({ candidate, component = false }) {
    /** * /
    {
      1: "A - Top",
      2: "B - Strong",
      3: "C - Good",
      4: "D - Stretch",
      5: "A+",
      6: "E"
    }
    /** */
    const labels = Definition.getAllLabels("platformRating");

    /** µ DISCARDED CODE (future cleanup) * /
    // 0 = C, 1=B, 2=A, 3=A+ -1 = D, -2+ = E
    console.debug('µ:calculatePlatformRating', candidate, Definition.getAllLabels("platformRating"));
    const positives = Definition.getAllLabels("positiveSignals");
    const psCount = candidate.positiveSignals?.length || 0;
    const nsCount = candidate.negativeSignals?.length || 0;
    const count = psCount - nsCount;
    console.debug("µ:calculatePlatformRating", {
      positives,
      labels,
      TOP_TIER_TECH_COMPANY: Definition.getId(
        "positiveSignals",
        "Top tier tech company"
      ),
    });
    /** */

    const WON_AWARD = Definition.getId("positiveSignals", "Won Award"); // 2
    const COMPUTER_DEGREE = Definition.getId(
      "positiveSignals",
      "Computer degree"
    ); // 3
    const FOUNDING_TEAM = Definition.getId("positiveSignals", "Founding Team"); // 6
    const PROMOTION = Definition.getId("positiveSignals", "Promotion"); // 14
    const STEM_COMPUTER = Definition.getId(
      "positiveSignals",
      "STEM computer related degree"
    ); // 16
    const STARTUP_EXPERIENCE = Definition.getId(
      "positiveSignals",
      "Startup experience"
    ); // 18
    const STRONG_TECH_COMPANY = Definition.getId(
      "positiveSignals",
      "Strong tech company"
    ); // 20
    const ELITE_UNIVERSITY = Definition.getId(
      "positiveSignals",
      "Elite university"
    ); // 21;
    const RANKED_UNIVERSITY = Definition.getId(
      "positiveSignals",
      "Ranked university"
    ); // 22;
    const TOP_RANKED_UNIVERSITY = Definition.getId(
      "positiveSignals",
      "Top ranked university"
    ); // 23;
    const TOP_TIER_TECH_COMPANY = Definition.getId(
      "positiveSignals",
      "Top tier tech company"
    ); // 24;
    const STRONG_UNIVERSITY = Definition.getId(
      "positiveSignals",
      "Strong university"
    ); // 26;
    const GREAT_GITHUB = Definition.getId("positiveSignals", "Great Github"); // 27;
    const STRONG_GITHUB = Definition.getId("positiveSignals", "Strong Github"); // 28;
    const IMPRESSIVE_GITHUB = Definition.getId(
      "positiveSignals",
      "Impressive Github"
    ); // 29;
    const HACKATHON_WINNER = Definition.getId(
      "positiveSignals",
      "Hackathon Winner"
    ); // 30
    const TECH_COMPANY = Definition.getId("positiveSignals", "Tech Company"); // 31;
    const UNICORN_STARTUP = Definition.getId(
      "positiveSignals",
      "Unicorn startup"
    ); // 34
    const YCOMBINATOR_STARTUP = Definition.getId(
      "positiveSignals",
      "YCombinator startup"
    ); // 36

    const { positiveSignals: candoPositiveSignal } = candidate;

    const _STARTUP_EXPERIENCE =
      candoPositiveSignal.includes(UNICORN_STARTUP) ||
      candoPositiveSignal.includes(YCOMBINATOR_STARTUP) ||
      candoPositiveSignal.includes(STARTUP_EXPERIENCE) ||
      candoPositiveSignal.includes(FOUNDING_TEAM);

    const _POSITIVE_SIGNALS =
      candoPositiveSignal.includes(PROMOTION) ||
      candoPositiveSignal.includes(HACKATHON_WINNER) ||
      candoPositiveSignal.includes(WON_AWARD);

    /** A+ */
    const A_PLUS = (
      candoPositiveSignal.includes(TOP_TIER_TECH_COMPANY)
      &&
      (
        candoPositiveSignal.includes(IMPRESSIVE_GITHUB)
        ||
        candoPositiveSignal.includes(ELITE_UNIVERSITY)
      )
      &&
      (Definition.getId("platformRating", "A+") || 5)
    );

    /** A - Top */
    const A = (
      (
        /** ANY OF THESE */
        (
          candoPositiveSignal.includes(TOP_TIER_TECH_COMPANY) ||
          candoPositiveSignal.includes(IMPRESSIVE_GITHUB) ||
          candoPositiveSignal.includes(ELITE_UNIVERSITY)
        )
        ||
        /** 2 OF THESE */
        (
          /** EDUCATION OR GITHUB */
          (
            (
              candoPositiveSignal.includes(ELITE_UNIVERSITY) ||
              candoPositiveSignal.includes(TOP_RANKED_UNIVERSITY) ||
              candoPositiveSignal.includes(STRONG_UNIVERSITY) ||
              candoPositiveSignal.includes(RANKED_UNIVERSITY) ||
              candoPositiveSignal.includes(IMPRESSIVE_GITHUB) ||
              candoPositiveSignal.includes(GREAT_GITHUB)
            ) && 1
          ) +
          /** COMPANY WORKED */
          (
            (
              candoPositiveSignal.includes(TOP_TIER_TECH_COMPANY) ||
              candoPositiveSignal.includes(STRONG_TECH_COMPANY)
            ) && 1
          ) +
          (_STARTUP_EXPERIENCE && 1)
          >= 2
        )
        ||
        /** 3 OF THESE */
        (
          /** EDUCATION OR GITHUB */
          (
            (
              candoPositiveSignal.includes(ELITE_UNIVERSITY) ||
              candoPositiveSignal.includes(TOP_RANKED_UNIVERSITY) ||
              candoPositiveSignal.includes(STRONG_UNIVERSITY) ||
              candoPositiveSignal.includes(IMPRESSIVE_GITHUB) ||
              candoPositiveSignal.includes(GREAT_GITHUB) ||
              candoPositiveSignal.includes(STRONG_GITHUB)
            ) && 1
          ) +
          /** COMPANY WORKED */
          (
            (
              candoPositiveSignal.includes(TOP_TIER_TECH_COMPANY) ||
              candoPositiveSignal.includes(STRONG_TECH_COMPANY)
            ) && 1
          ) +
          (_STARTUP_EXPERIENCE && 1) +
          (_POSITIVE_SIGNALS && 1)
          >= 3
        )
      )
      &&
      (Definition.getId("platformRating", "A - Top") || 1)
    );

    /** B - Strong */
    const B = (
      (
        /** ANY OF THESE */
        (
          candoPositiveSignal.includes(TOP_TIER_TECH_COMPANY) ||
          candoPositiveSignal.includes(STRONG_TECH_COMPANY) ||
          candoPositiveSignal.includes(ELITE_UNIVERSITY) ||
          candoPositiveSignal.includes(TOP_RANKED_UNIVERSITY) ||
          candoPositiveSignal.includes(STRONG_UNIVERSITY) ||
          candoPositiveSignal.includes(IMPRESSIVE_GITHUB) ||
          candoPositiveSignal.includes(GREAT_GITHUB) ||
          candoPositiveSignal.includes(STRONG_GITHUB) ||
          _STARTUP_EXPERIENCE
        )
        ||
        /** 2 OF THESE */
        (
          /** EDUCATION */
          (
            (
              candoPositiveSignal.includes(STEM_COMPUTER) ||
              candoPositiveSignal.includes(COMPUTER_DEGREE)
            ) && 1
          ) +
          (candoPositiveSignal.includes(RANKED_UNIVERSITY) && 1) +
          (_POSITIVE_SIGNALS && 1)
        ) >= 2
      )
      &&
      (Definition.getId("platformRating", "B - Strong") || 2)
    );

    /** C - Good */
    const C = (
      (
        candoPositiveSignal.includes(TECH_COMPANY) ||
        candoPositiveSignal.includes(STEM_COMPUTER) ||
        candoPositiveSignal.includes(COMPUTER_DEGREE) ||
        candoPositiveSignal.includes(RANKED_UNIVERSITY) ||
        _POSITIVE_SIGNALS
      )
      &&
      (Definition.getId("platformRating", "C - Good") || 3)
    );

    /** D - Strech */
    const D = (Definition.getId("platformRating", "D - Strech") || 4);

    /** E */
    const E = (Definition.getId("platformRating", "E") || 6);

    /** µ DISCARDED (future cleanup) * /
    const positiveSignals = candidate.positiveSignals;

    const intersectedResultWithPosSig = (positiveSignals) => (
      matchWith,
      intersectionStrength
    ) => {
      return (
        _.intersection(positiveSignals, matchWith).length ===
        intersectionStrength
      );
    };
    const intersectedResultWithPosSigWithBind = intersectedResultWithPosSig(
      candoPositiveSignal
    );

    const calculatedPR = (
      intersectedResultWithPosSigWithBind([TOP_TIER_TECH_COMPANY], 1) &&
      intersectedResultWithPosSigWithBind([IMPRESSIVE_GITHUB, ELITE_UNIVERSITY], 1)
        ? A_PLUS
        : intersectedResultWithPosSigWithBind([TOP_TIER_TECH_COMPANY, IMPRESSIVE_GITHUB,], 1)
          ? A
          : count >= 1
            ? B
            : count === 0
              ? C
              : count <= -2
                ? E
                : count <= -1
                  ? D
                  : C
    );
    /** */

    const calculatedPR = A_PLUS || A || B || C || D || E;
    return !!component ? (
      candidate.platformRating === 0 ? (
        <Fragment>
          <span className="c-red f-12">
            NOTE: The platform calculate and set the value to{" "}
            {labels[calculatedPR]}
          </span>
        </Fragment>
      ) : (
        <Fragment>
          {/** * /}
                <span className="c-gray">{labels[calculatedPR]}</span>
                {/** */}
          {candidate.platformRating !== calculatedPR ? (
            <span className="c-red f-12">
              WARNING: The platform's suggested rating is {labels[calculatedPR]}
            </span>
          ) : (
            ""
          )}
        </Fragment>
      )
    ) : (
      calculatedPR
    );
  },
  cleanCache: (em) =>
    Object.keys(cache).forEach((key) => {
      delete cache[key];
    }),

  getMyPdfUrl: (candidate) => {
    if (!candidate.resumes || !Array.isArray(candidate.resumes)) {
      return "";
    }

    let resume = candidate.resumes.find((el) => /.pdf/.test(el.url));

    if (!!candidate.resumePdfUrl) {
      return candidate.resumePdfUrl;
    } else {
      if (!resume) {
        resume = candidate.resumes[0];
      }

      return resume.url;
    }
  },

  getActives: (recruiterId, cb) => {
    /**
      “active” means:
      - anyone with introduced date <= 6 months
      - anyone with open (state) engagements
      - anyone with engagements pass Over/Hire/Guarantee stage.
     */
    const SIX_MONTH = 6 * 30 * 24 * 60 * 60 * 1000;
    cb = cb instanceof Function ? cb : function () { };
    if (cache.actives) {
      setTimeout((st) => cb(cache.actives));
    } else {
      const candidates = {};
      const results = [];
      let where = {};
      if (recruiterId === -1) {
        where = {};
      } else {
        where = { accountId: recruiterId };
      }
      Http.get(
        Core.getApi("Candidates"),
        {
          filter: JSON.stringify({
            ...commonQuery,
            where: {
              ...where,
              introduced: {
                gt: new Date(Date.now() - SIX_MONTH).toISOString(),
              },
            },
          }),
        },
        function onSuccess(response) {
          response.forEach((cand) => {
            if (!candidates[cand.id]) {
              candidates[cand.id] = true;
              results.push(cand);
            }
          });
          Http.get(
            Core.getApi("Engagements"),
            {
              filter: JSON.stringify({
                where: {
                  or: [
                    { state: "Open" },
                    {
                      or: [
                        { stage: "Over" },
                        { stage: "Hire" },
                        { stage: "Guanrantee" },
                      ],
                    },
                  ],
                },
                fields: { candidateId: true },
                include: {
                  candidate: [
                    "account",
                    { engagements: { job: "employer" } },
                    {
                      relation: "candidateStarreds",
                      scope: {
                        where: {
                          ...where,
                        },
                      },
                    },
                  ],
                },
              }),
            },
            function onSuccess(engagements) {
              if (!!engagements.length) {
                engagements.forEach((eng) => {
                  if (!candidates[eng.candidate.id]) {
                    candidates[eng.candidate.id] = true;
                    results.push(eng.candidate);
                  }
                });
              }
              if (!!results.length) {
                cache.actives = mapCandidates(results);
                cb(cache.actives);
              } else {
                cb([]);
              }
            }
          );
        }
      );
    }
  },
  getAll: (cb, filter, included = true) => {
    cb = cb instanceof Function ? cb : function () { };
    if (cache.all) {
      setTimeout((st) => cb(cache.all));
    } else {
      let commonQuery = included ? commonQuery2 : {};

      Http.get(
        Core.getApi("Candidates"),
        {
          filter: JSON.stringify({ ...commonQuery, ...filter }),
        },
        function onSuccess(response) {
          cache.all = mapCandidates(response);
          cb(cache.all);
        }
      );
    }
  },

  getActiveCandidates: (success, params) => {
    let maxDate;
    if (
      cache.activeCandidates &&
      cache.activeCandidatesDuration === params.duration
    ) {
      let allUpdatedAt = cache.activeCandidates.map(
        (el) => new Date(el.updatedAt)
      );
      maxDate = Math.max(...allUpdatedAt);
      setTimeout((st) => success(cache.activeCandidates));
    }
    return Http.get(
      Core.getApi("Candidates/active-candidates"),
      {
        params: JSON.stringify({
          ...params,
          latestUpdatedAt: maxDate,
        }
        ),
      },
      response => {
        if (response.length) {
          cache.activeCandidates = mapCandidates(response);
          cache.activeCandidatesDuration = params.duration;
        }

        const ret = !!cache.activeCandidates ? cache.activeCandidates : [];
        success(ret);
      }
    );
  },

  getPdfUrl: ({ url, filename, oldFilename }, success) => {
    if (Core.isLocalHost()) {
      // url = "http://d101010.go10x10.com:3000/api/ImportContainer/dev-resume-storage%2F5c57e50310fa3326fed27d48_5cc0d08dfe6ef9145ec2f7c1/download/LIMITED10_CANDIDATE_20190601_161208.pdf"
      url =
        "https://staging-api.go10x10.com/api/ImportContainer/go10x10-resume-storage%2F5bea0c1ae5438171bc83a4df_new20190425T184730155Z/download/LAKSHMI_CHINTA_20190425_114730.DOC";
    }
    Http.post(
      "https://to-pdf.go10x10.com/convert",
      { url, filename, oldFilename },
      success
    );
    //Http.post('http://localhost:5000/convert',{url,filename},success);
  },

  getS3UrlFromBinary: (filesArray, cb) => {
    Http.post(
      "https://to-pdf.go10x10.com/binary_file_to_s3",
      { filesArray },
      (response) => {
        cb(response);
      }
    );
    //Http.post('http://localhost:5000/convert',{url,filename},success);
  },

  getWhere: (where, cb, opts = {}) => {
    cb = cb instanceof Function ? cb : function () { };
    const key = JSON.stringify(where).replace(/\W/g, "");
    if (cache[key]) {
      setTimeout((st) => cb(cache[key]));
    } else {
      Http.get(
        Core.getApi("Candidates"),
        {
          filter: JSON.stringify({
            ...commonQuery,
            where: { ...where },
            ...opts,
          }),
        },
        function onSuccess(response) {
          cache[key] = mapCandidates(response);
          cb(cache[key]);
        }
      );
    }
  },
  get: (candidateId, cb, onFailCb = null, params) => {
    cb = cb instanceof Function ? cb : function () { };
    if (cache[candidateId] && !params?.skipCache) {
      setTimeout((st) => cb(cache[candidateId]));
    } else {
      const commonQueryOverrided = params?.commonQuery || commonQuery;
      Http.get(
        Core.getApi("Candidates/" + candidateId),
        {
          filter: JSON.stringify({ ...commonQueryOverrided })
        },
        function onSuccess(response) {
          cache[candidateId] = mapCandidate(response);
          cb(cache[candidateId]);
        },
        function onFail(response) {
          !!onFailCb && onFailCb(response);
        }
      );
    }
  },
  post: (candidate, success, fail) => {
    Candidate.cleanCache();
    Engagement.cleanCache();

    if (candidate.country === "parser-not-found") {
      candidate.country = "";
    }

    if (candidate.platformRating === 0) {
      candidate.platformRating = Candidate.calculatePlatformRating({ candidate });
    }

    Http.post(
      Core.getApi("Candidates"),
      getStateModel(candidate, getCandidateModel()),
      success,
      fail
    );
  },

  update: (candidateId, candidate, success, failure = () => { }) => {
    Candidate.cleanCache();
    Engagement.cleanCache();

    if (candidate.country === "parser-not-found") {
      candidate.country = "";
    }

    if (candidate.platformRating === 0) {
      candidate.platformRating = Candidate.calculatePlatformRating({ candidate });
    }

    let candidateModel = getCandidateModel();
    let candidateState = getStateModel(candidate, candidateModel);

    return Http.patch(
      Core.getApi("Candidates/" + candidateId),
      candidateState,
      (response) => {
        delete cache[candidateId];
        success instanceof Function && success(response);
      },
      failure
    );
  },
  updateStarred: (candidateId, starredId, starred, success) => {
    Candidate.cleanCache();
    if (starredId) {
      return Http.patch(
        Core.getApi("CandidatedStarred/" + starredId),
        { starred },
        success
      );
    } else {
      return Http.post(
        Core.getApi("CandidatedStarred"),
        {
          candidateId,
          starred,
          accountId: Core.getUserId(),
        },
        success
      );
    }
  },
  getNames: (success) => {
    Http.get(
      Core.getApi("Candidates"),
      {
        filter: JSON.stringify({
          fields: { id: true, firstName: true, lastName: true },
        }),
      },
      (response) => success(response)
    );
  },

  getOwnedPermittedJobs: (opts, success) => {
    Http.get(
      Core.getApi("Candidates/cando-owned-permitted-jobs"),
      opts.params,
      (response) => success(response)
    );
  },

  candoInConflictInPermittedJobOwnership: (opts, success) => {
    Http.get(
      Core.getApi("Candidates/conflicted-candos-permitted-jobs-status"),
      opts.params,
      (response) => success(response)
    );
  },

  /*
   * Make sure URLs include the appropriate location (e.g. linkedin or github).
   * Otherwise set them to null
   */
  processLinkedinGithubUrls: (candidate) => {
    let linkedinUrl = "";
    let githubUrl = "";

    try {
      linkedinUrl = candidate.linkedInURL.match(/linkedin.+\w/g).pop();
    } catch (e) { }

    /*
     *  Need to make sure the url is more than a generic url
     */
    if (!!linkedinUrl && linkedinUrl.split("/").length <= 2) {
      /*
       *  if there isn't more than linkedin.com/in/ then set to null
       */
      linkedinUrl = "";
    }

    try {
      githubUrl = candidate.gitHubURL.match(/github.+\w/g).pop();
    } catch (e) { }

    /*
     *  Need to make sure the url is more than a generic url
     */
    if (!!githubUrl) {
      /*
       *  Check if there is more to the url than github.com or github.io
       */
      if (githubUrl.match(/github.com/g)) {
        // check that the url is github.com/<username>/
        if (githubUrl.split("/").length <= 1) {
          githubUrl = "";
        }
      } else if (githubUrl.match(/github.io/g)) {
        // check that the url is <username>.github.io
        if (githubUrl.split(".").length <= 2) {
          githubUrl = "";
        }
      } else {
        // url doesn't include github.com or github.io
        githubUrl = "";
      }
    }

    return { githubUrl, linkedinUrl };
  },

  getPotentialDuplicated: (candidate, success) => {

    Http.get(
      Core.getApi("Candidates/duplicated"),
      {
        candidate: JSON.stringify({
          id: candidate.id,
          firstName: (candidate.firstName || "").trim(),
          lastName: (candidate.lastName || "").trim(),
          email: (candidate.email || "").trim(),
          phone: (candidate.phone || "").trim(),
          linkedInURL: (candidate.linkedInURL || "").trim(),
          gitHubURL: (candidate.gitHubURL || "").trim(),
        }),
      },
      function onSuccess(response) {
        success(
          mapCandidates(response.filter((can) => can.id !== candidate.id))
        );
      }
    );
  },

  getPotentialDuplicatedWithOwnerships: (candidate, success) => {
    const { linkedinUrl, githubUrl } = Candidate.processLinkedinGithubUrls(
      candidate
    );

    Http.get(
      Core.getApi("Candidates/duplicated-ownerships"),
      {
        candidate: JSON.stringify({
          id: candidate.id,
          firstName: (candidate.firstName || "").trim(),
          lastName: (candidate.lastName || "").trim(),
          email: (candidate.email || "").trim(),
          phone: (candidate.phone || "").trim(),
          linkedInURL: (linkedinUrl || "").trim(),
          gitHubURL: (githubUrl || "").trim(),
        }),
      },
      function onSuccess(response) {
        success(response);
      }
    );
  },

  delete: (candidateId, success, fail = null) => {
    Candidate.cleanCache();
    Engagement.cleanCache();
    Engagement.getWhere({ candidateId }, (response) => {
      // Core.log({ response });
      Http.delete(Core.getApi("Candidates/" + candidateId), success, fail);
      const next = (em) => {
        setTimeout((st) => {
          if (!!response.length) {
            const eng = response.pop();
            // Core.log({ eng });
            Http.delete(Core.getApi("Engagements/" + eng.id), (response) => {
              eng.boxKey && Streak.deleteBox({ boxKey: eng.boxKey });
            });
            next();
          }
        });
      };
      next();
    });
  },

  getCount: (success, filter = {}) => {
    Http.get(
      Core.getApi("Candidates/count"),
      { where: JSON.stringify(filter) },
      (response) => success(response.count)
    );
  },

  sendNewCandidateEmail: (params, success, failure, noDialog) => {
    const { from, to, cc, subject, html } = params;
    const send = (ev) => {
      Core.dialog.close();
      Http.post(
        Core.getApi("Candidates/sendNewCandidateEmail"),
        params,
        (response) => {
          success
            ? success(response)
            : !noDialog && Core.showMessage("Email sent to Candidate.");
        },
        (error) => {
          failure
            ? failure(error)
            : Core.failure({
              source: 'Candidate.js(lib)>sendNewCandidateEmail',
              exception: error,
              params,
              omitUIMessage: noDialog,
            });
        }
      );
    };
    if (noDialog) {
      send();
    } else {
      Core.dialog.open({
        title: "Email Preview",
        message: (
          <Fragment>
            <p>
              From: {from}
              <br />
              To: {to}
              <br />
              Cc: {cc}
              <br />
              Subject: {subject}
              <br />
            </p>
            <hr />
            <div
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </Fragment>
        ),
        actions: [
          <FlatButton
            label="Cancel"
            primary={true}
            onClick={Core.dialog.close}
          />,
          <FlatButton label="Send" primary={true} onClick={send} />,
        ],
      });
    }
  },

  sovrenOnSuccess: (
    response,
    currentResumeTimestamp,
    addToMongo = false,
    raw,
    cbMongo,
    cbForResumeFiles
  ) => {
    const normalizeEntityName = (name) => {
      if (!name) {
        return "";
      }
      return name
        .trim()
        .split(" ")
        .map((word) => _.capitalize(word))
        .join(" ");
    };

    const responseData = response["Resume"]["StructuredXMLResume"];
    const responseSummary = response["Resume"]["UserArea"];

    if (addToMongo) {
      const mongoEntryData = {
        contactInfo: responseData["ContactInfo"],
        employmentHistory: responseData["EmploymentHistory"],
        licensesAndCertifications: responseData["LicensesAndCertifications"],
        languages: responseData["Languages"],
        achievements: responseData["Achievements"],
        associations: responseData["Associations"],
        revisionDate: responseData["RevisionDate"],
        textResume: {},
        pdfResume: {},
        userArea: responseSummary,
        version: responseSummary["sov:ResumeUserArea"]["sov:ParserVersion"],
        raw,
        resumeTimestamp: currentResumeTimestamp,
      };

      SovrenData.post(mongoEntryData, (response) => {
        Candidate.sovrenToFiles(
          response.id,
          raw,
          mongoEntryData.version,
          cbForResumeFiles
        );
        !!cbMongo && cbMongo();
      });
    }

    const getContactDetails = () => {
      const baseArray = dig(responseData, "ContactInfo", "ContactMethod");

      let linkedInURL = "";
      let gitHubURL = "";

      let phone = "";
      let email = "";

      if (!!baseArray) {
        // Search for contact information we need
        baseArray.forEach((obj) => {
          Object.keys(obj).forEach((key) => {
            if ((key === "Telephone" || key === "Mobile") && phone === "") {
              // save the first phone number we find, ignore Fax, Pager and TTYTDD numbers
              phone = obj[key].FormattedNumber;
            } else if (key === "InternetEmailAddress" && email === "") {
              // save the first email address we find
              email = obj[key];
            } else if (/linked/i.test(obj[key])) {
              linkedInURL = formatURL(obj[key]);
            } else if (/github/i.test(obj[key])) {
              gitHubURL = formatURL(obj[key]);
            }
          });
        });
      }
      return { gitHubURL, email, linkedInURL, phone };
    };
    /**
     *  Pulls urls identified by sovren parser.  Returns a string of cancatenated urls
     */
    const getOtherUrls = () => {
      const baseArray = dig(
        responseSummary,
        "sov:ResumeUserArea",
        "sov:ReservedData",
        "sov:Urls",
        "sov:Url"
      );
      let otherLinks = [];
      let stackoverflowUrl = "";

      if (!!baseArray) {
        baseArray.forEach((obj) => {
          if (/stackoverflow/i.test(obj)) {
            stackoverflowUrl = formatURL(obj);
          }
          if (
            !/linkedin/i.test(obj) &&
            !/github/i.test(obj) &&
            !/stackoverflow/i.test(obj)
          ) {
            otherLinks.push(obj);
          }
        });
      }
      return { otherLinks: otherLinks.join(","), stackoverflowUrl };
    };

    /**
     *  Returns a string that can be "current", a date in the format "Year" or "Month Year", or null
     *  Dates have their time set to 00:00:00.000Z (UTC) using moment.utc
     *  This way the Date will be correct regardless of the user's time zone
     */
    const toDate = (type, date, format = "MMMM YYYY") => {
      if (!date || date === "notKnown" || date === "notApplicable") {
        return null;
      } else if (type === "StringDate") {
        return "current";
      } else {
        let newDate = null;
        switch (type) {
          case "Year":
          case "YearMonth":
          case "AnyDate":
            newDate = moment.utc(date).format(format);
            break;
          default:
        }
        return !!newDate ? newDate : null;
      }
    };

    const toMonthYear = (type, date) => {
      if (!date) {
        return null;
      }

      if (type === "YearMonth") {
        let split = date.split("-");
        return { month: +split[1], year: +split[0] };
      } else if (type === "Year") {
        return { month: null, year: +date };
      } else if (type === "AnyDate" && moment(date).isValid()) {
        let split = date.split("-");
        return { month: +split[1], year: +split[0] };
      } else {
        return { month: null, year: null };
      }
    };

    const getCurrentEmployerDetails = () => {
      let lastEmployer = Object(
        (dig(responseData, "EmploymentHistory", "EmployerOrg") || [])[0]
      );

      if (!lastEmployer) {
        return {
          currentEmployer: "",
          currentTitle: "",
          currentEmployerFrom: "",
          currentEmployerTo: "",
          yearsOfExperience: "",
          currentlyEmployed: "",
        };
      }

      let currentEmployer = normalizeEntityName(lastEmployer.EmployerOrgName);
      let currentTitle =
        dig(lastEmployer, "PositionHistory", [0], "Title") || ""; //job title
      let currentEmployerFrom =
        toDate(
          "YearMonth",
          dig(lastEmployer, "PositionHistory", [0], "StartDate", "YearMonth")
        ) ||
        toDate(
          "Year",
          dig(lastEmployer, "PositionHistory", [0], "StartDate", "Year"),
          "YYYY"
        ) ||
        toDate(
          "StringDate",
          dig(lastEmployer, "PositionHistory", [0], "StartDate", "StringDate")
        ) ||
        toDate(
          "AnyDate",
          dig(lastEmployer, "PositionHistory", [0], "StartDate", "AnyDate")
        ) ||
        "";
      let currentEmployerTo =
        toDate(
          "YearMonth",
          dig(lastEmployer, "PositionHistory", [0], "EndDate", "YearMonth")
        ) ||
        toDate(
          "Year",
          dig(lastEmployer, "PositionHistory", [0], "EndDate", "Year"),
          "YYYY"
        ) ||
        toDate(
          "StringDate",
          dig(lastEmployer, "PositionHistory", [0], "EndDate", "StringDate")
        ) ||
        toDate(
          "AnyDate",
          dig(lastEmployer, "PositionHistory", [0], "EndDate", "AnyDate")
        ) ||
        "";

      // Let's get the parser total months of experience
      let parserTotalMonthsOfExp = null;
      let adjustedTotalYearsOfExp = null;
      let yearsOfExperience = null;
      let monthsOfExp =
        dig(
          responseSummary,
          "sov:ResumeUserArea",
          "sov:ExperienceSummary",
          "sov:MonthsOfWorkExperience"
        ) || "";

      if (!!monthsOfExp) {
        parserTotalMonthsOfExp = parseInt(monthsOfExp);
        adjustedTotalYearsOfExp =
          Math.round((parserTotalMonthsOfExp / 12) * 10) / 10; // Round to the nearest 0.1 year
        yearsOfExperience = adjustedTotalYearsOfExp;
      }

      let currentlyEmployed = 2;

      if (currentEmployerTo === "current") {
        currentlyEmployed = 1;
      }

      return {
        currentEmployer,
        currentTitle,
        currentEmployerFrom,
        currentEmployerTo,
        yearsOfExperience,
        currentlyEmployed,
        parserTotalMonthsOfExp,
        adjustedTotalYearsOfExp,
      };
    };

    const getCurrentSchoolDetails = () => {
      let lastEducation = Object(
        dig(responseData, "EducationHistory", "SchoolOrInstitution", [0])
      );

      if (!lastEducation) {
        return {
          degreeType: "",
          undergraduateDegree: "",
          undergraduateMajor: "",
          undergraduateSchool: "",
          graduationYear: "",
        };
      }

      let lastDegree = dig(lastEducation, "Degree", [0]);

      if (!lastDegree) {
        return {
          degreeType: "",
          undergraduateDegree: "",
          undergraduateMajor: "",
          undergraduateSchool: "",
          graduationYear: "",
        };
      }

      const degreeType = lastDegree["@degreeType"];
      let undergraduateDegree =
        dig(
          lastDegree,
          "UserArea",
          "sov:DegreeUserArea",
          "sov:NormalizedDegreeName"
        ) || "";
      undergraduateDegree = Definition.getId(
        "undergraduateDegree",
        undergraduateDegree
      );

      let undergraduateMajor = (Object((lastDegree.DegreeMajor || [])[0])
        .Name || [])[0];
      let graduationYear =
        toDate("YearMonth", dig(lastDegree, "DegreeDate", "YearMonth")) ||
        toDate("Year", dig(lastDegree, "DegreeDate", "Year"), "YYYY") ||
        toDate("StringDate", dig(lastDegree, "DegreeDate", "StringDate")) ||
        toDate("AnyDate", dig(lastDegree, "DegreeDate", "AnyDate")) ||
        "";
      let undergraduateSchool = normalizeEntityName(
        dig(
          lastEducation,
          "UserArea",
          "sov:SchoolOrInstitutionTypeUserArea",
          "sov:NormalizedSchoolName"
        ) || ""
      );
      return {
        degreeType,
        undergraduateDegree,
        undergraduateMajor,
        undergraduateSchool,
        graduationYear,
      };
    };

    const getEmploymentHistory = () => {
      let employmentHistory = dig(
        responseData,
        "EmploymentHistory",
        "EmployerOrg"
      );

      if (!employmentHistory) {
        return [];
      }

      return employmentHistory.map((experience, index) => {
        let timestampTemporary = Math.random().toString(36);

        return {
          id: index,
          employerOrgName: normalizeEntityName(
            dig(
              experience,
              "UserArea",
              "sov:EmployerOrgUserArea",
              "sov:NormalizedEmployerOrgName"
            )
          ),
          isCurrentEmployer: (dig(experience, "PositionHistory") || []).some(
            (xp) => xp["@currentEmployer"] === "true"
          ),
          timestampTemporary,
          positionHistories: (dig(experience, "PositionHistory") || []).map(
            (position, index) => {
              // Get the startDate String
              let startDate = null;
              let startDateString =
                toDate("YearMonth", dig(position, "StartDate", "YearMonth")) ||
                toDate("Year", dig(position, "StartDate", "Year"), "YYYY") ||
                toDate(
                  "StringDate",
                  dig(position, "StartDate", "StringDate")
                ) ||
                toDate("AnyDate", dig(position, "StartDate", "AnyDate"));

              // get the date from the date string
              if (!!startDateString && startDateString !== "current") {
                startDate = moment.utc(startDateString); // creates a date in UTC to avoid timezone changes
              } else {
                startDate = null;
              }

              // Get the endDate String
              let endDate = null;
              let endDateString =
                toDate("YearMonth", dig(position, "EndDate", "YearMonth")) ||
                toDate("Year", dig(position, "EndDate", "Year"), "YYYY") ||
                toDate("StringDate", dig(position, "EndDate", "StringDate")) ||
                toDate("AnyDate", dig(position, "EndDate", "AnyDate"));

              // Check the currently employed status
              let currentlyEmployed = 2;

              if (!!endDateString && endDateString !== "current") {
                endDate = moment.utc(endDateString); // creates a date in UTC to avoid timezone changes
              } else {
                currentlyEmployed = 1;
                endDate = null;
              }

              let startYearMonthObject = toMonthYear(
                "YearMonth",
                dig(position, "StartDate", "YearMonth")
              ) ||
                toMonthYear("Year", dig(position, "StartDate", "Year")) ||
                toMonthYear(
                  "StringDate",
                  dig(position, "StartDate", "StringDate")
                ) ||
                toMonthYear(
                  "AnyDate",
                  dig(position, "StartDate", "AnyDate")
                ) || { month: null, year: null };

              let endYearMonthObject = toMonthYear(
                "YearMonth",
                dig(position, "EndDate", "YearMonth")
              ) ||
                toMonthYear("Year", dig(position, "EndDate", "Year")) ||
                toMonthYear(
                  "StringDate",
                  dig(position, "EndDate", "StringDate")
                ) ||
                toMonthYear("AnyDate", dig(position, "EndDate", "AnyDate")) || {
                month: null,
                year: null,
              };

              return {
                id: index,
                positionType: position["@positionType"],
                title: dig(
                  position,
                  "UserArea",
                  "sov:PositionHistoryUserArea",
                  "sov:NormalizedTitle"
                ),
                description: position.Description,
                startDate,
                endDate,
                startDateMonth: startYearMonthObject.month,
                startDateYear: startYearMonthObject.year,
                endDateMonth: endYearMonthObject.month,
                endDateYear: endYearMonthObject.year,
                currentlyEmployed,
                isSelfEmployed: dig(
                  position,
                  "UserArea",
                  "sov:PositionHistoryUserArea",
                  "sov:IsSelfEmployed"
                ),
              };
            }
          ),
        };
      });
    };

    const getEducationHistory = () => {
      let educationHistory = dig(
        responseData,
        "EducationHistory",
        "SchoolOrInstitution"
      );

      if (!educationHistory) {
        return [];
      }

      return educationHistory.map((education, index) => {
        return {
          id: index,
          timestampTemporary: Math.random().toString(36),
          schoolName: normalizeEntityName(
            dig(
              education,
              "UserArea",
              "sov:SchoolOrInstitutionTypeUserArea",
              "sov:NormalizedSchoolName"
            )
          ),
          schoolType: dig(education, "@schoolType"),
          postalAddress: {
            countryCode: dig(education, "PostalAddress", "CountryCode"),
            region: dig(education, "PostalAddress", "CountryCode"),
            municipality: dig(education, "PostalAddress", "Municipality"),
          },
          degrees: (dig(education, "Degree") || []).map((degree, index) => {
            const startDate = (degree.DatesOfAttendance || [])[0] || {};
            const degreeType = degree["@degreeType"];

            let degreeStartDate = null;
            let startDateString =
              toDate("YearMonth", dig(startDate, "StartDate", "YearMonth")) ||
              toDate("Year", dig(startDate, "StartDate", "Year"), "YYYY") ||
              toDate("StringDate", dig(startDate, "StartDate", "StringDate")) ||
              toDate("AnyDate", dig(startDate, "StartDate", "AnyDate"));
            // now get a date from the string
            if (!!startDateString && startDateString !== "current") {
              degreeStartDate = moment.utc(startDateString); // creates a date in UTC to avoid timezone changes
            } else {
              degreeStartDate = null;
            }

            let startYearMonthObject = toMonthYear(
              "YearMonth",
              dig(startDate, "StartDate", "YearMonth")
            ) ||
              toMonthYear("Year", dig(startDate, "StartDate", "Year")) ||
              toMonthYear(
                "StringDate",
                dig(startDate, "StartDate", "StringDate")
              ) ||
              toMonthYear(
                "AnyDate",
                dig(startDate, "StartDate", "AnyDate")
              ) || { month: null, year: null };

            let degreeEndDate = null;
            let endDateString =
              toDate("YearMonth", dig(degree, "DegreeDate", "YearMonth")) ||
              toDate("Year", dig(degree, "DegreeDate", "Year"), "YYYY") ||
              toDate("StringDate", dig(degree, "DegreeDate", "StringDate")) ||
              toDate("AnyDate", dig(degree, "DegreeDate", "AnyDate"));

            // now get a date from the string
            if (!!endDateString && endDateString !== "current") {
              degreeEndDate = moment.utc(endDateString); // creates a date in UTC to avoid timezone changes
            } else {
              degreeEndDate = null;
            }

            let endYearMonthObject = toMonthYear(
              "YearMonth",
              dig(degree, "DegreeDate", "YearMonth")
            ) ||
              toMonthYear("Year", dig(degree, "DegreeDate", "Year")) ||
              toMonthYear(
                "StringDate",
                dig(degree, "DegreeDate", "StringDate")
              ) ||
              toMonthYear("AnyDate", dig(degree, "DegreeDate", "AnyDate")) || {
              month: null,
              year: null,
            };

            // Bob is inclined to use DegreeType but Omair thinks DegreeType might not always be available.
            // We need to give it time and see what is better

            return {
              id: index,
              degreeType,
              degreeName: dig(
                degree,
                "UserArea",
                "sov:DegreeUserArea",
                "sov:NormalizedDegreeName"
              ),
              degreeMajor:
                (Object((degree.DegreeMajor || [])[0]).Name || [])[0] || "",
              degreeMinor:
                (Object((degree.DegreeMinor || [])[0]).Name || [])[0] || "",
              startDate: degreeStartDate,
              endDate: degreeEndDate,
              startDateMonth: startYearMonthObject.month,
              startDateYear: startYearMonthObject.year,
              endDateMonth: endYearMonthObject.month,
              endDateYear: endYearMonthObject.year,
            };
          }),
        };
      });
    };

    let firstName = normalizeEntityName(
      dig(responseData, "ContactInfo", "PersonName", "GivenName")
    );
    let lastName = normalizeEntityName(
      dig(responseData, "ContactInfo", "PersonName", "FamilyName")
    );
    let country = dig(
      response["Resume"],
      "UserArea",
      "sov:ResumeUserArea",
      "sov:Culture",
      "sov:Country"
    );
    let averageMonthsPerEmployer = dig(
      response["Resume"],
      "UserArea",
      "sov:ResumeUserArea",
      "sov:ExperienceSummary",
      "sov:AverageMonthsPerEmployer"
    );

    if (!!country) {
      country = country.toUpperCase();
    } else {
      country = "parser-not-found";
    }

    console.log({ employmentHisotry: getEmploymentHistory() });
    console.log({ educationHistory: getEducationHistory() });
    console.log({
      dddd: {
        country,
        firstName,
        lastName,
        ...getContactDetails(),
        currentlyEmployed: 0,
        averageMonthsPerEmployer,
        employmentHistories: getEmploymentHistory(),
        educationHistories: getEducationHistory(),
        ...getCurrentEmployerDetails(),
        ...getCurrentSchoolDetails(),
        otherLinks: getOtherUrls(),
      },
    });

    const { otherLinks, stackoverflowUrl } = getOtherUrls();

    return {
      country,
      firstName,
      lastName,
      ...getContactDetails(),
      currentlyEmployed: 0,
      averageMonthsPerEmployer,
      employmentHistories: getEmploymentHistory(),
      educationHistories: getEducationHistory(),
      ...getCurrentEmployerDetails(),
      ...getCurrentSchoolDetails(),
      otherLinks,
      stackoverflowUrl,
    };
  },

  sovrenParseResume: (
    resume,
    key,
    currentResumeTimestamp,
    cb,
    cbMongo,
    cbResumeFiles,
    cbOnFailure
  ) => {

    let reader = new FileReader();

    const failure = (error) => {
      cbOnFailure && cbOnFailure();
      Core.showFailure(`The parser failed to parse your resume giving status code ${error}. Your resume was uploaded suuccessflly and you can proceed with submitting the candidate. Please add a note in your candidate write up regarding the parser failure.`);
    };

    reader.onload = function (event) {
      let base64Text = Base64.encodeArray(event.target.result);
      const url = `${SOVREN_PARSER_URL}/parser/resume`;

      let data = {
        DocumentAsBase64String: base64Text,
        Configuration:
          "Coverage.AddCertificationsAndLicensesToSkills = true; Coverage.AddLanguagesToSkills = true; Coverage.AddPositionTitlesToSkills = true; Coverage.MilitaryHistoryAndSecurityCredentials = true; Coverage.PatentsPublicationsAndSpeakingEvents = true; OutputFormat.CreateBullets = true; OutputFormat.NormalizeRegions = true; OutputFormat.AllSummariesInEnglish = true; OutputFormat.ReformatPositionHistoryDescription = true; OutputFormat.TelcomNumber.Style = Formatted; Culture.PreferEnglishVersionIfTwoLanguagesInDocument = true",
        RevisionDate: moment(currentResumeTimestamp).format("YYYY-MM-DD"),
        OutputHtml: true,
        OutputPdf: true,
      };

      const headers = {
        Accept: "application/json",
        "Sovren-AccountId": "16174178",
        "Sovren-ServiceKey": "PGlFJgq2nVOGVVdUokFAbc8EUpnsQRWc2MOg9pVS",
      };

      Http.post(url, { ...data, headers }, (returnData) => {
        let response = JSON.parse(returnData.Value.ParsedDocument);
        returnData.Value.ParsedDocument = response;

        //check usage count
        // if usage count is 100 multiple then send email to admin
        if (
          +returnData.Value.CreditsRemaining % 100 === 0 ||
          +returnData.Value.CreditsRemaining % 100 === 1
        ) {
          /*
           * Commented out for now given this call costs 1 credit and returns results not aligned with the credits remaining
           * due to the asynchronous nature of repeated calls.
           *
          SovrenData.getAccountInfo((result) => {
            let {CreditsUsed, CreditsRemaining, ExpirationDate } = result.Value;
            const from = Core.getNewCandidateReceiverNotiFromEmail();
            const to = 'recruiter@10by10.io';

            let content = ``;

            content += `<p>Credits User so far are: <strong>${CreditsUsed}</strong></p>`;
            content += `<p>Credits remaining are: <strong>${CreditsRemaining}</strong></p>`;

            const expiryMoment = moment(ExpirationDate);
            const today = moment();
            const expiringInDays = expiryMoment.diff(today, 'day');

            content += `<p>Sovren account expiring in <strong>${expiringInDays} days</strong></p>`;

            Google.sendEmail(
              {
                from,
                to,
                subject: 'ALERT: Sovren Usage Info',
                html: content,
                source: 'lib/Candidate.js sovren account usage line - 1269'
              },
              null,
              error => Core.showMessage(error)
            );
          });
          */
          const from = Core.getNewCandidateReceiverNotiFromEmail();
          const to = "recruiter@10by10.io";

          let content = ``;

          content += `<p>Credits remaining are: <strong>${returnData.Value.CreditsRemaining}</strong></p>`;

          Google.sendEmail(
            {
              from,
              to,
              subject: "ALERT: Sovren Usage Info",
              html: content,
              source: "lib/Candidate.js sovren account usage line - 1269",
            },
            null,
            (error) => Core.showMessage(error)
          );
        }

        console.log({ sovrenResponse: response });
        return cb(
          Candidate.sovrenOnSuccess(
            response,
            currentResumeTimestamp,
            true,
            returnData,
            cbMongo,
            cbResumeFiles
          ),
          returnData
        );
      }, failure);

    };

    reader.readAsArrayBuffer(resume);
  },

  sovrenToFiles: (sovrenDataId, raw, version, cb) => {
    /*
     *  The filename for coverted resume files should include the following:
     *    id - the id of the corresponding SovrenData Collection entry in mongodb
     *    timestamp - the time we are sending the files to the S3 storage service
     *    random - a random hexadecimal number between 1 and 10 digits
     *    SVP - to signify the Sovren Parser
     *    version - the sovren parser version number with the '.' characters changed to '-'
     */
    const id = sovrenDataId;
    const timestamp = new Date().getTime();
    const minHex = 0x1000000000; // minimum 10 digit hexadecimal number
    const maxHex = 0xffffffffff; // maximum 10 digit hexadecimal number
    const random = Math.floor(Math.random() * (maxHex - minHex + 1)) + minHex; // +1 to have the number include min and max

    const filename = `${SOVREN_FILENAME_PREFIX}${id}_${timestamp}_${random.toString(
      16
    )}_SVP_${version.replace(/\./g, "-")}`;

    const filesToBeConverted = [
      {
        fileType: "pdf",
        key: "Pdf",
        fileData: raw.Value.Pdf,
        filename,
      },
      {
        fileType: "html",
        key: "Html",
        fileData: raw.Value.Html,
        filename,
      },
      {
        fileType: "txt",
        key: "Text",
        fileData: raw.Value.Text,
        filename,
      },
      {
        fileType: "txt",
        key: "ScrubbedParsedDocument",
        fileData: JSON.parse(raw.Value.ScrubbedParsedDocument).Resume
          .NonXMLResume.TextResume,
        filename: `${filename}_scrubbed`,
      },
      {
        fileType: "json",
        key: "raw",
        fileData: raw,
        filename,
      },
    ];

    Candidate.getS3UrlFromBinary(filesToBeConverted, (response) => {
      const urls = response.urls;
      const data = {
        filenamePrefix: SOVREN_FILENAME_PREFIX,
        textResume: Object(urls.find((obj) => obj.key === "Text")).url,
        pdfResume: Object(urls.find((obj) => obj.key === "Pdf")).url,
        scrubbedParsedDocument: Object(
          urls.find((obj) => obj.key === "ScrubbedParsedDocument")
        ).url,
        htmlResume: Object(urls.find((obj) => obj.key === "Html")).url,
        raw: {
          ...raw,
          Value: {
            ...raw.Value,
            Pdf: null,
            Html: null,
            Text: null,
            ScrubbedParsedDocument: null,
          },
        },
      };

      SovrenData.update(id, data);

      const stateAttrs = {
        resumePdfUrl: data.pdfResume,
        resumeTxtUrl: data.textResume,
        resumeScrubbedUrl: data.scrubbedParsedDocument,
        htmlResumeUrl: data.htmlResume,
        resumeJsonUrl: Object(urls.find((obj) => obj.key === "raw")).url,
        key: "resumes",
      };

      cb(stateAttrs);
    });
  },

  sovrenResumeLoad: (
    timestamp,
    forceSovrenToFiles = false,
    cb,
    stateUpdateCb
  ) => {
    const filter = {
      where: {
        resumeTimestamp: timestamp,
      },
      fields: [
        "raw",
        "id",
        "textResume",
        "pdfResume",
        "scrubbedParsedDocument",
        "htmlResume",
        "version",
      ],
      limit: 1,
    };

    SovrenData.get(filter, (response) => {
      const singleSovren = Object(response[0]);
      let rawForm = Object(response[0]).raw;
      let id = Object(response[0]).id;

      rawForm = rawForm.Value.ParsedDocument;
      let result = false;

      if (rawForm) {
        result = Candidate.sovrenOnSuccess(rawForm, null);
      }

      if (
        forceSovrenToFiles ||
        !singleSovren.textResume ||
        !singleSovren.pdfResume ||
        !singleSovren.textResume ||
        !singleSovren.scrubbedParsedDocument
      ) {
        const raw = response[0].raw;

        if (!!raw.Value.Html) {
          Candidate.sovrenToFiles(id, raw, singleSovren.version, stateUpdateCb);
        }
      }

      cb(result, rawForm);
    });
  },

  parseResume: (url, cb) => {
    const failure = (error) => {
      return false;
      /** * /
      try {
        Core.showFailure(
          "Parser: " +
            String(error)
              .split(":")
              .pop()
        );
      } catch (ex) {
        Core.showFailure("Parser: " + error);
      }
      /** */
    };
    Http.post(
      Core.getApi("Candidates/parseResume"),
      { url },
      (response) => {
        if (response.error) {
          return failure(response.error);
        }
        const parsedResume = response[0];
        Core.log("Candidate.parseResume", { parsedResume });
        if (parsedResume) {
          const model = {};
          Object.keys(getCandidateModel()).forEach((key) => {
            if (parsedResume[key]) {
              model[key] = parsedResume[key];
            }
          });
          if (!!parsedResume["name"] && !!parsedResume["name"].trim().length) {
            const splitName = parsedResume["name"].split(" ");
            if (splitName.length >= 2) {
              model.lastName = splitName.pop();
              model.firstName = splitName.join(" ");
            } else {
              model.firstName = splitName.join(" ");
            }
          }
          if (!!parsedResume.profiles && !!parsedResume.profiles.length) {
            const split = parsedResume.profiles.split(" |, |; ");
            split.forEach((value) => {
              if (/github/i.test(value)) {
                model.gitHubURL = value;
              } else if (/linkedin/i.test(value)) {
                model.linkedInURL = value;
              } else {
                model.links = model.links ? model.links + value : value;
              }
            });
          }
          if (typeof parsedResume.education === "string") {
            model.undergraduateSchool = parsedResume.education;
          }
          if (typeof parsedResume.experience === "string") {
            const skills = Definition.getAll().technicalSkillsIds;
            const skillKeys = {};
            parsedResume.experience.split(" ").forEach((word) => {
              word = word.replace(/\W/g, "").toLowerCase();
              Object.keys(skills).forEach((key) => {
                if (word === key.replace(/\W/g, "").toLowerCase()) {
                  skillKeys[skills[key]] = true;
                }
              });
            });
            const ts = Object.keys(skillKeys);
            if (ts.length) {
              model.technicalSkills = ts;
            }
            /** TODO commented because this is not sufficent acurated * /
            const roles = Definition.getAll().rolesIds;
            const roleKeys = {};
            parsedResume.experience.split("\n").forEach(word => {
              word = word.replace(/\W/g, "");
              Object.keys(roles).forEach(key => {
                if (new RegExp(key, "i").test(word)) {
                  roleKeys[roles[key]] = true;
                }
              });
            });
            const rs = Object.keys(roleKeys);
            if (rs.length) {
              model.roles = rs;
            }
            /** */
          }
          if (typeof parsedResume.skills === "string") {
            const skills = Definition.getAll().technicalSkillsIds;
            const skillKeys = {};
            parsedResume.skills.split(" ").forEach((word) => {
              word = word.replace(/\W/g, "").toLowerCase();
              Object.keys(skills).forEach((key) => {
                if (word === key.replace(/\W/g, "").toLowerCase()) {
                  skillKeys[skills[key]] = true;
                }
              });
            });
            const ts = Object.keys(skillKeys);
            if (ts.length) {
              model.technicalSkills = ts;
            }
          }
          if (!!Object.keys(model).length) {
            cb && cb(model);
          } else {
            // Core.showMessage("No matched data");
          }
        } else {
          // Core.showMessage("No matched data");
        }
      },
      failure
    );
  },

  isStale: (candidateId, candoUpdatedAt, successCb) => {
    Candidate.get(candidateId, (cando) => {
      console.log({ candoUpdatedAt, latest: cando.updatedAt })
      if (candoUpdatedAt !== cando.updatedAt) {
        successCb({
          isStale: true
        })
      }

      successCb({ isStale: false });
    }, () => {

    }, { skipCache: true, commonQuery: { fields: ['updatedAt'] } })
  },
  getMenu({ key }) {
    return menus.find(n => n.key === key);
  },
  getMoreMenu({ key }) {
    return more.find(n => n.key === key);
  },

  search: ({
    query = {
      "filters": [
        {
          "state": STATE_ACTIVE,
          // "candidateStarreds.starred":true,
          // "candidateStarreds.accountId":"604672f6ea71851aa4b719a0",
        }
      ],
      "sort": { "firstName": 1, "lastName": 1 },
      "skip": 0,
      "limit": 10,
      "query": "",
      "associations": [
        'account',
        'candidateStarreds',
        'engagements.job.employer'
      ]
    },
    onSuccess = () => { }
  } = { onSuccess() { } }) => {
    const mapResponse = response => {
      if (response[0]) {
        response = response[0];
        response.query = query;
        response.results = mapCandidates(response.results);
      }
      else {
        response = {
          query,
          results: []
        }
      }
      return response;
    }
    return Http.get(
      Core.getApi('Candidates/_search'),
      { query: JSON.stringify(query) },
      response => onSuccess(mapResponse(response))
    ).then(mapResponse);
  },

  suggestions: async ({ query = '' }) => {
    if (query.trim().length >= 2) {
      return Http.get(
        Core.getApi('Candidates/_autocomplete'),
        { query },
      ).then(response => response.map(({ term }) => term));
    }
    else {
      return [];
    }
  },

  fetchEngagements({ candidateId, cb }) {
    let where = {};
    if (Core.isAdminOrCoordinator()) {
      where = { candidateId };
    } else {
      where = { candidateId, state: "Open" };
    }
    Engagement.cleanCache();
    return Engagement.getWhere(where, undefined, undefined, true).then(mapEngagements);
  },
  bulkCopy(candidate) {
    Candidate.fetchEngagements({ candidateId: candidate.id }).then(
      engagements => {
        const contents = [];
        engagements.forEach(eng => {
          if (/confirmation/i.test(eng.stage) && /open/i.test(eng.state)) {
            // Core.log({ job: Job.getPreview(eng.job) });
            contents.push(Job.getPreview(eng.job));
          }
        });
        if (!!contents.length) {
          copyHTML("<p>" + contents.join("</p><p>") + "</p>")
            .then(em => {
              Core.log("Copy email command was successful");
              Core.showMessage("Copied!");
            })
            .catch(ex => {
              Core.log("Oops, unable to copy");
              Core.showMessage("Fail copy!");
            });
        } else {
          Core.showMessage("There are no Engagements on Confirmation stage");
        }
      }
    )
  },

  addEngagementToPermittedJobs({ candidate, engagement }) {
    const { id, jobsPermitted } = candidate;
    return Candidate
      .update(id, { jobsPermitted: [...jobsPermitted, engagement.jobId] })
      .then(mapCandidate);
  },

};

export default Candidate;
