/** ============================================ µ
 * @description Match [JS]
 *              UI Component
 * @route       /candidate/matchNew/:candidateId
 * @updatedAt   2021-07-16
 * ============================================ */

/* IMPORTS ==================================== */

import {
  Checkbox as MuiCheckbox,
  Divider as MuiDivider, Input, Select
} from "@mui/material";
import {
  Checkbox,
  Divider,
  FlatButton,
  List,
  ListItem,
  MenuItem,
  RadioButton,
  RadioButtonGroup,
  SelectField,
  TextField,
  TimePicker
} from "material-ui";
import moment from "moment";
import React, { Component, Fragment } from "react";
import Core from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import formatMoney from "../../../lib/tools/formatMoney";
import Chips from "../../Forms/Chips";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";
import Slider from "../../Forms/Slider";
import styles from "../../Forms/Styles";
import LocationSection from "./LocationSection";

/* CONSTANT =================================== */

const DASH = "—";

const UPTO_TECHNICAL_SKILL = 5;

/* MAIN CLASS ================================= */

class Match extends Component {
  constructor(args) {
    super(args);
    this.state = {
      availableStages: Definition.get('stage')
    }
  }

  technicalSkillsSubHeading = () => {
    const { techSkillsInNotes, techSkillsInResume } = this.props.parent.state;
    let subHeading = (
      Definition.get("technicalSkills")
        .filter(tech => (
          techSkillsInNotes.includes(tech.id) ||
          techSkillsInResume.includes(tech.id)
        ))
        .map(res => res.label)
        .sort()
    );
    if (!!subHeading.length) {
      return (
        <span>
          Found the following skills in resume or LinkedIn: {subHeading.join(", ")}
        </span>
      );
    }
    return null;
  };

  render() {
    const CandidateEditController = this.props.parent;
    const lineStyle = { borderColor: "#59736f" };
    const availableStages = this.state.availableStages;
    return (
      <List>
        <ListItem
          className="form-header"
          primaryText="MATCH"
          secondaryText={
            <Fragment>
              <div>Make money on autopilot</div>
              <div>
                Tell us about your candidate and get notified when a new job
                match is found.
              </div>
            </Fragment>
          }
          disabled={true}
        />

        {/** WORK VISA */}

        <Row style={{ marginBottom: '15px' }}>
          <Col>
            <label>
              Work visa&nbsp;
              <b style={{ color: 'orange' }}>*</b>
            </label>
            <SelectField
              underlineFocusStyle={lineStyle}
              name="visa"
              value={CandidateEditController.state.visa}
              onChange={(ev, i, visa) =>
                CandidateEditController.setStateStore({ visa, errorVisa: "", key: 'visa' })
              }
              fullWidth
              errorText={CandidateEditController.state.errorVisa}
            >
              {Definition.get("visaCandidate").map(item => {
                return (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    primaryText={item.label}
                  />
                );
              })}
            </SelectField>
          </Col>
          <Col>
            <label>Desired stage</label>
            <Select
              multiple
              value={CandidateEditController.state.desiredStage}
              onChange={(e) => {
                CandidateEditController.setStateStore({
                  desiredStage: e.target.value,
                  key: 'desiredStage'
                })
              }}
              input={<Input id="select-multiple-checkbox" />}
              renderValue={selected => (
                Definition
                  .getLabels('stage', selected)
                  .join(', ')
              )}
              fullWidth
              style={{ marginTop: '10px' }}
            >
              {availableStages.map(stage => (
                <MenuItem key={stage.id} value={stage.id}>
                  <Checkbox
                    checked={(
                      CandidateEditController.state.desiredStage
                        .indexOf(stage.id) > -1
                    )}
                    style={{ float: 'left' }}
                    label={stage.label}
                  />
                </MenuItem>
              ))}
            </Select>
          </Col>
        </Row>

        {/* epic-3338(new locations)-story-3385 | 2021-07-15 Thu µ */}
        {/**
         * @todo
         * 
         * Future cleanup
         * 2021-07-15 Thu µ
         * 
         */}
        <div className="p-1 m-2 rounded" style={{ border: "2px dotted #ccc", filter: 'grayscale(1)' }}>
          <Row><Col><label>DEPRECATED</label></Col></Row>

          {/** WORK REGION */}
          <Row>
            <Col fullWidth>
              <label>Desirable work region</label>
              <div className="checkbox-group">
                {Definition.get("locationCandidate").map(item => {
                  let subtitle = '';
                  if (item.label === 'Other') {
                    subtitle = '(anywhere in the US)';
                  }
                  if (item.label === 'Remotely') {
                    subtitle = '(100%)';
                  }
                  return <Checkbox
                    key={item.id}
                    label={item.label + ' ' + subtitle}
                    checked={!!~CandidateEditController.state.workLocationIds.indexOf(item.id)}
                    onCheck={(ev, checked) => {
                      // cloned ids to handle them
                      const workLocationIds = [...CandidateEditController.state.workLocationIds];
                      // uncheck "All Bay Area" 
                      workLocationIds.find(
                        (id, i) => id === 1 && workLocationIds.splice(i, 1)
                      );
                      if (checked) {
                        if (item.id === 0) {
                          workLocationIds.splice(0);
                          workLocationIds.push(0);
                        } else if (item.id === 1) {
                          // if checked & it is "All Bay Area", push 1-6 to the list 
                          workLocationIds.splice(0);
                          [1, 2, 3, 4, 5, 6].map(id => workLocationIds.push(id));
                        } else if (!~workLocationIds.indexOf(item.id)) {
                          //** if checked & item is not in the list push it
                          workLocationIds.push(item.id);
                        }
                        if (item.id !== 0) {
                          workLocationIds.find(
                            (id, i) => id === 0 && workLocationIds.splice(i, 1)
                          );
                        }
                      } else {
                        //** if unchecked, remove from list 
                        workLocationIds.find(
                          (id, i) =>
                            id === item.id && workLocationIds.splice(i, 1)
                        );
                      }
                      Core.log({ workLocationIds });
                      //** set the new list in the state *
                      CandidateEditController.setStateStore({
                        workLocationIds,
                        key: 'workLocationIds'
                      });
                    }}
                    disabled={true}
                  />
                })}
              </div>
            </Col>
          </Row>
          {/** */}

          {/** LOCATION DETAILS */}
          <Row>
            <Col fullWidth>
              <label>Location details</label>
              <TextField
                name="locationDetails"
                value={CandidateEditController.state.locationDetails}
                onChange={(event, locationDetails) =>
                  CandidateEditController.setStateStore({
                    locationDetails,
                    key: 'locationDetails'
                  })
                }
                underlineFocusStyle={lineStyle}
                multiLine={true}
                rowsMax={5}
                fullWidth
                disabled
              />
            </Col>
          </Row>
          {/** */}

          {/** WORK PREFERENCES */}
          <Row>
            <Col fullWidth>
              <label>Work from home preferences</label>
              <RadioButtonGroup
                name="workRemotly"
                className="radio-button-group"
                valueSelected={CandidateEditController.state.workRemotly}
                onChange={(event, workRemotly) => {
                  CandidateEditController.setStateStore({
                    workRemotly,
                    key: 'workRemotly'
                  });
                }}
              >
                {Definition.get("remotelyWork").map(item => (
                  <RadioButton key={item.id} value={item.id} label={item.label} disabled={true} />
                ))}
              </RadioButtonGroup>
            </Col>
          </Row>
          {/** */}

        </div>
        {/** */}


        {/** LOCATION */}
        <LocationSection
          CandidateEditController={CandidateEditController}
          BasicsController={this}
        />

        {/** HOW SOON */}
        <Row>
          <Col fullWidth>
            <label>How soon can they start after accepting an offer?</label>
            <RadioButtonGroup
              name="acceptOffer"
              className="radio-button-group"
              valueSelected={CandidateEditController.state.acceptOffer}
              onChange={(event, acceptOffer) =>
                CandidateEditController.setStateStore({
                  acceptOffer,
                  key: 'acceptOffer'
                })
              }
            >
              {Definition.get("offerAcceptance")
                .filter(item => item.visible !== false)
                .map(item => (
                  <RadioButton
                    key={item.id}
                    value={item.id}
                    label={item.label}
                    onClick={ev => {
                      if (CandidateEditController.state.acceptOffer === item.id) {
                        CandidateEditController.setStateStore({
                          acceptOffer: 0,
                          key: 'acceptOffer'
                        });
                      }
                    }}
                  />
                ))}
            </RadioButtonGroup>
          </Col>
        </Row>

        {/** SALARY >>> */}

        <div className="my-2 px-2">
          <MuiDivider />
        </div>

        <Row style={{ overflow: 'visible' }}>
          <Col>
            <label>Minimum company size preference</label>
            <Slider
              name="minCompanySize"
              min={0}
              max={10000}
              step={100}
              value={CandidateEditController.state.minCompanySize}
              onChange={(event, minCompanySize) =>
                CandidateEditController.setStateStore({
                  minCompanySize,
                  key: 'minCompanySize'
                })
              }
            />
          </Col>
          <Col>
            <label>Maximum company size preference</label>
            <Slider
              name="maxCompanySize"
              min={0}
              max={10000}
              step={100}
              value={CandidateEditController.state.maxCompanySize}
              onChange={(event, maxCompanySize) =>
                CandidateEditController.setStateStore({
                  maxCompanySize,
                  key: 'maxCompanySize'
                })
              }
            />
          </Col>
        </Row>
        <Row style={{ overflow: 'visible' }}>
          <Col>
            <label>
              Minimum salary:&nbsp; $
              {formatMoney(CandidateEditController.state.minimumSalary, 0)}
              &nbsp;<b style={{ color: 'orange' }}>*</b>
            </label>
            <Slider
              name="minimumSalary"
              min={0}
              max={250000}
              step={10000}
              value={CandidateEditController.state.minimumSalary}
              onChange={(event, minimumSalary) => {
                CandidateEditController.setStateStore({ minimumSalary, key: 'minimumSalary' });
              }}
            />
          </Col>
          <Col>
            <label>
              Ideal salary:&nbsp; ${formatMoney(CandidateEditController.state.desiredSalary, 0)}
            </label>
            <Slider
              name="desiredSalary"
              min={0}
              max={250000}
              step={10000}
              value={CandidateEditController.state.desiredSalary}
              onChange={(event, desiredSalary) => {
                CandidateEditController.setStateStore({ desiredSalary, key: 'desiredSalary' });
              }}
            />
          </Col>
        </Row>

        {/** FLAG SALARY SHAREABLE */}
        <Row className="mt-2">
          <Col>
            <div className="d-flex align-center">
              <MuiCheckbox
                name="salarySharableFlag"
                checked={CandidateEditController.state.salarySharableFlag}
                onChange={event => {
                  CandidateEditController.setStateStore({
                    salarySharableFlag: event.target.checked,
                    key: "salarySharableFlag"
                  });
                }}
                color="primary"
              />
              <div className="w-100 d-flex flex-column">
                <label className="legend align-left">
                  OK to share with employer?
                </label>
                <small className="align-left c-gray">
                  If comp. expectation unknown, leave it unchecked
                </small>
              </div>
            </div>
          </Col>
          <Col>
            <div className="d-flex flex-column">
              <label className="legend">
                Additional Compensation Notes
              </label>
              <small className="c-gray">
                How flexible or firm on base? base vs equity preference?
              </small>
            </div>
            <TextField
              name="salaryNote"
              value={CandidateEditController.state.salaryNote}
              onChange={(event, salaryNote) =>
                CandidateEditController.setStateStore({
                  salaryNote,
                  key: 'salaryNote'
                })
              }
              underlineFocusStyle={lineStyle}
              multiLine
              rowsMax={5}
              fullWidth
            />
          </Col>
        </Row>

        {/** LEVEL */}
        <Row>
          <Col>
            <label>Level</label>
            <SelectField
              name="level"
              underlineFocusStyle={lineStyle}
              value={CandidateEditController.state.level}
              onChange={(ev, i, level) => CandidateEditController.setStateStore({ level, key: 'level' })}
              fullWidth
            >
              {Definition.get("level").map(state => {
                return (
                  <MenuItem
                    key={state.id}
                    value={state.id}
                    primaryText={state.label}
                  />
                );
              })}
            </SelectField>
          </Col>
        </Row>

        {/** HOW DID YOU FIND */}
        <Row>
          <Col fullWidth>
            <label>How did you find the candidate?</label>
            <RadioButtonGroup
              name="relationship"
              className="radio-button-group"
              valueSelected={CandidateEditController.state.relationship}
              onChange={(event, relationship) => {
                CandidateEditController.setStateStore({ relationship, key: 'relationship' });
              }}
            >
              {Definition.get("relationShip").map(item => (
                <RadioButton key={item.id} value={item.id} label={item.label} />
              ))}
            </RadioButtonGroup>
          </Col>
        </Row>

        {/** TECHNICAL SKILLS */}
        <Row>
          <Col fullWidth>
            <Chips
              name="technicalSkills"
              label={(
                <>
                  <label>
                    Technical skills&nbsp;
                    <b style={{ color: 'orange' }}>*</b>
                  </label>
                  <span className="c-gray">
                    &nbsp;-&nbsp;
                    Select up to 5 top strengths that you would like the interviewers to focus on
                  </span>
                </>
              )}
              sub={this.technicalSkillsSubHeading()}
              values={CandidateEditController.state.technicalSkills}
              onChange={technicalSkills => {
                technicalSkills = (
                  Core.isAdmin()
                    ? technicalSkills
                    : technicalSkills.slice(0, UPTO_TECHNICAL_SKILL)
                );
                CandidateEditController.setStateStore({ technicalSkills, key: 'technicalSkills' })
              }}
              items={(
                Definition.get("technicalSkills")
                  .filter(tech => (
                    !CandidateEditController.state.techSkillsInNotes.includes(tech.id) &&
                    !CandidateEditController.state.techSkillsInResume.includes(tech.id)
                  ))
              )}
              additionalItems={(
                Definition.get("technicalSkills")
                  .filter(tech => (
                    CandidateEditController.state.techSkillsInNotes.includes(tech.id) ||
                    CandidateEditController.state.techSkillsInResume.includes(tech.id)
                  ))
              )}
              selectedTitle={(
                <strong style={{ fontSize: '14px' }}>
                  Most expert areas (select no more than {UPTO_TECHNICAL_SKILL})
                </strong>
              )}
              groupingTitle={(
                <strong style={{ fontSize: '14px' }}>
                  Skills found in resume and linkedIn
                </strong>
              )}
              allSkillsTitle={(
                <strong style={{ fontSize: '14px' }}>Additional Skills</strong>
              )}
            />
          </Col>
        </Row>

        {/** ADVANCED MATCH */}
        <Row className="mt-2">
          <Col>
            <FlatButton
              label={`${CandidateEditController.state.showAdvancedMatch ? DASH : "+"
                } Advanced Match`}
              style={{ ...styles.List.button, margin: 0 }}
              onClick={ev =>
                CandidateEditController.setStateStore({
                  showAdvancedMatch: !CandidateEditController.state.showAdvancedMatch,
                })
              }
            />
          </Col>
        </Row>
        {CandidateEditController.state.showAdvancedMatch && (
          <Fragment>
            <Divider className="normal-margin" />
            <Row>
              <Col fullWidth className="candidate-prefer">
                <label>Candidate Prefer</label>
                <div className="checkbox-group">
                  {Definition.get("contactPreference").map(item => (
                    <Checkbox
                      key={item.id}
                      label={item.label}
                      checked={
                        !!CandidateEditController.state.candidatePreferIds.find(
                          id => id === item.id
                        )
                      }
                      onCheck={(ev, checked) => {
                        const candidatePreferIds = [
                          ...CandidateEditController.state.candidatePreferIds
                        ];
                        if (checked) {
                          if (
                            !candidatePreferIds.find((id, i) => id === item.id)
                          ) {
                            candidatePreferIds.push(item.id);
                          }
                        } else {
                          candidatePreferIds.find(
                            (id, i) =>
                              id === item.id && candidatePreferIds.splice(i, 1)
                          );
                        }
                        CandidateEditController.setStateStore({ candidatePreferIds, key: 'candidatePreferIds' });
                      }}
                    />
                  ))}
                </div>
              </Col>
            </Row>
            <Row>
              <Col fullWidth>
                <label>
                  Can the candidate explain skills/experience clearly?
                </label>
                <RadioButtonGroup
                  name="recruiterRating"
                  className="radio-button-group"
                  valueSelected={CandidateEditController.state.recruiterRating}
                  onChange={(event, recruiterRating) => {
                    CandidateEditController.setStateStore({ recruiterRating, key: 'recruiterRating' });
                  }}
                >
                  {Definition.get("recruiterRating").map(item => (
                    <RadioButton
                      key={item.id}
                      value={item.id}
                      label={item.label}
                    />
                  ))}
                </RadioButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col fullWidth>
                <Chips
                  name="industry"
                  label="Preferred Industry"
                  values={CandidateEditController.state.industry}
                  onChange={industry => CandidateEditController.setStateStore({ industry, key: 'industry' })}
                />
                <Chips
                  name="technologyDomain"
                  label="Domain Focus"
                  values={CandidateEditController.state.technologyDomain}
                  onChange={technologyDomain =>
                    CandidateEditController.setStateStore({ technologyDomain, key: 'technologyDomain' })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col fullWidth>
                <label>Best time to call {CandidateEditController.state.bestTimeForCalls}</label>
                <div className="inline-blocks">
                  <TimePicker
                    hintText="From"
                    value={CandidateEditController.state._bestTimeFrom}
                    onChange={(ev, _bestTimeFrom) =>
                      CandidateEditController.setStateStore(
                        state => {
                          state._bestTimeFrom = _bestTimeFrom;
                          state.key = '_bestTimeFrom';
                          state.bestTimeForCalls = `${moment(
                            state._bestTimeFrom || new Date()
                          ).format("hh:mma")} - ${moment(
                            state._bestTimeTo || new Date()
                          ).format("hh:mma")}`;
                          return state;
                        },
                        then => console.log(CandidateEditController.state)
                      )
                    }
                  />
                  &nbsp;
                  <TimePicker
                    hintText="To"
                    value={CandidateEditController.state._bestTimeTo}
                    onChange={(ev, _bestTimeTo) =>
                      CandidateEditController.setStateStore(
                        state => {
                          state._bestTimeTo = _bestTimeTo;
                          state.key = '_bestTimeTo';
                          state.bestTimeForCalls = `${moment(
                            state._bestTimeFrom || new Date()
                          ).format("hh:mma")} - ${moment(
                            state._bestTimeTo || new Date()
                          ).format("hh:mma")}`;
                          return state;
                        },
                        then => console.log(CandidateEditController.state)
                      )
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Why leave current / last job?</label>
                <TextField
                  name="reasonsToLeaveLastJob"
                  value={CandidateEditController.state.reasonsToLeaveLastJob}
                  onChange={(ev, reasonsToLeaveLastJob) =>
                    CandidateEditController.setStateStore({ reasonsToLeaveLastJob, key: 'reasonsToLeaveLastJob' })
                  }
                  underlineFocusStyle={lineStyle}
                  fullWidth
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Explain any potential red flags</label>
                <TextField
                  name="explainRedFlags"
                  value={CandidateEditController.state.explainRedFlags}
                  onChange={(ev, explainRedFlags) =>
                    CandidateEditController.setStateStore({ explainRedFlags, key: 'explainRedFlags' })
                  }
                  underlineFocusStyle={lineStyle}
                  fullWidth
                />
              </Col>
            </Row>
          </Fragment>
        )}
      </List>
    );
  }
}

/* EXPORTS ==================================== */

export default Match;

/* ============================================ */
