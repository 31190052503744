import React from "react";
import {
  LOCATION__AMERICAS,
  LOCATION__CALIFORNIA,
  LOCATION__SAN_FRANCISCO_BAY_AREA,
  LOCATION__UNITED_STATES,
  LOC_TYPE__ALIAS_ID,
  LOC_TYPE__CITY_ID,
  LOC_TYPE__COUNTRY_ID,
  LOC_TYPE__METRO_AREA_ID,
  LOC_TYPE__METRO_AREA_SEC_ID,
  LOC_TYPE__MULTI_CONT_ID,
  LOC_TYPE__STATE_ID
} from '../../lib/Definition';
import TreeSelectDropdown from '../Forms/TreeSelect';
import { REACT_TABLE__COLUMN_XXXL } from "./useEnhancedReactTable.hook";
import { useFacets } from "./useFacets.hook";

export function FilterLocationsColumn(props) {
  let {
    column,
    column: {
      id: columnId,
      _renderSubFilter = props => null
    },
    context: { config: { facetsPath }, },
    filters = {},
    setFilter = () => { },
    latestQuery = {},
  } = props;
  const {
    suggestions,
    fetchSuggestions,
  } = useFacets(facetsPath);
  // console.debug(Definition.get('location'));
  let _props = {
    data: suggestions, // Definition.get('location'),
    maxTagCount: 'responsive',
    layers: [
      LOC_TYPE__MULTI_CONT_ID,
      LOC_TYPE__COUNTRY_ID,
      LOC_TYPE__STATE_ID,
      LOC_TYPE__METRO_AREA_ID,
      LOC_TYPE__METRO_AREA_SEC_ID,
      LOC_TYPE__CITY_ID,
    ],
    treeDefaultExpandedIds: [
      LOCATION__AMERICAS,
      LOCATION__UNITED_STATES,
      LOCATION__CALIFORNIA,
      LOCATION__SAN_FRANCISCO_BAY_AREA,
    ],
    value: filters[columnId],
    onChange: ({ value }) => {
      setFilter({
        columnId,
        value: value.length ? value : undefined
      });
    },
    onSearch: value =>
      fetchSuggestions({
        column,
        categoryKey: 'location',
        filters: latestQuery.filters,
        value
      })
    ,
    className: 'm-0 flex-align-left-center',
    dropdownMatchSelectWidth: REACT_TABLE__COLUMN_XXXL,
  };
  if (columnId === 'candidateLocations') {
    _props.layers.push(LOC_TYPE__ALIAS_ID);
  }
  return (
    <div className="d-flex flex-wrap flex-align-center w-100">
      <TreeSelectDropdown {..._props} />
      {_renderSubFilter(props)}
    </div>
  );
}
