import { MenuItem, SelectField, TextField } from "material-ui";
import React, { Component } from "react";
import Core from "../../lib/Core";
import Col from "../Forms/Col";
import MultipleSelect from "../Forms/MultipleSelect";
import RichTextBox from "../Forms/RichTextBox";
import Row from "../Forms/Row";

/**
 * @EmailObject {{
 *    accountType:{string},
 *    name:{string},
 *    email:{string}
 * }}
 * @property  {EmailObject[]} emails
 * @property  {EmailObject[]} to
 * @property  {EmailObject[]} cc
 * @property  {EmailObject[]} bcc
 * @property  {string} subject
 * @property  {string} body
 */
class EmailPreview extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      emails: (this.props.emails || []).map(
        item => (item.name ? `${item.name} <${item.email}>` : item.email)
      ),
      toInput: "",
      ccInput: "",
      selectedTo: (this.props.to && this.props.to[0]) || "",
      selectedCc: (this.props.cc && this.props.cc[0]) || "",
      to: (this.props.to || []).map(
        item => (item.name ? `${item.name} <${item.email}>` : item.email)
      ),
      cc: (this.props.cc || []).map(
        item => (item.name ? `${item.name} <${item.email}>` : item.email)
      ),
      bcc: (this.props.bcc || []).map(
        item => (item.name ? `${item.name} <${item.email}>` : item.email)
      ),
      subject: this.props.subject,
      body: this.props.body,
      snippets: this.props.snippets || [],
      selectedSnippet: ""
    };
    Core.log({ EPState: this.state });
  }
  setTo = (ev, index, to) =>
    this.setState(state => {
      // state.selectedTo = to;
      state.to.push(to);
      return state;
    });
  setCc = (ev, index, cc) =>
    this.setState(state => {
      // state.selectedCc = cc;
      state.cc.push(cc);
      return state;
    });
  setSnippet = (ev, index, snippetKey) => {
    const snippet = this.state.snippets.find(
      snippet => snippet.key === snippetKey
    );
    this.setState(
      state => {
        state.selectedSnippet = snippetKey;
        state.subject = snippet.subject;
        state.body = snippet.snippetText;
        return state;
      }
    );
  };
  onChangeSubject = ev => this.setState({ subject: ev.target.value });
  onChangeBody = body => this.setState({ body });
  getParams = ev => ({
    from: this.props.from || Core.getResumeSubmissionFromEmail(),
    to: this.state.to.join(","),
    cc: this.state.cc.join(","),
    bcc: this.state.bcc.join(","),
    subject: this.state.subject,
    html: this.state.body
  });
  render() {
    const styles = {
      underlineStyle: {
        borderColor: "#715EFF"
      }
    };
    const {
      emails = []
    } = this.props;
    const emailsOptions = emails.map(
      (
        {
          accountType = '',
          name = '',
          email = '',
        }
      ) => {
        const AccountType = (
          accountType
            ? (
              <strong>
                {`${accountType.trim()}:`}
              </strong>
            )
            : ''
        );
        const Name = (
          name
            ? ` ${name}`
            : ''
        );
        const Email = (
          email
            ? ` <${email.trim()}>`
            : ''
        );
        return (
          {
            label: (
              <small>
                {AccountType}
                {Name}
                {Email}
              </small>
            ),
            id: `${Name}${Email}`.trim()
          }
        )
      }

    );
    return (
      <div className="ui-email-preview" style={{ overflow: "auto" }}>
        {!!this.state.snippets.length && (
          <Row>
            <Col style={{ padding: 0 }} fullWidth>
              <label>Snippets</label>
              <SelectField
                name="to"
                value={this.state.selectedSnippet}
                onChange={this.setSnippet}
                underlineFocusStyle={styles.underlineStyle}
                fullWidth
              >
                {this.state.snippets.map(snippet => (
                  <MenuItem
                    value={snippet.key}
                    key={Core.getKey()}
                    primaryText={snippet.snippetName}
                  />
                ))}
              </SelectField>
            </Col>
          </Row>
        )}
        <Row>
          <Col style={{ width: "32px", padding: 0 }}>
            <label>To</label>
          </Col>
        </Row>
        <MultipleSelect
          placeholder={('type an email')}
          multiple="tags"
          data={emailsOptions}
          value={this.state.to}
          onChange={to => {
            this.setState({ to });
          }}
          optionLabelProp
        />
        <Row>
          <Col style={{ width: "32px", padding: 0 }}>
            <label>Cc</label>
          </Col>
        </Row>
        <MultipleSelect
          placeholder={('type an email')}
          multiple="tags"
          data={emailsOptions}
          value={this.state.cc}
          onChange={cc => this.setState({ cc })}
          optionLabelProp
        />
        <Row>
          <Col style={{ padding: 0 }} fullWidth>
            <label>Subject</label>
            <TextField
              name="subject"
              underlineFocusStyle={styles.underlineStyle}
              hintText="e.g. 10by10"
              value={this.state.subject}
              onChange={this.onChangeSubject}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ padding: 0 }} fullWidth>
            <RichTextBox
              name="body"
              value={this.state.body}
              onChange={this.onChangeBody}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default EmailPreview;
