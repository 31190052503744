import React, {Component, Fragment} from "react";

/* Lib files */
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from "moment";

import Engagement from "../../../../lib/Engagement";
import cleanHTML from "../../../../lib/tools/cleanHtml";
import Definition from "../../../../lib/Definition";

class SubmissionHistory extends Component{

    constructor() {
        super(...arguments);
        this.state = {
            jobEngagements:[]
        }
    }

    fetchEngagements = ()=>{
        Engagement.getWhere({jobId:this.props.job.id,rejectionReason: {nin:['10x10 - No Match','bad_match']}},(res)=>{
            this.setState(
                {
                    jobEngagements: res
                },
            );
        })
    }

    componentDidMount() {
        this.fetchEngagements();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!prevProps.job.id && prevProps.job.id !== this.props.job.id) {
            this.fetchEngagements();
        }
    }

    profiles = ()=>{
        let ret = {}
        let engagements = this.state.jobEngagements;

        if(!Array.isArray(engagements)){
            return;
        }

        ret.no = ()=>engagements.filter(el=> /review/i.test(el.stage) && /e -/i.test(el.status));

        ret.yes = ()=>{
            let filtered = engagements.filter(el=> !/confirmation|submission|review/i.test(el.stage));
            let hash = {
                Screen: 1,
                Onsite: 2,
                Offer: 3,
                Guarantee: 4,
                Hire: 5,
                End: 6
            };

            filtered.sort((a,b)=> {
                return ((new Date(b.submitted)).getTime()*hash[b.stage]) - ((new Date(a.submitted)).getTime()*hash[a.stage]);
            });

            return filtered;
        }

        ret.mayBe = ()=>engagements.filter(el=>/review/i.test(el.stage) && /w - employer feedback/i.test(el.status));

        ret.summary = ()=>{
            let onsite = engagements.filter(el=>/onsite|offer|guarantee|hire|end/i.test(el.stage));
            let screens = engagements.filter(el=> /screen/i.test(el.stage));
            let reviewed = [...ret.yes(),...ret.no()];
            let submitted = [...reviewed,...ret.mayBe()];
            let screenPercent, onSitesPercent = "0%";

            if(reviewed.length > 0){
                screenPercent = `${parseInt(((screens.length+onsite.length)*100)/reviewed.length)}%`;
                onSitesPercent = `${parseInt((onsite.length*100)/reviewed.length)}%`;
            }

            return <Fragment>
                <p>{submitted.length} submitted
                    | {reviewed.length} reviewed
                    | {screens.length} screens & after ({screenPercent})
                    | {onsite.length} onSites & after ({onSitesPercent})
                </p>
            </Fragment>
        }

        const printSingle = (eng)=>{
            let update = eng;
            let candidate = eng.candidate;
            let isRejected = eng.state==='Closed';
            let rejectedLabel = isRejected ? 'REJECTED' : '';
            let isAliveLabel = isRejected ? '' : '*';
            let rejectionReasonDetails = [eng.rejectionReason,eng.rejectionReasonAdditionalInfo].filter(el=>!!el).join(' - ');
            return cleanHTML(
                '<p>'+isAliveLabel+ String(eng.stage) +' | '+ String(eng.status)+ ' | '+
                [
                    [
                        moment(update.submitted).format("MM/DD/YY"),
                        `<a href="/#/candidate/edit/${
                            candidate.id
                            }" target="_blank">${candidate._name}</a>`,
                        Definition.getLabel(
                            "platformRating",
                            candidate.platformRating
                        )
                    ]
                        .filter(e => !!e && !!String(e).trim().length)
                        .join(", "),
                    Definition.getLabels(
                        "positiveSignals",
                        candidate.positiveSignals
                    ).join(", "),
                    Definition.getLabels(
                        "negativeSignals",
                        candidate.negativeSignals
                    ).join(", "),
                    candidate.tagLine
                ]
                    .filter(e => !!e && !!String(e).trim().length)
                    .join("; ") +
                "<strong> - "+rejectedLabel+"</strong>.</p>"
                + '<p style="font-size:11px;">'+rejectionReasonDetails+'</p>'
            );
        };

        ret.print = (array)=>{
            return array.map(el=>printSingle(el)).join('')
        }

        return ret;
    }

    render(){

        return  <Fragment>
                    <Card className="match-new section-card">
                        <div>
                            <CardContent className="match-new card-content">
                                <h2 className="match-new section-header">SUBMISSION HISTORY</h2>
                                <hr />
                                {/* <Grid container spacing={8} style={{padding:'15px'}}>
                                    <Grid item xs={12}> */}
                                        <p><strong>Summary</strong></p>
                                        {this.profiles().summary()}
                                        <p><strong>YES - Client accepted profile ({this.profiles().yes().length})</strong></p>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: this.profiles().print(this.profiles().yes())
                                            }}
                                        />

                                        <p><strong>MAYBE - Profiles under Client Review ({this.profiles().mayBe().length})</strong></p>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: this.profiles().print(this.profiles().mayBe())
                                            }}
                                        />

                                    {/* </Grid>
                                    <Grid item xs={12}> */}
                                        <p><strong>NO - Client rejected profile ({this.profiles().no().length})</strong></p>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: this.profiles().print(this.profiles().no())
                                            }}
                                        />

                                    {/* </Grid>
                                </Grid> */}
                            </CardContent>
                        </div>
                    </Card>
                </Fragment>
    }
}

export default SubmissionHistory;