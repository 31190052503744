import Account from '../../lib/Account';
import Core from "../../lib/Core";
import { STATE_ACTIVE } from '../../lib/Definition';
import { mapAccounts } from '../../lib/models/account';
import AccountDetails from '../Accounts/Card/AccountDetails';
import { FilterDateRangeColumn } from './FilterDateRangeColumn';
import { moreMenuOptionsAccounts } from './moreMenuOptionsAccounts';
import {
  CellActionsColumn,
  CellTagColumn,
  FilterActionsColumn, FilterTagColumn
} from "./ReactTable";
import { REACT_TABLE__COLUMN_MD, REACT_TABLE__COLUMN_XXL } from './useEnhancedReactTable.hook';

export const configAccounts = {
  Entity: Account,
  entityMapper: mapAccounts,
  searchPath: 'Accounts/_search',
  autocompletePath: 'Accounts/_autocomplete',
  facetsPath: 'Accounts/_facets',
  starredAccessor: 'accountStarreds',
  searchPlaceholder: 'Search by company or recruiter name',
  addNew: {
    label: '+ New Account',
    to: '/account/create'
  },
  defaultQuery: {
    "filters": [
      {
        "state": STATE_ACTIVE,
      }
    ],
    // "sort": { "firstName": 1, "lastName": 1 },
    "skip": 0,
    "limit": 10,
    "query": [],
    "associations": [
      'accountStarreds',
      'candidates'
    ]
  },
  hiddenColumns: [
    'state',
  ],
  columns: [
    {
      accessor: 'firstName',
      Header: 'Fullname',
      Cell: CellTagColumn,
      disableFilters: false,
      width: 160,
      _accessor: '_infoCmp',
    },
    /** * /
    {
      accessor: 'firstName',
      Header: 'First Name',
      disableFilters: false,
      width: 160,
    },
    {
      accessor: 'lastName',
      Header: 'Last Name',
      disableFilters: false,
      width: 160,
    },
    /** */
    {
      accessor: 'companyName',
      Header: 'Company Name',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'phone',
      Header: 'Phone',
      disableFilters: false,
      width: 160,
    },
    {
      accessor: 'email',
      Header: 'Email',
      Cell: CellTagColumn,
      _accessor: '_emailCmp',
      disableFilters: false,
      width: 360,
    },
    {
      accessor: 'updatedAt',
      Header: 'Recent',
      Cell: CellTagColumn,
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      _accessor: '_updatedAt',
    },
    {
      accessor: 'state',
      Header: 'State',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      width: 120,
      _acl: [Core.isAdminOrCoordinator()],
      _accessor: '_state',
      _categoryKey: 'state',
    },
    {
      id: 'actions',
      Header: 'Actions',
      Filter: FilterActionsColumn,
      Cell: CellActionsColumn,
      defaultCanFilter: true,
      disableFilters: false,
      disableSortBy: false,
      width: 170,
      _starredAccessor: 'accountStarreds.starred',
      _associationAccessor: 'engagements.job.employer',
      _headerClassName: 'sticky-end bg-main bl-1',
      _cellClassName: 'sticky-end bg-white bl-1 d-flex flex-align-right-center',
    }
  ],
  moreMenuOptions: moreMenuOptionsAccounts,
  ExpandedView: AccountDetails,
};
