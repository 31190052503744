import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Announcements from '../components/Announcements/Announcements';
import AnnouncementCreate from '../components/Announcements/AnnouncementsCreate';
import CandidateEdit from '../components/Candidates/Edit/CandidateEdit';
import CandidateResumeSubmission from '../components/Candidates/ResumeSubmission/CandidateResumeSubmission';
import EmployerEdit from '../components/Employers/Edit/EmployerEdit';
import HomeV1 from '../components/HomeV1/Main';
import ManageJobs from '../components/Jobs/Manage/ManageJobs';
import Login from '../components/Login/Login';
import TagManagement from '../components/Management/Tags';
import Settings from '../components/Settings/Settings';
import { NoMatch } from './NoMatch.routes';

export const CoordinatorUser = (em) => (
  <Switch>

    <Route exact path="/" component={Login} />
    <Route path="/login" component={Login} />
    <Route path="/forgot/password" component={Login} />
    <Route path="/reset/password/:token" component={Login} />
    <Route path="/home" component={HomeV1} />
    <Route path="/candidates" component={HomeV1} />
    <Route path="/candidate/create" component={CandidateEdit} />
    <Route path="/candidate/edit-section/:id" component={CandidateEdit} />
    <Route path="/candidate/edit/:id" component={CandidateEdit} />
    <Route path="/candidate/view/:id" component={CandidateEdit} />
    <Route path="/jobs" component={HomeV1} />
    <Route path="/job/view/:id" component={HomeV1} />
    <Route path="/settings" component={Settings} />
    <Route path="/announcements" component={Announcements} />
    <Route path="/accounts" component={HomeV1} />
    <Route
      path="/candidate/resume-submission/:id/:jobId"
      component={CandidateResumeSubmission}
    />
    <Route path="/employers" component={HomeV1} />
    <Route path="/employer/view/:id" component={EmployerEdit} />
    <Route path="/admin/tools" component={HomeV1} />
    <Route path="/management/tags" component={TagManagement} />
    <Route path="/create/announcements" component={AnnouncementCreate} />
    <Route path="/calendar" component={HomeV1} />
    <Route path="/reports" component={HomeV1} />
    <Route path="/job/management" component={ManageJobs} />
    <Route path="/engagements" component={HomeV1} />
    <Route path="/engagement/view/:id" component={HomeV1} />
    <Route path="/engagement/represent/:id" component={HomeV1} />

    <Route component={NoMatch} />

  </Switch>
);
