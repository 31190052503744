import { IconButton } from "@mui/material";
import { FlatButton } from "material-ui";
import moment from "moment";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Candidate from "../Candidate";
import {
  FULLDAY_MILL_SECOND_REFERENCE,
  FULLDAY_SECOND_REFERENCE
} from "../Constants";
import Core from "../Core";
import Definition from "../Definition";
import EngagementLib from "../Engagement";
import { newModel } from "../GenericTools.lib";
import Job from "../Job";
import { mapAccount } from "./account";
import { mapCandidate } from "./candidate";
import { mapJob } from "./job";
import { mapStarred } from "./mapStarred.tool";

const MODEL_NAME = 'Engagement';

const model = {
  status: "",
  stage: "",
  state: "",
  boxKey: "",

  /**
   * Greenhouse Application ID (number)
   *
   * Application is similar to engagement
   *
   * Expecting information to obtain with this ID
   * - stage
   * - status
   * - scheduled interview dates
   * - rejection reason
   * - rejection notes
   *
   * @see CandidateResumeSubmission UI Component
   * - render => onClickSendATS
   */
  greenhouseAtsApplicationId: null,

  /** foreing keys */
  jobId: "",
  candidateId: "",
  actionOwnerId: "",

  /** mixin timestamps */
  createdAt: null, // new Date().toISOString(), // "2017-12-18T20:51:55.157Z",
  updatedAt: null, // new Date().toISOString(), // "2017-12-18T20:51:55.157Z",
  lastMatchedDate: null,
  introduced: null, // date
  matched: null, // date
  lastAction: null,

  confirmed: null,
  submitted: null,
  reviewed: null,
  screened: null,
  onsite: null,
  offered: null,
  hired: null,
  startDate: null,
  guaranteeDate: null,
  holdDate: null,

  screen1: null,
  screen2: null,
  screen3: null,

  onsite1: null,
  onsite2: null,

  closed: null,
  rejectionReason: null, // string?
  rejectionReasonAdditionalInfo: null, // string?
  shouldTag: null,
  shouldNotTag: null,
  whyNoPrivateNote: null,
  whyNeedToReadCV: null,
  overdueDate: null,
  followedUpCount: null, // number?
  nextAction: null,

  matchStrength: null, // tagId(engagementMatchStrength)
  matchedByWho: null, // account(user)

  /**
   * {number}
   * "
   * A) Match Type:
   * // new attribute. use integer in DB, but code should be readable name
   * // please include comments in the code
   * // use tag management to make understanding tag faster
   * "
   *
   * @see MatchLib.setMatchFlagForAdditionalActions
   * @see MatchLib.setMatchFlagForBackMatch
   * @see story 2572
   */
  matchType: null,

  /**
   * {number}
   * "
   * B) only applicable if type is 10x10_back_match
   * // allow us to analyze how far should we back match later
   *    backMatchDays : Today's date - Candidate updatedAt (days)
   * "
   *
   * @see MatchLib.setMatchFlagForBackMatch
   * @see story 2572
   */
  backMatchDays: null,

  open: true,
  jobAnswers: [],
  useForMlTraining: null,

  reminderSentDates: {},
};

const extended = {
  ___model___: MODEL_NAME,
  modelName: 'engagement',
  id: null,
  _id: null,
  ...model,
  /** nested objects */
  job: {},
  candidate: {},
  recruiter: {},
  employer: {},
  actionOwner: {},
  history: [],
  ___keys___: []
};

const mdash = "—";

const mapEngagement = item => {
  const engagement = getEngagementModel({ extended: true });
  if (!!item) {
    Object.keys(extended).forEach(
      key => !!item[key] && (engagement[key] = item[key])
    );
    engagement.id = engagement.id || engagement._id;

    engagement._createdAt = engagement.createdAt ? moment(engagement.createdAt).format("MM-DD-YYYY") : mdash;
    engagement._updatedAt = engagement.updatedAt ? moment(engagement.updatedAt).format("MM-DD-YYYY") : mdash;

    mapStarred({ model: engagement, starredList: (item.engagementStarreds || []) });

    engagement.candidate = mapCandidate(item.candidate);
    engagement.job = mapJob(item.job);

    engagement.actionOwner = mapAccount(item.actionOwner);
    engagement._actionOwner = engagement.actionOwner.email || mdash;

    engagement.matchedByWho = mapAccount(item.matchedByWho);
    engagement._matchedByWho = engagement.matchedByWho.email || mdash;

    engagement._backMatchDays = engagement.backMatchDays || mdash;
    engagement._followedUpCount = engagement.followedUpCount || mdash;
    engagement._nextAction = engagement.nextAction || mdash;
    engagement._rejectionReason = engagement.rejectionReason || mdash;

    engagement.recruiter = engagement.candidate.recruiter;
    engagement.employer = engagement.job.employer;
    engagement.history = engagement.engagementHistories || [];
    engagement.lastAction = engagement.lastAction || engagement.updatedAt;

    Definition.set(engagement, 'matchType');
    engagement._matchType = engagement._matchType || mdash;

    Definition.set(engagement, 'engagementMatchStrength', 'matchStrength');
    engagement._matchStrength = engagement._matchStrength || mdash;

    engagement._starred = engagement.starred
      ? "Starred: True"
      : "Starred: False";
    engagement._name = `${engagement.candidate._name_rating ||
      ""} - ${engagement.employer._name_rating || ""}`;
    engagement._lastAction = moment(engagement.lastAction).format("MM-DD-YYYY");
    engagement._role = Definition.getLabel("roles", engagement.job.role) || mdash;

    engagement._roles = engagement.job._roles;

    engagement._agencyName = engagement.recruiter.companyName || mdash;
    engagement._recruiterName = `${engagement.recruiter.firstName} ${engagement.recruiter.lastName
      }`;
    engagement._candidateName = engagement.candidate._name;
    engagement._employerName = engagement.job.employer.name;
    engagement._recruiterTag = engagement.recruiter
      ? `${engagement._agencyName} - ${engagement._recruiterName}`
      : mdash;
    engagement._jobTag = `${engagement.job.jobTitle ||
      ""} - ${engagement._role || ""}`;

    engagement._introduced = moment(engagement.introduced).format("MM-DD-YYYY");
    engagement._matched = moment(engagement.matched).format("MM-DD-YYYY");

    engagement._confirmed = engagement.confirmed
      ? moment(engagement.confirmed).format("MM-DD-YYYY")
      : mdash;
    engagement._submitted = engagement.submitted
      ? moment(engagement.submitted).format("MM-DD-YYYY")
      : mdash;
    engagement._reviewed = engagement.reviewed
      ? moment(engagement.reviewed).format("MM-DD-YYYY")
      : mdash;

    engagement._screened = engagement.screened ? moment(engagement.screened).format("MM-DD-YYYY") : mdash;
    engagement._onsite = engagement.onsite ? moment(engagement.onsite).format("MM-DD-YYYY") : mdash;
    engagement._hired = engagement.hired ? moment(engagement.hired).format("MM-DD-YYYY") : mdash;
    engagement._startDate = engagement.startDate ? moment(engagement.startDate).format("MM-DD-YYYY") : mdash;
    engagement._guaranteeDate = engagement.guaranteeDate ? moment(engagement.guaranteeDate).format("MM-DD-YYYY") : mdash;
    engagement._holdDate = engagement.holdDate ? moment(engagement.holdDate).format("MM-DD-YYYY") : mdash;
    engagement._overdueDate = engagement.overdueDate ? moment(engagement.overdueDate).format("MM-DD-YYYY") : mdash;

    const isFullDay = (dateString) => {
      return (new Date(dateString)).getSeconds() === FULLDAY_SECOND_REFERENCE
        && (new Date(dateString)).getMilliseconds() === FULLDAY_MILL_SECOND_REFERENCE;
    };

    engagement._screen1IsFullday = isFullDay(engagement.screen1);
    engagement._screen2IsFullday = isFullDay(engagement.screen2);
    engagement._screen3IsFullday = isFullDay(engagement.screen3);

    engagement._onsite1IsFullday = isFullDay(engagement.onsite1);
    engagement._onsite2IsFullday = isFullDay(engagement.onsite2);

    const formatDate = (date, isFullday = false) => {
      return isFullday ? moment(date).format("MM/DD/YYYY") : moment(date).format("MM/DD/YYYY hh:mm A")
    };

    engagement._screen1 = engagement.screen1
      ? formatDate(engagement.screen1, engagement._screen1IsFullday)
      : mdash;
    engagement._screen2 = engagement.screen2
      ? formatDate(engagement.screen2)
      : mdash;
    engagement._screen3 = engagement.screen3
      ? formatDate(engagement.screen3)
      : mdash;
    engagement._onsite1 = engagement.onsite1
      ? formatDate(engagement.onsite1, engagement._onsite1IsFullday)
      : mdash;
    engagement._onsite2 = engagement.onsite2
      ? formatDate(engagement.onsite2, engagement._onsite2IsFullday)
      : mdash;

    engagement._offered = engagement.offered
      ? formatDate(engagement.offered)
      : mdash;


    engagement._candidatePlatformRating = engagement.candidate._platformRating;

    engagement._col1Cmp = <Column1 engagement={engagement} />;

    engagement.openDetails = ev => {
      Candidate.get(engagement.candidateId, candidate => {
        Job.get(engagement.jobId, job => {
          Core.log({ candidate, job });
          Core.openDrawer({
            style: {
              width: 1500,
              maxWidth: "calc(100vw - var(--leftSideWidth))",
              minWidth: 320
            },
            content: (
              <>
                {candidate.getEngagementDetails()}
                {job.getEngagementDetails()}
                <IconButton
                  style={{ position: "fixed", top: 0, right: 0 }}
                  onClick={ev => Core.closeDrawer()}
                >
                  <i className="material-icons">arrow_forward_ios</i>
                </IconButton>
              </>
            )
          });
        });
      });
    };

    /* for autocomplete */
    engagement.___keys___ = [
      engagement.employer.name,
      engagement.job.jobTitle,
      engagement.candidate._name,
      engagement.recruiter._name,
      engagement.recruiter.companyName,
      engagement.stage,
      engagement._role,
      engagement._starred,
      engagement.matchStrength,
      engagement.matchedByWho,
      engagement.lastMatchedDate
    ]
      /* combine and split values */
      .join("; ")
      .split("; ")
      /* remove empty values */
      .filter(e => !!e && String(e).trim());
  }
  return engagement;
};

const mapEngagements = data => {
  return (data || []).map(item => {
    const engagement = mapEngagement(item);
    engagement.filters = mapFilters(engagement);
    return engagement;
  });
};

function mapFilters(engagement) {
  const filters = {};
  function candidateTodo() {
    /** VER-312 show all the items waiting, not just overdue, sort by time */
    filters["Candidate Todo"] = false;
    if (/w - candidate/i.test(engagement.status) && engagement.overdueDate) {
      filters["Candidate Todo"] = engagement.overdueDate;
    } else if (
      /w - cando-emp/i.test(engagement.status) &&
      engagement.overdueDate
    ) {
      filters["Candidate Todo"] = engagement.overdueDate;
    } else if (
      /h - candidate/i.test(engagement.status) &&
      engagement.overdueDate
    ) {
      filters["Candidate Todo"] = engagement.overdueDate;
    }
  }
  function recruiterTodo() {
    /** VER-312 show all the items waiting, not just overdue, sort by time */
    filters["Recruiter Todo"] = false;
    if (/w - recruiter/i.test(engagement.status) && engagement.overdueDate) {
      filters["Recruiter Todo"] = engagement.overdueDate;
    } else if (
      /h - recruiter/i.test(engagement.status) &&
      engagement.overdueDate
    ) {
      filters["Recruiter Todo"] = engagement.overdueDate;
    }
  }
  function employerTodo() {
    /** VER-312 show all the items waiting, not just overdue, sort by time */
    filters["Employer Todo"] = false;
    if (/w - employer/i.test(engagement.status) && engagement.overdueDate) {
      filters["Employer Todo"] = engagement.overdueDate;
    } else if (
      /w - cando-emp/i.test(engagement.status) &&
      engagement.overdueDate
    ) {
      filters["Employer Todo"] = engagement.overdueDate;
    } else if (
      /h - employer/i.test(engagement.status) &&
      engagement.overdueDate
    ) {
      filters["Employer Todo"] = engagement.overdueDate;
    }
  }
  function tenByTenTodo() {
    filters["10x10 Todo"] = false;
    // W - 10x10* & Overdue date is <= now
    if (/w - 10x10/i.test(engagement.status) && engagement.overdueDate) {
      filters["10x10 Todo"] = engagement.overdueDate;
    } else if (/h - 10x10/i.test(engagement.status) && engagement.overdueDate) {
      filters["10x10 Todo"] = engagement.overdueDate;
    }
  }
  // W-Screen/Onsite date <= now and we're an admin or coordinator, set status = W-Employer-Feedback
  if (Core.isAdminOrCoordinator()) {
    setEventStatus(engagement);
  }
  candidateTodo();
  recruiterTodo();
  employerTodo();
  tenByTenTodo();
  filters.Candidate = engagement.candidate.lastName;
  filters.Recruiter = engagement.recruiter.lastName;
  filters.Company = engagement.employer.name;
  filters.Stage = engagement.stage;
  filters.Starred = engagement.starred === true ? "Starred" : "Non Starred";
  filters.Closed = engagement.open === false || engagement.stage === "End";
  engagement._overdueDays =
    engagement.overdueDate && moment(engagement.overdueDate).fromNow();
  engagement._isOverdue = moment(engagement.overdueDate) <= moment();
  return filters;
}

function setEventStatus(engagement) {
  // Get event date.
  const date = /w - screen/i.test(engagement.status)
    ? engagement.screen3 || engagement.screen2 || engagement.screen1
    : /w - onsite/i.test(engagement.status) &&
    (engagement.onsite2 || engagement.onsite1);
  if (
    /w - screen|w - onsite/i.test(engagement.status) &&
    date &&
    moment() >= moment(date)
  ) {
    engagement.status = "W - Employer Feedback";
    EngagementLib.update(
      engagement,
      { status: engagement.status },
      response => {
        Core.showMessage(
          <div className="inline-blocks">
            <span>
              {`${engagement._name}, ${engagement.stage} status changed to ${engagement.status
                }`}
              &nbsp;&nbsp;&nbsp;
            </span>
            <FlatButton
              label="Reload"
              className="button-white-bordered"
              style={{ display: "inline-block" }}
              onClick={ev => document.location.reload()}
            />
          </div>
        );
      }
    );
  }
}

function getOverdue(stringDate) {
  if (!stringDate) {
    return "";
  }
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s, overdue",
      past: "%s overdue"
    }
  });
  return moment(stringDate).fromNow();
}

function hoverDisplay(eng) {
  let date = eng.lastMatchedDate;

  if (!!date) {
    date = new Date(eng.lastMatchedDate);
    date = date.toLocaleDateString();
  }

  let collect = [];
  let fullName = null;
  let email = null;

  try {
    let fname = eng.matchedByWho.firstName;
    let lname = eng.matchedByWho.lastName;
    email = `(${eng.matchedByWho.email})`;

    if (!fname && !lname) {
      fullName = `matched by unknown`;
    } else {
      fullName = `matched by ${fname} ${lname}`;
    }
  } catch (e) { }

  const pushToArray = (el, array) => {
    if (!!el) {
      array.push(el);
    }

    return array;
  }

  pushToArray(date, collect);
  pushToArray(fullName, collect);
  pushToArray(email, collect);
  pushToArray(eng.rejectionReason, collect);
  return collect.join(' - ');
}
const listTabs = [
  "Stage",
  "Candidate Todo",
  "Recruiter Todo",
  "10x10 Todo",
  "Employer Todo",
  "Closed",
  "Recruiter",
  "Company",
  "Candidate",
  "Starred"
];
const listTab = "Stage";

const Engagement = {
  extendedModel: extended,
  model,
  extended,
  mapEngagement,
  mapEngagements,
  mapFilters,
  getOverdue,
  listTabs,
  listTab,
  hoverDisplay
};

class Column1 extends Component {
  render() {
    const { engagement } = this.props;
    return (
      <div
        onClick={this.props.onClick}
        className="first-item blocks cursor-default"
        style={{ minWidth: 320, maxWidth: 320 }}
        colSpan={3}
      >
        <NavLink
          title="Candidate - Employer (Employer Stage) | Go to edit Candidate"
          to={"/candidate/edit/" + engagement.candidateId}
        >
          <b>{engagement._name}</b>
        </NavLink>
        {Core.isAdminOrCoordinator() ? (
          <NavLink
            title="Job Title - Role | Go to edit Job"
            to={"/job/edit/" + engagement.jobId}
          >
            {engagement._jobTag}
          </NavLink>
        ) : (
          <span title="Job Title - Role">{engagement._jobTag}</span>
        )}
        <span title="Recruiter Name - Agency Name">
          {engagement._recruiterName} - {engagement._agencyName}
        </span>
        <span
          title="Stage, Status, State, Rejection Reason"
          className="bold"
          style={{ marginTop: "10px" }}
        >
          {engagement.stage}, {engagement.status}
          {`${Core.isAdminOrCoordinator() ? `, ${engagement.state}` : ""}`}
          {engagement.rejectionReason && /closed/i.test(engagement.state)
            ? ", " + engagement.rejectionReason
            : ""}
        </span>
        <span
          title={`Overdue Date: ${engagement.overdueDate
            }, Followed Up, Next Action`}
          className={engagement._isOverdue ? "cred" : ""}
        >
          {[
            !!engagement._overdueDays && "Overdue: " + engagement._overdueDays,
            !!engagement.followedUpCount &&
            "Followed Up: " + engagement.followedUpCount,
            !!engagement.nextAction && "Next: " + engagement.nextAction
          ]
            .filter(i => !!i)
            .join(", ")}
        </span>
        <span
          className="align-left"
          title={`Last Action: ${engagement.lastAction}, actionOwner`}
        >
          {[
            !!engagement.lastAction &&
            "Last: " + moment(engagement.lastAction).format("MM-DD-YYYY"),
            !!engagement.actionOwnerId &&
            "Owner: " + engagement.actionOwner._name
          ]
            .filter(i => !!i)
            .join(", ")}
          <a
            href={`https://mail.google.com/mail/u/1/#box/${engagement.boxKey}`}
            target="_blank"
            rel="noreferrer"
            className="pointer"
            title="Go to Streak Box"
            style={{ float: "right" }}
          >
            Streak&nbsp;❯&nbsp;&nbsp;&nbsp;
          </a>
        </span>
      </div>
    );
  }
}

function getEngagementModel({
  extended: isExtendedRequired,
} = {}) {
  return newModel(
    isExtendedRequired
      ? extended
      : model
  );
}

export {
  Engagement as default,
  model,
  extended,
  getEngagementModel,
  mapEngagement,
  mapEngagements,
  mapFilters as mapEngagementFilters,
  getOverdue,
  listTabs,
  listTab,
  hoverDisplay
};

