/** ============================================ µ
 * @description JobDetails [JSX]
 *              UI Component
 * @createdAt   2021-09-29 Wed
 * @updatedAt   2021-11-03 Wed
 * ============================================ */
/* IMPORTS ==================================== */

import { Divider, RadioButton, RadioButtonGroup } from "material-ui";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import loader from "../../../assets/images/loader.gif";
import Core from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import Engagement from "../../../lib/Engagement";
import { getJobModel } from "../../../lib/models/job";
import Store from "../../../lib/Store";
import cleanHTML from "../../../lib/tools/cleanHtml";
import formatURL from "../../../lib/tools/formatURL";
import getEngagementsByState from "../../../lib/tools/getEngagementsByState";
import RenderEngagementStructure from "../../../lib/tools/renderEngagementStructure";
import CardContent from "../../Details/CardContent";
import ModalUpdateStatus from "../../Engagements/Card/ModalUpdateStatus";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";
import LocationSection from "./LocationSection";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function JobDetails(props) {
  let {
    job,
    model,
    extraInfo = {},
    engagement: engagementFromProps,
    engagements: engagementsFromProps,
    candidateId,
    externalRelationAssociation,
    source,
  } = props;
  job = job || model || getJobModel();
  JobDetails.references = JobDetails.references || [];
  const { t } = useTranslation();
  const modalUpdateStatus = React.useRef();
  const [pickedMatchedStrength, setPickedMatchedStrength] = React.useState();
  const [engagement, setEngagement] = React.useState(engagementFromProps);
  const [engagements, setEngagements] = React.useState(engagementsFromProps);

  React.useEffect(() => {
    if (!engagements) {
      Engagement.getWhere({ jobId: job.id }, undefined, undefined, true).then(setEngagements);
    }
  }, [engagements, job]);

  function myDefaultMatchStrength(props) {
    let unspecified = Engagement.unSpecifiedStrength();
    let unspecifiedId = "";
    if (!!unspecified) {
      unspecifiedId = unspecified.id;
    }
    const fromCache = !engagement
      ? Store.get(
        Engagement.getCacheKeyJobMatch(candidateId, job.id)
      )
      : null;
    return (
      pickedMatchedStrength ||
      fromCache ||
      (engagement
        ? engagement.matchStrength
          ? engagement.matchStrength
          : unspecifiedId
        : unspecifiedId)
    );
  };

  function renderAdditionalNotes() {

    const { mustHaveQuestions } = job;

    let collection = [
      {
        key: "candidateMust",
        limited: true,
        label: "Must Have",
        visible: !!job.candidateMust,
      },
      {
        key: "candidateMustNot",
        limited: true,
        label: "Must NOT Have",
        visible: !!job.candidateMustNot,
      },
      {
        key: "candidatePositives",
        limited: true,
        label: "Strongly Preferred (Positives)",
        visible: !!job.candidatePositives,
      },
      {
        key: "candidateNegatives",
        limited: true,
        label: "Unlikely to Hire (Negatives)",
        visible: !!job.candidateNegatives,
      },
    ];

    const collectionHtml = collection.map((item, index) => {
      return (
        item.visible && (
          <CardContent title={item.label} key={`collectionHtml-${index}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: job[item.key],
              }}
            />
          </CardContent>
        )
      );
    });

    if ((mustHaveQuestions || []).length) {
      collectionHtml.push(
        <CardContent title="IMPORTANT: Must Answer Pre-screen Questions for Submission">
          <ul>
            {(mustHaveQuestions || []).map((q, index) => (
              <li key={`job-${job.id}-must-have-question-${index}`}>{q.question}</li>
            ))}
          </ul>
        </CardContent>
      );
    }

    return collectionHtml;
  };

  function profiles() {
    let _engagements = engagements || [];
    let ret = {};
    ret.no = () =>
      _engagements.filter(
        (el) => /review/i.test(el.stage) && /e -/i.test(el.status)
      );
    ret.yes = () => {
      let filtered = _engagements.filter(
        (el) => !/confirmation|submission|review/i.test(el.stage)
      );
      let hash = {
        Screen: 1,
        Onsite: 2,
        Offer: 3,
        Guarantee: 4,
        Hire: 5,
        End: 6,
      };
      filtered.sort((a, b) => {
        return (
          new Date(b.submitted).getTime() * hash[b.stage] -
          new Date(a.submitted).getTime() * hash[a.stage]
        );
      });
      return filtered;
    };
    ret.mayBe = () =>
      _engagements.filter(
        (el) =>
          /review/i.test(el.stage) && /w - employer feedback/i.test(el.status)
      );
    ret.summary = () => {
      let onsite = _engagements.filter((el) =>
        /onsite|offer|guarantee|hire|end/i.test(el.stage)
      );
      let screens = _engagements.filter((el) => /screen/i.test(el.stage));
      let reviewed = [...ret.yes(), ...ret.no()];
      let submitted = [...reviewed, ...ret.mayBe()];
      return (
        <>
          <p>
            {submitted.length} submitted | {reviewed.length} reviewed |{" "}
            {screens.length} screens & after (
            {parseInt(
              ((screens.length + onsite.length) * 100) / reviewed.length
            )}
            %) | {onsite.length} onSites & after (
            {parseInt((onsite.length * 100) / reviewed.length)}%)
          </p>
        </>
      );
    };
    const printSingle = (eng) => {
      let update = eng;
      let candidate = eng.candidate;
      let isRejected = eng.state === "Closed";
      let rejectedLabel = isRejected ? "REJECTED" : "";
      let isAliveLabel = isRejected ? "" : "*";
      let rejectionReasonDetails = [
        eng.rejectionReason,
        eng.rejectionReasonAdditionalInfo,
      ]
        .filter((el) => !!el)
        .join(" - ");
      return cleanHTML(
        "<p>" +
        isAliveLabel +
        String(eng.stage) +
        " | " +
        String(eng.status) +
        " | " +
        [
          [
            moment(update.submitted).format("MM/DD/YY"),
            `<a href="/#/candidate/edit/${candidate.id}" target="_blank">${candidate._name}</a>`,
            Definition.getLabel("platformRating", candidate.platformRating),
          ]
            .filter((e) => !!e && !!String(e).trim().length)
            .join(", "),
          Definition.getLabels(
            "positiveSignals",
            candidate.positiveSignals
          ).join(", "),
          Definition.getLabels(
            "negativeSignals",
            candidate.negativeSignals
          ).join(", "),
          candidate.tagLine,
        ]
          .filter((e) => !!e && !!String(e).trim().length)
          .join("; ") +
        "<strong> - " +
        rejectedLabel +
        "</strong>" +
        ".</p>" +
        '<p style="font-size:11px;">' +
        rejectionReasonDetails +
        "</p>"
      );
    };
    ret.print = (array) => {
      return array.map((el) => printSingle(el)).join("");
    };
    return ret;
  };

  function updateField(engagement, field, eventStatus, callback) {
    if (Core.isAdminOrCoordinator()) {
      if (engagement) {
        Engagement.update(
          engagement,
          field,
          setEngagement,
          () => Core.showMessage("failed to save")
        );
      } else {
        //create engagement with specific data and close it
        !!externalRelationAssociation &&
          externalRelationAssociation(field, eventStatus, callback);
      }
    }
    setPickedMatchedStrength(null);
  };

  function modalUpdateStatusDefaultValues(selectedStrength) {
    const cbUpdateField = updateField;
    if (!!engagement) {
      let matchStrength =
        pickedMatchedStrength || engagement.matchStrength;
      return {
        ...engagement,
        engagement,
        matchStrength,
        cbUpdateField,
      };
    } else {
      if (!selectedStrength) {
        selectedStrength = myDefaultMatchStrength({
          job,
          engagement,
          candidateId,
          pickedMatchedStrength,
        });
      }
      return {
        stage: "Confirmation",
        state: "Closed",
        status: "E - 10x10",
        rejectionReason: "10x10 - No Match",
        matchStrength: selectedStrength,
        cbUpdateField,
      };
    }
  };

  function matchStrengthHandler(event, selected) {
    if (job.isBlacklisted) {
      alert("Job is blacklisted, can't create engagement of it");
      return;
    }
    setPickedMatchedStrength(selected);
    if (
      engagement ||
      Engagement.negativeStrengths().includes(selected)
    ) {
      modalUpdateStatus.current.open(
        modalUpdateStatusDefaultValues(selected)
      );
    } else {
      Store.set(
        Engagement.getCacheKeyJobMatch(candidateId, job.id),
        selected
      );
    }
  };

  return (
    <>
      <div className="sticky-top bg-white">
        <Row className="pb-1">
          <Col fullWidth>
            <label>{job._name} | {job._roles}</label>
          </Col>
        </Row>
        <Divider />
      </div>

      {/** @todo to deprecate? 2021-11-03 */}
      {/** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      {source === "candidateMatch" && (
        <span>
          <RadioButtonGroup
            name="platformRating"
            className="radio-button-group"
            valueSelected={myDefaultMatchStrength({
              job,
              engagement,
              candidateId,
              pickedMatchedStrength,
            })}
            onChange={matchStrengthHandler}
          >
            {Definition.get("engagementMatchStrength").map((item) => (
              <RadioButton
                key={`matchStrengh#${item.key}`}
                value={item.id}
                label={item.label}
              />
            ))}
          </RadioButtonGroup>
          <hr />
        </span>
      )}
      {!!extraInfo.displayEngagements &&
        !!job.engagementStructureLoader && (
          <div>
            Loading engagements&nbsp;
            <img alt="loading..." height="14" src={loader} />
          </div>
        )}
      {!!extraInfo.displayEngagements &&
        !job.engagementStructureLoader && (
          <div>
            <RenderEngagementStructure
              structure={job.engagementStructure}
              stage="stage"
              count="count"
              name="recruiterName"
              title="Recuiter Name"
              lastSubmission={getEngagementsByState.getLastSubmissionDate(
                job.engagements
              )}
            />
          </div>
        )}
      {/** <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}

      <CardContent title="Summary">
        <div className="d-flex flex-wrap" >
          {!!job._roles && (<span title="Roles" className="w-col-1 mr-1 mb-1">{job._roles}</span>)}
          {!!job._level && (<span title="Level" className="w-col-1 mr-1 mb-1">{job._level}</span>)}
          {!!job.employer.teamCount && (<span title="Team Count" className="w-col-1 mr-1 mb-1">~{job.employer.teamCount}&nbsp;engineers</span>)}
          <div className="d-flex flex-column w-col-2 mr-1 mb-1">
            <div title="Salary Range" className="c-gray">
              {!!job.salaryMax
                ? `Salary: $${job.salaryMin || 0} - $${job.salaryMax
                }`
                : "Unknown Salary"}
            </div>
            {!!job.salaryNote && (
              <small>
                {" "}
                - {job.salaryNote}
                <br />
              </small>
            )}
            <small>
              <i>
                Actual compensation may be adjusted with interview
                feedback, leveling at the company, and candidate's
                location. Please do not set the expectation of the
                actual compensation.
              </i>
            </small>
          </div>

          {!!job.hiringTarget && (
            <span title="Hiring Target" className="w-col-1 mr-1 mb-1">
              Hiring Target:&nbsp;
              {job.hiringTarget}
            </span>
          )}

          {/* epic-3038(new locations)-story-3652-m4 | 2021-08-03 Tue µ */}
          {!!job._desiredEmploymentTypes.length && (
            <span title="Job Type" className="w-col-1 mr-1 mb-1">
              {Definition.getLabels("desiredEmploymentType", job.desiredEmploymentTypes).join(' | ')}
            </span>
          )}

          {!!job.employer.url && (
            <span title="Company URL" className="w-col-2 mr-1 mb-1">
              <a
                href={formatURL(job.employer.url)}
                target="_blank"
                rel="noreferrer"
              >
                {job.employer.url}
              </a>
            </span>
          )}
          {!!Definition.getLabel(
            "stage",
            job.employer.stage
          ) && (
              <span title="Employer Stage e.g. Series A" className="w-col-1 mr-1 mb-1">
                {Definition.getLabel(
                  "stage",
                  job.employer.stage
                )}
                &nbsp;Company
              </span>
            )}
          {!!job.employer.totalFunding && (
            <span title="Employer Founding" className="w-col-1 mr-1 mb-1">
              {!!job.employer.totalFunding
                ? "Funding $" + job.employer.totalFunding
                : "Unknown Funding"}
            </span>
          )}
          {!!job.employer.foundingYear && (
            <span title="Employer Founded" className="w-col-1 mr-1 mb-1">
              Founded {job.employer.foundingYear}
            </span>
          )}
          {Core.isAdminOrCoordinator() &&
            !!job.employer.proxyName && (
              <span title="Proxy Name" className="w-col-1 mr-1 mb-1">
                {job.employer.proxyName}
              </span>
            )}
          {Core.isAdminOrCoordinator() && (
            <span title="State" className="w-col-1 mr-1 mb-1">
              {Definition.getLabel("state", job.state) || (
                <i>&mdash;</i>
              )}
            </span>
          )}
        </div>
      </CardContent>

      <LocationSection job={job} />

      {!!job.employer.tagline && (
        <CardContent title={`${job.employer.name} elevator pitch`}>
          <div
            dangerouslySetInnerHTML={{
              __html: job.employer.tagline,
            }}
          />
        </CardContent>
      )}

      {!!job.employer.technicalSkills.length && (
        <CardContent title="EMPLOYER TECH STACK">
          <span>
            {job.employer.technicalSkills
              .map((id) =>
                Definition.getLabel("technicalSkills", id)
              )
              .join(", ")}
          </span>
        </CardContent>
      )}

      {!!job.technicalSkills.length && (
        <CardContent title="ACCEPTABLE TECH">
          {job.technicalSkills
            .map((id) =>
              Definition.getLabel("technicalSkills", id)
            )
            .join(", ")}
        </CardContent>
      )}

      {renderAdditionalNotes()}

      {!!job.qualificationNotes && (
        <CardContent title="INSIDER SCOOP">
          <div
            dangerouslySetInnerHTML={{
              __html: job.qualificationNotes,
            }}
          />
        </CardContent>
      )}

      {Core.isAdminOrCoordinator() && !!engagements && (
        <>
          <CardContent title={t('jobs.card.engagementsSummary.title')}>
            <div style={{ maxHeight: "150px", overflow: "auto" }}>
              {profiles().summary()}
            </div>
          </CardContent>
          <CardContent title="YES - Client accepted profile">
            <div
              style={{ maxHeight: "150px", overflow: "auto" }}
              dangerouslySetInnerHTML={{
                __html: profiles().print(profiles().yes()),
              }}
            />
          </CardContent>
        </>
      )}

      {Core.isAdminOrCoordinator() && (
        <CardContent title="MAYBE - Profiles under Client Review">
          <div style={{ maxHeight: "150px", overflow: "auto" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: profiles().print(
                  profiles().mayBe()
                ),
              }}
            />
          </div>
        </CardContent>
      )}

      {Core.isAdminOrCoordinator() && (
        <CardContent title="NO - Client rejected profile">
          <div
            style={{ maxHeight: "150px", overflow: "auto" }}
            dangerouslySetInnerHTML={{
              __html: profiles().print(profiles().no()),
            }}
          />
        </CardContent>
      )}

      {!!job.jobDescription && (
        <CardContent
          title={
            <>
              JOB DESCRIPTION&nbsp;
              {job.jobDescriptionPublicURL ? (
                <a
                  href={job.jobDescriptionPublicURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Company's job description link
                </a>
              ) : (
                ""
              )}
            </>
          }
        >
          <div
            dangerouslySetInnerHTML={{
              __html: job.jobDescription,
            }}
          />
        </CardContent>
      )}

      {!!job.employer.additionalInfo && (
        <CardContent title="HIRING PRIORITY">
          <div
            dangerouslySetInnerHTML={{
              __html: job.employer.additionalInfo,
            }}
          />
        </CardContent>
      )}
      {(!!job.jobInterviewProcess || !!job.employer.interviewProcess) && (
        <CardContent title="INTERVIEW PROCESS">
          <span ref={self => (JobDetails.references["interview-process"] = self)}></span>
          {!!job.employer.interviewProcess && (
            <>
              General:
              <div
                dangerouslySetInnerHTML={{
                  __html: job.employer.interviewProcess,
                }}
              />
              <br />
            </>
          )}
          {!!job.jobInterviewProcess && (
            <>
              Job Specific:
              <div
                dangerouslySetInnerHTML={{
                  __html: job.jobInterviewProcess,
                }}
              />
            </>
          )}
        </CardContent>
      )}
      {(!!job.publicNotes ||
        !!job.employer.publicNotes) && (
          <CardContent title="NOTES">
            {!!job.employer.publicNotes && (
              <>
                General:
                <div
                  dangerouslySetInnerHTML={{
                    __html: job.employer.publicNotes,
                  }}
                />
                <br />
              </>
            )}
            {!!job.publicNotes && (
              <>
                Job:
                <div
                  dangerouslySetInnerHTML={{
                    __html: job.publicNotes,
                  }}
                />
              </>
            )}
          </CardContent>
        )}
      {!!job.employer.product && (
        <CardContent title="COMPANY OVERVIEW">
          <div
            dangerouslySetInnerHTML={{
              __html: job.employer.product,
            }}
          />
        </CardContent>
      )}
      {!!job.employer.whyUs && (
        <CardContent title="WHY US">
          <div
            dangerouslySetInnerHTML={{
              __html: job.employer.whyUs,
            }}
          />
        </CardContent>
      )}
      {(!!job.employer.crunchbaseUrl ||
        !!job.employer.additionalUrl ||
        !!job.employer.notables ||
        !!job.employer.perks) && (
          <CardContent title="COMPANY INFO">
            {(!!job.employer.crunchbaseUrl ||
              !!job.employer.additionalUrl) && (
                <>
                  Links:
                  <br />
                  <br />
                  <div>
                    <a
                      href={formatURL(
                        job.employer.crunchbaseUrl
                      )}
                    >
                      {job.employer.crunchbaseUrl}
                    </a>
                  </div>
                  {job.employer.additionalUrl &&
                    job.employer.additionalUrl
                      .split(/\n/)
                      .map((url) => (
                        <div key={Core.getKey()}>
                          <a href={formatURL(url)}>{url}</a>
                        </div>
                      ))}
                  <br />
                </>
              )}
            {!!job.employer.notables && (
              <>
                Notable:
                <pre>{job.employer.notables}</pre>
                <br />
              </>
            )}
            {!!job.employer.perks && (
              <>
                Perks:
                <div
                  dangerouslySetInnerHTML={{
                    __html: job.employer.perks,
                  }}
                />
              </>
            )}
          </CardContent>
        )}
      {Core.isAdminOrCoordinator() &&
        !!job.jobBlackList.length && (
          <CardContent title="BLACK LIST">
            {job.jobBlackList.map((item) => (
              <li key={item.id}>
                {item.firstName} {item.lastName}
              </li>
            ))}
          </CardContent>
        )}

      <ModalUpdateStatus
        ref={modalUpdateStatus}
        parent={{ state: job, updateField }}
        hasRejectionReason={true}
        sourceClass="JobCard"
      />

    </>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { JobDetails as default, JobDetails };

/* ============================================ */
