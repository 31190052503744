/** ============================================ µ
 * @description Job Hopper Fieldset [JSX]
 *              UI Component
 * @route       /candidate/edit/:id
 * @createdAt   2021-03-11
 * @updatedAt   2021-08-12 Thu
 * ============================================ */
/* IMPORTS ==================================== */

import {
  Checkbox,
  Divider,
  TextField
} from "@mui/material";
import moment from "moment";
import React from "react";
import Core from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";

/* METHODS ==================================== */

/**
 * Returns true,
 * if finds an maximum of consecutive occurrences
 * of less of a minimum position duration
 * with different employers
 *
 * @param {object} params
 * @param {object} params.resume - parsed resume
 * @param {object[]} params.resume.employmentHistories
 * @param {object[]} params.resume.employmentHistories[].positionHistories
 * @param {number} params.maxJumps - max amount of jumps between employments
 * @param {number} params.minDuration - min position duration
 * @param {number} params.maxLastJobToReview - max of last jobs to review
 */
function isCandidateJobHopper({
  resume = {},
  maxJumps = 2,
  minDuration = 1,
  maxLastJobToReview = 5,
}) {
  let lastEmployer;
  let lastStartDate;
  /** count of occurrences */
  let count = 0;
  const unifiedEmploymentHistories = [];
  const { employmentHistories = [] } = resume;
  employmentHistories.forEach(empHist => {
    const {
      employerOrgName,
      positionHistories = []
    } = empHist;
    positionHistories.forEach(posHist => {
      const { startDate, endDate } = posHist;
      if (
        !!startDate
        &&
        (moment(startDate).format() !== 'Invalid date')
      ) {
        unifiedEmploymentHistories.push({
          employerOrgName,
          startDate: moment(startDate).format(),
          endDate: moment(endDate).format(),
        });
      }
    });
  });
  unifiedEmploymentHistories.sort((a, b) => {
    return String(a.startDate).localeCompare(b.startDate);
  });

  /** µ FOR DEBUG PURPOSES * /
  console.debug('µ:isCandidateJobHopper', { unifiedEmploymentHistories });
  /** */

  const result = !!(
    unifiedEmploymentHistories
      .slice(Math.max(unifiedEmploymentHistories.length - maxLastJobToReview, 0))
      .find(empHist => {
        const { employerOrgName, startDate } = empHist;
        const lastPositionDuration = (
          lastStartDate
            ? moment(startDate).diff(lastStartDate, 'years')
            : 0
        );
        count = (
          (
            !!lastEmployer
            &&
            (employerOrgName !== lastEmployer)
            &&
            (lastPositionDuration < minDuration)
          )
            ? count + 1
            : 0
        );
        /** µ FOR DEBUG PURPOSES * /
        console.debug('µ:isCandidateJobHopper', {
          count,
          lastPositionDuration,
          employerOrgName,
          lastEmployer,
          startDate,
          lastStartDate,
          endDate,
        });
        /** */
        lastEmployer = employerOrgName;
        lastStartDate = startDate;
        return count >= maxJumps;
      })
  );
  /** µ FOR DEBUG PURPOSES * /
  console.debug('µ:isCandidateJobHopper', { result });
  /** */
  return result;
}

/**
 * Functional Component
 *
 * "Is Candidate a Job Hopper?"
 *
 * - Only available for Admins and Recruiters
 *
 * @param {object} props
 * @param {object} props.candidateController CandidateEdit component (parent)
 * @param {object} props.basicsController  Basics component (child)
 *
 * @see isCandidateJobHopper
 */
function JobHopperFieldset(props) {
  const {
    /**
     * CandidateEdit component controller
     * (parent of Basics)
     */
    candidateController
  } = props;
  const {
    /** candidate state */
    state,
    /** function to update candidate state */
    setStateStore,
  } = candidateController;
  /**
   * candidate state fields
   */
  const {
    employmentHistories = [],
    jobHopReason4Emp,
    jobHopReason4Us,
    jobHopDisputeParser = false,
    negativeSignals,
  } = state;
  const flagIsCandidateJobHopper = (
    (Core.isAdmin() || Core.isRecruiter()) &&
    isCandidateJobHopper({
      resume: { employmentHistories },
    })
  );
  const JOB_HOPPER_ID = Definition.getId(
    'negativeSignals',
    'Job Hopper'
  );
  const flagIsJobHopperTagged = negativeSignals.includes(
    JOB_HOPPER_ID
  );
  function removeJobHopperFlag() {
    setStateStore({
      negativeSignals: [...negativeSignals].filter(n => n !== JOB_HOPPER_ID),
    });
  }
  function addJobHopperFlag() {
    setStateStore({
      negativeSignals: [...negativeSignals, JOB_HOPPER_ID],
    });
  }
  /**
   * SETS/UN-SETS "Job Hopper" negativeSignals tag
   */
  function updateNegativeSignalsState() {
    if (jobHopDisputeParser && flagIsJobHopperTagged) {
      removeJobHopperFlag();
    }
    else if (!jobHopDisputeParser && !flagIsJobHopperTagged) {
      addJobHopperFlag();
    }
  }
  const flagIsCandidateJobHopperRef = React.useRef(flagIsCandidateJobHopper);
  flagIsCandidateJobHopperRef.current = flagIsCandidateJobHopper;
  const updateNegativeSignalsStateRef = React.useRef(updateNegativeSignalsState);
  /**
   * UPDATING JobHopper flag on state ready
   */
  React.useEffect(() => {
    if (flagIsCandidateJobHopper) {
      updateNegativeSignalsStateRef.current();
    }
  }, [flagIsCandidateJobHopper]);
  /**
   * UPDATING JobHopper flag on toggle Checkbox
   */
  React.useEffect(() => {
    if (flagIsCandidateJobHopperRef.current) {
      updateNegativeSignalsStateRef.current();
    }
  }, [jobHopDisputeParser]);
  React.useEffect(() => {
    /** µ FOR DEBUG PURPOSES * /
    console.debug('µ:JobHopperFieldset', {
      flagIsCandidateJobHopper,
      flagIsJobHopperTagged,
      jobHopDisputeParser,
      negativeSignals,
      JOB_HOPPER_ID,
      state,
    });
    /** */
  });
  return (
    flagIsCandidateJobHopper ?
      (
        <Row className="mb-2">
          <Col fullWidth>
            <div className="my-2">
              <Divider />
            </div>

            {/** RED TEXT */}
            <div className="d-flex align-center c-red">
              {`WARNING: resume parser detects job hopping (2+ out of last 5 jobs < 1y).`}

              {/** CHECKBOX */}
              <Checkbox
                name="jobHopDisputeParser"
                checked={jobHopDisputeParser}
                onChange={event => {
                  setStateStore({
                    jobHopDisputeParser: event.target.checked,
                    key: "jobHopDisputeParser"
                  });
                }}
                color="primary"
              />
              Check here if parser is wrong (no short stints).
            </div>

            {jobHopDisputeParser && (
              <>

                {/** TEXTFIELD 1 */}
                <div className="d-flex align-center py-1">
                  <span className="w-100 d-flex justify-start">
                    Reasons to be included in the employer submission:
                  </span>
                  <TextField
                    name="jobHopReason4Emp"
                    type="text"
                    variant="standard"
                    placeholder="for example: company shut down"
                    value={jobHopReason4Emp}
                    onChange={event => {
                      setStateStore({ jobHopReason4Emp: event.target.value });
                    }}
                    color="primary"
                    fullWidth
                  />
                </div>

                {/** TEXTFIELD 2 */}
                <div className="d-flex align-center py-1">
                  <span className="w-100 d-flex justify-start">
                    Reasons for 10x10 and only share if employer on as needed basis:
                  </span>
                  <TextField
                    name="jobHopReason4Us"
                    type="text"
                    variant="standard"
                    placeholder="for example: disagree with company direction"
                    value={jobHopReason4Us}
                    onChange={event => {
                      setStateStore({ jobHopReason4Us: event.target.value });
                    }}
                    color="primary"
                    fullWidth
                  />
                </div>

              </>
            )}
          </Col>
        </Row>
      )
      : null
  );
}

/* EXPORTS ==================================== */

export {
  JobHopperFieldset as default,
  JobHopperFieldset,
  isCandidateJobHopper,
};

/* ============================================ */
