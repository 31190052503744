import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";

import ContentAdd from "material-ui/svg-icons/content/add";
import FloatingActionButton from "material-ui/FloatingActionButton";
import FlatButton from "material-ui/FlatButton";
//import Checkbox from "material-ui/Checkbox";
import Snackbar from "material-ui/Snackbar";

import Core, { colors } from "../../lib/Core";
import Employer from "../../lib/Employer";

import FilterControl from "../FilterControl/FilterControl";
import List from "../List/ListNoPaging";
import EmployerCard from "./Card/EmployerCard";
import Store from "../../lib/Store";
import Debug from "../../lib/Debug";

let attempt = 0;

class Employers extends Component {
  data;
  constructor() {
    super(...arguments);
    this.name = "Employers";
    this.state = {
      snackBarMessage: "",
      snackBarOpen: false,
      showAll: false
    };
    Store.set("path", window.location.href);
    this.reloadData = ev => this.loadData();
    this.loadData();
  }
  loadData() {
    setTimeout(st => {
      if (this.FilterControl) {
        Employer.getAll(
          employers =>
            this.FilterControl &&
            this.FilterControl.setItems(employers, /^Active$/)
        );
      } else {
        if (attempt < 10) {
          attempt++;
          Debug.log({ missingFilterControl: true });
          this.loadData();
        }
      }
    });
  }
  render() {
    return (
      <Fragment>
        <FilterControl
          ref={FilterControl => (this.FilterControl = FilterControl)}
          parent={this}
          menus={Employer.menus}
          more={Employer.more}
          onChange={filtered => this.List.setItems(filtered)}
          toolBarRight={
            Core.isAdmin() && (
              <NavLink exact to="/employer/create" className="ui-m-min">
                <FlatButton label="+ New Employer" className="list-add-new" />
              </NavLink>
            )
          }
        />
        <List
          ref={List => (this.List = List)}
          tabs={Employer.listTabs}
          tab={Employer.listTab}
          name="Employer"
          card={EmployerCard}
          parent={this}
          floatingButton={
            Core.isAdmin() && (
              <NavLink className="ui-m-max" exact to={`/employer/create`}>
                <FloatingActionButton
                  className="list-floating-button"
                  backgroundColor={colors.cyan}
                >
                  <ContentAdd />
                </FloatingActionButton>
              </NavLink>
            )
          }
        />
        <Snackbar
          open={this.state.snackBarOpen}
          message={this.state.snackBarMessage}
          className="snack-bar"
          autoHideDuration={7000}
          onRequestClose={this.hideMessage}
        />
      </Fragment>
    );
  }
}

export default Employers;
