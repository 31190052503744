import copy from "copy-to-clipboard";
import {
  Checkbox,
  FlatButton,
  IconButton,
  IconMenu,
  MenuItem
} from "material-ui";
import { MuiThemeProvider } from "material-ui/styles";
import moment from "moment";
import React, { Component } from "react";
import AccountDetails from "../../components/Accounts/Card/AccountDetails";
import Account from "../Account";
import Core from "../Core";
import Definition from "../Definition";
import { mapCandidates } from "./candidate";
import { mapStarred } from "./mapStarred.tool";

const mdash = "—";

export const MODEL_NAME_ACCOUNT = 'Account';

export function getBookmarkModel() {
  return (
    {
      createdAt: new Date().toISOString(),
      label: '',
      pathname: '', // from react-router-dom.location
      search: '', // from react-router-dom.location
      // hiddencolumn
    }
  );
};

export function mapBookmark(item = {}) {
  const bookmarkModel = getBookmarkModel();
  Object.keys(bookmarkModel).forEach(key => (item[key] = (item[key] || bookmarkModel[key])));
  return item;
}

export function mapBookmarks(items = []) {
  return items.map(mapBookmark);
}

function getAccountModel({ extended: requiredExtended } = {}) {

  const model = {
    firstName: "",
    lastName: "",
    role: "Admin",
    username: "",
    email: "",

    emailsList: [],
    excludeAutomatedEmailsUntil: '',

    phone: "",
    preferredName: "",
    title: "",
    talentAdvisor: "",
    linkedInUrl: "",
    calendarUrl: "",
    publicNotes: "",
    privateNotes: "",
    companyName: "",
    additionalPhone: "",
    additionalEmail: "",
    testBucket: null,
    extensionId: "",
    closeDate: null, // "2017-12-18T20:51:55.157Z",
    expirationDate: null, // "2018-01-19T15:43:52.163Z",

    /** special */
    password: "",

    /** useless */
    address: "",
    employerName: "",
    employerInfo: "",
    userSettings: "",
    emailVerified: false,

    /** mixin timestamps */
    createdAt: null, // new Date().toISOString(), // "2017-12-18T20:51:55.157Z",
    updatedAt: null, // new Date().toISOString(), // "2017-12-18T20:51:55.157Z",

    /** foreign keys */
    //accountStateTagId: ""
    state: 2,

    bookmarks: [],

  };

  const extended = {
    ___model___: MODEL_NAME_ACCOUNT,
    id: null,
    ...model,
    /** includes */
    candidates: [],
    //accountStateTag: {},
    /** for local use */
    retype: "",
    realRoleId: "",
    roleMapId: "",
    roles: [],
    realRole: {},
    roleMap: {},
    accountRecruiterWhiteList: [],
    accountEmployerBlackList: [],
    accountJobBlackList: [],
    accountEmployerSourceList: [],
    accountJobSourceList: [],
    ___keys___: []
  };

  return requiredExtended ? extended : model;

}

const mapAccount = item => {

  const account = getAccountModel({
    extended: true
  });

  if (item) {
    Object.keys(account).forEach(
      key => !!item[key] && (account[key] = item[key])
    );
    account.id = item.id || item._id;
    account._updatedAt = account.updatedAt
      ? moment(account.updatedAt).format("MM-DD-YYYY")
      : mdash;
    account.candidates = mapCandidates(account.candidates);

    account.bookmarks = mapBookmarks(account.bookmarks);

    account._candidatesLength = Number(account.candidates.length);

    mapStarred({ model: account, starredList: (item.accountStarreds || []) });

    account._starred = account.starred ? "Starred: True" : "Starred: False";
    account._name = `${account.firstName || ""} ${account.lastName ||
      ""}`.trim();

    /* set definition labels */
    Definition.set(account, "state");

    account._infoCmp = <AccountInfo account={account} />;
    account._emailCmp = <EmailInfo account={account} />;
    account._engagementsCmp = <AccountDetails account={account} />;
    account._rowOptionsCmp = <RowOptions account={account} />;

    account.checkStar = (checked, onSuccess) => {
      Account.updateStarred(
        account.id,
        account.starredId,
        checked,
        response => {
          account.starredId = response.id;
          account.starred = response.starred;
          account._starred = response.starred ? "Starred: True" : "Starred: False";
          account.filters = {
            ...account.filters,
            Starred: ["Non Starred", "Starred"][~~Boolean(response.starred)]
          };
          onSuccess && onSuccess(response);
        }
      );
    };
    account.copyEmail = em => {
      if (
        copy(account.email, {
          debug: true,
          message: "Press #{key} to copy"
        })
      ) {
        Core.showMessage(`${account.email} copied!`);
      } else {
        Core.showMessage("Fail copy!");
      }
    };
    account.delete = onSuccess => {
      const candidates = account.candidates || [];
      Core.dialog.open({
        title: (
          <>{`Delete "${account._name}"${!!candidates.length
            ? ` and ${candidates.length} candidate${candidates.length === 1 ? "" : "s"
            }?`
            : ""
            }`}</>
        ),
        message: "This action can't be undone.",
        style: { width: "320px" },
        actions: [
          <FlatButton
            label="Cancel"
            className="button-white-cyan"
            onClick={ev => {
              Core.dialog.close();
            }}
          />,
          <FlatButton
            label="Delete"
            className="button-flat-cyan"
            onClick={ev => {
              Core.dialog.close();
              Account.delete(account.id, onSuccess ? onSuccess : function () { });
            }}
          />
        ]
      });
    };

    /* for autocomplete */
    account.___keys___ = [
      account._state,
      account._name,
      account.companyName,
      account._starred,
      account._candidatesLength
    ]
      /* combine and split values */
      .join("; ")
      .split("; ")
      /* remove empty values */
      .filter(e => !!e && String(e).trim());

    // console.debug('map', account);

  }


  return account;
};

const mapAccounts = data => {
  return (data || []).map(item => {
    const account = mapAccount(item);
    return {
      ...account,
      filters: {
        Name: String(String(account.lastName)[0]).toUpperCase(),
        Role: account.role,
        Recent: moment(account.createdAt),
        Starred: ["Non Starred", "Starred"][~~Boolean(account.starred)]
      }
    };
  });
};

class AccountInfo extends Component {
  render() {
    const { account } = this.props;
    return (
      <span
        className="anchor"
        onClick={ev => Core.go({
          ...this.props,
          to: `/account/edit/${account.id}`
        })}
      >
        <b>
          {account.firstName || <i>&mdash;</i>}
          &nbsp;
          {account.lastName || <i>&mdash;</i>}
        </b>
      </span>
    );
  }
}
class EmailInfo extends Component {
  render() {
    const { account } = this.props;
    return (
      <MuiThemeProvider>
        <div>
          <IconButton
            iconStyle={account.openedColor}
            className="icon16"
            onClick={account.copyEmail}
          >
            <i className="material-icons">mail</i>
          </IconButton>
          &nbsp;
          <span className="truncate" style={{ width: "calc(100% - 48px)" }}>
            {account.email || <i>&mdash;</i>}
          </span>
        </div>
      </MuiThemeProvider>
    );
  }
}
class RowOptions extends Component {
  render() {
    // Core.log("RowOptions", "render");
    const { account } = this.props;
    return (
      <div className="row-options inline-blocks">
        <Checkbox
          className="starred"
          checked={account.starred}
          onCheck={(ev, checked) => {
            account.checkStar(checked, res => this.setState({ updated: true }));
          }}
          checkedIcon={<i className="material-icons">star</i>}
          uncheckedIcon={<i className="material-icons">star_border</i>}
        />
        <IconMenu
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          targetOrigin={{ horizontal: "right", vertical: "top" }}
          style={{ marginLeft: "4px" }}
          iconButtonElement={
            <IconButton style={{ width: 24, padding: 0 }}>
              <i className="material-icons">more_vert</i>
            </IconButton>
          }
        >
          {Core.isAdminOnLocal() && (
            <MenuItem
              primaryText="Delete"
              onClick={ev =>
                account.delete(res => Core.Main && Core.Main.fetchData())
              }
            />
          )}
        </IconMenu>
      </div>
    );
  }
}

export {
  getAccountModel,
  mapAccount,
  mapAccounts
};

