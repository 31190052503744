/** ============================================ µ
 * @Description EmailEntry [JSX]
 *              UI Component
 * @createdAt   2021-04-29
 * @updatedAt   2021-09-03 Fri
 * ============================================ */
/* IMPORTS ==================================== */

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Input } from 'antd';
import React from "react";
import { REC_MSG_TYPE__ALL_ID } from '../../lib/Definition';
import getEmailModel from '../../lib/models/email.model';
import Col from "./Col";
import Row from "./Row";
import { useTranslation } from "react-i18next";
import { NOT, validateEmail } from '../../lib/GenericTools.lib';

/* METHODS ==================================== */

/**
 *
 * @param {object} props
 * @param {object} props.data
 * @param {string} props.data.name
 * @param {string} props.data.email
 * @param {number[]} props.data.to
 * @param {number[]} props.data.cc
 * @param {number[]} props.data.bcc
 * @param {number} props.index
 * @param {function} props.onChange
 *
 * @returns
 */
function EmailEntry(props) {
  const {
    data = getEmailModel(),
    index = new Date().getTime(),
    messageTypeTags = [],
    onChange = ({ index, data }) => null,
    onRemove = ({ index }) => null,
  } = props;
  const {
    name = '',
    email = '',
    to = [],
    cc = [],
    bcc = []
  } = data;
  const { t } = useTranslation();
  // console.debug('data', data);
  let messageTypesData = messageTypeTags.map(({ id, label }) =>
    ({ id, label, delivery: to.includes(id) ? 'to' : cc.includes(id) ? 'cc' : bcc.includes(id) ? 'bcc' : '' })
  );
  let emailTest = NOT(email) || validateEmail(email);
  // console.debug('messageTypesData', messageTypesData);
  return (
    <div className="bg-a-05 p-1 py-2 my-1 rounded" style={{ position: 'relative' }}>
      <Row className="mb-2">
        <Col>
          <div className="d-flex ant-row-space-between">
            <h3>Email Address</h3>
            {NOT(emailTest) && (
              <span className="c-red">{t('email format is wrong')}</span>
            )}
          </div>
          <Input
            value={email}
            placeholder={t('Type here')}
            onChange={event =>
              onChange({
                index,
                data: { ...data, email: event.target.value }
              })
            }
          />
        </Col>
        <Col>
          <h3>Name/Alias</h3>
          <Input
            value={name}
            placeholder={t('Type here')}
            onChange={event =>
              onChange({
                index,
                data: { ...data, name: event.target.value }
              })
            }
          />
        </Col>
      </Row>
      {messageTypesData.map(({ id, label, delivery }) => (
        // !console.debug('deliver', delivery) &&
        <Row>
          <Col className="d-flex flex-wrap align-center" fullWidth>
            <h3 className="m-0 w-20 align-left">{label}</h3>
            <RadioGroup
              row
              aria-label={`message-type-${label}`}
              name={`message-type-${label}`}
              value={delivery}
              onChange={event =>
                onChange({
                  index,
                  data: {
                    ...data,
                    to: to.filter(_id => _id !== id),
                    cc: cc.filter(_id => _id !== id),
                    bcc: bcc.filter(_id => _id !== id),
                    [event.target.value]: REC_MSG_TYPE__ALL_ID === id ? [id] : [...data[event.target.value].filter(id => id !== REC_MSG_TYPE__ALL_ID), id]
                  }
                })
              }>
              {['to', 'cc', 'bcc'].map(
                value => (
                  <FormControlLabel value={value} labelPlacement="start" control={<Radio color="default" />} label={value} />
                )
              )}
            </RadioGroup>
          </Col>
        </Row>
      ))}
      <IconButton
        aria-label="delete"
        style={{
          position: 'absolute',
          bottom: '0.5rem',
          right: '0.5rem'
        }}
        onClick={onRemove({ index, data })}
      >
        <i className="material-icons">delete</i>
      </IconButton>
    </div>
  );
}

/* EXPORTS ==================================== */

export {
  EmailEntry as default,
  EmailEntry,
};

/* ============================================ */