import React, {Component, Fragment} from "react";
import githubGraphqlGetUser from "../../../../lib/services/GitHub/githubGraphqlGetUser";
/* Lib files */
import CandidateSkills from "./CandidateSkills";
import Candidate from "../../../../lib/Candidate";
import Qualifications from "./Qualifications";
import WriteUps from "./WriteUps";
import SubmissionHistory from "./SubmissionHistory";
import EmploymentHistory from "../../../Shared/MatchNew/CandidatePipe/EmploymentHistory";
import EducationHistory from "../../../Shared/MatchNew/CandidatePipe/EducationHistory";
import Loader from "../../../Shared/Loader";
import Grid from "@mui/material/Grid";

class CandidatePipe extends Component {
  state = {
    githubStats: null,
    candidateDetailsFetched: this.props.source === 'candidateMatch'
  };

  componentDidMount() {
    this.getGithubStats();
    console.log({cando: this.props.candidate});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.candidate?.id !== this.props.candidate?.id ) {
      this.getGithubStats();
      if(this.props.source !== 'candidateMatch'){
        this.fetchCandoDetails();
      }
    }
  }

  fetchCandoDetails() {
    this.setState({candidateDetailsFetched: false}, () => {
      Candidate.get(this.props.candidate.id, (candidate) => {
        this.setState({
          candidate: {...this.props.candidate, ...candidate},
          candidateDetailsFetched: true
        });
      }, () => {
        alert('failed to fetch cando details');
        this.setState({candidateDetailsFetched: false})
      }, {commonQuery: { include: ["account"]}})
    })
  }

  getGithubStats() {
    const {candidate} = this.props;

    if (candidate) {
      githubGraphqlGetUser(candidate.gitHubURL, (result) => {
        if (result) {
          this.setState({githubStats: result});
        } else {
          this.setState({githubStats: null});

        }
      })
    }
  }

  render() {
    const {githubStats, candidateDetailsFetched} = this.state;
    let {
      skillsHandler,
      saveContentCandidate,
      job,
      source,
      moveToApprovedChips,
      candidatePipeHeader,
      candidate,
    } = this.props;

    if(this.props.source !== 'candidateMatch'){
      candidate = this.state.candidate;
    }

    if (!candidate) {
      return <p className="match-new job-attributes" style={{textAlign: "center"}}>No active matched candidate to
        process !!!</p>
    }

    if (!candidateDetailsFetched) {
      return <Loader text={"loading candidate details"}/>
    }

    let jobArgs = [];
    if (!!job && !!job.mustHave.args) {
      jobArgs = [...job.mustHave.args];
    }
    if (!!job && !!job.niceToHave.args) {
      jobArgs = [...jobArgs, ...job.niceToHave.args];
    }

    return <Fragment>
      {candidatePipeHeader(candidate, job)}
      <Grid item xs={12}
            style={{
              height: 'calc(100vh - 310px)',
              overflow: "scroll"
            }}>
        {!!candidate && <Qualifications
          candidate={candidate}
          skillsHandler={skillsHandler}
          jobArgs={jobArgs}
          source={source}
          moveToApprovedChips={moveToApprovedChips}
        />}
        <br/>
        {!!candidate && <CandidateSkills
          data={
            [
              {
                label: <p><strong>Technology Domain</strong></p>,
                type: "technologyDomain",
                chips: candidate.technologyDomain
              },
              {
                label: <p><strong>Positive Signals</strong></p>,
                type: "positiveSignals",
                chips: candidate.positiveSignals
              },
              {
                label: <p><strong>Negative Signals</strong></p>,
                type: "negativeSignals",
                chips: candidate.negativeSignals
              }
            ]
          }
          jobArgs={jobArgs}
          skillsHandler={skillsHandler}
          source={source}
          candidate={candidate}
          githubStatsData={githubStats ? githubStats.getSingleRowHtml : null}
        />
        }
        <br/>
        {!!candidate && <WriteUps
          candidate={candidate}
          saveContentCandidate={saveContentCandidate}
          job={job}
        />}
        <br/>
        {!!candidate && <EmploymentHistory
          candidate={candidate}
        />}
        <br/>
        {!!candidate && <EducationHistory
          candidate={candidate}
        />}
        <br/>
        {!!candidate && <SubmissionHistory
          candidate={candidate}
        />}
        <br/>
      </Grid>


    </Fragment>
  }
}

export default CandidatePipe;