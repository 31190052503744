import i18n from 'i18next';
import React, { useState } from 'react';
import { render } from 'react-dom';
import { initReactI18next } from 'react-i18next';
import { HashRouter } from 'react-router-dom';
import CustomDialog from './components/Dialogs/CustomDialog';
import CustomDrawer from './components/Dialogs/CustomDrawer';
import CustomSnackbar from './components/Dialogs/CustomSnackbar';
import Loader from './components/Shared/Loader';
import { TRANSLATIONS_EN_US } from './i18n/en-US/translations';
import { TRANSLATIONS_ZH_CN } from './i18n/zh-CN/translations';
import './index.css';
import Core from './lib/Core';
import Debug from './lib/Debug';
import Definition from './lib/Definition';
import Streak from './lib/Streak';
import { AdminUser } from './routes/Admin.routes';
import { CommonUser } from './routes/CommonUser.routes';
import { CoordinatorUser } from './routes/Coordinator.routes';
import { onError, onErrorStreak } from './routes/OnError.routes';
import { RecruiterOnDev } from './routes/RecruiterOnDev.routes';
import { Unlogged } from './routes/Unlogged.routes';
import './theme.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { purple } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#536dfe', // purple[500],
    },
    secondary: {
      main: '#009688', // green.A700
    },
  },
});

i18n.use(initReactI18next).init({
  fallbackLng: 'en-US',
  resources: {
    'en-US': {
      translation: TRANSLATIONS_EN_US,
    },
    'zh-CN': {
      translation: TRANSLATIONS_ZH_CN,
    },
  },
});
export default i18n;

JSON.safeStringify = (obj, indent = 2) => {
  let cache = [];
  const retVal = JSON.stringify(
    obj,
    (key, value) =>
      typeof value === 'object' && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value,
    indent
  );
  cache = null;
  return retVal;
};

Debug.init();
Core.getVersion((version) => {
  setAdditionalFeatures(version);

  const cbStreakSuccess = () => {
    Definition.fetchAll((res) => {
      Debug.log({ definitions: Definition.getAll() });
      /*
      window.onbeforeunload = function() {
        return "Are you sure you want to navigate away?";
      };
      */

      render(
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
        , document.getElementById('root'));
    });
  };

  if (Core.isAdminOrCoordinator()) {
    Streak.fetch(
      Core.isOnDev() ? 'qatest' : 'engagements',
      (then) => {
        cbStreakSuccess();
      },
      onErrorStreak
    );
  } else {
    cbStreakSuccess();
  }
}, onError);

function setAdditionalFeatures(version) {
  render(
    <Loader text="Starting" />,
    document.getElementById('root')
  );
  if (Core.isOnDev()) {
    const packageVersion = document.createElement('div');
    if (Debug.isOn()) {
      packageVersion.setAttribute('class', 'package-version c-purple fixed');
    } else {
      packageVersion.setAttribute('class', 'package-version ondev fixed');
    }
    packageVersion.textContent = version;
    packageVersion.addEventListener('click', (ev) =>
      Debug.toggle().then((debug) => document.location.reload())
    );
    document.body.appendChild(packageVersion);
  }
  window['setFullStory']();
}

function App(props) {
  const [state, reload] = useState({});
  Core.reload = reload;
  console.debug('app', state);
  return (
    <HashRouter>
      <div className="App">
        {Core.isLoggedOut() ? (
          <Unlogged />
        ) : Core.isAdmin() ? (
          <AdminUser />
        ) : Core.isCoordinator() ? (
          <CoordinatorUser />
        ) : Core.isRecruiterOnDev() ? (
          <RecruiterOnDev />
        ) : (
          <CommonUser />
        )}
        <CustomDrawer
          ref={(self) => {
            Core.openDrawer = ({ direction, content, style, onClose }) =>
              self.open({
                direction,
                content,
                style,
                onClose,
              });
            Core.closeDrawer = (ev) => self.close(ev);
          }}
        />
        <CustomDialog
          ref={(self) => {
            Core.dialog = self;
            Core.showFailure = (failure, onClose) =>
              Core.dialog.open({
                title: 'Failure',
                message: failure,
                onClose,
              });
          }}
        />
        <CustomSnackbar
          ref={(self) => {
            Core.showMessage = (message, onClose) => {
              self
                ? self.open({
                  message,
                  onClose,
                })
                : alert(message);
            };
            Core.hideMessage = (ev) => self.close(ev);
          }}
        />
      </div>
    </HashRouter>
  );
}
