import React, {Component, Fragment} from "react";

import Grid from '@mui/material/Grid';
import {TextField} from "material-ui";
import styles from '../Styles.js';
import CategoryDropdown from "../Shared/CategoryDropdown";

class Text extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            text: !!this.props.updateAble ? this.props.updateAble.value : "",
            category: 'technicalSkills'
        }
    }

    finalStruct = () => {
        let {text, category} = this.state;

        return {
            type: 'text',
            value: text,
            category
        }
    }

    updateAttr = (key, value) => {
        let {onUpdate} = this.props;

        this.setState({[key]: value}, () => {
            !!onUpdate && onUpdate(this.finalStruct())
        })
    }

    render() {
        let {text, category} = this.state;

        return <Fragment>
            <Grid item xs={12} sm={12} className="job-custom-label">
                <CategoryDropdown onSave={this.updateAttr} category={category}/>
            </Grid>

            <Grid item xs={12} sm={9} className="mb-10">
                <TextField
                    value={text}
                    textareaStyle={styles.TextField.inputStyle}
                    underlineStyle={styles.TextField.underlineStyle}
                    style={styles.TextField}
                    rows={2}
                    rowsMax={2}
                    multiLine={true}
                    fullWidth
                    onChange={(event, text) => {
                        this.updateAttr('text',text);
                    }}
                />
            </Grid>
        </Fragment>;
    }
}

export default Text;
