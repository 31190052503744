import React from "react";
import MultipleSelect from "../Forms/MultipleSelect";
import { REACT_TABLE__COLUMN_XXXL } from "./useEnhancedReactTable.hook";

export function FilterStringColumn({
  column,
  column: {
    id: columnId,
    preFilteredRows
  },
  context: { config: { facetsPath }, },
  totalCount,
  filters = {},
  setFilter = () => { },
  latestQuery = {},
}) {
  return (
    <>
      <MultipleSelect
        name="filter"
        className="m-0"
        placeholder="Type and select to search"
        multiple="tags"
        value={(filters[columnId] || {}).$regex?.pattern?.split('|') || undefined}
        onChange={(value) => {
          setFilter({
            columnId,
            value: value.length ? { '$regex': { pattern: value.join('|'), option: 'i' } } : undefined
          });
        }}
        dropdownMatchSelectWidth={REACT_TABLE__COLUMN_XXXL}
      />
    </>
  );
}
