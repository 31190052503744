import Grid from "@mui/material/Grid";
import React, { Component } from "react";
import Candidate from "../../../lib/Candidate";
import Core from "../../../lib/Core";
import Definition, { STATE_ACTIVE } from "../../../lib/Definition";
import Engagement from "../../../lib/Engagement";
import EngagementDisagreement from "../../../lib/EngagementDisagreement";
import FilterControl from "../../../lib/FilterControl";
import annotatorActions from "../../../lib/tools/annotatorActions";
import getMisMatchThumbsAction from "../../../lib/tools/getMisMatchThumbsAction";
import { configJobs } from "../../Home/configJobs";
import { configMatchCandidates } from "../../Home/configMatchCandidates";
import { mapCandidateVisaToJobPresets, mapJobVisaToCandidatePresets } from "../../Home/mapVisaPresets.lib";
import ReactTable from "../../Home/ReactTable";
import Appbar from "./Appbar";
import ObjectListPipe from "./ObjectListPipe/ObjectListPipe";

/** @todo deprecated | to cleanup | 2021-10-06 Wed µ */
// import NewFilterControl from "../../NewFilterControl/NewFilterControl";

class Main extends Component {
  constructor() {
    super(...arguments);
    const {
      subjectFunc,
      objectListFunc,
      filterControl,
      filterObjects,
      getChips,
      putDownCallback,
      disEngagementModel,
      makeEngagement,
      sourceKey,
      getObjectLabels,
      getObjectConstraints,
      getObjectBlackListed,
    } = this.props.source;

    this.state = {
      subjectFunc,
      objectListFunc,
      filterControl,
      filterObjects,
      getChips,
      putDownCallback,
      disEngagementModel,
      makeEngagement,
      sourceKey,
      getObjectLabels,
      getObjectConstraints,
      getObjectBlackListed,
      subject: null,
      engagements: [],
      object: [],
      selectedObject: null,
      skippedObjects: [],
      multiSelectedObjects: [],
      menus: [],
      more: [],
      sources: [],
      chips: [],
      keywords: [],
      filteredObjects: [],
      annotatorMode: !!this.props.params.selected ? true : false,
      doneMLScore: false,
      shouldShowBlacklisted: false,
    };
    this._menuFlag = false;
  }

  componentDidUpdate() { }

  loadSubject(cb) {
    this.state.subjectFunc((subject, engagements) => {
      subject.applyLooseMatch = this.state.applyLooseMatch; // in case this was set before record was loaded and it work properly with filters
      this.setState({ subject, engagements }, () => {
        !!cb && cb();
      });
    });
  }

  componentDidMount() {
    this.loadSubject(() => {
      // omitted fetchObjectList, in order of load data using the new ReactTable component (v3) | 2021-10-10 Sun µ
      // this.fetchObjectList({}, true);
    });
  }

  fetchObjectList = (params = {}, shouldSetInitialFilters = false) => {
    this.setState({ isFetchingObjectList: true });
    this.state.objectListFunc((object) => {
      this.setState({ object, isFetchingObjectList: false }, () => {
        const { subject, object, filterControl } = this.state;

        if (!!subject && !!object.length) {
          if (shouldSetInitialFilters) {
            const getInitialFilters = filterControl({
              subject,
              object,
              selected: Object(params).selected,
            });
            let {
              menus,
              more,
              sources,
              chips,
              filteredObjects,
              constraintsObjects,
              withoutConstraintsObjects,
            } = getInitialFilters;
            this.setState({
              menus,
              more,
              sources,
              chips,
              filteredObjects,
              constraintsObjects,
              withoutConstraintsObjects,
            });
          } else {
            this.setState({ withoutConstraintsObjects: object }, () => {
              this.updateFilteredObjects();
            });
          }
        }
      });
    }, params);
  };

  deleteChip = (update, index) => {
    window.scrollTo(0, 0);
    let { menus, more, keywords, chips, subject } = this.state;

    if (update.keyword) {
      keywords = keywords.filter(
        (objKeyword) => objKeyword.name !== update.name
      );
    } else if (update.menu) {
      menus.find(unselectItem);
    } else if (update.more) {
      more.find(unselectItem);
    } else if (update.minimumSalary) {
      if (!!subject.tempSalaryMin) {
        subject.tempSalaryMin = 0;
      }
      if (!!subject.tempMinimumSalary) {
        subject.tempMinimumSalary = 0;
      }
    } else if (update.minimumXp) {
      if (!!subject.tempMinYearsOfExperience) {
        subject.tempMinYearsOfExperience = 0;
      }
      if (!!subject.tempMinimumExperience) {
        subject.tempMinimumExperience = 0;
      }
    } else if (update.minimumCompanySize) {
      if (!!subject.tempMinimumCompanySize) {
        subject.tempMinimumCompanySize = 0;
      }
    }

    function unselectItem(menu) {
      return Object.keys(menu.items).find((name) => {
        if (
          update.name === "Introduced:Active" &&
          name === "Active" &&
          menu.label === "Introduced"
        ) {
          menu.items[name] = false;
          return true;
        } else if (
          update.name === "State:Active" &&
          name === "Active" &&
          menu.label === "State"
        ) {
          menu.items[name] = false;
          return true;
        } else if (name === update.name) {
          menu.items[name] = false;
          return true;
        }
        return false;
      });
    }

    chips.splice(index, 1);

    this.setState(
      {
        keywords,
        menus,
        chips,
        subject,
      },
      () => {
        this.updateFilteredObjects();
      }
    );
  };

  updateKeywordChip = (update) => {
    let { keywords, chips } = this.state;

    keywords.push(update);
    chips.push(update);
    this.setState(
      {
        keywords,
        chips,
      },
      () => {
        this.updateFilteredObjects();
      }
    );
  };

  updateSliderValues = (key, update) => {
    let { subject, menus, more, keywords } = this.state;
    subject = {
      ...subject,
      [key]: update,
    };

    const chips = FilterControl.setChipsOnMoreFilters(
      subject,
      menus,
      more,
      keywords,
      this.state.getChips
    );

    this.setState(
      {
        subject,
        [key]: update,
        chips,
      },
      () => {
        this.updateFilteredObjects();
      }
    );
  };

  onChangeMenusHandler =
    (subject) =>
      (
        label,
        menuKey,
        checked,
        fields = [],
        cb,
        shouldUpdateFilteredObjects = true
      ) => {
        const filters = FilterControl.setSearchMenuOnChange(
          subject,
          label,
          menuKey,
          checked,
          fields,
          cb,
          this.state.menus,
          this.state.more,
          this.state.keywords,
          this.state.getChips
        );

        const { menus, more, chips } = filters;

        this.setState(
          {
            menus,
            more,
            chips,
          },
          () => {
            if (shouldUpdateFilteredObjects) {
              this.updateFilteredObjects();
            }
          }
        );
      };

  updateFilteredObjects = () => {
    const {
      subject,
      keywords,
      menus,
      more,
      filterObjects,
      withoutConstraintsObjects,
      constraintsObjects,
    } = this.state;
    const { params } = this.props;
    const filteredObjects = filterObjects(
      subject,
      withoutConstraintsObjects,
      keywords,
      menus,
      more,
      Object(params).selected,
      constraintsObjects
    );
    this.setState({ filteredObjects, doneMLScore: false });
  };

  handlerSelectJob = (selectedObject) => {
    if (!!selectedObject) {
      let obj =
        this.state.object.find((job) => selectedObject.id === job.id) ||
        selectedObject;
      !!obj && this.setState({ selectedObject: obj });
    }
  };

  handlerOnSelectBox = (job, jobStatus = true) => {
    let { multiSelectedObjects } = this.state;

    if (jobStatus) multiSelectedObjects.push(job);
    else
      multiSelectedObjects = multiSelectedObjects.filter(
        (allJobs) => allJobs.id !== job.id
      );

    this.setState({ multiSelectedObjects });
  };

  jobHasEngagement = (job) => {
    let { engagements, sourceKey } = this.state;
    return !!engagements.find((eng) => eng[`${sourceKey}Id`] === job.id);
  };

  isJobEngageable = (job) => {
    const {
      annotatorMode,
      getObjectConstraints,
      getObjectBlackListed,
      subject,
    } = this.state;
    return (
      !this.jobHasEngagement(job) &&
      !getObjectBlackListed(subject, job) &&
      !getObjectConstraints(subject, job, "jobsDeclined") &&
      !annotatorMode
    );
  };

  afterJobMatch = (object, engagement) => {
    const { sourceKey } = this.state;
    engagement[sourceKey] = object;
    const { engagements } = this.state;
    let updatedEngagements = [...engagements, engagement];
    this.handlerOnSelectBox(object, false);
    this.loadSubject();
    this.setState({ engagements: updatedEngagements });
  };

  createSingleEngagementWithDis = (job, model = {}, cb) => {
    let { subject } = this.state;
    let employer = job.employer;
    const createDis = (model) => (engagement) => {
      const matchStrengthLabel =
        /w - 10x10/i.test(Object(engagement).status) &&
          /detail/i.test(Object(engagement).status)
          ? "TBD"
          : model.matchStrengthLabel;
      this.createDisagreement({
        ...model,
        matchStrength: matchStrengthLabel,
        candidate: subject,
        employer,
        job,
        engagement,
      });
    };

    this.createSingleEngagement(job, model, (engagement) => {
      createDis(model)(engagement);
      !!cb && cb(engagement);
    });
  };

  createSingleEngagement = (job, model, cb) => {
    let { subject, engagements, makeEngagement, sourceKey } = this.state;
    const existingEngagement = engagements.find(
      (eng) => eng[`${sourceKey}Id`] === job.id
    );
    if (this.isJobEngageable(job)) {
      const candidate = subject;
      const engModel = makeEngagement(candidate, job);
      Engagement.match(
        { candidate: engModel.candidate, job: engModel.job, ...model },
        (engagement) => {
          this.afterJobMatch(job, engagement);
          !!cb && cb(engagement);
        },
        (error) => {
          Core.showFailure(error);
        }
      );
    } else {
      !!cb && cb(existingEngagement);
    }
  };

  createDisagreement = ({
    matchStrength,
    candidate,
    employer,
    job,
    engagement,
    shouldTag,
    reviewed,
    shouldNotTag,
    whyNoPrivateNote,
    whyNeedToReadCV,
    whyNoCategories,
    whyNoDetails,
    whyNoFieldsValues,
    cb,
  }) => {
    const { disEngagementModel } = this.state;
    // alert(matchStrength);
    let disagreementObj =
      !!disEngagementModel &&
      disEngagementModel({
        matchStrength,
        candidate,
        employer,
        reviewed,
        job,
        engagement,
        shouldTag,
        shouldNotTag,
        whyNoPrivateNote,
        whyNeedToReadCV,
        whyNoCategories,
        whyNoDetails,
        whyNoFieldsValues,
      });

    const { multiSelectedObjects } = this.state;
    if (!!multiSelectedObjects && !!multiSelectedObjects.length > 0) {
      this.setState({ multiSelectedObjects: [] });
    }

    EngagementDisagreement.findOrCreate(
      disagreementObj,
      () => {
        !!cb && cb();
        alert(`Added entry to ML Table with match Strength ${matchStrength}`);
        this.sendEmailOnAnnotatorAndMatcherConflict({
          engagement,
          matchStrength,
          disagreementObj,
          candidate,
          job,
        });
      },
      () => {
        Core.failure({
          source: 'Shared/Match/MainV3.js(component)>createDisagreement',
          exception: 'failed to create disagreement',
          params: { disagreementObj },
          options: { level: 'info' },
        });
      }
    );
  };

  sendEmailOnAnnotatorAndMatcherConflict = ({
    engagement,
    matchStrength,
    disagreementObj,
  }) => {
    const { annotatorMode } = this.state;
    if (!!annotatorMode) {
      const getMatcherEmail = !!engagement
        ? engagement.matchedByWho.email
        : null;
      const getMatcherMatchStrength = !!engagement
        ? Definition.getLabel(
          "engagementMatchStrength",
          engagement.matchStrength
        )
        : null;
      const newMatchStrength = matchStrength;

      EngagementDisagreement.get(
        (resp) => {
          let result = {};
          let oldNotEqualsNew = false;

          if (resp.length > 1) {
            for (let i = 0; i < resp.length; i++) {
              let target = resp[i];

              result = getMisMatchThumbsAction.getMisMatch({
                engagement,
                annotatorMatchStrength: newMatchStrength,
                oldMatchStrength: target.matchDecision,
                checkOldState: false,
              });

              if (result.newMS !== result.oldMS) {
                oldNotEqualsNew = true;
                break;
              }
            }
          } else {
            let oldMatchStrength = !!engagement
              ? engagement.matchStrength
              : null;

            result = getMisMatchThumbsAction.getMisMatch({
              engagement,
              annotatorMatchStrength: newMatchStrength,
              oldMatchStrength,
              checkOldState: true,
            });

            if (result.newMS !== result.oldMS) {
              oldNotEqualsNew = true;
            }
          }

          if ((!!engagement || resp.length > 1) && oldNotEqualsNew) {
            annotatorActions.sendAnnotatorEmail({
              annotatorMatchStrength: newMatchStrength,
              matcherMatchStrength: getMatcherMatchStrength,
              matcherEmail: getMatcherEmail,
              disagreementObj,
              engagement,
            });
          }
        },
        {
          where: {
            and: [
              { candidateId: disagreementObj.candidateId },
              { jobId: disagreementObj.jobId },
              { annotator: { neq: "ML" } },
            ],
          },
        }
      );
    }
  };

  finalSelectedJobs = () => {
    let { multiSelectedObjects, selectedObject } = this.state;
    let jobForMatch = !!multiSelectedObjects.length
      ? multiSelectedObjects
      : [selectedObject];
    return jobForMatch;
  };

  handlerMatchJob =
    ({ stage, status, matchStrength, matchStrengthLabel }, cb) =>
      () => {
        let jobForMatch = this.finalSelectedJobs();
        jobForMatch.forEach((j) => {
          this.createSingleEngagementWithDis(
            j,
            { stage, status, matchStrength, matchStrengthLabel },
            cb
          );
        });
      };

  pushEntityToCandidate = (putDownJobs) => {
    const { subject, putDownCallback } = this.state;
    this.setState(
      {
        subject: {
          ...subject,
          putDownJobs,
        },
      },
      () => {
        !!putDownCallback && !!putDownCallback(subject.id, putDownJobs);
      }
    );
  };

  hasJobEngageable = () => {
    return !!this.finalSelectedJobs().filter((job) => this.isJobEngageable(job))
      .length;
  };

  afterUpdateEngagement = (eng) => {
    const { engagements } = this.state;
    const existingState = engagements.filter((engg) => engg.id === eng.id);
    const withoutEng = engagements.filter((engg) => engg.id !== eng.id);

    this.setState({
      engagements: [
        ...withoutEng,
        {
          ...existingState,
          ...eng,
        },
      ],
    });
  };

  handlerSkippedJob = (job) => {
    this.setState({ skippedObjects: [...this.state.skippedObjects, job] });
  };

  updateHandlerAnnotatorMode = (annotatorMode) => {
    this.setState({ annotatorMode });
  };

  updateObject = (object) => {
    const { selectedObject } = this.state;
    const updateSelectedJob = !!selectedObject
      ? object.find((obj) => obj.id === selectedObject.id)
      : {};
    this.setState({
      object,
      selectedObject: updateSelectedJob,
    });
  };

  updateEngagement = (engagement, field) => {
    if (Core.isAdminOrCoordinator()) {
      if (engagement) {
        Engagement.update(
          engagement,
          field,
          (res) => { },
          () => alert("failed to save")
        );
      }
    }
  };

  handlerDoneMLScore = (value) => {
    console.log("ml handled ----- ");
    this.setState({ doneMLScore: value });
  };

  moveToApprovedChips = (candidate, data) => {
    Candidate.update(
      candidate.id,
      data,
      () => {
        Core.showMessage("Saved Successfully!");
      },
      () => alert("something went wrong, check network")
    );
  };

  handlerToggleShouldShowBlacklisted = (value) => {
    this.setState({ shouldShowBlacklisted: value });
  };

  render() {
    const {
      subject,
      object,
      engagements,
      skippedObjects,
      multiSelectedObjects,
      filteredObjects,
      selectedObject,
      sourceKey,
      annotatorMode,
      getObjectLabels,
      getObjectConstraints,
      getObjectBlackListed,
      doneMLScore,
      isFetchingObjectList,
      // shouldShowBlacklisted,
    } = this.state;
    const {
      sourcePipe,
      objectPipe,
      objectListCard,
      objectListCardEng,
    } = this.props.source;
    let { params } = this.props;

    console.log({ subject: subject, object: object });

    // console.debug('state', this.state);

    return (
      <div className="strategy-main">
        <Appbar
          subject={subject}
          selectedObject={selectedObject}
          sourceKey={sourceKey}
          {...this.props}
        />

        <Grid container>
          {/** */}
          <Grid item xs={12}>
            {subject && (
              <ReactTable
                {...this.props} // to pass the router context
                config={!!this.props.location.pathname.match(/candidate\/matchNew/)
                  ? configJobs
                  : configMatchCandidates
                }
                onChange={data => {
                  this.setState({
                    object: data,
                    filteredObjects: data,
                    isFetchingObjectList: false,
                    doneMLScore: false
                  });
                }}
                presets={(() => {
                  let presets = {
                    state: STATE_ACTIVE,
                    inOfficeRemoteFlags: subject.inOfficeRemoteFlags,
                    candidateLocations: subject.candidateLocations,
                    officeLocations: subject.officeLocations,
                    roles: subject.roles,
                    desiredEmploymentTypes: subject.desiredEmploymentTypes,
                    looseMatch: false,
                    blacklisted: false,
                  };
                  if (!!this.props.location.pathname.match(/candidate\/matchNew/)) {
                    mapCandidateVisaToJobPresets({
                      candidate: subject,
                      presets,
                    });
                    presets.salaryMax = subject.minimumSalary;
                    presets.minYearsOfExperience = subject.yearsOfExperience;
                  }
                  else if (!!this.props.location.pathname.match(/job\/matchNew/)) {
                    mapJobVisaToCandidatePresets({
                      job: subject,
                      presets
                    });
                    presets.minimumSalary = subject.salaryMax;
                    presets.yearsOfExperience = subject.minYearsOfExperience;
                  }
                  return presets;
                })()}
                subject={subject}
                disableBodyRender
              />
            )}
          </Grid>
          {/** */}
          <Grid item xs={12}>
            {!!multiSelectedObjects.length && (
              <p>Selected Job {multiSelectedObjects.length}</p>
            )}
            {/** @todo deprecated | to cleanup | 2021-10-06 Wed µ */}
            {/** * /}
            <div className="d-none">
              <NewFilterControl
                updateFilteredObjects={this.updateFilteredObjects}
                handlerToggleShouldShowBlacklisted={
                  this.handlerToggleShouldShowBlacklisted
                }
                menus={this.state.menus}
                more={this.state.more}
                sources={this.state.sources}
                chips={this.state.chips}
                updateKeywordChip={this.updateKeywordChip}
                onChangeMenusHandler={this.onChangeMenusHandler(subject)}
                deleteChip={this.deleteChip}
                minimumSalary={
                  !!this.state.subject && !!this.state.subject.tempMinimumSalary
                    ? this.state.subject.tempMinimumSalary
                    : 0
                }
                minimumExperience={
                  !!this.state.subject &&
                    !!this.state.subject.tempMinimumExperience
                    ? this.state.subject.tempMinimumExperience
                    : 0
                }
                minimumCompanySize={
                  !!this.state.subject &&
                    !!this.state.subject.tempMinimumCompanySize
                    ? this.state.subject.tempMinimumCompanySize
                    : 0
                }
                salaryPopup={this.props.source.salaryPopup}
                experiencePopup={this.props.source.experiencePopup}
                companySizePopup={this.props.source.companySizePopup}
                updateSliderValues={this.updateSliderValues}
                sourceKey={sourceKey}
                shouldShowBlacklisted={shouldShowBlacklisted}
                fetchObjectList={this.fetchObjectList}
              />
            </div>
            {/** */}
          </Grid>
          <Grid container item xs={12} sm={9}>
            <Grid item xs={12} sm={6}>
              {!!subject &&
                //!!object.length &&
                !!sourcePipe &&
                sourcePipe(subject, selectedObject, this.moveToApprovedChips)}
            </Grid>

            <Grid item xs={12} sm={6}>
              {!!subject &&
                //!!object.length &&
                !!objectPipe &&
                objectPipe(
                  selectedObject,
                  this.updateObject,
                  this.moveToApprovedChips
                )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ObjectListPipe
              jobs={filteredObjects}
              shouldShowBlacklisted={this.props.location.search.includes('blacklisted=true')}
              engagements={engagements}
              // fetchObjectList={this.fetchObjectList}
              isFetchingObjectList={isFetchingObjectList}
              candidate={subject}
              selectedJob={selectedObject}
              skippedJobs={skippedObjects}
              multiSelectedJobs={multiSelectedObjects}
              params={params}
              handlerSelectJob={this.handlerSelectJob}
              handlerOnSelectBox={this.handlerOnSelectBox}
              handlerMatchJob={this.handlerMatchJob}
              createSingleEngagement={this.createSingleEngagement}
              createSingleEngagementWithDis={this.createSingleEngagementWithDis}
              createDisagreement={this.createDisagreement}
              pushEntityToCandidate={this.pushEntityToCandidate}
              hasJobEngageable={this.hasJobEngageable}
              isJobEngageable={this.isJobEngageable}
              afterUpdateEngagement={this.afterUpdateEngagement}
              handlerSkippedJob={this.handlerSkippedJob}
              objectListCard={objectListCard}
              objectListCardEng={objectListCardEng}
              updateHandlerAnnotatorMode={this.updateHandlerAnnotatorMode}
              annotatorMode={annotatorMode}
              sourceKey={sourceKey}
              updateEngagement={this.updateEngagement}
              strategyGetLabels={getObjectLabels}
              strategyGetConstraints={getObjectConstraints}
              strategyGetBlackListed={getObjectBlackListed}
              doneMLScore={doneMLScore}
              handlerDoneMLScore={this.handlerDoneMLScore}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Main;
