import DialogContent from "@mui/material/DialogContent";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import Snackbar from "material-ui/Snackbar";
import queryString from "query-string";
import React, { Component, Fragment } from "react";
import Core from "../../lib/Core";
import Engagement from "../../lib/Engagement";
import { listTab, listTabs } from "../../lib/models/engagement";
import Store from "../../lib/Store";
import Streak from "../../lib/Streak";
import onReady from "../../lib/tools/onReady";
import { configEngagements } from "../Home/configEngagements";
import ReactTable from "../Home/ReactTable";
import List from "../List/List";
import CalendarEvent from "./CalendarEvent";
import EngagementCard from "./Card/EngagementCard";

class Engagements extends Component {
  data;
  constructor() {
    super(...arguments);
    this.state = {
      snackBarMessage: "",
      snackBarOpen: false,
      showActives: true,
      showInactives: false,
      right: true,
      selected: [],
      wantBulkUpdate: false,
      processingBulkUpdate: false,
      bulkUpdateResponse: [],
      engagements: [],
    };
    this._setReducedFilters = true;
    Store.set("path", window.location.href);
    this.reloadData = (ev) => this.loadData();
    this.loadData();
  }

  loadData() {
    let params = queryString.parse(this.props.location.search);
    const where = this.props.match.params.id
      ? { id: this.props.match.params.id }
      : this.state.showActives && this.state.showInactives
        ? {}
        : this.state.showActives
          ? { state: "Open" }
          : this.state.showInactives
            ? { state: "Closed" }
            : { state: "undefined" };
    where.rejectionReason = {};
    where.rejectionReason["neq"] = "10x10 - No Match";
    const include = [
      {
        relation: "engagementStarreds",
        scope: {
          where: {
            accountId: Core.getUserId(),
          },
        },
      },
      {
        relation: "candidate",
        scope: {
          fields: [
            "id",
            "accountId",
            "firstName",
            "lastName",
            "introduced",
            "platformRating",
            "email",
            "phone",
            "resumes",
            "resumeTxtUrl",
            "gitHubURL",
            "linkedInURL",
          ],
          include: [
            {
              relation: "account",
              scope: {
                fields: [
                  "firstName",
                  "lastName",
                  "companyName",
                  "email",
                  "role",
                ],
              },
            },
          ],
        },
      },
      {
        relation: "job",
        scope: {
          fields: [
            "jobTitle",
            "employerId",
            "id",
            "role",
            "email",
            "emailsList",
            "addressCity",
            "jobDescription",
          ],
          include: [
            {
              relation: "employer",
              scope: {
                fields: [
                  "name",
                  "proxyName",
                  "email",
                  "url",
                  "totalFunding",
                  "stage",
                  "technicalSkills",
                  "employeeCount",
                  "crunchBaseUrl",
                  "additionalUrl",
                  "tagline",
                  "product",
                  "teamCount",
                  "expectedResumeResponseTime",
                  "expectedSchedulingResponseTime",
                  "expectedScreenResponseTime",
                  "expectedOnsiteResponseTime",
                ],
              },
            },
          ],
        },
      },
      "actionOwner",
    ];

    let filters = { ...params };

    if (params.perf || this._setReducedFilters) {
      filters = { ...params, include };
    }

    Engagement.getWhere(
      where,
      (engagements) => {
        this.setState({ engagements });
        if (!!this.props.match.params.id) {
          onReady(this, "List").then((em) => {
            const engs = engagements
              .filter((eng) => eng.id === this.props.match.params.id)
              .map((eng) => {
                eng.expanded = true;
                return eng;
              });
            this.List.setItems(engs);
          });
        } else {
          onReady(this, "FilterControl").then((em) => {
            // this.FilterControl.setItems(engagements);
          });
        }
      },
      filters
    );
  }

  updateField = (engagement, update, callback = () => { }) => {
    const { selected } = this.state;

    if (/end/i.test(update.stage)) {
      update.open = false;
    }

    if (selected.length) {

      // This is the bulk operation flow | 2021-08-16 Mon µ
      selected.forEach((card) => {
        const { state: engagement } = card;
        Engagement.update(
          engagement,
          update,
          response => {

            engagement.checked = true;

            this.List.updateItem({ ...engagement, ...update }
              //, { reload: true }
            );

            // story-3778-m2 | 2021-08-13 Fri µ
            // The reload is not re-rendering the card component for that we must update the card state.
            card.setState({ ...engagement, ...update });

          },
          failure => Core.showMessage(`failed to update engagement with id = ${engagement.id}`)
        );
      });

    } else {

      Engagement.update(
        engagement,
        update,
        response => callback(response),
        failure => Core.showMessage(`failed to update engagement with id = ${engagement.id}`)
      );

    }

  };

  showMessage = (msg) => {
    this.setState({
      snackBarMessage: msg,
      snackBarOpen: true,
    });
  };
  hideMessage = () => {
    this.setState({
      snackBarMessage: "",
      snackBarOpen: false,
    });
  };

  toggleDrawer = (side, open) => (em) => {
    this.setState({
      [side]: open,
    });
  };

  handlerBulkUpdate = () => {
    let {
      selectedStatusForBulkUpdate,
      selected,
      engagements,
      selectedStatusForRejectionReason,
    } = this.state;

    if (!selectedStatusForBulkUpdate) {
      alert("you didn't pick any status");
      return;
    }

    if (!selected.length) {
      alert("no engagements are selected, please select atleast 1 engagement");
      return;
    }

    if (window.confirm("Are you sure")) {
      this.setState(
        { processingBulkUpdate: true, bulkUpdateResponse: [] },
        () => {
          let payload = selected.map((card) => {

            const { state: engagement } = card;

            let p = {
              id: engagement.id,
              status: selectedStatusForBulkUpdate
            };

            if (!!selectedStatusForRejectionReason) {
              p["rejectionReason"] = selectedStatusForRejectionReason;
            }

            return p;

          });

          Engagement.updateMany(
            payload,
            (response) => {
              let bulkUpdateResponse = response.res;

              bulkUpdateResponse.forEach((resEng) => {
                this.updateEngagementInArray(
                  engagements,
                  resEng.id,
                  Object(payload.find((eng) => eng.id === resEng.id))
                );
              });

              this.setState(
                {
                  engagements,
                  processingBulkUpdate: false,
                  bulkUpdateResponse,
                },
                () => {
                  //this.FilterControl.setItems(engagements);
                }
              );

              this.setState({ wantBulkUpdate: false });
            },
            () => {
              this.setState({ processingBulkUpdate: false });
              alert("something went wrong");
            }
          );
        }
      );
    }
  };

  updateEngagementInArray = (engagements, engId, data) => {
    engagements.forEach((engagement) => {
      if (engagement.id === engId) {
        Object.keys(data).forEach((attr) => {
          engagement[attr] = data[attr];
        });
      }
    });

    return engagements;
  };

  render() {
    let {
      selected,
      selectedStatusForBulkUpdate,
      processingBulkUpdate,
      bulkUpdateResponse,
      selectedStatusForRejectionReason,
    } = this.state;

    return (
      <Fragment>
        {/** * /}
        {!this.props.match.params.id && (
          <FilterControl
            ref={(FilterControl) => (this.FilterControl = FilterControl)}
            parent={this}
            menus={Engagement.menus}
            more={Engagement.more}
            onChange={(filtered) => this.List.setItems(filtered)}
            toolBarLeft={
              Core.isAdminOrCoordinator() &&
              !this.props.match.params.id && (
                <div className="">
                  <Checkbox
                    label="Active Engagements"
                    className="show-all"
                    checked={this.state.showActives}
                    onCheck={(ev, showActives) => {
                      this.List && this.List.restart();
                      this.setState({ showActives }, (then) => this.loadData());
                    }}
                  />
                  <Checkbox
                    label="Inactive Engagements"
                    className="show-all"
                    checked={this.state.showInactives}
                    onCheck={(ev, showInactives) => {
                      this.List && this.List.restart();
                      this.setState({ showInactives }, (then) =>
                        this.loadData()
                      );
                    }}
                  />
                </div>
              )
            }
            toolBarRight={
              <Fragment>
                {!!(this.state.selected || []).length && false && (
                  <FlatButton
                    label={`Update  ( ${this.state.selected.length} ) Engagements`}
                    className="list-add-new"
                    onClick={() => this.setState({ wantBulkUpdate: true })}
                  />
                )}
              </Fragment>
            }
          />
        )}
        {/** */}
        <div style={{ height: 'auto' }}>
          <ReactTable
            {...this.props} // to pass the router context
            config={configEngagements}
            onChange={data => {
              this.List.setItems(data);
            }}
            disableBodyRender
          />
        </div>
        <List
          ref={(List) => (this.List = List)}
          tabs={listTabs}
          tab={listTab}
          disableTabs={true}
          name="Engagement"
          card={EngagementCard}
          parent={this}
          onCheck={(id, checked, card) => {
            let selected = [...this.state.selected];

            if (!checked) {
              selected = selected.filter(card => card.state.id !== id);
            } else {
              selected.push(card);
            }

            let engagements = this.updateEngagementInArray(
              this.state.engagements,
              id,
              { checked }
            );

            this.setState({ selected, engagements });
          }}
        />
        <CalendarEvent
          ref={(self) => (this.CalendarEvent = self)}
          parent={this}
        />
        <Snackbar
          open={this.state.snackBarOpen}
          message={this.state.snackBarMessage}
          className="snack-bar"
          onRequestClose={this.hideMessage}
        />

        <Dialog
          title="Update Status"
          actions={[
            <FlatButton
              label="Cancel"
              primary={true}
              onClick={() => this.setState({ wantBulkUpdate: false })}
            />,
            <FlatButton
              label="Submit"
              primary={true}
              onClick={() => this.handlerBulkUpdate()}
            />,
          ]}
          contentStyle={{ width: 800 }}
          open={this.state.wantBulkUpdate}
          scroll={"body"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          {!processingBulkUpdate && (
            <DialogContent>
              You are going to update these ( {selected.length} ) engagements:
              {Array.isArray(selected) &&
                selected.map((card, index) => {
                  const { state: engagement } = card;
                  return (
                    <p key={`engagements-selected-${engagement.id}`}>
                      {!!bulkUpdateResponse.length ? (
                        bulkUpdateResponse
                          .map((e) => e.id)
                          .includes(engagement.id) ? (
                          <CheckIcon color={"primary"} />
                        ) : (
                          <CancelIcon color={"secondary"} />
                        )
                      ) : (
                        ""
                      )}
                      <strong style={{ fontSize: "12px" }}>
                        - {engagement._name} => {engagement._jobTag}
                      </strong>{" "}
                      &nbsp;&nbsp;
                      <span
                        className="anchor"
                        onClick={() => {
                          let engagements = this.updateEngagementInArray(
                            this.state.engagements,
                            engagement.id,
                            { checked: false }
                          );
                          this.setState(
                            {
                              engagements,
                              selected: selected.filter(
                                selectedEng =>
                                  (selectedEng.id !== engagement.id)
                              ),
                            },
                            () => {
                              // this.FilterControl.setItems(engagements);
                            }
                          );
                        }}
                      >
                        remove
                      </span>
                    </p>
                  );
                })}
              <div className="">
                Please pick Status from dropdown:&nbsp;&nbsp;
                <SelectField
                  name="status"
                  value={selectedStatusForBulkUpdate}
                  onChange={(event, index, selectedStatusForBulkUpdate) => {
                    this.setState({
                      selectedStatusForBulkUpdate,
                      bulkUpdateResponse: [],
                    });
                  }}
                >
                  {Streak.getDropdownStrings("Status")
                    .sort((a, b) => a.localeCompare(b))
                    .map((status) => (
                      <MenuItem
                        value={status}
                        key={status}
                        primaryText={status}
                      />
                    ))}
                </SelectField>
                <div className="Modal-block">
                  Rejection Reason
                  <SelectField
                    name="rejectionReason"
                    value={selectedStatusForRejectionReason}
                    onChange={(
                      event,
                      index,
                      selectedStatusForRejectionReason
                    ) => {
                      this.setState({
                        selectedStatusForRejectionReason,
                        bulkUpdateResponse: [],
                      });
                    }}
                  >
                    {Streak.getDropdownStrings("RejectionReason")
                      .sort((a, b) => a.localeCompare(b))
                      .map((reason) => (
                        <MenuItem
                          value={reason}
                          key={reason}
                          primaryText={reason}
                        />
                      ))}
                  </SelectField>
                </div>
              </div>
            </DialogContent>
          )}

          {!!processingBulkUpdate && (
            <Fragment>
              <h2>processing...</h2>
            </Fragment>
          )}
        </Dialog>
      </Fragment>
    );
  }
}
export default Engagements;
