import { Button } from "@mui/material";
import {
  DatePicker, List,
  ListItem,
  MenuItem, RadioButton,
  RadioButtonGroup,
  SelectField,
  TextField,
  Toggle
} from "material-ui";
import React, { Component, Fragment } from "react";
import Candidate from "../../../lib/Candidate";
import Core, { colors } from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import { getGithubPositiveSignalLabel } from "../../../lib/services/GitHub/githubGraphqlGetUser";
import cleanHTML from "../../../lib/tools/cleanHtml";
import Chips from "../../Forms/Chips";
import Col from "../../Forms/Col";
import RichTextBox from "../../Forms/RichTextBox";
import Row from "../../Forms/Row";
import styles from "../../Forms/Styles";
import MatchExclusionFieldset from "./MatchExclusionFieldset";

class Admin extends Component {
  state = {
    githubLabel: ''
  };
  componentDidMount() {
    const parent = this.props.parent;

    getGithubPositiveSignalLabel(parent.state.gitHubURL, (id) => {
      if (!!id) {
        const currentLabelIds = parent.state.positiveSignals;

        if (!currentLabelIds.map(el => +el).includes(+id)) {
          parent.setStateStore({ positiveSignals: [...currentLabelIds, id] })
        }

        const label = Definition.getLabel('positiveSignals', id);
        if (label) {
          this.setState({ githubLabel: label });
        }
      }
    })
  }

  render() {
    const CandidateEditController = this.props.parent;
    const { githubLabel } = this.state;
    const lineStyle = { borderColor: "#59736f" };
    const areOpenEngagements = CandidateEditController.state.engagements.find(
      eng => eng.state === "Open"
    );

    return (
      <List>
        <ListItem className="form-header" primaryText="ADMIN" disabled={true} />
        <Row>
          <Col fullWidth>
            <label>Conversation history</label>
            <TextField
              name="publicNotes"
              textareaStyle={styles.TextField.inputStyle}
              underlineStyle={styles.TextField.underlineStyle}
              style={styles.TextField}
              rows={6}
              rowsMax={12}
              multiLine={true}
              value={CandidateEditController.state.publicNotes}
              onChange={(ev, publicNotes) => CandidateEditController.setStateStore({ publicNotes, key: 'publicNotes' })}
              fullWidth
            />
          </Col>
        </Row>
        {Core.isAdminOrCoordinator() && (
          <div
            className="green-block py-1 rounded"
            style={{ backgroundColor: colors.greenBlock }}
          >
            <Row>
              <Col fullWidth>
                <h4>For 10 by 10 use only</h4>
              </Col>
            </Row>
            {CandidateEditController.state.initiallyIsDuplicate && (
              <Row>
                <Col>
                  <label>Duplicate Candidate</label>
                  <Toggle
                    className="purple-switch"
                    style={{ margin: "24px 0 16px" }}
                    toggled={CandidateEditController.state.isDuplicate}
                    onToggle={(event, isDuplicate) => {
                      CandidateEditController.setStateStore({ isDuplicate }, then => {
                        if (!CandidateEditController.state.warnedDuplicateDetectionOff) {
                          CandidateEditController.setStateStore({
                            warnedDuplicateDetectionOff: true,
                            key: 'warnedDuplicateDetectionOff',
                            snackbar:
                              { msg: "WARNING, duplicate detection is off.  Re-edit to enable duplicate detection once save as not a duplicate.", type: 'warning' }
                          });
                        }
                      });
                    }}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <label>Recruiter</label>
                <SelectField
                  name="recruiter"
                  underlineFocusStyle={lineStyle}
                  value={CandidateEditController.state.accountId || Core.getUserId()}
                  maxHeight={640}
                  onChange={(event, index, accountId) => {
                    const recruiter = CandidateEditController.state.recruiters.find(
                      recruiter => recruiter.id === accountId
                    );
                    CandidateEditController.setStateStore({
                      accountId,
                      key: 'accountId',
                      recruiter
                    });
                  }}
                  fullWidth
                >
                  {CandidateEditController.state.recruiters
                    .sort((a, b) => {
                      a = String(a.firstName || a.email).toLowerCase();
                      b = String(b.firstName || b.email).toLowerCase();
                      return a > b ? 1 : a < b ? -1 : 0;
                    })
                    .map(item => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        primaryText={`${item.firstName} ${item.lastName
                          } - ${item.companyName || item.email}`}
                      />
                    ))}
                </SelectField>
              </Col>
            </Row>
            <Row>
              <Col fullWidth>
                <Chips
                  name="positiveSignals"
                  label={
                    <Fragment>Positive signals<br />
                      <p>
                        Suggested From Linkedin : ( {Definition.getLabels('positiveSignals', CandidateEditController.state.linkedInSuggestedPosSignals)
                          .map(el => el).filter(el => !!el).join(', ')} )
                      </p>

                      {!!githubLabel && <p>
                        Suggested From Github : ( {githubLabel} )
                      </p>
                      }
                    </Fragment>}
                  values={CandidateEditController.state.positiveSignals}
                  onChange={positiveSignals => {
                    const crossedPositiveSignals = (CandidateEditController.state.crossedPositiveSignals || []).filter(crossed => !positiveSignals.includes(crossed));
                    CandidateEditController.setStateStore({ positiveSignals, key: 'positiveSignals', crossedPositiveSignals })
                  }
                  }
                  onRemoveChip={(chipId) => {
                    CandidateEditController.setStateStore({ crossedPositiveSignals: [...(CandidateEditController.state.crossedPositiveSignals || []), chipId] })
                  }}
                />
                <Chips
                  name="negativeSignals"
                  label="Negative signals"
                  values={CandidateEditController.state.negativeSignals}
                  onChange={negativeSignals =>
                    CandidateEditController.setStateStore({ negativeSignals, key: 'negativeSignals' })
                  }
                />

              </Col>
            </Row>
            <Row>
              <Col fullWidth>
                <div className="d-flex flex-align-left-center">
                  <label>Submission write wp to employer</label>
                  <Button
                    title={`Insert recruiter's full write up to the beginning of the text box below`}
                    startIcon={<i className="material-icons">content_paste_go</i>}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className="ml-auto text-truncate flex-align-left-center"
                    onClick={event => {
                      let original = String(CandidateEditController.state.submissionNotesToEmployer).replace('<p><br></p>', '').trim();
                      let insert = String(CandidateEditController.state.submissionNotes).replace('<p><br></p>', '').trim();
                      if(insert.length){
                        let newNotes = cleanHTML(`
                          ${insert}${original.length ? `<p>-----------</p>${original}` : ''}
                        `);
                        CandidateEditController.setStateStore({
                          submissionNotesToEmployer: newNotes
                        });
                      }
                      else{
                        Core.showMessage('Nothing to insert');
                      }
                    }}
                  >
                    Insert recruiter write up
                  </Button>
                </div>
                <RichTextBox
                  name="submissionNotesToEmployer"
                  value={CandidateEditController.state.submissionNotesToEmployer}
                  onChange={submissionNotesToEmployer => {
                    CandidateEditController.setStateStore({
                      submissionNotesToEmployer,
                      key: 'submissionNotesToEmployer'
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col fullWidth>
                <label>Tagline (Elevator pitch for your candidate)</label>
                <TextField
                  name="tagLine"
                  rows={1}
                  rowsMax={5}
                  multiLine={true}
                  value={CandidateEditController.state.tagLine}
                  onChange={(ev, tagLine) => CandidateEditController.setStateStore({ tagLine, key: 'tagLine' })}
                  underlineFocusStyle={lineStyle}
                  fullWidth
                />
              </Col>
            </Row>
            <Row>
              <Col fullWidth>
                <label className="w-auto">
                  Platform rating <b className="cred none">*</b>
                </label>
                {Candidate.calculatePlatformRating({
                  candidate: CandidateEditController.state,
                  component: true
                })}
                <RadioButtonGroup
                  name="platformRating"
                  className="radio-button-group"
                  valueSelected={(
                    CandidateEditController.state.platformRating
                      ? CandidateEditController.state.platformRating
                      : Candidate.calculatePlatformRating({ candidate: CandidateEditController.state })
                  )}
                  onChange={(event, platformRating) =>
                    CandidateEditController.setStateStore({ platformRating, errorPlatformRating: "", key: 'platformRating' })
                  }>
                  {Definition.get("platformRating").map(item => (
                    <RadioButton
                      key={item.id}
                      value={item.id}
                      label={item.label}
                    />
                  ))}
                </RadioButtonGroup>
                {CandidateEditController.state.errorPlatformRating && (
                  <span className="cred">
                    {CandidateEditController.state.errorPlatformRating}
                  </span>
                )}
              </Col>
            </Row>
            <Row>
              <Col fullWidth>
                <label>Platform rating notes</label>
                <RichTextBox
                  name="platformRatingNotes"
                  value={CandidateEditController.state.platformRatingNotes}
                  onChange={platformRatingNotes => {
                    CandidateEditController.setStateStore({
                      platformRatingNotes,
                      key: 'platformRatingNotes'
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col fullWidth>
                <label>Current state</label>
                <RadioButtonGroup
                  name="state"
                  className="radio-button-group"
                  valueSelected={CandidateEditController.state.state}
                  onChange={(event, state) => {
                    let holdDate = {};
                    if (
                      Definition.test("state", CandidateEditController.state.state, /Active/)
                    ) {
                      holdDate = { holdDate: null };
                    }
                    CandidateEditController.setStateStore({ state, ...holdDate, key: 'state' }, then => {
                      !!this.pickerHoldDate &&
                        this.pickerHoldDate.openDialog &&
                        this.pickerHoldDate.openDialog();
                      !!this.pickerWakeUpDate &&
                        this.pickerWakeUpDate.openDialog &&
                        this.pickerWakeUpDate.openDialog();
                    });
                  }}
                  fullWidth
                >
                  {Definition.get("state")
                    .filter(
                      tag =>
                        Core.isAdminOrCoordinator()
                          ? true
                          : Definition.test("state", tag.id, /draft/)
                    )
                    .map(tag => (
                      <RadioButton
                        key={tag.id}
                        value={tag.id}
                        label={tag.label}
                        style={{ marginBottom: 16 }}
                        disabled={
                          !!(
                            tag.label === "Hold" || // Temporaly disabled 2018-09-05
                            (tag.label === "Hold" && !areOpenEngagements) ||
                            (tag.label === "Inactive" && areOpenEngagements)
                          )
                        }
                      />
                    ))}
                </RadioButtonGroup>
              </Col>
            </Row>

            <MatchExclusionFieldset CandidateEditController={CandidateEditController} />

            <Row>
              <Col fullWidth>
                <label>
                  This recruiter has active representation
                </label>
                <span className="c-red f-12">If you change this value, please review all duplicate candidates to ensure there is only one owner</span>
                <RadioButtonGroup
                  name="isOwnedRightNow"
                  className="radio-button-group"
                  valueSelected={CandidateEditController.state.isOwnedRightNow}
                  onChange={(event, isOwnedRightNow) => {
                    CandidateEditController.setStateStore({ isOwnedRightNow })
                  }
                  }
                >
                  <RadioButton
                    key={'isOwnedRightNow-true'}
                    value={true}
                    label={'Yes'}
                  />
                  <RadioButton
                    key={'isOwnedRightNow-false'}
                    value={false}
                    label={'NO'}
                  />
                </RadioButtonGroup>

              </Col>
            </Row>

            {Definition.test("state", CandidateEditController.state.state, /Hold/) && (
              <Row>
                <Col>
                  <label>Hold date</label>
                  <DatePicker
                    name="holdDate"
                    ref={picker => (this.pickerHoldDate = picker)}
                    underlineFocusStyle={lineStyle}
                    okLabel="Set Hold Date"
                    value={
                      CandidateEditController.state.holdDate
                        ? new Date(CandidateEditController.state.holdDate)
                        : null
                    }
                    onChange={(ev, holdDate) =>
                      CandidateEditController.setStateStore({ holdDate: holdDate.toISOString(), key: 'holdDate' })
                    }
                  />
                </Col>
              </Row>
            )}
            {Definition.test("state", CandidateEditController.state.state, /Inactive/) && (
              <Row>
                <Col>
                  <label>Wake up date</label>
                  <DatePicker
                    name="wakeUpDate"
                    ref={picker => (this.pickerWakeUpDate = picker)}
                    underlineFocusStyle={lineStyle}
                    okLabel="Set Wake Up Date"
                    value={
                      CandidateEditController.state.wakeUpDate
                        ? new Date(CandidateEditController.state.wakeUpDate)
                        : null
                    }
                    onChange={(ev, wakeUpDate) =>
                      CandidateEditController.setStateStore({ wakeUpDate: wakeUpDate.toISOString(), key: 'wakeUpDate' })
                    }
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col fullWidth>
                <label>Private notes</label>
                <TextField
                  name="privateNotes"
                  textareaStyle={styles.TextField.inputStyle}
                  underlineStyle={styles.TextField.underlineStyle}
                  style={styles.TextField}
                  rows={6}
                  rowsMax={12}
                  multiLine={true}
                  value={CandidateEditController.state.privateNotes}
                  onChange={(ev, privateNotes) =>
                    CandidateEditController.setStateStore({ privateNotes, key: 'privateNotes' })
                  }
                  fullWidth
                />
              </Col>
            </Row>
          </div>
        )
        }
        <Row>
          <Col>
            <label>Introduced date</label>
            <DatePicker
              name="introduced"
              underlineFocusStyle={lineStyle}
              value={
                CandidateEditController.state.introduced
                  ? new Date(CandidateEditController.state.introduced)
                  : null
              }
              onChange={(ev, introduced) => {
                CandidateEditController.setStateStore({ introduced: introduced.toISOString(), key: 'introduced' });
              }}
              fullWidth
            />
          </Col>
          <Col>
            <label>Expiration date</label>
            <DatePicker
              name="expirationDate"
              underlineFocusStyle={lineStyle}
              value={
                CandidateEditController.state.expirationDate
                  ? new Date(CandidateEditController.state.expirationDate)
                  : null
              }
              onChange={(ev, expirationDate) => {
                CandidateEditController.setStateStore({
                  expirationDate: expirationDate.toISOString(),
                  key: 'expirationDate'
                });
              }}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Closed date</label>
            <DatePicker
              name="closeDate"
              underlineFocusStyle={lineStyle}
              value={
                CandidateEditController.state.closeDate ? new Date(CandidateEditController.state.closeDate) : null
              }
              onChange={(ev, closeDate) => {
                CandidateEditController.setStateStore({ closeDate: closeDate.toISOString(), key: 'closeDate' });
              }}
              fullWidth
            />
          </Col>
          <Col>
            <label>Last update</label>
            <DatePicker
              name="updatedAt"
              underlineFocusStyle={lineStyle}
              value={
                CandidateEditController.state.updatedAt ? new Date(CandidateEditController.state.updatedAt) : null
              }
              onChange={(ev, updatedAt) => {
                CandidateEditController.setStateStore({ updatedAt: updatedAt.toISOString(), key: 'updatedAt' });
              }}
              fullWidth
            />
          </Col>
        </Row>
      </List >
    );
  }
}

export default Admin;
