/** ============================================ µ
 * @Description Greenhouse Harvest [JS]
 *              Library
 * @createdAt   2021-03-16
 * @updatedAt   2021-05-12
/** =========================================== */

/* IMPORTS ==================================== */

import Greenhouse from './Greenhouse.lib';
import Core from "../../Core.js";
import tools from "../../GenericTools.lib";

/* METHODS ==================================== */

/**
 * Maps a Candidate model of 10x10
 * into a Candidate input for Greenhouse harvest
 *
 * @param {*} candidate Cando object based on 10x10 model
 * @param {integer} atsUserId The ID of the user creating the note
 * @param {integer} atsJobId The ID of the job you want to create an application to for this candidate
 * @param {integer} atsSourceId The ID of the source to be credited for this application
 * @param {*} submissionNote Object with submission notes
 * @param {string} submissionNote.public Public notes
 * @param {string} submissionNote.private Private notes
 * @returns {*} Object with results of mapping
 * @returns {*} results.candidate - Cando object based on GH post Input
 * @returns {[string]} results.failures - Array with texts of failures on mapping
 */
function mapCando10ToCandoGH({
  candidate,
  atsUserId: user_id,
  atsJobId: job_id,
  atsSourceId: source_id,
  atsReferrer = Greenhouse.DEFAULT_REFERRER_VALUE,
  submissionNotes,
}) {
  const {
    firstName: first_name,
    lastName: last_name,
    currentEmployer: company,
    currentTitle: title,
    phone,
    email,
    gitHubURL,
    linkedInURL,
    resumes = [],
  } = candidate;
  const results = {
    candidate: {
      first_name,
      last_name,
      company,
      title,
      phone_numbers: [],
      email_addresses: [],
      website_addresses: [],
      applications: [
        {
          job_id,
          source_id,
          referrer: {
            type: "outside",
            value: atsReferrer
          },
          attachments: [],
        }
      ],
      activity_feed_notes: []
    },
    failures: []
  };

  /**
   * µ NOTE be careful
   *
   * following conditionals are not equals than ingestion library
   */

  if (phone) {
    if (tools.validatePhone(phone)) {
      results.candidate.phone_numbers.push({
        value: phone, type: "home"
      });
    }
    else {
      results.failures.push(`Phone number must have at least 10 digits`);
      results.failures.push(`"${phone}"`);
    }
  }

  if (email) {
    if (tools.validateEmail(email)) {
      results.candidate.email_addresses.push({
        value: email, type: "personal"
      });
    }
    else {
      results.failures.push(`Email is badly formatted.`);
      results.failures.push(`"${email}"`);
    }
  }

  if (gitHubURL) {
    if (tools.validateURL(gitHubURL)) {
      results.candidate.website_addresses.push({
        value: gitHubURL, type: "personal"
      });
    }
    else {
      results.failures.push(`Website is badly formatted.`);
      results.failures.push(`"${gitHubURL}"`);
    }
  }

  if (linkedInURL) {
    if (tools.validateURL(linkedInURL)) {
      results.candidate.website_addresses.push({
        value: linkedInURL, type: "personal"
      });
    }
    else {
      results.failures.push(`Website is badly formatted.`);
      results.failures.push(`"${linkedInURL}"`);
    }
  }

  if (!!resumes.length) {
    const attachmentTypes = ["resume", "cover_letter", "admin_only"];
    resumes.forEach(resume => {
      const filename = tools.getFilenameFromURL(resume.url);
      if (!!filename && !!attachmentTypes.length) {
        results.candidate.applications[0].attachments.push({
          filename,
          /**
           * µ LOCALHOST PATCH
           * Greenhouse is no able
           * to get the resume file
           * from a localhost url,
           * for that reason we are using
           * a public domain test resume url
           */
          url: (
            Core.isLocalHost()
              ? Greenhouse.TEST_RESUME_URL
              : resume.url
          ),
          type: attachmentTypes.shift()
        });
      }
    });
  }

  if (!!submissionNotes.public) {
    results.candidate.activity_feed_notes.push({
      user_id,
      body: submissionNotes.public,
      visibility: "public"
    });
  }

  if (!!submissionNotes.private) {
    results.candidate.activity_feed_notes.push({
      user_id,
      body: submissionNotes.private,
      visibility: "private"
    });
  }

  /** µ SAMPLES of attachments vs resumes * /
    // Greenhouse
    "attachments": [
      {
        "filename": "John_Locke_Offer_Packet_09_27_2017.pdf",
        "url": "https://prod-heroku.s3.amazonaws.com/...",
        "type": "offer_packet"
      }
    ]
    // 10by10
    resumes:[
      {
        "url": "https://<10by10API>/.../download/MAR...537.pdf",
        "createdAt": "2021-02-16T18:35:38.929Z",
        "timestamp": 1613500538929
      }
    ]
  /** */

  /** µ FOR DEBUG PURPOSES */
  if (Core.isLocalHost()) {
    results.candidate.first_name = '_' + results.candidate.first_name;
    results.candidate.last_name = '_' + results.candidate.last_name;
  }
  /** */

  return results;
}

/**
 * Call POST a Candidate into Greenhouse
 *
 * @param {*} candidate Cando object based on 10x10 model
 * @param {string} atsApiKey The credential to use basic Auth
 * @param {string} atsOnBehalfOf ID of the user issuing this request. Required for auditing purposes.
 * @param {integer} atsUserId The ID of the user creating the note
 * @param {integer} atsJobId The ID of the job you want to create an application to for this candidate
 * @param {integer} atsSourceId The ID of the source to be credited for this application
 * @param {*} submissionNote Object with submission notes
 * @param {string} submissionNote.public Public notes
 * @param {string} submissionNote.private Private notes
 * @param {function} onSuccess Function to execute on success
 * @param {function} onFailure Function to execute on failure
 */
async function addCandidate({
  candidate,
  atsApiKey,
  atsOnBehalfOf: onBehalfOf,
  atsSourceId,
  atsUserId,
  atsJobId,
  atsReferrer,
  submissionNotes = { public: '', private: '' },
  onSuccess,
  onFailure
}) {
  const mappingCandidateResults = mapCando10ToCandoGH({
    candidate,
    atsUserId,
    atsJobId,
    atsSourceId,
    atsReferrer,
    submissionNotes
  });
  const {
    candidate: payload,
    failures
  } = mappingCandidateResults;
  const options = {
    url: Core.getApi('Candidates/greenhouse/harvest'),
    method: 'POST',
    body: {
      path: "/candidates/",
      auth: tools.encodeBase64(`${atsApiKey}:`),
      onBehalfOf,
      payload,
    }
  };

  /** µ FOR TEST PURPOSES un-existing endpoint * /
  options.url = Core.getApi('undefined');
  /** */

  /** µ FOR TEST PURPOSES error on payload * /
  options.body.payload = {};
  /** */

  /** µ FOR TEST PURPOSES error on payload * /
  options.body.payload.email_addresses[0].value = 'https://linkedin/in/x';
  /** */

  /** µ FOR DEBUG PURPOSES * /
  console.debug(
    'µ:Greenhouse+harvest::addCandidate',
    atsApiKey,
    onBehalfOf,
    atsSourceId,
    atsUserId,
    atsJobId,
    options,
  );
  // return;
  /** */

  return await Greenhouse.request({ options, failures, onSuccess, onFailure });

}

/**
 * Returns Greenhouse source id from agency,
 * by callback strategy.
 *
 * @param {string} apiKey required
 * @param {string} agencyName required
 * @param {number} page auxiliar
 * @param {function} onSuccess `response => {sourceIdAgencyId,sourceName}`
 * @param {function} onFailure `params => {failures,suggestions,statusCode}`
 * @returns {promise} Source Object
 */
async function getSourceId({
  apiKey = '',
  agencyName = '',
  page = 1,
  onSuccess = () => null,
  onFailure = () => null,
}) {
  // https://harvest.greenhouse.io/v1/sources/?per_page=500&page=1
  const options = {
    url: Core.getApi('Candidates/greenhouse/harvest'),
    method: 'GET',
    body: {
      path: `/sources/?per_page=500&page=${page}`,
      auth: tools.encodeBase64(`${apiKey}:`),
    }
  };
  return await Greenhouse.request({
    options,
    onSuccess(response = []) {

      if (Array.isArray(response)) {

        if (response.length === 0) {

          onFailure(
            [Greenhouse.MSG_NO_AGENCY_FOUND],
            [
              Greenhouse.MSG_VERIFY_AGENCY,
              Greenhouse.MSG_FIX_YOUR_SELF
            ]
          );

        }
        else {

          const {
            id: sourceIdAgencyId,
            name: sourceName
          } = response.find(({
            id,
            name: _agencyName = '',
            type = {}
          }) => {

            const {
              id: typeId = null,
              name: typeName = ''
            } = type;

            const isTypeAgency = (
              (
                typeId
                ===
                Greenhouse.SOURCE_TYPE_AGENCY_ID
              ) || (
                typeName.toLowerCase()
                ===
                Greenhouse.SOURCE_TYPE_AGENCY_LABEL
                  .toLocaleLowerCase()
              )
            );

            const isAgencyNameProvidedByClient = (
              _agencyName.toLowerCase()
              ===
              agencyName.toLowerCase()
            );

            const isAgencyName10x10 = (
              !!_agencyName
                .match(
                  /10\s*(?:x|by)\s*10/i
                )
            );

            return (
              isTypeAgency &&
              (
                isAgencyNameProvidedByClient ||
                isAgencyName10x10
              )
            );

          }) || {};

          if (sourceIdAgencyId) {

            onSuccess({
              sourceIdAgencyId,
              sourceName
            });

          }
          else {

            getSourceId({
              apiKey,
              agencyName,
              page: page + 1,
              onSuccess,
              onFailure
            });

          }

        }
      }
      else {

        onFailure(
          [Greenhouse.MSG_UNEXPECTED_RESPONSE],
          [Greenhouse.MSG_CONTACT_ADMIN]
        );

      }

    },
    onFailure
  });
}

/**
 * Returns Greenhouse user,
 * by callback or promise strategy.
 *
 * @param {string} apiKey required
 * @param {number} userId required
 * @param {function} onSuccess `response => {sourceIdAgencyId,sourceName}`
 * @param {function} onFailure `params => {failures,suggestions,statusCode}`
 * @returns promise
 */
async function getUserById({
  apiKey = '',
  userId,
  onSuccess = () => null,
  onFailure = () => null,
}) {
  // GET https://harvest.greenhouse.io/v1/users/{id}
  const options = {
    url: Core.getApi('Candidates/greenhouse/harvest'),
    method: 'GET',
    body: {
      path: `/users/${userId}`,
      auth: tools.encodeBase64(`${apiKey}:`),
    }
  };
  return await Greenhouse.request({
    options,
    onSuccess,
    onFailure
  });
  /** SUCCESS RESPONSE * /
  {
    "id": {number},
    "name": {string},
    "first_name": {string},
    "last_name": {string},
    "primary_email_address": {string},
    "updated_at": {string},
    "created_at": {string},
    "disabled": {boolean},
    "site_admin": {boolean},
    "emails": [{string}],
    "employee_id": {any},
    "linked_candidate_ids": [{any}]
  }
  /** */
}

/**
 * Returns Greenhouse user searching by name,
 *
 * @param {string} apiKey required
 * @param {string} userName required
 * @param {number} page auxiliar
 * @param {function} onSuccess `response => {sourceIdAgencyId,sourceName}`
 * @param {function} onFailure `params => {failures,suggestions,statusCode}`
 * @returns {promise} Source Object
 */
async function getUserByName({
  apiKey = '',
  userName = '',
  page = 1,
  onSuccess = () => null,
  onFailure = () => null,
}) {
  // https://harvest.greenhouse.io/v1/users/?per_page=500&page=1
  const options = {
    url: Core.getApi('Candidates/greenhouse/harvest'),
    method: 'GET',
    body: {
      path: `/users/?per_page=500&page=${page}`,
      auth: tools.encodeBase64(`${apiKey}:`),
    }
  };
  return await Greenhouse.request({
    options,
    onSuccess(response = []) {

      if (Array.isArray(response)) {

        if (response.length === 0) {

          onFailure(
            [Greenhouse.MSG_NO_USER_FOUND],
            [
              Greenhouse.MSG_VERIFY_USER,
              Greenhouse.MSG_FIX_YOUR_SELF
            ]
          );

        }
        else {

          const user = response.find(({
            id,
            name: _userName = ''
          }) => {

            const isUserNameProvidedByClient = (
              _userName.toLowerCase()
              ===
              userName.toLowerCase()
            );

            return isUserNameProvidedByClient;

          }) || {};

          if (user.id) {

            onSuccess(user);

          }
          else {

            getUserByName({
              apiKey,
              userName,
              page: page + 1,
              onSuccess,
              onFailure
            });

          }

        }
      }
      else {

        onFailure(
          [Greenhouse.MSG_UNEXPECTED_RESPONSE],
          [Greenhouse.MSG_CONTACT_ADMIN]
        );

      }

    },
    onFailure
  });
}

/* DICTIONARIES =============================== */

const GreenhouseHarvest = {
  addCandidate,
  getSourceId,
  getUserById,
  getUserByName
};

/* EXPORTS ==================================== */

export {
  GreenhouseHarvest as default,
  GreenhouseHarvest,
  addCandidate as getGreenhouseHarvestAddCandidate,
  getSourceId as getGreenhouseHarvestGetSourceId,
  getUserById as getGreenhouseHarvestUserById,
  getUserByName as getGreenhouseHarvestUserByName
};

/* NOTES ====================================== */

/** µ SAMPLE import and use * /

import Greenhouse from "./lib/Greenhouse.lib.js";
import * as Greenhouse from "./lib/Greenhouse.lib.js";
import { Greenhouse } from "./lib/Greenhouse.lib.js";

Greenhouse.harvest.addCandidate(...);

/** */

/** µ SAMPLE addCandidate post body * /
{
  "path": "/candidates/",
  "payload": {
    "first_name": "_cando_01",
    "last_name": "_lastname",
    "company": "10x10",
    "title": "Software Engineer",
    "phone_numbers": [],
    "email_addresses": [
      {
        "value": "_cando01@mail.com",
        "type": "other"
      }
    ],
    "website_addresses": [],
    "applications": [
      {
        "job_id": "400523600",
        "source_id": "400098400",
        "attachments": [
          {
            "filename": "_CANDO01_2021031_16414.pdf",
            "url": "https://d101010-api.go10x10.com/api/.../download/_CANDO01_2021031_16414.pdf",
            "type": "resume"
          }
        ]
      }
    ],
    "activity_feed_notes": [
      {
        "user_id": "401154700",
        "body": "µ public notes 2021-03-18 16:02 CST item 1\nitem 2",
        "visibility": "public"
      },
      {
        "user_id": "401154700",
        "body": "µ private notes 2021-03-18 16:02 CST",
        "visibility": "private"
      }
    ]
  }
}
/** */

/** µ SAMPLE addCandidate response * /
{
  "id": 427363500,
  "first_name": "_cando_01",
  "last_name": "_api",
  "company": "10x10",
  "title": "Software Engineer",
  "created_at": "2021-03-23T18:47:38.114Z",
  "updated_at": "2021-03-23T18:47:38.139Z",
  "last_activity": "2021-03-23T18:47:38.132Z",
  "is_private": false,
  "photo_url": null,
  "attachments": [
    {
      "filename": "_CANDO01_20210317_16414.pdf",
      "url": "https://grnhse-ghr-prod-s4.s3.amazonaws.com/person_attachments/data/421/444/600/original/_CANDO01_20210317_164140.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVQGOLGY3T6RAOEN7%2F20210323%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210323T184738Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=9a7840ffe279adbec575ee4a98db7d0ad2735090b813056fc2f23eafe05643b",
      "type": "resume"
    }
  ],
  "application_ids": [
    427705200
  ],
  "phone_numbers": [],
  "addresses": [],
  "email_addresses": [
    {
      "value": "_cando01@mail.com",
      "type": "other"
    }
  ],
  "website_addresses": [],
  "social_media_addresses": [],
  "recruiter": null,
  "coordinator": null,
  "can_email": true,
  "tags": [],
  "applications": [
    {
      "id": 427705200,
      "candidate_id": 427363500,
      "prospect": false,
      "applied_at": "2021-03-23T18:47:38.344Z",
      "rejected_at": null,
      "last_activity_at": "2021-03-23T18:47:38.115Z",
      "location": null,
      "attachments": [
        {
          "filename": "_CANDO01_20210317_16414.pdf",
          "url": "https://grnhse-ghr-prod-s4.s3.amazonaws.com/person_attachments/data/421/444/600/original/_CANDO01_2021031_16414.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVQGOLGY3T6RAOEN7%2F20210323%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210323T184738Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=9a7840ffe279adbec575ee4a98db7d0ad2735090b813056fc2f23eafe05643b",
          "type": "resume"
        }
      ],
      "source": {
        "id": 400098400,
        "public_name": "10 By 10, Inc"
      },
      "credited_to": null,
      "rejection_reason": null,
      "rejection_details": null,
      "jobs": [
        {
          "id": 4005236004,
          "name": "QA Job 1"
        }
      ],
      "status": "active",
      "current_stage": {
        "id": 403486800,
        "name": "Application Review"
      },
      "answers": [],
      "prospective_department": null,
      "prospective_office": null,
      "prospect_detail": {
        "prospect_pool": null,
        "prospect_stage": null,
        "prospect_owner": null
      }
    }
  ],
  "educations": [],
  "employments": [],
  "linked_user_ids": []
}
/** */
