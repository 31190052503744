import { Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { NavLink } from "react-router-dom";
import Core from '../../lib/Core';
import Employer from '../../lib/Employer';
import Job from '../../lib/Job';

export const moreMenuOptionsEmployers = [
  {
    acl: employer => (Core.isAdmin() || (Core.isRecruiter() && Core.isOnDev())),
    render: employer => (
      <NavLink
        to={"/v3/employer/edit/" + employer.id}
        className="navlink"
        key={`more-options-menu-option-${employer.id}-1`}
      >
        <MenuItem>Edit Employer</MenuItem>
      </NavLink>
    )
  },
  {
    acl: employer => Core.isAdmin(),
    label: 'Delete',
    action: employer => deleteEmployer(employer)
  }
];

function deleteEmployer(employer) {
  Job.getWhere({ employerId: employer.id }).then(
    jobs =>
      Core.dialog.open({
        message: `Delete "${employer._name}"${!!jobs.length
          ? ` and ${jobs.length} job${jobs.length === 1 ? "" : "s"
          }?`
          : ""
          }`,
        actions: [
          <Button
            className="button-white-cyan"
            onClick={Core.dialog.close}>
            Cancel
          </Button>,
          <Button
            className="button-flat-darker"
            onClick={
              ev =>
                Employer.delete(employer.id,
                  response => {
                    Core.showMessage(
                      "Employer was deleted successfully"
                    );
                    window.location.reload();
                  },
                  error => {
                    if (!!error) {
                      Core.showMessage(
                        "Can not delete employer. Please contact support."
                      );
                    }
                  }
                )
            }>
            Confirm
          </Button>
        ]
      })
  )
}