import React, {Component, Fragment} from "react";

/* Material UI theme   */

/* Custom Styles */
import InputFieldContent from "./InputFieldContent";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import JobRequirement from "../../../Jobs/Requirements/Main";
import JobTags from "./JobTags";

class Requirements extends Component {
    render() {
        const {
            selectedJob,
            candidate,
            saveContentJob,
            stringToChips,
            candidateResume,
            source
        } = this.props;
        return (

            <Fragment>
                <Card className="match-new section-card">
                    <div>
                        <CardContent className="match-new card-content">
                            <h2 className="match-new section-header">TECHNICAL SKILLS</h2>
                            <hr/>
                            {!!selectedJob && !!candidate && <JobRequirement
                                mustHave={selectedJob.mustHave}
                                niceToHave={selectedJob.niceToHave}
                                candidate={candidate}
                                onlyView={true}
                                selectedJob={selectedJob}
                                yearsOfExperience={!!selectedJob.minYearsOfExperience ? selectedJob.minYearsOfExperience : null}
                                onSave={(mustHave) => {

                                }}
                                candidateResume={candidateResume}
                                source={source}
                            />}
                            <p><strong>Job Tags</strong></p>
                            {!!candidate && !!selectedJob && !!selectedJob.jobTags.length && 
                                <JobTags 
                                    labels={selectedJob.jobTags.filter(tags=>!!tags.value).map(tags=>tags.value)}
                                    resume={candidate.resumeTxtUrl}
                                    source={source}
                                />
                            }
                            <p><strong>Job Skills</strong></p>
                            {!!selectedJob && !!selectedJob._technicalSkills && <div>{stringToChips(selectedJob._technicalSkills)}</div>}
                            {!!selectedJob &&
                            <InputFieldContent
                                label={<p><strong>New Technical Skills</strong></p>}
                                contentKey="newTechSkill"
                                selectedJob={selectedJob}
                                content={selectedJob.newTechSkill}
                                saveContent={saveContentJob}
                            />
                            }
                            {!!selectedJob && [
                                {
                                    key: 'candidateMust',
                                    label: 'Must Have'
                                },
                                {
                                    key: 'candidatePositives',
                                    label: 'Nice Have'
                                },
                                {
                                    key: 'candidateMustNot',
                                    label: 'Must Not'
                                },
                                {
                                    key: 'candidateNegatives',
                                    label: 'Nice Not'
                                }
                            ].map((el, index) => {
                                return !!selectedJob[el.key] && <Fragment key={index}>
                                    <p><strong>{el.label}</strong></p>
                                    <p dangerouslySetInnerHTML={{__html: selectedJob[el.key]}}></p>
                                </Fragment>
                            })}
                        </CardContent>
                    </div>
                </Card>
            </Fragment>

        );
    }
}

export default Requirements;