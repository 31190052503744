import Core from "./Core";
import Http from "./Http";
import Job from "./Job";
import {
  mapDisagreements,
  model as engagementDisagreement
} from "./models/engagement-disagreement";
import getStateModel from "./tools/getStateModel";

const cache = {};

const EngagementDisagreement = {

  get: (cb, opts) => {
    let { where, fields } = opts;
    where = Object(where);

    Http.get(
      Core.getApi("MatchDecisionTables"),
      {
        filter: JSON.stringify({ where, fields })
      },
      function onSuccess(response) {
        cache.all = mapDisagreements(response);
        cb(cache.all);
      }
    );
  },


  findOrCreate: (disagreement, success, error) => {
    EngagementDisagreement.get((resp) => {
      if (!!resp.length) {
        let firstEngDis = resp.pop();
        const newMatchStrength = disagreement.matchDecision;
        const updateEngDis = {
          ...firstEngDis,
          ...disagreement,
          matchDecision: newMatchStrength,
          updatedAt: new Date().toISOString()
        };
        EngagementDisagreement.update(updateEngDis, success);
      } else {
        EngagementDisagreement.post(disagreement, success, error);
      }
    },
      {
        where: {
          and: [
            { candidateId: disagreement.candidateId },
            { jobId: disagreement.jobId },
            { annotator: Core.getSessionEmail() },
          ]
        }
      })
  },

  post: (disagreement, success, error) => {
    disagreement =
    {
      candidateJobId: disagreement.jobId + '-' + disagreement.candidateId,
      ...disagreement
    };

    Http.post(
      Core.getApi("MatchDecisionTables"),
      getStateModel(disagreement, engagementDisagreement),
      success,
      error
    );

    Job.getMlMatchingScore(
      {
        params: {
          jobId: disagreement.jobId,
          candidateId: disagreement.candidateId
        }
      }, (res) => {
        let matchDecision = Object(res.match).match || Object(res.match).msg
        let disagreementObj = {
          ...disagreement, ...{
            whyNoCategories: [],
            whyNoDetails: '',
            whyNoFieldsValues: [],
            shouldTag: '',
            shouldNotTag: '',
            whyNoPrivateNote: '',
            whyNeedToReadCV: '',
          }
        };
        disagreementObj.annotator = 'ML';
        disagreementObj.matchDecision = 'ML_' + matchDecision;
        disagreementObj.probabilityScore = Object(res.match).score;

        Http.post(
          Core.getApi("MatchDecisionTables"),
          disagreementObj
        );
      }
    )
  },
  update: (disagreement, success) => {
    Http.patch(
      Core.getApi("MatchDecisionTables/" + disagreement.id),
      getStateModel(disagreement, engagementDisagreement),
      success
    );
  },

};

export default EngagementDisagreement;
