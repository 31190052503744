import React, { Component, Fragment } from "react";

import {
    FontIcon,
    MenuItem
} from "material-ui";

class CompanySize extends Component {

    constructor() {
        super(...arguments);
        this.state = {
            minimumCompanySize: this.props.minimumCompanySize || 0,
            showCompanySizePopup: false
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.minimumCompanySize !== this.props.minimumCompanySize){
            this.setState({minimumCompanySize : nextProps.minimumCompanySize});
        }
    }

    closeEventCompanySizePopup = () => {
        this.setState({showCompanySizePopup: false});
    }

    applyEventCompanySizePopup = (companySize) => {
        const {updateSliderValues} = this.props;

        this.closeEventCompanySizePopup();
        this.setState({minimumCompanySize: companySize}, () => {
            updateSliderValues("tempMinimumCompanySize", companySize);
        });
    }
    onChangeEventCompanySizePopup = (companySize) => {
        this.setState({minimumCompanySize: companySize});
    }
    showCompanySizePopup = () => {
        const {companySizePopup} = this.props;
        const {showCompanySizePopup, minimumCompanySize} = this.state;
        
        if(!!showCompanySizePopup && !!companySizePopup){
            return companySizePopup(this.closeEventCompanySizePopup, this.applyEventCompanySizePopup, 
                this.onChangeEventCompanySizePopup, minimumCompanySize);
        }
        return null;
    }

    render(){

        return(
            <Fragment>
                <MenuItem
                    key={`menu3`}
                    className="filter-control-item"
                    primaryText={'Company size'}
                    rightIcon={
                    <FontIcon className="material-icons">
                        arrow_drop_down
                    </FontIcon>
                    }
                    onClick={ev =>
                        this.setState({showCompanySizePopup: true})
                    }
                />
                {this.showCompanySizePopup()}
            </Fragment>
        );
    }
}

export default CompanySize;