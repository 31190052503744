import React from "react";
import Core from "../../Core";
import Http from "../../Http";

export async function autocomplete({ path = 'Entities/_autocomplete', query = '' }) {
  if (query.trim().length >= 2) {
    let promise = Http.get(
      Core.getApi(path),
      { query },
    )
      .then(response => {
        response = response.map(({ term }) => ({ id: term, label: term }));
        return response;
      });
    promise.catch(e => Core.showMessage(<>Autocomplete Failure:<br /><span className="f-md c-gray">{e}</span></>));
    return promise;
  }
  else {
    return [];
  }
};
