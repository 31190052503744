import { Button } from '@mui/material';
import React from 'react';
import Core from '../../lib/Core';
import Definition from '../../lib/Definition';
import Engagement from '../../lib/Engagement';
import Job from '../../lib/Job';
import { getJobModel } from '../../lib/models/job';
import copyHTML from '../../lib/tools/copyHtml';

export const moreMenuOptionsJobs = [
  {
    acl: job => Core.isAdminOrCoordinator(),
    label: 'Edit Job',
    to: job => `/v3/job/edit/${job.id}`,
  },
  {
    acl: job => Core.isAdminOrCoordinator(),
    to: job => `/v3/job/matchNew/${job.id}`,
    label: 'Match',
  },
  {
    acl: job => Core.isAdminOrCoordinator(),
    label: job => `Engagements Details(${job.engagements.length})`,
    action: job => job.openEngagementsDetails(),
  },
  {
    acl: job => Core.isAdminOrCoordinator(),
    label: 'Clone',
    action: cloneJob,
  },
  {
    label: 'Copy JD - long',
    action: copyJobLarge
  },
  {
    label: 'Copy JD - short',
    action: copyJobShort
  },
  {
    acl: job => Core.isAdmin(),
    label: 'Delete',
    action: job => deleteJob(job)
  }
];

function copyJobLarge(job) {
  copyHTML(Job.getPreview(job, 'large'))
    .then((em) => {
      Core.log('Copy email command was successful');
      Core.showMessage(
        'Copied - Job Description copied for you to paste elsewhere!'
      );
    })
    .catch((ex) => {
      Core.log("Oops, unable to copy");
      Core.showMessage("Fail copy!");
    })
}

function copyJobShort(job) {
  copyHTML(Job.getPreview(job, 'short'))
    .then((em) => {
      Core.log('Copy email command was successful');
      Core.showMessage(
        ('Copied - Job Description copied for you to paste elsewhere!')
      );
    })
    .catch((ex) => {
      Core.log("Oops, unable to copy");
      Core.showMessage("Fail copy!");
    })
}

function getEditJobModel(job) {
  const newJobModel = {};
  Object.keys(getJobModel()).forEach((key) => {
    if (
      typeof job[key] === "boolean" ||
      key === "visaTransfer" ||
      !!job[key]
    ) {
      newJobModel[key] = job[key];
    }
  });
  delete newJobModel.id;
  newJobModel.state = Definition.getId("state", "Draft");
  Core.log({ newJobModel });
  return newJobModel;
}

function cloneJob(job) {
  Job.post(
    getEditJobModel(job),
    (response) => Core.go({ ...this.props, to: `/job/edit/${response.id}` })
  );
}

function deleteJob(job) {
  Engagement.getWhere({ jobId: job.id }).then(
    engagements =>
      Core.dialog.open({
        message: `Delete "${job._name}"${!!engagements.length
          ? ` and ${engagements.length} engagement${engagements.length === 1 ? "" : "s"
          }?`
          : ""
          }`,
        actions: [
          <Button
            className="button-white-cyan"
            onClick={Core.dialog.close}>
            Cancel
          </Button>,
          <Button
            className="button-flat-darker"
            onClick={
              ev =>
                Job.delete(job.id,
                  response => {
                    Core.showMessage(
                      "Job was deleted successfully"
                    );
                    window.location.reload();
                  },
                  error => {
                    if (!!error) {
                      Core.showMessage(
                        "Can not delete job. Please contact support."
                      );
                    }
                  }
                )
            }>
            Confirm
          </Button>
        ]
      })
  )
}