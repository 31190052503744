import React, {Component, Fragment} from 'react';
import Definition from "../../lib/Definition";
import Core from "../../lib/Core";

import {
  MenuItem,
  SelectField
} from "material-ui";
import Tooltip from "@mui/material/Tooltip";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AddIcon from "@mui/icons-material/Add";
import AlternativePopupTag from "./AlternativePopupTag";
import {isEmpty} from "lodash";
import TenAutoSuggest from "./TenAutoSuggest";


export default class InputWithTagManagement extends Component {
  constructor() {
    super(...arguments);


    this.state = {
      shouldShowTagForm: false,
      value: this.getValue(this.props.value),
    };

    console.log('inside constructor');
  }

  getValue = (value) => {
    const labelId = Definition.getId(this.props.tagManagementKey, value);
    return labelId || value;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.value !== this.props.value){
      this.setState({value: this.getValue(this.props.value)});
    }
  }

  handlerAddAlternativeNames = () => {
    let {selectedTag} = this.state;
    selectedTag = Object(selectedTag);

    this.setState({
      shouldShowTagForm: true,
      tagFormIntent: 'addAlternativeName',
      tagFormObject: !isEmpty(selectedTag) ?  {
        value: selectedTag.id,
        label: selectedTag.label
      } : null
    });
  };

  handlerAddTag = () => {
    this.setState({
      shouldShowTagForm: true,
      tagFormIntent: 'add',
      tagFormObject: null
    });
  };

  handlerTagEdit = () => {
    let {selectedTag} = this.state;

    selectedTag = Object(selectedTag);
    this.setState({
      shouldShowTagForm: true,
      tagFormIntent: 'edit',
      tagFormObject: !isEmpty(selectedTag) ? {
        value: selectedTag.id,
        label: selectedTag.label,
      } : null
    });
  };

  onCancel = () => {
    this.setState({shouldShowTagForm: false});
  };

  onApply = (intent, data) => {
    const {selected, alternativeNames, newName} = data;
    const existing = data.tagsRaw;
    const existingValues = existing.values;
    let latestValues = existingValues;

    if(intent === 'add'){
      latestValues = [...latestValues, {id: (existingValues||[]).length + 1, label: newName, alternativeNames}];
    }else {
      const nonChangeable = existingValues.filter(tag => tag.id !== Object(selected).id);
      latestValues = [...nonChangeable, {...Object(selected), alternativeNames}];
    }

    latestValues = latestValues.sort(function(a, b){return a.id - b.id});

    Definition.update(existing.id, {values: latestValues}, () => {
      alert('updated successfully');
    });
  };

  renderActions = () => {
    return <span><Fragment>
      <Tooltip title="Add as an alternative name">
        <AllInboxIcon style={{cursor: 'pointer'}} onClick={this.handlerAddAlternativeNames}/>
      </Tooltip>
      <Tooltip title="Add as a new entry">
        <AddIcon style={{cursor: 'pointer'}} onClick={this.handlerAddTag}/>
      </Tooltip>
    </Fragment>
    </span>
  };

  render() {
    const {label, tagManagementKey, onChange, onBlur, key} = this.props;
    let {shouldShowTagForm, tagFormIntent} = this.state;

    let value = this.getValue(this.state.value);
    const isStringEntry = isNaN(+value);
    const backgroundColor = isStringEntry ? 'gold' : '';

    if(!value) {
      value = ""
    }

    return <span key={`input-tag-${key}`}>
      <label>{label} {isStringEntry && Core.isAdmin() && this.renderActions()}</label>
      {isStringEntry ? <TenAutoSuggest
        key={`input-tag-input-${key}`}
        value={String(value)}
        onChange={(ev, value) =>
          onChange(value)
        }
        onBlur={onBlur}
        suggestions={Definition.get(tagManagementKey)}
        style={{backgroundColor}}
      /> : <SelectField
        name={tagManagementKey}
        value={value}
        onChange={(ev, i, value) =>
          onChange(value)
        }
        fullWidth
      >
        {Definition.get(tagManagementKey).map(item => {
          return (
            <MenuItem
              key={item.id}
              value={item.id}
              primaryText={item.label}
            />
          );
        })}
      </SelectField>}

      {Core.isAdmin() && shouldShowTagForm &&
        <AlternativePopupTag
          intent={tagFormIntent}
          newName={value}
          tagManagementKey={tagManagementKey}
          selected={tagFormIntent === 'edit' ? null : null}
          onCancel={this.onCancel}
          onApply={this.onApply}
        />
      }
    </span>
  }
}