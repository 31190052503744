export const makeDecision = (result) => {
    
    let style = {};

    if(result === false) {
        style.border = "2px solid red";
    } else if(result === true) {
        style.border = "1px solid green";
    } else {
        style.border = "1px solid gray";
    }

    return style;
}