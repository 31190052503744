import Core from "./Core";
import Http from "./Http";
import { PDF_MICROSERVICE_BASE_URL, SOVREN_PARSER_URL } from "../lib/Constants";

const getData = (filter, cb) => {
  cb = cb instanceof Function ? cb : function () {
  };

  Http.get(
    Core.getApi("SovrenData/"),
    {filter: JSON.stringify(filter)},
    function onSuccess(response) {
      cb(response);
    }
  );
};

const SovrenData = {
  get: getData,

  post: (data, success) => {
    Http.post(
      Core.getApi("SovrenData/"),
      data,
      success
    );
  },

  update: (id, data) => {
    Http.patch(
      Core.getApi("SovrenData/" + id),
      data,
      response => {

      }
    )
  },

  destroyByTimestamp: (timestamp, cb) => {
    getData({where: {resumeTimestamp: timestamp}, fields:["id", "filenamePrefix"]}, (data) => {
      // hit pdf microservice here to delte files
      if(Array.isArray(data) && Object(data[0]).id){
        const sovrenObject = Object(data[0]);

        SovrenData.deleteSovrenFile(`${sovrenObject.filenamePrefix}${sovrenObject.id}`);
        Http.delete(
          Core.getApi(`SovrenData/${sovrenObject.id}`),
          ()=> {
            !!cb && cb(data[0]);
          }
        );
      }
    })
  },

  getAccountInfo: (cb) => {

    const headers = {
      "Accept": "application/json",
      "Sovren-AccountId": "16174178",
      "Sovren-ServiceKey": "PGlFJgq2nVOGVVdUokFAbc8EUpnsQRWc2MOg9pVS"
    };

    Http.get(
      `${SOVREN_PARSER_URL}/account`,
      {headers},
      (response) => {
        cb(response);
      }
    );
  },

  deleteSovrenFile: (filename, cb=()=>{}) => {
    Http.delete(`${PDF_MICROSERVICE_BASE_URL}/binary_file_to_s3/${filename}`,(response)=>{cb(response)});
    //Http.post('http://localhost:5000/convert',{url,filename},success);
  },

};

export default SovrenData;