/** ============================================ µ
 * @Description CandidateSkills [JS]
 *              UI Component
 * @route       /candidate/matchNew/:candidateId
 * @updatedAt   2021-04-23
 * ============================================ */

/* IMPORTS ==================================== */

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { Component, Fragment } from "react";
import Definition from "../../../../lib/Definition";
import { getCategoryKeys } from "../../../../lib/tools/getSpecificCategoryKey";
import Chips from "../../../Forms/ChipsNew";
import PositiveSignals from "./ElementsComparisonHaves/PositiveSignals";

/* MAIN CLASS ================================= */

class CandidateSkills extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      otherSkills: this.props.data,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        otherSkills: nextProps.data,
      });
    }
  }

  skillsHandler = (skills, type) => {
    const { skillsHandler } = this.props;
    let otherSkills = this.state.otherSkills;
    let changed = otherSkills.find((el) => el.type === type);
    changed.chips = skills;
    let changedIndex = otherSkills.findIndex((el) => el.type === type);
    otherSkills[changedIndex] = changed;

    this.setState({
      otherSkills,
    });
    !!skillsHandler && skillsHandler(skills, type);
  };

  renderList = (data) => {
    const { jobArgs, source, candidate, githubStatsData } = this.props;
    if (!Array.isArray(data)) {
      data = [];
    }
    let typeArray = [];
    let decisionArray = [];
    return data.map((obj, index) => {
      typeArray =
        obj.type !== "technologyDomain"
          ? getCategoryKeys({ key: obj.type }, jobArgs)
          : null;
      decisionArray = !!typeArray
        ? obj.chips.map((chip) => {
          const isMatched = PositiveSignals.result(
            parseInt(chip),
            typeArray,
            "candidatePipe"
          );
          return !!isMatched
            ? isMatched
            : source && source === "candidateMatch"
              ? null
              : isMatched;
        })
        : null;

      return (
        <div key={index}>
          {obj.label}
          {obj.type === "positiveSignals" &&
            !!(candidate.posSignalsInResume || []).length && (
              <Fragment>
                <small> - see additional suggestions found in resume</small>
                <ul>
                  {candidate.posSignalsInResume.map((obj) => {
                    return (
                      <li>
                        <small>
                          {Definition.getLabels("positiveSignals", obj.tags)} =>{" "}
                          <span style={{ color: "lightgray" }}>{obj.ref}</span>
                        </small>
                      </li>
                    );
                  })}
                </ul>
              </Fragment>
            )}
          <div>
            <Chips
              name={obj.type}
              values={obj.chips}
              decisionArray={decisionArray}
              onChange={(chips) => {
                this.skillsHandler(chips, obj.type);
              }}
            />
          </div>
          {obj.type === "positiveSignals" &&
            !!githubStatsData &&
            githubStatsData}
        </div>
      );
    });
  };

  render() {
    const { otherSkills } = this.state;

    return (
      <Fragment>
        <Card
          className="match-new section-card"
          style={{ boxShadow: "0px 0px 10px #888888" }}
        >
          <div>
            <CardContent className="match-new card-content">
              <h2 className="match-new section-header">
                OTHERS CANDIDATE SKILLS
              </h2>
              <hr />
              <div>{this.renderList(otherSkills)}</div>
            </CardContent>
          </div>
        </Card>
      </Fragment>
    );
  }
}

/* EXPORTS ==================================== */

export default CandidateSkills;

/* ============================================ */
