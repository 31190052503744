import { Checkbox, Icon } from '@mui/material/';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { FlatButton, TextField } from "material-ui";
import React, { Component, Fragment } from "react";
import CustomAutocomplete from "../../Shared/CustomAutocomplete";

class AlternativePopup extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      newAlternativeName: '',
      alternativeNames: [],
      interviewBar: '',
      selected: this.props.selected, // {value:'id', label: 'name'}
      newName: this.props.newName,
      sourceName: this.props.source === 'company' ? "Company" : "University",
      type: []
    };
  }

  onCancel = () => {
    const { onCancel } = this.props;
    !!onCancel && onCancel();
  };

  loadCompanies = (keyword, callback) => {
    const { dropdownFormatter, dropdownDataLoader } = this.props;

    dropdownDataLoader(keyword, companies => {
      const formattedCompanies = companies.map(dropdownFormatter);
      console.log({ formattedCompanies });
      callback(formattedCompanies);

      const selected = Object(companies.find(comp => comp.id === Object(this.state.selected).value));

      this.setState({
        allCompanies: companies,
        alternativeNames: selected.alternativeNames || [],
        interviewBar: selected.interviewBar,
        type: selected.type || []
      })
    });
  };

  optsCompanyDropdown = (initialValue) => (inputValue, callback) => {
    if (!inputValue) {
      inputValue = initialValue;
    }
    this.loadCompanies(inputValue, callback);
  };

  handlerCompanyChange = (selected) => {
    const { intent, newName } = this.props;

    const allCompanies = this.state.allCompanies;
    const thisCompany = selected ? Object(allCompanies.find(comp => comp.id === selected.value)) : {};
    let alternativeNames = thisCompany.alternativeNames || [];
    const interviewBar = thisCompany.interviewBar;
    const type = thisCompany.type;


    if (intent === 'addAlternativeName') {
      alternativeNames = [newName, ...alternativeNames]
    }

    this.setState({ selected, alternativeNames, interviewBar, type });
  };

  handleDeleteAlternative = (name) => () => {
    let { alternativeNames } = this.state;
    alternativeNames = alternativeNames.filter(aname => aname !== name);
    this.setState({ alternativeNames });
  };

  onApply = () => {
    const { intent } = this.props;
    this.props.onApply(intent, this.state)
  };

  handleChangeInterviewbar = (positiveSignalId) => (event) => {
    let { type, interviewBar } = this.state;

    if (event.target.checked) {
      type = Array.from(new Set([...type, positiveSignalId]))
    } else {
      type = type.filter(el => el !== positiveSignalId)

      if (interviewBar === positiveSignalId) {
        interviewBar = ''
      }
    }

    this.setState({ type, interviewBar });
  };

  renderDropdown = ({ label, defaultMenuIsOpen, height } = { label: '', defaultMenuIsOpen: false, height: 60 }) => {

    const { selected } = this.state;

    return <div style={{ height, marginBottom: 25 }}>
      <Typography color={'primary'} variant="" display="block" gutterBottom style={{ marginBottom: 15 }}>
        {label}
      </Typography>
      <CustomAutocomplete selected={selected}
        defaultMenuIsOpen={defaultMenuIsOpen}
        handleChange={this.handlerCompanyChange}
        placeholder={"write to search"}
        opts={this.optsCompanyDropdown(Object(selected).label)} />
    </div>
  };

  renderTextField = () => {
    let lineStyle = '';
    const { newName, sourceName } = this.state;

    return <div style={{ marginBottom: 10 }}>
      <Typography color={'primary'} variant="button" display="block" gutterBottom>
        {`${sourceName} Name`}
      </Typography>
      <TextField
        key={'newCompany'}
        name="field"
        underlineFocusStyle={lineStyle}
        rows={1}
        rowsMax={1}
        value={newName}
        placeholder={`${sourceName} Name`}
        onChange={(ev, field) => {
          this.setState({ newName: field });
        }}
        fullWidth
      />
    </div>
  };

  renderAlternativeNames = () => {
    const { alternativeNames, newAlternativeName } = this.state;
    let lineStyle = '';

    return <Fragment>
      <Typography color="primary" variant="button" display="block" gutterBottom>
        Alternative Names List:
      </Typography>

      <List style={{ margin: 0, padding: 0 }}>
        <ListItem style={{ margin: 0, padding: 0 }}>
          <TextField
            key={'newAlternativeName'}
            name="field"
            underlineFocusStyle={lineStyle}
            rows={1}
            rowsMax={1}
            value={newAlternativeName}
            placeholder="Write here for a new Alternative Name and press Enter..."
            onChange={(ev, field) => {
              this.setState({ newAlternativeName: field });
            }}
            onBlur={() => {
            }}
            fullWidth
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.setState({
                  alternativeNames: [newAlternativeName, ...alternativeNames],
                  newAlternativeName: ''
                });
              }
            }}
          />
        </ListItem>

        {alternativeNames.map(aname => {
          return <ListItem>
            <ListItemText
              primary={aname}
            />

            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete">
                <Icon onClick={this.handleDeleteAlternative(aname)}>delete</Icon>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        })}
      </List>
    </Fragment>;
  };

  renderInterviewBar = () => {
    const { interviewBar, type } = this.state;

    const isChecked = (arrayToCheck) => (value) => {
      return arrayToCheck.includes(value);
    };

    const isCheckedWithArray = isChecked([...(type || []), interviewBar]);

    const TOP_TIER_TECH_COMPANY = 24;
    const TECH_COMPANY = 31;
    const STRONG_TECH_COMPANY = 20;

    const FAANG = 35;
    const FORTUNE_1000 = 33;
    const PUBLIC_COMPAY = 38;

    const UNICORN_STARTUP = 34;
    const ACQUIRED_STARTUP = 37;
    const STARTUP_EXPERIENCE = 18;
    const YCOMBINATOR_STARTUP = 36;

    return <Fragment>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox checked={isCheckedWithArray(TOP_TIER_TECH_COMPANY)}
              onChange={this.handleChangeInterviewbar(TOP_TIER_TECH_COMPANY)} value={TOP_TIER_TECH_COMPANY} />
          }
          label="Top tier tech company"
        />

        <FormControlLabel
          control={
            <Checkbox checked={isCheckedWithArray(STRONG_TECH_COMPANY)}
              onChange={this.handleChangeInterviewbar(STRONG_TECH_COMPANY)} value={STRONG_TECH_COMPANY} />
          }
          label="Strong tech company"
        />

        <FormControlLabel
          control={
            <Checkbox checked={isCheckedWithArray(TECH_COMPANY)} onChange={this.handleChangeInterviewbar(TECH_COMPANY)}
              value={TECH_COMPANY} />
          }
          label="Tech Company"
        />
      </FormGroup>

      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox checked={isCheckedWithArray(FAANG)} onChange={this.handleChangeInterviewbar(FAANG)}
              value={FAANG} />
          }
          label="FAANG"
        />

        <FormControlLabel
          control={
            <Checkbox checked={isCheckedWithArray(FORTUNE_1000)} onChange={this.handleChangeInterviewbar(FORTUNE_1000)}
              value={FORTUNE_1000} />
          }
          label="Fortune 1000"
        />

        <FormControlLabel
          control={
            <Checkbox checked={isCheckedWithArray(PUBLIC_COMPAY)}
              onChange={this.handleChangeInterviewbar(PUBLIC_COMPAY)}
              value={PUBLIC_COMPAY} />
          }
          label="Public company"
        />
      </FormGroup>

      <FormGroup row style={{ marginBottom: 25 }}>
        <FormControlLabel
          control={
            <Checkbox checked={isCheckedWithArray(STARTUP_EXPERIENCE)}
              onChange={this.handleChangeInterviewbar(STARTUP_EXPERIENCE)} value={STARTUP_EXPERIENCE} />
          }
          label="Startup experience"
        />


        <FormControlLabel
          control={
            <Checkbox checked={isCheckedWithArray(UNICORN_STARTUP)}
              onChange={this.handleChangeInterviewbar(UNICORN_STARTUP)} value={UNICORN_STARTUP} />
          }
          label="Unicorn startup"
        />

        <FormControlLabel
          control={
            <Checkbox checked={isCheckedWithArray(YCOMBINATOR_STARTUP)}
              onChange={this.handleChangeInterviewbar(YCOMBINATOR_STARTUP)} value={YCOMBINATOR_STARTUP} />
          }
          label="YCombinator startup"
        />

        <FormControlLabel
          control={
            <Checkbox checked={isCheckedWithArray(ACQUIRED_STARTUP)}
              onChange={this.handleChangeInterviewbar(ACQUIRED_STARTUP)} value={ACQUIRED_STARTUP} />
          }
          label="Acquired startup"
        />


      </FormGroup>
    </Fragment>
  };

  render() {
    const { intent, newName, source } = this.props;
    const { sourceName } = this.state;

    let content = '';

    switch (intent) {
      case 'add':
        content = <Fragment>
          {this.renderTextField()}
          {source === 'company' && <Fragment>{this.renderInterviewBar()}</Fragment>}
          {this.renderAlternativeNames()}
        </Fragment>;
        break;
      case 'addAlternativeName':
        content = <Fragment>
          {this.renderDropdown({
            label: <span>Please pick the {source} for which you want alternative name: <mark>{newName}</mark></span>,
            defaultMenuIsOpen: true,
          }
          )}
          {source === 'company' && this.renderInterviewBar()}
          {this.renderAlternativeNames()}
        </Fragment>;
        break;
      case 'edit':
        content = <Fragment>
          {this.renderDropdown({
            label: `${sourceName} Name:`,
          })}
          {source === 'company' && this.renderInterviewBar()}
          {this.renderAlternativeNames()}
        </Fragment>;
        break;
      default:
        content = "no case found"
    }

    return (
      <Dialog
        maxWidth='xl'
        open={true}
        width={'lg'}
        onClose={() => {
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent style={{ width: 900 }}>
          {content}
        </DialogContent>

        <DialogActions>
          <FlatButton
            label="Cancel"
            className="filter-popup-button uppercase"
            onClick={() => this.onCancel()}
          />
          <FlatButton
            label="Save"
            className="filter-popup-button uppercase"
            onClick={() => {
              this.onApply()
            }}
          />
        </DialogActions>
      </Dialog>
    );

  }
}

export default AlternativePopup;