import React, { Component } from "react";

const styles = {
  "6": {
    width: "50%",
    //float: left;
    padding: "0px 16px"
  },
  "12": {
    width: "100%",
    padding: "0px 16px"
  }
};

class Col extends Component {
  render() {
    const alignRight = this.props.alignRight ? { textAlign: "right" } : {};
    return (
      <div
        className={this.props.className ? "col " + this.props.className : "col"}
        style={{
          ...styles[this.props.fullWidth ? 12 : this.props.size || 6],
          ...alignRight,
          ...this.props.style
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Col;
