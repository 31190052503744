import Checkbox from "@mui/material/Checkbox";
import Input from "@mui/material/Input";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import {
  IconButton,
  List,
  ListItem,
  MenuItem,
  RadioButton,
  RadioButtonGroup,
  SelectField,
  TextField
} from "material-ui";
import React, { Component, Fragment } from "react";
import Core, { colors } from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import formatMoney from "../../../lib/tools/formatMoney";
import lineStyle from "../../../static/textfield-line-style.json";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";
import Slider from "../../Forms/Slider";
import RichTextBox from "../../Forms/RichTextBox";
import InputArray from "../../Shared/InputArray";
import JobRequirement from "../Requirements/Main";
import JobTags from "../Tags/JobTags";
import LocationSection from "./LocationSection";
import AddItemsComponent from "./MustHaves/AddItems";
import CompanyComponent from "./MustHaves/Company";
import EducationComponent from "./MustHaves/Education";
import TechnicalSkillsComponent from "./MustHaves/TechnicalSkills";

class Basics extends Component {

  renderCandidateAttrs = () => {
    let { parent: JobEditController } = this.props;
    let collection = [
      //            {key: 'additionalNotes', limited: true, label: 'MUST have (original field)'},
      { key: "candidateMust", limited: true, label: "MUST have" },
      { key: "candidateMustNot", limited: true, label: "MUST NOT have" },
      {
        key: "candidatePositives",
        limited: true,
        label: "Strongly Preferred (Positives)",
      },
      {
        key: "candidateNegatives",
        limited: true,
        label: "Unlikely to Hire (Negatives)",
      },
    ];

    return collection.map((item) => {
      return (
        <Row>
          <Col fullWidth>
            <label>
              {item.label}&nbsp;
              {item.limited && (
                <b>
                  <small>(Visible to Limited Recruiters)</small>
                </b>
              )}
            </label>
            <RichTextBox
              name={item.key}
              value={JobEditController.state[item.key]}
              onChange={retObj => {
                let ret = {};
                ret[item.key] = retObj;
                JobEditController.setState(ret);
              }}
            />
          </Col>
        </Row>
      );
    });
  };

  /**
   * @todo
   * DEPRECATED ?
   * ask to BB or AC
   * 2021-06-09
   * µ
   */
  renderJobDynamicStructures = () => {
    const { parent: JobEditController } = this.props;
    return [
      { key: "candidateMustDetails", label: "Must Haves" },
      { key: "candidateMustNotDetails", label: "Must Not Haves" },
      {
        key: "candidatePositivesDetails",
        label: "Strongly Preferred (Positives)",
      },
      {
        key: "candidateNegativesDetails",
        label: "Unlikely to Hire (Negatives)",
      },
    ].map((obj, index) => {
      let baseObject = JobEditController.state[obj.key];

      return (
        <Fragment>
          <Row>
            <Col fullWidth style={{ padding: "0px 16px 10px" }}>
              <label>{obj.label}</label>
            </Col>
          </Row>
          <Row>
            <Col fullWidth style={{ backgroundColor: "lightblue" }}>
              <InputArray
                heading="Technical Skills"
                existing={baseObject.technicalSkills}
                elementComp={TechnicalSkillsComponent}
                parentUpdater={(result) => {
                  let mustHaveSkills = baseObject;
                  mustHaveSkills.technicalSkills = result;
                  JobEditController.setState({ mustHaveSkills: result });
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col fullWidth style={{ backgroundColor: "lightblue" }}>
              <InputArray
                heading="Work Experience"
                existing={baseObject.experience}
                elementComp={CompanyComponent}
                parentUpdater={(result) => {
                  let mustHaveSkills = baseObject;
                  mustHaveSkills.experience = result;
                  JobEditController.setState({ mustHaveSkills: result });
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col fullWidth style={{ backgroundColor: "lightblue" }}>
              <InputArray
                heading="Education"
                existing={baseObject.education}
                elementComp={EducationComponent}
                parentUpdater={(result) => {
                  let mustHaveSkills = baseObject;
                  mustHaveSkills.education = result;
                  JobEditController.setState({ mustHaveSkills: result });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col fullWidth style={{ backgroundColor: "lightblue" }}>
              <InputArray
                heading="Others"
                existing={baseObject.others}
                elementComp={AddItemsComponent}
                parentUpdater={(result) => {
                  let mustHaveSkills = baseObject;
                  mustHaveSkills.others = result;
                  JobEditController.setState({ mustHaveSkills: result });
                }}
              />
            </Col>
          </Row>
        </Fragment>
      );
    });
  };

  render() {

    const {
      parent: JobEditController
    } = this.props;

    const error = {};

    return (
      <List className="JobForm Basics">

        <ListItem
          className="form-header"
          primaryText="BASICS"
          disabled={true}
        />

        <Row>

          {
            /**
             * JOB ROLE
             */
          }
          <Col>
            <label>
              Job Role(s) <b className="cred">*</b>
            </label>
            <Select
              multiple
              value={JobEditController.state.roles}
              onChange={(e) => {
                JobEditController.setState({
                  roles: e.target.value,
                  role: e.target.value[0],
                });
              }}
              underlineFocusStyle={lineStyle}
              input={<Input id="select-multiple-checkbox" />}
              renderValue={(selected) =>
                Definition.getLabels("roles", selected).join(", ")
              }
              fullWidth
              style={{ marginTop: "10px" }}
            >
              {Definition.get("roles").map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  <Checkbox
                    checked={JobEditController.state.roles.indexOf(tag.id) > -1}
                    style={{ float: "left" }}
                  />
                  <ListItemText
                    primary={tag.label}
                    style={{ paddingTop: "10px" }}
                  />
                </MenuItem>
              ))}
            </Select>
          </Col>

          {
            /**
             * EMPLOYER
             */
          }
          <Col>
            <label>
              Employer <b className="cred">*</b>
            </label>
            <div className="inline-blocks margin-rights">
              <SelectField
                name="employer"
                underlineFocusStyle={lineStyle}
                required={true}
                value={JobEditController.state.employerId}
                maxHeight={640}
                className={!!JobEditController.state.employerId ? "col10" : ""}
                onChange={(event, index, employerId) => {
                  const employer = JobEditController.state.employers.find(
                    (employer) => employer.id === employerId
                  );
                  JobEditController.setState(
                    {
                      employerId,
                      employer,
                      visaTransfer: employer.visaTransfer,
                      remote: employer.remote,
                      addressCity: employer.addressCity,
                      location: employer.location,
                      error,
                    },
                    (then) => Core.log({ jobState: JobEditController.state })
                  );
                }}
                errorText={JobEditController.state.error.employer}
                fullWidth
              >
                {JobEditController.state.employers
                  .sort((a, b) => {
                    a = a.name.toLowerCase();
                    b = b.name.toLowerCase();
                    return a > b ? 1 : a < b ? -1 : 0;
                  })
                  .map((employer) => (
                    <MenuItem
                      key={employer.id}
                      value={employer.id}
                      primaryText={employer.name}
                    />
                  ))}
              </SelectField>
              {!!JobEditController.state.employerId && (
                <IconButton
                  className="icon24"
                  onClick={(ev) => Core.go({
                    ...this.props,
                    to: `/employer/edit/${JobEditController.state.employerId}`
                  })
                  }
                >
                  <i className="material-icons">business</i>
                </IconButton>
              )}
            </div>
          </Col>

        </Row>
        <Row>

          {
            /**
             * JOB TITLE
             */
          }
          <Col>
            <label>
              Job Title <b className="cred">*</b>
            </label>
            <TextField
              name="jobTitle"
              type="text"
              underlineFocusStyle={lineStyle}
              value={JobEditController.state.jobTitle}
              onChange={(event, jobTitle) => JobEditController.setState({ jobTitle })}
              fullWidth
              errorText={JobEditController.state.errorJobTitle}
            />
          </Col>

          {
            /**
             * LEVEL
             */
          }
          <Col>
            <label>Level</label>
            <SelectField
              name="level"
              underlineFocusStyle={lineStyle}
              required={true}
              value={JobEditController.state.level}
              maxHeight={640}
              onChange={(event, index, level) => JobEditController.setState({ level })}
              fullWidth
            >
              {Definition.get("level").map((tag) => (
                <MenuItem key={tag.id} value={tag.id} primaryText={tag.label} />
              ))}
            </SelectField>
          </Col>

        </Row>
        <Row>

          {
            /**
             * HIRING TARGET
             */
          }
          <Col>
            <label>Hiring Target</label>
            <TextField
              name="hiringTarget"
              underlineFocusStyle={lineStyle}
              type="number"
              value={JobEditController.state.hiringTarget}
              onChange={(event, hiringTarget) => {
                JobEditController.setState({ hiringTarget });
              }}
              fullWidth
            />
          </Col>

        </Row>

        {/** */}

        {/**
         * @todo
         *
         * Review to cleanup
         *
         * epic-3338(new locations)-story-3385 | 2021-07-16 Fri µ
         *
         */}
        <div className="p-1 m-2 rounded" style={{ border: "2px dotted #ccc", filter: 'grayscale(1)' }}>
          <Row><Col><label>DEPRECATED</label></Col></Row>

          {/* JOB TYPE */}
          <Col>
            <label>Job Type</label>
            <SelectField
              name="jobType"
              underlineFocusStyle={lineStyle}
              maxHeight={300}
              value={JobEditController.state.jobType}
              onChange={(event, index, jobType) => JobEditController.setState({ jobType })}
              fullWidth
              disabled
            >
              {Definition.get("jobType").map((tag) => (
                <MenuItem key={tag.id} value={tag.id} primaryText={tag.label} />
              ))}
            </SelectField>
          </Col>

          <Row>
            <Col>
              <label>City</label>
              <TextField
                name="city"
                type="text"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.addressCity || ""}
                onChange={(event, addressCity) =>
                  JobEditController.setState({ addressCity })
                }
                fullWidth
                disabled
              />
            </Col>
            <Col>
              <label>Region</label>
              <Select
                multiple
                value={JobEditController.state.locations}
                onChange={(e) => {
                  JobEditController.setState({
                    locations: e.target.value,
                    location: e.target.value[0],
                  });
                }}
                underlineFocusStyle={lineStyle}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={(selected) =>
                  Definition.getLabels("location", selected).join(", ")
                }
                fullWidth
                style={{ marginTop: "10px" }}
                disabled
              >
                {Definition.get("location").map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    <Checkbox
                      checked={JobEditController.state.locations.indexOf(tag.id) > -1}
                      style={{ float: "left" }}
                    />
                    <ListItemText
                      primary={tag.label}
                      style={{ paddingTop: "10px" }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Col>
          </Row>

          <Row>
            <Col fullWidth>
              <label>Work from Home (WFH) Policy</label>
              <RadioButtonGroup
                name="shipSpeed"
                // can't assume 0 is an invalid value, therefore need to check job value is set to a number. Need to check job first
                // valueSelected={ typeof JobEditController.state.remote === "number" ? JobEditController.state.remote : JobEditController.state.employer.remote }
                valueSelected={JobEditController.state.remote}
                onChange={(event, remote) => {
                  JobEditController.setState({ remote });
                }}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "16px 0 8px",
                }}
              >
                {Definition.get("remote").map((tag) => (
                  <RadioButton
                    key={tag.id}
                    value={tag.id}
                    label={tag.label}
                    style={{ width: "20%", fontSize: 12 }}
                    labelStyle={{ marginLeft: -10 }}
                    disabled
                  />
                ))}
              </RadioButtonGroup>
            </Col>
          </Row>

          <Row>
            <Col fullWidth>
              <label>Work From Home (Extra Info)</label>
              <TextField
                name="remoteExtraInfo"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.remoteExtraInfo}
                onChange={(event, remoteExtraInfo) => {
                  JobEditController.setState({ remoteExtraInfo });
                }}
                fullWidth
                disabled
              />
            </Col>
          </Row>

        </div>
        {/** */}

        <LocationSection
          JobEditController={JobEditController}
          BasicsController={this}
        />

        {
          /**
           * ELEVATOR PITCH
           */
        }
        <Row>
          <Col fullWidth>
            <label>Elevator Pitch</label>
            <RichTextBox
              name="tagline"
              value={JobEditController.state.employer.tagline || ""}
              disabled={true}
            />
          </Col>
        </Row>

        {
          /**
           * VISA
           */
        }
        <Row>
          <Col fullWidth>
            <label>Visa</label>
            <RadioButtonGroup
              name="visaTransfer"
              // ideally load employer values if job undefined, but job card doesn't do the same
              valueSelected={JobEditController.state.visaTransfer}
              // can't assume 0 is an invalid value, therefore need to check job value is set to a number. Need to check job first
              // valueSelected={ JobEditController.state.visaTransfer === "number" ? JobEditController.state.visaTransfer : JobEditController.state.employer.visaTransfer}
              onChange={(event, visaTransfer) => {
                JobEditController.setState({ visaTransfer });
              }}
              style={{
                display: "flex",
                flexWrap: "wrap",
                margin: "16px 0",
              }}
            >
              {Definition.get("visa").map((item) => (
                <RadioButton
                  key={item.id}
                  value={item.id}
                  label={item.label}
                  style={{ width: "20%" }}
                  labelStyle={{ marginLeft: -10, fontSize: 12 }}
                />
              ))}
            </RadioButtonGroup>
          </Col>
        </Row>

        {
          /**
           * SALARY SECTION
           */
        }
        <Row style={{ overflow: "visible" }}>
          <Col>
            <label>
              Minimum Salary:&nbsp; ${formatMoney(JobEditController.state.salaryMin, 0)}
            </label>
            <Slider
              name="salaryMin"
              min={0}
              max={250000}
              step={10000}
              value={JobEditController.state.salaryMin}
              onChange={(event, salaryMin) => {
                JobEditController.setState({ salaryMin });
              }}
            />
            <label>
              Maximum Salary:&nbsp; ${formatMoney(JobEditController.state.salaryMax, 0)}
            </label>
            <Slider
              name="salaryMax"
              min={0}
              max={250000}
              step={10000}
              value={JobEditController.state.salaryMax}
              onChange={(event, salaryMax) => {
                JobEditController.setState({ salaryMax });
              }}
            />
          </Col>
          <Col>
            <label>Equity Range</label>
            <span>
              <TextField
                name="equityMin"
                type="number"
                style={{ width: "100px" }}
                floatingLabelFocusStyle={{ color: colors.purple, fontSize: 12 }}
                underlineFocusStyle={{ borderColor: colors.purple }}
                value={JobEditController.state.equityMin}
                onChange={(event, equityMin) => {
                  JobEditController.setState({ equityMin });
                }}
              />
              %&nbsp;&nbsp;&nbsp;to&nbsp;&nbsp;&nbsp;
              <TextField
                name="equityMax"
                type="number"
                style={{ width: "100px" }}
                floatingLabelFocusStyle={{ color: colors.purple, fontSize: 12 }}
                underlineFocusStyle={{ borderColor: colors.purple }}
                value={JobEditController.state.equityMax}
                onChange={(event, equityMax) => {
                  JobEditController.setState({ equityMax });
                }}
              />
              {"%"}
            </span>
            <div>
              <label className="legend">Additional Information on Salary</label>
              <TextField
                name="salaryNote"
                placeholder="ex: remote candidate's salary will be adjusted for local cost of living.  ex: above market equity."
                rows={2}
                multiLine
                value={JobEditController.state.salaryNote}
                onChange={(event, salaryNote) => {
                  JobEditController.setState({ salaryNote });
                }}
                underlineFocusStyle={lineStyle}
                fullWidth
              />
            </div>
          </Col>
        </Row>

        {/* {this.renderJobDynamicStructures()} */}

        {
          /**
           * JOB DESCRIPTION
           */
        }
        <Row>
          <Col fullWidth>
            <div className="µ-public-job-desc">
              <label>Public Job Description</label>
              <input
                type="text"
                placeholder="Write company's job description link here"
                value={JobEditController.state.jobDescriptionPublicURL}
                onChange={(event) =>
                  JobEditController.setState({
                    jobDescriptionPublicURL: event.target.value,
                  })
                }
              />
            </div>
            <RichTextBox
              name="jobDescription"
              value={JobEditController.state.jobDescription}
              onChange={jobDescription => {
                JobEditController.setState({
                  jobDescription,
                });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col fullWidth style={{ padding: "0px 16px 10px" }}>
            <label>Add Tags</label>
          </Col>
        </Row>
        <Row>
          <Col fullWidth style={{ backgroundColor: "lightblue" }}>
            <InputArray
              heading="Tags"
              existing={JobEditController.state.jobTags}
              elementComp={JobTags}
              parentUpdater={(result) => {
                JobEditController.setState({ jobTags: result });
              }}
            />
          </Col>
        </Row>

        <JobRequirement
          mustHave={JobEditController.state.mustHave}
          niceToHave={JobEditController.state.niceToHave}
          onSave={(haves, key) => {
            JobEditController.setState({
              [key]: haves,
            });
          }}
          jobId={JobEditController.state.id}
          jobTags={JobEditController.state.jobTags}
        />

        {this.renderCandidateAttrs()}

        {Core.isAdminOrCoordinator() && (
          <>

            <Row>
              <Col fullWidth>
                <label>Accepted Profiles</label>
                <RichTextBox
                  name="examplesOfAccepted"
                  ref={(self) => (JobEditController.ExamplesOfAccepted = self)}
                  value={JobEditController.state.examplesOfAccepted}
                  onChange={examplesOfAccepted => {
                    JobEditController.setState({ examplesOfAccepted });
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col fullWidth>
                <label>Rejected Profiles</label>
                <RichTextBox
                  name="examplesOfRejected"
                  ref={(self) => (JobEditController.ExamplesOfRejected = self)}
                  value={JobEditController.state.examplesOfRejected}
                  onChange={examplesOfRejected => {
                    JobEditController.setState({ examplesOfRejected });
                  }}
                />
              </Col>
            </Row>

          </>
        )}
      </List>
    );
  }
}

export default Basics;
