import React, {Component, Fragment} from "react";

import Snackbar from "material-ui/Snackbar";
import Button from '@mui/material/Button';

import Core from "../../../../lib/Core";
import copyHTML from "../../../../lib/tools/copyHtml";
import Job from "../../../../lib/Job";

class SelectedBulkJob extends Component {

    constructor(){
        super(...arguments);
        this.state = {
            snackBarMessage: "",
            snackBarOpen: false
        }
    }

    showMessage = msg => {
        this.setState({
          snackBarMessage: msg,
          snackBarOpen: true
        });
    };

    hideMessage = () => {
        this.setState({
          snackBarMessage: "",
          snackBarOpen: false
        });
    };

    bulkCopy() {
        const { multiSelectedJobs, candidate } = this.props;
        let headers = [];
        let body = "";
        Core.log({ sel: multiSelectedJobs });
        multiSelectedJobs.forEach(job => {
            if (job && Job.getPreview(job)) {
                headers.push("<li>" + job._name + "</li>");
                body += Job.getPreview(job);
                body += "<br/><hr/>";
            }
        });
        Core.log({ length: body.length });
        copyHTML(`
          ${
            candidate._name
          } - Looking forward to helping you interview - ${Core.getEnvValue(
          "TEAM_NAME"
        )}<br/>
          <br/>
          Hi ${
            candidate.firstName
            } - Below my signature, you'll find team and job specific information about:<br/>
          <ul>
          ${headers.join("")}
          </ul>
          <b>Important</b><br/>
          When you've read this, please email me back with any questions. Based on your reply, I will connect you with the teams you like to get interviews started.<br/>
          <br/>
          <u>By working with us, we commit to give you:</u><br/>
          <ul>
          <li>Access to innovative companies, many with jobs not yet publically posted.</li>
          <li>Fast-track interview, so your resumes isn't lost in a black hole.</li>
          <li>An insider scoop to help you navigate the interview process and save time.</li>
          <li>Market compensation data and expert advice on offer negotiations.</li>
          <li>Care and consideration for your preferences and career goals.</li>
          </ul>
          <br/>
          I will be your point of contact throughout the interview,<br/>
          ${Core.getEmailSignature()}
          ${body}
        `)
          .then(em => {
            Core.log("Copy email command was successful");
            this.showMessage("Copied!");
          })
          .catch(ex => {
            Core.log("Oops, unable to copy");
            this.showMessage("Fail copy!");
          });
    }

    render() {
        return(
            <Fragment>
                <Button
                  variant="outlined"
                  style={{color: "#454545"}}
                  onClick={ev => this.bulkCopy()}>
                  {`Copy (${this.props.multiSelectedJobs.length})`}
                </Button>
                <Snackbar
                    open={this.state.snackBarOpen}
                    message={this.state.snackBarMessage}
                    className="snack-bar"
                    autoHideDuration={6000}
                    onRequestClose={this.hideMessage}
                />
            </Fragment>
        );
    }
}

export default SelectedBulkJob;