import Core from "./Core";
import Http from "./Http";
import cleanHTML from "./tools/cleanHtml";
import requestFormRedirect from "./tools/requestFormRedirect";

const handler = (method, response, onSuccess, onFailure) => {
  if (!!response.error) {
    Core.failure({
      source: `Google.js`,
      exception: response.error,
      params: { method }
    });
    try {
      onFailure &&
        onFailure(`Google.${method}:` + response.error.errors.pop().message);
    } catch (ex) {
      onFailure && onFailure(`Google.${method}: ` + JSON.stringify(response));
    }
  } else {
    onSuccess && onSuccess(response);
  }
};
const Google = {
  getCredential: (onSuccess, onFailure) => {
    Http.get(
      Core.getApi("Credentials"),
      {
        filter: JSON.stringify({
          where: {
            provider: "google"
          },
          limit: 1,
          order: "createdAt DESC"
        })
      },
      onSuccess,
      onFailure
    );
  },
  connect: em => {
    Http.get(
      Core.getApi("Credentials/authConfig"),
      { provider: "google" },
      response => {
        handler(
          "connect",
          response,
          response => {
            response.body.redirect_uri = response.body.redirect_uri.replace(
              "{BE_REDIRECT}",
              Core.getRedirectURI("be")
            );
            response.body.state = response.body.state
              .replace("{FE_REDIRECT}", Core.getRedirectURI("fe"))
              .replace("{BE_REDIRECT}", Core.getRedirectURI("be"));
            requestFormRedirect(response);
          },
          error => Core.showFailure(error)
        );
      }
    );
  },
  disconnect: (onSuccess, onFailure) => {
    /** This still not working */
    Http.post(
      Core.getApi("Credentials/api"),
      {
        provider: "google",
        action: "revokeToken"
      },
      response => handler("disconnect", response, onSuccess, onFailure),
      error => onFailure("Google.disconnect: " + error)
    );
  },
  listCalendars: (onSuccess, onFailure) => {
    Http.post(
      Core.getApi("Credentials/api"),
      {
        provider: "google",
        action: "listCalendars"
      },
      response => handler("listCalendars", response, onSuccess, onFailure),
      error => onFailure("Google.listCalendars: " + error)
    );
  },
  getColors: (onSuccess, onFailure) => {
    Http.post(
      Core.getApi("Credentials/api"),
      {
        provider: "google",
        action: "getColors"
      },
      response => handler("getColors", response, onSuccess, onFailure),
      error => onFailure("Google.getColors: " + error)
    );
  },
  listEvents: (calendarId, onSuccess, onFailure) => {
    // e.g. calendarId: calendars.items[0].id
    Http.post(
      Core.getApi("Credentials/api"),
      {
        provider: "google",
        action: "listEvents",
        params: { calendarId }
      },
      response => handler("listEvents", response, onSuccess, onFailure),
      error => onFailure("Google.listEvents: " + error)
    );
  },
  insertEvent: (
    { calendarId, sendNotifications, body },
    onSuccess,
    onFailure
  ) => {
    // if(Core.isLocalHost() || Core.isDevelopment()){
    //   alert('skipping insertEvent because its local or development');
    //   return;
    // }

    Http.post(
      Core.getApi("Credentials/api"),
      {
        provider: "google",
        action: "insertEvent",
        params: {
          calendarId,
          sendNotifications
        },
        body
      },
      response => handler("insertEvent", response, onSuccess, onFailure),
      error => onFailure("Google.insertEvent: " + error)
    );
  },
  patchEvent: (
    { calendarId, eventId, body, sendNotifications },
    onSuccess,
    onFailure
  ) => {
    if (Core.isLocalHost() || Core.isDevelopment()) {
      alert('skipping patchEvent because its local or development');
      return;
    }
    Http.post(
      Core.getApi("Credentials/api"),
      {
        provider: "google",
        action: "patchEvent",
        params: {
          calendarId,
          eventId,
          sendNotifications
        },
        body
      },
      response => handler("patchEvent", response, onSuccess, onFailure),
      error => onFailure("Google.patchEvent: " + error)
    );
  },
  updateEvent: (
    { calendarId, eventId, body, sendNotifications },
    onSuccess,
    onFailure
  ) => {

    if (Core.isLocalHost() || Core.isDevelopment()) {
      alert('skipping updateEvent because its local or development');
      return;
    }

    Http.post(
      Core.getApi("Credentials/api"),
      {
        provider: "google",
        action: "updateEvent",
        params: {
          calendarId,
          eventId,
          sendNotifications
        },
        body
      },
      response => handler("patchEvent", response, onSuccess, onFailure),
      error => onFailure("Google.patchEvent: " + error)
    );
  },
  deleteEvent: ({ calendarId, eventId }, onSuccess, onFailure) => {
    Http.post(
      Core.getApi("Credentials/api"),
      {
        provider: "google",
        action: "deleteEvent",
        params: {
          calendarId,
          eventId
        }
      },
      response => handler("deleteEvent", response, onSuccess, onFailure),
      error => onFailure("Google.deleteEvent: " + error)
    );
  },
  listLabels: (onSuccess, onFailure) => {
    Http.post(
      Core.getApi("Credentials/api"),
      {
        provider: "google",
        action: "listLabels"
      },
      response => handler("listLabels", response, onSuccess, onFailure),
      error => onFailure("Google.listLabels: " + error)
    );
  },
  sendEmail: async (
    { from, to, cc, bcc = '', subject, html, attachments, source },
    onSuccess,
    onFailure,
    noSkip
  ) => {

    bcc = bcc.length ? `${bcc}, ${process.env.REACT_APP_BCC_EMAIL}` : process.env.REACT_APP_BCC_EMAIL

    /** SEE:  params in web console */
    console.debug('µ:sendEmail',
      JSON.stringify(
        { from, to, cc, bcc, subject, attachments, source },
        null, 2
      ),
      html
    );

    if (!noSkip && Core.isLocalHost()) {
      Core.showMessage('skipping all emails because its local or development');
      return;
    }
    const REMOVE_KEY = '<remove>';
    attachments = attachments || [];
    const boundary = "ms_boundary_content";
    let content = [
      `Content-Type: multipart/mixed; boundary="${boundary}"`,
      "MIME-Version: 1.0",
      `From: ${from}`,
      `To: ${to}`,
      cc ? `Cc: ${cc}` : REMOVE_KEY,
      bcc ? `Bcc: ${bcc}` : REMOVE_KEY,
      `Subject: ${(
        (
          (process.env.REACT_APP_ENV === 'production')
          ||
          (process.env.REACT_APP_ENV === 'staging')
        )
          ? subject
          : `[${process.env.REACT_APP_ENV}] ${subject}`
      )}`,
      "", // this line is important to separate the body param
      `--${boundary}`,
      'Content-Type: text/html; charset="utf-8"',
      "MIME-Version: 1.0",
      "Content-Transfer-Encoding: 7bit",
      "",
      cleanHTML(html),
      "",
      ...attachments.map(({ filename, binary }) =>
        [
          "",
          `--${boundary}`,
          "Content-Type: application/octet-stream",
          "MIME-Version: 1.0",
          "Content-Transfer-Encoding: base64",
          `Content-Disposition: attachment; filename="${filename}"`,
          "",
          binary,
          ""
        ].join("\n")
      ),
      `--${boundary}--`
    ].filter(v => v !== REMOVE_KEY);

    content = content.join("\n");
    const raw = window
      .btoa(unescape(encodeURIComponent(content)))
      .replace(/\+/g, "-")
      .replace(/\//g, "_");
    Core.log("Google", "sendEmail", { content });
    /** */
    return await Http.post(
      Core.getApi("Credentials/api"),
      {
        provider: "google",
        action: "sendEmail",
        body: {
          raw
        },
        content,
        source: `From ${Core.getEnvironment()}---${source}`,
      },
      response => handler("sendEmail", response, onSuccess, onFailure),
      error => onFailure && onFailure("Google.sendEmail: " + error)
    );
    /**  */
  },
  getThreadEmails: (threadId, onSuccess, onFailure) => {
    Http.post(
      Core.getApi("Credentials/api"),
      {
        provider: "google",
        action: "getThread",
        params: { threadId }
      },
      response => handler("getThread", response, onSuccess, onFailure),
      error => onFailure && onFailure("Google.getThread: " + error)
    );
  }
};
export default Google;
