function formatURL(url) {
  if (!!url.length) {
    if (url.length < 7 && !/^(h|ht|htt|http|https):*\/{0,2}$/i.test(url)) {
      url = "http://" + url.split("//").pop();
    } else if (url.length >= 7 && !/^http(s)*:*\/{0,2}\w+.*$/i.test(url)) {
      url = "http://" + url.split("//").pop();
    }
  }
  return url;
}
export default formatURL;
