import React, {Component, Fragment} from 'react';

import './Stepper.css';

class Stepper extends Component {
	constructor () {
		super( ...arguments );
		this.state = {
			headers: this.props.headers,
			views: this.props.views,
			active: this.props.active || 0
		};
	};
	previous() {
		this.setActive(
			this.state.active > 0
			? this.state.active - 1
			: 0
		);
	}
	next() {
		this.setActive(
			this.state.active < this.state.headers.length
			? this.state.active + 1
			: this.state.headers.length
		);
	}
	setActive = active => {
		this.setState({	active });
		if( this.props.onStep instanceof Function ){
			this.props.onStep( active );
		}
	}
	render() {
		return (
			<Fragment>
				<div className="Stepper">
					<div className="conector" style={{width:(this.state.headers.length-1)*96}}></div>
					<div className="items">
						{this.state.headers.map( ( item, index ) => (
							<div key={index} 
								className={
									(index===this.state.active)
									? 'active'
									: (index<this.state.active)
									? 'step before'
									: 'step after'
								}
								onClick={ev=>{
									this.setActive( index );
								}}>
								<span className="icon">{index+1}</span>
								<span className="title">{item}</span>
							</div>
						))}
					</div>
				</div>
				{this.state.views[this.state.active]||'missing view'}
			</Fragment>
		);
	}
}

export default Stepper;
