import { IconButton } from '@mui/material';
import { AppBar } from 'material-ui';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { HashRouter, NavLink, Route } from 'react-router-dom';
import Core, { colors } from '../../lib/Core';
import Store from '../../lib/Store';
import AnnouncementBell from '../Announcements/AnnouncementBell';
import Calendar from '../Calendar/Calendar';
import Engagements from '../Engagements/Engagements';
import EngagementsV3 from '../Engagements/EngagementsV3';
import HistoryMenu from '../HistoryMenu/HistoryMenu';
import Jobs from '../Jobs/Jobs';
import JobsV3 from '../Jobs/JobsV3';
import RecruiterHome from '../Recruiter/Home';
import Reports from '../Reports/Reports';
import Tools from '../Tools/Tools';
import ListPages from './ListPages';
import SideMenu from './MainSideMenu';

const muiTheme = getMuiTheme({
  palette: {
    accent1Color: '#009688',
  },
});

const styles = {
  AppBar: {
    title: {
      color: colors.purple,
      fontSize: 20,
      overflow: 'inherit',
      textOverflow: 'initial',
    },
    rightLabel: {
      color: colors.cyan,
    },
  },
};

class Main extends Component {
  constructor() {
    super(...arguments);
    let betaStatus = Core.getUser().testBucket;
    this.state = {
      drawerOpened: false,
      betaStatus: betaStatus,
    };
    Store.set('path', window.location.href);
  }
  render() {
    const { t } = this.props;
    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }
    const navLink = (section, icon, path) => {
      return (
        <div
          className={`navlink${new RegExp(path || section, 'i').test(this.props.match.path) ? ' active' : ''}`}
          onClick={(ev) => Core.go({ ...this.props, to: `/${(path || section.toLowerCase())}` })}
        >
          <i className="material-icons-block">{icon}</i>
          <span>{section}</span>
        </div>
      );
    };
    console.debug('Home', this.props);
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div>
          <AppBar
            title="10by10"
            className="Home-AppBar"
            titleStyle={styles.AppBar.title}
            style={{ backgroundColor: '#ffffff', position: 'fixed', top: 0 }}
            iconElementRight={
              <Fragment>
                <div className="tabs center ui-xl-min nowrap">
                  {Core.isTrusted() && navLink('Home', 'home', 'recruiter')}
                  {Core.isAdminOrRecruiterOnDev() && (
                    <Fragment>{navLink('Engagements', 'sync')}</Fragment>
                  )}
                  {navLink(t('nav.candidates'), 'face')}
                  {navLink('Jobs', 'business_center')}
                  {Core.isAdminOrCoordinator() && (
                    <Fragment>
                      {navLink('Employers', 'business')}
                      {navLink('Accounts', 'people')}
                      {navLink(t('nav.reports'), 'insert_chart_outlined')}
                    </Fragment>
                  )}
                </div>
                <AnnouncementBell />
                {Core.isAdminOrCoordinator() && (
                  <NavLink
                    exact
                    activeClassName="navlink active"
                    className="navlink float-right"
                    to="/v3/calendar"
                  >
                    <IconButton>
                      <i className="material-icons">calendar_today</i>
                    </IconButton>
                  </NavLink>
                )}
                <HistoryMenu />
              </Fragment>
            }
            onLeftIconButtonClick={(ev) =>
              this.setState({
                drawerOpened: !this.state.drawerOpened,
              })
            }
          />
          <SideMenu
            drawerOpened={this.state.drawerOpened}
            closeDrawer={(ev) => this.setState({ drawerOpened: false })}
            logout={Core.logout}
          />
          <div style={{ height: 'calc(100vh - 64px)', marginTop: 64 }}>
            <HashRouter>
              <>
                {Core.isRecruiter() && (
                  <Route path="/v3/recruiter" component={RecruiterHome} />
                )}
                {Core.isAdminOrRecruiterOnDev() && (
                  <HashRouter>
                    <>
                      <Route path="/v3/engagements" component={ListPages} />
                      <Route
                        path="/v3/engagement/view/:id"
                        component={Engagements}
                      />
                      <Route
                        path="/v3/engagement/represent/:id"
                        component={Engagements}
                      />
                      <Route
                        path="/v3/v1/engagements"
                        component={EngagementsV3}
                      />
                    </>
                  </HashRouter>
                )}
                <Route path="/v3/candidates" component={ListPages} />
                <Route path="/v3/v1/jobs" component={JobsV3} />
                <Route path="/v3/jobs" component={ListPages} />
                <Route path="/v3/job/view/:id" component={Jobs} />
                {Core.isAdminOrCoordinator() && (
                  <Fragment>
                    <Route path="/v3/accounts" component={ListPages} />
                    <Route path="/v3/calendar" component={Calendar} />
                    <Route path="/v3/employers" component={ListPages} />
                    <Route path="/v3/matcher" component={ListPages} />
                    <Route path="/v3/reports" component={Reports} />
                    <Route path="/v3/admin/tools" component={Tools} />
                  </Fragment>
                )}
              </>
            </HashRouter>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withTranslation()(Main);
