import Dialog from '@mui/material/Dialog';
import Link from '@mui/material/Link';
import Switch from '@mui/material/Switch';
import _ from 'lodash';
import { IconButton } from "@mui/material";
import AppBar from 'material-ui/AppBar';
import FlatButton from 'material-ui/FlatButton';
import Paper from 'material-ui/Paper';
import Snackbar from 'material-ui/Snackbar';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import dig from 'object-dig';
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';
import { Redirect } from 'react-router';
import Account from '../../../lib/Account';
import Candidate from '../../../lib/Candidate';
import CandidateSkillsAction from '../../../lib/CandidateSkillsAction';
import { allowAttrOnMustHaveOrNot } from '../../../lib/Constants';
import Core from '../../../lib/Core';
import Definition from '../../../lib/Definition';
import Engagement from '../../../lib/Engagement';
import Google from '../../../lib/Google';
import HistoryLog from '../../../lib/HistoryLog';
import { getCandidateModel, mapCandidate } from '../../../lib/models/candidate';
import ConflictDetector from '../../../lib/services/Candidates/ConflictDetector';
import { sendSafeEmail } from '../../../lib/services/Email/Email.lib';
import SovrenData from '../../../lib/SovrenData';
import Store from '../../../lib/Store';
import Streak from '../../../lib/Streak';
import EmailPreview from '../../Dialogs/EmailPreview';
import SuccessDialog from '../../Dialogs/Success';
import Col from '../../Forms/Col';
import Row from '../../Forms/Row';
import styles from '../../Forms/Styles';
import HistoryMenu from '../../HistoryMenu/HistoryMenu';
import ResumeMatch from '../../Shared/ResumeMatch';
import Stepper from '../../Stepper/Stepper';
import Admin from '../Forms/Admin';
import Basics from '../Forms/Basics';
import LinkedinFieldsForm from '../Forms/LinkedinFieldsForm';
import Match from '../Forms/Match';
import { NavLink } from 'react-router-dom';
import { validProfileURL } from '../../../lib/tools/validProfileURL.tool';
import { removeRichTextFormat } from '../../../lib/GenericTools.lib';

const queryString = require('query-string');
const required = 'This field is required';
const warningBackBtnText = 'NO-TAKE ME BACK';
const warningContinueBtnText = 'YES - Submit';

class CandidateEdit extends Component {
  continue = 0;
  confirm = false;
  constructor() {
    super(...arguments);
    let candFromParams = this.props.match ? this.props.match.params.id : null;
    let candidateId = this.props.candidateId || candFromParams;
    this.scrollToRole = !!this.props.match.path.match(/section/i);
    this.state = {
      ...getCandidateModel({ extended: true }),
      /** controller states */
      categories: [],
      allCategories: [],
      candidates: [],
      recruiters: [],
      snackbar: null,
      errorFirstName: '',
      errorLastName: '',
      errorEmail: '',
      errorPhone: '',
      errorLinkedIn: '',
      errorGitHub: '',
      errorPlatformRating: '',
      loadingComplete: true,
      step: 0,
      isDirty: false,
      dirtyAttrs: [],
      initial: 1,
      jobTypes: [Definition.getId('jobType', 'Fulltime')],
      /** SET DRAFT AS DEFAULT value */
      state: Definition.getId('state', 'Lead'),
      undergraduateDegree: null,
      stepperHeaders: ['BASICS', 'MATCH'],
      stepperViews: [],
      initiallyIsDuplicate: false,
      tmpIntruduced: new Date().toISOString(),
      _isMounted: false,
      showLinkedInForm: false,
      candidateId,
      permittedJobs: [],
      candidateNotFound: false,
    };
    this._prolongPopup = false;
    this._isMounted = false;
    this._isDone = false;
    this._isLeavingComponent = false;
    this._warningAlreadyShown = false;
    this._sendNewCandidateEmail = false;

    Store.set('path', window.location.href);
    Core.log({
      state: this.state,
      defs: Definition.getAll(),
    });
    setTimeout((st) => {
      this.setState((state) => {
        state.stepperViews.push(
          <Basics
            parent={this}
            cbOpenPdf={this.openPdf}
            updateJobs={this.updateJobs}
          />
        );
        state.stepperViews.push(<Match parent={this} />);
        return state;
      });

      if (Core.isAdminOrCoordinator()) {
        Account.getRecruiters((recruiters) =>
          this.setState((state) => {
            state.recruiters = [Core.getUser(), ...recruiters];
            state.stepperHeaders.push('ADMIN');
            state.stepperViews.push(<Admin parent={this} />);
            return state;
          })
        );
      } else {
        Account.get(Core.getUserId(), (recruiter) => {
          this.setState({
            accountId: Core.getUserId(),
            recruiter: recruiter,
            recruiters: [recruiter],
          });
        });
      }
    });
  }

  updateJobs = (permittedJobs) => {
    this.setState({ permittedJobs });
  };

  loadDefaultValues = () => {
    let roles = dig(this.state, 'queryParams', 'defaultRoles');
    let jobsPermitted = dig(this.state, 'queryParams', 'defaultJobs');

    if (!!roles) {
      roles = roles ? roles.split(',') : [];

      if (Array.isArray(this.state.roles) && Array.isArray(roles)) {
        roles = roles.concat(this.state.roles);
        roles = Array.from(new Set(roles.map((el) => String(el))));
      }

      this.setStateStore({ roles, key: 'roles' });
    }
    if (!!jobsPermitted) {
      jobsPermitted = jobsPermitted ? jobsPermitted.split(',') : [];

      if (
        Array.isArray(this.state.jobsPermitted) &&
        Array.isArray(jobsPermitted)
      ) {
        jobsPermitted = jobsPermitted.concat(this.state.jobsPermitted);
        jobsPermitted = Array.from(
          new Set(jobsPermitted.map((el) => String(el)))
        );
      }

      this.setStateStore({ jobsPermitted, key: 'jobsPermitted' }, () => {
        this.setState({ loadDefaultValuesComplete: true });
      });
    } else {
      this.setState({ loadDefaultValuesComplete: true });
    }
  };

  resetDirty = () => {
    this.setState({ isDirty: false, dirtyAttrs: [] });
  };

  checkForDirty = (state) => {
    /** @todo review eslint fixing - 2021-08-12 Thu µ */
    // let isRecordPersisted = !!this.state.id;

    let persistedState = this.state._persisted || {};
    let isDirty = false;
    let dirtyAttrs = this.state.dirtyAttrs.filter((attr) => attr !== state.key);

    /** @todo review eslint fixing - 2021-08-12 Thu µ */
    // if (isRecordPersisted || 1 === 1) {

    let persistedValue = persistedState[state.key];
    let currentValue = state[state.key];

    if (Array.isArray(currentValue)) {
      if (!Array.isArray(persistedValue)) {
        persistedValue = [];
      }
      isDirty = !_.isEqual(currentValue.sort(), persistedValue.sort());
    }
    else if (typeof currentValue === 'string') {
      isDirty = removeRichTextFormat(currentValue) !== removeRichTextFormat(persistedValue);
    }
    else {
      isDirty = true;
    }

    if (currentValue === undefined || persistedValue === undefined) {
      isDirty = false;
      console.debug('this.state', this.stated);
    }

    if (isDirty) {
      dirtyAttrs.push(state.key);
    }

    let uniqueValues = Array.from(new Set(dirtyAttrs));
    dirtyAttrs = uniqueValues;

    console.debug('isDirty', isDirty);
    console.debug('dirtyAttrs', dirtyAttrs);
    console.debug(currentValue);
    console.debug(persistedValue);
    console.debug(removeRichTextFormat(currentValue));
    console.debug(removeRichTextFormat(persistedValue));

    this.setState({ dirtyAttrs, isDirty });

    /** @todo review eslint fixing - 2021-08-12 Thu µ */
    // }
  };

  setStateStore = (state, cb) => {

    let newState = { ...state };

    if (typeof state === 'function') {
      newState = { ...state({}) };
    }

    if (newState.hasOwnProperty('key')) {
      this.checkForDirty(newState);
    }


    /** 
     * @todo
       * the function setCandidateOnStore no any sense, since it is commented since 2 years ago the main action in the function 
       * https://github.com/10By10/10by10-web-app-client/blame/0feaefc246d8a6c98a8aef197d689b3997c59094/src/components/Candidates/Edit/CandidateEdit.js#L267
       * 2021-11-18 Thu µ
     */
    /** * /
    this.setState(newState, (then) => {
      if (this.state.source !== 'jobs') {
        this.setCandidateOnStore();
      }
      cb && cb(this.state);
    });
    /** */
    this.setState(newState, cb);

  };

  warningMessage = () => {
    let rows = [],
      coverHtml = '';
    rows.push(...this.setMissingInformationInCandidateEmail(this.state));

    if (!!rows.length) {
      rows = rows.join('');
      this._prolongPopup = true;
      coverHtml = this.makeCoverHTMLForPopup(rows);
    }

    return rows.length ? (
      <div>
        <br />
        <div
          style={{ textAlign: 'left' }}
          dangerouslySetInnerHTML={{ __html: coverHtml }}
        ></div>
        <br />
      </div>
    ) : (
      false
    );
  };

  setCandidateOnStore = (ev) => {
    if (Core.isPath('candidate/create')) {
      Core.log('newCandidate', this.state);
      const newCandidate = {};
      Object.keys(getCandidateModel({ extended: true })).forEach(
        (key) => (newCandidate[key] = this.state[key])
      );
      // Store.set("newCandidate", newCandidate);
    }
  };

  checkPotentialDuplicatedCandidates = (next, final) => {
    /* Skip duplicate check if it was already a duplicate */
    if (this.state.initiallyIsDuplicate && false) {
      // deprecated
      next();
    } else {
      Candidate.getPotentialDuplicated(
        this.state,
        (potentialDuplicatedCandidateList) => {
          new ConflictDetector(
            this.state,
            potentialDuplicatedCandidateList,
            (result) => {
              console.log({ result });
              const isDuplicated =
                result.isStrongConflict || result.isWeakConflict;

              if (isDuplicated) {
                if (result.isRecruiterCando) {
                  let msg = '';
                  let type = '';
                  // this.setState({lastValidDuplicateDigest: result.digest});
                  if (
                    result.isStrongConflict ||
                    result.isPotentialStrongConflict
                  ) {
                    msg = (
                      <span>
                        You have submitted another candidate with the same&nbsp;
                        {result.attrsThatConflictedFormatted.join(', ')}.&nbsp;
                        Creating a duplicate candidate could delay your
                        representation.
                        <br />
                        Click&nbsp;
                        <span
                          className="anchor"
                          onClick={() => {
                            Core.go({
                              ...this.props,
                              to: `/candidate/edit/${result.identifiedDuplicateCando.id}`,
                              refresh: true,
                            });
                          }}
                        >
                          here
                        </span>
                        &nbsp; to edit the existing candidate
                      </span>
                    );

                    if (result.isStrongConflict) {
                      type = 'danger';
                    } else {
                      type = 'warning';
                    }

                    this.setState({ snackbar: { msg, type } });
                  }
                  else if (false) { }

                }
                else {
                  if (result.isStrongConflict) {
                    let msg = `WARNING - Another recruiter has representation for the jobs marked in Red.`;
                    let type = 'danger';
                    this.setState({ snackbar: { msg, type } });
                  } else if (result.isTbdConflict) {
                    let msg = `WARNING - You can still submit the candidate! Another recruiter may already have representation for the jobs marked in Gray. Your account team will review them once you submit or save your changes.`;
                    let type = 'warning';
                    this.setState({ snackbar: { msg, type } });
                  }
                }
                //send admin email
              }

              if (isDuplicated || result.isLegacyConflict) {
                this.setState({
                  ...this.operateDuplicate(
                    result,
                    result.isLegacyConflict && isDuplicated
                  ),
                  isDuplicate: true,
                });
              }

              this.setState(
                {
                  potentialDuplicatedCandidateList,
                  alreadyOwnIt: result.iOwnIt,
                  duplicateResult: result,
                  isOwnedRightNow: this.state.id
                    ? this.state.isOwnedRightNow
                    : !result.someoneOwnIt,
                  duplicateTriggerredBy: '',
                  ownershipResult: result.ownershipResult,
                  ownershipDupEmail: !!isDuplicated ? result.fnSendEmail : null,
                },
                () => {
                  /** CANDIDATE: potential duplicated */
                  next();
                }
              );
            }
          );
        }
      );
    }
  };

  operateDuplicate = (dupResult, showDuplicateWarnings) => {
    let pdc = dupResult.identifiedDuplicateCando;

    const state = {
      errorGitHub: '',
      errorLinkedIn: '',
      errorPhone: '',
      errorEmail: '',
      errorFirstName: '',
      errorLastName: '',
    };

    if (showDuplicateWarnings) {
      if (dupResult.attrsThatConflicted.includes('_gitHubURL')) {
        state.isDuplicate = true;
        // state.errorGitHub = "Warning: potential duplicate candidate";
        // state.snackbar =
        //     "WARNING, the GitHub URL is the same as another candidate";
      }

      if (dupResult.attrsThatConflicted.includes('_linkedInURL')) {
        state.isDuplicate = true;
        // state.errorLinkedIn ="Warning: potential duplicate candidate";
        // state.snackbar =
        //     "WARNING, the LinkedIn URL is the same as another candidate";
      }
      if (dupResult.attrsThatConflicted.includes('phone')) {
        state.isDuplicate = true;
        // state.errorPhone = "Warning: potential duplicate candidate";
        // state.snackbar =
        //     "WARNING, the phone number is the same as another candidate";
      }
      if (dupResult.attrsThatConflicted.includes('email')) {
        state.isDuplicate = true;
        // state.errorEmail = "Warning: potential duplicate candidate";
        // state.snackbar =
        //     "WARNING, the email is the same as another candidate";
      }

      if (dupResult.attrsThatConflicted.includes('Name')) {
        state.isDuplicate = true;
        // state.errorFirstName = "Warning: potential duplicate candidate";
        // state.errorLastName = "Warning: potential duplicate candidate";
        // state.snackbar =
        //     "WARNING, there is another candidate with the same name. Click Save again to submit potential duplicate candidate.";
      }
    }

    if (pdc.duplicatedLevel === 'primary' || !pdc.duplicatedLevel) {
      this._duplicatedFrom = pdc;
      state.duplicatedFrom = pdc.id;
      state.duplicatedLevel = 'secondary';
    } else {
      state.duplicatedFrom = pdc.duplicatedFrom;
      state.duplicatedLevel = 'secondary';
    }
    return state;
  };

  isValid = (ev) => {
    const { state } = this;
    const { yearsOfExperience: relevantYearsOfExperience = '' } = state;
    /** FIRST NAME: required */
    if (String(this.state.firstName).trim().length < 1) {
      window.scrollTo(0, 0);
      this.setState({
        snackbar: { msg: 'First Name is required', type: 'danger' },
        errorFirstName: required,
      });
      return false;
    }
    /** LAST NAME: required */
    if (String(this.state.lastName).trim().length < 1) {
      window.scrollTo(0, 0);
      this.setState({
        snackbar: { msg: 'Last Name is required', type: 'danger' },
        errorLastName: required,
      });
      return false;
    }
    /** EMAIL: validation */
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.state.email
      )
    ) {
      window.scrollTo(0, 0);
      this.setState({
        snackbar: { msg: 'Email is invalid', type: 'danger' },
        errorEmail: 'Please enter a valid email',
      });
      return false;
    }

    // LinkedIn: validation
    if (
      !!(this.state.linkedInURL || '').trim().length &&
      !validProfileURL(this.state.linkedInURL)
    ) {
      window.scrollTo(0, 0);
      this.setState({
        snackbar: { msg: 'LinkedIn profile url is invalid', type: 'danger' },
        errorLinkedIn: 'Please enter a valid profile url',
      });
      return false;
    }

    // GitHub: validation
    if (
      !!(this.state.gitHubURL || '').trim().length &&
      !validProfileURL(this.state.gitHubURL)
    ) {
      window.scrollTo(0, 0);
      this.setState({
        snackbar: { msg: 'Github profile url is invalid', type: 'danger' },
        errorGitHub: 'Please enter a valid profile url',
      });
      return false;
    }

    if (!relevantYearsOfExperience) {
      this.setState({
        snackbar: {
          msg: 'Relevant Years of Experience is required',
          type: 'danger',
        },
        errorRelevantYearsOfExperience:
          'Please set a valid number for relevant years of experience.',
      });
      return false;
    }
    /** PHONE: required */
    // if (String(this.state.phone).trim().length < 1) {
    //   window.scrollTo(0, 0);
    //   this.setState({
    //     snackbar: "Phone is required",
    //     errorPhone: required
    //   });
    //   return false;
    // }
    /** PHONE: required */
    // if (String(this.state.jobsHaveCandidatePermission).trim().length < 1) {
    //   window.scrollTo(0, 0);
    //   this.setState({
    //     snackbar: "Jobs with candidate's permission",
    //     errorJobsHaveCandidatePermission: required
    //   });
    //   return false;
    // }
    // /** VISA: required */
    // if (isNaN(this.state.visa)) {
    //   window.scrollTo(0, 0);
    //   this.setState({
    //     snackbar: "Visa is required",
    //     errorVisa: required
    //   });
    //   return false;
    // }
    /** VISA: required */
    // if (!this.state.roles.length) {
    //   window.scrollTo(0, 320);
    //   this.setState({
    //     snackbar: "At least 1 role is required",
    //     errorRoles: required
    //   });
    //   return false;
    // }
    /** * /
    if (Core.isAdminOrCoordinator() && !this.state.platformRating) {
      window.scrollTo(0, 1000);
      this.setState({
        snackbar: "Platform rating is required (under Admin or Coordinator)",
        errorPlatformRating: required
      });
      return false;
    }
    /** */
    /** ALL TESTS PASSED */
    return true;
  };

  checkAndSendEditEmail = () => {
    // if(this.state.isDirty){
    //   this.resetDirty();
    // }
  };

  beforeStateUpdateActions = () => {
    this.checkAndSendEditEmail();
  };

  processDirtyAttrAndStates = () => {
    const { dirtyAttrs } = this.state;
    const oldState = this.state._persisted;
    const newState = this.state;
    let finalDirtyAttrs = [];

    finalDirtyAttrs = dirtyAttrs.map((val, index) => {
      let dirtyObject = {};
      dirtyObject.key = val;
      dirtyObject.label = val;
      dirtyObject.oldState = oldState[val];
      dirtyObject.newState = newState[val];

      return dirtyObject;
    });

    if (!!finalDirtyAttrs.length)
      CandidateSkillsAction.processUpdate(this.state, finalDirtyAttrs);
  };

  update = (success, final) => {
    if (this.isValid()) {
      this.setState({ formIsSubmitting: true });
      this.checkPotentialDuplicatedCandidates((em) => {
        const candidate = { ...this.state };

        if (!candidate.introduced && this.isSubmitting()) {
          candidate.introduced = candidate.tmpIntruduced;
          this._sendNewCandidateEmail = true;
        }
        if (candidate.id) {
          /* Jira Ticket Ticket VER-195 ===>>> */
          /* >Match candidate & job - prefill roles & visa. */
          /* -It is needed to remove searchConfig from the candidate
            in order to preset the chips on the filter for match
            when the candidate change.
          */
          candidate.searchConfig = {};
          /* <<< === Jira Ticket Ticket VER-195 */
          const queryParams = this.state.queryParams;
          if (
            parseInt(queryParams.conflict_attempt) === 1 &&
            queryParams.ownership === 'false'
          ) {
            let introduced = new Date().toISOString();
            let privateNotes = `${candidate.privateNotes} <br/> candidate was submitted on ${candidate.introduced} and resubmitted on ${introduced}`;
            candidate.privateNotes = privateNotes;
            candidate.introduced = introduced;
            candidate.isOwnedRightNow = true;
            this.setState({ privateNotes, introduced, isOwnedRightNow: true });
          }
          console.debug('candidate', candidate);
          Candidate.update(
            candidate.id,
            candidate,
            (response) => {
              this.checkPotentialDuplicatedCandidates((em) => {
                // const dupEmail = this.state.ownershipDupEmail;
                // !!dupEmail && dupEmail();
              });

              this.processDirtyAttrAndStates();
              this.beforeStateUpdateActions();
              this.setState({ candidate: response }); //getting the latest state

              /**
               * WHEN state Hold and holdDate is set
               * there will be additional request running
               * on background
               */
              if (
                Definition.test('state', candidate.state, /Hold/) &&
                !!candidate.holdDate
              ) {
                const status = 'H - Candidate'; // "W - On Hold";
                const streakStatusKey = Streak.getDropdownKey('Status', status);
                if (streakStatusKey) {
                  const engagements = candidate.engagements.filter(
                    (eng) => eng.state === 'Open'
                  );
                  const next = (em) => {
                    setTimeout((st) => {
                      if (!!engagements.length) {
                        const eng = engagements.pop();
                        Engagement.update(
                          eng,
                          {
                            status,
                            holdDate: candidate.holdDate,
                          },
                          next,
                          next
                        );
                      }
                    });
                  };
                  next();
                }
              }
              success('edit')(response);
            },
            (err) => {
              this.setState({
                jobsPermitted: Object(this.state._persisted).jobsPermitted,
                formIsSubmitting: false,
              });
              this.setState({
                snackbar: {
                  msg: err,
                  type: 'danger',
                },
              });
            }
          );
        } else {
          this.insertNewCandidate(candidate, (result) => {
            this.setState({ _persisted: result }, () => {
              this.checkPotentialDuplicatedCandidates((em) => {
                // console.log('sendi')
                // const dupEmail = this.state.ownershipDupEmail;
                // !!dupEmail && dupEmail();
              });
              success('new')(result);
            });
          });
          Store.remove('newCandidate');
        }

        //update duplicated from
        if (!!Object(this._duplicatedFrom).id) {
          Candidate.update(
            candidate.duplicatedFrom,
            { duplicatedLevel: 'primary', isDuplicate: true },
            (response) => { }
          );
        }
      }, final);
    }
  };
  /** Rename resumes uploaded before New Candidate was first saved.  Then insert candidate.
   *  Ex: http(s)://<host>/api/ImportContainer/<S3 bucketname>%2F<container>/download/<filename>
   * */
  insertNewCandidate = (candidate, success) => {
    const resumes = [];
    const currentResumes = [...this.state.resumes];
    const next = (em) => {
      if (!!currentResumes.length) {
        const resume = currentResumes.pop();
        var urlParts = resume.url.split('/');
        var olfFilename = urlParts.pop();
        if (olfFilename.startsWith('_')) {
          urlParts.pop();
          var bucketnameContainer = urlParts.pop();
          var bucketname = bucketnameContainer.replace('%2F', '/');
          var newFilename = `${this.state.firstName
            .replace(/ /g, '')
            .toUpperCase()}_${this.state.lastName
              .replace(/ /g, '')
              .toUpperCase()}${olfFilename.slice(1)}`;
          var newURL = `${urlParts.join(
            '/'
          )}/${bucketnameContainer}/download/${newFilename}`;
          Core.renameS3Resume(
            bucketname,
            olfFilename,
            newFilename,
            (response) => {
              resume.url = newURL;
              resumes.push(resume);
              next();
            },
            (error) => {
              Core.failure({
                source: 'CandidateEdit.js>insertNewCandidate>renameS3Resume',
                exception: error,
                params: {
                  bucketname,
                  olfFilename,
                  newFilename
                }
              });
              resumes.push(resume);
              next();
            }
          );
        } else {
          resumes.push(resume);
          next();
        }
      } else {
        this.setState({ resumes }, (then) => {
          Candidate.post(candidate, success, () => {
            this.setState({ formIsSubmitting: false });
          });
        });
      }
    };
    next();
  };

  /*
   * Build warning messages and email by determining which critical fields are missing
   */
  getMissingCandidateResume = (candidate) => {
    let singleRow = '';

    if (!candidate.resumes.length) {
      singleRow =
        '<li><span style="color: red;"><strong>Resume</strong></span> (page 1)</li>';
    }
    return singleRow;
  };
  getMissingCandidateLinkedInURL = (candidate) => {
    let singleRow = '';

    if (!candidate.linkedInURL.length) {
      singleRow = `<li><span style="color: red;"><strong>LinkedIn URL</strong></span> (page 1)</li>`;
    }
    return singleRow;
  };
  getMissingCandidatePhone = (candidate) => {
    let singleRow = '';
    if (candidate.phone.length <= 4) {
      singleRow = `<li><span style="color: red;"><strong>Phone</strong></span> (page 1)</li>`;
    }
    return singleRow;
  };
  getMissingCandidateRoles = (candidate) => {
    let singleRow = '';

    if (!!candidate.roles && !candidate.roles.length) {
      singleRow = `<li><span style="color: red;"><strong>Roles</strong></span> selection (page 1)</li>`;
    }
    return singleRow;
  };
  getMissingCandidatePermittedJobs = (candidate) => {
    let singleRow = '';

    if (!!candidate.jobsPermitted && !candidate.jobsPermitted.length) {
      singleRow = `<li><span style="color: red;"><strong>Jobs your candidate approved for Submission</strong></span> selection (page 1)</li>`;
    }
    return singleRow;
  };
  getMissingCandidateVisa = (candidate) => {
    let singleRow = '';

    if (candidate.visa === 0) {
      singleRow = `<li><span style="color: red;"><strong>Candidate Visa</strong></span> status (page 1)</li>`;
    }
    return singleRow;
  };
  getMissingCandidateWorkExperience = (candidate) => {
    let singleRow = '';

    if (
      candidate.yearsOfExperience === -1 ||
      candidate.yearsOfExperience === ''
    ) {
      singleRow = `<li><span style="color: red;"><strong>Years of Relevant Work Experience</strong></span> selection (page 2)</li>`;
    }
    return singleRow;
  };
  getMissingCandidateTechnicalExperience = (candidate) => {
    let singleRow = '';

    if (!!candidate.technicalSkills && !candidate.technicalSkills.length) {
      singleRow = `<li><span style="color: red;"><strong>Technical Skills</strong></span> selection (page 2)</li>`;
    }
    return singleRow;
  };
  getMissingCandidateSalary = (candidate) => {
    let singleRow = '';

    if (!candidate.minimumSalary) {
      singleRow = `<li><span style="color: red;"><strong>Minimum Salary</strong></span> requirement (page 2)</li>`;
    }
    return singleRow;
  };
  getMissingCandidateWorkRegion = (candidate) => {
    let singleRow = '';

    if (
      !candidate.workLocationIds.length ||
      candidate.workLocationIds.includes(0)
    ) {
      singleRow = `<li><span style="color: red;"><strong>Desirable Work Region</strong></span> preference (page 2)</li>`;
    }
    return singleRow;
  };
  setMissingQuestionAnswersInEmail = (heading, finalObject) => {
    let singleRow = '';

    if (!!finalObject && !!Object.keys(finalObject).length) {
      singleRow = `<tr>
                      <td>
                        ${heading}
                        <ul>`;
      Object.values(finalObject).forEach((qa) => {
        singleRow += `<li>
                              <span style="color: purple"><strong>${qa.title}</strong></span>
                              <ul>`;
        qa.q.forEach((quest) => {
          singleRow += `<li>${quest}</li>`;
        });
        qa.r.forEach((quest) => {
          singleRow += `<li>${quest}</li>`;
        });
        singleRow += '</ul></li>';
      });
      singleRow += '</ul></td></tr>';
    }
    return singleRow;
  };
  setMissingQuestionAnswersInData = (candidate) => {
    const { permittedJobs } = this.state;
    const { permittedJobsAnswers } = candidate;

    let finalObject = {},
      mySavedValue = '',
      missedQuestions = [];

    const getJobQuestionValue = (jobId, qId) => {
      let jobValue = Object(permittedJobsAnswers)[jobId] || [];
      return Object(jobValue.find((el) => parseInt(el.qId) === parseInt(qId)))
        .ans;
    };

    permittedJobs.forEach((job) => {
      missedQuestions = job.mustHaveQuestions
        .filter((question) => {
          mySavedValue = getJobQuestionValue(job.id, question.id);
          return !mySavedValue;
        })
        .map((q) => q.question);

      if (!!missedQuestions.length) {
        finalObject[job.id] = {
          title: `${job.employer.name} - ${job.jobTitle} Questions`,
          q: missedQuestions,
          r: [],
        };
      }
    });

    return finalObject;
  };
  getMissingCandidateQuestions = (candidate) => {
    const finalObject = this.setMissingQuestionAnswersInData(candidate);
    const heading =
      '<span style="color: red; text-decoration: underline;"><strong>Submission Blockers:</strong></span> Missing information for selected jobs';

    return this.setMissingQuestionAnswersInEmail(heading, finalObject);
  };
  setMissingMustHavesOrNotInData = () => {
    const { permittedJobs } = this.state;
    let finalObjectMustHave = {},
      candidMustDetail = [],
      candidMustNotDetail = [];

    permittedJobs.forEach((job) => {
      candidMustDetail.push(
        allowAttrOnMustHaveOrNot
          .filter(
            (mustHaves) =>
              !Object.keys(job.candidateMustDetails).includes(mustHaves)
          )
          .join(', ')
      );
      candidMustNotDetail.push(
        allowAttrOnMustHaveOrNot
          .filter(
            (mustHaveNot) =>
              !Object.keys(job.candidateMustNotDetails).includes(mustHaveNot)
          )
          .join(', ')
      );

      if (!!candidMustDetail.length || !!candidMustNotDetail.length) {
        finalObjectMustHave[job.id] = {
          title: `${job.jobTitle} - ${job.employer.name}`,
          q: candidMustDetail,
          r: candidMustNotDetail,
        };
      }
    });

    return {
      finalObjectMustHave,
    };
  };
  getMissingMustHaveOrNotHaves = () => {
    const { firstName, lastName } = this.state;
    const finalObject = this.setMissingMustHavesOrNotInData();
    const heading = `Did ${firstName} ${lastName} meet the <span><strong>minimum requirements</strong>?</span>`;
    const finalRow = this.setMissingQuestionAnswersInEmail(
      heading,
      finalObject.finalObjectMustHave
    );

    return finalRow;
  };
  setMissingInformationInCandidateEmail = (candidate) => {
    const { t } = this.props;

    const resumeInfo = this.getMissingCandidateResume(candidate);
    const linkedInUrlInfo = this.getMissingCandidateLinkedInURL(candidate);
    const phoneInfo = this.getMissingCandidatePhone(candidate);
    const visaInfo = this.getMissingCandidateVisa(candidate);
    const rolesInfo = this.getMissingCandidateRoles(candidate);
    const permittedJobsInfo = this.getMissingCandidatePermittedJobs(candidate);
    const expInfo = this.getMissingCandidateWorkExperience(candidate);
    const tSkillsInfo = this.getMissingCandidateTechnicalExperience(candidate);
    const salaryInfo = this.getMissingCandidateSalary(candidate);

    /* epic-3338(new locations)-story-3455 | 2021-07-14 Wed µ */
    /** * /
    const workRegionInfo = this.getMissingCandidateWorkRegion(candidate);
    /** */

    function makeStatement({ title = '', page = NaN }) {
      return `<li><span style="color: red;"><strong>${title}</strong></span> (page ${page})</li>`;
    }

    const {
      desiredEmploymentTypes = [],
      candidateLocations = [],
      inOfficeRemoteFlags = [],
    } = candidate;

    const desiredEmploymentTypesStatement = !!desiredEmploymentTypes.length
      ? ''
      : makeStatement({
        title: t('candidate.desiredEmploymentTypes.label'),
        page: 2,
      });
    const candidateLocationStatement = !!candidateLocations.length
      ? ''
      : makeStatement({
        title: t('candidate.candidateLocations.label'),
        page: 2,
      });
    const inOfficeRemoteFlagsStatement = !!inOfficeRemoteFlags.length
      ? ''
      : makeStatement({
        title: t('candidate.inOfficeRemoteFlags.title'),
        page: 2,
      });

    let basicFields = '';

    if (
      resumeInfo ||
      linkedInUrlInfo ||
      phoneInfo ||
      visaInfo ||
      rolesInfo ||
      permittedJobsInfo ||
      expInfo ||
      tSkillsInfo ||
      salaryInfo ||
      desiredEmploymentTypesStatement ||
      candidateLocationStatement ||
      inOfficeRemoteFlagsStatement
    )
      basicFields = [
        `<tr><td><ul>`,
        resumeInfo,
        linkedInUrlInfo,
        phoneInfo,
        visaInfo,
        rolesInfo,
        permittedJobsInfo,
        desiredEmploymentTypesStatement,
        candidateLocationStatement,
        inOfficeRemoteFlagsStatement,
        salaryInfo,
        expInfo,
        tSkillsInfo,
        `</ul></td></tr>`,
      ].join('');

    return [
      basicFields,
      this.getMissingCandidateQuestions(candidate),
      // this.getMissingMustHaveOrNotHaves(candidate)
    ];
  };

  makeCoverHTMLForPopup = (rows) => {
    const html = `
      <table>
        <tr>
            <td style="color: red;">
              ${!(this.state.jobsPermitted || []).length
        ? 'You will not have representation for this candidate until the candidate grants permission to be submitted and you add jobs the “Jobs that candidate has APPROVED for submission” section'
        : ''
      }
            </td>
        </tr>
        <tr>
          <td>
            <span style="color: red; text-decoration: underline;"><strong>Key Information Missing:</strong></span> Processing will be delayed due to missing information.
          </td>
        </tr>
        ${rows}
      </table>
    `.trim();

    return html;
  };

  makeCoverHTML = (rows, candidate) => {
    const { firstName, lastName } = this.state;
    const html = `
      <HTML>
        <head></head>
        <body>
          <table>
            <tr>
              <td>
                SUBJECT - ${firstName} ${lastName} processing delay
              </td>
            </tr>
            <tr><td>&nbsp;</td></tr>
              <tr>
                <td style="color: red;">
                  ${!(this.state.jobsPermitted || []).length
        ? 'You will not have representation for this candidate until the candidate grants permission to be submitted and you add jobs the “Jobs that candidate has APPROVED for submission” section'
        : ''
      }
                </td>
            </tr>
            <tr>
              <td>
                <span style="color: red; text-decoration: underline;"><strong>Key Information Missing:</strong></span> Processing will be delayed due to missing information. <a href="${window.location.host
      }${Core.getLink(
        `/candidate/edit/${candidate.id}`
      )}">Click here to go back to edit the candidate.</a>
              </td>
            </tr>
            <tr><td>&nbsp;</td></tr>
            ${rows}
          </table>
        </body>
      </html>
    `.trim();
    return html;
  };

  /** SEND NEW CANDIDATE EMAIL */
  sendNewCandidateEmail = (candidate) => {
    var from = Core.getNewCandidateReceiverNotiFromEmail();
    var to;
    const recruiter = candidate.recruiter;
    /** * /
    Object(
      this.state.recruiters.find(
        recruiter => recruiter.id === this.state.accountId
      )
    );
    /** */

    const roles = Definition.getLabels('roles', this.state.roles);

    if (Core.isDuc() || Core.isTeam()) {
      to =
        (recruiter.firstName + ' ' + recruiter.lastName).trim() +
        ' <' +
        recruiter.email +
        '>';
    } else {
      to = Core.getNewCandidateReceiverNotiToEmail();
    }
    var cc = Core.getNewCandidateReceiverNotiToEmail();
    const subject = `NEW CANDIDATE | ${this.state.firstName} ${this.state.lastName
      } | submitted by ${recruiter.firstName} ${recruiter.lastName} | ${roles.length ? roles.join(', ') : 'no roles added'
      } (using ${!!window.location.href.includes('local')
        ? 'localhost'
        : window.location.href.split('/')[2]
      })`;

    const html = this.candidateEmailAttrDiffTable(candidate);
    var params = {
      from,
      to,
      cc,
      subject,
      html,
      source: 'New Candidate Email - CandidateEdit.js => line 944',
    };
    Core.log('Email notification for new Candidate', params);
    sendSafeEmail(params, null, (error) => Core.showMessage(error));
  };

  sendMissingAttributeEmail = (candidate) => {
    if (
      candidate._isDraft ||
      Core.isAdminOrCoordinator() ||
      !this._sendNewCandidateEmail
    ) {
      return;
    }

    const from = Core.getNewCandidateReceiverNotiFromEmail();
    const to = Core.getUserName() + ' <' + Core.getSessionEmail() + '>';
    const cc = Core.getNewCandidateReceiverNotiToEmail();

    const recruiter = candidate.recruiter;

    const roles = Definition.getLabels('roles', this.state.roles);

    const subject = `CANDIDATE MISSING INFO | ${this.state.firstName} ${this.state.lastName
      } | submitted by ${recruiter.firstName} ${recruiter.lastName} | ${roles.length ? roles.join(', ') : 'no roles added'
      }`;

    let rows = [];
    rows.push(...this.setMissingInformationInCandidateEmail(candidate));

    if (!!rows.length > 0) {
      rows = rows.join('');
    }

    if (!!rows) {
      const html = this.makeCoverHTML(rows, candidate);

      var params = {
        from,
        to,
        cc,
        subject,
        html,
        source: 'Missing Attribute Email - CandidateEdit.js => line 984',
      };
      Core.log('Email notification for new Candidate', params);
      Google.sendEmail(params, null, (error) => Core.showMessage(error));
    }
  };

  // routine to get the url when the candidate record was edited on a non-produciton platform
  getAdminHelperUrlPath = (path) => {
    // figure out our current base
    let helperUrl = null;
    if (!!window.location.href.includes('beta')) {
      let url = window.location.href.split('/');
      helperUrl = `${url[0]}/${url[1]}/${url[2]}/#/${path}`;
    }
    return helperUrl;
  };

  candidateEmailAttrDiffTable = (candidate) => {
    let editCandidateUrl = Core.getPath(`candidate/edit/${candidate.id}`);
    let helperEditCandidateUrl = this.getAdminHelperUrlPath(
      `candidate/edit/${candidate.id}`
    );
    let matchCandidateUrl = Core.getPath(`candidate/matchNew/${candidate.id}`);
    let helperMatchCandidateUrl = this.getAdminHelperUrlPath(
      `candidate/matchNew/${candidate.id}`
    );
    let dupCandidateUrl = Core.getPath(
      `candidates?viewDup=t&cId=${candidate.id}`
    );
    let helperDupCandidateUrl = this.getAdminHelperUrlPath(
      `candidates?viewDup=t&cId=${candidate.id}`
    );
    let state = this.state;
    const { ownershipDupEmail } = this.state;

    const mappings = [
      { key: 'acceptOffer', def: 'offerAcceptance' },
      { key: 'candidatePreferIds', def: 'contactPreference' },
      { key: 'workLocationIds', def: 'locationCandidate' },
      { key: 'visa', def: 'visaCandidate' },
      { key: 'desiredStage', def: 'stage' },
      { key: 'workRemotly', def: 'remotelyWork' },
      { key: 'currentlyEmployed', def: 'diversity' },
      { key: 'relationship', def: 'relationShip' },
      { key: 'technicalSkills', def: 'technicalSkills' },
      { key: 'roles', def: 'roles' },
      { key: 'undergraduateDegree', def: 'undergraduateDegree' },
      { key: 'level', def: 'level' },
      { key: 'state', def: 'state' },
      { key: 'platformRating', def: 'platformRating' },
      { key: 'recruiterRating', def: 'recruiterRating' },
      { key: 'positiveSignals', def: 'positiveSignals' },
      { key: 'negativeSignals', def: 'negativeSignals' },
      { key: 'diversity', def: 'diversity' },
    ];

    const mappingKeys = mappings.map((obj) => obj.key);
    let oldState = candidate._persisted;
    let newState = candidate;
    let finalDirtyAttrs = state.dirtyAttrs
      .filter((el) => !!el)
      .map((val, index) => {
        let oldStateVal = oldState[val];
        let newStateVal = newState[val];

        if (mappingKeys.includes(val)) {
          let foundOne = mappings.find((obj) => obj.key === val);

          if (!!foundOne) {
            if (Array.isArray(newStateVal)) {
              oldStateVal = Definition.getLabelsWithId(
                foundOne['def'],
                oldStateVal
              )
                .map(
                  (label) =>
                    `<span style="font-size: 10px;">${label}</span><br/>`
                )
                .join('');
              newStateVal = Definition.getLabelsWithId(
                foundOne['def'],
                newStateVal
              )
                .map(
                  (label) =>
                    `<span style="font-size: 10px;">${label}</span><br/>`
                )
                .join('');
            } else {
              oldStateVal = `<span style="font-size: 10px;">${Definition.getLabelWithId(
                foundOne['def'],
                oldStateVal
              )}</span><br/>`;
              newStateVal = `<span style="font-size: 10px;">${Definition.getLabelWithId(
                foundOne['def'],
                newStateVal
              )}</span><br/>`;
            }
          }
        }

        const jobsFields = ['jobsPermitted', 'jobsPitched', 'jobsDeclined'];

        if (jobsFields.includes(val)) {
          const list = Array.isArray(newState[`_${val}`])
            ? newState[`_${val}`]
            : [];
          oldStateVal = (oldStateVal || [])
            .map((id) => {
              let job = list.find((job) => job.id === id) || {};
              return `${dig(job, 'employer', 'name') || ''} | ${job.jobTitle || ''
                } | ${job.addressCity || ''} | jid:${dig(job, 'id')} | eid:${dig(
                  job,
                  'employer',
                  'id'
                )}`;
            })
            .map(
              (label) => `<span style="font-size: 10px;">${label}</span><br/>`
            )
            .join('<br/>');

          newStateVal = (newStateVal || [])
            .map((id) => {
              let job = list.find((job) => job.id === id) || {};

              return `${dig(job, 'employer', 'name') || ''} | ${job.jobTitle || ''
                } | ${job.addressCity || ''} | jid:${dig(job, 'id')} | eid:${dig(
                  job,
                  'employer',
                  'id'
                )}`;
            })
            .map(
              (label) => `<span style="font-size: 10px;">${label}</span><br/>`
            )
            .join('<br/>');
        }

        if (val === 'permittedJobsAnswers') {
          oldStateVal = Object.keys(Object(oldStateVal))
            .map((jobId) => {
              let questions = (oldStateVal[jobId] || []).map((obj) => {
                return `<span style="font-size: 10px;">question: ${dig(
                  obj,
                  'question'
                )}</span><br/><span style="font-size: 10px;">answer: ${dig(
                  obj,
                  'ans'
                )}</span>`;
              });

              return `<span style="font-size: 10px;"><strong>For Job ${jobId}</strong></span><hr/><span style="font-size: 10px;">${questions.join(
                ''
              )}</span><hr/>`;
            })
            .join('');

          newStateVal = Object.keys(Object(newStateVal))
            .map((jobId) => {
              let questions = (newStateVal[jobId] || []).map((obj) => {
                return `<span style="font-size: 10px;">question: ${dig(
                  obj,
                  'question'
                )}</span><br/><span style="font-size: 10px;">answer: ${dig(
                  obj,
                  'ans'
                )}</span>`;
              });

              return `<span style="font-size: 10px;"><strong>For Job ${jobId}</strong></span><hr/><span style="font-size: 10px;">${questions.join(
                ''
              )}</span><hr/>`;
            })
            .join('');
        }

        return { key: val, oldStateVal, newStateVal };
      });

    let rows = finalDirtyAttrs.map((obj) => {
      return `<tr>
                  <td>${obj.key}</td>
                  <td>${obj.oldStateVal}</td>
                  <td>${obj.newStateVal}</td>
                </tr>`;
    });

    let html = `<html>
        <head></head>
        <body>
            ${!!ownershipDupEmail ? ownershipDupEmail() : ''}<br />
            The following candidate attributes were edited:<br /><br />
            <a href="${editCandidateUrl}">View Candidate </a>
            <a href="${matchCandidateUrl}"> | Match Candidate </a>
            <a href="${dupCandidateUrl}"> | View Conflict Details</a><br />
            ${!!helperEditCandidateUrl
        ? `<a href=${helperEditCandidateUrl}>View Candidate </a>`
        : ''
      }
            ${!!helperMatchCandidateUrl
        ? `<a href=${helperMatchCandidateUrl}> | Match Candidate </a>`
        : ''
      }
            ${!!helperDupCandidateUrl
        ? `<a href=${helperDupCandidateUrl}> | View Conflict Details</a>`
        : ''
      }
            <br />
            <table style="width:60%" border="1">
              <tr>
                <th>Attribute</th>
                <th>Old Value</th> 
                <th>New Value</th>
              </tr>
              ${rows.join('')}
            </table>
        </body></html>`;

    return html;
  };

  sendCandidateEditEmail = (candidate) => {
    if (
      candidate._isDraft ||
      Core.isAdminOrCoordinator() ||
      !this.state.isDirty
    ) {
      return;
    }

    let { ownershipDupEmail } = this.state;

    let from = Core.getNewCandidateReceiverNotiFromEmail();
    let to = Core.getNewCandidateReceiverNotiToEmail();
    let cc = Core.getNewCandidateReceiverNotiToEmail();

    let subject = `Edit CANDIDATE | ${this.state.firstName} ${this.state.lastName
      } | edited by ${Core.getUserName()}`;

    const html = this.candidateEmailAttrDiffTable(candidate);
    subject = !!ownershipDupEmail ? `Duplicate - ${subject}` : subject;

    let params = {
      from,
      to,
      cc,
      subject,
      html,
      source: 'Candidate Edit Email - CandidateEdit.js => line 1118',
    };
    Core.log('Email notification for existing Candidate', params);
    Google.sendEmail(params, null, (error) => Core.showMessage(error));
  };

  /** SEND DUPLICATE CANDIDATE EMAIL */
  sendDuplicateCandidateEmail = (candidate) => {
    // Deprecated
    var from = Core.getNewCandidateReceiverNotiFromEmail();
    var to;
    const recruiter = candidate.recruiter;

    if (Core.isDuc() || Core.isTeam()) {
      to =
        (recruiter.firstName + ' ' + recruiter.lastName).trim() +
        ' <' +
        recruiter.email +
        '>';
    } else {
      to = Core.getNewCandidateReceiverNotiToEmail();
    }
    let cc = Core.getNewCandidateReceiverNotiToEmail();
    let subject = `Duplicate Candidate: ${recruiter.firstName} ${recruiter.lastName} attempt to submit ${this.state.firstName} ${this.state.lastName}`;

    let html = `<html><head></head><body><table>`;

    Core.log(
      'potential duplicated candidate list',
      this.state.potentialDuplicatedCandidateList
    );
    /** CANDIDATE: potential duplicated */
    if (!!this.state.potentialDuplicatedCandidateList.length) {
      const pdcs = this.state.potentialDuplicatedCandidateList;
      let state = { isDuplicate: false };
      let duplicative = `<tr><td>Duplicative `;

      pdcs.forEach((pdc) => {
        //state = this.operateDuplicate(pdcs[i]);
        // if(state.isDuplicate){
        //   return;
        // }

        if (
          !!pdc.firstName.length &&
          pdc.firstName.toLowerCase() === this.state.firstName.toLowerCase() &&
          !!pdc.lastName.length &&
          pdc.lastName.toLowerCase() === this.state.lastName.toLowerCase()
        ) {
          state.isDuplicate = true;
          duplicative += `Name: ${this.state.firstName} ${this.state.lastName}, `;
        }

        if (!!pdc.gitHubURL.length && pdc.gitHubURL === this.state.gitHubURL) {
          state.isDuplicate = true;
          duplicative += `GitHub: ${this.state.gitHubURL}, `;
        }

        if (
          !!pdc.linkedInURL.length &&
          pdc.linkedInURL === this.state.linkedInURL
        ) {
          state.isDuplicate = true;
          duplicative += `LinkedIn: ${this.state.linkedInURL}, `;
        }

        if (!!pdc.phone.length && pdc.phone === this.state.phone) {
          state.isDuplicate = true;
          duplicative += `Phone: ${this.state.phone}, `;
        }

        if (!!pdc.email.length && pdc.email === this.state.email) {
          state.isDuplicate = true;
          duplicative += `Email: ${this.state.email}, `;
        }
      });

      duplicative += `</td></tr>`;
      html += `${duplicative}`;
    }
    html += `</table></body></html>`;

    var params = {
      from,
      to,
      cc,
      subject,
      html,
      source: 'Dupliate Candidate Email - CandidateEdit.js => line 1204',
    };
    Core.log('Email notification for Duplicative Candidate', params);
    Google.sendEmail(params, null, (error) => Core.showMessage(error));
  };

  sendIncompleteCandidateEmail = (candidate) => {
    var from = Core.getNewCandidateReceiverNotiFromEmail();
    var to;
    const recruiter = candidate.recruiter;

    const roles = Definition.getLabels('roles', this.state.roles);

    if (Core.isDuc() || Core.isTeam()) {
      to =
        (recruiter.firstName + ' ' + recruiter.lastName).trim() +
        ' <' +
        recruiter.email +
        '>';
    } else {
      to = Core.getDraftCandidateReceiverNotiToEmail();
    }

    var cc = Core.getNewCandidateReceiverNotiToEmail();
    const subject = `DRAFT CANDIDATE | ${this.state.firstName} ${this.state.lastName
      } | created by ${recruiter.firstName} ${recruiter.lastName} | ${roles.length ? roles.join(', ') : 'no roles added'
      }`;

    var editCandidateUrl = Core.getPath(`candidate/edit/${candidate.id}`);
    var html = `<html><head></head><body>Candidate is in draft state<br/><br><a href="${editCandidateUrl}">View Candidate</a></body></html>`;

    var params = {
      from,
      to,
      cc,
      subject,
      html,
      source: 'Incomplete Candidate Email - CandidateEdit.js => line 1236',
    };
    Core.log('Email notification for Draft Candidate', params);
    Google.sendEmail(params, null, (error) => Core.showMessage(error));
  };
  unsetNewCandidateFromStore = (cb) => {
    Store.remove('newCandidate');

    if (!!Core.isPath('candidate/create') && !!Store.get('newCandidate')) {
      /** */
      if (Array.isArray(this.state.resumes)) {
        const resumes = [...this.state.resumes];
        const next = (em) => {
          if (resumes.length) {
            const resume = resumes.pop();
            const arr = String(resume.url).split('/');
            const filename = arr.pop();
            const container = arr.pop() && arr.pop();
            Core.deleteFile(container, filename, (result) => next());
          } else {
            Store.remove('newCandidate');
            cb && cb();
          }
        };
        next();
      } else {
        cb && cb();
      }
      /** */
    } else {
      cb && cb();
    }
  };
  /** CANCEL BUTTON */
  cancel = (ev) => {
    if (!this.state.id && this.state.resumes) {
      this.state.resumes.map((resume, index) =>
        SovrenData.destroyByTimestamp(resume.timestamp)
      );
    }

    if (!!this.props.backAction) {
      this.props.backAction();
    } else {
      this.unsetNewCandidateFromStore((ev) => {
        Core.goBack(this.props);
      });
    }
  };
  /** PREVIOUS BUTTON */
  previous = (ev) => {
    this.refs.stepper.previous();
  };

  isSubmitting = () => {
    return this._action === 'submit';
  };
  isSaving = () => {
    return this._action === 'save';
  };

  askConfirmation = (textCancel, textConfirm, confirmAction) => () => {
    // Moved warningMessage() call to this function rather than
    // passed in as a parameter.  Found warningMessage was called every time
    // onClick referenced askConfirmation resulting in 3-7 unnecessary
    // calls just rendering page.
    const msg = this.warningMessage();
    if (msg && this.isValid()) {
      this.setState({
        generalDialogCancelBtnText: textCancel,
        generalDialogConfirmBtnText: textConfirm,
      });

      this.generalConfirmDialog.open(<Fragment>{msg}</Fragment>, (ev) => {
        confirmAction();
        this._warningAlreadyShown = true;
        this.generalConfirmDialog.close();
      });
    } else {
      confirmAction();
    }
  };
  /** SAVE & NEXT BUTTON */
  submitThenGoNext = (action, opts) => (ev) => {
    const continueWithExecution = () => {
      if (this.state.formIsSubmitting) {
        console.log('Tried to resubmit but stopped making a duplicate request');
        return;
      }
      this._action = action;
      const isSaveAsDraft = /save/i.test(this._action);

      this.update(
        (act) => (response) => {
          this.setState({ formIsSubmitting: false });
          let msg = 'Candidate has been successfully ';

          if (this._sendNewCandidateEmail) {
            Candidate.get(response.id, (candidate) => {
              candidate._persisted = candidate;
              let latestCandidate = { ...this.state, ...candidate };
              this.sendNewCandidateEmail(latestCandidate);
              setTimeout(() => {
                this.sendMissingAttributeEmail(latestCandidate);
                this._sendNewCandidateEmail = false;
              }, 5000);
              if (this.state.isDirty) {
                this.resetDirty();
              }
            });

            if (this.state.isDuplicate) {
              //this.sendDuplicateCandidateEmail(this.state); // deprecated
            }
          }

          if (this.state.id) {
            this.sendCandidateEditEmail(this.state);
            setTimeout(() => {
              this.sendMissingAttributeEmail(this.state);
            }, 5000);
            if (this.state.isDirty) {
              this.resetDirty();
            }
          }

          if (this.state.id && !opts.next) {
            msg = isSaveAsDraft ? msg + 'saved' : msg + 'submitted';
          } else {
            msg = isSaveAsDraft ? msg + 'saved' : msg + 'submitted';
            // update resume (converted to pdf) name here

            const runOnOneCandidate = (candidate) => {
              if (!candidate.resumes || !Array.isArray(candidate.resumes)) {
                return;
              }

              // let resumes = candidate.resumes;
              // let firstCv = Array.isArray(resumes)&&!!resumes.length ? resumes[0]:null;

              // if(!!firstCv){
              //   Candidate.getPdfUrl({url:firstCv.url, filename:`id_${candidate.id}`, oldFilename: this.state.anonFilename},(res) => {
              //
              //     Candidate.update(
              //         candidate.id,
              //         {
              //           resumePdfUrl:res.url,
              //           resumeTxtUrl: res.urlTxt
              //         },
              //         result => {
              //           Core.log("RESULT", result);
              //           this.setState({
              //             resumePdfUrl:res.url,
              //             resumeTxtUrl: res.urlTxt
              //           })
              //         }
              //     )
              //   }, candidate.resumePdfUrl)
              // }
            };

            runOnOneCandidate(response);
          }

          this.setState({ ...mapCandidate(response) }, (then) => {
            if (!!this.props.backAction) {
              this.props.backAction();
            } else {
              if (opts.next) {
                this.refs.stepper.next();
              } else {
                this.refs.successDialog.open(msg);
              }
              Core.go({ ...this.props, to: `/candidate/edit/${this.state.id}` });
            }
          });
        },
        true
      );
    };
    /**
     * Route(/candidate/create)
     * Route(/candidate/edit/:id)
     * On click submit, the state of candidate...
     * will become active.
     * START >>>
     */
    if (action === 'submit') {
      this.setState({ state: Definition.getId('state', 'Active') }, () => {
        continueWithExecution();
      });
    } else {
      continueWithExecution();
    }
    /** <<< END */
  };

  isOwner = () => {
    return (
      this.isNewRecord() ||
      (this.state.accountId && this.state.accountId === Core.getUserId())
    );
  };

  isNewRecord = () => {
    return this.state.id == null;
  };

  componentWillUnmount() {
    this._isMounted = false;

    if (this.state.id && this.state._isDraft && !this.state.draftEmailSentAt) {
      Candidate.update(this.state.id, {
        draftEmailSentAt: new Date().toISOString(),
      });
    }
    // console.log('printing dirty');
    let doSave = false;
    if (
      this.state.isDirty &&
      !this._isDone &&
      this.props.source !== 'jobsPage' &&
      window.confirm('want to save your data?')
    ) {
      doSave = true;
    }

    if (!this.state.id) {
      (this.state.resumes || []).forEach((resume) => {
        setTimeout(() => {
          SovrenData.destroyByTimestamp(resume.timestamp);
        });
      });
    }

    this.leavingPageUpdates(doSave);

    window.removeEventListener('beforeunload', this.handleWindowClose);
  }

  isLastStep = () => this.state.step === (Core.isAdminOrCoordinator() ? 2 : 1);

  handleWindowClose = (ev) => {
    ev.preventDefault();

    if (this.state.isDirty && this.state.id) {
      let txt = 'Are you sure you want to close?';
      ev.returnValue = txt;
      return txt;
    }
  };

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('beforeunload', this.handleWindowClose);
    this.setState(
      { queryParams: queryString.parse(this.props.location.search) },
      () => {
        // this.loadDefaultValues();
        let candFromParams = this.props.match
          ? this.props.match.params.id
          : null;
        let candidateId = this.props.candidateId || candFromParams;
        // console.debug('this.props', this.props);
        // console.debug('candidateId', candidateId);
        /** LOAD EMPLOYER DATA to the current state */
        if (candidateId) {
          Candidate.get(
            candidateId,
            (model) => {
              // console.debug('model', model);
              this.setState(
                {
                  ...model,
                  candidate: model,
                  initiallyIsDuplicate: model.isDuplicate,
                  loadingComplete: true,
                },
                (then) => {
                  Core.log({ candidateState: this.state });
                  HistoryLog.set({
                    group: 'candidates',
                    label: this.state._name,
                  });
                  this.loadDefaultValues();
                  setTimeout(() => {
                    this.checkPotentialDuplicatedCandidates((em) => { });
                  }, 2000); //wait for all page to load
                }
              );
            },
            (error) => {
              if (error === 404) {
                this.setState({ candidateNotFound: true });
              }
            },
            { skipCache: true }
          );
        } else {
          let source = dig(this.state, 'queryParams', 'source');
          const newCandidate =
            source === 'jobs' ? '' : Store.get('newCandidate');
          this.setState({ loadingComplete: true });
          if (newCandidate) {
            newCandidate.accountId = Core.getUserId();
            this.setState(
              {
                ...newCandidate,
                candidate: newCandidate,
                loadingComplete: true,
              },
              () => {
                this.loadDefaultValues();
              }
            );
          } else {
            this.loadDefaultValues();
          }
        }
      }
    );
  }

  leavingPageUpdates(doSave) {
    this._isLeavingComponent = true;

    if (this.state._isDraft && !this.state.draftEmailSentAt) {
      //get all candidate details first
      Candidate.get(this.state.id, (candidate) => {
        this.sendIncompleteCandidateEmail(candidate);
      });
    }

    if (doSave) {
      this.update((action) => (res) => { });
    }
  }

  /** NEXT BUTTON */
  next = (ev) => {
    if (this.state.step === 0 && !this.isValid()) {
      return;
    }

    this.checkPotentialDuplicatedCandidates((em) => this.refs.stepper.next());
  };
  /**
   * SUBMIT; DONE or SAVE BUTTONS
   */
  // submit = action => ev => {
  //   this._action = action;
  //   this.update(response => {
  //     if (this.state.id) {
  //       this.refs.successDialog.open("Candidate has been successfully updated");
  //     } else {
  //       Candidate.get(response.id, candidate => {
  //         this.setState({ ...candidate }, then => {
  //           this.sendNewCandidateEmail(candidate);
  //           this.refs.successDialog.open(
  //             "Candidate has been successfully created"
  //           );
  //         });
  //       });
  //     }
  //   }, true);
  // };
  getFullname = (em) => {
    return !!this.state.firstName
      ? (this.state.firstName + ' ' + this.state.lastName).trim()
      : '';
  };

  openMessage = (ev) => {
    const emails = [];
    const candidate = this.state;
    candidate.email &&
      emails.push({
        name: candidate._name || 'Candidate',
        email: candidate.email,
      });
    candidate.recruiter.email &&
      emails.push({
        name: candidate.recruiter._name || 'Recruiter',
        email: candidate.recruiter.email,
      });
    Core.dialog.open({
      title: <>Message</>,
      message: (
        <EmailPreview
          ref={(self) => (this.EmailMessage = self)}
          emails={emails}
          to={!!emails[0] && [emails[0]]}
          subject=""
          body={[].filter((line) => !!line).join('<br/>')}
        />
      ),
      className: 'p-0',
      actions: [
        <FlatButton
          label="Cancel"
          className="button-flat-darker"
          onClick={(ev) => Core.go({ ...this.props, to: '/candidates' })}
        />,
        <FlatButton
          label="Send"
          className="button-white-cyan"
          onClick={(ev) => {
            Core.dialog.close();
            Google.sendEmail(
              {
                ...this.EmailMessage.getParams(),
                source:
                  'Candidate Email from popup - CandidateEdit.js => line 1572',
              },
              (response) => Core.showMessage('Email sent'),
              (error) => Core.showFailure(error)
            );
          }}
        />,
      ],
    });
  };

  openPdf = () => {
    if (!!this.state.resumes.length) {
      Core.openPopUp(this.state.resumePdfUrl);
    } else {
      alert('no resumes found to show pdf');
    }
  };

  saveAsDraftBtn = (klass) => {
    const isSaveAsDraft = this.isNewRecord() || this.state._isDraft;
    const formIsSubmitting = this.state.formIsSubmitting;

    return (
      <FlatButton
        disabled={formIsSubmitting}
        label={
          formIsSubmitting
            ? 'Please Wait...'
            : isSaveAsDraft
              ? 'SAVE AS DRAFT'
              : 'SAVE'
        }
        className={klass}
        onClick={
          isSaveAsDraft
            ? this.submitThenGoNext('save', { next: false })
            : this.askConfirmation(
              warningBackBtnText,
              warningContinueBtnText,
              this.submitThenGoNext('save', { next: false })
            )
        }
      />
    );
  };

  render() {
    // const { t } = this.props; // this would be used in future

    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }

    const { candidateNotFound, formIsSubmitting } = this.state;

    let candFromParams = this.props.match ? this.props.match.params.id : null;
    let candidateId = this.props.candidateId || candFromParams;

    return (
      <MuiThemeProvider>
        <div className="edit candidates">
          <div className="appBar" style={styles.AppBar}>
            <AppBar
              title={
                (!!this.state.id ? 'Edit ' : 'New ') +
                'Candidate' +
                (!!this.getFullname() ? ' - ' + this.getFullname() : '')
              }
              titleStyle={styles.AppBar.title}
              style={styles.AppBar.nav}
              iconElementLeft={
                <FlatButton
                  icon={<i className="material-icons">arrow_back</i>}
                  style={styles.AppBar.backButton}
                  onClick={this.cancel}
                />
              }
              iconElementRight={
                <>
                  {!!Core.isPath('candidate/create') &&
                    !!Store.get('newCandidate') && (
                      <IconButton
                        aria-label="Clean Cache Form"
                        className="float-right hint--left hint--rounded"
                        onClick={(ev) => {
                          this.unsetNewCandidateFromStore((ev) =>
                            document.location.reload()
                          );
                        }}
                      >
                        <i className="material-icons">clear</i>
                      </IconButton>
                    )}
                  {!!this.state.resumes.length && (
                    <Fragment>
                      <IconButton
                        className="float-right"
                        onClick={(ev) => {
                          Core.openPopUp(Candidate.getMyPdfUrl(this.state));
                        }}
                      >
                        <i className="material-icons">picture_as_pdf</i>
                      </IconButton>

                      {this.state.resumeTxtUrl && (
                        <ResumeMatch
                          candidateResume={this.state.resumeTxtUrl}
                          element={(cb) => {
                            return (
                              <IconButton
                                className="float-right"
                                onClick={(ev) => {
                                  cb();
                                }}
                              >
                                <i className="material-icons">book</i>
                              </IconButton>
                            );
                          }}
                        />
                      )}
                    </Fragment>
                  )}
                  {Core.isAdmin() && (
                    <>
                      {/* story-3296-m6 | 2021-07-16 Fri µ */}
                      {!!this.state.id && (
                        <NavLink to={`/candidate/matchNew/${this.state.id}`}>
                          <IconButton title="Go to match">
                            <i className="material-icons">fact_check</i>
                          </IconButton>
                        </NavLink>
                      )}

                      <IconButton
                        className="float-right"
                        onClick={this.openMessage}
                      >
                        <i className="material-icons">email</i>
                      </IconButton>
                      <HistoryMenu />
                    </>
                  )}
                </>
              }
            />
          </div>
          {!candidateNotFound ? (
            <Paper
              className={`form${Core.isAdminOrCoordinator() &&
                !!this.state.id &&
                this.state.isDuplicate
                ? ' bgcolorred'
                : ''
                }`}
              style={styles.Paper}
              zDepth={1}
            >
              {this.saveAsDraftBtn('form-save-btn')}
              {Core.isAdmin() && (
                <Fragment>
                  <Switch
                    checked={this.state.showLinkedInForm}
                    onChange={(event) => {
                      this.setState({ showLinkedInForm: event.target.checked });
                    }}
                    value="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  {!!this.state.rawSovren && (
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ viewSovrenData: true });
                      }}
                    >
                      view sovren raw data
                    </Link>
                  )}

                  <Dialog
                    maxWidth={'md'}
                    fullWidth={'md'}
                    open={this.state.viewSovrenData}
                    onClose={() => {
                      this.setState({ viewSovrenData: false });
                    }}
                    aria-labelledby="form-dialog-title"
                  >
                    <div style={{ padding: 20 }}>
                      <ReactJson src={this.state.rawSovren} />
                    </div>
                  </Dialog>
                </Fragment>
              )}

              {this.state.loadingComplete &&
                Core.isAdminOrCoordinator() &&
                this.state.showLinkedInForm ? (
                <LinkedinFieldsForm parent={this} />
              ) : (
                <Stepper
                  ref="stepper"
                  active={this.state.initial - 1}
                  headers={this.state.stepperHeaders}
                  views={this.state.stepperViews}
                  onStep={(step) => {
                    const candidate = this.state;
                    if (candidate.id) {
                      Candidate.isStale(
                        candidate.id,
                        candidate.updatedAt,
                        (result) => {
                          if (result.isStale) {
                            this.setState({
                              snackbar: {
                                msg: 'The candidate record has been edited by someone else. Please refresh the page and re-apply your changes before saving',
                                type: 'danger',
                              },
                            });
                          }
                        }
                      );
                    }

                    this.setState({ step });
                  }}
                />
              )}
              <Row style={{ padding: '16px 0' }}>
                <Col style={{ width: '30%' }}>
                  <FlatButton
                    label="CANCEL"
                    className="btn-white-crimson"
                    onClick={this.cancel}
                  />
                </Col>
                <Col style={{ textAlign: 'right', width: '70%' }}>
                  {this.state.step === 0 ? (
                    ''
                  ) : (
                    <FlatButton
                      label="PREVIOUS"
                      className="btn-white-cyan"
                      onClick={this.previous}
                    />
                  )}
                  {this.state.step > 1 ? (
                    ''
                  ) : (
                    <Fragment>{this.saveAsDraftBtn('btn-white-cyan')}</Fragment>
                  )}
                  {!this.isLastStep() && (
                    <Fragment>
                      <FlatButton
                        label="Next"
                        className="btn-white-cyan"
                        onClick={this.next}
                      />
                    </Fragment>
                  )}

                  {this.isLastStep() &&
                    (this.isNewRecord() || this.state._isDraft) ? (
                    <FlatButton
                      label={formIsSubmitting ? 'Please Wait...' : 'Submit'}
                      className="btn-cyan-white"
                      disabled={formIsSubmitting}
                      onClick={this.askConfirmation(
                        warningBackBtnText,
                        warningContinueBtnText,
                        this.submitThenGoNext('submit', {
                          next: !this.isLastStep(),
                        })
                      )}
                    />
                  ) : this.isLastStep() && this.state.step > 1 ? (
                    <Fragment>{this.saveAsDraftBtn('btn-white-cyan')}</Fragment>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <SuccessDialog
                ref="successDialog"
                modal={true}
                actions={((actions) => {
                  actions.push(
                    <FlatButton
                      label="STAY"
                      primary={true}
                      onClick={(ev) => {
                        this.refs.successDialog.close();
                        Core.go({
                          ...this.props,
                          to: `/candidate/edit/${this.state.id}`,
                        });
                      }}
                    />
                  );
                  Core.isAdmin() &&
                    actions.push(
                      <FlatButton
                        label="GO TO MATCH"
                        primary={true}
                        keyboardFocused={true}
                        onClick={(ev) =>
                          Core.go({
                            ...this.props,
                            to: `/candidate/matchNew/${this.state.id}`,
                          })
                        }
                      />
                    );
                  actions.push(
                    <FlatButton
                      label="DONE"
                      primary={true}
                      keyboardFocused={true}
                      onClick={(ev) => {
                        this._isDone = true;
                        Core.go({ ...this.props, to: '/candidates' });
                      }}
                    />
                  );
                  return actions;
                })([])}
              />

              <SuccessDialog
                ref={(confirmDialog) => {
                  this.generalConfirmDialog = confirmDialog;
                  //this.generalConfirmDialog.cancelBtnText = "Take me back";
                }}
                modal={true}
                icon="info"
                contentHeight={!!this._prolongPopup ? '600px' : '150px'}
                width={!!this._prolongPopup ? '1000px' : '400px'}
                overFlow={!!this._prolongPopup ? 'auto' : 'hidden'}
                marginTop={!!this._prolongPopup ? '-200px' : '0px'}
                actions={[
                  <FlatButton
                    label={this.state.generalDialogCancelBtnText}
                    primary={true}
                    keyboardFocused={true}
                    onClick={(event) => {
                      this.refs.stepper.setActive(0);
                      this.generalConfirmDialog.close();
                    }}
                  />,
                  <FlatButton
                    label={this.state.generalDialogConfirmBtnText}
                    primary={true}
                    keyboardFocused={true}
                    onClick={(event) => {
                      this.generalConfirmDialog.onRequestClose();
                    }}
                  />,
                ]}
              />

              <SuccessDialog
                ref={(duplicateDialog) => {
                  this.duplicateDialog = duplicateDialog;
                }}
                modal={true}
                icon="info"
                contentHeight={'100px'}
                width={'800px'}
                overFlow={!!this._prolongPopup ? 'auto' : 'hidden'}
                marginTop={!!this._prolongPopup ? '-100px' : '0px'}
                actions={[
                  <FlatButton
                    label={'Yes Load Existing Candidate'}
                    primary={true}
                    keyboardFocused={true}
                    onClick={(event) => {
                      this.duplicateDialog.onRequestClose();
                    }}
                  />,
                  <FlatButton
                    label={'No, Take Me Back'}
                    primary={true}
                    keyboardFocused={true}
                    onClick={(event) => {
                      this.setState({ duplicateDialogSeen: true }, () => {
                        this.duplicateDialog.close();
                      });
                    }}
                  />,
                ]}
              />
            </Paper>
          ) : (
            <p>
              <strong>Candidate {candidateId} not found</strong>
            </p>
          )}
          <Snackbar
            open={!!this.state.snackbar}
            message={
              Object(this.state.snackbar).msg || this.state.snackbar || ''
            }
            className="snack-bar"
            bodyStyle={{
              backgroundColor: !!this.state.snackbar
                ? !!this.state.snackbar.type
                  ? this.state.snackbar.type === 'success'
                    ? 'green'
                    : this.state.snackbar.type === 'warning'
                      ? 'gold'
                      : 'red'
                  : ''
                : '',
              height: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            style={{
              top: '0px',
              bottom: 'auto',
            }}
            onRequestClose={(ev) => {
              this.setState({ snackbar: null });
            }}
          ></Snackbar>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withTranslation()(CandidateEdit);
