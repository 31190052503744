import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { blue500, cyan300, orange500 } from 'material-ui/styles/colors';

import getMuiTheme from 'material-ui/styles/getMuiTheme';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import Snackbar from 'material-ui/Snackbar';
import TextField from 'material-ui/TextField';

import Core from '../../lib/Core';
import backgroundImage from '../../assets/images/background.jpg';
import logoImage from '../../assets/images/10x10icon.png';
import './Login.css';
import Store from '../../lib/Store';

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: blue500,
    accent1Color: cyan300,
  },
});

const styles = {
  errorStyle: {
    color: orange500,
  },
  h1Style: {
    marginTop: '20px',
    color: 'white',
    weight: 'normal',
  },
  textFieldStyle: {
    display: 'block',
    width: '100%',
    margin: 'auto',
  },
  inputStyle: {
    color: 'white',
  },
  buttonStyle: {
    marginTop: '20px',
    float: 'right',
    borderRadius: '40px',
  },
  snackBar: {
    textAlign: 'center',
  },
};

class Login extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      email: '',
      password: '',
      confirm: '',
      error: {
        email: false,
        password: false,
        confirm: false,
      },
      dialog: {
        open: false,
        message: 'default',
      },
      snackBar: {
        open: false,
        message: 'default',
      },
    };
  }
  showMessage = (msg) => {
    this.setState({
      snackBar: {
        message: msg,
        open: true,
      },
      error: {
        email: msg,
      },
    });
  };
  setSnackBarMessage = (msg) => {
    this.setState({
      snackBar: {
        message: msg,
        open: true,
      },
    });
  };
  hideMessage = () => {
    this.setState({
      snackBar: {
        message: 'default',
        open: false,
      },
    });
  };
  doLogin = (ev) => {
    var self = this;
    var WEEKS_12 = 60 * 60 * 24 * 7 * 12;
    ev.preventDefault();
    validateInputs(
      {
        email: this.state.email.toLowerCase(),
        password: this.state.password,
        ttl: WEEKS_12,
      },
      (inputs) => {
        Core.login({ ...inputs }, null, Core.showMessage);
      }
    );
    function validateInputs(input, callback) {
      if (!String(input['email']).trim().length) {
        return self.setState({
          error: {
            email: 'This field is required',
          },
        });
      }
      if (!String(input['password']).trim().length) {
        return self.setState({
          error: {
            password: 'This field is required',
          },
        });
      }
      input['time'] = new Date().toJSON();
      callback(input);
    }
  };
  doForgot = (ev) => {
    var self = this;
    ev.preventDefault();
    validateInputs(
      {
        email: String(this.state.email).toLowerCase(),
      },
      (inputs) => {
        Store.set('email', inputs.email);
        Core.forgot(
          inputs,
          (res) =>
            this.setSnackBarMessage(
              'If an account exists, a reset password link will be sent to the email address.'
            ),
          (err) =>
            this.setSnackBarMessage(
              'If an account exists, a reset password link will be sent to the email address.'
            )
        );
      }
    );
    function validateInputs(inputs, callback) {
      if (!String(inputs.email).trim().length) {
        return self.setState({
          error: {
            email: 'This field is required',
          },
        });
      }
      callback(inputs);
    }
  };
  doReset = (ev) => {
    var self = this;
    ev.preventDefault();
    validateInputs(
      {
        newPassword: this.state.password,
      },
      (inputs) => {
        Core.resetPassword(
          { ...inputs, token: this.props.match.params.token },
          (res) => {
            this.setSnackBarMessage(
              'Your password has been reset successfully'
            );
            const email = Store.get('email');
            if (email) {
              setTimeout((st) => {
                Core.login(
                  { email, password: inputs.newPassword },
                  null,
                  (err) => Core.showFailure(err)
                );
              }, 1000);
            } else {
              Core.go({ ...this.props, to: '/login' });
            }
          },
          (err) => Core.showFailure(err)
        );
      }
    );
    function validateInputs(inputs, callback) {
      if (!String(inputs['newPassword']).trim().length) {
        return self.setState({
          error: {
            password: 'This field is required',
          },
        });
      }
      if (inputs.newPassword !== self.state.confirm) {
        return self.setState({
          error: {
            confirm: 'New password and confirm password does not match',
          },
        });
      }
      callback(inputs);
    }
  };
  render() {
    const isV3Enabled = (Core.getUIVersion() === 3);
    if (Core.isLogged()) {
      return Core.isTrusted() ? (
        isV3Enabled ? (
          <Redirect to="/v3/recruiter" />
        ) : (
          <Redirect to="/recruiter" />
        )
      ) : isV3Enabled ? (
        <Redirect to="/v3/jobs" />
      ) : (
        <Redirect to="/jobs" />
      );
    }
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div
          className="login"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className="login-header">
            <img alt="Logo" src={logoImage} className="login-header-logo" />
          </div>
          <div className="login-middle">
            <div className="login-center">
              <label className="login-label">
                Say “You’re Hired” to Every Single Candidate
              </label>
              {{
                '/forgot/password': (
                  <form className="login-middle-form" onSubmit={this.doForgot}>
                    <TextField
                      name="email"
                      autoComplete="email"
                      value={this.state.email}
                      onChange={(ev, email) =>
                        this.setState({
                          email,
                          error: { email: false },
                          snackBar: {
                            message: '',
                            open: false,
                          },
                        })
                      }
                      floatingLabelText="Email"
                      floatingLabelFixed={true}
                      type="email"
                      errorText={this.state.error.email}
                      style={styles.textFieldStyle}
                      required
                    />
                    <RaisedButton
                      label="FORGOT PASSWORD"
                      type="submit"
                      className="login-submit"
                      fullWidth
                    />
                    <div className="normal-padding margin-top">
                      <a href="/#/login">Log In</a>
                    </div>
                  </form>
                ),
                '/reset/password/:token': (
                  <form className="login-middle-form" onSubmit={this.doReset}>
                    <TextField
                      name="password"
                      autoComplete="password"
                      className=""
                      value={this.state.password}
                      onChange={(ev, password) =>
                        this.setState({
                          password,
                          error: { password: false },
                          snackBar: {
                            message: '',
                            open: false,
                          },
                        })
                      }
                      floatingLabelText="Password"
                      floatingLabelFixed={true}
                      type="password"
                      errorText={this.state.error.password}
                      style={styles.textFieldStyle}
                      required
                    />
                    <TextField
                      name="confirm"
                      autoComplete="confirm"
                      className=""
                      value={this.state.confirm}
                      onChange={(ev, confirm) =>
                        this.setState({
                          confirm,
                          error: { confirm: false },
                          snackBar: {
                            message: '',
                            open: false,
                          },
                        })
                      }
                      floatingLabelText="Confirmation"
                      floatingLabelFixed={true}
                      type="password"
                      errorText={this.state.error.confirm}
                      style={styles.textFieldStyle}
                      required
                    />
                    <RaisedButton
                      label="RESET PASSWORD"
                      type="submit"
                      className="login-submit"
                      fullWidth
                    />
                    <div className="normal-padding margin-top">
                      <a href="/#/login">Log In</a>
                    </div>
                  </form>
                ),
              }[this.props.match.path] || (
                  <form className="login-middle-form" onSubmit={this.doLogin}>
                    <TextField
                      id="email"
                      name="email"
                      autoComplete="email"
                      onClick={(ev) =>
                        this.setState({
                          error: { email: false },
                          snackBar: {
                            message: '',
                            open: false,
                          },
                        })
                      }
                      floatingLabelText="Email"
                      floatingLabelFixed={true}
                      type="email"
                      errorText={this.state.error.email}
                      style={styles.textFieldStyle}
                      value={this.state.email}
                      onChange={(ev, email) =>
                        this.setState({
                          email,
                        })
                      }
                      required
                    />
                    <br />
                    <TextField
                      id="password"
                      name="password"
                      autoComplete="password"
                      className=""
                      value={this.state.password}
                      onChange={(ev, password) =>
                        this.setState({
                          password,
                        })
                      }
                      onClick={() =>
                        this.setState({
                          error: { password: false },
                          snackBar: {
                            message: '',
                            open: false,
                          },
                        })
                      }
                      floatingLabelText="Password"
                      floatingLabelFixed={true}
                      type="password"
                      errorText={this.state.error.password}
                      style={styles.textFieldStyle}
                      required
                    />
                    <RaisedButton
                      label="log in"
                      type="submit"
                      className="login-submit"
                      fullWidth
                    />
                    <div className="normal-padding margin-top">
                      <a href="/#/forgot/password">Forgot Password</a>
                    </div>
                    <label className="login-tos-label">
                      By signing in, you agree to 10x10's Terms of Service and
                      Privacy Policy. You also agree to receive subsequent email
                      and third-party communications, which you may opt out of at
                      any time.
                    </label>
                  </form>
                )}
            </div>
          </div>
          <div className="login-footer">
            <div className="inline-blocks">
              <FlatButton
                label="Privacy Policy"
                href="https://www.iubenda.com/privacy-policy/73371936"
                className="footer-button"
              />
              <span className="ui-m-min">|</span>
              <FlatButton
                label="Terms & Conditions"
                href="http://www.go10x10.com.s3-website-us-east-1.amazonaws.com/"
                className="footer-button"
              />
            </div>
          </div>
          <Snackbar
            open={this.state.snackBar.open}
            message={this.state.snackBar.message}
            className="snack-bar"
            onClick={this.hideMessage}
            onRequestClose={this.hideMessage}
            style={styles.snackBar}
          />
          <Dialog
            actions={[
              <FlatButton
                label="OK"
                primary={true}
                onClick={this.hideMessage}
              />,
            ]}
            modal={false}
            contentStyle={{
              width: '400px',
              height: '300px',
              textAlign: 'center',
            }}
            open={this.state.dialog.open}
            onRequestClose={this.hideMessage}
          >
            <div style={{ padding: '40px 20px' }}>
              {this.state.dialog.message}
            </div>
          </Dialog>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default Login;
