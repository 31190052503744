import Core from "./Core";

import moment from "moment-business-days";
import Candidate from "./Candidate";
import Definition from "./Definition";
import Http from "./Http";
import Job from "./Job";
import {
  mapEngagement,
  mapEngagements,
  model as engModel
} from "./models/engagement";
import Streak from "./Streak";
import cleanHTML from "./tools/cleanHtml";
import getStateModel from "./tools/getStateModel";
import MatchLib from "./services/Matching/Match.lib";

/** µ DISCARDED (future cleanup) * /
import Store from "./Store";
/** */

const cache = {};
const commonQuery = {
  include: [
    {
      relation: "engagementStarreds",
      scope: {
        where: {
          accountId: Core.getUserId()
        }
      }
    },
    { candidate: "account" },
    { job: "employer" },
    "actionOwner"
  ]
};

/** * /

db.Engagement.find({filter:include:[{relation:"engagementStarreds",scope:{where:{accountId:"5a73ad4f7c136c3b3d173f41"}}},{candidate:"account"},{job:"employer"},"actionOwner"]}})

/** */

const menus = [];
const more = [];
const listTabs = ["Name", "Recent", "Starred"];
const matchStrengths = [
  { key: 'A', value: 'A - Strong Match' },
  { key: 'B', value: 'B - Match' },
  { key: 'C', value: 'C - Stretch Match' },
  { key: 'E', value: 'E - Mismatch' },
  { key: 'F', value: 'F - Strong Mismatch' },
  { key: 'U', value: 'U - Unknown' },
];

const cacheStrengthsPrefix = "jobMatchStrength-";

const listTab = "Name";
const stageOrder = [
  "Pending",
  "Confirmation",
  "Submission",
  "Review",
  "Screen",
  "Onsite",
  "Offer",
  "Guarantee",
  "Hire",
  "End"
];

const Engagement = {
  name: 'Engagement',
  menus,
  more,
  listTabs,
  listTab,
  stageOrder,
  matchStrengths,
  cacheStrengthsPrefix,
  columns: [
    {
      headers: [
        {
          label: "Info",
          key: "_col1Cmp",
          sortKey: "_name",
          filter: true
        },
        { label: "Flags", key: "_flags" }
      ],
      selected: 0,
      style: { minWidth: 256 }
    },
    {
      headers: [
        { label: "Stage", key: "_stage" },
        { label: "Employees", key: "_employeeCount", sortKey: "employeeCount" }
      ],
      selected: 0
    },
    {
      headers: [
        { label: "City", key: "addressCity" },
        { label: "State", key: "_state" }
      ],
      selected: 0
    },
    {
      headers: [
        { label: "Active Jobs", key: "_active", sortKey: "_activeNum" },
        { label: "Jobs Count", key: "_jobsLength", sortKey: "_jobsLengthNum" }
      ],
      selected: 0
    },
    {
      headers: [
        {
          label: "Starred",
          key: "_rowOptionsCmp",
          sortKey: "_starred",
          reverseSort: true,
          hint: "Options"
        }
      ],
      selected: 0,
      style: { width: 124, textAlign: "right" }
    }
  ],
  cleanCache: em => {
    Object.keys(cache).forEach(key => {
      delete cache[key];
    })
  },

  /**
   * Creates an Streak Box,
   * then updates boxKey of engagement on DB
   */
  createBox: ({ engagement, onSuccess, onFailure }) => {
    const today = new Date();
    const {
      candidate,
      recruiter,
      job,
      _role: role,
      stage,
      status,
      state
    } = engagement;
    const {
      employer
    } = job;
    const boxOptions = {
      name: `${candidate._name} - ${Object(employer).name}`,
      recruiter: `${Object(recruiter)._name}`,
      stage: stage || "Pending",
      status: status || "W - 10x10",
      state: state || "Open",
      role,
      introduced: candidate.introduced,
      matched: today.getTime(),
      confirmed: /submission/i.test(stage) ? today.toISOString() : null,
      platformRating: candidate.platformRating,
      jobTitle: job.jobTitle,
      engagementURL: Core.getPath(`engagement/view/${engagement.id}`)
    };
    console.debug('µ:createBox', boxOptions, engagement);
    Streak.createBox(
      boxOptions,
      box => {
        Core.log("Created Box", box);
        const { boxKey } = box;
        Engagement.updateBoxKey({
          engagement,
          boxKey,
          onSuccess,
          onFailure
        });
      },
      onFailure
    );
  },

  /**
   * Updates boxKey of an engagement on DB
   */
  updateBoxKey({ engagement, boxKey, onSuccess, onFailure }) {
    const update = { boxKey, status: engagement.status };
    Engagement.overdueCalculation(engagement, update);
    Http.patch(
      Core.getApi("Engagements/" + engagement.id),
      update,
      engagement => {
        engagement = mapEngagement(engagement);
        Core.log("Updated boxKey on Engagement", engagement);
        onSuccess(engagement);
      },
      onFailure
    );
  },

  getActives: (success, skipCache) => {
    success = success instanceof Function ? success : function () {
    };
    if (!skipCache && cache.actives) {
      setTimeout(st => success(cache.actives));
    } else {
      return Http.get(
        Core.getApi("Engagements"),
        {
          filter: JSON.stringify({ ...commonQuery, where: { state: 1 } })
        },
        function onSuccess(response) {
          cache.actives = mapEngagements(response);
          success(cache.actives);
        }
      );
    }
  },

  negativeStrengths: () => {
    return Definition.get("engagementMatchStrength").filter(obj => (/Mismatch/i.test(obj.label))).map(o => o.id);
  },

  unSpecifiedStrength: () => {
    return Definition.get("engagementMatchStrength").filter(obj => (/Unspecified|Unknown/i.test(obj.label))).find(o => o.id);
  },

  matchStrengthLabel: (number) => {
    let el = Definition.get("engagementMatchStrength").find(o => o.id === number);
    let ret = "";
    if (el) {
      ret = el.label;
    }

    return ret;
  },

  getAll: (cb,skipCache) => {
    cb = cb instanceof Function ? cb : function () {
    };
    if (!skipCache&&cache.all) {
      setTimeout(st => cb(cache.all));
    } else {
      return Http.get(
        Core.getApi("Engagements"),
        {
          filter: JSON.stringify({ ...commonQuery })
        },
        function onSuccess(response) {
          cache.all = mapEngagements(response);
          cb(cache.all);
          //Core.log("Engagement.getAll", {response});
        }
      );
    }
  },

  getCacheKeyJobMatch: (candidateId, jobId) => {
    return `${cacheStrengthsPrefix}${candidateId}-${jobId}`
  },

  getWhere: (where, cb, filters = {}, skipCache = false) => {
    cb = cb instanceof Function ? cb : function () {
    };
    const key = JSON.stringify(where).replace(/\W/g, "");
    if (cache[key] && !skipCache) {
      setTimeout(st => cb(cache[key]));
    } else {
      const include = !!filters.include ? {} : commonQuery
      return Http.get(
        Core.getApi("Engagements"),
        {
          filter: JSON.stringify({
            ...include,
            ...filters,
            where: { ...where },
          })
        },
        function onSuccess(response) {
          cb((cache[key] = mapEngagements(response)));
          //Core.log("Engagement.getWhere", {where, response});
        },
        function onFailure(error) {
          cb([]);
        }
      );
    }
  },
  getCandidates: success => {
    Http.get(
      Core.getApi("Engagements"),
      {
        filter: JSON.stringify({
          fields: ["candidateId", "boxKey"],
          include: ["candidate"]
        })
      },
      function onSuccess(engagements) {
        success(mapEngagements(engagements));
      }
    );
  },
  getBoxIds: success => {
    Http.get(
      Core.getApi("Engagements"),
      {
        filter: JSON.stringify({
          fields: { id: true, boxKey: true }
        })
      },
      success
    );
  },
  /**
   *
   * @param {*} params
   * @param {*} params.status open (default) | closed | all
   * @param {*} params.onSuccess
   * @param {*} params.onFailure
   * @returns
   */
  getBoxIdsV2({
    status = 'open',
    onSuccess = () => { },
    onFailure = () => { },
  }) {
    const query = {
      'all': ({
        fields: { id: true, boxKey: true }
      }),
      'open': ({
        where: { state: "Open" },
        fields: { id: true, boxKey: true }
      }),
      'closed': ({
        where: { state: "Closed" },
        fields: { id: true, boxKey: true }
      })
    };
    return Http.get(
      Core.getApi("Engagements"),
      {
        filter: JSON.stringify(query[status])
      },
      onSuccess,
      onFailure,
    );
  },
  get: (engagementId, cb = () => { }) => {
    if (cache[engagementId]) {
      setTimeout(st => cb(cache[engagementId]));
    } else {
      return Http.get(
        Core.getApi("Engagements/" + engagementId),
        {
          filter: JSON.stringify({ ...commonQuery })
        },
        function onSuccess(response) {
          cache[engagementId] = mapEngagement(response);
          cb(cache[engagementId]);
        }
      );
    }
  },
  overdueCalculation: (engagement, update) => {
    const locale = moment.locale();
    moment.updateLocale(moment.locale(), {
      holidays: Definition.get("holidays").map(item => item.label),
      holidayFormat: "YYYY-MM-DD",
      workingWeekdays: [1, 2, 3, 4, 5]
    });
    const getOverdue = (date, hours) => {
      const days = Math.ceil((hours || 48) / 24);
      let result = moment(date)
        .startOf("day")
        .businessAdd(days)
        ._d.toISOString();
      Core.log({
        locale,
        date: moment(date).toISOString(),
        hours,
        days,
        result
      });
      return result;
    };
    if (
      /**
       * If stage is Review
       * and there is updating submitted
       * overdue is submitted + employer.expectedResumeResponseTime
       */
      /review/i.test(engagement.stage) &&
      update.submitted
    ) {
      update.overdueDate = getOverdue(
        update.submitted,
        engagement.employer.expectedResumeResponseTime
      );
    } else if (
      /**
       * If stage is Screen
       * and there is updating an eventDate
       * overdue is eventDate + employer.expectedScreenResponseTime
       */
      /screen/i.test(engagement.stage) &&
      (update.screen3 || update.screen2 || update.screen1)
    ) {
      update.overdueDate = getOverdue(
        update.screen3 || update.screen2 || update.screen1,
        engagement.employer.expectedScreenResponseTime
      );
    } else if (
      /**
       * If stage is Onsite
       * and there is updating an eventDate
       * overdue is eventDate + employer.expectedOnsiteResponseTime
       */
      /onsite/i.test(engagement.stage) &&
      (update.onsite2 || update.onsite1)
    ) {
      update.overdueDate = getOverdue(
        update.onsite2 || update.onsite1,
        engagement.employer.expectedOnsiteResponseTime
      );
    }
    if (
      /**
       * If there is updating the status to "W - Employer" or "W - Cando-Emp"
       * overdue is now + employer.expectedSchedulingResponseTime
       */
      update.status &&
      /w - employer|w - cando-emp/i.test(update.status)
    ) {
      update.overdueDate = getOverdue(
        moment(),
        engagement.employer.expectedSchedulingResponseTime
      );
    } else if (
      /**
       * If there is updating the status to "W - Candidate" or "W - Recruiter"
       * overdue is now + 2 days
       */
      update.status &&
      /w - candidate|w - recruiter/i.test(update.status)
    ) {
      update.overdueDate = getOverdue(moment(), 48);
    } else if (
      /**
       * If there is updating the status to "W - 10x10"
       * overdue is now
       */
      update.status &&
      /w - 10x10/i.test(update.status)
    ) {
      update.overdueDate = moment().toISOString();
    }
    /**
     * If there is updating the holdDate
     * overdue is holdDate
     */
    if (!!update.holdDate) {
      update.overdueDate = update.holdDate;
    } else if (update.status && /h - /i.test(update.status)) {
      /**
       * If and there is updating the status to "H - *"
       * overdue is now + employer.expectedSchedulingResponseTime
       */
      update.holdDate =
        engagement.holdDate ||
        getOverdue(
          moment(),
          engagement.employer.expectedSchedulingResponseTime
        );
      update.overdueDate = update.holdDate;
    }
    return update;
  },
  match: (
    {
      candidate,
      job,
      stage,
      status,
      matchStrength,
      jobAnswers = [],
      rejectionReason,
      rejectionReasonAdditionalInfo,
      state
    },
    onSuccess, onFailure
  ) => {
    const employer = job.employer;
    const recruiter = candidate.recruiter;
    const today = new Date();
    Engagement.cleanCache();
    Job.cleanCache();
    Candidate.cleanCache();
    const engFlags = MatchLib.getMatchFlags({
      status,
      candidate,
      job,
    });
    /** µ FOR DEBUG PURPOSES * /
    console.debug('µ:Engagement::Lib::match', { engFlags });
    return;
    /** */
    Http.post(
      Core.getApi("Engagements"),
      getStateModel(
        {
          candidateId: candidate.id,
          jobId: job.id,
          stage: stage || "Pending",
          status: status || "W - 10x10",
          state: state || "Open",
          jobAnswers,
          matched: today.toISOString(),
          matchStrength,
          matchedByWho: { email: Object(Core.getUser().email) },
          lastMatchedDate: new Date().toISOString(),
          introduced: candidate.introduced,
          confirmed: /submission/i.test(stage) ? today.toISOString() : null,
          platformRating: candidate.platformRating,
          jobTitle: job.jobTitle,
          rejectionReason,
          rejectionReasonAdditionalInfo,
          ...engFlags,
        },
        engModel
      ),
      engagement => {

        engagement = mapEngagement(engagement);
        Core.log("Created engagement", engagement);
        Streak.createBox(
          {
            name: `${candidate._name} - ${Object(employer).name}`,
            recruiter: `${Object(recruiter)._name}`,
            stage: stage || "Pending",
            status: status || "W - 10x10",
            state: state || "Open",
            role: job._role,
            introduced: candidate.introduced,
            matched: today.getTime(),
            confirmed: /submission/i.test(stage) ? today.toISOString() : null,
            platformRating: candidate.platformRating,
            jobTitle: job.jobTitle,
            engagementURL: Core.getPath(`engagement/view/${engagement.id}`)
          },
          box => {
            Core.log("Created Box", box);
            const update = { boxKey: box.boxKey, status: engagement.status };
            Engagement.overdueCalculation(engagement, update);
            Http.patch(
              Core.getApi("Engagements/" + engagement.id),
              update,
              engagement => {
                engagement = mapEngagement(engagement);
                Core.log("Updated boxKey on Engagement", engagement);
                onSuccess(engagement);
              },
              onFailure
            );
          }
        );
      },
      onFailure
    );
  },

  updateMany: (engagements, success, failure) => {
    Http.patch(
      Core.getApi("Engagements/updateMany"),
      engagements,
      success,
      failure
    )
  },

  update: async (engagement, update, success, failure) => {
    const engagementId = engagement.id;
    Engagement.cleanCache();
    Job.cleanCache();
    Candidate.cleanCache();
    Engagement.overdueCalculation(engagement, update);
    update.lastAction = update.lastAction || new Date().toISOString();
    Core.log("Engagement", "update", engagementId, update);
    return await Http.patch(
      Core.getApi("Engagements/" + engagementId),
      getStateModel(update, engModel),
      response => {
        response = mapEngagement(response);
        const boxKey = response.boxKey;
        if (!!boxKey) {
          Core.log("Engagement", "updated", response);
          const fields = [];
          update.stage !== undefined &&
            fields.push({
              stageKey: Streak.getDropdownKey("Stage", update.stage)
            });
          update.status !== undefined &&
            fields.push({
              field: "Status",
              value: Streak.getDropdownKey("Status", update.status)
            });
          update.state !== undefined &&
            fields.push({
              field: "State",
              value: Streak.getDropdownKey("State", update.state)
            });
          update.introduced !== undefined &&
            fields.push({
              field: "Introduced",
              value: update.introduced
                ? new Date(update.introduced).getTime()
                : ""
            });
          update.matched !== undefined &&
            fields.push({
              field: "Matched",
              value: update.matched ? new Date(update.matched).getTime() : ""
            });
          update.confirmed !== undefined &&
            fields.push({
              field: "Confirmed",
              value: update.confirmed
                ? new Date(update.confirmed).getTime()
                : ""
            });
          update.submitted !== undefined &&
            fields.push({
              field: "Submitted",
              value: update.submitted
                ? new Date(update.submitted).getTime()
                : ""
            });
          update.reviewed !== undefined &&
            fields.push({
              field: "Reviewed",
              value: update.reviewed ? new Date(update.reviewed).getTime() : ""
            });
          update.screened !== undefined &&
            fields.push({
              field: "Screened",
              value: update.screened ? new Date(update.screened).getTime() : ""
            });
          update.onsite !== undefined &&
            fields.push({
              field: "Onsite",
              value: update.onsite ? new Date(update.onsite).getTime() : ""
            });
          update.screen1 !== undefined &&
            fields.push({
              field: "Screen1",
              value: update.screen1 ? new Date(update.screen1).getTime() : ""
            });
          update.screen2 !== undefined &&
            fields.push({
              field: "Screen2",
              value: update.screen2 ? new Date(update.screen2).getTime() : ""
            });
          update.screen3 !== undefined &&
            fields.push({
              field: "Screen3",
              value: update.screen3 ? new Date(update.screen3).getTime() : ""
            });
          update.onsite1 !== undefined &&
            fields.push({
              field: "Onsite1",
              value: update.onsite1 ? new Date(update.onsite1).getTime() : ""
            });
          update.onsite2 !== undefined &&
            fields.push({
              field: "Onsite2",
              value: update.onsite2 ? new Date(update.onsite2).getTime() : ""
            });
          update.offered !== undefined &&
            fields.push({
              field: "Offered",
              value: update.offered ? new Date(update.offered).getTime() : ""
            });
          update.hired !== undefined &&
            fields.push({
              field: "Hired",
              value: update.hired ? new Date(update.hired).getTime() : ""
            });
          update.startDate !== undefined &&
            fields.push({
              field: "StartDate",
              value: update.startDate
                ? new Date(update.startDate).getTime()
                : ""
            });
          update.guaranteeDate !== undefined &&
            fields.push({
              field: "GuaranteeDate",
              value: update.guaranteeDate
                ? new Date(update.guaranteeDate).getTime()
                : ""
            });
          update.holdDate !== undefined &&
            fields.push({
              field: "HoldDate",
              value: update.holdDate ? new Date(update.holdDate).getTime() : ""
            });
          update.closed !== undefined &&
            fields.push({
              field: "Closed",
              value: update.closed ? new Date(update.closed).getTime() : ""
            });
          update.rejectionReason !== undefined &&
            fields.push({
              field: "Rejection Reason",
              value: Streak.getDropdownKey(
                "Rejection Reason",
                update.rejectionReason
              )
            });
          fields.push({
            field: "Last Action",
            value: update.lastAction
              ? new Date(update.lastAction).getTime()
              : new Date().getTime()
          });
          fields.push({
            field: "EngagementURL",
            value: Core.getPath(`engagement/view/${response.id}`)
          });
          Streak.updateBox({
            boxKey,
            fields,
            engagementId: response.id
          });
        }
        // console.debug('response', response);
        if (/screen/i.test(update.stage)) {
          const candidate = engagement.candidate;
          const job = engagement.job;
          const line = cleanHTML(
            "<p>" +
            [
              [
                moment(update.lastAction).format("MM/DD/YY"),
                `<a href="/#/candidate/edit/${candidate.id
                }" target="_blank">${candidate._name}</a>`,
                Definition.getLabel(
                  "platformRating",
                  candidate.platformRating
                )
              ]
                .filter(e => !!e && !!String(e).trim().length)
                .join(", "),
              Definition.getLabels(
                "positiveSignals",
                candidate.positiveSignals
              ).join(", "),
              Definition.getLabels(
                "positiveSignals",
                candidate.negativeSignals
              ).join(", "),
              candidate.tagLine
            ]
              .filter(e => !!e && !!String(e).trim().length)
              .join("; ") +
            ".</p>"
          );
          const examplesOfAccepted =
            typeof job.examplesOfAccepted === "string"
              ? line + job.examplesOfAccepted
              : line;
          Job.update(
            job.id,
            {
              examplesOfAccepted
            },
            jobResponse => {
              /**
               * Delaying response to avoid contention warnings
               * from Streak
               */
              setTimeout(() => {
                success && success(response);
              }, 15);
            }
          );
        } else {
          /**
           * Delaying response to avoid contention warnings
           * from Streak
           */
          setTimeout(() => {
            success && success(response);
          }, 15);
        }
      },
      failure
    );
  },
  delete: (engagementId, success) => {
    Engagement.cleanCache();
    Engagement.getWhere({ id: engagementId }, eng => {
      eng = { ...eng[0] };
      Http.delete(Core.getApi("Engagements/" + engagementId), response => {
        Streak.deleteBox({ boxKey: eng.boxKey });
        success && success(response);
      });
    });
  },
  updateStarred: (engagementId, starredId, starred, success) => {
    Engagement.cleanCache();
    if (starredId) {
      return Http.patch(
        Core.getApi("EngagementStarred/" + starredId),
        { starred },
        success
      );
    } else {
      return Http.post(
        Core.getApi("EngagementStarred"),
        {
          engagementId,
          starred,
          accountId: Core.getUserId()
        },
        success
      );
    }
  },
  sendResumeSubmissionEmail: (params, success, failure) => {
    /**
      var params = {
        from: from,
        to: to.join(","),
        cc: cc.join(","),
        bcc: bcc,
        subject: subject,
        html: html
      };
     */
    Http.post(
      Core.getApi("Engagements/sendResumeSubmissionEmail"),
      params,
      response => {
        Core.showMessage("Resume submission sent.");
        success && success(response);
      },
      error => {
        Core.showFailure(
          "Email may not be sent. Please check the Sent folder and retry."
        );
        failure && failure(error);
      }
    );
  },
  newPipelineReport: (params, success, failure) => {
    Http.post(
      Core.getApi("Engagements/newPipelineReport"),
      {
        ...params
      },
      success,
      failure
    );
  },
  getStructure: (queryParam = '', cb) => {
    Http.get(
      Core.getApi(`Engagements/getStructure${queryParam}`),
      function onSuccess(response) {
        cb(response);
      }
    );
  },
  getStructureAccount: (queryParam = '', cb) => {
    Http.get(
      Core.getApi(`Engagements/getStructureAccount${queryParam}`),
      function onSuccess(response) {
        cb(response);
      }
    );
  }
};

export default Engagement;
