import React, {Component, Fragment} from "react";

import Definition from "../../../lib/Definition";

import {TextField,FlatButton,AutoComplete,SelectField,MenuItem} from "material-ui";
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

class JobTags extends Component {

    constructor() {
        super(...arguments);
        this.state = {
            id: this.props.element.id,
            key: this.props.element.key || "",
            value: this.props.element.value || "",
            defaultTM: Object.values(Definition.getRes()).map(def=> def.label).pop(),
            def: Definition.getRawDef("jobLevelGroup"),
            sources: Definition.getRawDef("jobLevelGroup").values.map(data=> data.key),
            defAll: Definition.getRes(),
            defAllKeys: Object.values(Definition.getRes()).map(def=> def.label),
            createWithPopup: false
        }
    }

    finalStruct = () => {
        let {id, key, value} = this.state;

        return {
            id,
            key,
            value
        }
    }

    updateAttr = (key, value) => {
        const {onDone} = this.props;
        this.setState({[key] : value}, () => {
            !!onDone && onDone(this.finalStruct())
        });
    }

    autoFillKeyValues = (key) => {
        let {def} = this.state;

        const allValues = def.values;
        const searchedValue = allValues.find(val=>val.key === key);

        if(!!searchedValue){
            this.setState({value: searchedValue.label});
        }
    }

    updateGroup = () => {
        let {def, key, value} = this.state;
        let values = def.values;
        let keys = values.map(el=>el.key);
        let newLabel={};
        let newindex = 0;

        let length = !!values.length ? (values.length - 1) : 0;

        if(!keys.includes(key)){
            newindex = !!values.length ? values[length].id : 0;
            newLabel = {id:newindex+1,key:key,label:value};
            values.push(newLabel);
            Definition.update(def.id,{values})
        }

        let newDefs = {
            ...def,
            values
        }
        let newSources = values.map(data=> data.key);

        this.setState({def: newDefs, sources: newSources});
    }

    updateTagManagement = () => {

        let {defAll, defaultTM, value, key, createWithPopup} = this.state;
        let needToUpdate = {};
        let keyNeedToupdate = "";

        Object.keys(defAll).forEach(def=> {
            if(defAll[def].label === defaultTM){
                needToUpdate = {...defAll[def]};
                keyNeedToupdate = def;
            }
        });

        if(!!Object.keys(needToUpdate).length){
            let values = needToUpdate.values;
            let keys = values.map(el=>el.key);
            let newLabel={};
            let newindex = 0;

            if(!keys.includes(key)){
                newindex = values[values.length-1].id;
                newLabel = {id:newindex+1,key:key,label:value};
                values.push(newLabel);
                Definition.update(needToUpdate.id,{values});
            }

            let newDefs = {
                ...needToUpdate,
                values
            }
            let newDefAll = {
                ...defAll,
                [keyNeedToupdate]: newDefs
            };
            this.setState({defAll: newDefAll, createWithPopup: !createWithPopup});
        }

    }

    handlerDialog = () => {
        let {createWithPopup} = this.state;
        this.setState({createWithPopup: !createWithPopup});
    }

    render() {
        const {key, value, sources, createWithPopup, defAllKeys,defaultTM} = this.state;
        const {element, handlerDeleteItem} = this.props;

        return <Fragment>
            <Grid container>
                <Grid item xs={1} sm={1} style={{width: '50px !important'}}>
                    <span className="fc-group-button-icon fc-button-remove cross-icon-jt" onClick={() => handlerDeleteItem(element)}></span>
                </Grid>
                <Grid item xs={3} sm={3} className="mr-10 mb-10 job-custom-label">
                    <span><strong>Key : </strong></span>
                    <AutoComplete
                        name="search"
                        placeholder="Key"
                        underlineFocusStyle={{ borderColor: "transparent" }}
                        filter={AutoComplete.fuzzyFilter}
                        dataSource={sources}
                        style={{width: 150}}
                        maxSearchResults={7}
                        searchText={key}
                        onUpdateInput={search => this.updateAttr("key", search)}
                        onNewRequest={search => {
                            this.autoFillKeyValues(search)
                        }}
                    />
                </Grid>
                <Grid item xs={3} sm={3} className="mr-10 mb-10 job-custom-label">
                <span><strong>Value : </strong></span>
                    <TextField
                        value={value}
                        style={{width: "150px"}}
                        placeholder={"Value"}
                        onChange={(event, text) => {
                            this.updateAttr('value', text)
                        }}
                    />
                </Grid>
                <Grid item xs={3} sm={3} className="mr-10 mb-10 job-custom-label">
                    <FlatButton
                        label="Make Tag Gobal"
                        className="filter-popup-button uppercase"
                        onClick={()=>{
                            this.updateGroup();
                        }}
                    />
                    {/*<FlatButton*/}
                    {/*    label="Tag M"*/}
                    {/*    className="filter-popup-button uppercase"*/}
                    {/*    onClick={() => {*/}
                    {/*        this.handlerDialog();*/}
                    {/*    }}*/}
                    {/*/>*/}
                </Grid>
            </Grid>

            {!!createWithPopup && <Dialog
                maxWidth='xl'
                open={true}
                onClose={() => {
                }}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <Fragment>
                        <p>Update keys and value in one of the List from Tag Management</p>
                        <SelectField
                            name="TM"
                            value={defaultTM}
                            maxHeight={640}
                            onChange={(event, index, selected) => {
                                this.setState({defaultTM: selected});
                            }}
                            fullWidth
                        >
                            {defAllKeys.map((item,id) => (
                                <MenuItem
                                    key={id}
                                    value={item}
                                    primaryText={item}
                                />
                            ))}
                        </SelectField>
                    </Fragment>
                </DialogContent>

                <DialogActions>
                    <FlatButton
                        label="Cancel"
                        className="filter-popup-button uppercase"
                        onClick={()=>this.handlerDialog()}
                    />
                    <FlatButton
                        label="Update"
                        className="filter-popup-button uppercase"
                        onClick={() => {
                            this.updateTagManagement()
                        }}
                    />
                </DialogActions>
            </Dialog>}

        </Fragment>;
    }
}

export default JobTags;
