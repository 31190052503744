import { Card, CardHeader, CardText } from "material-ui/Card";
import Checkbox from "material-ui/Checkbox";
import Divider from "material-ui/Divider";
import IconButton from "material-ui/IconButton";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import { Table, TableBody, TableRow, TableRowColumn } from "material-ui/Table";
import TextField from "material-ui/TextField";
import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Core, { colors } from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import Employer from "../../../lib/Employer";
import { getEmployerModel } from "../../../lib/models/employer";
import ModalDelete from "./ModalDelete";

class EmployerCard extends Component {
  timeout;
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      ...getEmployerModel({extended:true}),
      /** controller states */
      expanded: false,
      openedColor: null,
      CardStyle: null,
      rightArrow: {},
      /** load */
      ...this.props.model
    };
  }
  unexpandCard = event => {
    this.setState({
      expanded: false,
      CardStyle: null,
      openedColor: null,
      rightArrow: { transform: "rotate(0deg)" }
    });
  };
  expandCard = event => {
    this.setState({
      expanded: true,
      CardStyle: { margin: "10px auto" },
      openedColor: { color: "#715EFF" },
      rightArrow: { transform: "rotate(180deg)" }
    });
    Employer.getBlackList(this.state.id, employerBlackList =>
      this.setState({ employerBlackList })
    );
    Employer.getWhiteList(this.state.id, employerWhiteList =>
      this.setState({ employerWhiteList })
    );
    setTimeout(st => Core.log(this.state));
  };
  handleToggle = event => {
    this.state.expanded ? this.unexpandCard() : this.expandCard();
  };
  onCheckStar = (ev, checked) => {
    const updateLocalList = response => {
      const state = {
        id: this.state.id,
        starredId: response.id,
        starred: response.starred,
        filters: {
          ...this.state.filters,
          Starred: ["Non Starred", "Starred"][~~Boolean(response.starred)]
        }
      };
      this.setState(state);
      this.parent.updateItem(state);
    };
    Employer.updateStarred(
      this.state.id,
      this.state.starredId,
      checked,
      updateLocalList
    );
    this.unexpandCard();
  };
  onChangeEmployerNotes = (ev, publicNotes) => {
    this.setState({ publicNotes });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      Core.updateEmployer(
        this.state.id,
        { publicNotes },
        null,
        response => true
      );
    }, 1000);
  };
  render() {
    const mapActiveJobs = load => {
      const active = this.state.jobs.filter(job =>
        Definition.test("state", job.state, /active/)
      ).length;
      return active ? active + " Active" : <i>&mdash;</i>;
    };
    return (
      <Card
        className="row-card employer"
        expanded={this.state.expanded}
        style={this.state.CardStyle}
      >
        {/** COLLAPSED VIEW */}
        <Table className="collapsed-view" style={{ marginBottom: 10 }}>
          <TableBody displayRowCheckbox={false}>
            <TableRow selectable={false} onClick={this.handleToggle}>
              <TableRowColumn title="Employer" className="first-item">
                {Core.isAdmin() ? (
                  <NavLink
                    title="Go to Edit"
                    to={"/employer/edit/" + this.state.id}
                  >
                    <b
                      style={{
                        color: colors.black,
                        fontWeight: 500,
                        ...this.state.openedColor
                      }}
                    >
                      {this.state._name || <i>&mdash;</i>}
                    </b>
                  </NavLink>
                ) : (
                  <b
                    style={{
                      color: colors.black,
                      fontWeight: 500,
                      ...this.state.openedColor
                    }}
                  >
                    {this.state.name || <i>&mdash;</i>}
                  </b>
                )}
              </TableRowColumn>
              <TableRowColumn
                onClick={this.handleToggle}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                {Definition.getLabel("stage", this.state.stage) || (
                  <i>&mdash;</i>
                )}
              </TableRowColumn>
              <TableRowColumn
                onClick={this.handleToggle}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                {this.state.addressCity || <i>&mdash;</i>}
              </TableRowColumn>
              <TableRowColumn
                onClick={this.handleToggle}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                {mapActiveJobs()}
              </TableRowColumn>
              <TableRowColumn title="Options" className="last-col" rowSpan="2">
                <Checkbox
                  title="Click to Starred"
                  className="star"
                  checked={this.state.starred}
                  onCheck={this.onCheckStar}
                  checkedIcon={<i className="material-icons">star</i>}
                  uncheckedIcon={<i className="material-icons">star_border</i>}
                  iconStyle={this.state.openedColor}
                />
                {
                  Core.isAdmin() && (
                  <IconMenu
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    targetOrigin={{ horizontal: "right", vertical: "top" }}
                    style={{ marginLeft: "4px" }}
                    onClick={ev => {
                      ev.stopPropagation()
                    }}
                    iconButtonElement={
                      <IconButton
                        style={{
                          width: 24,
                          padding: 0
                        }}
                        iconStyle={this.state.openedColor}
                      >
                        <i className="material-icons">more_vert</i>
                      </IconButton>
                    }
                  >
                    {/* <NavLink
                      to={"/employer/view/" + this.state.id}
                      className="navlink"
                    >
                      <MenuItem primaryText="View" />
                    </NavLink>
                    <NavLink
                      to={"/employer/edit/" + this.state.id}
                      className="navlink"
                    >
                      <MenuItem primaryText="Edit" />
                    </NavLink> */}
                    {/* {false &&
                      !this.state.engagements.length && (
                        <MenuItem
                          primaryText="Delete"
                          onClick={event => this.refs.modalDelete.open()}
                        />
                      )} */}
                      <MenuItem
                        primaryText="Delete Employer"
                        onClick={event => this.refs.modalDelete.open()}
                      />
                  </IconMenu>
                )
                }
                <i
                  className="material-icons"
                  style={{
                    width: 24,
                    height: 24,
                    margin: 0,
                    cursor: "pointer",
                    ...this.state.openedColor,
                    ...this.state.rightArrow
                  }}
                  onClick={this.handleToggle}
                >
                  arrow_drop_down
                </i>
              </TableRowColumn>
            </TableRow >
            <TableRow selectable={false} onClick={this.handleToggle}>
              <TableRowColumn
                className="first-col"
                onClick={this.handleToggle}
              >
                {this.state._flags || <i>&mdash;</i>}
              </TableRowColumn>
              <TableRowColumn
                onClick={this.handleToggle}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                {this.state.employeeCount ? (
                  <Fragment>
                    ~{this.state.employeeCount}
                    &nbsp;employees&nbsp;&nbsp;&nbsp;
                  </Fragment>
                ) : (
                  <i>&mdash;</i>
                )}
              </TableRowColumn>
              <TableRowColumn
                onClick={this.handleToggle}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                {Definition.getLabel("state", this.state.state) || (
                  <i>&mdash;</i>
                )}
              </TableRowColumn>
              <TableRowColumn
                onClick={this.handleToggle}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                {this.state.jobs.length ? (
                  this.state.jobs.length + " Jobs"
                ) : (
                  <i>&mdash;</i>
                )}
              </TableRowColumn>
            </TableRow>
          </TableBody>
        </Table>
        <Divider />
        {/** EXPANDED VIEW */}
        <CardText className="expanded-view" expandable={true}>
          {!!this.state.jobs.length && (
            <Fragment>
              <CardHeader title="HIRING" />
              <CardText className="contents">
                {Core.isAdminOrCoordinator()
                  ? this.state.jobs.map(job => (
                      <NavLink key={job.id} to={"/job/view/" + job.id}>
                        <li style={{ marginBottom: 16 }}>
                          {Definition.getLabel("roles", job.role)}
                          &nbsp;&mdash;&nbsp;
                          {Definition.getLabel("state", job.state)}
                        </li>
                      </NavLink>
                    ))
                  : this.state.jobs.map(job => (
                      <li>{Definition.getLabel("roles", job.role)}</li>
                    ))}
              </CardText>
            </Fragment>
          )}
          {!!this.state._technicalSkills.length && (
            <Fragment>
              <Divider />
              <CardHeader title="TECHNOLOGY STACK" />
              <CardText className="contents">
                {this.state._technicalSkills}
              </CardText>
              <Divider />
            </Fragment>
          )}
          <CardHeader title="NOTES" />
          <CardText className="contents">
            <TextField
              name="employerNotes"
              underlineFocusStyle={{
                borderColor: colors.purple,
                borderWidth: "1.5px"
              }}
              type="text"
              rows={3}
              rowsMax={6}
              multiLine={true}
              value={this.state.publicNotes}
              onChange={this.onChangeEmployerNotes}
              fullWidth
            />
          </CardText>
          {Core.isAdminOrCoordinator() &&
            (!!this.state.employerBlackList.length ||
              !!this.state.employerWhiteList.length) && <Divider />}
          {Core.isAdminOrCoordinator() &&
            !!this.state.employerBlackList.length && (
              <div className="ui-g-6">
                <CardHeader
                  avatar={<i className="material-icons">block</i>}
                  title="BLACK LIST"
                />
                <CardText>
                  {this.state.employerBlackList.map(item => (
                    <li key={item.id}>
                      {item.firstName} {item.lastName}
                    </li>
                  ))}
                </CardText>
              </div>
            )}
          {Core.isAdminOrCoordinator() &&
            !!this.state.employerWhiteList.length && (
              <div className="ui-g-6">
                <CardHeader
                  avatar={<i className="material-icons">done</i>}
                  title="WHITE LIST"
                />
                <CardText>
                  {this.state.employerWhiteList.map(item => (
                    <li key={item.id}>
                      {item.firstName} {item.lastName}
                    </li>
                  ))}
                </CardText>
              </div>
            )}
        </CardText>
        <ModalDelete ref="modalDelete" parent={this} />
      </Card>
    );
  }
}

export default EmployerCard;
