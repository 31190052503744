import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
// import {emphasize} from '@mui/styles/colorManipulator';
import AsyncSelect from 'react-select/async';
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
        backgroundColor: //emphasize
        (
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700] // ,0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        width:'800px',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});



class IntegrationReactSelect extends React.Component {
    state = {
        single: null,
        multi: null,
    };

    handleChange = name => value => {
        this.setState({
            [name]: value,
        });
    };


    render() {
        const {classes, selected , defaultMenuIsOpen, placeholder} = this.props;

        let value = !!selected ? selected: null;

        return (
            <div className={classes.root}>
                <AsyncSelect
                    cacheOptions
                    loadOptions={this.props.opts}
                    defaultOptions
                    isClearable={true}
                    isSearchable={true}
                    value={value}
                    defaultMenuIsOpen={defaultMenuIsOpen}
                    onChange={this.props.handleChange}
                    placeholder={placeholder}
                />

            </div>
        );
    }
}

IntegrationReactSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(IntegrationReactSelect);