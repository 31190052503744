import React, {
  Component
} from "react";
import { Redirect } from "react-router";
import {NavLink} from "react-router-dom";
import moment from "moment";

import {
  DatePicker,
  Paper,
  MenuItem,
  SelectField,
  Snackbar,
  Table,
  TableBody,
  TableRow,
  TableRowColumn,
  Divider
} from "material-ui";

import List from '@mui/material/List';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Core from "../../lib/Core";
import Account from "../../lib/Account";
import Employer from "../../lib/Employer";
import Engagement from "../../lib/Engagement";
import onReady from "../../lib/tools/onReady";
import Store from "../../lib/Store";

class Reports extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      linked: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      snackBarOpen: false,
      snackBarMessage: "...",
      engagementsLoading: false,
      open: false,
      scroll: 'paper',
      clickedCountEngagements: [],
      recruiters: [],
      employers: [],
      recruiter: {},
      employer: {},
      duration: "Monthly",
      viewBy: "Engagements",
      startDate: moment()
        .tz("America/Los_Angeles")
        .startOf("Day")
        .subtract(15, "months")
        .startOf("month")
        .toISOString(),
      endDate: moment()
        .tz("America/Los_Angeles")
        .endOf("day")
        .toISOString()
    };
    Store.set("path", window.location.href);
    onReady(this, "rowsContainer").then(em => {
      Account.getActives(recruiters => {
        recruiters = [
          {
            id: "0",
            firstName: "All",
            lastName: "",
            companyName: "",
            email: ""
          },
          ...recruiters
        ]; // Add "All" option
        Employer.getActives(employers => {
          employers = [
            {
              id: "0",
              name: "All"
            },
            ...employers
          ]; // Add "All" option
          this.setState(
            { accountId: "0", recruiters, employerId: "0", employers },
            then => {
              this.getData();
            }
          );
        });
      });
    });
  };

    handleCountClicked = (scroll, val, dateAttr, stage = []) => () => {
        this.setState({engagementsLoading: true});
        this.setState({open: true, scroll});

        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let month = monthNames.indexOf(val.split('/')[0]);
        let year = +2000 + +val.split('/')[1];
        let startMonth = moment
            .tz([year, month], "America/Los_Angeles")
            .startOf("Day")
            .startOf("month")
            .toISOString();

        let endMonth = moment([year, month])
            .tz("America/Los_Angeles")
            .startOf("Day")
            .endOf("month")
            .toISOString();

        let and1 = {};
        and1[dateAttr] = {neq: null};
        let and2 = {};
        and2[dateAttr] = {gte: startMonth};
        let and3 = {};
        and3[dateAttr] = {lte: endMonth};

        let condCombined = [and1, and2, and3];

        if (stage.length > 0) {
            condCombined.push({"or": stage});
        }

        let params = {
            and: condCombined
        };

        Engagement.getWhere(params, response => {
                this.setState({clickedCountEngagements: response});
                this.setState({engagementsLoading: false});
            },
            error => {
                Core.showFailure("Unable to retrieve data: " + error);
                this.setState({engagementsLoading: false});

            }
        );

    };

    handleClose = () => {
        this.setState({open: false});
    };

  showMessage = msg => {
    this.setState({
      snackBarMessage: msg,
      snackBarOpen: true
    });
  };
  hideMessage = () => {
    this.setState({
      snackBarMessage: "",
      snackBarOpen: false
    });
  };
  getData = event => {
    let params = {
      recruiterId: this.state.accountId,
      employerId: this.state.employerId,
      duration: this.state.duration,
      viewBy: this.state.viewBy,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };
    Engagement.newPipelineReport(
      params,
      response => {
        this.setRows(response);
      },
      error => {
        Core.showFailure("Unable to retrieve new pipeline data: " + error);
      }
    );
  };
  setRows = data => {
    /*
    [
      {
        pipeline: "New Submission",
          cols: [
            { group: "1/14-2/14", value: 40 },
            { group: "2/14-3/14", value: 24 },
            { group: "3/14-4/14", value: 34 },
            { group: "3/14-5/14", value: 45 }
          ]
      }, ...
    ]*/
    const rows = [];
    if (data[0]) {
      let myPaddingStyle = {
          paddingLeft: 0,
          paddingRight: 0,
          textAlign: 'center'
      }
      /** TABLE HEADERS */
      rows.push(
        <TableRow key={Core.getKey()} className="bgcolor2" selectable={false}>
          <TableRowColumn  className="bolder fbig" />
          {data[0].cols.map(col => (
            <TableRowColumn key={Core.getKey()} style={myPaddingStyle} className="bolder">
              {col.group}
            </TableRowColumn>
          ))}
        </TableRow>
      );

      let that = this;
      /** TABLE BODY */
      data.forEach(item => {
          rows.push(
              <TableRow key={Core.getKey()} selectable={false}>
                  <TableRowColumn style={myPaddingStyle} className="inline-blocks">
                      {item.pipeline}
                  </TableRowColumn>
                  {item.cols.map(col => (
                      <TableRowColumn style={myPaddingStyle}
                                      key={Core.getKey()}><span
                          onClick={that.handleCountClicked('paper', col.group, col.dateAttr, col.stage)}>{col.value}</span></TableRowColumn>

                  ), this)}
              </TableRow>
          );
      });
      this.setState({ rows });
    }
  };
  render() {
    const lineStyle = { borderColor: "#59736f" };
    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="main-padding cdarker">
        <h3>New Pipeline</h3>
        <Paper className="main-padding">
          <Snackbar
            open={this.state.snackBarOpen}
            message={this.state.snackBarMessage}
            className="snack-bar"
            autoHideDuration={4000}
            onRequestClose={this.hideMessage}
          />
          <Table className="collapsed-view">
            <TableBody displayRowCheckbox={false}>
              <TableRow selectable={false} className="v-align-top">
                <TableRowColumn className="col blocks">
                  <label>Recruiter</label>
                  <SelectField
                    name="recruiter"
                    underlineFocusStyle={{ borderColor: "#59736f" }}
                    value={this.state.accountId || Core.getUserId()}
                    maxHeight={640}
                    onChange={(event, index, accountId) => {
                      const recruiter = this.state.recruiters.find(
                        recruiter => recruiter.id === accountId
                      );
                      this.setState({ accountId, recruiter }, then => {
                        this.getData();
                      });
                    }}
                    fullWidth
                  >
                    {this.state.recruiters
                      .sort((a, b) => {
                        if (a.id === "0") {
                          return -1;
                        }
                        a = String(a.firstName || a.email).toLowerCase();
                        b = String(b.firstName || b.email).toLowerCase();
                        return a > b ? 1 : a < b ? -1 : 0;
                      })
                      .map(item => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          primaryText={
                            item.id === "0"
                              ? item.firstName
                              : `${item.firstName} ${
                                  item.lastName
                                } - ${item.companyName || item.email}`
                          }
                        />
                      ))}
                  </SelectField>
                </TableRowColumn>
                <TableRowColumn className="col blocks">
                  <label>Employer</label>
                  <SelectField
                    name="employer"
                    underlineFocusStyle={{ borderColor: "#59736f" }}
                    value={this.state.employerId}
                    maxHeight={640}
                    onChange={(event, index, employerId) => {
                      const employer = this.state.employers.find(
                        employer => employer.id === employerId
                      );
                      this.setState({ employerId, employer }, then => {
                        this.getData();
                      });
                    }}
                    fullWidth
                  >
                    {this.state.employers
                      .sort((a, b) => {
                        if (a.id === "0") {
                          return -1;
                        }
                        a = String(a.name).toLowerCase();
                        b = String(b.name).toLowerCase();
                        return a > b ? 1 : a < b ? -1 : 0;
                      })
                      .map(item => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          primaryText={item.name}
                        />
                      ))}
                  </SelectField>
                </TableRowColumn>
                <TableRowColumn className="col blocks">
                  <label>Duration</label>
                  <SelectField
                    name="duration"
                    underlineFocusStyle={{ borderColor: "#59736f" }}
                    value={this.state.duration}
                    maxHeight={640}
                    onChange={(event, index, duration) => {
                      this.setState({ duration }, then => {
                        if (/Weekly/i.test(duration)) {
                          this.setState(
                            {
                              startDate: moment()
                                .tz("America/Los_Angeles")
                                .startOf("Day")
                                .subtract(15, "weeks")
                                .startOf("week")
                                .toISOString(),
                              endDate: moment()
                              .tz("America/Los_Angeles")
                              .endOf("day")
                              .toISOString()
                            },
                            then => {
                              this.getData();
                            }
                          );
                        } else {
                          this.setState(
                            {
                              startDate: moment()
                                .tz("America/Los_Angeles")
                                .startOf("Day")
                                .subtract(11, "months")
                                .startOf("month")
                                .toISOString(),
                              endDate: moment()
                                .tz("America/Los_Angeles")
                                .endOf("day")
                                .toISOString()
                            },
                            then => {
                              this.getData();
                            }
                          );
                        }
                      });
                    }}
                    fullWidth
                  >
                    {["Weekly", "Monthly"].map(item => (
                      <MenuItem key={item} value={item} primaryText={item} />
                    ))}
                  </SelectField>
                </TableRowColumn>
                <TableRowColumn className="col blocks">
                  <label>View By</label>
                  <SelectField
                    name="viewBy"
                    underlineFocusStyle={{ borderColor: "#59736f" }}
                    value={this.state.viewBy}
                    maxHeight={640}
                    onChange={(event, index, viewBy) => {
                      this.setState({ viewBy }, then => {
                        this.getData();
                      });
                    }}
                    fullWidth
                  >
                    {["Engagements", "Candidates"].map(item => (
                      <MenuItem key={item} value={item} primaryText={item} />
                    ))}
                  </SelectField>
                </TableRowColumn>
                <TableRowColumn className="col blocks">
                  <label>State Date</label>
                  <DatePicker
                    name="startDate"
                    underlineFocusStyle={lineStyle}
                    formatDate={date => moment(date).format("MM-DD-YYYY")}
                    value={
                      this.state.startDate
                        ? new Date(this.state.startDate)
                        : null
                    }
                    onChange={(ev, startDate) => {
                      this.setState(
                        {
                          startDate: moment.tz(startDate, "America/Los_Angeles")
                            .startOf("day")
                            .toISOString()
                        },
                        then => {
                          this.getData();
                        }
                      );
                    }}
                    fullWidth
                  />
                </TableRowColumn>
                <TableRowColumn className="col blocks">
                  <label>End Date</label>
                  <DatePicker
                    name="endDate"
                    underlineFocusStyle={lineStyle}
                    formatDate={date => moment(date).format("MM-DD-YYYY")}
                    value={
                      this.state.endDate ? new Date(this.state.endDate) : null
                    }
                    onChange={(ev, endDate) => {
                      this.setState(
                        {
                          endDate: moment.tz(endDate,"America/Los_Angeles")
                            .endOf("day")
                            .toISOString()
                        },
                        then => {
                          this.getData();
                        }
                      );
                    }}
                    fullWidth
                  />
                </TableRowColumn>
                <TableRowColumn className="col blocks" />
              </TableRow>
            </TableBody>
          </Table>
          <Divider className=" margin-top" />
          <Table className="collapsed-view">
            <TableBody
              ref={self => (this.rowsContainer = self)}
              displayRowCheckbox={false}
            >
              {this.state.rows}
            </TableBody>
          </Table>
        </Paper>

          <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              scroll={this.state.scroll}
              aria-labelledby="scroll-dialog-title"
          >
              <DialogContent>
                  <strong className="align-center">Engagements</strong>
                  <hr/>
                  <div className="align-center">
                      {this.state.engagementsLoading && <CircularProgress color="inherit"/>}
                      {(this.state.clickedCountEngagements.length > 0) ? (<List subheader={<li/>}>
                          {this.state.clickedCountEngagements.map(obj => (
                              <p key={obj.id}>
                                  <NavLink
                                      title="Click to see engagement "
                                      to={"/engagement/view/" + obj.id}
                                  >
                                      <b>{obj.id}</b>
                                  </NavLink>
                              </p>

                          ))}
                      </List>) : (<p>No Records found</p>)}
                  </div>
              </DialogContent>
              <DialogActions>
                  <Button fullWidth={true} onClick={this.handleClose} color="primary">
                      Close
                  </Button>
              </DialogActions>
          </Dialog>
      </div>
    );
  }
}

export default Reports;
