/*global chrome*/

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  FlatButton,
  List,
  MenuItem, SelectField, TextField
} from "material-ui";
import dig from 'object-dig';
import React, { Component } from "react";
import 'react-tagsinput/react-tagsinput.css';
import Candidate from "../../../lib/Candidate";
import Core from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import Job from "../../../lib/Job";
import formatURL from "../../../lib/tools/formatURL";
import SuccessDialog from "../../Dialogs/Success";
import Chips from "../../Forms/Chips";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";
import Slider from "../../Forms/Slider";
import InputArray from "../../Shared/InputArray";
import Education from "./Education";
import Experience from "./Experience";

class LinkedinFieldsForm extends Component {
  constructor(args) {
    super(args);
    const t = this.props.parent.state._tags || [];
    const myTags = this.props.parent.state._myTags || [];
    this.state = {
      tags: t,
      myTags: myTags,
      allTags: t,
      removedElements: [],
      newElements: [],
      linkedInData: null,
      showQuestions: false
    };


    this.getAvailableRoles();
  };


  componentDidMount() {
  }

  tagOnChange = (allTags, changed, index, opts = { action: 'add' }) => {
    const parent = this.props.parent;
    this.setState({ tags: allTags });
    let myTag = this.state.myTags;

    /** @todo eslint fixings | 2021-08-12 Thu µ */
    const _state = { ...this.state };

    if (opts.action === 'add') {
      _state.newElements.push(allTags.slice(-1));
      myTag = [...myTag, ...this.state.newElements];
    } else {
      _state.removedElements = [...this.state.removedElements, ...changed];
      myTag = myTag.filter(x => !this.state.removedElements.includes(x));
    }

    let latestSet = new Set(myTag);
    let def = this.props.parent.state.tags;

    def[Core.getUserRole()] = {};
    def[Core.getUserRole()][Core.getUserId()] = {};

    def[Core.getUserRole()][Core.getUserId()] = { tags: Array.from(latestSet) };
    parent.setStateStore({ ..._state, tags: def, key: 'tags' });

  };

  parseLinkedIn = () => {

    var extensionId = Core.isLocalHost() ? "gkefmiflkdpdelifedadlpkpcnneogha" : "dfjfkiebdocfeanjkdabfpfjiiffbjam";
    // var extensionId = "dfjfkiebdocfeanjkdabfpfjiiffbjam";

    const lurl = this.props.parent.state.linkedInURL;
    const that = this;
    const firstElementFromArray = function (array, n) {
      if (array == null)
        return void 0;
      if (n == null)
        return array[0];
      if (n < 0)
        return [];
      return array.slice(0, n);
    };

    const matchFromDef = (key, rawText, defaultLabel) => {
      let defaultt = Definition.get(key).find(obj => obj.label === defaultLabel);
      let result = Definition.get(key).find(obj => {
        let labels = obj.label.split(" ");
        return !!labels.find((label) => {
          let regex1 = RegExp(label, 'i');
          return regex1.test(rawText);
        })
      });
      return !!result ? result : defaultt;
    }

    const formatLinkedinJobs = (linkedinJobs) => {
      return linkedinJobs.map((el, index) => {
        let [employerFrom, employerTo] = String(el.date).split('–');
        el.id = index + 1;
        el.employerFrom = employerFrom;
        el.employerTo = employerTo;

        return el;
      });
    }

    const formatLinkedinSchools = (linkedinSchools) => {
      return linkedinSchools.map((el, index) => {
        let degreeFromDef = matchFromDef("undergraduateDegree", el.degree, 'None');

        let dateSplitted = el.date.split('–');
        el.initialDegree = el.degree;
        el.degree = degreeFromDef.id;
        el.graduationYearTo = dateSplitted.pop();
        el.graduationYearFrom = dateSplitted.pop();
        el.id = index + 1;
        return el;
      });
    }

    let passed = false;

    const extensionCb = (type) => {
      chrome.runtime.sendMessage(extensionId, { linkedInUrl: lurl, type },
        response => {

          let [firstKey] = Object.keys(response.data);
          const regexFirstKey = new RegExp(lurl, 'i');

          if (regexFirstKey.test(firstKey)) {
            that.setState({ linkedInData: response.data[firstKey] });

            let userDetails = firstElementFromArray(response.data[firstKey].userDetails) || "";
            let username = that.sanitizeString(userDetails.name) || '';
            let parent = this.props.parent;
            let schoolsLinkedIn = formatLinkedinSchools(response.data[firstKey].education);

            that.props.parent.setStateStore({
              firstName: parent.state.firstName || username.split(' ')[0],
              lastName: parent.state.lastName || username.split(' ')[1],
              jobsLinkedIn: formatLinkedinJobs(response.data[firstKey].userExperience),
              schoolsLinkedIn
            });

            passed = true;
          } else {
            if (!dig(response, 'data')) {
              alert('you need to install extension');
              that.clearInterval();
            }
          }

          if (!passed) {
            window.setTimeout(function () {
              extensionCb('update');
            }, 2000);
          }
        }
      );
    }
    extensionCb('start');
  };

  clearInterval = () => {
    //clearInterval(this.intervalID);
  };

  getAvailableRoles = () => {
    let allRoles = Definition.get('roles');
    let allRolesIds = allRoles.map(el => el.id);
    let filters = { where: { role: { inq: allRolesIds } }, fields: ['role', 'state'] }

    if (!this.state.chipsAvailableRoles) {
      Job.getAll(response => {
        let onlyActiveIds = response.filter(el => parseInt(el.state) === 1);
        let myRoles = this.props.parent.state.roles || [];
        let allAvailableIds = [...onlyActiveIds.map(el => parseInt(el.role)), ...myRoles];
        let availableIdsUniq = Array.from(new Set(allAvailableIds));
        let filtered = allRoles.filter(el => availableIdsUniq.includes(parseInt(el.id)));

        this.setState({ chipsAvailableRoles: filtered })
      }, filters, { commonQuery: { include: false } })
    }
  }

  sanitizeString = (str) => {
    if (typeof str === 'string') {
      str = str.replace(/[^a-z0-9áéíóúñü .,_-]/gim, "");
      return str.trim();
    } else {
      return str;
    }
  }

  render() {
    this.intervalID && clearInterval(this.intervalID);
    const parent = this.props.parent;
    const lineStyle = { borderColor: "#59736f" };
    let yearsOfExperience = parent.state.yearsOfExperience || (new Date()).getFullYear() - parseInt(parent.state.graduationYear);

    return (
      <List className="form">

        <Row>
          <Col>
            <label>
              First Name <b className="cred">*</b>
            </label>
            <TextField
              name="firstName"
              underlineFocusStyle={lineStyle}
              value={parent.state.firstName}
              onChange={(ev, firstName) =>
                parent.setStateStore({
                  firstName,
                  key: 'firstName',
                  errorFirstName: "",
                  errorLastName: ""
                })
              }
              onBlur={ev => !!parent.state.firstName && parent.checkPotentialDuplicatedCandidates(em => { })}
              required
              fullWidth
              errorText={parent.state.errorFirstName}
            />
          </Col>
          <Col>
            <label>
              Last Name <b className="cred">*</b>
            </label>
            <TextField
              name="lastName"
              underlineFocusStyle={lineStyle}
              value={parent.state.lastName}
              onChange={(ev, lastName) =>
                parent.setStateStore({
                  lastName,
                  key: 'lastName',
                  errorFirstName: "",
                  errorLastName: ""
                })
              }
              onBlur={ev => !!parent.state.lastName && parent.checkPotentialDuplicatedCandidates(em => { })}
              required
              fullWidth
              errorText={parent.state.errorLastName}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <label className="inline-blocks">
              LinkedIn&nbsp;
              {!!parent.state.linkedInURL && (
                <i
                  className="material-icons pointer"
                  style={{ fontSize: 18 }}
                  onClick={this.parseLinkedIn}
                >
                  open_in_new
                </i>
              )}
              {!!parent.state.linkedInURL && Core.isAdminOrCoordinator() && (
                <i
                  className="material-icons pointer"
                  style={{ fontSize: 18 }}
                  onClick={this.parseLinkedIn}
                >
                  swap_vert
                </i>
              )}
            </label>
            <TextField
              name="linkedInURL"
              value={parent.state.linkedInURL}
              onChange={(ev, linkedInURL) =>
                parent.setStateStore({
                  linkedInURL: formatURL(linkedInURL),
                  key: 'linkedInURL',
                  errorLinkedIn: ""
                })
              }
              onBlur={ev => !!parent.state.linkedInURL && parent.checkPotentialDuplicatedCandidates(em => { })}
              fullWidth
              underlineFocusStyle={lineStyle}
              errorText={parent.state.errorLinkedIn}
            />
          </Col>

        </Row>
        <Row style={{ overflow: 'visible' }}>
          <Col>
            <label>Currently Employed</label>
            <SelectField
              name="currentlyEmployed"
              underlineFocusStyle={lineStyle}
              value={parent.state.currentlyEmployed}
              onChange={(ev, i, currentlyEmployed) =>
                parent.setStateStore({ currentlyEmployed, key: 'currentlyEmployed' })
              }
              fullWidth
            >
              {Definition.get("diversity").map(state => {
                return (
                  <MenuItem
                    key={state.id}
                    value={state.id}
                    primaryText={state.label}
                  />
                );
              })}
            </SelectField>
          </Col>
          <Col>
            <label>Years of Relevant Work Experience</label>
            <Slider
              name="yearsOfExperience"
              min={0}
              max={30}
              step={1}
              value={yearsOfExperience}
              onChange={(event, yearsOfExperience) =>
                parent.setStateStore({ yearsOfExperience, key: 'yearsOfExperience' })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <Chips
              name="positiveSignals"
              label="Positive Signals"
              values={parent.state.positiveSignals}
              onChange={positiveSignals =>
                parent.setStateStore({ positiveSignals, key: 'positiveSignals' })
              }
            />
            <Chips
              name="negativeSignals"
              label="Negative Signals"
              values={parent.state.negativeSignals}
              onChange={negativeSignals =>
                parent.setStateStore({ negativeSignals, key: 'negativeSignals' })
              }
            />

          </Col>
        </Row>

        {Core.isAdmin() && <InputArray
          heading="Add New Education"
          existing={parent.state.educationHistories.map(el => {
            el.positiveSignalsTags = parent.state.positiveSignals;
            el.negativeSignalsTags = parent.state.negativeSignals;
            return el;
          })}
          onMarkAsDefault={(el) => {
            const latestDegree = Object((el.degree || [])[0]);
            parent.setStateStore({
              undergraduateMajor: latestDegree.degreeMajor,
              undergraduateSchool: el.schoolName,
              graduationYear: latestDegree.endDate || '',
              undergraduateDegree: latestDegree.degreeName
            })
          }
          }
          elementComp={Education}
          parentUpdater={(result) => {
            // create required tags
            let positiveSignalTags = Array.from((new Set(result.map(el => el.positiveSignalsTags).flat())));
            let finalPsArray = Array.from((new Set([...positiveSignalTags].flat())));
            finalPsArray = finalPsArray.filter(el => !!el);

            let negativeSignalTags = Array.from((new Set(result.map(el => el.negativeSignalsTags).flat())));
            let finalNsArray = Array.from((new Set([...parent.state.negativeSignals, ...negativeSignalTags].flat())));
            finalNsArray = finalNsArray.filter(el => !!el);

            parent.setStateStore(
              {
                educationHistories: result,
                positiveSignals: finalPsArray,
                negativeSignals: finalNsArray
              }
            );
          }}
        />}

        {Core.isAdmin() && <InputArray
          source="candidateEdits"
          heading="Add New Experience"
          existing={parent.state.employmentHistories.map(el => {
            el.positiveSignalsTags = parent.state.positiveSignals;
            el.negativeSignalsTags = parent.state.negativeSignals;
            return el;
          })}
          elementComp={Experience}
          extraInfo={
            {
              crossedPositiveSignals: parent.state.crossedPositiveSignals || [],
            }
          }
          onMarkAsDefault={(el) => {
            //autofill old datastructure
            //currentEmployer,currentTitle,currentEmployerFrom,currentEmployerTo
            const latestPosition = Object((el.positionHistories || [])[0]);
            parent.setStateStore({
              currentEmployerTo: latestPosition.endDate,
              currentEmployerFrom: latestPosition.startDate,
              currentEmployer: el.employerOrgName,
              currentTitle: latestPosition.title
            })
          }
          }
          parentUpdater={(result) => {
            // create required tags
            console.log({ result });
            let positiveSignalTags = Array.from((new Set(result.map(el => el.positiveSignalsTags).flat())));
            let finalPsArray = Array.from((new Set([...positiveSignalTags].flat())));
            finalPsArray = finalPsArray.filter(el => !!el);

            let negativeSignalTags = Array.from((new Set(result.map(el => el.negativeSignalsTags).flat())));
            let finalNsArray = Array.from((new Set([...parent.state.negativeSignals, ...negativeSignalTags].flat())));
            finalNsArray = finalNsArray.filter(el => !!el);

            parent.setStateStore(
              {
                employmentHistories: result,
                positiveSignals: finalPsArray,
                negativeSignals: finalNsArray
              }
            );

          }}
        />}



        <SuccessDialog
          ref={confirmDialog => (this.confirmDialog = confirmDialog)}
          modal={true}
          actions={[
            <FlatButton
              label="CANCEL"
              primary={true}
              keyboardFocused={true}
              onClick={event => this.confirmDialog.close()}
            />,
            <FlatButton
              label="CONFIRM"
              primary={true}
              keyboardFocused={true}
              onClick={event => {
                this.confirmDialog.close();
                this.confirmDialog.onRequestClose();
              }}
            />
          ]}
        />

        <Dialog
          fullWidth={'xl'}
          maxWidth={'xl'}
          open={this.state.newDegreeFound}
          onClose={() => { }}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">New Degree(s) found</DialogTitle>
          <DialogContent>
            <InputArray
              heading="Education"
              existing={parent.state.schoolsLinkedIn.filter(el => !!parseInt(Object(el).degree))}
              elementComp={Education}
              parentUpdater={(result) => {

                if (!!parent.state.id) {
                  Candidate.update(
                    parent.state.id,
                    {
                      schoolsLinkedIn: result,
                    },
                    result => {
                      Core.log("RESULT", result);
                      parent.setStateStore({
                        schoolsLinkedIn: result.schoolsLinkedIn,
                        snackbar: "Saved Successfully!"
                      });
                    }
                  )
                } else {
                  parent.setStateStore({
                    snackbar: "System cannot save from here on New Candidate, please save the candidate and it will be automatically saved"
                  });
                }

              }}
            />
          </DialogContent>
          <DialogActions>
            <FlatButton onClick={() => { this.setState({ newDegreeFound: false }) }} color="primary">
              Close
            </FlatButton>
          </DialogActions>
        </Dialog>
      </List>
    );
  }
}
export default LinkedinFieldsForm;
