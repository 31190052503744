import React, { Component } from "react";

import TextField from "material-ui/TextField";
import Slider from "material-ui/Slider";

class CSlider extends Component {
  render() {
    const { min, max, step } = this.props;
    return (
      <div className="slider relative inline-blocks v-aligns-bottom">
        <Slider
          name="maximumSalary"
          min={min}
          max={max}
          step={step}
          className="slider-comp"
          value={this.props.value||0}
          onChange={(event, value) => {
            this.setState({ value });
            if (this.props.onChange instanceof Function) {
              this.props.onChange(event, value);
            }
          }}
        />
        <TextField
          name="maximumSalaryInput"
          className="slider-input"
          type="number"
          value={this.props.value}
          onChange={(event, value) => {
            let numVal = parseFloat(value);
            this.setState({ numVal });
            if (this.props.onChange instanceof Function) {
              this.props.onChange(event, numVal);
            }
          }}
        />
      </div>
    );
  }
}

export { CSlider as default, CSlider as Slider };
