
import Definition from '../Definition';
import {getCollegeCategory} from './getCollegeCategory';

export const findSchoolEducationTag = (school,field,ranking) => {
    let major = field;

    const manageSchoolRanking = (school) => {

        const {positiveSignalTag, matched, schoolRanking} = getCollegeCategory(ranking, school, "getId");
        return {posTags:[positiveSignalTag],schoolMatched:matched,schoolRanking};

        // April - add bootcamp as Negative Signals
        // If school name is
    };

    const manageWordLabels = (major,school)=>{

        const stemTag = (major)=>{
            const list = ['Electrical','EE', 'Mathematics','Math', 'Statistics','Physics','Mechanical Engineering'];
            let id;

            let foundWords = list.filter(word=>{
                let regex = new RegExp(word,'i');
                return regex.test(major);
            });

            if(!!foundWords.length){
               id = Definition.getId('positiveSignals', 'STEM computer related degree');
            }

            return id;

        };

        const computerTag = (major) => {
            if (!major) {
                major = '';
            }

            major = major.trim();

            let out = '';
            if (!!major && /computer/i.test(major)) {
                out = Definition.getId('positiveSignals', 'Computer degree');
            }

            return out;
        };

        const tagsFromSchoolField = (school)=>{
            const list = ['Galvanize','Insight Data Science','Lambda School','Ironhack','AppAcademy','General Assembly','Bloc','Thinkful','Flatiron School','The Tech Academy','Hack Reactor','Tech Talent South','Hackbright Academy'];
            let id;

            let foundWords = list.filter(word=>{
                let regex = new RegExp(word,'i');
                return regex.test(school);
            });

            if(!!foundWords.length){
                id = Definition.getId('negativeSignals', 'Coding Bootcamp');
            }

            return id;
        };

        let posSign = [stemTag(major),computerTag(major)].filter(el=>!!el);
        let negSign = [tagsFromSchoolField(school)].filter(el=>!!el);

        return {negativeSignals: negSign, positiveSignals: posSign};
    };


    let {posTags,schoolMatched,schoolRanking} = manageSchoolRanking(school);
    let {positiveSignals,negativeSignals} = manageWordLabels(major,school);

    let finalArrayPos = [...posTags,...positiveSignals].filter(el=>!!el);
    let finalArrayNeg = [...negativeSignals].filter(el=>!!el);

    return {
       positiveSignalsTags: finalArrayPos,
       negativeSignalsTags: finalArrayNeg,
       rankingMeta: schoolMatched,
       schoolRanking
    }

}


