import React, {Component} from 'react';
import Row from "../../Forms/Row";
import Col from "../../Forms/Col";
import TextField from "material-ui/TextField";
import Grid from "@mui/material/Grid";
import YearsDropdown from "../../Shared/YearsDropdown";
import InputWithTagManagement from "../../Shared/InputWithTagManagement";

class EducationDegree extends Component {

    constructor(props) {
        super(props);
        this.state = {...this.props.element};
        if (this.props.isNewRecord) {
            this.props.onDone(this.getUpdatedStructure());
        }
    }

    getUpdatedStructure = () => {
        const {id,degreeName, degreeMajor, startDate, endDate, startDateMonth, startDateYear, endDateMonth, endDateYear} = this.state;
        return {id, degreeName, degreeMajor, startDate, endDate, startDateMonth, startDateYear, endDateMonth, endDateYear};
    };

    render() {
        let {onDone, handlerDeleteItem, element} = this.props;
        const {degreeName, degreeMajor, id, startDateMonth, startDateYear, endDateMonth, endDateYear} = this.state;
        let lineStyle = '';

        return <Grid item xs={12} style={{backgroundColor: '#E8E8E8', marginBottom: '10px'}}>
            <Row>
                <Col fullWidth>
                    <InputWithTagManagement
                      key={`degree-input-tag-management${id}`}
                      label={
                          <label> Degree <span className="fc-group-button-icon fc-button-remove" onClick={() => handlerDeleteItem(element)}></span></label>
                      }
                      value={degreeName}
                      tagManagementKey={'undergraduateDegree'}
                      onChange={(value, intent) => {
                          this.setState({degreeName: value}, () => {
                              onDone(this.getUpdatedStructure());
                          })
                      }
                      }
                      onBlur={(ev, value) => {
                          this.setState({degreeName: String(value).trim()})
                      }
                      }
                    />

                    {/*<SelectWithOther*/}
                    {/*    value={parseInt(degreeName)}*/}
                    {/*    otherValue={degreeName}*/}
                    {/*    updater={(data) => {*/}
                    {/*        this.setState({degreeName: data.selectValue, degreeOtherValue: data.otherValue}, () => {*/}
                    {/*            // onDone(this.getUpdatedStructure());*/}
                    {/*        });*/}

                    {/*    }}*/}
                    {/*/>*/}
                </Col>

            </Row>
            <Row>
                <Col>
                    <label>Start date</label>
                    <YearsDropdown
                      month={startDateMonth}
                      year={startDateYear}
                      onChangeHandler={(startDateMonth, startDateYear) => {
                          this.setState({startDateMonth, startDateYear}, () => {
                              onDone(this.getUpdatedStructure())
                          })
                      }}
                    />
                </Col>

                <Col>
                    <label>End date (or expected)</label>
                    <YearsDropdown
                      month={endDateMonth}
                      year={endDateYear}
                      onChangeHandler={(endDateMonth, endDateYear)=>{
                          this.setState({endDateMonth, endDateYear}, () => {
                              onDone(this.getUpdatedStructure())
                          })
                      }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <label>Field of study</label>
                    <TextField
                        name="field"
                        underlineFocusStyle={lineStyle}
                        rows={1}
                        rowsMax={5}
                        multiLine={true}
                        value={degreeMajor}
                        onChange={(ev, degreeMajor) =>
                            this.setState({degreeMajor})
                        }

                        onBlur={() => {
                            //CS/CE degree
                            this.setState({}, () => {
                                // this.findMyEducationTag();
                                onDone(this.getUpdatedStructure());
                            })

                        }
                        }
                        fullWidth
                    />
                </Col>
            </Row>

        </Grid>
    }
}


export default EducationDegree;