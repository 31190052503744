import {colors} from "./Core";

const getStructure = (ownershipResult, employerId, currentCandoId, jobId = null, identifiedDuplicateCando, isCandoUniqDirty=false) => {
    const myStats = ownershipResult.filter(obj => (String(obj.candidateId) === String(currentCandoId)));
    ownershipResult = ownershipResult.filter(obj => (String(obj.candidateId) !== String(currentCandoId)));

    const forThisEmployerOnly = ownershipResult.filter(obj => !!obj.conflictedEmployers.find(obj =>
      String(obj.employerId) === String(employerId)) && !!obj.isPotentialStrongConflict);

    let isOwned = forThisEmployerOnly.filter(obj => !!obj.conflictedEmployers.find(obj => String(obj.employerId) === String(employerId) && obj.hasOwnership === 'owned'));
    let isTbd = forThisEmployerOnly.filter(obj => !!obj.conflictedEmployers.find(obj => String(obj.employerId) === String(employerId) && obj.hasOwnership === 'tbd'));

    let color = colors.lightRed;
    let label = 'OwnedByOther';
    let hasEngagement = false;

    //checking for just respectively picked cando
    const identifiedDuplicateCandoId = Object(identifiedDuplicateCando).id;

    if (!!identifiedDuplicateCandoId) {
        isOwned = isOwned.filter(obj => String(obj.candidateId) === String(identifiedDuplicateCandoId) || String(currentCandoId) === String(obj.candidateId));
        isTbd = isTbd.filter(obj => String(obj.candidateId) === String(identifiedDuplicateCandoId) || String(currentCandoId) === String(obj.candidateId));
    }

    let iOwnIt = myStats.find(obj => !!obj.conflictedEmployers.find(obj => String(obj.employerId) === String(employerId) && obj.hasOwnership === 'owned'));
    let iTbdIt = myStats.find(obj => !!obj.conflictedEmployers.find(obj => String(obj.employerId) === String(employerId) && obj.hasOwnership === 'tbd'));

    if (!isOwned.length) {
        if (!!isTbd.length || !!iTbdIt) {
            color = colors.gray;
            label = 'TbdForOther'
        } else {
            color = colors.purple;
            label = 'OwnedbyNoOne'
        }
    }

    if (!!myStats.length) {
        hasEngagement = myStats[0].conflictedEmployers.find(obj => !!obj.hasEngagement && String(obj.jobId) === String(jobId));
    }

    let iTbdItWithoutEngagement = false;
    if(!isCandoUniqDirty){

        iTbdItWithoutEngagement = iTbdIt && !hasEngagement;

        if (!!iOwnIt) {
            color = colors.green;
            label = 'iAlreadyOwnIt';
        } else if (iTbdItWithoutEngagement) {
            color = colors.gray;
            label = 'TbdForCando';
        }
    }else{
    }


    return {color, label, isOwned, iOwnIt, isTbd, hasEngagement, iTbdItWithoutEngagement};
};

export default getStructure;