/** ============================================ µ
 * @description Filter [JSX]
 *              Library
 * @createdAt   2021-07-24
 * @updatedAt   2021-07-30
 * ============================================ */
/* IMPORTS ==================================== */

import moment from "moment";
import filterPatterns from "../../../static/filter-bar__location-menus__filter-patterns.json";
import jobPatterns from "../../../static/filter-bar__location-menus__job-patterns.json";
import filterTestCases from "../../../static/filter-bar__test-cases__AC_2021-07-27_tue.json";
import Definition, {
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID,
  STATE_ACTIVE,
  STATE_HOLD
} from "../../Definition";
import LocationLib from "../../DefinitionLocation.lib";
import {
  getArrayDiff,
  getArrayOfNumberSeries,
  NOT,
  parseBinaryResults,
  YES
} from "../../GenericTools.lib";
import { CANDIDATE_MODEL_NAME, getCandidateModel } from "../../models/candidate";
import { JOB_MODEL_NAME } from "../../models/job";
import Store from "../../Store";

/* CONSTANTS ================================== */

const UNIT_TESTING_ENABLED_EXTENDED = (
  window.location.href.includes('test-all-filters-extended')
);

/* METHODS ==================================== */

function filterList(options) {

  let {
    items = [],
    minimumSalary = 0,
    minimumXp = 0,
    minimumCompanySize = 0,
    keywords = [],
    menus = [],
    more = [],
    pageName = '',
  } = options;

  let filtered = [...items];

  // console.debug(options);

  /** FILTER BY MINIMUM AND MAXIMUM SALARY */
  if (
    /Jobs|CandidateMatch/.test(pageName) &&
    minimumSalary !== 0
  ) {
    filtered = filtered.filter(
      item =>
        Number(
          String(item.salaryMax || Number.MAX_SAFE_INTEGER).replace(
            /(\..*)|\D/g,
            ""
          )
        ) >= Number(minimumSalary)
    );
  } else if (
    /Candidates|JobMatch/.test(pageName) &&
    minimumSalary !== 0
  ) {
    filtered = filtered.filter(
      item =>
        Number(
          String(item.minimumSalary || 0).replace(/(\..*)|\D/g, "")
        ) <= Number(minimumSalary)
    );
  }

  /** FILTER BY MINIMUM AND MAXIMUM XP */
  if (
    /Jobs|CandidateMatch/.test(pageName) &&
    minimumXp !== 0
  ) {
    filtered = filtered.filter(
      item =>
        Number(
          Number(item.minYearsOfExperience) > -1
            ? String(item.minYearsOfExperience).replace(/(\..*)|\D/g, "")
            : String(Number.MAX_SAFE_INTEGER).replace(/(\..*)|\D/g, "")
        ) <= Number(minimumXp)
    );
  } else if (
    /Candidates|JobMatch/.test(pageName) &&
    minimumXp !== 0
  ) {
    filtered = filtered.filter(
      item =>
        Number(
          String(item.yearsOfExperience || 0).replace(/(\..*)|\D/g, "")
        ) >= Number(minimumXp)
    );
  }

  /* FILTER BY COMPANY SIZE */
  if (
    /Jobs/i.test(pageName) &&
    minimumCompanySize > 0
  ) {
    filtered = filtered.filter(
      item => (!!item.employer &&
        Number(
          Number(item.employer.employeeCount) > -1
            ? String(item.employer.employeeCount).replace(/(\..*)|\D/g, "")
            : String(Number.MAX_SAFE_INTEGER).replace(/(\..*)|\D/g, "")
        ) <= Number(minimumCompanySize))
    );
  }

  /** FILTER BY KEYWORDS */
  if (!!keywords.length) {
    filtered = filtered.filter(item => {
      return keywords.every(objKeyword => {
        return item.___keys___.some(label =>
          new RegExp(
            String(objKeyword.name)
              .replace("+", "\\+")
              .replace(".", "\\.")
              .replace("#", "\\#")
              .replace("(", "\\(")
              .replace(")", "\\)"),
            "i"
          ).test(label)
        );
      });
    });
  }

  filtered = filterItemsByCheckedOptionsInFilterBar({
    items: filtered,
    menus,
    more
  });

  /** * /
  console.debug('filterList-inputs', {
    minimumSalary,
    minimumXp,
    minimumCompanySize,
    keywords,
    menus,
    more,
    pageName,
  });
  console.debug('filtered', filtered.length);
  /** */

  return filtered;
}

/**
 * 
 * Returns a filtered list of candidates by c.matchExclusions[0].excludeUntil
 * 
 * story-3869-m4 | 2021-08-30 Mon µ
 * 
 * @param {object} options
 * @param {object[]} options.candidates
 * @returns {object[]}
 */
function filterCandidatesByMatchExclusion({
  candidates = []
}) {

  let filtered = [...candidates];

  filtered = filtered.filter(candidate => {
    const { matchExclusions = [], state } = candidate;
    if (state !== STATE_ACTIVE && state !== STATE_HOLD) { return true; }
    const _matchExclusions = matchExclusions[0] || getCandidateModel({ matchExclusion: true });
    const { excludeUntil = '' } = _matchExclusions;
    if (YES(excludeUntil) && moment().isBefore(excludeUntil)) {
      console.debug(`match-exclude:${candidate._name}`);
      return false;
    }
    return true;
  });

  return filtered;

}

/* epic-3038(new locations)-story-3578-M2 | 2021-07-29 Thu µ */
/**
 *
 * @param {*} options
 * @param {object[]} options.items
 * @param {object[]} options.menus
 * @param {object[]} options.more
 * @returns
 */
function filterItemsByCheckedOptionsInFilterBar({
  items = [],
  menus = [],
  more = []
}) {

  let filtered = [...items];

  /** FILTER BY MENU KEYS */
  const checks = {}; // to store the checked menu items for filter
  let filter = false; // flag for the filter by tag

  /* epic-3038(new locations)-story-3492-m11 */
  let isFilteringLocations = false;
  const checkedLocationOptionsMenu = {};

  /* step 1: get selected tags */
  [...menus, ...more].forEach(menu => {
    const labels = menu.items
      ? Object.keys(menu.items).filter(
        label => menu.items[label] === true
      )
      : [];

    if (!!labels.length) {
      /* set filter if there is a selected tag in menu */

      if (menu['mappings']) {
        checks[menu.key] = labels.map(label => {
          return menu['mappings'][label];
        }).flat();
      } else {
        checks[menu.key] = labels;
      }

      filter = true;

    }
  });

  /* list-pages-filtering */
  /* step 2: if filter flag is true, then filter by the checked menu items */
  if (filter) {
    filtered = filtered.filter(item => { // Jobs/Candidates loop
      // for all menus (AND)
      return Object.keys(checks).every(menuKey => { // Menu loop
        // Except for location menus
        if (
          !!menuKey.match(
            /inOfficeRemoteFlags|officeLocations|candidateLocations/i
          )
        ) {
          checkedLocationOptionsMenu[menuKey] = checks[menuKey];
          return isFilteringLocations = true;
        }


        // find some menu item (OR)
        const match = checks[menuKey].some(

          /* epic-3038(new locations)-story-3675-m3 | 2021-08-04 Wed µ */
          (value = '') =>
            !!item.___keys___.includes(value.trim())

        );

        // console.debug('Checks/Keywords', checks[menuKey], item.___keys___, match);

        return match;
      });
    });
  }

  // console.debug('filteredA', filtered.length);

  /* epic-3038(new locations)-story-3492-m11 */
  if (!!filtered.length && isFilteringLocations) {

    const {
      itemsHasJobs,
      itemsHasCandidates,
      inOfficeRemoteFlags = [],
      candidateLocations = [],
      officeLocations = [],
    } = translateLocationMenus({
      filtered,
      checkedLocationOptionsMenu,
    })

    filtered = filterListLocationMenus({
      items: filtered,
      itemsHasJobs,
      itemsHasCandidates,
      inOfficeRemoteFlags,
      candidateLocations,
      officeLocations,
    })

  }

  FilterLib.unitTestingAllFilters(filtered);

  return filtered;

}

/**
 *
 * @param {object} options
 * @param {object} options.checkedLocationOptionsMenu
 * @param {number[]} options.checkedLocationOptionsMenu.inOfficeRemoteFlags inOfficeRemoteTagIds
 * @param {number[]} options.checkedLocationOptionsMenu.candidateLocations locationTagIds
 * @param {number[]} options.checkedLocationOptionsMenu.officeLocations locationTagIds
 * @param {object[]} options.filtered
 * @param {string} options.filtered.___model____
 * @returns menus as an arrays of tag-ids
 */
function translateLocationMenus({
  checkedLocationOptionsMenu = {},
  filtered = [],
}) {

  const getTagLabel = (prefixedLabel = '') => prefixedLabel.replace(/^.+: /, '').trim();
  const itemsHasJobs = YES(filtered[0]?.___model___ === JOB_MODEL_NAME);
  const itemsHasCandidates = YES(filtered[0]?.___model___ === CANDIDATE_MODEL_NAME);

  let {
    inOfficeRemoteFlags = [],
    candidateLocations = [],
    officeLocations = [],
  } = checkedLocationOptionsMenu;

  /* map prefixed-labels to location-tag-ids */
  inOfficeRemoteFlags = inOfficeRemoteFlags.map(v =>
    Definition.getId('inOfficeRemote', getTagLabel(v))
  );
  candidateLocations = candidateLocations.map(v =>
    Definition.getId('location', getTagLabel(v))
  );
  officeLocations = officeLocations.map(v =>
    Definition.getId('location', getTagLabel(v))
  );

  return ({
    itemsHasJobs,
    itemsHasCandidates,
    inOfficeRemoteFlags,
    candidateLocations,
    officeLocations,
  });

}

function filterMatchSingleItem({ filter, item }) {

  // console.debug({ item });

  const filterSetRemote = YES(
    filter.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
  );

  const filterSetPartWeek = YES(
    filter.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
  );

  const filterSetFullWeek = YES(
    filter.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
  );

  const filterSetCandidateLocations = YES(
    filter.candidateLocations.length
  );

  const filterSetOfficeLocations = YES(
    filter.officeLocations.length
  );

  const itemSetRemote = YES(
    item.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
  );

  const itemSetPartWeek = YES(
    item.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
  );

  const itemSetFullWeek = YES(
    item.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
  );

  const itemMatchFilterCandidateLocations = YES(
    NOT(item.candidateLocations.length) ||
    item.candidateLocations.find(itemCandidateLocationId =>
      filter.candidateLocations.find((filterCandidateLocationId = '') =>
        YES(

          /* epic-3038(new locations)-story-3689-m3 | 2021-08-05 Thu µ */
          LocationLib.evalLineage({
            memberA: filterCandidateLocationId,
            memberB: itemCandidateLocationId
          })

          /* epic-3038(new locations)-story-3689-m3 | 2021-08-05 Thu µ */
          /** @todo mark to cleanup | 2021-08-05 Thu µ */
          /** * /
          (filterCandidateLocationId === itemCandidateLocationId) ||
          LocationLib.evalAlias({
            // Filter set alias - Pacific
            aliasId: filterCandidateLocationId,
            // Job set location - California => match
            locationId: itemCandidateLocationId
          }) ||
          LocationLib.evalAlias({
            // Job set alias - Pacific
            aliasId: itemCandidateLocationId,
            // Filter set location - California => match [?] April
            locationId: filterCandidateLocationId
          }) ||
          LocationLib.evalAncest({
            ancestId: filterCandidateLocationId,
            descendantId: itemCandidateLocationId
          })
          /** */

        )
      )
    )
  );

  const itemMatchFilterOfficeLocations = YES(
    NOT(item.officeLocations.length) ||
    item.officeLocations.find(itemOfficeLocationId =>
      filter.officeLocations.find(filterOfficeLocationId =>
        YES(

          /* epic-3038(new locations)-story-3689-m3 | 2021-08-05 Thu µ */
          LocationLib.evalLineage({
            memberA: filterOfficeLocationId,
            memberB: itemOfficeLocationId
          })

          /* epic-3038(new locations)-story-3689-m3 | 2021-08-05 Thu µ */
          /** @todo mark to cleanup | 2021-08-05 Thu µ */
          /** * /
          (itemOfficeLocationId === filterOfficeLocationId) ||
          LocationLib.evalAlias({
            aliasId: filterOfficeLocationId,
            locationId: itemOfficeLocationId
          }) ||
          LocationLib.evalAlias({
            aliasId: itemOfficeLocationId,
            locationId: filterOfficeLocationId
          }) ||
          LocationLib.evalAncest({
            ancestId: filterOfficeLocationId,
            descendantId: itemOfficeLocationId
          })
          /** */

        )
      )
    )
  );

  const filterId = parseBinaryResults([
    filterSetRemote,
    filterSetFullWeek,
    filterSetPartWeek,
    filterSetCandidateLocations,
    filterSetOfficeLocations
  ]);

  const itemMatchId = parseBinaryResults([
    itemSetRemote,
    itemSetFullWeek,
    itemSetPartWeek,
    itemMatchFilterCandidateLocations,
    itemMatchFilterOfficeLocations
  ]);

  const filterPatternIds = [0].concat(filterPatterns[filterId]);

  const match = (
    undefined !== filterPatternIds.find(patternId =>
      (jobPatterns[patternId] || []).includes(itemMatchId)
    )
  );

  /* story-3492-m11 | 2021-07-26 Mon µ */
  // unit-testing
  if (UNIT_TESTING_ENABLED_EXTENDED && !!item.__unitTestingItemId) {
    console.debug(item.jobTitle || item._name, match, {
      unitTestingItemId: item.__unitTestingItemId,
      filterId,
      filterPatternIds,
      filterSetRemote,
      filterSetFullWeek,
      filterSetPartWeek,
      filterCandidateLocations: filter.candidateLocations,
      filterOfficeLocations: filter.officeLocations,
      itemMatchId,
      itemSetRemote,
      itemSetFullWeek,
      itemSetPartWeek,
      itemMatchFilterCandidateLocations,
      itemMatchFilterOfficeLocations,
      itemCandidateLocations: item.candidateLocations,
      itemOfficeLocations: item.officeLocations,
    });
  }

  return match;

}

/**
 * Filters a list of items according to the filter-bar inputs.
 *
 * @param {object} options
 * @param {number[]} options.inOfficeRemoteFlags filter-bar inOfficeRemote TagIds
 * @param {number[]} options.candidateLocations filter-bar location TagIds
 * @param {number[]} options.officeLocations filter-bar location TagIds
 * @param {object[]} options.items list of items to filtering
 * @param {boolean} options.itemsHasJobs true if items is a list of jobs - useless by the moment
 * @param {boolean} options.itemsHasCandidates true if items is a list of candidates - useless by the moment
 * @returns {object[]} filtered list of items
 */
function filterListLocationMenus({
  inOfficeRemoteFlags = [],
  candidateLocations = [],
  officeLocations = [],
  items = [],
  itemsHasJobs = false,
  itemsHasCandidates = false,
}) {

  let filtered = [...items];

  const filter = {
    inOfficeRemoteFlags,
    candidateLocations,
    officeLocations,
  }

  filtered = filtered.filter(item => filterMatchSingleItem({ filter, item }));

  return filtered;

}

/**
 * Unit testing
 *
 * @param {object[]} items list of jobs
 * @returns
 */
function unitTestingAllFilters(items = []) {

  const unitTestingAllFiltersInListPages = Store.get('unitTestingAllFiltersInListPages');

  if (NOT(unitTestingAllFiltersInListPages) || YES(window.location.href.match(/match/))) { return; }

  let _testCasesSource = 'local';
  let _filterTestCases = window.location.href.match(/___(.+)___/) || [];

  _filterTestCases = _filterTestCases[1];

  if (_filterTestCases) {
    try {
      _filterTestCases = JSON.parse(atob(_filterTestCases));
      _testCasesSource = 'url';
    }
    catch (ex) {
      console.debug(ex);
      console.debug(_filterTestCases);
      _filterTestCases = filterTestCases;
    }
  }
  else {
    _filterTestCases = filterTestCases;
  }

  items = items.filter(m => m.header === undefined);

  if (!!items.length) {

    Object.keys(_filterTestCases).forEach(testId => {

      const {
        inOfficeRemoteFlags = [],
        candidateLocations = [],
        officeLocations = [],
        expected = '',
      } = _filterTestCases[testId];

      UNIT_TESTING_ENABLED_EXTENDED && console.debug('ui-items', items.length);

      const filtered = filterListLocationMenus({
        items,
        itemsHasJobs: true,
        inOfficeRemoteFlags,
        candidateLocations,
        officeLocations
      });

      const _expected = getArrayOfNumberSeries(expected);
      const results = filtered.map(j => j.__unitTestingItemId).filter(n => !!n);
      const diff = getArrayDiff(_expected, results);

      if (UNIT_TESTING_ENABLED_EXTENDED) {
        console.debug('test-cases-source', _testCasesSource);
        console.debug('filtered', filtered.length);
      }

      console.debug('inOfficeRemoteFlags', inOfficeRemoteFlags);
      console.debug('candidateLocations', candidateLocations);
      console.debug('officeLocations', officeLocations);
      console.debug('expected', `(${_expected.length}) [${expected}]`);
      UNIT_TESTING_ENABLED_EXTENDED && console.debug('raw-expected', _expected);
      console.debug(`results (${results.length}) [${results.join(',')}]`);

      (
        !!diff.length
          ? console.debug(`\u001b[33m${!diff.length} testId ${testId} diff\u001b[0m (${diff.length}) [${diff.join(',')}]`)
          : console.debug(`\u001b[32m${!diff.length} testId ${testId}\u001b[0m`)
      );

      console.debug('=============================');

    });

  }

}

/* DICTIONARIES =============================== */

const FilterLib = {
  filterList,
  filterCandidatesByMatchExclusion,
  filterItemsByCheckedOptionsInFilterBar,
  unitTestingAllFilters,
  translateLocationMenus,
  filterListLocationMenus,
  filterMatchSingleItem,
}

/* EXPORTS ==================================== */

export { FilterLib as default, FilterLib };

/* ============================================ */