import Chip from "@mui/material/Chip";
import _ from "lodash";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import React, { Fragment } from "react";
import CandidatePipe from "../../../../components/Candidates/MatchNew/CandidatePipe/CandidatePipe";
import CandidatePipeHeader from "../../../../components/Candidates/MatchNew/CandidatePipe/CandidatePipeHeader";
import JobPipe from "../../../../components/Candidates/MatchNew/JobPipe/JobPipe";
import JobPipeHeader from "../../../../components/Candidates/MatchNew/JobPipe/JobPipeHeader";
import CompanyPreference from "../../../../components/Candidates/MatchStrategy/Comparison/CompanyPreference";
import LoctionDetails from "../../../../components/Candidates/MatchStrategy/Comparison/LocationDetails";
import Stage from "../../../../components/Candidates/MatchStrategy/Comparison/Stage";
import WorkFromHome from "../../../../components/Candidates/MatchStrategy/Comparison/WorkFromHome";
import Candidate from "../../../../lib/Candidate";
import CandidateSkillsAction from "../../../../lib/CandidateSkillsAction";
import Core from "../../../../lib/Core";
import Engagement from "../../../../lib/Engagement";
import FilterControl from "../../../../lib/FilterControl";
import Google from "../../../../lib/Google";
import Job from "../../../../lib/Job";
import { getCandidateModel } from "../../../../lib/models/candidate";
import { getJobModel } from "../../../../lib/models/job";
import FilterLib from "../../../../lib/services/Filtering/Filter.lib";
import MatchLib from "../../../../lib/services/Matching/Match.lib";
import formatMoney from "../../../../lib/tools/formatMoney";
import { YearsOfExperienceColor } from "../../../../lib/YearOfExperienceColor";
import SingleCandidateCard from "../../../Candidates/MatchNew/JobPipeCard/SingleCandidateCard";
import {
  getOwnershipChip, notFoundInPermittedJobWithOwnership
} from "../../../Candidates/MatchStrategy/Strategy";
import EmailPreview from "../../../Dialogs/EmailPreview";
import Slider from "../../../Forms/Slider";
import MatchLocationChips, { MATCH_LOCATION_CHIPS__CD_REQUESTER, MATCH_LOCATION_CHIPS__JD_REQUESTER } from "../../../Shared/MatchLocationChips";


let allCandidates = [];
let job = null;
let EmailMessage = null;

const subjectFunc = (id) => (cb) => {
  Job.get(id, (j) => {
    Engagement.getWhere({ jobId: j.id }, (eng) => {
      j["tempSalaryMin"] = j.salaryMax;
      //April doesn't want it anymore as we have loose match buffer available
      // j['tempMinimumSalary'] = j.salaryMax*1.4;
      j["tempMinimumSalary"] = j.salaryMax;
      j["tempMinYearsOfExperience"] = j.minYearsOfExperience;
      j["tempMinimumExperience"] = j.minYearsOfExperience;
      job = j;
      !!cb && cb(j, eng);
    });
  });
};

const objectFunc = (id, cb) => { };

const objectListFunc =
  (params) =>
    (cb, paramsExtra = {}) => {
      const paramsObj = new URLSearchParams({ ...params, ...paramsExtra });

      if (params.loadAll) {
        Candidate.getAll((candidates) => {
          allCandidates = candidates;
          !!cb && cb(candidates);
        });
      } else {

        /* FILTERING FIELD FROM DB!! */
        const filters = {
          fields: [
            "id",
            "firstName",
            "lastName",
            "email",
            "phone",
            "yearsOfExperience",
            "minimumSalary",
            "workLocationIds",
            "visa",
            "platformRating",
            "technicalSkills",
            "linkedinUrl",
            "githubUrl",
            "stage",
            "state",
            "roles",
            "introduced",
            "accountId",
            "jobsPermitted",
            "positiveSignals",
            "negativeSignals",
            "jobsPitched",
            "jobsDeclined",
            "isDuplicate",
            "techSkillsInResume",
            "jobsLinkedIn",
            "techSkillsInNotes",
            "technologyDomain",
            "officeLocations",
            "desiredEmploymentTypes",

            /* epic-3038-story-3459 - 2021-07-14 µ */
            "candidateLocations",

            /* epic-3038-story3330-m2 | 2021-07-01 µ */
            "inOfficeRemoteFlags",

            // story-3869 | 2021-08-30 Mon µ
            'matchExclusions',

            // story-2572 | bug fixing | 4391-M11/08a
            'updatedAt'

          ],
        };

        Candidate.getActiveCandidates(
          (candidates) => {
            allCandidates = candidates;
            !!cb && cb(candidates);
          },
          { duration: paramsObj.get("d"), filters }
        );
      }
    };

const getDefaultFields = (job) => {
  return [
    { key: "visa", label: job._visaTransfer, checked: true },
    { key: "locationCandidate", label: job._locations, checked: true },
    { key: "stage", label: job._employeeStage, checked: false },
  ];
};

const filterControl = ({
  subject, // JOB
  object // LIST OF CANDIDATES
}) => {

  let preset = "^Active$";
  if (subject._roles && subject._roles.length) {
    preset += ("|^" + subject._roles.replace(/, |,/g, "$|^") + "$");
  }
  // console.debug({ preset },subject._roles);

  /**
   * If it is Job._jobType defined
   * then preset it to filter Candidates by
   * Candidate._desiredEmploymentTypes
   * story-3083-M1-1
   */
  if (subject._jobType && !!subject._jobType.length) {
    preset += `|^${subject._jobType.trim().replace(/\(|\)/g, ".+")}$`;
  }

  const label = getDefaultFields(subject);
  const fields = label;

  const filters = FilterControl.setInitialSearchMenu(
    object,
    _.cloneDeep(Candidate.menus),
    _.cloneDeep(Candidate.more),
    preset,
    subject,
    [],
    label,
    fields,
    getChips,
    { job: subject }
  );

  const { menus, more, sources, chips } = filters;

  const filteredObjects = filterCandidates(subject, object, [], menus, more);

  return {
    menus,
    more,
    sources,
    chips,
    filteredObjects,
    constraintsObjects: [],
    withoutConstraintsObjects: object,
  };
};

const skillsHandler = (candidate, cbUpdateCandidates) => (skills, type) => {
  let oldSkills;
  let oldCandidateState;
  let oldCandidateStateIndex;
  let withoutThisCandidate;
  let updateOldCandidateState;
  let updatedCandidate;

  switch (type) {
    case "technicalSkills":
      oldSkills = candidate.technicalSkills;

      oldCandidateState =
        allCandidates.find((c) => c.id === candidate.id) || candidate;
      oldCandidateStateIndex = allCandidates.findIndex(
        (c) => c.id === candidate.id
      );
      withoutThisCandidate = allCandidates.filter((c) => c.id !== candidate.id);

      updateOldCandidateState = {
        ...oldCandidateState,
        technicalSkills: skills,
      };

      updatedCandidate = [...withoutThisCandidate];

      updatedCandidate.splice(
        oldCandidateStateIndex,
        0,
        updateOldCandidateState
      );
      allCandidates = [...updatedCandidate];

      !!cbUpdateCandidates && cbUpdateCandidates(allCandidates);
      Candidate.update(
        candidate.id,
        {
          technicalSkills: skills,
        },
        (response) => {
          const dirtyAttrs = [
            {
              key: type,
              label: "Technical Skills",
              oldState: oldSkills,
              newState: skills,
            },
          ];
          CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
          Core.showMessage("Saved Successfully!");
        }
      );
      break;
    case "technologyDomain":
      oldSkills = candidate.technologyDomain;

      oldCandidateState =
        allCandidates.find((c) => c.id === candidate.id) || candidate;
      oldCandidateStateIndex = allCandidates.findIndex(
        (c) => c.id === candidate.id
      );
      withoutThisCandidate = allCandidates.filter((c) => c.id !== candidate.id);

      updateOldCandidateState = {
        ...oldCandidateState,
        technologyDomain: skills,
      };

      updatedCandidate = [...withoutThisCandidate];

      updatedCandidate.splice(
        oldCandidateStateIndex,
        0,
        updateOldCandidateState
      );

      allCandidates = [...updatedCandidate];

      !!cbUpdateCandidates && cbUpdateCandidates(allCandidates);
      Candidate.update(
        candidate.id,
        {
          technologyDomain: skills,
        },
        (response) => {
          const dirtyAttrs = [
            {
              key: type,
              label: "Technology Domain",
              oldState: oldSkills,
              newState: skills,
            },
          ];
          CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
          Core.showMessage("Saved Successfully!");
        }
      );

      break;
    case "positiveSignals":
      oldSkills = candidate.positiveSignals;

      oldCandidateState =
        allCandidates.find((c) => c.id === candidate.id) || candidate;
      oldCandidateStateIndex = allCandidates.findIndex(
        (c) => c.id === candidate.id
      );
      withoutThisCandidate = allCandidates.filter((c) => c.id !== candidate.id);

      updateOldCandidateState = {
        ...oldCandidateState,
        positiveSignals: skills,
      };

      updatedCandidate = [...withoutThisCandidate];

      updatedCandidate.splice(
        oldCandidateStateIndex,
        0,
        updateOldCandidateState
      );

      allCandidates = [...updatedCandidate];

      !!cbUpdateCandidates && cbUpdateCandidates(allCandidates);
      Candidate.update(
        candidate.id,
        {
          positiveSignals: skills,
        },
        (response) => {
          const dirtyAttrs = [
            {
              key: type,
              label: "Positive Signals",
              oldState: oldSkills,
              newState: skills,
            },
          ];
          CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
          Core.showMessage("Saved Successfully!");
        }
      );
      break;
    case "negativeSignals":
      oldSkills = candidate.negativeSignals;

      oldCandidateState =
        allCandidates.find((c) => c.id === candidate.id) || candidate;
      oldCandidateStateIndex = allCandidates.findIndex(
        (c) => c.id === candidate.id
      );
      withoutThisCandidate = allCandidates.filter((c) => c.id !== candidate.id);

      updateOldCandidateState = {
        ...oldCandidateState,
        negativeSignals: skills,
      };

      updatedCandidate = [...withoutThisCandidate];

      updatedCandidate.splice(
        oldCandidateStateIndex,
        0,
        updateOldCandidateState
      );

      allCandidates = [...updatedCandidate];

      !!cbUpdateCandidates && cbUpdateCandidates(allCandidates);
      Candidate.update(
        candidate.id,
        {
          negativeSignals: skills,
        },
        (response) => {
          const dirtyAttrs = [
            {
              key: type,
              label: "Negative Signals",
              oldState: oldSkills,
              newState: skills,
            },
          ];
          CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
          Core.showMessage("Saved Successfully!");
        }
      );
      break;
    default:
      break;
  }
};

const saveContentCandidate =
  (candidate, cbUpdateCandidates) => (key, content) => {
    const oldCandidateState = allCandidates.find((c) => c.id === candidate.id);
    const oldCandidateStateIndex = allCandidates.findIndex(
      (c) => c.id === candidate.id
    );
    const withoutThisCandidate = allCandidates.filter(
      (c) => c.id !== candidate.id
    );

    const updateOldCandidateState = {
      ...oldCandidateState,
      [key]: content,
    };

    let updatedCandidate = [...withoutThisCandidate];

    updatedCandidate.splice(oldCandidateStateIndex, 0, updateOldCandidateState);

    allCandidates = [...updatedCandidate];

    !!cbUpdateCandidates && cbUpdateCandidates(allCandidates);

    Candidate.update(
      candidate.id,
      {
        [key]: content,
      },
      (response) => {
        Core.showMessage("Saved Successfully!");
      }
    );
  };

const openMessage = (e, candidate) => {
  const emails = [];
  // let candidate = this.state.candidate
  candidate.email &&
    emails.push({
      name: candidate._name || "Candidate",
      email: candidate.email,
    });
  candidate.recruiter.email &&
    emails.push({
      name: candidate.recruiter._name || "Recruiter",
      email: candidate.recruiter.email,
    });
  Core.dialog.open({
    title: <>Message</>,
    message: (
      <EmailPreview
        ref={(self) => (EmailMessage = self)}
        emails={emails}
        to={!!emails[0] && [emails[0]]}
        subject=""
        body={[].filter((line) => !!line).join("<br/>")}
      />
    ),
    className: "p-0",
    actions: [
      <FlatButton
        label="Cancel"
        className="button-flat-darker"
        onClick={(ev) => {
          Core.dialog.close();
        }}
      />,
      <FlatButton
        label="Send"
        className="button-white-cyan"
        onClick={(ev) => {
          Core.dialog.close();
          Google.sendEmail(
            {
              ...EmailMessage.getParams(),
              source: "jobs/MatchStrategy/Strategy.js line 325",
            },
            (response) => Core.showMessage("Email sent"),
            (error) => Core.showFailure(error)
          );
        }}
      />,
    ],
  });
};

const segregateRolesChip = (candidate, job) => {
  let colorAlarm = "red";

  if (!!candidate && !!candidate._roles) {
    candidate._roles.split(",").forEach((roles) => {
      const rolesRegExp = new RegExp(roles.trim(), "gi").test(job._roles);
      if (!!rolesRegExp) colorAlarm = "";
    });

    return candidate._roles.split(",").map((roles) => {
      return staticRowDisplayChip(
        roles,
        job._roles,
        staticRowDisplayColor(roles, job, colorAlarm).getRolesColor()
      );
    });
  }
};

const segregateLocationChip = (candidate, job) => {
  let colorAlarm = "red";

  if (!!candidate && !!candidate._workLocationIds) {
    candidate._workLocationIds.split(",").forEach((loc) => {
      const locRegExp = new RegExp(loc.trim(), "gi").test(job._locations);
      if (!!locRegExp) colorAlarm = "";
    });

    return candidate._workLocationIds.split(",").map((loc) => {
      return staticRowDisplayChip(
        loc,
        job._locations,
        staticRowDisplayColor(loc, job, colorAlarm).getLocationColor()
      );
    });
  }
};

const makeChipsForCandidate = (job, candidate) => {

  job = job || getJobModel({ extended: true });
  candidate = candidate || getCandidateModel({ extended: true });

  return (
    <div>
      {!!job &&
        !!candidate &&
        !!job._visaTransfer &&
        !!candidate._visa &&
        staticRowDisplayChip(
          candidate._visa,
          job._visaTransfer,
          staticRowDisplayColor(candidate, job).getVisaColor()
        )}

      <MatchLocationChips
        {...{
          requester: MATCH_LOCATION_CHIPS__CD_REQUESTER,
          job,
          candidate
        }}
      />

      {!!job &&
        !!candidate &&
        !!job.minYearsOfExperience &&
        staticRowDisplayChip(
          candidate._yearsOfExperienceForCalc + " yrs",
          job.minYearsOfExperience,
          staticRowDisplayColor(candidate, job).getYearsXp()
        )}
      {segregateLocationChip(candidate, job)}
      {!!job &&
        !!candidate &&
        typeof candidate.minimumSalary !== "undefined" &&
        staticRowDisplayChip(
          "<=$" + candidate.minimumSalary,
          job.salaryMax,
          staticRowDisplayColor(candidate, job).getSalaryColor()
        )}
      {!!job &&
        !!candidate &&
        !!candidate._platformRating &&
        staticRowDisplayChip(candidate._platformRating, null, null)}
      <CompanyPreference
        candidateCompanyPreferenceMin={!!candidate && candidate.minCompanySize}
        candidateCompanyPreferenceMax={!!candidate && candidate.maxCompanySize}
        jobCompanyPreference={
          !!job && job.employer ? job.employer.employeeCount : null
        }
        source="candidate"
      />
      <WorkFromHome
        candidateWorkFromHome={!!candidate && candidate.workRemotly}
        jobWorkFromHome={!!job ? job.remote : null}
        source="candidate"
      />
      <Stage
        candidateStage={!!candidate && candidate.desiredStage}
        jobStage={!!job && job.employer ? job.employer.stage : null}
        source="candidate"
      />
      <LoctionDetails
        candidateText={!!candidate && candidate.locationDetails}
        jobText={
          !!job && !!job.remoteExtraInfo
            ? job.remoteExtraInfo
            : !!job && !!job.employer.remoteExtraInfo
              ? job.employer.remoteExtraInfo
              : null
        }
        source="candidate"
      />
    </div>
  );
};

const candidatePipe = (
  selectedCando,
  cbUpdateCandidates,
  moveToApprovedChips
) => {
  return (
    <CandidatePipe
      candidate={selectedCando}
      candidatePipeHeader={candidatePipeHeader}
      job={job}
      skillsHandler={skillsHandler(selectedCando, cbUpdateCandidates)}
      saveContentCandidate={saveContentCandidate(
        selectedCando,
        cbUpdateCandidates
      )}
      openMessage={openMessage}
      makeChipsForCandidatePipe={makeChipsForCandidate}
      source="jobMatch"
      moveToApprovedChips={moveToApprovedChips}
    />
  );
};

const candidatePipeHeader = (selectedCando) => {
  return (
    <CandidatePipeHeader
      candidate={selectedCando}
      openMessage={openMessage}
      job={job}
      makeChipsForCandidatePipe={makeChipsForCandidate}
    />
  );
};

const saveContentJob = (key, content) => {
  job = {
    ...job,
    [key]: content,
  };

  Job.update(
    job.id,
    {
      [key]: content,
    },
    () => {
      Core.showMessage("Saved Successfully!");
    }
  );
};

const staticRowDisplayChip = (jobAtr, candAtr, color) => {
  const singleAttrJob = !!jobAtr ? jobAtr.toString().split(",") : [];

  if (!jobAtr || !candAtr) {
    return singleAttrJob.map((attr, index) => {
      return (
        <Chip
          key={index}
          className="slim-chip ui-job-match-strategy-chip-a"
          label={attr}
          size="small"
          variant="outlined"
          style={{
            border: `1px solid gray`,
          }}
        />
      );
    });
  }

  return singleAttrJob.map((attr, index) => {
    return (
      <Chip
        key={index}
        className="slim-chip ui-job-match-strategy-chip-b"
        label={attr}
        size="small"
        variant="outlined"
        style={{
          border: `${color === 'red' ? 2 : 1}px solid ${color}`,
        }}
      />
    );
  });
};
const staticRowDisplayColor = (candidate, job, color) => {
  let obj = {};

  obj.getSalaryColor = ((candidate, job) => () => {
    if (!candidate.minimumSalary || !job.salaryMax) {
      return "";
    }

    let color;

    if (candidate.minimumSalary <= job.salaryMax) {
      color = "green";
    } else if (candidate.minimumSalary <= 1.15 * job.salaryMax) {
      color = "grey";
    } else if (candidate.minimumSalary <= 1.4 * job.salaryMax) {
      color = "grey";
    } else {
      color = "red";
    }
    // console.log("salary " + color + " cand sal "+candidate.minimumSalary+ " job sal " + job.salaryMax)
    return color;
  })(candidate, job);

  obj.getVisaColor = ((candidate, job) => () => {
    let menu = Candidate.menus.find((obj) => obj.key === "visa");
    let myMappings = menu.mappings[job._visaTransfer] || [];
    return myMappings.includes(candidate._visa) ? "green" : "red";
  })(candidate, job);

  obj.getRolesColor = ((candidateRole, job) => () => {
    const rolesRegExp = new RegExp(candidateRole.trim(), "gi").test(job._roles);
    return !!rolesRegExp ? "green" : color;
  })(candidate, job);

  obj.getLocationColor = ((candidateLocationLabel, job) => () => {
    const locRegExp = new RegExp(candidateLocationLabel.trim(), "gi").test(
      job._locations
    );
    return !!locRegExp ? "green" : color;
  })(candidate, job);

  obj.getYearsXp = ((candidate, job) => () => {
    return YearsOfExperienceColor(job, candidate);
  })(candidate, job);
  return obj;
};

const stringToChips = (words) => {
  if (typeof words === "number") {
    words = words.toString();
  }
  if (typeof words === "string") {
    if (words === "") {
      return null;
    } else if (words.includes(",")) {
      words = words.split(/[,]/);
    } else {
      words = [words];
    }
  }

  return (
    <div>
      {words.map((word, index) => {
        return (
          <Fragment key={index}>
            <Chip
              key={index}
              className="chip slim-chip"
              variant="outlined"
              size="small"
              label={word}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

const makeChipsForJob = (job, selectedCandidate) => () => {

  job = job || getJobModel({ extended: true });
  selectedCandidate = selectedCandidate || getCandidateModel({ extended: true });

  return (
    <div>

      <Chip
        className="slim-chip"
        variant="outlined"
        size="small"
        label={!!job && !!job._visaTransfer && job._visaTransfer}
      />

      {!!job && (
        !!job._roles &&
        job._roles &&
        job._roles
          .split(",")
          .map((role, index) => (
            <Chip
              key={index}
              className="slim-chip"
              variant="outlined"
              size="small"
              label={role}
            />
          ))
      )}

      <Chip
        className="slim-chip"
        variant="outlined"
        size="small"
        label={`${!!job &&
          typeof job.minYearsOfExperience !== "undefined" &&
          job.minYearsOfExperience
          } yrs`}
      />

      <MatchLocationChips
        {...{
          requester: MATCH_LOCATION_CHIPS__JD_REQUESTER,
          job,
          candidate: selectedCandidate
        }}
      />

      <Chip
        className="slim-chip"
        variant="outlined"
        size="small"
        label={`>$${!!job && typeof job.salaryMax !== "undefined" && job.salaryMax
          }`}
      />
      <Chip
        className="slim-chip"
        variant="outlined"
        size="small"
        label={!!job && !!job._employeeRating && job._employeeRating}
      />
      <CompanyPreference
        candidateCompanyPreferenceMin={
          !!selectedCandidate ? selectedCandidate.minCompanySize : null
        }
        candidateCompanyPreferenceMax={
          !!selectedCandidate ? selectedCandidate.maxCompanySize : null
        }
        jobCompanyPreference={
          !!job && job.employer ? job.employer.employeeCount : null
        }
        source="job"
      />
      <WorkFromHome
        candidateWorkFromHome={
          !!selectedCandidate ? selectedCandidate.workRemotly : null
        }
        jobWorkFromHome={!!job ? job.remote : null}
        source="job"
      />
      <Stage
        candidateStage={!!selectedCandidate && selectedCandidate.desiredStage}
        jobStage={!!job && job.employer ? job.employer.stage : null}
        source="job"
      />
      <LoctionDetails
        candidateText={!!selectedCandidate && selectedCandidate.locationDetails}
        jobText={
          !!job && !!job.remoteExtraInfo
            ? job.remoteExtraInfo
            : !!job && !!job.employer.remoteExtraInfo
              ? job.employer.remoteExtraInfo
              : null
        }
        source="job"
      />
    </div>
  );
};

const jobPipe = (job, selectedJob) => {
  return (
    <JobPipe
      candidate={selectedJob}
      selectedJob={job}
      saveContentJob={saveContentJob}
      staticRowDisplayChip={staticRowDisplayChip}
      staticRowDisplayColor={staticRowDisplayColor}
      stringToChips={stringToChips}
      makeChipsForJobPipe={makeChipsForJob(job, selectedJob)}
      source="jobMatch"
      jobPipeHeader={jobPipeHeader}
    />
  );
};

const jobPipeHeader = (selectedJob, candidate) => {
  return (
    <JobPipeHeader
      candidate={selectedJob}
      selectedJob={job}
      makeChipsForJobPipe={makeChipsForJob(job, candidate)}
    />
  );
};

const jobContainConstraints = (job, candidate, constraint) => {
  //for permitted/declined/pitched
  if (
    Array.isArray(candidate[constraint]) &&
    candidate[constraint].includes(job.id)
  ) {
    return true;
  }
  return false;
};

const isJobBlackListed = (job, candidate) => {
  if (!!candidate.recruiter) {
    const { id } = candidate.recruiter;
    const { employer } = job;

    if (
      (Array.isArray(job.jobBlackList) &&
        job.jobBlackList.map((el) => el.id).includes(id)) ||
      (Array.isArray(employer.employerBlackList) &&
        employer.employerBlackList.map((el) => el.id).includes(id))
    ) {
      return true;
    }
    return false;
  }
  return false;
};

const isCandidateHired = (candidate) => {
  if (!!candidate.engagements.length) {
    let foo = candidate.engagements.filter((eng) => eng.stage === "Guarantee");
    if (foo.length !== 0) {
      return true;
    }
  }
  return false;
};

const getJobLabels = (job, candidate) => {
  let jobTypes = [];

  if (jobContainConstraints(job, candidate, "jobsPermitted"))
    jobTypes.push("Permitted");
  if (jobContainConstraints(job, candidate, "jobsPitched"))
    jobTypes.push("Awaiting");
  if (jobContainConstraints(job, candidate, "jobsDeclined"))
    jobTypes.push("Declined");
  if (isJobBlackListed(job, candidate)) {
    jobTypes.push("BlackListed");
  } else if (isCandidateHired(candidate)) {
    jobTypes.push("Hired");
  } else if (candidate.recruiter.role === "LimitedRecruiter") {
    jobTypes.push("Limited");
  }
  return jobTypes;
};

const jobListCard = ({
  job,
  type,
  candidate,
  isSelected,
  annotatorMode,
  MLScoreMode,
  handlerSelectJob,
  handlerOnSelectBox,
  multiSelectedJobs,
  handlerMatchJob,
  createDisagreement,
  createSingleEngagement,
  createSingleEngagementWithDis,
  pushEntityToCandidate,
  isJobEngageable,
  index,
  style,
  measure,
  handlerMIScoreObjects,
  label,
  resizeIndex,
  resizeAllIndices,
  processMLScoreSingle,
}) => {
  return (
    <SingleCandidateCard
      job={job}
      key={`cando-match-card-${job.id}-${type}`}
      candidate={candidate}
      selected={isSelected}
      annotatorMode={annotatorMode}
      handlerSelectJob={handlerSelectJob}
      handlerOnSelectBox={handlerOnSelectBox}
      handlerMatchJob={handlerMatchJob}
      createDisagreement={createDisagreement}
      createSingleEngagement={createSingleEngagement}
      createSingleEngagementWithDis={createSingleEngagementWithDis}
      pushEntityToCandidate={pushEntityToCandidate}
      isJobEngageable={isJobEngageable}
      getJobLabels={getJobLabels}
      getOwnershipChip={getOwnershipChip}
      notFoundInPermittedJobWithOwnership={notFoundInPermittedJobWithOwnership}
      multiSelectedJobs={multiSelectedJobs}
      staticRowDisplayChip={staticRowDisplayChip}
      staticRowDisplayColor={staticRowDisplayColor}
      index={index}
      style={style}
      measure={measure}
      segregateRolesChip={segregateRolesChip}
      segregateLocationChip={segregateLocationChip}
      handlerMIScoreObjects={handlerMIScoreObjects}
      MLScoreMode={MLScoreMode}
      label={label}
      resizeIndex={resizeIndex}
      resizeAllIndices={resizeAllIndices}
      strategyGetConstraints={jobContainConstraints}
      processMLScoreSingle={processMLScoreSingle}
    />
  );
};

const engagementListCard = ({
  engagement,
  type,
  candidate,
  isSelected,
  annotatorMode,
  MLScoreMode,
  handlerSelectJob,
  handlerOnSelectBox,
  multiSelectedJobs,
  handlerMatchJob,
  createDisagreement,
  createSingleEngagement,
  createSingleEngagementWithDis,
  pushEntityToCandidate,
  isJobEngageable,
  afterUpdateEngagement,
  index,
  style,
  handlerMIScoreObjects,
  updateEngagement,
  measure,
  label,
  resizeIndex,
  resizeAllIndices,
  processMLScoreSingle,
}) => {
  return (
    <SingleCandidateCard
      key={`cando-match-card-${engagement.id}-${type}`}
      job={engagement.candidate}
      engagement={engagement}
      candidate={candidate}
      selected={isSelected}
      annotatorMode={annotatorMode}
      handlerSelectJob={handlerSelectJob}
      handlerMatchJob={handlerMatchJob}
      handlerOnSelectBox={handlerOnSelectBox}
      createDisagreement={createDisagreement}
      createSingleEngagement={createSingleEngagement}
      createSingleEngagementWithDis={createSingleEngagementWithDis}
      pushEntityToCandidate={pushEntityToCandidate}
      isJobEngageable={isJobEngageable}
      getJobLabels={getJobLabels}
      getOwnershipChip={getOwnershipChip}
      notFoundInPermittedJobWithOwnership={notFoundInPermittedJobWithOwnership}
      afterMainUpdateEngagement={afterUpdateEngagement}
      multiSelectedJobs={multiSelectedJobs}
      staticRowDisplayChip={staticRowDisplayChip}
      staticRowDisplayColor={staticRowDisplayColor}
      index={index}
      style={style}
      measure={measure}
      segregateRolesChip={segregateRolesChip}
      segregateLocationChip={segregateLocationChip}
      handlerMIScoreObjects={handlerMIScoreObjects}
      MLScoreMode={MLScoreMode}
      updateEngagement={updateEngagement}
      label={label}
      resizeIndex={resizeIndex}
      resizeAllIndices={resizeAllIndices}
      strategyGetConstraints={jobContainConstraints}
      processMLScoreSingle={processMLScoreSingle}
    />
  );
};

const getChips = (job, menus, more, keywords) => {
  const chips = [];

  menus.forEach((menu) => {
    menu.items &&
      Object.keys(menu.items).forEach(
        (name) => menu.items[name] === true && chips.push({ name, menu: true })
      );
  });

  more.forEach((menu) => {
    menu.items &&
      Object.keys(menu.items).forEach((name) => {
        if (name === "Active") {
          const activeLabel = `${menu.label}:${name}`;
          menu.items[name] === true &&
            chips.push({ name: activeLabel, more: true });
        } else {
          menu.items[name] === true && chips.push({ name, more: true });
        }
      });
  });

  !!keywords.length && keywords.forEach((item) => chips.push(item));

  if (!!job.tempMinimumSalary && job.tempMinimumSalary !== 0) {
    let prefix = "";
    prefix = "Accepts Salary <=";

    chips.push({
      name: `${prefix} $${formatMoney(job.tempMinimumSalary, 0)}`,
      minimumSalary: true,
    });
  }

  if (!!job.maximumSalary && job.maximumSalary !== 250000) {
    chips.push({
      name: `Max Salary: $${formatMoney(job.maximumSalary, 0)}`,
      maximumSalary: true,
    });
  }

  if (!!job.tempMinimumExperience && job.tempMinimumExperience > 0) {
    let prefix = "";
    prefix = `has >= ${job.tempMinimumExperience}y exp`;

    chips.push({
      name: `${prefix}`,
      minimumXp: true,
    });
  }

  return chips;
};

const filterCandidates = (job, candidates, keywords, menus, more) => {

  MatchLib.unitTestingPresetLocationMenusAll({ job });

  let filtered = candidates;
  if (!filtered) {
    return [];
  }

  /** FILTER BY MINIMUM AND MAXIMUM SALARY */
  if (job && job.tempMinimumSalary !== 0) {
    filtered = filtered.filter((item) => {
      const candidate = item;
      let salaryGreaterThan = job.tempMinimumSalary;

      if (!!job.applyLooseMatch) {
        //increase salary requirement of given job for highly ranked candidates
        if (+candidate.platformRating === 5) {
          // A+
          salaryGreaterThan = 1.5 * salaryGreaterThan;
        } else if (+candidate.platformRating === 1) {
          // A-Top
          salaryGreaterThan = 1.3 * salaryGreaterThan;
        } else if (+candidate.platformRating === 2) {
          // B-Strong
          salaryGreaterThan = 1.15 * salaryGreaterThan;
        }
      }

      return (
        Number(String(item.minimumSalary || 0).replace(/(\..*)|\D/g, "")) <=
        Number(salaryGreaterThan)
      );
    });
  }

  if (job && job.tempMinimumExperience !== 0) {
    filtered = filtered.filter((item) => {
      const candidate = item;
      let experienceLessThan = job.tempMinimumExperience;

      if (!!job.applyLooseMatch) {
        //reduce years of experience requirement of given job for highly ranked candidates
        if (+candidate.platformRating === 5) {
          // A+
          experienceLessThan = 0.5 * experienceLessThan;
        } else if (+candidate.platformRating === 1) {
          // A-Top
          experienceLessThan = 0.7 * experienceLessThan;
        } else if (+candidate.platformRating === 2) {
          // B-Strong
          experienceLessThan = 0.85 * experienceLessThan;
        }
      }

      return (
        Number(String(item.yearsOfExperience || 0).replace(/(\..*)|\D/g, "")) >= Number(experienceLessThan)
      );
    });
  }

  /** FILTER BY KEYWORDS */
  if (!!keywords.length) {
    filtered = filtered.filter((item) => {
      return keywords.every((objKeyword) => {
        return item.___keys___.some((label) =>
          new RegExp(
            String(objKeyword.name)
              .replace("+", "\\+")
              .replace(".", "\\.")
              .replace("#", "\\#")
              .replace("(", "\\(")
              .replace(")", "\\)"),
            "i"
          ).test(label)
        );
      });
    });
  }

  filtered = FilterLib.filterCandidatesByMatchExclusion({
    candidates: filtered
  });

  /* epic-3038(new locations)-story-3578-M2 | 2021-07-29 Thu µ */
  filtered = FilterLib.filterItemsByCheckedOptionsInFilterBar({
    items: filtered,
    menus,
    more,
  });

  /** @todo to cleanup | 2021-07-29 Thu µ */
  /* epic-3038(new locations)-story-3578-M2 | 2021-07-29 Thu µ */
  /** FILTER BY MENU KEYS * /
  const checks = {}; // to store the checked menu items for filter
  let filter = false; // flag for the filter by tag

  /* epic-3038(new locations)-story-3492-m11 * /
  let isFilteringLocations = false;
  const checkedLocationOptionsMenu = {};

  /* step 1: get selected tags * /
  [...menus, ...more].forEach((menu) => {
    const labels = menu.items
      ? Object.keys(menu.items).filter((
        label) => menu.items[label] === true
      )
      : [];

    if (!!labels.length) {
      /* set filter if there is a selected tag in menu * /

      if (menu["mappings"]) {
        checks[menu.key] = labels
          .map((label) => {
            return menu["mappings"][label];
          })
          .flat();
      } else {
        checks[menu.key] = labels;
      }

      filter = true;
    }
  });

  if (filter) {
    filtered = filtered.filter((candidate) => {
      console.debug('=================================');
      console.debug('Candidate', candidate);
      console.debug('CandidateKeys', candidate.___keys___);
      // for all menus (AND)
      return Object.keys(checks).every((menuKey) => {
        // find some menu item (OR)
        if (menuKey === "introduced" || menuKey === "state") {
          return checks[menuKey].some(
            (value) => !!~candidate.___keys___.indexOf(`${menuKey}${value}`)
          );
        } else {
          console.debug('MenuChecks', menuKey, checks[menuKey]);
          return checks[menuKey].some(
            (value) => {
              console.debug('Matched', candidate.___keys___.includes(value), value);

              if (menuKey === "officeLocations") {
                return candidate.officeLocations.some(candidateWorkLocationId =>
                  job.officeLocations.some(jobOfficeLocationId =>
                    LocationLib.evalLineage({
                      memberA: jobOfficeLocationId,
                      memberB: candidateWorkLocationId
                    })
                  )
                );
              }

              return !!~candidate.___keys___.indexOf(value)
            }
          );
        }
      });
    });
  }
  /** */

  console.log("my result ::::: ", filtered.length);
  return [...filtered];
};

const candidateOfferSalary = (
  closeEvent,
  applyEvent,
  onChangeEvent,
  salary
) => {
  return (
    <Dialog
      title={"Filter by"}
      onRequestClose={(ev) => console.log("apply nothing")}
      actions={
        <Fragment>
          <FlatButton
            label="Cancel"
            primary={true}
            onClick={(ev) => closeEvent()}
          />
          <FlatButton
            label="Apply"
            primary={true}
            onClick={(ev) => {
              applyEvent(salary);
            }}
          />
        </Fragment>
      }
      modal={false}
      open={true}
      autoScrollBodyContent={true}
      contentStyle={{ width: 550 }}
      bodyStyle={{ padding: "0px 20px 20px" }}
    >
      <label>
        Can Work for Salary less than: &nbsp; ${formatMoney(salary, 0)}
      </label>
      <Slider
        name="minimumSalary"
        min={0}
        max={250000}
        step={10000}
        value={salary}
        onChange={(event, salary) => {
          !!onChangeEvent && onChangeEvent(salary);
        }}
      />
    </Dialog>
  );
};

const candidateOfferExperience = (
  closeEvent,
  applyEvent,
  onChangeEvent,
  experience
) => {
  return (
    <Dialog
      title={"Filter by"}
      onRequestClose={(ev) => console.log("apply nothing")}
      actions={
        <Fragment>
          <FlatButton
            label="Cancel"
            primary={true}
            onClick={(ev) => closeEvent()}
          />
          <FlatButton
            label="Apply"
            primary={true}
            onClick={(ev) => {
              applyEvent(experience);
            }}
          />
        </Fragment>
      }
      modal={false}
      open={true}
      autoScrollBodyContent={true}
      contentStyle={{ width: 550 }}
      bodyStyle={{ padding: "0px 20px 20px" }}
    >
      <label>
        Candidate has xp equal or greater than:&nbsp;{" "}
        {formatMoney(experience, 0)} years
      </label>
      <Slider
        name="minimumExperience"
        min={0}
        max={40}
        step={1}
        value={experience}
        onChange={(event, experience) => {
          !!onChangeEvent && onChangeEvent(experience);
        }}
      />
    </Dialog>
  );
};

const jobPutDownCandidates = (id, putDownJobs) => {
  Job.update(id, {
    putDownJobs,
  });
};

const disEngagementModel = ({
  matchStrength,
  candidate,
  employer,
  job,
  engagement,
  shouldTag = "",
  shouldNotTag = "",
  whyNoPrivateNote = "",
  whyNeedToReadCV = "",
  whyNoCategories = [],
  whyNoDetails = "",
  whyNoFieldsValues = [],
  reviewed,
}) => {
  return {
    source: "jobMatch",
    annotator: Core.getSessionEmail(),
    matchDecision: matchStrength,
    candidateName: job._name, ///
    employerName: Object(employer).name,
    jobName: candidate.jobTitle,
    engagementStage: Object(engagement).stage,
    engagementStatus: Object(engagement).status,
    matchingUrl: `https://staging.go10x10.com/#/job/matchNew/${candidate.id}?selected=${job.id}`,
    engagementUrl: !!engagement
      ? `https://staging.go10x10.com/#/engagement/view/${engagement.id}`
      : "",
    candidateUrl: `https://staging.go10x10.com/#/job/edit/${candidate.id}`,
    jobUrl: `https://staging.go10x10.com/#/candidate/edit/${job.id}`,
    engagementId: Object(engagement).id,
    jobId: Object(candidate).id,
    candidateId: Object(job).id,
    jobRoles: candidate._roles,
    reviewed,
    shouldTag,
    whyNoDetails,
    whyNoFieldsValues,
    whyNoCategories,
    shouldNotTag,
    whyNoPrivateNote,
    whyNeedToReadCV,
  };
};

const makeEngagement = (candidate, job) => {
  return {
    candidate: job,
    job: candidate,
  };
};

export const StrategyMain = function (id, param) {
  return {
    subjectFunc: subjectFunc(id),
    objectFunc,
    objectListFunc: objectListFunc(param),
    sourcePipe: jobPipe,
    sourcePipeHeader: jobPipeHeader,
    objectPipe: candidatePipe,
    objectPipeHeader: candidatePipeHeader,
    objectListCard: jobListCard,
    objectListCardEng: engagementListCard,
    filterControl,
    filterObjects: filterCandidates,
    salaryPopup: candidateOfferSalary,
    experiencePopup: candidateOfferExperience,
    getChips: getChips,
    putDownCallback: jobPutDownCandidates,
    disEngagementModel,
    makeEngagement,
    getObjectLabels: getJobLabels,
    getObjectConstraints: jobContainConstraints,
    getObjectBlackListed: isJobBlackListed,
    sourceKey: "candidate",
  };
};
