import moment from 'moment';
import Candidate from '../../lib/Candidate';
import Core from '../../lib/Core';
import { STATE_ACTIVE } from '../../lib/Definition';
import { mapCandidates } from '../../lib/models/candidate';
import CandidateExpandedView from '../Candidates/Card/ExpandedView';
import { CandidateNameCell } from './CellCandidateNameColumn';
import { FilterDateRangeColumn } from './FilterDateRangeColumn';
import { FilterLocationsColumn } from './FilterLocationsColumn';
import { FilterNumber } from './FilterNumber';
import { FilterStringColumn } from './FilterStringColumn';
import { moreMenuOptionsCandidates } from './moreMenuOptionsCandidates';
import {
  CellActionsColumn,
  CellTagColumn,
  FilterActionsColumn,
  FilterTagColumn
} from './ReactTable';
import {
  candidateLocationsWarningRender,
  inOfficeRemoteFlagsWarningRender,
  officeLocationsWarningRender
} from './renderLocationColumnWarning.lib';
import {
  REACT_TABLE__COLUMN_ACTION,
  REACT_TABLE__COLUMN_LG, REACT_TABLE__COLUMN_MD, REACT_TABLE__COLUMN_SM,
  REACT_TABLE__COLUMN_XL,
  REACT_TABLE__COLUMN_XS,
  REACT_TABLE__COLUMN_XXL,
  REACT_TABLE__COLUMN_XXXL
} from './useEnhancedReactTable.hook';

export const configCandidates = {
  Entity: Candidate,
  entityMapper: mapCandidates,
  searchPath: 'Candidates/_search',
  autocompletePath: 'Candidates/_autocomplete',
  facetsPath: 'Candidates/_facets',
  starredAccessor: 'candidateStarreds',
  searchPlaceholder:
    'Search by candidate first or last name, recruiter first or last name, agency name',
  addNew: {
    label: '+ New Candidate',
    to: '/candidate/create',
  },
  defaultQuery: {
    filters: [
      {
        ...(() => {
          let limitDate = moment().subtract(180, 'days').toISOString();
          if (Core.isAdminOrCoordinator()) {
            return {
              state: STATE_ACTIVE,
              introduced: { $ne: null },
              updatedAt: { $gt: limitDate },
            };
          }
          return {
            state: STATE_ACTIVE,
            accountId: { $id: Core.getUserId() },
            updatedAt: { $gt: limitDate },
          };
        })(),
      },
    ],
    // "sort": { "firstName": 1, "lastName": 1 },
    skip: 0,
    limit: 10,
    query: [],
    associations: ['account', 'candidateStarreds', 'engagements.job.employer'],
    resume: true,
  },
  hiddenColumns: [
    'selection',
    'firstName',
    'lastName',
    'nickName',
    'phone',
    'email',
    'negativeSignals',
    'desiredStage',
    'updatedAt',
    'desiredEmploymentTypes',
    'account.lastName',
  ],
  columns: [
    {
      accessor: 'firstName',
      Header: 'Name',
      Filter: FilterStringColumn,
      Cell: CandidateNameCell,
      width: REACT_TABLE__COLUMN_XL,
      _headerClassName: 'sticky-start bg-main br-1',
      _cellClassName: 'sticky-start bg-white br-1',
    },
    {
      accessor: 'roles',
      Header: 'Roles',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      _accessor: '_roles',
      _categoryKey: 'roles',
    },
    {
      accessor: 'platformRating',
      Header: 'Platform Rating',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_XS,
      _acl: [Core.isAdminOrCoordinator()],
      _accessor: '_platformRating',
      _categoryKey: 'platformRating',
    },
    {
      accessor: 'visa',
      Header: 'Visa',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_SM,
      _accessor: '_visa',
      _categoryKey: 'visaCandidate',
    },
    {
      accessor: 'yearsOfExperience',
      Header: 'Years of Experience',
      Filter: FilterNumber,
      width: REACT_TABLE__COLUMN_SM,
      _accessor: '_years',
      _maximumValue: 40,
    },
    {
      accessor: 'technicalSkills',
      Header: 'Tech Skills',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      _accessor: '_technicalSkills',
      _categoryKey: 'technicalSkills',
    },
    {
      accessor: 'inOfficeRemoteFlags',
      Header: 'On-site/Remote',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_LG,
      _accessor: '__inOfficeRemoteFlags',
      _categoryKey: 'inOfficeRemote',
      _renderSubFilter: inOfficeRemoteFlagsWarningRender,
    },
    {
      accessor: 'candidateLocations',
      Header: 'WFH Locations',
      Filter: FilterLocationsColumn,
      Cell: CellTagColumn,
      disableSortBy: true,
      width: REACT_TABLE__COLUMN_XL,
      _accessor: '__candidateLocations',
      _categoryKey: 'location',
      _renderSubFilter: candidateLocationsWarningRender,
      _facetTagRequired: true,
    },
    {
      accessor: 'officeLocations',
      Header: 'Office Locations',
      Filter: FilterLocationsColumn,
      Cell: CellTagColumn,
      disableSortBy: true,
      width: REACT_TABLE__COLUMN_XL,
      _accessor: '__officeLocations',
      _categoryKey: 'location',
      _renderSubFilter: officeLocationsWarningRender,
      _facetTagRequired: true,
    },
    {
      accessor: 'minimumSalary',
      Header: 'Min Salary',
      Filter: FilterNumber,
      width: REACT_TABLE__COLUMN_SM,
      _accessor: '_minimumSalary',
      _maximumValue: 250000,
    },
    {
      _acl: [Core.isAdminOrCoordinator()],
      Header: 'Positive Signals',
      accessor: 'positiveSignals',
      _accessor: '_positiveSignals',
      _categoryKey: 'positiveSignals',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
    },
    {
      accessor: 'diversity',
      Header: 'Diversity',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_SM,
      _acl: [Core.isAdminOrCoordinator()],
      _accessor: '__diversity',
      _categoryKey: 'diversity',
    },
    {
      accessor: 'account.firstName',
      Header: "Recruiter's Firstname",
      Filter: FilterStringColumn,
      width: REACT_TABLE__COLUMN_SM,
    },
    {
      accessor: 'account.companyName',
      Header: 'Company',
      Filter: FilterStringColumn,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      _acl: [Core.isAdminOrCoordinator()],
      accessor: 'relationship',
      Header: 'Relation Ship',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      _accessor: '_relationship',
      _categoryKey: 'relationShip',
    },
    {
      accessor: 'matchExclusions.reasons',
      Header: 'Match Exclusions',
      Cell: CellTagColumn,
      _acl: [Core.isAdminOrCoordinator()],
      _accessor: '_matchExclusionsReasons',
      _categoryKey: 'matchExclusionReason',
      _nestedObject: true,
    },
    {
      accessor: 'matchExclusions.excludeUntil',
      Header: 'Match Exclude Until',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      _acl: [Core.isAdminOrCoordinator()],
      _accessor: '_matchExclusionsUntil',
    },
    {
      accessor: 'introduced',
      Header: 'Introduced',
      Filter: FilterDateRangeColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_XXL,
      _accessor: '_introduced',
    },
    {
      accessor: 'state',
      Header: 'State',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      width: 120,
      _acl: [Core.isAdminOrCoordinator()],
      _accessor: '_state',
      _categoryKey: 'state',
    },
    {
      accessor: 'updatedAt',
      Header: 'Updated At',
      Filter: FilterDateRangeColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_XXL,
      _accessor: '_updatedAt',
    },
    /** * /
    {
      accessor: 'firstName',
      Header: 'First Name',
      width: REACT_TABLE__COLUMN_SM,
    },
    /** */
    {
      accessor: 'lastName',
      Header: 'Last Name',
      Filter: FilterStringColumn,
      width: REACT_TABLE__COLUMN_SM,
    },
    {
      accessor: 'nickName',
      Header: 'Nickname',
      width: REACT_TABLE__COLUMN_SM,
    },
    {
      accessor: 'phone',
      Header: 'Phone',
      width: REACT_TABLE__COLUMN_SM,
    },
    {
      accessor: 'email',
      Header: 'Email',
      width: REACT_TABLE__COLUMN_XXXL,
    },
    {
      _acl: [Core.isAdminOrCoordinator()],
      Header: 'Negative Signals',
      accessor: 'negativeSignals',
      _accessor: '_negativeSignals',
      _categoryKey: 'negativeSignals',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
    },
    {
      accessor: 'desiredStage',
      Header: 'Stage',
      _accessor: '_desiredStage',
      _categoryKey: 'stage',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
    },
    {
      accessor: 'desiredEmploymentTypes',
      Header: 'Desired employment type',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_SM,
      _accessor: '_desiredEmploymentTypes',
      _categoryKey: 'desiredEmploymentType',
    },
    {
      accessor: 'account.lastName',
      Header: "Recruiter's Lastname",
      Filter: FilterStringColumn,
      width: REACT_TABLE__COLUMN_SM,
    },
    {
      id: 'actions',
      Header: 'Actions',
      Filter: FilterActionsColumn,
      Cell: CellActionsColumn,
      defaultCanFilter: true,
      width: REACT_TABLE__COLUMN_ACTION,
      _starredAccessor: 'candidateStarreds.starred',
      _associationAccessor: !Core.isLimited()
        ? 'engagements.job.employer'
        : null,
      _headerClassName: 'sticky-end bg-main bl-1',
      _cellClassName: 'sticky-end bg-white bl-1 d-flex flex-align-right-center',
    },
  ],
  renderCellClassName: ({ model: candidate = {}, className = '' }) =>
    `${className} ${Core.isAdminOrCoordinator() && candidate.isDuplicate
      ? candidate.duplicatedLevel === 'primary'
        ? ' bgcolorredYellow'
        : ' bgcolorred'
      : ''
    }`,
  moreMenuOptions: moreMenuOptionsCandidates,
  ExpandedView: CandidateExpandedView,
};
