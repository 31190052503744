import React, {Component, Fragment} from "react";

import ResumeMatch from "../../../Shared/ResumeMatch";

class TextCVContentDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textCV: ""
        }
    }

    renderTextCV = (text) => {
        this.setState({
            textCV: text
        });
    }

    render() {
        let {selectedJob, candidate} = this.props;

        return (
        <Fragment>
            {!!selectedJob && !!candidate &&
            <Fragment>
                <div
                    style={{
                        width: "auto",
                        padding: "5px 10px 5px 10px",
                        fontSize: "13px"
                    }} 
                    dangerouslySetInnerHTML={{__html: this.state.textCV}} 
                />
                <ResumeMatch
                    showOnDrawer={true}
                    renderTextCV={this.renderTextCV}
                    candidateResume={candidate.resumeTxtUrl}
                    jobSkills={selectedJob._technicalSkills}
                    employerSkills={selectedJob.employer._technicalSkills}
                    element={(cb)=>{
                        return (null)
                    }}
                />
            </Fragment>
            }
        </Fragment>
        )
    }
}
export default TextCVContentDrawer;