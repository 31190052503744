/** ============================================ µ
 * @description StageColumn [JS]
 *              UI Component
 * @path        /engagements
 * @parent      EngagementCard
 * @updatedAt   2021-08-13 Fri
 * ============================================ */

/* IMPORTS ==================================== */

import {
  IconButton,
  MenuItem,
  TableRowColumn
} from "material-ui";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Core from "../../../lib/Core";
import { hoverDisplay } from "../../../lib/models/engagement";
import {
  openRecruiterRejectionEmailPreview, openRecruiterStageTransitionEmailPreview
} from "../../../lib/services/Email/EmailRecruiter.lib";
import Streak, {
  STATUS_W_CANDIDATE_AVAILABILITY,
  STATUS_W_CANDIDATE_DETAILS,
  STATUS_W_CANDIDATE_HOMEWORK,
  STATUS_W_CANDO_EMP_AVAILABILITY,
  STATUS_W_EMPLOYER_AVAILABILITY,
  STATUS_W_EMPLOYER_DETAILS,
  STATUS_W_RECRUITER_DETAILS
} from "../../../lib/Streak";
import DropDown from "../../Forms/DropDown";
import CustomPicker from "./CustomPicker";

/* METHODS ==================================== */

class StageColumn extends Component {
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      emailRejectionHTML: "",
      includeRejectionReason: false,
    };
  }
  render() {
    const EngagementCardController = this.props.parent;
    const engagement = EngagementCardController.state;
    const {
      ref, // identifier of the column.
      label, // initial label to show.
      stage, // stage which it belongs.
      next, // next stage on click next button.
      next2, // next stage on click next button (exception for hire).
      next3, // next stage on click next button (exception for guarantee).
      value, // default Engagement date value to show.
      edit, // Engagement field to save updates.
      streak, // Streak field to save updates.
      colSpan, // Column Span.
      width,
    } = this.props.args;
    const dropdowns = this.parent.dropdowns;
    const pickers = this.parent.pickers;
    const updateField = this.parent.updateField;
    const engStage = engagement.stage;
    const engStatus = engagement.status;
    const _selected = stage.test(engStage);
    const _value = engagement[value];
    const _label = !/offer/i.test(ref)
      ? label
      : /hire/i.test(engStage)
        ? next
        : /guarantee/i.test(engStage)
          ? next2
          : label;
    const _field = edit || value;
    const _next = /hire/i.test(engStage)
      ? next2
      : /guarantee/i.test(engStage)
        ? next3
        : next;

    const picker = ({ value, field, streakField, none, validStage, label }) => (
      <CustomPicker
        parent={this}
        args={{ value, field, streakField, none, validStage, label }}
      />
    );
    let _statusArr =
      Streak.getPipeline().stageStatus[engStage] ||
      Streak.getDropdownStrings("Status");
    if (/closed/i.test(engagement.state) && /guarantee/i.test(engStage)) {
      _statusArr = [];
    }
    const nextStage = (ev) => {
      const nextStatusArr =
        Streak.getPipeline().stageStatus[_next] ||
        Streak.getDropdownStrings("Status");
      const update = { stage: _next, status: nextStatusArr[0] };
      const newDate = moment().tz("America/Los_Angeles");
      /** * /
        .startOf("day")
        .add(5, "h");
        /** */
      if (/hire/i.test(_next)) {
        update.offered = newDate.toISOString();
        update.status = "W - Start Date";
      } else if (/guarantee/i.test(_next)) {
        update.status = "W - Payment";
        update.hired = newDate.toISOString();
        update.startDate = newDate.toISOString();
      } else if (/end/i.test(_next)) {
        delete update.stage;
        update.state = "Closed";
        update.status = "E - Success";
        update.closed = newDate.toISOString();
      } else {
        update[_field] = newDate.toISOString();
      }
      if (update.state === "Closed") {
        update.closed = newDate.toISOString();
      }
      updateField(update, null, (update) => {
        if (!/hire|guarantee|end/i.test(_next)) {
          setTimeout((st) => {
            const dropdown = dropdowns[next];
            console.debug();
            if (/screen/i.test(_next)) {
              openRecruiterStageTransitionEmailPreview({
                engagement,
                eventType: "screen",
                previousStage: engStage,
                dropdown,
              });
            } else if (/onsite/i.test(_next)) {
              openRecruiterStageTransitionEmailPreview({
                engagement,
                eventType: "onsite",
                previousStage: engStage,
                dropdown,
              });
            } else if (/offer/i.test(_next)) {
              openRecruiterStageTransitionEmailPreview({
                engagement,
                eventType: "offer",
                previousStage: engStage,
                dropdown,
              });
            } else {
              dropdown && dropdown.open();
            }
          });
        }
      });
    };
    const onChangeStatus = (status) => {
      let update = { status };
      if (/e - /i.test(status)) {
        /** END STATUS */
        update[_field] = new Date().toISOString();
        Core.log("END STATUS", { update, _field });
      }
      updateField(
        update,
        /w - event|w - screen|w - onsite/i.test(status),
        (update) => {
          if (/e - /i.test(status)) {
            /** END STATUS */
            const dropdown = dropdowns[`${stage}Rejection`];
            dropdown && dropdown.open();
          } else if (/h -/i.test(status)) {
            pickers.holdDate && pickers.holdDate.openDialog();
          } else if (
            /screen/i.test(ref) &&
            /w - event|w - screen/i.test(status)
          ) {
            /** W - EVENT ON SCREEN */
            this.parent.parent.parent.CalendarEvent.open({
              engagement: engagement,
              callback: (newDate, isFullday) => {
                /**
                 * On close Drawer of Screen Event
                 * Set new date in the UI item then update Engagement
                 */
                let index = !engagement["screen1"]
                  ? 1
                  : !engagement["screen2"]
                    ? 2
                    : !engagement["screen3"] && 3;

                if (!!index) {
                  const sField = "screen" + index;
                  const update = {};
                  update[sField] = newDate.toISOString();
                  updateField(update);
                  openRecruiterStageTransitionEmailPreview({
                    engagement,
                    eventType: index === 1 ? "firstScreenInterview" : "interview",
                    previousStatus: engStatus,
                    interviewDate: newDate.toISOString(),
                    isFullday,
                  });
                }
              },
            });
          } else if (
            /onsite/i.test(ref) &&
            /w - event|w - onsite/i.test(status)
          ) {
            /** W - EVENT ON SITE */
            this.parent.parent.parent.CalendarEvent.open({
              engagement: engagement,
              callback: (newDate, isFullday) => {
                /**
                 * On close Drawer of Onsite Event
                 * Set new date in the UI item then update Engagement
                 */
                const index = !engagement["onsite1"]
                  ? 1
                  : !engagement["onsite2"] && 2;
                if (!!index) {
                  updateField({ ["onsite" + index]: newDate.toISOString() });
                  openRecruiterStageTransitionEmailPreview({
                    engagement,
                    eventType: "interview",
                    previousStatus: engStatus,
                    interviewDate: newDate.toISOString(),
                    isFullday,
                  });
                }
              },
            });
          } else if (
            /screen|onsite/i.test(ref) &&
            [
              STATUS_W_EMPLOYER_AVAILABILITY,
              STATUS_W_EMPLOYER_DETAILS,
              STATUS_W_CANDO_EMP_AVAILABILITY,
              STATUS_W_CANDIDATE_AVAILABILITY,
              STATUS_W_CANDIDATE_DETAILS,
              STATUS_W_CANDIDATE_HOMEWORK,
              STATUS_W_RECRUITER_DETAILS
            ].includes(status)
          ) {
            openRecruiterStageTransitionEmailPreview({
              engagement,
              eventType: "status",
              previousStatus: engStatus
            });
          }
        }
      );
    };
    const onChangeRejectionReason = (rejectionReason) => {
      updateField(
        {
          state: "Closed",
          closed: new Date().getTime(),
          rejectionReason,
        },
        null,
        ({rejectionReasonAdditionalInfo}) => {
          console.debug(engagement);
          openRecruiterRejectionEmailPreview({
            rejectionReason,
            rejectionReasonAdditionalInfo,
            engagement,
            EngagementCardController,
            StageColumnController: this,
          });
        }
      );
    };
    return (
      <TableRowColumn
        colSpan={colSpan || 1}
        className={`mid-col blocks ${_selected ? "stage-selected " : ""}`}
        style={{ minWidth: width || 120, maxWidth: width || 120 }}
      >
        <div className="card-col-header v-align-mid">
          {_label}
          {
            /** NEXT ICON */
            _selected && Core.isAdminOrCoordinator() && (
              <IconButton className="icon16" onClick={nextStage}>
                <i className="material-icons">arrow_forward</i>
              </IconButton>
            )
          }
        </div>
        {
          /** COMMON DATE PICKER */
          !/confirmation|screen|onsite|offer/i.test(ref) && (
            <CustomPicker
              parent={this}
              args={
                _value
                  ? {
                    value: new Date(_value),
                    field: _field,
                    streakField: streak,
                  }
                  : {
                    validStage: stage,
                    field: _field,
                    streakField: streak,
                  }
              }
            />
          )
        }
        {
          /** OFFER DATE PICKERS */
          /confirmation/i.test(ref) && (
            <Fragment>
              <div className="date-picker inline-blocks v-aligns-middle relative">
                <label className="f-small">Introduced:&nbsp;</label>
                <span>
                  {moment(engagement.introduced).format("MM-DD-YYYY")}
                </span>
              </div>
              {!!engagement.matched ? (
                <CustomPicker
                  parent={this}
                  args={{
                    value:
                      !!engagement.matched && new Date(engagement.matched),
                    field: "matched",
                    streakField: "Matched",
                    label: "Matched",
                  }}
                />
              ) : (
                <CustomPicker
                  parent={this}
                  args={{
                    value:
                      !!engagement.createdAt &&
                      new Date(engagement.createdAt),
                    field: "matched",
                    streakField: "Matched",
                    label: "Matched ?",
                  }}
                />
              )}
              <CustomPicker
                parent={this}
                args={{
                  value:
                    !!engagement.confirmed && new Date(engagement.confirmed),
                  field: "confirmed",
                  streakField: "confirmed",
                  label: "Confirmed",
                }}
              />
            </Fragment>
          )
        }
        {
          /** SCREEN DATE PICKERS */
          /screen/i.test(ref) && (
            <Fragment>
              {engagement.screen1 && (
                <CustomPicker
                  parent={this}
                  args={{
                    value: new Date(engagement.screen1),
                    field: "screen1",
                    streakField: "Screen1",
                    isFullday: engagement._screen1IsFullday,
                  }}
                />
              )}
              {engagement.screen2 && (
                <CustomPicker
                  parent={this}
                  args={{
                    value: new Date(engagement.screen2),
                    field: "screen2",
                    streakField: "Screen2",
                    isFullday: engagement._screen2IsFullday,
                  }}
                />
              )}
              {engagement.screen3 && (
                <CustomPicker
                  parent={this}
                  args={{
                    value: new Date(engagement.screen3),
                    field: "screen3",
                    streakField: "Screen3",
                    isFullday: engagement._screen3IsFullday,
                  }}
                />
              )}
            </Fragment>
          )
        }
        {
          /** ONSITE DATE PICKERS */
          /onsite/i.test(ref) && (
            <Fragment>
              {engagement.onsite1 && (
                <CustomPicker
                  parent={this}
                  args={{
                    value: new Date(engagement.onsite1),
                    field: "onsite1",
                    streakField: "Onsite1",
                    isFullday: engagement._onsite1IsFullday,
                  }}
                />
              )}
              {engagement.onsite2 && (
                <CustomPicker
                  parent={this}
                  args={{
                    value: new Date(engagement.onsite2),
                    field: "onsite2",
                    streakField: "Onsite2",
                    isFullday: engagement._onsite2IsFullday,
                  }}
                />
              )}
            </Fragment>
          )
        }
        {
          /** OFFER DATE PICKERS */
          /offer|hire|guarantee/i.test(ref) && (
            <Fragment>
              <CustomPicker
                parent={this}
                args={{
                  value: !!engagement.onsite && new Date(engagement.onsite),
                  field: "onsite",
                  streakField: "Onsite",
                  label: "Extended",
                }}
              />
              <CustomPicker
                parent={this}
                args={{
                  value: !!engagement.offered && new Date(engagement.offered),
                  field: "offered",
                  streakField: "Offered",
                  label: "Accepted",
                  onChange: (ev, date) => {
                    if (/offer/i.test(engStage)) {
                      nextStage(ev);
                    }
                  },
                }}
              />
              <CustomPicker
                parent={this}
                args={{
                  value:
                    !!engagement.startDate && new Date(engagement.startDate),
                  field: "startDate",
                  streakField: "StartDate",
                  label: "Start",
                }}
              />
              <CustomPicker
                parent={this}
                args={{
                  value:
                    !!engagement.guaranteeDate &&
                    new Date(engagement.guaranteeDate),
                  field: "guaranteeDate",
                  streakField: "GuaranteeDate",
                  label: "Guarantee",
                }}
              />
            </Fragment>
          )
        }
        {
          /** STATUS - DROPDOWN */
          _selected &&
          (Core.isAdminOrCoordinator() ? (
            <div className={"blocks"} title={`${hoverDisplay(engagement)}`}>
              {!!_statusArr.length && (
                <DropDown
                  placeholder="Status"
                  ref={(self) => (dropdowns[ref] = self)}
                  value={engStatus}
                  className="card-col-dropdown"
                  onChange={onChangeStatus}
                  fullWidth
                  disabled={Core.isRecruiter()}
                >
                  {_statusArr.map((status) => (
                    <MenuItem
                      value={status}
                      key={status}
                      primaryText={status}
                    />
                  ))}
                </DropDown>
              )}
              {/open/i.test(engagement.state) &&
                /submission/i.test(ref) &&
                /submission/i.test(engStage) &&
                /W - 10x10/i.test(engStatus) && (
                  <NavLink
                    exact
                    to={
                      "/candidate/resume-submission/" +
                      engagement.candidate.id +
                      "/" +
                      engagement.jobId
                    }
                    className="ui-m-min"
                    style={{ marginTop: 5 }}
                  >
                    <i title="Submit Resume" className="material-icons">
                      assignment_ind
                    </i>
                  </NavLink>
                )}
              {/H -/i.test(engStatus) &&
                picker({
                  value: engagement.holdDate
                    ? moment(engagement.holdDate).toDate()
                    : moment().toDate(),
                  field: "holdDate",
                  streakField: "HoldDate",
                  label: "Hold",
                })}
            </div>
          ) : (
            <span className="card-eng-status">{engStatus}</span>
          ))
        }
        {
          /** REJECTION REASON - DROPDOWN */
          _selected &&
          /** if status is END(e - ) but not SUCCESS */
          /e - /i.test(engStatus) &&
          !/success/i.test(engStatus) &&
          (Core.isAdminOrCoordinator() ? (
            <div title={engagement.rejectionReasonAdditionalInfo}>
              <DropDown
                placeholder="Rejection Reason"
                ref={(self) => (dropdowns[`${stage}Rejection`] = self)}
                value={engagement.rejectionReason}
                className="card-col-dropdown"
                onChange={onChangeRejectionReason}
                fullWidth
                disabled={Core.isRecruiter()}
              >
                {Streak.getDropdownStrings("RejectionReason")
                  .sort((a, b) => a.localeCompare(b))
                  .map((reason) => (
                    <MenuItem
                      value={reason}
                      key={reason}
                      primaryText={reason}
                    />
                  ))}
              </DropDown>
            </div>
          ) : (
            <span>{engagement.rejectionReason}</span>
          ))
        }
        {/** TO CLEANUP 2021-05-11 µ */}
        {/** * /}
          _selected &&
          parentState.overdueDate && (
            <CustomPicker
              parent={this}
              args={{
                value:
                  !!parentState.overdueDate &&
                  new Date(parentState.overdueDate),
                field: "overdueDate",
                streakField: "overdueDate",
                label: "Overdue"
              }}
            />
          )}
        {_selected && (
          <CustomPicker
            parent={this}
            args={{
              value:
                !!parentState.lastAction && new Date(parentState.lastAction),
              field: "lastAction",
              streakField: "Last Action",
              label: "Last Action"
            }}
          />
        )
        {/** */}
      </TableRowColumn>
    );
  }
}

/* EXPORTS ==================================== */

export default StageColumn;

/* ============================================ */
