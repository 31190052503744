import { Checkbox, IconButton } from '@mui/material';
import React, { useState } from "react";
import MoreOptionsMenu from './MoreOptionsMenu';

export function CellActionsColumn(props) {
  let {

    // from react-table-hook
    row,

    // config
    context: { config },

    // from enhancedReactTableContext
    invertExpanded,

  } = props;
  const {
    ExpandedView,
    Entity = {},
  } = config;
  let {
    original: model = {},
    isExpanded
  } = row;
  let _isExpanded = invertExpanded ? !isExpanded : isExpanded;
  let {
    starred,
  } = model;
  const [_starred, setStarred] = useState(starred);
  const onClickStar = checked => Entity.updateStarred(
    model.id,
    model.starredId,
    checked,
  ).then(({ starred, id, }) => {
    model.starredId = id;
    model.starred = starred;
    return starred;
  });
  return (
    <div className="d-flex flex-align-right-center">
      {ExpandedView && (
        <IconButton onClick={event => {
          row.toggleRowExpanded();
        }}>
          <i className="material-icons">
            {_isExpanded ? 'expand_less' : 'expand_more'}
          </i>
        </IconButton>
      )}
      {model.openDetails && (
        <IconButton onClick={event => {
          model.openDetails();
        }}>
          <i className="material-icons">
            chevron_right
          </i>
        </IconButton>
      )}
      <Checkbox
        title="Click to Starred"
        className="star"
        checked={starred}
        defaultChecked={_starred}
        onChange={event => onClickStar(event.target.checked).then(setStarred)}
        checkedIcon={<i className="material-icons">star</i>}
        icon={<i className="material-icons">star_border</i>}
      />
      <MoreOptionsMenu {...props} />
    </div>
  );
}
