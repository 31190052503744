import React, {Component, Fragment} from "react";

/* Lib files */
import styles from "../../../Forms/Styles";
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

class RequirementsSection3 extends Component{
    renderList = (data)=>{
        if(!Array.isArray(data)){
            data = [];
        }

        return data.map((obj,index)=>{
            return <div key={index} style={styles.CandidateMatch.margins.mBottom20}>
                <div style={styles.CandidateMatch.margins.mBottom10}>
                    {
                        obj.chips.map((chip, key)=>{
                            return <Chip
                                key={key}
                                variant="outlined"
                                style={{border:'solid 0.5px #3fb213'}}
                                label={chip}
                                onClick={()=>{}}
                            />
                        })
                    }

                </div>
                <div>
                    {obj.label}
                </div>
            </div>
        })

    }

    render(){

        const {
            selectedJob
        } = this.props;

        return (
            <Fragment>
                <Card className="match-new section-card" style={{boxShadow: '0px 0px 10px #888888'}}>
                    <div>
                        <CardContent className="match-new card-content">
                            {/* <h2 className="match-new section-header">Requirements Section 3</h2>
                            <hr /> */}
                            {/* <div style={styles.CandidateMatch.border.bLeft}> */}
                                {!!selectedJob&&!!selectedJob.qualificationNotes && <Fragment>
                                    <div className="match-new heading-center">
                                        INSIDER SCOOP<span> - qualificationNotes</span>
                                    </div>
                                    <hr />
                                    <p className="subm-write" dangerouslySetInnerHTML={{__html: selectedJob.qualificationNotes}}></p>
                                </Fragment>}

                                {!!selectedJob&&!!selectedJob.publicNotes && <Fragment>
                                    <div className="match-new heading-center">PUBLIC NOTES</div>

                                    <hr />
                                    <p className="subm-write"
                                        dangerouslySetInnerHTML={{__html: selectedJob.publicNotes}}></p>
                                </Fragment>}

                                {!!selectedJob && !!selectedJob.privateNotes && <Fragment>
                                    <div className="match-new heading-center">PRIVATE NOTES</div>

                                    <hr />
                                    <p className="subm-write"
                                        dangerouslySetInnerHTML={{__html: selectedJob.privateNotes}}></p>
                                </Fragment>}

                                {!!selectedJob && !!selectedJob.jobDescription && <Fragment>
                                    <div className="match-new heading-center">JOB DESCRIPTION</div>
                                    <hr />
                                    <p className="subm-write"
                                            dangerouslySetInnerHTML={{__html: selectedJob.jobDescription}}></p>
                                </Fragment>}

{/*
                                {!!selectedJob && !!selectedJob.additionalNotes && <Fragment>
                                    <div className="match-new heading-center">OLD MUST HAVE<span> - additionalNotes</span></div>
                                    <hr />
                                    <p className="subm-write"
                                            dangerouslySetInnerHTML={{__html: selectedJob.additionalNotes}}></p>
                                </Fragment>}
*/}
                            {/* </div> */}
                        </CardContent>
                    </div>
                </Card>
            </Fragment>
        );
    }
}

export default RequirementsSection3;