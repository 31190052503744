import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import deburr from 'lodash/deburr';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';

function renderInputComponent(inputProps) {
  const {
    classes, inputRef = () => {
    }, ref, ...other
  } = inputProps;

  // review to cleanup | 2021-08-16 Mon µ
  /** * /
  const hrStyles = {
    borderTop: "none rgb(224, 224, 224)",
    borderLeft: "none rgb(224, 224, 224)",
    borderRight: "none rgb(224, 224, 224)",
    borderBottom: "1px solid rgb(224, 224, 224)",
    bottom: '8',
    boxSizing: 'content-box',
    margin: '0',
    position: 'absolute',
    width: '100%'
  };
  /** */

  return (
    <div>
      <TextField
        variant="standard"
        fullWidth
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
          },
        }}
        inputProps={
          { style: { marginTop: 11, borderBottom: '1px solid rgb(224, 224, 224)' } }
        }
        {...other}
      />
      {/*<div>*/}
      {/*  <hr aria-hidden="true"*/}
      {/*      style={hrStyles}/>*/}
      {/*  <hr aria-hidden="true"*/}
      {/*      style={hrStyles}/>*/}
      {/*</div>*/}
    </div>
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          ),
        )}
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

const styles = {
  root: {
    flexGrow: 1,
    marginBottom: 10,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 999,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {},
};

export default class TenAutoSuggest extends Component {
  state = {
    single: '',
    popper: '',
    suggestions: [],
  };

  getSuggestions = (value) => {
    let { suggestions } = this.props;

    if (!suggestions) {
      suggestions = this.state.suggestions;
    }

    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    // let count = 0;

    return inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
        const regex = new RegExp(inputValue, 'i')
        return regex.test(suggestion.label);
      });
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const { handleSuggestionsFetchRequested } = this.props;

    if (value.length > 0) {
      if (!!handleSuggestionsFetchRequested) {
        handleSuggestionsFetchRequested(deburr(value.trim()).toLowerCase(), (response) => {
          this.setState({
            suggestions: response,
          });
        });
      } else {
        this.setState({
          suggestions: this.getSuggestions(value),
        });
      }
    }
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = name => (event, { newValue }) => {
    const { onChange } = this.props;
    onChange(event, (newValue || ''));
  };

  handleBlur = (event) => {
    const { onBlur } = this.props;
    !!onBlur && onBlur(event, (event.target.value || ''));
  };

  render() {
    const classes = styles;
    const {
      value
      //, onBlur
    } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
    };

    return (
      <div style={{ ...Object(this.props.style), ...styles.root }}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            label: '',
            placeholder: 'Type to search',
            value: value,
            onChange: this.handleChange('popper'),
            onBlur: this.handleBlur,
            inputRef: node => {
              this.popperNode = node;
            },
            InputLabelProps: {
              shrink: true,
            },
          }}
          theme={{
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Popper anchorEl={this.popperNode} open={Boolean(options.children)}>
              <Paper
                square
                {...options.containerProps}
                style={{ width: this.popperNode ? this.popperNode.clientWidth : null }}
              >
                {options.children}
              </Paper>
            </Popper>
          )}
        />
      </div>
    );
  }
}