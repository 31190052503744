import React, {Component} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default class ConfirmParser extends Component {
  render() {
    const {open, onCancel, onAccept} = this.props;

    return (
      <div>
        <Dialog
          open={open}
          onClose={onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Overwrite these fields from the newly uploaded file?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p> 
                First name, Last name, Email, Phone, URLs, and employment, education.
              </p>
              <p>
                Only use “Upload & Parse File” if new companies/education are added.
                Please re-verify the accuracy since previously entered data will be overwritten.
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={onCancel}>
              Upload File Only
            </Button>
            <Button color="primary" onClick={onAccept} autoFocus>
              Upload & Parse
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}