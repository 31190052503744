import React, { Component, Fragment } from "react";
import MatchDrawer from "../../../Shared/Match/MatchDrawer";
import JobContentDrawer from "./JobContentDrawer";

class JobPipeHeader extends Component {

  handleDrawer = (refString) => {
    if (refString === "jobInfoDrawer") {
      this.refs.jobInfoDrawer.handleOpen();
    }
  }

  render() {
    const {
      candidate,
      selectedJob,
      makeChipsForJobPipe
    } = this.props;

    if (!selectedJob) {
      return '';
    }

    let jobEntities = [];
    if (!!selectedJob && !!selectedJob.employer._employerHiringDifficulty) {
      jobEntities.push(`(${selectedJob.employer._employerHiringDifficulty})`);
    }
    if (!!selectedJob && !!Object(selectedJob.filters).Role) {
      jobEntities.push(selectedJob.filters.Role);
    }
    if (!!selectedJob && !!selectedJob.addressCity) {
      jobEntities.push(selectedJob.addressCity);
    }

    return <Fragment>
      <MatchDrawer
        ref="jobInfoDrawer"
        content={
          <JobContentDrawer
            selectedJob={selectedJob}
          />
        }
      />
      <p className="match-new job-attributes">
        <a href={`#/employer/edit/${!!selectedJob && selectedJob.employer.id}`} target="_blank" rel="noreferrer">{!!selectedJob && selectedJob.employer.name}</a> {!!jobEntities.length ? jobEntities.join(", ") : ''}
      </p>
      <p className="match-new job-title">
        <a href={`#/job/edit/${!!selectedJob && selectedJob.id}`} target="_blank" rel="noreferrer">{!!selectedJob && selectedJob.jobTitle}</a> - <span className="anchor" onClick={() => this.handleDrawer("jobInfoDrawer")}>Job Details</span>
      </p>
      {!!makeChipsForJobPipe && makeChipsForJobPipe(selectedJob, candidate)}
    </Fragment>
  }
}

export default JobPipeHeader;