/** ============================================ µ
 * @description ComponentName [JSX]
 *              UI Component
 * @createdAt   2021-11-01
 * @updatedAt   2021-11-05
 * ============================================ */
/* IMPORTS ==================================== */

import { CardContent, Divider, Icon, Typography } from "@mui/material";
import React from "react";
// import { useTranslation } from "react-i18next";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function ComponentName({
  icon,
  title = '',
  children = '',
  className,
  style,
}) {
  // const { something } = props;
  // const { t } = useTranslation();
  return (
    <>
      <CardContent className={className} style={style}>
        <div className="d-flex flex-align-left-center mb-1">
          {icon && <Icon className="mr-1">{icon}</Icon>}
          <Typography gutterBottom variant="h6" component="h6" className="text-uppercase c-black m-0">
            {title}
          </Typography>
        </div>
        <Typography variant="body2" component="div" className="c-gray pt-1">
          {children}
        </Typography>
      </CardContent>
      <Divider />
    </>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { ComponentName as default, ComponentName };

/* ============================================ */
