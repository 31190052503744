import React, {Component} from "react";

class And extends Component {

    render() {
        const {decision} = this.props;
        
        let text = <span><i style={{fontSize: '20px'}} className="material-icons">done</i></span>;

        decision.forEach(dec => {
            if(dec.group === 0){
                text = <span><i style={{fontSize: '20px'}} className="material-icons">cancel</i></span>;
            }
        });
        return text;
    }
}

export default And;
