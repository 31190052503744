import React, { Fragment, Component } from "react";

import Checkbox from "material-ui/Checkbox";

class MultiSelectJob extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            selected: !!this.props.multiSelectedJobs 
                        ? this.props.multiSelectedJobs.find(sjob => sjob.id === this.props.job.id) 
                        : false
        };
    }

    onSelectJobBox = () => {
        const { handlerOnSelectBox, job } = this.props;
        this.setState({ selected: !this.state.selected }, () => {
            !!handlerOnSelectBox && handlerOnSelectBox(job, this.state.selected);
        });
    }

    componentWillUpdate(nextProps, nextState){
        if(nextProps.job.id !== this.props.job.id){
            this.setState({selected: false});
        }
    }

    render(){
        const {selectedCheck} = this.props;

        return(
            <Fragment>
                <Checkbox
                    title="Click to Select"
                    value={selectedCheck}
                    checked={!!selectedCheck ? true : false}
                    onCheck={this.onSelectJobBox}
                />
            </Fragment>
        );
    }
}

export default MultiSelectJob;