import _ from 'lodash';
import AppBar from "material-ui/AppBar";
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import Popover from "material-ui/Popover";
import Snackbar from "material-ui/Snackbar";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import loader from "../../../assets/images/loader.gif";
import Candidate from "../../../lib/Candidate";
import Core, { colors } from "../../../lib/Core";
import Engagement from "../../../lib/Engagement";
import Google from "../../../lib/Google";
import HistoryLog from "../../../lib/HistoryLog";
import Job from "../../../lib/Job";
import { getCandidateModel } from "../../../lib/models/candidate";
import Store from "../../../lib/Store";
import Streak from "../../../lib/Streak";
import copyHTML from "../../../lib/tools/copyHtml";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import EmailPreview from "../../Dialogs/EmailPreview";
import "../../Dialogs/Modal.css";
import DialogSuccess from "../../Dialogs/Success";
import FilterControl from "../../FilterControl/FilterControl";
import styles from "../../Forms/Styles";
import HistoryMenu from "../../HistoryMenu/HistoryMenu";
import JobCard from "../../Jobs/Card/JobCard";
import List from "../../List/ListMatch";
import AnswerJobsQuestions from "../Forms/AnswerJobsQuestions";
import CandidateProfile from "./CandidateProfile";

let attempt = 0;

class MatchCandidate extends Component {
  data;
  constructor() {
    super(...arguments);
    this.name = "CandidateMatch";
    this.state = {
      ...getCandidateModel({ extended: true }),
      engaged: {},
      jobsPermitted: {},
      snackBarMessage: "",
      snackBarOpen: false,
      matching: false,
      badMatching: false,
      openPopover: false,
      anchorEl: null,
      selected: false
    };
    Store.set("path", window.location.href);
    this.reloadData = this.loadData;
    this.loadData();
  }

  /* take permitted answers from state and update candidate */

  onProceedJobQuestions = () => {
    const permittedJobsAnswers = this.state.permittedJobsAnswers;
    Candidate.update(
      this.state.id,
      {
        permittedJobsAnswers
      }
    );
  }

  onClickBadMatch = event => {
    event.preventDefault();
    const jobs = this.List.selected;
    let count = 0;
    this.setState({ badMatching: true }, () => {
      jobs.forEach(job => {
        let field = {
          stage: 'Confirmation',
          state: 'Closed',
          status: 'E - 10x10',
          rejectionReason: '10x10 - No Match'
        }
        this.createRejectionReason(this.state)(job)(field, null, () => {
          count++;
          if (count === jobs.length) {
            this.loadData(() => {
              this.List.clearSelected();
              this.setState({ badMatching: false });
            });
          }
        });
      })
    });
  };

  createRejectionReason = (candidate) => (job) => (field, eventStatus, callback) => {
    // e.g externalObjId = candidateId & currentObjId= jobId if card is of Job id
    // this is particularly created for engagement creation on job and candidate
    if (Core.isAdminOrCoordinator()) {
      Engagement.match(
        { candidate: candidate, job, stage: field.stage, status: field.status },
        response => {
          if (/end/i.test(field.stage)) {
            field.open = false;
          }
          field.closed = new Date().getTime();
          this.setState({ matching: false }, then => {
            Engagement.update(response, field, () => {
              if (callback) {
                callback();
              } else {
                this.successDialog.open("success");
              }
            });
          });
        },
        error => {
          Core.showFailure(error);
        }
      );
    }
  }

  availablePermittedJobs = (candidate, engagedJobs) => {
    const permitted = candidate.jobsPermitted || []
    return permitted.filter(obj => !engagedJobs.includes(obj))
  }

  awaitingJobs = (candidate, engagedJobs) => {
    const pitched = candidate.jobsPitched || []
    return pitched.filter(obj => !engagedJobs.includes(obj))
  }

  declinedJobs = (candidate, engagedJobs) => {
    const declined = candidate.jobsDeclined || []
    return declined.filter(obj => !engagedJobs.includes(obj))
  }

  loadData(cb) {
    setTimeout(st => {
      if (this.FilterControl) {
        Candidate.get(this.props.match.params.id, candidate => {
          const engaged = {};
          candidate.engagements.forEach(eng => {
            engaged[eng.jobId] = eng;
          });

          let availablePermittedJobs = this.availablePermittedJobs(candidate, Object.keys(engaged));
          let awaitingJobs = this.awaitingJobs(candidate, Object.keys(engaged));
          let declinedJobs = this.declinedJobs(candidate, Object.keys(engaged));

          this.setState({ ...candidate, engaged, awaitingJobs, declinedJobs, availablePermittedJobs, selected: (availablePermittedJobs.length > 0) }, then => {
            this.SubmissionNotes &&
              this.SubmissionNotes.setValue(this.state.submissionNotes);
            Job.getAll(jobs => {
              this.setState({ jobs }, then => {
                let preset = "^Active$";
                if (candidate._roles && candidate._roles.length) {
                  preset += "|^" + candidate._roles.replace(/, /g, "$|^") + "$";
                }

                let filterState = {};
                if (!!candidate.minimumSalary) {
                  filterState.minimumSalary = parseInt(candidate.minimumSalary);
                }
                if (!!candidate.yearsOfExperience && candidate.yearsOfExperience > 0) {
                  filterState.minimumXp = parseInt(candidate.yearsOfExperience);
                }

                this.FilterControl.setItems(jobs, preset, this.jiraVER20(), this.jiraVER20(), filterState);
                this.List.setEngaged(jobs);
                this.List.setSelected(jobs, availablePermittedJobs);

                // if (Core.isAdmin()) {
                //   try {
                //     this.FilterControl.setState({
                //       ...JSON.parse(this.state.searchConfig)
                //     });
                //   } catch (ex) {
                //     console.warn(ex);
                //   }
                // }
                HistoryLog.set({
                  group: "candidates",
                  label: `Match: ${candidate._name}`
                });
                Core.log({
                  CadidateMatch: this.state,
                  preset
                });

                if (cb) {
                  cb();
                };
              });
            });
          });
        });
      } else {
        if (attempt < 10) {
          attempt++;
          Core.log({ missingFilterControl: true });
          this.loadData();
        }
      }
    });
  }
  jiraVER20 = em => {
    /** [ Jira Ticket VER-20 ] ===>>> * /
    /**
      For Visa,
      Citzen > Green Card > Visa Transfer > Visa Sponsor
      If a candidate is a Citzen, it should fit all jobs.
      If a candidate requires Visa transfers,
        it should fit both Visa transfer and Visa sponsor
          (but not green card and citzen) jobs.
      Always include unspecified, unknow in the search results.

      Candidate Visa Options:

      Citizen
      Green Card
      Needs H1B Visa Transfer
      Needs H1B1/E-3 (Chile, Singapore, Australia)
      Needs TN (Canada, Mexico)
      Needs New Visa Sponsor
      Visa Status Unknown

      Job Visa Options:

      Citizen
      Green Card
      Transfer Visa
      Sponsor Visa
      No Support Visa
      Visa Support Unknown
     */
    const candidate = this.state;
    // let visa = "Visa Support Unknown";
    // if (
    //   candidate._visa === "Citizen"
    //   // || candidate._visa === "No Support Visa"
    // ) {
    //   visa = "Citizen";
    // } else if (/Needs TN|Needs H1/i.test(candidate._visa)) {
    //   visa = "Transfer Visa";
    // } else if (/Sponsor/i.test(candidate._visa)) {
    //   visa = "Sponsor Visa";
    // } else if (/Green Card/i.test(candidate._visa)) {
    //   visa = "Green Card";
    // }
    // see also FilterControl.jiraVER20 method.
    // see also FilterControl/SelectTagDialog call to parent.jiraVER20 method.
    // see also JobMatch.jiraVER20 method.
    /** <<<=== [ Jira Ticket VER-20 ] */
    return [
      { key: 'visa', label: candidate._visa, checked: true },
      { key: 'location', label: candidate._workLocationIds, checked: true }
    ];

    // return candidate._visa;
  };
  showMessage = msg => {
    this.setState({
      snackBarMessage: msg,
      snackBarOpen: true
    });
  };
  hideMessage = () => {
    this.setState({
      snackBarMessage: "",
      snackBarOpen: false
    });
  };
  onClickMatch = event => {
    event.preventDefault();
    this.setState({
      openPopover: true,
      anchorEl: event.currentTarget
    });
  };


  matchCandidate = (stage, status) => (answers = {}) => {
    this.setState({ openPopover: false, matching: true });

    // const withStrengthMatch = this.List.selected.filter((o)=>(!!Store.get(`${Engagement.cacheStrengthsPrefix}${o.id}`)));
    // const withNegativeMatch = withStrengthMatch.filter((o)=>(Engagement.negativeStrengths.includes(Store.get(`${Engagement.cacheStrengthsPrefix}${o.id}`))));
    //
    // if(withNegativeMatch.length) {
    //   this.List.highlightSrengthFlag(withNegativeMatch);
    //   alert('Please click on 3 dots to complete the bad matches for yellow highlighted items');
    //   this.setState({matching:false});
    //   return;
    // }

    const jobIds = this.List.selected.map(item => item.id);
    let count = 0;
    this.lastJobId = null;

    const next = em => {
      if (!!jobIds.length) {
        const jobId = jobIds.pop();
        const job = this.state.jobs.find(job => job.id === jobId);
        //let jobAnswers = answers[jobId]||[]; // not required now
        const matchStrength = Store.get(Engagement.getCacheKeyJobMatch(this.state.id, jobId));
        Engagement.match(
          { candidate: this.state, job, stage, status, matchStrength },
          response => {
            this.lastJobId = jobId;
            next(count++);
          },
          error => {
            next();
            Core.showFailure(error);
          }
        );
      } else {
        this.setState({ matching: false }, then => {
          this.successDialog.open(
            "Candidate has been successfully matched to " +
            count +
            " job" +
            (count > 1 ? "s" : "")
          );
        });
      }
    };
    next();
  };
  bulkCopy() {
    let headers = [];
    let body = "";
    Core.log({ sel: this.List.state.selected });
    this.List.selected.forEach(state => {
      const card = this.List.cards[state.id];
      if (card && card.getPreview) {
        headers.push("<li>" + state._name + "</li>");
        body += card.getPreview();
        body +=
          "<br/><hr/>";
      }
    });
    Core.log({ length: body.length });
    copyHTML(`
      ${this.state._name
      } - Looking forward to helping you interview - ${Core.getEnvValue(
        "TEAM_NAME"
      )}<br/>
      <br/>
      Hi ${this.state.firstName
      } - Below my signature, you'll find team and job specific information about:<br/>
      <ul>
      ${headers.join("")}
      </ul>
      <b>Important</b><br/>
      When you've read this, please email me back with any questions. Based on your reply, I will connect you with the teams you like to get interviews started.<br/>
      <br/>
      <u>By working with us, we commit to give you:</u><br/>
      <ul>
      <li>Access to innovative companies, many with jobs not yet publically posted.</li>
      <li>Fast-track interview, so your resumes isn't lost in a black hole.</li>
      <li>An insider scoop to help you navigate the interview process and save time.</li>
      <li>Market compensation data and expert advice on offer negotiations.</li>
      <li>Care and consideration for your preferences and career goals.</li>
      </ul>
      <br/>
      I will be your point of contact throughout the interview,<br/>
      ${Core.getEmailSignature()}
      ${body}
    `)
      .then(em => {
        Core.log("Copy email command was successful");
        this.showMessage("Copied!");
      })
      .catch(ex => {
        Core.log("Oops, unable to copy");
        this.showMessage("Fail copy!");
      });
  }
  saveSearch() {
    if (Core.isAdmin()) {
      setTimeout(st => {
        if (this.FilterControl) {
          let searchConfig = "{}";
          if (!!this.FilterControl.state.chips.length) {
            const state = {
              ...this.FilterControl.state
            };
            delete state.items;
            delete state.sources;
            delete state.anchorSavedSearches;
            searchConfig = JSON.stringify(state);
          }
          Candidate.update(this.state.id, { searchConfig });
        }
      });
    }
  }
  preventLoseMatch = ev => {
    alert("Are you sure you want to navigate away?");
  };
  openMessage = ev => {
    const emails = [];
    const candidate = this.state;
    candidate.email &&
      emails.push({
        name: candidate._name || "Candidate",
        email: candidate.email
      });

    candidate.recruiter.email &&
      emails.push({
        name: candidate.recruiter._name || "Recruiter",
        email: candidate.recruiter.email
      });
    Core.dialog.open({
      title: <>Message</>,
      message: (
        <EmailPreview
          ref={self => (this.EmailMessage = self)}
          emails={emails}
          to={!!emails[0] && [emails[0]]}
          subject=""
          body={[].filter(line => !!line).join("<br/>")}
        />
      ),
      className: "p-0",
      actions: [
        <FlatButton
          label="Cancel"
          className="button-flat-darker"
          onClick={ev => {
            Core.dialog.close();
          }}
        />,
        <FlatButton
          label="Send"
          className="button-white-cyan"
          onClick={ev => {
            Core.dialog.close();
            Google.sendEmail(
              {
                ...this.EmailMessage.getParams(),
                source: 'CandidateMatch.js line 498'
              },
              response => Core.showMessage("Email sent"),
              error => Core.showFailure(error)
            );
          }}
        />
      ]
    });
  };

  render() {
    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }
    return (
      <MuiThemeProvider>
        <div className="match">
          <div className="appBar" style={styles.AppBar}>
            <AppBar
              title="Candidate Match"
              titleStyle={styles.AppBar.title}
              style={styles.AppBar.nav}
              iconElementLeft={
                <FlatButton
                  icon={<i className="material-icons">arrow_back</i>}
                  style={{
                    color: colors.darkGray,
                    marginTop: "5px"
                  }}
                  onClick={ev => Core.goBack(this.props)}
                />
              }
              iconElementRight={
                <Fragment>
                  {!!this.state.resumes.length && (
                    <IconButton
                      className="float-right"
                      title="CV"
                      onClick={ev => Core.openPopUp(Candidate.getMyPdfUrl(this.state))}
                    >
                      <i className="material-icons">picture_as_pdf</i>
                    </IconButton>
                  )}
                  <IconButton
                    className="float-right"
                    onClick={this.openMessage}
                  >
                    <i className="material-icons">email</i>
                  </IconButton>
                  <HistoryMenu />
                </Fragment>
              }
            />
            <ConfirmDialog
              ref={self => (this.ConfirmDialog = self)}
              title="Go to Candidates"
              message="Are you sure that you want leave?"
              actionLabel="Go"
            />
          </div>
          <div className="match-mid flex">
            <div className="match-left-side">
              <CandidateProfile ref="CandidateProfile" parent={this} />
            </div>
            <div className="match-right-side">
              <FilterControl
                ref={FilterControl => (this.FilterControl = FilterControl)}
                parent={this}
                menus={_.cloneDeep(Job.menus)}
                more={_.cloneDeep(Job.more)}
                paddingTop={false}
                source={'candidateMatch'}
                onChange={filtered => {
                  this.List && this.List.setItems(filtered);

                  let availablePermittedJobs = this.state.availablePermittedJobs;
                  let awaitingJobs = this.state.awaitingJobs;
                  let declinedJobs = this.state.declinedJobs;

                  let pids = availablePermittedJobs.map(job => job.id);
                  let aids = awaitingJobs.map(job => job.id);
                  let dids = declinedJobs.map(job => job.id);

                  let filtered2 = filtered.filter(j => ![...pids, ...aids, ...dids].includes(j.id));
                  let permittedJobs = this.state.jobs.filter(j => [...availablePermittedJobs, ...awaitingJobs, ...declinedJobs].includes(j.id));

                  let final = [...filtered2, ...permittedJobs].filter(el => !!el);
                  Store.set('filtJobs', final);
                  // this.saveSearch();
                }}
                toolBarRight={
                  (!!this.state.selected || this.state.badMatching || this.state.matching) && (
                    <Fragment>
                      <IconButton onClick={ev => this.bulkCopy()}>
                        <i className="material-icons">content_copy</i>
                      </IconButton>
                      <FlatButton
                        label={
                          this.state.badMatching ? (
                            <img alt="B Matching..." height="36" src={loader} />
                          ) : (
                            "NO MATCH"
                          )
                        }
                        className="list-match"
                        onClick={this.onClickBadMatch}
                        disabled={this.state.badMatching}
                      />
                      <FlatButton
                        label={
                          this.state.matching ? (
                            <img alt="Matching..." height="36" src={loader} />
                          ) : (
                            "MATCH"
                          )
                        }
                        className="list-match"
                        onClick={this.onClickMatch}
                        disabled={this.state.matching}
                      />
                      <Popover
                        open={this.state.openPopover}
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={{
                          horizontal: "left",
                          vertical: "bottom"
                        }}
                        targetOrigin={{ horizontal: "left", vertical: "top" }}
                        onRequestClose={ev =>
                          this.setState({
                            openPopover: false
                          })
                        }
                      >
                        <Menu>
                          {Streak.getPipeline().matchOptions.map(option => (
                            <MenuItem
                              key={option}
                              primaryText={option}
                              onClick={ev =>
                                this.setState(
                                  {
                                    showQuestions: true,
                                    openPopover: false,
                                    handlerNextAnswers: this.matchCandidate(
                                      option.split(",")[0].trim(),
                                      option.split(",")[1].trim()
                                    )
                                  }
                                )

                              }
                            />
                          ))}
                        </Menu>
                      </Popover>
                    </Fragment>
                  )
                }
              />
              <List
                ref={List => (this.List = List)}
                tabs={Job.listTabs}
                tab={Job.listTab}
                name="Job"
                candidateId={this.state.id}
                card={JobCard}
                defaultSelected={this.state.jobsPermitted}
                externalRelationAssociation={this.createRejectionReason(this.state)}
                parent={this}
                parentModel={this.state}
                source={'candidateMatch'}
                onCheck={selected =>
                  this.setState({ selected: !!selected.length })
                }
              />


              <Snackbar
                open={this.state.snackBarOpen}
                message={this.state.snackBarMessage}
                className="snack-bar"
                autoHideDuration={6000}
                onRequestClose={this.hideMessage}
              />
              <DialogSuccess
                ref={dialog => (this.successDialog = dialog)}
                modal={true}
                width="520px"
                actions={[
                  <FlatButton
                    label="STAY"
                    title="Stay on Candidate Match"
                    className="button-flat-darker"
                    onClick={ev => window.location.reload()}
                  />,
                  <FlatButton
                    label="CANDIDATES"
                    title="Go to Jobs"
                    className="button-flat-darker"
                    onClick={ev => Core.go({ ...this.props, to: '/candidates/' })}
                  />,
                  <FlatButton
                    label="ENGAGEMENTS"
                    title="Go to Engagements"
                    className="button-flat-cyan"
                    onClick={ev => Core.go({ ...this.props, to: '/engagements/' })}
                  />,
                  <FlatButton
                    label="SUBMISSION"
                    title="Go to Resume Submission"
                    className="button-white-cyan"
                    onClick={ev => {
                      const candidateId = this.state.id;
                      const jobId = this.lastJobId;
                      if (jobId) {
                        Core.go({ ...this.props, to: `/candidate/resume-submission/${candidateId}/${jobId}` });
                      } else {
                        Core.showMessage("Missing job id");
                      }
                    }}
                  />
                ]}
              />
            </div>
          </div>
        </div>
        <AnswerJobsQuestions
          finalUpdater={res => { }}
          handlerNext={ansStruct => () => {
            !!this.state.handlerNextAnswers ? this.state.handlerNextAnswers(ansStruct) : e => { };
            this.setState({
              showQuestions: false, permittedJobsAnswers: {
                ...this.state.permittedJobsAnswers,         /* Copy all      candidate's permitted answers */
                ...ansStruct                                /* Copy updated  candidate's permitted answers */
              }
            }, () => { this.onProceedJobQuestions() }    /* async call to update candidate's permitted answers */
            );
          }}
          handlerNextOnCancel={data => () => {
            this.setState({ showQuestions: false, permittedJobsAnswers: data.initial })
          }
          }
          nxtBtnText="Proceed"
          cancelBtnText="Cancel"
          alwaysShow={true}
          initialState={this.state.permittedJobsAnswers}
          jobs={!!this.List ? this.List.selected.map(item => item.id) : []}
          open={this.state.showQuestions}

          close={el => { this.setState({ showQuestions: false }) }}
        />

      </MuiThemeProvider>
    );
  }
}

export default MatchCandidate;
