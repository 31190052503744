import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Announcements from '../components/Announcements/Announcements';
import CandidateEdit from '../components/Candidates/Edit/CandidateEdit';
import CandidateMatch from '../components/Candidates/Match/CandidateMatch';
import CandidateMatchNew from '../components/Candidates/MatchNew/CandidateMatchNew';
import MainV3 from '../components/Home/Main';
import MainV1 from '../components/HomeV1/Main';
import Login from '../components/Login/Login';
import Settings from '../components/Settings/Settings';
import { NoMatch } from './NoMatch.routes';

export const CommonUser = (em) => (
  <Switch>

    {/** COMMON SECTIONS */}
    <Route exact path="/" component={Login} />
    <Route path="/login" component={Login} />
    <Route path="/forgot/password" component={Login} />
    <Route path="/reset/password/:token" component={Login} />

    {/** COMMON SECTIONS V1 */}
    <Route path="/candidate/create" component={CandidateEdit} />
    <Route path="/candidate/edit-section/:id" component={CandidateEdit} />
    <Route path="/candidate/edit/:id" component={CandidateEdit} />
    <Route path="/candidate/view/:id" component={CandidateEdit} />
    <Route path="/candidate/match/:id" component={CandidateMatch} />
    <Route path="/candidate/matchNew/:id" component={CandidateMatchNew} />
    <Route path="/settings" component={Settings} />
    <Route path="/announcements" component={Announcements} />
    <Route path="/home" component={MainV1} />
    <Route path="/candidates" component={MainV1} />
    <Route path="/jobs" component={MainV1} />
    <Route path="/job/view/:id" component={MainV1} />
    <Route path="/recruiter" component={MainV1} />

    {/** COMMON SECTIONS V3 */}
    <Route path="/v3/candidate/create" component={CandidateEdit} />
    <Route path="/v3/candidate/edit-section/:id" component={CandidateEdit} />
    <Route path="/v3/candidate/edit/:id" component={CandidateEdit} />
    <Route path="/v3/candidate/view/:id" component={CandidateEdit} />
    <Route path="/v3/candidate/match/:id" component={CandidateMatch} />
    <Route path="/v3/candidate/matchNew/:id" component={CandidateMatchNew} />
    <Route path="/v3/settings" component={Settings} />
    <Route path="/v3/announcements" component={Announcements} />
    <Route path="/v3/recruiter" component={MainV1} />
    <Route path="/v3/home" component={MainV3} />
    <Route path="/v3/candidates" component={MainV3} />
    <Route path="/v3/jobs" component={MainV3} />
    <Route path="/v3/job/view/:id" component={MainV3} />
    <Route path="/v3/v1/jobs" component={MainV3} />

    {/* DEFAULT */}
    <Route component={NoMatch} />

  </Switch>
);
