import Definition from "../Definition";

const cvMatch = (skills, text) => {
        let processedSkills = [];
        const allSkills = Definition.getLabels("technicalSkills", skills);

        if (allSkills.length > 0 && text) {
            allSkills.forEach(val => {
                if(!!matchSkillWithText(val,text)){
                    processedSkills.push(val);
                }
            })
        }
        
        processedSkills = processedSkills.map(pSkills => Definition.getId("technicalSkills", pSkills));
        return processedSkills;
}

const escapeRegExp = (string) =>  {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const matchSkillWithText = (skill, text) => {
    let skillLabel = escapeRegExp(skill);
    const skillRegx = new RegExp(`\\b${skillLabel}\\b|^${skillLabel}\\b|^${skillLabel},\\w`, 'gi');

    return !!skillRegx.test(text);
}

export default cvMatch;