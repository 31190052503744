/*global chrome*/

/**
 * NOTE:
 * "global chrome" is a functional comment
 * don't remove it!
 * 2021-04-21 µ
 */

/** ============================================ µ
 * @description Basics [JS]
 *              UI Component
 * @routes      /candidate/edit/:candidateId
 *              /candidate/create
 * @updatedAt   2021-08-12 Thu
 * ============================================ */

/* IMPORTS ==================================== */

import { Accordion, Icon } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  Chip,
  FlatButton,
  List,
  ListItem,
  MenuItem,
  SelectField,
  TextField
} from "material-ui";
import MuiPhoneNumber from "material-ui-phone-number";
import moment from "moment";
import dig from "object-dig";
import React, { Component, Fragment } from "react";
import "react-tagsinput/react-tagsinput.css";
import Candidate from "../../../lib/Candidate";
import Company from "../../../lib/Company";
import Core, { colors } from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import Job from "../../../lib/Job";
import School from "../../../lib/School";
import SovrenData from "../../../lib/SovrenData";
import { matchSkillWithText } from "../../../lib/tools/cvMatch";
import formatURL from "../../../lib/tools/formatURL";
import SuccessDialog from "../../Dialogs/Success";
import DragAndDropZone from "../../DragAndDrop/DragAndDropZone";
import Chips from "../../Forms/Chips";
import Col from "../../Forms/Col";
import RichTextBox from "../../Forms/RichTextBox";
import Row from "../../Forms/Row";
import Slider from "../../Forms/Slider";
import LoaderInPopup from "../../ProgressTracker/loader-in-popup";
import InputArray from "../../Shared/InputArray";
import ResumeMatch from "../../Shared/ResumeMatch";
import TenAutoSuggest from "../../Shared/TenAutoSuggest";
import YearsDropdown from "../../Shared/YearsDropdown";
import AnswerJobsQuestions from "../Forms/AnswerJobsQuestions";
import CandidateJobChoices from "../Forms/CandidateJobChoices";
import ConfirmParser from "./ConfirmParser";
import Education from "./Education";
import Experience from "./Experience";
import JobHopperFieldset from "./JobHopperFieldset";
import ResumeLinks from "./ResumeLinks";

/* CONSTANTS ================================== */

const MONTHS = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const matchFromDef = (key, rawText, defaultLabel) => {
  let defaultt = Definition.get(key).find((obj) => obj.label === defaultLabel);
  let result = Definition.get(key).find((obj) => {
    let labels = [...(obj.alternativeNames || []), ...obj.label.split(" ")];
    return !!labels.find((label) => {
      let regex1 = RegExp(label, "i");
      return regex1.test(rawText);
    });
  });
  return !!result ? result : defaultt;
};

/* MAIN CLASS ================================= */

class Basics extends Component {
  constructor(args) {
    super(args);
    const t = this.props.parent.state._tags || [];
    const myTags = this.props.parent.state._myTags || [];
    this.state = {
      tags: t,
      myTags: myTags,
      allTags: t,
      removedElements: [],
      shouldShowConfirmParser: false,
      newElements: [],
      linkedInData: null,
      showQuestions: false,
      chipsAvailableRoles: [],
      availableStages: Definition.get("stage"),
    };
    this._hasjobsPermittedArrived = false;
    this._allTechnicalSkills = Definition.get("technicalSkills");
    this.getAvailableRoles();
    setTimeout(() => {
      if (this.props.parent.scrollToRole) {
        if (this.rolesLabel && !this.props.parent.rolesLabelScrolled) {
          this.rolesLabel.scrollIntoView("smooth");
          this.props.parent.rolesLabelScrolled = true;
        }
      }
    }, 1111);
  }

  componentDidUpdate() {
    if (
      !this._hasjobsPermittedArrived &&
      !!this.props.parent.state.jobsPermitted.length
    ) {
      this._hasjobsPermittedArrived = true;
      Job.getWhere(
        { id: { inq: this.props.parent.state.jobsPermitted } },
        (res) => {
          let qaResponse = res.filter(
            (j) =>
              Array.isArray(j.mustHaveQuestions) && !!j.mustHaveQuestions.length
          );
          !!this.props.updateJobs && this.props.updateJobs(qaResponse);
        }
      );
    }
  }

  defaultRenderTag = (propsCustom) => (props) => {
    let { tag, key } = props;
    let mine =
      propsCustom.myTags.includes(tag) ||
      !propsCustom.allTags.includes(tag) ||
      propsCustom.allTags.length === 0;
    return (
      <Chip
        key={key}
        className="chip"
        backgroundColor={colors.purple}
        labelStyle={{
          fontWeight: 300,
          fontSize: 13,
        }}
        style={{
          margin: 4,
          display: "inlineBlock",
        }}
        labelColor={colors.white}
        onRequestDelete={
          mine
            ? (e) => {
              let value = this.state.tags.concat([]);
              let index = key;
              if (index > -1 && index < value.length) {
                let changed = value.splice(index, 1);
                this.tagOnChange(value, changed, [index], {
                  action: "delete",
                });
              }
            }
            : null
        }
      >
        {tag}
      </Chip>
    );
  };

  tagOnChange = (allTags, changed, index, opts = { action: "add" }) => {
    const CandidateEditController = this.props.parent;
    this.setState({ tags: allTags });
    let myTag = this.state.myTags;

    if (opts.action === "add") {
      this.state.newElements.push(allTags.slice(-1));
      myTag = [...myTag, ...this.state.newElements];
    } else {
      this.setState({
        removedElements: [...this.state.removedElements, ...changed],
      });
      myTag = myTag.filter((x) => !this.state.removedElements.includes(x));
    }

    let latestSet = new Set(myTag);
    let def = this.props.parent.state.tags;

    def[Core.getUserRole()] = {};
    def[Core.getUserRole()][Core.getUserId()] = {};

    def[Core.getUserRole()][Core.getUserId()] = { tags: Array.from(latestSet) };
    CandidateEditController.setStateStore({ tags: def, key: "tags" });
  };
  /*
   * The following routine is defined but never used
   *
  parseLinkedInUpdate = ()=>{
      var extensionId = "gkefmiflkdpdelifedadlpkpcnneogha";
  };
  */

  parseLinkedIn = () => {
    // var extensionId = Core.isLocalHost() ? "gkefmiflkdpdelifedadlpkpcnneogha" : "dfjfkiebdocfeanjkdabfpfjiiffbjam";
    var extensionId = (Core.getSession().user || {}).extensionId;
    if (!extensionId) {
      alert("extension id missing, please add in account page first.");
      return;
    }
    const lurl = this.props.parent.state.linkedInURL;
    const that = this;
    const CandidateEditController = this.props.parent;
    const firstElementFromArray = function (array, n) {
      if (array == null) return void 0;
      if (n == null) return array[0];
      if (n < 0) return [];
      return array.slice(0, n);
    };

    /*
     * the following function is commented out below, therefore commenting it out here
     *
    const linkedInToDefinition = (keyValue)=>()=>{
        //degree,major,school
        let that = this;
        let keys = Object.keys(keyValue);
        let state = {};

        keys.forEach(key=>{
            let def = Definition.getRawDef(key);
            let values = def.values;
            let labels = values.map(el=>el.label);

            if(!!keyValue[key] && !labels.includes(keyValue[key])){
                let newLabel = {id:labels.length+1,label:keyValue[key]};
                state[key]=newLabel.id;
                values.push(newLabel);
                Definition.update(def.id,{values})
            }else{
                let [current] = values.filter(el=>el.label===keyValue[key]);
                state[key] = Object(current).id;
            }
        });

        return state;
    };
    */

    const formatLinkedinJobs = (linkedinJobs) => {
      console.log({ linkedinJobs });
      let description = "";
      return linkedinJobs.map((el, index) => {
        let [employerFrom, employerTo] = String(el.date).split("–");
        el.id = index + 1;
        el.employerFrom = employerFrom;
        el.employerTo = employerTo;
        description = !!el.description ? el.description : "";
        el.chips = this._allTechnicalSkills
          .filter(
            (skill) =>
              !!matchSkillWithText(
                skill.label,
                description.replace(/(<([^>]+)>)/gi, "")
              )
          )
          .map((skill) => skill.id);
        return el;
      });
    };

    const formatLinkedinSchools = (linkedinSchools) => {
      return linkedinSchools.map((el, index) => {
        let degreeFromDef = matchFromDef(
          "undergraduateDegree",
          el.degree,
          "None"
        );

        // if(/none/i.test(Object(degreeFromDef).label)) {
        //     el.linkedInToDefClosure = linkedInToDefinition({undergraduateDegree:el.degree});
        // }

        let dateSplitted = el.date.split("–");
        el.initialDegree = el.degree;
        el.degree = degreeFromDef.id;
        el.graduationYearTo = dateSplitted.pop();
        el.graduationYearFrom = dateSplitted.pop();
        el.id = index + 1;
        return el;
      });
    };

    const formatLinkedinSchoolsTo10by10 = (linkedinSchools) => {
      return linkedinSchools.map((el, index) => {
        let dateSplitted = (el.date || "").split("–");
        let endDate = dateSplitted.pop() || null;
        let startDate = dateSplitted.pop() || null;

        let defaultDegree = {
          degreeName: el.degree,
          endDate:
            !!endDate && moment.utc(endDate).isValid()
              ? moment.utc(endDate)
              : endDate,
          startDate:
            !!startDate && moment.utc(startDate).isValid()
              ? moment.utc(startDate)
              : startDate,
          startDateMonth: null,
          startDateYear: !!startDate ? +startDate : null,
          endDateMonth: null,
          endDateYear: !!endDate ? +endDate : null,
        };

        return {
          timestampTemporary: Math.random().toString(36),
          id: index,
          schoolName: el.school,
          schoolType: "",
          degrees: [defaultDegree],
        };
      });
    };

    const fromLinkedinTo10By10 = (linkedinJobs) => {
      const { parent } = this.props;

      return linkedinJobs.map((el, index) => {
        let [employerFrom, employerTo] = (el.date || "").split("–");
        let defaultPositionHistory = {
          title: el.title,
          description: el.description || "",
          startDate: moment.utc(employerFrom).isValid()
            ? moment.utc(employerFrom)
            : null,
          endDate: moment.utc(employerTo).isValid()
            ? moment.utc(employerTo)
            : null,
          isSelfEmployed: false,
        };

        let isCurrentEmployer = false;

        const MONTHS = [
          "",
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        let positionHistories = [
          defaultPositionHistory,
          ...(el.children || []),
        ].map((position) => {
          let [from, to] = (el.date || "").split("–");
          from = from.trim();
          to = to.trim();

          let currentlyEmployed = 2;

          if (String(to).trim() === "Present") {
            to = "current";
            currentlyEmployed = 1;
            parent.setState({ currentlyEmployed });
            isCurrentEmployer = true;
          }

          let monthStringStartDate =
            from.split(" ").length === 1 ? "" : from.split(" ")[0];
          let monthStringEndDate =
            to.split(" ").length === 1 ? "" : to.split(" ")[0];
          const regexMonthStringStartDate = new RegExp(
            monthStringStartDate,
            "g"
          );
          const regexMonthStringEndDate = new RegExp(monthStringEndDate, "g");

          return {
            title: position.title,
            description: position.description || "",
            startDate: moment.utc(from).isValid() ? moment.utc(from) : null,
            endDate:
              to === "current"
                ? null
                : moment.utc(to).isValid()
                  ? moment.utc(to)
                  : null,
            isSelfEmployed: false,
            startDateMonth:
              MONTHS.indexOf(
                MONTHS.find((m) => regexMonthStringStartDate.test(m))
              ) > 0
                ? MONTHS.indexOf(
                  MONTHS.find((m) => regexMonthStringStartDate.test(m))
                )
                : null,
            currentlyEmployed,
            startDateYear:
              from.split(" ").length === 1
                ? +from.split(" ")[0]
                : +from.split(" ")[1],
            endDateMonth:
              (to === "current"
                ? null
                : MONTHS.indexOf(
                  MONTHS.find((m) => regexMonthStringEndDate.test(m))
                )
                  ? MONTHS.indexOf(
                    MONTHS.find((m) => regexMonthStringEndDate.test(m))
                  )
                  : null) || null,
            endDateYear:
              (to === "current"
                ? null
                : to.split(" ").length === 1
                  ? to.split(" ")[0]
                  : to.split(" ")[1]) || null,
          };
        });

        return {
          id: index,
          timestampTemporary: Math.random().toString(36),
          employerOrgName: el.employer,
          isCurrentEmployer,
          positionHistories,
        };
      });
    };

    let passed = false;

    const getCurrentEmployer = (employmentHistories) => {
      const latest = employmentHistories[0];
      let ret = {
        currentEmployer: "",
        currentTitle: "",
        currentEmployerFrom: "",
        currentEmployerTo: "",
        currentlyEmployed: 2,
      };

      if (!latest) {
        return ret;
      }

      const latestPositionHistory = Object(latest.positionHistories[0]);

      let currentEmployerFrom = `${MONTHS[latestPositionHistory.startDateMonth || 0]
        } ${latestPositionHistory.startDateYear || ""}`.trim();
      let currentEmployerTo = `${MONTHS[latestPositionHistory.endDateMonth || 0]
        } ${latestPositionHistory.endDateYear || ""}`.trim();

      return {
        currentEmployer: latest.employerOrgName,
        currentTitle: latestPositionHistory.title,
        currentEmployerFrom,
        currentEmployerTo,
        currentlyEmployed: latestPositionHistory.currentlyEmployed,
      };
    };

    const getCurrentSchool = (educationHistories) => {
      const latest = educationHistories[0];
      let ret = {
        undergraduateDegree: "",
        undergraduateMajor: "",
        undergraduateSchool: "",
        graduationYear: "",
      };

      if (!latest) {
        return ret;
      }

      const latestDegree = Object(latest.degrees[0]);
      // let reta = {
      //   undergraduateDegree: latestDegree.degreeName,
      //   undergraduateMajor: '',
      //   undergraduateSchool: latest.schoolName,
      //   graduationYear: latestDegree.endDate
      // };

      let degreeFromDef = matchFromDef(
        "undergraduateDegree",
        latestDegree.degreeName,
        "None"
      );

      let graduationYear = `${MONTHS[latestDegree.endDateMonth || 0]} ${latestDegree.endDateYear || ""
        }`.trim();

      return {
        undergraduateDegree: Object(degreeFromDef).id,
        undergraduateMajor: "",
        undergraduateSchool: latest.schoolName,
        graduationYear,
      };
    };

    const extensionCb = (type) => {
      chrome.runtime.sendMessage(
        extensionId,
        { linkedInUrl: lurl, type },
        (response) => {
          let [firstKey] = Object.keys(response.data);
          const regexFirstKey = new RegExp(lurl, "i");

          if (regexFirstKey.test(firstKey)) {
            console.log({ response });
            that.setState({ linkedInData: response.data[firstKey] });

            let userDetails =
              firstElementFromArray(response.data[firstKey].userDetails) || "";
            let username = that.sanitizeString(userDetails.name) || "";
            let parent = this.props.parent;
            let educationHistories = formatLinkedinSchoolsTo10by10(
              response.data[firstKey].education
            );
            let schoolsLinkedIn = formatLinkedinSchools(
              response.data[firstKey].education
            );
            const employmentHistories = fromLinkedinTo10By10(
              response.data[firstKey].userExperience
            );

            console.log({
              history: fromLinkedinTo10By10(
                response.data[firstKey].userExperience
              ),
            });
            CandidateEditController.setStateStore({
              firstName: parent.state.firstName || username.split(" ")[0],
              lastName: parent.state.lastName || username.split(" ")[1],
              jobsLinkedIn: formatLinkedinJobs(
                response.data[firstKey].userExperience
              ),
              employmentHistories,
              educationHistories,
              schoolsLinkedIn,
              ...getCurrentEmployer(employmentHistories),
              ...getCurrentSchool(educationHistories),
            });

            passed = true;
          } else {
            if (!dig(response, "data")) {
              alert("you need to install extension");
              that.clearInterval();
            }
          }

          if (!passed) {
            window.setTimeout(function () {
              extensionCb("update");
            }, 2000);
          }
        }
      );
    };
    extensionCb("start");
  };

  clearInterval = () => {
    //clearInterval(this.intervalID);
  };

  getCompanyInfo = (value, cb) => {
    let employerOrgName = this.sanitizeString(value);

    Company.get(
      (response) => {
        this.setState(
          { companies: response, shouldShowCompanyBgColor: true },
          () => {
            !!cb && cb(response);
          }
        );
      },
      {
        where: {
          or: [
            { name: { like: employerOrgName, options: "i" } },
            { alternativeNames: employerOrgName },
          ],
        },
        fields: ["alternativeNames", "id", "name", "interviewBar", "type"],
        limit: 5,
      }
    );
  };

  getAvailableRoles = () => {
    let allRoles = Definition.get("roles");
    let allRolesIds = allRoles.map((el) => el.id);
    let filters = {
      where: { role: { inq: allRolesIds } },
      fields: ["role", "state"],
    };

    if (!this.state.chipsAvailableRoles.length) {
      Job.getAll(
        (response) => {
          let onlyActiveIds = response; //.filter(el=>parseInt(el.state)===1);
          let myRoles = this.props.parent.state.roles || [];
          let allAvailableIds = [
            ...onlyActiveIds.map((el) => parseInt(el.role)),
            ...myRoles,
          ];
          let availableIdsUniq = Array.from(new Set(allAvailableIds));
          let filtered = allRoles.filter((el) =>
            availableIdsUniq.includes(parseInt(el.id))
          );

          this.setState({ chipsAvailableRoles: filtered });
        },
        filters,
        { commonQuery: { include: false } }
      );
    }
  };

  /**
   *
   * Removes unwanted characters from strings.
   *
   * Amended to sanitize phone numbers differently by allowing '+', '(', and ')' to allow for formated phone
   * numbers from Sovren parser.  Otherwise filter '+', '(', and ')'
   */
  sanitizeString = (str, key = "") => {
    if (typeof str === "string") {
      if (key === "phone") {
        str = str.replace(/[^:/@a-z0-9áéíóúñü .,_\-+()]/gim, "");
      } else {
        str = str.replace(/[^:/@a-z0-9áéíóúñü .,_-]/gim, "");
      }
      return str.trim();
    } else {
      return str;
    }
  };

  callbackParsedSovren = (parsedResume, rawSovren) => {
    if (!parsedResume) {
      alert("Existing Parsed resume not found, contact support.");
    }

    const { parent: CandidateEditController } = this.props;

    const _parsedResume = { ...parsedResume };
    delete _parsedResume.yearsOfExperience;

    /** µ FOR DEBUG PURPOSES * /
        console.debug('µ:callbackParsedSovren', { _parsedResume });
    /** */

    /** µ POSSIBLE FUTURE IMPLEMENTATION (cleanup after 2021-03-31) * /
    Core.setKeyValue(
      'years-of-total-experience-detected-by-parser',
      _parsedResume.adjustedTotalYearsOfExp
    );
    /** */

    /** µ MARKED FOR FUTURE CLEANUP (ask to BB) * /
    /** This code appears to be redundent so commenting out - BB
    const sanitizeString = (str) => {
      if (typeof str === 'string') {
        str = str.replace(/[^a-z0-9áéíóúñü \.@:/,_-]/gim, "");
        return str.trim();
      } else {
        return str;
      }
    };
    */

    this.setState({ shouldShowLoader: false, progressMsg: "" });

    CandidateEditController.setStateStore(
      (state) => {
        state.rawSovren = rawSovren;
        Object.keys(_parsedResume).forEach((key) => {
          if (key === "gitHubURL" || key === "linkedInURL") {
            const regxGit = new RegExp("github.com/\\w+", "i");
            const regxGitDirty = new RegExp(
              "github.com/\\w+.+?(?=linkedin)",
              "i"
            );

            const regxlinked = new RegExp("linkedin.com/in/\\w+", "i");
            const regxlinkedDirty = new RegExp(
              "linkedin.com/in/\\w+.+?(?=github)",
              "i"
            );

            let links = _parsedResume[key] || "";
            let githubUrl = Array.isArray(links.match(regxGit))
              ? links.match(regxGit)[0]
              : "";
            let linkedInUrl = Array.isArray(links.match(regxlinked))
              ? links.match(regxlinked)[0]
              : "";

            if (/linked/i.test(githubUrl)) {
              githubUrl = Array.isArray(githubUrl.match(regxGitDirty))
                ? githubUrl.match(regxGitDirty)[0]
                : "";
            }

            if (/github/i.test(linkedInUrl)) {
              linkedInUrl = Array.isArray(linkedInUrl.match(regxlinkedDirty))
                ? linkedInUrl.match(regxlinkedDirty)[0]
                : "";
            }

            state[key] = githubUrl;
            state["linkedInURL"] = linkedInUrl;
          }

          state[key] = this.sanitizeString(
            _parsedResume[key],
            key === "phone" ? key : ""
          );
        });

        return state;
      },
      (then) => {
        CandidateEditController.setCandidateOnStore();
        // this.setState({rerender:true});

        this.setState({
          parsedResumeModel: _parsedResume,
          showParserPopup: true,
        });
      }
    );
  };

  runSovrenParser = (uploadedResumeUrl, filename) => {
    const { parent: CandidateEditController } = this.props;
    const { candidate = {}, resumes = [] } = CandidateEditController.state;
    const { currentUploadedResume, currentResumeTimestamp } = this.state;

    this.setState({
      shouldShowLoader: true,
      progressMsg: "Parser in progress...",
    });

    Candidate.sovrenParseResume(
      currentUploadedResume,
      CandidateEditController.state.firstName,
      currentResumeTimestamp,
      this.callbackParsedSovren,
      () => {
        this.setState({
          sovrenPushedToMongo: true,
          shouldShowLoader: false,
          progressMsg: "",
        });
      },
      (state) => {
        //just for Sovren urls
        CandidateEditController.setStateStore(state, () => {
          if (CandidateEditController.state.id) {
            Candidate.update(CandidateEditController.state.id, state);
          }
        });

      },
      () => {

        this.setState({ shouldShowLoader: false, progressMsg: "" });

        if (!candidate?.id) {
          Candidate.getPdfUrl({ uploadedResumeUrl, filename }, (res) => {
            CandidateEditController.setStateStore(
              state => {
                state.resumePdfUrl = res.url;
                state.resumeTxtUrl = res.urlTxt;
                state.anonFilename = filename;
                state.resumeScrubbedUrl = '';
                state.htmlResumeUrl = '';
                state.resumeJsonUrl = '';

                return state;
              });
          })
        } else {
          let firstCv = !!resumes.length ? resumes[0] : null;

          Candidate.getPdfUrl({
            url: firstCv.url,
            filename: `id_${candidate.id}`,
            oldFilename: CandidateEditController.state.anonFilename
          }, (res) => {
            Candidate.update(
              CandidateEditController.state.id,
              {
                resumes: CandidateEditController.state.resumes,
                resumePdfUrl: res.url,
                resumeTxtUrl: res.urlTxt,
                resumeScrubbedUrl: '',
                htmlResumeUrl: '',
                resumeJsonUrl: ''
              },
              result => {
                Core.log("RESULT", result);
                CandidateEditController.setStateStore(
                  {
                    resumePdfUrl: res.url,
                    resumeTxtUrl: res.urlTxt,
                    resumeScrubbedUrl: '',
                    htmlResumeUrl: '',
                    resumeJsonUrl: '',
                    key: 'resumes',

                  },
                  then => {
                    this.setState({ rerender: true });
                    CandidateEditController.beforeStateUpdateActions();
                  }
                );
              }
            );
          });
        }
      });

  };

  reparseSovrenResume = (timestamp) => {
    const { parent: CandidateEditController } = this.props;

    this.setState({
      shouldShowLoader: true,
      progressMsg: "Parser in progress...",
    });

    return () =>
      Candidate.sovrenResumeLoad(
        timestamp,
        false,
        this.callbackParsedSovren,
        (state) => {
          CandidateEditController.setStateStore(state, () => {
            if (CandidateEditController.state.id) {
              Candidate.update(CandidateEditController.state.id, state);
            }
          });
        }
      );
  };

  reparseSovrenResumeForFiles = (timestamp) => {
    const { parent: CandidateEditController } = this.props;

    return () =>
      Candidate.sovrenResumeLoad(
        timestamp,
        true,
        (result, raw) => {
          CandidateEditController.setStateStore({ rawSovren: raw });
        },
        (state) => {
          CandidateEditController.setStateStore(state, () => {
            if (CandidateEditController.state.id) {
              Candidate.update(CandidateEditController.state.id, state);
            }
          });
        }
      );
  };

  getSchoolInfo = (value, callback) => {
    let schoolName = this.sanitizeString(value);

    School.get(
      (response) => {
        this.setState(
          { ranking: response, shouldShowCompanyBgColor: true },
          () => {
            !!callback && callback(response);
          }
        );
      },
      {
        include: {
          relation: "schoolRating",
          scope: {
            fields: [
              "degree",
              "rating",
              "positiveSignalsTags",
              "negativeSignalsTags",
              "signals",
            ],
            scope: {
              where: {
                degree: Definition.getId("positiveSignals", "Computer degree"),
              },
            },
          },
        },
        where: {
          or: [
            { universityName: { like: schoolName, options: "i" } },
            { alternativeNames: schoolName },
          ],
        },
        fields: ["alternativeNames", "id", "schoolRating", "universityName"],
        limit: 5,
      }
    );
  };
  render() {
    this.intervalID && clearInterval(this.intervalID);

    const {
      parent: CandidateEditController = {}
    } = this.props;

    const {
      state = {}
    } = CandidateEditController;

    const {
      errorRelevantYearsOfExperience
    } = state;

    const lineStyle = { borderColor: "#59736f" };
    const {
      shouldShowConfirmParser,
      progressMsg,
      shouldShowLoader,
    } = this.state;
    // const {myTags,allTags,jobsList,availableStages, shouldShowConfirmParser, showParserPopup, progressMsg, shouldShowLoader} = this.state;

    /*
    const warnStyle = {
      color: colors.yellow,
      borderColor: colors.yellow
    };
    */
    let {
      resumePdfUrl,
      resumeTxtUrl,
      htmlResumeUrl,
      resumeScrubbedUrl,
      resumeJsonUrl,
    } = this.props.parent.state;
    let totalYearsOfExp = (
      !!CandidateEditController.state.adjustedTotalYearsOfExp
        ? CandidateEditController.state.adjustedTotalYearsOfExp
        : null
    );
    let relevantYearsOfExp = (
      !!CandidateEditController.state.yearsOfExperience
        ? CandidateEditController.state.yearsOfExperience
        : null
    );
    /** µ POSSIBLE FUTURE IMPLEMENTATION (cleanup after 2021-03-31) * /
    const yearOfTotalExp = Core.getKeyValue(
      'years-of-total-experience-detected-by-parser'
    );
    /** */
    const RelevantYearsOfExpWarning = (
      (!!totalYearsOfExp) ? (
        (totalYearsOfExp < relevantYearsOfExp) ? (
          <small className="c-red d-flex">
            WARNING: resume parser detected&nbsp;
            {totalYearsOfExp}&nbsp;
            years of total experience
          </small>
        ) : null
      ) : null
    );
    const resumeUrls = [
      {
        url: resumePdfUrl,
        render: (
          <span
            className="anchor"
            onClick={() => Core.openPopUp(formatURL(resumePdfUrl))}
          >
            PDF
          </span>
        ),
      },
      {
        url: htmlResumeUrl,
        render: (
          <span
            className="anchor"
            onClick={() => Core.openPopUp(formatURL(htmlResumeUrl))}
          >
            HTML
          </span>
        ),
      },
      {
        url: resumeTxtUrl,
        render: (
          <ResumeMatch
            candidateResume={resumeTxtUrl}
            element={(cb) => {
              return (
                <span className="anchor" onClick={ev => cb()}>
                  TXT
                </span>
              );
            }}
          />
        ),
      },
      {
        url: resumeScrubbedUrl,
        render: (
          <ResumeMatch
            candidateResume={resumeScrubbedUrl}
            element={(cb) => {
              return (
                <span
                  className="anchor"
                  onClick={(ev) => {
                    cb();
                  }}
                >
                  Scrubbed TXT
                </span>
              );
            }}
          />
        ),
      },
      {
        url: resumeJsonUrl,
        render: (
          <span
            className="anchor"
            onClick={() => Core.openPopUp(formatURL(resumeJsonUrl))}
          >
            JSON
          </span>
        ),
      },
    ].filter((obj) => !!obj.url);

    // console.debug('µ:Candidate::Basics', { state });

    return (
      <List className="form">
        {shouldShowLoader && <LoaderInPopup text={<p>{progressMsg}</p>} />}

        <ListItem
          className="form-header"
          primaryText="BASICS"
          secondaryText={
            <Fragment>
              <div>
                IMPORTANT - need the information to check if the candidate has
                been submitted by another recruiter.
              </div>
            </Fragment>
          }
          // leftIcon={<i className="material-icons">business</i>}
          disabled={true}
        />

        <Row>
          <Col>
            <label>
              Resume&nbsp;<b style={{ color: "orange" }}>*</b>
            </label>
            {/*{this.state.showParserPopup &&*/}
            {/*<ParserPopup cbOpenPdf={this.props.cbOpenPdf} model={this.state.parsedResumeModel} parent={this} target={parent}*/}
            {/*             open={!!this.state.parsedResumeModel} cbOnClose={()=>{this.setState({showParserPopup:false})}}/>*/}
            {/*}*/}
            {Core.isAdminOrCoordinator() && (
              <div style={{ display: "flex" }}>
                {resumeUrls.map((obj) => {
                  return <Fragment>{obj.render}&nbsp;&nbsp;</Fragment>;
                })}
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <DragAndDropZone
              accept="application/pdf, application/msword, .pdf, .doc, .docx, .txt"
              placeholder={
                <Fragment>
                  Drag Resume to Upload
                  <br />
                  (PDF, Word, Text)
                  <br />
                  <br />
                  or <button>Choose file</button>
                  {Core.isPath("candidate/create") && 1 === 0 && (
                    <span className="cgray f-small">
                      <br />
                      <br />
                      Parser will extract resume's info to pre-fill some fields
                      <br />
                      (beta version)
                    </span>
                  )}
                </Fragment>
              }
              value={CandidateEditController.state.resumes}
              maxSize={4000000}
              onChange={(ev, resume, err) => {
                const extension = String(resume.name).split(".").pop();

                if (err) {
                  if (Object(resume).size > 4000000) {
                    CandidateEditController.setStateStore({
                      snackbar: {
                        msg: "File size exceeded",
                        type: "alert",
                      },
                    });
                  } else {
                    CandidateEditController.setStateStore({
                      snackbar: {
                        msg:
                          "File rejected due to unknown error, please contact support",
                        type: "alert",
                      },
                    });
                  }
                }

                if (!/pdf|doc|docx|txt/i.test(extension)) {
                  CandidateEditController.setStateStore({
                    snackbar: {
                      msg:
                        "File rejected as only pdf, doc, docx and txt are supported",
                      type: "alert",
                    },
                  });
                } else if (!err) {
                  this.setState({
                    progressMsg: "File upload is in progress...",
                    shouldShowLoader: true,
                  });

                  let filename = `${String(CandidateEditController.state.firstName)
                    .replace(/ /g, "")
                    .toUpperCase()}_${String(CandidateEditController.state.lastName)
                      .replace(/ /g, "")
                      .toUpperCase()}_${moment().format(
                        "YYYYMMDD_HHmmss"
                      )}.${extension}`;

                  Core.uploadFile(
                    CandidateEditController.state.id,
                    resume,
                    filename,
                    (url) => {
                      let currentResumeTimestamp = new Date().getTime();

                      CandidateEditController.setStateStore(
                        {
                          resumes: [
                            {
                              url,
                              createdAt: new Date().toISOString(),
                              timestamp: currentResumeTimestamp,
                            },
                            ...CandidateEditController.state.resumes,
                          ],
                          key: "resumes",
                        },
                        () => {
                          this.setState(
                            {
                              currentResumeTimestamp,
                              currentUploadedResume: resume,
                            },
                            () => {
                              if (CandidateEditController.state.id) {
                                // parent.setStateStore({
                                //   snackbar: {msg:"File uploaded", type: 'success'}
                                // });
                                const runOnOneCandidate = (candidate) => {
                                  // let resumes = candidate.resumes;
                                  // let firstCv = !!resumes.length ? resumes[0]:null;
                                  this.setState({
                                    shouldShowConfirmParser: true,
                                  });
                                  Candidate.update(
                                    CandidateEditController.state.id,
                                    {
                                      resumes: CandidateEditController.state.resumes,
                                      key: "resumes",
                                    },
                                    (result) => {
                                      Core.log("RESULT", result);
                                      CandidateEditController.beforeStateUpdateActions();
                                    }
                                  );
                                  // if(parent.state.resumes.length > 1
                                  //   || parent.state.dirtyAttrs.filter(attr=>!!attr && attr !== 'resumes').length ){
                                  //   this.setState({shouldShowConfirmParser:true});
                                  // }else{
                                  //   this.runSovrenParser();
                                  // }

                                  // Candidate.getPdfUrl({url:firstCv.url, filename:`id_${candidate.id}`, oldFilename: parent.state.anonFilename},(res) => {
                                  //   Candidate.update(
                                  //     parent.state.id,
                                  //     {
                                  //       resumes: parent.state.resumes,
                                  //       resumePdfUrl:res.url,
                                  //       resumeTxtUrl: res.urlTxt
                                  //     },
                                  //     result => {
                                  //       Core.log("RESULT", result);
                                  //       parent.setStateStore(
                                  //         {
                                  //           resumePdfUrl: res.url,
                                  //           resumeTxtUrl: res.urlTxt,
                                  //           key:'resumes',
                                  //
                                  //         },
                                  //         then => {
                                  //           this.setState({rerender:true});
                                  //           parent.beforeStateUpdateActions();
                                  //         }
                                  //       );
                                  //     }
                                  //   );
                                  //
                                  //
                                  //
                                  // })
                                };
                                runOnOneCandidate(CandidateEditController.state);
                              } else {
                                // let date = new Date();
                                // let filename = `anonumous_${date.getTime()}`;

                                if (
                                  this.props.parent.state.resumes.length > 1 ||
                                  this.props.parent.state.dirtyAttrs.filter(
                                    (attr) => !!attr && attr !== "resumes"
                                  ).length
                                ) {
                                  this.setState({
                                    shouldShowConfirmParser: true,
                                  });
                                } else {
                                  this.runSovrenParser(url, filename);
                                }

                                // Candidate.getPdfUrl({url,filename},(res)=>{
                                //   parent.setStateStore(
                                //     state => {
                                //       state.resumePdfUrl = res.url;
                                //       state.resumeTxtUrl = res.urlTxt;
                                //       state.anonFilename = filename;
                                //     });
                                //
                                //
                                // })
                              }
                            }
                          );
                        }
                      );
                    },
                    (failure) =>
                      CandidateEditController.setStateStore({
                        snackbar: {
                          msg: failure,
                          type: "alert",
                        },
                      })
                  );
                }
              }}
              fullWidth
            />
          </Col>
          <Col>
            {!!CandidateEditController.state.resumes.length && (
              <ResumeLinks
                resumes={CandidateEditController.state.resumes}
                hasNewEntry={this.state.sovrenPushedToMongo}
                onError={(error) => {
                  CandidateEditController.setStateStore({
                    snackbar: error,
                  });
                }}
                onDelete={(ev, resume, index) => {
                  this.confirmDialog.open(
                    <Fragment>
                      Do you want to delete?
                      <br />
                      <br />
                      {decodeURIComponent(String(resume.url).split("/").pop())}
                    </Fragment>,
                    (ev) => {
                      const arr = String(resume.url).split("/");
                      const filename = arr.pop();
                      const container = arr.pop() && arr.pop();
                      CandidateEditController.setStateStore({
                        resumes: CandidateEditController.state.resumes.filter(
                          (r, i) => i !== index
                        ),
                        key: "resumes",
                      });
                      if (CandidateEditController.state.id) {
                        setTimeout((st) =>
                          Candidate.update(
                            CandidateEditController.state.id,
                            { resumes: CandidateEditController.state.resumes },
                            (result) => {
                              Core.log(result);
                              CandidateEditController.beforeStateUpdateActions();
                            }
                          )
                        );
                      }
                      Core.deleteFile(container, filename, (result) =>
                        Core.log(result)
                      );

                      SovrenData.destroyByTimestamp(
                        resume.timestamp,
                        (sovrenObj) => {
                          const pdfUrl = CandidateEditController.state.resumePdfUrl;
                          const regex = new RegExp(sovrenObj.id, "i");

                          if (regex.test(pdfUrl)) {
                            CandidateEditController.setStateStore(
                              (state) => {
                                state.resumePdfUrl = "";
                                state.resumeTxtUrl = "";
                                state.htmlResumeUrl = "";
                                state.resumeScrubbedUrl = "";
                                state.resumeJsonUrl = "";
                                return state;
                              },
                              () => {
                                if (CandidateEditController.state.id) {
                                  Candidate.update(CandidateEditController.state.id, {
                                    resumePdfUrl: "",
                                    resumeTxtUrl: "",
                                    htmlResumeUrl: "",
                                    resumeScrubbedUrl: "",
                                    resumeJsonUrl: "",
                                  });
                                }
                              }
                            );
                          }
                        }
                      );
                    }
                  );
                }}
                onReparse={(timestamp) => {
                  this.setState({ sovrenPushedToMongo: false }, () => {
                    this.setState({
                      shouldShowConfirmParser: true,
                      shouldShowConfirmParserCbOnCancel: this.reparseSovrenResumeForFiles(
                        timestamp
                      ),
                      shouldShowConfirmParserCb: this.reparseSovrenResume(
                        timestamp
                      ),
                    });
                  });
                }}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <label>
              First name <b className="cred">*</b>
            </label>
            <TextField
              name="firstName"
              underlineFocusStyle={lineStyle}
              value={CandidateEditController.state.firstName}
              onChange={(ev, firstName) =>
                CandidateEditController.setStateStore({
                  firstName: firstName.replace(/\s{2,}/, " "),
                  key: "firstName",
                  errorFirstName: "",
                  errorLastName: "",
                })
              }
              onBlur={(ev) => {
                !!CandidateEditController.state.firstName &&
                  CandidateEditController.setState({ duplicateTriggerredBy: "name" }, () => {
                    CandidateEditController.checkPotentialDuplicatedCandidates((em) => { });
                  });
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  CandidateEditController.setState({ duplicateTriggerredBy: "name" }, () => { });
                }
              }}
              required
              fullWidth
              errorText={CandidateEditController.state.errorFirstName}
            />
          </Col>
          <Col>
            <label>
              Last name <b className="cred">*</b>
            </label>
            <TextField
              name="lastName"
              underlineFocusStyle={lineStyle}
              value={CandidateEditController.state.lastName}
              onChange={(ev, lastName) =>
                CandidateEditController.setStateStore({
                  lastName: lastName.replace(/\s{2,}/, " "),
                  key: "lastName",
                  errorFirstName: "",
                  errorLastName: "",
                })
              }
              onBlur={(ev) => {
                !!CandidateEditController.state.lastName &&
                  CandidateEditController.setState({ duplicateTriggerredBy: "name" }, () => {
                    CandidateEditController.checkPotentialDuplicatedCandidates((em) => { });
                  });
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  CandidateEditController.setState({ duplicateTriggerredBy: "name" }, () => { });
                }
              }}
              required
              fullWidth
              errorText={CandidateEditController.state.errorLastName}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Preferred name</label>
            <TextField
              name="nickName"
              underlineFocusStyle={lineStyle}
              value={CandidateEditController.state.nickName}
              onChange={(ev, nickName) =>
                CandidateEditController.setStateStore({ nickName, key: "nickName" })
              }
              fullWidth
            />
          </Col>
          <Col>
            <label>Where else are they interviewing?</label>
            <TextField
              name="otherCompanyInterviews"
              rows={1}
              rowsMax={5}
              multiLine={true}
              value={CandidateEditController.state.otherCompanyInterviews}
              onChange={(ev, otherCompanyInterviews) =>
                CandidateEditController.setStateStore({
                  otherCompanyInterviews,
                  key: "otherCompanyInterviews",
                })
              }
              underlineFocusStyle={lineStyle}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>
              Email <b className="cred">*</b>
            </label>
            <TextField
              name="email"
              underlineFocusStyle={lineStyle}
              type="email"
              value={CandidateEditController.state.email}
              onChange={(ev, email) =>
                CandidateEditController.setStateStore({ email, errorEmail: "", key: "email" })
              }
              onBlur={(ev) => {
                !!CandidateEditController.state.email &&
                  CandidateEditController.setState({ duplicateTriggerredBy: "email" }, () => {
                    CandidateEditController.checkPotentialDuplicatedCandidates((em) => { });
                  });
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  CandidateEditController.setState({ duplicateTriggerredBy: "email" }, () => { });
                }
              }}
              fullWidth
              required
              errorText={CandidateEditController.state.errorEmail}
            />
          </Col>

          <Col style={{ display: "inline-grid" }}>
            <label>
              Phone <b style={{ color: "orange" }}>*</b>
            </label>
            <MuiPhoneNumber
              name="phone"
              disableAreaCodes
              defaultCountry="us"
              value={CandidateEditController.state.phone}
              onChange={(phone) => {
                CandidateEditController.setStateStore({
                  phone: phone,
                  errorPhone: "",
                  key: "phone",
                });
              }}
              onBlur={(phone, countryData) => {
                !!CandidateEditController.state.phone &&
                  CandidateEditController.setState({ duplicateTriggerredBy: "phone" }, () => {
                    CandidateEditController.checkPotentialDuplicatedCandidates((em) => { });
                  });
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  CandidateEditController.setState({ duplicateTriggerredBy: "phone" }, () => { });
                }
              }}
              fullWidth
              error={!!CandidateEditController.state.errorPhone}
              helperText={CandidateEditController.state.errorPhone}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex mt-2">
              <label className="mr-2">
                LinkedIn&nbsp;<b style={{ color: "orange" }}>*</b>
              </label>
              {!!CandidateEditController.state.linkedInURL && (
                <i
                  className="material-icons pointer mr-1"
                  style={{ fontSize: 18 }}
                  onClick={(ev) => window.open(CandidateEditController.state.linkedInURL)}
                >
                  open_in_new
                </i>
              )}
              {!!CandidateEditController.state.linkedInURL && Core.isAdmin() && (
                <i
                  className="material-icons pointer"
                  style={{ fontSize: 18 }}
                  onClick={this.parseLinkedIn}
                >
                  swap_vert
                </i>
              )}
            </div>
            <TextField
              name="linkedInURL"
              value={CandidateEditController.state.linkedInURL}
              onChange={(ev, linkedInURL) =>
                CandidateEditController.setStateStore({
                  linkedInURL: formatURL(linkedInURL),
                  key: "linkedInURL",
                  errorLinkedIn: "",
                })
              }
              onBlur={(ev) => {
                !!CandidateEditController.state.linkedInURL &&
                  CandidateEditController.setState(
                    { duplicateTriggerredBy: "linkedInURL" },
                    () => {
                      CandidateEditController.checkPotentialDuplicatedCandidates((em) => { });
                    }
                  );
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  CandidateEditController.setState(
                    { duplicateTriggerredBy: "linkedInURL" },
                    () => { }
                  );
                }
              }}
              fullWidth
              underlineFocusStyle={lineStyle}
              errorText={CandidateEditController.state.errorLinkedIn}
            />
          </Col>
          <Col>
            <div className="d-flex mt-2">
              <label className="mr-1">GitHub</label>
              {!!CandidateEditController.state.gitHubURL && (
                <i
                  className="material-icons pointer"
                  style={{ fontSize: 18 }}
                  onClick={(ev) => window.open(CandidateEditController.state.gitHubURL)}
                >
                  open_in_new
                </i>
              )}
            </div>
            <TextField
              name="gitHubURL"
              value={CandidateEditController.state.gitHubURL}
              onChange={(ev, gitHubURL) =>
                CandidateEditController.setStateStore({
                  gitHubURL: formatURL(gitHubURL),
                  key: "gitHubURL",
                  errorGitHub: "",
                })
              }
              onBlur={(ev) => {
                !!CandidateEditController.state.gitHubURL &&
                  CandidateEditController.setState(
                    { duplicateTriggerredBy: "gitHubURL" },
                    () => {
                      CandidateEditController.checkPotentialDuplicatedCandidates((em) => { });
                    }
                  );
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  CandidateEditController.setState(
                    { duplicateTriggerredBy: "gitHubURL" },
                    () => { }
                  );
                }
              }}
              fullWidth
              underlineFocusStyle={lineStyle}
              errorText={CandidateEditController.state.errorGitHub}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="inline-blocks">
              Stack Overflow&nbsp;
              {!!CandidateEditController.state.stackoverflowUrl && (
                <i
                  className="material-icons pointer"
                  style={{ fontSize: 18 }}
                  onClick={(ev) => window.open(CandidateEditController.state.stackoverflowUrl)}
                >
                  open_in_new
                </i>
              )}
            </label>
            <TextField
              name="stackoverflowUrl"
              value={CandidateEditController.state.stackoverflowUrl}
              onChange={(ev, stackoverflowUrl) =>
                CandidateEditController.setStateStore({
                  stackoverflowUrl: formatURL(stackoverflowUrl),
                  key: "stackoverflowUrl",
                  errorStackoverflowUrl: "",
                })
              }
              onBlur={(ev) => {
                !!CandidateEditController.state.stackoverflowUrl &&
                  CandidateEditController.setState(
                    { duplicateTriggerredBy: "stackoverflowUrl" },
                    () => {
                      CandidateEditController.checkPotentialDuplicatedCandidates((em) => { });
                    }
                  );
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  CandidateEditController.setState(
                    { duplicateTriggerredBy: "stackoverflowUrl" },
                    () => { }
                  );
                }
              }}
              fullWidth
              underlineFocusStyle={lineStyle}
              errorText={CandidateEditController.state.errorStackoverflowUrl}
            />
          </Col>
          <Col>
            <label>Additional links</label>
            <TextField
              name="otherLinks"
              underlineFocusStyle={lineStyle}
              rows={1}
              rowsMax={5}
              multiLine={true}
              value={CandidateEditController.state.otherLinks}
              onChange={(ev, otherLinks) => {
                otherLinks = otherLinks
                  .split("\n")
                  .map((url) => formatURL(url))
                  .join("\n");
                CandidateEditController.setStateStore({ otherLinks, key: "otherLinks" });
              }}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Most recent company</label>
            <TenAutoSuggest
              value={CandidateEditController.state.currentEmployer || ""}
              onChange={(ev, currentEmployer) =>
                CandidateEditController.setStateStore({
                  currentEmployer,
                  key: "currentEmployer",
                })
              }
              handleSuggestionsFetchRequested={(value, callback) => {
                this.getCompanyInfo(value, (response) => {
                  callback(response.map((school) => ({ label: school.name })));
                });
              }}
            />
          </Col>

          <Col>
            <label>Most recent job title</label>
            <TextField
              name="currentTitle"
              underlineFocusStyle={lineStyle}
              rows={1}
              rowsMax={5}
              multiLine={true}
              value={CandidateEditController.state.currentTitle}
              onChange={(ev, currentTitle) =>
                CandidateEditController.setStateStore({ currentTitle, key: "currentTitle" })
              }
              fullWidth
            />
          </Col>
          {/** µ DISCARDED? (future cleanup)? (ask to BB) */}
          {/*<Col>*/}
          {/*  <label>Is Currently Employed?</label>*/}
          {/*  <SelectField*/}
          {/*    name="currentlyEmployed"*/}
          {/*    fullWidth*/}
          {/*    value={parent.state.currentlyEmployed}*/}
          {/*    onChange={(ev, i, currentlyEmployed) => {*/}
          {/*      parent.setStateStore({ currentlyEmployed, key: 'currentlyEmployed' });*/}
          {/*      if(parseInt(currentlyEmployed) === 1){*/}
          {/*        parent.setStateStore({ currentEmployerTo: 'current', key: 'currentEmployerTo' })*/}
          {/*      }*/}
          {/*    }*/}
          {/*    }*/}
          {/*  >*/}
          {/*    {Definition.get("diversity").map(state => {*/}
          {/*      return (*/}
          {/*        <MenuItem*/}
          {/*          key={state.id}*/}
          {/*          value={state.id}*/}
          {/*          primaryText={state.label}*/}
          {/*        />*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </SelectField>*/}
          {/*</Col>*/}
        </Row>
        <Row>
          <Col>
            <label>Currently working here?</label>
            <SelectField
              name="currentlyEmployed"
              value={CandidateEditController.state.currentlyEmployed}
              onChange={(ev, i, currentlyEmployed) => {
                CandidateEditController.setStateStore({
                  currentlyEmployed,
                  key: "currentlyEmployed",
                });
                if (parseInt(currentlyEmployed) === 1) {
                  CandidateEditController.setStateStore({
                    currentEmployerTo: "current",
                    key: "currentEmployerTo",
                  });
                }
              }}
            >
              {Definition.get("diversity").map((state) => {
                return (
                  <MenuItem
                    key={state.id}
                    value={state.id}
                    primaryText={state.label}
                  />
                );
              })}
            </SelectField>
          </Col>
          <Col>
            <label>Start date</label>
            <YearsDropdown
              key={"currentEmployerFrom"}
              month={
                (CandidateEditController.state.currentEmployerFrom || "").trim().split(" ")
                  .length > 1
                  ? (CandidateEditController.state.currentEmployerFrom || "")
                    .trim()
                    .split(" ")[0]
                  : 0
              }
              year={
                (CandidateEditController.state.currentEmployerFrom || "").trim().split(" ")
                  .length > 1
                  ? (CandidateEditController.state.currentEmployerFrom || "")
                    .trim()
                    .split(" ")[1]
                  : (CandidateEditController.state.currentEmployerFrom || "")
                    .trim()
                    .split(" ")[0]
              }
              onChangeHandler={(month, year, monthString) => {
                CandidateEditController.setStateStore({
                  currentEmployerFrom: `${monthString || ""} ${year || ""}`,
                  key: "currentEmployerFrom",
                });
              }}
            />
          </Col>
          <Col>
            <label>End date</label>
            {parseInt(CandidateEditController.state.currentlyEmployed) === 1 ? (
              <TextField
                name={"currentlyEmployed"}
                value={"current"}
                onChange={(value) => {
                  // parent.setStateStore({ currentEmployerTo: value, key: 'currentEmployerTo' })
                }}
                fullWidth
                rowsMax={5}
                errorText={""}
                disabled
              />
            ) : (
              <YearsDropdown
                month={
                  (CandidateEditController.state.currentEmployerTo || "").trim().split(" ")
                    .length > 1
                    ? (CandidateEditController.state.currentEmployerTo || "")
                      .trim()
                      .split(" ")[0]
                    : 0
                }
                key={"currentEmployerTo"}
                year={
                  (CandidateEditController.state.currentEmployerTo || "").trim().split(" ")
                    .length > 1
                    ? (CandidateEditController.state.currentEmployerTo || "")
                      .trim()
                      .split(" ")[1]
                    : (CandidateEditController.state.currentEmployerTo || "")
                      .trim()
                      .split(" ")[0]
                }
                onChangeHandler={(month, year, monthString) => {
                  CandidateEditController.setStateStore({
                    currentEmployerTo: `${monthString || ""} ${year || ""}`,
                    key: "currentEmployerTo",
                  });
                }}
              />
            )}
          </Col>
        </Row>

        <Row style={{ overflow: "visible" }}>
          <Col>
            <label>
              Total years of work experience
              <b style={{ color: "orange" }}>*</b>
              <span>
                {!!CandidateEditController.state.parserTotalMonthsOfExp
                  ? " (parser " +
                  CandidateEditController.state.parserTotalMonthsOfExp +
                  " months)"
                  : null}{" "}
              </span>
            </label>
            <Slider
              name="yearsOfExperience"
              min={0}
              max={40}
              step={0.1}
              value={totalYearsOfExp}
              onChange={(event, adjustedTotalYearsOfExp) => {
                adjustedTotalYearsOfExp =
                  Math.round(adjustedTotalYearsOfExp * 10) / 10;
                CandidateEditController.setStateStore({
                  adjustedTotalYearsOfExp,
                  key: "adjustedTotalYearsOfExp",
                });
              }}
            />
          </Col>
          <Col>
            <div className="µ-fieldset-header">
              <label>
                Relevant years of work experience{" "}
                <b style={{ color: "red" }}>*</b>
              </label>
            </div>
            <small className="c-light">
              Please don't include internships, part time, different roles, and experiences at other countries that are different.
            </small>
            {RelevantYearsOfExpWarning}
            <Slider
              name="yearsOfExperience"
              min={0}
              max={40}
              step={0.1}
              value={relevantYearsOfExp}
              onChange={(event, yearsOfExperience) => {
                yearsOfExperience = Math.round(yearsOfExperience * 10) / 10;
                CandidateEditController.setStateStore({
                  yearsOfExperience,
                  key: "yearsOfExperience",
                  errorRelevantYearsOfExperience: ""
                });
              }}
            />
            <small className="c-red">
              {errorRelevantYearsOfExperience}
            </small>
          </Col>
        </Row>
        <JobHopperFieldset
          candidateController={CandidateEditController}
          basicsController={this}
        />
        <Accordion style={{ display: Core.isAdmin() ? 'block' : 'none' }} defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<Icon>expand_more</Icon>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography ><label>Companies: <strong>({(CandidateEditController.state.employmentHistories || []).length})</strong></label>
              {CandidateEditController.state.averageMonthsPerEmployer && <span style={{ color: 'red' }}>&nbsp;&nbsp;Average {Math.round((CandidateEditController.state.averageMonthsPerEmployer / 12) * 10) / 10} years per company</span>}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid spacing={3} justify="space-between" >
              <InputArray
                source="candidateEdits"
                heading="Add company"
                existing={CandidateEditController.state.employmentHistories.map(el => {
                  el.positiveSignalsTags = CandidateEditController.state.positiveSignals;
                  el.negativeSignalsTags = CandidateEditController.state.negativeSignals;
                  return el;
                })}
                extraInfo={
                  {
                    crossedPositiveSignals: CandidateEditController.state.crossedPositiveSignals || [],
                  }
                }

                elementComp={Experience}
                onMarkAsDefault={(el) => {
                  //autofill old datastructure
                  //currentEmployer,currentTitle,currentEmployerFrom,currentEmployerTo
                  const latestPosition = Object((el.positionHistories || [])[0]);

                  const MONTHS = [
                    '',
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                  ];

                  CandidateEditController.setStateStore({
                    currentEmployerTo: `${MONTHS[latestPosition.endDateMonth] || ''} ${latestPosition.endDateYear || ''}`,
                    currentEmployerFrom: `${MONTHS[latestPosition.startDateMonth] || ''} ${latestPosition.startDateYear || ''}`,
                    currentlyEmployed: latestPosition.currentlyEmployed,
                    currentEmployer: el.employerOrgName,
                    currentTitle: latestPosition.title
                  })
                }
                }
                parentUpdater={(result) => {
                  // create required tags
                  console.log({ result });
                  let positiveSignalTags = Array.from((new Set(result.map(el => el.positiveSignalsTags).flat())));
                  let finalPsArray = Array.from((new Set([...positiveSignalTags].flat())));
                  finalPsArray = finalPsArray.filter(el => !!el);

                  let negativeSignalTags = Array.from((new Set(result.map(el => el.negativeSignalsTags).flat())));
                  let finalNsArray = Array.from((new Set([...CandidateEditController.state.negativeSignals, ...negativeSignalTags].flat())));
                  finalNsArray = finalNsArray.filter(el => !!el);

                  CandidateEditController.setStateStore(
                    {
                      employmentHistories: result,
                      positiveSignals: finalPsArray,
                      negativeSignals: finalNsArray
                    }
                  );
                }}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Row style={{ overflow: "visible" }}>
          <Col>
            <label>School</label>
            <TenAutoSuggest
              value={CandidateEditController.state.undergraduateSchool}
              onChange={(event, undergraduateSchool) =>
                CandidateEditController.setStateStore({
                  undergraduateSchool,
                  key: "undergraduateSchool",
                })
              }
              handleSuggestionsFetchRequested={(value, callback) => {
                this.getSchoolInfo(value, (response) => {
                  callback(
                    response.map((school) => ({ label: school.universityName }))
                  );
                });
              }}
            />
          </Col>
          <Col>
            <label>End date (or expected)</label>
            <YearsDropdown
              key={"currentEmployerFrom"}
              month={
                (CandidateEditController.state.graduationYear || "").split(" ").length > 1
                  ? (CandidateEditController.state.graduationYear || "").split(" ")[0]
                  : 0
              }
              year={
                (CandidateEditController.state.graduationYear || "").split(" ").length > 1
                  ? (CandidateEditController.state.graduationYear || "").split(" ")[1]
                  : (CandidateEditController.state.graduationYear || "").split(" ")[0]
              }
              onChangeHandler={(month, year, monthString) => {
                CandidateEditController.setStateStore({
                  graduationYear: `${monthString} ${year}`,
                  key: "graduationYear",
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>
              Degree &nbsp;
              <span>
                {dig(CandidateEditController.state, "linkedInRaw", "degree") &&
                  `(From linkdedIn: ${dig(
                    CandidateEditController.state,
                    "linkedInRaw",
                    "degree"
                  )})`}
              </span>
            </label>
            <SelectField
              underlineFocusStyle={lineStyle}
              name="undergraduateDegree"
              value={CandidateEditController.state.undergraduateDegree}
              onChange={(ev, i, undergraduateDegree) =>
                CandidateEditController.setStateStore({
                  undergraduateDegree,
                  key: "undergraduateDegree",
                })
              }
              fullWidth
            >
              {Definition.get("undergraduateDegree").map((item) => {
                return (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    primaryText={item.label}
                  />
                );
              })}
            </SelectField>
          </Col>
          <Col>
            <label>Field of study</label>
            <TextField
              name="undergraduateMajor"
              value={CandidateEditController.state.undergraduateMajor}
              onChange={(event, undergraduateMajor) =>
                CandidateEditController.setStateStore({
                  undergraduateMajor,
                  key: "undergraduateMajor",
                })
              }
              underlineFocusStyle={lineStyle}
              fullWidth
            />
          </Col>
        </Row>
        {Core.isAdmin() && (
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<Icon>expand_more</Icon>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <label>
                  Education{" "}
                  <strong>
                    ({(CandidateEditController.state.educationHistories || []).length})
                  </strong>
                </label>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3} justify="space-between">
                <InputArray
                  heading="Add school"
                  existing={CandidateEditController.state.educationHistories.map((el) => {
                    el.positiveSignalsTags = CandidateEditController.state.positiveSignals;
                    el.negativeSignalsTags = CandidateEditController.state.negativeSignals;
                    return el;
                  })}
                  onMarkAsDefault={(el) => {
                    const latestDegree = Object((el.degrees || [])[0]);

                    const MONTHS = [
                      "",
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July",
                      "August",
                      "September",
                      "October",
                      "November",
                      "December",
                    ];

                    let degreeFromDef = {};
                    if (typeof latestDegree.degreeName === "string") {
                      degreeFromDef = matchFromDef(
                        "undergraduateDegree",
                        latestDegree.degreeName,
                        "None"
                      );
                    } else {
                      degreeFromDef = { id: latestDegree.degreeName };
                    }

                    CandidateEditController.setStateStore({
                      undergraduateMajor: latestDegree.degreeMajor,
                      undergraduateSchool: el.schoolName,
                      graduationYear: `${MONTHS[latestDegree.endDateMonth] || ""
                        } ${latestDegree.endDateYear || ""}`,
                      undergraduateDegree: degreeFromDef.id,
                    });
                  }}
                  elementComp={Education}
                  parentUpdater={(result) => {
                    let positiveSignalTags = Array.from((new Set(result.map(el => el.positiveSignalsTags).flat())));
                    let finalPsArray = Array.from((new Set([...positiveSignalTags].flat())));
                    finalPsArray = finalPsArray.filter(el => !!el);

                    let negativeSignalTags = Array.from(
                      new Set(result.map((el) => el.negativeSignalsTags).flat())
                    );
                    let finalNsArray = Array.from(
                      new Set(
                        [
                          ...CandidateEditController.state.negativeSignals,
                          ...negativeSignalTags,
                        ].flat()
                      )
                    );
                    finalNsArray = finalNsArray.filter((el) => !!el);

                    CandidateEditController.setStateStore({
                      educationHistories: result,
                      positiveSignals: finalPsArray,
                      negativeSignals: finalNsArray,
                    });
                  }}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        <Row>
          <Col fullWidth>
            <Chips
              name="roles"
              items={this.state.chipsAvailableRoles}
              isLoading={!!this.state.chipsAvailableRoles.length ? false : true}
              label={
                <label ref={(self) => (this.rolesLabel = self)}>
                  Roles <b style={{ color: "orange" }}>*</b>
                </label>
              }
              sub="Please select all the roles your candidate is interested in applying for"
              values={CandidateEditController.state.roles || []}
              onChange={(roles) => {
                CandidateEditController.setStateStore({ roles, errorRoles: "", key: "roles" });
              }}
            />
            <div className="cred">{CandidateEditController.state.errorRoles}</div>
          </Col>
        </Row>
        {/*<Row>*/}
        {/*<Col>*/}
        {/*<label>Tagging</label>*/}
        {/*</Col>*/}
        {/*</Row>*/}
        {/*<Row>*/}
        {/*<Col fullWidth className={"chips"} style={{marginTop:"3px"}}>*/}
        {/*<TagsInput renderTag={this.defaultRenderTag({myTags,allTags})}*/}
        {/*value={this.state.tags}*/}
        {/*onChange={this.tagOnChange}/>*/}
        {/*</Col>*/}
        {/*</Row>*/}
        <AnswerJobsQuestions
          finalUpdater={(res) =>
            CandidateEditController.setStateStore({
              permittedJobsAnswers: res,
              key: "permittedJobsAnswers",
            })
          }
          handlerNext={(ansStruct) => () => {
            this.setState({ showQuestions: false });

            if (CandidateEditController.state.id) {
              // Candidate.update(
              //   parent.state.id,
              //   {
              //     jobsPermitted:parent.state.jobsPermitted,
              //     permittedJobsAnswers: ansStruct,
              //   },
              //   result => {
              //     Core.log("RESULT", result);
              //       parent.setStateStore(
              //          {
              //              jobsPermitted:parent.state.jobsPermitted,
              //              permittedJobsAnswers: ansStruct,
              //              key:'permittedJobsAnswers'
              //          },
              //          parent.beforeStateUpdateActions
              //   )
              //   }
              // )
            }
            // else{
            //   parent.setStateStore({
            //     snackbar: "Can't save on new candidate from here, as soon as you save candidate, this will also be saved"
            //   });
            // }
          }}
          handlerNextOnCancel={(data) => () => {
            CandidateEditController.setStateStore({ permittedJobsAnswers: data.initial });
            this.setState({ showQuestions: false });
          }}
          updateJobs={this.props.updateJobs}
          nxtBtnText="Apply"
          cancelBtnText="Close"
          alwaysShow={this.state.showQuestionsForced}
          initialState={CandidateEditController.state.permittedJobsAnswers}
          jobs={CandidateEditController.state.jobsPermitted}
          open={this.state.showQuestions}
          close={(el) => {
            this.setState({ showQuestions: false });
          }}
        />
        <CandidateJobChoices
          parentComponent={CandidateEditController}
          parent={CandidateEditController.state}
          handlerQuestionsLink={(e) =>
            this.setState({ showQuestions: true, showQuestionsForced: true })
          }
          onChange={(item) => (result, jobObjects) => {
            let toBeSaved = { key: item.key };
            toBeSaved[item.key] = result;
            CandidateEditController.setStateStore(toBeSaved);
            CandidateEditController.setState({ [`_${item.key}`]: jobObjects });
            CandidateEditController.checkPotentialDuplicatedCandidates((em) => { });
            setTimeout(() => {
              this.setState({
                showQuestions: true,
                showQuestionsForced: false,
              });
            });
          }}
        />

        <Row>
          <Col fullWidth>
            <label>Recruiter's candidate write up</label>
            <RichTextBox
              name="submissionNotes"
              value={CandidateEditController.state.submissionNotes}
              onChange={submissionNotes => {
                CandidateEditController.setStateStore({
                  submissionNotes,
                  key: "submissionNotes",
                });
              }}
            />
          </Col>
        </Row>
        {Core.isAdminOrCoordinator() && (
          <div className="green-block">
            <Row>
              <Col fullWidth>
                <h4>For 10 by 10 use only</h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <label>Diversity</label>
                <SelectField
                  underlineFocusStyle={lineStyle}
                  value={CandidateEditController.state.diversity}
                  onChange={(ev, i, diversity) =>
                    CandidateEditController.setStateStore({ diversity, key: "diversity" })
                  }
                  fullWidth
                >
                  {Definition.get("diversity").map((item) => {
                    return (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        primaryText={item.label}
                      />
                    );
                  })}
                </SelectField>
              </Col>
            </Row>
          </div>
        )}
        <ConfirmParser
          open={shouldShowConfirmParser}
          onCancel={() => {
            this.setState(
              { shouldShowConfirmParser: false, shouldShowLoader: false },
              () => {
                const { shouldShowConfirmParserCbOnCancel } = this.state;
                !!shouldShowConfirmParserCbOnCancel &&
                  shouldShowConfirmParserCbOnCancel();
              }
            );
          }}
          onAccept={() => {
            this.setState({ shouldShowConfirmParser: false });
            !!this.state.shouldShowConfirmParserCb
              ? this.state.shouldShowConfirmParserCb()
              : this.runSovrenParser();
            this.setState({ shouldShowConfirmParserCb: null });
          }}
        />
        <SuccessDialog
          ref={(confirmDialog) => (this.confirmDialog = confirmDialog)}
          modal={true}
          actions={[
            <FlatButton
              label="CANCEL"
              primary={true}
              keyboardFocused={true}
              onClick={(event) => this.confirmDialog.close()}
            />,
            <FlatButton
              label="CONFIRM"
              primary={true}
              keyboardFocused={true}
              onClick={(event) => {
                this.confirmDialog.close();
                this.confirmDialog.onRequestClose();
              }}
            />,
          ]}
        />

        <Dialog
          fullWidth={"xl"}
          maxWidth={"xl"}
          open={this.state.newDegreeFound}
          onClose={() => { }}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            New Degree(s) found
          </DialogTitle>
          <DialogContent>
            <InputArray
              heading="Education"
              existing={CandidateEditController.state.schoolsLinkedIn.filter(
                (el) => !!parseInt(Object(el).degree)
              )}
              elementComp={Education}
              parentUpdater={(result) => {
                if (!!CandidateEditController.state.id) {
                  Candidate.update(
                    CandidateEditController.state.id,
                    {
                      schoolsLinkedIn: result,
                    },
                    (result) => {
                      Core.log("RESULT", result);
                      CandidateEditController.setStateStore(
                        {
                          schoolsLinkedIn: result.schoolsLinkedIn,
                          key: "schoolsLinkedIn",
                          snackbar: "Saved Successfully!",
                        },
                        CandidateEditController.beforeStateUpdateActions
                      );
                    }
                  );
                } else {
                  CandidateEditController.setStateStore({
                    snackbar:
                      "System cannot save from here on New Candidate, please save the candidate and it will be automatically saved",
                  });
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <FlatButton
              onClick={() => {
                this.setState({ newDegreeFound: false });
              }}
              color="primary"
            >
              Close
            </FlatButton>
          </DialogActions>
        </Dialog>
      </List>
    );
  }
}

/* EXPORTS ==================================== */

export default Basics;

/* ============================================ */
