import React, {Component} from "react";
import moment from "moment-timezone";

import {
    Table,
    TableBody, TableHeader, TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from "material-ui";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

class PermittedJobTable extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            openForm: true
        }
    }

    componentDidMount() {
        this.loadData();
    };

    loadData = () => {

    };

    handleClose = () => {
        this.setState({openForm: false});
        !!this.props.onClose && this.props.onClose();
    };

    render() {
        const {openForm} = this.state;
        const {candoPermittedJobs} = this.props;

        return <Dialog fullWidth={'xl'} maxWidth={'xl'}
                       open={openForm} onClose={this.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Permitted Job Details</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHeader selectable={false} displaySelectAll={false}>
                        <TableRow>
                            <TableHeaderColumn>
                                Action
                            </TableHeaderColumn>
                            <TableHeaderColumn>
                                hasOwnership?
                            </TableHeaderColumn>
                            <TableHeaderColumn>
                                Timestamp (24hrs)
                            </TableHeaderColumn>
                            <TableHeaderColumn>
                                OperatedBy
                            </TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                        {candoPermittedJobs.map(job => {
                            let timestamp = job.timestamp;

                            return <TableRow key={`permitted-job-details-manager-${job.id}`}>
                                <TableRowColumn>
                                    {job.action}
                                </TableRowColumn>

                                <TableRowColumn>
                                    {job.hasOwnership}
                                </TableRowColumn>

                                <TableRowColumn>
                                    {!!timestamp ? moment.tz(timestamp, "America/Los_Angeles").format('MM/DD/YYYY HH:mm') : "N/A"} PST
                                </TableRowColumn>

                                <TableRowColumn>
                                    {job.operatedBy} - {Object(job.operatedBye).email}
                                </TableRowColumn>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>

            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    }
}

export default PermittedJobTable;

