/** ============================================ µ
 * @description Jobs [JS]
 *              UI Component
 * @route       /jobs
 * @updatedAt   2021-11-01 Mon
 * ============================================ */

/* IMPORTS ==================================== */

import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import _ from "lodash";
import FlatButton from "material-ui/FlatButton";
import FloatingActionButton from "material-ui/FloatingActionButton";
import Snackbar from "material-ui/Snackbar";
import ContentAdd from "material-ui/svg-icons/content/add";
import dig from "object-dig";
import queryString from "query-string";
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { NavLink } from "react-router-dom";
import Candidate from "../../lib/Candidate";
import Core, { colors } from "../../lib/Core";
import Engagement from "../../lib/Engagement";
import Job from "../../lib/Job";
import SourceListHash from "../../lib/SourceListHash";
import Store from "../../lib/Store";
import copyHTML from "../../lib/tools/copyHtml";
import getJobSourceListApis from "../../lib/tools/getJobSourceListApis";
import onReady from "../../lib/tools/onReady";
import EditJobSourceList from "../Accounts/Admin/EditJobSourceList";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import { configJobs } from "../Home/configJobs";
import ReactTable from "../Home/ReactTable";
import List from "../List/ListNoPaging";
import CustomAutocomplete from "../Shared/CustomAutocomplete";
import JobCard from "./Card/JobCard";
import JobSourceList from "./Services/JobSourceList";

/* METHODS ==================================== */

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Jobs extends Component {
  data;
  constructor() {
    super(...arguments);
    this.name = "Jobs";

    this.state = {
      showAll: false,
      snackBarMessage: "",
      snackBarOpen: false,
      selected: false,
      isSubmittingCandidate: false,
      isSubmittingCandidateChoice: false,
      candidates: [],
      submittingCandidatePicked: null,
      params: queryString.parse(this.props.location.search),
      displayEngagements: Core.isAdminOrCoordinator(),
      hasEngagementsFetched: false,
      allJobs: [],
      recruiterJobIds: [],
      collectedSourceLists: [],
      jobIdHashedWithAccount: {},
    };

    Store.set("path", window.location.href);
    this.reloadData = (ev) => this.loadData();
    this.loadData();
  }

  componentDidMount() {
    this.getRecruiterJob();
  }

  getRecruiterJob = () => {
    if (Core.isRecruiter()) {
      getJobSourceListApis.getJobIdsByRecruiter((recruiterJobIds) =>
        this.setState({
          recruiterJobIds: recruiterJobIds.map((job) => ({
            jobId: job.jobId,
            startDate: job.startDate,
          })),
        })
      );
    }
  };

  setJobIdsHash = () => {
    let hash = {};
    hash = SourceListHash(this.state.collectedSourceLists, "jobId");

    this.setState({ jobIdHashedWithAccount: hash }, () => {
      this.List.setItems(this.state.filteredItems);
    });
  };

  getRecruiterDetailsThroughJobs = () => {
    const { allJobs } = this.state;
    const onlyIds = allJobs.map((job) => job.id);

    if (Core.isAdmin() && !this.state.collectedSourceLists.length) {
      let chunked = _.chunk(onlyIds, 200);
      chunked.forEach((subArray, index) => {
        getJobSourceListApis.getRecruiterDetailByJobIds(
          "jobId",
          subArray,
          (response) => {
            /**
             * "Job source list shows both active and inactive sourcing recruiters.
             * Should only show active (only has a start date)."
             * @see story 2534 task 10
             */
            const filteredActiveSource = [...response].filter(n => !n.endDate);
            this.setState(
              {
                collectedSourceLists: [
                  ...this.state.collectedSourceLists,
                  ...filteredActiveSource,
                ],
              },
              () => {
                this.setJobIdsHash();
              }
            );
          }
        );
      });
    }
  };

  loadData() {
    const { displayEngagements, hasEngagementsFetched } = this.state;
    if (!!hasEngagementsFetched) {
      this.List.setItems(this.state.filteredItems);
      return;
    }

    let opts = { commonQuery: { engagements: displayEngagements } };
    let allJobs = [];

    if (this.props.match.params.id) {
      const where = { id: this.props.match.params.id };

      Job.getWhere(
        where,
        (jobs) => {
          onReady(this, "List").then((em) => {
            allJobs = jobs
              .filter((job) => job.id === this.props.match.params.id)
              .map((job) => {
                job.expanded = true;
                return job;
              });
            this.List.setItems(allJobs);
            this.setState({
              allJobs,
              hasEngagementsFetched: displayEngagements,
            });
          });
        },
        opts
      );
    } else {
      if (Core.isAdminOrCoordinator()) {
        Job.cleanCache();
        this.setState({ loadingWithEngagements: true });
        Job.getAll(
          (jobs) =>
            onReady(this, "FilterControl").then((em) => {
              this.FilterControl.setItems(jobs, /^Active$/);
              this.setState(
                {
                  allJobs: jobs.filter((job) => job.state === 1),
                  loadingWithEngagements: false,
                  hasEngagementsFetched: displayEngagements,
                },
                () => {
                  this.getRecruiterDetailsThroughJobs();
                }
              );
            }),
          {},
          opts
        );
      } else {
        Job.getActives(
          (jobs) =>
            onReady(this, "FilterControl").then((em) => {
              this.FilterControl.setItems(jobs, /^Active$/);
              this.setState(
                { allJobs: jobs, hasEngagementsFetched: displayEngagements },
                () => {
                  this.getRecruiterDetailsThroughJobs();
                }
              );
            }),
          opts
        );
      }
    }
  }

  loadCandidates = (keyword, callback) => {
    setTimeout(() => {
      const ilikeStruct = { like: `.*${keyword}.*`, options: "i" };

      Candidate.getWhere(
        {
          or: [
            { firstName: ilikeStruct },
            { lastName: ilikeStruct },
            { email: ilikeStruct },
            { linkedInURL: ilikeStruct },
            { gitHubURL: ilikeStruct },
          ],
        },
        (candidates) => {
          const formattedCandos = candidates.map((el) => {
            let label = `${el._name} | ${el.email} | ${el.phone}`;
            return { value: el.id, label };
          });
          console.log({ formattedCandos });
          callback(formattedCandos);
        },
        { limit: 50, fields: ["id", "firstName", "lastName", "email", "phone"] }
      );
    });
  };

  showMessage = (msg) => {
    this.setState({
      snackBarMessage: msg,
      snackBarOpen: true,
    });
  };
  hideMessage = () => {
    this.setState({
      snackBarMessage: "",
      snackBarOpen: false,
    });
  };
  bulkCopy(type) {
    const t = this.props.t;
    if (!this.state.selected || this.state.selected.length === 0) {
      Core.showMessage(
        "Please select at least 1 job to copy. The selected jobs’ description will be copied to paste elsewhere"
      );
      return;
    }
    let body = "";
    Core.log({ sel: this.List.state.selected });
    this.List.selected.forEach((state) => {
      const card = this.List.cards[state.id];
      if (card && card.getPreview) {
        body += card.getPreview(type);
        body += "<br/><hr/>";
      }
    });
    Core.log({ length: body.length });
    copyHTML(`
      ${body}
    `)
      .then((em) => {
        Core.log("Copy email command was successful");
        Core.showMessage(
          t('jobs.copyButton.successful')
        );
      })
      .catch((ex) => {
        Core.log("Oops, unable to copy");
        Core.showMessage("Fail copy!");
      });
  }

  dialogClose = (state) => () => {
    this.setState(state);
  };

  optsCandidateDropdown = (inputValue, callback) => {
    this.loadCandidates(inputValue, callback);
  };

  handlerSubmittingCandidatePicked = (selected) => {
    this.setState({ submittingCandidatePicked: selected });
  };

  submittingCandidateEdit = () => {
    this.closeAndResetCandChoice();
    this.setState({ isSubmittingCandidate: true });
  };

  //may be used when clicked in card and need to show data in expanded
  fetchEngagements = (cb) => {
    const { selected } = this.state;
    let jobEngagements = [];
    let count = 0;
    selected.forEach((job) => {
      Engagement.getWhere({ jobId: job.id }, (resp) => {
        jobEngagements.push(resp);
        count++;
        if (count === selected.length) {
          !!cb && cb(jobEngagements);
        }
      });
    });
  };

  // deletingBulkJobs = () => {
  //   const {selected} = this.state;

  //   return <FlatButton
  //             label={`DELETE JOB(s)`}
  //             className="list-add-new"
  //             onClick={ev =>
  //               this.fetchEngagements(engagements => {
  //                 let name = "";
  //                 let i=-1;
  //                 engagements.forEach(eng => {
  //                   i++;
  //                   name += `Delete "${selected[i]._name}"${
  //                           !!eng.length ? ` and ${eng.length} engagement${
  //                                 eng.length === 1 ? "" : "s"
  //                         }?` : ""
  //                   }`;
  //                 });
  //                 this.ConfirmDialog.open({
  //                   message: name,
  //                   onConfirm: ev => {
  //                     selected.forEach(job => {
  //                         Job.delete(job.id, response => {
  //                           this.loadData()
  //                         })
  //                     });
  //                   }
  //                 })

  //             })
  //           }
  //   />
  // }

  recruiterJobs = () => {
    const { recruiterJobIds } = this.state;
    const recruiterOnlyIds = recruiterJobIds.map((job) => job.jobId);
    const { allJobs } = this.state;
    let filteredJobItems = [],
      restoredAddedJobsWithDates = [];

    if (!!allJobs.length) {
      filteredJobItems = allJobs.map((d) => ({ id: d.id, label: d._name }));

      return (
        <EditJobSourceList
          accountJobSourceList={filteredJobItems}
          title={"EDIT MY SOURCING"}
          values={recruiterOnlyIds}
          addButtonClass={"list-add-new chip-button m-0 mb-1 mr-1"}
          labelColor={"#ffffff"}
          heading="Add or delete job to source"
          onChange={(jobs, jobObj) => {
            JobSourceList.process(recruiterOnlyIds, jobs);
            JobSourceList.sendEmailManager(
              recruiterOnlyIds,
              jobs,
              filteredJobItems,
              recruiterJobIds
            );
            restoredAddedJobsWithDates = JobSourceList.restoreAddedJobsWithDates(
              recruiterJobIds,
              jobs
            );
            this.setState(
              { recruiterJobIds: restoredAddedJobsWithDates },
              () => {
                this.List.setItems(this.state.allJobs);
              }
            );
          }}
        />
      );
    }

    return null;
  };

  dialogCreateCandChoice = () => {
    const isShowingCandDropdown =
      this.state.submittingCandidateChoice === "ex" &&
      this.state.candidates.length >= 0;
    const isCandSelected = !!this.state.submittingCandidatePicked;

    return (
      <Dialog
        open={this.state.isSubmittingCandidateChoice}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContent id="alert-dialog-slide-description">
            {!isShowingCandDropdown && (
              <span>
                <FlatButton
                  label={`Create New Candidate?`}
                  className="list-add-new"
                  onClick={this.handlerSubmitCandidateOpt("new")}
                />
                <span style={{ textAlign: "center", display: "block" }}>
                  {" "}
                  OR{" "}
                </span>
                <FlatButton
                  label={`Pick from Existing Ones?`}
                  className="list-add-new"
                  onClick={this.handlerSubmitCandidateOpt("ex")}
                />
              </span>
            )}

            {isShowingCandDropdown ? (
              <div>
                <h3 style={{ width: "700px", display: "block" }}>
                  Enter Existing Candidate Name or Email
                </h3>
                <div style={{ height: 500 }}>
                  <CustomAutocomplete
                    defaultMenuIsOpen
                    selected={this.state.submittingCandidatePicked}
                    handleChange={this.handlerSubmittingCandidatePicked}
                    placeholder={"write to search"}
                    opts={this.optsCandidateDropdown}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <FlatButton
            label={`Cancel`}
            className="list-add-new"
            onClick={this.closeAndResetCandChoice}
          />
          {isShowingCandDropdown && isCandSelected && (
            <FlatButton
              label={`SUBMIT CANDIDATE`}
              className="list-add-new"
              onClick={this.submittingCandidateEdit}
            />
          )}
        </DialogActions>
      </Dialog>
    );
  };

  closeAndResetCandChoice = () => {
    this.dialogClose({
      isSubmittingCandidateChoice: false,
      submittingCandidateChoice: null,
      isSubmittingCandidate: false,
    })();
  };

  dialogSubmitCandidate = () => {
    if (!this.state.isSubmittingCandidate) {
      return;
    }
    let defaultValues = {};
    let permittedJobs = this.state.selected
      ? this.state.selected.map((o) => o.id)
      : [];
    let roles = this.state.selected
      ? this.state.selected.map((o) => o.role)
      : [];

    if (permittedJobs.length) {
      defaultValues["jobsPermitted"] = permittedJobs;
    }

    if (roles.length) {
      let set = new Set(roles);
      defaultValues["roles"] = Array.from(set);
    }

    let candidateId = dig(this.state, "submittingCandidatePicked", "value");
    let path = "";
    let queryString =
      "source=jobs&defaultRoles=" +
      roles.join(",") +
      "&" +
      "defaultJobs=" +
      permittedJobs.join(",");

    if (candidateId) {
      path = `/candidate/edit/${candidateId}?${queryString}`;
    } else {
      path = `/candidate/create?${queryString}`;
    }

    return <Redirect to={path} push />;
    // return <Dialog
    //     key={'jeeto'}
    //     fullScreen
    //     open={this.state.isSubmittingCandidate}
    //     onClose={this.dialogClose({isSubmittingCandidate:false})}
    //     scroll={"paper"}
    //     TransitionComponent={Transition}
    // >
    //     <DialogContent>
    //         <CandidateEdit
    //             candidateId={!!this.state.submittingCandidatePicked ? this.state.submittingCandidatePicked.value:null}
    //             backAction={this.dialogClose({
    //                 isSubmittingCandidate:false,
    //                 isSubmittingCandidateChoice:false,
    //                 submittingCandidatePicked:{}
    //               }
    //             )}
    //             source={"jobsPage"}
    //             defaultValues={defaultValues}
    //             selectedJobs={this.state.selected}/>
    //     </DialogContent>
    // </Dialog>
  };

  handlerSubmitCandidate = () => {
    if (this.state.selected.length > 0) {
      this.setState({ isSubmittingCandidateChoice: true });
    } else {
      this.setState({ isSubmittingCandidate: true });
    }
  };

  handlerSubmitCandidateOpt = (val) => () => {
    this.setState({
      submittingCandidateChoice: val,
      isSubmittingCandidate: val === "new",
    });
  };

  handleClickBulkCopy = (event) => {
    this.setState({ bulkCopyAnchorEl: event.currentTarget });
  };

  handleCloseBulkCopy = (type) => (ev) => {
    this.setState({ bulkCopyAnchorEl: null });
    !!type && this.bulkCopy(type);
  };

  render() {
    const { t } = this.props;
    const {
      recruiterJobIds,
      jobIdHashedWithAccount,
      displayEngagements,
    } = this.state;
    const {
      List: ListController = {}
    } = this;
    const {
      selected: selectedJobs = []
    } = ListController;
    const isCopyJobsDisabled = !selectedJobs.length;
    console.debug('JobsV3', this.state);
    return (
      <>


        <div style={{ height: 'auto' }}>
          <ReactTable
            {...this.props} // to pass the router context
            config={configJobs}
            onChange={data => {
              this.setState(
                {
                  allJobs: data
                },
                state => {
                  this.List.setItems(data);
                }
              );
            }}
            disableBodyRender
          />
        </div>

        <div className="d-flex flex-align-right-center pt-1 px-3">
          {
            /** * /
            Core.isAdmin() && (
              <span>
                <Checkbox
                  label={
                    this.state.loadingWithEngagements
                      ? "Engagements Stats - loading..."
                      : "Engagements Stats"
                  }
                  className="show-all"
                  style={{ width: 250 }}
                  checked={this.state.displayEngagements}
                  onCheck={(ev, displayEngagements) => {
                    this.setState(
                      {
                        displayEngagements,
                        //allJobs: jobs.filter((job) => job.state === 1),
                        //loadingWithEngagements: false,
                        //hasEngagementsFetched: displayEngagements,
                      },
                      () => {
                        //this.getRecruiterDetailsThroughJobs();

                      }
                    );
                    // this.setState({ displayEngagements }, () => { this.loadData(); });
                  }}
                />
              </span>
              )
              /** */
          }
          {Core.isRecruiter() && this.recruiterJobs()}
          <FlatButton
            label={
              !!this.state.selected.length
                ? `Submit Candidate to ( ${this.state.selected.length} ) Jobs`
                : "Submit new Candidate"
            }
            className="list-add-new mb-1 mr-1"
            onClick={this.handlerSubmitCandidate}
          />
          <div
            className="hint--left hint--rounded"
            aria-label={t('jobs.copyButton.ariaLabel.label')} l
          >
            <FlatButton
              label={`Copy Job(s)`}
              className="list-add-new mb-1 mr-1"
              disabled={isCopyJobsDisabled}
              onClick={this.handleClickBulkCopy}
            />
          </div>
          <MuiMenu
            id="simple-menu"
            anchorEl={this.state.bulkCopyAnchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            keepMounted
            open={Boolean(this.state.bulkCopyAnchorEl)}
            onClose={this.handleCloseBulkCopy()}
          >
            <MuiMenuItem onClick={this.handleCloseBulkCopy("large")}>
              Long JD
            </MuiMenuItem>
            <MuiMenuItem onClick={this.handleCloseBulkCopy("short")}>
              Short JD
            </MuiMenuItem>
          </MuiMenu>
          {Core.isAdmin() && (
            <NavLink exact to="/job/create" className="ui-m-min">
              <FlatButton label="+ New Job" className="list-add-new mb-1 mr-1" />
            </NavLink>
          )}
        </div>

        {this.dialogSubmitCandidate()}
        {/* {!!this.state.selected.length && this.deletingBulkJobs()} */}
        {this.state.selected && this.dialogCreateCandChoice()}

        <List
          className="p-0"
          ref={(List) => (this.List = List)}
          tabs={Job.listTabs}
          tab={Job.listTab}
          disableTabs={true}
          disableFooter
          name="Job"
          card={JobCard}
          params={this.state.params}
          parent={this}
          onCheck={(selected) => {
            // const selected = { ...this.state.selected };
            // if (!checked) {
            //   delete selected[id];
            // } else {
            //   selected[id] = checked;
            // }
            // console.log({selected});
            this.setState({ selected });
          }}
          floatingButton={
            Core.isAdmin() && (
              <NavLink className="ui-m-max" exact to={`/job/create`}>
                <FloatingActionButton
                  className="list-floating-button"
                  backgroundColor={colors.cyan}
                >
                  <ContentAdd />
                </FloatingActionButton>
              </NavLink>
            )
          }
          extraInfo={{
            recruiterJobIds,
            jobIdHashedWithAccount,
            displayEngagements,
          }}
        />
        <Snackbar
          open={this.state.snackBarOpen}
          message={this.state.snackBarMessage}
          className="snack-bar"
          autoHideDuration={4000}
          onRequestClose={this.hideMessage}
        />
        <ConfirmDialog
          ref={(self) => (this.ConfirmDialog = self)}
          title="Delete Job(s)?"
          message="This action can't be undone."
          actionLabel="Delete"
        />
      </>
    );
  }
}

/* EXPORTS ==================================== */

export default withTranslation()(Jobs);

/* ============================================ */
