import copy from "copy-to-clipboard";
import { Card, CardText } from "material-ui/Card";
import Checkbox from "material-ui/Checkbox";
import Divider from "material-ui/Divider";
import IconButton from "material-ui/IconButton";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import { Table, TableBody, TableRow, TableRowColumn } from "material-ui/Table";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import loader from "../../../assets/images/loader.gif";
import Account from "../../../lib/Account";
import Core, { colors } from "../../../lib/Core";
import Engagement from "../../../lib/Engagement";
import { getAccountModel } from "../../../lib/models/account";
import getEngagementsByState from "../../../lib/tools/getEngagementsByState";
import RenderEngagementStructure from "../../../lib/tools/renderEngagementStructure";
import ModalDelete from "./ModalDelete";

class AccountCard extends Component {
  timeout;
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      ...getAccountModel({ extended: true }),
      /** controller states */
      expanded: false,
      openedColor: null,
      CardStyle: null,
      rightArrow: {},
      /** load */
      ...this.props.model,
      engagementStructure: [],
      engagementStructureLoader: true
    };
  }
  // unexpandCard = event => {
  //   /** * /
  //   this.setState({
  //     expanded: false,
  //     CardStyle: null,
  //     openedColor: null,
  //     rightArrow: { transform: "rotate(0deg)" }
  //   });
  //   /** */
  // };
  // expandCard = event => {
  //   /** * /
  //   this.setState({
  //     expanded: true,
  //     CardStyle: { margin: "10px auto" },
  //     openedColor: { color: "#715EFF" },
  //     rightArrow: { transform: "rotate(180deg)" }
  //   });
  //   /** */
  //   Core.log(this.state);
  // };
  handleToggle = event => {
    this.state.expanded ? this.unexpandCard() : this.expandCard();
  };
  onCheckStar = (ev, checked) => {
    const updateLocalList = response => {
      const state = {
        id: this.state.id,
        starredId: response.id,
        starred: response.starred,
        filters: {
          ...this.state.filters,
          Starred: ["Non Starred", "Starred"][~~Boolean(response.starred)]
        }
      };
      this.setState(state);
      this.parent.updateItem(state);
    };
    Account.updateStarred(
      this.state.id,
      this.state.starredId,
      checked,
      updateLocalList
    );
    this.unexpandCard();
  };
  onChangeAccountNotes = (ev, publicNotes) => {
    this.setState({ publicNotes });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      Core.updateAccount(
        this.state.id,
        { publicNotes },
        null,
        response => true
      );
    }, 1000);
  };
  copyEmail = email => {
    if (
      copy(email, {
        debug: true,
        message: "Press #{key} to copy"
      })
    ) {
      Core.showMessage(`${email} copied!`);
    } else {
      Core.showMessage("Fail copy!");
    }
  };

  renderJobNames = () => {
    const { extraInfo } = this.props;
    const names = !!extraInfo && !!extraInfo.jobIdHashedWithAccount ? extraInfo.jobIdHashedWithAccount : "";

    if (!!names.length) {
      return <label>{names}</label>;
    }
    return null;
  }

  expandCard = event => {
    const { engagementStructure } = this.state;
    if (Core.isAdmin() && !engagementStructure.length) {
      Engagement.getStructureAccount(`?groupBy=job&aid=${this.state.id}`, (response => {
        this.setState({ engagementStructure: response.res, engagementStructureLoader: false });
      }));
    }
    this.setState(
      {
        expanded: true,
        CardStyle: { margin: "10px auto" },
        openedColor: { color: "#715EFF" },
        rightArrow: { transform: "rotate(180deg)" }
      }
    );
  };
  unexpandCard = event => {
    this.setState({
      expanded: false,
      CardStyle: null,
      openedColor: null,
      rightArrow: { transform: "rotate(0deg)" }
    });
  };
  handleExpanded = event => {
    this.state.expanded ? this.unexpandCard() : this.expandCard();
  };
  render() {
    const candidates = this.state.candidates;
    const totalCandidates = candidates.length;
    const jobSourceName = Core.isAdmin() ? this.renderJobNames() : null;

    let engagements = [];
    candidates.map(
      cand => (engagements = engagements.concat(cand.engagements))
    );
    let { render, count } = getEngagementsByState.getEngagementByGroup(engagements);
    return (
      <Card
        className="row-card account"
        expanded={this.state.expanded}
        style={this.state.CardStyle}
      >
        {/** COLLAPSED VIEW */}
        <Table className="collapsed-view" style={{ marginBottom: 10 }}>
          <TableBody displayRowCheckbox={false}>
            <TableRow selectable={false} onClick={this.handleToggle}>
              <TableRowColumn
                title="Account"
                className="first-item"
                style={{ wordWrap: "wrap" }}
              >
                {Core.isAdmin() ? (
                  <NavLink
                    title="Go to Edit"
                    to={"/account/edit/" + this.state.id}
                  >
                    <b
                      style={{
                        color: colors.purple,
                        fontWeight: 500
                      }}
                    >
                      {this.state.firstName || <i>&mdash;</i>}
                      &nbsp;
                      {this.state.lastName || <i>&mdash;</i>}
                    </b>
                  </NavLink>
                ) : (
                  <b
                    style={{
                      fontWeight: 600
                    }}
                  >
                    {this.state.firstName || <i>&mdash;</i>}
                    &nbsp;
                    {this.state.lastName || <i>&mdash;</i>}
                  </b>
                )}
                <br />
                <span title="Role">{this.state.role || <i>&mdash;</i>}</span>
              </TableRowColumn>
              <TableRowColumn
                title="Company"
                className="mid-col"
                onClick={this.handleToggle}
              >
                {this.state.companyName || <i>&mdash;</i>}
              </TableRowColumn>
              <TableRowColumn
                title="Title"
                className="mid-col"
                onClick={this.handleToggle}
              >
                {this.state.title || <i>&mdash;</i>}
              </TableRowColumn>
              <TableRowColumn title="Email" className="mid-col v-align-mid">
                <IconButton
                  title="Copy Account email"
                  iconStyle={this.state.openedColor}
                  className="icon16"
                  onClick={ev => this.copyEmail(this.state.email)}
                >
                  <i className="material-icons">mail</i>
                </IconButton>
                &nbsp;
                <span
                  className="truncate"
                  style={{ width: "calc(100% - 48px)" }}
                >
                  {this.state.email || <i>&mdash;</i>}
                </span>
              </TableRowColumn>
              <TableRowColumn
                title="Phone"
                className="mid-col"
                onClick={this.handleToggle}
              >
                {this.state.phone}
              </TableRowColumn>
              <TableRowColumn title="Options" className="last-col" rowSpan="2">
                <Checkbox
                  title="Click to Starred"
                  className="star"
                  checked={this.state.starred}
                  onCheck={this.onCheckStar}
                  checkedIcon={<i className="material-icons">star</i>}
                  uncheckedIcon={<i className="material-icons">star_border</i>}
                  iconStyle={this.state.openedColor}
                />
                {Core.isAdmin() && (
                  <IconMenu
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    targetOrigin={{ horizontal: "right", vertical: "top" }}
                    style={{ marginLeft: "4px" }}
                    onClick={ev => ev.stopPropagation()}
                    iconButtonElement={
                      <IconButton
                        style={{
                          width: 24,
                          padding: 0
                        }}
                        iconStyle={this.state.openedColor}
                      >
                        <i className="material-icons">more_vert</i>
                      </IconButton>
                    }
                  >
                    {/** * /}
                    <NavLink
                      to={"/account/view/" + this.state.id}
                      className="navlink"
                    >
                      <MenuItem primaryText="View" />
                    </NavLink>
                    {Core.isAdmin() && (
                      <NavLink
                        to={"/account/edit/" + this.state.id}
                        className="navlink"
                      >
                        <MenuItem primaryText="Edit" />
                      </NavLink>
                    )}
                    {/** */}
                    {Core.isAdminOnLocal() && (
                      <MenuItem
                        primaryText="Delete"
                        onClick={ev => this.refs.modalDelete.open()}
                      />
                    )}
                  </IconMenu>
                )}
                <i
                  className="material-icons"
                  style={{
                    width: 24,
                    height: 24,
                    margin: 0,
                    cursor: "pointer",
                    fontWeight: 200,
                    ...this.state.openedColor,
                    ...this.state.rightArrow
                  }}
                  onClick={this.handleExpanded}
                >
                  arrow_drop_down
                </i>
                {/** * /
                  <i
                    className="material-icons"
                    style={{
                      width: 24,
                      height: 24,
                      margin: 0,
                      cursor: "pointer",
                      ...this.state.openedColor,
                      ...this.state.rightArrow
                    }}
                    onClick={this.handleToggle}
                  >
                    arrow_drop_down
                  </i>
                /** */}
              </TableRowColumn>
            </TableRow>
            <TableRow onClick={this.handleToggle}>
              <TableRowColumn
                className="first-col v-align-mid"
                style={{
                  overflow: "none",
                  whiteSpace: "nowrap"
                }}
              >
                <span title="Total of Candidates">
                  {totalCandidates} Candidates | {count} Engagement(s)
                </span>

              </TableRowColumn>

            </TableRow>

            {!!render && (<TableRow>
              <TableRowColumn
                className="first-col v-align-mid"
                style={{
                  overflow: "none",
                  whiteSpace: "nowrap"
                }}
              >
                <div className="cursor-default" onClick={this.expandCard}>
                  {
                    render.map((grp, index) => {
                      return <div key={index} title={`${grp}`}>{grp}</div>
                    })
                  }
                </div>
              </TableRowColumn>
            </TableRow>)}

            {Core.isAdmin() && !!jobSourceName && (<TableRow selectable={false}>
              <TableRowColumn
                title="Source Recruiters"
                style={{ overflow: 'visible !important' }}
              >
                Source&nbsp;:&nbsp;&nbsp;{jobSourceName}
              </TableRowColumn>
            </TableRow>)}

          </TableBody>
        </Table>
        <Divider />
        {/** EXPANDED VIEW */}
        <CardText className="expanded-view" expandable={true}>
          {!!this.state.engagementStructureLoader && <div>
            Loading engagements&nbsp;
            <img alt="loading..." height="14" src={loader} />
          </div>}
          {!this.state.engagementStructureLoader && <div>
            <RenderEngagementStructure
              structure={this.state.engagementStructure}
              stage='stage'
              count='count'
              name='jobTitle'
              title='Job Name'
              makeLink={{
                path: 'job/edit'
              }}
              lastSubmission={getEngagementsByState.getLastSubmissionDate(engagements)}
            />
          </div>
          }
        </CardText>
        <ModalDelete ref="modalDelete" parent={this} />
      </Card>
    );
  }
}

export default AccountCard;
