/** ============================================ µ
 * @description ATS [JS]
 *              Library
 * @route       /candidate/resume-submission/:candidateId/:jobId
 * @createdAt   2021-05-12
 * @updatedAt   2021-05-24
 * ============================================ */
/* IMPORTS ==================================== */

import React from "react";
import Core from "../../Core";
import {
  ATS_TYPE__GH_HARVEST_ID,
  ATS_TYPE__GH_INGESTION_ID,
  EMP_SUB_MET__ATS_ID
} from "../../Definition";
import { tools } from "../../GenericTools.lib";
import Google from "../../Google";
import Greenhouse from "./Greenhouse.lib";

/* METHODS ==================================== */

/**
 * Handler event on click "Submit to ATS" green button
 *
 * @param {string} apiName "harvest" | "ingestion"
 * @returns A promise.
 */
async function sendToATS({
  apiName,
  candidate = {},
  job = {},
  submissionNotes = {},
  emailParams = {},
}) {

  const {
    employer = {},
    atsJobId,
  } = job;

  /** ATS params */
  const {
    atsTypeId,
    atsApiProviders = {},
  } = employer;

  const atsApiProvider = atsApiProviders[atsTypeId] || {};

  const {
    /** common for greenhouse */
    apiKey: atsApiKey,
    /** specific for greenhouse harvest */
    onBehalfOfEmployerUserId: atsUserId,
    sourceIdAgencyId: atsSourceId,
    agencyRecruiterName: atsReferrer,
    /** specific for greenhouse ingestion */
    onBehalfOfEmployerUser,

  } = atsApiProvider;

  const atsApiName = tools.capitalize(apiName);

  const isAtsGhHarvestSelected = (
    atsTypeId === ATS_TYPE__GH_HARVEST_ID
  );

  const isAtsGhIngestionSelected = (
    atsTypeId === ATS_TYPE__GH_INGESTION_ID
  );

  const atsOnBehalfOf = (
    isAtsGhHarvestSelected
      ? atsUserId
      : isAtsGhIngestionSelected
        ? onBehalfOfEmployerUser
        : null
  );

  /** MAKING CALL TO GREENHOUSE */
  return await Greenhouse[apiName].addCandidate({

    /** PARAMS TO SEND */
    candidate,
    atsApiKey,
    atsOnBehalfOf,
    atsSourceId,
    atsUserId,
    atsJobId,
    atsReferrer,
    submissionNotes,

    /** SUCCESS CALLBACK */
    onSuccess: async response => {

      const {
        id: greenhouseAtsApplicationId
      } = response;

      Core.dialog.open({
        title: "Success",
        message: "Added candidate to greenhouse successfully!"
      });

      /** µ FOR DEBUG PURPOSES * /
      console.debug('µ:CandoSubmitResume::onSuccess', response);
      /** */

      /* SEND EMAIL TO ADMIN ============== */

      const emailOptions = {
        ...emailParams,
        to: Core.getResumeSubmissionBcc()
      };

      emailOptions.subject = (
        `Submitted via Greenhouse ${atsApiName} API | ${emailOptions.subject}`
      );

      await Google.sendEmail(emailOptions);

      /* ============== SEND EMAIL TO ADMIN */

      /** MAKE STAGE TRANSITION */
      const CandidateResumeSubmissionController = Core.getKeyValue(
        'CandidateResumeSubmissionController'
      );
      await CandidateResumeSubmissionController.transitionAfterSubmission({
        submissionMethodId: EMP_SUB_MET__ATS_ID,
        greenhouseAtsApplicationId
      });
      /** */

    },

    /** FAILURE CALLBACK */
    onFailure: (failures, suggestions, statusCode) => {

      const dialogTitle = (
        statusCode
          ? `Greenhouse ${atsApiName} API - POST Candidate failed`
          : `Some values are bad formatted`
      );

      Core.dialog.open({
        title: dialogTitle,
        message: (
          <>
            {(
              statusCode
                ? <h4>Error code: {statusCode}</h4>
                : ''
            )}
            <h4>Error messages:</h4>
            <small>
              <b>
                <pre>{failures.join('\n\n')}</pre>
              </b>
            </small>
            <h4>Suggestions for taking action:</h4>
            <small>
              <b>
                <i>
                  <pre>{suggestions.join('\n')}</pre>
                </i>
              </b>
            </small>
          </>
        )
      });

      /* SEND EMAIL TO ADMIN ============== */

      const emailOptions = {
        ...emailParams,
        to: Core.getResumeSubmissionBcc()
      };

      emailOptions.subject = (
        `ERROR | Greenhouse ${atsApiName} API failed | ${emailOptions.subject}`
      );

      /* adding failures to the email body */
      emailOptions.html = emailOptions.html.replace('<html>', '');
      emailOptions.html = emailOptions.html.replace('<head></head>', '');
      emailOptions.html = emailOptions.html.replace('<body>', '');
      emailOptions.html = (
        (`
          <html>
            <head></head>
            <body>
              <p>
                --------------------------------------------------
              </p>
              <p>
                Failures:
              </p>
              <p>
                <ul>
                  <li>
                    ${failures.join('</li><li>')}
                  </li>
                </ul>
              </p>
              <p>
                Suggestions:
              </p>
              <p>
                <ul>
                  <li>
                    ${suggestions.join('</li><li>')}
                  </li>
                </ul>
              </p>
              <p>
                --------------------------------------------------
              </p>
        `).replace(/\s{10}/g, '') +
        emailOptions.html
      );

      Google.sendEmail(emailOptions);

      /* ============== SEND EMAIL TO ADMIN */

    }
  });

}

/**
 * Getting public and private notes
 * from raw submission notes
 *
 * @param {*} param0
 * @returns
 */
function makeSubmissionNotes({
  candidate = {},
  rawSubmissionNotes
}) {

  const submissionNotes = {
    public: [],
    private: []
  };

  rawSubmissionNotes
    .split('\n')
    .map(n => n.split(':').map(n => n.trim()).join(': ').trim())
    .filter(n => {
      if (
        !n ||
        !!n.match(/CANDIDATE SUBMISSION:/i) ||
        !!n.match(/salary/i)
      ) {
        return false;
      }
      if (
        !!n.match(/visa/i) ||
        !!n.match(/work authorization/i)
      ) {
        submissionNotes.private.push(n);
      }
      else {
        submissionNotes.public.push(n);
      }
      return true;
    })
    .join('\n');

  if (candidate.salarySharableFlag) {
    submissionNotes.private.push([
      'Compensation expectation: ',
      candidate._minimumSalary,
      candidate.salaryNote ? ' - ' + candidate.salaryNote : ''
    ].join(''));
  }

  submissionNotes.public = submissionNotes.public.join('\n');
  submissionNotes.private = submissionNotes.private.join('\n');

  /** µ FOR DEBUG PURPOSES * /
  console.debug('µ', {
    rawSubmissionNotes,
    submissionNotes,
  });
  /** */

  return submissionNotes;

}

/* DICTIONARIES =============================== */

const AtsLib = {
  send: sendToATS,
  makeSubmissionNotes,
}

/* EXPORTS ==================================== */

export {
  AtsLib as default,
  AtsLib,
  sendToATS,
  makeSubmissionNotes as makeAtsSubmissionNotes,
};

/* ============================================ */
