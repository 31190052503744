import moment from "moment";
import React from "react";
import Core from "./Core";
import Http from "./Http";


const pipelineKeys = {
  engagements:
    "agxzfm1haWxmb29nYWVyMAsSDE9yZ2FuaXphdGlvbiIJMTBieTEwLmlvDAsSCFdvcmtmbG93GICAgICArpkKDA",
  qatest:
    "agxzfm1haWxmb29nYWVyMAsSDE9yZ2FuaXphdGlvbiIJMTBieTEwLmlvDAsSCFdvcmtmbG93GICAgIDOnJwKDA"
};
const pipelineKey = pipelineKeys[Core.isOnDev() ? "qatest" : "engagements"];
const streakMap = {};
const mdash = "—";

// STAGES
export const STAGE_CONFIRMATION = "Confirmation";
export const STAGE_SUBMISSION = "Submission";
export const STAGE_REVIEW = "Review";
export const STAGE_SCREEN = "Screen";
export const STAGE_ONSITE = "Onsite";
export const STAGE_OFFER = "Offer";
export const STAGE_HIRE = "Hire";
export const STAGE_GUARANTEE = "Guarantee";

// WAITING 10x10 STATUS
export const STATUS_W_10BY10 = "W - 10by10";
export const STATUS_W_10X10 = "W - 10x10";
export const STATUS_W_10X10_ASK_DETAILS = "W - 10x10 Ask Details";
export const STATUS_W_10X10_ASK_PERMISSION = "W - 10x10 Ask Permission";
export const STATUS_W_10X10_INTAKE_CALL = "W - 10x10 Intake Call";
export const STATUS_W_10X10_NOTIFY_REJECTION = "W - 10x10 Notify Rejection";
export const STATUS_W_10X10_REVIEW = "W - 10x10 Review";
export const STATUS_W_10X10_SCHEDULE = "W - 10x10 Schedule";
export const STATUS_W_10X10_SUBMISSION = "W - 10x10 Submission";

// WAITING CANDIDATE STATUS
export const STATUS_W_CANDIDATE = "W - Candidate";
export const STATUS_W_CANDIDATE_AVAILABILITY = "W - Candidate Availability";
export const STATUS_W_CANDIDATE_DETAILS = "W - Candidate Details";
export const STATUS_W_CANDIDATE_HOMEWORK = "W - Candidate Homework";
export const STATUS_W_CANDIDATE_PERMISSION = "W - Candidate Permission";
export const STATUS_W_CANDIDATE_SIGN_OFFER = "W - Candidate Sign Offer";

// WAITING CANDIDATE/EMPLOYER STATUS
export const STATUS_W_CANDO_EMP_AVAILABILITY = "W - Cando-Emp Availability";

// WAITING EMPLOYER STATUS
export const STATUS_W_EMPLOYER = "W - Employer";
export const STATUS_W_EMPLOYER_AVAILABILITY = "W - Employer Availability";
export const STATUS_W_EMPLOYER_DETAILS = "W - Employer Details";
export const STATUS_W_EMPLOYER_FEEDBACK = "W - Employer Feedback";
export const STATUS_W_EMPLOYER_HOMEWORK = "W - Employer Homework";
export const STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK = "W - Employer Homework Feedback";
export const STATUS_W_EMPLOYER_REFERENCE = "W - Employer Reference";
export const STATUS_W_EMPLOYER_SCHEDULE = "W - Employer Schedule";
export const STATUS_W_EMPLOYER_VERBAL_OFFER = "W - Employer Verbal Offer";
export const STATUS_W_EMPLOYER_VISA_PROCESSING = "W - Employer Visa Processing";
export const STATUS_W_EMPLOYER_WRITTEN_OFFER = "W - Employer Written Offer";

export const STATUS_W_EVENT = "W - Event";
export const STATUS_W_FEEDBACK = "W - Feedback";
export const STATUS_W_GUARANTEE = "W - Guarantee";
export const STATUS_W_HOLD = "W - Hold";
export const STATUS_W_HOMEWORK = "W - Homework";

// WAITING ONSITE STATUS
export const STATUS_W_ONSITE = "W - Onsite";
export const STATUS_W_ONSITE_INFORMAL_IN_PERSON = "W - Onsite Informal In Person";

export const STATUS_W_PAYMENT = "W - Payment";

// WAITING RECRUITER STATUS
export const STATUS_W_RECRUITER = "W - Recruiter";
export const STATUS_W_RECRUITER_AVAILABILITY = "W - Recruiter Availability";
export const STATUS_W_RECRUITER_DETAILS = "W - Recruiter Details";
export const STATUS_W_RECRUITER_HOMEWORK = "W - Recruiter Homework";
export const STATUS_W_RECRUITER_INTRO = "W - Recruiter Intro";
export const STATUS_W_RECRUITER_PERMISSION = "W - Recruiter Permission";

export const STATUS_W_SCHEDULE = "W - Schedule";

// WAITING SCREEN STATUS
export const STATUS_W_SCREEN = "W - Screen";
export const STATUS_W_SCREEN_CODING = "W - Screen Coding";
export const STATUS_W_SCREEN_INFORMAL_IN_PERSON = "W - Screen Informal In Person";
export const STATUS_W_SCREEN_TECHNICAL = "W - Screen Technical";

export const STATUS_W_START_DATE = "W - Start Date";

// HOLD STATUS
export const STATUS_H_10X10 = "H - 10x10";
export const STATUS_H_CANDIDATE = "H - Candidate";
export const STATUS_H_EMPLOYER = "H - Employer";
export const STATUS_H_RECRUITER = "H - Recruiter";

// EXIT STATUS
export const STATUS_E_10BY10 = "E - 10by10";
export const STATUS_E_10X10 = "E - 10x10";
export const STATUS_E_CANDIDATE = "E - Candidate";
export const STATUS_E_EMPLOYER = "E - Employer";
export const STATUS_E_EMPLOYER_REFUND = "E - Employer Refund";
export const STATUS_E_EMPLOYER_REPLACE = "E - Employer Replace";
export const STATUS_E_RECRUITER = "E - Recruiter";
export const STATUS_E_SUCCESS = "E - Success";

const stageStatus = {
  Confirmation: [
    /** WAITING FOR 10X10 */
    STATUS_W_10X10,
    STATUS_W_10X10_ASK_DETAILS,
    STATUS_W_10X10_ASK_PERMISSION,
    STATUS_W_10X10_INTAKE_CALL,
    STATUS_W_10X10_NOTIFY_REJECTION,
    STATUS_W_10X10_REVIEW,
    /** WAITING FOR CANDIDATE */
    "W - Candidate Availability",
    "W - Candidate Details",
    "W - Candidate Permission",
    /** WAITING FOR EMPLOYER */
    "W - Employer details",
    /** WAITING FOR RECRUITER */
    "W - Recruiter Details",
    "W - Recruiter Intro",
    "W - Recruiter Permission",
    /** ON HOLD */
    "H - 10x10",
    "H - Candidate",
    "H - Employer",
    "H - Recruiter",
    /** ENDED */
    "E - 10x10",
    "E - Candidate",
    "E - Employer",
    "E - Recruiter"
  ],
  Submission: [
    /** WAITING */
    "W - 10x10 Review",
    "W - 10x10 Submission",
    "W - Candidate",
    "W - Recruiter",
    /** ON HOLD */
    "H - 10x10",
    "H - Candidate",
    "H - Employer",
    "H - Recruiter",
    /** ENDED */
    "E - 10x10",
    "E - Candidate",
    "E - Employer",
    "E - Recruiter",
  ],
  Review: [
    "W - Candidate Details",
    "W - Employer Feedback",
    STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK,
    "W - Recruiter Details",
    /** ON HOLD */
    "H - Candidate",
    "H - Employer",
    "H - Recruiter",
    /** ENDED */
    "E - 10x10",
    "E - Candidate",
    "E - Employer",
    "E - Recruiter"
  ],
  Screen: [
    "W - 10x10 Schedule",
    /** WAITING FOR CANDIDATE */
    "W - Candidate Availability",
    "W - Candidate Details",
    "W - Candidate Homework",
    "W - Cando-Emp Availability",
    /** WAITING FOR EMPLOYER */
    "W - Employer Availability",
    "W - Employer Details",
    "W - Employer Feedback",
    STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK,
    "W - Employer Homework",
    "W - Employer Schedule",
    /** WAITING FOR RECRUITER */
    "W - Recruiter Availability",
    "W - Recruiter Details",
    "W - Recruiter Homework",
    /** WAITING FOR EVENT */
    "W - Screen",
    "W - Screen Coding",
    "W - Screen Informal In Person",
    "W - Screen Technical",
    /** ON HOLD */
    "H - 10x10",
    "H - Candidate",
    "H - Employer",
    "H - Recruiter",
    /** ENDED */
    "E - 10x10",
    "E - Candidate",
    "E - Employer",
    "E - Recruiter"
  ],
  Onsite: [
    "W - 10x10 Schedule",
    /** WAITING FOR CANDIDATE */
    "W - Candidate Availability",
    "W - Candidate Details",
    "W - Candidate Homework",
    "W - Cando-Emp Availability",
    /** WAITING FOR EMPLOYER */
    "W - Employer Availability",
    "W - Employer Details",
    "W - Employer Feedback",
    "W - Employer Homework",
    STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK,
    "W - Employer Reference",
    "W - Employer Schedule",
    /** WAITING FOR RECRUITER */
    "W - Recruiter Availability",
    "W - Recruiter Details",
    "W - Recruiter Homework",
    /** WAITING FOR EVENT */
    "W - Onsite",
    "W - Onsite Informal In Person",
    /** ON HOLD */
    "H - 10x10",
    "H - Candidate",
    "H - Employer",
    "H - Recruiter",
    /** ENDED */
    "E - 10x10",
    "E - Candidate",
    "E - Employer",
    "E - Recruiter"
  ],
  Offer: [
    /** WAITING FOR CANDIDATE */
    "W - Candidate Details",
    "W - Candidate Sign Offer",
    /** WAITING FOR EMPLOYER */
    "W - Employer Details",
    "W - Employer Reference",
    "W - Employer Verbal Offer",
    "W - Employer Written Offer",
    /** WAITING FOR RECRUITER */
    "W - Recruiter Details",
    /** ON HOLD */
    "H - 10x10",
    "H - Candidate",
    "H - Employer",
    "H - Recruiter",
    /** ENDED */
    "E - Candidate",
    "E - Employer"
  ],
  Hire: [
    "W - Candidate Details",
    "W - Employer Details",
    "W - Employer Visa processing",
    "W - Start Date",
    /** WAITING FOR RECRUITER */
    "W - Recruiter Details",
    /** ON HOLD */
    "H - Candidate",
    "H - Employer",
    "H - Recruiter",
    /** ENDED */
    "E - Candidate",
    "E - Employer"
  ],
  Guarantee: [
    /** WAITING */
    "W - Guarantee",
    "W - Payment",
    /** ENDED */
    "E - Candidate",
    "E - Employer Refund",
    "E - Employer Replace"
  ]
};

const matchOptions = [
  [STAGE_SUBMISSION, STATUS_W_10X10_SUBMISSION].join(', '),
  "Confirmation, W - 10x10",
  "Confirmation, W - 10x10 Ask Details",
  "Confirmation, W - 10x10 Ask Permission",
  "Confirmation, W - 10x10 Notify Rejection",
  "Confirmation, W - 10x10 Review",
  "Confirmation, W - Candidate",
  "Confirmation, W - Candidate Details",
  "Confirmation, W - Candidate Permission",
  "Confirmation, W - Employer",
  "Confirmation, W - Employer details",
  "Confirmation, W - Recruiter",
  "Confirmation, W - Recruiter Details",
  "Confirmation, W - Recruiter Intro",
  "Confirmation, W - Recruiter Permission"
];

const mapStreakEngagementPipeline = pipeline => {
  //Core.log({ StreakPipeline: pipeline });
  streakMap[pipeline.key] = {
    streakPipeline: pipeline.name,
    streakPipelineKey: pipeline.key,
    streakStage: {},
    streakStageOrder: []
  };
  streakMap[pipeline.key].streakStageOrder = pipeline.stageOrder;
  /** SET STAGE VALUES */
  pipeline.stages &&
    Object.keys(pipeline.stages).forEach(
      key =>
        (streakMap[pipeline.key].streakStage[key] = pipeline.stages[key].name)
    );
  /** SET FIELD VALUES */
  setFieldValues("Employer");
  setFieldValues("Introduced");
  setFieldValues("Matched");
  setFieldValues("Confirmed");
  setFieldValues("Onsite");
  setFieldValues("Recruiter");
  setFieldValues("Reviewed");
  setFieldValues("Role");
  setFieldValues("Screened");
  setFieldValues("State");
  setFieldValues("Status");
  setFieldValues("Submitted");
  setFieldValues("Last Action");
  setFieldValues("Offered");
  setFieldValues("Hired");
  setFieldValues("StartDate");
  setFieldValues("GuaranteeDate");
  setFieldValues("Screen 1");
  setFieldValues("Screen 2");
  setFieldValues("Screen 3");
  setFieldValues("Onsite 1");
  setFieldValues("Onsite 2");
  setFieldValues("HoldDate");
  setFieldValues("Closed");
  setFieldValues("EngagementURL");
  setFieldValues("Platform Rating");
  setFieldValues("Rejection Reason");
  setFieldValues("Job Title");
  setFieldValues("Platform Rating");
  function setFieldValues(field) {
    /** field is the name of the item from pipeline.fields */
    const codeName = `streak${field.replace(" ", "")}`;
    const key = pipeline.key;
    (
      Object(
        Object(
          (pipeline.fields || []).find(item => {
            if (item.name === field) {
              streakMap[key][`${codeName}FK`] = item.key;
              streakMap[key][`${codeName}Type`] = item.type;
              return true;
            }
            return false;
          })
        ).dropdownSettings
      ).items || []
    ).forEach(item => {
      streakMap[key][codeName] = streakMap[key][codeName] || {};
      streakMap[key][codeName][item.key] = item.name;
    });
  }
  const statuses = JSON.stringify(streakMap[pipeline.key].streakStatus);
  Object.keys(stageStatus).forEach(stage => {
    stageStatus[stage].forEach((status, index) => {
      if (!new RegExp(status, "i").test(statuses)) {
        console.warn(
          "StreakMap",
          "stageStatus",
          "Missing Status on Pipeline",
          stage,
          status,
          statuses
        );
        Core.failure({
          source: 'Streak.js(lib)>mapStreakEngagementPipeline:stageStatus',
          exception: `Streak | stageStatus | Missing Status on Pipeline`,
          params: { pipeline, stage, status, statuses }
        });
        stageStatus[stage].splice(index, 1);
      }
    });
  });
  streakMap[pipeline.key].stageStatus = stageStatus;
  matchOptions.forEach((option, index) => {
    option = option.split(", ");
    const stage = option[0];
    const status = option[1];
    if (!stageStatus[stage] || !new RegExp(status, "i").test(statuses)) {
      console.warn(
        "StreakMap",
        "matchOptions",
        "Missing Status on Pipeline",
        stage,
        status,
        statuses
      );
      Core.failure({
        source: 'Streak.js(lib)>mapStreakEngagementPipeline:matchOptions',
        exception: `Streak | matchOptions | Missing Status on Pipeline`,
        params: { pipeline, stage, status, statuses }
      });
      matchOptions.splice(index, 1);
    }
  });
  streakMap[pipeline.key].matchOptions = matchOptions;
  //Core.log({ StreakPipelineMap: streakMap[pipeline.key] });
  return { ...streakMap[pipeline.key] };
};
const getValue = (box, field, absolute) => {
  const codeName = `streak${field.replace(" ", "")}`;
  const pipeline = Object(streakMap[box.pipelineKey]);
  const fieldKey = String(pipeline[`${codeName}FK`]);
  const fieldList = Object(pipeline[codeName]);
  const boxField = box.fields[fieldKey];
  const defaultValue = absolute ? undefined : mdash;
  switch (pipeline[`${codeName}Type`]) {
    case "FORMULA":
      return Object(boxField).calculationStatus || defaultValue;
    case "DATE":
      return boxField ? moment(boxField).format() : defaultValue;
    case "DROPDOWN":
      return fieldList[boxField] || defaultValue;
    case "TEXT_INPUT":
      return boxField || defaultValue;
    default:
      /** STAGE */
      return fieldList[box[`${field.toLowerCase()}Key`]] || defaultValue;
  }
};
const getPipeline = em => ({ ...streakMap[pipelineKey] });
const getBox = (key, success) => {
  Http.get(
    Core.getApi("Engagements/streak"),
    { path: `/boxes/${key}` },
    success,
    error => Core.showFailure("Streak: " + error)
  );
};
const getBoxes = (engagements, callback) => {
  let boxes = [];
  engagements.forEach(
    item =>
      item.boxKey &&
      Http.get(
        Core.getApi("Engagements/streak"),
        { path: `/boxes/${item.boxKey}` },
        box =>
          box &&
          box.pipelineKey &&
          callback(
            (boxes = [...boxes, box]
              /** sorting by stage */
              .sort(
                (a, b) =>
                  streakMap[a.pipelineKey].streakStageOrder.indexOf(
                    a.stageKey
                  ) -
                  streakMap[b.pipelineKey].streakStageOrder.indexOf(b.stageKey)
              )
              /** sorting by state */
              .sort(
                (a, b) =>
                  (getValue(a, "State", "common") === "Open" ? 0 : 1) -
                  (getValue(b, "State", "common") === "Open" ? 0 : 1)
              ))
          ),
        error => Core.showFailure("Streak: " + error)
      )
  );
};
const getPipelineBoxes = success =>
  Http.get(
    Core.getApi("Engagements/streak"),
    { path: `/pipelines/${pipelineKey}/boxes` },
    success,
    error => Core.showFailure("Streak: " + error)
  );
const createBox = (
  options,
  onSuccess = () => { },
  onFailure = () => { }
) => {
  const pipeline = streakMap[pipelineKey];
  if (pipeline) {
    /** BASED MODEL */
    const model = {
      name: options.name,
      recruiter: findKey(pipeline.streakRecruiter, options.recruiter),
      role: findKey(pipeline.streakRole, options.role, true),
      stage:
        findKey(pipeline.streakStage, options.stage) ||
        pipeline.streakStageOrder[0],
      status: findKey(pipeline.streakStatus, options.status || "W - 10x10"),
      state: findKey(pipeline.streakState, options.state || "Open"),
      introduced: date(options.introduced, true),
      matched: date(options.matched, true),
      lastAction: date(null, true),
      confirmed: date(options.confirmed),
      platformRating: options.platformRating,
      jobTitle: options.jobTitle,
      engagementURL: options.engagementURL || ""
    };
    function date(valid, alwaysReturnDate) {
      return valid
        ? new Date(valid).getTime()
        : alwaysReturnDate
          ? new Date().getTime()
          : null;
    }
    function findKey(dropdown, name, exact) {
      if (exact) {
        return Object.keys(dropdown).find(key => name === dropdown[key]);
      } else {
        return Object.keys(dropdown).find(key =>
          new RegExp(String(name).replace(/\W/g, ".?"), "i").test(
            String(dropdown[key])
          )
        );
      }
    }
    Core.log(options, model);
    /** CREATE NEW STREAK BOX */
    Http.put(
      Core.getApi("Engagements/streak"),
      {
        path: `/pipelines/${pipelineKey}/boxes`,
        options: { name: model.name, stageKey: model.stage }
      },
      box => {
        Core.log(box);
        if (box.key) {
          onSuccess(box);
          const fields = [
            {
              field: "State",
              value: model.state
            },
            {
              field: "Role",
              value: model.role
            },
            {
              field: "Status",
              value: model.status
            },
            {
              field: "Introduced",
              value: model.introduced
            },
            {
              field: "Platform Rating",
              value: model.platformRating
            },
            {
              field: "Job Title",
              value: model.jobTitle
            },
            {
              field: "Matched",
              value: model.matched
            },
            {
              field: "Last Action",
              value: model.lastAction
            },
            {
              field: "Recruiter",
              value: model.recruiter
            },
            {
              field: "EngagementURL",
              value: model.engagementURL
            }
          ];
          model.confirmed &&
            fields.push({
              field: "Confirmed",
              value: model.confirmed
            });
          updateBox({ boxKey: box.key, fields });
        }
      },
      error => {
        Core.showFailure("Streak: " + error);
        onFailure(error);
      }
    );
  }
};

async function updateBox({ boxKey, fields, engagementId }) {

  const pipeline = streakMap[pipelineKey];
  const failures = [];
  const _fields = {};
  let _stageKey;
  let response;

  fields.forEach(({ field, value, stageKey }) => {
    if (stageKey) { _stageKey = stageKey; return; }
    _fields[pipeline[`streak${field.replace(" ", "")}FK`]] = value;
    if (field === "Status" && !/h -/i.test(value)) {
      _fields[pipeline[`streakHoldDateFK`]] = '';
    }
  });

  try {
    response = await Http.post(
      Core.getApi("Engagements/streak"),
      { path: `/boxes/${boxKey}`, options: { stageKey: _stageKey, fields: _fields } }
    );
  }
  catch (failure) {
    Core.showMessage(`Fails to update Streak: ${failure};`);
    if (!Core.isLocalHost() && !Core.isDevelopment()) {
      Core.failure({
        source: 'Streak.js(lib)>updateBox:catch',
        exception: failure,
        params: {
          boxKey,
          fields,
          engagementId,
        }
      });
    }
    failures.push(failure);
  }

  window.streakFailures = window.streakFailures || {};
  window.streakFailures[engagementId] = failures;

  console.debug({ boxKey, fields, _fields, pipeline, _stageKey, engagementId, response, failures });

  return {
    response,
    failures
  }

}

const getDropdownStrings = field => {
  const pipeline = streakMap[pipelineKey];
  if (pipeline) {
    const codeName = `streak${field.replace(" ", "")}`;
    const dropdownList = pipeline[codeName];
    if (dropdownList) {
      return Object.keys(dropdownList).map(key => dropdownList[key]);
    }
  }
  return [];
};
const getDropdownKey = (field, option) => {
  const pipeline = streakMap[pipelineKey];
  if (pipeline) {
    const codeName = `streak${field.replace(" ", "")}`;
    const dropdownList = pipeline[codeName];
    return Object.keys(dropdownList).find(key => dropdownList[key] === option);
  } else {
    return null;
  }
};
const fetch = (keyName, success, failure) => {
  if (Core.isLogged()) {
    Http.get(
      Core.getApi("Engagements/streak"),
      { path: `/pipelines/${pipelineKeys[keyName]}` },
      response => {
        mapStreakEngagementPipeline(response);
        success && success();
      },
      failure
    );
  } else {
    success && success();
  }
};
const Streak = {
  fetch,
  getPipeline,
  getBox,
  getBoxes,
  getPipelineBoxes,
  getDropdownStrings,
  getDropdownKey,
  getValue,
  getDateValue: (box, field, format) => {
    const codeName = `streak${field.replace(" ", "")}`;
    const pipeline = Object(streakMap[box.pipelineKey]);
    const fieldKey = String(pipeline[`${codeName}FK`]);
    const boxField = box.fields[fieldKey];
    return boxField ? moment(boxField).format(format || "") : mdash;
  },
  createBox,
  updateBox,
  deleteBox: ({ boxKey, onSuccess, onFailure }) => {
    Http.delete(
      Core.getApi("Engagements/streak"),
      { path: `/boxes/${boxKey}` },
      onSuccess,
      onFailure
    );
  },
  createComment: ({ boxKey, message }, onSuccess, onFailure) => {
    Http.post(
      Core.getApi("Engagements/streak/v2"),
      {
        path: `/boxes/${boxKey}/comments`,
        options: { message }
      },
      response =>
        onSuccess
          ? onSuccess(response)
          : alert(<pre>{JSON.stringify(response, null, 2)}</pre>),
      error => (onFailure ? onFailure(error) : Core.showFailure(error))
    );
  },
  getComments: (boxKey, onSuccess, onFailure) => {
    Http.get(
      Core.getApi("Engagements/streak"),
      {
        path: `/boxes/${boxKey}/comments`
      },
      response =>
        onSuccess
          ? onSuccess(response)
          : alert(<pre>{JSON.stringify(response, null, 2)}</pre>),
      error => (onFailure ? onFailure(error) : Core.showFailure(error))
    );
  },
  getThreads: (boxKey, onSuccess, onFailure) => {
    Http.get(
      Core.getApi("Engagements/streak"),
      {
        path: `/boxes/${boxKey}/threads`
      },
      response =>
        onSuccess
          ? onSuccess(response)
          : alert(<pre>{JSON.stringify(response, null, 2)}</pre>),
      error => (onFailure ? onFailure(error) : Core.showFailure(error))
    );
  },
  updateNotes: ({ boxKey, notes }, onSuccess, onFailure) => {
    Http.post(
      Core.getApi("Engagements/streak"),
      { path: `/boxes/${boxKey}`, options: { notes } },
      response =>
        onSuccess
          ? onSuccess(response)
          : alert(<pre>{JSON.stringify(response, null, 2)}</pre>),
      error => (onFailure ? onFailure(error) : Core.showFailure(error))
    );
  },
  putEmailInBox: async ({ boxKey, threadGmailId }, onSuccess, onFailure) => {
    return await Http.put(
      Core.getApi("Engagements/streak"),
      {
        path: `/boxes/${boxKey}/threads`,
        options: { boxKey, threadGmailId }
      },
      response =>
        onSuccess
          ? onSuccess(response)
          : Core.showMessage("Successfully put email in box"),
      error =>
        onFailure
          ? onFailure("Streak: " + error)
          : Core.showFailure("Streak: " + error)
    );
  },
  getSnippets: (onSuccess, onFailure) => {
    Http.get(
      Core.getApi("Engagements/streak"),
      {
        path: `/snippets`
      },
      response =>
        onSuccess
          ? onSuccess(response)
          : alert(<pre>{JSON.stringify(response, null, 2)}</pre>),
      error => (onFailure ? onFailure(error) : Core.showFailure(error))
    );
  }
};
export default Streak;
