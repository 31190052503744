import {
  AutoComplete,
  Chip,
  Divider,
  FlatButton,
  FontIcon,
  IconButton,
  MenuItem,
  Popover, Table,
  TableBody,
  TableRow,
  TableRowColumn, TextField
} from "material-ui";
import moment from "moment";
import React, { Component, Fragment } from "react";
import Core, { colors } from "../../lib/Core";
import Definition from "../../lib/Definition";
import FilterControlLib from "../../lib/FilterControl";
import FilterLib from "../../lib/services/Filtering/Filter.lib";
import Store from "../../lib/Store";
import formatMoney from "../../lib/tools/formatMoney";
import AllFiltersDialog from "./Dialogs/AllFiltersDialog";
import SelectTagDialog from "./Dialogs/SelectTagDialog";
import FilterBarMenu from "./FilterBarMenu";

// import Debug from "../../lib/Debug";

/** THIS COMPONENT IS BEING USED BY ALL MAIN SECTIONS */
/** be careful what you change something in this file */
class FilterControl extends Component {
  timeout;
  minSalary = 0;
  maxSalary = 250000;
  minCompanySize = 0;
  minXp = 0;
  maxXp = 40;
  maxCompanySize = 500;
  sliderStep = 10000;
  sliderStepXp = 1;
  sliderStepCS = 5;
  tested = false;

  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    const state = {
      items: [],
      chips: [],
      keywords: [],
      search: "",
      sources: [],
      openSavedSearches: false,
      anchorSavedSearches: null,
      savedSearches: [],
      minimumSalary: this.props.minimumSalary || Number(this.minSalary),
      maximumSalary: Number(this.maxSalary),
      minimumXp: this.props.minimumXp || Number(this.minXp),
      maximumXp: Number(this.maxXp),
      minimumCompanySize: Number(this.minCompanySize),
      maximumCompanySize: Number(this.maxCompanySize)
    };
    state.menus = this.props.menus || [];
    state.more = this.props.more || [];
    this.state = state;

  }
  setItems = (items, selected, visa, fields = [], filterState = {}) => {
    this.setState(
      state => {
        state.items = items;
        const sources = {};
        const menus = state.menus;
        const more = state.more;
        /* fill menu items */
        items.forEach(item => {
          /* autocomplete */
          item.___keys___.forEach(label => (sources[label] = true));
          /* fill menus items */
          menus.forEach(menu => {
            menu.items = menu.items || {};

            if (!!menu.options) {
              menu.items = menu.options
            } else {
              if (item[menu.field]) {
                if (!!menu.multiple) {

                  if (this.props.source === "jobs" && menu.key === "roles") {
                    if (item.state === 1) {
                      Object.keys(item[menu.field]).forEach(label =>
                        getChecked(menu.items, label)
                      );
                    }
                  }
                  else {
                    /* set new multiple values, if previous values set them */
                    Object.keys(item[menu.field]).forEach(label =>
                      getChecked(
                        menu.items,
                        /* story-3083 M4 | New Locations */
                        FilterControlLib.getItemValue({
                          menu,
                          itemLabel: label
                        })
                      )
                    );
                  }

                } else {
                  /* set previous single value, if not set it as false */
                  getChecked(menu.items, item[menu.field]);
                }
              }
            }

          });
        });

        /* for autocomplete */
        state.sources = Object.keys(sources).sort();
        /* fill more items */
        more.forEach(menu => {
          menu.items = menu.items || {};
          let defKeys = Definition.get(menu.definitionKey || menu.key);

          if (defKeys.length === 0 && menu.options) {
            defKeys = menu.options;
          }

          defKeys.forEach(
            /* set previous single value, if not set it as false */
            def =>
              getChecked(
                menu.items,
                /diversity/i.test(menu.key)
                  ? "Diversity: " + def.label
                  : def.label
              )
          );
        });

        function getChecked(items, label) {
          /* Gets the menu item checkbox value */
          /* xxx(not anymore) if value exits previously return it */
          /* then if value match with selected expresion return it */
          /* if not return false */
          // Core.log(label,selected);
          return (items[label] =
            /** next line is commented because
             * if you go to a match list, it preselect certain tags
             * then on back to the main list the preseleted appears again.
             * we should observe if this change would affect some features.
             */
            // items[label] ||
            selected ? new RegExp(selected, "i").test(label) : false);
        }
        return { ...state, ...filterState };
      },
      then => this.jiraVER20({ label: visa, menuKey: 'visa', checked: true, fields, cb: this.onChange, source: 'setItems' })
    );
  };
  jiraVER20 = ({ label, menuKey, checked, fields = [], cb, source }) => {
    /** [ Jira Ticket VER-20 ] ===>>> * /
    /**
    For Visa,
    Citzen > Green Card > Visa Transfer > Visa Sponsor
    If a candidate is a Citzen, it should fit all jobs.
    If a candidate requires Visa transfers,
    it should fit both Visa transfer and Visa sponsor
    (but not green card and citzen) jobs.
    Always include unspecified, unknow in the search results.
    */
    if (!label) {
      !!cb && cb();
      return;
    }

    const processSingle = (menus, menuKey, label, checked, source) => {
      const menu = menus.find(menu => menu.key === menuKey);

      if (!!menu && menu.inputType === 'radio') {
        let keys = Object.keys(menu.items);
        let items = {};

        keys.forEach(key => {
          items[key] = false;
        })

        menu.items = items;
      }

      /**
       * @todo
       * TO DEPRECATE / CLEANUP?
       * 2021-06-23
       * µ
       */
      /** * /
      if (source === 'setItems') {
        if (this.props.source === 'candidateMatch' && menuKey === 'location') {
          let isBayArea = ['All Bay Area', 'East Bay', 'South Bay', 'North Bay', 'Peninsula', 'San Francisco'].find(lab => {
            let regex = new RegExp(lab, 'i');
            return regex.test(label);
          })

          if (!!isBayArea) {
            label = 'All Bay Area';
          }
        }

        if (this.props.source === 'jobMatch' && menuKey === 'locationCandidate') {
          let bayAreas = ['East Bay', 'North Bay', 'Peninsula', 'San Francisco', 'South Bay'];
          let isBayArea = bayAreas.find(lab => {
            let regex = new RegExp(lab, 'i');
            return regex.test(label);
          })

          if (!!isBayArea) {
            label = bayAreas.join(',');
          }
        }
      }
      /** */

      if (!!label && !!menu) {
        let labels = label.split(',').map(el => el.trim());
        labels.forEach(label => {
          if (Object.keys(menu.items).includes(label)) {
            menu.items[label] = checked;
          }
        })
      }
    }

    this.setState(state => {
      const menus = [...state.menus, ...state.more];

      if (!!fields.length) {
        fields.forEach(el => {
          processSingle(menus, el.key, el.label, el.checked, source);
        })
      } else {
        processSingle(menus, menuKey, label, checked);
      }



      // if (/CandidateMatch|Jobs/.test(this.parent.name)) {
      //   /** Follow code is for JobList also CandidateMatch
      //    Job Visa Options:
      //    Citizen
      //    Green Card
      //    Transfer Visa
      //    Sponsor Visa
      //    No Support Visa
      //    Visa Support Unknown
      //    */
      //   if (label === "Citizen" && checked) {
      //     Object.keys(visaMenu.items).forEach(visa => {
      //       /*
      //       if (visa === "Green Card") {
      //         visaMenu.items[visa] = false;
      //       } else {
      //         */
      //       visaMenu.items[visa] = true;
      //       //}
      //     });
      //   } else if (label === "Green Card" && checked) {
      //     Object.keys(visaMenu.items).forEach(visa => {
      //       if (visa === "Citizen") {
      //         visaMenu.items[visa] = false;
      //       } else {
      //         visaMenu.items[visa] = true;
      //       }
      //     });
      //   } else if (label === "Transfer Visa" && checked) {
      //     Object.keys(visaMenu.items).forEach(visa => {
      //       if (
      //           visa === "Transfer Visa" ||
      //           visa === "Sponsor Visa" ||
      //           visa === "Visa Support Unknown"
      //       ) {
      //         visaMenu.items[visa] = true;
      //       } else {
      //         visaMenu.items[visa] = false;
      //       }
      //     });
      //   } else if (label === "Sponsor Visa" && checked) {
      //     Object.keys(visaMenu.items).forEach(visa => {
      //       if (visa === "Sponsor Visa" || visa === "Visa Support Unknown") {
      //         visaMenu.items[visa] = true;
      //       } else {
      //         visaMenu.items[visa] = false;
      //       }
      //     });
      //   }
      // } else if (/JobMatch|Candidates/.test(this.parent.name)) {
      //   /** Follow code is for CandidateList also JobMatch
      //    Candidate Visa Options:
      //    Citizen
      //    Green Card
      //    Needs H1B Visa Transfer
      //    Needs H1B1/E-3 (Chile, Singapore, Australia)
      //    Needs TN (Canada, Mexico)
      //    Needs New Visa Sponsor
      //    Visa Status Unknown
      //    */
      //   if (label === "Citizen" && checked) {
      //     Object.keys(visaMenu.items).forEach(visa => {
      //       if (visa === "Citizen" || visa === "Visa Status Unknown") {
      //         visaMenu.items[visa] = true;
      //       } else {
      //         visaMenu.items[visa] = false;
      //       }
      //     });
      //   } else if (label === "Green Card" && checked) {
      //     Object.keys(visaMenu.items).forEach(visa => {
      //       if (
      //           visa === "Citizen" ||
      //           visa === "Green Card" ||
      //           visa === "Visa Status Unknown"
      //       ) {
      //         visaMenu.items[visa] = true;
      //       } else {
      //         visaMenu.items[visa] = false;
      //       }
      //     });
      //   } else if (
      //       (label === "Needs H1B Visa Transfer" ||
      //           label === "Needs H1B1/E-3 (Chile, Singapore, Australia)" ||
      //           label === "Needs TN (Canada, Mexico)") &&
      //       checked
      //   ) {
      //     Object.keys(visaMenu.items).forEach(visa => {
      //       if (
      //           visa === "Citizen" ||
      //           visa === "Green Card" ||
      //           visa === "Needs H1B Visa Transfer" ||
      //           visa === "Needs H1B1/E-3 (Chile, Singapore, Australia)" ||
      //           visa === "Needs TN (Canada, Mexico)" ||
      //           visa === "Visa Status Unknown"
      //       ) {
      //         visaMenu.items[visa] = true;
      //       } else {
      //         visaMenu.items[visa] = false;
      //       }
      //     });
      //   } else if (
      //       (label === "Needs New Visa Sponsor") |
      //       (label === "Visa Status Unknown") &&
      //       checked
      //   ) {
      //     Object.keys(visaMenu.items).forEach(visa => {
      //       visaMenu.items[visa] = true;
      //     });
      //   }
      // }
      return state;
    }, cb);
    // see also FilterControl/SelectTagDialog call to parent.jiraVER20 method.
    // see also CandidateMatch.jiraVER20 method.
    // see also JobMatch.jiraVER20 method.
    /** <<<=== [ Jira Ticket VER-20 ] */
  };
  checkLocations = ({ label, checked, cb }) => {
    cb();
    // this.setState(state => {
    //   const menus = state.menus;
    //   const locationMenu = menus.find(menu => menu.key === "locationCandidate");
    //   if (locationMenu && /Candidates/.test(this.parent.name)) {
    //     if (label === "All Bay Area" && checked) {
    //       Object.keys(locationMenu.items).forEach(location => {
    //         locationMenu.items[location] = !/unspecified/i.test(location);
    //       });
    //     } else if (!/All Bay Area|unspecified/i.test(label) && !checked) {
    //       locationMenu.items["All Bay Area"] = false;
    //     }
    //   }
    //   return state;
    // }, cb);
  };
  unsetChip = item => {
    this.setState(state => {
      const menus = state.menus;
      const more = state.more;
      if (item.maximumSalary) {
        state.maximumSalary = Number(this.maxSalary);
      } else if (item.minimumSalary) {
        state.minimumSalary = Number(this.minSalary);
      } else if (item.minimumXp) {
        state.minimumXp = Number(this.minXp);
      } else if (item.minimumCompanySize) {
        state.minimumCompanySize = Number(this.minCompanySize);
      } else if (item.keyword) {
        state.keywords = state.keywords.filter(
          objKeyword => objKeyword.name !== item.name
        );
      } else if (item.menu) {
        menus.find(unselectItem);
      } else if (item.more) {
        more.find(unselectItem);
      }
      function unselectItem(menu) {
        return Object.keys(menu.items).find(name => {
          if (name === item.name) {
            menu.items[name] = false;
            return true;
          }
          return false;
        });
      }
      return state;
    }, this.onChange);
  };
  storeState = ev =>
    new Promise(resolve => {
      const state = {
        ...this.state,
        date: moment().toISOString(),
        name: ""
      };
      delete state.items;
      delete state.sources;
      delete state.anchorSavedSearches;
      try {
        Core.showMessage("Search Saved");
        Store.set("saved-searches", [
          state,
          ...(Store.get("saved-searches") || [])
        ]);
      } catch (ex) {
        Core.showFailure("Search was not Saved");
        console.warn(ex);
      }
    }).then();
  cleanSearch = ev =>
    new Promise(resolve => {
      this.setState(state => {
        const menus = state.menus;
        const more = state.more;
        menus.forEach(menu => {
          Object.keys(menu.items).forEach(name => {
            menu.items[name] = false;
          });
        });
        more.forEach(menu => {
          Object.keys(menu.items).forEach(name => {
            menu.items[name] = false;
          });
        });
        return {
          menus,
          more,
          keywords: [],
          search: "",
          maximumSalary: this.maxSalary,
          minimumSalary: this.minSalary,
          minimumXp: this.minXp,
          minimumCompanySize: Number(this.minCompanySize)
        };
      }, this.onChange);
    }).then();
  getChips = em => {
    const chips = [];
    const menus = this.state.menus;
    const more = this.state.more;
    const state = this.state;

    menus.forEach(menu => {
      menu.items &&
        Object.keys(menu.items).forEach(
          name => menu.items[name] === true && chips.push({ name, menu: true })
        );
    });
    more.forEach(menu => {
      menu.items &&
        Object.keys(menu.items).forEach(
          name => menu.items[name] === true && chips.push({ name, more: true })
        );
    });
    state.keywords.forEach(item => chips.push(item));

    if (state.minimumSalary !== this.minSalary) {
      let prefix = '';

      if (/Candidates|jobMatch/.test(this.props.source)) {
        prefix = 'Accepts Salary <='
      } else {
        prefix = 'Pays Salary >='
      }
      chips.push({
        name: `${prefix} $${formatMoney(state.minimumSalary, 0)}`,
        minimumSalary: true
      });
    }

    if (state.maximumSalary !== this.maxSalary) {
      chips.push({
        name: `Max Salary: $${formatMoney(state.maximumSalary, 0)}`,
        maximumSalary: true
      });
    }

    if (state.minimumXp !== this.minXp) {
      let prefix = '';

      if (/Candidates|jobMatch/.test(this.props.source)) {
        prefix = `has >= ${state.minimumXp}y exp`;
      } else {
        prefix = `require <= ${state.minimumXp}y exp`;
      }

      chips.push({
        name: `${prefix}`,
        minimumXp: true
      });
    }

    if (/Jobs/i.test(this.props.source) && state.minimumCompanySize > this.minCompanySize) {
      let prefix = `require <= ${state.minimumCompanySize} company size`;
      chips.push({
        name: `${prefix}`,
        minimumCompanySize: true
      });
    }

    return chips;
  };
  onChange = e => {
    if (this.props.onChange instanceof Function) {
      this.setState({ chips: this.getChips() }, then => {

        let filtered = FilterLib.filterList({
          ...this.state,
          pageName: this.parent.name,
        });

        this.props.onChange(filtered, this.state.keywords);

      });
    }
  };
  render() {
    const FilterControlController = this;
    const openSavedSearches = ev => {
      this.setState({
        savedSearches: Store.get("saved-searches") || [],
        anchorSavedSearches:
          ev && ev.currentTarget
            ? ev.currentTarget
            : this.state.anchorSavedSearches,
        openSavedSearches: true
      });
    };
    const menus = this.state.menus;
    const more = this.state.more;
    return (
      <div
        className="FilterControl"
        style={{ paddingTop: this.props.paddingTop === false ? "0px" : "24px" }}
      >
        <div className="filter-control-search">
          {/** CHIPS BAR */}
          {!!this.state.chips.length && (
            <div className="filter-control-search-chips nowrap scroll">
              <i className="material-icons filter-icon">filter_list</i>
              {!this.state.chips.length && (
                <span className="filter-control-placehoder">
                  Select Filters
                </span>
              )}
              {this.state.chips.map((item, index) => (
                <Chip
                  key={`chip${index}`}
                  className="filter-control-search-chip"
                  backgroundColor={colors.purple}
                  labelColor={colors.white}
                  labelStyle={{ fontWeight: 300, fontSize: 13 }}
                  onRequestDelete={ev => this.unsetChip(item, index)}
                >
                  {item.name}
                </Chip>
              ))}
            </div>
          )}
          {/** SEARCH BAR */}
          <div className="filter-form">
            <i className="material-icons filter-icon">search</i>
            <AutoComplete
              name="search"
              placeholder="Enter a Keyword"
              className="filter-control-search-input"
              underlineFocusStyle={{ borderColor: "transparent" }}
              filter={AutoComplete.fuzzyFilter}
              dataSource={this.state.sources}
              listStyle={{ width: "auto" }}
              maxSearchResults={7}
              searchText={this.state.search}
              onUpdateInput={search => this.setState({ search })}
              onNewRequest={search => {
                if (!!this.state.search.trim().length) {
                  this.setState(
                    {
                      keywords: [
                        ...this.state.keywords,
                        {
                          name: this.state.search.trim(),
                          keyword: true
                        }
                      ],
                      search: ""
                    },
                    this.onChange
                  );
                }
              }}
            />
          </div>
          {/** SAVE AN CLEAN BUTTONS */}
          {!!this.state.chips.length && (
            <div className="filter-action-bar ui-m-min">
              {Core.isAdminOrCoordinator() && (
                <FlatButton
                  label="Save Search"
                  className="filter-save-search"
                  onClick={this.storeState}
                />
              )}
              <FlatButton
                label="Clear All"
                className="filter-clear-all"
                onClick={this.cleanSearch}
              />
            </div>
          )}
          <Divider />
        </div>
        <div className="material-table">
          <Table className="filter-toolbar layout-auto">
            <TableBody displayRowCheckbox={false}>
              <TableRow selectable={false}>
                <TableRowColumn className="v-align-top">
                  <div className="d-flex nowrap scroll-x">
                    {/** MENUS */
                      menus.map(
                        menu =>
                          <FilterBarMenu
                            {...{
                              menu,
                              FilterControlController
                            }}
                          />
                      )}
                    {/** MORE FILTERS */}
                    <MenuItem
                      className="filter-control-item"
                      primaryText="More Filters"
                      style={{ fontSize: 14 }}
                      rightIcon={
                        <FontIcon className="material-icons">
                          arrow_drop_down
                        </FontIcon>
                      }
                      onClick={ev => this.AllFilters.open(more)}
                    />
                    {this.props.toolBarLeft}
                  </div>
                </TableRowColumn>
                <TableRowColumn className="d-flex flex-wrap flex-align-right-top overflow-unset">
                  {/** SAVED SEARCHES: start */}
                  {Core.isAdminOrCoordinator() &&
                    !!(Store.get("saved-searches") || []).length && (
                      <Fragment>
                        <FlatButton
                          label="Saved Searches"
                          className="filter-saved-searches"
                          onClick={ev => openSavedSearches(ev)}
                        />
                        <Popover
                          open={this.state.openSavedSearches}
                          anchorEl={this.state.anchorSavedSearches}
                          anchorOrigin={{
                            horizontal: "left",
                            vertical: "bottom"
                          }}
                          targetOrigin={{ horizontal: "left", vertical: "top" }}
                          onRequestClose={ev =>
                            this.setState({ openSavedSearches: false })
                          }
                        >
                          {this.state.savedSearches.map((state, index) => (
                            <div key={state.date} className="inline-blocks">
                              <FlatButton
                                title="Tap to apply filters"
                                label="Apply"
                                primary={true}
                                onClick={ev =>
                                  this.setState(state, then => this.onChange())
                                }
                              />
                              <TextField
                                title="Enter a name for you search"
                                name="search"
                                className="filter-control-saved-search"
                                style={{ width: "300px", margin: 8 }}
                                autoComplete="off"
                                placeholder={state.date}
                                underlineFocusStyle={{
                                  borderColor: "transparent"
                                }}
                                type="text"
                                value={state.name}
                                onChange={(event, name) => {
                                  this.setState({
                                    savedSearches: this.state.savedSearches.map(
                                      saved => {
                                        if (saved.date === state.date) {
                                          saved.name = name;
                                        }
                                        return saved;
                                      }
                                    )
                                  });
                                  Store.set(
                                    "saved-searches",
                                    Store.get("saved-searches").map(saved => {
                                      if (saved.date === state.date) {
                                        saved.name = name;
                                      }
                                      return saved;
                                    })
                                  );
                                }}
                              />
                              <IconButton
                                title="Delete saved search"
                                onClick={ev => {
                                  Store.set(
                                    "saved-searches",
                                    Store.get("saved-searches").filter(
                                      saved => {
                                        if (saved.date !== state.date) {
                                          return true;
                                        }
                                        return false;
                                      }
                                    )
                                  );
                                  openSavedSearches();
                                }}
                              >
                                <i className="material-icons">close</i>
                              </IconButton>
                            </div>
                          ))}
                        </Popover>
                      </Fragment>
                    )}
                  {/** SAVED SEARCHES: end */}
                  {this.props.toolBarRight}
                </TableRowColumn>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <SelectTagDialog
          ref={self => (this.SelectTag = self)}
          FilterControlController={this}
        />
        <AllFiltersDialog
          ref={self => (this.AllFilters = self)}
          parent={this}
        />
      </div >
    );
  }
}

export default FilterControl;