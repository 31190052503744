import { List, ListItem, MenuItem, SelectField, TextField } from "material-ui";
import React, { Component } from "react";
import Definition, { EMP_MSG_TYPE__REMINDER_ID } from "../../../lib/Definition";
import jobModel from "../../../lib/models/job";
import formatURL from "../../../lib/tools/formatURL";
import Col from "../../Forms/Col";
import EmailList from "../../Forms/EmailsList";
import Row from "../../Forms/Row";

const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailInvalid = "Email is invalid";
const emailError = "Please enter a valid email";
const emailActions = [
  <MenuItem key="to" value="to" primaryText="TO" />,
  <MenuItem key="cc" value="cc" primaryText="CC" />
];
const lineStyle = { borderColor: "#59736f" };

/**
 * @todo
 *
 * DEPRECATED
 * 
 * Review to cleanup
 *
 * story-3927-m7 | 2021-09-03 Fri µ
 *
 */
class JobElement extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      ...jobModel,
      ...this.props.model
    };
  }
  onChange = state => {
    this.setState(state, then => {
      if (this.props.onChange instanceof Function) {
        this.props.onChange(this.state);
      }
    });
  };
  render() {
    return (
      <Row>
        <Col className="col2">
          <h4 className="cdarker">{this.state.jobTitle}</h4>
        </Col>
        <Col className="col10">
          <Row>
            <Col className="col2">
              <SelectField
                name="resumeSubmissionEmailAction1"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionEmailAction1}
                onChange={(ev, i, resumeSubmissionEmailAction1) =>
                  this.onChange({ resumeSubmissionEmailAction1 })
                }
                fullWidth
                disabled
              >
                {emailActions}
              </SelectField>
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionName1"
                placeholder="Name"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionName1}
                onChange={(ev, resumeSubmissionName1) =>
                  this.onChange({ resumeSubmissionName1 })
                }
                fullWidth
                disabled
              />
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionEmail1"
                placeholder="Email"
                type="email"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionEmail1}
                onChange={(ev, resumeSubmissionEmail1) =>
                  this.onChange({ resumeSubmissionEmail1, errorEmail1: "" })
                }
                onBlur={ev => {
                  if (
                    !!this.state.resumeSubmissionEmail1 &&
                    !emailRE.test(this.state.resumeSubmissionEmail1)
                  ) {
                    this.setState({
                      snackbar: emailInvalid,
                      errorEmail1: emailError
                    });
                    return false;
                  }
                }}
                errorText={this.state.errorEmail1}
                errorStyle={{ color: "orange" }}
                fullWidth
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col className="col2">
              <SelectField
                name="resumeSubmissionEmailAction2"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionEmailAction2}
                onChange={(ev, i, resumeSubmissionEmailAction2) =>
                  this.onChange({ resumeSubmissionEmailAction2 })
                }
                fullWidth
                disabled
              >
                {emailActions}
              </SelectField>
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionName2"
                placeholder="Name"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionName2}
                onChange={(ev, resumeSubmissionName2) =>
                  this.onChange({ resumeSubmissionName2 })
                }
                fullWidth
                disabled
              />
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionEmail2"
                placeholder="Email"
                type="email"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionEmail2}
                onChange={(ev, resumeSubmissionEmail2) =>
                  this.onChange({ resumeSubmissionEmail2, errorEmail2: "" })
                }
                onBlur={ev => {
                  if (
                    !!this.state.resumeSubmissionEmail2 &&
                    !emailRE.test(this.state.resumeSubmissionEmail2)
                  ) {
                    this.setState({
                      snackbar: emailInvalid,
                      errorEmail2: emailError
                    });
                    return false;
                  }
                }}
                errorText={this.state.errorEmail2}
                errorStyle={{ color: "orange" }}
                fullWidth
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col className="col2">
              <SelectField
                name="resumeSubmissionEmailAction3"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionEmailAction3}
                onChange={(ev, i, resumeSubmissionEmailAction3) =>
                  this.onChange({ resumeSubmissionEmailAction3 })
                }
                fullWidth
                disabled
              >
                {emailActions}
              </SelectField>
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionName3"
                placeholder="Name"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionName3}
                onChange={(ev, resumeSubmissionName3) =>
                  this.onChange({ resumeSubmissionName3 })
                }
                fullWidth
                disabled
              />
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionEmail3"
                placeholder="Email"
                type="email"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionEmail3}
                onChange={(ev, resumeSubmissionEmail3) =>
                  this.onChange({ resumeSubmissionEmail3, errorEmail3: "" })
                }
                onBlur={ev => {
                  if (
                    !!this.state.resumeSubmissionEmail3 &&
                    !emailRE.test(this.state.resumeSubmissionEmail3)
                  ) {
                    this.setState({
                      snackbar: emailInvalid,
                      errorEmail3: emailError
                    });
                    return false;
                  }
                }}
                errorText={this.state.errorEmail3}
                errorStyle={{ color: "orange" }}
                fullWidth
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col className="col2">
              <SelectField
                name="resumeSubmissionEmailAction4"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionEmailAction4}
                onChange={(ev, i, resumeSubmissionEmailAction4) =>
                  this.onChange({ resumeSubmissionEmailAction4 })
                }
                fullWidth
                disabled
              >
                {emailActions}
              </SelectField>
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionName4"
                placeholder="Name"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionName4}
                onChange={(ev, resumeSubmissionName4) =>
                  this.onChange({ resumeSubmissionName4 })
                }
                fullWidth
                disabled
              />
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionEmail4"
                placeholder="Email"
                type="email"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionEmail4}
                onChange={(ev, resumeSubmissionEmail4) =>
                  this.onChange({ resumeSubmissionEmail4, errorEmail4: "" })
                }
                onBlur={ev => {
                  if (
                    !!this.state.resumeSubmissionEmail4 &&
                    !emailRE.test(this.state.resumeSubmissionEmail4)
                  ) {
                    this.setState({
                      snackbar: emailInvalid,
                      errorEmail4: emailError
                    });
                    return false;
                  }
                }}
                errorText={this.state.errorEmail4}
                errorStyle={{ color: "orange" }}
                fullWidth
                disabled
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

class JobEmailsList extends Component {
  constructor() {
    super(...arguments);
    this.state = this.props.job;
  }
  render() {
    let {
      job,
      index,
      EmployerEditController
    } = this.props;
    return (
      <EmailList
        title={`${job.jobTitle}(job)`}
        EntityController={this}
        onChange={
          (emailsList = [], cb) =>
            EmployerEditController.setState(state => {
              state.jobsCollection[index].emailsList = emailsList;
              state.updatedJobs[job.id] = job;
              return state;
            }, cb)
        }
        messageTypeTags={Definition.get('employerMessageType')}
        disabledExclude
      />
    );
  }
}

class Contact extends Component {
  render() {
    const labelStyle = { fontWeight: "600", color: "#59736f" };
    const lineStyle = { borderColor: "#59736f" };
    const EmployerEditController = this.props.parent;
    let {
      state: employer = {}
    } = EmployerEditController;
    return (
      <List className="EmployerForm Basics">
        <ListItem
          className="form-header"
          primaryText="CONTACT"
          disabled={true}
        />
        <Row>
          <Col>
            <label>Contact Name</label>
            <TextField
              name="primaryContactName"
              underlineFocusStyle={lineStyle}
              value={EmployerEditController.state.primaryContactName}
              onChange={(ev, primaryContactName) =>
                EmployerEditController.setState({ primaryContactName })
              }
              fullWidth
            />
          </Col>
          <Col>
            <label>Title / Position</label>
            <TextField
              name="primaryContactTitle"
              underlineFocusStyle={lineStyle}
              value={EmployerEditController.state.primaryContactTitle}
              onChange={(ev, primaryContactTitle) =>
                EmployerEditController.setState({ primaryContactTitle })
              }
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Email</label>
            <TextField
              name="primaryContactEmail"
              underlineFocusStyle={lineStyle}
              value={EmployerEditController.state.primaryContactEmail}
              onChange={(ev, primaryContactEmail) =>
                EmployerEditController.setState({ primaryContactEmail })
              }
              fullWidth
            />
          </Col>
          <Col>
            <label>Phone</label>
            <TextField
              name="primaryContactPhone"
              underlineFocusStyle={lineStyle}
              value={EmployerEditController.state.primaryContactPhone}
              onChange={(ev, primaryContactPhone) =>
                EmployerEditController.setState({ primaryContactPhone })
              }
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>LinkedIn URL</label>
            <TextField
              name="primaryContactLinkedin"
              underlineFocusStyle={lineStyle}
              value={EmployerEditController.state.primaryContactLinkedin}
              onChange={(ev, primaryContactLinkedin) =>
                EmployerEditController.setState({
                  primaryContactLinkedin: formatURL(primaryContactLinkedin)
                })
              }
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <label>Notes</label>
            <TextField
              name="notes"
              floatingLabelFixed={true}
              floatingLabelFocusStyle={labelStyle}
              floatingLabelStyle={labelStyle}
              underlineFocusStyle={lineStyle}
              value={EmployerEditController.state.primaryContactNotes}
              onChange={(ev, primaryContactNotes) =>
                EmployerEditController.setState({ primaryContactNotes })
              }
              rows={5}
              rowsMax={5}
              multiLine={true}
              fullWidth
            />
          </Col>
        </Row>

        <EmailList
          title={`${employer._name}(employer)`}
          EntityController={EmployerEditController}
          messageTypeTags={
            Definition.get('employerMessageType')
              /** 
               * @todo 
               * filtered options by a task request.
               * remove it in future if added more employer message types.
               * 
               * story-3927-M15 | 2021-09-09 Thu µ
               */
              .filter(({ id }) => id === EMP_MSG_TYPE__REMINDER_ID)
          }
        />

        {!!EmployerEditController.state.jobsCollection.length && (
          EmployerEditController.state.jobsCollection.map((job, index) => (
            <JobEmailsList
              key={`job-emails-list-${job.id}`}
              job={job}
              index={index}
              EmployerEditController={EmployerEditController}
            />
          ))
        )}

        {/**
         * @todo
         *
         * DEPRECATED
         * 
         * Review to cleanup
         *
         * story-3927-m7 | 2021-09-03 Fri µ
         *
         */}
        <div className="p-1 m-2 rounded" style={{ border: "2px dotted #ccc", filter: 'grayscale(1)' }}>
          <Row><Col><label>DEPRECATED</label></Col></Row>
          {!!EmployerEditController.state.jobsCollection.length && (
            <>
              <Row>
                <Col fullWidth>
                  <label>Jobs Resume Submission Contacts</label>
                </Col>
              </Row>
              <div className="normal-padding">
                {EmployerEditController.state.jobsCollection.map(job => (
                  <JobElement
                    key={job.id}
                    model={job}
                    onChange={job =>
                      EmployerEditController.setState(state => {
                        state.updatedJobs[job.id] = job;
                        state.jobsCollection.find((jobC, index) => {
                          if (jobC.id === job.id) {
                            state.jobsCollection[index] = job;
                            return true;
                          }
                          return false;
                        });
                        return state;
                      })
                    }
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </List>
    );
  }
}

export default Contact;
