import Chip from '@mui/material/Chip';
import React, { Component, Fragment } from "react";
import { matchSkillWithText } from "../../../../lib/tools/cvMatch";
import { makeDecision } from "../Decision/Decision";

class LoctionDetails extends Component {
  constructor() {
    super(...arguments);
    this.state = {};
  }

  getDisplayColor = () => {
    const {
      candidateText,
      jobText
    } = this.props;

    if (!candidateText | !jobText) {
      return null;
    }

    if (candidateText.length > jobText.length) {
      return matchSkillWithText(jobText, candidateText);
    } else if (candidateText.length < jobText.length) {
      return matchSkillWithText(candidateText, jobText);
    } else {
      if (candidateText === jobText) {
        return true;
      }
    }

    return false;
  }

  getDisplayLabel = () => {
    const {
      candidateText,
      jobText,
      source
    } = this.props;

    let label = null;

    if (source === 'candidate') {
      return candidateText;
    } else if (source === 'job') {
      return jobText;
    }

    return label;
  }

  render() {

    const { candidateText, jobText, source } = this.props;

    if (source === 'candidate' && !candidateText) {
      return null;
    }
    if (source === 'job' && !jobText) {
      return null;
    }

    return (
      <Fragment>
        <Chip
          className="slim-chip"
          variant="outlined"
          size="small"
          style={makeDecision(this.getDisplayColor())}
          label={this.getDisplayLabel()}
        />
      </Fragment>
    );
  }
}

export default LoctionDetails;