import React, { Component, Fragment } from "react";
// import { NavLink } from "react-router-dom";

import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
import Divider from "material-ui/Divider";
import FloatingActionButton from "material-ui/FloatingActionButton";
import Paper from "material-ui/Paper";
import EditorModeEdit from "material-ui/svg-icons/editor/mode-edit";

import Core, { colors } from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import DetailItem from "../../Items/DetailItem";
import { FlatButton } from "material-ui";

class JobProfile extends Component {
  openEditJob = ev => {
    Core.dialog.open({
      title: <>Edit Job</>,
      style: {
        width: "70vw",
        minWidth: "320px",
        maxWidth: "none"
      },
      message: (
        <iframe
          className="covered"
          title="Edit Job"
          style={{ height: "70vh", minHeight: "375px" }}
          src={`/#/job/edit/${this.props.parent.state.id}`}
        />
      ),
      actions: [
        <FlatButton
          label="Reload"
          title="If you change something, reload the page"
          className="button-flat-darker"
          onClick={ev => document.location.reload()}
        />,
        <FlatButton
          label="Close"
          title="If you only reviewed the info"
          className="button-white-cyan"
          onClick={ev => Core.dialog.close()}
        />
      ]
    });
  };
  render() {
    const parent = this.props.parent;
    return (
      <Paper className="match-profile" zDepth={2}>
        <Card
          className="profile-header"
          style={{ backgroundColor: colors.purple }}
        >
          <CardHeader
            avatar={<i className="material-icons">business_center</i>}
            title={
              <Fragment>
                {parent.state.employer.name}
                <br />
                {parent.state.jobTitle}
                {`${parent.state._level ? ` - ${parent.state._level}` : ""}`}
              </Fragment>
            }
            titleStyle={{ fontSize: 22 }}
            subtitle={
              <Fragment>
                {`${parent.state.addressCity}${
                  parent.state.employer.employeeCount
                    ? `, ${parent.state.employer.employeeCount} employees`
                    : ""
                }${
                  parent.state.employer.stage
                    ? `, ${Definition.getLabel(
                        "stage",
                        parent.state.employer.stage
                      )}`
                    : "—"
                }${
                  parent.state.employer.teamCount
                    ? `, ${parent.state.employer.teamCount} engineers`
                    : ""
                }`}
              </Fragment>
            }
          />
          <CardText>
            <div
              dangerouslySetInnerHTML={{
                __html: parent.state.employer.tagline
              }}
            />
          </CardText>
          {Core.isAdmin() && (
            <CardActions>
              <FloatingActionButton
                style={{
                  position: "absolute",
                  bottom: -20,
                  right: 20
                }}
                onClick={this.openEditJob}
              >
                <EditorModeEdit />
              </FloatingActionButton>
            </CardActions>
          )}
        </Card>
        <div className="match-profile-details">
          <DetailItem label="Job Visa" value={parent.state._visaTransfer} />
          <DetailItem
              label="Minimum Years of Experience"
              value={parent.state.minYearsOfExperience}
          />
          <DetailItem
            label="Ideal Years of Experience"
            value={`${parent.state.idealMinExperience} ~ ${
              parent.state.idealMaxExperience
            }`}
          />


          {!!(
              parent.state.salaryMax
          ) && (
              <DetailItem
                  label="Max Salary"
                  value={`$${parent.state.salaryMax}`}
              />
          )}
          {!!(parent.state.equityMin || parent.state.equityMax) && (
            <DetailItem
              label="Equity Range"
              value={`${parent.state.equityMin}% ~ ${parent.state.equityMax}%`}
            />
          )}
          <DetailItem
            label="Work From Home"
            value={
              parent.state.remote &&
              Definition.getLabel("remote", parent.state.remote)
            }
          />
          <Divider />
          <CardHeader
            avatar={<i className="material-icons">local_offer</i>}
            title="Tags"
          />
          <DetailItem
            label="Technical Skills"
            value={parent.state._technicalSkills}
          />
          <DetailItem label="Experience" value={parent.state._experience} />
          <DetailItem
            label="Positive Signals"
            value={parent.state._positiveSignals}
          />
          <DetailItem
            label="Negative Signals"
            value={parent.state._negativeSignals}
          />
          <Divider />
          <CardHeader
            avatar={<i className="material-icons">note</i>}
            title="Notes"
          />
          {!!parent.state.additionalNotes.length && (
            <DetailItem
              label="Candidates Absolutly MUST or MUST NOT have"
              value={
                <div
                  className="match-profile-html-item"
                  dangerouslySetInnerHTML={{
                    __html: parent.state.additionalNotes
                  }}
                />
              }
            />
          )}
          {!!parent.state.qualificationNotes.length && (
            <DetailItem
              label="Insider Scoop"
              value={
                <div
                  className="match-profile-html-item"
                  dangerouslySetInnerHTML={{
                    __html: parent.state.qualificationNotes
                  }}
                />
              }
            />
          )}
        </div>
      </Paper>
    );
  }
}

export default JobProfile;
