/** ============================================ µ
 * @description Tree Select Dropdown [JSX]
 *              Customized for Locations Tags.
 *              UI Component
 * @createdAt   2021-06-02
 * @updatedAt   2021-11-17 Wed
 * ============================================ */
/* IMPORTS ==================================== */

import { TreeSelect } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LocationLib from '../../lib/DefinitionLocation.lib';

/* CONSTANTS ================================== */

const { SHOW_PARENT } = TreeSelect;

/* FUNCTIONS ================================== */

/* METHODS ==================================== */

function TreeSelectDropdown(props) {
  const {
    data = [],
    value,
    onChange = () => null,
    onSearch = () => null,
    onFocus,
    layers = [],
    multiple = true,
    placeholder = null,
    treeDefaultExpandedIds = [],
    dropdownMatchSelectWidth = true,
    listHeight = '50vh',
    onDropdownVisibleChange,
    size = 'middle',
    maxTagCount = 'tags',
    autoFocus,
    disabled,
    className,
  } = props;
  const { t } = useTranslation();

  const ref = React.useRef();
  const [_value, setValue] = React.useState([]);

  let values = !multiple
    ? value
    : value
      ? [
        ...value,
        /**
           * @todo
           * Experimental
           * Trying to make alias expandable
           * 2021-06-11
           * µ
           * /
          ...value.map(v => `alias-child-7:${v}`)
          /** */
      ]
      : _value;

  let treeData = [];

  treeData = LocationLib.getTreeData(data, LocationLib.getRootIds({ data }));

  if (!!layers.length) {
    treeData = LocationLib.filterTreeDataLayers({ treeData, layers });
  }

  // console.debug({ treeData, layers });

  const treeDefaultExpandedKeys = LocationLib.findTreeDataNodesByTagId(
    treeData,
    treeDefaultExpandedIds
  ).map((n) => n.key);

  const autoFocusRef = React.useRef(autoFocus);
  autoFocusRef.current = autoFocus;
  React.useEffect(() => {
    // console.debug('µ:mounting');
    autoFocusRef.current && ref.current.focus();
    return () => {
      // console.debug('µ:unmounting');
    };
  }, []);

  function _onChange(value) {
    /**
     * @todo
     * Experimental
     * Trying to make alias expandable
     * 2021-06-11
     * µ
     */
    /** * /
    value = value.map(v => (typeof v === 'string') ? Number(v.split(':').pop()) : v);
    /** */

    /* epic-3038(new locations)-story-3675-m1 | 2021-08-04 Wed µ */
    if (multiple) {
      value = value.map((v) => v.value);
    }

    // console.debug("µ", "onChange ", value);

    setValue(value);
    onChange({ value });
  }

  function useDropdownVisibleChange(open) {
    return {
      _onDropdownVisibleChange: (open) => {
        onDropdownVisibleChange
          ? onDropdownVisibleChange(open)
          : open && onSearch('');
      },
    };
  }
  const { _onDropdownVisibleChange } = useDropdownVisibleChange();

  useEffect(() => {
    if (!!value?.length && !data?.length) {
      _onDropdownVisibleChange(true);
    }
  }, [_onDropdownVisibleChange, data, value]);

  // console.debug({ values, treeData });
  const _props = {
    allowClear: true,
    treeData,
    value: values,
    onChange: _onChange,
    onSearch,
    onFocus,
    treeCheckable: multiple,
    showSearch: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: placeholder ? placeholder : t('treeSelect.placeholder'),
    treeNodeFilterProp: 'label',
    treeNodeLabelProp: 'label',
    style: { width: '100%' },
    className,
    listHeight,
    virtual: false,
    treeDefaultExpandedKeys,

    dropdownMatchSelectWidth,
    dropdownStyle: { borderRadius: 4 },

    size,
    disabled,
    maxTagCount,

    /* epic-3038(new locations)-story-3675-m1 | 2021-08-04 Wed µ */
    treeCheckStrictly: multiple,

    onDropdownVisibleChange: _onDropdownVisibleChange,

  };

  // console.debug('TreeSelectProps', props, _props);
  return <TreeSelect ref={ref} {..._props} />;
}

/* EXPORTS ==================================== */

export { TreeSelectDropdown as default, TreeSelectDropdown };

/* ============================================ */
