/** ============================================ µ
 * @Description AccountEdit [JSX]
 *              UI Component
 * @route       /account/edit/:accountId
 * @updatedAt   2021-09-02
 * @updatedAt   2021-12-08 Wed
 * ============================================ */
/* IMPORTS ==================================== */

import AppBar from "material-ui/AppBar";
import DatePicker from "material-ui/DatePicker";
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import MenuItem from "material-ui/MenuItem";
import Paper from "material-ui/Paper";
import {
  RadioButton,
  RadioButtonGroup
} from "material-ui/RadioButton";
import SelectField from "material-ui/SelectField";
import Snackbar from "material-ui/Snackbar";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import TextField from "material-ui/TextField";
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import Account from "../../../lib/Account";
import Core from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import Employer from "../../../lib/Employer";
import Google from "../../../lib/Google";
import HistoryLog from "../../../lib/HistoryLog";
import Job from "../../../lib/Job";
import { getAccountModel } from "../../../lib/models/account";
import Store from "../../../lib/Store";
import formatURL from "../../../lib/tools/formatURL";
import getEmployerSourceListApis from "../../../lib/tools/getEmployerSourceListApis";
import getJobSourceListApis from "../../../lib/tools/getJobSourceListApis";
import getStateModel from "../../../lib/tools/getStateModel";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import EmailPreview from "../../Dialogs/EmailPreview";
import SuccessDialog from "../../Dialogs/Success";
import Col from "../../Forms/Col";
import EmailList from "../../Forms/EmailsList";
import RichTextBox from "../../Forms/RichTextBox";
import Row from "../../Forms/Row";
import styles from "../../Forms/Styles";
import HistoryMenu from "../../HistoryMenu/HistoryMenu";
import SelectListDialog from "./SelectListDialog";

/* MAIN CLASS ================================= */

class AccountEdit extends Component {
  selectListDialog;
  continue = 0;
  confirm = false;
  constructor() {
    super(...arguments);
    this.state = {
      ...getAccountModel({ extended: true }),
      /** controller states */
      accounts: [],
      recruiters: [],
      snackbar: null,
      errorFirstName: "",
      errorLastName: "",
      errorRole: "",
      errorEmail: "",
      errorPhone: "",
      errorPassword: "",
      errorRetype: "",
      state: Definition.getId("state", "Draft"),
      existingAccountJobSource: [],
      accountJobSourceListIdsFromServer: [],
      accountJobSourceList: [],
      recruitersRemovedJobs: [],
      recruitersAddedJobs: [],
      newRecruitersRemovedJobs: [],
      recruiterAllJobs: [],
      existingAccountEmployerSource: [],
      accountEmployerSourceListIdsFromServer: [],
      accountEmployerSourceList: [],
      recruitersRemovedEmployers: [],
      recruitersAddedEmployers: [],
      newRecruitersRemovedEmployers: [],
      recruiterAllEmployers: [],
      _roles: [],
    };
    this._getRemovedRecruiterJobIdsFlag = true;
    this._getRemovedRecruiterEmployerIdsFlag = true;
    Store.set("path", window.location.href);
  }
  componentDidMount = () => {
    setTimeout(() => {
      Account.getRoles((roles) => {
        this.setState({
          _roles: roles
            .map((item) => {
              switch (item.name) {
                case "SysAdmin":
                  item.order = 1;
                  break;
                case "TrustedRecruiter":
                  item.order = 2;
                  break;
                case "LimitedRecruiter":
                  item.order = 3;
                  break;
                default:
              }
              return item;
            })
            .sort((a, b) => {
              a = a.order;
              b = b.order;
              return a > b ? 1 : a < b ? -1 : 0;
            }),
        });
      });
      /** LOAD EMPLOYER DATA to the current state */
      if (this.props.match.params.id) {
        Account.get(this.props.match.params.id, (model) => {
          this.setState({ ...model }, (then) =>
            HistoryLog.set({ group: "accounts", label: this.state._name })
          );
          this.getRoleMapping(this.props.match.params.id);
          this.getAccountRecruiterWhiteList(this.props.match.params.id);
          this.getAccountEmployerBlackList(this.props.match.params.id);
          this.getAccountJobBlackList(this.props.match.params.id);
          this.getAccountEmployerSourceList(this.props.match.params.id);
          this.getAccountJobSourceList(this.props.match.params.id);
        });
      }
    }, 300);
  }
  fetchRecruiterAllJobs = () => {
    getJobSourceListApis.getAllJobsOfAdmin(this.state.id, (response) => {
      let endedJobs = [];
      let addedJobs = [];

      response.forEach((resp) => {
        if (resp.endDate === null) {
          addedJobs.push(resp.jobId);
        } else {
          endedJobs.push(resp.jobId);
        }
      });
      this.setState({
        recruitersRemovedJobs: endedJobs,
        recruitersAddedJobs: addedJobs,
        recruiterAllJobs: response,
      });
    });
  };
  fetchRecruiterAllEmployers = () => {
    getEmployerSourceListApis.getAllEmployersOfAdmin(
      this.state.id,
      (response) => {
        let endedEmployers = [];
        let addedEmployers = [];

        response.forEach((resp) => {
          if (resp.endDate === null) {
            addedEmployers.push(resp.employerId);
          } else {
            endedEmployers.push(resp.employerId);
          }
        });
        this.setState({
          recruitersRemovedEmployers: endedEmployers,
          recruitersAddedEmployers: addedEmployers,
          recruiterAllEmployers: response,
        });
      }
    );
  };
  componentDidUpdate() {
    if (
      Core.isAdmin() &&
      this.state.id &&
      this._getRemovedRecruiterJobIdsFlag
    ) {
      this._getRemovedRecruiterJobIdsFlag = false;
      this.fetchRecruiterAllJobs();
    }
    if (
      Core.isAdmin() &&
      this.state.id &&
      this._getRemovedRecruiterEmployerIdsFlag
    ) {
      this._getRemovedRecruiterEmployerIdsFlag = false;
      this.fetchRecruiterAllEmployers();
    }
  }

  getRoleMapping(accountId) {
    Account.getRoleMapping(accountId, (roleMap) => {
      if (roleMap) {
        this.setState({
          roleMapId: roleMap.id,
          roleMap,
          realRoleId: roleMap.roleId,
          realRole: roleMap.role,
        });
      }
    });
  }

  getAccountRecruiterWhiteList(accountId) {
    Account.getRecruiterWhiteList(accountId, (accountRecruiterWhiteList) =>
      this.setState({ accountRecruiterWhiteList })
    );
  }
  getAccountEmployerBlackList(accountId) {
    Account.getEmployerBlackList(accountId, (accountEmployerBlackList) =>
      this.setState({ accountEmployerBlackList })
    );
  }
  getAccountJobBlackList(accountId) {
    Account.getJobBlackList(accountId, (accountJobBlackList) =>
      this.setState({ accountJobBlackList })
    );
  }
  getAccountEmployerSourceList(accountId) {
    Account.getEmployerSourceList(accountId, (accountEmployerSourceList) =>
      this.setState({
        accountEmployerSourceList,
        existingAccountEmployerSource: accountEmployerSourceList.map(
          (job) => job.id
        ),
        accountEmployerSourceListIdsFromServer: accountEmployerSourceList.map(
          (job) => job.id
        ),
      })
    );
  }
  getAccountJobSourceList(accountId) {
    Account.getJobSourceList(accountId, (accountJobSourceList) =>
      this.setState({
        accountJobSourceList,
        accountJobSourceListIdsFromServer: accountJobSourceList.map(
          (job) => job.id
        ),
        existingAccountJobSource: accountJobSourceList.map((job) => job.id),
      })
    );
  }

  getFullname = (em) => {
    return (this.state.firstName + " " + this.state.lastName).trim();
  };
  openMessage = (ev) => {
    const emails = [];
    const account = this.state;
    account.email &&
      emails.push({
        name: account._name || "Account",
        email: account.email,
      });
    Core.dialog.open({
      title: <>Message</>,
      message: (
        <EmailPreview
          ref={(self) => (this.EmailMessage = self)}
          emails={emails}
          to={!!emails[0] && [emails[0]]}
          subject=""
          body={[].filter((line) => !!line).join("<br/>")}
        />
      ),
      className: "p-0",
      actions: [
        <FlatButton
          label="Cancel"
          className="button-flat-darker"
          onClick={(ev) => {
            Core.dialog.close();
          }}
        />,
        <FlatButton
          label="Send"
          className="button-white-cyan"
          onClick={(ev) => {
            Core.dialog.close();
            Google.sendEmail(
              {
                ...this.EmailMessage.getParams(),
                source: "From Account Edit, AccountEdit.js line 263",
              },
              (response) => Core.showMessage("Email sent"),
              (error) => Core.showFailure(error)
            );
          }}
        />,
      ],
    });
  };

  sortSourceLists = (accountSourceList, addedJob, endedJobs, newJobs) => {
    let sortedByEndDate = [];

    accountSourceList.forEach((item) => {
      if (!!addedJob.includes(item.id)) {
        sortedByEndDate.unshift(item);
      } else if (!!endedJobs.includes(item.id)) {
        sortedByEndDate.push(item);
      }
    });

    accountSourceList.forEach((item) => {
      if (!!newJobs.includes(item.id)) {
        sortedByEndDate.push(item);
      }
    });

    return sortedByEndDate;
  };

  endJob = (id, key, accountSourceList) => () => {
    let popupName = accountSourceList
      .filter((job) => job.id === id)
      .map((job) => job._name)
      .pop();
    this.ConfirmDialog.open({
      message: `Do you want to pause the ${key} - ${popupName} for the recruiter ?`,
      onConfirm: (ev) => {
        const otherFields = {
          who: Core.getSessionEmail(),
        };

        if (key === "Job") {
          const {
            recruitersRemovedJobs,
            recruitersAddedJobs,
            recruiterAllJobs,
          } = this.state;

          getJobSourceListApis.deleteRecruiterJobByAdmin(
            id,
            this.state.id,
            otherFields,
            (response) => {
              const witoutThisJob = recruiterAllJobs.filter(
                (job) => job.jobId !== response.jobId
              );
              const newRecruiterALlJobs = [...witoutThisJob, response];
              this.setState({
                recruitersRemovedJobs: [...recruitersRemovedJobs, id],
                recruitersAddedJobs: recruitersAddedJobs.filter(
                  (job) => job !== id
                ),
                recruiterAllJobs: newRecruiterALlJobs,
              });
            }
          );
        } else if (key === "Employer") {
          const {
            recruitersRemovedEmployers,
            recruitersAddedEmployers,
            recruiterAllEmployers,
          } = this.state;
          getEmployerSourceListApis.deleteRecruiterEmployerByAdmin(
            id,
            this.state.id,
            otherFields,
            (response) => {
              const witoutThisEmployer = recruiterAllEmployers.filter(
                (emp) => emp.employerId !== response.employerId
              );
              const newRecruiterAllEmployers = [
                ...witoutThisEmployer,
                response,
              ];
              this.setState({
                recruitersRemovedEmployers: [...recruitersRemovedEmployers, id],
                recruitersAddedEmployers: recruitersAddedEmployers.filter(
                  (job) => job !== id
                ),
                recruiterAllEmployers: newRecruiterAllEmployers,
              });
            }
          );
        }
      },
    });
  };

  resumeJob = (id, key, accountSourceList) => () => {
    let popupName = accountSourceList
      .filter((job) => job.id === id)
      .map((job) => job._name)
      .pop();
    this.ConfirmDialog.open({
      message: `Do you want to resume the ${key} - ${popupName} for the recruiter`,
      onConfirm: (ev) => {
        const otherFields = {
          who: Core.getSessionEmail(),
        };

        if (key === "Job") {
          const {
            recruitersRemovedJobs,
            recruitersAddedJobs,
            recruiterAllJobs,
          } = this.state;
          getJobSourceListApis.resumeRecruiterJobByAdmin(
            id,
            this.state.id,
            otherFields,
            (response) => {
              const witoutThisJob = recruiterAllJobs.filter(
                (job) => job.jobId !== response.jobId
              );
              const newRecruiterALlJobs = [...witoutThisJob, response];
              this.setState({
                recruitersAddedJobs: [...recruitersAddedJobs, id],
                recruitersRemovedJobs: recruitersRemovedJobs.filter(
                  (job) => job !== id
                ),
                recruiterAllJobs: newRecruiterALlJobs,
              });
            }
          );
        }
        if (key === "Employer") {
          const {
            recruitersRemovedEmployers,
            recruitersAddedEmployers,
            recruiterAllEmployers,
          } = this.state;
          getEmployerSourceListApis.resumeRecruiterEmployerByAdmin(
            id,
            this.state.id,
            otherFields,
            (response) => {
              const witoutThisEmployer = recruiterAllEmployers.filter(
                (emp) => emp.employerId !== response.employerId
              );
              const newRecruiterAllEmployers = [
                ...witoutThisEmployer,
                response,
              ];
              this.setState({
                recruitersAddedEmployers: [...recruitersAddedEmployers, id],
                recruitersRemovedEmployers: recruitersRemovedEmployers.filter(
                  (job) => job !== id
                ),
                recruiterAllEmployers: newRecruiterAllEmployers,
              });
            }
          );
        }
      },
    });
  };

  getLabelsOfSourceList = (
    id,
    removedJobs,
    addedJobs,
    accountSourceList,
    key
  ) => {
    let label = null;

    if (addedJobs.includes(id)) {
      label = (
        <Fragment>
          <span
            title="Pause Job"
            className="rec-actions"
            style={{ backgroundColor: "red" }}
            onClick={this.endJob(id, key, accountSourceList)}
          >
            Pause {key}
          </span>
        </Fragment>
      );
    }
    if (removedJobs.includes(id)) {
      label = (
        <Fragment>
          <span
            title="Resume Job"
            className="rec-actions"
            style={{ backgroundColor: "green" }}
            onClick={this.resumeJob(id, key, accountSourceList)}
          >
            Resume {key}
          </span>
        </Fragment>
      );
    }

    return label;
  };

  showDates = (id, recruiterObject, sourceKey) => {
    let date = [];
    let label = "",
      formatDate = "";

    recruiterObject.forEach((obj) => {
      if (obj[sourceKey] === id) {
        if (!!obj.startDate) {
          formatDate = new Date(obj.startDate);
          date.push(
            `${formatDate.getMonth() + 1
            }/${formatDate.getDate()}/${formatDate.getFullYear()}`
          );
        }
        if (!!obj.endDate) {
          formatDate = new Date(obj.endDate);
          date.push(
            `${formatDate.getMonth() + 1
            }/${formatDate.getDate()}/${formatDate.getFullYear()}`
          );
        }
        label = <span>({date.join(" - ")})</span>;
      }
    });

    return label;
  };

  render() {

    console.debug(this.state);

    const {
      accountJobSourceList,
      recruitersRemovedJobs,
      recruitersAddedJobs,
      newRecruitersRemovedJobs,

      accountEmployerSourceList,
      recruitersRemovedEmployers,
      recruitersAddedEmployers,
      newRecruitersRemovedEmployers,
    } = this.state;

    // console.log("logout", Core.isLoggedOut());
    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }
    if (Core.isRecruiter()) {
      return <Redirect to="/" />;
    }
    const lineStyle = { borderColor: "#59736f" };
    const required = "This field is required";
    // const niceToHave = "Please enter to get better match";
    /** BACK BUTTON */
    const goBack = (event) => {
      cancel();
    };
    /** CANCEL BUTTON */
    const cancel = (event) => {
      Core.goBack(this.props);
    };
    /** DONE BUTTON */
    const save = (event) => {
      if (!Core.isAdmin()) {
        Core.showFailure("This action is restricted to Administrators");
        return false;
      }
      this.setState({ state: Definition.getId("state", "Draft") });
      setTimeout(submit);
    };
    /**
     * SUBMIT
     */
    const submit = (event) => {
      if (!Core.isAdmin()) {
        Core.showFailure("This action is restricted to Administrators");
        return false;
      }
      if (isValid()) {
        const {
          recruitersRemovedJobs,
          accountJobSourceListIdsFromServer,
          existingAccountJobSource,
          existingAccountEmployerSource,
          accountEmployerSourceListIdsFromServer,
        } = this.state;
        /** IF ALL FIELDS ARE VALID */
        if (this.state.id) {
          /** DELETE JOB**/
          const differenceInJobs = accountJobSourceListIdsFromServer.filter(
            (sJob) => !existingAccountJobSource.includes(sJob)
          );
          differenceInJobs.forEach((difIds) => {
            getJobSourceListApis.removeJobByAdmin(
              difIds,
              this.state.id,
              {},
              (response) => { }
            );
          });
          /** UPDATE */
          Account.update(
            this.state.id,
            getStateModel(this.state, getAccountModel()),
            this.state.roleMapId,
            this.state.realRoleId,
            {
              accountRecruiterWhiteList: this.state.accountRecruiterWhiteList,
              accountEmployerBlackList: this.state.accountEmployerBlackList,
              accountJobBlackList: this.state.accountJobBlackList,
              accountEmployerSourceList: this.state.accountEmployerSourceList,
              accountJobSourceList: this.state.accountJobSourceList,
            },
            (response) => {
              /* DELETE EMPLOYER **/
              const differenceInEmployers = accountEmployerSourceListIdsFromServer.filter(
                (sEmp) => !existingAccountEmployerSource.includes(sEmp)
              );
              differenceInEmployers.forEach((difIds) => {
                getEmployerSourceListApis.removeEmployerByAdmin(
                  difIds,
                  this.state.id,
                  {},
                  (response) => { }
                );
              });
              this.setState(
                {
                  id: response.id,
                  recruitersAddedJobs: accountJobSourceList
                    .filter((job) => !recruitersRemovedJobs.includes(job.id))
                    .map((job) => job.id),
                  newRecruitersRemovedJobs: [],

                  accountJobSourceListIdsFromServer: accountJobSourceList.map(
                    (job) => job.id
                  ),
                  existingAccountJobSource: accountJobSourceList.map(
                    (job) => job.id
                  ),

                  accountEmployerSourceListIdsFromServer: accountEmployerSourceList.map(
                    (job) => job.id
                  ),
                  existingAccountEmployerSource: accountEmployerSourceList.map(
                    (job) => job.id
                  ),

                  recruitersAddedEmployers: accountEmployerSourceList
                    .filter(
                      (emp) => !recruitersRemovedEmployers.includes(emp.id)
                    )
                    .map((emp) => emp.id),
                  newRecruitersRemovedEmployers: [],
                },
                () => {
                  setTimeout(() => {
                    this.fetchRecruiterAllJobs();
                    this.fetchRecruiterAllEmployers();
                  }, 3000);
                }
              );
              this.getRoleMapping(response.id);
              this.refs.successDialog.open("User has been successfully updated");
            }
          );
        } else {
          /** CREATE */
          Account.post(
            getStateModel(this.state, getAccountModel()),
            this.state.realRoleId,
            {
              accountRecruiterWhiteList: this.state.accountRecruiterWhiteList,
              accountEmployerBlackList: this.state.accountEmployerBlackList,
              accountJobBlackList: this.state.accountJobBlackList,
              accountEmployerSourceList: this.state.accountEmployerSourceList,
              accountJobSourceList: this.state.accountJobSourceList,
            },
            (response) => {
              /* DELETE EMPLOYER **/
              const differenceInEmployers = accountEmployerSourceListIdsFromServer.filter(
                (sEmp) => !existingAccountEmployerSource.includes(sEmp)
              );
              differenceInEmployers.forEach((difIds) => {
                getEmployerSourceListApis.removeEmployerByAdmin(
                  difIds,
                  this.state.id,
                  {},
                  (response) => { }
                );
              });
              this.setState(
                {
                  id: response.id,
                  recruitersAddedJobs: accountJobSourceList
                    .filter((job) => !recruitersRemovedJobs.includes(job.id))
                    .map((job) => job.id),
                  newRecruitersRemovedJobs: [],

                  accountJobSourceListIdsFromServer: accountJobSourceList.map(
                    (job) => job.id
                  ),
                  existingAccountJobSource: accountJobSourceList.map(
                    (job) => job.id
                  ),

                  accountEmployerSourceListIdsFromServer: accountEmployerSourceList.map(
                    (job) => job.id
                  ),
                  existingAccountEmployerSource: accountEmployerSourceList.map(
                    (job) => job.id
                  ),

                  recruitersAddedEmployers: accountEmployerSourceList
                    .filter(
                      (emp) => !recruitersRemovedEmployers.includes(emp.id)
                    )
                    .map((emp) => emp.id),
                  newRecruitersRemovedEmployers: [],
                },
                () => {
                  setTimeout(() => {
                    this.fetchRecruiterAllJobs();
                    this.fetchRecruiterAllEmployers();
                  }, 3000);
                }
              );
              this.getRoleMapping(response.id);
              this.refs.successDialog.open("User has been successfully created");
            }
          );
        }
      }
    };
    /**
     * VALIDATIONS
     */
    const isValid = (event) => {
      /** FIRST NAME: required */
      if (!String(this.state.firstName).trim().length) {
        window.scrollTo(0, 0);
        this.setState({
          snackbar: "First Name is required",
          errorFirstName: required,
        });
        return false;
      }
      /** LAST NAME: required */
      if (!String(this.state.lastName).trim().length) {
        window.scrollTo(0, 0);
        this.setState({
          snackbar: "Last Name is required",
          errorLastName: required,
        });
        return false;
      }
      /** ROLE: required */
      if (!String(this.state.realRoleId).trim().length) {
        window.scrollTo(0, 0);
        this.setState({
          snackbar: "Role is required",
          errorRole: required,
        });
        return false;
      }
      /** EMAIL: required */
      if (!String(this.state.email).trim().length) {
        window.scrollTo(0, 0);
        this.setState({
          snackbar: "Email is required",
          errorEmail: required,
        });
        return false;
      }
      /** EMAIL: validation */
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.state.email
        )
      ) {
        window.scrollTo(0, 0);
        this.setState({
          snackbar: "Email is invdalid",
          errorEmail: "Please enter a valid email",
        });
        return false;
      }
      /** PHONE: required */
      if (!String(this.state.phone).trim().length) {
        window.scrollTo(0, 0);
        this.setState({
          snackbar: "Phone is required",
          errorPhone: required,
        });
        return false;
      }
      /** PASSWORD */
      if (
        !this.props.match.params.id &&
        !String(this.state.password).trim().length
      ) {
        window.scrollTo(0, 0);
        this.setState({
          snackbar: "Password is required",
          errorPassword: required,
        });
        return false;
      }
      /** PASSWORD: retype */
      if (
        (!!String(this.state.password).trim().length ||
          !!String(this.state.retype).trim().length) &&
        this.state.password !== this.state.retype
      ) {
        window.scrollTo(0, 0);
        this.setState({
          snackbar: "Password retype does not match",
          errorRetype: "No match with password",
        });
        return false;
      }
      /** ALL TESTS PASSED */
      return true;
    };
    return (
      <MuiThemeProvider>
        <>
          <div className="edit account">
            <div className="appBar" style={styles.AppBar}>
              <AppBar
                title={
                  (this.props.match.params.id ? "Edit " : "New ") +
                  "Account" +
                  (!!this.getFullname() ? " - " + this.getFullname() : "")
                }
                titleStyle={styles.AppBar.title}
                style={styles.AppBar.nav}
                iconElementLeft={
                  <FlatButton
                    icon={<i className="material-icons">arrow_back</i>}
                    style={styles.AppBar.backButton}
                    onClick={goBack}
                  />
                }
                iconElementRight={
                  <Fragment>
                    <IconButton
                      className="float-right"
                      onClick={this.openMessage}
                    >
                      <i className="material-icons">email</i>
                    </IconButton>
                    <HistoryMenu />
                  </Fragment>
                }
              />
            </div>
            <Paper className="form" style={styles.Paper} zDepth={1}>
              <FlatButton
                label="SAVE"
                className="form-save-btn"
                onClick={submit}
              />
              <Row style={{ paddingTop: "32px" }}>
                <Col>
                  <label>
                    First Name <b className="cred">*</b>
                  </label>
                  <TextField
                    name="firstName"
                    type="text"
                    underlineFocusStyle={lineStyle}
                    value={this.state.firstName}
                    onChange={(event, firstName) => {
                      this.setState({ firstName, errorFirstName: "" });
                    }}
                    required
                    fullWidth
                    errorText={this.state.errorFirstName}
                  />
                </Col>
                <Col>
                  <label>
                    Last Name <b className="cred">*</b>
                  </label>
                  <TextField
                    name="lastName"
                    type="text"
                    underlineFocusStyle={lineStyle}
                    value={this.state.lastName}
                    onChange={(event, lastName) => {
                      this.setState({ lastName, errorLastName: "" });
                    }}
                    required
                    fullWidth
                    errorText={this.state.errorLastName}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Nickname</label>
                  <TextField
                    name="preferredName"
                    type="text"
                    underlineFocusStyle={lineStyle}
                    value={this.state.preferredName}
                    onChange={(event, preferredName) =>
                      this.setState({ preferredName })
                    }
                    fullWidth
                  />
                </Col>
                <Col>
                  <label>Talent Advisor</label>
                  <TextField
                    name="talentAdvisor"
                    type="text"
                    underlineFocusStyle={lineStyle}
                    value={this.state.talentAdvisor}
                    onChange={(event, talentAdvisor) =>
                      this.setState({ talentAdvisor })
                    }
                    fullWidth
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Title</label>
                  <TextField
                    name="title"
                    type="text"
                    underlineFocusStyle={lineStyle}
                    value={this.state.title}
                    onChange={(event, title) => {
                      this.setState({ title });
                    }}
                    fullWidth
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>
                    Role <b className="cred">*</b>
                  </label>
                  <SelectField
                    name="role"
                    underlineFocusStyle={lineStyle}
                    value={this.state.realRoleId}
                    maxHeight={640}
                    onChange={(event, index, realRoleId) => {
                      const realRole = this.state._roles.find(
                        (realRole) => realRole.id === realRoleId
                      );
                      this.setState({
                        realRoleId,
                        realRole,
                        role: realRole.name,
                        errorRole: "",
                      });
                    }}
                    fullWidth
                    errorText={this.state.errorRole}
                  >
                    {this.state._roles.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        primaryText={item.description}
                      />
                    ))}
                  </SelectField>
                </Col>
                <Col>
                  <label>Company Name</label>
                  <TextField
                    name="companyName"
                    type="text"
                    underlineFocusStyle={lineStyle}
                    value={this.state.companyName}
                    onChange={(event, companyName) => {
                      this.setState({ companyName });
                    }}
                    fullWidth
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>
                    Email <b className="cred">*</b>
                  </label>
                  <TextField
                    name="email"
                    type="email"
                    underlineFocusStyle={lineStyle}
                    value={this.state.email}
                    onChange={(event, email) => {
                      email = String(email).toLowerCase();
                      this.setState({ email, errorEmail: "" });
                    }}
                    required
                    fullWidth
                    errorText={this.state.errorEmail}
                  />
                </Col>
                <Col>
                  <label>
                    Phone <b className="cred">*</b>
                  </label>
                  <TextField
                    name="phone"
                    type="phone"
                    underlineFocusStyle={lineStyle}
                    value={this.state.phone}
                    onChange={(event, phone) => {
                      this.setState({ phone, errorPhone: "" });
                    }}
                    required
                    fullWidth
                    errorText={this.state.errorPhone}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Additional Email</label>
                  <TextField
                    name="additionalEmail"
                    type="email"
                    underlineFocusStyle={lineStyle}
                    value={this.state.additionalEmail}
                    onChange={(event, additionalEmail) => {
                      this.setState({ additionalEmail });
                    }}
                    fullWidth
                  />
                </Col>
                <Col>
                  <label>Additional Phone</label>
                  <TextField
                    name="additionalPhone"
                    type="phone"
                    underlineFocusStyle={lineStyle}
                    value={this.state.additionalPhone}
                    onChange={(event, additionalPhone) => {
                      this.setState({ additionalPhone });
                    }}
                    fullWidth
                  />
                </Col>
              </Row>
              <EmailList
                EntityController={this}
                messageTypeTags={Definition.get('recruiterMessageType')}
              />
              <Row>
                <Col>
                  <label>LinkedIn</label>
                  <TextField
                    name="linkedin"
                    type="text"
                    underlineFocusStyle={lineStyle}
                    value={this.state.linkedInUrl}
                    onChange={(event, linkedInUrl) =>
                      this.setState({ linkedInUrl: formatURL(linkedInUrl) })
                    }
                    fullWidth
                  />
                </Col>
                <Col>
                  <label>Calendar</label>
                  <TextField
                    name="calendar"
                    type="text"
                    underlineFocusStyle={lineStyle}
                    value={this.state.calendarUrl}
                    onChange={(event, calendarUrl) => {
                      this.setState({ calendarUrl });
                    }}
                    fullWidth
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>
                    {this.props.match.params.id ? (
                      "Enter Password"
                    ) : (
                      <Fragment>
                        Password <b className="cred">*</b>
                      </Fragment>
                    )}
                  </label>
                  <form>
                    <TextField
                      name="password"
                      type="password"
                      autoComplete="password"
                      underlineFocusStyle={lineStyle}
                      value={this.state.password}
                      onChange={(event, password) => {
                        this.setState({ password, errorPassword: "" });
                      }}
                      required={!this.props.match.params.id}
                      fullWidth
                      errorText={this.state.errorPassword}
                    />
                  </form>
                </Col>
                <Col>
                  <label>Confirm Password</label>
                  <form>
                    <TextField
                      name="retype"
                      type="password"
                      autoComplete="retype"
                      underlineFocusStyle={lineStyle}
                      value={this.state.retype}
                      onChange={(event, retype) => {
                        this.setState({ retype, errorRetype: "" });
                      }}
                      fullWidth
                      errorText={this.state.errorRetype}
                    />
                  </form>
                </Col>
              </Row>
              <Row />

              <Row>
                <Col>
                  <label>LinkedIn Extension Id</label>
                  <form>
                    <TextField
                      name="Linkedin Extension key"
                      type="text"
                      autoComplete="extensionId"
                      underlineFocusStyle={lineStyle}
                      value={this.state.extensionId}
                      onChange={(event, extensionId) => {
                        this.setState({ extensionId, errorRetype: "" });
                      }}
                      fullWidth
                    />
                  </form>
                </Col>
              </Row>
              <Row>
                <Col fullWidth>
                  <label>Notes</label>
                  <RichTextBox
                    name="publicNotes"
                    value={this.state.publicNotes}
                    onChange={publicNotes => {
                      this.setState({
                        publicNotes,
                      });
                    }}
                  />
                </Col>
              </Row>
              <div className="green-block py-1 rounded">
                <Row>
                  <Col fullWidth>
                    <h4>For 10 by 10 use only</h4>
                  </Col>
                </Row>
                <Row className="edit-account-list-row">
                  <Col className="edit-account-list-col">
                    <label>Black List Employers</label>
                    <IconButton
                      className="icon-button"
                      children={<i className="material-icons">create</i>}
                      onClick={(ev) => {
                        Employer.getAll((employers) => {
                          this.selectListDialog.open(
                            "Black List Employers",
                            employers,
                            this.state.accountEmployerBlackList.map(
                              (item) => item.id
                            ),
                            (accountEmployerBlackList) =>
                              this.setState({ accountEmployerBlackList })
                          );
                        });
                      }}
                    />
                    {this.state.accountEmployerBlackList.map((item) => (
                      <li key={item.id}>{item._name || <i>&mdash;</i>}</li>
                    ))}
                  </Col>
                  <Col className="edit-account-list-col">
                    <label>Black List Job</label>
                    <IconButton
                      className="icon-button"
                      children={<i className="material-icons">create</i>}
                      onClick={(ev) => {
                        Job.getActives((jobs) => {
                          this.selectListDialog.open(
                            "Black List Jobs",
                            jobs,
                            this.state.accountJobBlackList.map(
                              (item) => item.id
                            ),
                            (accountJobBlackList) =>
                              this.setState({ accountJobBlackList })
                          );
                        });
                      }}
                    />
                    {this.state.accountJobBlackList.map((item) => (
                      <li key={item.id}>{item._name || <i>&mdash;</i>}</li>
                    ))}
                  </Col>
                </Row>
                <Row className="edit-account-list-row">
                  <Col className="edit-account-list-col">
                    <label>Source List Employers</label>
                    <IconButton
                      className="icon-button"
                      children={<i className="material-icons">create</i>}
                      onClick={(ev) => {
                        Employer.getAll((employers) => {
                          this.selectListDialog.open(
                            "Source List Employers",
                            employers,
                            this.state.accountEmployerSourceList.map(
                              (item) => item.id
                            ),
                            (accountEmployerSourceList) => {
                              const concatServerEmployers = [
                                ...recruitersRemovedEmployers,
                                ...recruitersAddedEmployers,
                              ];
                              let onlyEmpFromPopup = [];
                              if (!!concatServerEmployers.length) {
                                onlyEmpFromPopup = accountEmployerSourceList
                                  .filter(
                                    (emp) =>
                                      !concatServerEmployers.includes(emp.id)
                                  )
                                  .map((emp) => emp.id);
                              } else {
                                onlyEmpFromPopup = accountEmployerSourceList.map(
                                  (emp) => emp.id
                                );
                              }

                              this.setState({
                                accountEmployerSourceList,
                                newRecruitersRemovedEmployers: onlyEmpFromPopup,
                                existingAccountEmployerSource: accountEmployerSourceList.map(
                                  (job) => job.id
                                ),
                              });
                            }
                          );
                        });
                      }}
                    />
                    {this.sortSourceLists(
                      this.state.accountEmployerSourceList,
                      recruitersAddedEmployers,
                      recruitersRemovedEmployers,
                      newRecruitersRemovedEmployers
                    ).map((item) => (
                      <li key={item.id}>
                        {item._name || <i>&mdash;</i>}&nbsp;&nbsp;
                        {this.showDates(
                          item.id,
                          this.state.recruiterAllEmployers,
                          "employerId"
                        )}
                        &nbsp;&nbsp;
                        {this.getLabelsOfSourceList(
                          item.id,
                          recruitersRemovedEmployers,
                          recruitersAddedEmployers,
                          this.state.accountEmployerSourceList,
                          "Employer"
                        )}
                      </li>
                    ))}
                  </Col>
                  <Col className="edit-account-list-col">
                    <label>Source List Job</label>
                    <IconButton
                      className="icon-button"
                      children={<i className="material-icons">create</i>}
                      onClick={(ev) => {
                        Job.getActives((jobs) => {
                          let notInQuery = this.state.accountJobSourceList.filter(
                            (j) => !jobs.map((el) => el.id).includes(j.id)
                          );
                          let combined = [...jobs, ...notInQuery];

                          this.selectListDialog.open(
                            "Source List Jobs",
                            combined,
                            this.state.accountJobSourceList.map(
                              (item) => item.id
                            ),
                            (accountJobSourceList) => {
                              let oldIds = this.state.accountJobSourceList.map(
                                (el) => el.id
                              );
                              const concatServerJobs = [
                                ...recruitersRemovedJobs,
                                ...recruitersAddedJobs,
                              ];
                              let onlyJobFromPopup = [];
                              if (!!concatServerJobs.length) {
                                onlyJobFromPopup = accountJobSourceList
                                  .filter(
                                    (job) => !concatServerJobs.includes(job.id)
                                  )
                                  .map((job) => job.id);
                              } else {
                                onlyJobFromPopup = accountJobSourceList.map(
                                  (job) => job.id
                                );
                              }

                              if (
                                oldIds.filter(
                                  (el) =>
                                    !accountJobSourceList
                                      .map((item) => item.id)
                                      .includes(el)
                                ).length
                              ) {
                                if (
                                  window.confirm(
                                    "you want to delete the the job for the recruiter, can't be undo.  If you want to pause, please use pause, if you don't see pause then save record first"
                                  )
                                ) {
                                  this.setState({
                                    accountJobSourceList,
                                    newRecruitersRemovedJobs: onlyJobFromPopup,
                                    existingAccountJobSource: accountJobSourceList.map(
                                      (job) => job.id
                                    ),
                                  });
                                }
                              } else {
                                this.setState({
                                  accountJobSourceList,
                                  newRecruitersRemovedJobs: onlyJobFromPopup,
                                  existingAccountJobSource: accountJobSourceList.map(
                                    (job) => job.id
                                  ),
                                });
                              }
                            }
                          );
                        });
                      }}
                    />
                    {this.sortSourceLists(
                      this.state.accountJobSourceList,
                      recruitersAddedJobs,
                      recruitersRemovedJobs,
                      newRecruitersRemovedJobs
                    ).map((item) => (
                      <Fragment>
                        <li key={item.id}>
                          {item._name || <i>&mdash;</i>}&nbsp;&nbsp;
                          {this.showDates(
                            item.id,
                            this.state.recruiterAllJobs,
                            "jobId"
                          )}
                          &nbsp;&nbsp;
                          {this.getLabelsOfSourceList(
                            item.id,
                            recruitersRemovedJobs,
                            recruitersAddedJobs,
                            this.state.accountJobSourceList,
                            "Job"
                          )}
                        </li>
                      </Fragment>
                    ))}
                  </Col>
                </Row>
                <Row className="edit-account-list-row">
                  <Col className="edit-account-list-col">
                    <label>White List Employers</label>
                    <IconButton
                      className="icon-button"
                      children={<i className="material-icons">create</i>}
                      onClick={(ev) => {
                        Employer.getAll((employers) => {
                          this.selectListDialog.open(
                            "White List Recruiters",
                            employers,
                            this.state.accountRecruiterWhiteList.map(
                              (item) => item.id
                            ),
                            (accountRecruiterWhiteList) =>
                              this.setState({ accountRecruiterWhiteList })
                          );
                        });
                      }}
                    />
                    {this.state.accountRecruiterWhiteList.map((item) => (
                      <li key={item.id}>{item._name || <i>&mdash;</i>}</li>
                    ))}
                  </Col>
                  <Col />
                </Row>
                <SelectListDialog
                  ref={(selectListDialog) =>
                    (this.selectListDialog = selectListDialog)
                  }
                  parent={this}
                />
                <Row>
                  <Col fullWidth>
                    <label>Test Bucket</label>
                    <RadioButtonGroup
                      name="testBucket"
                      className="radio-button-group"
                      style={{ width: "100%" }}
                      valueSelected={this.state.testBucket}
                      onChange={(event, testBucket) => {
                        this.setState({ testBucket });
                      }}
                      fullWidth
                    >
                      {Definition.get("testBucket")
                        .filter((tag) =>
                          Core.isAdminOrCoordinator()
                            ? true
                            : Definition.test("testBucket", tag.id, /Beta1/)
                        )
                        .map((tag) => (
                          <RadioButton
                            key={tag.id}
                            value={tag.id}
                            label={tag.label}
                            style={{ marginBottom: 16 }}
                          />
                        ))}
                    </RadioButtonGroup>
                  </Col>
                </Row>
                <Row>
                  <Col fullWidth>
                    <label>Current State</label>
                    <RadioButtonGroup
                      name="state"
                      className="radio-button-group"
                      style={{ width: "100%" }}
                      valueSelected={this.state.state}
                      onChange={(event, state) => {
                        this.setState({ state });
                      }}
                      fullWidth
                    >
                      {Definition.get("state")
                        .filter((tag) =>
                          Core.isAdminOrCoordinator()
                            ? true
                            : Definition.test("state", tag.id, /draft/)
                        )
                        .map((tag) => (
                          <RadioButton
                            key={tag.id}
                            value={tag.id}
                            label={tag.label}
                            style={{ marginBottom: 16 }}
                          />
                        ))}
                    </RadioButtonGroup>
                  </Col>
                </Row>
                <Row>
                  <Col fullWidth>
                    <label>Private Notes</label>
                    <RichTextBox
                      name="privateNotes"
                      value={this.state.privateNotes}
                      onChange={privateNotes => {
                        this.setState({
                          privateNotes,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <Row>
                <Col>
                  <label>Creation Date</label>
                  <DatePicker
                    name="createdAt"
                    underlineFocusStyle={lineStyle}
                    value={
                      this.state.createdAt
                        ? new Date(this.state.createdAt)
                        : null
                    }
                    onChange={(ev, createdAt) => {
                      this.setState({ createdAt: createdAt.toISOString() });
                    }}
                    fullWidth
                  />
                </Col>
                <Col>
                  <label>Expiration Date</label>
                  <DatePicker
                    name="expirationDate"
                    underlineFocusStyle={lineStyle}
                    value={
                      this.state.expirationDate
                        ? new Date(this.state.expirationDate)
                        : null
                    }
                    onChange={(ev, expirationDate) => {
                      this.setState({
                        expirationDate: expirationDate.toISOString(),
                      });
                    }}
                    fullWidth
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Closed Date</label>
                  <DatePicker
                    name="closeDate"
                    underlineFocusStyle={lineStyle}
                    value={
                      this.state.closeDate
                        ? new Date(this.state.closeDate)
                        : null
                    }
                    onChange={(ev, closeDate) => {
                      this.setState({ closeDate: closeDate.toISOString() });
                    }}
                    fullWidth
                  />
                </Col>
                <Col>
                  <label>Last Update</label>
                  <DatePicker
                    name="updatedAt"
                    underlineFocusStyle={lineStyle}
                    value={
                      this.state.updatedAt
                        ? new Date(this.state.updatedAt)
                        : null
                    }
                    onChange={(ev, updatedAt) => {
                      this.setState({ updatedAt: updatedAt.toISOString() });
                    }}
                    fullWidth
                  />
                </Col>
              </Row>
              <Row style={{ padding: "16px 0" }}>
                <Col>
                  <FlatButton
                    label="CANCEL"
                    style={styles.List.cancel}
                    onClick={goBack}
                  />
                  {(!this.state.state ||
                    Definition.test("state", this.state.state, /draft/)) && (
                      <FlatButton
                        label="SAVE DRAFT"
                        style={styles.List.save}
                        onClick={save}
                      />
                    )}
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <FlatButton
                    label="DONE"
                    style={styles.List.button}
                    onClick={submit}
                  />
                </Col>
              </Row>
              <SuccessDialog
                ref="successDialog"
                modal={true}
                actions={[
                  <FlatButton
                    label="STAY"
                    primary={true}
                    onClick={(ev) => this.refs.successDialog.close()}
                  />,
                  <FlatButton
                    label="DONE"
                    primary={true}
                    keyboardFocused={true}
                    onClick={(ev) => Core.go({ ...this.props, to: '/accounts/' })}
                  />,
                ]}
              />
              <SuccessDialog
                ref="confirmDialog"
                modal={true}
                actions={[
                  <FlatButton
                    label="CANCEL"
                    primary={true}
                    onClick={(event) => this.refs.confirmDialog.close()}
                  />,
                  <FlatButton
                    label="CONFIRM"
                    primary={true}
                    keyboardFocused={true}
                    onClick={(event) => {
                      this.refs.confirmDialog.close();
                      this.confirm = true;
                      submit();
                    }}
                  />,
                ]}
              />
            </Paper>
            <Snackbar
              open={!!this.state.snackbar}
              message={this.state.snackbar || ""}
              className="snack-bar"
              autoHideDuration={6000}
              onRequestClose={(ev) => this.setState({ snackbar: null })}
            />
          </div>
          <ConfirmDialog
            ref={(self) => (this.ConfirmDialog = self)}
            title="Confirmation"
            message=""
            actionLabel="Confirm"
          />
        </>
      </MuiThemeProvider>
    );
  }
}

/* EXPORTS ==================================== */

export default AccountEdit;

/* ============================================ */
