/** ============================================ µ
 * @description GlobalChipsBar [JSX]
 *              EnhancedReactTable UI Component
 * @createdAt   2021-10-29 Fri
 * @updatedAt   2021-11-01 Mon
 * ============================================ */
/* IMPORTS ==================================== */

import { Chip, Icon } from '@mui/material';
import moment from 'moment';
import React from 'react';
import Definition from '../../lib/Definition';
import { DATE_FORMAT } from './FilterDateRangeColumn';
import {
  REACT_TABLE__DEFAULT_PAGE_SIZE,
  REACT_TABLE__INITIAL_PAGE
} from './useEnhancedReactTable.hook';
import { newUrlState } from './useUrlQuery.hook';

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function GlobalChipsBar({
  enhancedReactTableContext: {
    t,
    allColumns = [],
    filters = {},
    globalSearchValue = [],
    page = REACT_TABLE__INITIAL_PAGE,
    pageSize = REACT_TABLE__DEFAULT_PAGE_SIZE,
    sort = {},
    resume,
    looseMatch,
    blacklisted,
    _updatedAt,
    updateUrlState = () => null,
    setFilter,
  },
  style,
  className,
}) {

  const columns = {};

  allColumns.forEach(column => columns[column.id] = column);

  // console.debug('allColumns', columns);

  const list = [];

  Object.keys(filters).forEach((columnId, index) => {
    // list.push(<span key={`${columnId}-${index}`} className="mr-1">{columnId}:</span>);
    let column = columns[columnId] || {};
    let {
      _categoryKey: categoryKey
    } = column;
    let columnFilter = filters[columnId];

    // Filter contains an ARRAY of values
    if (Array.isArray(columnFilter)) {
      columnFilter.forEach(value => {
        let _value = categoryKey ? Definition.getLabel(categoryKey, value) : value;
        const label = (
          <>
            <span className="c-gray">{column.Header}: </span>
            <span>{_value}</span>
          </>
        );
        list.push(
          renderChip({
            columnId,
            value,
            label,
            columnFilter,
            onDelete: () => {
              console.debug('You clicked the delete icon.');
              setFilter({ columnId, value: columnFilter.filter(v => v !== value) });
            }
          })
        );
      });
    }
    // Filter contains an OBJECT
    else if (Object(columnFilter) === columnFilter) {
      if (columnFilter['$lte']) {
        let value = columnFilter['$lte'];
        value = (Number(value) === value) ? value : moment(value).format(DATE_FORMAT);
        const label = (
          <>
            <span className="c-gray">{column.Header}: </span>
            <span>lte {value}</span>
          </>
        );
        list.push(
          renderChip({
            columnId,
            value,
            label,
            columnFilter,
            onDelete: () => {
              console.debug('You clicked the delete icon.');
              delete columnFilter.$lte;
              setFilter({ columnId, value: Object.keys(columnFilter).length ? columnFilter : undefined });
            }
          })
        );
      }
      if (columnFilter['$gte']) {
        let value = columnFilter['$gte'];
        value = (Number(value) === value) ? value : moment(value).format(DATE_FORMAT);
        const label = (
          <>
            <span className="c-gray">{column.Header}: </span>
            <span>gte {value}</span>
          </>
        );
        list.push(
          renderChip({
            columnId,
            value,
            label,
            columnFilter,
            onDelete: () => {
              console.debug('You clicked the delete icon.');
              delete columnFilter.$gte;
              setFilter({ columnId, value: Object.keys(columnFilter).length ? columnFilter : undefined });
            }
          })
        );
      }
    }
    // Filter contains a NUMBER
    else if (!isNaN(columnFilter)) {
      let _value = categoryKey ? Definition.getLabel(categoryKey, columnFilter) : columnFilter;
      const label = (
        <>
          <span className="c-gray">{column.Header}: </span>
          <span>{_value}</span>
        </>
      );
      list.push(
        renderChip({
          columnId,
          value: columnFilter,
          label,
          columnFilter,
          onDelete: () => {
            console.debug('You clicked the delete icon.');
            setFilter({ columnId, value: undefined });
          }
        })
      );
    }
  });

  // console.debug('filters', filters);

  if (list.length) {
    list.unshift(
      renderChip({
        columnId: 'resetFilters',
        value: 'resetFilters',
        label: 'Reset Filters',
        onClick: () => {
          console.debug('You clicked the Chip.');
          updateUrlState({
            ...newUrlState(),
            globalSearchValue,
            resume,
            looseMatch,
            blacklisted
          });
        }
      })
    );
  }

  return (
    <div className="d-flex flex-align-left-center flex-wrap" style={{ position: 'sticky', left: '1rem', width: 'calc(100vw - 3rem)' }}>
      {list}
    </div>
  );
}

function renderChip({ columnId, value, label, columnFilter, onClick, onDelete }) {
  return (
    <Chip
      key={`chip-${columnId}-${value}`}
      variant={onClick ? undefined : 'outlined'}
      color={onClick ? 'primary' : undefined}
      icon={
        onClick
          ? <Icon fontSize="small" >filter_list_off</Icon>
          : undefined
      }
      size="small"
      label={label}
      className="mr-1 mb-1"
      onClick={onClick}
      onDelete={onDelete}
    />
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { GlobalChipsBar as default, GlobalChipsBar };

/* ============================================ */
