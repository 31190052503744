import React, { Component } from "react";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";

import Core from "../../../lib/Core";
import Streak from "../../../lib/Streak";
import "../../Dialogs/Modal.css";
import Definition from "../../../lib/Definition";
import {TextField} from "material-ui";

const styles = {
  underlineStyle: {
    borderColor: "#715EFF"
  }
};

class ModalUpdateStatus extends Component {
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      open: false,
      model: null,
      stage: null,
      status: null,
      rejectionReason: null,
      state: null,
      stages: null,
      statuses: null,
      rejectionReasons: null,
      states: null,
      key: null,
      description: ""
    };
    this.statuses = {};
  }
  open = model => {
    this.setState({
      model,
      stages: Streak.getDropdownStrings("Stage").map(stage => (
        <MenuItem value={stage} key={stage} primaryText={stage} />
      )),
      statuses: Streak.getDropdownStrings("Status")
        .sort((a, b) => a.localeCompare(b))
        .map(status => (
          <MenuItem value={status} key={status} primaryText={status} />
        )),
      states: Streak.getDropdownStrings("State").map(state => (
        <MenuItem value={state} key={state} primaryText={state} />
      )),
      rejectionReasons: Streak.getDropdownStrings("RejectionReason")
            .sort((a, b) => a.localeCompare(b)).map(reason => (
        <MenuItem value={reason} key={reason} primaryText={reason} />
      )),

      matchStrengths: Definition.get("engagementMatchStrength").map(strength => (
        <MenuItem value={strength.id} key={strength.id} primaryText={strength.label} />
      )),
      stage: model.stage,
      status: model.status,
      state: model.state,
      engagement: model.engagement,
      rejectionReason: model.rejectionReason,
      matchStrength: model.matchStrength,
      cbUpdateField: model.cbUpdateField,
      rejectionReasonAdditionalInfo: !!model.engagement ? (model.engagement.rejectionReasonAdditionalInfo || model.engagement.rejectionReason):'',
      open: true,
    }, ()=>{
    });
  };
  close = () => {
    this.setState({ open: false });
  };
  submit = event => {
    this.close();
    const update = {
      stage: this.state.stage,
      status: this.state.status,
      state: this.state.state,
      rejectionReason: this.state.rejectionReason,
      matchStrength:this.state.matchStrength,
      rejectionReasonAdditionalInfo: this.state.rejectionReasonAdditionalInfo
    };
    const newDate = new Date();
    const newISO = newDate.toISOString();
    [
      [/submission|review|screen|onsite|offer|hire|guarantee/i, "confirmed"],
      [/review|screen|onsite|offer|hire|guarantee/i, "submitted"],
      [/screen|onsite|offer|hire|guarantee/i, "reviewed"],
      [/onsite|offer|hire|guarantee/i, "screened"],
      [/offer|hire|guarantee/i, "onsite"],
      [/hire|guarantee/i, "offered"]
    ].forEach(item => {
      if (item[0].test(update.stage) && !this.parent.state[item[1]]) {
        update[item[1]] = newISO;
        Core.log(item[1]);
      }
    });
    let fields = update;

    if(!!this.state.engagement ) {
      if((this.state.matchStrength !== this.state.engagement.matchStrength)) {
        fields = {...fields, matchedByWho: Core.getUser(), lastMatchedDate:new Date().toISOString()};
      }
    }else{
      //it means its a new entry
      fields = {...fields, matchedByWho: Core.getUser(), lastMatchedDate:new Date().toISOString()}
    }

    Core.log("Modal Update Status from Submit", "fields", fields);
    if(!!this.state.cbUpdateField){
      this.state.cbUpdateField(this.state.engagement,fields);
    }else{
      this.parent.updateField(fields);
    }

  };
  setStage = (event, index, stage) => {
    this.setState({ stage });
  };
  setMatchStrength = (event, index, matchStrength) => {
    this.setState({ matchStrength });
  };

  setAddInfo = (event, rejectionReasonAdditionalInfo) => {
    this.setState({ rejectionReasonAdditionalInfo });
  };

  setReason = (event, index, rejectionReason) => {
    this.setState({ rejectionReason });
  };
  setStatus = (event, index, status) => {
    this.setState({ status });
  };
  setSt = (event, index, state) => {
    this.setState({ state });
  };
  onChangeDescription = event => {
    this.setState({
      description: event.target.value
    });
  };
  render() {
    const actions = [
      <FlatButton label="Cancel" primary={true} onClick={this.close} />,
      <FlatButton label="Submit" primary={true} onClick={this.submit} />
    ];

    let {hasRejectionReason} = this.props;

    return (
      <Dialog
        title="Update Status"
        actions={actions}
        modal={true}
        contentStyle={{ width: 400 }}
        open={this.state.open}
      >
        <div className="Modal-block">
          Stage
          <SelectField
            name="stage"
            value={this.state.stage}
            onChange={this.setStage}
            underlineFocusStyle={styles.underlineStyle}
            style={{ float: "right" }}
          >
            {this.state.stages}
          </SelectField>
        </div>
        <div className="Modal-block">
          Status
          <SelectField
            name="status"
            value={this.state.status}
            onChange={this.setStatus}
            underlineFocusStyle={styles.underlineStyle}
            style={{ float: "right" }}
          >
            {this.state.statuses}
          </SelectField>
        </div>
        <div className="Modal-block">
          State
          <SelectField
            name="state"
            value={this.state.state}
            onChange={this.setSt}
            underlineFocusStyle={styles.underlineStyle}
            style={{ float: "right" }}
          >
            {this.state.states}
          </SelectField>
        </div>
        <div className="Modal-block">
          Strength
          <SelectField
              name="matchStrength"
              value={this.state.matchStrength}
              onChange={this.setMatchStrength}
              underlineFocusStyle={styles.underlineStyle}
              style={{ float: "right" }}
          >

            {this.state.matchStrengths}
          </SelectField>
        </div>

        {hasRejectionReason && <div className="Modal-block">
          Rej Reason
          <SelectField
            name="rejectionReason"
            value={this.state.rejectionReason}
            onChange={this.setReason}
            underlineFocusStyle={styles.underlineStyle}
            style={{ float: "right" }}
          >
            <MenuItem value={""} key={""} primaryText={"Select None"}  style={{ color: 'silver' }} />
            {this.state.rejectionReasons}
          </SelectField>
        </div>}

        {hasRejectionReason &&  <div className="Modal-block">
          RR-Add Info &nbsp;
          <TextField
              name={`rejectionReasonAdditionalInfo`}
              value={this.state.rejectionReasonAdditionalInfo}
              onChange={this.setAddInfo}
              multiLine={true}
              onBlur={ev => {
              }}
              rowsMax={5}
              errorText={""}
          />
        </div>}




      </Dialog>
    );
  }
}

export default ModalUpdateStatus;
