import React, { Component, Fragment } from "react";

import { DatePicker, FlatButton, IconButton } from "material-ui";

import { TextField } from "@mui/material";

import Core from "../../../lib/Core";

import moment from "moment";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {FULLDAY_MILL_SECOND_REFERENCE, FULLDAY_SECOND_REFERENCE} from "../../../lib/Constants";
import withImportantStyle from "react-with-important-style";
const MyDiv = withImportantStyle('div');

const theme = createTheme({
  palette: {
    primary: {
      light: "#92a2fa",
      main: "#757ce8",
      dark: "#536dfe",
      contrastText: "#fff"
    }
  }
});

const mdash = "—";
class CustomPicker extends Component {
  constructor() {
    super(...arguments);
    this.parent = this.props.parent.parent;
    this.state = {};
  }
  render() {
    const stage = this.parent.state.stage;
    const {
      value,
      field,
      streakField,
      validStage,
      label,
      onChange,
      isFullday
    } = this.props.args;
    const pickers = this.parent.pickers;
    const updateValue = date => {
      if (Core.isAdminOrCoordinator()) {
        try {
          const update = {};
          const newDate = new Date(date);
          update[field] = newDate.toISOString();
          Core.log("update", update);
          this.parent.updateField(update, null, update => {
            if (onChange instanceof Function) {
              onChange({}, date);
            }
          });
        } catch (ex) {
          console.error(ex);
        }
      }
      this.removeOnDismiss = false;
    };
    const deleteValue = ev => {
      const update = {};
      update[field] = "";
      this.parent.updateField(update);
    };

    const dateFormat = isFullday ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm';
    const dateType = isFullday ? "date" : "datetime-local";

    return /screen\d|onsite\d/i.test(field) ? (
      <ThemeProvider theme={theme}>
        <MyDiv className="display-flex" >
          <TextField
            id="datetime-local"
            inputRef={self => (pickers[field] = self)}
            // label={streakField}
            title={`${streakField}: ${moment(value).format()}`}
            type={dateType}
            className="card-picker-date-tf"
            style={{ display: "block", maxWidth: 200 }}
            defaultValue={moment(value).format(dateFormat)}
            variant="standard"
            onChange={ev => {
              let date = ev.target.value;
              if (!date) {
                //ev.target.blur() &&
                ev.target.focus();
                setTimeout(st => {
                  !pickers[field].value && deleteValue();
                });
              }
              /*
              Core.log({
                ev: ev,
                evNat: ev.nativeEvent.target.value,
                target: ev.target,
                type: typeof ev.target.value,
                value: ev.target.value,
                html: ev.target.outerHTML
                  .match(/value=".*"/)[0]
                  .replace(/"|value=/g, "")
              });
              */

              if(isFullday){
                date = new Date(date);
                date.setSeconds(FULLDAY_SECOND_REFERENCE, FULLDAY_MILL_SECOND_REFERENCE);
              }

              date && updateValue(date);
            }}
            InputLabelProps={{
              shrink: true
            }}
          />

          <MyDiv style={{marginTop: '5px !important'}}>
            <IconButton
              title={`Remove ${streakField}`}
              className="icon16"
              onClick={ev => {
                ev.preventDefault();
                deleteValue();
              }}
            >
              <i className="material-icons">remove_circle_outline</i>
            </IconButton>
          </MyDiv>
        </MyDiv>
      </ThemeProvider>
    ) : (
      <div className="date-picker inline-blocks v-aligns-middle relative">
        {label && (
          <label className="f-small">
            {label}
            :&nbsp;
          </label>
        )}
        {value ? (
          <Fragment>
            <DatePicker
              name={`${stage}${Core.getKey()}`}
              ref={self => (pickers[field] = self)}
              className="card-picker-date"
              formatDate={date => moment(date).format("MM-DD-YYYY")}
              value={value}
              onChange={(ev, date) => {
                date =
                  moment(date).format("YYYY-MM-DD") +
                  "T" +
                  moment()
                    .tz("America/Los_Angeles")
                    /** * /
                    .startOf("day")
                    .add(5, "h")
                    /** */
                    .format("HH:mm:ssZ");
                updateValue(date);
              }}
              onDismiss={ev => {
                Core.log({ onDismiss: this.removeOnDismiss });
                if (this.removeOnDismiss) {
                  const update = {};
                  update[field] = null;
                  this.parent.setState(update, then => {});
                }
                this.removeOnDismiss = false;
              }}
              title={`${streakField}: ${moment(value).format()}`}
              okLabel={label || streakField}
              autoOk={false}
              placeholder={mdash}
              disabled={Core.isRecruiter()}
            />
            {Core.isAdminOrCoordinator() && (
              <IconButton
                title={`Remove ${streakField}`}
                className="btn-remove icon16 none"
                onClick={ev => {
                  ev.preventDefault();
                  deleteValue();
                }}
              >
                <i className="material-icons">remove_circle_outline</i>
              </IconButton>
            )}
          </Fragment>
        ) : (
          <FlatButton
            label={mdash}
            className={`btn-mdash`}
            onClick={ev => {
              if (!validStage || validStage.test(stage)) {
                const update = {};
                update[field] = new Date().toISOString();
                Core.log({ inFirst: this.parent.state[field] });
                this.removeOnDismiss = !this.parent.state[field];
                this.parent.setState(update, then => {
                  pickers[field] &&
                    pickers[field].openDialog &&
                    pickers[field].openDialog();
                });
              }
            }}
            disabled={Core.isRecruiter()}
          />
        )}
      </div>
    );
  }
}

export default CustomPicker;
