import copy from "copy-to-clipboard";
import _ from 'lodash';
import {
  Checkbox,
  FlatButton,
  IconButton,
  IconMenu,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableRowColumn
} from "material-ui";
import { MuiThemeProvider } from "material-ui/styles";
import moment from "moment";
import * as R from 'ramda';
import React, { Component, Fragment } from "react";
import EmailPreview from "../../components/Dialogs/EmailPreview";
import Col from "../../components/Forms/Col";
import Row from "../../components/Forms/Row";
import { DATE_FORMAT } from "../../components/Home/FilterDateRangeColumn";
import Candidate from "../Candidate";
import Core from "../Core";
import Definition from "../Definition";
import Engagement from "../Engagement";
import FilterControlLib from "../FilterControl";
import { getUnitTestingItemId, newModel, NOT } from "../GenericTools.lib";
import Google from "../Google";
import Job from "../Job";
import Streak from "../Streak";
import cleanHTML from "../tools/cleanHtml";
import copyHTML from "../tools/copyHtml";
import downloadFile from "../tools/downloadFile";
import formatMoney from "../tools/formatMoney";
import { mapAccount } from "./account";
import { mapEngagements } from "./engagement";
import { mapStarred } from "./mapStarred.tool";

export const MODEL_NAME_CANDIDATE = 'Candidate';

const mdash = "—";

const modelMatchExclusion = {
  excludeUntil: '', // <iso-date-string>
  reasons: [],      // <int: new `Definition` tag category (matchExclusionReason) with values: `New Job`, `Stay at Job`, and `Pause`, optional>
  note: '',         // <free text, optional>
  updatedBy: '',    // <accountId>
  updatedAt: ''     // <iso-date-string>
}

const model = {
  /** required fields */
  firstName: "",
  lastName: "",
  email: "",
  phone: "",

  // this is an associations it should not in base model | 2021-09-23 Thu µ
  // account: "",

  /** basics step 1 important */
  gitHubURL: "",
  linkedInURL: "",
  stackoverflowUrl: "",
  /** basics step 1 */
  nickName: "",
  country: "",
  diversity: 0,
  currentlyEmployed: 0,
  currentEmployer: "",
  currentTitle: "",
  otherLinks: "", // URLs separated by linebreaks
  resumes: "", // URLs
  jobHopReason4Emp: "",
  jobHopReason4Us: "",
  jobHopDisputeParser: false,
  /** match step 2 */
  undergraduateDegree: "",
  undergraduateSchool: "",
  undergraduateMajor: "",
  level: 0,
  roles: [],
  technicalSkills: [],
  tagLine: "",
  recruiterRating: 0, // "Can the candidate explain skills/experience clearly?"
  platformRating: 0,
  platformRatingNotes: '',
  visa: 0,
  yearsOfExperience: "", // NUMBER, default should not be zero.
  parserTotalMonthsOfExp: null,
  adjustedTotalYearsOfExp: null,
  minCompanySize: "", // NUMBER, default should not be zero.
  maxCompanySize: "", // NUMBER, default should not be zero.
  minimumSalary: 0,
  desiredSalary: 0,
  salarySharableFlag: false,
  salaryNote: "",
  /** @todo TO CLEANUP? 2021-07-01 µ */
  /** µ POSSIBLE FUTURE IMPLEMENTATION * /
  salaryNote: (`
    Min:2, Ideal: 5, Max: 15.
    Check if the numbers exist 2y+, sweet spot: 5y, probably overqualified: 15+
  `).trim().split('\n').map(n => n.trim()).join('\n'),
  /** */
  industry: [],
  employmentHistories: [],
  educationHistories: [],
  technologyDomain: [],
  experienceNotes: "",
  degreeNotes: "",
  motivationNotes: "",
  positiveSignals: [],
  negativeSignals: [],
  jobsLinkedIn: [],
  schoolsLinkedIn: [],
  currentEmployerFrom: "",
  currentEmployerTo: "",
  tags: {},
  draftEmailSentAt: "",
  _status: '',
  jobsPermitted: [],
  graduationYear: "",
  jobsPitched: [],
  jobsDeclined: [],
  resumePdfUrl: "",
  resumeJsonUrl: "",
  resumeTxtUrl: "",
  htmlResumeUrl: "",
  resumeScrubbedUrl: "",
  isOwnedRightNow: undefined, //keep it undefined as I have if statements based upon it
  /** recruiter step 3 */
  accountId: "", // FK, recruiterId.
  relationship: 0,
  acceptOffer: 0,
  otherCompanyInterviews: "",
  bestTimeForCalls: "",
  candidatePreferIds: [], // [NUMBER]
  reasonsToLeaveLastJob: "",
  explainRedFlags: "",
  publicNotes: "",
  jobsHaveCandidatePermission: "",
  jobsWaitingCandidatePermission: "",
  jobsCandidateDeclined: "",
  duplicatedLevel: "",
  duplicatedFrom: "",
  averageMonthsPerEmployer: "",
  submissionNotes: cleanHTML(`
    Please list the jobs your candidate is interested in applying to using the 3 sections above.<br/>
    Why this candidate is interested and qua∂lified for the job?<br/><br/>
    Why this candidate is leaving/left current job?<br/><br/>
    Explain Red/Yellow Flags (short stints, employment gap, contracting background, etc):<br/><br/>
    Additional intake notes:<br/>
  `),
  /** recruiter step 3 green block >>> */
  submissionNotesToEmployer: "",
  //candidateStateTagId: "", // FK
  privateNotes: "",
  /** <<< recruiter step 3 green block */
  holdDate: null,
  wakeUpDate: null,
  searchConfig: null,
  expirationDate: null, // "2018-01-19T15:43:52.163Z",
  closeDate: null, // "2017-12-18T20:51:55.157Z",
  introduced: null, // Date,
  createdAt: null, // MIXIN, "2017-12-15T18:48:33.331Z",
  updatedAt: null, // MIXIN, "2017-12-15T18:48:33.331Z"
  state: 5,
  desiredStage: [],
  /** misc */
  isDuplicate: false,
  /** disabled edition fields */
  jobTypeIds: [], // [NUMBER]
  permittedJobsAnswers: {},
  putDownJobs: [],
  candidateSkills: [],
  techSkillsInNotes: [],
  techSkillsInResume: [],
  posSignalsInResume: [],

  /* epic-3038(new locations)-story-3330-M2 | 2021-07-01 Thu µ */
  /* epic-3038(new locations)-story-3385 | 2021-07-15 Thu µ */
  /* epic-3038(new locations)-story-3573 | 2021-07-28 Wed µ */
  workRemotly: 0,             // old field | remotelyWork(TagId)
  inOfficeRemoteFlags: [],    // new field | inOfficeRemote(TagId)

  /* epic-3038(new locations) 2021-06-11 Fri µ */
  desiredEmploymentTypes: [], // new field | desiredEmploymentType(TagId) - this is to match with Job.jobType

  /* epic-3038(new locations)-story-3385 | 2021-07-15 Thu µ */
  /* epic-3038(new locations)-story-3573 | 2021-07-28 Wed µ */
  workLocationIds: [],        // old field | locationCandidate(TagId)
  officeLocations: [],        // new field | location(TagId)

  /* epic-3038(new locations)-story-3459 | 2021-07-14 Wed µ */
  /* epic-3038(new locations)-story-3573 | 2021-07-28 Wed µ */
  candidateLocations: [],     // new field

  /* epic-3038(new locations)-story-3385 | 2021-07-15 Thu µ */
  locationDetails: "",        // old field(it was just defined on FE)

  // story-3869 | 2021-08-30 Mon µ
  matchExclusions: [
    modelMatchExclusion
  ] // modelMatchExclusions

};

const extended = {
  ___model___: MODEL_NAME_CANDIDATE,
  id: null,
  ...model,
  /** includes */
  recruiter: {},
  engagements: [],
  /** local use */
  engaged: [],
  potentialDuplicatedCandidateList: [],
  starred: false,
  /** mapping */
  _name: "",
  _platformRating: "",
  _visa: "",
  _years: "",
  _tags: "",
  _minimumSalary: "",
  _companiesString: "",
  _boxesCount: "",
  _boxesCountStr: "",
  _technicalSkills: "",
  _positiveSignals: "",
  _negativeSignals: "",
  _officeLocations: "",
  _workOrAdditionalLocations: "",
  _undergraduateDegree: "",
  _isDraft: false,  //keep it false as this is used by default for new record and have if checks
  ___keys___: []
};

const parser = {
  objective: "",
  summary: "",
  technology: "",
  experience: "",
  education: "",
  skills: "",
  languages: "",
  cources: "",
  projects: "",
  links: "",
  contacts: "",
  positions: "",
  profiles: "",
  awards: "",
  honors: "",
  additional: "",
  certification: "",
  interests: "",
  github: {
    name: "",
    location: "",
    email: "",
    link: "",
    joined: "",
    company: ""
  },
  linkedin: {
    summary: "",
    name: "",
    positions: [],
    languages: [],
    skills: [],
    educations: [],
    volunteering: [],
    volunteeringOpportunities: []
  },

  skype: "",
  name: "",
  email: "",
  phone: ""
};

export function mapMatchExclusion(candidate) {
  candidate._matchExclusionsReasons = candidate.matchExclusions
    .map(({ reasons }) => Definition.getLabels('matchExclusionReason', reasons).join(', '))
    .join(', ');
  candidate._matchExclusionsUntil = candidate.matchExclusions
    .map(({ excludeUntil }) => excludeUntil ? moment(excludeUntil).format(DATE_FORMAT) : excludeUntil)
    .filter(v => !!v)
    .join(', ');
}

const mapCandidate = item => {

  const candidate = getCandidateModel({
    extended: true
  });

  const mdash = "—";
  if (item) {
    Object.keys(extended).forEach(
      key => !!item[key] && (candidate[key] = item[key])
    );

    candidate.id = item.id || item._id;
    candidate.submissionNotes = cleanHTML(candidate.submissionNotes);
    candidate._persisted = _.cloneDeep(item);
    candidate.posSignalsInResume = item.posSignalsInResume;
    candidate.isOwnedRightNow = item.isOwnedRightNow;

    if (Core.isProduction() || Core.isStaging()) {
      (candidate.resumes || []).forEach(resume => {
        let existingPath = resume.url || "";
        let relativePath = existingPath.trim().match(/Import.+\w/g);
        if (!!relativePath && Array.isArray(relativePath)) {
          let newCompletePath = `${Core.getApi()}/${relativePath.pop()}`;
          resume.url = newCompletePath;
        }
      });
    }

    if ((!candidate.introduced || candidate.introduced === "") && candidate.draftEmailSentAt) {
      candidate._status = "InDraft";
      candidate._introducedStatus = "introducedInDraft";
    } else {
      candidate._status = "Active";
      candidate._introducedStatus = "introducedActive";
    }

    mapMatchExclusion(candidate);

    if (!!candidate.jobsLinkedIn.length) {
      candidate._companiesString = candidate.jobsLinkedIn.filter(el => !!el.rankingMeta).map(job => {
        let cName = job.employer;
        let acquiredBy = Object(job.rankingMeta).acquiredBy;
        let acquiredByLabel = !!acquiredBy ? `acquired by ${acquiredBy}` : '';
        let tags = '';
        let pSignals = job.positiveSignalsTags;

        if (Array.isArray(pSignals)) {
          tags = pSignals.map(id => Definition.getLabel('positiveSignals', id)).join(' - ');
        }

        let labels = [cName, acquiredByLabel, tags].filter(el => !!el).join(' - ');
        return `Worked at ${labels}`;
      }).join(',');
    }

    mapStarred({ model: candidate, starredList: (item.candidateStarreds || []) });

    // map recruiter used on common loopback endpoints | 2021-09-16 Thu µ
    candidate.recruiter = mapAccount(item.account);

    // map account to be used in v3 | 2021-09-16 Thu µ
    candidate.account = mapAccount(item.account);

    candidate.engagements = mapEngagements(item.engagements);

    /*
     * Remove the whitespace and http:// or https:// from url for comparison
     */
    candidate._linkedInURL = candidate.linkedInURL;
    try {
      candidate._linkedInURL = candidate.linkedInURL.trim().match(/linked.+\w/g).pop();
    } catch (e) {
    }

    /*
     *  Remove the whitespace, the trailing / and http:// and https:// from the url for comparison
     *  We can't use the same expression as we did for linkedin because there are 2 github
     *  URL styles github.com/<something> and <something>.github.io.
     *
     *  NOTE - do not combine multiple statements in one try/catch as if the first one fails
     *  the additional instructions are not executed.
     */
    candidate._gitHubURL = candidate.gitHubURL;

    try {
      candidate._gitHubURL = candidate.gitHubURL.trim().replace(/http+s?:\/\//g, "").replace(/\/*$/, "");
    } catch (e) {
    }

    candidate._isDraft = candidate.id && !candidate.introduced;
    /* mapping stuff */
    candidate._starred = candidate.starred ? "Starred: True" : "Starred: False";
    candidate._engagementsLength = Number(candidate.engagements.length);
    candidate._name =
      `${candidate.firstName || ""} ${candidate.lastName || ""}`.trim() ||
      mdash;
    candidate._recruiterName = candidate.recruiter._name;
    candidate._companyName = candidate.recruiter.companyName;
    candidate._name_rating = candidate._name;
    if (Core.isAdminOrCoordinator() && candidate.platformRating) {
      candidate._name_rating +=
        " (" +
        String(Definition.getLabel("platformRating", candidate.platformRating))
          .slice(0, 2)
          .trim() +
        ")";
    }

    let linkdInTechnicalChips = [];
    if (!!candidate.jobsLinkedIn.length) {
      linkdInTechnicalChips = candidate.jobsLinkedIn.filter(job => !!job.chips).map(job => job.chips).flat();
    }
    candidate._strongTechnicalSkills = Array.from(new Set([
      ...candidate.techSkillsInNotes,
      ...linkdInTechnicalChips
    ]));

    if (!candidate.yearsOfExperience || parseInt(candidate.yearsOfExperience) <= 0) {
      let currentYear = (new Date()).getFullYear();
      let mine = parseInt(candidate.graduationYear);
      candidate.yearsOfExperience = (currentYear - mine) || -1;
    }

    candidate._yearsOfExperienceForCalc = (parseInt(candidate.yearsOfExperience) > -1) ? parseInt(candidate.yearsOfExperience) : 0;

    candidate._years = (candidate.yearsOfExperience && candidate.yearsOfExperience > -1)
      ? `${candidate.yearsOfExperience} years`
      : mdash;
    candidate._minimumSalary = formatMoney(candidate.minimumSalary, 0);
    candidate._minimumSalary =
      candidate._minimumSalary !== "0"
        ? `$${formatMoney(candidate.minimumSalary, 0)}`
        : mdash;
    candidate._boxesCount = candidate.engagements.filter(
      eng => !!eng.boxKey
    ).length;
    candidate._boxesCountStr =
      !!candidate._boxesCount && `${candidate._boxesCount} engagements`;

    const bestTimeForCalls =
      String(candidate.bestTimeForCalls).split(" - ") || [];
    const bestTimeFrom = moment(bestTimeForCalls[0], "hh:mma");
    const bestTimeTo = moment(bestTimeForCalls[1], "hh:mma");
    candidate._bestTimeFrom = null;
    candidate._bestTimeTo = null;
    if (bestTimeFrom.isValid()) {
      candidate._bestTimeFrom = bestTimeFrom.toDate();
    }
    if (bestTimeTo.isValid()) {
      candidate._bestTimeTo = bestTimeTo.toDate();
    }

    if (!candidate.workLocationIds.length) {
      candidate.workLocationIds.push(0);
    }
    candidate._allBayArea = [...candidate.workLocationIds].find(id => id === 1);
    candidate._officeLocations = candidate._allBayArea
      ? "All Bay Area"
      : Definition.getLabels("locationCandidate", [
        ...candidate.workLocationIds
      ]).filter(el => !!el).join(", ");
    /*
        Set Combination of _officeLocations with additional location details
        _workOrAdditionalLocations holds both of their values
        this key is been displayed in candidate card as work locations
     */
    candidate._workOrAdditionalLocations = candidate.locationDetails
      ? candidate._officeLocations.concat(", ").concat(candidate.locationDetails)
      : candidate._officeLocations;
    candidate._officeLocationsEmailTemplate = ("Unspecified" === candidate._officeLocations) ? "" : candidate._officeLocations;
    candidate._introduced = candidate.introduced
      ? moment(candidate.introduced).format("MM-DD-YYYY")
      : mdash;

    candidate._updatedAt = candidate.updatedAt
      ? moment(candidate.updatedAt).format("MM-DD-YYYY")
      : mdash;


    let degreeLabel = Definition.getLabel('undergraduateDegree', candidate.undergraduateDegree);
    const array1ForUnified = [degreeLabel, candidate.undergraduateMajor];
    const arrayFinalForUnified = [array1ForUnified.join(' ').trim(), candidate.undergraduateSchool].filter(el => el.length);
    candidate._degreeUnifiedString = `${arrayFinalForUnified.join(' @ ')}`;

    // labels without prefix for v3
    candidate.__diversity = Definition.getLabel("diversity", candidate.diversity);

    candidate._diversity =
      "Diversity: " + candidate.__diversity;

    /* set definition labels */
    /* for fill filter menus and autocomplete */

    // if (
    //   candidate.state !== 4 &&
    //   moment(candidate.introduced) > moment().subtract(6, "months")
    // ) {
    //   candidate.state = 1;
    // }

    candidate.withEngagements = !!candidate.engagements.length ? 'Yes' : 'No';
    candidate._withEngagements = !!candidate.engagements.length ? 'Yes' : 'No';


    Definition.set(candidate, "state");
    candidate._newState = `state${candidate._state}`;

    Definition.set(candidate, "level");
    Definition.set(candidate, "visaCandidate", "visa");
    Definition.set(candidate, "platformRating");
    Definition.set(candidate, "offerAcceptance", "acceptOffer");
    Definition.set(candidate, "recruiterRating");

    // category     // candidate.field
    Definition.set(candidate, "relationShip", "relationship");

    /** @todo REVIEW TO DEPRECATED 2021-06-30 µ */
    Definition.set(candidate, "remotelyWork", "workRemotly");

    Definition.set(candidate, "undergraduateDegree");

    /* "Remote work preference" */
    /* epic-3038(new locations)-story-3330-m2 - 2021-07-01 µ */
    /* sets candidate._inOfficeRemoteFlags and candidate._inOfficeRemoteFlagsKeys */
    Definition.map(candidate, "inOfficeRemote", "inOfficeRemoteFlags");

    // labels without prefix for v3
    candidate.__inOfficeRemoteFlags = candidate._inOfficeRemoteFlags;

    /* sets the menu-prefix on each keyword
        contained in candidate._inOfficeRemoteFlags
        to include them into candidate.___keys___ */
    candidate._inOfficeRemoteFlags = FilterControlLib.getItemValues({
      menu: Candidate.getMenu({ key: "inOfficeRemoteFlags" }),
      itemLabels: candidate._inOfficeRemoteFlags
    });

    /* NEW FIELDS 2021-06-14 µ story-3053 */
    // Definition.set(candidate, "location", "candidateLocation");
    Definition.map(candidate, "location", "candidateLocations");

    /* NEW FIELDS 2021-06-14 µ story-3053 */
    Definition.map(candidate, "desiredEmploymentType", "desiredEmploymentTypes");

    /* NEW FIELDS 2021-06-14 µ story-3053 */
    /* "Desired Locations" */
    Definition.map(candidate, "location", "officeLocations");

    /* NEW LOCATIONS 2021-06-25 µ story-3083 M1-4 */
    /** set menu.prefix * /
    candidate._candidateLocation = FilterControlLib.getItemValue({
      menu: Candidate.getMenu({ key: "candidateLocation" }),
      itemLabel: candidate._candidateLocation
    });
    /** */

    // labels without prefix for v3
    candidate.__candidateLocations = candidate._candidateLocations;

    candidate._candidateLocations = FilterControlLib.getItemValues({
      menu: Candidate.getMenu({ key: "candidateLocations" }),
      itemLabels: candidate._candidateLocations
    });

    // labels without prefix for v3
    candidate.__officeLocations = candidate._officeLocations;

    /* NEW LOCATIONS 2021-06-24 µ story-3083 M4 */
    /** set menu.prefix */
    candidate._officeLocations = FilterControlLib.getItemValues({
      menu: Candidate.getMenu({ key: "officeLocations" }),
      itemLabels: candidate._officeLocations
    });

    Definition.map(candidate, "roles", "roles");
    Definition.map(candidate, "technicalSkills");
    Definition.map(candidate, "industry");
    Definition.map(candidate, "technologyDomain");
    Definition.map(candidate, "positiveSignals");
    Definition.map(candidate, "negativeSignals");
    Definition.map(candidate, "jobType", "jobTypes");
    Definition.map(candidate, "contactPreference", "candidatePreferIds");
    Definition.map(candidate, "locationCandidate", "workLocationIds");
    Definition.map(candidate, "stage", "desiredStage");

    candidate._tags = [];
    candidate._myTags = [];

    if (candidate.tags[Core.getUserRole()]) {
      if (Core.isAdmin()) {
        const tagObj = candidate.tags;
        const roles = Reflect.ownKeys(candidate.tags);
        let tags = roles.map(r => Reflect.ownKeys(tagObj[r]).map(id => tagObj[r][id].tags));
        if (candidate.id === "5c5f4b223d3e4ccd41f92204") {
          //debugger
        }

        candidate._tags = tags.flat(2);
        candidate._myTags = R.pathOr([], ['tags', Core.getUserRole(), Core.getUserId(), 'tags'])(candidate);
      } else {
        candidate._tags = R.pathOr([], ['tags', Core.getUserRole(), Core.getUserId(), 'tags'])(candidate);
        candidate._myTags = candidate._tags;
      }
    }

    candidate._infoCmp = <CandidateInfo candidate={candidate} />;
    candidate._recruiterCmp = <RecruiterInfo candidate={candidate} />;
    candidate._engagementsCmp = <EngagementsInfo candidate={candidate} />;
    candidate._additionalInfoCmp = <AdditionalInfo candidate={candidate} />;
    candidate._rowCheckCmp = <RowCheckbox candidate={candidate} />;
    candidate._rowOptionsCmp = <RowOptions candidate={candidate} />;
    candidate._latestStage =
      Engagement.stageOrder[
      Math.max.apply(
        null,
        candidate.engagements.map(eng =>
          Engagement.stageOrder.indexOf(eng.stage)
        )
      )
      ];

    candidate.copyString = str => {
      if (
        copy(str, {
          debug: true,
          message: "Press #{key} to copy"
        })
      ) {
        Core.showMessage(`${str} copied!`);
      } else {
        Core.showMessage("Fail copy!");
      }
    };
    candidate.bulkCopy = em => {
      const engagements = candidate.engagements || [];
      const contents = [];
      engagements.forEach(eng => {
        if (/confirmation/i.test(eng.stage) && /open/i.test(eng.state)) {
          // Core.log({ job: Job.getPreview(eng.job) });
          contents.push(Job.getPreview(eng.job));
        }
      });
      if (!!contents.length) {
        copyHTML("<p>" + contents.join("</p><p>") + "</p>")
          .then(em => {
            Core.log("Copy email command was successful");
            Core.showMessage("Copied!");
          })
          .catch(ex => {
            Core.log("Oops, unable to copy");
            Core.showMessage("Fail copy!");
          });
      } else {
        Core.showMessage("There are no Engagements on Confirmation stage");
      }
    };
    candidate.openMessage = ev => {
      /** */
      Core.showMessage("Getting snippets, wait a moment...");
      const continueFlow = snippets => {
        Core.hideMessage();
        Core.log({ snippets });
        const emails = [];
        candidate.email &&
          emails.push({
            name: candidate._name || "Action Owner",
            email: candidate.email
          });
        candidate.recruiter.email &&
          emails.push({
            name: candidate.recruiter._name || "Recruiter",
            email: candidate.recruiter.email
          });
        Core.dialog.open({
          title: <>Message</>,
          message: (
            <EmailPreview
              ref={self => (this.EmailMessage = self)}
              emails={emails}
              to={!!emails[0] && [emails[0]]}
              subject={emails.map(item => item.name).join(", ")}
              body={[candidate._name, candidate.recruiter._name]
                .filter(line => !!line)
                .join("<br/>")}
              snippets={snippets}
            />
          ),
          className: "p-0",
          actions: [
            <FlatButton
              label="Cancel"
              className="button-flat-darker"
              onClick={ev => {
                Core.dialog.close();
              }}
            />,
            <FlatButton
              label="Send"
              className="button-white-cyan"
              onClick={ev => {
                Core.dialog.close();
                Google.sendEmail(
                  { ...this.EmailMessage.getParams(), source: 'lib/models/candidate.js line 572' },
                  response => Core.showMessage("Email sent"),
                  error => Core.showFailure(error)
                );
              }}
            />
          ]
        });
      };

      Streak.getSnippets(
        snippets => continueFlow(snippets),
        error => {
          continueFlow([]);
          Core.showMessage("Snippets:" + error);
        }
      );
      /** */
    };

    /** @todo v2 to deprecate | 2021-09-30 Thu µ */
    /** * /
    candidate.openDetails = em => {
      Core.openDrawer({
        style: {
          width: 1600,
          maxWidth: "calc(100vw - var(--leftSideWidth))",
          minWidth: 320
        },
        content: (
          <Fragment>
            <Details candidate={candidate} />
            <IconButton
              style={{ position: "fixed", top: 0, right: 0 }}
              onClick={ev => Core.closeDrawer()}
            >
              <i className="material-icons">arrow_forward_ios</i>
            </IconButton>
          </Fragment>
        )
      });
    };
    /** */

    candidate.openEngagementsDetails = (ev) => {
      if (Object(ev).preventDefault) {
        ev.preventDefault();
        ev.stopPropagation();
      }
      Core.openDrawer({
        style: {
          width: 900,
          minWidth: '60vw',
          maxWidth: 'calc(100vw - var(--leftSideWidth))',
        },
        content: (
          <>
            {candidate.getEngagementDetails()}
            <IconButton
              style={{ position: 'fixed', top: 0, right: 0, zIndex: 2 }}
              onClick={(ev) => Core.closeDrawer()}
            >
              <i className="material-icons">arrow_forward_ios</i>
            </IconButton>
          </>
        ),
      });
    };

    candidate.getEngagementDetails = em => <Details candidate={candidate} />;
    candidate.delete = onSuccess => {
      const engagements = candidate.engagements || [];
      Core.dialog.open({
        title: (
          <>{`Delete "${candidate._name}"${!!engagements.length
            ? ` and ${engagements.length} engagement${engagements.length === 1 ? "" : "s"
            }?`
            : ""
            }`}</>
        ),
        message: "This action can't be undone.",
        style: { width: "320px" },
        actions: [
          <FlatButton
            label="Cancel"
            className="button-white-cyan"
            onClick={ev => {
              Core.dialog.close();
            }}
          />,
          <FlatButton
            label="Delete"
            className="button-flat-cyan"
            onClick={ev => {
              Core.dialog.close();
              Candidate.delete(
                candidate.id,
                onSuccess ? onSuccess : function () { }
              );
            }}
          />
        ]
      });
    };
    candidate.checkStar = (checked, onSuccess) => {
      Candidate.updateStarred(
        candidate.id,
        candidate.starredId,
        checked,
        response => {
          candidate.starredId = response.id;
          candidate.starred = response.starred;
          candidate._starred = response.starred
            ? "Starred: True"
            : "Starred: False";
          candidate.filters = {
            ...candidate.filters,
            Starred: ["Non Starred", "Starred"][~~Boolean(response.starred)]
          };
          onSuccess && onSuccess(response);
        }
      );
    };
    candidate.select = (checked, onSuccess) => {
      candidate.selected = checked;
      onSuccess && onSuccess(checked);
    };
    candidate.goMatch = ev =>
      (document.location.href = "/#/candidate/matchNew/" + candidate.id);
    candidate.goEdit = ev =>
      (document.location.href = "/#/candidate/edit/" + candidate.id);
    /* for autocomplete */
    candidate.___keys___ = [
      /* Jira Ticket Ticket VER-20: Always include unspecified, unknow in the search results. */
      // "Visa Status Unknown",
      candidate._name,
      candidate.recruiter._name,
      candidate.recruiter.companyName,
      candidate.jobTitle,
      candidate._diversity,

      /** @todo TO CLEANUP - 2021-06-28 µ * /
      candidate._officeLocations, // this is needed to get it works the location menu.
      /** */

      /** @todo REVIEW TO DEPRECATE - 2021-06-28 µ */
      candidate._workLocationIds,

      candidate._workOrAdditionalLocations,   // Combination of work locations and additional locations
      candidate._visa, // this is needed to get it works the visa menu.
      candidate._platformRating,
      candidate._roles, // this is needed to get it works the role menu.
      candidate._technicalSkills, // this is needed to get it works the technology menu.
      candidate._positiveSignals,
      candidate._withEngagements,
      candidate._tags,
      candidate._negativeSignals,
      candidate._industry,
      candidate._technologyDomain,
      candidate._jobTypes,
      candidate._starred,
      candidate._state,
      candidate._newState,
      // candidate._status,
      candidate._introducedStatus,
      candidate._introduced,
      candidate._desiredStage,

      /** New fields 2021-06-14 µ */
      candidate._desiredEmploymentTypes,
      candidate._candidateLocation,
      candidate._officeLocations,

      /* epic-3038(new locations)-story-3330-M2 - 2021-07-01 µ */
      candidate._inOfficeRemoteFlags,

    ]
      /* combine and split values */
      .join(",")
      .split(",")
      /* remove empty values */
      .filter((s = '') => !!s.trim())
      .map(s => s.trim());

    /* epic-3038(new locations)-story-3652-m2 | 2021-08-03 Tue µ */
    if (NOT(candidate.desiredEmploymentTypes.length)) {
      Definition.get('desiredEmploymentType').forEach(tag =>
        candidate.___keys___.push(tag.label)
      );
    }

    candidate._persisted = _.cloneDeep(candidate);

  }

  /* epic-3038(new locations)-story-3573-m2 | 2021-07-28 Wed µ */
  candidate.__unitTestingItemId = getUnitTestingItemId(candidate.firstName);

  return candidate;

};

const mapCandidates = data => {
  return (data || []).map(item => {
    const candidate = mapCandidate(item);
    return {
      ...candidate,
      filters: {
        Name: candidate.lastName[0].toUpperCase(),
        Introduced: candidate.introduced,
        Recent: moment(candidate.updatedAt),
        Recruiter: candidate.recruiter.lastName,
        Starred: ["Non Starred", "Starred"][~~Boolean(candidate.starred)]
      }
    };
  });
};

/** @todo v2 to deprecate | 2021-09-30 Thu µ */
export class Details extends Component {
  render() {
    const { candidate } = this.props;
    return (
      <div>
        <Row>
          <Col>
            <label>Engagements for candidate: {candidate._name}</label>
          </Col>
        </Row>
        <hr />
        {!!candidate.engagements.length ? (
          <Fragment>
            <div className="material-table border-bottom-gray">
              <Table>
                <TableBody displayRowCheckbox={false}>
                  <TableRow selectable={false}>
                    {!Core.isLimited() && (
                      <TableRowColumn>Employer</TableRowColumn>
                    )}
                    <TableRowColumn>Last Action</TableRowColumn>
                    <TableRowColumn>Role</TableRowColumn>
                    <TableRowColumn className="inline-blocks">
                      Stage&nbsp;
                      <i
                        title="Confirmation - need candidate's approval for submission&#013;Submission - 10x10 processing&#013;Review - employer reviewing resume&#013;Screen - phone screen or homework&#013;Onsite - in person interview&#013;Offer - offer discussion in progress&#013;Hire - offer accepted&#013;Guarantee - employment started"
                        className="material-icons"
                      >
                        info
                      </i>
                    </TableRowColumn>
                    <TableRowColumn className="inline-blocks">
                      Status&nbsp;
                      <i
                        title="W - waiting on the entity listed to complete the next action to move the interview forward&#013;E - the interview process was ended by the entity listed"
                        className="material-icons"
                      >
                        info
                      </i>
                    </TableRowColumn>
                    <TableRowColumn>Submitted</TableRowColumn>
                    <TableRowColumn>CV Review</TableRowColumn>
                    <TableRowColumn>Screen</TableRowColumn>
                    <TableRowColumn>Onsite</TableRowColumn>
                    <TableRowColumn>State</TableRowColumn>
                  </TableRow>
                  {candidate.engagements.map(eng => (
                    <TableRow
                      key={Core.getKey()}
                      selectable={false}
                      style={{
                        opacity: eng.state === "Open" ? 1 : 0.4
                      }}
                      onClick={ev => Core.log({ eng })}
                    >
                      {!Core.isLimited() && (
                        <TableRowColumn>{eng.employer._name}</TableRowColumn>
                      )}
                      <TableRowColumn title={eng.lastAction}>
                        {eng._lastAction}
                      </TableRowColumn>
                      <TableRowColumn>
                        <span
                          className="anchor"
                          onClick={ev => Core.open(`/job/edit/${eng.jobId}`)}
                        >
                          {eng._role}
                        </span>
                      </TableRowColumn>
                      <TableRowColumn>{eng.stage}</TableRowColumn>
                      <TableRowColumn>{eng.status}</TableRowColumn>
                      <TableRowColumn title={eng.submitted}>
                        {eng._submitted}
                      </TableRowColumn>
                      <TableRowColumn title={eng.reviewed}>
                        {eng._reviewed}
                      </TableRowColumn>
                      <TableRowColumn>
                        <div title={`Screen 1: ${eng.screen1}`}>
                          {eng._screen1}
                        </div>
                        <div title={`Screen 2: ${eng.screen2}`}>
                          {eng._screen2}
                        </div>
                        <div title={`Screen 3: ${eng.screen3}`}>
                          {eng._screen3}
                        </div>
                      </TableRowColumn>
                      <TableRowColumn>
                        <div title={`Onsite 1: ${eng.onsite1}`}>
                          {eng._onsite1}
                        </div>
                        <div title={`Onsite 2: ${eng.onsite2}`}>
                          {eng._onsite2}
                        </div>
                      </TableRowColumn>
                      <TableRowColumn>{eng.state}</TableRowColumn>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="material-table border-bottom-gray">
              <Table>
                <TableBody displayRowCheckbox={false}>
                  <TableRow selectable={false}>
                    <TableRowColumn>No engagments</TableRowColumn>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}
class CandidateInfo extends Component {
  render() {
    const { candidate } = this.props;
    return (
      <MuiThemeProvider>
        <div className="inline-blocks">
          <IconButton
            title="Copy Candidate email"
            className="icon16"
            onClick={ev => candidate.copyString(candidate.email)}
          >
            <i className="material-icons">mail</i>
          </IconButton>
          &nbsp;
          {!!candidate.phone ? (
            <IconButton
              title="Copy Candidate phone"
              className="icon16"
              onClick={ev => candidate.copyString(candidate.phone)}
            >
              <i className="material-icons">phone</i>
            </IconButton>
          ) : (
            <div style={{ width: 16, height: 16 }} />
          )}
          &nbsp;
          {!!candidate.resumes.length ? (
            (resume => {
              return (
                <IconButton
                  className="icon16"
                  onClick={ev =>
                    downloadFile({
                      url: resume.url,
                      mimeType: "application/pdf",
                      onError: error => Core.showFailure(error)
                    })
                  }
                >
                  <i className="material-icons">file_download</i>
                </IconButton>
              );
            })(candidate.resumes[0])
          ) : (
            <div style={{ width: 16, height: 16 }} />
          )}
          &nbsp;
          <a href={"/#/candidate/edit/" + candidate.id}>
            <b>{candidate._name_rating || <i>&mdash;</i>}</b>
          </a>
        </div>
      </MuiThemeProvider>
    );
  }
}
class RecruiterInfo extends Component {
  render() {
    const { candidate } = this.props;
    return (
      <div className="inline-blocks no-wrap">
        {!!candidate.recruiter.email ? (
          <Fragment>
            <IconButton
              title="Copy Recruiter email"
              iconStyle={candidate.openedColor}
              className="icon16"
              onClick={ev => candidate.copyString(candidate.recruiter.email)}
            >
              <i className="material-icons">mail</i>
            </IconButton>
            &nbsp;
            <span title="Recruiter">{candidate.recruiter._name}</span>
          </Fragment>
        ) : (
          <i>&mdash;</i>
        )}
        {!!candidate.recruiter.companyName && (
          <span title="Agency">
            &nbsp;-&nbsp;
            {candidate.recruiter.companyName}
          </span>
        )}
      </div>
    );
  }
}
class AdditionalInfo extends Component {
  render() {
    const { candidate } = this.props;
    return (
      <div className="inline-blocks">
        <span title="Minimum Years of Experience">{candidate._years}</span>
        &nbsp;&nbsp;&nbsp;
        <span title="Minimum Salary">{candidate._minimumSalary}</span>
      </div>
    );
  }
}
class EngagementsInfo extends Component {
  render() {
    const { candidate } = this.props;
    const engagements = candidate.engagements;

    /** * /
    const totalEngagements = engagements.length;
    const activeEngagements = engagements.filter(
      eng =>
        eng.state === "Open" && Engagement.stageOrder.indexOf(eng.stage) >= 3
    ).length;
    const hireEngagements = engagements.filter(eng => /hire/i.test(eng.stage))
      .length;
    /** */

    const reviewEngagements = engagements.filter(
      eng => /open/i.test(eng.state) && /review/i.test(eng.stage)
    ).length;
    const screenEngagements = engagements.filter(
      eng => /open/i.test(eng.state) && /screen/i.test(eng.stage)
    ).length;
    const onsiteEngagements = engagements.filter(
      eng => /open/i.test(eng.state) && /onsite/i.test(eng.stage)
    ).length;

    const confEngagements = engagements.filter(
      eng => /open/i.test(eng.state) && /confirmation/i.test(eng.stage)
    ).length;
    const inactiveEngagements = engagements.filter(eng =>
      /closed/i.test(eng.state)
    ).length;

    const submissions = engagements
      .map(eng =>
        eng.submitted
          ? moment(eng.submitted)
            .toDate()
            .getTime()
          : 0
      )
      .filter(time => !!time);
    const lastSubmissionTime = Math.max.apply(null, submissions);
    const lastSubmissionDate = moment(lastSubmissionTime).toISOString();
    const lastSubmission = submissions.length
      ? moment(lastSubmissionTime).format("MM/DD/YY")
      : 0;
    /** * /
    const offerEngagements = engagements.filter(
      eng => /open/i.test(eng.state) && /offer/i.test(eng.stage)
    ).length;
    const totalEngagements = engagements.length;
    const activeEngagements = engagements.filter(
      eng =>
        eng.state === "Open" && Engagement.stageOrder.indexOf(eng.stage) >= 3
    ).length;
    /** */
    /** */
    const latestStage =
      Engagement.stageOrder[
      Math.max.apply(
        null,
        engagements.map(eng => Engagement.stageOrder.indexOf(eng.stage))
      )
      ];
    /** * /
    const stageCount = engagements.filter(eng => eng.stage === latestStage)
      .length;
    /** */
    const componentEngagements = (
      <div className="cursor-pointer" onClick={ev => candidate.openDetails()}>
        {/** * /}
        <span title="Total Engagements">{totalEngagements} all</span>
        <span title="Engagements Open and stage higher than Review stage">
          {activeEngagements} active -&nbsp;
        </span>
        <span title="Open, Offer">{offerEngagements} offer</span>
        <span title="Total Hire">{hireEngagements} hire |&nbsp;</span>
        {/** */}
        <span title="Open, Confirmation">{confEngagements} conf |&nbsp;</span>
        <span title="Open, Review">{reviewEngagements} review |&nbsp;</span>
        <span title="Open, Screen">{screenEngagements} screen |&nbsp;</span>
        <span title="Open, Onsite">{onsiteEngagements} onsite</span>
        <br />
        <span title="Most advanced stage">
          <b>{latestStage || mdash}</b> |&nbsp;
        </span>
        <span title="Closed">{inactiveEngagements} inactive |&nbsp;</span>
        <span title={`Last submission: ${lastSubmissionDate}`}>
          <b>{lastSubmission || mdash}</b>
        </span>
      </div>
    );
    const alternativeCmp = <div />;
    /** * /
    const oldCMP = (
      <div className="cursor-default">
        <span title="Engagements Open and stage higher than Review stage">
          {activeEngagements} active -&nbsp;
        </span>
        <span title="Engagements Open and Confirmation stage">
          {confEngagements} conf -&nbsp;
        </span>
        <span title="Total Engagements">{totalEngagements} all</span>
        <br />
        <span title="Engagements Open and Hire stage">
          {hireEngagements} hire
        </span>
        <span title={`Last submission: ${lastSubmissionDate}`}>
          {!!lastSubmission ? `, ${lastSubmission} last submit` : ""}
        </span>
      </div>
    );
    /** */
    return Core.isAdminOrCoordinator() ? componentEngagements : alternativeCmp;
  }
}
class RowCheckbox extends Component {
  render() {
    const { candidate } = this.props;
    return (
      <div className="row-checkbox">
        <Checkbox
          checked={candidate.selected}
          onCheck={(ev, checked) => {
            candidate.select(checked, res => this.setState({ updated: true }));
          }}
        />
      </div>
    );
  }
}
class RowOptions extends Component {
  render() {
    // Core.log("RowOptions", "render");
    const { candidate } = this.props;
    return (
      <div className="row-options inline-blocks">
        <Checkbox
          className="starred"
          checked={candidate.starred}
          onCheck={(ev, checked) => {
            candidate.checkStar(checked, res =>
              this.setState({ updated: true })
            );
          }}
          checkedIcon={<i className="material-icons">star</i>}
          uncheckedIcon={<i className="material-icons">star_border</i>}
        />
        {Core.isRecruiter() &&
          Core.isProduction() &&
          !candidate.resumes.length ? (
          false
        ) : (
          <IconMenu
            //title="Menu"
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            targetOrigin={{ horizontal: "right", vertical: "top" }}
            style={{ marginLeft: "4px" }}
            iconButtonElement={
              <IconButton style={{ width: 24, padding: 0 }}>
                <i className="material-icons">more_vert</i>
              </IconButton>
            }
          >
            {(Core.isAdmin() || (Core.isRecruiter() && Core.isOnDev())) && (
              <MenuItem primaryText="Match" onClick={candidate.goMatch} />
            )}
            {!!candidate.resumes.length && (
              <MenuItem
                title="Curriculum Vitae"
                primaryText="CV"
                onClick={event => {
                  if (
                    /.*.(doc|docx)/i.test(candidate.resumes[0].url) === true
                  ) {
                    Core.openSameWindow(candidate.resumes[0].url);
                  } else {
                    Core.openPopUp(candidate.resumes[0].url);
                  }
                }}
              />
            )}
            {Core.isAdmin() && (
              <MenuItem
                primaryText="Copy Confirmation Job Description"
                onClick={ev => candidate.bulkCopy()}
              />
            )}
            {Core.isAdmin() && (
              <MenuItem primaryText="Message" onClick={candidate.openMessage} />
            )}
            {Core.isAdmin() && (
              <MenuItem
                primaryText="Delete"
                onClick={ev =>
                  candidate.delete(res => Core.Main && Core.Main.fetchData())
                }
              />
            )}
          </IconMenu>
        )}
        <i
          className="material-icons"
          style={{
            width: 24,
            height: 24,
            margin: 0,
            cursor: "pointer",
            fontWeight: 200,
            ...candidate.openedColor,
            ...candidate.rightArrow
          }}
          onClick={candidate.openDetails}
        >
          chevron_right
        </i>
        {candidate.blacklisted}
      </div>
    );
  }
}

/**
 * @note
 *
 * Sets the default accountId
 */
setTimeout(st => (extended.accountId = Core.getUserId()));

/**
 *
 * @param {object} options Optional
 * @param {boolean} options.extended
 * @param {boolean} options.parser
 * @param {boolean} options.matchExclusion
 * @returns {object} A new model
 */
function getCandidateModel({
  extended: isExtendedRequired,
  parser: isParserModelRequired,
  matchExclusion,
} = {}) {
  return newModel(
    isExtendedRequired
      ? extended
      : isParserModelRequired
        ? parser
        : matchExclusion ?
          modelMatchExclusion
          : model
  );
}

export {
  parser as parserModel,
  MODEL_NAME_CANDIDATE as CANDIDATE_MODEL_NAME,
  getCandidateModel,
  mapCandidate,
  mapCandidates
};

