import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import React, { Component } from "react";

/** @todo to deprecate | story-3945-m6 | 2021-09-01 Wed µ */
// import { Dialog, FlatButton } from "material-ui";

const muiTheme = getMuiTheme({
  palette: {
    accent1Color: "#009688"
  }
});

class CustomDialog extends Component {
  constructor() {
    super(...arguments);
    this.state = { open: false, title: "", message: "", style: {} };
  }
  open = ({ title, message, actions, style, className, classNamePaper, onClose }) => {
    this.setState({
      open: true,
      title: title || "Message",
      message: message || "",
      actions: actions ||
        this.props.actions || [
          <Button variant="outlined" color="primary" onClick={this.close}>
            Ok
          </Button>
        ],
      style: style || {},
      className: className || 'min-w',
      classNamePaper: classNamePaper || '',
      onClose
    });
  };
  close = ev => {
    this.setState({ open: false, title: "", message: "" }, then => {
      if (this.state.onClose instanceof Function) {
        this.state.onClose();
        this.setState({ onClose: null });
      }
    });
  };
  render() {
    return (
      <MuiThemeProvider muiTheme={muiTheme}>

        <Dialog
          open={this.state.open}
          onClose={this.close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          PaperProps={{ className: this.state.className, style: this.state.style }}
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title" className="c-purple">
            {this.state.title}
          </DialogTitle>
          <DialogContent>
            {this.state.message}
          </DialogContent>
          <DialogActions>
            {this.state.actions}
          </DialogActions>
        </Dialog>

        {/** @todo to deprecate | story-3945-m6 | 2021-09-01 Wed µ */}
        {/** * /}
        <Dialog
          title={this.state.title}
          actions={this.state.actions}
          modal={true}
          className={["custom-dialog", this.state.className].join(' ')}
          contentStyle={{
            width: 768,
            minWidth: 320,
            ...this.state.style
          }}
          paperClassName={['custom-dialog-paper', this.classNamePaper].join(' ')}
          bodyClassName="custom-dialog-body"
          bodyStyle={{ overflow: "auto" }}
          open={this.state.open}
        >
          {this.state.message}
        </Dialog>
        {/** */}

      </MuiThemeProvider >
    );
  }
}

export default CustomDialog;
