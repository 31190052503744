import React, { Component } from "react";
import Core from "../../../lib/Core";

import { MenuItem, SelectField } from "material-ui";
import Checkbox from "material-ui/Checkbox";
import DatePicker from "material-ui/DatePicker";
import { List, ListItem } from "material-ui/List";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import TextField from "material-ui/TextField";
import Definition, {
  EMP_SUB_MET__ATS_ID,
  ATS_TYPE__GH_HARVEST_ID,
  ATS_TYPE__GH_INGESTION_ID
} from "../../../lib/Definition";
import { mapAccount } from "../../../lib/models/account";
import Chips from "../../Forms/Chips";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";
import RichTextBox from "../../Forms/RichTextBox";
import QuestionsMustHave from "../../Shared/QuestionsMustHave";
import { Fragment } from "react";
import EmailList from "../../Forms/EmailsList";

/** 
 * µ TO REVIEW in future
 * following lines could be replaced by 
 * a method from GenericTools library 
 */
const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailInvalid = "Email is invalid";
const emailError = "Please enter a valid email";
const emaiActions = [
  <MenuItem key="to" value="to" primaryText="TO" />,
  <MenuItem key="cc" value="cc" primaryText="CC" />,
];

class Process extends Component {
  render() {
    const JobEditController = this.props.parent;
    const lineStyle = { borderColor: "#59736f" };

    const {
      state = {}
    } = JobEditController;
    const {
      atsJobId,
      employer = {},
      submissionMethods = [],
    } = state;
    const {
      atsTypeId
    } = employer;

    const isAtsAvailable = (
      submissionMethods.includes(EMP_SUB_MET__ATS_ID) &&
      (
        (atsTypeId === ATS_TYPE__GH_HARVEST_ID) ||
        (atsTypeId === ATS_TYPE__GH_INGESTION_ID)
      )
    );

    /** µ FOR DEBUG PURPOSES */
    console.debug('µ:JobProcessRender', state, submissionMethods);
    /** */

    return (
      <List className="JobForm Process">
        <ListItem
          className="form-header"
          primaryText="PROCESS"
          disabled={true}
        />
        <Row>
          <Col>
            <Chips
              name="employerSubmissionMethod"
              label={<label>Submission Method(s)</label>}
              sub="Please select all the submission methods your are interested in"
              values={JobEditController.state.submissionMethods || []}
              onChange={(submissionMethods) => {
                JobEditController.setState({ submissionMethods });
              }}
            />
            {isAtsAvailable && (
              <Fragment>
                <label>ATS Job ID</label>
                <small className="c-gray">
                  The ID of the job you want to create an application to for this candidate
                </small>
                <TextField
                  name="atsJobId"
                  type="number"
                  underlineFocusStyle={lineStyle}
                  value={atsJobId}
                  onChange={(ev, atsJobId) => JobEditController.setState({ atsJobId })}
                  fullWidth
                />
              </Fragment>
            )}
          </Col>
          <Col>
            <label>Submission Method (obsolete)</label>
            <SelectField
              name="submissionMethod"
              underlineFocusStyle={lineStyle}
              value={parseInt(JobEditController.state.submissionMethod)}
              onChange={(ev, i, submissionMethod) =>
                JobEditController.setState({ submissionMethod })
              }
              fullWidth
            >
              {Definition.get("employerSubmissionMethod").map((tag) => (
                <MenuItem key={tag.id} value={tag.id} primaryText={tag.label} />
              ))}
            </SelectField>
          </Col>
        </Row>


        <Row>
          <Col fullWidth>
            <label>10x10 Internal Submission Instructions</label>
            <RichTextBox
              name="internalSubmissionInstructions"
              value={JobEditController.state.internalSubmissionInstructions}
              onChange={internalSubmissionInstructions => {
                JobEditController.setState({
                  internalSubmissionInstructions,
                });
              }}
            />
          </Col>
        </Row>

        <QuestionsMustHave
          existing={JobEditController.state.mustHaveQuestions}
          parentUpdater={(array) => {
            JobEditController.setState({ mustHaveQuestions: array });
          }}
        />

        <Row>
          <Col fullWidth>
            <label>Employer Generic Interview Process</label>
            <RichTextBox
              name="interviewProcess"
              value={JobEditController.state.employer.interviewProcess}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <label>Job Specific Interview Process</label>
            <RichTextBox
              name="jobInterviewProcess"
              value={JobEditController.state.jobInterviewProcess}
              onChange={jobInterviewProcess => {
                JobEditController.setState({
                  jobInterviewProcess,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <label>
              Public Notes&nbsp;
              <b>
                <small>(Visible to Limited Recruiters)</small>
              </b>
            </label>
            <RichTextBox
              name="publicNotes"
              value={JobEditController.state.publicNotes}
              onChange={publicNotes => {
                JobEditController.setState({
                  publicNotes,
                });
              }}
            />
          </Col>
        </Row>

        {/**
         * @todo
         *
         * DEPRECATED
         * 
         * Review to cleanup
         *
         * story-3927-m6 | 2021-09-02 Thu µ
         *
         */}
        <div className="p-1 m-2 rounded" style={{ border: "2px dotted #ccc", filter: 'grayscale(1)' }}>
          <Row><Col><label>DEPRECATED</label></Col></Row>
          <Row>
            <Col fullWidth>
              <label>
                Send Resume Submission To&nbsp;
                <small>(name, email)</small>:
              </label>
            </Col>
          </Row>
          <Row>
            <Col className="col2">
              <SelectField
                name="resumeSubmissionEmailAction1"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.resumeSubmissionEmailAction1}
                onChange={(ev, i, resumeSubmissionEmailAction1) =>
                  JobEditController.setState({ resumeSubmissionEmailAction1 })
                }
                fullWidth
                disabled
              >
                {emaiActions}
              </SelectField>
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionName1"
                placeholder="Name"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.resumeSubmissionName1}
                onChange={(ev, resumeSubmissionName1) =>
                  JobEditController.setState({ resumeSubmissionName1 })
                }
                fullWidth
                disabled
              />
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionEmail1"
                placeholder="Email"
                type="email"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.resumeSubmissionEmail1}
                onChange={(ev, resumeSubmissionEmail1) =>
                  JobEditController.setState({ resumeSubmissionEmail1, errorEmail1: "" })
                }
                onBlur={(ev) => {
                  if (
                    !!JobEditController.state.resumeSubmissionEmail1 &&
                    !emailRE.test(JobEditController.state.resumeSubmissionEmail1)
                  ) {
                    JobEditController.setState({
                      snackbar: emailInvalid,
                      errorEmail1: emailError,
                    });
                    return false;
                  }
                }}
                errorText={JobEditController.state.errorEmail1}
                errorStyle={{ color: "orange" }}
                fullWidth
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col className="col2">
              <SelectField
                name="resumeSubmissionEmailAction2"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.resumeSubmissionEmailAction2}
                onChange={(ev, i, resumeSubmissionEmailAction2) =>
                  JobEditController.setState({ resumeSubmissionEmailAction2 })
                }
                fullWidth
                disabled
              >
                {emaiActions}
              </SelectField>
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionName2"
                placeholder="Name"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.resumeSubmissionName2}
                onChange={(ev, resumeSubmissionName2) =>
                  JobEditController.setState({ resumeSubmissionName2 })
                }
                fullWidth
                disabled
              />
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionEmail2"
                placeholder="Email"
                type="email"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.resumeSubmissionEmail2}
                onChange={(ev, resumeSubmissionEmail2) =>
                  JobEditController.setState({ resumeSubmissionEmail2, errorEmail2: "" })
                }
                onBlur={(ev) => {
                  if (
                    !!JobEditController.state.resumeSubmissionEmail2 &&
                    !emailRE.test(JobEditController.state.resumeSubmissionEmail2)
                  ) {
                    JobEditController.setState({
                      snackbar: emailInvalid,
                      errorEmail2: emailError,
                    });
                    return false;
                  }
                }}
                errorText={JobEditController.state.errorEmail2}
                errorStyle={{ color: "orange" }}
                fullWidth
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col className="col2">
              <SelectField
                name="resumeSubmissionEmailAction3"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.resumeSubmissionEmailAction3}
                onChange={(ev, i, resumeSubmissionEmailAction3) =>
                  JobEditController.setState({ resumeSubmissionEmailAction3 })
                }
                fullWidth
                disabled
              >
                {emaiActions}
              </SelectField>
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionName3"
                placeholder="Name"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.resumeSubmissionName3}
                onChange={(ev, resumeSubmissionName3) =>
                  JobEditController.setState({ resumeSubmissionName3 })
                }
                fullWidth
                disabled
              />
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionEmail3"
                placeholder="Email"
                type="email"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.resumeSubmissionEmail3}
                onChange={(ev, resumeSubmissionEmail3) =>
                  JobEditController.setState({ resumeSubmissionEmail3, errorEmail3: "" })
                }
                onBlur={(ev) => {
                  if (
                    !!JobEditController.state.resumeSubmissionEmail3 &&
                    !emailRE.test(JobEditController.state.resumeSubmissionEmail3)
                  ) {
                    JobEditController.setState({
                      snackbar: emailInvalid,
                      errorEmail3: emailError,
                    });
                    return false;
                  }
                }}
                errorText={JobEditController.state.errorEmail3}
                errorStyle={{ color: "orange" }}
                fullWidth
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col className="col2">
              <SelectField
                name="resumeSubmissionEmailAction4"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.resumeSubmissionEmailAction4}
                onChange={(ev, i, resumeSubmissionEmailAction4) =>
                  JobEditController.setState({ resumeSubmissionEmailAction4 })
                }
                fullWidth
                disabled
              >
                {emaiActions}
              </SelectField>
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionName4"
                placeholder="Name"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.resumeSubmissionName4}
                onChange={(ev, resumeSubmissionName4) =>
                  JobEditController.setState({ resumeSubmissionName4 })
                }
                fullWidth
                disabled
              />
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionEmail4"
                placeholder="Email"
                type="email"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.resumeSubmissionEmail4}
                onChange={(ev, resumeSubmissionEmail4) =>
                  JobEditController.setState({ resumeSubmissionEmail4, errorEmail4: "" })
                }
                onBlur={(ev) => {
                  if (
                    !!JobEditController.state.resumeSubmissionEmail4 &&
                    !emailRE.test(JobEditController.state.resumeSubmissionEmail4)
                  ) {
                    JobEditController.setState({
                      snackbar: emailInvalid,
                      errorEmail4: emailError,
                    });
                    return false;
                  }
                }}
                errorText={JobEditController.state.errorEmail4}
                errorStyle={{ color: "orange" }}
                fullWidth
                disabled
              />
            </Col>
          </Row>
        </div>

        <EmailList
          EntityController={JobEditController}
          messageTypeTags={Definition.get('employerMessageType')}
        />

        <div className="green-block py-2 p-1 m-2 rounded">
          <Row>
            <Col>
              <h4>For 10 by 10 use only</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Current State</label>
              <RadioButtonGroup
                name="rstate"
                valueSelected={JobEditController.state.state}
                onChange={(event, state) => {
                  JobEditController.setState({ state });
                }}
              // style={{ display: "flex" }}
              >
                {Definition.get("state")
                  .filter((tag) =>
                    Core.isAdminOrCoordinator()
                      ? true
                      : Definition.test("state", tag.id, /draft/)
                  )
                  .map((tag) => (
                    <RadioButton
                      key={tag.id}
                      value={tag.id}
                      label={tag.label}
                      style={{ width: "15%", margin: "16px 0" }}
                      labelStyle={{ marginLeft: -10 }}
                    />
                  ))}
              </RadioButtonGroup>
            </Col>
            <Col>
              <label>Flags</label>
              {Definition.get("flags").map((tag) => (
                <Checkbox
                  key={tag.id}
                  label={tag.label}
                  style={{ margin: "16px 0" }}
                  checked={!!~JobEditController.state.flags.indexOf(tag.id)}
                  onCheck={(ev, checked) => {
                    JobEditController.setState((state) => {
                      state = { flags: JobEditController.state.flags };
                      if (checked) {
                        if (!state.flags.find((id) => id === tag.id)) {
                          state.flags.push(tag.id);
                        }
                      } else {
                        state.flags = state.flags.filter((id) => id !== tag.id);
                      }
                      Core.log({ state });
                      return state;
                    });
                  }}
                />
              ))}
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Placement Fee</label>
              <TextField
                name="placementFee"
                underlineFocusStyle={lineStyle}
                type="number"
                max={100}
                maxLength={5}
                value={JobEditController.state.placementFee}
                onChange={(ev, placementFee) =>
                  JobEditController.setState({
                    placementFee: Number(
                      placementFee > 100
                        ? 100
                        : String(placementFee).slice(0, 5)
                    ),
                  })
                }
              />
              <b className="text-icon">%</b>
            </Col>
            <Col>
              <label>Guarantee</label>
              <TextField
                name="guarantee"
                underlineFocusStyle={lineStyle}
                type="number"
                maxLength={3}
                value={JobEditController.state.guarantee}
                onChange={(ev, guarantee) =>
                  JobEditController.setState({
                    guarantee:
                      guarantee > 999
                        ? 999
                        : String(Math.round(guarantee)).slice(0, 3),
                  })
                }
              />
              <b className="text-icon">days</b>
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <label>Additional Contract Information</label>
              <TextField
                name="additionalContractInfo"
                underlineFocusStyle={lineStyle}
                type="text"
                rows={1}
                rowsMax={3}
                multiLine={true}
                value={JobEditController.state.additionalContractInfo}
                onChange={(ev, additionalContractInfo) =>
                  JobEditController.setState({
                    additionalContractInfo,
                  })
                }
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <label>Default Resume Submission Template</label>
              <SelectField
                name="emailTemplates"
                underlineFocusStyle={lineStyle}
                value={JobEditController.state.resumeSubmissionEmailTemplateId}
                maxHeight={300}
                onChange={(event, index, resumeSubmissionEmailTemplateId) => {
                  JobEditController.setState({ resumeSubmissionEmailTemplateId });
                }}
                fullWidth
              >
                {(JobEditController.state.emailTemplates || []).map((item) => {
                  return (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      primaryText={item.name}
                    />
                  );
                })}
              </SelectField>
            </Col>
          </Row>
          <Row className="edit-account-list-row">
            {!!JobEditController.state.employer &&
              JobEditController.state.employer.employerSourceList && (
                <Col className="edit-account-list-col">
                  <label>Employer Source List</label>
                  {JobEditController.state.employer.employerSourceList.map((item) => {
                    item = mapAccount(item);
                    return (
                      <li key={item.id}>{item._name || <i>&mdash;</i>}</li>
                    );
                  })}
                </Col>
              )}
            {!!JobEditController.state.jobSourceList && (
              <Col className="edit-account-list-col">
                <label>Job Source List</label>
                {JobEditController.state.jobSourceList.map((item) => {
                  item = mapAccount(item);
                  return <li key={item.id}>{item._name || <i>&mdash;</i>}</li>;
                })}
              </Col>
            )}
          </Row>
          <Row>
            <Col fullWidth>
              <label>Private Notes</label>
              <RichTextBox
                name="privateNotes"
                value={JobEditController.state.privateNotes}
                onChange={privateNotes => {
                  JobEditController.setState({
                    privateNotes,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <label>Creation Date</label>
            <DatePicker
              name="createdAt"
              underlineFocusStyle={lineStyle}
              value={
                JobEditController.state.createdAt ? new Date(JobEditController.state.createdAt) : null
              }
              onChange={(ev, createdAt) => {
                JobEditController.setState({ createdAt: createdAt.toISOString() });
              }}
              fullWidth
            />
          </Col>
          <Col>
            <label>Last Update</label>
            <DatePicker
              name="updatedAt"
              underlineFocusStyle={lineStyle}
              value={
                JobEditController.state.updatedAt ? new Date(JobEditController.state.updatedAt) : null
              }
              onChange={(ev, updatedAt) => {
                JobEditController.setState({ updatedAt: updatedAt.toISOString() });
              }}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Closed Date</label>
            <DatePicker
              name="closeDate"
              underlineFocusStyle={lineStyle}
              value={
                JobEditController.state.closeDate ? new Date(JobEditController.state.closeDate) : null
              }
              onChange={(ev, closeDate) => {
                JobEditController.setState({ closeDate: closeDate.toISOString() });
              }}
              fullWidth
            />
          </Col>
        </Row>
      </List>
    );
  }
}

export default Process;
