import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import copy from "copy-to-clipboard";
import {
  AppBar,
  Dialog,
  FlatButton,
  IconButton,
  Paper,
  TextField
} from "material-ui";
import moment from "moment";

import Core, { colors } from "../../lib/Core";
import Store from "../../lib/Store";
import Announcement from "../../lib/Announcement";
import Row from "../Forms/Row";
import Col from "../Forms/Col";
import styles from "../Forms/Styles";
import Definition from "../../lib/Definition";
import { mapAccount } from "../../lib/models/account";

const lineStyle = { borderColor: colors.purple };

class Announcements extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      data: [],
      sortDate: true, // false = ASC; true = DESC;
      sortMethod: this.sortDate
    };
    Store.set("path", window.location.href);
    this.fetchAnnouncements();
  }
  copyToClipboard = message => {
    if (
      copy(message, {
        debug: true,
        message: "Press #{key} to copy"
      })
    ) {
      Core.showMessage(`Copied!`);
    } else {
      Core.showMessage("Fail copy!");
    }
  };
  fetchAnnouncements() {
    Announcement.getMostRecent({}, data => {
      this.setState(
        state => {
          state.data = data;
          // if state of sort column is defined, invert value for sorting well in #following-sort-call.
          state.sortDate = state.sortDate !== undefined && !state.sortDate;
          state.sortStatus =
            state.sortStatus !== undefined && !state.sortStatus;
          state.sortName = state.sortName !== undefined && !state.sortName;
          return state;
        },
        then => {
          window.scrollTo(0, 0);
          Core.log({ AnnouncementsState: this.state });
          // #following-sort-call.
          this.state.sortMethod();
        }
      );
    });
  }
  sortDate = ev =>
    this.setState(state => {
      state.data = state.data.sort((a, b) =>
        String(a.updatedAt).localeCompare(String(b.updatedAt))
      );
      if (!state.sortDate) {
        state.data = state.data.reverse();
      }
      state.sortDate = !state.sortDate;
      state.sortStatus = undefined;
      state.sortName = undefined;
      state.sortNote = undefined;
      state.sortMethod = this.sortDate;
      return state;
    });
  sortStatus = ev =>
    this.setState(state => {
      state.data = state.data.sort((a, b) =>
        String(a._status).localeCompare(String(b._status))
      );
      if (state.sortStatus) {
        state.data = state.data.reverse();
      }
      state.sortStatus = !state.sortStatus;
      state.sortDate = undefined;
      state.sortName = undefined;
      state.sortNote = undefined;
      state.sortMethod = this.sortStatus;
      return state;
    });
  sortName = ev =>
    this.setState(state => {
      state.data = state.data.sort((a, b) =>
        String(a._name).localeCompare(String(b._name))
      );
      if (state.sortName) {
        state.data = state.data.reverse();
      }
      state.sortName = !state.sortName;
      state.sortDate = undefined;
      state.sortStatus = undefined;
      state.sortNote = undefined;
      state.sortMethod = this.sortName;
      return state;
    });
  sortNote = ev =>
    this.setState(state => {
      state.data = state.data.sort((a, b) =>
        String(a.note).localeCompare(String(b.note))
      );
      if (state.sortNote) {
        state.data = state.data.reverse();
      }
      state.sortNote = !state.sortNote;
      state.sortName = undefined;
      state.sortDate = undefined;
      state.sortStatus = undefined;
      state.sortMethod = this.sortNote;
      return state;
    });
  // [TODO: this is not working correctly]
  dateToFromNowDaily = myDate => {
    // get from-now for this date
    var fromNow = moment(myDate).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment(myDate).calendar(null, {
      // when the date is closer, specify custom values
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      lastWeek: "[Last] dddd",
      nextWeek: "dddd",
      // when the date is further away, use from-now functionality
      sameElse: function () {
        return "[" + fromNow + "]";
      }
    });
  };

  render() {
    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }
    return (
      <MuiThemeProvider>
        <div className="view announcement bgcolor2 min-100vh">
          <AppBar
            title="Announcements"
            titleStyle={styles.AppBar.title}
            style={styles.AppBar.nav}
            className="fixed"
            iconElementLeft={
              <FlatButton
                icon={<i className="material-icons">arrow_back</i>}
                style={styles.AppBar.backButton}
                onClick={ev => {
                  Core.goBack(this.props);
                }}
              />
            }
          />
          <div className="main-padding padding-top-96">
            <Paper className="view announcement-paper main-padding scroll-x">
              {/** HEADERS */}
              <Row style={{ minWidth: "768px" }}>
                <Col style={{ width: "200px" }}>
                  <FlatButton
                    label="Date"
                    labelPosition="before"
                    className="align-left"
                    icon={
                      this.state.sortDate === false ? (
                        <i className="material-icons">arrow_drop_up</i>
                      ) : (
                        this.state.sortDate === true && (
                          <i className="material-icons">arrow_drop_down</i>
                        )
                      )
                    }
                    onClick={this.sortDate}
                  />
                </Col>
                <Col style={{ width: "250px" }}>
                  <FlatButton
                    label="Status"
                    labelPosition="before"
                    className="align-left"
                    icon={
                      this.state.sortStatus === true ? (
                        <i className="material-icons">arrow_drop_up</i>
                      ) : (
                        this.state.sortStatus === false && (
                          <i className="material-icons">arrow_drop_down</i>
                        )
                      )
                    }
                    onClick={this.sortStatus}
                  />
                </Col>
                <Col>
                  <FlatButton
                    label="Announcement"
                    labelPosition="before"
                    className="align-left"
                    icon={
                      this.state.sortName === true ? (
                        <i className="material-icons">arrow_drop_up</i>
                      ) : (
                        this.state.sortName === false && (
                          <i className="material-icons">arrow_drop_down</i>
                        )
                      )
                    }
                    onClick={this.sortName}
                  />
                </Col>
                <Col>
                  <FlatButton
                    label={
                      Core.isAdminOrCoordinator()
                        ? "Note (visible to limited recruiters)"
                        : "Note"
                    }
                    labelPosition="before"
                    className="align-left"
                    icon={
                      this.state.sortNote === true ? (
                        <i className="material-icons">arrow_drop_up</i>
                      ) : (
                        this.state.sortNote === false && (
                          <i className="material-icons">arrow_drop_down</i>
                        )
                      )
                    }
                    onClick={this.sortNote}
                  />
                </Col>
                <Col style={{ width: "220px" }}>&nbsp;</Col>
              </Row>
              <hr />
              {/** ITEMS */}
              {this.state.data.map((item, index) => {
                const eventType = Definition.getLabel(
                  `announcementJobEventType`,
                  item.eventType
                );
                return (
                  <Row
                    key={item.id}
                    style={{ minWidth: "768px", padding: "8px 0" }}
                  >
                    <Col title="Date" style={{ width: "200px" }}>
                      {item._date}
                    </Col>
                    <Col title="Status" style={{ width: "250px" }}>
                      {
                        {
                          Employer: (
                            <div
                              className={`announcement-chip ${{
                                  New: "green",
                                  Reopened: "yellow",
                                  Closed: "red"
                                }[item._eventType]
                                }`}
                            >
                              <i className="material-icons">business</i>
                              <span>{item._status}</span>
                            </div>
                          ),
                          Job: (
                            <div
                              className={`announcement-chip ${{
                                  New: "green",
                                  Changed: "yellow",
                                  Closed: "red"
                                }[item._eventType]
                                }`}
                            >
                              <i className="material-icons">business_center</i>
                              <span>{item._status}</span>
                            </div>
                          )
                        }[item.entity]
                      }
                    </Col>
                    <Col title="Announcement">
                      {
                        {
                          Employer: Core.isAdminOrCoordinator() ? (
                            <a
                              className=""
                              href={Core.getLink(`/employer/view/${item.employerId}`)}
                            >
                              {item._name}
                            </a>
                          ) : (
                            item._name
                          ),
                          Job: (
                            <a className="" href={Core.getLink(`/job/view/${item.jobId}`)}>
                              {item._name}
                            </a>
                          )
                        }[item.entity]
                      }
                    </Col>
                    <Col title="Notes">
                      {Core.isAdminOrCoordinator() ? (
                        <TextField
                          name="note"
                          maxLength="140"
                          rows={1}
                          rowsMax={3}
                          multiLine={true}
                          underlineFocusStyle={lineStyle}
                          value={this.state.data[index].note}
                          onChange={(ev, note) =>
                            this.setState(
                              state => {
                                state.data[index].note = note;
                                return state;
                              },
                              then => {
                                clearTimeout(this.timeout);
                                this.timeout = setTimeout(
                                  st =>
                                    Announcement.update(
                                      item.id,
                                      this.state.data[index]
                                    ),
                                  1000
                                );
                              }
                            )
                          }
                          fullWidth
                        />
                      ) : (
                        item.note
                      )}
                    </Col>
                    <Col title="Action" style={{ width: "220px" }}>
                      {Core.isAdminOrCoordinator() && (
                        <Fragment>
                          <IconButton
                            onClick={ev => {
                              if (item.id) {
                                this.ConfirmDialog.open({
                                  message: {
                                    Employer: `${item._date} Employer - ${item._eventType
                                      } ${item._name}`,
                                    Job: `${item._date} Job - ${item._eventType
                                      } ${item._name}`
                                  }[item.entity],
                                  onConfirm: ev =>
                                    Announcement.delete(item.id, response =>
                                      this.fetchAnnouncements()
                                    )
                                });
                              } else {
                                this.setState(state => {
                                  state.data.splice(index, 1);
                                  return state;
                                });
                              }
                            }}
                          >
                            <i className="material-icons">delete</i>
                          </IconButton>
                          {!!item.jobId &&
                            /closed/i.test(eventType) && (
                              <IconButton
                                onClick={ev => {
                                  const desc = [
                                    item.job.jobTitle,
                                    "job",
                                    eventType + ":",
                                    item.note
                                  ].join(" ");
                                  const sources = [
                                    ...item.job.jobSourceList,
                                    ...item.job.employer.employerSourceList
                                  ]
                                    .map(item => {
                                      item = mapAccount(item);
                                      return [item._name, item.email].join(" ");
                                    })
                                    .join("\n");
                                  this.copyToClipboard(
                                    [
                                      desc,
                                      !!sources.trim().length
                                        ? sources
                                        : "no sources"
                                    ].join("\n")
                                  );
                                }}
                              >
                                <i className="material-icons">file_copy</i>
                              </IconButton>
                            )}
                        </Fragment>
                      )}
                    </Col>
                  </Row>
                );
              })}
            </Paper>
            <ConfirmDialog
              ref={self => (this.ConfirmDialog = self)}
              title="Delete Announcement?"
              message="This action can't be undone."
              actionLabel="Delete"
            />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

class ConfirmDialog extends Component {
  constructor() {
    super(...arguments);
    this.state = { open: false };
  }
  open = ({ message, onConfirm }) => {
    this.setState({ open: true, message, onConfirm });
  };
  submit = id => {
    this.close();
    if (this.props.onConfirm instanceof Function) {
      this.props.onConfirm();
    }
    if (this.state.onConfirm instanceof Function) {
      this.state.onConfirm();
    }
  };
  close = ev => this.setState({ open: false });
  render() {
    const actions = [
      <FlatButton label="Cancel" primary={true} onClick={this.close} />,
      <FlatButton
        label={this.props.actionLabel}
        primary={true}
        onClick={this.submit}
      />
    ];
    return (
      <Dialog
        title={this.props.title}
        actions={actions}
        modal={true}
        contentStyle={{ width: 400 }}
        open={this.state.open}
      >
        {this.props.message}
        <br />
        {!!this.state.message && `"${this.state.message}"`}
      </Dialog>
    );
  }
}

export default Announcements;
