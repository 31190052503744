import { Paper } from '@mui/material';
import {
  AppBar,
  FlatButton,
  IconButton,
  MenuItem,
  SelectField
} from 'material-ui';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Announcement from '../../lib/Announcement';
import Core from '../../lib/Core';
import Definition from '../../lib/Definition';
import Employer from '../../lib/Employer';
import Job from '../../lib/Job';
import { extended as announcementModel } from '../../lib/models/announcement';
import Store from '../../lib/Store';
import styles from '../Forms/Styles';
import EditHotJobsPaper from './EditHotJobsPaper';

const lineStyle = { borderColor: '#59736f' };

class Announcements extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      data: [{ ...announcementModel }],
      employers: [],
      jobs: [],
    };
    Store.set('path', window.location.href);
    Employer.getActives((employers) => {
      Job.getActives((jobs) => {
        this.setState({
          jobs: jobs.sort((a, b) =>
            String(a._name).localeCompare(String(b._name))
          ),
          employers,
        });
      });
    });
  }
  publish = (ev) => {
    const data = [...this.state.data];
    const next = (em) => {
      setTimeout((st) => {
        if (!!data.length) {
          const announcement = data.pop();
          Core.log({ announcement });
          if (
            !announcement.id &&
            {
              Employer: !!announcement.employerId,
              Job: announcement.jobId,
            }[announcement.entity] &&
            announcement.eventType
          ) {
            Announcement.post(
              announcement,
              (response) => {
                const eventType = Definition.getLabel(
                  `announcementJobEventType`,
                  announcement.eventType
                );
                const inactiveId = Definition.getId('state', 'Inactive');
                const jobId = announcement.jobId;
                Core.log({ jobId, inactiveId, eventType });
                if (jobId && /closed/i.test(eventType)) {
                  Job.update(jobId, { state: inactiveId });
                }
                next();
              },
              (error) => {
                Core.showMessage('Announcement Failure');
                next();
              }
            );
          } else {
            next();
          }
        } else {
          Core.go({ ...this.props, to: '/announcements' });
        }
      });
    };
    next();
  };
  render() {
    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }
    let showSave = false;
    return (
      <MuiThemeProvider>
        <div className="management bgcolor2 min-100vh">
          <AppBar
            title="Create Announcements"
            titleStyle={styles.AppBar.title}
            style={styles.AppBar.nav}
            className="fixed"
            iconElementLeft={
              <FlatButton
                icon={<i className="material-icons">arrow_back</i>}
                style={styles.AppBar.backButton}
                onClick={(ev) => {
                  Core.goBack(this.props);
                }}
              />
            }
          />
          <div className="main-padding padding-top-96">
            <Paper className="management announcement-paper main-padding scroll-x">
              <div className="management announcement-headers inline-blocks margin-rights">
                <div>#</div>
                <div>Entity</div>
                <div>Subject</div>
                <div>Event Type</div>
                <IconButton
                  onClick={(ev) => {
                    this.setState((state) => {
                      state.data.push({ ...announcementModel });
                      return state;
                    });
                  }}
                >
                  <i className="material-icons">add_circle_outline</i>
                </IconButton>
              </div>
              {this.state.data.map((item, index) => (
                <div
                  key={Core.getKey()}
                  className="management announcement-row inline-blocks margin-rights"
                >
                  <div>#{index + 1}</div>
                  <SelectField
                    underlineFocusStyle={lineStyle}
                    value={this.state.data[index].entity}
                    onChange={(ev, i, entity) =>
                      this.setState((state) => {
                        state.data[index].entity = entity;
                        return state;
                      })
                    }
                  >
                    <MenuItem value="Employer" primaryText="Employer" />
                    <MenuItem value="Job" primaryText="Job" />
                  </SelectField>
                  {this.state.data[index].entity === 'Employer' ? (
                    <SelectField
                      underlineFocusStyle={lineStyle}
                      value={this.state.data[index].employerId}
                      onChange={(ev, i, employerId) =>
                        this.setState((state) => {
                          state.data[index].employerId = employerId;
                          return state;
                        })
                      }
                    >
                      {this.state.employers.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          primaryText={item.name}
                        />
                      ))}
                    </SelectField>
                  ) : (
                    <SelectField
                      underlineFocusStyle={lineStyle}
                      value={this.state.data[index].jobId}
                      menuItemStyle={{ width: 400 }}
                      onChange={(ev, i, jobId) =>
                        this.setState((state) => {
                          state.data[index].jobId = jobId;
                          return state;
                        })
                      }
                    >
                      {this.state.jobs.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          primaryText={item._name}
                        />
                      ))}
                    </SelectField>
                  )}
                  <SelectField
                    underlineFocusStyle={lineStyle}
                    value={this.state.data[index].eventType}
                    onChange={(ev, i, eventType) =>
                      this.setState((state) => {
                        state.data[index].eventType = eventType;
                        return state;
                      })
                    }
                  >
                    {Definition.get(
                      `announcement${this.state.data[index].entity}EventType`
                    ).map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        primaryText={item.label}
                      />
                    ))}
                  </SelectField>
                  <IconButton
                    onClick={(ev) => {
                      this.setState((state) => {
                        state.data.splice(index, 1);
                        return state;
                      });
                    }}
                  >
                    <i className="material-icons">remove_circle_outline</i>
                  </IconButton>
                  {!item.id &&
                    {
                      Employer: !!this.state.data[index].employerId,
                      Job: this.state.data[index].jobId,
                    }[this.state.data[index].entity] &&
                    this.state.data[index].eventType &&
                    (showSave = true) && <i className="material-icons">done</i>}
                </div>
              ))}
              <br />
              {showSave && (
                <FlatButton
                  className="button-1 float-right"
                  label="Publish"
                  onClick={this.publish}
                />
              )}
            </Paper>
            <EditHotJobsPaper />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default Announcements;
