import React, {Component, Fragment} from "react";

/* Material files */
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {TextField} from "material-ui";

/* Lib files */
import RichTextBox from "../../../Forms/RichTextBox";

/* CSS files */
import styles from "../../../Forms/Styles";
import getJobQuestionValue from "../../../../lib/getJobQuestionValue";

class CandidatePipe extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            submissionNotes: this.props.candidate.submissionNotes,
            submissionNotesToEmployer: this.props.candidate.submissionNotesToEmployer,
            privateNotes: this.props.candidate.privateNotes,
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.candidate !== this.props.candidate) {
            this.setState({
                submissionNotes: nextProps.candidate.submissionNotes,
                submissionNotesToEmployer: nextProps.candidate.submissionNotesToEmployer,
                privateNotes: nextProps.candidate.privateNotes,
            });
        }
    }

    render() {
        const {candidate, saveContentCandidate,job} = this.props;
        const {submissionNotes, submissionNotesToEmployer, privateNotes} = this.state;
        const currentJobAnswersFromCando = !!job ? Object(Object(candidate).permittedJobsAnswers)[job.id] || [] : [];

        return <Fragment>
            <Card className="match-new section-card">
                <CardContent className="match-new card-content">
                    {/* <h2 className="match-new section-header">Write Ups</h2>
                            <hr/> */}
                    <Fragment>
                        <div className="match-new heading-center">RECRUITER WRITE UP&nbsp;&nbsp;<i
                            className="material-icons icon-edit"
                            onClick={() => saveContentCandidate("submissionNotes", submissionNotes)}>save</i>
                        </div>
                        <RichTextBox
                            key={`submission-notes-${candidate.id}`}
                            className="match-new"
                            name="submissionNotes"
                            value={submissionNotes}
                            onChange={submissionNotes => {
                                this.setState({submissionNotes});
                            }}
                        />
                    </Fragment>

                    <Fragment>
                        <div className="match-new heading-center">PRE-SCREEN QUESTIONS</div>

                        <ol>{(Object(job).mustHaveQuestions||[]).map(q=> {
                            return <li>
                                <p><strong>{q.question}</strong></p>
                                <p>{getJobQuestionValue(currentJobAnswersFromCando, q.id)}</p>
                            </li>
                        })}</ol>


                    </Fragment>

                    <Fragment>
                        <div className="match-new heading-center">10X10 SUBMISSION WRITE UP&nbsp;&nbsp;<i
                            className="material-icons icon-edit"
                            onClick={() => saveContentCandidate("submissionNotesToEmployer", submissionNotesToEmployer)}>save</i>
                        </div>
                        <RichTextBox
                            key={`submission-writeups-${candidate.id}`}
                            className="match-new"
                            name={"submissionNotesToEmployer"}
                            value={submissionNotesToEmployer}
                            onChange={submissionNotesToEmployer => {
                                this.setState({submissionNotesToEmployer});
                            }}
                        />
                    </Fragment>

                    {!!privateNotes && (<Fragment>
                        <div className="match-new heading-center">CANDIDATE PRIVATE NOTES&nbsp;&nbsp;<i
                            className="material-icons icon-edit"
                            onClick={() => saveContentCandidate("privateNotes", privateNotes)}>save</i></div>
                        <TextField
                            className="text-field match-new"
                            name={"privateNotes"}
                            rows={2}
                            textareaStyle={styles.TextField.inputStyle}
                            underlineStyle={styles.TextField.underlineStyle}
                            style={styles.TextField}
                            //rowsMax={12}
                            multiLine={true}
                            value={privateNotes}
                            onChange={(event, privateNotes) => {
                                this.setState({privateNotes});
                            }}
                            fullWidth
                        />

                    </Fragment>)}
                </CardContent>
            </Card>
        </Fragment>
    }
}

export default CandidatePipe;