import React, {Component, Fragment} from "react";
import Definition from "../../../../lib/Definition";
import ComparisonHaves from "../../../Candidates/MatchNew/CandidatePipe/ComparisonHaves";
import BasicChip from "../../../Candidates/MatchNew/CandidatePipe/ElementsComparisonHaves/Chip";
import ChipGroup from "../../../Candidates/MatchNew/CandidatePipe/ElementsComparisonHaves/ChipGroup";
import ChipTag from "../../../Candidates/MatchNew/CandidatePipe/ElementsComparisonHaves/ChipTag";
import {getColorsOnBorder} from "../../../../lib/tools/getColorsOnBorders";
import {
    Chip,
} from "material-ui";

class MustChip extends Component {

    constructor() {
        super(...arguments);
        this.state ={
            element: this.props.element
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.element !== this.props.element){
            this.setState({
                element: this.props.element
            })
        }
    }

    handlerValue = (ev) => {
        const {element} = this.state;
        element.specialInstruction = ev.target.value;
        this.setState({element}, () =>{
            const {onApply} = this.props;
            !!onApply && onApply(element);
        });
    }

    handlerCategory = (category) => {

        let {element} = this.state;
        element.category = category;
        this.setState({element}, () =>{
            const {onApply} = this.props;
            !!onApply && onApply(element);
        });
    }

    render() {
        let {element} = this.state;
        let {onlyView, toMatchWith, isMatchedComplex, source,candidateResume,pageSource} = this.props;
        let defRaw = !!Definition.getRawDef(element.key) ? Definition.getRawDef(element.key) : [];

        let groupEntry = Array.isArray(defRaw.groups) ? defRaw.groups.find(group=>group.id===element.value) : {};
        let label = element.type==='chipGroup' ? groupEntry.name : Definition.getLabel(element.key, element.value);
        label = element.type ==='chipTag' ? element.value : label;
        let isMatched = false;
        if(!source && !!toMatchWith){
            if(!!element.type && element.type === "chipGroup"){
                isMatched = ChipGroup.result(element, toMatchWith);
            }else if(!!element.type && element.type === "chipTag"){
                isMatched = ChipTag.result(element, candidateResume);
            }else{
                isMatched = BasicChip.isChipMatched(element, toMatchWith);
            }
        }

        return <Fragment>
            {source !== 'complex' && !!onlyView && !!toMatchWith && <ComparisonHaves
                element={element}
                toMatchWith={toMatchWith}
                candidateResume={candidateResume}
            />}
            {!!label ? <Chip
                backgroundColor={!!onlyView ? '#FFF':"#536DFF"}
                labelStyle={!onlyView ? {
                    padding: '5px',
                    // paddingRight: '5px',
                    lineHeight: '0px',
                    color: '#fff'
                } : {
                    padding: '5px',
                    color: '#454545',
                    lineHeight: '0px',
                    fontSize: '0.8125rem'
                }}
                style={!!onlyView ? {
                    margin: '2px 0px',
                    display: "inline-block",
                    border: getColorsOnBorder(pageSource, toMatchWith, isMatchedComplex, isMatched)
                } : {
                    margin: '2px 0px',
                    display: "inline-block",
                    border: "1px solid #536DFF"
                }}
            >

               {element.preferred ? <strong >*{label}</strong> : element.type==='chipGroup' ? <strong >{label}*</strong> : label}
            </Chip> : null}
            &nbsp;{!!onlyView && <span>{element.specialInstruction}</span>}&nbsp;
            {!onlyView && !!element.specialInstruction && <input class="fc-value-editor" value={element.specialInstruction}  onChange={(ev) => this.handlerValue(ev)}/>}&nbsp;
            {/* {!!element.category&&<span style={{backgroundColor: 'green'}}>Category : {element.category}</span>} */}
            {/* {!!element.category && !onlyView &&
                <NewCategoryDropdown
                    category={element.category}
                    handlerCategory={this.handlerCategory}
                    onlyView={onlyView}
                />
            } */}
        </Fragment>
    }
}

export default MustChip;
