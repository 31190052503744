import moment from "moment";

const getEngagementGroups = () => {
  return {
    open: {
      'sequence-1': {
        review: { label: 'review', count: 0, parent: 'open' },
        onsite: { label: 'onsite', count: 0, parent: 'open' },
        offer: { label: 'offer', count: 0, parent: 'open' },
        hire: { label: 'hire', count: 0, parent: 'open' },
        screen: { label: 'Screen', count: 0, parent: 'open' },
        guarantee: { label: 'guarantee', count: 0, parent: 'open' },
        end: { label: 'end', count: 0, parent: 'open' },
      },
      'sequence-2': {
        confirmation: { label: 'confirmation', count: 0, parent: 'open' },
        submission: { label: 'submission', count: 0, parent: 'open' },
      },
      // screen: {label: 'screen', count: 0, parent: 'open'},
      // pending: {label: 'pending', count: 0,parent: 'open'},
      count: 0
    },
    closed: {
      'sequence-1': {
        review: { label: 'review', count: 0, parent: 'closed' },
        onsite: { label: 'onsite', count: 0, parent: 'closed' },
        offer: { label: 'offer', count: 0, parent: 'closed' },
        hire: { label: 'hire', count: 0, parent: 'closed' },
        guarantee: { label: 'guarantee', count: 0, parent: 'closed' },
        end: { label: 'end', count: 0, parent: 'closed' },
        screen: { label: 'Screen', count: 0, parent: 'closed' },
      },
      'sequence-2': {
        confirmation: { label: 'confirmation', count: 0, parent: 'closed' },
        submission: { label: 'submission', count: 0, parent: 'closed' },
      },
      // screen: {label: 'screen', count: 0, parent: 'open'},
      // pending: {label: 'pending', count: 0,parent: 'open'},
      count: 0
    }
  }
};

const getLastSubmissionDate = (engagements, account) => {
  const submissions = engagements
    .map(eng =>
      eng.submitted
        ? moment(eng.submitted)
          .toDate()
          .getTime()
        : 0
    )
    .filter(time => !!time);
  const lastSubmissionTime = Math.max.apply(null, submissions);
  const lastSubmission = submissions.length
    ? moment(lastSubmissionTime).format("MM/DD/YY")
    : 0;

  return lastSubmission;
}

const render = (engagmentGroups, engagements) => {
  const lastSubmission = getLastSubmissionDate(engagements);

  let groups = [];
  let sequencesOpen = {};

  let joinSeq1 = Object.values(engagmentGroups.open['sequence-1']).map((grp, index) =>
    (grp.count > 0) && `${grp.count} ${grp.label}`
  ).filter(el => !!el);

  let joinSeq2 = Object.values(engagmentGroups.open['sequence-2']).map((grp, index) =>
    (grp.count > 0) && `${grp.count} ${grp.label}`
  ).filter(el => !!el);

  if (!!joinSeq1.length) {
    sequencesOpen['sequence-1'] = joinSeq1.join(" - ");
  }
  if (!!joinSeq2.length) {
    sequencesOpen['sequence-2'] = joinSeq2.join(" - ");
  }

  if (!!engagmentGroups.open.count > 0) {
    groups.push(`Active * (${engagmentGroups.open.count}) ${Object.values(sequencesOpen).join(" | ")}`);
  }

  let sequencesClose = {};

  joinSeq1 = Object.values(engagmentGroups.closed['sequence-1']).map((grp, index) =>
    (grp.count > 0) && `${grp.count} ${grp.label}`
  ).filter(el => !!el);
  joinSeq2 = Object.values(engagmentGroups.closed['sequence-2']).map((grp, index) =>
    (grp.count > 0) && `${grp.count} ${grp.label}`
  ).filter(el => !!el);

  if (!!joinSeq1.length) {
    sequencesClose['sequence-1'] = joinSeq1.join(" - ");
  }
  if (!!joinSeq2.length) {
    sequencesClose['sequence-2'] = joinSeq2.join(" - ");
  }

  if (!!engagmentGroups.closed.count > 0) {
    groups.push(`Closed (${engagmentGroups.closed.count}) ${Object.values(sequencesClose).join(" | ")}`);
  }

  if (!!lastSubmission) {
    groups.push(`Last submission date : ${lastSubmission}`);
  }

  return groups;
}

const getEngagementByGroup = (engagements, item) => {
  let groups = Object.assign({}, getEngagementGroups());

  engagements.forEach(eng => {
    if (/open/i.test(eng.state) && /review/i.test(eng.stage)) {
      groups.open['sequence-1'].review.count += 1;
      groups.open.count += 1;
    }

    else if (/closed/i.test(eng.state) && /review/i.test(eng.stage)) {
      groups.closed['sequence-1'].review.count += 1;
      groups.closed.count += 1;
    }
    else if (/open/i.test(eng.state) && /screen/i.test(eng.stage)) {
      groups.open['sequence-1'].screen.count += 1;
      groups.open.count += 1;
    } else if (/closed/i.test(eng.state) && /screen/i.test(eng.stage)) {
      groups.closed['sequence-1'].screen.count += 1;
      groups.closed.count += 1;
    }
    else if (/open/i.test(eng.state) && /onsite/i.test(eng.stage)) {
      groups.open['sequence-1'].onsite.count += 1;
      groups.open.count += 1;
    }
    else if (/closed/i.test(eng.state) && /onsite/i.test(eng.stage)) {
      groups.closed['sequence-1'].onsite.count += 1;
      groups.closed.count += 1;
    }
    else if (/open/i.test(eng.state) && /offer/i.test(eng.stage)) {
      groups.open['sequence-1'].offer.count += 1;
      groups.open.count += 1;
    }
    else if (/closed/i.test(eng.state) && /offer/i.test(eng.stage)) {
      groups.closed['sequence-1'].offer.count += 1;
      groups.closed.count += 1;
    }
    else if (/open/i.test(eng.state) && /confirmation/i.test(eng.stage)) {
      groups.open['sequence-2'].confirmation.count += 1;
      // groups.open.count +=1;
    }
    else if (/closed/i.test(eng.state) && /confirmation/i.test(eng.stage)) {
      groups.closed['sequence-2'].confirmation.count += 1;
      // groups.closed.count += 1;
    }
    else if (/open/i.test(eng.state) && /submission/i.test(eng.stage)) {
      groups.open['sequence-2'].submission.count += 1;
      // groups.open.count +=1;
    }
    else if (/closed/i.test(eng.state) && /submission/i.test(eng.stage)) {
      groups.closed['sequence-2'].submission.count += 1;
      // groups.closed.count += 1;
    }
    else if (/open/i.test(eng.state) && /hire/i.test(eng.stage)) {
      groups.open['sequence-1'].hire.count += 1;
      groups.open.count += 1;
    }
    else if (/closed/i.test(eng.state) && /hire/i.test(eng.stage)) {
      groups.closed['sequence-1'].hire.count += 1;
      groups.closed.count += 1;
    }
    // else if(/open/i.test(eng.state) && /pending/i.test(eng.stage)) {
    //     groups.open.pending.count += 1;
    //     groups.open.count +=1;
    // } else if(/closed/i.test(eng.state) && /pending/i.test(eng.stage)) {
    //     groups.closed.pending.count += 1;
    //     groups.closed.count += 1;
    // }
    else if (/open/i.test(eng.state) && /guarantee/i.test(eng.stage)) {
      groups.open['sequence-1'].guarantee.count += 1;
      groups.open.count += 1;
    }
    else if (/closed/i.test(eng.state) && /guarantee/i.test(eng.stage)) {
      groups.closed['sequence-1'].guarantee.count += 1;
      groups.closed.count += 1;
    }
    else if (/open/i.test(eng.state) && /end/i.test(eng.stage)) {
      groups.open['sequence-1'].end.count += 1;
      groups.open.count += 1;
    }
    else if (/closed/i.test(eng.state) && /end/i.test(eng.stage)) {
      groups.closed['sequence-1'].end.count += 1;
      groups.closed.count += 1;
    }
    // else if(/closed/i.test(eng.state)) {
    //     groups.closed.inactive.count += 1;
    //     groups.closed.count += 1;
    // }
  });

  return {
    count: groups.open.count + groups.closed.count,
    render: render(groups, engagements)
  };
}

const toExport = {
  getEngagementByGroup,
  getLastSubmissionDate
}

export default toExport;