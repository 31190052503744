/** ============================================ µ
 * @description Recruiter/Home [JSX]
 *              UI Component
 * @route       /recruiter
 * @createdAt   2021-08-17 Tue
 * @updatedAt   2021-08-20 Fri
 * ============================================ */
/* IMPORTS ==================================== */

import { Paper } from "@mui/material";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import Core from "../../lib/Core";
import TemplateLib, {
  TEMPLATE_NAME_HOT_JOBS_TRUSTED,
  TEMPLATE_NAME_RECRUITER_TODO_REMINDER,
  TEMPLATE_TYPE_DOCUMENT,
  TEMPLATE_TYPE_EMAIL
} from "../../lib/Template.lib";
import Col from "../Forms/Col";
import Loading from "../Forms/Loading";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

async function fetchTodoList({
  recruiterId,
  setTodoList
}) {
  const template = await TemplateLib.getBy({
    type: TEMPLATE_TYPE_EMAIL,
    name: TEMPLATE_NAME_RECRUITER_TODO_REMINDER
  });
  TemplateLib.getRender({
    templateId: template.id,
    recruiterId,
    setRenderedTemplate: ({ errors = [], rendered = {}, mixins = {} }) => {
      const { bodyHtml = '' } = rendered;
      let todoList = bodyHtml.replace(/<strong>.*Your todo:.*<\/strong>|<p>.*<strong>.*Note:.*<\/strong>/ig, 'DIVIDER').split('DIVIDER');
      todoList = todoList.length === 3 ? (`<p>${todoList[1].trim()}`) : '';
      console.debug('body', bodyHtml);
      console.debug('todoList', todoList);
      setTodoList(todoList);
    }
  });
};

function RecruiterHome(props) {

  const { t } = useTranslation();

  const recruiterId = Core.getUserId();

  const [todoList, setTodoList] = React.useState(true);
  const [hotJobs, setHotJobs] = React.useState(true);

  React.useEffect(() => {
    if (todoList === true) {
      fetchTodoList({ recruiterId, setTodoList });

    }
  }, [todoList, recruiterId]);

  React.useEffect(() => {
    if (hotJobs === true) {
      TemplateLib.getBy({
        type: TEMPLATE_TYPE_DOCUMENT,
        name: TEMPLATE_NAME_HOT_JOBS_TRUSTED,
        setTemplate: template => setHotJobs(template.templates.content.replace(/<a /gi, '<a target="_blank" rel="noreferrer" '))
      })
    }
  }, [hotJobs]);

  console.debug({ recruiterId, todoList, hotJobs });

  return (
    <div className="d-flex flex-wrap py-2 px-1" style={{ justifyContent: "center" }}>

      <Paper className="w-sm m-1 p-1">
        <Col fullWidth>
          <label className="mb-2">
            {t(`Your todo list`)}
          </label>
          {todoList === true ? (
            <Loading>
              Fetching list
            </Loading>
          ) : !!todoList?.length ? (
            <div dangerouslySetInnerHTML={{ __html: todoList }} />
          ) : (
            <span>{t('recruiter.home.todoList.empty')}</span>
          )}
        </Col>
      </Paper>
      <Paper className="w-sm m-1 p-1">
        <Col>
          <label className="mb-2">
            {t(`Hot jobs`)} {hotJobs?.updatedAt && <>({moment(hotJobs.updatedAt).format('M/D')})</>}
          </label>
        </Col>
        <Col fullWidth>
          {hotJobs === true ? (
            <Loading>
              Fetching list
            </Loading>
          ) : !!hotJobs?.length ? (
            <div dangerouslySetInnerHTML={{ __html: hotJobs }} />
          ) : (
            <span>Document not found</span>
          )}
        </Col>
      </Paper>
    </div>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export {
  RecruiterHome as default,
  RecruiterHome,
};

/* ============================================ */