import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import copy from 'copy-to-clipboard';
import {
  Checkbox,
  Divider,
  FlatButton,
  IconButton,
  IconMenu,
  MenuItem,
} from 'material-ui';
import { Card, CardText } from 'material-ui/Card';
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';
import moment from 'moment';
import dig from 'object-dig';
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import withImportantStyle from 'react-with-important-style';
import Candidate from '../../../lib/Candidate';
import CandoPermittedJob from '../../../lib/CandoPermittedJob';
import Core from '../../../lib/Core';
import {
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID,
} from '../../../lib/Definition';
import LocationLib from '../../../lib/DefinitionLocation.lib';
import Engagement from '../../../lib/Engagement';
import Google from '../../../lib/Google';
import Job from '../../../lib/Job';
import { getCandidateModel } from '../../../lib/models/candidate';
import { hoverDisplay } from '../../../lib/models/engagement';
import SovrenData from '../../../lib/SovrenData';
import Streak from '../../../lib/Streak';
import copyHTML from '../../../lib/tools/copyHtml';
import downloadFile from '../../../lib/tools/downloadFile';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';
import EmailPreview from '../../Dialogs/EmailPreview';
import PrintMlScore from '../../Shared/PrintMlScore';
import ResumeMatch from '../../Shared/ResumeMatch';
import ManageOwnershipPermittedJobs from '../ManageOwnershipPermittedJobs';

const mdash = '—';
const MyPara = withImportantStyle('p');
const MySpan = withImportantStyle('span');

class CandidateCard extends Component {
  timeout;
  constructor() {
    super(...arguments);

    this.parent = this.props.parent;
    this.state = {
      ...getCandidateModel({ extended: true }),
      /** controller states */
      isEngagementsRetrieved: true,
      expanded: false,
      openedColor: null,
      CardStyle: null,
      rightArrow: {},
      checked: false,
      boxes: [],
      /** load */
      ...this.props.model,
      blacklisted: '',
      mustHavesDialog: false,
    };
    if (dig(this.parent, 'parent', 'name') === 'JobMatch') {
      const jobBlackList = this.parent.parent.state.jobBlackList;
      const employerBlackList = Object(
        this.parent.parent.state.employer
      ).employerBlackList;
      setTimeout((st) => {
        if (
          !!jobBlackList.find(
            (recruiter) => recruiter.id === this.state.recruiter.id
          ) ||
          !!employerBlackList.find(
            (blacklisted) => blacklisted.id === this.state.recruiter.id
          )
        ) {
          this.setState({
            blacklisted: <b style={{ display: 'block' }}>Blacklisted</b>,
          });
        }
      }, 100);
    }
  }
  unexpandCard = (event) => {
    // if (Core.isAdmin()) {
    this.setState({
      expanded: false,
      CardStyle: null,
      openedColor: null,
      rightArrow: { transform: 'rotate(0deg)' },
    });
    // }
  };
  expandCard = (ev) => {
    // if (Core.isAdmin()) {
    Engagement.getWhere({ candidateId: this.state.id }, (res) => {
      const allJobIds = res.map((eng) => eng.jobId);
      CandoPermittedJob.get(
        {
          where: {
            jobId: {
              inq: allJobIds,
            },
            candidateId: this.state.id,
          },
        },
        (candoPermittedJobs) => {
          res.forEach((eng) => {
            eng.candoPermittedJobs = candoPermittedJobs.filter(
              (cpj) => cpj.jobId === eng.jobId
            );
          });

          this.setState(
            {
              expanded: true,
              CardStyle: { margin: '10px auto' },
              openedColor: { color: '#715EFF' },
              rightArrow: { transform: 'rotate(180deg)' },
              engagements: res,
            },
            (then) => Core.log(this.state)
          );
        }
      );
    });

    /** * /
    if (!this.state.boxes.length && !this.state.isEngagementsRetrieved) {
      this.fetchEngagements(engagements => {
        if (!!engagements.length) {
          Streak.getBoxes(engagements, boxes => {
            this.setState({ boxes, isEngagementsRetrieved: true });
          });
        } else {
          this.setState({ isEngagementsRetrieved: true });
        }
      });
    }
    /** */
    //}
  };
  fetchEngagements = (cb) => {
    let where = {};
    if (Core.isAdminOrCoordinator()) {
      where = { candidateId: this.state.id };
    } else {
      where = { candidateId: this.state.id, state: 'Open' };
    }
    Engagement.getWhere(where, (engagements) => {
      this.setState({ engagements }, (then) => {
        if (cb instanceof Function) {
          cb(engagements);
        }
      });
    });
  };
  handleToggle = (event) => {
    this.state.expanded ? this.unexpandCard() : this.expandCard();
  };
  onCheckStar = (ev, checked) => {
    const updateLocalList = (response) => {
      const state = {
        id: this.state.id,
        starredId: response.id,
        starred: response.starred,
        filters: {
          ...this.state.filters,
          Starred: ['Non Starred', 'Starred'][~~Boolean(response.starred)],
        },
      };
      this.setState(state);
      this.parent.updateItem(state);
    };
    Candidate.updateStarred(
      this.state.id,
      this.state.starredId,
      checked,
      updateLocalList
    );
    this.unexpandCard();
  };
  copyString = (str) => {
    if (
      copy(str, {
        debug: true,
        message: 'Press #{key} to copy',
      })
    ) {
      Core.showMessage(`${str} copied!`);
    } else {
      Core.showMessage('Fail copy!');
    }
  };
  bulkCopy() {
    this.fetchEngagements((engagements) => {
      const contents = [];
      engagements.forEach((eng) => {
        if (/confirmation/i.test(eng.stage) && /open/i.test(eng.state)) {
          // Core.log({ job: Job.getPreview(eng.job) });
          contents.push(Job.getPreview(eng.job));
        }
      });
      if (!!contents.length) {
        copyHTML('<p>' + contents.join('</p><p>') + '</p>')
          .then((em) => {
            Core.log('Copy email command was successful');
            Core.showMessage('Copied!');
          })
          .catch((ex) => {
            Core.log('Oops, unable to copy');
            Core.showMessage('Fail copy!');
          });
      } else {
        Core.showMessage('There are no Engagements on Confirmation stage');
      }
    });
  }
  openMessage = (ev) => {
    /** */
    Core.showMessage('Getting snippets, wait a moment...');
    const continueFlow = (snippets) => {
      Core.hideMessage();
      Core.log({ snippets });
      const emails = [];
      const candidate = this.state;
      candidate.email &&
        emails.push({
          name: candidate._name || 'Action Owner',
          email: candidate.email,
        });
      candidate.recruiter.email &&
        emails.push({
          name: candidate.recruiter._name || 'Recruiter',
          email: candidate.recruiter.email,
        });
      Core.dialog.open({
        title: <>Message</>,
        message: (
          <EmailPreview
            ref={(self) => (this.EmailMessage = self)}
            emails={emails}
            to={!!emails[0] && [emails[0]]}
            subject={emails.map((item) => item.name).join(', ')}
            body={[candidate._name, candidate.recruiter._name]
              .filter((line) => !!line)
              .join('<br/>')}
            snippets={snippets}
          />
        ),
        className: 'p-0',
        actions: [
          <FlatButton
            label="Cancel"
            className="button-flat-darker"
            onClick={(ev) => {
              Core.dialog.close();
            }}
          />,
          <FlatButton
            label="Send"
            className="button-white-cyan"
            onClick={(ev) => {
              Core.dialog.close();
              Google.sendEmail(
                {
                  ...this.EmailMessage.getParams(),
                  source: 'From CandidateCard, CandidateCard.js line 274',
                },
                (response) => Core.showMessage('Email sent'),
                (error) => Core.showFailure(error)
              );
            }}
          />,
        ],
      });
    };
    Streak.getSnippets(
      (snippets) => continueFlow(snippets),
      (error) => {
        continueFlow([]);
        Core.showMessage('Snippets:' + error);
      }
    );
    /** */
  };

  handlerForceMlOn = () => {
    // this.setState({forceMlOn:true});
    this._forceMlOn = true;
    this.setState({});
  };

  componentDidUpdate = (prevProps, prevState) => {
    // this.state.forceMlOn = false;
    this._forceMlOn = false;
  };

  staticRowDisplayColor = () => {
    let obj = {};
    let candidate = this.state;
    let job = this.props.parentModel;

    obj.getSalaryColor = ((candidate, job) => () => {
      if (!candidate.minimumSalary || !job.salaryMax) {
        return '';
      }

      let color;

      if (candidate.minimumSalary <= job.salaryMax) {
        color = 'green';
      } else if (candidate.minimumSalary <= 1.15 * job.salaryMax) {
        color = 'grey';
      } else if (candidate.minimumSalary <= 1.4 * job.salaryMax) {
        color = 'grey';
      } else {
        color = 'red';
      }

      return color;
    })(candidate, job);

    obj.getVisaColor = ((candidate, job) => () => {
      let menu = Candidate.menus.find((obj) => obj.key === 'visa');
      let myMappings = menu.mappings[job._visaTransfer] || [];
      return myMappings.includes(candidate._visa) ? 'green' : 'red';
    })(candidate, job);

    obj.getLocationColor = ((candidate, job) => () => {
      let hasMatched = job._locations.split(',').some((label) => {
        let regx = new RegExp(label, 'i');
        return regx.test(candidate._workLocationIds);
      });
      return hasMatched ? 'green' : 'red';
    })(candidate, job);

    obj.getYearsXp = ((candidate, job) => () => {
      let color = 'red';
      let diff =
        parseFloat(job.minYearsOfExperience) -
        parseFloat(candidate._yearsOfExperienceForCalc);
      let diffPercent = diff / parseInt(job.minYearsOfExperience);

      if (diff <= 0) {
        color = 'green';
      } else if (diff > 0 && diffPercent <= 0.4) {
        color = 'grey';
      }

      return color;
    })(candidate, job);
    return obj;
  };

  staticRowDisplayTag = (jobAtr, candAtr, color) => {
    if (!jobAtr || !candAtr) {
      return candAtr;
    }

    return (
      <MySpan
        style={{
          backgroundColor: `${color} !important`,
          color: 'white !important',
          padding: '5px !important',
        }}
      >
        {candAtr}
      </MySpan>
    );
  };

  addEngagementToPermittedJobs = (eng) => {
    const { id, jobsPermitted } = this.state;

    Candidate.update(
      id,
      { jobsPermitted: [...jobsPermitted, eng.jobId] },
      (cando) => {
        alert('candidate updated successfully with added permitted job');
        this.setState({ jobsPermitted: [...cando.jobsPermitted] });
      }
    );
  };

  render() {
    console.log('- ------------------------ candidate card ');
    const {
      jobsPermitted,
      inOfficeRemoteFlags = [],
      officeLocations = [],
      candidateLocations = [],
    } = this.state;
    const engaged =
      this.props.onCheck &&
      (!!this.parent.parent.state.engagements || []).find(
        (eng) => eng.candidateId === this.state.id
      );
    let eng = Array.isArray(dig(this.parent, 'parent', 'state', 'engagements'))
      ? this.parent.parent.state.engagements.length
      : 0;
    let engInFiltered = 0;

    const engagements =
      dig(this.parent, 'parent', 'state', 'engagements') || [];
    const filteredObjects = dig(this.parent, 'parent', 'List', 'data');

    engagements.forEach((eng) => {
      filteredObjects.forEach((obj) => {
        if (eng.candidateId === obj.id) {
          engInFiltered++;
        }
      });
    });

    console.log(engInFiltered + ' out of ' + eng + ' Engagements');
    console.log(
      engInFiltered +
      ' out of ' +
      (filteredObjects || []).length +
      ' Candidates'
    );

    const tdStyle = Core.isAdminOrCoordinator()
      ? { verticalAlign: 'top !important' }
      : { verticalAlign: 'middle !important' };

    // console.debug(this.state);

    let _candidateLocationsLabels = '';
    if (inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)) {
      /* epic-3038(new locations)-story-3689-m2 | 2021-08-05 Thu µ */
      _candidateLocationsLabels = LocationLib.getLocationsString({
        locations: candidateLocations,
      });
    }

    let _officeLocationsLabels = '';
    if (
      inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID) ||
      inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
    ) {
      /* epic-3038(new locations)-story-3689-m2 | 2021-08-05 Thu µ */
      _officeLocationsLabels = LocationLib.getLocationsString({
        locations: officeLocations,
      });
    }

    return (
      <Card
        className={`row-card candidate${Core.isAdminOrCoordinator() && this.state.isDuplicate
          ? this.state.duplicatedLevel === 'primary'
            ? ' bgcolorredYellow'
            : ' bgcolorred'
          : ''
          }`}
        expanded={this.state.expanded}
        style={this.state.CardStyle}
      >
        {/** COLLAPSED VIEW */}
        <Table
          ref={(collapsedView) => (this.collapsedView = collapsedView)}
          className={`collapsed-view${Core.isAdminOrCoordinator() && this.state.isDuplicate
            ? this.state.duplicatedLevel === 'primary'
              ? ' bgcolorredYellow'
              : ' bgcolorred'
            : ''
            }`}
        >
          <TableBody displayRowCheckbox={false}>
            {this.props.source === 'JobMatch' && (
              <TableRow
                className="tr-match-override"
                style={{ backgroundColor: 'lightcyan' }}
                selectable={false}
              >
                <TableRowColumn
                  style={{
                    width: 36,
                    textAlign: 'right',
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                ></TableRowColumn>
                <TableRowColumn
                  title={`${this.state._platformRating}|${this.state._yearsOfExperienceForCalc}|${this.state._minimumSalary}|${this.state._visa}|${this.state._workLocationIds}`}
                  className="first-item td-match-override"
                  colSpan={6}
                >
                  {this.state._platformRating}&nbsp;&nbsp;|&nbsp;&nbsp;
                  {this.staticRowDisplayTag(
                    this.props.parentModel.minYearsOfExperience,
                    this.state._yearsOfExperienceForCalc,
                    this.staticRowDisplayColor().getYearsXp()
                  )}
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  {this.staticRowDisplayTag(
                    this.props.parentModel.salaryMax,
                    this.state.minimumSalary,
                    this.staticRowDisplayColor().getSalaryColor()
                  )}
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  {this.staticRowDisplayTag(
                    this.props.parentModel._locations,
                    this.state._workLocationIds,
                    this.staticRowDisplayColor().getLocationColor()
                  )}
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  {this.staticRowDisplayTag(
                    this.props.parentModel._visaTransfer,
                    this.state._visa,
                    this.staticRowDisplayColor().getVisaColor()
                  )}
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                </TableRowColumn>
                <TableRowColumn
                  title={this.state._technicalSkills}
                  className="mid-col"
                  colSpan={2}
                >
                  {this.state._technicalSkills}
                </TableRowColumn>
                <TableRowColumn title="" className="mid-col">
                  N/A
                </TableRowColumn>
                <TableRowColumn
                  title="click link to see details"
                  className="mid-col"
                >
                  <Link
                    component="button"
                    onClick={() =>
                      this.setState({
                        mustHavesDialog: true,
                      })
                    }
                  >
                    Musts/Must Nots
                  </Link>
                </TableRowColumn>

                <Dialog
                  fullWidth={true}
                  maxWidth={'xl'}
                  open={this.state.mustHavesDialog}
                  onClose={() => this.setState({ mustHavesDialog: false })}
                  aria-labelledby="simple-dialog-title"
                >
                  <Grid container spacing={2} style={{ padding: '15px' }}>
                    <Grid item xs={6}>
                      <p>
                        <strong>Candidate Must(s)</strong>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.props.parentModel.candidateMust,
                        }}
                      ></p>
                    </Grid>
                    <Grid item xs={6}>
                      <p>
                        <strong>Candidate Must Not(s)</strong>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.props.parentModel.candidateMustNot,
                        }}
                      ></p>
                    </Grid>

                    <Grid item xs={12}>
                      <p>
                        <strong>Insider Scoop</strong>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.props.parentModel.qualificationNotes,
                        }}
                      ></p>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={1} style={{ padding: '15px' }}>
                    <Grid item xs={6}>
                      <p>
                        <strong>Positive Signals</strong>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.props.parentModel._positiveSignals,
                        }}
                      ></p>
                    </Grid>
                    <Grid item xs={6}>
                      <p>
                        <strong>Negative Signals</strong>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.props.parentModel._negativeSignals,
                        }}
                      ></p>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={1} style={{ padding: '15px' }}>
                    <Grid item xs={6}>
                      <p>
                        <strong>Accepted Profiles</strong>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.props.parentModel.examplesOfAccepted,
                        }}
                      ></p>
                    </Grid>
                    <Grid item xs={6}>
                      <p>
                        <strong>Rejected Profiles</strong>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.props.parentModel.examplesOfRejected,
                        }}
                      ></p>
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      onClick={() => this.setState({ mustHavesDialog: false })}
                      color="primary"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </TableRow>
            )}

            {/** ROW 01 */}
            <TableRow
              selectable={false}
              style={{ marginBottom: 10, opacity: engaged ? 0.4 : 1 }}
              onClick={this.handleToggle}
            >
              {/** COL 00: checkbox for match job */}
              {!!this.props.onCheck && (
                <TableRowColumn
                  title="Check to Select for Matching"
                  style={{
                    width: 36,
                    textAlign: 'right',
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                  rowSpan="2"
                >
                  <Checkbox
                    className="star"
                    checked={engaged || this.state.checked}
                    onClick={(e) => e.stopPropagation()}
                    onCheck={(event, checked) => {
                      this.setState({ checked });
                      this.props.onCheck(this.state.id, checked);
                    }}
                    /** disabled checkbox if candidate is engaged with job */
                    disabled={engaged || !!this.state.blacklisted}
                  />
                </TableRowColumn>
              )}
              {/** COL 01 */}
              <TableRowColumn
                title="Candidate"
                className="first-item v-align-mid"
                style={tdStyle}
                colSpan="2"
              >
                <IconButton
                  title="Copy Candidate email"
                  iconStyle={this.state.openedColor}
                  className="icon16"
                  onClick={(ev) => this.copyString(this.state.email)}
                >
                  <i className="material-icons">mail</i>
                </IconButton>
                &nbsp;
                {!!this.state.phone ? (
                  <IconButton
                    title="Copy Candidate phone"
                    iconStyle={this.state.openedColor}
                    className="icon16"
                    onClick={(ev) => this.copyString(this.state.phone)}
                  >
                    <i className="material-icons">phone</i>
                  </IconButton>
                ) : (
                  <div style={{ width: 16, height: 16 }} />
                )}
                &nbsp;
                {!!this.state.resumes.length ? (
                  ((resume) => {
                    return (
                      <IconButton
                        className="icon16"
                        onClick={(ev) =>
                          downloadFile({
                            url: resume.url,
                            mimeType: 'application/pdf',
                            onError: (error) => {
                              this.parent.setState({
                                snackbar: error,
                              });
                            },
                          })
                        }
                      >
                        <i className="material-icons">file_download</i>
                      </IconButton>
                    );
                  })(this.state.resumes[0])
                ) : (
                  <div style={{ width: 16, height: 16 }} />
                )}
                &nbsp;
                <NavLink
                  title="Go to Edit"
                  to={'/candidate/edit/' + this.state.id}
                  target={
                    this.props.source === 'candoDuplicateDetails'
                      ? '_blank'
                      : ''
                  }
                >
                  <b>{this.state._name_rating || <i>&mdash;</i>}</b>
                  {this.state._isDraft && (
                    <MyPara style={{ color: 'red !important' }}>
                      Draft - not submitted yet
                    </MyPara>
                  )}
                </NavLink>
              </TableRowColumn>
              {/** COL 02 */}
              <TableRowColumn
                title="Roles"
                onClick={this.handleToggle}
                className="mid-col"
                colSpan="2"
              >
                {this.state._roles || <i>&mdash;</i>}
              </TableRowColumn>
              {/** COL 03 */}
              <TableRowColumn
                title="Recruiter"
                className="link-item mid-col v-align-mid"
                colSpan="2"
              >
                {!!this.state.recruiter.email ? (
                  <Fragment>
                    <IconButton
                      title="Copy Recruiter email"
                      iconStyle={this.state.openedColor}
                      className="icon16"
                      onClick={(ev) =>
                        this.copyString(this.state.recruiter.email)
                      }
                    >
                      <i className="material-icons">mail</i>
                    </IconButton>
                    &nbsp;
                    <span title="Recruiter" onClick={this.handleToggle}>
                      {this.state.recruiter._name}
                    </span>
                  </Fragment>
                ) : (
                  <i>&mdash;</i>
                )}
                {!!this.state.recruiter.companyName && (
                  <span title="Agency">
                    &nbsp;-&nbsp;
                    {this.state.recruiter.companyName}
                  </span>
                )}
              </TableRowColumn>
              {/** COL 04 */}
              <TableRowColumn
                title="Visa"
                className="mid-col"
                onClick={this.handleToggle}
                colSpan="2"
              >
                {this.state._visa}
              </TableRowColumn>
              {/** COL 05 */}
              {Core.isAdminOrCoordinator() && (
                <TableRowColumn
                  title="Introduced Date"
                  className="mid-col align-center"
                  onClick={this.handleToggle}
                  colSpan="1"
                >
                  {this.state._introduced}
                </TableRowColumn>
              )}
              {/** LAST COL: options */}
              <TableRowColumn
                title="Options"
                className="last-col"
                rowSpan={Core.isAdminOrCoordinator() ? 3 : 1}
              >
                <Checkbox
                  title="Click to Starred"
                  className="star"
                  checked={this.state.starred}
                  onCheck={this.onCheckStar}
                  checkedIcon={<i className="material-icons">star</i>}
                  uncheckedIcon={<i className="material-icons">star_border</i>}
                  iconStyle={this.state.openedColor}
                />
                {Core.isRecruiter() &&
                  Core.isProduction() &&
                  !this.state.resumes.length ? (
                  false
                ) : (
                  <IconMenu
                    title="Menu"
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                    style={{ marginLeft: '4px' }}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      this.fetchEngagements();
                    }}
                    iconButtonElement={
                      <IconButton
                        style={{
                          width: 24,
                          padding: 0,
                        }}
                        iconStyle={this.state.openedColor}
                      >
                        <i className="material-icons">more_vert</i>
                      </IconButton>
                    }
                  >
                    {/** * /}
                    {(Core.isAdmin() ||
                      (Core.isRecruiter() && Core.isOnDev())) && (
                        <NavLink
                          to={"/candidate/match/" + this.state.id}
                          className="navlink"
                        >
                          <MenuItem primaryText="Match" />
                        </NavLink>
                      )}
                    {/** */}
                    {(Core.isAdmin() ||
                      (Core.isRecruiter() && Core.isOnDev())) && (
                        <>
                          <NavLink
                            to={'/candidate/matchNew/' + this.state.id}
                            className="navlink"
                          >
                            <MenuItem primaryText="Match" />
                          </NavLink>
                          {/** * /}
                          <NavLink
                            to={'/v3/candidate/matchNew/' + this.state.id}
                            className="navlink"
                          >
                            <MenuItem primaryText="MatchV3" />
                          </NavLink>
                          {/** */}
                        </>
                      )}

                    {(Core.isAdmin() ||
                      (Core.isRecruiter() && Core.isOnDev())) && (
                        <MenuItem
                          primaryText="View Dups"
                          onClick={() => Core.openExact(`/candidates?viewDup=t&cId=${this.state.id}`)}
                        />
                      )}

                    {!!this.state.linkedInURL && Core.isAdmin() && (
                      <MenuItem
                        title="LinkedIn"
                        primaryText="LinkedIn"
                        onClick={(event) => {
                          Core.openPopUp(this.state.linkedInURL);
                        }}
                      />
                    )}

                    {!!this.state.gitHubURL && Core.isAdmin() && (
                      <MenuItem
                        title="Github"
                        primaryText="Github"
                        onClick={(event) => {
                          Core.openPopUp(this.state.gitHubURL);
                        }}
                      />
                    )}

                    {this.state.resumeTxtUrl && (
                      <ResumeMatch
                        candidateResume={this.state.resumeTxtUrl}
                        jobSkills={
                          Object(this.props.parentModel)._technicalSkills
                        }
                        employerSkills={
                          Object(Object(this.props.parentModel).employer)
                            ._technicalSkills
                        }
                      />
                    )}
                    {!!this.state.resumes.length && (
                      <MenuItem
                        title="Curriculum Vitae"
                        primaryText="CV"
                        onClick={(event) => {
                          Core.openPopUp(Candidate.getMyPdfUrl(this.state));
                        }}
                      />
                    )}
                    {Core.isAdminOrCoordinator() &&
                      this.props.source === 'JobMatch' && (
                        <MenuItem
                          primaryText="Calculate Match Score"
                          onClick={this.handlerForceMlOn}
                        />
                      )}

                    {Core.isAdmin() && [
                      <MenuItem
                        key={Core.getKey()}
                        primaryText="Copy Confirmation Job Description"
                        onClick={(ev) => this.bulkCopy()}
                      />,

                      <MenuItem
                        key={Core.getKey()}
                        primaryText="Message"
                        onClick={this.openMessage}
                      />,
                      <MenuItem
                        key={Core.getKey()}
                        primaryText="Delete"
                        onClick={(ev) =>
                          this.fetchEngagements((engagements) =>
                            this.ConfirmDialog.open({
                              message: `Delete "${this.state._name}"${!!engagements.length
                                ? ` and ${engagements.length} engagement${engagements.length === 1 ? '' : 's'
                                }?`
                                : ''
                                }`,
                              onConfirm: (ev) =>
                                Candidate.delete(
                                  this.state.id,
                                  (response) => {
                                    const state = this.state;

                                    if (Array.isArray(state.resumes)) {
                                      state.resumes.forEach((resume) => {
                                        SovrenData.destroyByTimestamp(
                                          resume.timestamp
                                        );
                                      });
                                    }

                                    this.parent.parent
                                      .deleteCandidateFromCollection &&
                                      this.parent.parent.deleteCandidateFromCollection(
                                        this.state.id
                                      );
                                  },
                                  (error) => {
                                    if (!!error) {
                                      !!this.parent.parent &&
                                        this.parent.parent.setState({
                                          snackBarMessage:
                                            'Can not delete candidate. Please contact support.',
                                          snackBarOpen: true,
                                        });
                                    }
                                  }
                                ),
                            })
                          )
                        }
                      />,
                    ]}
                  </IconMenu>
                )}
                <i
                  title="Toggle Expand/Collapse"
                  className="material-icons"
                  style={{
                    width: 24,
                    height: 24,
                    margin: 0,
                    cursor: 'pointer',
                    ...this.state.openedColor,
                    ...this.state.rightArrow,
                  }}
                  onClick={this.handleToggle}
                >
                  arrow_drop_down
                </i>
                {this.state.blacklisted}
                <ConfirmDialog
                  ref={(self) => (this.ConfirmDialog = self)}
                  title="Delete Candidate?"
                  message="This action can't be undone."
                  actionLabel="Delete"
                />
              </TableRowColumn>
            </TableRow>
            {/** ROW 02 */}
            {Core.isAdminOrCoordinator() && (
              <TableRow
                selectable={false}
                style={{ marginBottom: 10, opacity: engaged ? 0.4 : 1 }}
                onClick={this.handleToggle}
              >
                {/** COL 01 */}
                <TableRowColumn
                  title="Technical Skills"
                  className="first-col"
                  colSpan="2"
                  onClick={this.handleToggle}
                >
                  {this.state._technicalSkills}
                </TableRowColumn>
                <TableRowColumn
                  title="Positive Signals"
                  className="mid-col"
                  colSpan="2"
                  onClick={this.handleToggle}
                >
                  {this.state._positiveSignals}
                </TableRowColumn>
                <TableRowColumn
                  title="Negative Signals"
                  className="mid-col"
                  colSpan="2"
                  onClick={this.handleToggle}
                >
                  {this.state._negativeSignals}
                </TableRowColumn>
                <TableRowColumn
                  title="Locations"
                  className="mid-col"
                  colSpan="2"
                  onClick={this.handleToggle}
                >
                  {_candidateLocationsLabels && (
                    <div className="d-block">
                      Remotely from: {_candidateLocationsLabels}
                    </div>
                  )}
                  {_officeLocationsLabels && (
                    <div className="d-block">
                      In Office: {_officeLocationsLabels}
                    </div>
                  )}
                </TableRowColumn>
                <TableRowColumn
                  className="mid-col align-center"
                  colSpan="1"
                  onClick={this.handleToggle}
                >
                  <span title="Minimum Years of Experience">
                    {this.state._years}
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <span title="Minimum Salary">
                    {this.state._minimumSalary}
                  </span>
                </TableRowColumn>
              </TableRow>
            )}
            {/** ROW 03 */}
            {Core.isAdminOrCoordinator() && (
              <TableRow
                selectable={false}
                style={{ marginBottom: 10, opacity: engaged ? 0.4 : 1 }}
              >
                {/** COL 01 */}
                <TableRowColumn
                  title="Technical Skills"
                  className="first-col"
                  colSpan="8"
                  onClick={this.handleToggle}
                >
                  {this.props.params && this.props.params.count ? (
                    <EngagementsInfo candidate={this.state} />
                  ) : (
                    ''
                  )}
                  {
                    <PrintMlScore
                      candidate={this.state}
                      job={this.props.parentModel}
                      visible={
                        (this.props.source === 'JobMatch' &&
                          this.parent.parent._mlOn) ||
                        this._forceMlOn
                      }
                    />
                  }
                </TableRowColumn>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Divider />
        {/** EXPANDED VIEW */}
        <CardText className="expanded-view" expandable={true}>
          {Core.isAdmin() && this.props.source === 'candoDuplicateDetails' && (
            <ManageOwnershipPermittedJobs candidate={this.state} />
          )}
          {!!this.state.engagements.length ? (
            <Fragment>
              {/** * /
              <CardHeader title="Engagements" />
              /** */}
              <div className="material-table border-bottom-gray">
                <Table>
                  <TableBody displayRowCheckbox={false}>
                    <TableRow selectable={false}>
                      {!Core.isLimited() && (
                        <TableRowColumn style={{ width: '40px' }}>
                          Employer
                        </TableRowColumn>
                      )}
                      {Core.isAdmin() && (
                        <TableRowColumn style={{ width: '350px' }}>
                          Job Title
                        </TableRowColumn>
                      )}
                      <TableRowColumn style={{ width: '100px' }}>
                        Last Action
                      </TableRowColumn>
                      <TableRowColumn style={{ width: '120px' }}>
                        Role
                      </TableRowColumn>
                      <TableRowColumn
                        style={{ width: '80px' }}
                        className="inline-blocks"
                      >
                        Stage&nbsp;
                        <i
                          title="Confirmation - need candidate's approval for submission&#013;Submission - 10x10 processing&#013;Review - employer reviewing resume&#013;Screen - phone screen or homework&#013;Onsite - in person interview&#013;Offer - offer discussion in progress&#013;Hire - offer accepted&#013;Guarantee - employment started"
                          className="material-icons"
                        >
                          info
                        </i>
                      </TableRowColumn>
                      <TableRowColumn
                        style={{ width: '130px' }}
                        className="inline-blocks"
                      >
                        Status&nbsp;
                        <i
                          title="W - waiting on the entity listed to complete the next action to move the interview forward&#013;E - the interview process was ended by the entity listed"
                          className="material-icons"
                        >
                          info
                        </i>
                      </TableRowColumn>
                      <TableRowColumn style={{ width: '100px' }}>
                        Confirmed
                      </TableRowColumn>
                      <TableRowColumn style={{ width: '100px' }}>
                        Submitted
                      </TableRowColumn>
                      <TableRowColumn style={{ width: '100px' }}>
                        CV Review
                      </TableRowColumn>
                      <TableRowColumn style={{ width: '145px' }}>
                        Screen
                      </TableRowColumn>
                      <TableRowColumn style={{ width: '145px' }}>
                        Onsite
                      </TableRowColumn>
                      <TableRowColumn>State</TableRowColumn>
                    </TableRow>
                    {this.state.engagements
                      .filter(
                        (obj) =>
                          ![
                            '10x10 - No Match',
                            '10x10 - Bad Match',
                            '10x10 - Review',
                          ].includes(obj.rejectionReason)
                      )
                      .map((eng) => (
                        <TableRow
                          key={Core.getKey()}
                          selectable={false}
                          style={{
                            opacity: eng.state === 'Open' ? 1 : 0.4,
                          }}
                          onClick={(ev) => Core.log({ eng })}
                        >
                          {!Core.isLimited() && (
                            <TableRowColumn>
                              {eng.employer._name}
                            </TableRowColumn>
                          )}
                          {Core.isAdmin() && (
                            <TableRowColumn
                              className="ui-col"
                              title={eng.job._jobTitle}
                            >
                              {eng.job._jobTitle}
                              {!(jobsPermitted || []).includes(eng.jobId) && (
                                <>
                                  &nbsp;|&nbsp;
                                  <span
                                    className="anchor"
                                    onClick={() =>
                                      this.addEngagementToPermittedJobs(eng)
                                    }
                                  >
                                    Add?
                                  </span>
                                </>
                              )}
                            </TableRowColumn>
                          )}
                          <TableRowColumn title={eng.lastAction}>
                            {eng._lastAction}
                          </TableRowColumn>
                          <TableRowColumn>
                            {Core.isAdminOrCoordinator() ? (
                              <span
                                className="anchor"
                                onClick={(ev) => Core.open(`/job/edit/${eng.jobId}`)}
                              >
                                {eng._role}
                              </span>
                            ) : (
                              <span>{eng._role}</span>
                            )}
                          </TableRowColumn>
                          <TableRowColumn>{eng.stage}</TableRowColumn>
                          <TableRowColumn title={hoverDisplay(eng)}>
                            {eng.status}
                          </TableRowColumn>
                          <TableRowColumn title={eng.confirmed}>
                            {eng._confirmed}
                          </TableRowColumn>
                          <TableRowColumn title={eng.submitted}>
                            {eng._submitted}
                          </TableRowColumn>
                          <TableRowColumn title={eng.reviewed}>
                            {eng._reviewed}
                          </TableRowColumn>
                          <TableRowColumn>
                            <div
                              className="cando-screen-display"
                              title={`Screen 1: ${eng.screen1}`}
                            >
                              {eng._screen1}
                            </div>
                            <div
                              className="cando-screen-display"
                              title={`Screen 2: ${eng.screen2}`}
                            >
                              {eng._screen2}
                            </div>
                            <div
                              className="cando-screen-display"
                              title={`Screen 3: ${eng.screen3}`}
                            >
                              {eng._screen3}
                            </div>
                          </TableRowColumn>
                          <TableRowColumn>
                            <div
                              className="cando-onsite-display"
                              title={`Onsite 1: ${eng.onsite1}`}
                            >
                              {eng._onsite1}
                            </div>
                            <div
                              className="cando-onsite-display"
                              title={`Onsite 2: ${eng.onsite2}`}
                            >
                              {eng._onsite2}
                            </div>
                          </TableRowColumn>
                          <TableRowColumn>{eng.state}</TableRowColumn>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </Fragment>
          ) : (
            this.state.isEngagementsRetrieved && (
              <Fragment>
                <div className="material-table border-bottom-gray">
                  <Table>
                    <TableBody displayRowCheckbox={false}>
                      <TableRow selectable={false}>
                        <TableRowColumn>No engagments</TableRowColumn>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </Fragment>
            )
          )}
        </CardText>
      </Card>
    );
  }
}

class EngagementsInfo extends Component {
  render() {
    const { candidate } = this.props;
    const engagements = candidate.engagements;

    /** * /
    const totalEngagements = engagements.length;
    const activeEngagements = engagements.filter(
      eng =>
        eng.state === "Open" && Engagement.stageOrder.indexOf(eng.stage) >= 3
    ).length;
    const hireEngagements = engagements.filter(eng => /hire/i.test(eng.stage))
      .length;
    /** */

    const reviewEngagements = engagements.filter(
      (eng) => /open/i.test(eng.state) && /review/i.test(eng.stage)
    ).length;
    const screenEngagements = engagements.filter(
      (eng) => /open/i.test(eng.state) && /screen/i.test(eng.stage)
    ).length;
    const onsiteEngagements = engagements.filter(
      (eng) => /open/i.test(eng.state) && /onsite/i.test(eng.stage)
    ).length;

    const confEngagements = engagements.filter(
      (eng) => /open/i.test(eng.state) && /confirmation/i.test(eng.stage)
    ).length;
    const inactiveEngagements = engagements.filter((eng) =>
      /closed/i.test(eng.state)
    ).length;

    const submissions = engagements
      .map((eng) =>
        eng.submitted ? moment(eng.submitted).toDate().getTime() : 0
      )
      .filter((time) => !!time);
    const lastSubmissionTime = Math.max.apply(null, submissions);
    const lastSubmissionDate = moment(lastSubmissionTime).toISOString();
    const lastSubmission = submissions.length
      ? moment(lastSubmissionTime).format('MM/DD/YY')
      : 0;
    /** * /
    const offerEngagements = engagements.filter(
      eng => /open/i.test(eng.state) && /offer/i.test(eng.stage)
    ).length;
    const totalEngagements = engagements.length;
    const activeEngagements = engagements.filter(
      eng =>
        eng.state === "Open" && Engagement.stageOrder.indexOf(eng.stage) >= 3
    ).length;
    /** */
    /** */
    const latestStage =
      Engagement.stageOrder[
      Math.max.apply(
        null,
        engagements.map((eng) => Engagement.stageOrder.indexOf(eng.stage))
      )
      ];
    /** * /
    const stageCount = engagements.filter(eng => eng.stage === latestStage)
      .length;
    /** */
    const componentEngagements = (
      <div className="cursor-default">
        {/** * /}
        <span title="Total Engagements">{totalEngagements} all</span>
        <span title="Engagements Open and stage higher than Review stage">
          {activeEngagements} active -&nbsp;
        </span>
        <span title="Open, Offer">{offerEngagements} offer</span>
        <span title="Total Hire">{hireEngagements} hire |&nbsp;</span>
        {/** */}
        <span title="Open, Confirmation">{confEngagements} conf |&nbsp;</span>
        <span title="Open, Review">{reviewEngagements} review |&nbsp;</span>
        <span title="Open, Screen">{screenEngagements} screen |&nbsp;</span>
        <span title="Open, Onsite">{onsiteEngagements} onsite</span>
        <br />
        <span title="Most advanced stage">
          <b>{latestStage || mdash}</b> |&nbsp;
        </span>
        <span title="Closed">{inactiveEngagements} inactive |&nbsp;</span>
        <span title={`Last submission: ${lastSubmissionDate}`}>
          <b>{lastSubmission || mdash}</b>
        </span>
      </div>
    );
    const alternativeCmp = <div />;
    /** * /
    const oldCMP = (
      <div className="cursor-default">
        <span title="Engagements Open and stage higher than Review stage">
          {activeEngagements} active -&nbsp;
        </span>
        <span title="Engagements Open and Confirmation stage">
          {confEngagements} conf -&nbsp;
        </span>
        <span title="Total Engagements">{totalEngagements} all</span>
        <br />
        <span title="Engagements Open and Hire stage">
          {hireEngagements} hire
        </span>
        <span title={`Last submission: ${lastSubmissionDate}`}>
          {!!lastSubmission ? `, ${lastSubmission} last submit` : ""}
        </span>
      </div>
    );
    /** */
    return Core.isAdminOrCoordinator() ? componentEngagements : alternativeCmp;
  }
}

export default CandidateCard;
