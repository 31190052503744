/**
 * @todo
 * 
 * OUTDATED
 * 
 * Review to cleanup
 * 
 * 2021-09-10 Fri µ
 * 
 */

import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import AppBar from "material-ui/AppBar";
import FlatButton from "material-ui/FlatButton";
import Snackbar from "material-ui/Snackbar";
import Popover from "material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";

import Core, { colors } from "../../../lib/Core";
import Streak from "../../../lib/Streak";
import Candidate from "../../../lib/Candidate";
import Engagement from "../../../lib/Engagement";
import Job from "../../../lib/Job";
import JobProfile from "./JobProfile";
import DialogSuccess from "../../Dialogs/Success";
import styles from "../../Forms/Styles";
import { extended as extendedModel } from "../../../lib/models/job";

import "../../Dialogs/Modal.css";

import FilterControl from "../../FilterControl/FilterControl";
import List from "../../List/List";
import CandidateCard from "../../Candidates/Card/CandidateCard";

import loader from "../../../assets/images/loader.gif";
import Store from "../../../lib/Store";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import HistoryLog from "../../../lib/HistoryLog";
import HistoryMenu from "../../HistoryMenu/HistoryMenu";
import { IconButton } from "@mui/material";
import EmailPreview from "../../Dialogs/EmailPreview";
import Google from "../../../lib/Google";
import _ from 'lodash';
import queryString from "query-string";

let attempt = 0;

class JobMatch extends Component {
  data;
  constructor() {
    super(...arguments);
    this.name = "JobMatch";
    this.state = {
      ...extendedModel,
      snackBarMessage: "",
      snackBarOpen: false,
      matching: false,
      openPopover: false,
      anchorEl: null,
      selected: {}
    };
    Store.set("path", window.location.href);
    this.reloadData = this.loadData;
    this._mlOn = false;
    this.loadData();
  }
  loadData() {
    setTimeout(st => {
      if (this.FilterControl) {
        Job.get(this.props.match.params.id, job => {
          this.setState({ ...job }, then => {

            const candoCallback = candidates => {
              this.setState({ candidates }, then => {
                let preset = `^${job._role}$`;
                let filterState = {};
                if (!!job.salaryMin) {
                  filterState.minimumSalary = parseInt(job.salaryMax) * 1.4;
                }
                if (!!job.minYearsOfExperience) {
                  filterState.minimumXp = parseInt(job.minYearsOfExperience);
                }
                this.FilterControl.setItems(
                  candidates,
                  preset,
                  this.jiraVER20(),
                  this.jiraVER20(),
                  filterState
                );
                // if (Core.isAdmin()) {
                //   try {
                //     this.FilterControl.setState({
                //       ...JSON.parse(this.state.searchConfig)
                //     });
                //   } catch (ex) {
                //     console.warn(ex);
                //   }
                // }
                HistoryLog.set({
                  group: "jobs",
                  label: `Match: ${this.state._name}`
                });
                Core.log({ JobMatchState: this.state, preset: job._role });
              });
            };

            let params = queryString.parse(this.props.location.search);
            if (params.loadAll) {
              Candidate.getAll(candoCallback);

            } else {
              Candidate.getActiveCandidates(candoCallback);

            }
          });
        });
      } else {
        if (attempt < 10) {
          attempt++;
          Core.log({ missingFilterControl: true });
          this.loadData();
        }
      }
    });
  }
  jiraVER20 = em => {
    /** [ Jira Ticket VER-20 ] ===>>> * /
    /**
      For Visa,
      Citzen > Green Card > Visa Transfer > Visa Sponsor
      If a candidate is a Citzen, it should fit all jobs.
      If a candidate requires Visa transfers,
        it should fit both Visa transfer and Visa sponsor
          (but not green card and citzen) jobs.
      Always include unspecified, unknow in the search results.

      Candidate Visa Options:

      Citizen
      Green Card
      Needs H1B Visa Transfer
      Needs H1B1/E-3 (Chile, Singapore, Australia)
      Needs TN (Canada, Mexico)
      Needs New Visa Sponsor
      Visa Status Unknown

      Job Visa Options:

      Citizen
      Green Card
      Transfer Visa
      Sponsor Visa
      No Support Visa
      Visa Support Unknown
     */
    const job = this.state;
    // let visa = "Visa Status Unknown";
    // if (job._visaTransfer === "Citizen") {
    //   visa = "Citizen";
    // } else if (/Green Card/i.test(job._visaTransfer)) {
    //   visa = "Green Card";
    // } else if (/Transfer/i.test(job._visaTransfer)) {
    //   visa = "Needs H1B Visa Transfer";
    // } else if (/Sponsor/i.test(job._visaTransfer)) {
    //   visa = "Needs New Visa Sponsor";
    // }
    // see also FilterControl.jiraVER20 method.
    // see also FilterControl/SelectTagDialog call to parent.jiraVER20 method.
    // see also CandidateMatch.jiraVER20 method.
    /** <<<=== [ Jira Ticket VER-20 ] */
    return [
      { key: 'visa', label: job._visaTransfer, checked: true },
      { key: 'locationCandidate', label: job._locations, checked: true },
      { key: 'introduced', label: 'Active', checked: true }
    ];

    // return job._visaTransfer;
  };
  showMessage = msg => {
    this.setState({
      snackBarMessage: msg,
      snackBarOpen: true
    });
  };
  hideMessage = () => {
    this.setState({
      snackBarMessage: "",
      snackBarOpen: false
    });
  };
  onClickMatch = event => {
    // This prevents ghost click.
    event.preventDefault();
    this.setState({
      openPopover: true,
      anchorEl: event.currentTarget
    });
  };
  matchJob = (stage, status) => {
    this.setState({ openPopover: false, matching: true });
    const candidateIds = Object.keys(this.state.selected).filter(
      key => this.state.selected[key]
    );
    let count = 0;
    this.lastCandidateId = null;
    const next = em => {
      if (!!candidateIds.length) {
        const candidateId = candidateIds.pop();
        const candidate = this.state.candidates.find(
          candidate => candidate.id === candidateId
        );
        Engagement.match(
          { candidate, job: this.state, stage, status },
          response => {
            this.lastCandidateId = candidateId;
            next(count++);
          },
          error => {
            next();
            Core.showFailure(error);
          }
        );
      } else {
        this.setState({ matching: false });
        this.refs.DialogSuccess.open(
          "Job has been succesfuly matched to " + count + " candidates"
        );
      }
    };
    next();
  };
  saveSearch() {
    if (Core.isAdmin()) {
      setTimeout(st => {
        if (this.FilterControl) {
          let searchConfig = "{}";
          if (!!this.FilterControl.state.chips.length) {
            const state = {
              ...this.FilterControl.state
            };
            delete state.items;
            delete state.sources;
            delete state.anchorSavedSearches;
            searchConfig = JSON.stringify(state);
          }
          Job.update(this.state.id, { searchConfig });
        }
      });
    }
  }
  goBack = ev => {
    if (Object.keys(this.state.selected).length) {
      this.ConfirmDialog.open({
        message: "There are pendant Matches to complete",
        onConfirm: ev => {
          Core.goBack(this.props); // "/#/jobs";
        }
      });
    } else {
      Core.goBack(this.props); // "/#/jobs";
    }
  };
  openMessage = ev => {
    const emails = [];
    const job = this.state;
    job.employer.primaryContactEmail &&
      emails.push({
        name: job.employer._name || "Employer",
        email: job.employer.primaryContactEmail
      });
    job.resumeSubmissionEmail1 &&
      emails.push({
        name: job.resumeSubmissionName1 || "Submission Contact 1",
        email: job.resumeSubmissionEmail1
      });
    job.resumeSubmissionEmail2 &&
      emails.push({
        name: job.resumeSubmissionName2 || "Submission Contact 2",
        email: job.resumeSubmissionEmail2
      });
    job.resumeSubmissionEmail3 &&
      emails.push({
        name: job.resumeSubmissionName3 || "Submission Contact 3",
        email: job.resumeSubmissionEmail3
      });
    job.resumeSubmissionEmail4 &&
      emails.push({
        name: job.resumeSubmissionName4 || "Submission Contact 4",
        email: job.resumeSubmissionEmail4
      });
    Core.dialog.open({
      title: <>Message</>,
      message: (
        <EmailPreview
          ref={self => (this.EmailMessage = self)}
          emails={emails}
          to={!!emails[0] && [emails[0]]}
          subject=""
          body={[].filter(line => !!line).join("<br/>")}
        />
      ),
      className: "p-0",
      actions: [
        <FlatButton
          label="Cancel"
          className="button-flat-darker"
          onClick={ev => {
            Core.dialog.close();
          }}
        />,
        <FlatButton
          label="Send"
          className="button-white-cyan"
          onClick={ev => {
            Core.dialog.close();
            Google.sendEmail(
              { ...this.EmailMessage.getParams(), source: 'jobMatch.js line 304' },
              response => Core.showMessage("Email sent"),
              error => Core.showFailure(error)
            );
          }}
        />
      ]
    });
  };

  calculateScore = () => {
    this._mlOn = true;
    this.List.loadData();

    //quick hack to reset
    // bad existing code.
    setTimeout(() => {
      this._mlOn = false;
    }, 2000);
  }

  render() {
    //console.log("render :::::: ", _.cloneDeep(Candidate.menus));
    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }
    return (
      <MuiThemeProvider>
        <div className="match">
          <div className="appBar" style={styles.AppBar}>
            <AppBar
              title="Job Match"
              titleStyle={styles.AppBar.title}
              style={styles.AppBar.nav}
              iconElementLeft={
                <FlatButton
                  icon={<i className="material-icons">arrow_back</i>}
                  title="Go to Jobs"
                  style={{
                    color: colors.darkGray,
                    marginTop: "5px"
                  }}
                  onClick={this.goBack}
                />
              }
              iconElementRight={
                <Fragment>
                  <IconButton
                    className="float-right"
                    onClick={this.openMessage}
                  >
                    <i className="material-icons">email</i>
                  </IconButton>
                  <HistoryMenu />
                </Fragment>
              }
            />
            <ConfirmDialog
              ref={self => (this.ConfirmDialog = self)}
              title="Go to Jobs"
              message="Are you sure that you want leave?"
              actionLabel="Go"
            />
          </div>
          <div className="match-mid flex">
            <div className="match-left-side">
              <JobProfile ref="JobProfile" parent={this} />
            </div>
            <div className="match-right-side">
              <FilterControl
                ref={FilterControl => (this.FilterControl = FilterControl)}
                parent={this}
                menus={_.cloneDeep(Candidate.menus)}
                more={_.cloneDeep(Candidate.more)}
                paddingTop={false}
                source={"jobMatch"}
                onChange={filtered => {
                  this.List && this.List.setItems(filtered);
                  //this.saveSearch();
                }}
                toolBarRight={
                  <Fragment>
                    {!!Object.keys(this.state.selected).length && (
                      <Fragment>
                        <FlatButton
                          label={
                            this.state.matching ? (
                              <img alt="loading..." height="36" src={loader} />
                            ) : (
                              "MATCH"
                            )
                          }
                          className="list-match"
                          onClick={this.onClickMatch}
                          disabled={this.state.matching}
                        />
                        <Popover
                          open={this.state.openPopover}
                          anchorEl={this.state.anchorEl}
                          anchorOrigin={{
                            horizontal: "left",
                            vertical: "bottom"
                          }}
                          targetOrigin={{ horizontal: "left", vertical: "top" }}
                          onRequestClose={ev =>
                            this.setState({
                              openPopover: false
                            })
                          }
                        >
                          <Menu>
                            {Streak.getPipeline().matchOptions.map(option => (
                              <MenuItem
                                key={option}
                                primaryText={option}
                                onClick={ev =>
                                  this.matchJob(
                                    option.split(",")[0].trim(),
                                    option.split(",")[1].trim()
                                  )
                                }
                              />
                            ))}
                          </Menu>
                        </Popover>
                      </Fragment>
                    )}
                  </Fragment>
                }
              />
              <button onClick={this.calculateScore}>Calculate Score</button>
              <List
                ref={List => (this.List = List)}
                tabs={Candidate.listTabs}
                tab={Candidate.listTab}
                name="Candidate"
                source={"JobMatch"}
                card={CandidateCard}
                parent={this}
                parentModel={this.state}
                onCheck={(id, checked) => {
                  const selected = { ...this.state.selected };
                  if (!checked) {
                    delete selected[id];
                  } else {
                    selected[id] = checked;
                  }
                  this.setState({ selected });
                }}
              />
              <Snackbar
                open={this.state.snackBarOpen}
                message={this.state.snackBarMessage}
                className="snack-bar"
                autoHideDuration={6000}
                onRequestClose={this.hideMessage}
              />
              <DialogSuccess
                ref="DialogSuccess"
                modal={true}
                width="520px"
                actions={[
                  <FlatButton
                    label="STAY"
                    title="Stay on Job Match"
                    className="button-flat-darker"
                    onClick={ev => window.location.reload()}
                  />,
                  <FlatButton
                    label="JOBS"
                    title="Go to Jobs"
                    className="button-flat-darker"
                    onClick={ev => Core.go({ ...this.props, to: '/jobs/' })}
                  />,
                  <FlatButton
                    label="ENGAGEMENTS"
                    title="Go to Engagements"
                    className="button-flat-cyan"
                    onClick={ev => Core.go({ ...this.props, to: '/engagements/' })}
                  />,
                  <FlatButton
                    label="SUBMISSION"
                    title="Go to Resume Submission"
                    className="button-white-cyan"
                    onClick={ev => {
                      const candidateId = this.lastCandidateId;
                      const jobId = this.state.id;
                      if (candidateId) {
                        Core.go({ ...this.props, to: `/candidate/resume-submission/${candidateId}/${jobId}` });
                      } else {
                        Core.showMessage("Missing candidate id");
                      }
                    }}
                  />
                ]}
              />
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default JobMatch;
