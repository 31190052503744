import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import {
  AppBar,
  FlatButton,
  Paper,
  RaisedButton,
  Divider,
  TextField,
  Snackbar
} from "material-ui";

import Core from "../../lib/Core";
import styles from "../Forms/Styles";
import linkedinLogo from "../../assets/images/linkedin.svg";
import Row from "../Forms/Row";
import Col from "../Forms/Col";
import Account from "../../lib/Account";
import Store from "../../lib/Store";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import Google from "../../lib/Google";

class Settings extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      linked: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      snackBarOpen: false,
      snackBarMessage: "..."
    };
    Store.set("path", window.location.href);
    if (Core.isAdminOrCoordinator()) {
      Google.getCredential(
        response => {
          if (!!response.length) {
            this.setState(
              {
                googleCredential: response[0].payload
              },
              then => Core.log({ SettingsState: this.state })
            );
          }
        },
        error => Core.showFailure(error)
      );
    }
  }
  showMessage = msg => {
    this.setState({
      snackBarMessage: msg,
      snackBarOpen: true
    });
  };
  hideMessage = () => {
    this.setState({
      snackBarMessage: "",
      snackBarOpen: false
    });
  };
  render() {
    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }
    return (
      <MuiThemeProvider>
        <div className="management bgcolor2 min-100vh">
          <AppBar
            title="Settings"
            titleStyle={styles.AppBar.title}
            style={styles.AppBar.nav}
            className="fixed"
            iconElementLeft={
              <FlatButton
                icon={<i className="material-icons">arrow_back</i>}
                style={styles.AppBar.backButton}
                onClick={ev => Core.goBack(this.props)}
              />
            }
          />
          <Snackbar
            open={this.state.snackBarOpen}
            message={this.state.snackBarMessage}
            className="snack-bar"
            autoHideDuration={4000}
            onRequestClose={this.hideMessage}
          />
          <div className="main-padding padding-top-96 cdarker">
            <h3>Reset Your Password</h3>
            <Paper className="main-padding">
              <form
                onSubmit={ev => {
                  ev.preventDefault();
                  if (!this.state.oldPassword.trim()) {
                    this.setState({ errorOldPassword: "Empty Field" }, then =>
                      Core.showMessage("Fill the old password")
                    );
                  } else if (!this.state.newPassword.trim()) {
                    this.setState({ errorNewPassword: "Empty Field" }, then =>
                      Core.showMessage("Fill the new password")
                    );
                  } else if (
                    this.state.newPassword !== this.state.confirmPassword
                  ) {
                    this.setState(
                      {
                        errorConfirmPassword:
                          "New password and confirm password does not match"
                      },
                      then =>
                        Core.showMessage(
                          "New password and confirm password does not match"
                        )
                    );
                  } else {
                    Account.changePassword(
                      {
                        oldPassword: this.state.oldPassword,
                        newPassword: this.state.newPassword
                      },
                      response => {
                        this.setState(
                          {
                            oldPassword: "",
                            newPassword: "",
                            confirmPassword: ""
                          },
                          then =>
                            Core.showMessage(
                              "Your password was successfully changed"
                            )
                        );
                      },
                      error => {
                        this.setState({
                          errorOldPassword: error
                        });
                        Core.showFailure(error);
                      }
                    );
                  }
                }}
              >
                <Row>
                  <Col className="inline-blocks margin-rights" fullWidth>
                    <label style={{ display: "inline-block", width: 150 }}>
                      Password
                    </label>
                    <TextField
                      name="password"
                      type="password"
                      autoComplete="old-password"
                      value={this.state.oldPassword}
                      onChange={(event, oldPassword) => {
                        this.setState({ oldPassword, errorOldPassword: "" });
                      }}
                      errorText={this.state.errorOldPassword}
                      errorStyle={{ color: "red" }}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="inline-blocks margin-rights" fullWidth>
                    <label style={{ display: "inline-block", width: 150 }}>
                      New Password
                    </label>
                    <TextField
                      name="newPassword"
                      type="password"
                      autoComplete="new-password"
                      value={this.state.newPassword}
                      onChange={(event, newPassword) => {
                        this.setState({ newPassword, errorNewPassword: "" });
                      }}
                      errorText={this.state.errorNewPassword}
                      errorStyle={{ color: "red" }}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="inline-blocks margin-rights" fullWidth>
                    <label style={{ display: "inline-block", width: 150 }}>
                      Confirm Password
                    </label>
                    <TextField
                      name="confirmPassword"
                      type="password"
                      autoComplete="confirm-password"
                      value={this.state.confirmPassword}
                      onChange={(event, confirmPassword) => {
                        this.setState({
                          confirmPassword,
                          errorConfirmPassword: ""
                        });
                      }}
                      errorText={this.state.errorConfirmPassword}
                      errorStyle={{ color: "red" }}
                      required
                    />
                    <RaisedButton
                      label="Submit"
                      className="clighter bgcolor3"
                      labelStyle={{ textTransform: "none" }}
                      style={{ width: 150, float: "right" }}
                      type="submit"
                    />
                  </Col>
                </Row>
              </form>
            </Paper>
            {Core.isAdmin() && (
              <Fragment>
                <h3>Connect Your Online Account</h3>
                <Paper className="main-padding">
                  {false && (
                    <Fragment>
                      <Row>
                        <Col className="main-padding inline-blocks" fullWidth>
                          Connect your Google account and you can sign in with
                          your Google login (we redirect you to Google to login
                          and don't have access to your password).
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <Col className="inline-blocks" fullWidth>
                          <div className="main-padding center cdarker">
                            <img
                              alt="LinkedIn"
                              src={linkedinLogo}
                              style={{ width: 32 }}
                            />
                            <div>LinkedIn</div>
                          </div>

                          <RaisedButton
                            label={
                              this.state.linked
                                ? "Connected"
                                : "Connect with LinkedIn"
                            }
                            icon={
                              this.state.linked && (
                                <i className="material-icons">check</i>
                              )
                            }
                            labelStyle={{ textTransform: "none" }}
                            className="clighter"
                            onClick={ev => {
                              const client_id = "78euf17p3ylwjx";
                              const redirect_uri =
                                "http://localhost:3000/api/Accounts/linkCredential";
                              window.open(
                                `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=987654321&scope=r_emailaddress`
                              );
                            }}
                            primary={this.state.linked}
                            secondary={!this.state.linked}
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                  <Row>
                    <Col className="inline-blocks" fullWidth>
                      <RaisedButton
                        label={
                          !!this.state.googleCredential
                            ? "Connected with Google"
                            : "Connect with Google"
                        }
                        icon={
                          !!this.state.googleCredential && (
                            <i className="material-icons">check</i>
                          )
                        }
                        labelStyle={{ textTransform: "none" }}
                        className="clighter"
                        onClick={ev => {
                          if (!this.state.googleCredential) {
                            Google.connect();
                          } else {
                            this.ConfirmDialog.open({
                              onConfirm: ev =>
                                Google.disconnect(
                                  response =>
                                    Core.showMessage("Disconnected", onClose =>
                                      document.location.reload()
                                    ),
                                  error => Core.showFailure(error)
                                )
                            });
                          }
                        }}
                        primary={!!this.state.googleCredential}
                        secondary={!this.state.googleCredential}
                      />
                      <ConfirmDialog
                        ref={self => (this.ConfirmDialog = self)}
                        title="Remove Access?"
                        message="This action will remove Google credentials"
                        actionLabel="Revoke Token"
                      />
                    </Col>
                  </Row>
                </Paper>
              </Fragment>
            )}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default Settings;
