/** ============================================ µ
 * @description EmployerDetails [JSX]
 *              UI Component
 * @createdAt   2021-09-29 Wed
 * @updatedAt   2021-11-05 Fri
 * ============================================ */
/* IMPORTS ==================================== */

import { Divider, TextField } from "material-ui";
import React from "react";
import Core, { colors } from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import Employer from "../../../lib/Employer";
import CardContent from "../../Details/CardContent";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function EmployerDetails(props) {
  const { employer } = props;
  const [publicNotes, setPublicNotes] = React.useState(employer.publicNotes);
  function onChangeEmployerNotes(ev, publicNotes) {
    setPublicNotes(publicNotes);
    clearTimeout(EmployerDetails.timeout);
    EmployerDetails.timeout = setTimeout(() => {
      Employer.update(employer.id, { publicNotes }).then(() => {
        employer.publicNotes = publicNotes;
      });
    }, 1000);
  };
  return (
    <>
      <div className="sticky-top bg-white">
        <Row className="pb-1">
          <Col fullWidth>
            <label>{employer.name}</label>
          </Col>
        </Row>
        <Divider />
      </div>
      {!!employer.jobs.length && (
        <CardContent title="HIRING">
          <ul>
            {Core.isAdminOrCoordinator()
              ? employer.jobs.map(job => (
                <span
                  key={`employer-${employer.id}-details-hiring-${job.id}`}
                  className="anchor"
                  onClick={ev => Core.go({
                    ...this.props,
                    to: `/job/view/${job.id}`
                  })}
                >
                  <li style={{ marginBottom: 16 }}>
                    {Definition.getLabel("roles", job.role)}
                    &nbsp;&mdash;&nbsp;
                    {Definition.getLabel("state", job.state)}
                  </li>
                </span>
              ))
              : employer.jobs.map(job => (
                <li key={
                  `employer-${employer.id}-details-hiring-${job.id}-role`
                }>
                  {Definition.getLabel("roles", job.role)}
                </li>
              ))}
          </ul>
        </CardContent>
      )}
      {!!employer._technicalSkills.length && (
        <CardContent title="TECHNOLOGY STACK">
          {employer._technicalSkills}
        </CardContent>
      )}
      <CardContent title="NOTES">
        <TextField
          name="employerNotes"
          underlineFocusStyle={{
            borderColor: colors.purple,
            borderWidth: "1.5px"
          }}
          type="text"
          rows={3}
          rowsMax={6}
          multiLine={true}
          value={publicNotes}
          onChange={onChangeEmployerNotes}
          fullWidth
        />
      </CardContent>
      <div className="d-flex">
        {Core.isAdminOrCoordinator() && !!employer.employerBlackList.length && (
          <CardContent
            icon={<i className="material-icons">block</i>}
            title="BLACK LIST"
            className="w-50"
          >
            <ul>
              {employer.employerBlackList.map(item => (
                <li key={`employer-${employer.id}-blacklist-item-${item.id}`}>
                  {item.firstName} {item.lastName}
                </li>
              ))}
            </ul>
          </CardContent>
        )}
        {Core.isAdminOrCoordinator() && !!employer.employerWhiteList.length && (
          <CardContent
            icon={<i className="material-icons">done</i>}
            title="WHITE LIST"
            className="w-50"
          >
            <ul>
              {employer.employerWhiteList.map(item => (
                <li key={`employer-${employer.id}-whitelist-item-${item.id}`}>
                  {item.firstName} {item.lastName}
                </li>
              ))}
            </ul>
          </CardContent>
        )}
      </div>
    </>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { EmployerDetails as default, EmployerDetails };

/* ============================================ */
