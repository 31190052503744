import React, { Component, Fragment } from "react";

import {
  AutoComplete,
  Chip,
  Divider,
  FlatButton,
  FontIcon,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableRowColumn,
} from "material-ui";

import Core, { colors } from "../../lib/Core";
import SelectTagDialog from "../FilterControl/Dialogs/SelectTagDialog";
import CompanySize from "./More/CompanySize";
import TextField from "material-ui/TextField";
import Row from "../Forms/Row";
import Col from "../Forms/Col";
import Slider from "@mui/material/Slider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "material-ui/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "material-ui/Dialog";
import Switch from "@mui/material/Switch";
import Definition from "../../lib/Definition";
import FilterBarMenu from "../FilterControl/FilterBarMenu";

class NewFilterControl extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      chips: [],
      keywords: [],
      minimumSalary: this.props.minimumSalary,
      minimumExperience: this.props.minimumExperience,
      minimumCompanySize: this.props.minimumCompanySize || 0,
      sliderCandidateDuration: [0, 6],
      shouldShowBlacklisted: this.props.shouldShowBlacklisted,
      applyLooseMatch: true,
      toggleShouldShowBlacklisted: false,
      toggleApplyLooseMatch: false,
      stateFilter: [1],
    };
  }

  componentDidMount() {
    const { updateSliderValues } = this.props;
    const { applyLooseMatch } = this.state;

    if (applyLooseMatch) {
      updateSliderValues("applyLooseMatch", applyLooseMatch);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.minimumSalary !== this.props.minimumSalary) {
      this.setState({ minimumSalary: nextProps.minimumSalary });
    }
    if (nextProps.minimumExperience !== this.props.minimumExperience) {
      this.setState({ minimumExperience: nextProps.minimumExperience });
    }
    if (nextProps.minimumCompanySize !== this.props.minimumCompanySize) {
      this.setState({ minimumCompanySize: nextProps.minimumCompanySize });
    }
  }

  checkLocations = ({ label, checked, cb }) => {
    cb();
  };

  jiraVER20 = ({
    label,
    menuKey,
    checked,
    fields = [],
    cb,
    shouldUpdateFilteredObjects = true,
  }) => {
    const { onChangeMenusHandler } = this.props;
    !!onChangeMenusHandler &&
      onChangeMenusHandler(
        label,
        menuKey,
        checked,
        [],
        cb,
        shouldUpdateFilteredObjects
      );
  };

  onChange = (e) => {
    //console.log("onchange");
  };

  cleanSearch = (ev) => {
    //console.log("clean search");
  };

  updateChip = (update) => {
    const { updateKeywordChip } = this.props;
    !!updateKeywordChip && updateKeywordChip(update);
  };
  ///////////////////////
  closeEventSalaryPopup = () => {
    this.setState({ showSalaryPopup: false });
  };

  applyEventSalaryPopup = (salary) => {
    const { updateSliderValues } = this.props;

    this.closeEventSalaryPopup();
    this.setState({ minimumSalary: salary }, () => {
      updateSliderValues("tempMinimumSalary", salary);
    });
  };

  onChangeEventSalaryPopup = (salary) => {
    this.setState({ minimumSalary: salary });
  };
  ///////////////////////
  closeEventExperiencePopup = () => {
    this.setState({ showExperiencePopup: false });
  };

  applyEventExperiencePopup = (experience) => {
    const { updateSliderValues } = this.props;

    this.closeEventExperiencePopup();
    this.setState({ minimumExperience: experience }, () => {
      updateSliderValues("tempMinimumExperience", experience);
    });
  };

  onChangeEventExperiencePopup = (experience) => {
    this.setState({ minimumExperience: experience });
  };

  showSalaryPopup = () => {
    const { salaryPopup } = this.props;
    const { showSalaryPopup, minimumSalary } = this.state;

    if (!!showSalaryPopup && !!salaryPopup) {
      return salaryPopup(
        this.closeEventSalaryPopup,
        this.applyEventSalaryPopup,
        this.onChangeEventSalaryPopup,
        minimumSalary
      );
    }
    return null;
  };

  showExperiencePopup = () => {
    const { experiencePopup } = this.props;
    const { showExperiencePopup, minimumExperience } = this.state;

    if (!!showExperiencePopup && !!experiencePopup) {
      return experiencePopup(
        this.closeEventExperiencePopup,
        this.applyEventExperiencePopup,
        this.onChangeEventExperiencePopup,
        minimumExperience
      );
    }
    return null;
  };

  handlerMoreFiltersApply() {
    const {
      updateFilteredObjects,
      fetchObjectList,
      handlerToggleShouldShowBlacklisted,
      updateSliderValues,
    } = this.props;
    const {
      sliderCandidateDuration,
      toggleShouldShowBlacklisted,
      shouldShowBlacklisted,
      toggleApplyLooseMatch,
      applyLooseMatch,
      stateFilterChanged,
      stateFilter,
      stateFilterChangedCb,
    } = this.state;

    updateFilteredObjects();
    if (this.state.durationChanged) {
      fetchObjectList({ d: sliderCandidateDuration.join(",") });
    }

    if (toggleShouldShowBlacklisted) {
      handlerToggleShouldShowBlacklisted(shouldShowBlacklisted);
    }

    if (toggleApplyLooseMatch) {
      updateSliderValues("applyLooseMatch", applyLooseMatch);
    }

    if (stateFilterChanged) {
      fetchObjectList({ state: stateFilter });
      stateFilterChangedCb && stateFilterChangedCb();
    }

    this.setState({
      showMoreFilter: false,
      durationChanged: false,
      toggleShouldShowBlacklisted: false,
      toggleApplyLooseMatch: false,
    });
  }

  render() {
    const {
      menus,
      more,
      chips,
      sources,
      fetchObjectList
    } = this.props;
    const {
      search,
      showMoreFilter,
      sliderCandidateDuration,
      shouldShowBlacklisted,
      applyLooseMatch,
      stateFilter,
    } = this.state;

    const FilterControlController = this;

    FilterControlController.matchingPage = true;

    return (
      <Fragment>
        <div
          className="FilterControl"
          style={{
            paddingTop: this.props.paddingTop === false ? "0px" : "24px",
          }}
        >
          <div className="filter-control-search main-chips">
            {/** CHIPS BAR */}
            <div className="filter-control-search-chips nowrap scroll">
              <i className="material-icons filter-icon">filter_list</i>
              {!chips.length && (
                <span className="filter-control-placehoder">
                  Select Filters
                </span>
              )}
              {chips.map((item, index) => (
                <Chip
                  key={`chip${index}`}
                  className="filter-control-search-chip"
                  backgroundColor={colors.purple}
                  labelColor={colors.white}
                  labelStyle={{ fontWeight: 300, fontSize: 13 }}
                  onRequestDelete={(ev) => {
                    setTimeout(() => {
                      if (item.key === "state") {
                        const stateId = +Definition.getId(item.key, item.name);
                        const stateFilterNew = stateFilter.filter(
                          (stateIdF) => +stateIdF !== +stateId
                        );
                        this.setState({ stateFilter: stateFilterNew }, () => {
                          fetchObjectList({ state: stateFilterNew });
                        });
                      }
                    });

                    this.props.deleteChip(item, index);
                  }}
                >
                  {item.name}
                </Chip>
              ))}
            </div>
            <div className="search-bar float-right">
              <i className="material-icons filter-icon">search</i>
              <AutoComplete
                name="search"
                placeholder="Enter a Keyword"
                className="search-input"
                underlineFocusStyle={{ borderColor: "transparent" }}
                filter={AutoComplete.fuzzyFilter}
                dataSource={sources}
                listStyle={{ width: "auto" }}
                maxSearchResults={7}
                searchText={search}
                onUpdateInput={(search) => {
                  // console.log("what is an update ;;;; ", search);
                  // Core.Main.Search.value = search;
                  // Core.Main.Search.update();
                  // this.update();
                  this.setState({ search });
                }}
                onNewRequest={(search) => {
                  // console.log("what is onNewRequest ;;;; ", search);
                  // Core.Main.Search.value = "";
                  // Core.Main.Search.values.push(search);
                  // Core.Main.Search.update(true);
                  // this.update();
                  this.setState({ search: "" }, () => {
                    this.updateChip({ name: search.trim(), keyword: true });
                  });
                }}
                fullWidth
              />
              &nbsp;
              {/** * /}
              {!!this.value.trim().length && (
                <span aria-label="Clear search" className="hint--left pointer">
                  <i
                    className="material-icons"
                    onClick={ev => {
                      // Core.Main.Search.value = "";
                      // this.setState({ updated: true }, then =>
                      //     Core.Main.Table.updateData({})
                      // );
                    }}
                  >
                    cancel
                  </i>
                </span>
              )}
              {/** */}
            </div>

            {/** SEARCH BAR */}
            {/** * /}
            <div className="filter-form">
              <i className="material-icons filter-icon">search</i>
              <AutoComplete
                name="search"
                placeholder="Enter a Keyword"
                className="filter-control-search-input"
                underlineFocusStyle={{ borderColor: "transparent" }}
                filter={AutoComplete.fuzzyFilter}
                dataSource={[]}
                listStyle={{ width: "auto" }}
                maxSearchResults={7}
                searchText={""}
                onUpdateInput={search => console.log("on update input : Autocomplete")}
                onNewRequest={search => console.log("on new request : Autocomplete")}
              />
            </div>
            {/** */}

            {/** SAVE AN CLEAN BUTTONS */}
            {!!this.state.chips.length && (
              <div className="filter-action-bar ui-m-min">
                {Core.isAdminOrCoordinator() && (
                  <FlatButton
                    label="Save Search"
                    className="filter-save-search"
                    onClick={this.storeState}
                  />
                )}
                <FlatButton
                  label="Clear All"
                  className="filter-clear-all"
                  onClick={this.cleanSearch}
                />
              </div>
            )}
            <Divider />
          </div>
          <div className="material-table">
            <Table className="filter-toolbar layout-auto">
              <TableBody displayRowCheckbox={false}>
                <TableRow selectable={false}>
                  <TableRowColumn>
                    <div className="d-flex nowrap scroll-x">
                      {
                        /** MENUS */
                        menus.length &&
                        menus.map(
                          menu =>
                            <FilterBarMenu
                              {...{
                                menu,
                                FilterControlController,
                              }}
                            />
                        )
                      }

                      <MenuItem
                        key={`menu1`}
                        className="filter-control-item"
                        primaryText={"Salary"}
                        rightIcon={
                          <FontIcon className="material-icons">
                            arrow_drop_down
                          </FontIcon>
                        }
                        onClick={(ev) =>
                          this.setState({ showSalaryPopup: true })
                        }
                      />
                      {this.showSalaryPopup()}

                      <MenuItem
                        key={`menu2`}
                        className="filter-control-item"
                        primaryText={"Experience"}
                        rightIcon={
                          <FontIcon className="material-icons">
                            arrow_drop_down
                          </FontIcon>
                        }
                        onClick={(ev) =>
                          this.setState({ showExperiencePopup: true })
                        }
                      />

                      {this.props.sourceKey === "job" && (
                        <CompanySize
                          minimumCompanySize={this.state.minimumCompanySize}
                          updateSliderValues={this.props.updateSliderValues}
                          companySizePopup={this.props.companySizePopup}
                        />
                      )}

                      {
                        /** MORE FILTERS */
                        !!more.length && (
                          <MenuItem
                            key={`more-menu-filters`}
                            className="filter-control-item"
                            primaryText={"More Filters"}
                            rightIcon={
                              <FontIcon className="material-icons">
                                arrow_drop_down
                              </FontIcon>
                            }
                            onClick={(ev) =>
                              this.setState({ showMoreFilter: true })
                            }
                          />
                        )
                      }

                      {this.showExperiencePopup()}
                    </div>
                  </TableRowColumn>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <SelectTagDialog
            ref={(self) => (this.SelectTag = self)}
            FilterControlController={this}
          />

          <Dialog
            title={"Filter by"}
            onRequestClose={(ev) => () => {
              alert("onclose");
            }}
            actions={
              <Fragment>
                {
                  <TextField
                    name="searchFilter"
                    className="input"
                    floatingLabelText="Search keywords"
                    required={true}
                    style={{
                      width: "calc(100% - 16px)",
                      fontSize: 14,
                      margin: "0 8px",
                    }}
                    floatingLabelStyle={{ color: colors.gray }}
                    floatingLabelFocusStyle={{
                      color: colors.purple,
                      fontSize: 12,
                    }}
                    underlineFocusStyle={{ borderColor: colors.purple }}
                    type="text"
                    value={this.state.search}
                    onChange={(event, search) => this.setState({ search })}
                    autoFocus
                  />
                }
                <FlatButton
                  label="Cancel"
                  primary={true}
                  onClick={() => this.setState({ showMoreFilter: false })}
                />
                <FlatButton
                  label="Apply"
                  primary={true}
                  onClick={(ev) => {
                    this.handlerMoreFiltersApply();
                  }}
                />
              </Fragment>
            }
            modal={false}
            open={showMoreFilter}
            autoScrollBodyContent={true}
            contentStyle={{ width: 550 }}
            bodyStyle={{ padding: "0px 20px 20px" }}
          >
            {this.props.sourceKey === "job" && (
              <Row key={`menu-duration`}>
                <Col fullWidth>
                  <label>State</label>
                  {more
                    .filter((el) => el.key === "state")
                    .map((menu) => {
                      return (
                        <Row key={`menu${menu.key}`}>
                          <Col fullWidth>
                            <br />
                            <List key={"list"} style={{ maxHeight: "60vh" }}>
                              {Object.keys(menu.items || {})
                                .sort()
                                .filter((label) =>
                                  new RegExp(this.state.search, "i").test(label)
                                )
                                .map((label) => (
                                  <ListItem
                                    key={`listItem${label}`}
                                    role={undefined}
                                    dense
                                    button
                                    onClick={(ev) => {
                                      menu.items[label] = !menu.items[label];
                                      const labelId = +Definition.getId(
                                        "state",
                                        label
                                      );
                                      this.setState((state) => state);

                                      let stateFilter = [
                                        ...this.state.stateFilter,
                                        labelId,
                                      ];

                                      if (!menu.items[label]) {
                                        stateFilter = stateFilter.filter(
                                          (id) => id !== labelId
                                        );
                                      }

                                      this.setState({
                                        stateFilter,
                                        stateFilterChanged: true,
                                        stateFilterChangedCb: () => {
                                          this.checkLocations({
                                            label,
                                            checked: menu.items[label],
                                            cb: (then) =>
                                              this.jiraVER20({
                                                label,
                                                menuKey: menu.key,
                                                checked: menu.items[label],
                                                cb: this.onChange(),
                                                shouldUpdateFilteredObjects: false,
                                              }),
                                          });
                                        },
                                      });
                                    }}
                                  >
                                    <ListItemIcon
                                      style={{ minWidth: "inherit" }}
                                    >
                                      <Checkbox
                                        edge="start"
                                        checked={Object(menu.items)[label]}
                                        tabIndex={-1}
                                        color="primary"
                                        disableRipple
                                        inputProps={{
                                          "aria-labelledby": label,
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText id={label} primary={label} />
                                  </ListItem>
                                ))}
                            </List>
                          </Col>
                        </Row>
                      );
                    })}
                </Col>
              </Row>
            )}

            {more
              .filter((el) => el.key !== "state")
              .map((menu) => {
                return menu.visible === false ? '' : (
                  <Row key={`menu${menu.key}`}>
                    <Col fullWidth>
                      <label>{menu.label}</label>
                      <br />
                      <List key={"list"} style={{ maxHeight: "60vh" }}>
                        {Object.keys(menu.items || {})
                          .sort()
                          .filter((label) =>
                            new RegExp(this.state.search, "i").test(label)
                          )
                          .map((label) => (
                            <ListItem
                              key={`listItem${label}`}
                              role={undefined}
                              dense
                              button
                              onClick={(ev) => {
                                menu.items[label] = !menu.items[label];
                                this.setState((state) => state);

                                this.checkLocations({
                                  label,
                                  checked: menu.items[label],
                                  cb: (then) =>
                                    this.jiraVER20({
                                      label,
                                      menuKey: menu.key,
                                      checked: menu.items[label],
                                      cb: this.onChange(),
                                      shouldUpdateFilteredObjects: false,
                                    }),
                                });
                              }}
                            >
                              <ListItemIcon style={{ minWidth: "inherit" }}>
                                <Checkbox
                                  edge="start"
                                  checked={Object(menu.items)[label]}
                                  tabIndex={-1}
                                  color="primary"
                                  disableRipple
                                  inputProps={{ "aria-labelledby": label }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={label}
                                primary={
                                  label === "State: Lead" ? "Lead" : label
                                }
                              />
                            </ListItem>
                          ))}
                      </List>
                    </Col>
                  </Row>
                );
              })}

            {this.props.sourceKey === "candidate" && (
              <Row key={`menu-duration`}>
                <Col fullWidth>
                  <label>Duration</label>
                  <Slider
                    value={sliderCandidateDuration}
                    marks
                    style={{ width: "80%" }}
                    valueLabelDisplay="auto"
                    onChange={(event, newValue) => {
                      if (sliderCandidateDuration !== newValue) {
                        this.setState({ sliderCandidateDuration: newValue });
                      }
                    }}
                    onChangeCommitted={(event, newValue) => {
                      this.setState({ durationChanged: true });
                    }}
                    aria-labelledby="range-slider"
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col fullWidth>
                <label>Show Blacklisted</label>
                <Switch
                  checked={shouldShowBlacklisted}
                  onChange={(event) => {
                    this.setState({
                      shouldShowBlacklisted: event.target.checked,
                      toggleShouldShowBlacklisted: true,
                    });
                  }}
                  name="checkedA"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </Col>
            </Row>

            {
              <Row>
                <Col fullWidth>
                  <label>Loose Match Expanded Search</label>
                  <Switch
                    checked={applyLooseMatch}
                    onChange={(event) => {
                      this.setState({
                        applyLooseMatch: event.target.checked,
                        toggleApplyLooseMatch: true,
                      });
                    }}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Col>
              </Row>
            }
          </Dialog>

          {/** * /}
          <AllFiltersDialog
            ref={self => (this.AllFilters = self)}
            parent={this}
          />
          {/** */}

        </div>
      </Fragment>
    );
  }
}

export default NewFilterControl;
