import React, {Component, Fragment} from "react";

/* Material files */
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Definition from "../../../../lib/Definition";

class EducationHistory extends Component{

    getEducationHistory = (candidate) => {
        let list = [];

        if(!!candidate.educationHistories && candidate.educationHistories.length !== 0) {
            candidate.educationHistories.forEach((school) => {
                school.degrees.forEach((degree) => {
                    list.push(
                        `${degree.degreeMajor + ", " || ''}${degree.degreeType + ", " || ''}${school.schoolName + ", " || ''}` +
                        `${!!degree.startDate ? degree.startDateYear + " - " : ''}${!!degree.endDate ? degree.endDateYear : ''}`
                    )
                })
            })
        } else {
            candidate.schoolsLinkedIn.forEach((obj, index) => {
                list.push(
                    `${obj.field + ", " || ''}${Definition.getLabel('undergraduateDegree', obj.degree) + ", " || ''}`+
                    `${obj.school + ", " || ''}${obj.date || ''}`
                )
            })
        }
        return list;
    }

    render(){
        const {candidate} = this.props;

        return (
        <Fragment>
            <Card className="match-new section-card">
                <CardContent className="match-new card-content">
                    <h2 className="match-new section-header">EDUCATION</h2>
                    <hr/>
                    {((cando) => {
                        let history = this.getEducationHistory(cando);
                        return history.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <p>
                                        {item}
                                    </p>
                                </Fragment>
                            )
                        })
                    })(candidate)}
                </CardContent>
            </Card>
        </Fragment>
        )
    }
}

export default EducationHistory;