import React, { Component, Fragment } from "react";
import { IconButton } from "@mui/material";
import HistoryLog from "../../lib/HistoryLog";
import Row from "../Forms/Row";
import Col from "../Forms/Col";
import Core from "../../lib/Core";
import moment from "moment";

class HistoryMenu extends Component {
  openDrawer = ev => {
    const groupList = HistoryLog.getGroupList();
    Core.openDrawer({
      style: { width: 375 },
      content: (
        <Fragment>
          <Row>
            <Col fullWidth>
              <h1>History</h1>
            </Col>
          </Row>
          <Row>
            <Col fullWidth />
          </Row>
          <Row>
            <Col fullWidth>
              <label>Accounts</label>
              {groupList.accounts.map(itm => (
                <p
                  key={Core.getKey()}
                  className="anchor"
                  onClick={ev => HistoryLog.go(itm)}
                >
                  <span>{itm.label}</span> -{" "}
                  <span>{moment(itm.date).format("MM/DD HH:mm")}</span>
                </p>
              ))}
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <label>Candidates</label>
              {groupList.candidates.map(itm => (
                <p
                  key={Core.getKey()}
                  className="anchor"
                  onClick={ev => HistoryLog.go(itm)}
                >
                  <span>{itm.label}</span> -{" "}
                  <span>{moment(itm.date).format("MM/DD HH:mm")}</span>
                </p>
              ))}
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <label>Employers</label>
              {groupList.employers.map(itm => (
                <p
                  key={Core.getKey()}
                  className="anchor"
                  onClick={ev => HistoryLog.go(itm)}
                >
                  <span>{itm.label}</span> -{" "}
                  <span>{moment(itm.date).format("MM/DD HH:mm")}</span>
                </p>
              ))}
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <label>Jobs</label>
              {groupList.jobs.map(itm => (
                <p
                  key={Core.getKey()}
                  className="anchor"
                  onClick={ev => HistoryLog.go(itm)}
                >
                  <span>{itm.label}</span> -{" "}
                  <span>{moment(itm.date).format("MM/DD HH:mm")}</span>
                </p>
              ))}
            </Col>
          </Row>
        </Fragment>
      )
    });
  };
  render() {
    return (
      <IconButton
        title="Open History"
        className="float-right"
        onClick={this.openDrawer}
      >
        <i className="material-icons">history</i>
      </IconButton>
    );
  }
}
export default HistoryMenu;
