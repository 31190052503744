/** ============================================ µ
 * @description RichTextBox [JSX]
 *              UI Component
 * @createdAt   2021-12-06 Mon
 * @updatedAt   2021-12-08 Wed
 * ============================================ */
/* IMPORTS ==================================== */

import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function RichTextBox({
  value = '',
  defaultValue = '',
  onChange = () => null,
  style,
  className,
  placeholder,
  refs = self => null,
  disabled,
  _onRejectionCase
}) {
  refs({ setValue: _onChange });
  const { t } = useTranslation();
  const [_value, setLocalValue] = useState(value || '');
  const _ref = useRef();
  function _onChange(value, event) {
    // console.debug('onChange', value);
    // special case | undetermined reason
    !_onRejectionCase && setLocalValue(value);
    onChange(value);
  }
  clearTimeout(_ref.current);
  _ref.current = setTimeout(() => {
    if (value && value !== _value) {
      // console.debug(value, _value);
      setLocalValue(value);
    }
  });
  return (
    <div style={style} className={[disabled && 'ql-rtb-disabled', 'py-1', className].filter(v => !!v).join(' ')}>
      <ReactQuill
        ref={_ref}
        value={String(_value || defaultValue)}
        onChange={_onChange}
        theme="snow"
        placeholder={placeholder || (disabled ? t('Disabled') : t('Type something'))}
        modules={disabled ? { toolbar: [] } : {
          toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean'],
          ],
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          }
        }}
        formats={[
          'header', 'font', 'size',
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'list', 'bullet', 'indent',
          'link', 'image', 'video'
        ]}
        readOnly={disabled}
      />
    </div>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { RichTextBox as default, RichTextBox };

/* ============================================ */
