/** ============================================ µ
 * @description   MatchLib [JS]
 *                Library
 * @cretedBefore  2021-07-30
 * @updatedAt     2021-11-08 Mon
 * ============================================ */

/* IMPORTS ==================================== */

import moment from 'moment';
import filterTestCases from '../../../static/filter-bar__test-cases__AC_2021-07-27_tue.json';
import Candidate from '../../Candidate';
import Definition, {
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID,
} from '../../Definition';
import FilterControlLib from '../../FilterControl';
import {
  getArrayDiff,
  getArrayOfNumberSeries,
  NOT,
  parseBinaryResults,
  YES,
} from '../../GenericTools.lib';
import Job from '../../Job';
import { mapCandidates } from '../../models/candidate';
import { mapJobs } from '../../models/job';
import Store from '../../Store';
import FilterLib from '../Filtering/Filter.lib';

/* CONSTANTS ================================== */

/* METHODS ==================================== */

/**
 * Checks if a match needs to set some flags
 *
 * then set flags into returned object
 *
 * @see story 2572
 */
function getMatchFlags({ status, candidate, job }) {
  const isRouteCandidateMatch = !!window.location.href.match(
    /\/candidate\/matchNew\//i
  );
  const isRouteJobMatch = !!window.location.href.match(/\/job\/matchNew\//i);
  const engagement = {};
  const flagIsValidStatus =
    !!status.match(/^W - 10x10$/i) ||
    !!status.match(/^W - 10x10 Review$/i) ||
    !!status.match(/^W - 10x10 ask permission$/i) ||
    !!status.match(/^W - 10x10 ask details$/i);
  console.debug('µ:reviewForAdditionalActions', {
    candidate,
    job,
    isRouteCandidateMatch,
    isRouteJobMatch,
  });
  /** CANDIDATE MATCH PAGE BEHAVIOUR */
  if (isRouteCandidateMatch) {
    const { jobsPermitted = [], jobsPitched = [] } = candidate;
    const isJobPermitted = jobsPermitted.includes(job.id);
    const isJobPitched = jobsPitched.includes(job.id);
    console.debug('µ:reviewForAdditionalActions', {
      isJobPermitted,
    });
    if (!isJobPermitted && !isJobPitched && flagIsValidStatus) {
      return MatchLib.setMatchFlagForAdditionalActions({ engagement });
    }
  } else if (isRouteJobMatch) {
  /** JOB MATCH PAGE BEHAVIOUR */
    if (flagIsValidStatus) {
      return MatchLib.setMatchFlagForBackMatch({ engagement, candidate });
    }
  }
  return engagement;
}

/**
 * Set flag into returned object
 *
 * Additional Match
 *
 * @see story 2572
 */
function setMatchFlagForAdditionalActions({ engagement }) {
  const ADMIN_ADDITIONAL_MATCH_ID =
    Definition.getId('matchType', 'Admin Additional Match') || 3;
  engagement.matchType = ADMIN_ADDITIONAL_MATCH_ID;
  return engagement;
}

/**
 * Set flags into returned object
 *
 * Back Match
 *
 * @see story 2572
 */
function setMatchFlagForBackMatch({ engagement, candidate }) {
  const ADMIN_BACK_MATCH_ID =
    Definition.getId('matchType', 'Admin Back Match') || 4;
  const today = moment();
  const { updatedAt } = candidate;
  engagement.matchType = ADMIN_BACK_MATCH_ID;
  engagement.backMatchDays = today.diff(updatedAt, 'days');
  console.debug({ engagement, candidate });
  return engagement;
}

function presetLocationMenus({
  menu = {},
  inOfficeRemoteTags = [],
  mainEntity = {},
}) {
  if (NOT(mainEntity.___model___)) {
    return false;
  }

  // console.debug('mainEntity', mainEntity);

  let preset = false;

  if (menu.key === 'inOfficeRemoteFlags') {
    preset = menu;
    inOfficeRemoteTags.forEach((tag) => {
      const itemValue = FilterControlLib.getItemValue({
        menu,
        itemLabel: tag.label,
      });
      menu.items[itemValue] =
        menu.items[itemValue] ||
        mainEntity.inOfficeRemoteFlags.includes(tag.id);
    });
  } else if (menu.key === 'candidateLocations') {
    preset = menu;
    mainEntity.candidateLocations.forEach((candidateLocationId) => {
      const candidateLocationLabel = Definition.getLabel(
        'location',
        candidateLocationId
      );
      const itemValue = FilterControlLib.getItemValue({
        menu,
        itemLabel: candidateLocationLabel,
      });
      menu.items[itemValue] =
        menu.items[itemValue] ||
        mainEntity.inOfficeRemoteFlags.includes(
          IN_OFFICE_REMOTE__REMOTE_ONLY_ID
        );
    });
  } else if (menu.key === 'officeLocations') {
    preset = menu;
    mainEntity.officeLocations.forEach((officeLocationId) => {
      const officeLocationLabel = Definition.getLabel(
        'location',
        officeLocationId
      );
      const itemValue = FilterControlLib.getItemValue({
        menu,
        itemLabel: officeLocationLabel,
      });
      menu.items[itemValue] =
        menu.items[itemValue] ||
        YES(
          mainEntity.inOfficeRemoteFlags.includes(
            IN_OFFICE_REMOTE__FULL_WEEK_ID
          ) ||
            mainEntity.inOfficeRemoteFlags.includes(
              IN_OFFICE_REMOTE__PART_WEEK_ID
            )
        );
    });
  }

  return preset;
}

function unitTestingPresetLocationMenusSingle({ mainEntity = {}, items = [] }) {
  const inOfficeRemoteTags = Definition.get('inOfficeRemote');

  const checkedLocationOptionsMenu = {};

  // emulated filter-bar menu.
  [
    { key: 'inOfficeRemoteFlags', items: {} },
    { key: 'candidateLocations', items: {} },
    { key: 'officeLocations', items: {} },
  ].forEach((menu) => {
    // get the presets in menu
    // THIS IS THE OBJECTIVE FUNCTION OF THIS UNIT-TESTING
    const menuOptions = MatchLib.presetLocationMenus({
      menu,
      inOfficeRemoteTags,
      mainEntity,
    }).items;
    checkedLocationOptionsMenu[menu.key] = Object.keys(menuOptions).filter(
      (k) => YES(menuOptions[k])
    );
  });

  // get the tag-ids of each menu-options.
  let {
    inOfficeRemoteFlags = [], // inOfficeRemote tag-ids
    candidateLocations = [], // location tag-ids
    officeLocations = [], // location tag-ids
  } = FilterLib.translateLocationMenus({
    checkedLocationOptionsMenu,
  });

  // the filter-input
  const filter = {
    inOfficeRemoteFlags,
    candidateLocations,
    officeLocations,
  };

  // get the filter-id
  const filterId = parseBinaryResults([
    filter.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID),
    filter.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID),
    filter.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID),
    !!filter.candidateLocations.length,
    !!filter.officeLocations.length,
  ]);

  // get a filter name (compressed inputs info)
  const filterName = [
    [
      filter.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
        ? 'R'
        : 'E',
      filter.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
        ? 'F'
        : 'E',
      filter.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
        ? 'P'
        : 'E',
    ].join(''),
    !!filter.candidateLocations.length ? 'S' : 'E',
    !!filter.officeLocations.length ? 'S' : 'E',
  ].join('_');

  // get the test case according to the filter-id
  const testCase = filterTestCases[filterId];

  // once we now the filter has already preset locations
  // we will be overwriting the filter-input locations by the testCase-locations,
  // in order to test the matching of the new filter-id obtained.
  candidateLocations =
    YES(candidateLocations.length) && YES(testCase.candidateLocations.length)
      ? testCase.candidateLocations
      : candidateLocations;
  officeLocations =
    YES(officeLocations.length) && YES(testCase.officeLocations.length)
      ? testCase.officeLocations
      : officeLocations;

  // matching-results
  let matchResults = FilterLib.filterListLocationMenus({
    items,
    inOfficeRemoteFlags,
    candidateLocations,
    officeLocations,
  });

  // setting values to print.
  const _inputItems = items
    .map((v) => v.__unitTestingItemId)
    .filter((n) => !!n);
  const _expected = getArrayOfNumberSeries(testCase.expected);
  const results = matchResults
    .map((v) => v.__unitTestingItemId)
    .filter((n) => !!n);
  const diff = getArrayDiff(_expected, results);
  const _name = mainEntity.jobTitle || mainEntity._name.replace(' ', '');

  // console prints
  console.debug('Unit Testing Matching Pages');
  console.debug('input-items', _inputItems);
  console.debug('test-case.expected-results', _expected);
  console.debug('main-entity.match-results', results);
  !!diff.length
    ? console.debug(
        `\u001b[33m${!diff.length} ${_name} filterId:${filterId} ${filterName} diff\u001b[0m (${
          diff.length
        }) [${diff.join(',')}]`
      )
    : console.debug(
        `\u001b[32m${!diff.length} ${_name} filterId:${filterId} ${filterName}\u001b[0m`
      );
  if (!!diff.length) {
    console.debug('filter.inOfficeRemoteFlags', inOfficeRemoteFlags);
    console.debug('filter.candidateLocations', candidateLocations);
    console.debug('filter.officeLocations', officeLocations);
    items.forEach((v) => {
      if (diff.includes(v.__unitTestingItemId)) {
        console.debug(
          `${v.jobTitle || v._name} cLoc:[${v.candidateLocations}] oLoc:[${
            v.officeLocations
          }]`
        );
      }
    });
  }

  console.debug('=============================');

  return checkedLocationOptionsMenu;
}

let candidates = [];
let jobs = [];
async function unitTestingPresetLocationMenusAll({ job, candidate }) {
  const unitTestingLocationsInMatchPages = Store.get(
    'unitTestingLocationsInMatchPages'
  );
  if (NOT(unitTestingLocationsInMatchPages)) {
    return false;
  }

  if (NOT(candidates.length)) {
    candidates = await Candidate.getActiveCandidates(() => {}, {});
    candidates = mapCandidates(candidates);
    candidates = candidates.filter((v) => YES(v.__unitTestingItemId));
  }
  if (NOT(jobs.length)) {
    jobs = await Job.getActives(() => {});
    jobs = mapJobs(jobs);
    jobs = jobs.filter((v) => YES(v.__unitTestingItemId));
  }

  console.debug({ candidates, jobs });

  if (YES(job)) {
    jobs.forEach((job) => {
      unitTestingPresetLocationMenusSingle({
        mainEntity: job,
        items: candidates,
      });
    });
  } else if (YES(candidate)) {
    candidates.forEach((candidate) => {
      unitTestingPresetLocationMenusSingle({
        mainEntity: candidate,
        items: jobs,
      });
    });
  }
}

/* DICTIONARIES =============================== */

const MatchLib = {
  getMatchFlags,
  setMatchFlagForAdditionalActions,
  setMatchFlagForBackMatch,
  presetLocationMenus,
  unitTestingPresetLocationMenusAll,
};

/* EXPORTS ==================================== */

export { MatchLib as default, MatchLib };

/** ============================================ */
