import { IconButton } from "material-ui";
import AppBar from "material-ui/AppBar";
import FlatButton from "material-ui/FlatButton";
import Paper from "material-ui/Paper";
import Snackbar from "material-ui/Snackbar";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import Core from "../../../lib/Core";
import Definition, { EMP_MSG_TYPE__ALL_ID, EMP_MSG_TYPE__SUBMISSION_ID } from "../../../lib/Definition";
import EmailTemplate from "../../../lib/EmailTemplate";
import Employer from "../../../lib/Employer";
import Google from "../../../lib/Google";
import HistoryLog from "../../../lib/HistoryLog";
import Job from "../../../lib/Job";
import { getJobModel } from "../../../lib/models/job";
import Store from "../../../lib/Store";
import EmailPreview from "../../Dialogs/EmailPreview";
import SuccessDialog from "../../Dialogs/Success";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";
import styles from "../../Forms/Styles";
import HistoryMenu from "../../HistoryMenu/HistoryMenu";
import Stepper from "../../Stepper/Stepper";
import Basics from "../Forms/Basics";
import Process from "../Forms/Process";
import Requirements from "../Forms/Requirements";

const required = "This field is required";

class JobEdit extends Component {
  state = {
    ...getJobModel({ extended: true }),
    /** controller states */
    categories: [],
    allCategories: [],
    employers: [],
    snackbar: null,
    error: {},
    errorJobTitle: "",
    step: 0,
    initial: 1,
  };
  constructor() {
    super(...arguments);
    Store.set("path", window.location.href);
    if (Core.getUserRole() !== "SysAdmin") {
      Core.go({ ...this.props, to: '/' });
    }
    Employer.getList((employers) => {
      this.setState(
        {
          employers,
          /** setting fulltime as default value of job type */
          jobType: Definition.getId("jobType", "Fulltime"),
          /** setting unknown as default value of remote */
          remote: Definition.getId("remote", "Unknown"),
        },
        (then) => {
          if (this.props.match.params.id) {
            Job.get(this.props.match.params.id, (model) => {
              this.setState(
                {
                  ...model,
                  employers: [...this.state.employers],
                },
                (then) => {
                  EmailTemplate.getAll((emailTemplates) => {
                    this.setState({ emailTemplates }, (then) =>
                      HistoryLog.set({ group: "jobs", label: this.state._name })
                    );
                  });
                }
              );
              setTimeout((st) => Core.log(this.state));
            });
          }
        }
      );
    });
  }
  /** POST/UPDATE JOB */
  update = (success, final) => {
    if (this.isValid()) {
      if (this.state.id) {
        /* Jira Ticket Ticket VER-195 ===>>> */
        /* >Match candidate & job - prefill roles & visa. */
        /* -It is needed to remove searchConfig from the job
            in order to preset the chips on the filter for match
            when the job change.
        */
        const job = { ...this.state };
        job.searchConfig = {};
        /* <<<=== Jira Ticket Ticket VER-195 */
        Job.update(this.state.id, job, success);
      } else {
        Job.post(this.state, success);
      }
    }
  };
  /** SAVE & NEXT BUTTON */
  submitThenGoNext = (ev) => {
    if (this.isValid()) {
      this.update((response) =>
        this.setState({ id: response.id }, (then) => this.refs.stepper.next())
      );
    }
  };
  goBack = (ev) => this.cancel();
  cancel = (ev) => {
    Core.goBack(this.props); // "/#/jobs";
  };
  previous = (ev) => {
    this.refs.stepper.previous();
  };
  next = (ev) => {
    if (this.state.step === 0 && !this.isValid()) {
      return;
    }
    this.refs.stepper.next();
  };
  save = (event) => {
    this.setState({ state: Definition.getId("state", "Draft") }, (then) =>
      this.submit()
    );
  };
  /**
   * SUBMIT; DONE BUTTON
   */
  submit = (ev) => {
    this.update((response) => {
      if (this.state.id) {
        this.refs.successDialog.open("Job has been successfully updated");
      } else {
        this.setState({ id: response.id }, (then) => {
          this.refs.successDialog.open("Job has been successfully created");
        });
      }
    }, true);
  };
  isValid = (event) => {
    if (!this.state.roles.length) {
      window.scrollTo(0, 0);
      this.setState({
        snackbar: "Role is required",
        error: { role: required },
      });
      return false;
    }
    if (!this.state.employerId) {
      window.scrollTo(0, 0);
      this.setState({
        snackbar: "Employer is required",
        error: { employer: required },
      });
      return false;
    }
    if (!String(this.state.jobTitle).trim().length) {
      window.scrollTo(0, 0);
      this.setState({
        snackbar: "Job Title is required",
        errorJobTitle: required,
      });
      return false;
    }
    return true;
  };
  openMessage = (ev) => {
    const job = this.state;
    const emails = [
      job.emailsList
        .filter(({ to }) => (
          to.includes(EMP_MSG_TYPE__ALL_ID) ||
          to.includes(EMP_MSG_TYPE__SUBMISSION_ID)
        ))
    ];
    Core.dialog.open({
      title: <>Message</>,
      message: (
        <EmailPreview
          ref={(self) => (this.EmailMessage = self)}
          emails={emails}
          to={!!emails[0] && [emails[0]]}
          subject=""
          body={[].filter((line) => !!line).join("<br/>")}
        />
      ),
      className: "p-0",
      actions: [
        <FlatButton
          label="Cancel"
          className="button-flat-darker"
          onClick={(ev) => {
            Core.dialog.close();
          }}
        />,
        <FlatButton
          label="Send"
          className="button-white-cyan"
          onClick={(ev) => {
            Core.dialog.close();
            Google.sendEmail(
              {
                ...this.EmailMessage.getParams(),
                source: "JobEdit.js line 222",
              },
              (response) => Core.showMessage("Email sent"),
              (error) => Core.showFailure(error)
            );
          }}
        />,
      ],
    });
  };

  render() {
    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }
    return (
      <MuiThemeProvider>
        <div className="edit job">
          <div className="appBar" style={styles.AppBar}>
            <AppBar
              title={
                (this.props.match.params.id ? "Edit " : "New ") +
                "Job" +
                (!!this.state.jobTitle
                  ? " - " +
                  (!!this.state.role
                    ? Definition.getLabel("roles", this.state.role) + "/"
                    : "") +
                  this.state.jobTitle
                  : "")
              }
              titleStyle={styles.AppBar.title}
              style={styles.AppBar.nav}
              iconElementLeft={
                <FlatButton
                  icon={<i className="material-icons">arrow_back</i>}
                  style={styles.AppBar.backButton}
                  onClick={this.goBack}
                />
              }
              iconElementRight={
                <Fragment>
                  <IconButton
                    className="float-right"
                    onClick={this.openMessage}
                  >
                    <i className="material-icons">email</i>
                  </IconButton>
                  <HistoryMenu />
                </Fragment>
              }
            />
          </div>
          <Paper className="form" style={styles.Paper} zDepth={1}>
            <FlatButton
              label="SAVE"
              className="form-save-btn"
              onClick={this.submit}
            />
            <Stepper
              ref="stepper"
              active={this.state.initial - 1}
              headers={["BASICS", "REQUIREMENTS", "PROCESS"]}
              views={[
                <Basics ref="basics" parent={this} />,
                <Requirements ref="requirements" parent={this} />,
                <Process ref="process" parent={this} />,
              ]}
              onStep={(step) => this.setState({ step })}
            />
            <Row style={{ padding: "16px 0" }}>
              <Col>
                <FlatButton
                  label="CANCEL"
                  className="btn-white-crimson"
                  onClick={this.goBack}
                />
              </Col>
              <Col style={{ textAlign: "right" }}>
                {this.state.step === 0 ? (
                  ""
                ) : (
                  <FlatButton
                    label="PREVIOUS"
                    className="btn-white-cyan"
                    onClick={this.previous}
                  />
                )}
                {this.state.step === 2 ? (
                  <FlatButton
                    label="DONE"
                    className="btn-cyan-white"
                    onClick={this.submit}
                  />
                ) : (
                  <Fragment>
                    <FlatButton
                      label="NEXT"
                      className="btn-white-cyan"
                      onClick={this.next}
                    />
                    <FlatButton
                      label="SAVE & NEXT"
                      className="btn-cyan-white"
                      onClick={this.submitThenGoNext}
                    />
                  </Fragment>
                )}
              </Col>
            </Row>
            <SuccessDialog
              ref="successDialog"
              modal={true}
              actions={[
                <FlatButton
                  label="STAY"
                  primary={true}
                  onClick={(ev) => this.refs.successDialog.close()}
                />,
                <FlatButton
                  label="DONE"
                  primary={true}
                  keyboardFocused={true}
                  onClick={(ev) => Core.go({ ...this.props, to: '/jobs/' })}
                />,
              ]}
            />
          </Paper>
          <Snackbar
            open={!!this.state.snackbar}
            message={this.state.snackbar || ""}
            className="snack-bar"
            autoHideDuration={6000}
            onRequestClose={(ev) => this.setState({ snackbar: null })}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default JobEdit;
