/** ============================================ µ
 * @description Candidate Email [JSX]
 *              Library
 * @routes      /engagements/
 *              /engagement/represent/:id
 * @see         EngagementCard | component
 * @createdAt   2021-05-06
 * @updatedAt   2021-06-21
 * ============================================ */
/* IMPORTS ==================================== */

import { FlatButton } from "material-ui";
import React from "react";
import EmailPreview from "../../../components/Dialogs/EmailPreview";
import Candidate from "../../Candidate";
import Core from "../../Core";
import { REC_MSG_TYPE__REPRESENTATION_ID } from "../../Definition";
import Streak from "../../Streak";
import cleanHTML from "../../tools/cleanHtml";
import { sendSafeEmail } from "./Email.lib";
import { mapRecruiterEmailDeliveries } from "./EmailRecruiter.lib";

/* METHODS ==================================== */

function openRepresentEmailPreview({
  candidateId,
  engagement
}) {
  Candidate.get(candidateId,
    async candidate => {
      /** */
      // const path = Core.getPath(`engagements`);
      if (candidate.email) {

        let jobList = [];

        // const jobListCopy = [];

        let companies = {};
        candidate.engagements.forEach(eng => {
          if (/open/i.test(eng.state)) {
            if (/submission|review/i.test(eng.stage)) {
              companies[eng.job.employer.name] = true;

              jobList.push(
                [
                  (!!eng.job.jobDescriptionPublicURL?.trim()
                    ? `<a href="${eng.job.jobDescriptionPublicURL}" target="_blank">${eng.job.jobTitle}</a>`
                    : eng.job.jobTitle
                  ),
                  (!!eng.job.employer.url
                    ? `<a href="${eng.job.employer.url}" target="_blank">${eng.job.employer.name}</a>`
                    : eng.job.employer.name
                  )
                ].filter(n => !!n).join(' at ')
              );
              //jobListCopy.push(eng.job.getPreview(eng.job));
            }
          }
        });
        jobList = `<li>${jobList.filter(n => !!n).map(n => n.trim()).join('</li><li>')}</li>`;

        Core.log({ companies: Object.keys(companies).length });

        companies = (
          Object.keys(companies)
            .join(", ")
            .trim()
        );

        if (!!companies.length) {

          const candidateEmailEntry = {
            accountType: "Candidate",
            name: candidate._name || "Candidate",
            email: candidate.email
          }
          const {
            recruiter
          } = candidate;

          /** This is for represent email */
          const {
            recruiterEmails = [],
            recruiterCcList = []
          } = await mapRecruiterEmailDeliveries({
            messageType: REC_MSG_TYPE__REPRESENTATION_ID,
            recruiterId: recruiter.id,
            add10by10EmailToCcList: true,
            useMainEmailWhenToIsEmpty: false,
            useMainEmailWhenCcIsEmpty: true
          });

          const emailsList = [
            candidateEmailEntry,
            ...recruiterEmails,
          ];
          const deliveryTo = [
            candidateEmailEntry
          ];
          const deliveryCc = [
            ...recruiterCcList,
          ];

          /** µ FOR DEBUG PURPOSES * /
          console.debug('µ:openRepresentEmail', { recruiter });
          console.debug('µ:openRepresentEmail', { emailsList });
          console.debug('µ:openRepresentEmail', { deliverCc: deliveryCc, recruiterMainEmail });
          /** */

          const html = cleanHTML(`
          <div>
            <p>
              Hi ${candidate.firstName}
            </p>
            <p>
              We heard from our partner <b>${candidate.recruiter.talentAdvisor || candidate.recruiter._name}</b>&nbsp;
              that you are interested in new opportunities. To avoid delay, we have already submitted your application. 
            </p>
            <p>
              <b>Please review the job description(s)</b> for the jobs listed below to ensure that we have submitted 
              you to the correct role(s).
            </p>
            <ul>${jobList}</ul>
            <p>
              Interview prep - The employer expects that you are excited about the company/role and can answer "why are you 
              interested in interviewing with us". If you have any questions, please ask us!
            </p>
            <p>
            Please confirm that you have read the job description and that your contact information below is correct:
            <p>
              <ul>
                <li>
                  email: ${!!candidate.email ? candidate.email : '?'}
                </li>
                <li>
                  phone: ${!!candidate.phone ? candidate.phone : '?'}
                </li>
                <li>
                  best time to contact you if the hiring team wants to know your interview availability: 
                  ${!!candidate.bestTimeForCalls ? candidate.bestTimeForCalls : '?'}
                </li>
              </ul>
            </p>
            <p>               
              <a href="https://www.10by10.io/candidate">10x10</a> will represent you with the employers 
              and facilitate the interview process. ${candidate.recruiter.talentAdvisor || candidate.recruiter._name} 
              (Cc'd), has access to all the information provided by the employers, and together we will support you 
              with your interview preparation. 
            </p>
            <p>
              If you have any questions, we'll work together to get you prompt answers and ensure you have a great interviewing 
              experience. Best luck!
            </p>
            ${Core.getEmailSignature()}
          </div>
        `);
          Core.dialog.open({
            title: <>Represent Email</>,
            message: (
              <EmailPreview
                ref={self => Core.setKeyValue('CandidateRepresentEmailPreview', self)}
                from={Core.getNewCandidateReceiverNotiFromEmail()}
                emails={emailsList}
                to={deliveryTo}
                cc={deliveryCc}
                subject={`Thanks for your interest in ${companies}`}
                body={html}
              />
            ),
            className: "p-0",
            actions: [
              <FlatButton
                label="Cancel"
                className="button-flat-darker"
                onClick={ev => {
                  Core.dialog.close();
                }}
              />,
              <FlatButton
                label="Send"
                className="button-white-cyan"
                onClick={ev => {
                  Core.dialog.close();
                  sendSafeEmail(
                    {
                      ...(
                        Core.getKeyValue(
                          'CandidateRepresentEmailPreview'
                        ).getParams()
                      ),
                      source: 'CandidateEmail.lib.js'
                    },
                    response => {
                      Core.showMessage("Email sent");
                      if (!response.threadId) {
                        Core.showFailure("It is missing the threadGmailId");
                      } else {
                        Streak.putEmailInBox(
                          {
                            boxKey: engagement.boxKey,
                            threadGmailId: response.threadId
                          },
                          response =>
                            Core.log("putEmailInBox", "success", response)
                        );
                      }
                    },
                    error => Core.showFailure(error)
                  );
                }}
              />
            ]
          });
        } else {
          Core.showMessage(
            "There are no engagements in submission or review for this candidate"
          );
        }
      } else {
        Core.showMessage("There is missing the candidate email");
      }
    });
};

/* DICTIONARY ================================= */

const CandidateEmailLib = {
  openRepresentEmailPreview
};

/* EXPORTS ==================================== */

export {
  CandidateEmailLib as default,
  CandidateEmailLib,
  openRepresentEmailPreview as openCandidateRepresentEmailPreview,
};

/* ============================================ */
