import React, { Component, Fragment } from "react";

import {
  Divider,
  FlatButton,
  IconButton,
  MenuItem,
  TextField,
} from "material-ui";

import TagBox from "./TagBox";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Input from "@mui/material/Input";
import { LOC_TYPE__ALIAS_ID } from "../../lib/Definition";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

class CategoryBox extends Component {
  constructor() {
    super(...arguments);
    const state = {
      cat: this.props.cat,
      tags: this.props.tags,
      open: this.props.expanded,
      newGroup: this.getNewGroup(),
    };
    if (this.props.cat) {
      state.cat = Object.assign(state.cat, this.props.cat);
    }
    this.state = state;
  }

  getNewGroup = () => {
    return { name: "", ids: [], id: new Date().getTime() };
  };

  save = (ev) => {

    const {
      onUpdate = () => { }
    } = this.props;

    if (ev.cat) {
      onUpdate({
        category: ev.cat,
      });
    } else if (this.state.changes) {
      this.setState(
        {
          changes: false,
          edit: false,
          cat: this.state.cat || { ...this.props.cat },
        },
        (em) => {
          onUpdate({
            category: this.state.cat,
          });
        }
      );
    }
    setTimeout((st) => this.props.onBlur && this.props.onBlur(ev));
  };

  handleClosePopup = () => {
    this.setState({ addNewCategory: false });
  };

  handlerCreateGroup = () => {
    const cat = { ...this.state.cat };
    let groups = cat.groups || [];
    let action = this.state.groupAction;
    let group;

    if (action === "create") {
      groups.push(this.state.newGroup);
    } else {
      group = this.state.newGroup;
      let currentIndex = groups.findIndex(
        (gr) => gr.id === this.state.newGroup.id
      );
      groups[currentIndex] = group;
    }

    cat.groups = groups;
    this.setState({ newGroup: this.getNewGroup() });
    this.save({ cat });
  };

  handlerDeleteGroup = (group) => {
    const cat = { ...this.state.cat };
    let groups = cat.groups || [];
    let newGroup = this.state.newGroup;

    cat.groups = groups.filter((group) => group.id !== newGroup.id);
    this.save({ cat });
  };

  render() {
    const cat = this.state.cat;
    const tags = this.state.tags;
    const newTagBoxKey = `cat${cat.key}AddNewTag`;
    const icon =
      !this.state.cat.archived &&
      this.state.cat.key &&
      (!!this.state.open ? (
        <i className="material-icons">keyboard_arrow_up</i>
      ) : (
        <i className="material-icons">keyboard_arrow_down</i>
      ));

    let { addNewCategory, newGroup } = this.state;

    const {
      onUpdate = () => { }
    } = this.props;

    return (
      <div className="blocks margin-top">
        <div className="inline-blocks">
          <div className={this.state.edit ? "col4" : "col8"}>
            <FlatButton
              label={cat.label}
              className="align-left"
              icon={icon}
              onClick={(ev) => {
                /** collapse/expand toogle */
                const cat = { ...this.state.cat };
                cat.open = !cat.open;
                this.save({ cat });
              }}
              disabled={this.state.cat.archived}
            />
            <small className="c-gray">{cat.key}</small>
          </div>

          {!/categories/i.test(this.state.cat.key) && (
            <div
              className={`align-right inline-blocks v-align-middles ${this.state.edit ? "col8" : "col4"
                }`}
            >
              <IconButton
                className=""
                onClick={(ev) => {
                  this.setState({
                    addNewCategory: true,
                    groupAction: "create",
                    newGroup: this.getNewGroup(),
                  });
                }}
              >
                <i className="material-icons">group</i>
              </IconButton>

              <Dialog
                fullWidth="lg"
                maxWidth="lg"
                open={addNewCategory}
                onClose={this.handleClosePopup}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Add/Edit Group</DialogTitle>
                <DialogContent>
                  <DialogContentText>Name Of Group</DialogContentText>
                  <TextField
                    id="standard-full-width"
                    label="Name of Group"
                    style={{ margin: 8 }}
                    value={newGroup.name}
                    placeholder=""
                    helperText="Group Name"
                    fullWidth
                    margin="normal"
                    onChange={(event) => {
                      newGroup.name = event.target.value;
                      this.setState({ newGroup });
                    }}
                  />

                  {
                    /**
                     * @todo
                     * TO CLEANUP ?
                     * ask Bob
                     * 2021-06-09
                     * µ
                     */
                  }
                  {/*<DialogContentText>*/}
                  {/*Unique Id of group*/}
                  {/*</DialogContentText>*/}

                  {/*<TextField*/}
                  {/*id="standard-full-width-unique-key"*/}
                  {/*label="Unique Key"*/}
                  {/*style={{margin: 8}}*/}
                  {/*value={newGroup.id}*/}
                  {/*placeholder=""*/}
                  {/*helperText="Unique Key"*/}
                  {/*fullWidth*/}
                  {/*margin="normal"*/}
                  {/*onChange={(event) => {*/}
                  {/*newGroup.id = event.target.value;*/}
                  {/*this.setState({newGroup})*/}
                  {/*}*/}
                  {/*}*/}
                  {/*/>*/}

                  <DialogContentText>Associated Labels</DialogContentText>
                  <Select
                    multiple
                    value={newGroup.ids}
                    onChange={(event) => {
                      newGroup.ids = event.target.value;
                      this.setState({ newGroup });
                    }}
                    input={<Input id="select-multiple-checkbox" />}
                    renderValue={(selected) =>
                      this.state.cat.values
                        .filter((el) => selected.includes(el.id))
                        .map((el) => el.label)
                        .join(", ")
                    }
                    fullWidth
                    style={{ marginTop: "10px" }}
                  >
                    {this.state.cat.values.map((tag) => (
                      <MenuItem key={tag.id} value={tag.id}>
                        <Checkbox
                          checked={newGroup.ids.indexOf(tag.id) > -1}
                          style={{ float: "left" }}
                        />
                        <ListItemText
                          primary={tag.label}
                          style={{ paddingTop: "10px" }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClosePopup} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={this.handlerCreateGroup} color="primary">
                    Save
                  </Button>

                  {
                    /**
                     * @todo
                     * TO CLEANUP ?
                     * ask Bob
                     * 2021-06-09
                     * µ
                     */
                  }
                  {/*<Button onClick={this.handlerDeleteGroup} color="primary">*/}
                  {/*Delete*/}
                  {/*</Button>*/}

                </DialogActions>
              </Dialog>

              {this.state.edit && (
                <Fragment>
                  <small className="c-gray mr-1">Label:</small>
                  <TextField
                    name={`${cat.key}-label`}
                    placeholder={"label"}
                    className="input-1 margin-right"
                    value={this.state.cat.label}
                    onChange={(ev, change) => {
                      this.setState((state) => {
                        state.cat.label = change;
                        state.changes = true;
                        return state;
                      });
                    }}
                    disabled={this.state.cat.archived}
                  />
                  <small className="c-gray mr-1">Key: </small>
                  <TextField
                    name={`${cat.key}-key`}
                    placeholder={"key"}
                    className="input-1"
                    value={cat.key}
                    onChange={(ev, change) => {
                      this.setState((state) => {
                        state.cat.key = change;
                        state.changes = true;
                        return state;
                      });
                    }}
                    disabled={this.state.cat.archived}
                  />
                </Fragment>
              )}
              {cat.archived ? (
                <IconButton
                  className=""
                  onClick={(ev) => {
                    const cat = { ...this.state.cat };
                    cat.archived = false;
                    this.save({ cat });
                  }}
                >
                  <i className="material-icons">unarchive</i>
                </IconButton>
              ) : this.state.changes ? (
                <IconButton onClick={this.save}>
                  <i className="material-icons">save</i>
                </IconButton>
              ) : (
                <IconButton
                  className=""
                  onClick={(ev) => this.setState({ edit: !this.state.edit })}
                >
                  <i className="material-icons">mode_edit</i>
                </IconButton>
              )}
              {!this.props.new && !cat.archived && (
                <IconButton
                  onClick={(ev) => {
                    const cat = { ...this.state.cat };
                    cat.archived = true;
                    this.save({ cat });
                  }}
                >
                  <i className="material-icons">archive</i>
                </IconButton>
              )}
            </div>
          )}
        </div>

        <div className="inline-blocks">
          {Array.isArray(cat.groups) &&
            cat.groups.map((group) => {
              return (
                <FlatButton
                  label={group.name}
                  className="align-left"
                  onClick={(ev) => {
                    this.setState({
                      addNewCategory: true,
                      newGroup: group,
                      groupAction: "edit",
                    });
                  }}
                />
              );
            })}
        </div>
        {!!this.state.cat.open &&
          !this.state.cat.archived &&
          this.state.cat.key ? (
          <div className="d-flex flex-wrap col bgcolor1 padding-10 border-1 scroll-y">
            <label className="mt-0">Tags</label>
            {tags &&
              tags.map((tag, tagIndex) => {
                const tagUKey = `${cat.key}_${tagIndex}`;
                return (
                  <TagBox
                    category={this.state.cat}
                    key={tagUKey}
                    name={tagUKey}
                    ref={(tf) => (this[tagUKey] = tf)}
                    tag={tag}
                    onUpdate={(tag) => {
                      const update = [...tags];
                      update[tagIndex] = tag;
                      onUpdate({
                        tags: update,
                      });
                    }}
                    onArchived={({ tag }) => {

                      const update = [...tags];
                      update[tagIndex] = tag;

                      const {
                        id,
                        type,
                        parentId,
                      } = tag;

                      function removeLocationFromAlias({
                        locationId,
                        aliasTag,
                      }) {
                        aliasTag.aliasLocationIds = (
                          aliasTag.aliasLocationIds || []
                        );
                        const locationIndex = aliasTag.aliasLocationIds.indexOf(locationId);
                        if (!!~locationIndex) {
                          aliasTag.aliasLocationIds.splice(locationIndex, 1);
                        }
                      }

                      function setParentUpToChild({
                        locationTag,
                        parentId,
                        grandParentId,
                      }) {
                        if (locationTag.parentId === parentId) {
                          locationTag.parentId = grandParentId || null;
                        }
                      }

                      if (type !== LOC_TYPE__ALIAS_ID) {
                        update.forEach((locationTag, locationTagIndex) => {

                          if (locationTag.type === LOC_TYPE__ALIAS_ID) {
                            removeLocationFromAlias({
                              aliasTag: locationTag,
                              locationId: id
                            });
                          }

                          if (locationTag.type !== LOC_TYPE__ALIAS_ID) {
                            setParentUpToChild({
                              locationTag,
                              parentId: id,
                              grandParentId: parentId
                            })
                          }

                        });
                      }

                      onUpdate({
                        tags: update,
                      });

                    }}
                  />
                );
              })}

            {/**
              * TO ADD NEW TAG
              */}
            <Divider className="my-1" />
            <label>Add a new Tag</label>
            <TagBox
              name="addNewTag"
              isNew={true}
              ref={(tf) => (this[newTagBoxKey] = tf)}
              placeholder="Add a New Tag"
              onUpdate={(tag) => {
                const update = [...tags];
                tag.id = update.length + 1;
                update.push(tag);
                onUpdate({
                  tags: update,
                });
              }}
              new={true}
            />

          </div>
        ) : (
          <Divider />
        )}
      </div>
    );
  }
}
export default CategoryBox;
