import Core from "../../lib/Core";
import { STATE_ACTIVE } from '../../lib/Definition';
import Employer from '../../lib/Employer';
import { mapEmployers } from '../../lib/models/employer';
import { FilterDateRangeColumn } from "./FilterDateRangeColumn";
import { FilterLocationsColumn } from "./FilterLocationsColumn";
import { moreMenuOptionsEmployers } from "./moreMenuOptionsEmployers";
import {
  CellActionsColumn,
  CellTagColumn,
  FilterActionsColumn,
  FilterNumberRangeColumn,
  FilterTagColumn
} from "./ReactTable";
import { REACT_TABLE__COLUMN_XXL } from "./useEnhancedReactTable.hook";

export const configEmployers = {
  Entity: Employer,
  entityMapper: mapEmployers,
  searchPath: 'Employers/_search',
  autocompletePath: 'Employers/_autocomplete',
  facetsPath: 'Employers/_facets',
  starredAccessor: 'employerStarreds',
  searchPlaceholder: 'Search by Employer company name, contact name',
  addNew: {
    label: '+ New Employer',
    to: '/employer/create'
  },
  defaultQuery: {
    "filters": [
      {
        "state": STATE_ACTIVE,
      }
    ],
    // "sort": { "name": 1 },
    "skip": 0,
    "limit": 10,
    "query": [],
    "associations": [
      'jobs',
      'employerStarreds'
    ]
  },
  hiddenColumns: [
    'state',
  ],
  columns: [
    {
      Header: 'Employer Name',
      accessor: 'name',
      disableFilters: false,
      width: 160,
      _headerClassName: 'sticky-start bg-main br-1',
      _cellClassName: 'sticky-start bg-white br-1',
    },
    {
      accessor: 'jobs.roles',
      Header: 'Job Roles',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      _accessor: '_jobRoles',
      _categoryKey: 'roles',
    },
    {
      accessor: 'location',
      Header: 'Location',
      Filter: FilterLocationsColumn,
      //Filter: FilterTagColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      width: REACT_TABLE__COLUMN_XXL,
      _accessor: '_location',
      _categoryKey: 'location',
      _facetTagRequired: true,
    },
    {
      accessor: 'technicalSkills',
      Header: 'Technology',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      _accessor: '_technicalSkills',
      _categoryKey: 'technicalSkills',
    },
    {
      accessor: 'visaTransfer',
      Header: 'Visa',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      width: 160,
      _accessor: '_visaTransfer',
      _categoryKey: 'visa',
    },
    {
      accessor: 'stage',
      Header: 'Stage',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      _accessor: '_stage',
      _categoryKey: 'stage',
    },
    {
      accessor: 'employeeCount',
      Header: 'Employees',
      Filter: FilterNumberRangeColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      _accessor: '_employeeCount',
    },
    {
      accessor: 'updatedAt',
      Header: 'Recent',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_updatedAt',
    },
    {
      accessor: 'state',
      Header: 'State',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      width: 120,
      _acl: [Core.isAdminOrCoordinator()],
      _accessor: '_state',
      _categoryKey: 'state',
    },
    {
      id: 'actions',
      Header: 'Actions',
      Filter: FilterActionsColumn,
      Cell: CellActionsColumn,
      defaultCanFilter: true,
      disableFilters: false,
      disableSortBy: false,
      width: 170,
      _starredAccessor: 'candidateStarreds.starred',
      _associationAccessor: 'engagements.employer.employer',
      _headerClassName: 'sticky-end bg-main bl-1',
      _cellClassName: 'sticky-end bg-white bl-1 d-flex flex-align-right-center',
    }
  ],
  moreMenuOptions: moreMenuOptionsEmployers,
};
