import Core from "../../../../lib/Core";
import React, { Component, Fragment } from "react";

/* Material UI theme   */
import Paper from "material-ui/Paper";

import List from "react-virtualized/dist/commonjs/List";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import InfiniteLoader from "react-virtualized/dist/commonjs/InfiniteLoader";
import {
  CellMeasurerCache,
  CellMeasurer,
} from "react-virtualized/dist/commonjs/CellMeasurer";

/* Custom Styles */
import Engagement from "../../../../lib/Engagement";
import ThumbBar from "./ThumbBar";
import { objectListGroups } from "../../../../lib/Constants";
import Job from "../../../../lib/Job";
import loader from "../../../../assets/images/loader.gif";
import { MLM__HARDCODED_ID, MLM__NO_MODEL_ID } from "../../../../lib/Definition";
import { withTranslation } from "react-i18next";

const _cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 350,
});

class ObjectListPipe extends Component {
  constructor() {

    super(...arguments);

    const HARD_CODED_MODEL = (Core.isOnDev() ? MLM__NO_MODEL_ID : MLM__HARDCODED_ID);

    this.state = {
      allJobs: null,
      engagements: null,
      groups: [],
      countForThumbBarHeading: {},
      annotatorMode: this.props.annotatorMode,
      MLScoreMode: false,
      doneMLScore: false,
      arrayWithScore: [],
      isGroupingComplete: false,
      currentMlModel: HARD_CODED_MODEL,
    };
    this._totalItems = [];
    this._groupBreaker = {};
  }

  componentWillUnmount() { }

  getMySortWeightage = (job) => {
    const { strategyGetLabels, candidate } = this.props;
    let myLabels = strategyGetLabels(candidate, job);
    if (myLabels.includes("Permitted")) {
      return 3;
    } else if (myLabels.includes("Awaiting")) {
      return 2;
    } else if (myLabels.includes("Declined")) {
      return 0;
    } else if (myLabels.includes("BlackListed")) {
      return -1;
    } else {
      return -2;
    }
  };

  getSortWeightabeByMatchType = (job) => {
    return (job.score || {}).match === "submission" ? 3 : 2;
  };

  processMlScoresJobs = (jobs) => {
    const { doneMLScore, currentMlModel } = this.state;
    const { candidate, handlerDoneMLScore } = this.props;
    const jobIds = jobs.map((j) => j.id);

    let params = { candidateId: candidate.id, jobIds, currentMlModel };

    if (!doneMLScore) {
      Job.getMlMatchingScoreBulkCandoMatch(
        { params, apiMethod: "get_jobs_scores" },
        (response) => {
          if (!Array.isArray(response)) {
            return;
          }

          jobs.forEach((job, index) => {
            const score = response.find((item) => item.item_id === job.id);

            if (score) {
              job.mlScore = (Object(score).match || {}).score;
              job.mlScoreObject = Object(score).match;
            }
          });

          handlerDoneMLScore(true);
        },
        (failure) => {
          console.log(failure);
        }
      );
    }
  };

  processMlScoresCandos = (candos) => {
    const { doneMLScore, currentMlModel } = this.state;
    const job = this.props.candidate;
    const { handlerDoneMLScore } = this.props;
    const candoIds = candos.map((c) => c.id);

    let params = { jobId: job.id, candoIds, currentMlModel };

    if (!doneMLScore) {
      Job.getMlMatchingScoreBulkCandoMatch(
        { params, apiMethod: "get_candos_scores" },
        (response) => {
          candos.forEach((cando) => {
            const score = response.find((item) => item.item_id === cando.id);

            if (score) {
              cando.mlScore = (Object(score).match || {}).score;
              cando.mlScoreObject = Object(score).match;
            }
          });

          handlerDoneMLScore(true);
        },
        (failure) => {
          console.log(failure);
        }
      );
    }
  };

  processMLScoreSingle = (params, apiMethod, cb) => {
    const { currentMlModel } = this.state;

    Job.getMlMatchingScoreBulkCandoMatch(
      { params: { ...params, currentMlModel }, apiMethod },
      (response) => {
        cb(response);
      },
      (failure) => {
        console.log(failure);
      }
    );
  };

  processMLScores = (jobs) => {
    const { allJobs, doneMLScore, arrayWithScore } = this.state;
    const { candidate, sourceKey, handlerDoneMLScore } = this.props;
    let params = {};

    if (!doneMLScore && !!candidate && !!allJobs) {
      jobs.forEach((object, index) => {
        params = {
          jobId: sourceKey === "job" ? object.id : candidate.id,
          candidateId: sourceKey === "job" ? candidate.id : object.id,
        };
        let existingWithScore = arrayWithScore.find(
          (obj) => object.id === obj.id
        );
        if (!Object(existingWithScore).id) {
          Job.getMlMatchingScore(
            {
              params: params,
            },
            (response) => {
              object.mlScore = response.match.score;
              arrayWithScore.push(object);

              if (arrayWithScore.length === jobs.length) {
                handlerDoneMLScore();
              }

              this.setState({ arrayWithScore });
            },
            (failure) => {
              console.log(failure);
            }
          );
        }
      });
    }
  };

  sortMatchJobs = (objects) => {
    const { strategyGetLabels, candidate } = this.props;

    const getBucket = (objects) => (type) => {
      const filteredByType = !!type
        ? objects.filter((j) => {
          let myLabels = strategyGetLabels(candidate, j);
          return myLabels.includes(type);
        })
        : objects;

      const permittedPositives = filteredByType.filter(
        (j) => Object(j.mlScoreObject).match === "submission"
      );
      const permittedNegatives = filteredByType.filter(
        (j) => Object(j.mlScoreObject).match !== "submission"
      );

      const permittedPositivesSorted = permittedPositives.sort(
        (a, b) => parseFloat(b.mlScore) - parseFloat(a.mlScore)
      );
      const permittedNegativesSorted = permittedNegatives.sort(
        (a, b) => parseFloat(b.mlScore) - parseFloat(a.mlScore)
      );

      return [...permittedPositivesSorted, ...permittedNegativesSorted];
    };

    const getBucketWithObjects = getBucket(objects);

    const knownGroupsJobs = [
      ...getBucketWithObjects("Permitted"),
      ...getBucketWithObjects("Awaiting"),
      ...getBucketWithObjects("Declined"),
      ...getBucketWithObjects("BlackListed"),
    ];

    const knownGroupsObjectIds = knownGroupsJobs.map((j) => j.id);
    const unknownGroupsObjects = objects.filter(
      (o) => !knownGroupsObjectIds.includes(o.id)
    );

    return [...knownGroupsJobs, ...getBucket(unknownGroupsObjects)()];
  };

  makeGroupings = () => {
    this.setState({ isGroupingComplete: false });
    setTimeout(() => {
      let { allJobs, engagements, doneMLScore, MLScoreMode } = this.state;
      const {
        skippedJobs,
        candidate,
        params,
        sourceKey,
        shouldShowBlacklisted,
        strategyGetBlackListed,
      } = this.props;
      let objectIdsWithEng = [];
      let jobIdsWithSkip = [];
      let jobIdsInPuDownCand = [];
      let joinOfEngWithSkip = [];
      let selectedJobByQueryParam = {};
      let countForThumbBarHeading = {};
      console.log({ sourceKey });
      if (!allJobs) {
        allJobs = [];
      }
      /*
       * This code is very confusing.
       * Sometimes the primary object is a candidate then 'candidate' is a candidate and alljobs is a list of jobs.  (Candidate match page)
       * Sometimes the primary object is a job then 'candidate' is a job and alljobs is a list of candidates. (Job match page)
       * Now, we have 2 isJobBlackListed routines, one for candidates and one for jobs.  The code is the same but the order of attributes
       * different.  We need to fix this to be more readable.
       */

      if (!shouldShowBlacklisted && !!candidate && allJobs) {
        if (sourceKey === "candidate") {
          allJobs = allJobs.filter(
            (job) => !strategyGetBlackListed(candidate, job)
          ); // Order of arguments are the same for this line and next
        } else {
          allJobs = allJobs.filter(
            (job) => !strategyGetBlackListed(candidate, job)
          ); // because the different routines swap the order.
        }
      }

      if (!Array.isArray(engagements) || !Array.isArray(allJobs)) {
        return;
      }

      if (Array.isArray(engagements)) {
        objectIdsWithEng = engagements.map((eng) => eng[`${sourceKey}Id`]);
      }

      if (skippedJobs.length) {
        jobIdsWithSkip = skippedJobs.map((skip) => skip.id);
      }

      if (!!candidate && Object(candidate.putDownJobs).length)
        jobIdsInPuDownCand = candidate.putDownJobs;

      if (!!Object(params).selected) {
        selectedJobByQueryParam = allJobs.find(
          (job) => job.id === params.selected
        );
      }

      joinOfEngWithSkip = [
        ...objectIdsWithEng,
        ...jobIdsWithSkip,
        ...jobIdsInPuDownCand,
        Object(selectedJobByQueryParam).id,
      ];

      let toMatchJobs = this.sortMatchJobs(
        allJobs.filter((job) => !joinOfEngWithSkip.includes(job.id))
      );

      let engToSubmit = [],
        engToProcess = [],
        engInProgress = [],
        engInActive = [],
        engOldNoMatch = [],
        jobNoMatch = [];
      jobNoMatch =
        !!candidate && !!candidate.putDownJobs.length
          ? allJobs.filter((job) => candidate.putDownJobs.includes(job.id))
          : [];
      console.log(`hitting ml api for ${toMatchJobs.length} jobs/candidates`);
      if (MLScoreMode) {
        if (sourceKey === "candidate") {
          this.processMlScoresCandos(toMatchJobs);
        } else {
          this.processMlScoresJobs(toMatchJobs);
        }
        if (!doneMLScore) {
          return;
        }
      } else {
        allJobs.forEach((job) => {
          job.mlScore = undefined;
          job.mlScoreObject = {};
        });
      }

      engagements.forEach((eng) => {
        if (/open/i.test(eng.state) && /submission/i.test(eng.stage)) {
          engToSubmit.push(eng);
        } else if (
          /open/i.test(eng.state) &&
          /confirmation/i.test(eng.stage) &&
          /W - 10/i.test(eng.status)
        ) {
          engToProcess.push(eng);
        } else if (
          (/open/i.test(eng.state) &&
            /confirmation/i.test(eng.stage) &&
            !/W - 10/i.test(eng.status)) ||
          (/open/i.test(eng.state) &&
            Engagement.stageOrder.indexOf("Review") <=
            Engagement.stageOrder.indexOf(eng.stage))
        ) {
          engInProgress.push(eng);
        } else if (
          (/closed/i.test(eng.state) &&
            Engagement.stageOrder.indexOf("Submission") <=
            Engagement.stageOrder.indexOf(eng.stage)) ||
          (/closed/i.test(eng.state) &&
            /confirmation/i.test(eng.stage) &&
            eng.rejectionReason !== "10x10 - No Match")
        ) {
          engInActive.push(eng);
        } else if (
          /closed/i.test(eng.state) &&
          /confirmation/i.test(eng.stage) &&
          eng.rejectionReason === "10x10 - No Match"
        ) {
          engOldNoMatch.push(eng);
        }
      });
      // /open/i.test(eng.state) && /submission/i.test(eng.stage))

      let type = "job";
      let selectedEng = !!Object(selectedJobByQueryParam).id
        ? selectedJobByQueryParam
        : null;
      let mainlLabel = "jobs";

      if (objectIdsWithEng.includes(Object(selectedJobByQueryParam).id)) {
        type = "engagement";
        selectedEng = engagements.find(
          (eng) => eng[`${sourceKey}Id`] === selectedJobByQueryParam.id
        );
        mainlLabel = "engagements";
      }

      let groups = [
        {
          label: objectListGroups.selected.label,
          [mainlLabel]: !!selectedEng ? [selectedEng] : [],
          items: !!selectedEng ? [selectedEng] : [],
          type: type,
          key: objectListGroups.selected.key,
        },
        {
          label: objectListGroups.toMatch.label,
          items: toMatchJobs,
          type: "job",
          key: objectListGroups.toMatch.key,
          // Jobs in black list.  Label = blacklist
          // P2 - Jobs has engagement with this employer.  label = "other role"
          // P2 sort, no label first, then other role, then blacklist
        },
        // {
        //     label: 'TO SUBMIT',
        //     items: engToSubmit,
        //     type: 'engagement',
        //     key: 'toSubmit',
        // },
        {
          label: objectListGroups.toProcess.label,
          items: [...engToSubmit, ...engToProcess],
          type: "engagement", // && status == W-10* (10x10*, 10by10*)
          key: objectListGroups.toProcess.key,
        },
        {
          label: objectListGroups.jobInProgress.label,
          items: engInProgress,
          type: "engagement",
          key: objectListGroups.jobInProgress.key,
          // (state == open && stage == confirmation && (status != W-10*))
          // OR (state == open && stage >= Review)
          // P2 - sort by stage,
        },
        {
          label: objectListGroups.inActive.label,
          items: engInActive,
          type: "engagement",
          key: objectListGroups.inActive.key,
          // OR state == closed && stage >= submission (inlcudes review, screen, onsite....)
          // OR state == closed && stage == confirmation && rejectionReason != "10x10 - No Match"
          // P2 - sort by stage,
        },
        {
          label: objectListGroups.oldNoMatch.label,
          items: engOldNoMatch,
          type: "engagement",
          key: objectListGroups.oldNoMatch.key,
          // and rejectionReason == "10x10 - No Match"
        },
        {
          label: objectListGroups.noMatch.label,
          items: jobNoMatch,
          type: "job",
          key: objectListGroups.noMatch.key,
          // job was thumbed down without creating an engegement SHOULD GO TO SAME NOT MATCH GROUP
        },
      ];

      let nonGrouped = groups
        .filter((g) => g.type === "engagement")
        .map((group) => group.items)
        .flat()
        .map((eng) => eng.id);

      const debugged = engagements.filter(
        (eng) => !nonGrouped.includes(eng.id)
      );

      groups.push({
        label: objectListGroups.debug.label,
        // ANY LEFT OVER ENGAGEMENTS.  SHOULD NOT HAVE ANY.  IF THERE IS ANY, PUT IN THIS GROUP
        // TO_REMOVE_2019_OCT leave this comment
        engagements: debugged,
        items: debugged,
        type: "engagement",
      });
      // Thumb bar heading Data
      countForThumbBarHeading[objectListGroups.toMatch.key] = {
        label: objectListGroups.toMatch.heading,
        key: objectListGroups.toMatch.key,
        count: toMatchJobs.length,
      };
      countForThumbBarHeading[objectListGroups.toProcess.key] = {
        label: objectListGroups.toProcess.heading,
        key: objectListGroups.toProcess.key,
        count: engToSubmit.length + engToProcess.length,
      };
      countForThumbBarHeading[objectListGroups.jobInProgress.key] = {
        label: objectListGroups.jobInProgress.heading,
        key: objectListGroups.jobInProgress.key,
        count: engInProgress.length,
      };
      countForThumbBarHeading[objectListGroups.inActive.key] = {
        label: objectListGroups.inActive.heading,
        key: objectListGroups.inActive.key,
        count: engInActive.length,
      };
      countForThumbBarHeading[objectListGroups.oldNoMatch.key] = {
        label: objectListGroups.oldNoMatch.heading,
        key: objectListGroups.oldNoMatch.key,
        count: `${engOldNoMatch.length}`,
      };
      countForThumbBarHeading[objectListGroups.noMatch.key] = {
        label: objectListGroups.noMatch.heading,
        key: objectListGroups.noMatch.key,
        count: `${jobNoMatch.length}`,
      };

      // One dimensional items from groups
      const selectedByParam = !!selectedEng ? [selectedEng] : [];
      this._totalItems = [
        ...selectedByParam,
        ...toMatchJobs,
        ...engToSubmit,
        ...engToProcess,
        ...engInProgress,
        ...engInActive,
        ...engOldNoMatch,
        ...jobNoMatch,
        ...debugged,
      ];
      // Data contains keys and indices
      let counter = 0;
      groups
        .filter((group) => !!group.items.length > 0)
        .forEach((group) => {
          this._groupBreaker[group.key] = counter;
          counter += group.items.length;
        });
      if (!!engagements) {
        this.setState(
          { groups, countForThumbBarHeading, isGroupingComplete: true },
          () => {
            this.autoSelectDefaultJob();
          }
        );
      }
    });
  };

  autoSelectDefaultJob = () => {
    const { groups, allJobs } = this.state;
    const { handlerSelectJob, params, selectedJob } = this.props;
    const jobsGroup = groups.find(
      (g) => g.type === "job" && Object(g).key === "toMatch"
    );
    const firstJob = jobsGroup.items.slice(0, 1).pop();

    const selectedJobFromParams =
      (allJobs || []).find((j) => j.id === Object(params).selected) ||
      firstJob ||
      selectedJob;

    //
    // let selectedJobCard = document.getElementById(`job-${Object(selectedJobFromParams).id}`);
    // if (selectedJobCard !== null) {
    //     selectedJobCard.scrollIntoView();
    // }

    !!handlerSelectJob && handlerSelectJob(selectedJobFromParams);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.jobs !== this.props.jobs ||
      prevProps.jobs.length !== this.props.jobs.length
    ) {
      this.setState(
        {
          allJobs: this.props.jobs,
        },
        () => {
          this.makeGroupings();
          this._resizeAll();
        }
      );
    }

    if (
      prevProps.engagements !== this.props.engagements ||
      prevProps.engagements.length !== this.props.engagements.length
    ) {
      this.setState(
        {
          engagements: this.props.engagements,
        },
        () => {
          this.makeGroupings();
          this._resizeAll();
        }
      );
    }

    if (prevProps.skippedJobs.length !== this.props.skippedJobs.length) {
      this.makeGroupings();
      this._resizeAll();
    }

    if (prevProps.candidate !== this.props.candidate) {
      this.makeGroupings();
    }

    if (
      Object(prevProps.selectedJob).id !== Object(this.props.selectedJob).id
    ) {
      this.setState({ selectedJob: this.props.selectedJob });
    }

    if (prevProps.doneMLScore !== this.props.doneMLScore) {
      this.setState({ doneMLScore: this.props.doneMLScore }, () => {
        this.makeGroupings();
      });
    }
  }

  handlerAnnotatorMode = (annotatorMode) => {
    const { updateHandlerAnnotatorMode } = this.props;

    this.setState({ annotatorMode: annotatorMode }, () => {
      !!updateHandlerAnnotatorMode && updateHandlerAnnotatorMode(annotatorMode);
    });
  };

  handlerMLScoreMode = (mode) => {
    this.setState(
      { MLScoreMode: mode, doneMLScore: !mode, isGroupingComplete: !mode },
      () => {
        if (!mode) {
          this.props.handlerDoneMLScore(false);
        }
        this.makeGroupings();
      }
    );
  };

  handlerMIScoreObjects = (object) => {
    const { allJobs } = this.state;

    const oldObjectState = object;
    const oldObjectStateIndex = allJobs.findIndex((j) => j.id === object.id);
    const withoutThisObject = allJobs.filter((j) => j.id !== object.id);

    const updateOldJobState = {
      ...oldObjectState,
    };

    let updatedObjects = [...withoutThisObject];

    updatedObjects.splice(oldObjectStateIndex, 0, updateOldJobState);

    const allObjects = [...updatedObjects];

    this.setState({
      allJobs: allObjects,
    });
  };

  setHeadingAtSpecificIndex = (index) => {
    let label = null;
    Object.keys(this._groupBreaker).forEach((group) => {
      if (this._groupBreaker[group] === index) {
        label = !!objectListGroups[group]
          ? objectListGroups[group].label
          : null;
      }
    });
    return label;
  };

  isRowLoaded = ({ index }) => {
    return !!this._totalItems[index];
  };

  fetchFeed = ({ startIndex, stopIndex }) => {
    return this._totalItems;
  };

  _setListRef = (ref) => {
    this._list = ref;
  };

  _resizeIndex = (index) => () => {
    _cache.clear(index, 0);
    if (this._list) {
      this._list.recomputeRowHeights(index);
    }
  };

  _resizeAll = () => {
    _cache.clearAll();
    if (this._list) {
      this._list.recomputeRowHeights();
    }
  };

  _scrollToIndex = (key) => {
    const { annotatorMode } = this.state;
    if (!!Object.keys(this._groupBreaker).length && !annotatorMode) {
      const getIndex = !!this._groupBreaker.hasOwnProperty(key)
        ? this._groupBreaker[key]
        : null;
      if (this._list && getIndex !== null) {
        this._list.scrollToRow(getIndex);
      }
    }
  };

  handlerMlModelChangeDropdown = (value) => {
    this.setState({ currentMlModel: value });
  };
  render() {
    let {
      annotatorMode,
      MLScoreMode,
      countForThumbBarHeading,
      doneMLScore,
      isGroupingComplete,
      currentMlModel,
    } = this.state;

    // const showObjectList = !!doneMLScore && !!groups.length;

    let {
      selectedJob,
      handlerSelectJob,
      candidate,
      handlerOnSelectBox,
      handlerMatchJob,
      createDisagreement,
      createSingleEngagement,
      pushEntityToCandidate,
      createSingleEngagementWithDis,
      isJobEngageable,
      afterUpdateEngagement,
      multiSelectedJobs,
      engagements,
      handlerJobMatch,
      hasJobEngageable,
      handlerSkippedJob,
      objectListCard,
      objectListCardEng,
      sourceKey,
      strategyGetConstraints,
      strategyGetBlackListed,
      fetchObjectList,
      isFetchingObjectList,
    } = this.props;
    const handlerMIScoreObjects = this.handlerMIScoreObjects;
    console.log({
      isFetchingObjectList,
      isGroupingComplete,
      doneMLScore,
      MLScoreMode,
    });

    // console.debug({ candidate, selectedJob });

    const { t } = this.props;
    return (
      <Fragment>
        {!!candidate && (
          <ThumbBar
            candidate={candidate}
            fetchObjectList={fetchObjectList}
            job={selectedJob}
            engagements={engagements}
            callbackJobMatch={handlerJobMatch}
            handlerSelectJob={handlerSelectJob}
            handlerSkippedJob={handlerSkippedJob}
            multiSelectedJobs={multiSelectedJobs}
            handlerMatchJob={handlerMatchJob}
            createSingleEngagement={createSingleEngagement}
            createSingleEngagementWithDis={createSingleEngagementWithDis}
            pushEntityToCandidate={pushEntityToCandidate}
            createDisagreement={createDisagreement}
            hasJobEngageable={hasJobEngageable}
            sourceKey={sourceKey}
            handlerAnnotatorMode={this.handlerAnnotatorMode}
            isAnnotatorSelected={annotatorMode}
            mlScoreMode={MLScoreMode}
            handlerMLScoreMode={this.handlerMLScoreMode}
            countForThumbBarHeading={countForThumbBarHeading}
            resizeAllIndices={this._resizeAll}
            scrollToIndex={this._scrollToIndex}
            strategyGetConstraints={strategyGetConstraints}
            strategyGetBlackListed={strategyGetBlackListed}
            currentMlModel={currentMlModel}
            handlerMlModelChangeDropdown={this.handlerMlModelChangeDropdown}
          />
        )}
        {/* <TechnicalStack/> */}
        {!isFetchingObjectList &&
          isGroupingComplete &&
          (!!doneMLScore || !MLScoreMode) ? (
          !!this._totalItems.length ? (
            <Paper
              style={{
                height: "calc(100vh - 330px)",
                backgroundColor: "none",
                //boxShadow: 'rgba(0, 0, 0, 0.12) 12px 0px 2px, rgba(0, 0, 0, 0.12) 0px 1px 4px'
              }}
            >
              <div className="virtualized-list-body">
                <InfiniteLoader
                  isRowLoaded={this.isRowLoaded}
                  loadMoreRows={this.fetchFeed}
                  rowCount={this._totalItems.length}
                  ref={(ref) => (this.infiniteLoaderRef = ref)}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                      {({ width, height }) => {
                        return (
                          <List
                            rowCount={this._totalItems.length} //
                            width={width}
                            height={height}
                            rowHeight={_cache.rowHeight}
                            deferredMeasurementCache={_cache}
                            onRowsRendered={onRowsRendered}
                            ref={this._setListRef}
                            rowRenderer={({ style, key, index, parent }) => {
                              return (
                                <CellMeasurer
                                  cache={_cache}
                                  columnIndex={0}
                                  key={key}
                                  rowIndex={index}
                                  parent={parent}
                                  rowCount={this._totalItems.length} //
                                >
                                  {({ measure }) => {
                                    const currentItem = this._totalItems[index];
                                    let engagement = null;
                                    let job = null;
                                    let type = "job";
                                    const labelHeading =
                                      this.setHeadingAtSpecificIndex(index);

                                    if (!!currentItem.modelName) {
                                      engagement = currentItem;
                                      job = engagement.job;
                                      let isSelected =
                                        !!selectedJob &&
                                        selectedJob.id ===
                                        currentItem[sourceKey].id;
                                      type = "engagement";
                                      return (
                                        <Fragment>
                                          {objectListCardEng({
                                            // entities
                                            job,
                                            engagement,
                                            type,
                                            candidate,
                                            isSelected,
                                            annotatorMode,
                                            MLScoreMode,
                                            // ref funcitons
                                            handlerSelectJob,
                                            handlerOnSelectBox,
                                            multiSelectedJobs,
                                            handlerMatchJob,
                                            createDisagreement,
                                            createSingleEngagement,
                                            createSingleEngagementWithDis,
                                            pushEntityToCandidate,
                                            isJobEngageable,
                                            afterUpdateEngagement,
                                            handlerMIScoreObjects,
                                            // virtualized keys
                                            key,
                                            index,
                                            style,
                                            measure,
                                            resizeIndex: this._resizeIndex(index),
                                            resizeAllIndices: this._resizeAll,
                                            //heading
                                            label: labelHeading,
                                            processMLScoreSingle:
                                              this.processMLScoreSingle,
                                          })}
                                        </Fragment>
                                      );
                                    } else {
                                      job = currentItem;
                                      let isSelected =
                                        !!selectedJob &&
                                        selectedJob.id === currentItem.id;
                                      return (
                                        <Fragment>
                                          {objectListCard({
                                            // entities
                                            job,
                                            engagement,
                                            type,
                                            candidate,
                                            isSelected,
                                            annotatorMode,
                                            MLScoreMode,
                                            // ref funcitons
                                            handlerSelectJob,
                                            handlerOnSelectBox,
                                            multiSelectedJobs,
                                            handlerMatchJob,
                                            createDisagreement,
                                            createSingleEngagement,
                                            createSingleEngagementWithDis,
                                            pushEntityToCandidate,
                                            isJobEngageable,
                                            afterUpdateEngagement,
                                            handlerMIScoreObjects,
                                            // virtualized keys
                                            key,
                                            index,
                                            style,
                                            measure,
                                            resizeIndex: this._resizeIndex(index),
                                            resizeAllIndices: this._resizeAll,
                                            //heading
                                            label: labelHeading,
                                            processMLScoreSingle:
                                              this.processMLScoreSingle,
                                          })}
                                        </Fragment>
                                      );
                                    }
                                  }}
                                </CellMeasurer>
                              );
                            }}
                            overscanRowCount={3}
                            className="virtualized-list"
                          />
                        );
                      }}
                    </AutoSizer>
                  )}
                </InfiniteLoader>
              </div>
            </Paper>
          )
            : (
              <p className="match-new job-attributes" style={{ textAlign: "center" }}>
                {t('matchPages.noItemsToShow.label')}
              </p>
            )
        ) : (
          <div>
            Loading&nbsp;
            <img alt="loading..." height="14" src={loader} />
          </div>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(ObjectListPipe);
