import React, {Component} from 'react';
import {IconButton, Table, TableBody, TableRow, TableRowColumn} from "material-ui";
import Core from "../../../lib/Core";
import downloadFile from "../../../lib/tools/downloadFile";
import SovrenData from "../../../lib/SovrenData";
import formatURL from "../../../lib/tools/formatURL";


export default class ResumeLinks extends Component {
  constructor() {
    super(...arguments);
    this.getSovrenRecords();
    this.state = {
      sovrenData: []
    }
  }

  getUniqIdForResumes = (resumes) => {
    return (resumes||[]).map(resume=> resume.timestamp).join('-');
  };

  getSovrenRecords = () => {
    const {resumes} = this.props;

    if(!resumes.length){
      return;
    }
    const timestamps = resumes.map(res => res.timestamp);

    if(timestamps.length) {
      SovrenData.get({where: {resumeTimestamp: {inq: timestamps}}, fields: ['resumeTimestamp']},(sovrenData)=>{
        this.setState({sovrenData});
      })
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { resumes } = this.props;

    const newEntryAddedToSovrenData = (prevProps.hasNewEntry !== this.props.hasNewEntry && this.props.hasNewEntry);
    const onlyNewResumeAddedOrLoaded = (resumes.length && this.getUniqIdForResumes(prevProps.resumes) !== this.getUniqIdForResumes(resumes))

    if(newEntryAddedToSovrenData || onlyNewResumeAddedOrLoaded){
      this.getSovrenRecords();
    }
  };

  hasSovrenRecord = (resume) => {
    const {sovrenData} = this.state;
    return !!sovrenData.find(data=> data.resumeTimestamp === resume.timestamp);
  };

  render() {
    const { resumes, onError, onDelete, onReparse } = this.props;

    return <Table style={{ tableLayout: "auto" }}>
      <TableBody displayRowCheckbox={false}>
        {!!resumes.length &&
        resumes.map((resume, index) => (
          <TableRow key={Core.getKey()} selectable={false}>
            <TableRowColumn
              className="v-aligns-middle"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <IconButton
                onClick={ev =>
                  downloadFile({
                    url: resume.url,
                    mimeType: "application/pdf",
                    onError: error => {
                      onError(error);
                    }
                  })
                }
              >
                <i className="material-icons">file_download</i>
              </IconButton>
              {Core.isAdmin() && this.hasSovrenRecord(resume) && <IconButton
                onClick={ev =>
                  onReparse(resume.timestamp)
                }
              >
                <i className="material-icons">call_received</i>
              </IconButton>}

              <IconButton
                className="icon-button"
                children={<i className="material-icons">close</i>}
                onClick={ev => {
                  onDelete(ev, resume, index);
                }}
              />

              <a href={formatURL(resume.url)} target="_blank" rel="noreferrer">
                {decodeURIComponent(
                  String(resume.url)
                    .split("/")
                    .pop()
                )}
              </a>
            </TableRowColumn>
            {/** * /
             <TableRowColumn
             onClick={this.handleToggle}
             style={{ paddingLeft: 0, paddingRight: 0 }}
             >
             {moment(resume.createAt).format("LLLL")}
             </TableRowColumn>
             /** */}

          </TableRow>
        ))}
      </TableBody>

    </Table>
  }
}