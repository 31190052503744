import Core from "../../lib/Core";

export const reactTableMoreMenuOptions = [
  {
    label: 'Candidates',
    to: () => `/v3/candidates`
  },
  {
    label: 'Jobs',
    to: () => `/v3/jobs`
  },
  {
    acl: job => Core.isAdminOrCoordinator(),
    label: 'Employers',
    to: () => `/v3/employers`
  },
  {
    acl: job => Core.isAdminOrCoordinator(),
    label: 'Accounts',
    to: () => `/v3/accounts`
  },
  {
    acl: job => Core.isAdminOrCoordinator(),
    label: 'Engagements',
    to: () => `/v3/engagements`
  },
  {
    acl: job => Core.isAdminOrCoordinator(),
    label: 'Matcher',
    to: () => `/v3/matcher`
  },
  {
    label: 'Jobs(v1-custom-table)',
    to: () => `/v3/v1/jobs`
  },
  {
    acl: job => Core.isAdminOrCoordinator(),
    label: 'Engagements(v1-custom-table)',
    to: () => `/v3/v1/engagements`
  },
];
