import React from 'react';
import { matchSorter } from 'match-sorter';
import {
  usePagination,
  useTable,
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useBlockLayout,
  useResizeColumns,
  useRowSelect,
} from 'react-table';
import Core from '../../lib/Core';
import Engagement from '../../lib/Engagement';

import CssBaseline from '@mui/material/CssBaseline';
import MaUTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Paper, TableFooter, TablePagination } from '@mui/material';
import TablePaginationActions from '../Home/TablePaginationActions';

JSON.safeStringify = (obj, indent = 2) => {
  let cache = [];
  const retVal = JSON.stringify(
    obj,
    (key, value) =>
      typeof value === "object" && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value,
    indent
  );
  cache = null;
  return retVal;
};

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{' '}
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

/** @todo following method are not in use yet */
/** * /
// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}
/** */

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

function Table({
  columns, data
}) {

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({

      // from filter
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,

      // from column resize
      minWidth: 160,
      width: 240,
      maxWidth: 640,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    // from pagination
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,

    /** @todo ignored by the moment */
    // pageOptions,
    // nextPage,
    // previousPage,

    // from filter
    rows,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,

    // from column resize
    /** @todo ignored by the moment */
    // resetResizing,

    selectedFlatRows,

    state: { pageIndex, pageSize, selectedRowIds },

    allColumns,
    getToggleHideAllColumnsProps,

  } = useTable(
    {
      columns,
      data,

      // form pagination
      initialState: { pageIndex: 0 },

      // from filter
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,

    // from column resize
    useBlockLayout,
    useResizeColumns,

    usePagination,

    useRowSelect,

    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          disableResizing: true,
          width: 48,
          minWidth: 48,
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }

  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  /** @todo ignored by the moment */
  // const firstPageRows = rows.slice(0, 10);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };
  // Render the UI for your table
  return (
    <>
      <div>
        <div>
          <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle
          All
        </div>
        {allColumns.map(column => (
          <div key={column.id}>
            <label>
              <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
              {column.id}
            </label>
          </div>
        ))}
        <br />
      </div>
      <MaUTable {...getTableProps()} style={{ height: '100%', width: '100%' }}>
        <TableHead style={{ position: 'sticky', top: 0, zIndex: 2, backgroundColor: '#eee' }}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps()}

                  // this is required for resize 
                  // review index.css .tanstack-resize .th
                  className="th"

                >

                  {/*!!console.debug(column);*/}

                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                  </div>

                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>

                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>

                  {!column.disableResizing && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${column.isResizing ? 'isResizing' : ''
                        }`}
                    />
                  )}

                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableRow>
            <TableCell
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter className="w-100" style={{ position: 'sticky', bottom: 0, backgroundColor: '#eee' }} >
          <TableRow className="w-100">
            <TablePagination
              rowsPerPageOptions={[
                5,
                10,
                25,
                50,
                { label: 'All', value: data.length },
              ]}
              colSpan={visibleColumns.length}
              count={rows.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              style={{ display: 'inline-flex', position: 'sticky', left: 0 }}
            />
          </TableRow>
        </TableFooter>
      </MaUTable>
      <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
            },
            null,
            2
          )}
        </code>
      </pre>
      <div>
        <pre>
          <code>{JSON.stringify(state.filters, null, 2)}</code>
        </pre>
      </div>
      <pre>
        <code>
          {JSON.safeStringify(
            {
              selectedRowIds: selectedRowIds,
              'selectedFlatRows[].original': selectedFlatRows.map(
                d => d.original.id
              ),
            },
            2
          )}
        </code>
      </pre>
    </>
  );
}

function TanstackPoc() {
  const [engagements, setEngagements] = React.useState();
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const include = [
      {
        relation: 'engagementStarreds',
        scope: {
          where: {
            accountId: Core.getUserId(),
          },
        },
      },
      {
        relation: 'candidate',
        scope: {
          fields: [
            'id',
            'accountId',
            'firstName',
            'lastName',
            'introduced',
            'platformRating',
            'email',
            'phone',
            'resumes',
            'resumeTxtUrl',
            'gitHubURL',
            'linkedInURL',
          ],
          include: [
            {
              relation: 'account',
              scope: {
                fields: [
                  'firstName',
                  'lastName',
                  'companyName',
                  'email',
                  'role',
                ],
              },
            },
          ],
        },
      },
      {
        relation: 'job',
        scope: {
          fields: [
            'jobTitle',
            'employerId',
            'id',
            'role',
            'email',
            'emailsList',
            'addressCity',
            'jobDescription',
          ],
          include: [
            {
              relation: 'employer',
              scope: {
                fields: [
                  'name',
                  'proxyName',
                  'email',
                  'url',
                  'totalFunding',
                  'stage',
                  'technicalSkills',
                  'employeeCount',
                  'crunchBaseUrl',
                  'additionalUrl',
                  'tagline',
                  'product',
                  'teamCount',
                  'expectedResumeResponseTime',
                  'expectedSchedulingResponseTime',
                  'expectedScreenResponseTime',
                  'expectedOnsiteResponseTime',
                ],
              },
            },
          ],
        },
      },
      'actionOwner',
    ];
    if (!engagements) {
      Engagement.getWhere(
        { state: 'Open' },
        (response) =>
          (setEngagements(response), { ...include }) &
          setData(response.slice(0, 100))
      );
      document.querySelector('#root').style.height = '100%';
      document.querySelector('.App').style.height = '100%';
      document.querySelector('#root').style.width = '100%';
      document.querySelector('.App').style.width = '100%';
    }
  }, [engagements, data]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Recruiter',
        columns: [
          {
            Header: 'First Name',
            accessor: 'recruiter.firstName',
            filter: 'fuzzyText',
          },
          {
            Header: 'Last Name',
            accessor: 'recruiter.lastName',
          },
        ],
      },
      {
        Header: 'Employer',
        columns: [
          {
            Header: 'Name',
            accessor: 'employer._name',
          },
        ],
      },
      {
        Header: 'Candidate',
        columns: [
          {
            Header: 'First Name',
            accessor: 'candidate.firstName',
          },
          {
            Header: 'Last Name',
            accessor: 'candidate.lastName',
          },
        ],
      },
      {
        Header: 'Job',
        columns: [
          {
            Header: 'Title',
            accessor: 'job.jobTitle',
          },
          {
            Header: 'Role',
            accessor: 'job._roles',
          },
          {
            Header: 'Min Salary',
            accessor: 'job.salaryMin',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
        ],
      },
      {
        Header: 'Info',
        columns: [
          {
            Header: 'Stage',
            accessor: 'stage',
          },
          {
            Header: 'Status',
            accessor: 'status',
          },
          {
            Header: 'State',
            accessor: 'state',
          },
        ],
      },
      {
        id: 'actions', // It needs an ID
        disableResizing: true,
        // Make an expander cell
        Header: () => 'Actions', // No header
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span>
            {!!console.debug(row)}
            <Button onClick={ev => alert(`Eng(${row.original.id})`)}>
              <div>
                Some action<br />
                <small>(ex. open a drawer)</small>
              </div>
            </Button>
          </span>
        ),
      },
    ],
    []
  );

  // const data = React.useMemo(() => makeData(100000), []);
  console.debug('engagements', engagements);
  return (
    <>
      <CssBaseline />
      <Paper className="p-0 tanstack-resize" style={{ overflowX: 'auto', height: '100%', width: '100%' }}>
        <Table
          columns={columns}
          data={engagements || []}
        />
      </Paper>
    </>
  );
}

export default TanstackPoc;
