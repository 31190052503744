import React, { Component } from 'react';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

import Core from '../../../lib/Core';
import '../../Dialogs/Modal.css';

class ModalReminder extends Component {
	constructor() {
		super(...arguments);
		this.parent = this.props.parent;
		this.state = { open: false };
	};
	open = model => this.setState({ open: true });
	submit = (id) => {
		this.close();
		Core.deleteAccount(
			this.parent.state.id,
			response => this.parent.parent.parent.reloadData()
		);
	};
	close = ev => this.setState({ open: false });
	render() {
		const actions = [
			<FlatButton
				label="Cancel"
				primary={true}
				onClick={this.close}
			/>,
			<FlatButton
				label="Delete"
				primary={true}
				onClick={this.submit}
			/>,
		];
		return (
			<Dialog
				title="Delete Account?"
				actions={actions}
				modal={true}
				contentStyle={{ width: 400 }}
				open={this.state.open}
			>{"This action can't be undone."}
			</Dialog>
		);
	}
}

export default ModalReminder;
