import React, {Component, Fragment} from "react";

import Chip from './ElementsComparisonHaves/Chip';
import Complex from './ElementsComparisonHaves/Complex';
import ChipTag from './ElementsComparisonHaves/ChipTag';
import Text from './ElementsComparisonHaves/Text';

class ComparisonHaves extends Component {

    getElementCategory = () => {
        const {element, toMatchWith,candidateResume} = this.props;

        if(!!element.args && !!element.args.length){
            return Complex.result(element, toMatchWith,candidateResume);
        }else if(element.type === 'chip'||element.type === 'chipGroup'){
            return Chip.result(element, toMatchWith);
        }else if(element.type === 'chipTag'){
            return ChipTag.resultWithDecision(element, candidateResume);
        }else if(element.type === 'text'){
            return Text.result();
        }

        return null;
    }

    render() {
        return <Fragment>
            &nbsp;&nbsp;{this.getElementCategory()}
        </Fragment>
    }
}

export default ComparisonHaves;
