import React from "react";
import { Route, Switch } from "react-router-dom";
import AccountEdit from "../components/Accounts/Edit/AccountEdit";
import Announcements from "../components/Announcements/Announcements";
import AnnouncementCreate from "../components/Announcements/AnnouncementsCreate";
import CandidateEdit from "../components/Candidates/Edit/CandidateEdit";
import CandidateMatch from "../components/Candidates/Match/CandidateMatch";
import CandidateMatchNew from "../components/Candidates/MatchNew/CandidateMatchNew";
import CandidateResumeSubmission from "../components/Candidates/ResumeSubmission/CandidateResumeSubmission";
import EmployerEdit from "../components/Employers/Edit/EmployerEdit";
import MainV3 from "../components/Home/Main";
import MainV1 from "../components/HomeV1/Main";
import MainV2 from "../components/HomeV2/Main";
import JobEdit from "../components/Jobs/Edit/JobEdit";
import ManageJobs from "../components/Jobs/Manage/ManageJobs";
import JobMatch from "../components/Jobs/Match/JobMatch";
import JobMatchNew from "../components/Jobs/MatchNew/JobMatchNew";
import TanstackPoc from "../components/Lab/TanstackPoc";
import Login from "../components/Login/Login";
import TagManagement from "../components/Management/Tags";
import Settings from "../components/Settings/Settings";
import { NoMatch } from "./NoMatch.routes";

export const AdminUser = (props) => {
  return (
    <Switch>

      {/** COMMON SECTIONS */}
      <Route exact path="/" component={Login} />
      <Route path="/login" component={Login} />
      <Route path="/forgot/password" component={Login} />
      <Route path="/reset/password/:token" component={Login} />

      {/** COMMON SECTIONS V1 */}
      <Route path="/candidate/create" component={CandidateEdit} />
      <Route path="/candidate/edit-section/:id" component={CandidateEdit} />
      <Route path="/candidate/edit/:id" component={CandidateEdit} />
      <Route path="/candidate/view/:id" component={CandidateEdit} />
      <Route path="/candidate/match/:id" component={CandidateMatch} />
      <Route path="/candidate/matchNew/:id" component={CandidateMatchNew} />
      <Route path="/settings" component={Settings} />
      <Route path="/announcements" component={Announcements} />
      <Route path="/home" component={MainV1} />
      <Route path="/candidates" component={MainV1} />
      <Route path="/jobs" component={MainV1} />
      <Route path="/job/view/:id" component={MainV1} />
      <Route path="/recruiter" component={MainV1} />

      {/** COMMON SECTIONS V3 */}
      <Route path="/v3/candidate/create" component={CandidateEdit} />
      <Route path="/v3/candidate/edit-section/:id" component={CandidateEdit} />
      <Route path="/v3/candidate/edit/:id" component={CandidateEdit} />
      <Route path="/v3/candidate/view/:id" component={CandidateEdit} />
      <Route path="/v3/candidate/match/:id" component={CandidateMatch} />
      <Route path="/v3/candidate/matchNew/:id" component={CandidateMatchNew} />
      <Route path="/v3/settings" component={Settings} />
      <Route path="/v3/announcements" component={Announcements} />
      <Route path="/v3/home" component={MainV3} />
      <Route path="/v3/candidates" component={MainV3} />
      <Route path="/v3/jobs" component={MainV3} />
      <Route path="/v3/job/view/:id" component={MainV3} />
      <Route path="/v3/v1/jobs" component={MainV3} />

      {/** ADMIN SECTIONS V1 */}
      <Route path="/account/create" component={AccountEdit} />
      <Route path="/account/edit/:id" component={AccountEdit} />
      <Route path="/account/view/:id" component={AccountEdit} />
      <Route path="/candidate/resume-submission/:id/:jobId" component={CandidateResumeSubmission} />
      <Route path="/employer/create" component={EmployerEdit} />
      <Route path="/employer/edit/:id" component={EmployerEdit} />
      <Route path="/employer/view/:id" component={EmployerEdit} />
      <Route path="/management/tags" component={TagManagement} />
      <Route path="/create/announcements" component={AnnouncementCreate} />
      <Route path="/job/management" component={ManageJobs} />
      <Route path="/engagement/view/:id" component={MainV1} />
      <Route path="/engagement/represent/:id" component={MainV1} />
      <Route path="/job/create" component={JobEdit} />
      <Route path="/job/edit/:id" component={JobEdit} />
      <Route path="/job/match/:id" component={JobMatch} />
      <Route path="/job/matchNew/:id" component={JobMatchNew} />
      <Route path="/lab/tanstack/poc" component={TanstackPoc} />
      <Route path="/accounts" component={MainV1} />
      <Route path="/engagements" component={MainV1} />
      <Route path="/employers" component={MainV1} />
      <Route path="/admin/tools" component={MainV1} />
      <Route path="/calendar" component={MainV1} />
      <Route path="/reports" component={MainV1} />

      {/** ADMIN SECTIONS V2 */}
      <Route path="/v2/accounts" component={MainV2} />
      <Route path="/v2/candidates" component={MainV2} />
      <Route path="/v2/employers" component={MainV2} />
      <Route path="/v2/engagements" component={MainV2} />
      <Route path="/v2/jobs" component={MainV2} />

      {/** ADMIN SECTIONS V3 */}
      <Route path="/v3/account/create" component={AccountEdit} />
      <Route path="/v3/account/edit/:id" component={AccountEdit} />
      <Route path="/v3/account/view/:id" component={AccountEdit} />
      <Route path="/v3/candidate/resume-submission/:id/:jobId" component={CandidateResumeSubmission} />
      <Route path="/v3/employer/create" component={EmployerEdit} />
      <Route path="/v3/employer/edit/:id" component={EmployerEdit} />
      <Route path="/v3/employer/view/:id" component={EmployerEdit} />
      <Route path="/v3/management/tags" component={TagManagement} />
      <Route path="/v3/create/announcements" component={AnnouncementCreate} />
      <Route path="/v3/job/management" component={ManageJobs} />
      <Route path="/v3/job/create" component={JobEdit} />
      <Route path="/v3/job/edit/:id" component={JobEdit} />
      <Route path="/v3/job/match/:id" component={JobMatch} />
      <Route path="/v3/job/matchNew/:id" component={JobMatchNew} />
      <Route path="/v3/lab/tanstack/poc" component={TanstackPoc} />
      <Route path="/v3/accounts" component={MainV3} />
      <Route path="/v3/engagements" component={MainV3} />
      <Route path="/v3/v1/engagements" component={MainV3} />
      <Route path="/v3/engagement/view/:id" component={MainV3} />
      <Route path="/v3/engagement/represent/:id" component={MainV3} />
      <Route path="/v3/employers" component={MainV3} />
      <Route path="/v3/matcher" component={MainV3} />
      <Route path="/v3/admin/tools" component={MainV3} />
      <Route path="/v3/calendar" component={MainV3} />
      <Route path="/v3/reports" component={MainV3} />

      {/* DEFAULT */}
      <Route component={NoMatch} />

    </Switch>
  );
}