import React, { Component } from "react";

import BigCalendar from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

import Core from "../../lib/Core";
import events from "./events";
import "./Calendar.css";

const localizer = BigCalendar.momentLocalizer(moment);

export default class Calendar extends Component {
  static getClassName = (event, start, end, isSelected) => {
    let className = "text-event " + event.type;
    return { className };
  };
  constructor() {
    super();
    this.state = {
      events: []
    };
    if (Core.isLoggedOut()) {
      return (document.location.href = "#/");
    }
  }
  componentWillMount() {
    this.loadData();
  }
  loadData() {
    // TODO: Load and map events from server
    this.setState({ events });
  }
  render() {
    //const allowedViews = ["month", "week", "day"]; //["month", "week", "work_week", "day", "agenda"];
    let allViews = ["month", "week", "day"];
    /** * /
    Object.keys(BigCalendar.Views).map(
      k => BigCalendar.Views[k]
    );
    /** * /
    const components = {
      toolbar: CustomToolbar
    };
    /** */
    return (
      <div className="calendar-container">
        <BigCalendar
          events={this.state.events}
          views={allViews}
          tooltipAccessor="tooltip"
          defaultDate={new Date(/** * /2015, 3, 1/** */)}
          popup={true}
          // components={components}
          eventPropGetter={Calendar.getClassName}
          // showMultiDayTimes={true}
          localizer={localizer}
        />
      </div>
    );
  }
}
