import React, { Component } from "react";
import { Redirect } from "react-router";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { AppBar, FlatButton } from "material-ui";

import Core from "../../lib/Core";
import Definition from "../../lib/Definition";
import styles from "../Forms/Styles";

import CategoryBox from "./CategoryBox";
import TagBox from "./TagBox";
import Store from "../../lib/Store";

class TagManagement extends Component {
  constructor() {
    super(...arguments);
    this.state = { categories: [] };
    Store.set("path", window.location.href);
    this.load();
  }
  load() {
    Definition.fetchAll(newDefs => {
      Core.log({ newDefs });

      this.setState(
        state => {
          const keys = Definition.getRes();
          const categories = Object.keys(keys)
            .sort()
            .map(key => keys[key]);
          state.categories = categories;
          return state;
        },
        then => window.scrollTo(0, 0)
      );
    });
  }
  render() {
    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }

    /** µ FOR DEBUG PURPOSES * /
    console.debug(
      'µ:TagManagement',
      Definition.getAll()
    );
    /** */

    return (
      <MuiThemeProvider>
        <div className="management bgcolor2 min-100vh">
          <AppBar
            title="Tag Managament"
            titleStyle={styles.AppBar.title}
            style={styles.AppBar.nav}
            className="fixed"
            iconElementLeft={
              <FlatButton
                icon={<i className="material-icons">arrow_back</i>}
                style={styles.AppBar.backButton}
                onClick={ev => Core.goBack(this.props)}
              />
            }
          />
          <div className="main-padding padding-top-96">
            {this.state.categories.map((cat, catIndex) => (
              <CategoryBox
                key={Core.getKey()}
                cat={cat}
                tags={cat.values}
                onUpdate={({ category, tags }) => {
                  this.setState(async state => {
                    /** * /
                    console.debug({
                      category,
                      tags,
                      state
                    });
                    /** */
                    if (category) {
                      const update = {
                        key: category.key,
                        label: category.label,
                        open: category.open,
                        archived: category.archived,
                        groups: category.groups
                      };
                      state.categories[catIndex] = category;
                      Core.log({ id: cat.id, update });
                      await Definition.update(cat.id, update);
                    }
                    if (tags) {
                      const update = {
                        values: tags
                      };
                      state.categories[catIndex].values = tags;
                      Core.log({ id: cat.id, update });
                      await Definition.update(cat.id, update);
                    }
                    return state;
                  });
                }}
                expanded={!!cat.open}
              />
            ))}
            <TagBox
              key={Core.getKey()}
              name="addNewCategory"
              placeholder="Add a New Category"
              onUpdate={tag => {
                Core.log({ tag });
                Definition.post({ label: tag.label }, res => this.load());
              }}
              new={true}
            />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default TagManagement;
