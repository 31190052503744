/** ============================================ µ
 * @description EmployerEmail [JS]
 *              Library
 * @routes      /engagements/
 * @see         EngagementCard | component
 * @createdAt   2021-04-09
 * @reviewedAt  2021-05-13
/** =========================================== */

/* IMPORTS ==================================== */

import { FlatButton } from "material-ui";
import moment from "moment";
import React from "react";
import EmailPreview from "../../../components/Dialogs/EmailPreview";
import Core from "../../Core";
import { EMP_MSG_TYPE__ALL_ID, EMP_MSG_TYPE__SUBMISSION_ID } from "../../Definition";
import { newModel } from "../../GenericTools.lib";
import Google from "../../Google";
import Streak, { STATUS_W_EMPLOYER_HOMEWORK, STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK } from "../../Streak";
import TemplateLib from "../../Template.lib";
import {
  processEmployerOtherCandidatesReminder,
  sendSafeEmail,
  updateReminderSentDates
} from "./Email.lib";

/* CONSTANTS ================================== */

const processingModels = {
  "offer-in-progress": {
    key: "offer-in-progress", //done
    label: "Offer - in progress",
    stageLabel: "Offer",
    daysAgoDate: [{ attr: "onsite", msg: "moved to $stage" }],
    engagement: [],
    count: 0,
  },
  "onsite-schedule": {
    key: "onsite-schedule", //done
    label: "Onsite to Schedule",
    stageLabel: "Onsite",
    engagement: [],
    daysAgoDate: [
      { attr: "screened", msg: "$date moved to $stage" },
      { attr: "onsite1", msg: "$date $stage" },
      { attr: "onsite2", msg: "$date $stage" },
    ],
    count: 0,
  },
  "onsite-feedback": {
    key: "onsite-feedback", //done
    label: "Onsite Feedback",
    stageLabel: "Onsite",
    daysAgoDate: [
      { attr: "screened", msg: "$date moved to $stage" },
      { attr: "onsite1", msg: "$date $stage" },
      { attr: "onsite2", msg: "$date $stage" },
    ],
    engagement: [],
    count: 0,
  },
  "screen-schedule": {
    key: "screen-schedule", // done
    label: "Screen to Schedule",
    stageLabel: "Screen",
    daysAgoDate: [
      { attr: "reviewed", msg: "$date moved to $stage" },
      { attr: "screen1", msg: "$date $stage" },
      { attr: "screen2", msg: "$date $stage" },
      { attr: "screen3", msg: "$date $stage" },
    ],
    engagement: [],
    count: 0,
  },
  "screen-feedback": {
    key: "screen-feedback", // done
    label: "Screen Feedback",
    stageLabel: "Screen",
    daysAgoDate: [
      { attr: "reviewed", msg: "$date moved to $stage" },
      { attr: "screen1", msg: "$date $stage" },
      { attr: "screen2", msg: "$date $stage" },
      { attr: "screen3", msg: "$date $stage" },
    ],
    engagement: [],
    count: 0,
  },
  "homework-to-send": {
    key: "homework-to-send", // done
    label: "Homework to Send",
    stageLabel: "Homework",
    daysAgoDate: [
      { attr: "reviewed", msg: "$date moved to $stage" },
      { attr: "screen1", msg: "$date $stage" },
      { attr: "screen2", msg: "$date $stage" },
      { attr: "screen3", msg: "$date $stage" },
    ],
    engagement: [],
    count: 0,
  },
  "homework-to-review": {
    key: "homework-to-review", // done
    label: "Homework to Review",
    stageLabel: "Homework",
    daysAgoDate: [
      { attr: "reviewed", msg: "$date moved to $stage" },
      { attr: "screen1", msg: "$date $stage" },
      { attr: "screen2", msg: "$date $stage" },
      { attr: "screen3", msg: "$date $stage" },
    ],
    engagement: [],
    count: 0,
  },
  "cv-feedback": {
    key: "cv-feedback",
    label: "CV  Feedback",
    labelSentence: "CV  Feedback",
    stageLabel: "CV",
    daysAgoDate: [{ attr: "submitted", msg: "$date CV submitted" }],
    engagement: [],
    count: 0,
  },
  "screen-stage": {
    key: "screen-stage",
    label: "Screen Stage",
    stageLabel: "Screen",
    daysAgoDate: [
      { attr: "screen1", msg: "screen scheduled for $date" },
      { attr: "screen2", msg: "screen scheduled for $date" },
      { attr: "screen3", msg: "screen scheduled for $date" },
    ],
    engagement: [],
    count: 0,
  },
  "onsite-stage": {
    key: "onsite-stage",
    label: "Onsite Stage",
    stageLabel: "Onsite",
    daysAgoDate: [
      { attr: "onsite1", msg: "onsite scheduled for $date" },
      { attr: "onsite2", msg: "onsite scheduled for $date" },
    ],
    engagement: [],
    count: 0,
  },
};

/* FUNCTIONS ================================== */

/**
 *
 * @param {object} engagement
 * @param {source} processingInstance
 * @returns Processed candidate line
 */
function processCandidateLine(
  engagement,
  processingInstance
) {

  let date = "date not found";
  let label = "label not found";
  let agoDate = `ago date attribute not found for ${processingInstance.stageLabel} stage`;
  let candidateName = engagement.candidate._name;

  if (
    Array.isArray(processingInstance["daysAgoDate"]) &&
    !!processingInstance["daysAgoDate"].length
  ) {
    processingInstance["daysAgoDate"].forEach((obj) => {

      let attr = obj.attr;

      if (!!engagement[attr]) {

        date = moment(engagement[attr]).format("MM/DD");

        let now = moment();
        agoDate = now.diff(engagement[attr], "days");

        if (agoDate < 7) {
          agoDate = agoDate + " day(s)";

          if ([1, 2, 5].includes(+engagement.candidate.platformRating)) {
            candidateName = "<b>" + candidateName + "</b>";
          }
        } else {
          agoDate = "<b><u>" + agoDate + " day(s)</u></b>";
          candidateName = "<b>" + candidateName + "</b>";
        }

        label = obj.msg;

        [
          { prop: "stage", value: engagement.stage },
          { prop: "date", value: date },
        ].forEach((obj) => {
          label = label.replace("$" + obj.prop, obj.value);
        });

      }

    });
  }

  let ratingFlag = (
    [1, 5].includes(+engagement.candidate.platformRating)
      ? `**`
      : [2].includes(+engagement.candidate.platformRating)
        ? `*`
        : ``
  );

  return [
    `${candidateName}`,
    `- ${label} |`,
    `${agoDate}`,
    `${ratingFlag}`,
  ].join(" ");

}

/**
 * Maps engagements into a processing instances.
 * 
 * @param {array} engagements 
 * @returns {object} processingInstances.
 */
function processDataStructure(engagements) {

  const processingInstances = newModel(processingModels);

  engagements.forEach((engagement) => {
    if (
      /offer/i.test(engagement.stage) &&
      (
        /W - Employer Details/i.test(engagement.status) ||
        /W - Employer Reference/i.test(engagement.status) ||
        /W - Employer Verbal Offer/i.test(engagement.status) ||
        /W - Employer Written Offer/i.test(engagement.status) ||
        /W - Employer Reference/i.test(engagement.status)
      )
    ) {
      processingInstances["offer-in-progress"].engagement.push(
        processCandidateLine(engagement, processingInstances["offer-in-progress"])
      );
      processingInstances["offer-in-progress"].count += 1;
    } else if (
      /onsite/i.test(engagement.stage) &&
      (/W - Employer Availability/i.test(engagement.status) ||
        /W - Employer Schedule/i.test(engagement.status) ||
        /W - Cando-Emp Availability/i.test(engagement.status))
    ) {
      processingInstances["onsite-schedule"].engagement.push(
        processCandidateLine(engagement, processingInstances["onsite-schedule"])
      );
      processingInstances["onsite-schedule"].count += 1;
    }

    else if (
      /onsite/i.test(engagement.stage) &&
      (
        /W - Employer Details/i.test(engagement.status) ||
        /W - Employer Feedback/i.test(engagement.status) ||
        /W - Employer Reference/i.test(engagement.status)
      )
    ) {
      processingInstances["onsite-feedback"].engagement.push(
        processCandidateLine(engagement, processingInstances["onsite-feedback"])
      );
      processingInstances["onsite-feedback"].count += 1;
    }

    else if (
      /screen/i.test(engagement.stage) &&
      (
        /W - Employer Availability/i.test(engagement.status) ||
        /W - Employer Schedule/i.test(engagement.status) ||
        /W - Cando-Emp Availability/i.test(engagement.status)
      )
    ) {
      processingInstances["screen-schedule"].engagement.push(
        processCandidateLine(engagement, processingInstances["screen-schedule"])
      );
      processingInstances["screen-schedule"].count += 1;
    } else if (
      /screen/i.test(engagement.stage) &&
      (
        /W - Employer Details/i.test(engagement.status) ||
        /W - Employer Feedback/i.test(engagement.status)
      )
    ) {
      processingInstances["screen-feedback"].engagement.push(
        processCandidateLine(engagement, processingInstances["screen-feedback"])
      );
      processingInstances["screen-feedback"].count += 1;
    }
    else if (
      /screen/i.test(engagement.stage) &&
      engagement.status === STATUS_W_EMPLOYER_HOMEWORK
    ) {
      processingInstances["homework-to-send"].engagement.push(
        processCandidateLine(engagement, processingInstances["homework-to-send"])
      );
      processingInstances["homework-to-send"].count += 1;
    }
    else if (
      /screen/i.test(engagement.stage) &&
      engagement.status === STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK
    ) {
      processingInstances["homework-to-review"].engagement.push(
        processCandidateLine(engagement, processingInstances["homework-to-review"])
      );
      processingInstances["homework-to-review"].count += 1;
    }
    else if (
      /review/i.test(engagement.stage) &&
      /W - Employer Feedback/i.test(engagement.status)
    ) {
      processingInstances["cv-feedback"].engagement.push(
        processCandidateLine(engagement, processingInstances["cv-feedback"])
      );
      processingInstances["cv-feedback"].count += 1;
    } else if (
      /screen/i.test(engagement.stage) &&
      /NOT W-Employer/i.test(engagement.status)
    ) {
      processingInstances["screen-stage"].engagement.push(
        processCandidateLine(engagement, processingInstances["screen-stage"])
      );
      processingInstances["screen-stage"].count += 1;
    } else if (
      /onsite/i.test(engagement.stage) &&
      /NOT W-Employer/i.test(engagement.status)
    ) {
      processingInstances["onsite-stage"].engagement.push(
        processCandidateLine(engagement, processingInstances["onsite-stage"])
      );
      processingInstances["onsite-stage"].count += 1;
    }
  });

  return processingInstances;
}

/**
 * 
 * @param {object} processingInstances 
 * @returns {string} Stages list that will appear 
 * into parenthesis on Job Header.
 */
function processStages(processingInstances) {

  let subjectContent = [];

  Object.values(processingInstances)
    .filter((el) => !!el)
    .forEach((data) => {
      if (data.count > 0) {
        subjectContent.push(`${data.count} ${data.label}`);
      }
    });

  return `${subjectContent.join(" | ")}`;

}

/**
 * 
 * @param {object} processingInstances 
 * @returns {string} Processed Candidates from Job Engagements, as HTML text.
 */
function processJobEngagementsCandidates(processingInstances) {

  let emailContent = "";

  Object.values(processingInstances).forEach(
    processingInstance => {
      if (!!processingInstance.engagement.length) {

        /* JOB TITLE */
        emailContent += (
          `<p><strong>${processingInstance.labelSentence || processingInstance.label} :</strong></p>`
        );

        /* CANDIDATES LIST */
        emailContent += `<ul>`;
        processingInstance.engagement.forEach(
          engagement => {
            emailContent += `<li>${engagement}</li>`;
          }
        );
        emailContent += `</ul>`;

      }
    }
  );

  return `${emailContent}`;

}

/**
 * Push email/name pair into an array list.
 * 
 * This methods updates content of email param.
 * 
 * @param {array} emails Reference of list to update.
 * @param {object} emailCheck Reference of a hash list to prevent duplicates.
 * @param {object} engagement Engagement instance.
 */
function setEmailsListFromEngagement({
  emails = [],
  emailCheck = {},
  engagement = { employer: {}, job: {} },
}) {
  if (!emailCheck[engagement.employer.primaryContactEmail]) {
    engagement.employer.primaryContactEmail &&
      emails.push({
        name: engagement.employer.primaryContactName || "Primary Contact",
        email: engagement.employer.primaryContactEmail,
      });
    emailCheck[engagement.employer.primaryContactEmail] = true;
  }
  engagement.job.emailsList
    .filter(({ to }) => (
      to.includes(EMP_MSG_TYPE__ALL_ID) ||
      to.includes(EMP_MSG_TYPE__SUBMISSION_ID)
    )).forEach(contact => {
      if (!emailCheck[contact.email]) {
        emails.push({
          accountType: `${engagement.job.jobTitle}(job)`,
          ...contact
        });
        emailCheck[contact.email] = true;
      }
    });
}

/**
 * Sets a object hash-list tagging by job-title,
 * containing an array of Engagements.
 * 
 * This methods updates content of engagementsGroupedByJob param.
 * 
 * ---
 * 
 * Pushes an email/name pair into an array list.
 * 
 * This methods updates content of email param.
 * 
 * ---
 * 
 * @param {object} engagements Engagements list.
 * @param {object} engagementsGroupedByJob References of Job Hash List of Engagements list to set/update.
 * @param {array} emails Reference of list to update.
 * @param {object} emailCheck Reference of a hash list to prevent duplicates.
 */
function setGroupEngagementsByJob({
  engagements = [],
  engagementsGroupedByJob = {},
  emails = [],
  emailCheck = {},
}) {
  engagements.forEach(engagement => {

    engagementsGroupedByJob[engagement.job.jobTitle] = (
      engagementsGroupedByJob[engagement.job.jobTitle] || []
    );
    engagementsGroupedByJob[engagement.job.jobTitle].push(engagement);

    /* SET EMAILS LIST */
    setEmailsListFromEngagement({
      emails,
      emailCheck,
      engagement,
    });

  });
}

/* METHODS ==================================== */

/**
 * @param {array} engagements
 * @returns
 * - emailFormat -
 * An HTML text to be used into RichTextBoxes
 * and Email Body.
 *
 * - emails -
 * An Emails List to be used for send email.
 * 
 * - validEngagements - List of valid engagements to send employer reminder.
 * - validEngagementsOverdue - sub-list of validEngagements
 * - validEngagementsNoOverdue - sub-list of validEngagements
 * - otherEngagements - List of other engagements related to the employer.
 */
function makeEmailFormat({ employerId, engagements = [] }) {

  const validEngagements = [];
  const otherEngagements = [];
  const validEngagementsGroupedByJob = {};
  const otherEngagementsGroupedByJob = {};
  const validEngagementsOverdue = [];
  const validEngagementsNoOverdue = [];
  const emails = [];
  const emailCheck = {};
  const content = [];

  /* SPLIT VALID ENGAGEMENTS */
  engagements.forEach((engagement) => {

    /* ENGAGEMENTS WHERE WE GET CANDIDATES LIST FOR EMAIL REMINDER */
    if (

      engagement.employer.id === employerId &&

      !/h -/i.test(engagement.status) &&

      (
        (
          /review|offer/i.test(engagement.stage) &&
          /w - employer/i.test(engagement.status)
        )
        ||
        (
          /screen|onsite/i.test(engagement.stage) &&
          /w - employer|w - cando-emp/i.test(engagement.status)
        )
      )

    ) {
      validEngagements.push(engagement);
      /**
       * @note
       * Used to set the gmailThreadId
       * into the Streak boxes
       * after sent the reminder email.
       * @todo
       * To review it for improvements.
       * µ 2021-05-13
       */
      if (moment() >= moment(engagement.overdueDate)) {
        validEngagementsOverdue.push(engagement);
      } else {
        validEngagementsNoOverdue.push(engagement);
      }
    }
    /* ENGAGEMENTS WHERE WE GET OTHER CANDIDATES LIST */
    else if (

      engagement.employer.id === employerId &&

      !/h -/i.test(engagement.status) &&

      /screen|onsite/i.test(engagement.stage) &&

      /w - 10x10|w - recruiter|w - candidate|w - screen|w - onsite|w - event/i.test(
        engagement.status
      )

    ) {
      otherEngagements.push(engagement);
    }
  });

  if (!!validEngagements.length) {

    /* GROUPING ENGAGEMENTS BY JOB */
    setGroupEngagementsByJob({
      engagements: validEngagements,
      engagementsGroupedByJob: validEngagementsGroupedByJob,
      emails,
      emailCheck,
    });

    setGroupEngagementsByJob({
      engagements: otherEngagements,
      engagementsGroupedByJob: otherEngagementsGroupedByJob,
      emails,
      emailCheck,
    });

    /* GET EMAIL CONTENTS  */
    content.push("<p>Hi,</p>");
    content.push(
      "<p>Please let us know the next steps for these candidates waiting for your feedback:</p>"
    );

    /* GET EMAIL CONTENTS - PROCESSING JOB GROUPS */
    Object.keys(
      validEngagementsGroupedByJob
    ).forEach((jobTitle) => {

      const processingInstances = processDataStructure(
        validEngagementsGroupedByJob[jobTitle]
      );

      /** µ FOR DEBUG PURPOSES * /
      console.debug('µ:makeEmailFormat', { processingInstances });
      /** */

      /* JOB TITLE */
      const processedStages = processStages(
        processingInstances
      );
      content.push(
        `<p><u><strong>${jobTitle} (${processedStages})</strong></u></p>`
      );

      /* CANDIDATES LIST */
      const processedJobEngagements = processJobEngagementsCandidates(
        processingInstances
      );
      content.push(processedJobEngagements);

      /* OTHER CANDIDATES LIST */
      if (otherEngagementsGroupedByJob[jobTitle]) {
        const processedOtherCandidates = processEmployerOtherCandidatesReminder({
          engagements: otherEngagementsGroupedByJob[jobTitle]
        });
        content.push(processedOtherCandidates);
      }

    });

  }
  content.push("<p>This message is auto generated by the 10 By 10 platform to help you keep track of your candidates' status.  Suggestions/feedback welcome!</p>");

  /* RESULTS */
  return {
    validEngagements,
    validEngagementsOverdue,
    validEngagementsNoOverdue,
    otherEngagements,
    emails,
    emailFormat: content
      .filter(n => !!n)
      .join("\n")
      .trim(),
  };
}

function openReminderEmailPreview_({
  employer,
  engagements = []
}) {

  const {
    id: employerId,
    name: employerName,
  } = employer;

  const {
    validEngagementsOverdue,
    validEngagementsNoOverdue,
    emails,
    emailFormat,
  } = makeEmailFormat({
    employerId,
    engagements
  });

  if (!!emailFormat) {

    Core.dialog.open({
      title: <>Employer Reminder</>,
      message: (
        <EmailPreview
          ref={self => Core.setKeyValue('EmployerReminderEmailPreview', self)}
          emails={emails}
          to={emails[0] && [emails[0]]}
          subject={`${employerName} - 10x10 Pending Candidates Who Need Your Attention`}
          body={emailFormat}
        />
      ),
      className: "p-0",
      actions: [
        <FlatButton
          label="Cancel"
          className="button-flat-darker"
          onClick={ev => {
            Core.dialog.close();
          }}
        />,
        <FlatButton
          label="Send"
          className="button-white-cyan"
          onClick={ev => {
            Core.dialog.close();
            sendSafeEmail(
              {
                ...Core.getKeyValue('EmployerReminderEmailPreview').getParams(),
                source: 'EmployerEmail.lib.js'
              },
              response => {
                Core.showMessage("Email sent");
                if (!response.threadId) {
                  Core.showFailure("It is missing the threadGmailId");
                } else {
                  const engagements = [
                    ...validEngagementsOverdue,
                    ...validEngagementsNoOverdue
                  ];
                  const next = em => {
                    if (!!engagements.length) {
                      const engagement = engagements.pop();
                      Core.log("putEmailInBox", "success", response);
                      updateReminderSentDates({
                        engagement,
                        next,
                        reminder: {
                          date: new Date().toISOString(), // date - time reminder was set
                          source: 'employer', // source of email, e.g. job, employer, recruiter, agency, null (for candidate)
                        }
                      });
                    }
                  };
                  next();
                }
              },
              error => Core.showFailure(error)
            );
            /** */
          }}
        />
      ]
    });
  } else {
    Core.showMessage("There are no Engagements for sending a reminder");
  }
};

async function openReminderEmailPreview({
  employer,
  engagements = []
}) {

  const {
    id: employerId,
    // name: employerName,
  } = employer;

  /** */
  const {
    validEngagementsOverdue,
    validEngagementsNoOverdue,
    // emails,
    // emailFormat,
  } = makeEmailFormat({
    employerId,
    engagements
  });
  /** */

  const template = await TemplateLib.getBy({
    type: 'email',
    name: 'employer-todo-reminder'
  });

  const renderResponse = await TemplateLib.getRender({
    templateId: template.id,
    employerId
  });

  const { errors = [], rendered = {}, mixins = {} } = renderResponse;

  console.debug({ errors, rendered, mixins, employerId });

  if (!errors.length) {

    const { subject = '', bodyHtml: body = '' } = rendered;

    const {
      from,
      emails = [],
      to = [],
      cc = [],
      bcc = [],
    } = mixins;

    Core.dialog.open({
      title: `Employer Reminder (API envelope)`,
      message: (
        <EmailPreview
          ref={self => Core.setKeyValue('EmployerReminderEmailPreview', self)}
          emails={emails}
          from={from}
          to={to}
          cc={cc}
          bcc={bcc}
          subject={subject}
          body={body}
        />
      ),
      className: "p-0",
      actions: [
        <FlatButton
          label="Cancel"
          className="button-flat-darker"
          onClick={ev => {
            Core.dialog.close();
          }}
        />,
        <FlatButton
          label="Send"
          className="button-white-cyan"
          onClick={ev => {
            Core.dialog.close();
            sendSafeEmail(
              {
                ...Core.getKeyValue('EmployerReminderEmailPreview').getParams(),
                source: 'EmployerEmail.lib.js'
              },
              response => {
                Core.showMessage("Email sent");
                if (!response.threadId) {
                  Core.showFailure("It is missing the threadGmailId");
                } else {
                  const engagements = [
                    ...validEngagementsOverdue,
                    ...validEngagementsNoOverdue
                  ];
                  const next = em => {
                    if (!!engagements.length) {
                      const engagement = engagements.pop();
                      Core.log("putEmailInBox", "success", response);
                      updateReminderSentDates({
                        engagement,
                        next,
                        reminder: {
                          date: new Date().toISOString(), // date - time reminder was set
                          source: 'employer', // source of email, e.g. job, employer, recruiter, agency, null (for candidate)
                        }
                      });
                    }
                  };
                  next();
                }
              },
              error => Core.showFailure(error)
            );
            /** */
          }}
        />
      ]
    });
  } else {
    Core.showMessage(`${errors.map(n => n.message).join(', ')}`);
  }
};

async function openJobReminderEmailPreview({
  job,
  employer,
  engagements
}) {

  const {
    id: jobId,
    jobTitle,
  } = job;

  const {
    id: employerId,
    // name: employerName,
  } = employer;

  const jobEngagements = engagements.filter((engagement) => { return engagement.jobId === jobId });

  const {
    validEngagementsOverdue,
    validEngagementsNoOverdue,
    emailFormat,
  } = makeEmailFormat({
    employerId,
    engagements: jobEngagements
  });

  const renderResponse = await TemplateLib.getRender({
    templateName: 'job-todo-reminder',
    jobId
  });

  const { errors = [], rendered = {}, mixins = {} } = renderResponse;

  console.debug({ errors, rendered, mixins, jobId });

  if (!errors.length) {

    // const { subject = '', bodyHtml: body = '' } = rendered;

    const {
      from,
      emails = [],
      to = [],
      cc = [],
      bcc = [],
    } = mixins;

    Core.dialog.open({
      title: <>Job Reminder(API envelope)</>,
      message: (
        <EmailPreview
          ref={self => Core.setKeyValue('EmployerReminderEmailPreview', self)}
          emails={emails}
          from={from}
          to={to}
          cc={cc}
          bcc={bcc}
          subject={`${jobTitle} - 10x10 Pending Candidates Who Need Your Attention`}
          body={emailFormat}
        />
      ),
      className: "p-0",
      actions: [
        <FlatButton
          label="Cancel"
          className="button-flat-darker"
          onClick={ev => {
            Core.dialog.close();
          }}
        />,
        <FlatButton
          label="Send"
          className="button-white-cyan"
          onClick={ev => {
            Core.dialog.close();
            Google.sendEmail(
              {
                ...Core.getKeyValue('EmployerReminderEmailPreview').getParams(),
                source: 'EmployerEmail.lib.js'
              },
              response => {
                Core.showMessage("Email sent");
                if (!response.threadId) {
                  Core.showFailure("It is missing the threadGmailId");
                } else {
                  const engagements = [
                    ...validEngagementsOverdue,
                    ...validEngagementsNoOverdue
                  ];
                  const next = em => {
                    if (!!engagements.length) {
                      const engagement = engagements.pop();
                      Streak.putEmailInBox(
                        {
                          boxKey: engagement.boxKey,
                          threadGmailId: response.threadId
                        },
                        response => {
                          Core.log("putEmailInBox", "success", response);
                          updateReminderSentDates({
                            engagement,
                            next: () => { },
                            reminder: {
                              date: new Date().toISOString(), // date - time reminder was set
                              source: 'employer', // source of email, e.g. job, employer, recruiter, agency, null (for candidate)
                            }
                          });
                        }
                      );
                    }
                  };
                  next();
                }
              },
              error => Core.showFailure(error)
            );
          }}
        />
      ]
    });
  } else {
    Core.showMessage(`${errors.map(n => n.message).join(', ')}`);
  }
};

/* DICTIONARY ================================= */

const EmployerEmailLib = {
  makeEmailFormat,
  openReminderEmailPreview
};

/* EXPORTS ==================================== */

export {
  EmployerEmailLib as default,
  EmployerEmailLib,
  makeEmailFormat as makeEmployerReminderEmailFormat,
  openReminderEmailPreview_ as openEmployerReminderEmailPreview_, // old 
  openReminderEmailPreview as openEmployerReminderEmailPreview, // new
  openJobReminderEmailPreview
};

/* ============================================ */
