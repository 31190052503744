import React, { Component } from "react";

import { List, ListItem } from "material-ui/List";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";

import TextField from "material-ui/TextField";

import Row from "../../Forms/Row";
import Col from "../../Forms/Col";
import RichTextBox from "../../Forms/RichTextBox";

import { colors } from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import Chips from "../../Forms/Chips";

class Basics extends Component {
  render() {
    const lineStyle = { borderColor: "#59736f" };
    const parent = this.props.parent;
    return (
      <List className="EmployerForm Basics">
        <ListItem
          className="form-header"
          primaryText="ENGINEERING"
          disabled={true}
        />
        <Row>
          <Col>
            <label>How many engineers work at the company?</label>
            <TextField
              name="minCompanySize"
              underlineFocusStyle={lineStyle}
              min="1"
              type="number"
              value={parent.state.teamCount}
              onChange={(event, teamCount) => {
                parent.setState({ teamCount });
              }}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <label>
              Explain the Engineering team Structure and the process(es) you
              employ
            </label>
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <RichTextBox
              name="teamStructure"
              value={parent.state.teamStructure}
              onChange={teamStructure =>
                parent.setState({ teamStructure })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <label>
              Explain the most exciting and interesting engineering challenges
              you're solving in the next 6 - 18 months?
            </label>
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <RichTextBox
              name="teamChallenge"
              value={parent.state.teamChallenge}
              onChange={teamChallenge =>
                parent.setState({ teamChallenge })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <Chips
              name="technicalSkills"
              label="Technology Stack"
              values={parent.state.technicalSkills}
              onChange={technicalSkills => parent.setState({ technicalSkills })}
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <label>Visa</label>
            <br />
            <RadioButtonGroup
              name="visaTransfer"
              style={{ display: "flex", flexwrap: "nowrap" }}
              valueSelected={parent.state.visaTransfer}
              onChange={(event, visaTransfer) => {
                parent.setState({ visaTransfer });
              }}
              fullWidth
            >
              {Definition.get("visa").map(item => (
                <RadioButton
                  key={item.id}
                  value={item.id}
                  label={item.label}
                  style={{ width: "20%", marginBottom: 16 }}
                  labelStyle={{
                    marginLeft: -10,
                    color: colors.gray,
                    fontSize: 12
                  }}
                />
              ))}
            </RadioButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <label>Work From Home (WFH) Policy</label>
            <br />
            <RadioButtonGroup
              name="workremote"
              style={{ display: "flex", flexwrap: "nowrap" }}
              valueSelected={parent.state.remote}
              onChange={(event, remote) => {
                parent.setState({ remote });
              }}
              fullWidth
            >
              {Definition.get("remote").map(
                tag => (
                  <RadioButton
                    key={tag.id}
                    value={tag.id}
                    label={tag.label}
                    style={{ width: "20%", marginBottom: 16 }}
                    labelStyle={{
                      color: colors.gray,
                      fontSize: 14
                    }}
                  />
                )
              )}
            </RadioButtonGroup>
            <label style={{display: "inline", marginRight: "10px"}}>WFH Details</label>
            <TextField
              style={{width: "75%"}}
              maxlength={100}
              placeholder="Specify additional information"
              value={parent.state.remoteExtraInfo}
              label="Standard"
              margin="normal"
              onChange={(event, remoteExtraInfo) =>
                parent.setState({ remoteExtraInfo })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <label>Relocation Provided?</label>
            <br />
            <RadioButtonGroup
              name="relocation"
              style={{ display: "flex", flexwrap: "nowrap" }}
              valueSelected={parent.state.relocation}
              onChange={(event, relocation) => {
                parent.setState({ relocation });
              }}
              fullWidth
            >
              <RadioButton
                value={0}
                label="Yes"
                style={{ width: "20%", marginBottom: 16 }}
                labelStyle={{
                  marginLeft: -10,
                  color: colors.gray,
                  fontSize: 12
                }}
              />
              <RadioButton
                value={1}
                label="No"
                style={{ width: "20%", marginBottom: 16 }}
                labelStyle={{
                  marginLeft: -10,
                  color: colors.gray,
                  fontSize: 12
                }}
              />
              <RadioButton
                value={2}
                label="Unknown"
                style={{ width: "20%", marginBottom: 16 }}
                labelStyle={{
                  marginLeft: -10,
                  color: colors.gray,
                  fontSize: 12
                }}
              />
            </RadioButtonGroup>
            <label style={{display: "inline", marginRight: "10px"}}>Relocation Details</label>
            <TextField
              style={{width: "75%"}}
              maxlength={100}
              placeholder="Specify additional information"
              value={parent.state.relocationExtraInfo}
              label="Standard"
              margin="normal"
              onChange={(event, relocationExtraInfo) =>
                parent.setState({ relocationExtraInfo })
              }
            />
          </Col>
        </Row>
      </List>
    );
  }
}

export default Basics;
