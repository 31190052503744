/** ============================================ µ
 * @description LocationSection [JSX]
 *              UI Component
 * @route       /candidate/edit/:id > Match
 * @createdAt   2021-06-11
 * @updatedAt   2021-08-17 Tue
 * ============================================ */
/* IMPORTS ==================================== */

// import lineStyle from "../../../static/textfield-line-style.json";
import { Input } from 'antd';
import {
  Checkbox
} from "material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import Candidate from '../../../lib/Candidate';
import Core from "../../../lib/Core";
import Definition, {
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID,
  LOCATION__AMERICAS,
  LOCATION__CALIFORNIA,
  LOCATION__SAN_FRANCISCO_BAY_AREA,
  LOCATION__UNITED_STATES,
  LOC_TYPE__ALIAS_ID, LOC_TYPE__CITY_ID,
  LOC_TYPE__COUNTRY_ID,
  LOC_TYPE__METRO_AREA_ID,
  LOC_TYPE__METRO_AREA_SEC_ID,
  LOC_TYPE__MULTI_CONT_ID,
  LOC_TYPE__STATE_ID
} from "../../../lib/Definition";
import LocationLib from '../../../lib/DefinitionLocation.lib';
import FilterControlLib from '../../../lib/FilterControl';
import { NOT, YES } from '../../../lib/GenericTools.lib';
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";
import TreeSelectDropdown from "../../Forms/TreeSelect";

/* CONSTANTS ================================== */

const { TextArea } = Input;

/* METHODS ==================================== */

function LocationSection(props) {
  const {
    CandidateEditController = {}
  } = props;
  const {
    desiredEmploymentTypes = [],
    candidateLocations = [],
    inOfficeRemoteFlags = [],
    officeLocations = [],
    locationDetails = '',
  } = CandidateEditController.state;
  const { t } = useTranslation();
  const locationTags = Definition.get("location");
  const desiredEmploymentTypeTags = Definition.get("desiredEmploymentType");
  const inOfficeRemoteTags = Definition.get("inOfficeRemote");

  const isRemoteWork = YES(
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
  );

  const isInOfficeWork = YES(
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID) ||
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
  );

  /**
   * @todo
   *
   * Review to cleanup
   *
   * epic-3338(new locations)-story-3385 | 2021-07-15 Thu µ
   *
   * covering possible rollback
   *
   */
  function _patch3385(state) {
    /** * /
    const REMOTE_MAPPING = {
      'None': 'In office full week',
      '1 - 2 Days a Week': 'In office part week',
      '3 - 4 Days a Week': 'In office part week',
      '5 Days a Week': '100% remote',
    };
    {
      "key": "remotelyWork",
      "label": "Work from home partial week",
      "values": [
        { "id": 1, "label": "No" },
        { "id": 2, "label": "1 - 2 Days a Week" },
        { "id": 3, "label": "2 - 3 Days a Week" },
        { "id": 4, "label": "5 Days a Week" }
      ]
    }
    location: {
      "id": 11,
      "label": "Remote",
      "archived": true,
      "parentId": null
    }
    locationCandidate: [
      { "id": 7, "label": "Remotly" }
    ]
    /** */
    if (state.inOfficeRemoteFlags) {
      state.workRemotly = (
        state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
          ? 1
          : state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
            ? 2
            : state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
              ? 4
              : 1
      );
      state.workLocationIds = CandidateEditController.state.workLocationIds || [];
      state.workLocationIds = state.workLocationIds.filter(n => n !== 7);

      if (state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)) {
        state.workLocationIds.push(7);
      }

    }
    else if (!!state.officeLocations) {
      const SEPARATOR_SECTION = '\n___________';
      const SEPARATOR_LINE = '\n';
      const mapping = {
        '88': 6, // San Francisco Bay Area > San Francisco
        '4': 6, // San Francisco > San Francisco
        '3': 5, // Peninsula
        '1': 2, // East Bay
        '2': 4, // North Bay
        '5': 3, // South Bay
      };
      state.privateNotes = CandidateEditController.state.privateNotes || '';
      state.privateNotes = state.privateNotes.split(SEPARATOR_SECTION)[0] + SEPARATOR_SECTION;

      state.workLocationIds = state.officeLocations.map(workLocationId => {
        const _workLocationId = (
          mapping[workLocationId] || (
            LocationLib.evalAncest({ ancestId: 3, descendantId: workLocationId })
              ? 5
              : LocationLib.evalAncest({ ancestId: 1, descendantId: workLocationId })
                ? 2
                : LocationLib.evalAncest({ ancestId: 2, descendantId: workLocationId })
                  ? 4
                  : LocationLib.evalAncest({ ancestId: 5, descendantId: workLocationId })
                    ? 3
                    : 0
          )
        );
        if (12 <= workLocationId) {
          const workLocationLabel = FilterControlLib.getItemValue({
            menu: Candidate.getMenu({ key: 'officeLocations' }),
            itemLabel: Definition.getLabel('location', workLocationId)
          });
          if (!state.privateNotes?.includes(workLocationLabel)) {
            state.privateNotes = [
              state.privateNotes,
              `${SEPARATOR_LINE}${workLocationLabel}`
            ].filter(n => !!n.trim()).join('');
          }
        }
        return _workLocationId;
      }).filter(n => !!n);

      if (CandidateEditController.state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)) {
        state.workLocationIds.push(7);
      }

      const candidateLocationLabel = FilterControlLib.getItemValue({
        menu: Candidate.getMenu({ key: 'candidateLocations' }),
        itemLabel: Definition.getLabel('location',
          CandidateEditController.state.candidateLocations[0]
        )
      });

      if (!state.privateNotes?.includes(candidateLocationLabel)) {
        state.privateNotes = [
          state.privateNotes,
          `${SEPARATOR_LINE}${candidateLocationLabel}`
        ].filter(n => !!n.trim()).join('');
      }

    }
    else if (!!state.candidateLocations.length) {
      const SEPARATOR_SECTION = '\n___________';
      const SEPARATOR_LINE = '\n';
      state.privateNotes = CandidateEditController.state.privateNotes || '';
      state.privateNotes = state.privateNotes.split(SEPARATOR_SECTION)[0] + SEPARATOR_SECTION;

      state.officeLocations = CandidateEditController.state.officeLocations || [];
      state.officeLocations.forEach(workLocationId => {
        if (12 <= workLocationId) {
          const workLocationLabel = FilterControlLib.getItemValue({
            menu: Candidate.getMenu({ key: 'officeLocations' }),
            itemLabel: Definition.getLabel('location', workLocationId)
          });
          if (!state.privateNotes?.includes(workLocationLabel)) {
            state.privateNotes = [
              state.privateNotes,
              `${SEPARATOR_LINE}${workLocationLabel}`
            ].filter(n => !!n.trim()).join('');
          }
        }
      });

      const candidateLocationLabel = FilterControlLib.getItemValue({
        menu: Candidate.getMenu({ key: 'candidateLocations' }),
        itemLabel: Definition.getLabel('location', state.candidateLocations[0])
      });

      if (!state.privateNotes?.includes(candidateLocationLabel)) {
        state.privateNotes = [
          state.privateNotes,
          `${SEPARATOR_LINE}${candidateLocationLabel}`
        ].filter(n => !!n.trim()).join('');
      }

    }

    console.debug('_patch3385', state);

    return state;

  }

  console.debug('CandidateEditController.state', CandidateEditController.state);

  return (
    <div
      ref={(self) => Core.setKeyValue("location-section", self)}
      className="bg-a-05 pb-2 m-1 rounded"
    /** @todo experimental 2021-07-15 Thu µ */
    /** * /
    onClick={(ev) =>
      Core.getKeyValue("location-section")?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      })
    }
    /** */
    >

      <Row>
        <Col fullWidth>
          <label>
            {t('candidate.desiredEmploymentTypes.label')}&nbsp;
            <b style={{ color: "orange" }}>*</b>
          </label>
          <div className="d-flex my-2">
            {desiredEmploymentTypeTags.map((item) => (
              <Checkbox
                key={item.id}
                label={item.label}
                checked={desiredEmploymentTypes.includes(item.id)}
                onCheck={(ev, checked) => {
                  CandidateEditController.setStateStore({
                    desiredEmploymentTypes: !!checked
                      ? [...desiredEmploymentTypes, item.id]
                      : desiredEmploymentTypes.filter((n) => n !== item.id),
                    key: "desiredEmploymentTypes",
                  });
                }}
              />
            ))}
            <div className="w-100">&nbsp;</div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col fullWidth>
          <label>
            {t('candidate.candidateLocations.label')}&nbsp;
            <b style={{ color: "orange" }}>*</b>
          </label>
          {(NOT(candidateLocations.length) && YES(isRemoteWork))
            ? (<span className="c-red">{t('candidate.candidateLocations.danger')}</span>)
            : (<span className="c-gray">{t('candidate.candidateLocations.subtitle')}</span>)
          }
          <TreeSelectDropdown
            /* epic-3338(new locations)-story-3604 | 2021-07-30 Fri µ */
            //disabled={NOT(isRemoteWork)}
            /* story-3220-M3 | "do not show aliases" */
            data={locationTags}
            value={candidateLocations[0]}
            onChange={({ value: candidateLocation }) =>
              CandidateEditController.setState(
                /* epic-3338(new locations)-story-3385 | 2021-07-15 Thu µ */
                _patch3385(
                  { candidateLocations: [candidateLocation] }
                )
              )
            }
            layers={[

              // story-3796-m1 | 2021-08-17 Tue µ
              // LOC_TYPE__ALIAS_ID,

              LOC_TYPE__MULTI_CONT_ID,
              LOC_TYPE__COUNTRY_ID,
              LOC_TYPE__STATE_ID,
              LOC_TYPE__METRO_AREA_ID,
              LOC_TYPE__METRO_AREA_SEC_ID,
              LOC_TYPE__CITY_ID
            ]}
            treeDefaultExpandedIds={[
              LOCATION__AMERICAS,
              LOCATION__UNITED_STATES,
              LOCATION__CALIFORNIA,
              LOCATION__SAN_FRANCISCO_BAY_AREA
            ]}
            multiple={false}
            placeholder={t('candidate.candidateLocations.placeholder')}
            className="my-1"
          />
        </Col>
      </Row>

      <Row>
        <Col fullWidth>
          <label>
            {t('candidate.inOfficeRemoteFlags.label')}&nbsp;
            <b style={{ color: "orange" }}>*</b>
          </label>
          <div className="d-flex my-2">
            {inOfficeRemoteTags.map((tag) => (
              <Checkbox
                key={tag.id}
                label={tag.label}
                checked={inOfficeRemoteFlags.includes(tag.id)}
                onCheck={(ev, checked) => {
                  CandidateEditController.setState(
                    /* epic-3338(new locations)-story-3385 | 2021-07-15 Thu µ */
                    _patch3385(
                      {
                        inOfficeRemoteFlags: !!checked
                          ? [...inOfficeRemoteFlags, tag.id]
                          : inOfficeRemoteFlags.filter((n) => n !== tag.id)
                      }
                    ),
                    then => {
                      const state = CandidateEditController.state;
                      let update;
                      /* epic-3338(new locations)-story-3604 | 2021-07-30 Fri µ */
                      /** * /
                      if (
                        NOT(
                          state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
                        )
                      ) {
                        update = {};
                        update.candidateLocations = [];
                      }
                      /** */
                      /** */
                      if (
                        NOT(
                          state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID) ||
                          state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
                        )
                      ) {
                        update = update || {}
                        update.officeLocations = [];
                      }
                      YES(update) && CandidateEditController.setState(_patch3385(update));
                      /** */
                    }
                  );
                }}
              />
            ))}
          </div>
        </Col>
      </Row>

      <Row>
        <Col fullWidth>
          <label>{t('candidate.officeLocations.label')}</label>
          {(NOT(officeLocations.length) && YES(isInOfficeWork))
            ? (<span className="c-red">{t('candidate.officeLocations.danger')}</span>)
            : (<span className="c-gray">{t('candidate.officeLocations.subtitle')}</span>)
          }
          <div
            className="hint--top hint--rounded w-100"
            aria-label={NOT(isInOfficeWork) ? t('candidate.officeLocations.disabled') : ''}
          >
            <TreeSelectDropdown
              disabled={NOT(isInOfficeWork)}
              data={locationTags}
              layers={[
                LOC_TYPE__ALIAS_ID,
                LOC_TYPE__MULTI_CONT_ID,
                LOC_TYPE__COUNTRY_ID,
                LOC_TYPE__STATE_ID,
                LOC_TYPE__METRO_AREA_ID,
                LOC_TYPE__METRO_AREA_SEC_ID,
                LOC_TYPE__CITY_ID
              ]}
              value={officeLocations}
              treeDefaultExpandedIds={[
                LOCATION__AMERICAS,
                LOCATION__UNITED_STATES,
                LOCATION__CALIFORNIA,
                LOCATION__SAN_FRANCISCO_BAY_AREA
              ]}
              onChange={({ value: officeLocations }) => {
                CandidateEditController.setState(
                  /* epic-3338(new locations)-story-3385 | 2021-07-15 Thu µ */
                  _patch3385(
                    { officeLocations }
                  )
                );
              }}
              className="my-1"
            />
          </div>
        </Col>
      </Row>

      {/* epic-3038-story-3326 | 2021-07-01 µ */}
      <Row>
        <Col fullWidth>
          <label>{t('candidate.locationDetails.label')}</label>
          <TextArea
            autoSize={{ minRows: 3, maxRows: 6 }}
            placeholder="Type here"
            value={locationDetails}
            style={{ fontSize: "13px" }}
            onChange={event =>
              CandidateEditController.setState({
                locationDetails: event.target.value
              })
            }
          />

        </Col>

      </Row>

    </div >
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { LocationSection as default, LocationSection };

/* ============================================ */
