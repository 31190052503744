/** ============================================ µ
 * @description Bookmarks [JSX]
 *              V3-table UI Component
 * @createdAt   2021-10-22 Fri
 * @updatedAt   2021-10-23 Sat
 * ============================================ */
/* IMPORTS ==================================== */

import { IconButton, Menu } from '@mui/material';
import React, { useState } from 'react';
import Core from '../../lib/Core';
import Loader from '../Shared/Loader';
import Bookmark from './Bookmark';
import { useAccountBookmarks } from './useAccountBookmarks.hook';

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function Bookmarks(props) {
  const { enhancedReactTableContext } = props;

  const [anchorEl, setAnchorEl] = useState();
  const open = !!anchorEl;

  const [showNewBookmark, setShowNewBookmark] = useState(false);

  let { loading, bookmarks, fetchBookmarks, upsertBookmark, deleteBookmark } =
    useAccountBookmarks({ accountId: Core.getUserId() });
  let _props = { ...props };
  delete _props.enhancedReactTableContext;
  return (
    <div {..._props}>
      <IconButton
        onClick={(event) => {
          setShowNewBookmark(false);
          setAnchorEl(event.currentTarget);
          fetchBookmarks();
        }}
        title="Bookmarks"
      >
        <i className="material-icons">bookmark</i>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(event) => {
          if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
          ) {
            return;
          }
          setAnchorEl();
        }}
      >
        <div className="" style={{ minWidth: 360 }}>
          <div className="d-flex flex-align-left-center px-1 col">
            <label className="m-0">Bookmarks</label>
            <Loader loading={loading} text="" />
            {!showNewBookmark && (
              <IconButton
                onClick={(event) => setShowNewBookmark(true)}
                className="ml-auto"
                title="Add new bookmark"
              >
                <i className="material-icons">add_circle_outline</i>
              </IconButton>
            )}
          </div>
          <Bookmark
            enhancedReactTableContext={enhancedReactTableContext}
            showNewBookmark={showNewBookmark}
            upsertBookmark={upsertBookmark}
            setShowNewBookmark={setShowNewBookmark}
          />
          <div
            className="scroll-y"
            style={{ maxHeight: 'calc(100vh - 12rem)' }}
          >
            {bookmarks
              .sort((a, b) => String(a.pathname).localeCompare(b.pathname))
              .map((bookmark, index) => {
                return (
                  <>
                    {bookmark.pathname !== bookmarks[index - 1]?.pathname && <div className="p-1 bg-eee">{bookmark.pathname}</div>}
                    <Bookmark
                      key={`bookmark-${bookmark.createdAt}`}
                      enhancedReactTableContext={enhancedReactTableContext}
                      bookmark={bookmark}
                      upsertBookmark={upsertBookmark}
                      deleteBookmark={deleteBookmark}
                      setShowNewBookmark={setShowNewBookmark}
                    />
                  </>
                )
              })}
          </div>
        </div>
      </Menu>
    </div>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { Bookmarks as default, Bookmarks };

/* ============================================ */
