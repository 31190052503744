import React, { Component } from "react";

/* Material UI theme   */
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

/* Custom Styles */
import Main from "../../../components/Shared/Match/Main";
import MainV3 from "../../../components/Shared/Match/MainV3";

/* Functionality Job */
import queryString from "query-string";
import { StrategyMain } from "../../../components/Candidates/MatchStrategy/Strategy";

class CandidateMatchNew extends Component {
  render() {
    let { location: { pathname } } = this.props;
    return (
      <MuiThemeProvider>
        <div className="match">
          {
            !!pathname.match(/v3/)
              ? (
                <MainV3
                  {...this.props} // to pass router props
                  source={StrategyMain(
                    this.props.match.params.id,
                    this.props.location.search
                  )}
                  candidateId={this.props.match.params.id}
                  params={queryString.parse(this.props.location.search)}
                />
              )
              : (
                <Main
                  source={StrategyMain(
                    this.props.match.params.id,
                    this.props.location.search
                  )}
                  candidateId={this.props.match.params.id}
                  params={queryString.parse(this.props.location.search)}
                />
              )
          }
        </div>
      </MuiThemeProvider>
    );
  }
}

export default CandidateMatchNew;
