import FlatButton from "material-ui/FlatButton";
import FloatingActionButton from "material-ui/FloatingActionButton";
import IconButton from "material-ui/IconButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import { Table, TableBody, TableRow, TableRowColumn } from "material-ui/Table";
import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import loader from "../../assets/images/loader.gif";
import Core, { colors } from "../../lib/Core";
import Debug from "../../lib/Debug";
import EngagementModel from "../../lib/models/engagement";

let itemsPerPage = 50;

const loadWrapper = [
  <div key={"loaderKey"} className="not-found inline-blocks">
    Loading&nbsp;
    <img alt="loading..." height="14" src={loader} />
  </div>
];

class List extends Component {
  data;
  tabState = {};
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      collection: [],
      page: loadWrapper,
      tab: this.props.tab,
      index: 0
    };
    itemsPerPage =
      Number(
        /items=/.test(document.location.href) &&
        document.location.href.split("items=").pop()
      ) || itemsPerPage;

  }
  setItems(data) {
    this.data = data;
    this.loadData();
  }
  restart() {
    this.setState({
      collection: [],
      page: loadWrapper,
      index: 0
    });
  }
  updateItem = (item, opts = {}) => {
    this.data = this.data.map(current => {
      if (current.id === item.id) {
        current = { ...current, ...item };
      }
      return current;
    });

    !!opts.reload && this.loadData();
  };
  loadData(tab) {
    this.selectTab(tab || this.state.tab);
  }
  selectTab(tab) {
    Debug.time("Render List");
    this.setState({ tab }, then => {
      this.groupBy(tab);
    });
  }
  groupBy(filter) {
    var items = {};
    // organice data by filter key
    (this.data || []).forEach(item => {
      var header = item.filters[filter];
      if (filter !== undefined && filter !== null) {
        if (filter === "Recent") {
          header = Core.getFromNow(header);
        }
        if (filter === "Introduced") {
          header = "Introduced";
        }
        items[header] = items[header] || [];
        if (filter === "Starred") {
          if (item.filters["Starred"] === "Starred") {
            items[header].push(item);
          }
        } else {
          if (header !== false) {
            items[header].push(item);
          }
        }
      }
    });
    const headers =
      filter === "Stage"
        ? [
          "End",
          "Hire",
          "Guarantee",
          "Offer",
          "Onsite",
          "Screen",
          "Review",
          "Submission",
          "Confirmation",
          "Pending"
        ]
        : filter === "Starred"
          ? ["Starred", "Non Starred"]
          : filter === "Recent"
            ? Object.keys(items).sort((a, b) => {
              a = items[a][0].filters.Recent;
              b = items[b][0].filters.Recent;
              return a < b ? 1 : -1;
            })
            : Object.keys(items).sort((a, b) => a.localeCompare(b));
    this.setCollection(headers, items, filter);
  }
  setCollection(headers, items, filter) {
    var collection = [];
    // push filtered data in result
    headers.forEach(header => {
      if (items[header]) {
        collection = collection.concat(sort(filter, items[header]));
      }
    });
    // update the collection
    this.setPage(0, collection);
    function sort(header, items) {
      Core.setKeyValue('list-header', header);
      Core.setKeyValue('list-header-rec-todo', !!header.match(/Recruiter Todo/i));
      Core.setKeyValue('list-header-emp-todo', !!header.match(/Employer Todo/i));
      switch (header) {
        case "Introduced":
          return items
            .sort((a, b) =>
              String(a.introduced).localeCompare(String(b.introduced))
            )
            .reverse();
        case "Candidate Todo":
        case "Recruiter Todo":
        case "10x10 Todo":
        case "Employer Todo":
          return items
            .map(item => {
              // update filters to do status transition if needed.
              item.filters = EngagementModel.mapFilters(item);
              return item;
            })
            .sort((a, b) => a.filters[header].localeCompare(b.filters[header]));
        case "Stage":
          return items.sort((a, b) => ~~a.open - ~~b.open);
        default:
          return items;
      }
      /** * /
       function stageIndex(item) {
        return [
          "End",
          "Hire",
          "Guarantee",
          "Offer",
          "Onsite",
          "Screen",
          "Review",
          "Submission",
          "Confirmation",
          "Pending"
        ].indexOf(item.stage);
      }
       /** */
    }
  }
  setPage(index, collection) {
    const Card = this.props.card;
    collection = collection || this.state.collection;
    index =
      index < 0 ? 0 : index > collection.length ? collection.length : index;
    const page = collection.slice(index, index + itemsPerPage);
    this.setState({
      index,
      collection,
      page: page.length ? (
        page.map(model => (
          <Card
            key={`list-card-${model.id}`}
            model={model}
            params={this.props.params}
            parent={this}
            parentModel={this.props.parent.state}
            source={this.props.source || "ListMatch"}
            onCheck={this.props.onCheck}
          />
        ))
      ) : (
        <div className="not-found">No matches</div>
      )
    });
    Debug.showTimeEnd("Render List");
    this.scroller && this.scroller.scrollTo(0, 0);
  }


  render() {
    const collectionLength = this.state.collection.length;
    const previousIndex = this.state.index - itemsPerPage;
    const currentIndex = this.state.index;
    let nextIndex = this.state.index + itemsPerPage;
    nextIndex = nextIndex > collectionLength ? collectionLength : nextIndex;
    return (
      <Fragment>
        {this.props.source === 'JobMatch' && <div className={"list-scroller"}>
          <Table style={{ marginBottom: 10 }}>
            <TableBody displayRowCheckbox={false}>
              <TableRow style={{ backgroundColor: 'lightgoldenrodyellow' }} >
                <TableRowColumn style={
                  {
                    width: 36,
                    textAlign: "right",
                    paddingLeft: 0,
                    paddingRight: 0
                  }}
                >
                </TableRowColumn>

                <TableRowColumn
                  title={`${this.props.parentModel._platformRating || this.props.parentModel._employeeRating}|${this.props.parentModel.yearsOfExperience || this.props.parentModel.minYearsOfExperience}|${this.props.parentModel.desiredSalary || this.props.parentModel.salaryMax}|${this.props.parentModel._visa || this.props.parentModel._visaTransfer}|${this.props.parentModel._officeLocations || this.props.parentModel._locations}`}
                  className="first-item"
                  colSpan={3}
                >
                  <p>Rating|Exp|Comp|location|visa</p>
                  {this.props.parentModel._platformRating || this.props.parentModel._employeeRating}&nbsp;&nbsp;|&nbsp;&nbsp;
                  {this.props.parentModel.yearsOfExperience || this.props.parentModel.minYearsOfExperience}&nbsp;&nbsp;|&nbsp;&nbsp;
                  {this.props.parentModel.desiredSalary || this.props.parentModel.salaryMax}&nbsp;&nbsp;|&nbsp;&nbsp;
                  {this.props.parentModel._officeLocations || this.props.parentModel._locations}&nbsp;&nbsp;|&nbsp;&nbsp;
                  {this.props.parentModel._visa || this.props.parentModel._visaTransfer}&nbsp;&nbsp;|&nbsp;&nbsp;
                </TableRowColumn>

                <TableRowColumn
                  title={this.props.parentModel._technicalSkills}
                  className="mid-col"
                  colSpan={2}
                >
                  <p>Skills</p>
                  {this.props.parentModel._technicalSkills}
                </TableRowColumn>
                <TableRowColumn
                  title={this.props.parentModel.employer._technicalSkills}
                  className="mid-col"
                >
                  <p>Tech Stack</p>
                  <p>{this.props.parentModel.employer._technicalSkills}</p>
                </TableRowColumn>
                <TableRowColumn
                  title=""
                  className="mid-col"
                >
                  <p>Others</p>
                  <p>N/A</p>
                </TableRowColumn>
              </TableRow>
            </TableBody>
          </Table>
        </div>}
        <div className="list">
          <div className="sort-buttons">
            <div className="nowrap scroll-x align-right">
              {!this.props.disableTabs &&
                !!this.props.tabs &&
                !!this.props.tabs.length &&
                this.props.tabs
                  .filter(tab => {
                    if (tab === "Closed") {
                      if (Core.isRecruiter()) {
                        return false;
                      } else if (!this.parent.state.showAll) {
                        return false;
                      }
                    }
                    if (/todo/i.test(tab)) {
                      if (this.parent.state.showAll) {
                        return false;
                      }
                    }
                    return true;
                  })
                  .map((tab, index) => (
                    <FlatButton
                      key={`tab${tab}`}
                      className={`sort-button${this.state.tab === tab ? " tab-selected" : ""
                        }`}
                      label={tab}
                      onClick={event => this.selectTab(tab)}
                    />
                  ))}
            </div>
            {!this.props.disableTabs &&
              !!collectionLength && (
                <div className="collection-controls inline-blocks align-left">
                  <div className="collection-info">
                    {currentIndex + 1} - {nextIndex} of {collectionLength}
                  </div>
                  <IconButton
                    className="icon24"
                    onClick={ev => this.setPage(0)}
                    disabled={currentIndex === 0}
                  >
                    <i className="material-icons">first_page</i>
                  </IconButton>
                  <IconButton
                    className="icon24"
                    onClick={ev => this.setPage(previousIndex)}
                    disabled={currentIndex === 0}
                  >
                    <i className="material-icons">navigate_before</i>
                  </IconButton>
                  <IconButton
                    className="icon24"
                    onClick={ev => this.setPage(nextIndex)}
                    disabled={nextIndex >= collectionLength}
                  >
                    <i className="material-icons">navigate_next</i>
                  </IconButton>
                  <IconButton
                    className="icon24"
                    onClick={ev =>
                      this.setPage(collectionLength - itemsPerPage)
                    }
                    disabled={nextIndex >= collectionLength}
                  >
                    <i className="material-icons">last_page</i>
                  </IconButton>
                </div>
              )}
          </div>
          <div
            ref={scroller => (this.scroller = scroller)}
            className="list-scroller"
          >
            {this.state.page}
          </div>
          {this.state.collection.length > 4 && (
            <div className="blocks fixed-bottom-right">
              <IconButton
                className="icon24"
                onClick={ev => this.scroller && this.scroller.scrollTo(0, 0)}
              >
                <i className="material-icons">expand_less</i>
              </IconButton>
              <IconButton
                className="icon24"
                onClick={ev => {
                  this.scroller &&
                    this.scroller.scrollTo(0, this.scroller.scrollTop + 500);
                }}
              >
                <i className="material-icons">expand_more</i>
              </IconButton>
            </div>
          )}
          {Core.isAdminOrRecruiterOnDev() && (
            <NavLink
              className="ui-m-max"
              exact
              to={`/${(this.props.name || "").toLowerCase()}/create`}
            >
              <FloatingActionButton
                className="list-floating-button"
                backgroundColor={colors.cyan}
              >
                <ContentAdd />
              </FloatingActionButton>
            </NavLink>
          )}
        </div>
      </Fragment>
    );
  }
}

export default List;
