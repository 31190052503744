/** ============================================ µ
 * @description EditHotJobsPaper [JSX]
 *              UI Component
 * @route       /create/announcements
 * @createdAt   2021-08-17 Tue
 * @updatedAt   2021-08-18 Wed
 * ============================================ */
/* IMPORTS ==================================== */

import { Button, Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import getTemplateModel from "../../lib/models/template.model";
import TemplateLib, { TEMPLATE_NAME_HOT_JOBS_TRUSTED, TEMPLATE_TYPE_DOCUMENT } from "../../lib/Template.lib";
import Col from "../Forms/Col";
import Row from "../Forms/Row";
import RichTextBox from "../Forms/RichTextBox";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function EditHotJobsPaper(props) {
  const { t } = useTranslation();
  const [template, setTemplate] = React.useState(true);
  const [hotJobs, setHotJobs] = React.useState('');
  const [enableSave, setEnableSave] = React.useState(false);

  React.useEffect(() => {
    if (template === true) {
      TemplateLib.getBy({
        type: TEMPLATE_TYPE_DOCUMENT,
        name: TEMPLATE_NAME_HOT_JOBS_TRUSTED,
        setTemplate: _setTemplate
      });
    }
  }, [template]);

  const _setTemplate = template => {
    setTemplate(template);
    setHotJobs(template.templates.content || '');
  }
  const _onClickSave = event => {
    const _template = template === true ? getTemplateModel() : template;
    _template.templates.content = hotJobs;
    TemplateLib.upsert({
      template: _template,
      type: TEMPLATE_TYPE_DOCUMENT,
      name: TEMPLATE_NAME_HOT_JOBS_TRUSTED,
      setTemplate: setTemplate,
    })
    setEnableSave(false);
  }
  return (
    <Paper className="p-2 my-2 mx-auto" style={{ minWidth: 280, maxWidth: 990 }}>
      <Row>
        <Col className="d-flex px-0" fullWidth>
          <label>
            <i>{t('Hot Jobs!! 🔥')}</i>
          </label>
          {enableSave && (
            <Button className="ml-auto" onClick={_onClickSave}>
              Save
              <i className="material-icons">save</i>
            </Button>
          )}
        </Col>
      </Row>
      <RichTextBox
        value={hotJobs}
        onChange={html => {
          setHotJobs(html);
          if (hotJobs !== html) {
            setEnableSave(true);
          }
        }}
      />
    </Paper>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { EditHotJobsPaper as default, EditHotJobsPaper };

/* ============================================ */