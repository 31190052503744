import React, {Component, Fragment} from "react";

import ChipElement from './Chip';
import TextElement from './Text';
import ComplexElement from './Complex';
import OperatorElement from './Operator';
import AndElement from '../Operators/And';
import OrElement from '../Operators/Or';
import NotElement from '../Operators/Not';
import NumberDropdown from "../Shared/NumberDropdown";
import OperatorDropdown from "../Shared/OperatorDropdown";
import ComparisonHaves from "../../../Candidates/MatchNew/CandidatePipe/ComparisonHaves";
import ChipGroup from "../../../Candidates/MatchNew/CandidatePipe/ElementsComparisonHaves/ChipGroup";
import BasicChip from "../../../Candidates/MatchNew/CandidatePipe/ElementsComparisonHaves/Chip";
import ChipTag from "../../../Candidates/MatchNew/CandidatePipe/ElementsComparisonHaves/ChipTag";

const ElementComponents = {
    'chip': ChipElement,
    'text': TextElement,
    'complex': ComplexElement,
    'operator': OperatorElement,
    'and': AndElement,
    'or': OrElement,
    'not': NotElement,
};

const Operators = [
    {name: 'and'},
    {name: 'or'},
    {name: 'not'}
];

class Complex extends Component {

    constructor(){
        super(...arguments);
        this.state = {
            element: this.props.element
        };
    }

    handlerOperatorOption = (operator) => {

        let {element} = this.state;
        element.type = operator;
        this.setState({element, openOperatorDD: false}, () =>{
            const {onApply} = this.props;
            !!onApply && onApply(element);
        });
    }

    componentWillReceiveProps (nextProps) {
        if(nextProps.element !== this.props.element){
            this.setState({element: nextProps.element});
          }
    }

    handlerNumberOption = (number) => {

        let {element} = this.state;
        element.atleast = number;
        this.setState({element}, () =>{
            const {onApply} = this.props;
            !!onApply && onApply(element);
        });

    }

    handlerSpecialInstruction = (text) => {

        let {element} = this.state;
        element.specialInstruction = text.target.value;
        this.setState({element}, () =>{
            const {onApply} = this.props;
            !!onApply && onApply(element);
        });
    }

    handlerCategory = (category) => {

        let {element} = this.state;
        element.category = category;
        this.setState({element}, () =>{
            const {onApply} = this.props;
            !!onApply && onApply(element);
        });
    }

    render() {

        let {onlyView, toMatchWith,candidateResume,pageSource} = this.props;
        const {element} = this.state;

        return <Fragment>
            {!!onlyView && !!toMatchWith && <ComparisonHaves
                element={element}
                toMatchWith={toMatchWith}
                candidateResume={candidateResume}
            />}
            {!!element.type &&
                <OperatorDropdown
                    type={element.type}
                    handlerOperator={this.handlerOperatorOption}
                    onlyView={onlyView}
                    operators={Operators}
                />
            }&nbsp;&nbsp;
            {!!element.atleast && element.type ==='or' &&
                <Fragment>
                    <NumberDropdown
                        atleast={element.atleast}
                        handlerNumber={this.handlerNumberOption}
                        onlyView={onlyView}
                    /><span>
                        of&nbsp;&nbsp;
                    </span>
                </Fragment>
            }

            {!!element.args && element.args.map((el, index) => {
                let type = el.type;

                if (['or', 'and', 'not'].includes(type)) {
                    type = 'complex';
                }

                if(type==='chipGroup'){
                    type = 'chip';
                }
                if(type==='chipTag'){
                    type = 'chip';
                }

                let isMatched = false;
                if(!!toMatchWith){
                    if(!!el.type && el.type === "chipGroup"){
                        isMatched = ChipGroup.result(el, toMatchWith);
                    }else if(!!el.type && el.type === "chipTag"){
                        isMatched = ChipTag.result(el,candidateResume);
                    }else{
                        isMatched = BasicChip.isChipMatched(el, toMatchWith);
                    }
                }
                let ElementComponent = ElementComponents[type];
                return <ElementComponent
                            key={index}
                            element={el}
                            onlyView={onlyView}
                            isMatchedComplex={isMatched}
                            toMatchWith={toMatchWith}
                            candidateResume={candidateResume}
                            source='complex'
                            pageSource={pageSource}
                        />
            })}
            {!onlyView && !!element.specialInstruction && <input className="fc-value-editor" value={element.specialInstruction} onChange={(ev) => this.handlerSpecialInstruction(ev)}/>}&nbsp;
            {!!onlyView && <span >{element.specialInstruction}</span>}&nbsp;
            {/* {!!element.category && <span style={{backgroundColor: 'green'}}>Category : {element.category}</span>} */}
            {/* {!!element.category && !onlyView &&
                <NewCategoryDropdown
                    category={element.category}
                    handlerCategory={this.handlerCategory}
                    onlyView={onlyView}
                />
            } */}
        </Fragment>
    }
}

export default Complex;
