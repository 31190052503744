import React, {Component, Fragment} from 'react';
import Row from "../../Forms/Row";
import Col from "../../Forms/Col";
import DeleteIcon from '@mui/icons-material/Delete';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import Button from '@mui/material/Button';
import Definition from "../../../lib/Definition";
import Company from '../../../lib/Company';
import _ from "lodash";
import AlternativePopup from "./AlternativePopup";
import Core from "../../../lib/Core";
import ExperiencePositionHistory from './ExperiencePositionHistory';
import InputArray from "../../Shared/InputArray";
import Tooltip from '@mui/material/Tooltip';
import TenAutoSuggest from "../../Shared/TenAutoSuggest";

// const filter = createFilterOptions();

class Experience extends Component {
  constructor() {
    super(...arguments);
    this.state = this.initialize();
    if (this.props.isNewRecord) {
      this.props.onDone(this.getUpdatedStructure());
    }
  }

  initialize() {
    let state = {};
    state = this.normalizeModel(this.props.element);
    state.positiveSignalsTags = state.positiveSignalsTags || [];
    state.negativeSignalsTags = state.negativeSignalsTags || [];
    state.tmpPositiveSignalsTags = [];
    state.tmpNegativeSignalsTags = [];
    state.companies = [];
    state.unicornCompanies = [];
    state.yCombCompanies = [];
    state.alternativeNamesPopup = false;

    return state;
  }

  componentDidMount() {
    if (!!this.state.employerOrgName) {
      this.findMyCompaniesTag();
      this.getCompanyInfo();
    }
  }



  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (Array.isArray(this.props.element.positiveSignalsTags) && !_.isEqual(this.state.positiveSignalsTags, this.props.element.positiveSignalsTags)) {
      let positiveSignalsTags = this.props.element.positiveSignalsTags || [];
      this.setState({positiveSignalsTags});
    }

    if (Array.isArray(this.props.element.negativeSignalsTags) && !_.isEqual(this.state.negativeSignalsTags, this.props.element.negativeSignalsTags)) {
      let negativeSignalsTags = this.props.element.negativeSignalsTags || [];
      this.setState({negativeSignalsTags});
    }

    // if (
    //   this.props.element.employerOrgName !== this.state.employerOrgName
    // ) {
    //  this.setState({employerOrgName: this.props.element.employerOrgName})
    // }
  }

  getCompanyInfo = (value, cb, forceAddingTagsToPositiveSignals = false) => {
    let {employerOrgName} = this.state;

    if(!!value) {
      employerOrgName = value
    }

    if(!employerOrgName){
      return;
    }

    Company.get((response) => {
        this.setState({companies: response, shouldShowCompanyBgColor: true}, () => {
          this.findMyCompaniesTag(forceAddingTagsToPositiveSignals);
          !!cb && cb(response);
        });
      },
      {
        where: {
          or: [
            {name: {'like': String(employerOrgName).trim(), options: 'i'}},
            {alternativeNames: {'like':employerOrgName, options: 'i'}}
          ]
        },
        fields: ['alternativeNames', 'id', 'name', 'interviewBar', 'type'],
        limit: 5,

      })
  }

  getUpdatedStructure = () => {
    let {
      id, employerOrgName, isDefault, location,
      positiveSignalsTags, rankingMeta, negativeSignalsTags, positionHistories, isCurrentEmployer,
      chips
    } = this.state;

    const createdAt = this.props?.element?.createdAt;
    return {
      id,
      employerOrgName,
      positionHistories,
      isDefault,
      location,
      chips,
      isCurrentEmployer,
      positiveSignalsTags,
      negativeSignalsTags,
      rankingMeta,
      createdAt
    };
  };

  findMyCompaniesTag = (forceAddingToPositiveSignals = false) => {
    let {employerOrgName, companies, positionHistories} = this.state;
    let {extraInfo: { crossedPositiveSignals }} = this.props;

    let employer = employerOrgName;

    const isNewRecord = !this.props.element.createdAt;
    // const shouldAddToPositiveSignals = isNewRecord || forceAddingToPositiveSignals;

    const manageCompanyRanking = (company) => {
      if (!company) {
        company = '';
      }

      company = company.trim();

      let matched = companies.find(sc => {
        try {
          let regx = new RegExp(sc.name, 'i');
          return regx.test(company) || sc.alternativeNames.find(an => {
            let rgx = new RegExp(company, 'i');
            return rgx.test(an);
          });
        } catch (e) {
          return sc.alternativeNames.includes(company);
        }
      });

      let interviewBar = Object(matched).interviewBar || "";
      let type = Object(matched).type || [];

      let positiveSignalTagId = !!matched ? [interviewBar, ...type].filter(el => !!el) : [];
      let positiveSignalTagId2 = !!matched && !!Object(matched).type && !!Object(matched).type.length ? Definition.getId('positiveSignals', Object(Object(matched).type.pop()).name) : "";


      if (!matched) {
        this.setState({tmpPositiveSignalsTags: []}, () => {
          this.props.onDone(this.getUpdatedStructure())
        });
      }

      return {
        posTags: [...positiveSignalTagId, positiveSignalTagId2].filter(obj => !!obj),
        companyMatched: matched
      };
    };

    const manageWordLabels = (company) => {

      const tagsFromCompanyName = (company) => {
        const list = ['Infosys', 'Tata', 'Cognizant', 'Wipro']; // April - Most the constants to a different file for easy udpates
        let id;

        let foundWords = list.filter(word => {
          try {
            let regex = new RegExp(word, 'i');
            return regex.test(company);
          } catch (e) {
            return false;
          }

        });

        if (!!foundWords.length) {
          id = Definition.getId('negativeSignals', 'Consulting Background');
        }

        return id;
      };

      let negSign = [tagsFromCompanyName(company)].filter(el => !!el);
      return {negativeSignals: negSign};
    };

    const textMatch = (description, key) => {
      if (!description) {
        description = '';
      }

      let words = [
        {label: 'github', tag: 'Github'},
        {label: 'hackathon *win*', tag: 'Hackthon'},
        {label: 'hackthon', tag: 'Hackthon'},
        {label: 'side project', tag: 'Side Project'},
        {label: 'open source contribution', tag: 'Open Source Project'},
        {label: 'founding time', tag: 'Founding Team'},
        {label: 'founding engineer', tag: 'Founding Team'},
        // {label: 'first * employee', tag: 'Founding Team'},
        // {label: 'first * engineer', tag: 'Founding Team'},
        {label: 'founder', tag: 'Founding Team'},
        {label: 'co-founder', tag: 'Founding Team'},
        {label: 'cofounder', tag: 'Founding Team'},
        {label: 'patent', tag: 'Patent'},
        {label: 'promoted to', tag: 'Promotion'},
        {label: 'interview panel', tag: 'Interview Team'},
        {label: 'interview bar raiser', tag: 'Interview Team'},
        {label: 'scholarship', tag: 'Scholarship'},
        {label: 'mentoring', tag: 'Mentor'},
        {label: 'mentor', tag: 'Mentor'},
      ];

      if(key === 'employer') {
        words = [
          {label: 'facebook', tag: 'FAANG'},
          {label: 'amazon', tag: 'FAANG'},
          {label: 'netflix', tag: 'FAANG'},
          {label: 'apple', tag: 'FAANG'},
          {label: 'google', tag: 'FAANG'}
        ];
      }

      let foundWords = words.filter(word => {
        try {
          let regex = new RegExp(word.label, 'i');
          return regex.test(description);
        } catch (e) {
          return false;
        }
      });

      let tagIds = foundWords.map(word => {
          return Definition.getId('positiveSignals', word.tag);
        }
      );
      return {textMatchPos: tagIds};
    };

    let {posTags, companyMatched} = manageCompanyRanking(employer);
    let {negativeSignals} = manageWordLabels(employer);
    const text = (positionHistories || []).map(ph => `${ph.title} ${ph.description}`).join(' ');
    const textEmployer = (positionHistories || []).map(ph => `${ph.title}`).join(' ');

    let {textMatchPos} = textMatch(text);
    let textEmployerResult = textMatch(textEmployer, 'employer');
    textMatchPos = [...textMatchPos, ...textEmployerResult.textMatchPos];

    let finalArrayPos = [...posTags, ...textMatchPos].filter(el => !!el);
    let finalArrayNeg = [...negativeSignals].filter(el => !!el);

    this.setState(
      {
        tmpPositiveSignalsTags:  Array.from(new Set(finalArrayPos)),
        tmpNegativeSignalsTags: Array.from(new Set(finalArrayNeg)),
        rankingMeta: companyMatched
      },
      () => {
        if(forceAddingToPositiveSignals){
          this.state.tmpPositiveSignalsTags.forEach(sig => {
            //queueing the state update, don't remove setTimeout otherwise only last tag will show result
            setTimeout(()=> {
              this.handlerPositiveSignalChipClick(sig)
            });
          })
        }else if(isNewRecord){
          this.state.tmpPositiveSignalsTags.filter(ps => !crossedPositiveSignals.includes(ps)).forEach(sig => {
            //queueing the state update, don't remove setTimeout otherwise only last tag will show result
            setTimeout(()=> {
              this.handlerPositiveSignalChipClick(sig)
            });
          })
        }
      }
    );

  };

  onApply = (intent, data) => {

    const onSave = (company) => {
      this.setState({rankingMeta: company}, ()=>{
        this.getCompanyInfo();
        this.onCancel();
      });
    };

    let {newName, alternativeNames, interviewBar, selected, type } = data;
    alternativeNames.filter(name => !!name);

    if (intent === 'add') {
      Company.post({
        name: newName,
        alternativeNames,
        interviewBar,
        type
      }, (response) => {
        !!onSave && onSave(response);
      })
    } else {
      Company.update(selected.value, {
        alternativeNames,
        interviewBar,
        type
      }, (response) => {
        !!onSave && onSave(response);
      });
    }
  };

  onCancel = () => {
    // const {rankingMeta, alternativeNamesPopup} = this.state;
    this.setState({shouldShowCompanyForm: false});
    // const names = rankingMeta.alternativeNames;
    // this.setState({
    //     rankingMeta: {
    //         ...rankingMeta,
    //         alternativeNames: names.slice(0, -1)
    //     },
    //     alternativeNamesPopup: !alternativeNamesPopup
    // });
  }

  normalizeModel = (model) => {
    return {
      id: model.id,
      employerOrgName: model.employerOrgName,
      positionHistories: model.positionHistories,
      description: model.description,
      isCurrentEmployer: model.isCurrentEmployer,
      positiveSignalsTags: model.positiveSignalsTags,
      negativeSignalsTags: model.negativeSignalsTags,
      createdAt: model.createdAt
    }
  };

  handlerAddAlternativeNames = () => {
    let {rankingMeta} = this.state;
    rankingMeta = Object(rankingMeta);
    this.setState({
      shouldShowCompanyForm: true,
      companyFormIntent: 'addAlternativeName',
      companyFormObject: !!rankingMeta ? {
        value: rankingMeta.id,
        label: rankingMeta.name
      } : null
    });
  };

  handlerAddCompany = () => {
    this.setState({
      shouldShowCompanyForm: true,
      companyFormIntent: 'add',
      companyFormObject: null
    });
  };

  handlerCompanyEdit = () => {
    let {rankingMeta} = this.state;
    rankingMeta = Object(rankingMeta);
    this.setState({
      shouldShowCompanyForm: true,
      companyFormIntent: 'edit',
      companyFormObject: !!rankingMeta ? {
        value: rankingMeta.id,
        label: rankingMeta.name
      } : null
    });
  };

  dropdownDataLoad = (keyword, callback) => {
    setTimeout(() => {
      const ilikeStruct = {like: `.*${String(keyword).trim()}.*`, options: "i"};

      const opts = {
        limit: 50,
        fields: ['id', 'name', 'alternativeNames', 'interviewBar', 'type'],
        where: {
          or: [
            {name: ilikeStruct},
            {alternativeNames: keyword},
          ]
        }
      };

      Company.get(companies => {
        callback(companies);
      }, opts);
    });

  };

  dropdownFormatter = el => {
    let label = `${el.name}`;
    return {value: el.id, label}
  };

  handlerPositiveSignalChipClick = (id, shouldRemove) => {
    let {
      positiveSignalsTags
    } = this.state;

    if(shouldRemove){
      positiveSignalsTags = positiveSignalsTags.filter(tag=>tag!==id);
    }else{
      positiveSignalsTags =  [...positiveSignalsTags, id];
    }

    this.setState({positiveSignalsTags}, () => {
      this.props.onDone(this.getUpdatedStructure());
    });
  };

  render() {
    console.log({ppp:this.props})
    let {element, handlerDeleteItem, onDone, markAsDefault, kk} = this.props;

    let {
      employerOrgName, isDefault, tmpPositiveSignalsTags,
      positiveSignalsTags, negativeSignalsTags, tmpNegativeSignalsTags, rankingMeta,
      positionHistories, isCurrentEmployer, shouldShowCompanyForm,
      companyFormObject, companyFormIntent, shouldShowCompanyBgColor,
    } = this.state;

    let updatedElement = this.getUpdatedStructure();
    const companyMissingButPositionHistoryExists =  _.isEmpty(employerOrgName) && (positionHistories||[]).length;
    let backgroundColor = (Core.isAdmin() && !!shouldShowCompanyBgColor && !rankingMeta) ? 'gold' :  'ghostwhite';

    if(companyMissingButPositionHistoryExists){
      backgroundColor = 'red';
    }

    return <div style={{backgroundColor, marginBottom: '10px', paddingBottom: '20px', width: '100%'}}>
      {isDefault && <Row>
        <p style={{color: 'white'}}>This is default</p>
      </Row>}
      <Row>
        <Col fullWidth>
          <label>{kk}Company {isCurrentEmployer ? "(current) " : ''}
              - Positions ({(positionHistories || []).length})&nbsp;&nbsp;
            {Core.isAdmin() && shouldShowCompanyBgColor &&
            <span>{!rankingMeta && (employerOrgName || "").length >= 2 ? <Fragment>
              <Tooltip title="Add as alternative name">
                <AllInboxIcon style={{cursor: 'pointer'}} onClick={this.handlerAddAlternativeNames}/>
              </Tooltip>
              <Tooltip title="Add as a new company">
                <AddIcon style={{cursor: 'pointer'}} onClick={this.handlerAddCompany}/>
              </Tooltip>
            </Fragment> : !!rankingMeta && <Tooltip title="Edit this company/employer">
              <CreateIcon style={{cursor: 'pointer'}} onClick={this.handlerCompanyEdit}/>
            </Tooltip>
            }</span>}</label>

          <TenAutoSuggest
            value={employerOrgName||""}
            onChange={(ev, employerOrgNameField) => {
              if(String(employerOrgName).toLowerCase() !== String(employerOrgNameField).toLowerCase()){
                this.setState({employerOrgName: employerOrgNameField, shouldShowCompanyBgColor: false}, () => {
                  onDone(this.getUpdatedStructure());
                  this.getCompanyInfo(null, null,true);
                })
              }
            }}
            onBlur={(ev,employerOrgNameField) => {
              if(String(employerOrgName).toLowerCase() !== String(employerOrgNameField).toLowerCase()){
                this.setState({employerOrgName: String(employerOrgNameField).trim(), shouldShowCompanyBgColor: false}, () => {
                  onDone(this.getUpdatedStructure());
                  this.getCompanyInfo(null, null,true);
                })
              }
            }}
            handleSuggestionsFetchRequested={(value, callback) => {
              if(String(employerOrgName).toLowerCase() !== String(value).toLowerCase()){
                  this.getCompanyInfo(value, (response) => {
                    callback(response.map(school=> ({label: school.name})))
                  });
              }
            }}
          />
        </Col>
      </Row>

      <Row style={{flexDirection: 'column'}}>
        <InputArray
          source="candidateEdit"
          heading="Add position"
          existing={positionHistories}
          elementComp={ExperiencePositionHistory}
          parentUpdater={(result) => {
            this.setState({positionHistories: result}, () => {
              //this.findMyCompaniesTag();
              onDone(this.getUpdatedStructure());
            });
          }}
        />
      </Row>

      {Core.isAdmin() && shouldShowCompanyForm &&
      <AlternativePopup
        intent={companyFormIntent}
        newName={!rankingMeta ? employerOrgName : ''}
        selected={companyFormIntent === 'edit' ? companyFormObject : null}
        onCancel={this.onCancel}
        onApply={this.onApply}
        dropdownDataLoader={this.dropdownDataLoad}
        dropdownFormatter={this.dropdownFormatter}
        source={"company"}
      />
      }
      <Row>
        <Col fullWidth>
          <Button onClick={() => handlerDeleteItem(element)} variant="contained" size="small" className={''}>
            <DeleteIcon/>
            Remove
          </Button>&nbsp;&nbsp;
          {/*<Button onClick={()=> onDone(updatedElement)} variant="contained" size="small" className={''}>*/}
          {/*<SaveIcon/>*/}
          {/*Save*/}
          {/*</Button>&nbsp;&nbsp;*/}
          <Button onClick={() => markAsDefault(updatedElement)}
                  variant="contained" size="small"
                  className={''}>
            Set Default
          </Button>&nbsp;&nbsp;

          {tmpPositiveSignalsTags.filter(el => !!el).map(id => {
            return <Fragment>
              <Button style={{backgroundColor: 'green'}}
                      onClick={() => {
                        const shouldRemove = positiveSignalsTags.includes(id);
                        this.handlerPositiveSignalChipClick(id, shouldRemove);
                      }}
                      variant="contained" size="small"
                      className={''}>
                {Definition.getLabel('positiveSignals', id)}
                {positiveSignalsTags.includes(id)  && <span>(Added)</span>}
              </Button>&nbsp;&nbsp;</Fragment>;
          })}

          {tmpNegativeSignalsTags.filter(el => !!el).map(id => {
            return <Fragment><Button style={{backgroundColor: 'red'}} onClick={() => {
              this.setState({negativeSignalsTags: [...negativeSignalsTags, id]}, () => {
                this.props.onDone(this.getUpdatedStructure());
              });
            }}
                                     variant="contained" size="small"
                                     className={''}>
              {Definition.getLabel('negativeSignals', id)} {negativeSignalsTags.includes(id) &&
            <span>(Added)</span>}
            </Button>&nbsp;&nbsp;</Fragment>;
          })}
        </Col>

      </Row>
    </div>

  }
}

export default Experience;
