import React, {Component} from "react";

class Or extends Component {

    render() {
        const {decision, atLeast} = this.props;
        let text = <span><i style={{fontSize: '20px'}} className="material-icons">cancel</i></span>;

        let addAllOrTypes = 0;
        decision.forEach(dec => {
            addAllOrTypes += dec.group;
        });
        if(addAllOrTypes >= atLeast){
            text = <span><i style={{fontSize: '20px'}} className="material-icons">done</i></span>;
        }
        return text;
    }
}

export default Or;
