import React, {Component, Fragment} from "react";
import CandidateCard from "./Card/CandidateCard";
import Candidate from "../../lib/Candidate";
import Job from "../../lib/Job";

import List from "../List/List";
import Core, {colors} from "../../lib/Core";
import {NavLink} from "react-router-dom";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import ConflictDetector from "../../lib/services/Candidates/ConflictDetector";
import {accountIncludeForCandidate, fieldsForCandidatePage} from '../../lib/Constants';

class CandoDuplicateDetails extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            candidateDuplicatesList: [],
            candidate: null,
            processingComplete: false
        }
    }

    componentDidMount() {
        const {candidateId} = this.props;
        Candidate.get(candidateId, (cando) => {
            this.setState({candidate: cando}, () => {
                Candidate.getPotentialDuplicated(cando, (result) => {
                    Job.getWhere({
                        id: {
                            inq: cando.jobsPermitted
                        }
                    }, (jobs) => {
                        cando._jobsPermitted = jobs;
                        new ConflictDetector(cando, result, (detectorResult) => {
                            console.log({detectorResult});
                            let allJobIds = [];

                            result.forEach(element => {
                                const duplicated = (detectorResult.matchingPool || []).find(el => String(el.id) === String(element.id));
                                if (!!duplicated) {
                                    console.log({duplicated});
                                    element = {...element, ...duplicated};
                                }
                                allJobIds = [...allJobIds,...element.jobsPermitted];
                            });

                            const params= {
                                include: [
                                    ...accountIncludeForCandidate,
                                    // { engagements: { job: "employer" } },    // not required for card information, on expand, it calls for engagements
                                    {
                                        relation: "candidateStarreds",
                                        scope: {
                                            where: { accountId: Core.getUserId() }
                                        }
                                    }
                                ],
                                fields: fieldsForCandidatePage,
                                where: {
                                    id: {
                                        inq: result.map(cando=>cando.id)
                                    }
                                }
                            };

                            //need to do this to fetch all required attrs of cando
                            Candidate.getAll(candidates => {
                                Job.getWhere({
                                    id:{
                                        inq: allJobIds
                                    },
                                },(allJobs)=>{
                                    const matchPoolCandoIds = detectorResult.matchingPool.map(cando=>cando.id);
                                    let compiledCandos = result.filter(cando=>matchPoolCandoIds.includes(cando.id)).map(cando=>{
                                        const jobsPermitted = allJobs.filter(job=>cando.jobsPermitted.includes(String(job.id)));
                                        let candoDetails = candidates.find(c=>String(c.id)===String(cando.id));
                                        return {...cando,...candoDetails,_jobsPermitted:jobsPermitted, jobsPermitted:cando.jobsPermitted};
                                    });

                                    setTimeout(() => {
                                        this.List.setItems(compiledCandos);
                                    });
                                    this.setState({candidateDuplicatesList: compiledCandos, detectorResult, processingComplete: true});
                                }, {
                                    include: [{
                                        relation: 'employer',
                                        scope: {
                                            fields: ['id','name']
                                        }
                                    }],
                                    fields: ['employerId','id','jobTitle']
                                });
                            },params,true);
                        });
                    })

                })
            });
        })
    }

    render() {
        const {candidate, processingComplete} = this.state;

        return <Fragment>
            {!!candidate && <Fragment>
                <p>{[candidate.email,candidate.phone,candidate.linkedInURL,candidate.gitHubURL].filter(el=>!!el).join(' || ')}</p>
                <CandidateCard source="candoDuplicateDetails" model={candidate} parent={this}/>
            </Fragment>}
            {!!processingComplete && <List
                ref={List => (this.List = List)}
                parent={this}
                tabs={
                    Core.isRecruiter()
                        ? Candidate.listTabsRecruiters
                        : Candidate.listTabs
                }
                tab={Candidate.listTab}
                name="Candidate"
                card={DuplicateCard}
                source={"duplicate-candidates"}
                floatingButton={
                    <NavLink className="ui-m-max" exact to={`/candidate/create`}>
                        <FloatingActionButton
                            className="list-floating-button"
                            backgroundColor={colors.cyan}
                        >
                            <ContentAdd/>
                        </FloatingActionButton>
                    </NavLink>
                }
            />}</Fragment>;
    }
}

export default CandoDuplicateDetails;

const DuplicateCard = (props) => {
    const model = props.model;
    const permittedJobs = model._jobsPermitted.map(job=>job._name);
    return <Fragment>
        <p>{model.isStrongConflict ?
            <span>Strong Conflict on <mark>{model.attrsThatConflictedStrong.join(',')}</mark></span> : (model.isWeakConflict&&!model.isPotentialStrongConflict) ?
                <span>Weak Conflict on <mark>{model.attrsThatConflictedWeak.join(',')}</mark></span> : ""} </p>
        <p>{model.isPotentialStrongConflict && !model.isStrongConflict ?
            <Fragment>Potential Strong Conflict on <mark>{model.attrsThatConflictedStrong.join(',')}</mark>
            </Fragment> : ""}</p>
        <p>{!!model.jobsPermitted.length ?
            <Fragment>permitted jobs: <mark>{permittedJobs.join(', ')}</mark></Fragment> : ''}</p>

        <p>{[model.email,model.phone,model.linkedInUrl,model.gitHubURL].filter(el=>!!el).join(' || ')}</p>
        <CandidateCard  key={`dup-cando-${model.id}`} {...props} model={props.model} parent={props.parent} source="candoDuplicateDetails"/>
    </Fragment>
};