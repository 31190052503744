import React, { Component } from "react";

import { colors } from "../../lib/Core";

class DetailItem extends Component {
  render() {
    let style = {
      padding: "0 16px 16px",
      width: "100%",
      minHeight: 30,
      whiteWpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    };
    if (this.props.inlineBlock === true) {
      style = {
        ...style,
        display: "inline-block",
        width: this.props.width || "30%"
      };
    }
    return <div style={style}>
        <span style={{ color: colors.gray, fontSize: 12 }}>
          {this.props.label}
        </span>
        <br />
        {this.props.value||'-'}
      </div>
  }
}

export default DetailItem;
