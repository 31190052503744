import React, { Fragment } from "react";
import Grid from '@mui/material/Grid';
import Core from "../Core";

const baseUrl = Core.getBasePath();

const excludeEngagementsInCount = [
  'Confirmation'
];

const engagementStages = [
    // {key:"confirmation", label:"Confirmation"},
    {key:"submission",   label:"Submission"},
    {key:"screen",       label:"Screen"},
    {key:"review",       label:"Review"},
    // {key:"pending",      label:"Pending"},
    {key:"onsite",       label:"Onsite"},
    {key:"offer",        label:"Offer"},
    {key:"guarantee",    label:"Guarantee"},
    {key:"hire",         label:"Hire"},
    {key:"end",          label:"End"},
];

const getProcessedData = (structure, stage, count) => {
    let stageLabels = engagementStages.map(stage => stage.label);
      
    let eachDataInStructure = [];
    let newData = [], fetchCount = 0;
    let totalCountOfEngagements = 0;

    structure.forEach(struct => {
        newData = [];
        eachDataInStructure = struct.data.map(data => data[stage]);
        struct['totalCount'] = 0;

        stageLabels.forEach(lab => {
          if(eachDataInStructure.includes(lab)){
            fetchCount = struct.data.find(strct=>strct[stage] === lab)[count];
            struct['totalCount'] += fetchCount;
            if(!excludeEngagementsInCount.includes(lab)){
              totalCountOfEngagements += fetchCount;
            }
            newData.push({[stage]: lab, [count]: fetchCount});
          }
        });
        struct.data = newData;
    } );

    return {totalCountOfEngagements};
}

const render = (props) => {
    
    let {structure, stage, count, name, title, makeLink, lastSubmission} = props;
    
    const {totalCountOfEngagements} = getProcessedData(structure, stage, count);

    if(!totalCountOfEngagements){
      return <div>No Engagements</div>;
    }
    
    let submissionDate = null;
    if(!!lastSubmission){
      submissionDate = `| Last Submission Date : ${lastSubmission}`;
    }

    return <Fragment>{!!totalCountOfEngagements && (<div>{totalCountOfEngagements} Open Engagement(s) {submissionDate}</div>)}<div>{structure.map((eng, index)=> {
        return <Fragment key={index}>
          <Grid container style={{padding: '10px 0px'}}>
            <Grid item xs={12}>
              {title} : {!!makeLink ? <span><a href={`${baseUrl}/#/${makeLink.path}/${eng.jobId}`}>{eng[name]}</a>&nbsp;&nbsp;|&nbsp;&nbsp;Engagement(s) : {eng['totalCount']}</span> : <span>{eng[name]}&nbsp;&nbsp;|&nbsp;&nbsp;Engagement(s) : {eng['totalCount']}</span>}
            </Grid>
          </Grid>
          <Grid container>
            {eng.data.map((dat,InnerIndex)=> {
              return  <Grid key={InnerIndex} item xs={1}>
                <Grid container style={{padding: '5px 0px'}}>
                  <Grid item xs={12}>
                    {dat[stage]}
                  </Grid>
                </Grid>
                <Grid container style={{padding: '5px 0px'}}>
                  <Grid item xs={12}>
                    {dat[count]}
                  </Grid>
                </Grid>
              </Grid>

            })}
          </Grid>
        </Fragment>
      })}
    </div></Fragment>;
}

export default render;