/** ============================================ µ
 * @description Bookmark [JSX]
 *              V3-table UI Component
 * @createdAt   2021-10-22 Fri
 * @updatedAt   2021-10-22 Fri
 * ============================================ */
/* IMPORTS ==================================== */

import { IconButton } from '@mui/material';
import { Divider, Input } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getBookmarkModel, mapBookmark } from '../../lib/models/account';

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function Bookmark({
  enhancedReactTableContext: {
    context: { location },
    updateUrlState,
  },
  bookmark = getBookmarkModel(),
  upsertBookmark,
  deleteBookmark,
  showNewBookmark,
  setShowNewBookmark,
}) {
  const isNewBookmark = (showNewBookmark !== undefined);
  const [label, setLabel] = useState();
  const [pathname, setPathname] = useState();
  const [search, setSearch] = useState();
  const _bookmark = mapBookmark({
    createdAt: bookmark.createdAt,
    label: (label !== undefined)
      ? label
      : isNewBookmark
        ? bookmark.createdAt
        : bookmark.label,
    pathname: (pathname !== undefined)
      ? pathname
      : isNewBookmark
        ? decodeURIComponent(location.pathname)
        : bookmark.pathname,
    search: (search !== undefined)
      ? search
      : isNewBookmark
        ? decodeURIComponent(location.search)
        : bookmark.search,
  });
  const updated = !_.isEqual(bookmark, _bookmark);
  return (isNewBookmark && !showNewBookmark) ? null : (
    <div className={isNewBookmark ? 'bg-a-yellow' : ''}>
      <div className="d-flex flex-align-left-center ml-1">
        {isNewBookmark ? (
          <>
            <Input
              addonBefore={isNewBookmark && 'Label'}
              title={!isNewBookmark && 'Label'}
              className="mr-1"
              style={{ width: 530 }}
              value={_bookmark.label}
              onChange={(event, value) => setLabel(event.target.value)}
              allowClear
            />
            <Input
              addonBefore={isNewBookmark && 'Pathname'}
              title={!isNewBookmark && 'Pathname'}
              className="mr-1"
              style={{ width: 530 }}
              value={_bookmark.pathname}
              onChange={(event, value) => setPathname(event.target.value)}
            />
            <Input
              addonBefore={isNewBookmark && 'Search'}
              title={!isNewBookmark && 'Search'}
              className="mr-1"
              value={_bookmark.search}
              onChange={(event, value) => setSearch(event.target.value)}
            />
          </>
        ) : (
          <NavLink to={`${_bookmark.pathname}${_bookmark.search}`} >
            {_bookmark.label}
          </NavLink>
        )}
        <div className="ml-auto">
          {isNewBookmark && (
            <IconButton
              className={updated ? 'bg-a-yellow mr-1' : 'mr-1'}
              onClick={(event) => {
                upsertBookmark({ bookmark: _bookmark });
                setShowNewBookmark(false);
                setLabel(undefined);
                setPathname(undefined);
                setSearch(undefined);
              }}
              disabled={!(upsertBookmark && updated)}
            >
              <i className="material-icons">save</i>
            </IconButton>
          )}
          {deleteBookmark && (
            <IconButton
              className="mr-1"
              onClick={(event) => {
                deleteBookmark(_bookmark);
                updateUrlState({ _updatedAt: new Date().toISOString() });
              }}
            >
              <i className="material-icons">delete</i>
            </IconButton>
          )}
        </div>
      </div>
      <Divider className="m-0" />
    </div>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { Bookmark as default, Bookmark };

/* ============================================ */
