import Definition from "./Definition";
import { YES } from "./GenericTools.lib";
import MatchLib from "./services/Matching/Match.lib";

function getChecked(selected, label) {
  let fromRegex = false;

  try {
    fromRegex = !!label.match(new RegExp(selected, "gi"));
  } catch (e) { }

  return selected ? fromRegex : false;
}

const setSources = (items = []) => {
  const sources = {};

  items.forEach((item) => {
    item.___keys___.forEach((label) => (sources[label] = true));
  });

  return Object.keys(sources).sort();
};

/**
 * This is setting the items into the main-menu-filters.
 *
 * @param {array} items - jobs-list if candidate match page, candidates-list if job match page.
 * @param {object} menus - main-menu-filters configuration from entity(Candidate|Job) library - In this will be set items attribute.
 * @param {string} selected - list of selected menu-filter-items in regex string format.
 * @param {object} paramsForLocation
 * @param {object} paramsForLocation.candidate - this is coming when you are in candidate match page.
 * @param {object} paramsForLocation.job - this is coming when you are in job match page.
 * @returns
 */
const setMenu = (
  items = [],
  menus,
  selected,
  { candidate, job } = {}
) => {

  const stopMakingDefaultSelected = ["technicalSkills", "level"];

  const inOfficeRemoteTags = Definition.get('inOfficeRemote');

  // console.debug('menus', menus);

  items.forEach((item) => {

    menus.forEach((menu) => {
      menu.items = menu.items || {};

      if (!!menu.options) {
        menu.items = menu.options;
      }
      else if (item[menu.key]) {

        if (
          YES(
            MatchLib.presetLocationMenus({
              menu,
              inOfficeRemoteTags,
              mainEntity: (job || candidate)
            })
          )
        ) {
          return;
        }

        /** @todo to cleanup | 2021-07-29 Thu µ */
        /* epic-3038(new locations)-story-3578-M2 | 2021-07-29 Thu µ */
        /* epic-3038(new locations)-story-3083-m1-2/story-3330-m2 - 2021-07-01 µ */
        /* JobMatch-CandidateMenu: "Remote Work Preference" */
        /* matching j.inOfficeRemoteFlags with c.inOfficeRemoteFlags * /
        if (!!job && (menu.key === 'inOfficeRemoteFlags')) {

          const candidate = item;

          job.inOfficeRemoteFlags = job.inOfficeRemoteFlags || [];
          candidate.inOfficeRemoteFlags = candidate.inOfficeRemoteFlags || [];

          candidate.inOfficeRemoteFlags.forEach(candidateRemotePreferenceId => {
            const candidateRemotePreferenceLabel = Definition.getLabel(
              'inOfficeRemote',
              candidateRemotePreferenceId
            );
            const itemValue = FilterControlLib.getItemValue({
              menu,
              itemLabel: candidateRemotePreferenceLabel
            });
            menu.items[itemValue] = (
              menu.items[itemValue] ||
              job.inOfficeRemoteFlags.includes(candidateRemotePreferenceId)
            );
          });

        }
        /** */

        /* epic-3038(new locations)-story-3083-m2-2/story-3330-m2 - 2021-07-01 µ */
        /* CandidateMatch-JobMenu: "Remote" */
        /* matching j.inOfficeRemoteFlags with c.inOfficeRemoteFlags * /
        else if (!!candidate && (menu.key === 'inOfficeRemoteFlags')) {

          const job = item;

          job.inOfficeRemoteFlags = job.inOfficeRemoteFlags || [];
          candidate.inOfficeRemoteFlags = candidate.inOfficeRemoteFlags || [];

          job.inOfficeRemoteFlags.forEach(jobRemotePreferenceId => {
            const jobRemotePreferenceLabel = Definition.getLabel(
              'inOfficeRemote',
              jobRemotePreferenceId
            );
            console.debug({ jobRemotePreferenceLabel });
            const itemValue = FilterControlLib.getItemValue({
              menu,
              itemLabel: jobRemotePreferenceLabel
            });
            menu.items[itemValue] = (
              menu.items[itemValue] ||
              candidate.inOfficeRemoteFlags.includes(jobRemotePreferenceId)
            );
          });

        }
        /** */

        /** @todo to cleanup | 2021-07-29 Thu µ */
        /* epic-3038(new locations)-story-3578-M2 | 2021-07-29 Thu µ */
        /* epic-3038(new locations)-story-3083-m1-3 - 2021-06 µ */
        /* JobMatch-CandidateMenu: "Desired Locations" */
        /* matching j.officeLocations with c.officeLocations * /
        else if (!!job && (menu.key === 'officeLocations')) {

          const candidate = item;

          job.officeLocations = job.officeLocations || [];
          candidate.officeLocations = candidate.officeLocations || [];

          /** POC * /
          job.officeLocations.forEach((officeLocationId) => {

            const officeLocationLabel = Definition.getLabel('location', officeLocationId);
            const itemValue = FilterControlLib.getItemValue({
              menu,
              itemLabel: officeLocationLabel
            });

            menu.items[itemValue] = (
              menu.items[itemValue] ||
              !!candidate.officeLocations.find(
                workLocationId => LocationLib.evalLineage({
                  memberA: officeLocationId,
                  memberB: workLocationId
                })
              )
            );

          });
          /** */

        /** * /
        candidate.officeLocations.forEach((workLocationId) => {

          const workLocationLabel = Definition.getLabel('location', workLocationId);
          const itemValue = FilterControlLib.getItemValue({
            menu,
            itemLabel: workLocationLabel
          });

          menu.items[itemValue] = (
            menu.items[itemValue] ||
            !!job.officeLocations.find(
              officeLocationId => LocationLib.evalLineage({
                memberA: officeLocationId,
                memberB: workLocationId
              })
            )
          );

        });
        /** * /

      }
      /** */

        /** @todo to cleanup | 2021-07-29 Thu µ */
        /* epic-3038(new locations)-story-3578-M2 | 2021-07-29 Thu µ */
        /* epic-3038(new locations)-story-3083-m2-3 - 2021-06 µ */
        /* CandidateMatch-JobMenu: "In-office Locations" */
        /* matching c.officeLocations with j.officeLocations * /
        else if (!!candidate && (menu.key === 'officeLocations')) {

          const job = item;

          candidate.officeLocations = candidate.officeLocations || [];
          job.officeLocations = job.officeLocations || [];

          job.officeLocations.forEach((officeLocationId) => {

            const officeLocationLabel = Definition.getLabel('location', officeLocationId);
            const itemValue = FilterControlLib.getItemValue({
              menu,
              itemLabel: officeLocationLabel
            });

            menu.items[itemValue] = (
              menu.items[itemValue] ||
              !!candidate.officeLocations.find(
                workLocationId => LocationLib.evalLineage({
                  memberA: officeLocationId,
                  memberB: workLocationId
                })
              )
            );

          });

        }
        /** */

        /** @todo to cleanup | 2021-07-29 Thu µ */
        /* epic-3038(new locations)-story-3578-M2 | 2021-07-29 Thu µ */
        /* epic-3038(new locations)-story-3083-m1-4/story-3079-M6 - 2021-06 µ */
        /* JobMatch-CandidateMenu: "Home Location" */
        /* matching j.candidateLocations or j.e.candidateLocations with c.candidateLocation * /
        else if (!!job && !!job.candidateLocations && (menu.key === 'candidateLocation')) {

          const candidate = item;
          const { candidateLocation: candidateLocationId } = candidate;
          const candidateLocationLabel = Definition.getLabel('location', candidateLocationId);
          const itemValue = FilterControlLib.getItemValue({
            menu,
            itemLabel: candidateLocationLabel
          });

          menu.items[itemValue] = (
            menu.items[itemValue] ||
            !!job.candidateLocations.find((candidateLocationId) => {

              // const candidateLocationLabel = Definition.getLabel('location', candidateLocationId); // just in case need it for debug

              /* epic-3038-story-3079-M6 (New Locations|job.employer.candidateLocations) 2021-06-30 µ * /
              if (candidateLocationId === LOCATION__EMP_HIRING_LOCS_ID) {

                const { employer = {} } = job;
                const { candidateLocations: employerCandidateLocations = [] } = employer;

                return employerCandidateLocations.find(hiringLocationId => {

                  // const hiringLocationLabel = Definition.getLabel('location', hiringLocationId); // just in case need it for debug

                  return LocationLib.evalLineage({
                    memberA: hiringLocationId,
                    memberB: candidateLocationId
                  });

                });

              }

              else {

                return LocationLib.evalLineage({
                  memberA: candidateLocationId,
                  memberB: candidateLocationId
                });

              }

            })
          );

        }
        /** */

        /** @todo to cleanup | 2021-07-29 Thu µ */
        /* epic-3038(new locations)-story-3578-M2 | 2021-07-29 Thu µ */
        /* epic-3038(new locations)-story-3083-m2-4/story-3079-M6 - 2021-06 µ */
        /* CandidateMatch-JobMenu: "Remote Locations" */
        /* matching c.candidateLocation with j.candidateLocations or j.e.candidateLocations * /
        else if (!!candidate && !!candidate.candidateLocation && (menu.key === 'candidateLocations')) {

          candidate.inOfficeRemoteFlags = candidate.inOfficeRemoteFlags || [];

          const job = item;
          const {
            candidateLocation: candidateLocationId
          } = candidate;
          const candidateLocationLabel = Definition.getLabel('location', candidateLocationId);

          job.candidateLocations.forEach((candidateLocationId) => {

            // epic-3038-story-3079-M6 (New Locations|job.employer.candidateLocations) 2021-06-28 µ
            if (candidateLocationId === LOCATION__EMP_HIRING_LOCS_ID) {

              const { employer = {} } = job;
              const { candidateLocations: employerCandidateLocations = [] } = employer;
              employerCandidateLocations.forEach(hiringLocationId => {
                const hiringLocationLabel = Definition.getLabel('location', hiringLocationId);
                const itemValue = FilterControlLib.getItemValue({
                  menu,
                  itemLabel: hiringLocationLabel
                });
                menu.items[itemValue] = (
                  menu.items[itemValue] ||
                  (
                    (
                      candidate.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID) ||
                      candidate.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
                    ) &&
                    LocationLib.evalLineage({
                      memberA: hiringLocationId,
                      memberB: candidateLocationId
                    })
                  )
                );
                console.debug('match:job.hiringLocation => candidate.candidateLocation');
                console.debug(hiringLocationLabel, '/', candidateLocationLabel, ':', menu.items[itemValue]);
              });

            }

            else {
              const candidateLocationLabel = Definition.getLabel('location', candidateLocationId);
              const itemValue = FilterControlLib.getItemValue({
                menu,
                itemLabel: candidateLocationLabel
              });
              menu.items[itemValue] = (
                menu.items[itemValue] ||
                (
                  (
                    candidate.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID) ||
                    candidate.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
                  ) &&
                  LocationLib.evalLineage({
                    memberA: candidateLocationId,
                    memberB: candidateLocationId
                  })
                )
              );
              console.debug('match:job.candidateLocation=>candidate.candidateLocation');
              console.debug(candidateLocationLabel, '/', candidateLocationLabel, ':', menu.items[itemValue]);
            }

          });

        }
        /** */

        else if (!!menu.multiple) {
          Object.keys(item[menu.field]).forEach((label) => {
            menu.items[label] = !stopMakingDefaultSelected.includes(menu.key)
              ? getChecked(selected, label)
              : false;
          });
        } else {
          menu.items[item[menu.field]] = !stopMakingDefaultSelected.includes(
            menu.key
          )
            ? getChecked(selected, item[menu.field])
            : false;
        }
      }
    });
  });

  return menus;
};

const setMore = (more, selected, { candidate, job } = {}) => {
  const stopMakingDefaultSelected = ["platformRating"];

  let label = "";
  more.forEach((menu) => {
    menu.items = menu.items || {};
    let defKeys = Definition.get(menu.definitionKey || menu.key);

    if (defKeys.length === 0 && menu.options) {
      defKeys = menu.options;
    }

    defKeys.forEach((def) => {
      label = /diversity/i.test(menu.key)
        ? "Diversity: " + def.label
        : def.label;
      menu.items[label] = !stopMakingDefaultSelected.includes(menu.key)
        ? getChecked(selected, label)
        : false;
    });
  });

  return more;
};

const processMenuMore = ({
  label,
  menuKey,
  checked,
  fields = [],
  source,
  menus,
  more,
}) => {
  const processSingle = (menus, menuKey, label, checked, source) => {
    const menu = menus.find((menu) => menu.key === menuKey);

    if (!!menu && menu.inputType === "radio") {
      let keys = Object.keys(menu.items);
      let items = {};

      keys.forEach((key) => {
        items[key] = false;
      });

      menu.items = items;
    }
    /**
     * @todo
     * TO DEPRECATE / CLEANUP?
     * 2021-06-23
     * µ
     */
    /** * /
    if (source === "setItems") {
      if (menuKey === "location") {
        let isBayArea = [
          "All Bay Area",
          "East Bay",
          "South Bay",
          "North Bay",
          "Peninsula",
          "San Francisco",
        ].find((lab) => {
          let regex = new RegExp(lab, "i");
          return regex.test(label);
        });

        if (!!isBayArea) {
          label = "All Bay Area";
        }
      }
      if (menuKey === "locationCandidate") {
        let bayAreas = [
          "East Bay",
          "North Bay",
          "Peninsula",
          "San Francisco",
          "South Bay",
        ];
        let isBayArea = bayAreas.find((lab) => {
          let regex = new RegExp(lab, "i");
          return regex.test(label);
        });

        if (!!isBayArea) {
          label = bayAreas.join(",");
        }
      }
    }
    /** */

    /** @todo TO REVIEW a possible bug. - 2021-06-15 µ */
    // console.debug({ menuKey, menu }); // { menuKey: "locationCandidate", menu: undefined } BUG?
    if (!!menu && !!label) {
      let labels = label.split(",").map((el) => el.trim());
      labels.forEach((label) => {
        if (Object.keys(menu.items).includes(label)) {
          menu.items[label] = checked;
        }
      });
    }

    return menu;
  };

  let menusWithMore = [...menus, ...more];

  if (!!fields.length) {
    menusWithMore = fields.map((el) => {
      return processSingle(menusWithMore, el.key, el.label, el.checked, source);
    });
  } else {
    menusWithMore = processSingle(menusWithMore, menuKey, label, checked);
  }

  return {
    processedMenus: menus,
    processedMore: more,
  };
};

const setInitialSearchMenu = (
  items = [],
  menus,
  more,
  selected,
  candidate,
  keywords,
  label,
  fields,
  callBackGetChips,
  paramsForLocation,
) => {
  const newMenus = setMenu(items, menus, selected, paramsForLocation);
  const newSources = setSources(items);
  const newMores = setMore(more, selected, paramsForLocation);
  const newMenuMore = processMenuMore({
    label,
    menuKey: "visa",
    checked: true,
    fields,
    source: "setItems",
    menus: newMenus,
    more: newMores,
  });
  const { processedMenus, processedMore } = newMenuMore;
  const newChips = callBackGetChips(
    candidate,
    processedMenus,
    processedMore,
    keywords
  );

  return {
    menus: processedMenus,
    sources: newSources,
    more: processedMore,
    chips: newChips,
  };
};

const setSearchMenuOnChange = (
  candidate,
  label,
  menuKey,
  checked,
  fields = [],
  cb,
  menus,
  more,
  keywords,
  callBackGetChips
) => {
  const newMenuMore = processMenuMore({
    label,
    menuKey,
    checked,
    fields,
    source: "setItems",
    menus,
    more,
  });

  const { processedMenus, processedMore } = newMenuMore;
  const newChips = callBackGetChips(
    candidate,
    processedMenus,
    processedMore,
    keywords
  );

  return {
    menus: processedMenus,
    more: processedMore,
    chips: newChips,
  };
};

const setChipsOnMoreFilters = (
  candidate,
  processedMenus,
  processedMore,
  keywords,
  callBackGetChips
) => {
  const chips = callBackGetChips(
    candidate,
    processedMenus,
    processedMore,
    keywords
  );
  return chips;
};

/**
 * This is returning a menu.label abbr.
 *
 * To be used as prefix value for `Entity.menu > item/label`
 *
 * story-3083
 *
 * @param {object} params
 * @param {object} params.menu
 * @param {string} params.menu.label
 * @returns {string}
 */
function getMenuPrefix({ menu: { label: menuLabel = 'undefined' } }) {
  return menuLabel.match(/\b([A-Z|a-z]{1,2})/g).join('');
}

/**
 * From a `Entity.menu > item/label`,
 * If menu has a prefix flag, then will return the item/label item with an menu.label abbr. prefixed.
 *
 * This is used to modify a item/label with same value from a different menu.
 *
 * story-3083
 *
 * @param {object} params
 * @param {object} params.menu
 * @param {string|boolean} params.menu.prefix
 * @param {string} params.menu.label
 * @param {string} params.itemLabel
 * @returns
 */
function getItemValue({ menu = {}, itemLabel = 'undefined' }) {
  itemLabel = itemLabel.trim();
  return (
    menu.prefix && !!itemLabel
      ? menu.prefix === true ? `${getMenuPrefix({ menu })}: ${itemLabel}` : `${menu.prefix}: ${itemLabel}`
      : itemLabel
  );
}

/**
 * From a `Entity.menu > item/value` this will remove the prefix and return a label.
 *
 * This is used to get the string to show in the UI.
 *
 * story-3083
 *
 * @param {object} params
 * @param {object} params.menu
 * @param {string|boolean} params.menu.prefix
 * @param {string} params.menu.label
 * @param {string} params.itemValue
 * @returns {string}
 */
function getItemLabel({ menu = {}, itemValue = 'undefined' }) {
  return (
    menu.prefix
      ? itemValue.replace(menu.prefix === true ? `${getMenuPrefix({ menu })}: ` : `${menu.prefix}: `, '').trim()
      : itemValue.trim()
  );
}

/**
 * From an array of `Entity.menu > item/label`,
 * If menu has a prefix flag, then will return the item/labels with an menu abbr. prefixed as csv.
 *
 * This is used to modify item/labels with same value from a different menu.
 *
 * story-3083
 *
 * @param {object} params
 * @param {object} params.menu
 * @param {string|boolean} params.menu.prefix
 * @param {string} params.menu.label
 * @param {string} params.itemLabels - csv
 * @returns {string} - csv
 */
function getItemValues({ menu = {}, itemLabels = "" }) {
  return itemLabels.split(",").map(itemLabel => getItemValue({ menu, itemLabel })).join(",");
}

function evalSameMenu({ menu = {}, labels = [] }) {
  return labels.every((label = '') => label.includes(menu.prefix));
}

const FilterControlLib = {
  setInitialSearchMenu,
  setSearchMenuOnChange,
  setChipsOnMoreFilters,
  evalSameMenu,
  getMenuPrefix,
  getItemValue,
  getItemLabel,
  getItemValues,
};

export {
  FilterControlLib as default,
  FilterControlLib,
}
