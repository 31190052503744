// epic-3038(new locations)-story-3705-m2 | 2021-08-06 Fri µ
/** @todo to cleanup | 2021-08-06 Fri µ */
/** * /
import Snackbar from "material-ui/Snackbar";
/** */

import { Dialog, DialogTitle } from "@mui/material";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import React, { Component } from "react";

const muiTheme = getMuiTheme({
  palette: {
    accent1Color: "#009688"
  }
});

class CustomSnackbar extends Component {
  constructor() {
    super(...arguments);
    this.state = { open: false, title: "", message: "" };
  }
  open = ({ title, message, onClose }) => {
    this.setState({
      open: true,
      message: message || "",
      onClose
    });
  };
  close = ev => {
    this.setState({ open: false }, then => {
      if (this.state.onClose instanceof Function) {
        this.state.onClose();
      }
    });
  };
  render() {

    // epic-3038(new locations)-story-3705-m2 | 2021-08-06 Fri µ
    /** */
    return (
      // epic-3038(new locations)-story-3753-m2 | 2021-08-10 Tue µ
      <MuiThemeProvider muiTheme={muiTheme}>
        <Dialog onClose={this.close} aria-labelledby="simple-dialog-title" open={this.state.open} style={{ zIndex: 9999 }}>
          <DialogTitle id="simple-dialog-title">{this.state.message}</DialogTitle>
        </Dialog>
      </MuiThemeProvider>
    );
    /** */

    // epic-3038(new locations)-story-3705-m2 | 2021-08-06 Fri µ
    /** @todo to cleanup | 2021-08-06 Fri µ */
    /** * /
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <Snackbar
          open={this.state.open}
          message={this.state.message}
          className="µ-snack-bar"
          onRequestClose={this.close}
        />
      </MuiThemeProvider>
    );
    /** */

  }
}

export default CustomSnackbar;
