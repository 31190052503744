import React, {Component, Fragment} from "react";
import ReactDOM from 'react-dom';

class OperatorDropdown extends Component {

    constructor() {
        super(...arguments);
        this.state = {
            opeartor: this.props.type,
            openOperatorDD: false
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }
    
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
    
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                openOperatorDD: false
            });
        }
    }
    
    componentWillReceiveProps (nextProps) {
        if(nextProps.type !== this.props.type){
            this.setState({opeartor: nextProps.type});
        }
    }

    openOperatorDD = () => {
        let {openOperatorDD} = this.state;
        this.setState({openOperatorDD: !openOperatorDD});
    }

    handlerOperatorOption = (opeartor) => () => {
        const {handlerOperator,mainComponent} = this.props;
        this.setState({opeartor, openOperatorDD: false}, () => {
            !!handlerOperator && handlerOperator(opeartor, !!mainComponent ? mainComponent : "");
        });
    }

    render() {
        const {opeartor,openOperatorDD} = this.state;
        const {onlyView, operators} = this.props;
        const styleView = !!onlyView ? 'fc-button-color-view' : 'fc-button-color-secondary';
        
        return <Fragment>
            <span className="fc-dropdownmenu">
                {!!opeartor &&
                    <span
                        className={`fc-dropdownmenu-button ${styleView}`}
                        onClick={this.openOperatorDD}
                        onKeyDown={this.openOperatorDD}
                    >
                        <span>{opeartor}</span>
                    </span>
                }
                {!onlyView && !!openOperatorDD && 
                    <span className="fc-dropdownmenu-contextmenu fc-dropdown-position">
                        {operators.map((item, index) => {
                            return <div
                                    className="fc-dropdownmenu-contextmenu-item"
                                    key={index}
                                    selected={index}
                                    onClick={this.handlerOperatorOption(item.name)}
                                >
                                    {item.name}
                                </div>
                        })}
                    </span>
                }
            </span>&nbsp;&nbsp;
        </Fragment>
    }
}

export default OperatorDropdown;
