import Core from "./Core";
import Http from "./Http";

const School = {

    post: (data, success) => {
        Http.post(
            Core.getApi("School/"),
            data,
            success
        );
    },
    update: (schoolId, data, success) => {
        Http.patch(
            Core.getApi("School/"+schoolId),
            data,
            success
        );
    },
    get: (cb, opts) => {
        let {include, where, fields, limit} = opts;
        if(!include){
            include = {};
        }
        if(!where){
            where = {};
        }

        if(!fields){
            fields = {};
        }

        Http.get(
            Core.getApi("School"),
            {
                filter: JSON.stringify({include,where,fields,limit}),
            },
            function onSuccess(response) {
                cb(response);
            }
        );
    },
};

export default School;
