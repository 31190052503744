/** ============================================ µ
 * @description ATSPreviewBlock [JSX]
 *              UI Component
 * @route       /candidate/resume-submission/:candoId/:jobId
 * @createdAt   2021-05-13
 * @updatedAt   2021-12-08 Wed
 * ============================================ */
/* IMPORTS ==================================== */

// import { TextField } from "material-ui";
import { TextField } from "@mui/material";
import React from "react";
import { makeAtsSubmissionNotes, sendToATS } from "../../../lib/services/ATS/ATS.lib";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";
import AtsSubmitButton from "./AtsSubmitButton";

/* METHODS ==================================== */

function ATSPreviewBlock(props) {

  const {
    candidate = {},
    selectedJob: job = {},
    rawSubmissionNotes,
    emailParams = {},
  } = props;

  const {
    employer = {},
    submissionMethods = [],
  } = job;

  /** ATS params */
  const {
    id: employerId,
    name: employerName = '',
    atsUrl,
    atsTypeId,
    atsApiProviders = {},
  } = employer;

  const atsApiProvider = atsApiProviders[atsTypeId] || {};

  const {
    manualSubmissionUrl = '',
  } = atsApiProvider;

  const submissionNotes = makeAtsSubmissionNotes({
    candidate,
    rawSubmissionNotes
  })

  const [state, setState] = React.useState({});
  function updateState(newState) {
    setState({ ...state, ...newState });
  }

  const publicSubmissionNotes = (
    state.publicSubmissionNotes ||
    submissionNotes.public
  );

  const privateSubmissionNotes = (
    state.privateSubmissionNotes ||
    submissionNotes.private
  );

  function onChangeInput(field) {
    return (event, value) => {
      const update = {};
      update[field] = value;
      updateState(update);
    }
  }

  async function onClickSubmit(apiName) {
    const result = await sendToATS({
      apiName,
      candidate,
      job,
      submissionNotes: {
        public: publicSubmissionNotes,
        private: privateSubmissionNotes,
      },
      emailParams,
    });
    /** µ FOR DEBUG PURPOSES */
    console.debug('µ:onClickSubmit', { result });
    /** */
    return result;
  }

  return (
    <div className="bg-a-05 rounded m-2 pb-1">
      <Row>
        <Col fullWidth>
          <label>ATS Submission Notes</label>
          <small>PUBLIC</small>
          <TextField
            name="publicSubmissionNotes"
            value={publicSubmissionNotes}
            onChange={onChangeInput("publicSubmissionNotes")}
            fullWidth
            multiline
            rows={5}
            maxRows={11}
            variant="filled"
            className="my-1"
          />
        </Col>
      </Row>
      <Row>
        <Col fullWidth>
          <label>&nbsp;</label>
          <small>PRIVATE</small>
          <TextField
            name="privateSubmissionNotes"
            value={privateSubmissionNotes}
            onChange={onChangeInput("privateSubmissionNotes")}
            fullWidth
            multiline
            rows={5}
            maxRows={11}
            variant="filled"
            className="my-1"
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex" fullWidth>
          <AtsSubmitButton
            atsTypeId={atsTypeId}
            employerName={employerName}
            employerId={employerId}
            submissionMethods={submissionMethods}
            manualSubmissionUrl={manualSubmissionUrl || atsUrl}
            onClick={onClickSubmit}
          />
        </Col>
      </Row>
    </div>
  );
}

/* EXPORTS ==================================== */

export {
  ATSPreviewBlock as default,
  ATSPreviewBlock,
};

/* ============================================ */
