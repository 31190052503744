import React, { useMemo } from "react";
import {
  useBlockLayout, useColumnOrder, useExpanded, useFilters,
  useGlobalFilter, usePagination, useResizeColumns,
  useRowSelect, useSortBy, useTable
} from 'react-table';
import { CellTagColumn } from "./CellTagColumn";
import { FilterDefaultColumn } from './FilterDefaultColumn';
import { IndeterminateCheckbox } from './IndeterminateCheckbox';

export function useReactTable({
  config = {},
  data = []
}) {
  let {
    columns = [],
    hiddenColumns = [],
  } = config;
  const _columns = useMemo(
    () => columns.filter(col => {
      if (col) {
        let { _acl = [], } = col;
        return ((_acl.every(v => !!v)) && col);
      }
      return false;
    }),
    [columns]
  );
  const defaultColumn = useMemo(() => ({
    Filter: FilterDefaultColumn,
    Cell: CellTagColumn,
    minWidth: 120,
    width: 240,
    maxWidth: 720,
  }), []);
  const initialState = useMemo(() => ({ hiddenColumns }), [hiddenColumns]);
  return useTable(
    {
      columns: _columns,
      data,
      initialState,
      manualFilters: true,
      manualSortBy: true,
      autoResetFilters: false,
      defaultColumn,
    },
    useColumnOrder,
    useFilters,
    useGlobalFilter,
    useSortBy,

    // from column resize
    useBlockLayout,
    useResizeColumns,

    useExpanded,
    usePagination,
    useRowSelect,

    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          disableResizing: true,
          width: 75,
          minWidth: 64,
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  );
}
