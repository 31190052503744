import React, {Component, Fragment} from "react";
import {MenuItem, SelectField} from "material-ui";


class Or extends Component {

    constructor() {
        super(...arguments);
        this.state = {number: this.props.number || 1};
    }


    updateAttr = (key, value) => {
        const {onUpdate} = this.props;

        this.setState({[key]: value}, () => {
            !!onUpdate && onUpdate('number', value)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.number !== this.props.number) {
            this.setState({number: this.props.number});
        }
    }

    render() {
        let {number} = this.state;
        let {editable} = this.props;


        return <Fragment>
            {!!editable ?
                <SelectField
                    name="level"
                    required={true}
                    value={number}
                    width={80}
                    maxWidth={80}
                    maxHeight={500}
                    onChange={(event, index, number) => this.updateAttr('number', number)}
                    style={{width: "80px"}}
                >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((tag, id) => (
                        <MenuItem key={id} value={tag} primaryText={tag}/>
                    ))}
                </SelectField> : <span>&nbsp;{number}</span>} of
        </Fragment>;

    }
}

export default Or;
