/** ============================================ µ
 * @description Tools [JS]
 *              UI Component
 * @route       /tools
 * @updatedAt   2021-05-21
 * ============================================ */
/* IMPORTS ==================================== */

import {
  Button,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Icon,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Radio as RadioAntD } from 'antd';
import FuzzySearch from 'fuzzy-search';
import { Divider, FlatButton, RaisedButton } from 'material-ui';
import React, { Component, Fragment } from 'react';
import Account from '../../lib/Account';
import Candidate from '../../lib/Candidate';
import Core, { DEFAULT_UI_VERSION } from '../../lib/Core';
import Definition from '../../lib/Definition';
import Engagement from '../../lib/Engagement';
import Job from '../../lib/Job';
import Store from '../../lib/Store';
import Streak from '../../lib/Streak';
import readTSV from '../../lib/tools/readTSV';
import AsyncActionButton from '../Buttons/AsyncActionButton';
import DragAndDropZone from '../DragAndDrop/DragAndDropZone';
import Col from '../Forms/Col';
import Row from '../Forms/Row';

/** µ DISCARDED (future cleanup) * /
// import { find CategoryByName } from "../../lib/tools/find CategoryTag";
/** */

/* CONSTANTS ================================== */

/**
 * @todo
 * Review and comment this line
 * 2021-05-20 µ
 */
const TODO = true;

const user = [Core.getUser()].find((user) => {
  user.name = `${user.firstName} ${user.lastName}`;
  return user;
});
const mdash = '—';

/* METHODS ==================================== */

const toISOString = (date) => {
  return date ? new Date(date).toISOString() : mdash;
};

const getUpdate = (pre) => {
  const update = {};
  Object.keys(pre).forEach(
    (key) => pre[key] !== mdash && (update[key] = pre[key])
  );
  delete update.engagementId;
  if (!update.status) {
    update.status = 'W - 10x10';
  }
  return update;
};

const parsePlatformRating = (platformRatingLetter) =>
  Number(platformRatingLetter) ||
  { A: 1, B: 2, C: 3, D: 4 }[platformRatingLetter];

/* MAIN CLASS ================================= */

class Tools extends Component {
  missingCandidates = [];
  constructor() {
    super(...arguments);
    this.state = {
      results: [],
      message: '',
      open: false,
    };
    if (TODO) {
      return;
    }
    /*
    Core.getTag Categories(categories => {
      const rolesCategory = [];//find CategoryByName(categories, "Roles");
      const roleTags = {};
      rolesCategory.tags.forEach(tag => {
        roleTags[tag.name] = tag;
      });
      this.setState({
        categories,
        rolesCategory,
        roleTags
      });
    });
    */
    setTimeout(
      (st) =>
        this.setResults('Pipeline:', [Streak.getPipeline().streakPipeline]),
      1000
    );
  }
  cleanResults = (ev) => {
    this.missingCandidates = [];
    this.setState({ results: [], message: '' });
  };
  setResults = (header, results = []) => {
    setTimeout((st) => {
      this.setState(
        {
          results: [
            ...this.state.results,
            <b className="inline-blocks">{header}</b>,
            ...results,
            <br
              ref={(self) =>
                Core.setKeyValue('AdminTools-lastResultItem', self)
              }
            />,
          ],
        },
        (state) => {
          Core.getKeyValue('AdminTools-lastResultItem')?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }
      );
    });
  };
  scrollToTop = (event) => {
    setTimeout(() => {
      this.results && this.results.scrollTo(0, 0);
    }, 100);
  };
  /** TOOL #1 */
  getMissingCandidates = (em) => {
    this.cleanResults();
    this.setState({ message: 'Looking for Candidates on MongoDB...' });
    let recruiters;
    Account.getNames((accounts) => {
      recruiters = accounts.map((account) => {
        account.name = `${account.firstName} ${account.lastName}`;
        return account;
      });
    });
    Candidate.getNames((candidates) => {
      candidates = JSON.stringify(
        candidates.map(
          (candidate) => `${candidate.firstName} ${candidate.lastName}`
        )
      );
      this.setState({ message: 'Looking for Candidates on Streak...' });
      Streak.getPipelineBoxes((boxes) => {
        const results = {};
        boxes.forEach((box) => {
          const candidateName = box.name.split(' - ')[0];
          if (!new RegExp(`${candidateName}`, 'i').test(candidates)) {
            results[candidateName] = box;
            results[candidateName].roles = results[candidateName].roles || [];
            results[candidateName].roles.push(
              Streak.getValue(box, 'Role', true)
            );
          }
        });
        Core.log(this.state);
        this.cleanResults();
        this.missingCandidates = [];
        if (!Object.keys(results).length) {
          this.setState({
            message: 'Tool #1 Summary: No missing Candidates on MongoDB',
          });
        } else {
          this.setResults(
            <span className="cyellow">
              CANDIDATES ON STREAK BUT NOT IN MONGODB
            </span>,
            Object.keys(results).map((key) => {
              const box = results[key];
              const candidateName = box.name.split(' - ')[0];
              const firstName = candidateName.split(' ')[0];
              const lastName = candidateName.split(' ')[1];
              const recruiterName = Streak.getValue(box, 'Recruiter', true);
              const introduced = Streak.getValue(box, 'Introduced', true);
              const introducedISO = introduced
                ? new Date(introduced).toISOString()
                : new Date().toISOString();
              const platformRating = parsePlatformRating(
                Streak.getValue(box, 'Platform Rating', true)
              );
              const account = recruiters.find((account) =>
                new RegExp(account.name, 'i').test(recruiterName)
              );
              const accountId = account && account.id;
              const categories = [{ ...this.state.rolesCategory }];
              categories[0].tags = [];
              box.roles.forEach((role) => {
                if (!!this.state.roleTags[role]) {
                  categories[0].tags.push(this.state.roleTags[role]);
                }
              });
              this.missingCandidates.push({
                firstName,
                lastName,
                accountId: accountId || user.id,
                platformRating: platformRating || 0,
                introduced: introducedISO,
                createdByTool: true,
                createdBy: user.name,
                categories,
              });
              return (
                <Fragment>
                  <div>---</div>
                  <div>{`Candidate Name: ${candidateName}`}</div>
                  <div>
                    {`Recruiter Name: ${recruiterName} ---> Account Id: ${accountId}`}
                  </div>
                  <div>{`Platform Rating: ${platformRating}`}</div>
                  <div>{`Roles: ${JSON.stringify(box.roles)}`}</div>
                  <div>
                    {`Introduced Date: ${introduced} ---> ${introducedISO}`}
                  </div>
                  {!accountId && (
                    <div className="cyellow">
                      {`Provisional Recruiter: ${user.name} ---> Account Id: ${user.id}`}
                    </div>
                  )}
                </Fragment>
              );
            })
          );
          this.setState({
            message: `Tool #1 Summary: ${this.missingCandidates.length} missing Candidates found`,
          });
        }
      });
    });
  };
  /** TOOL #2 */
  syncEngagements = (em) => {
    this.cleanResults();
    this.setState({ message: 'Looking for Engagements on MongoDB...' });
    Engagement.getBoxIds((engagements) => {
      const length = engagements.length;
      const failures = [];
      let countBoxes = 0;
      let responseBoxes = 0;
      let count = 0;
      this.setState({ message: 'Looking for Boxes on Streak...' });
      const next = (em) => {
        setTimeout((st) => {
          if (engagements.length) {
            const eng = engagements.shift();
            if (!eng.boxKey) {
              failures.push(eng);
              Core.isOnDev() && Engagement.delete(eng.id);
            } else {
              this.setState({
                message: `Looking for StreakBox ${++countBoxes} of ${length} Engagements on MongoDB`,
              });
              Streak.getBox(eng.boxKey, (box) => {
                this.setState({
                  message: `Processing StreakBox ${++responseBoxes} of ${length} Engagements on MongoDB`,
                });
                const setSummary = (em) => {
                  this.setState({
                    message: `Tool #02 Summary: Updated ${count} of ${length} Engagement on MongoDB; ${failures.length} Failures.`,
                  });
                  !!failures.length &&
                    this.setResults(
                      <span className="cyellow">
                        MISSING BOX KEY IN ENGAGEMENT
                      </span>,
                      [
                        <Fragment>
                          <pre>{JSON.stringify(failures, null, 4)}</pre>
                        </Fragment>,
                      ]
                    );
                };
                if (box.success === false) {
                  failures.push({ eng, boxResponse: box });
                  // Engagement.delete(eng.id);
                  if (failures.length === length) {
                    setSummary();
                  }
                } else {
                  const pre = {
                    engagementId: eng.id,
                    boxKey: eng.boxKey,
                    stage: Streak.getValue(box, 'Stage'),
                    status: Streak.getValue(box, 'Status'),
                    state: Streak.getValue(box, 'State', true),
                    introduced: toISOString(
                      Streak.getValue(box, 'Introduced', true)
                    ),
                    matched: toISOString(Streak.getValue(box, 'Matched', true)),
                    confirmed: toISOString(
                      Streak.getValue(box, 'Confirmed', true)
                    ),
                    lastAction: toISOString(
                      Streak.getValue(box, 'Last Action', true)
                    ),
                    reviewed: toISOString(
                      Streak.getValue(box, 'Reviewed', true)
                    ),
                    submitted: toISOString(
                      Streak.getValue(box, 'Submitted', true)
                    ),
                    holdDate: toISOString(
                      Streak.getValue(box, 'HoldDate', true)
                    ),
                    offered: toISOString(Streak.getValue(box, 'Offered', true)),
                    startDate: toISOString(
                      Streak.getValue(box, 'StartDate', true)
                    ),
                    guaranteeDate: toISOString(
                      Streak.getValue(box, 'GuaranteeDate', true)
                    ),
                    screened: toISOString(
                      Streak.getValue(box, 'Screened', true)
                    ),
                    onsite: toISOString(Streak.getValue(box, 'Onsite', true)),
                    hired: toISOString(Streak.getValue(box, 'Hired', true)),
                    screen1: toISOString(
                      Streak.getValue(box, 'Screen 1', true)
                    ),
                    screen2: toISOString(
                      Streak.getValue(box, 'Screen 2', true)
                    ),
                    screen3: toISOString(
                      Streak.getValue(box, 'Screen 3', true)
                    ),
                    screen4: toISOString(
                      Streak.getValue(box, 'Screen 4', true)
                    ),
                    screen5: toISOString(
                      Streak.getValue(box, 'Screen 5', true)
                    ),
                    onsite1: toISOString(
                      Streak.getValue(box, 'Onsite 1', true)
                    ),
                    onsite2: toISOString(
                      Streak.getValue(box, 'Onsite 2', true)
                    ),
                    onsite3: toISOString(
                      Streak.getValue(box, 'Onsite 3', true)
                    ),
                    onsite4: toISOString(
                      Streak.getValue(box, 'Onsite 4', true)
                    ),
                    onsite5: toISOString(
                      Streak.getValue(box, 'Onsite 5', true)
                    ),
                  };
                  Engagement.update(eng, getUpdate(pre), (response) => {
                    ++count;
                    if (count + failures.length === length) {
                      setSummary();
                    } else {
                      this.setState({
                        message: `Updated ${count} of ${length} Engagement on MongoDB`,
                      });
                      /** * /
                      Streak.update Box({
                        boxKey: eng.boxKey,
                        field: "EngagementURL",
                        value: Core.getPath(`engagement/view/${eng.id}`)
                      });
                      /** */
                    }
                  });
                }
              });
            }
            next();
          } else {
            this.setState({
              message: `Waiting for responses...`,
            });
          }
        });
      };
      next();
    });
  };
  /** TOOL #3 */
  getMissingEngagements = () => {
    this.cleanResults();
    var candidate, candidates, candidateSearcher;
    var employerSearcher;
    var job, jobs, jobSearcher;
    var engagements;
    this.setState({ message: 'Looking for Candidate Names on MongoDB...' });
    Candidate.getNames((response) => {
      candidates = response.map((candidate) => {
        return {
          id: candidate.id,
          name: `${candidate.firstName} ${candidate.lastName}`,
        };
      });
      candidateSearcher = new FuzzySearch(candidates, ['name'], {
        caseSensitive: false,
      });
      getJobsList();
    });
    const getJobsList = (em) => {
      this.setState({ message: 'Looking for Jobs List on MongoDB...' });
      Job.getAll((response) => {
        jobs = response.map((job) => {
          job.roleName = Definition.getLabel('roles', job.role);
          return job;
        });
        jobSearcher = new FuzzySearch(jobs, ['roleName'], {
          caseSensitive: false,
        });
        getEngagementsBoxIds();
      });
    };
    const getEngagementsBoxIds = (em) => {
      this.setState({ message: 'Looking for Engagements List on MongoDB...' });
      Engagement.getBoxIds((response) => {
        engagements = response;
        const keys = {};
        engagements.forEach((eng) => (keys[eng.boxKey] = true));
        this.setState({ message: 'Looking for Boxes on Streak...' });
        Streak.getPipelineBoxes((boxes) => {
          const totalBoxes = boxes.length;
          boxes = boxes.filter((box) => !keys[box.boxKey]);
          let count = 0;
          const length = boxes.length;
          const failures = [];
          const next = (em) => {
            setTimeout((st) => {
              if (boxes.length) {
                const box = boxes.shift();
                const candidateName = box.name.split(' - ')[0];
                const role = Streak.getValue(box, 'Role');
                candidate = candidateSearcher.search(candidateName)[0];
                if (!candidate) {
                  failures.push(
                    <Fragment>
                      <div>boxName: {box.name}</div>
                      <div>boxRole: {role}</div>
                      <div>
                        {`fail: "${candidateName}" is a missing Candidate on MongoDB`}
                      </div>
                      <div>---</div>
                    </Fragment>
                  );
                  return next();
                }
                jobs = jobSearcher.search(role);
                if (!jobs.length) {
                  failures.push(
                    <Fragment>
                      <div>
                        <div>boxName: {box.name}</div>
                        <div>boxRole: {role}</div>
                        {`fail: "${role}" is not set as Role in any Job on MongoDB`}
                      </div>
                      <div>---</div>
                    </Fragment>
                  );
                  return next();
                }
                employerSearcher = new FuzzySearch(jobs, ['employer.name'], {
                  caseSensitive: false,
                });
                const employerName = box.name.split(' - ')[1];
                job = employerSearcher.search(employerName)[0];
                if (!job) {
                  failures.push(
                    <Fragment>
                      <div>boxName: {box.name}</div>
                      <div>boxRole: {role}</div>
                      <div>
                        {`fail: "${employerName}" hasn't Jobs as Employer on MongoDB`}
                      </div>
                      <div>---</div>
                    </Fragment>
                  );
                  return next();
                }
                if (!!candidate && !!job) {
                  Engagement.post(
                    candidate.id,
                    job.id,
                    { introduced: candidate.introduced, boxKey: box.boxKey },
                    (response) => {
                      this.setState({
                        message: `Posted Engagement ${++count} of ${length} Missing Boxes on MongoDB...`,
                      });
                      next();
                    }
                  );
                } else {
                  failures.push(
                    <Fragment>
                      <div>boxName: {box.name}</div>
                      <div>boxRole: {Streak.getValue(box, 'Role')}</div>
                      <div>fail: ? </div>
                      <div>---</div>
                    </Fragment>
                  );
                  next();
                }
              } else {
                this.setState({
                  message: `Tool #03 Summary: ${totalBoxes} total boxes. ${length} boxes missing on MongoDB. ${count} new Engagements on MongoDB. ${failures.length} Failures.`,
                });
                !!failures.length &&
                  this.setResults(
                    <span className="cyellow">Failures</span>,
                    failures
                  );
              }
            });
          };
          next();
        });
      });
    };
  };
  /** TOOL #3.1 */
  getMissingRoles = () => {
    this.cleanResults();
    this.setMessage(`Getting all engagements from MongoDB...`);
    Engagement.getCandidates((engagements) => {
      const length = engagements.length;
      let updated = 0;
      let skipped = 0;
      let failures = 0;
      const failuresResult = [];
      const next = (em) => {
        setTimeout((st) => {
          if (updated + skipped + failures === length) {
            this.setMessage(
              `Tool #3.1 Summary: ${updated} updates; ${skipped} skips; ${failures} failures.`
            );
            !!failuresResult.length &&
              this.setResults(
                <span className="cyellow">Failures</span>,
                failuresResult
              );
            return;
          } else {
            this.setMessage(
              `Tool #3.1: ${updated + skipped + failures
              } Candidates processed from ${length} Engagements;\n
                ${updated} updates; ${skipped} skips; ${failures} failures.`
            );
            next();
          }
          if (!!engagements.length) {
            const eng = engagements.shift();
            if (!!eng.boxKey) {
              Streak.getBox(eng.boxKey, (box) => {
                if (box.success === false) {
                  Core.log({ box });
                  failures++;
                  failuresResult.push(
                    <pre>{JSON.stringify({ box }, null, 2)}</pre>
                  );
                  return;
                }
                const candidate = eng.candidate;
                const candCats = candidate.categories;
                const candRoles = candCats.find((cat) => cat.name === 'Roles');

                const boxRole = Streak.getValue(box, 'Role');
                const newRole = this.state.roleTags[boxRole];

                const categories = [];

                candCats.forEach((cr) => categories.push(cr));

                if (!candRoles) {
                  const categoryRole = Object.assign(
                    {},
                    this.state.rolesCategory
                  );
                  categoryRole.tags = [];
                  categories.push(categoryRole);
                }

                const catRolesIds = {};
                const catRoles = categories.find((cat) => cat.name === 'Roles');

                catRoles.tags.forEach((role) => (catRolesIds[role.id] = true));

                if (!newRole || !newRole.id) {
                  failures++;
                  failuresResult.push(
                    <pre>{JSON.stringify({ box }, null, 2)}</pre>
                  );
                  return;
                }

                if (!catRolesIds[newRole.id]) {
                  catRoles.tags.push(newRole);
                  Core.log({ categories, catRoles });
                  return TODO;
                  /*
                  return Core.post CandidateCategories(
                    candidate.id,
                    categories,
                    tags => {
                      updated++;
                    },
                    response => {
                      failures++;
                      failuresResult.push(
                        <pre>
                          {JSON.stringify({ candidate, categories }, null, 2)}
                        </pre>
                      );
                    }
                  );
                  */
                }
                skipped++;
              });
            } else {
              failures++;
              failuresResult.push(
                <pre>{JSON.stringify({ eng }, null, 2)}</pre>
              );
            }
          }
        });
      };
      next();
    });
  };
  /** TOOL #7 */
  updateResumesURLS = (em) => {
    this.cleanResults();
    this.setState({ message: 'Updating candidates resume URLs..' });
    Candidate.getWhere({ resumes: { neq: null } }, (candidates) => {
      candidates.forEach((candidate) => {
        var resumes = candidate.resumes.map((resume) => {
          var storage = Core.getResumeS3Storage();
          if (!resume.url.includes(storage)) {
            resume.url = resume.url.replace(
              'ImportContainer/',
              'ImportContainer/' + storage + '%2F'
            );
          }
          return resume;
        });
        Candidate.update(candidate.id, { resumes: resumes }, (result) => {
          //console.log("UPDATED: Candidate.id=" + candidate.id);
        });
      });
      this.setState({ message: 'DONE' });
    });
  };

  /**
   * Sets information about current status
   * of operations
   *
   * @param {string} statusInfo
   */
  setStatusInfo = (statusInfo = '') => {
    this.setState({ statusInfo });
  };

  /**
   * TOOL #8.1
   *
   * Event Function
   *
   * Get then set in memory,
   * result lists of inspect box/engagements
   *
   * To be used by
   *
   * @see onClickFixEngagementsMissingBoxKey - TOOL #8.2
   * @see onClickFixEngagementsInvalidBoxKey - TOOL #8.3
   *
   */
  onClickInspectBoxesAndEngagements = async (event) => {
    this.setState({ isStopSignal: false });

    const { selectedEngagementsStatus = 'open' } = this.state;

    Core.setKeyValue('engagements-streak-pairs', null);
    Core.setKeyValue('streak-engagements-pairs', null);
    Core.setKeyValue('missing-box-key-engagements', null);
    Core.setKeyValue('engagementsWithInvalidBoxKey', null);

    this.cleanResults();

    this.setStatusInfo('Fetching Streak Boxes...');

    /**
     * FETCH STREAK BOXES
     */
    const boxes = await Streak.getPipelineBoxes();

    console.debug({ boxes });

    this.setStatusInfo('Obtaining Engagements Ids from the boxes');

    const engagementBoxes = {};
    const boxEngagements = {};
    let duplicatedBoxesLength = 0;
    const duplicatedBoxes = {};
    const unexpectedBoxState = {};

    /**
     * Find engagement in box
     */
    boxes.forEach((box) => {
      /** GET ENGAGEMENT ID */
      const engagementID = (String(Streak.getValue(box, 'EngagementURL')).match(
        /http.+view\/(.+)$/
      ) || [])[1];

      /**
       * GET BOX STATE
       */
      const boxState = Streak.getValue(box, 'State') || '';

      /**
       * IF LOOK FOR ALL
       * OR BOX STATE MATCH WITH OPEN OR CLOSED
       */
      if (
        selectedEngagementsStatus === 'all' ||
        selectedEngagementsStatus.match(new RegExp(boxState, 'i'))
      ) {
        /**
         * IF ENGAGEMENT ID PREVIOUSLY EXISTS
         * IN THE HASH RESULTS
         * NEW ONE IS A DUPLICATED BOX
         */
        if (engagementBoxes[engagementID]) {
          /**
           * IF EXITS PREVIOUSLY
           * AN ARRAY OF DUPLICATED BOXES
           * FOR A ENGAGEMENT
           * PUSH THE BOX ON IT
           */
          if (Array.isArray(engagementBoxes[engagementID])) {
            engagementBoxes[engagementID].push(box.boxKey);
            duplicatedBoxes[engagementID] = engagementBoxes[engagementID];
            duplicatedBoxesLength++;
          } else {
            /**
             * ELSE CREATE NEW ARRAY
             * WITH CURRENT EXISTING BOX
             * AND THE DUPLICATED
             */
            engagementBoxes[engagementID] = [
              engagementBoxes[engagementID],
              box.boxKey,
            ];
            duplicatedBoxes[engagementID] = engagementBoxes[engagementID];
            duplicatedBoxesLength++;
          }
        } else {

          /**
           * ADD THE BOX TO HASH RESULTS (ENG/BOX)
           */
          engagementBoxes[engagementID] = box.boxKey;
        }
      }

      /**
       * ADD THE BOX TO HASH RESULTS (BOX/ENG)
       */
      boxEngagements[box.boxKey] = engagementID;

      /**
       * IF BOX STATE IS OTHER THAN OPEN OR CLOSED
       */
      if (!boxState.match(/Open|Closed/i)) {
        unexpectedBoxState[box.boxKey] = boxState;
      }
    });

    /**
     * SET GLOBAL VARIABLE
     * TO BE USED ON THE "FIX" TOOL 8.2
     */
    Core.setKeyValue('engagements-streak-pairs', engagementBoxes);

    console.debug({ engagementBoxes });
    console.debug({ boxEngagements });

    /**
     * RENDER TOTAL BOXES
     */
    this.setResults(
      <span className="c-yellow">Streak boxes ({boxes.length})</span>
    );

    /**
     * RENDER TOTAL ENG/BOX PAIRS BY STATE
     */
    this.setResults(
      <span className="c-yellow">
        Streak boxes paired with engagement for "{selectedEngagementsStatus}"
        states ({Object.keys(engagementBoxes).length})
      </span>
    );

    /**
     * KEEP AS REFERENCE
     * 2021-05-21 µ
     */
    /** * /
    this.setResults(
      <span className="c-yellow">
        Streak engagementId:boxKey pairs ({Object.keys(engagementBoxes).length})
      </span>,
      [<pre>{JSON.stringify(engagementBoxes, null, 2)}</pre>]
    );
    /** */

    /**
     * RENDER TOTAL ENG/BOX PAIRS
     * WITH UNKNOWN STATE
     */
    const unexpectedBoxStateLength = Object.keys(unexpectedBoxState).length;
    if (!!unexpectedBoxStateLength) {
      this.setResults(
        <span className="c-yellow">
          Boxes with unknown state ({unexpectedBoxStateLength})
        </span>,
        [<pre>{JSON.stringify(unexpectedBoxState, null, 2)}</pre>]
      );
    }

    /**
     * RENDER TOTAL BOXES DUPLICATED
     * BY REFERENCE TO ENGAGEMENT
     */
    if (!!duplicatedBoxesLength) {
      this.setResults(
        <span className="c-yellow">
          Boxes duplicated ({duplicatedBoxesLength})
        </span>,
        [<pre>{JSON.stringify(duplicatedBoxes, null, 2)}</pre>]
      );
    }

    this.setStatusInfo(`Fetching Engagements(${selectedEngagementsStatus})...`);

    /**
     * FETCH ENGAGEMENTS BY STATE
     */
    const engagementsBoxKeys = await Engagement.getBoxIdsV2({
      status: selectedEngagementsStatus,
    });

    /**
     * RENDER TOTAL ENGAGEMENTS BY THE STATE
     */
    this.setResults(
      <span className="c-yellow">
        Engagements for "{selectedEngagementsStatus}" state (
        {Object.keys(engagementsBoxKeys).length})
      </span>
    );

    /**
     * GET ENGAGEMENTS WITH MISSING BOX KEY
     * GET ENGAGEMENTS WITH OUT VALID BOX KEY
     */
    const engagementsWithInvalidBoxKey = {};
    const engagementsWithoutBoxKey = engagementsBoxKeys.filter((engagement) => {
      /**
       * IF ENGAGEMENT BOX KEY IS NOT STREAK BY STATUS
       */
      if (!!engagement.boxKey && !boxEngagements[engagement.boxKey]) {
        if (engagementBoxes[engagement.id]) {
          engagementsWithInvalidBoxKey[engagement.id] = {
            current: engagement.boxKey,
            trusted: engagementBoxes[engagement.id],
          };
        } else {
          engagementsWithInvalidBoxKey[engagement.id] = engagement.boxKey;
        }
      }
      return !engagement.boxKey;
    });

    console.debug('µ:getMissingBoxKeyEngagements', engagementsWithoutBoxKey);

    /**
     * SET GLOBAL VARIABLE
     * TO BE USED ON THE "FIX" TOOL 8.2
     */
    Core.setKeyValue('missing-box-key-engagements', engagementsWithoutBoxKey);

    /**
     * SET GLOBAL VARIABLE
     * TO BE USED ON THE "FIX" TOOL 8.3
     */
    Core.setKeyValue(
      'engagementsWithInvalidBoxKey',
      engagementsWithInvalidBoxKey
    );

    /**
     * RENDER ENGAGEMENTS WITHOUT BOX KEY
     */
    this.setResults(
      <span className="c-yellow">
        Engagements without boxKey ({engagementsWithoutBoxKey.length})
      </span>,
      engagementsWithoutBoxKey.map((n) => n.id)
    );

    /**
     * RENDER ENGAGEMENTS WITH INVALID BOX KEY
     */
    const engagementsWithInvalidBoxKeysLength = Object.keys(
      engagementsWithInvalidBoxKey
    ).length;
    if (!!engagementsWithInvalidBoxKeysLength) {
      this.setResults(
        <span className="c-yellow">
          Engagements with boxKey not located in Streak (
          {engagementsWithInvalidBoxKeysLength})
        </span>,
        [<pre>{JSON.stringify(engagementsWithInvalidBoxKey, null, 2)}</pre>]
      );
    }

    this.setStatusInfo();

    this.scrollToTop();
  };

  /**
   * TOOL #8.2
   *
   * Event Function
   * 
   * From result list set in memory by 
   * 
   * @see onClickInspectBoxesAndEngagements - TOOL #8.1
   *
   * For each engagement
   * 
   * - If does not exist an streak box 
      for current engagement,
      then a new box will be created
      then set the new boxKey in the engagement.
   * 
   * - else the boxKey found will be 
      set on engagement.
   *
   */
  onClickFixEngagementsMissingBoxKey = (ev) => {
    /** FUNCTION TO CLEAN MEMORY ON ALL OPERATION DONE */
    const emptyMemory = () => {
      Core.setKeyValue('missing-box-key-engagements', null);
      this.setState({
        statusInfo: '',
        isRecursiveOperationRunning: false,
        isStopSignal: false,
      });
    };

    /** RETURN PROMISE REQUIRED FOR ASYNC ACTION BUTTON */
    return new Promise((resolve = emptyMemory, reject = emptyMemory) => {
      this.setState({ isRecursiveOperationRunning: true });

      /** GET ENGAGEMENTS MISSING BOX KEY */
      const missing = [
        ...(Core.getKeyValue('missing-box-key-engagements') || []),
      ];

      /** GET EXISTING ENG/BOX PAIRS */
      const pairs = Core.getKeyValue('engagements-streak-pairs');

      /** RECURSIVE FUNCTION */
      const next = (em) => {
        const { isStopSignal } = this.state;

        /** EXIT CONDITION */
        if (!missing.length || isStopSignal) {
          emptyMemory();
          return resolve();
        }

        /** GET CURRENT ENGAGEMENT ID */
        const id = missing.pop().id;

        /** FETCH ENGAGEMENT INFORMATION */
        this.setState({ statusInfo: `Fetching Engagement(${id}) info` });
        Engagement.get(id, (engagement) => {
          /** LOOK FOR BOX PAIR */
          const boxKey = pairs[id];

          /** ENG/BOX PAIR FOUND */
          if (!!boxKey) {
            /** UPDATE ENGAGEMENT WITH BOX KEY FOUND */
            this.setState({
              statusInfo: `Updating Engagement(${id}) boxKey(${boxKey})`,
            });
            Engagement.updateBoxKey({
              engagement,
              boxKey,

              onSuccess: ({ id, boxKey }) => {
                /** RENDER UPDATE RESULT */
                this.setResults(
                  <span className="c-success">
                    {`success to update boxKey(${boxKey}) on engagement(${id})`}
                  </span>
                );

                /** GO FOR FOLLOWING ENGAGEMENT IN LIST */
                setTimeout(next);
              },

              onFailure: (error) => {
                console.debug('µ:fixMissingBoxKeyEngagements', { error });

                /** RENDER FAILURE RESULT */
                this.setResults(
                  <span className="c-failure">
                    {`failure to update boxKey(${boxKey}) for engagement(${id})`}
                  </span>
                );

                /** FINISH OPERATIONS */
                emptyMemory();
                reject();
              },
            });
          } else {
            this.setState({
              statusInfo: `Creating new box for Engagement(${id})`,
            });
            Engagement.createBox({
              engagement,
              onSuccess: ({ id, boxKey }) => {
                /** RENDER CREATE RESULT */
                this.setResults(
                  <span className="c-success">
                    {`success to create boxKey(${boxKey}) for engagement(${id})`}
                  </span>
                );

                /** GO FOR FOLLOWING ENGAGEMENT IN LIST */
                setTimeout(next);
              },
              onFailure: (error) => {
                console.debug('µ:fixMissingBoxKeyEngagements', { error });

                /** RENDER FAILURE RESULT */
                this.setResults(
                  <span className="c-failure">
                    {`failure to create boxKey for engagement(${id})`}
                  </span>
                );

                /** FINISH OPERATIONS */
                emptyMemory();
                reject();
              },
            });
          }
        });
      };

      /** START OPERATIONS */
      next();
    });
  };

  /**
   * TOOL #8.2
   *
   * Event Function
   * 
   * From result list set in memory by 
   * 
   * @see onClickInspectBoxesAndEngagements - TOOL #8.1
   *
   * For each engagement
   * 
   * - If boxKey found 
      for current engagement (`trusted`)
      the boxKey found will be 
      set on engagement.
   * - else a new box will be created
      then set the new boxKey in the engagement.
   *
   */
  onClickFixEngagementsInvalidBoxKey = (ev) => {
    /** FUNCTION TO CLEAN MEMORY ON ALL OPERATION DONE */
    const emptyMemory = () => {
      Core.setKeyValue('engagementsWithInvalidBoxKey', null);
      this.setState({
        statusInfo: '',
        isRecursiveOperationRunning: false,
        isStopSignal: false,
      });
    };

    /** RETURN PROMISE REQUIRED FOR ASYNC ACTION BUTTON */
    return new Promise((resolve = emptyMemory, reject = emptyMemory) => {
      this.setState({ isRecursiveOperationRunning: true });

      /** GET ENGAGEMENTS INVALID BOX KEY */
      const engagementsWithInvalidBoxKey = Core.getKeyValue(
        'engagementsWithInvalidBoxKey'
      );
      const engagementsIds = Object.keys(engagementsWithInvalidBoxKey);

      /** RECURSIVE FUNCTION */
      const next = (em) => {
        const { isStopSignal } = this.state;

        /** EXIT CONDITION */
        if (!engagementsIds.length || isStopSignal) {
          emptyMemory();
          resolve();
          return false;
        }

        /** GET CURRENT ENGAGEMENT ID */
        const id = engagementsIds.pop();

        /** FETCH ENGAGEMENT INFORMATION */
        this.setStatusInfo(`Fetching Engagement(${id})`);
        Engagement.get(id, (engagement) => {
          /** LOOK FOR BOX KEY VALUE */
          const boxKey = engagementsWithInvalidBoxKey[id];
          console.debug({ boxKey });

          /** IF BOX KEY VALUE CONTAINS A TRUSTED PAIR */
          if (boxKey === Object(boxKey) && !!boxKey.trusted) {
            this.setStatusInfo(`Updating Engagement(${id}) boxKey(${boxKey})`);

            /** UPDATE ENGAGEMENT BOX KEY WITH TRUSTED */
            Engagement.updateBoxKey({
              engagement,
              boxKey: boxKey.trusted,
              onSuccess: ({ id, boxKey }) => {
                /** RENDER UPDATE RESULT */
                this.setResults(
                  <span className="c-success">
                    {`success to update boxKey(${boxKey}) on engagement(${id})`}
                  </span>
                );

                /** GO FOR FOLLOWING ENGAGEMENT IN LIST */
                setTimeout(next);
              },
              onFailure: (error) => {
                console.debug('µ:fixMissingBoxKeyEngagements', { error });

                /** RENDER FAILURE RESULT */
                this.setResults(
                  <span className="c-failure">
                    {`failure to update boxKey(${boxKey}) for engagement(${id})`}
                  </span>
                );

                /** FINISH OPERATIONS */
                emptyMemory();
                reject();
              },
            });
          } else {

            /** IF BOX KEY NOT CONTAINS A TRUSTED VALUE THEN */
            this.setState({
              statusInfo: `Creating new box for Engagement(${id})`,
            });

            /** CREATE A NEW BOX */
            Engagement.createBox({
              engagement,
              onSuccess: ({ id, boxKey }) => {
                /** RENDER CREATE RESULT */
                this.setResults(
                  <span className="c-success">
                    {`success to create boxKey(${boxKey}) for engagement(${id})`}
                  </span>
                );

                /** GO FOR FOLLOWING ENGAGEMENT IN LIST */
                setTimeout(next);
              },
              onFailure: (error) => {
                console.debug('µ:fixMissingBoxKeyEngagements', { error });

                /** RENDER FAILURE RESULT */
                this.setResults(
                  <span className="c-failure">
                    {`failure to create boxKey for engagement(${id})`}
                  </span>
                );

                /** FINISH OPERATIONS */
                emptyMemory();
                reject();
              },
            });
          }
        });
      };

      /** START OPERATIONS */
      next();
    });
  };

  onClickCreateCandidates = (ev) => {
    /** TOOL 1 */
    ev.preventDefault();
    this.setState({ results: [], message: '' });
    const stack = this.missingCandidates;
    const length = this.missingCandidates.length;
    let count = 0;
    const next = (em) => {
      setTimeout((st) => {
        if (stack.length) {
          const entry = stack.pop();
          const categories = entry.categories;
          delete entry.categories;
          Core.log(entry, categories);
          Candidate.post(entry, (response) => {
            count++;
            this.setMessage(`Created ${count} of ${length} Candidates`);
          });
          /* TODO fix categories update
          Core.post Candidate(entry, categories, response => {
            count++;
            this.setMessage(`Created ${count} of ${length} Candidates`);
          });
          */
          next();
        } else {
          this.setMessage(
            `Tool #01 Summary: ${length} Candidates successfully created on MongoDB`
          );
        }
      });
    };
    next();
  };

  onClickCandidatesApply = (ev) => {
    /** TOOL 4 */
    ev.preventDefault();
    this.cleanResults();
    if (
      window.confirm(
        `This action will to ${this.state.action === 'replace'
          ? 'drop table and insert new entries on'
          : 'insert new entries on'
        } ${this.state.table}\nAre you sure that want to continue?`
      )
    ) {
      const length = this.state.mappedEntries.length;
      let count = 0;
      let fails = 0;
      this.cleanResults();
      this.state.mappedEntries.forEach((entry) => {
        Candidate.post(
          entry,
          (response) => {
            count++;
            this.setMessage(
              count + fails === length
                ? `${count} Candidates succesfully inserted on MongoDB; ${fails} failures`
                : `Inserted ${count} of ${length} Candidates`
            );
          },
          (response) => {
            fails++;
            this.setMessage(
              count + fails === length
                ? `${length} Candidates succesfully inserted on MongoDB; ${fails} failures`
                : `${fails} failures of ${length} Candidates`
            );
            this.setResults(<span className="cred">Failure</span>, [
              <pre>{JSON.stringify({ entry, response }, null, 4)}</pre>,
            ]);
          }
        );
      });
    }
  };

  onDropChange = (ev, file, err) => {
    /** TOOL #04 */
    ev.preventDefault();
    this.cleanResults();
    Core.log(file, typeof file);
    this.setMessage('Processing File...');
    readTSV(file, (lines) => {
      const table = lines[1][0];
      const action = lines[1][1];
      const headers = lines[2];
      const entries = [...lines.slice(4)];
      const length = String(entries.length);
      const mappedEntries = [];
      const failures = [];
      const mapEntries = (entries) => {
        setTimeout((st) => {
          this.setMessage(`Mapping: left ${entries.length} of ${length}`);
          mappedEntries.push(mapCandidateEntry(headers, entries.pop()));
          if (!!entries.length) {
            mapEntries(entries);
          } else {
            !!failures.length &&
              this.setResults(
                <span className="cyellow">FAILURES FROM TSV</span>,
                [<pre>{JSON.stringify(failures, null, 4)}</pre>]
              );
            this.setResults(<span className="clime">DATA FROM TSV</span>, [
              <pre>{JSON.stringify(mappedEntries, null, 4)}</pre>,
            ]);
            this.setState({
              table,
              action,
              mappedEntries,
            });
            this.setMessage(
              `Successfully Mapped: ${mappedEntries.length} of ${length}`
            );
          }
        });
      };
      const mapCandidateEntry = (headers, entry) => {
        const result = {};
        headers.forEach((key, index) => {
          let value = entry[index].trim();
          if (value && !/false/i.test(key)) {
            if (key === 'firstName' && !value) {
              failures.push(result);
            }
            if (key === 'lastName' && !value) {
              failures.push(result);
            }
            if (key === 'email') {
              value = value.toLowerCase();
            }
            if (key === 'platformRating' && !Number(value)) {
              value = parsePlatformRating(value);
            }
            if (
              key === 'introduced' ||
              key === 'lastAction' ||
              key === 'createdAt' ||
              key === 'updatedAt'
            ) {
              try {
                value = new Date(value).toISOString();
              } catch (ex) {
                console.warn(`Invalid Date ${value}`);
                failures.push(result);
              }
            }
            result[key] = value;
          }
        });
        if (headers.length !== entry.length) {
          failures.push(result);
        }
        return result;
      };
      this.setMessage(`${length} entries`);
      mapEntries(entries);
    });
  };

  setMessage = (message) => setTimeout((st) => this.setState({ message }));

  toggleCollapse = () => {
    this.setState({ open: !this.state.open });
  };

  toggleCollapseOthers = () => {
    this.setState({ openOthers: !this.state.openOthers });
  };

  render() {
    const {
      open,
      openOthers,
      statusInfo,
      isStopSignal,
      isRecursiveOperationRunning,
      selectedEngagementsStatus = 'open',
    } = this.state;
    const unitTestingAllFiltersInListPages = Store.get(
      'unitTestingAllFiltersInListPages'
    );
    const unitTestingLocationsInMatchPages = Store.get(
      'unitTestingLocationsInMatchPages'
    );
    console.debug(this.state);
    return (
      <div className="list tools">
        <div className="inline-blocks v-align-tops">
          <div className="col4 scroll-x">
            <Row>
              <Col className="c-gray margin-tops" fullWidth>
                <label>
                  FIX MISSING STREAK-BOX ON ENGAGEMENTS&nbsp;
                  <span className="f-small">(tool#8)</span>
                </label>
                <br />
                <div className="d-flex">
                  <div className="w40p">
                    <FormControl
                      component="fieldset"
                      className="material-v4-form-control m-2"
                    >
                      <FormLabel component="legend">
                        Engagement Status
                      </FormLabel>
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={selectedEngagementsStatus}
                        onChange={(event) => {
                          this.setState({
                            selectedEngagementsStatus: event.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value="open"
                          control={<Radio />}
                          label="Open"
                        />
                        <FormControlLabel
                          value="closed"
                          control={<Radio />}
                          label="Closed"
                        />
                        <FormControlLabel
                          value="all"
                          control={<Radio />}
                          label="All"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="w60p">
                    <ol>
                      <li>
                        <small>Inspect Boxes & Engagements</small>
                      </li>
                      <AsyncActionButton
                        label="Inspect"
                        onClick={this.onClickInspectBoxesAndEngagements}
                        className="center m-1"
                        primary
                        fullWidth
                      />
                      <li>
                        <small>Fix Engagements without boxKey</small>
                      </li>
                      <AsyncActionButton
                        label="Fix"
                        onClick={this.onClickFixEngagementsMissingBoxKey}
                        className="center m-1"
                        primary
                        fullWidth
                        disabled={
                          !Object.keys(
                            Core.getKeyValue('missing-box-key-engagements') ||
                            {}
                          ).length ||
                          !Core.getKeyValue('engagements-streak-pairs')
                        }
                      />
                      <li>
                        <small>
                          Fix Engagements with boxKey where is not in Streak
                        </small>
                      </li>
                      <AsyncActionButton
                        label="Fix"
                        onClick={this.onClickFixEngagementsInvalidBoxKey}
                        className="center m-1"
                        primary
                        fullWidth
                        disabled={
                          !Object.keys(
                            Core.getKeyValue('engagementsWithInvalidBoxKey') ||
                            {}
                          ).length
                        }
                      />
                      {isRecursiveOperationRunning && (
                        <Button
                          onClick={(event) =>
                            this.setState({ isStopSignal: true })
                          }
                          variant="contained"
                          color="secondary"
                          className="center m-1 c-white"
                          fullWidth
                        >
                          {isStopSignal ? 'STOPPING...' : 'STOP'}
                        </Button>
                      )}
                    </ol>
                  </div>
                </div>
              </Col>
            </Row>

            <Divider className="mt-2" />
            <List>
              <ListItem button onClick={this.toggleCollapse}>
                <ListItemIcon>
                  <Icon>
                    <i className="material-icons">build</i>
                  </Icon>
                </ListItemIcon>
                <ListItemText primary="SETUP DATABASE" />
                {open ? (
                  <Icon>
                    <i className="material-icons">expand_less</i>
                  </Icon>
                ) : (
                  <Icon>
                    <i className="material-icons">expand_more</i>
                  </Icon>
                )}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Row>
                  <Col className="c-gray margin-tops">
                    <label>
                      STEP 1 <span className="f-small">(tool#4)</span>
                    </label>
                    <ol className="padding-side">
                      <li>Drop a TSV file to get Candidates.</li>
                      <li>
                        Then click on APPLY to import Candidates into MongoDB.
                      </li>
                    </ol>
                    <div className="c-red">
                      Be careful! this tool could duplicate Candidates in
                      MongoDB if you don't know what you are doing.
                    </div>
                    <br />
                  </Col>
                  <Col className="margin-tops">
                    <DragAndDropZone
                      accept="text/tsv"
                      placeholder={
                        <Fragment>
                          Drag and drop or click to select a TSV file to upload
                          <br />
                          (tab-separated value .tsv)
                        </Fragment>
                      }
                      maxSize={1000000}
                      onChange={this.onDropChange}
                      fullWidth
                    />
                    {!!this.state.mappedEntries && (
                      <RaisedButton
                        label={'APPLY'}
                        onClick={this.onClickCandidatesApply}
                        className="center"
                        primary
                        fullWidth
                      />
                    )}
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col className="c-gray margin-tops">
                    <label>
                      STEP 2 <span className="f-small">(tool#1)</span>
                    </label>
                    <ol className="padding-side">
                      <li>
                        Click on FIND CANDIDATES in Streak but not in MongoDB.
                      </li>
                      <li>
                        Then click on CREATE to insert the Candidates into
                        MongoDB.
                      </li>
                    </ol>
                  </Col>
                  <Col className="margin-tops">
                    <RaisedButton
                      label="Find Candidates"
                      onClick={this.getMissingCandidates}
                      className="center"
                      primary
                      fullWidth
                      disabled
                    />
                    {!!this.missingCandidates.length && (
                      <RaisedButton
                        label="Create"
                        onClick={this.onClickCreateCandidates}
                        className="center"
                        primary
                        fullWidth
                      />
                    )}
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col className="c-gray margin-tops">
                    <label>
                      STEP 3 <span className="f-small">(tool#3)</span>
                    </label>
                    <ol className="padding-side">
                      <li>
                        Click on CREATE ENGAGEMENTS to insert new Engagements on
                        MongoDB based on Streak Boxes.
                      </li>
                    </ol>
                    <div>
                      This tool just will record the jobId, candidateId and
                      boxId. You must run the following tools to get updated
                      other fields such as dates from Streak Boxes.
                    </div>
                    <br />
                  </Col>
                  <Col className="margin-tops">
                    <RaisedButton
                      label="Create Engagements"
                      onClick={this.getMissingEngagements}
                      className="center"
                      primary
                      fullWidth
                      disabled
                    />
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col className="c-gray margin-tops">
                    <label>
                      STEP 4 <span className="f-small">(tool#3.1)</span>
                    </label>
                    <ol className="padding-side">
                      <li>
                        Click on ADD ROLES to update the Candidate Roles on
                        MongoDB based on Streak Boxes.
                      </li>
                    </ol>
                  </Col>
                  <Col className="margin-tops">
                    <RaisedButton
                      label="Add Roles"
                      onClick={this.getMissingRoles}
                      className="center"
                      primary
                      fullWidth
                      disabled
                    />
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col className="c-gray margin-tops">
                    <label>
                      STEP 5 <span className="f-small">(tool#2)</span>
                    </label>
                    <ol className="padding-side">
                      <li>
                        Click on UPDATE ENGAGEMENTS to copy Streak Boxes data
                        such as dates into the Engagements on MongoDB.
                      </li>
                    </ol>
                    <div>
                      This tool also will update the engagementURL in the Streak
                      Boxes, with the url related to the environment which is
                      running this tool.
                    </div>
                    <br />
                  </Col>
                  <Col className="margin-tops">
                    <RaisedButton
                      label="Update Engagements"
                      onClick={this.syncEngagements}
                      className="center"
                      primary
                      fullWidth
                    />
                  </Col>
                </Row>
              </Collapse>
            </List>
            <Divider />
            <List>
              <ListItem button onClick={this.toggleCollapseOthers}>
                <ListItemIcon>
                  <Icon>
                    <i className="material-icons">build</i>
                  </Icon>
                </ListItemIcon>
                <ListItemText primary="OTHER TOOLS" />
                {openOthers ? (
                  <Icon>
                    <i className="material-icons">expand_less</i>
                  </Icon>
                ) : (
                  <Icon>
                    <i className="material-icons">expand_more</i>
                  </Icon>
                )}
              </ListItem>
              <Collapse in={openOthers} timeout="auto" unmountOnExit>
                <Row>
                  <Col className="c-gray margin-tops">
                    <label>
                      OTHER 1 <span className="f-small">(tool#6)</span>
                    </label>
                    <ol className="padding-side">
                      <li>
                        Click on UPDATE STATES to change the old Category/Tag to
                        the new approach.
                      </li>
                    </ol>
                    <div>This tool can be runned many times.</div>
                    <br />
                  </Col>
                  <Col className="margin-tops">
                    <RaisedButton
                      label="Update States"
                      onClick={(ev) => Definition.updateTableStates()}
                      className="center"
                      primary
                      fullWidth
                    />
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col className="c-gray margin-tops">
                    <label>
                      OTHER 2<span className="f-small">(tool#7)</span>
                    </label>
                    <ol className="padding-side">
                      <li>Click to update Candidate resume URLs for S3.</li>
                    </ol>
                    <div>
                      This tool can only be run more than once. URLs already
                      migrated will be ignored.
                    </div>
                    <br />
                  </Col>
                  <Col className="margin-tops">
                    <RaisedButton
                      label="Update Resumes URLS"
                      onClick={this.updateResumesURLS}
                      className="center"
                      primary
                      fullWidth
                    />
                  </Col>
                </Row>
              </Collapse>
            </List>
            <Divider />

            <Row>
              <Col fullWidth>
                <label className="to-upper-case">Unit Testing</label>
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={unitTestingAllFiltersInListPages}
                        onChange={(event) => {
                          Store.set(
                            'unitTestingAllFiltersInListPages',
                            event.target.checked
                          );
                          this.setState({});
                        }}
                        color="primary"
                      />
                    }
                    label="All Filters In ListPages"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={unitTestingLocationsInMatchPages}
                        onChange={(event) => {
                          Store.set(
                            'unitTestingLocationsInMatchPages',
                            event.target.checked
                          );
                          this.setState({});
                        }}
                        color="primary"
                      />
                    }
                    label="Location Menus In Match Pages"
                  />
                </div>
              </Col>
            </Row>

            {Core.isAdmin() && (
              <Row>
                <Col>
                  <label>UI Version</label>
                  <RadioAntD.Group
                    defaultValue={DEFAULT_UI_VERSION}
                    value={Core.getUIVersion()}
                    size="small"
                    style={{ marginTop: 16 }}
                    onChange={(event) => {
                      let value = parseInt(event.target.value);
                      let { history, location } = this.props;
                      Core.setUIVersion(value);
                      let pathname = location.pathname.replace(
                        /\/v2|\/v3/g,
                        ''
                      );
                      if (value === 3) {
                        history.push(`/v3${pathname}`);
                      } else if (value === 2) {
                        history.push(`/v2${pathname}`);
                      } else {
                        history.push(pathname);
                      }
                    }}
                  >
                    <RadioAntD.Button value={1}>v1</RadioAntD.Button>
                    {/** * /}<RadioAntD.Button value={2}>v2</RadioAntD.Button>{/** */}
                    <RadioAntD.Button value={3}>v3</RadioAntD.Button>
                  </RadioAntD.Group>
                </Col>
              </Row>
            )}
          </div>

          <div className="col8 margin-tops">
            <small className="c-red">{statusInfo}</small>
            <div
              className="results fbigs"
              ref={(results) => (this.results = results)}
            >
              {this.state.results.map((item) => (
                <div key={Core.getKey()}>{item}</div>
              ))}
            </div>
            {!!this.state.results.join('').trim() && (
              <div className="d-flex">
                <FlatButton
                  label="Scroll to top"
                  className="ml-auto mr-6"
                  onClick={this.scrollToTop}
                />
              </div>
            )}
            <div className="align-right tools">{this.state.message}</div>
          </div>
        </div>
      </div>
    );
  }
}

/* EXPORTS ==================================== */

export default Tools;

/* ============================================ */
