/** ============================================ µ
 * @description JobCard / LocationSection [JSX]
 *              UI Component
 * @route       /jobs
 * @relatedTo   epic-3038-story-3382
 * @createdAt   2021-07-02
 * @updatedAt   2021-08-05
 * ============================================ */
/* IMPORTS ==================================== */

import React from "react";
import { useTranslation } from "react-i18next";
import Core from "../../../lib/Core";
import Definition, {
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID, LOCATION__EMP_HIRING_LOCS_ID,
  RELOCATION__LABELS
} from "../../../lib/Definition";
import LocationLib from "../../../lib/DefinitionLocation.lib";
import CardContent from "../../Details/CardContent";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

/**
 * JobCard / LocationSection
 * @param {object} props
 * @returns
 */
function LocationSection(props) {
  const { t } = useTranslation();
  const { job = {} } = props;
  const {
    employer = {},
    _employerHeadQuarterAddress: employerHeadQuarterAddress = "",
    inOfficeRemoteFlags = [],
    officeLocations = [],
    candidateLocations = [],
    locationDetails = '',
    relocation = null,
    relocationExtraInfo = '',
  } = job;
  const {
    candidateLocations: employerCandidateLocations = []
  } = employer;
  const { addressStreet: employerAddressStreet = "" } = employer;
  const isHqAddressAvailable = (
    !!employerAddressStreet.trim() &&
    !!employerHeadQuarterAddress.trim()
  );
  const _inOfficeRemoteFlags = Definition.getLabels("inOfficeRemote", inOfficeRemoteFlags).join(", ").trim();
  const _officeLocations = (
    (
      inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID) ||
      inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
    ) &&
    (!!officeLocations.length

      /* epic-3038(new locations)-story-3689-m2 | 2021-08-05 Thu µ */
      ? LocationLib.getLocationsString({ locations: officeLocations })

      : t('term.noneSpecified.label')
    )
  );
  const _candidateLocations = (
    (
      inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
    ) &&
    (

      /* epic-3038(new locations)-story-3689-m2 | 2021-08-05 Thu µ */
      LocationLib.getLocationsString({
        locations: [
          ...candidateLocations,
          ...(
            candidateLocations.includes(LOCATION__EMP_HIRING_LOCS_ID)
              ? employerCandidateLocations
              : []
          )
        ]
      }) ||

      t('term.noneSpecified.label')
    )
  );
  const _relocation = RELOCATION__LABELS[relocation];
  return (
    Core.isAdminOrTrusted() && (
      <CardContent title={t('jobs.card.locationSection.label')}>
        <div className="d-flex flex-wrap contents">

          {!!_inOfficeRemoteFlags && (
            <div className="w-col-1 mr-1 mb-1">
              <span className="normal-weight">
                {t('jobs.card.locationSection.inOfficeRemoteFlags.label')}
              </span><br />
              {_inOfficeRemoteFlags}
            </div>
          )}

          {!!_officeLocations && (
            <div className="w-col-1 mr-1 mb-1">
              <span className="normal-weight">
                {t('jobs.card.locationSection.officeLocations.label')}
              </span><br />
              {_officeLocations}
            </div>
          )}

          {!!_candidateLocations && (
            <div className="w-col-1 mr-1 mb-1">
              <span className="normal-weight">
                {t('jobs.card.locationSection.candidateLocations.label')}
              </span><br />
              {_candidateLocations}
            </div>
          )}

          {isHqAddressAvailable && (
            <div className="w-col-2 mr-1 mb-1">
              <span className="normal-weight">
                {t('jobs.card.locationSection.employerHeadQuarterAddress.label')}
              </span><br />
              {employerHeadQuarterAddress}
            </div>
          )}

          {!!locationDetails && (
            <div className="w-col-2 mr-1 mb-1">
              <span className="normal-weight">
                {t('jobs.card.locationSection.locationDetails.label')}
              </span><br />
              {locationDetails}
            </div>
          )}

          {!!_relocation && (
            <div className="w-col-1 mr-1 mb-1">
              <span className="normal-weight">
                {t('jobs.card.locationSection.relocation.label')}
              </span><br />
              {_relocation}
            </div>
          )}

          {!!_relocation && !!relocationExtraInfo && (
            <div className="w-col-1 mr-1 mb-1">
              <span className="normal-weight">
                {t('jobs.card.locationSection.relocationExtraInfo.label')}
              </span><br />
              {relocationExtraInfo}
            </div>
          )}

        </div>
      </CardContent>
    )
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { LocationSection as default, LocationSection };

/* ============================================ */
