import React, { Component, Fragment } from "react";

/* Material UI theme   */
import FlatButton from "material-ui/FlatButton";

/* Custom Styles */

class Heading extends Component {
    render(){
        return(
            <Fragment>
                <div 
                    style={{
                        padding: '10px 0px',
                        textAlign: 'center',
                        boxShadow: '5px 1px 10px 0px #ccc'
                    }}
                    id={this.props.idRef}
                    className={this.props.classNameRef}
                >
                    <FlatButton
                        label={this.props.heading}
                        className="filter-right-heading"
                    />
                </div>
            </Fragment>
        );
    }
}

export default Heading;