import { colors } from '../../lib/Core';

const Styles = {
  AppBar: {
    marginBottom: '24px',
    title: {
      color: colors.purple,
      fontSize: '20px',
    },
    rightLabel: {
      color: colors.cyan,
    },
    backButton: {
      color: colors.darkGray,
      marginTop: '5px'
    },
    nav: {
      backgroundColor: colors.white
    },
    navNew: {
      backgroundColor: colors.white
    },
    iconElementLeft: {
      color: colors.darkGray,
      marginTop: "5px"
    }
  },
  Paper: {
    margin: 'auto',
    maxWidth: 856
  },
  List: {
    cancel: {
      border: '1px solid',
      borderColor: colors.crimson,
      color: colors.crimson,
      margin: '0 5px',
      float: 'left'
    },
    save: {
      border: '1px solid',
      borderColor: colors.purple,
      color: colors.purple,
      margin: '0 5px'
    },
    button: {
      backgroundColor: colors.cyan,
      color: colors.white,
      margin: '0 5px'
    }
  },
  TextField: {
    width: '100%',
    fontSize: '12px',
    underlineStyle: {
      borderColor: 'transparent',
      borderWidth: 0
    },
    inputStyle: {
      backgroundColor: colors.white,
      color: colors.black,
      fontFamily: 'Roboto',
      fontSize: '14px',
      lineHeight: '24px',
      padding: '8px',
      border: '1px solid #ddd',
      borderRadius: '3px',
    }
  },
  CandidateMatch: {
    margins: {
      m20: {
        margin: '20px 20px'
      },
      mRight10: {
        marginRight: '10px'
      },
      mBottom10: {
        marginBottom: '10px'
      },
      mBottom20: {
        marginBottom: '20px'
      }
    },
    padding: {
      m2050: {
        padding: '20px 50px'
      },
      m1540: {
        padding: '15px 40px'
      },
      m2020: {
        padding: '20px 20px'
      },
      m2010: {
        padding: '20px 10px'
      },
      mRight10: {
        paddingRight: '10px'
      }
    },
    border: {
      bLeft: {
        borderLeft: '1px solid lightgray'
      },
      bBottom: {
        borderBottom: '1px solid lightgray'
      },
      bRight: {
        borderRight: '1px solid lightgray'
      }
    },
    fonts: {
      sizes: {
        f12: {
          font: '12px',
        },
        f14: {
          font: '14px'
        }
      }
    },
    backgrounds: {
      colors: {
        backgroundColor: 'white'
      } 
    }
  }

};

export default Styles;