import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import {
  Chip,
  Dialog,
  Divider,
  FlatButton,
  IconButton,
  TextField,
} from 'material-ui';

import loader from '../../assets/images/loader.gif';
import FuzzySearch from 'fuzzy-search';
import Core, { colors } from '../../lib/Core';
import Definition from '../../lib/Definition';

class Chips extends Component {
  constructor() {
    super(...arguments);
    let items =
      this.props.items && this.props.items.length
        ? this.props.items
        : Definition.get(this.props.name);
    let additionalItems =
      this.props.additionalItems && this.props.additionalItems.length
        ? this.props.additionalItems
        : [];
    items = _.sortBy(items, (o) => o.label);
    //items.sort((a,b) => (a.label.toLowerCase()  - b.label.toLowerCase()));
    this.state = {
      selected: [],
      items,
      additionalItems,
      search: '',
      open: false,
    };
  }
  open = (ev) => {
    const { hidePopupContent, onClickAddChipEvent } = this.props;
    !!hidePopupContent && hidePopupContent();
    !!onClickAddChipEvent && onClickAddChipEvent();

    this.setState((state) => {
      state.open = true;
      this.setSelected(state);

      return state;
    });
  };

  componentDidMount() {
    this.setState((state) => {
      this.setSelected(state);
      return state;
    });
  }

  setSelected = (state) => {
    state.selected = [];
    let propsValues = this.props.values.map((o) => {
      return typeof o === 'object' ? o : String(o);
    });
    if (!!state.additionalItems.length) {
      state.additionalItems.forEach((item) => {
        let found =
          typeof item === 'object'
            ? !!propsValues.find((v) =>
                typeof v === 'object'
                  ? (String(v.id) === String(item.id) ||
                      String(v.label) === String(item.label)) &&
                    v.key === item.key
                  : !!~propsValues.indexOf(String(item.id))
              )
            : !!~propsValues.indexOf(String(item.id));
        if (found) {
          state.selected.push({ ...item });
          item.selected = true;
        } else {
          delete item.selected;
        }
      });
    }
    state.items.forEach((item) => {
      let found =
        typeof item === 'object'
          ? !!propsValues.find((v) =>
              typeof v === 'object'
                ? (String(v.id) === String(item.id) ||
                    String(v.label) === String(item.label)) &&
                  v.key === item.key
                : !!~propsValues.indexOf(String(item.id))
            )
          : !!~propsValues.indexOf(String(item.id));
      if (found) {
        state.selected.push({ ...item });
        item.selected = true;
      } else {
        delete item.selected;
      }
    });
  };
  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (!_.isEmpty(nextProps.items) && nextProps.items !== this.state.items) {
      this.setState({ items: nextProps.items });
    }
    if (nextProps.additionalItems !== this.props.additionalItems) {
      this.setState({ additionalItems: nextProps.additionalItems });
    }
    if (nextProps.values !== this.props.values) {
      this.setState({ selected: nextProps.values }, () => {
        this.setState((state) => {
          this.setSelected(state);
          return state;
        });
      });
    }
  }

  apply = (ev) => {
    const { displayPopupContent } = this.props;
    !!displayPopupContent && displayPopupContent();
    this.setState((state) => {
      state.open = false;
      this.props.onChange &&
        this.props.onChange(
          state.selected.map((item) => item.id),
          state.selected
        );
      return state;
    });
  };

  findValue = (id) => {
    let item = {};
    let { items, additionalItems } = this.props;

    if (typeof id === 'object' && !!id.id) {
      if (items) {
        item =
          items.find(
            (item) =>
              (item.id === id.id || item.label === id.label) &&
              item.key === id.key
          ) || {};
      }

      if (!item.id && !!additionalItems && !!additionalItems.length) {
        item =
          additionalItems.find(
            (item) =>
              (item.id === id.id || item.label === id.label) &&
              item.key === id.key
          ) || {};
      }
    }

    if (typeof id === 'string') {
      if (items) {
        item = items.find((item) => item.id === id) || {};
      }
    }

    return item ? item.label : 'notFound';
  };

  chipOption = (model, selected) => {
    const onClickHandler = (model, selected) => (ev) => {
      if (selected) {
        this.setState(
          (state) => {
            state.selected = state.selected.filter(
              (item) => item.id !== model.id
            );

            if (!!state.items.find((item) => item.id === model.id)) {
              delete state.items.find((item) => item.id === model.id).selected;
            }

            if (!!state.additionalItems.find((item) => item.id === model.id)) {
              delete state.additionalItems.find((item) => item.id === model.id)
                .selected;
            }

            return state;
          },
          () => {
            if (!!this.props.withOutDialog) {
              this.props.onChange &&
                this.props.onChange(
                  this.state.selected.map((item) => item.id),
                  this.state.selected
                );
            }
          }
        );
      } else {
        if (!this.state.selected.find((item) => item.id === model.id)) {
          this.setState(
            (state) => {
              state.selected.push(model);
              state.items.find((item) => item.id === model.id).selected = true;
              return state;
            },
            () => {
              if (!!this.props.withOutDialog) {
                this.props.onChange &&
                  this.props.onChange(
                    this.state.selected.map((item) => item.id),
                    this.state.selected
                  );
              }
            }
          );
        }
      }
    };

    return !!this.props.chipOptionRenderer ? (
      this.props.chipOptionRenderer(
        model,
        selected,
        onClickHandler(model, selected)
      )
    ) : (
      <Chip
        key={Core.getKey()}
        backgroundColor={selected ? '#536DFF' : '#fff'}
        labelColor={
          selected ? '#FFF' : !selected && model.selected ? '#CCC' : '#7A7A7A'
        }
        style={{
          margin: 4,
          display: 'inline-block',
          border: selected
            ? '1px solid #536DFF'
            : !selected && model.selected
            ? '1px dotted #7A7A7A'
            : '1px solid #7A7A7A',
        }}
        onClick={onClickHandler(model, selected)}
      >
        {model.label}
      </Chip>
    );
  };

  chipAdditionalOption = (model, selected) => {
    return (
      <Chip
        key={Core.getKey()}
        backgroundColor={!!model.selected ? '#536DFF' : '#00bfa5'}
        labelColor={
          selected ? '#FFF' : !selected && model.selected ? '#CCC' : '#FFF'
        }
        style={{
          margin: 4,
          display: 'inline-block',
          border: selected
            ? '1px solid #536DFF'
            : !selected && model.selected
            ? '1px dotted #7A7A7A'
            : '1px solid #7A7A7A',
        }}
        onClick={(ev) => {
          if (selected) {
            this.setState(
              (state) => {
                state.selected = state.selected.filter(
                  (item) => item.id !== model.id
                );
                delete state.additionalItems.find(
                  (item) => item.id === model.id
                ).selected;
                return state;
              },
              () => {
                if (!!this.props.withOutDialog) {
                  !!this.props.onChange &&
                    this.props.onChange(
                      this.state.selected.map((item) => item.id),
                      this.state.selected
                    );
                }
              }
            );
          } else {
            if (!this.state.selected.find((item) => item.id === model.id)) {
              this.setState(
                (state) => {
                  state.selected.push(model);
                  state.additionalItems.find(
                    (item) => item.id === model.id
                  ).selected = true;
                  return state;
                },
                () => {
                  if (!!this.props.withOutDialog) {
                    !!this.props.onChange &&
                      this.props.onChange(
                        this.state.selected.map((item) => item.id),
                        this.state.selected
                      );
                  }
                }
              );
            }
          }
        }}
      >
        {model.label}
      </Chip>
    );
  };

  mainHtmlWithDialog = () => {
    return (
      <Fragment>
        <Dialog
          title={this.props.label || this.props.heading}
          modal={false}
          open={this.state.open}
          onRequestClose={() => this.setState({ open: false })}
          autoScrollBodyContent={true}
          contentStyle={!this.props.openWidth ? { width: 417 } : {}}
          bodyStyle={{ padding: '0px 20px 20px' }}
          actions={
            <Fragment>
              <FlatButton
                label="Cancel"
                primary={true}
                onClick={(ev) => {
                  const { displayPopupContent } = this.props;
                  !!displayPopupContent && displayPopupContent();
                  this.setState({ open: false });
                }}
              />
              <FlatButton
                label="Apply"
                primary={true}
                keyboardFocused={false}
                onClick={this.apply}
              />
            </Fragment>
          }
        >
          {this.mainHtml()}
        </Dialog>
      </Fragment>
    );
  };

  mainHtml = () => {
    return (
      <Fragment>
        {this.props.topRow}
        <div style={{ position: 'relative', marginTop: 0 }}>
          <i
            className="material-icons"
            style={{
              position: 'absolute',
              top: 16,
              left: 0,
              width: 24,
              padding: 0,
            }}
          >
            search
          </i>
          <TextField
            name="searcher"
            placeholder="Separate search terms by comma or space"
            required={true}
            style={{
              width: 'calc(100% - 32px)',
              fontSize: 14,
              margin: '0 8px 0 32px',
            }}
            underlineFocusStyle={{ borderColor: colors.purple }}
            type="text"
            value={this.state.search}
            onChange={(event, search) => this.setState({ search })}
            autoFocus
          />
          {!!this.state.search.length && (
            <IconButton
              style={{
                position: 'absolute',
                top: 0,
                right: 8,
                width: 24,
                padding: 0,
              }}
              onClick={(ev) => this.setState({ search: '' })}
            >
              <i className="material-icons">clear</i>
            </IconButton>
          )}
        </div>
        {!!this.state.selected.length && (
          <Fragment>
            {!!this.props.selectedTitle ? this.props.selectedTitle : null}
            <div className="padding-top-bottom">
              {this.state.selected.map((item, i) =>
                item.type === 'chipGroup'
                  ? this.chipAdditionalOption(item, true)
                  : this.chipOption(item, true)
              )}
            </div>
            <Divider />
          </Fragment>
        )}
        {!!this.props.isPartialLoadingComp && this.props.isPartialLoadingComp()}
        {!!this.state.additionalItems.length ? (
          <Fragment>
            {!!this.props.groupingTitle ? this.props.groupingTitle : null}
            <div className="padding-top-bottom">
              {(!!this.state.search.length
                ? ((em) => {
                    const result = [];
                    const words = this.state.search
                      .trim()
                      .split(/,\s*|\s|\||;/)
                      .filter((term) => !!term.trim());
                    words.forEach((word) => {
                      new FuzzySearch(this.state.additionalItems, ['label'], {
                        caseSensitive: false,
                      })
                        .search(word)
                        .forEach(
                          (item) =>
                            !result.find(
                              (resItem) => item.label === resItem.label
                            ) && result.push(item)
                        );
                    });
                    return result;
                  })()
                : this.state.additionalItems
              ).map((item, i) => this.chipAdditionalOption(item))}
            </div>
            <Divider />
          </Fragment>
        ) : null}
        {!!this.props.isLoading ? (
          <div className="not-found inline-blocks">
            Loading&nbsp;
            <img alt="loading..." height="14" src={loader} />
          </div>
        ) : (
          <Fragment>
            {!!this.state.items.length && !!this.props.allSkillsTitle
              ? this.props.allSkillsTitle
              : null}
            <div className="padding-top-bottom">
              {(!!this.state.search.length
                ? ((em) => {
                    const result = [];
                    const words = this.state.search
                      .trim()
                      .split(/,\s*|\s|\||;/)
                      .filter((term) => !!term.trim());
                    words.forEach((word) => {
                      new FuzzySearch(this.state.items, ['label'], {
                        caseSensitive: false,
                      })
                        .search(word)
                        .forEach(
                          (item) =>
                            !result.find(
                              (resItem) => item.label === resItem.label
                            ) && result.push(item)
                        );
                    });
                    return result;
                  })()
                : this.state.items
              ).map((item, i) => this.chipOption(item))}
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  };

  onRequestDelete = (id) => (ev) => {
    this.props.onChange &&
      this.props.onChange(
        this.props.values.filter((_id) => _id !== id),
        this.props.values.filter((_id) => _id !== id)
      );
    this.props.onRemoveChip && this.props.onRemoveChip(id);
  };

  render() {
    return (
      <Fragment>
        {!!this.props.label && <label>{this.props.label}</label>}
        <span className="cgray f-small">{this.props.sub}</span>
        <div className="chips">
          {!this.props.withOutDialog ? (
            <div className="inline-blocks">
              {!this.props.hideExternalValues &&
                this.props.values.map((id) => {
                  let value = this.props.commonCase
                    ? this.findValue(id)
                    : Definition.getLabel(this.props.name, id);
                  return !!this.props.chipRenderer ? (
                    this.props.chipRenderer(
                      id,
                      this.props.name,
                      value,
                      this.onRequestDelete(id)
                    )
                  ) : (
                    <Chip
                      key={`${this.props.name}-tag-${id}`}
                      className="chip"
                      backgroundColor={colors.purple}
                      labelStyle={{
                        fontWeight: 300,
                        fontSize: 13,
                      }}
                      style={{
                        margin: 4,
                        display: 'inlineBlock',
                      }}
                      labelColor={colors.white}
                      onRequestDelete={this.onRequestDelete(id)}
                    >
                      {value}
                    </Chip>
                  );
                })}
              <Chip
                title="Tap to add"
                backgroundColor={colors.white}
                style={{
                  margin: 4,
                  display: 'inlineBlock',
                  border: '1px solid ' + colors.purple,
                }}
                labelColor={
                  !this.props.labelColor ? colors.purple : this.props.labelColor
                }
                className={
                  !this.props.addButtonClass
                    ? 'chip'
                    : this.props.addButtonClass
                }
                onClick={this.open}
              >
                {this.props.title ? this.props.title : '+ Add'}
              </Chip>
            </div>
          ) : null}
          {!this.props.withOutDialog
            ? this.mainHtmlWithDialog()
            : this.mainHtml()}
        </div>
      </Fragment>
    );
  }
}

export default Chips;
