/** ============================================ µ
 * @description AddNewButton [JSX]
 *              UI Component
 * @createdAt   2021-11-10
 * @updatedAt   2021-11-10
 * ============================================ */
/* IMPORTS ==================================== */

import { Button } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import Core from "../../lib/Core";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function AddNewButton({ enhancedReactTableContext, style, className }) {
  const { context: { config } } = enhancedReactTableContext;
  const { addNew = {} } = config;
  const { label = '+ New Element', to = '/' } = addNew;
  return (
    <NavLink exact to={Core.getLink(to)} style={style} className={className}>
      <Button variant="contained" color="primary" size="sm" className="text-truncate">
        {label}
      </Button>
    </NavLink>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { AddNewButton as default, AddNewButton };

/* ============================================ */