import Grid from "@mui/material/Grid";
import React, { Component } from "react";
import Candidate from "../../../../lib/Candidate";
import TextCVContentDrawer from "../JobPipe/TextCVContentDrawer";

class DoubleContentDrawer extends Component {
  render() {
    const { selectedJob, candidate } = this.props;

    return (
      <Grid container style={{ width: "100%", height: "100%", overflow: "hidden" }}>
        <Grid item xs={12} sm={5}>
          <Grid item xs={12}
            style={{
              width: "100%",
              height: "95vh",
              overflowX: "hidden",
              overflowY: "scroll"
            }}
          >
            {!!candidate && !!candidate.resumeTxtUrl && !!selectedJob
              ? (
                <TextCVContentDrawer
                  style={{
                    width: "100%",
                    height: "100%"
                  }}
                  selectedJob={selectedJob}
                  candidate={candidate}
                />
              ) : (
                <p>No Text CV</p>
              )}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={7}>
          {/* <FormControlLabel
                control={
                    <Switch
                        size="small"
                        edge="start"
                        checked={this.state.showLinkedIn}
                        color="primary"
                        onChange={(event)=>{
                            this.toggleCV(event)
                        }}
                        value={this.state.showLinkedIn}
                    />
                }
                label="LinkedIn"
                labelPlacement="end"
            /> */}
          <Grid item xs={12}
            style={{
              width: "100%",
              height: "95vh",
            }}
          >
            {!!candidate && !!candidate.resumePdfUrl
              ? (
                <object aria-label="pdf" data={`${Candidate.getMyPdfUrl(candidate)}?#view=fitH&toolbar=1`} type="application/pdf" width="100%" height="100%"></object>
              ) : (
                <p>No CV</p>
              )}
            {/* {!this.state.showLinkedIn
                    ? (
                        !!candidate && !!candidate.resumePdfUrl
                        ? (
                            <object data={`${Candidate.getMyPdfUrl(candidate)}?#zoom=100&toolbar=1`} type="application/pdf" width="100%" height="100%"></object>
                        ) : (
                            <p>No CV</p>
                        )
                    ) : (
                        !!candidate && !!candidate.linkedInURL
                        ? (
                            <object data={`${candidate.linkedInURL}`} width="100%" height="100%"></object>
                        ) : (
                            <p>No LinkedIn</p>
                        )
                    )} */}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
export default DoubleContentDrawer;