/** ============================================ µ
 * @description BulkCopyButton [JSX]
 *              UI Component
 * @createdAt   2021-11-26
 * @updatedAt   2021-11-26
 * ============================================ */
/* IMPORTS ==================================== */

import { Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import Core from "../../lib/Core";
import Job from "../../lib/Job";
import copyHTML from "../../lib/tools/copyHtml";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function bulkCopy({ t, selected, type }) {
  if (!selected || !selected.length) {
    Core.showMessage(
      "Please select at least 1 job to copy. The selected jobs’ description will be copied to paste elsewhere"
    );
    return;
  }
  let body = '';
  selected.forEach(model => {
    body += Job.getPreview(model, type);
    body += "<br/><hr/>";
  });
  console.debug('body', body);
  copyHTML(`
    ${body}
  `)
    .then((em) => {
      Core.log("Copy email command was successful");
      Core.showMessage(
        t('jobs.copyButton.successful')
      );
    })
    .catch((ex) => {
      Core.log("Oops, unable to copy");
      Core.showMessage("Fail copy!");
    });
}

function BulkCopyButton({ enhancedReactTableContext, style, className }) {
  const [anchorEl, setAnchorEl] = useState();
  const { t, context, rows } = enhancedReactTableContext;
  const selected = rows.filter(row => row.isSelected).map(row => row.original);
  function onClick(type) {
    return event => {
      setAnchorEl(false);
      bulkCopy({ t, selected, type });
    }
  }
  return (
    context.match.path === '/v3/jobs'
      ? (
        <div
          className={className}
          style={style}
        >
          <Button
            variant="contained"
            color="secondary"
            size="sm"
            className="text-truncate"
            disabled={!selected.length}
            onClick={event => setAnchorEl(event.currentTarget)}
          >
            COPY JOB(S)
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center", }}
            transformOrigin={{ vertical: "top", horizontal: "center", }}
            onClose={event => setAnchorEl(false)}
          >
            <MenuItem onClick={onClick('large')}>
              Long JD
            </MenuItem>
            <MenuItem onClick={onClick('short')}>
              Short JD
            </MenuItem>
          </Menu>
        </div>
      )
      : null
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { BulkCopyButton as default, BulkCopyButton };

/* ============================================ */