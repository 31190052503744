/** ============================================ µ
 * @Description Employer / Process [JS]
 *              UI Component
 * @routes      /employer/edit/:employerId
 *              /employer/create
 * @updatedAt   2021-06-24
 * ============================================ */

/* IMPORTS ==================================== */

import { FlatButton } from "material-ui";
import AutoComplete from "material-ui/AutoComplete";
import Checkbox from "material-ui/Checkbox";
import DatePicker from "material-ui/DatePicker";
import { List, ListItem } from "material-ui/List";
import MenuItem from "material-ui/MenuItem";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import SelectField from "material-ui/SelectField";
import TextField from "material-ui/TextField";
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import Core, { colors } from "../../../lib/Core";
import Definition, {
  ATS_TYPE__GH_HARVEST_ID,
  ATS_TYPE__GH_INGESTION_ID,
  ATS_TYPE__NONE_ID,
  EMP_SUB_MET__ATS_ID
} from "../../../lib/Definition";
import Greenhouse from "../../../lib/services/ATS/Greenhouse.lib";
import { getAtsProviderModel } from "../../../lib/models/employer";
import Chips from "../../Forms/Chips";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";
import styles from "../../Forms/Styles";
import RichTextBox from "../../Forms/RichTextBox";
import QuestionsMustHave from "../../Shared/QuestionsMustHave";
import TreeSelectDropdown from "../../Forms/TreeSelect";

/* MAIN CLASS ================================= */

class Process extends Component {
  render() {

    const self = this;

    const lineStyle = { borderColor: "#59736f" };

    const EmployerEditController = this.props.parent;
    const { t } = this.props;

    const {
      state,
    } = EmployerEditController;

    const {
      atsTypeId,
      atsUrl,
      atsApiProviders = {},
      submissionMethods = [],
      validatingConnection = false,
      atsSourceNameWarning,
      atsUserNameWarning,
      atsUserIdWarning,
      atsUserName,
      atsUserEmail,
      atsUserDisabled,
    } = state;

    const locationTags = Definition.get("location");

    const atsTypes = (
      Definition.get("aTSTypes")
        .sort(
          (a, b) =>
            String(a.label)
              .localeCompare(String(b.label))
        )
    );

    const isAtsSelected = (
      !!atsTypeId &&
      (atsTypeId !== ATS_TYPE__NONE_ID)
    );

    const atsApiProvider = (
      atsApiProviders[atsTypeId] ||
      getAtsProviderModel({ atsTypeId })
    );

    /** µ FOR DEBUG PURPOSES * /
    console.debug('µ:Process', { atsApiProvider });
    /** */

    const {
      /** common for greenhouse */
      apiKey,
      manualSubmissionUrl,
      /** specific for greenhouse harvest */
      onBehalfOfEmployerUserName = '',
      onBehalfOfEmployerUserId,
      sourceIdAgencyId,
      agencyName = '',
      agencyRecruiterName,
      /** specific for greenhouse ingestion */
      onBehalfOfEmployerUser,
    } = atsApiProvider;

    const isAtsValidateConnectionDisabled = !(
      !!apiKey?.trim() &&
      !!agencyName?.trim() &&
      (!!onBehalfOfEmployerUserName?.trim() || !!onBehalfOfEmployerUserId)
    );

    const isAtsGhHarvestSelected = (
      atsTypeId === ATS_TYPE__GH_HARVEST_ID
    );

    const isAtsUserInfoAvailable = (
      isAtsGhHarvestSelected &&
      (atsUserName || atsUserEmail)
    );

    const isAtsGhIngestionSelected = (
      atsTypeId === ATS_TYPE__GH_INGESTION_ID
    );

    const isAtsMethodIncluded = (
      submissionMethods.includes(EMP_SUB_MET__ATS_ID)
    );

    function onChangeSelectAtsType(event, index, atsTypeId) {
      EmployerEditController.setState({
        /**
         * @todo
         * Review patch code for "ats" field
         * after 2021-06-01
         * to determine utility of maintain it.
         *
         * @see employer.patchATS | model library
         */
        ats: String(atsTypeId),
        atsTypeId
      });
    }

    function onChangeAtsProviderInput(field) {
      return (event, value) => {
        EmployerEditController.setState(({
          atsApiProviders
        }) => {
          atsApiProvider[field] = value;
          atsApiProviders[atsTypeId] = atsApiProvider;
          if (field === 'manualSubmissionUrl') {
            return {
              /**
               * @todo
               * Review patch code
               * for "atsUrl" field
               * after 2021-06-01
               * to determine utility
               * of maintain it.
               * Ask to BB.
               *
               * @see employer.patchATS - model library
               */
              atsUrl: value,
              atsApiProviders
            };
          }
          else {
            return {
              atsApiProviders
            };
          }
        });
      }
    }

    function onGreenhouseFailure(failures, suggestions, statusCode) {

      EmployerEditController.setState({ validatingConnection: false });

      Core.dialog.open({
        title: `Greenhouse API connection failed`,
        message: (
          <Fragment>
            {(
              statusCode
                ? <h4>Error code: {statusCode}</h4>
                : ''
            )}
            <h4>Error messages:</h4>
            <small>
              <b>
                <pre>{failures.join('\n\n')}</pre>
              </b>
            </small>
            <h4>Suggestions for taking action:</h4>
            <small>
              <b>
                <i>
                  <pre>{suggestions.join('\n')}</pre>
                </i>
              </b>
            </small>
          </Fragment>
        )
      });
    }

    async function getGreenhouseSourceId() {
      return await Greenhouse.harvest.getSourceId({
        apiKey,
        agencyName,
        onSuccess({
          sourceIdAgencyId = null,
          sourceName = ''
        }) {
          if (sourceName.toLowerCase() === agencyName.toLowerCase()) {
            onChangeAtsProviderInput('sourceIdAgencyId')(
              null, sourceIdAgencyId
            );
          }
          else {
            EmployerEditController.setState({
              atsSourceNameWarning: `Found "${sourceName}" for agency name rather than one provided "${agencyName}".`
            });
          }
        },
        onFailure: onGreenhouseFailure
      });
    }

    async function getGreenhouseUserById({ atsUserId }) {
      return await Greenhouse.harvest.getUserById({
        apiKey,
        userId: atsUserId,
        onSuccess({
          id,
          name: atsUserName,
          first_name,
          last_name,
          primary_email_address: atsUserEmail,
          updated_at,
          created_at,
          disabled: atsUserDisabled,
          site_admin,
          emails = [],
          employee_id,
          linked_candidate_ids = [],
        }) {
          /**
            * @note
            * The Id is the important value for us,
            * so we will prefer the user got it by id,
            * over the one got it by name.
            * For that reason here is not a conditional,
            * as it is on getGreenhouseUserByName > onSuccess
            */
          onChangeAtsProviderInput('onBehalfOfEmployerUserName')(
            null, atsUserName
          );
          EmployerEditController.setState({
            atsUserName,
            atsUserEmail,
            atsUserDisabled,
          });
        },
        onFailure(failures, suggestions, statusCode) {
          EmployerEditController.setState({
            atsUserIdWarning: `User not found, please review this user id is right.`
          }, () => {
            onGreenhouseFailure(failures, suggestions, statusCode);
          });
        }
      });
    }

    function getGreenhouseUserByName() {
      return new Promise(function (resolve, reject) {
        Greenhouse.harvest.getUserByName({
          apiKey,
          userName: onBehalfOfEmployerUserName,
          onSuccess(user) {
            if (!onBehalfOfEmployerUserId) {
              onChangeAtsProviderInput('onBehalfOfEmployerUserId')(
                null, user.id
              );
            }
            console.debug({ user });
            EmployerEditController.setState({
              atsUserName: user.name,
              atsUserEmail: user.primary_email_address,
              atsUserDisabled: user.disabled,
            }, state => {
              resolve(user);
            });
          },
          onFailure: (failures, suggestions, statusCode) => {
            onGreenhouseFailure(failures, suggestions, statusCode);
            EmployerEditController.setState({ atsUserNameWarning: failures[0] });
            reject({ failures, suggestions, statusCode });
          }
        });
      });
    }

    function updateValidations(update) {
      if (update) {
        self.validations = {
          ...self.validations,
          ...update
        };
      }
      else {
        self.validations = {};
        EmployerEditController.setState({
          validatingConnection: true,
          atsSourceNameWarning: null,
          atsUserNameWarning: null,
          atsUserIdWarning: null,
          atsUserName: null,
          atsUserEmail: null,
          atsUserDisabled: null,
        },
          state => onChangeAtsProviderInput('sourceIdAgencyId')()
        );
      }
    }

    async function onClickValidateConnectionButton(event) {

      /**
       * Starting
       * - Set loading flag.
       * - Clean warning text.
       * - Clean state values to get from GH.
       */
      updateValidations();

      /**
       * Make calls
       */
      let atsUser;
      if (!!onBehalfOfEmployerUserName.trim()) {
        atsUser = await getGreenhouseUserByName();
      }

      const atsUserId = onBehalfOfEmployerUserId || atsUser?.id;
      if (!!atsUserId) {
        await getGreenhouseUserById({ atsUserId });
      }

      await getGreenhouseSourceId();

      /**
       * Done
       */
      EmployerEditController.setState({
        validatingConnection: false
      });

    }

    /** µ FOR DEBUG PURPOSES * /
    console.debug('µ:Employer:Process:render', { state });
    console.debug('µ:Employer:Process:render', { atsApiProvider });
    /**  */

    return (
      <List className="EmployerForm Process">

        <ListItem
          className="form-header"
          primaryText="PROCESS"
          disabled={true}
        />

        <Row>

          <Col>

            <Chips
              name="employerSubmissionMethod"
              label={<label>Submission Method(s)</label>}
              sub="Please select all the submission methods your are interested in."
              values={submissionMethods}
              onChange={submissionMethods => {
                EmployerEditController.setState({ submissionMethods });
              }}
            />

          </Col>

          <Col>

            <label>Who will primarily schedule interviews?</label>
            <SelectField
              name="scheduling"
              underlineFocusStyle={lineStyle}
              value={EmployerEditController.state.scheduling}
              onChange={(ev, i, scheduling) => EmployerEditController.setState({ scheduling })}
              fullWidth
            >
              {["Company", "10by10"].map((scheduling) => (
                <MenuItem
                  key={scheduling}
                  value={scheduling}
                  primaryText={scheduling}
                />
              ))}
            </SelectField>

          </Col>

        </Row>

        {isAtsMethodIncluded && (

          <div className="bg-a-05 pb-2">

            <Row>

              <Col>
                <label>What ATS is the company using?</label>
                <small className="c-gray">Choose one from the dropdown</small>
                <SelectField
                  name="atsTypeId"
                  underlineFocusStyle={lineStyle}
                  value={atsTypeId}
                  onChange={onChangeSelectAtsType}
                  fullWidth
                >
                  {atsTypes.map((tag) => (
                    <MenuItem key={tag.id} value={tag.id} primaryText={tag.label} />
                  ))}
                </SelectField>
              </Col>

              {isAtsSelected && (
                <Col>
                  <label>ATS URL</label>
                  <small>
                    The URL to open for submission to ATS in case the Auth is not available.
                  </small>
                  {/** µ TO REVIEW 2021-04-29 */}
                  {!manualSubmissionUrl && !!atsUrl && (
                    <small className="c-red d-block">
                      This value is coming from deprecated field,
                      please edit it then save to being use it,
                      for selected ATS provider!
                    </small>
                  )}
                  {/** */}
                  <TextField
                    name="_atsUrl"
                    underlineFocusStyle={lineStyle}
                    /**

                      NOTES
                      ---

                      atsUrl
                      - It is the old singleton field.

                      manualSubmissionUrl
                      - It is the new nested field
                        for submission providers
                        object.

                      > Review it in future
                      for cleanup.

                      2021-04-07 µ

                    */
                    value={manualSubmissionUrl || atsUrl}
                    onChange={onChangeAtsProviderInput('manualSubmissionUrl')}
                    style={{ marginBottom: '0.75rem' }}
                    fullWidth
                  />
                </Col>
              )}

            </Row>

            <Row>

              {(isAtsGhHarvestSelected || isAtsGhIngestionSelected) && (
                <Col>
                  <label>API key</label>
                  <small className="c-gray">Authentication credential to ATS.</small>
                  <TextField
                    name="_atsApiKey"
                    underlineFocusStyle={lineStyle}
                    value={apiKey}
                    onChange={onChangeAtsProviderInput('apiKey')}
                    fullWidth
                  />
                </Col>
              )}

              {isAtsGhIngestionSelected && (
                <Col>
                  <label>On-Behalf-Of</label>
                  <small className="c-gray">User name issuing this request.</small>
                  <TextField
                    name="_atsOnBehalfOf"
                    underlineFocusStyle={lineStyle}
                    value={onBehalfOfEmployerUser}
                    onChange={onChangeAtsProviderInput('onBehalfOfEmployerUser')}
                    fullWidth
                  />
                </Col>
              )}

            </Row>

            {isAtsGhHarvestSelected && (
              <Fragment>

                <Row>

                  <Col>
                    <label>On-Behalf-Of Name</label>
                    <small className="c-gray">
                      Name of the user issuing this request.
                      {atsUserEmail && (<>&nbsp;<b>({atsUserEmail})</b></>)}
                    </small>
                    <small className="c-red d-block">
                      {atsUserNameWarning}
                    </small>
                    <TextField
                      name="_atsOnBehalfOfName"
                      underlineFocusStyle={lineStyle}
                      value={onBehalfOfEmployerUserName}
                      onChange={onChangeAtsProviderInput('onBehalfOfEmployerUserName')}
                      fullWidth
                    />
                  </Col>

                  <Col>
                    <label>On-Behalf-Of ID</label>
                    <small className="c-gray">
                      ID of the user issuing this request.
                    </small>
                    <small className="c-red d-block">
                      {atsUserIdWarning}
                    </small>
                    <TextField
                      name="_atsOnBehalfOfId"
                      underlineFocusStyle={lineStyle}
                      value={onBehalfOfEmployerUserId}
                      type="number"
                      onChange={onChangeAtsProviderInput('onBehalfOfEmployerUserId')}
                      fullWidth
                    />
                  </Col>

                </Row>

                <Row>

                  <Col>
                    <label>Agency Name</label>
                    <small>Agency name employer gave 10x10.</small>
                    <small className="c-red d-block">
                      {atsSourceNameWarning}
                    </small>
                    <TextField
                      name="_atsAgencyName"
                      underlineFocusStyle={lineStyle}
                      value={agencyName}
                      onChange={onChangeAtsProviderInput('agencyName')}
                      fullWidth
                    />
                  </Col>

                  {sourceIdAgencyId && (
                    <Col>
                      <label>ATS Source ID</label>
                      <small>The ID of the source to be credited for application creation</small>
                      <TextField
                        name="_atsSourceId"
                        type="number"
                        underlineFocusStyle={lineStyle}
                        value={sourceIdAgencyId}
                        onChange={onChangeAtsProviderInput('sourceIdAgencyId')}
                        fullWidth
                      />
                    </Col>
                  )}

                </Row>

                <Row>

                  <Col>
                    <label>Agency Recruiter Name</label>
                    <small className="c-gray">Recruiter name employer gave 10x10.</small>
                    <TextField
                      name="_atsAgencyRecruiterName"
                      underlineFocusStyle={lineStyle}
                      value={agencyRecruiterName}
                      onChange={onChangeAtsProviderInput('agencyRecruiterName')}
                      fullWidth
                    />
                  </Col>

                  <Col>
                  </Col>

                </Row>

                <Row>

                  <Col>
                    <FlatButton
                      label={
                        validatingConnection
                          ? "VALIDATING..."
                          : "VALIDATE CONNECTION"
                      }
                      title={isAtsValidateConnectionDisabled
                        ? 'Disabled'
                        : null
                      }
                      className="btn-cyan-white"
                      onClick={onClickValidateConnectionButton}
                      disabled={isAtsValidateConnectionDisabled}
                    />
                  </Col>

                  {isAtsUserInfoAvailable && (
                    <Col>
                      <label>User</label>
                      <small>
                        <b>Name: </b>{atsUserName}<br />
                        <b>Email: </b>{atsUserEmail}
                      </small>
                      {atsUserDisabled && (
                        <small>
                          &nbsp;|&nbsp;
                          <b className="c-red to-upper-case">Disabled</b>
                        </small>
                      )}
                    </Col>
                  )}

                </Row>

              </Fragment>
            )}


          </div>

        )}

        <Row>
          <Col fullWidth>
            <label>
              10x10 Internal Submission Instructions <br />
              <span style={{ color: "red", fontSize: "8px" }}>
                (Changes will not be reflected in existing jobs, only future new
                jobs)
              </span>
            </label>
            <RichTextBox
              name="internalSubmissionInstructions"
              value={EmployerEditController.state.internalSubmissionInstructions}
              onChange={internalSubmissionInstructions => {
                EmployerEditController.setState({
                  internalSubmissionInstructions,
                });
              }}
            />
          </Col>
        </Row>
        <QuestionsMustHave
          existing={EmployerEditController.state.mustHaveQuestions}
          parentUpdater={(array) => {
            EmployerEditController.setState({ mustHaveQuestions: array });
          }}
        />

        <Row>
          <Col fullWidth>
            <label>Interview Process</label>
            <RichTextBox
              name="interviewProcess"
              value={EmployerEditController.state.interviewProcess}
              onChange={interviewProcess =>
                EmployerEditController.setState({ interviewProcess })
              }
            />
          </Col>
        </Row>
        {(Core.getUserRole() === "SysAdmin" ||
          Core.getUserRole() === "TrustedRecruiter") && (
            <Row>
              <Col fullWidth>
                <label>
                  Hiring Priority&nbsp;
                  <b>
                    <small>(Visible to Limited Recruiters)</small>
                  </b>
                </label>
                <RichTextBox
                  name="additionalInfo"
                  value={EmployerEditController.state.additionalInfo}
                  onChange={additionalInfo =>
                    EmployerEditController.setState({ additionalInfo })
                  }
                />
              </Col>
            </Row>
          )}
        <Row>
          <Col>
            <label>How did you hear of us?</label>
            <AutoComplete
              name="reference"
              underlineFocusStyle={lineStyle}
              dataSource={["Social Media", "News", "Ads", "Other"]}
              searchText={EmployerEditController.state.reference}
              onUpdateInput={(reference) => EmployerEditController.setState({ reference })}
              onNewRequest={(ev) => EmployerEditController.setState({ searchText: "" })}
              rowsMax={5}
              multiLine={true}
              fullWidth
            />
          </Col>
          <Col>
            <label>
              Other Recruiting agencies / services that you are using?
            </label>
            <TextField
              name="recruitingAgencies"
              underlineFocusStyle={lineStyle}
              value={EmployerEditController.state.recruitingAgencies}
              onChange={(ev, recruitingAgencies) =>
                EmployerEditController.setState({ recruitingAgencies })
              }
              rowsMax={5}
              multiLine={true}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Expected Resume Review Response Time</label>
            <span className={"material-icons text-icon"}>access_time</span>
            <TextField
              name="expectedResumeResponseTime"
              underlineFocusStyle={lineStyle}
              style={{ width: "calc(100% - 30px)" }}
              type="number"
              value={EmployerEditController.state.expectedResumeResponseTime}
              onChange={(ev, expectedResumeResponseTime) =>
                EmployerEditController.setState({
                  expectedResumeResponseTime,
                })
              }
              onFocus={(ev) => ev.target.select()}
              fullWidth
            />
            hrs
          </Col>
          <Col>
            <label>Expected Scheduling Response Time</label>
            <span className={"material-icons text-icon"}>access_time</span>
            <TextField
              name="expectedSchedulingResponseTime"
              underlineFocusStyle={lineStyle}
              type="number"
              value={EmployerEditController.state.expectedSchedulingResponseTime}
              onChange={(ev, expectedSchedulingResponseTime) =>
                EmployerEditController.setState({
                  expectedSchedulingResponseTime,
                })
              }
              onFocus={(ev) => ev.target.select()}
              style={{ width: "calc(100% - 30px)" }}
              fullWidth
            />
            hrs
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Expected Screen Feedback Response Time</label>
            <span className={"material-icons text-icon"}>access_time</span>
            <TextField
              name="expectedScreenResponseTime"
              underlineFocusStyle={lineStyle}
              type="number"
              value={EmployerEditController.state.expectedScreenResponseTime}
              onChange={(ev, expectedScreenResponseTime) =>
                EmployerEditController.setState({
                  expectedScreenResponseTime,
                })
              }
              onFocus={(ev) => ev.target.select()}
              style={{ width: "calc(100% - 30px)" }}
              fullWidth
            />
            hrs
          </Col>
          <Col>
            <label>Expected Onsite Feedback Response Time</label>
            <span className={"material-icons text-icon"}>access_time</span>
            <TextField
              name="expectedOnsiteResponseTime"
              underlineFocusStyle={lineStyle}
              type="number"
              value={EmployerEditController.state.expectedOnsiteResponseTime}
              onChange={(ev, expectedOnsiteResponseTime) =>
                EmployerEditController.setState({
                  expectedOnsiteResponseTime,
                })
              }
              onFocus={(ev) => ev.target.select()}
              style={{ width: "calc(100% - 30px)" }}
              fullWidth
            />
            hrs
          </Col>
        </Row>
        <div
          className="green-block"
          style={{ backgroundColor: colors.greenBlock }}
        >
          <Row>
            <Col fullWidth>
              <h4>For 10 by 10 use only</h4>
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <label>
                Additional recruiting services that employer did not mention
              </label>
              <TextField
                name="additionalRecruitingAgencies"
                underlineFocusStyle={lineStyle}
                value={EmployerEditController.state.additionalRecruitingAgencies}
                onChange={(ev, additionalRecruitingAgencies) =>
                  EmployerEditController.setState({
                    additionalRecruitingAgencies,
                  })
                }
                fullWidth
              />
            </Col>
          </Row>

          {/** related to story-3258 */}
          <Row>
            <Col fullWidth>
              <label>{t('employer.process.location.label')}</label>
              <TreeSelectDropdown
                data={locationTags}
                layers={[]}
                value={EmployerEditController.state.location}
                onChange={({ value: location }) => {
                  EmployerEditController.setState({ location });
                }}
                multiple={false}
                placeholder={t('employer.process.location.placeholder')}
                className="my-1"
              />
            </Col>
          </Row>
          {/** */}

          <Row>
            <Col>
              <label>Current State</label>
              <RadioButtonGroup
                name="rstate"
                style={{ width: "100%" }}
                valueSelected={EmployerEditController.state.state}
                onChange={(event, state) => {
                  EmployerEditController.setState({ state });
                }}
                fullWidth
              >
                {Definition.get("state")
                  .filter((tag) =>
                    Core.isAdminOrCoordinator()
                      ? true
                      : Definition.test("state", tag.id, /draft/)
                  )
                  .map((tag) => (
                    <RadioButton
                      key={tag.id}
                      value={tag.id}
                      label={tag.label}
                      style={{ width: "15%", margin: "16px 0" }}
                      labelStyle={{ marginLeft: -10 }}
                    />
                  ))}
              </RadioButtonGroup>
            </Col>

            {/**
             * @todo
             * TO CLEANUP
             * related to story-3258
             * 2021-06-24
             * µ
             */}
            {/** * /}
            <Col>
              <label>Location</label>
              <br />
              <RadioButtonGroup
                name="location"
                style={{ width: "100%" }}
                valueSelected={EmployerEditController.state.location}
                onChange={(event, location) => {
                  EmployerEditController.setState({ location });
                }}
                fullWidth
              >
                {Definition.get("location").map((tag) => (
                  <RadioButton
                    key={tag.id}
                    value={tag.id}
                    label={tag.label}
                    style={{ marginBottom: 16 }}
                  />
                ))}
              </RadioButtonGroup>
            </Col>
            {/** */}

            <Col>
              <label>Hiring Difficulty</label>
              <br />
              <RadioButtonGroup
                name="employerHiringDifficulty"
                style={{ width: "100%" }}
                valueSelected={EmployerEditController.state.employerHiringDifficulty}
                onChange={(ev, employerHiringDifficulty) =>
                  EmployerEditController.setState({ employerHiringDifficulty })
                }
                className="inline-blocks"
                fullWidth
              >
                {Definition.get("employerHiringDifficulty").map((tag) => (
                  <RadioButton
                    key={tag.id}
                    value={tag.id}
                    label={tag.label}
                    style={{ marginBottom: 16 }}
                  />
                ))}
              </RadioButtonGroup>
            </Col>
            <Col>
              <label>Flags</label>
              <br />
              {Definition.get("flags").map((tag) => (
                <Checkbox
                  key={tag.id}
                  label={tag.label}
                  checked={!!~EmployerEditController.state.flags.indexOf(tag.id)}
                  onCheck={(ev, checked) => {
                    EmployerEditController.setState((state) => {
                      state = { flags: EmployerEditController.state.flags };
                      if (checked) {
                        if (!state.flags.find((id) => id === tag.id)) {
                          state.flags.push(tag.id);
                        }
                      } else {
                        state.flags = state.flags.filter((id) => id !== tag.id);
                      }
                      Core.log({ state });
                      return state;
                    });
                  }}
                />
              ))}
              <hr />
              <Checkbox
                label="Confidential"
                checked={EmployerEditController.state.confidential}
                onCheck={(ev, confidential) =>
                  EmployerEditController.setState({ confidential })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Placement Fee</label>
              <TextField
                name="placementFee"
                underlineFocusStyle={lineStyle}
                type="number"
                max={100}
                maxLength={5}
                value={EmployerEditController.state.placementFee}
                onChange={(ev, placementFee) =>
                  EmployerEditController.setState({
                    placementFee: Number(
                      placementFee > 100
                        ? 100
                        : String(placementFee).slice(0, 5)
                    ),
                  })
                }
              />
              <b className="text-icon">%</b>
            </Col>
            <Col>
              <label>Guarantee</label>
              <TextField
                name="guarantee"
                underlineFocusStyle={lineStyle}
                type="number"
                maxLength={3}
                value={EmployerEditController.state.guarantee}
                onChange={(ev, guarantee) =>
                  EmployerEditController.setState({
                    guarantee:
                      guarantee > 999
                        ? 999
                        : String(Math.round(guarantee)).slice(0, 3),
                  })
                }
              />
              <b className="text-icon">days</b>
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <label>Additional Contract Information</label>
              <TextField
                name="additionalContractInfo"
                underlineFocusStyle={lineStyle}
                type="text"
                rows={1}
                rowsMax={3}
                multiLine={true}
                value={EmployerEditController.state.additionalContractInfo}
                onChange={(ev, additionalContractInfo) =>
                  EmployerEditController.setState({
                    additionalContractInfo,
                  })
                }
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <label>
                Public Notes&nbsp;
                <b>
                  <small>(Visible to Limited Recruiters)</small>
                </b>
              </label>
              <TextField
                name="publicNotes"
                textareaStyle={styles.TextField.inputStyle}
                underlineStyle={styles.TextField.underlineStyle}
                style={styles.TextField}
                rows={5}
                rowsMax={10}
                multiLine={true}
                value={EmployerEditController.state.publicNotes}
                onChange={(ev, publicNotes) => EmployerEditController.setState({ publicNotes })}
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <label>Internal Privacy Notes not visible to recruiters</label>
              <TextField
                name="internalNotes"
                textareaStyle={styles.TextField.inputStyle}
                underlineStyle={styles.TextField.underlineStyle}
                style={styles.TextField}
                rows={5}
                rowsMax={10}
                multiLine={true}
                value={EmployerEditController.state.internalNotes}
                onChange={(ev, internalNotes) =>
                  EmployerEditController.setState({ internalNotes })
                }
                fullWidth
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <label>Creation Date</label>
            <DatePicker
              name="createdAt"
              underlineFocusStyle={lineStyle}
              value={
                EmployerEditController.state.createdAt ? new Date(EmployerEditController.state.createdAt) : null
              }
              onChange={(ev, createdAt) => {
                EmployerEditController.setState({ createdAt: createdAt.toISOString() });
              }}
              fullWidth
            />
          </Col>
          <Col>
            <label>Last Update</label>
            <DatePicker
              name="updatedAt"
              underlineFocusStyle={lineStyle}
              value={
                EmployerEditController.state.updatedAt ? new Date(EmployerEditController.state.updatedAt) : null
              }
              onChange={(ev, updatedAt) => {
                EmployerEditController.setState({ updatedAt: updatedAt.toISOString() });
              }}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Closed Date</label>
            <DatePicker
              name="closeDate"
              underlineFocusStyle={lineStyle}
              value={
                EmployerEditController.state.closeDate ? new Date(EmployerEditController.state.closeDate) : null
              }
              onChange={(ev, closeDate) => {
                EmployerEditController.setState({ closeDate: closeDate.toISOString() });
              }}
              fullWidth
            />
          </Col>
        </Row>
      </List>
    );
  }
}

/* EXPORTS ==================================== */

export default withTranslation()(Process);

/* ============================================ */
