/** ============================================ µ
 * @description MatchExclusionFieldset [JSX]
 *              UI Component
 * @route       /account/edit/:accountId
 * @createdAt   2021-08-30 Mon
 * @updatedAt   2021-08-31 Tue
 * story-3869
 * ============================================ */
/* IMPORTS ==================================== */

import { DatePicker, Input } from 'antd';
import { Checkbox } from "material-ui";
import moment from 'moment';
import React from "react";
import { useTranslation } from "react-i18next";
import Core from "../../../lib/Core";
import Definition, { MATCH_EXCLUSION__10X10_PLACEMENT, MATCH_EXCLUSION__DO_NOT_MATCH, MATCH_EXCLUSION__NEW_JOB_ANOTHER_SOURCE, MATCH_EXCLUSION__OTHER, MATCH_EXCLUSION__PAUSE, MATCH_EXCLUSION__RECRUITER_OWN_PLACEMENT, MATCH_EXCLUSION__STAY_AT_JOB } from "../../../lib/Definition";
import { NOT, YES } from '../../../lib/GenericTools.lib';
import { getCandidateModel } from "../../../lib/models/candidate";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";

/* CONSTANTS ================================== */

const { TextArea } = Input;
const dateFormat = 'YYYY-MM-DD';

/* METHODS ==================================== */

function MatchExclusionFieldset(props) {
  const { CandidateEditController } = props;
  const { state: candidate = {} } = CandidateEditController;
  const { matchExclusions = [] } = candidate;
  const _matchExclusions = matchExclusions[0] || getCandidateModel({ matchExclusion: true });
  const {
    reasons = [],
    excludeUntil = '',
    note = '',
    // updatedBy = ''
  } = _matchExclusions;
  const { t } = useTranslation();
  const matchExclusionReasonTags = Definition.get('matchExclusionReason');
  function setState(update) {
    CandidateEditController.setState({
      matchExclusions: [{
        ..._matchExclusions,
        ...update,
        updatedBy: Core.getUserId(),
        updatedAt: new Date().toISOString()
      }]
    });
  }
  // console.debug('candidate', candidate);
  return (
    Core.isAdmin() && YES(candidate.id)
      ? (
        <div className="bg-a-05 pb-2 rounded mx-1"  >
          <Row>
            <Col>
              <label className="mb-2">{t('candidate.matchExclusions.label')}</label>
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <h3 className="c-gray">{t('candidate.matchExclusions.reasons.title')}</h3>
              <div className="d-flex flex-wrap my-2">
                {matchExclusionReasonTags.map((item) => (
                  <Checkbox
                    key={item.id}
                    label={item.label}
                    checked={reasons.includes(item.id)}
                    style={{ minWidth: 240, width: `calc(100% / ${matchExclusionReasonTags.length})` }}
                    onCheck={(ev, checked) => {
                      let _reasons = (
                        !!checked
                          ? [...reasons, item.id]
                          : reasons.filter((n) => n !== item.id)
                      );
                      let _excludeUntil = '';
                      [
                        { tagId: MATCH_EXCLUSION__DO_NOT_MATCH, wakeup: '5 years' },
                        { tagId: MATCH_EXCLUSION__10X10_PLACEMENT, wakeup: '2 years' },
                        { tagId: MATCH_EXCLUSION__RECRUITER_OWN_PLACEMENT, wakeup: '1 year' },
                        { tagId: MATCH_EXCLUSION__NEW_JOB_ANOTHER_SOURCE, wakeup: '10 months' },
                        { tagId: MATCH_EXCLUSION__STAY_AT_JOB, wakeup: '1 month' },
                        { tagId: MATCH_EXCLUSION__PAUSE, wakeup: '1 month' },
                        { tagId: MATCH_EXCLUSION__OTHER, wakeup: '1 month' }
                      ].find(({ tagId, wakeup }) => {
                        if (_reasons.includes(tagId)) {
                          let [amount, unit] = wakeup.split(' ');
                          if (NOT(_reasons.length)) {
                            _excludeUntil = '';
                          }
                          else {
                            _excludeUntil = moment().add(amount, unit).toISOString();
                          }
                          return true;
                        }
                        return false;
                      });
                      setState({
                        reasons: _reasons,
                        excludeUntil: _excludeUntil,
                      });
                    }}
                  />
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="pb-2">
              <h3 className="c-gray">{t('candidate.matchExclusions.excludeUntil.title')}</h3>
              <div className="d-flex align-center">
                <DatePicker
                  value={excludeUntil ? moment(excludeUntil, dateFormat) : null}
                  format={dateFormat}
                  onChange={excludeUntil =>
                    setState({
                      excludeUntil: excludeUntil ? excludeUntil.toISOString() : '',
                    })
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <h3 className="c-gray">{t('candidate.matchExclusions.note.title')}</h3>
              <TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                placeholder="Type here"
                value={note}
                style={{ fontSize: "13px" }}
                onChange={event =>
                  setState({
                    note: event.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </div >
      ) : ''
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export {
  MatchExclusionFieldset as default,
  MatchExclusionFieldset
};

/* ============================================ */