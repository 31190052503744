import React, {Component, Fragment} from "react";

import {TextField, FlatButton} from "material-ui";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CustomAutocomplete from "./CustomAutocomplete";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Definition from "../../lib/Definition";

class AlternativePopupTag extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      newAlternativeName: '',
      alternativeNames: [],
      selected: this.props.selected, // {value:'id', label: 'name'}
      newName: this.props.newName,
      tags: Definition.get(this.props.tagManagementKey),
      tagsRaw: Definition.getRawDef(this.props.tagManagementKey),

    };
  }

  onCancel = () => {
    const {onCancel} = this.props;
    !!onCancel && onCancel();
  };

  load = (keyword, callback) => {
    const {dropdownFormatter, dropdownDataLoader} = this.props;

    dropdownDataLoader(keyword, companies => {
      const formattedCompanies = companies.map(dropdownFormatter);
      console.log({formattedCompanies});
      callback(formattedCompanies);

      const selected = Object(companies.find(comp => comp.id === Object(this.state.selected).value));

      this.setState({
        allCompanies: companies,
        alternativeNames: selected.alternativeNames || [],
      })
    });
  };

  optsDropdown = (initialValue) => (inputValue, callback) => {
    if (!inputValue) {
      inputValue = initialValue;
    }
    this.loadCompanies(inputValue, callback);
  };

  handlerChange = (selected) => {
    const {intent, newName} = this.props;

    const allTags = this.state.tags;
    const thisTag = selected ? Object(allTags.find(tag => tag.id === selected.value)) : {};
    let alternativeNames = thisTag.alternativeNames || [];

    if (intent === 'addAlternativeName') {
      alternativeNames = [newName, ...alternativeNames]
    }
    this.setState({selected, alternativeNames});
  };

  handleDeleteAlternative = (name) => () => {
    let {alternativeNames} = this.state;
    alternativeNames = alternativeNames.filter(aname => aname !== name);
    this.setState({alternativeNames});
  };

  onApply = () => {
    const {intent, onApply} = this.props;
    const {alternativeNames, selected, newName, tagsRaw} = this.state;
    onApply(intent, {alternativeNames, selected, newName, tagsRaw});
  };

  renderDropdown = ({label, defaultMenuIsOpen, height} = {label: '', defaultMenuIsOpen: false, height: 60}) => {

    const {selected, tags} = this.state;

    return <div style={{height, marginBottom: 25}}>
      <Typography color={'primary'} variant="button" display="block" gutterBottom style={{marginBottom: 15}}>
        {label}
      </Typography>
      <CustomAutocomplete selected={selected}
                          defaultOptions={tags}
                          defaultMenuIsOpen={defaultMenuIsOpen}
                          handleChange={this.handlerChange}
                          placeholder={"write to search"}
                          opts={this.promiseOptions}/>
    </div>
  };

  filterTags = (inputValue) => {
    const {tags} = this.state;

    return tags.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  promiseOptions = inputValue => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.filterTags(inputValue));
      }, 1000);
    });
  };

  renderTextField = () => {
    let lineStyle = '';
    const {newName} = this.state;

    return <div style={{marginBottom: 10}}>
      <Typography color={'primary'} variant="button" display="block" gutterBottom>
        Tag Name:
      </Typography>
      <TextField
        key={'newTag'}
        name="field"
        underlineFocusStyle={lineStyle}
        rows={1}
        rowsMax={1}
        value={newName}
        placeholder="Tag Name"
        onChange={(ev, field) => {
          this.setState({newName: field});
        }}
        fullWidth
      />
    </div>
  };

  renderAlternativeNames = () => {
    const {alternativeNames, newAlternativeName} = this.state;
    let lineStyle = '';

    return <Fragment>
      <Typography color="primary" variant="button" display="block" gutterBottom>
        Alternative Names List:
      </Typography>

      <List style={{margin: 0, padding: 0}}>
        <ListItem style={{margin: 0, padding: 0}}>
          <TextField
            key={'newAlternativeName'}
            name="field"
            underlineFocusStyle={lineStyle}
            rows={1}
            rowsMax={1}
            value={newAlternativeName}
            placeholder="Write here for a new Alternative Name and press Enter..."
            onChange={(ev, field) => {
              this.setState({newAlternativeName: field});
            }}
            onBlur={() => {
            }}
            fullWidth
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.setState({
                  alternativeNames: [newAlternativeName, ...alternativeNames],
                  newAlternativeName: ''
                });
              }
            }}
          />
        </ListItem>

        {alternativeNames.map(aname => {
          return <ListItem>
            <ListItemText
              primary={aname}
            />

            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete">
                <DeleteIcon onClick={this.handleDeleteAlternative(aname)}/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        })}
      </List>
    </Fragment>;
  };

  render() {
    const {intent, newName, source} = this.props;
    let content = '';

    switch (intent) {
      case 'add':
        content = <Fragment>
          {this.renderTextField()}
          {this.renderAlternativeNames()}
        </Fragment>;
        break;
      case 'addAlternativeName':
        content = <Fragment>
          {this.renderDropdown({
              label: <span>Please pick the {source} for which you want alternative name: <mark>{newName}</mark></span>,
              defaultMenuIsOpen: true,
            }
          )}
          {this.renderAlternativeNames()}
        </Fragment>;
        break;
      case 'edit':
        content = <Fragment>
          {this.renderDropdown({
            label: 'Tag Name:',
          })}
          {this.renderAlternativeNames()}
        </Fragment>;
        break;
      default:
        content = "no case found"
    }

    return (
      <Dialog
        maxWidth='xl'
        open={true}
        width={'lg'}
        onClose={() => {
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent style={{width: 900}}>
          {content}
        </DialogContent>

        <DialogActions>
          <FlatButton
            label="Cancel"
            className="filter-popup-button uppercase"
            onClick={() => this.onCancel()}
          />
          <FlatButton
            label="Save"
            className="filter-popup-button uppercase"
            onClick={() => {
              this.onApply()
            }}
          />
        </DialogActions>
      </Dialog>
    );

  }
}

export default AlternativePopupTag;