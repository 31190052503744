import React, { Component } from "react";

import MagicDropzone from "react-magic-dropzone";
import "./DragAndDropZone.css";

class DragAndDropZone extends Component {
  render() {
    return (
      <MagicDropzone
        className={
          this.props.className ? `Dropzone ${this.props.className}` : "Dropzone"
        }
        accept={this.props.accept}
        title={this.props.accept}
        multiple={false}
        maxSize={this.props.maxSize || Infinity}
        disablePreview={true}
        style={{
          ...(this.props.fullWidth ? { width: "100%" } : {}),
          ...this.props.style
        }}
        value={this.props.value}
        onDropAccepted={(acceptedFiles, ev) => {
          if (this.props.onChange instanceof Function) {
            this.props.onChange(ev, acceptedFiles[0]);
          }
        }}
        onDropRejected={(rejectedFiles, ev) => {
          if (this.props.onChange instanceof Function) {
            this.props.onChange(ev, rejectedFiles[0], true);
          }
        }}
      >
        <div className="Dropzone-content">
          {this.props.placeholder || "Drag and Drop Zone"}
        </div>
      </MagicDropzone>
    );
  }
}

export default DragAndDropZone;
