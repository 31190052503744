import React, {Component} from "react";

import Chips from "../../../Forms/Chips";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Definition from "../../../../lib/Definition";

class Education extends Component {
    constructor() {
        super(...arguments);
        this.state = this.props.element;
        this.state.education = this.state.education || [];
        this.state.dialogOpen = this.props.isNewRecord;
    }

    handleClickOpen = () => {
        this.setState({dialogOpen: true});
    }

    handleClose = () => {
        this.setState({dialogOpen: false}, () => {
            if(!!this.state.education.length){
                this.props.onDone(this.getUpdatedStructure());
            }
        });
    }

    getUpdatedStructure = () => {
        let {
            id, education
        } = this.state;

        return {
            id,
            education
        };
    }

    render() {

        let {
            education, dialogOpen
        } = this.state;

        let {handlerDeleteItem} = this.props;
        education = education.map(val => val.id);

        return (
            <div>
                {!!education.length && <span>Main Education<span
                    style={{color: 'yellow'}}>{Definition.getLabels('undergraduateDegree', education).join(',')}</span></span>}

                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        this.handleClickOpen();
                    }}
                >
                    Edit&nbsp;|&nbsp;
                </Link>
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        handlerDeleteItem(this.getUpdatedStructure());
                    }}
                >
                    Delete
                </Link>

                <Dialog
                    open={dialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth="lg"
                    maxWidth="lg"
                >
                    <DialogTitle id="form-dialog-title">Technical options</DialogTitle>
                    <DialogContent>
                        <Chips
                            name="undergraduateDegree"
                            label="Education"
                            values={education}
                            onChange={comps => {
                                const education = comps.map(val => {
                                    return {id: val, label: Definition.getLabel('undergraduateDegree',val)}
                                });

                                this.setState({education});
                            }}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default Education;

