/** ============================================ µ
 * @description FilterBarMenu [JSX]
 *              UI Component
 * @paths       list-pages | match-pages
 * @createdAt   2021-07-08
 * @updatedAt   2021-07-08
 * @note        epic-3038-story-3389
 * ============================================ */
/* IMPORTS ==================================== */

import {
  FontIcon,
  MenuItem
} from "material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import TreeListMenu from "./Dialogs/TreeListMenu";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

/**
 *
 * @param {*} props
 * @returns
 */
function FilterBarMenu(props) {
  const {
    menu = {},
    FilterControlController = {},
  } = props;
  const {
    showLocationDropdown
  } = FilterControlController.state;
  const { t } = useTranslation();
  function _onClickMenuBar({ menu }) {
    return event => {
      if (menu.definitionKey === 'location') {
        showLocationDropdown
          ? FilterControlController.setState({ showLocationDropdown: false })
          : FilterControlController.setState({ showLocationDropdown: menu.key })
      }
      else {
        showLocationDropdown
          ? FilterControlController.setState({ showLocationDropdown: false }, state => {
            FilterControlController.SelectTag.open(menu, event.currentTarget || event.target);
          })
          : FilterControlController.SelectTag.open(menu, event.currentTarget || event.target)
      }
    }
  }
  return (
    /**
     * @todo
     *
     * Experimental
     * AC request show the menu all the time.
     * Even if there are not selectable options,
     * if approved behavior, just remove it,
     * else un-comment.
     *
     * 2021-07-09
     * µ
     *
     */
    /** * /
    !!Object.keys(menuItems).length &&
    /** */
    (
      <div className="blocks">
        <MenuItem
          key={`menu${menu.key}`}
          className="filter-control-item"
          primaryText={t(menu.label)}
          rightIcon={
            <FontIcon className="material-icons">
              arrow_drop_down
            </FontIcon>
          }
          onClick={_onClickMenuBar({ menu })}
        />
        {
          showLocationDropdown === menu.key
          && (
            <TreeListMenu
              {...{
                menu,
                FilterControlController,
              }}
            />
          )
        }
      </div>
    )
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export {
  FilterBarMenu as default,
  FilterBarMenu
};

/* ============================================ */
