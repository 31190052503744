import jobSourceLists from "../jobSourceList";
import Core from "../Core";

const getRecruiterDetailByJobIds = (searchKey, ids, cb) => {              // used by admin to show names
    jobSourceLists.getJobIds(
        {
            include: [
                {
                    relation: "account",
                    scope: { fields: ["firstName", "lastName"] },
                },
                {
                    relation: "job",
                    scope: { fields: ["jobTitle"] },
                }
            ],
            where: {
                [searchKey] : {inq:ids}
            },
            fields: ["jobId", "id", "accountId", "startDate", "endDate"]
        },
        (response => {
            cb(response);
        })
    );
}

const getAllJobsOfAdmin = (accId, cb) => {              // used by recruiter job listing
    jobSourceLists.getJobIds(
        {
            where: {
                and : [
                    {accountId:accId}
                ]
            },
            fields: ["jobId", "id", "startDate", "endDate"]
        },
        (response => {
            cb(response);
        })
    );
}

const getJobIdsByRecruiter = (cb) => {              // used by recruiter job listing
    jobSourceLists.getJobIds(
        {
            where: {
                and : [
                    {accountId:Core.getUserId()},
                    {endDate:{eq: null}}
                ]
            },
            fields: ["jobId", "id", "startDate"]
        },
        (response => {
            cb(response);
        })
    );
}

const getRemovedRecruiterJobIds = (accountId, cb) => {      // used by admin account edit
    jobSourceLists.getJobIds(
        {
            where: {
                and : [
                    {accountId:accountId},
                    {endDate:{neq: null}}
                ]
            },
            fields: ["jobId"]
        },
        (response => {
            cb(response);
        })
    );
}

const addJobToRecruiter = (jobId, accountId, data, cb) => {     // used by recruiter job listing
    jobSourceLists.upsertJob(
        jobId,
        accountId,
        data,
        (response => {
            cb(response);
        })
    );
}

// const deleteJobByRecruiter = (jobId, cb) => {
//     jobSourceLists.deleteJob(
//         jobId,
//         (response => {
//             cb(response);
//         })
//     );
// }

const deleteRecruiterJobByAdmin = (jobId, accountId, data, cb) => {
    // POST OR PATCH ENTRY
    jobSourceLists.postOrPatchJob(      // used by admin account edit
        jobId,
        accountId,
        data,
        (response => {
            cb(response);
        })
    );
}

const resumeRecruiterJobByAdmin = (jobId, accountId, data, cb) => {
    // POST OR PATCH ENTRY
    jobSourceLists.patchJobByResume(      // used by admin account edit
        jobId,
        accountId,
        data,
        (response => {
            cb(response);
        })
    );
}

const deleteRecruiterJobByRecruiter = (jobId, accountId, data, cb) => {
    // POST OR PATCH ENTRY
    jobSourceLists.postOrPatchJob(      // used by recruiter account edit
        jobId,
        accountId,
        data,
        (response => {
            cb(response);
        })
    );
}

const removeJobByAdmin = (jobId, accountId, data={}, cb) => {
    jobSourceLists.removeJob(      // used by recruiter account edit
        jobId,
        accountId,
        data,
        (response => {
            cb(response);
        })
    );
}

const toExport = {
    getAllJobsOfAdmin,
    getRecruiterDetailByJobIds,
    getJobIdsByRecruiter,
    getRemovedRecruiterJobIds,
    addJobToRecruiter,
    deleteRecruiterJobByAdmin,
    deleteRecruiterJobByRecruiter,
    resumeRecruiterJobByAdmin,
    removeJobByAdmin
}

export default toExport;