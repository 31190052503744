/** ============================================ µ
 * @description AccountDetails [JSX]
 *              UI Component
 * @createdAt   2021-10-01 Fri
 * @updatedAt   2021-10-01 Fri
 * ============================================ */
/* IMPORTS ==================================== */

import { Paper } from "@mui/material";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import Core from "../../../lib/Core";
import Engagement from "../../../lib/Engagement";
import { mapEngagements } from "../../../lib/models/engagement";
import Loader from "../../Shared/Loader";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function useEngagements(defaultValue) {
  const [account, setAccount] = React.useState({});
  const [engagements, setEngagements] = React.useState(defaultValue);
  const [loading, setLoading] = React.useState(false);
  async function fetchEngagements({ account }) {
    let candidates = account.candidates || [];
    let _engagements = [];
    setLoading(true);
    setEngagements(_engagements);
    await Promise.all(
      candidates.map(
        ({ id: candidateId }) =>
          Engagement
            .getWhere({ candidateId }, undefined, undefined, true)
            .then(mapEngagements)
            .then(response => (_engagements = [...(engagements || []), ...response]))
      )
    );
    setEngagements(_engagements);
    setAccount(account);
    setLoading(false);
  }
  return {
    account,
    engagements,
    fetchEngagements,
    loading,
  }
}

function AccountDetails(props) {

  let { account, model } = props;
  account = account || model;
  
  // console.debug('account', account);

  const modelIsAnAccount = (account.___model___ === 'Account');

  const { t } = useTranslation();
  const [accountId, setAccountId] = React.useState();
  const { engagements, account: _account, fetchEngagements, loading, } = useEngagements([]);

  React.useEffect(() => {
    if (modelIsAnAccount && (accountId !== account.id)) {
      setAccountId(account.id);
      fetchEngagements({ account });
    }
    return () => { }
  }, [fetchEngagements, account, accountId, modelIsAnAccount])

  const alternativeCmp = <div />;

  if (modelIsAnAccount) {
    const candidates = account.candidates;
    const totalCandidates = candidates.length;
    let _engagements = (_account.id === account.id) ? engagements : [];
    const totalEngagements = _engagements.length;
    const closedEngagements = _engagements.filter(
      eng => /closed/i.test(eng.state)
    ).length;
    const confirmationEngagements = _engagements.filter(
      eng => /open/i.test(eng.state) && /confirmation/i.test(eng.stage)
    ).length;
    const reviewEngagements = _engagements.filter(
      eng => /open/i.test(eng.state) && /review/i.test(eng.stage)
    ).length;
    const screenEngagements = _engagements.filter(
      eng => /open/i.test(eng.state) && /screen/i.test(eng.stage)
    ).length;
    const onsiteEngagements = _engagements.filter(
      eng => /open/i.test(eng.state) && /onsite/i.test(eng.stage)
    ).length;
    const guaranteeEngagements = _engagements.filter(
      eng => /open/i.test(eng.state) && /guarantee/i.test(eng.stage)
    ).length;
    const offerEngagements = _engagements.filter(
      eng => /open/i.test(eng.state) && /offer/i.test(eng.stage)
    ).length;
    const submissionEngagements = _engagements.filter(
      eng => /open/i.test(eng.state) && /submission/i.test(eng.stage)
    ).length;
    const submissions = candidates
      .map(cand =>
        cand.introduced
          ? moment(cand.introduced)
            .toDate()
            .getTime()
          : 0
      )
      .filter(time => !!time);
    const lastSubmissionTime = Math.max.apply(null, submissions);
    const lastSubmissionDate = moment(lastSubmissionTime).toISOString();
    const lastSubmission = submissions.length
      ? moment(lastSubmissionTime).format("MM/DD/YY")
      : 0;
    const componentEngagements = (
      <Paper className="cursor-default p-1 c-gray">
        <span title={t('Total of Candidates')}>{totalCandidates} candidates</span>
        &nbsp;|&nbsp;
        <span title="Total of Engagements">{totalEngagements} engagements</span>
        <br />
        {loading ? <Loader /> : (
          _engagements.length
            ? (
              <>
                <span title="Engagements Closed status">
                  Closed ({closedEngagements})
                </span>
                &nbsp;|&nbsp;
                <span title="Engagements Open and Review stage">
                  Review ({reviewEngagements})
                </span>
                &nbsp;|&nbsp;
                <span title="Engagements Open and Onsite stage">
                  On Site ({onsiteEngagements})
                </span>
                &nbsp;|&nbsp;
                <span title="Engagements Open and Offer stage">
                  Offer ({offerEngagements})
                </span>
                &nbsp;|&nbsp;
                <span title="Engagements Open and Guarantee stage">
                  Guarantee ({guaranteeEngagements})
                </span>
                &nbsp;|&nbsp;
                <span title="Engagements Open and Screen stage">
                  Screen ({screenEngagements})
                </span>
                &nbsp;|&nbsp;
                <span title="Engagements Open and Confirmation stage">
                  Confirmation ({confirmationEngagements})
                </span>
                &nbsp;|&nbsp;
                <span title="Engagements Open and Submission stage">
                  Submission ({submissionEngagements})
                </span>
  
                <br />
  
                <span title={`Last submission: ${lastSubmissionDate}`}>
                  {!!lastSubmission ? `Last submission date: ${lastSubmission}` : ""}
                </span>
  
              </>
            )
            : ''
        )}
  
      </Paper >
    );
    return (Core.isAdminOrCoordinator() && modelIsAnAccount) ? componentEngagements : alternativeCmp;
  }

  return alternativeCmp;

}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { AccountDetails as default, AccountDetails };

/* ============================================ */
