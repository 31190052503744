import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import moment from "moment";
import React from "react";
import { v4 as uuidv4 } from 'uuid';
import packageJson from "../../package.json";
import revisionJson from "../revision.json";
import colors from "../static/colors.json";
import Definition from "./Definition";
import { copyString } from "./GenericTools.lib";
import Http from "./Http";
import Store from "./Store";
import cleanHTML from "./tools/cleanHtml";

// const LOCALHOST = "http://localhost:3000";
// const DEVELOPMENT = "http://34.227.159.118:3000";
// const STAGING = "https://staging-api.go10x10.com";
// const PRODUCTION = "https://api.go10x10.com";

let key = 1;

// function getSelectedApi(input) {
//   switch (input) {
//     case "production":
//       return PRODUCTION;
//     case "development":
//       return DEVELOPMENT;
//     case "staging":
//       return STAGING;
//     case "local":
//       return LOCALHOST;
//     default:
//       throw new Error(
//         "You need to specify the type of API that will be used in your environment (MISSING: [REACT_APP_API_ENV])"
//       );
//   }
// }

// Select the API to be used throughout the application depending on NODE_ENV
// let localhost = getSelectedApi(process.env.REACT_APP_API_ENV);
// const development = localhost;
// const staging = localhost;
// const production = localhost;

// const api = {
//   localhost,
//   "127.0.0.1": localhost,
//   "34.227.159.118": development,
//   "d101010.go10x10.com": "http://d101010.go10x10.com:3000",
//   "staging.go10x10.com": staging,
//   "cfstaging.go10x10.com": staging,
//   "cf.go10x10.com": production,
//   "go10x10.com": production,
//   "www.go10x10.com": production,
//   "app.go10x10.com": production,
//   "beta.go10x10.com": staging,
//   "cfbeta.go10x10.com": staging,
//   "cfstaging.go10x10.com": staging,
//   "cf.go10x10.com": staging,
// };

function stringToBoolean(val) {
  var a = {
    'true': true,
    'false': false
  };
  return a[val];
}

const baseApiPath = process.env.REACT_APP_API_URL;
const basePath = process.env.REACT_APP_BASE_URL
const isLocalHost = stringToBoolean(process.env.REACT_APP_IS_LOCALHOST);
const isDevelopment = stringToBoolean(process.env.REACT_APP_IS_DEVELOPMENT);
const isProduction = stringToBoolean(process.env.REACT_APP_IS_PRODUCTION);
const isStaging = stringToBoolean(process.env.REACT_APP_IS_STAGING);
const Core = {
  getBasePath: () => basePath,
  /** STORAGE & ENVIRONMENT */
  getApi: path => {
    return !!path ? `${baseApiPath}/api/${path}` : `${baseApiPath}/api`;
  },
  getPath: path => {
    return `${basePath}/#/${path}`;
    // if (/go10x10/.test(document.location.hostname)) {
    //   return `https://go10x10.com/#/${path}`;
    // } else if (/localhost|127\.0\.0\.1/.test(document.location.hostname)) {
    //   return `http://${document.location.hostname}:3001/#/${path}`;
    // } else {
    //   return `http://${document.location.hostname}/#/${path}`;
    // }
  },
  getRedirectURI: end => {
    switch (end) {
      case "be":
        return baseApiPath + "/api/Credentials/auth";
      case "fe":
      default:
        return document.location.origin + "/#/settings";
    }
  },
  getUniqueId: num =>
    parseInt(
      num + "" + new Date().getTime() + "" + parseInt(Math.random() * 1000, 10),
      10
    ),
  getUserId: em => Core.getSession().userId,
  getAccessToken: em => Core.getSession().id,
  getUser: em => Object(Core.getSession().user),
  getSession: em => Object(Store.get("session")),
  getSessionEmail: em => Store.get("email"),
  getUserName: em => {
    const user = Core.getUser();
    return `${user.firstName || "Anonymous"} ${user.lastName || "User"}`;
  },
  getUserRole: em => Core.getSession().role,
  isLocalHost: em => isLocalHost,
  isDevelopment: em => isDevelopment,
  isProduction: em => isProduction,
  isStaging: em => isStaging,
  isOnDev: em => isDevelopment || isLocalHost,
  isAdmin: em => Core.getUserRole() === "SysAdmin",
  isCoordinator: em => Core.getUserRole() === "Coordinator",
  isAdminOrCoordinator: em => Core.isAdmin() || Core.isCoordinator(),
  isLimited: em => Core.getUserRole() === "LimitedRecruiter",
  isTrusted: em => Core.getUserRole() === "TrustedRecruiter",
  getUIVersion: em => parseInt(Store.get('ui-version')) || DEFAULT_UI_VERSION,
  setUIVersion: num => Store.set('ui-version', num),
  go({ history, to = '/jobs', refresh } = { to: '/jobs' }) {
    to = Core.getLink(to);
    if (history) {
      history.push(to);
    }
    else {
      console.debug('history', history);
      window.location.href = `/#${to}`;
    }
    refresh && window.location.reload();
  },
  goBack: ({ history } = {}) => {
    if(!!window.location.href.match(/candidate\/match/i)){
      window.location.href = '/#/candidates';
    }
    else if(!!window.location.href.match(/job\/match/i)){
      window.location.href = '/#/jobs';
    }
    else if (history) {
      history.length > 2 ? history.goBack() : history.push('/');
    }
    else {
      console.debug('history', history);
      window.location.href = '/#';
    }
  },
  open(to) {
    to = Core.getLink(to);
    window.open(`#${to}`);
  },
  openExact(to) {
    window.open(`#${to}`);
  },
  getLink(to) {
    to = to.replace('/v3', '');
    if ((Core.getUIVersion() === 3) && (to !== '/')) {
      to = `/v3${to}`;
    }
    return to;
  },
  isRecruiter: em => Core.isTrusted() || Core.isLimited(),
  isAdminOrTrusted: em => Core.isAdminOrCoordinator() || Core.isTrusted(),
  isAdminOnDev: em => Core.isAdmin() && Core.isOnDev(),
  isAdminOrRecruiterOnDev: em =>
    Core.isAdminOrCoordinator() || (Core.isRecruiter() && Core.isOnDev()),
  isNotAdminOrRecruiterOnDev: em => !Core.isAdminOrRecruiterOnDev(),
  isAdminOnLocal: em => Core.isAdmin() && Core.isLocalHost(),
  isRecruiterOnDev: em => Core.isRecruiter() && Core.isOnDev(),
  osWindows: em => /windows/i.test(window.navigator.userAgent),
  isDuc: em => /duc\+recruiter@10by10.io/i.test(Core.getSessionEmail()),
  isTeam: em => /recruiter@10by10.io/i.test(Core.getSessionEmail()),
  isLogged: em => !!Core.getAccessToken(),
  isLoggedOut: em => !Core.isLogged(),
  isPath: search => new RegExp(search, "i").test(window.location.hash),
  failure({
    title,
    message,
    exception,
    source,
    params,
    options,
    omitSentToSentry = false,
    omitUIMessage = false,
  }) {
    const ticketId = uuidv4();
    const timeStamp = moment().toISOString();
    const user = Core.getUser().email;
    const url = window.location.href;
    let ticket = (`
      ${source} | ${exception} | ${user} | ${url}\n${(
        JSON.stringify({
          ticketId,
          timeStamp,
          user,
          url,
          title,
          message,
          exception,
          source,
          params,
        }, null, 2)
      )}
    `).trim();
    console.debug('<');
    console.debug('FAILURE');
    console.debug(ticket);
    console.debug('omitSentToSentry', omitSentToSentry);
    console.debug('omitUIMessage', omitUIMessage);
    console.debug('>');
    let messageTitle = '';
    let messageText = '';
    let messageTicket = false;
    if (exception === 'Network Failure') {
      messageTitle = exception;
      messageText = 'Try in a few minutes, if the issue persists contact to the support team';
    }
    else {
      if (!omitSentToSentry) {
        Sentry.withScope(scope => {
          const ticketObject = {
            ticketId,
            timeStamp,
            user,
            url,
            title,
            message,
            exception,
            source,
            params,
          };
          Object.keys(ticketObject).forEach(
            key => {
              if (ticketObject[key] !== undefined) {
                let value = ticketObject[key];
                if (Object(value) === value) { value = JSON.stringify(value, null, 2).trim() }
                scope.setExtra(key, value);
              }
            });
          Sentry.captureMessage(`${timeStamp} | ${source} | ${exception} | ${user} | ${url} | ${ticketId}`, options || 'error');
        });
      }
      messageTitle = 'Sorry something went wrong';
      messageText = 'Send following code as text to the support team to track this issue';
      messageTicket = true;
    }
    if (!omitUIMessage) {
      Core.showMessage
        ? Core.showMessage(
          <>
            {title ? title : (
              <div className="text-capitalize">{messageTitle}</div>
            )}
            <div className="f-sm c-gray">
              {message ? message : (
                <>
                  <i>{messageText}</i><br />
                  {messageTicket && (
                    <span
                      className="text-mono pointer"
                      onClick={event => copyString(`${ticketId} | ${timeStamp}`)}
                    >
                      {ticketId}<br />{timeStamp}
                    </span>
                  )}
                </>
              )}
            </div>
          </>
        )
        : alert(`${messageTitle}\n\n${messageText}\n${messageTicket ? `${ticketId}\n${timeStamp}` : ''}`);
    }
  },
  getEnvironment: em =>
    `${Core.isProduction() ? "domain" : Core.isStaging() ? "staging" : Core.isDevelopment() ? "d101010" : "local"
    }${process.env.REACT_APP_ENV ? "/" + process.env.REACT_APP_ENV : ""}`,
  getFrontendVersion: () => {
    return `${packageJson.version}-${revisionJson.revision || 'local'}`;
  },
  getVersion: (success, failure) => {
    const env = Core.getEnvironment();
    const fullVersionRevision = Core.getFrontendVersion();
    Store.set("FE", fullVersionRevision);

    if (!Core.isLocalHost()) {
      Sentry.init({

        dsn: "https://3959265fb5424436a9706084cef96dc2@sentry.io/1229219",

        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        release: fullVersionRevision,
        integrations: [new Integrations.BrowserTracing()],

        environment: process.env.REACT_APP_ENV,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: Core.isProduction() ? 0.2 : 1.0,

      });
    }

    Http.get(
      Core.getApi("Accounts/version"),
      function onSuccess(apiVersion) {
        Store.set("BE", apiVersion);
        success(`${env} FE@${fullVersionRevision} BE@${apiVersion}`);
        info();
      },
      function onFailure(err) {
        failure(err, `${env} FE@${fullVersionRevision}`);
        info();
      }
    );
    function info() {
      console.info(
        JSON.stringify(
          {
            "server/environment": env,
            fe: Store.get("FE"),
            be: Store.get("BE"),
            session: Core.isOnDev() && Core.getSession(),
            isLocalhost: Core.isLocalHost(),
            isDevelopment: Core.isDevelopment(),
            isProduction: Core.isProduction(),
            isOnDev: Core.isOnDev(),
            isAdmin: Core.isAdmin(),
            isCoordinator: Core.isCoordinator(),
            isLimited: Core.isLimited(),
            isTrusted: Core.isTrusted()
          },
          null,
          2
        )
      );
    }
  },
  getEnvValue: key => process.env[`REACT_APP_${key}`] || `(${key})`,
  getEmailSignature: em =>
    cleanHTML(`
      <p>
        ${Core.getEnvValue("TEAM_NAME")}<br>
        <a href="tel:${Core.getEnvValue("TEAM_PHONE")}">
          ${Core.getEnvValue("TEAM_PHONE")}
        </a>&nbsp;|&nbsp;
        <a href="mailto:${Core.getEnvValue(
          "TEAM_EMAIL"
        )}">
          ${Core.getEnvValue("TEAM_EMAIL")}
        </a>&nbsp;|&nbsp;
        <a href="${Core.getEnvValue("TEAM_CALENDAR")}" target="_blank">
          calendar
        </a><br/>
        ${Core.getEnvValue("TEAM_SLOGAN")}
      </p>

    `),
  getResumeSubmissionFromEmail: em => {
    return process.env.REACT_APP_RESUME_SUBMISSION_FROM_EMAIL;
  },
  getResumeSubmissionBcc: em => {
    return process.env.REACT_APP_RESUME_SUBMISSION_BCC;
  },
  getNewCandidateReceiverNotiFromEmail: em => {
    return process.env.REACT_APP_NEW_CANDIDATE_RECEIVER_NOTI_FROM_EMAIL;
  },

  getDraftCandidateReceiverNotiToEmail: em => {
    return process.env.REACT_APP_DRAFT_CANDIDATE_RECEIVER_NOTI_TO_EMAIL;
  },
  getNewCandidateReceiverNotiToEmail: em => {
    return process.env.REACT_APP_NEW_CANDIDATE_RECEIVER_NOTI_TO_EMAIL;
  },
  getResumeS3Storage: em => {
    return process.env.REACT_APP_RESUME_S3_BUCKET;
  },
  getCalendarCoordinatorPhone: em => {
    return process.env.REACT_APP_CALENDAR_COORDINATOR_PHONE;
  },
  log: Function.prototype.bind.call(console.log, console),
  /** AUTH */
  logout: () => {
    Sentry.setUser();
    Store.remove("session");
    Store.remove("BE");
    Store.remove("FE");
    Store.remove("email");
    Store.remove("history");
  },
  login: (inputs, success, failure) => {
    Http.post(
      Core.getApi("Accounts/login?include=user"),
      inputs,
      function onSuccess(response) {
        if (response.id) {
          if (Definition.test("state", response.user.state, /inactive/)) {
            Core.showFailure("User is inactive");
          } else {
            Sentry.setUser({
              email: inputs.email,
              id: response.user.id
            });
            Store.set("email", inputs.email);
            Store.set("session", response);
            window["setFullStory"]();
            document.location.reload();
            const path = Store.get("path");
            if (!!path) {
              document.location.href = path;
            }
          }
        } else {
          Core.showFailure("Unexpected error");
        }
      },
      failure
    );
  },
  root: em => (window.location.href = "/"),

  // this is overwritten by the app controller
  reload: em => window.location.reload(),

  refresh: em => window.location.reload(),
  forgot: (inputs, success, failure) => {
    Http.post(Core.getApi("Accounts/reset"), inputs, success, failure);
  },
  resetPassword: (inputs, success, failure) => {
    Http.post(Core.getApi("Accounts/reset-password"), inputs, success, failure);
  },
  /** SHARED */
  getKey: () => key++,
  getFromNow: stringDate => {
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago"
      }
    });
    return moment(stringDate).fromNow();
  },
  openPopUp: url => {
    let newWindow = window.open(
      url,
      "newWindow",
      "width=720,height=960,top=0,left=" + (window.screen.width - 720)
    );
    newWindow.resizeTo(720, 960);
    newWindow.moveTo(window.screen.width - 720, 0);
  },
  openSameWindow: url => {
    window.open(url, "_self");
  },
  /** CUSTOM SNACKBAR start >>> */
  /** */
  showMessage(message, onClose) {/** interface */ return { open() { } } },
  hideMessage() {/** interface */ },
  /** <<< end */
  /** REMOTE STORAGE start block >>> */
  /** */
  uploadFile: (container, file, filename, success, failure) => {
    container = `${Core.getUserId()}_${container ||
      "new" + new Date().toISOString().replace(/-|:|\./g, "")}`;
    container = Core.getResumeS3Storage() + "%2F" + container;
    if (file instanceof File) {
      uploadFile(file, filename);
    } else {
      failure("Invalid file to upload");
    }
    function uploadFile(_file, _filename) {
      Core.log(_file);
      const data = new FormData();
      data.append("file", _file, _filename);
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          if (this.status === 200) {
            if (failure instanceof Function) {
              try {
                const result = JSON.parse(this.responseText).result.files
                  .file[0];

                const resumeUrl = `${Core.getApi(
                  "ImportContainer"
                )}/${container}/download/${result.name}`;

                Core.log(result);
                success(resumeUrl);
              } catch (ex) {
                failure("unexpected error");
                Core.failure({
                  source: 'Core.js(lib)>uploadFile:catch',
                  exception: ex,
                  params: {
                    container,
                    file,
                    filename,
                  }
                });
              }
            }
          } else {
            if (failure instanceof Function) {
              try {
                failure(JSON.parse(this.responseText).error.message);
              } catch (ex) {
                failure("unexpected error");
              }
              Core.failure({
                source: 'Core.js(lib)>uploadFile:!ok',
                exception: this.responseText,
                params: {
                  container,
                  file,
                  filename,
                }
              });
            }
          }
        }
      });
      xhr.open(
        "POST",
        `${Core.getApi("ImportContainer")}/${container}/upload/`
      );
      const token = Core.getAccessToken();
      if (token) {
        xhr.setRequestHeader("Authorization", token);
      }
      xhr.setRequestHeader("cache-control", "no-cache");
      xhr.send(data);
    }
  },
  deleteFile: (container, file, success) => {
    Http.delete(
      Core.getApi(`ImportContainer/${container}/files/${file}`),
      success
    );
  },
  renameS3Resume: (bucketname, oldkey, newkey, success, failure) => {
    Http.post(
      Core.getApi("Importcontainer/rename"),
      {
        bucketname,
        oldkey,
        newkey
      },
      success,
      failure
    );
  },
  /** <<< end block */
  /**
   * Core.setKeyValue
   *
   * Sets to memory a pair of (key:value) to be used on post-routines.
   *
   * This is util to pass values thru controllers without re-render
   * and/or to avoid miss values on re-render inside same controller
   *
   * @sample `Core.setKeyValue('my-key-name', <any>);`
   * @note ***Be sure your key is unique across all routine proceses***
   * @see Core.getKeyValue;
   */
  setKeyValue(key = '', value) {
    GLOBAL_KEY_VALUE_PAIRS[key] = value;
  },
  /**
   * Core.getKeyValue
   *
   * Returns value related to a key, previously set by Core.setKeyValue.
   *
   * @sample `let myVar = Core.getKeyValue('my-key-name');`
   * @see Core.setKeyValue;
   */
  getKeyValue(key = '') {
    return GLOBAL_KEY_VALUE_PAIRS[key];
  }
};
/**
 * Cache to be used by Core.setKeyValue & Core.getKeyValue
 *
 * @see Core.setKeyValue;
 * @see Core.getKeyValue;
 */
const GLOBAL_KEY_VALUE_PAIRS = {};

export const DEFAULT_UI_VERSION = 1;
export const LATEST_UI_VERSION = 3;

export { Core as default, colors };

