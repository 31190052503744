import { getNormalizedProfileExpression } from "./getNormalizedProfileExpression.tool";

/**
 * Gets the domain and the nickname from a profile url
 * then returns a regular expression string that can be used in a query to get a match
 *
 * @param {string} url
 * @returns {string}
 */
export function validProfileURL(url = '') {
  const expression = getNormalizedProfileExpression(url);
  const res = (
    !!expression &&
    !expression.includes('10x10NoMatch') &&
    !expression.includes('undefined')
  );
  return res;
}
