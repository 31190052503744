import React, {Component, Fragment} from "react";

import Chip from '@mui/material/Chip';
import FlatButton from "material-ui/FlatButton";

import Row from "../../Forms/Row";
import Col from "../../Forms/Col";
import InputArray from '../../Shared/InputArray'
import PrintSingleMustHave from "./PrintSingleMustHave";
import PopupAdElement from "./PopupAdElement";
import OperatorDropdown from "./Shared/OperatorDropdown";
import Job from "../../../lib/Job";
import Core from "../../../lib/Core";
import _ from "lodash";
import {YearsOfExperienceColor, IsYearsOfExperienceGreen} from "../../../lib/YearOfExperienceColor";

const Operators = [
    {name: 'and'},
    {name: 'or'}
];

class Main extends Component {

    renderAdditionalYearsOFExperience = () => {
        const {yearsOfExperience,selectedJob,candidate} = this.props;
        let color = YearsOfExperienceColor(selectedJob,candidate);
        let isGreen = IsYearsOfExperienceGreen(selectedJob,candidate)===1;
        let decisionMark = isGreen ? <span><i style={{fontSize: '20px'}} className="material-icons">done</i></span>:<span><i style={{fontSize: '20px'}} className="material-icons">cancel</i></span>

        if (!!yearsOfExperience) {
            return <p>{decisionMark} Experience : <Chip
                key={1}
                className="slim-chip"
                label={`${yearsOfExperience} yrs`}
                size="small"
                variant="outlined"
                style={{
                    border: "1px solid " + color
                }}
            />
            </p>;
        }
        return null;
    }

    getLabels = (item) => {
        let catTypes = [];

        if (item.category === "technicalSkills" || item.key === "technicalSkills")
            catTypes.push("technicalSkills");
        if (item.category === "workExperience" || item.key === "workExperience")
            catTypes.push("workExperience");
        if (item.category === "education" || item.key === "education")
            catTypes.push("education");
        if (item.category === "others" || item.key === "others")
            catTypes.push("others");

        return catTypes;
    }

    sortMustHaves = (item) => {
        let categoryTypes = this.getLabels(item);

        if (categoryTypes.includes('technicalSkills')) {
            return 3;
        } else {
            return -1;
        }
    }

    getSortedHaves = (existing) => {
        let newObject = !!existing ? [...existing] : [];

        newObject.sort((a, b) =>
            this.sortMustHaves(b) - this.sortMustHaves(a)
        );

        return [...newObject];
    }

    handlerOperatorOption = (operator, key) => {
        const {onSave} = this.props;

        if (key === "mustHave") {
            let mustHave = {
                type: operator,
                args: this.props.mustHave.args
            }
            !!onSave && onSave(mustHave, "mustHave");
        } else if (key === "niceToHave") {
            let niceToHave = {
                type: operator,
                args: this.props.niceToHave.args
            }
            !!onSave && onSave(niceToHave, "niceToHave");
        }
    }

    submitHave = (key, have) => () => {
        console.log({have});
        const {jobId} = this.props;
        Job.update(jobId, {
            [key]: have
        }, () => {
            Core.showMessage("Saved Successfully!");
        });
    }

    render() {
        const {onSave, mustHave, niceToHave, onlyView, candidate, jobTags, candidateResume, source} = this.props;
        const extraInfo = {
            jobTags, candidateResume, toMatchWith: candidate, source
        };
        const styleCol = {
            backgroundColor: '#fff',
            padding: !!onlyView ? '2px 0px' : '0px 15px',
            wordBreak: 'break-word',
            overflow: 'hidden'
        };

        return <Fragment>
            <Row>
                <Col fullWidth style={!!onlyView ? {padding: '0px'} : {}}>
                    {!!onlyView ? <p><strong>Must Have</strong></p> :
                        <label className='pad-btm-15'>Must Have<FlatButton label="Save" className='must-have-save'
                                                                           primary={true}
                                                                           onClick={this.submitHave('mustHave', mustHave)}/></label>}
                    {this.renderAdditionalYearsOFExperience()}
                    <InputArray
                        heading={!!mustHave.type && (<OperatorDropdown
                            type={mustHave.type}
                            handlerOperator={this.handlerOperatorOption}
                            onlyView={onlyView}
                            operators={Operators}
                            mainComponent="mustHave"
                        />)}
                        existing={_.cloneDeep(mustHave.args)}
                        elementComp={PrintSingleMustHave}
                        CreatePopupComponent={PopupAdElement}
                        createWithPopup={true}
                        onlyView={onlyView}
                        extraInfo={extraInfo}
                        styleCol={styleCol}
                        parentUpdater={(result) => {
                            const operator = !!this.props.mustHave.type ? this.props.mustHave.type : "and";
                            let result2 = result.map(obj => {

                                    if (['or', 'not', 'and'].includes(obj.type)) {
                                        let {type, atleast, specialInstruction, args} = obj;
                                        return {type, atleast, specialInstruction, args};
                                    } else if (obj.type === 'chip' || obj.type === 'chipGroup' || obj.type === 'chipTag') {
                                        let {type, key, value, specialInstruction, category} = obj;
                                        return {type, key, value, specialInstruction, category};
                                    } else if (obj.type === 'text') {
                                        let {type, value, category} = obj;
                                        return {type, value, category};
                                    } else {
                                        return {};
                                    }

                                }
                            )

                            let mustHave = {
                                type: operator,
                                args: _.cloneDeep(result2)
                            };

                            this.setState({mustHave})
                            onSave(mustHave, "mustHave");
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col fullWidth style={!!onlyView ? {padding: '0px'} : {}}>
                    {!!onlyView ? <p><strong>Nice To Have</strong></p> :
                        <label className='pad-btm-15'>Nice To Have<FlatButton label="Save" className='must-have-save'
                                                                              primary={true}
                                                                              onClick={this.submitHave('niceToHave', niceToHave)}/></label>}
                    <InputArray
                        heading={!!niceToHave.type && (<OperatorDropdown
                            type={niceToHave.type}
                            handlerOperator={this.handlerOperatorOption}
                            onlyView={onlyView}
                            operators={Operators}
                            mainComponent="niceToHave"
                        />)}
                        existing={_.cloneDeep(niceToHave.args)}
                        elementComp={PrintSingleMustHave}
                        CreatePopupComponent={PopupAdElement}
                        createWithPopup={true}
                        onlyView={onlyView}
                        extraInfo={extraInfo}
                        styleCol={styleCol}
                        parentUpdater={(result) => {
                            const operator = !!this.props.niceToHave.type ? this.props.niceToHave.type : "and";
                            let result2 = result.map(obj => {

                                    if (['or', 'not', 'and'].includes(obj.type)) {
                                        let {type, atleast, specialInstruction, args} = obj;
                                        return {type, atleast, specialInstruction, args};
                                    } else if (obj.type === 'chip' || obj.type === 'chipGroup' || obj.type === 'chipTag') {
                                        let {type, key, value, specialInstruction, category} = obj;
                                        return {type, key, value, specialInstruction, category};
                                    } else if (obj.type === 'text') {
                                        let {type, value, category} = obj;
                                        return {type, value, category};
                                    } else {
                                        return {};
                                    }

                                }
                            )


                            this.setState({niceToHave: this.props.niceToHave})

                            onSave({
                                type: operator,
                                args: _.cloneDeep(result2)
                            }, "niceToHave");
                        }}
                    />
                </Col>
            </Row>
        </Fragment>
    }
}

export default Main;
