import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { TextField } from "material-ui";
import Checkbox from "material-ui/Checkbox";
import IconButton from "material-ui/IconButton";
import IconMenu from "material-ui/IconMenu";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import Popover from "material-ui/Popover";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Account from "../../../../lib/Account";
import Candidate from "../../../../lib/Candidate";
import { engagementMatchStrength } from "../../../../lib/Constants";
import Core from "../../../../lib/Core";
import Definition from "../../../../lib/Definition";
import Job from "../../../../lib/Job";
import { mapEmployers } from "../../../../lib/models/employer";
import Streak from "../../../../lib/Streak";
import copyHTML from "../../../../lib/tools/copyHtml";
import CreateDisagreement from "../../../Disagreements/createWithPopup";
import ManageEngagement from "../../../Shared/ManageEngagement";
import Heading from "../../../Shared/Match/ObjectListPipe/Heading";
import JobCardThumbsActions from "../../../Shared/Match/ObjectListPipe/JobCardThumbsActions";
import MultiSelectJob from "../../../Shared/Match/ObjectListPipe/MultiSelectJob";
import MatchLocationChips, { MATCH_LOCATION_CHIPS__JD_REQUESTER } from "../../../Shared/MatchLocationChips";
import ResumeMatch from "../../../Shared/ResumeMatch";
import AnswerJobsQuestions from "../../Forms/AnswerJobsQuestions";

const ROLE_LIMITED_RECRUITER = "LimitedRecruiter";

class SingleJobCard extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      /** µ DUPLICATED ATTR. (future cleanup) * /
      job: this.props.job,
      /** */
      selected: this.props.selected,
      forceMlOn: this.props.MLScoreMode,
      openPopover: false,
      anchorEl: null,
      getDisagreementDetails: false,
      showQuestions: false,
      wantsUpdateEngagement: false,
      engagement: this.props.engagement,
      reviewed: false,
      MlDecisionPending: true,
      job: this.props.job,
      otherDecisionMakers: [],
      employerName: "Employer",
    };

    this.cardStyles = {
      marginBottom: "0px",
      borderBottom: "2px solid #454545",
      cursor: "pointer",
    };

    setTimeout(() => {
      this.getEmployerName();
    });
  }

  getEmployerName = async () => {
    const { candidate, job } = this.props;
    const { account: recruiter } = candidate;
    const { employer } = job;
    let flagHideEmployerName = false;
    if (recruiter?.role === ROLE_LIMITED_RECRUITER) {
      const whiteList = mapEmployers(
        await Account.getRecruiterWhiteList(recruiter?.id)
      );
      flagHideEmployerName = true;
      whiteList.find(({ id: _empID }) => {
        if (_empID === employer.id) {
          flagHideEmployerName = false;
          return true;
        }
        return false;
      });
    }
    const employerName = flagHideEmployerName
      ? employer.proxyName || employer.id
      : employer.name;
    this.setState({ employerName });
  };

  afterUpdateEngagement = (model = {}) => {
    this.setState(
      {
        engagement: {
          ...this.state.engagement,
          ...model,
        },
      },
      () => {
        const { engagement } = this.state;
        const { afterMainUpdateEngagement } = this.props;
        afterMainUpdateEngagement(engagement);
      }
    );
  };

  closeParentPopup = () => {
    this.setState({ wantsUpdateEngagement: false });
  };

  handlerUpdateEngagement = () => {
    this.setState({ wantsUpdateEngagement: true });
  };

  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.MLScoreMode !== this.props.MLScoreMode &&
      nextProps.MLScoreMode === true
    ) {
      this.setState({ forceMlOn: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.selected !== this.props.selected) {
      this.setState({ selected: this.props.selected });
    }

    if (prevState.job !== this.props.job) {
      this.setState({ job: this.props.job });
    }
  }

  onCheckStar = (job) => (ev, checked) => {
    //ev.stopPropagation();
    Job.updateStarred(job.id, this.state.starredId, checked, (response) => {
      job.starred = response.starred;
      this.setState({ job });
    });
  };

  handlerOnSelect = (job, engagement) => () => {
    const { resizeIndex } = this.props;
    this.setState({ selected: true });
    !!this.props.handlerSelectJob && this.props.handlerSelectJob(job);
    !!resizeIndex && resizeIndex();
  };

  handlerForceMlOn = () => {
    this.setState({ forceMlOn: true });
  };

  handlerScoreMAtch = () => {
    this.setState({ forceMlOn: false });
  };

  handlerSkippedJob = () => {
    let { handlerSkippedJob, job, handlerSelectJob } = this.props;
    handlerSkippedJob(job);
    handlerSelectJob(null);
  };

  renderPopOver = () => {
    const { handlerMatchJob, candidate, notFoundInPermittedJobWithOwnership } =
      this.props;
    const { matchStrength, job } = this.state;
    const matchStrengthLabel = Definition.getLabel(
      "engagementMatchStrength",
      matchStrength
    );

    return (
      <Popover
        open={this.state.openPopover}
        anchorEl={this.state.anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        targetOrigin={{ horizontal: "left", vertical: "top" }}
        onRequestClose={(ev) =>
          this.setState({
            openPopover: false,
          })
        }
      >
        {Streak.getPipeline().matchOptions && (
          <Menu>
            {Streak.getPipeline().matchOptions.map((option) => (
              <MenuItem
                key={option}
                primaryText={option}
                onClick={(ev) => {
                  const engKeys = {
                    stage: option.split(",")[0].trim(),
                    status: option.split(",")[1].trim(),
                    matchStrength,
                    matchStrengthLabel,
                  };

                  const next = () => {
                    this.setState({
                      showQuestions: true,
                      openPopover: false,
                      handlerNextAnswers: handlerMatchJob(engKeys),
                    });
                  };

                  const validationMsg = notFoundInPermittedJobWithOwnership(
                    candidate,
                    job,
                    engKeys
                  );
                  if (validationMsg) {
                    alert(validationMsg);
                    // if(window.confirm('This recruiter does not have permission to submit this candidate. Do you want to permit the candidate right now?')){
                    //     next();
                    // }else{
                    // }
                  } else {
                    next();
                  }
                }}
              />
            ))}
          </Menu>
        )}
      </Popover>
    );
  };

  onCloseDisagreementPopup = () => {
    this.setState({ getDisagreementDetails: false });
  };

  localCreateDisagreement = (model, cb) => {
    const { createDisagreement, job, candidate, engagement } = this.props;
    const { matchStrength, reviewed } = this.state;
    let matchStrengthLabel = Definition.getLabel(
      "engagementMatchStrength",
      matchStrength
    );

    if (
      [
        engagementMatchStrength.STRONG_YES,
        engagementMatchStrength.YES,
        engagementMatchStrength.WEAK_YES,
      ].includes(matchStrength) &&
      /w - 10x10/i.test(Object(engagement).status) &&
      /detail/i.test(Object(engagement).status)
    ) {
      matchStrengthLabel = "TBD";
    }

    let structure = {
      ...model,
      matchStrength: matchStrengthLabel,
      job,
      candidate,
      engagement,
      employer: job.employer,
      reviewed,
      cb: () => {
        !!cb && cb();
      },
    };

    !!createDisagreement && createDisagreement(structure);
  };

  handlerThumbsUp = (ev, matchStrength) => {
    const { isJobEngageable, job } = this.props;

    let matchStrengthText;
    switch (matchStrength) {
      case 1:
        matchStrengthText = "STRONG YES";
        break;
      case 2:
        matchStrengthText = "YES";
        break;
      case 3:
        matchStrengthText = "WEAK YES";
        break;
      default:
        matchStrengthText = "";
        break;
    }
    if (window.confirm(`Confirm this match is a ${matchStrengthText}?`)) {
      if (!!isJobEngageable && isJobEngageable(job)) {
        this.setState({
          matchStrength,
          openPopover: true,
          anchorEl: ev.currentTarget,
        });
      } else {
        this.setState(
          {
            matchStrength,
          },
          () => {
            this.localCreateDisagreement();
          }
        );
      }
    }
  };

  handlerThumbsDown = (ev, matchStrength) => {
    let matchStrengthText;
    switch (matchStrength) {
      case 5:
        matchStrengthText = "NO";
        break;
      case 6:
        matchStrengthText = "STRONG NO";
        break;
      default:
        matchStrengthText = "";
        break;
    }
    if (window.confirm(`Confirm this match is a ${matchStrengthText}?`)) {
      this.setState({
        getDisagreementDetails: true,
        matchStrength,
      });
    }
  };

  saveCandoPrivateNoteAddition = () => {
    //privateNotes
    let { candidate } = this.props;
    let { candoPrivateNoteText, permittedJobsAnswers } = this.state;

    let finalNotes = candidate.privateNotes;

    if (!!candoPrivateNoteText) {
      finalNotes = candidate.privateNotes
        ? candidate.privateNotes + "\n" + candoPrivateNoteText
        : candoPrivateNoteText;
    }

    Candidate.update(candidate.id, {
      privateNotes: finalNotes,
      permittedJobsAnswers,
    });
  };

  specialEngagementCreation =
    (matchStrength) =>
      (
        {
          candidate,
          job,
          pickedReasons,
          disagreementReasonDetails,
          shouldTag,
          shouldNotTag,
          whyNoPrivateNote,
          whyNeedToReadCV,
        },
        { disagreementModel, cb }
      ) => {
        let {
          createSingleEngagementWithDis,
          engagement,
          strategyGetConstraints,
        } = this.props;
        let { reviewed } = this.state;
        const matchStrengthLabel = Definition.getLabel(
          "engagementMatchStrength",
          matchStrength
        );

        let stage, status, state, rejectionReason, rejectionReasonAdditionalInfo;

        if (
          !engagement &&
          (strategyGetConstraints(candidate, job, "jobsPermitted") ||
            strategyGetConstraints(candidate, job, "jobsPitched"))
        ) {
          stage = "Confirmation";
          status = "W - 10x10 Notify Rejection";
          state = "Open";
          rejectionReason = pickedReasons
            .filter((el) => !["positiveSignals", "negativeSignals"].includes(el))
            .join(",");
          rejectionReasonAdditionalInfo = disagreementReasonDetails;
        } else {
          stage = "Confirmation";
          status = "E - 10x10";
          state = "Closed";
          rejectionReason = "10x10 - No Match";
          rejectionReasonAdditionalInfo = disagreementReasonDetails;
        }
        createSingleEngagementWithDis(job, {
          stage,
          status,
          state,
          rejectionReason,
          rejectionReasonAdditionalInfo,
          matchStrength,
          matchStrengthLabel,
          reviewed,
          ...disagreementModel,
        });

        !!cb && cb();

        if (
          [
            engagementMatchStrength.NO,
            engagementMatchStrength.STRONG_NO,
          ].includes(matchStrength)
        )
          this.pushingToCandoOnThumbsDown({ candidate, job });
      };

  pushingToCandoOnThumbsDown = (data) => {
    const { pushEntityToCandidate } = this.props;
    const jobId = data.job.id;
    let putDownJobs = data.candidate.putDownJobs || [];

    putDownJobs.push(jobId);
    putDownJobs = Array.from(new Set(putDownJobs));
    !!pushEntityToCandidate && pushEntityToCandidate(putDownJobs);
  };

  updateMIScore = (text) => {
    let { job } = this.state;
    const { handlerMIScoreObjects, resizeIndex } = this.props;

    job["matchScore"] = text;
    this.setState({ job }, () => {
      !!handlerMIScoreObjects && handlerMIScoreObjects(job);
      !!resizeIndex && resizeIndex();
    });
  };

  handlerMlDecisionMaking = (status, MlDecisionMakers) => {
    const { resizeIndex } = this.props;
    this.setState(
      {
        MlDecisionPending: status,
        otherDecisionMakers: MlDecisionMakers,
      },
      () => {
        !!resizeIndex && resizeIndex();
      }
    );
  };

  render() {
    let {
      getDisagreementDetails,
      matchStrength,
      candoPrivateNoteText,
      selected,
      wantsUpdateEngagement,
      engagement,
      reviewed,
      MlDecisionPending,
      otherDecisionMakers,
      job,
      employerName,
    } = this.state;

    let {
      candidate,
      handlerOnSelectBox,
      getJobLabels,
      multiSelectedJobs,
      annotatorMode,
      segregateRolesChip,

      /** @todo review and cleanup (old locations) | 2021-09-01 Wed µ */
      // segregateLocationChip,

      updateEngagement,
      getOwnershipChip,
      onLoad,
      measure,
    } = this.props;

    let cardStyles = selected
      ? {
        ...this.cardStyles,
        border: "3px solid #5f57fe",
        boxShadow: "0px 0px 10px #5f57fe",
      }
      : this.cardStyles;

    if (!!engagement) {
      cardStyles = {
        ...cardStyles,
        backgroundColor: "lightgrey",
      };
    }

    //76.67: MH 50 (andor: 1, not 1), NH 20 (andor: 1, not 1), PR 20

    const mappingToStats = {
      score: "S",
      score_must_haves: "MH",
      without_not_operator_truthies_divide_by_total_must: "andor",
      with_not_operator_weightage_to_subtract_must: "not",
      score_nice_haves: "NH",
      without_not_operator_truthies_divide_by_total_nice: "andor",
      with_not_operator_weightage_to_subtract_nice: "not",
      score_platform_rating: "PR",
    };

    const mlStatsJoined = Object.keys(Object(job.mlScoreObject))
      .map((k) => {
        let value = parseFloat(job.mlScoreObject[k]);

        value = value.toFixed(2);

        if (Object.keys(mappingToStats).includes(k)) {
          if (k === "score") {
            return <strong>{`${mappingToStats[k]}: ${value}`}, </strong>;
          }
          return <span>{`${mappingToStats[k]}: ${value}`}, </span>;
        } else {
          return "";
        }
      })
      .filter((st) => !!st);

    return (
      <div key={this.props.index} style={this.props.style} onLoad={measure}>
        {this.props.label && !annotatorMode ? (
          <Heading
            idRef={`list-card-section-${this.props.label}`}
            classNameRef="list-card-section"
            heading={`${this.props.label}`}
          />
        ) : null}
        <Card
          onLoad={onLoad}
          id={`job-${job.id}`}
          style={cardStyles}
          onClick={this.handlerOnSelect(job, engagement)}
        >
          <CardHeader
            className="match-new job-list-card-header"
            action={
              <CardActions>
                {!!job && (
                  <MultiSelectJob
                    job={job}
                    handlerOnSelectBox={handlerOnSelectBox}
                    selectedCheck={
                      !!multiSelectedJobs &&
                      multiSelectedJobs.find((sjob) => sjob.id === job.id)
                    }
                  />
                )}
              </CardActions>
            }
            title={`${employerName} (${Object(job.employer)._employerHiringDifficulty
              }) | ${Object(job).addressCity || Object(job)._officeLocations}`}
            // component='p' // this causes error in console, <div> cannot be descendent of <p>, if its necessary, must figure some other way
            disableTypography={true}
          />

          <CardContent
            className="match-new job-list-card-content"
            style={{ position: "relative" }}
          >
            {/* <Typography variant="body2" color="textSecondary" component="p">
                        {job._roles} | {job.jobTitle}
                    </Typography> */}
            <p className="match-new job-card-text">
              {job._roles} | {job.jobTitle}
            </p>

            <div>
              {!!job &&
                !!job._visaTransfer &&
                this.props.staticRowDisplayChip(
                  job._visaTransfer,
                  candidate._visa,
                  this.props
                    .staticRowDisplayColor(job, candidate)
                    .getVisaColor()
                )}
              {!!segregateRolesChip && segregateRolesChip(job, candidate)}
              {!!job && typeof job.minYearsOfExperience !== "undefined"
                ? this.props.staticRowDisplayChip(
                  job.minYearsOfExperience + " yrs",
                  candidate._yearsOfExperienceForCalc,
                  this.props
                    .staticRowDisplayColor(job, candidate)
                    .getYearsXp()
                )
                : null}

              {/* 2021-09-01 µ */}
              <MatchLocationChips
                {...{
                  requester: MATCH_LOCATION_CHIPS__JD_REQUESTER,
                  job,
                  candidate
                }}
              />

              {/** @todo review and cleanup (old locations) | 2021-09-01 Wed µ */}
              {/** * /}
              {!!segregateLocationChip && segregateLocationChip(job, candidate)}
              {/** */}

              {!!job &&
                typeof job.salaryMax !== "undefined" &&
                this.props.staticRowDisplayChip(
                  "<=$" + job.salaryMax,
                  candidate.minimumSalary,
                  this.props
                    .staticRowDisplayColor(job, candidate)
                    .getSalaryColor()
                )}
              {!!job &&
                !!job._employeeRating &&
                this.props.staticRowDisplayChip(
                  job._employeeRating,
                  null,
                  null
                )}
            </div>

            {/* Candidate Match Score update */}
            {!!job && !!candidate && (
              <Fragment>
                <div style={annotatorMode ? { display: "none" } : {}}>
                  <p style={{ fontSize: "12px" }}>
                    <mark>{mlStatsJoined}</mark>
                  </p>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <JobCardThumbsActions
                    handlerThumbsUp={this.handlerThumbsUp}
                    handlerThumbsDown={this.handlerThumbsDown}
                    candidateId={!!candidate && candidate.id}
                    jobId={!!job && job.id}
                    handlerMlDecisionMaking={this.handlerMlDecisionMaking}
                  />
                  &nbsp;&nbsp;
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        edge="start"
                        checked={reviewed}
                        onChange={(event) => {
                          this.setState({ reviewed: event.target.checked });
                        }}
                        value={reviewed}
                        color="primary"
                      />
                    }
                    label="Reviewed"
                    labelPlacement="end"
                  />
                </div>
              </Fragment>
            )}
            {!!engagement && (
              <Typography
                variant="subtitle2"
                color="textPrimary"
                component="span"
                style={{ display: "inline" }}
              >
                {!annotatorMode && (
                  <span>
                    {Definition.getLabel(
                      "engagementMatchStrength",
                      engagement.matchStrength
                    )}
                    &nbsp;&nbsp;&nbsp;
                  </span>
                )}
                <Typography
                  variant="caption"
                  color="textSecondary"
                  component="span"
                  style={{ display: "inline" }}
                >
                  {moment(engagement.lastMatchedDate).format("MMMM - DD/YY")} by{" "}
                  {Object(engagement.matchedByWho).email}
                </Typography>
              </Typography>
            )}
            {!!engagement && !annotatorMode && (
              <Typography
                variant="body2"
                color="textSecondary"
                component="strong"
              >
                {engagement.state} | {engagement.status} | {engagement.stage} |{" "}
                {engagement.rejectionReason}{" "}
                {engagement.rejectionReasonAdditionalInfo}
              </Typography>
            )}
            {!annotatorMode ? (
              !MlDecisionPending ? (
                <Fragment>
                  {!!otherDecisionMakers.length ? (
                    otherDecisionMakers.map((dec) => {
                      return (
                        <p onLoad={measure} className="match-new job-card-text">
                          {dec.matchDecision} - {dec.annotator} -{" "}
                          {moment(dec.updatedAt).format("YYYY/MM/DD")}
                        </p>
                      );
                    })
                  ) : (
                    <p className="match-new job-card-text">
                      No annotations found
                    </p>
                  )}
                </Fragment>
              ) : (
                <p className="match-new job-card-text">Decision is pending</p>
              )
            ) : null}
            {!!candidate && (
              <Typography
                variant="body2"
                color="textSecondary"
                component="strong"
              >
                {getJobLabels(candidate, job).map((job, index) => (
                  <Fragment key={index}>
                    <span
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        padding: "2px",
                        marginRight: "2px",
                      }}
                    >
                      {job}
                    </span>
                  </Fragment>
                ))}
                {job.blacklisted}
                {!!candidate && getOwnershipChip(candidate, job)}
              </Typography>
            )}

            {this.renderPopOver()}
            <div style={{ display: "none" }}>
              <CreateDisagreement
                engagement={engagement}
                open={getDisagreementDetails}
                candidate={candidate}
                jobs={[job]}
                employer={job.employer}
                decision={"false-positive"}
                createWith={this.specialEngagementCreation(matchStrength)}
                matchDecision={Definition.getLabel(
                  "engagementMatchStrength",
                  matchStrength
                )}
                onClose={this.onCloseDisagreementPopup}
              />
            </div>

            {!!job && !!candidate && (
              <AnswerJobsQuestions
                finalUpdater={(res) => { }}
                handlerNext={(ansStruct) => () => {
                  !!this.state.handlerNextAnswers
                    ? this.state.handlerNextAnswers(ansStruct)
                    : (e) => { };
                  this.setState({ showQuestions: false });
                  this.setState({ permittedJobsAnswers: ansStruct }, () => {
                    this.saveCandoPrivateNoteAddition();
                  });
                }}
                handlerNextOnCancel={(data) => () => {
                  this.setState({
                    showQuestions: false,
                    permittedJobsAnswers: data.initial,
                  });
                }}
                nxtBtnText="Proceed"
                cancelBtnText="Cancel"
                alwaysShow={true}
                initialState={candidate.permittedJobsAnswers}
                jobs={[job.id]}
                open={this.state.showQuestions}
                close={(el) => {
                  this.setState({ showQuestions: false });
                }}
                extraComponentStructure={
                  <Fragment>
                    <InputLabel>
                      <strong>
                        Text to be added in Candidate's private note
                      </strong>
                    </InputLabel>
                    <TextField
                      style={{ height: 40 }}
                      placeholder="add your free comments"
                      onChange={(el) => {
                        this.setState({
                          candoPrivateNoteText: el.target.value,
                        }); // this is to make sure component re renders as value changes
                      }}
                      value={candoPrivateNoteText}
                      onBlur={(el) => { }}
                      fullWidth
                    />
                  </Fragment>
                }
              />
            )}

            {!!engagement && (
              <ManageEngagement
                engagement={engagement}
                open={wantsUpdateEngagement}
                closeParentPopup={this.closeParentPopup}
                afterUpdateEngagement={this.afterUpdateEngagement}
              />
            )}
            {Core.isAdminOrTrusted() && (
              <IconMenu
                style={{
                  position: "absolute",
                  bottom: 15,
                  right: 15,
                }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                targetOrigin={{ horizontal: "right", vertical: "bottom" }}
                iconButtonElement={
                  <IconButton
                    style={{
                      width: 24,
                      padding: 0,
                    }}
                    iconstyle={this.state.openedColor}
                  >
                    <i className="material-icons">more_vert</i>
                  </IconButton>
                }
              >
                {(Core.isAdmin() || (Core.isRecruiter() && Core.isOnDev())) && (
                  <NavLink to={"/job/matchNew/" + job.id} className="navlink">
                    <MenuItem primaryText="Match" />
                  </NavLink>
                )}
                {/** * /}
                {Core.isAdmin() && [
                  <NavLink
                    key={Core.getKey()}
                    to={"/job/view/" + this.state.id}
                    className="navlink"
                  >
                    <MenuItem primaryText="View" />
                  </NavLink>,
                  <NavLink
                    key={Core.getKey()}
                    to={"/job/edit/" + this.state.id}
                    className="navlink"
                  >
                    <MenuItem primaryText="Edit" />
                  </NavLink>,
                ]}
                {Core.isAdminOrCoordinator() && (
                  <MenuItem primaryText="Clone" onClick={this.cloneJob} />
                )}
                {/** */}
                {!!candidate && candidate.resumeTxtUrl && (
                  <ResumeMatch
                    candidateResume={candidate.resumeTxtUrl}
                    jobSkills={job._technicalSkills}
                    employerSkills={job.employer._technicalSkills}
                  />
                )}
                <MenuItem
                  primaryText="Calculate Match Score"
                  onClick={() => {
                    job.mlScore = "calculating";
                    job.mlScoreObject = {};
                    this.setState({ job });
                    if (this.props.processMLScoreSingle) {
                      let params = {
                        candidateId: candidate.id,
                        jobIds: [job.id],
                      };

                      this.props.processMLScoreSingle(
                        params,
                        "get_jobs_scores",
                        (result) => {
                          const thisJobResult = result.find(
                            (res) => res.item_id === job.id
                          );
                          if (thisJobResult) {
                            job.mlScore = (
                              Object(thisJobResult).match || {}
                            ).score;
                            job.mlScoreObject = Object(thisJobResult).match;

                            this.setState({ job });
                          }
                        }
                      );
                    }
                  }}
                />
                <MenuItem className="star-menu-item">
                  <Checkbox
                    title="Click to Star"
                    className="star"
                    checked={job.starred}
                    onCheck={this.onCheckStar(job)}
                    checkedIcon={<i className="material-icons">star</i>}
                    uncheckedIcon={
                      <i className="material-icons">star_border</i>
                    }
                  />
                </MenuItem>
                {!!engagement && (
                  <MenuItem
                    primaryText="Update Engagement"
                    onClick={this.handlerUpdateEngagement}
                  />
                )}
                {Core.isAdminOrCoordinator() &&
                  (this.state.checked || this.state.engagement) && (
                    <Fragment>
                      {!!this.state.engagement && !!job && !!candidate && (
                        <MenuItem
                          key={Core.getKey()}
                          primaryText={"Submission"}
                          onClick={(ev) =>
                            Core.go({ ...this.this.props, to: `/candidate/resume-submission/${candidate.id}/${job.id}` })
                          }
                        />
                      )}

                      {this.state.engagement && (
                        <Fragment>
                          <MenuItem
                            key={Core.getKey()}
                            primaryText={"Mark ML Positive"}
                            onClick={(ev) => {
                              updateEngagement(engagement, {
                                useForMlTraining: "true",
                              });
                            }}
                          />
                          <MenuItem
                            key={Core.getKey()}
                            primaryText={"Mark ML Negative"}
                            onClick={(ev) => {
                              updateEngagement(engagement, {
                                useForMlTraining: "false",
                              });
                            }}
                          />
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                {Core.isAdminOrTrusted() && (
                  <MenuItem
                    primaryText="Copy"
                    onClick={(ev) =>
                      copyHTML(Job.getPreview(job))
                        .then((em) => {
                          Core.log("Copy email command was successful");
                          Core.showMessage("Copied!");
                        })
                        .catch((ex) => {
                          Core.log("Oops, unable to copy");
                          Core.showMessage("Fail copy!");
                        })
                    }
                  />
                )}
              </IconMenu>
            )}
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default SingleJobCard;
