
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../components/Login/Login';

export const Unlogged = (em) => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/forgot/password" component={Login} />
    <Route path="/reset/password/:token" component={Login} />
    <Route component={Login} />
  </Switch>
);
