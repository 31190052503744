import { colors } from '../../../lib/Core';

const Styles = {
    AppBar: {
        marginBottom: '24px',
        title: {
            color: colors.purple,
            fontSize: '20px',
        },
        rightLabel: {
            color: colors.cyan,
        },
        backButton: {
            color: colors.darkGray,
            marginTop: '5px'
        },
        nav: {
            backgroundColor: colors.white
        },
    },
    Buttons: {
        textAlign: 'center',
        cancel: {
            color: 'purple',
            border: '1px solid purple'
        },
        save: {
            color: 'white',
            backgroundColor: 'purple'
        }
    },
    TextField: {
        width: '100%',
        fontSize: '12px',
        underlineStyle: {
          borderColor: 'transparent',
          borderWidth: 0
        },
        inputStyle: {
          backgroundColor: colors.white,
          color: colors.black,
          fontFamily: 'Roboto',
          fontSize: '14px',
          lineHeight: '24px',
          padding: '8px',
          border: '1px solid #ddd',
          borderRadius: '5px',
        },
        additionalField: {
            fontSize: '12px !important',
            height: '38px !important'
        }
    }
};

export default Styles;