import React from 'react'

import FirstPageIcon from '@mui/icons-material/FirstPage'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { makeStyles, useTheme } from '@mui/styles'
import PropTypes from 'prop-types'
import { REACT_TABLE__INITIAL_PAGE } from './useEnhancedReactTable.hook'

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}))

function TablePaginationActions({
  page,
  rowsPerPage: pageSize,
  count: totalCount,
  onPageChange,
}) {
  const classes = useStyles()
  const theme = useTheme()
  page = page + 1;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, REACT_TABLE__INITIAL_PAGE)
  }

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(totalCount / pageSize)))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === REACT_TABLE__INITIAL_PAGE}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === REACT_TABLE__INITIAL_PAGE}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <input
        type="number"
        style={{
          width: 48,
          textAlign: 'center',
          border: '1px solid #ccc',
          outline: 'none',
          borderRadius: 3
        }}
        value={page}
        onChange={event => onPageChange(event, Number(event.target.value))}
        max={Math.max(0, Math.ceil(totalCount / pageSize))}
      />
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(totalCount / pageSize)}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(totalCount / pageSize)}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
}

export default TablePaginationActions
