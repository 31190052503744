import moment from 'moment';
import { MDASH } from '../Constants';
import { getCandidateModel, mapCandidate } from './candidate';
import { getEngagementModel, mapEngagement } from './engagement';
import { getJobModel, mapJob } from './job';

export const MODEL_NAME_MATCH_DECISION_TABLE = 'MatchDecisionTable';

export function getMatchDecisionTableModel({
  extended: requiredExtended,
} = {}) {
  const model = {
    fromAnnotator: null, // 'string',
    toAnnotator: null, //  'string',
    decision: null, //  'string',
    disagreementReason: null, //  'string',
    probScore: null, //  'string',
    engagementId: null, //  'string',
    jobId: null, //  'string',
    candidateId: null, //  'string',
    createdAt: null, //  '2021-10-26T22:39:28.476Z',
    updatedAt: null, //  '2021-10-26T22:39:28.476Z',

    annotator: null, // "ML"
    candidateJobId: null, //"5d62d4b1924b024519ef6421-61046160b1d46722d5880724"
    candidateName: null, //"__C2_EEE _CA_EM"
    candidateUrl: null, // "https://staging.go10x10.com/#/candidate/edit/61046160b1d46722d5880724"
    employerName: null, // "_Test Emp"
    engagementStage: null, // "Submission"
    engagementStatus: null, // "W - 10x10 Submission"
    engagementUrl: null, // "https://staging.go10x10.com/#/engagement/view/617588d98a77792d50f72fbe"
    jobName: null, // "__j111"
    jobRoles: null, // "Backend Engineer"
    jobUrl: null, // "https://staging.go10x10.com/#/job/edit/5d62d4b1924b024519ef6421"
    matchDecision: null, // "ML_undefined"
    matchingUrl: null, // "https://staging.go10x10.com/#/candidate/matchNew/61046160b1d46722d5880724?selected=5d62d4b1924b024519ef6421"
    shouldNotTag: null, // ""
    shouldTag: null, // ""
    source: null, // "candidateMatch"
    whyNeedToReadCV: null, // ""
    whyNoCategories: [], //
    whyNoDetails: null, // ""
    whyNoFieldsValues: [], //
    whyNoPrivateNote: null, //""

  };

  const extended = {
    ___model___: MODEL_NAME_MATCH_DECISION_TABLE,
    id: null,
    ...model,
    engagement: getEngagementModel({ extended: true }),
    candidate: getCandidateModel({ extended: true }),
    job: getJobModel({ extended: true }),
  };

  return requiredExtended ? extended : model;
}

export function mapMatchDecisionTable(item) {
  const matchDecisionTable = getMatchDecisionTableModel({
    extended: true,
  });

  if (item) {
    Object.keys(matchDecisionTable).forEach(
      (key) => !!item[key] && (matchDecisionTable[key] = item[key])
    );
    matchDecisionTable.id = item.id || item._id;
    matchDecisionTable._updatedAt = matchDecisionTable.updatedAt
      ? moment(matchDecisionTable.updatedAt).format('MM-DD-YYYY')
      : MDASH;

    matchDecisionTable._createdAt = matchDecisionTable.createdAt
      ? moment(matchDecisionTable.createdAt).format('MM-DD-YYYY')
      : MDASH;

    matchDecisionTable.engagement = mapEngagement(
      matchDecisionTable.engagement
    );
    matchDecisionTable.candidate = mapCandidate(matchDecisionTable.candidate);
    matchDecisionTable.job = mapJob(matchDecisionTable.job);

    matchDecisionTable._whyNoCategories = matchDecisionTable.whyNoCategories.join(', ')
  }

  return matchDecisionTable;
}

export function mapMatchDecisionTables(data = []) {
  return data.map(mapMatchDecisionTable);
}
