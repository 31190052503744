/** ============================================ µ
 * @description MoreOptionsMenu [JSX]
 *              UI Component
 * @createdAt   2021-09-22 Wed
 * @updatedAt   2021-10-12 Tue
 * ============================================ */
/* IMPORTS ==================================== */

import {
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function MoreOptionsMenu({ row = {}, context: { config }, overrideOptions }) {

  const { moreMenuOptions: options = [] } = config;
  const _options = overrideOptions || options;
  const { original: model = {} } = row;

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (method = () => { }) => event => {
    setAnchorEl(null);
    setTimeout(() => method(model));
  };

  return (
    <>

      <IconButton onClick={handleClick}>
        <i className="material-icons">more_vert</i>
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose()}
        closeAfterTransition
        PaperProps={{
          style: {
            maxHeight: '80vh',
            width: 280,
          },
        }}
      >
        {_options.map(({
          acl = model => true,
          title,
          label,
          render,
          to,
          target = engagement => '',
          action = model => { }
        }, index) => {
          const key = `more-options-menu-option-${label}-${index}`;
          label = (typeof label === 'string') ? t(label) : label instanceof Function ? label(model) : label;
          return (
            acl(model) && (
              render
                ? render(model, { key, onClick: handleClose(action) })
                :
                (to instanceof Function)
                  ? (
                    <NavLink
                      key={key}
                      title={title}
                      to={to(model)}
                      target={target(model)}
                      className="navlink"
                      onClick={handleClose(action)}
                    >
                      <MenuItem>
                        {label}
                      </MenuItem>
                    </NavLink>
                  )
                  : (
                    <MenuItem
                      key={key}
                      title={title}
                      onClick={handleClose(action)}
                    >
                      {label}
                    </MenuItem>
                  )
            )
          );
        })}
      </Menu>
    </>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { MoreOptionsMenu as default, MoreOptionsMenu };

/* ============================================ */