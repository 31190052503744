/** ============================================ µ
 * @Description EmailList [JSX]
 *              UI Component
 * @createdAt   2021-04-29
 * @updatedAt   2021-12-17 Fri
 * ============================================ */
/* IMPORTS ==================================== */

import { Button } from "@mui/material";
import { DatePicker } from "antd";
import moment from "moment";
import React from "react";
import getEmailModel from "../../lib/models/email.model";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import Col from "./Col";
import EmailEntry from "./EmailEntry";
import Row from "./Row";
import { useTranslation } from "react-i18next";

/* CONSTANTS ==================================== */

const DATE_FORMAT = 'YYYY-MM-DD';

/* METHODS ==================================== */

/**
 * 
 * @param {object} props 
 * @param {object} props.EntityController
 * @param {object} props.EntityController.state
 * @param {object[]} props.EntityController.state.emailsList
 * @param {function} props.EntityController.setState
 * 
 * @returns
 */
function EmailList(props) {
  const ConfirmDialogRef = React.useRef();
  const AddANewEmailButtonRef = React.useRef();
  const LastEmailEntryRef = React.useRef();
  const { t } = useTranslation();
  let {
    title = '',
    EntityController = {
      setState() { }
    },
    onChange = () => { },
    messageTypeTags = [],
    disabledExclude
  } = props;
  // console.debug('messageTypeTags', messageTypeTags);
  const {
    state = {}
  } = EntityController;
  const {
    emailsList = [],
    excludeAutomatedEmailsUntil,
  } = state;
  function scrollToLastEmailEntry() {
    try {
      LastEmailEntryRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      });
    } catch { }
  }
  function _onChange() {
    onChange(emailsList, scrollToLastEmailEntry);
  }
  function onChangeEmailEntry({
    index,
    data = {}
  }) {
    EntityController.setState(state => {
      const newState = { ...state };
      emailsList[index] = { ...data };
      newState.emailsList = emailsList;
      return newState;
    }, _onChange);
  }
  function onClickAddNewEmail(event) {
    EntityController.setState(state => {
      const newState = { ...state };
      emailsList.push(getEmailModel());
      newState.emailsList = emailsList;
      return newState;
    }, _onChange);
  }
  function onClickRemoveEmailEntry({
    index,
    data
  }) {
    const {
      name,
      email
    } = data;
    return event => {
      ConfirmDialogRef.current.open({
        message: (
          name && email
            ? `Do you want remove ${name || '?'} - ${email || '?'} from list?`
            : `Do you want remove the email entry number ${index + 1} from list?`
        ),
        onConfirm: ev => {
          EntityController.setState(state => {
            const newState = { ...state };
            emailsList.splice(index, 1);
            newState.emailsList = emailsList;
            return newState;
          }, _onChange);
        }
      });
    }
  }
  return (
    <div className="bg-a-05 p-1 m-2 rounded">

      <Row>
        <Col>
          <label>{title ? `${title} - ` : ''}Emails List</label>
        </Col>
        {!disabledExclude && (
          <Col className="pb-2 d-flex flex-align-right-center">
            <h3 className="c-gray m-0 mr-2">{t('emailsList.excludeUntil')}</h3>
            <div className="d-flex align-center">
              <DatePicker
                value={excludeAutomatedEmailsUntil ? moment(excludeAutomatedEmailsUntil, DATE_FORMAT) : null}
                format={DATE_FORMAT}
                onChange={excludeAutomatedEmailsUntil =>
                  EntityController.setState({
                    excludeAutomatedEmailsUntil: excludeAutomatedEmailsUntil ? excludeAutomatedEmailsUntil.toISOString() : '',
                  })
                }
              />
            </div>
          </Col>
        )}
      </Row>
      {emailsList.map((data, index) => (
        <div ref={LastEmailEntryRef}>
          <EmailEntry
            data={data}
            index={index}
            messageTypeTags={messageTypeTags}
            onChange={onChangeEmailEntry}
            onRemove={onClickRemoveEmailEntry}
          />
        </div>
      ))}
      <Row>
        <Col fullWidth className="d-flex">
          <div
            ref={AddANewEmailButtonRef}
            className="m-1 ml-auto"
          >
            <Button
              className="btn-cyan-white"
              onClick={onClickAddNewEmail}
            >
              Add a new email
            </Button>
          </div>
        </Col>
      </Row>
      <ConfirmDialog
        ref={ConfirmDialogRef}
        title="Confirmation"
        message=""
        actionLabel="Confirm"
      />

    </div>
  );
}

/* EXPORTS ==================================== */

export {
  EmailList as default,
  EmailList,
};

/* ============================================ */