import React, {Component, Fragment} from "react";

import Chip from '@mui/material/Chip';
import {matchSkillWithText} from "../../../../lib/tools/cvMatch";

class JobTags extends Component {
    constructor(){
        super(...arguments);
        this.state = {
            labels: this.props.labels.map(label=>label.split(",")).flat() || [],
            resumeText: null
        };
    }
    
    componentWillMount(){
        if(!!this.props.resume){
            this.fetchResume(this.props.resume);
        }
    }
    
    componentWillUpdate(nextPProps, nextState){
        if(nextPProps.resume !== this.props.resume){
            this.fetchResume(nextPProps.resume);
        }
        if(nextPProps.labels !== this.props.labels){
            const newLabels = nextPProps.labels.map(label=>label.split(",")).flat();
            this.setState({labels: newLabels});
        }
    }
    
    fetchResume = (resume) => {
        fetch(resume).then(responseText => {
            responseText.text().then(text => {
                this.setState({resumeText: text});
            });
        });
    }

    findMatchingTagsWithResume = (chip) => {
        const {resumeText} = this.state;
        if(!!resumeText){
            return !!matchSkillWithText(chip, resumeText);
        }
        return false;
    }
    
    render(){
        const {labels, resumeText} = this.state;
        const {source} = this.props;
        
        return <Fragment>
            {labels.map((label, index)=> (
                <Chip 
                    key={index}
                    className="chip slim-chip"
                    variant="outlined"
                    size="small"
                    label={label}
                    style={{
                        border: !resumeText ? "1px solid #888888" : !!this.findMatchingTagsWithResume(label) ? "1px solid green" : source === "jobMatch" ? "1px solid #888888" : "2px solid red"
                    }}
                />
            ))}
        </Fragment>;
    }
}

export default JobTags;