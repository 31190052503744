import React, {Component} from 'react';
import Row from "../../Forms/Row";
import Col from "../../Forms/Col";
import TextField from "material-ui/TextField";
import Grid from "@mui/material/Grid";
import YearsDropdown from "../../Shared/YearsDropdown";
import {MenuItem, SelectField} from "material-ui";
import Definition from "../../../lib/Definition";

class ExperiencePositionHistory extends Component {
    constructor(props) {
        super(props);
        this.state={...this.props.element};
        if(this.props.isNewRecord){
            this.props.onDone(this.getUpdatedStructure());
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // if(
        //     this.props.element.title !== this.state.title
        //     || this.props.element.description !== this.state.description
        //     || this.props.element.startDate !== this.state.startDate ||
        //     this.props.element.endDate !== this.state.endDate
        // ){
        //     this.setState({...this.props.element})
        // }
    }


    getUpdatedStructure = () => {
        const {title, startDate, endDate,id,currentlyEmployed, startDateMonth, startDateYear, endDateMonth, endDateYear} = this.state;
        return {id,title,startDate,endDate,currentlyEmployed, startDateMonth, startDateYear, endDateMonth, endDateYear};
    };

    render() {
        let {onDone,handlerDeleteItem,element} = this.props;
        const {title, currentlyEmployed, startDateMonth, startDateYear, endDateMonth, endDateYear} = this.state;
        let lineStyle = '';

        return <Grid item xs={12} style={{backgroundColor: '#E8E8E8', marginBottom: '10px'}}>
            <Row>
                <Col fullWidth>
                    <label>Title &nbsp;<span style={{color: 'red', cursor: 'pointer'}} onClick={() => handlerDeleteItem(element)}>X</span>
                    </label>
                    <TextField
                        name="title"
                        underlineFocusStyle={lineStyle}
                        rows={1}
                        rowsMax={5}
                        multiLine={true}
                        value={title}
                        onChange={(ev, title) => {
                            this.setState({title}, () => {
                                onDone(this.getUpdatedStructure());
                            })
                        }
                        }
                        onBlur={() => {
                            onDone(this.getUpdatedStructure())
                        }
                        }
                        fullWidth
                    />
                </Col>

            </Row>
            <Row>
                <Col>
                    <label>Currently working here?</label>
                    <SelectField
                      name="currentlyEmployed"
                      fullWidth
                      value={currentlyEmployed}
                      onChange={(ev, i, currentlyEmployed) => {
                          this.setState({currentlyEmployed})
                          if(parseInt(currentlyEmployed) === 1){
                              this.setState({endDate: 'current'}, () => {
                                  onDone(this.getUpdatedStructure());
                              })
                          }
                      }
                      }
                    >
                        {Definition.get("diversity").map(state => {
                            return (
                              <MenuItem
                                key={state.id}
                                value={state.id}
                                primaryText={state.label}
                              />
                            );
                        })}
                    </SelectField>

                </Col>

                <Col>
                    <label> Start date</label>
                    <YearsDropdown
                      month={startDateMonth}
                      year={startDateYear}
                      onChangeHandler={(startDateMonth, startDateYear) => {
                          this.setState({startDateMonth, startDateYear}, () => {
                              onDone(this.getUpdatedStructure());
                          })
                      }}
                    />
                </Col>

                {parseInt(currentlyEmployed) === 1 ? <Col>
                  <label>End date</label>
                  <TextField
                    name={'employerTo'}
                    value={'current'}
                    onChange={(ev,val) => {
                        this.setState({endDate: val})
                    }}
                    onBlur={ev => {
                    }}
                    fullWidth
                    errorText={""}
                    disabled
                  /></Col> :
                  <Col>
                      <label>End date</label>
                      <YearsDropdown
                        month={endDateMonth}
                        year={endDateYear}
                        onChangeHandler={(endDateMonth, endDateYear) => {
                            this.setState({endDateMonth, endDateYear}, () => {
                                onDone(this.getUpdatedStructure())
                            })
                        }}
                      />

                  </Col>
                }
            </Row>

        </Grid>
    }
}

export default ExperiencePositionHistory;