/** ============================================ µ
 * @description Async Action Button [JSX]
 *              UI Common Component
 * @createdAt   2021-05-20
 * @updatedAt   2021-05-20
 * ============================================ */
/* IMPORTS ==================================== */

import { RaisedButton } from "material-ui";
import React from "react";
import Core from "../../lib/Core";
import loader from "../../assets/images/loader.gif";

/* METHODS ==================================== */

/**
 * 
 * @param {object} props 
 * @param {string} props.label
 * @param {promise} props.onClick 
 * @returns 
 */
function AsyncActionButton(props) {
  const {
    label = "Async Action",
    onClick: onClickPromise = async (event) => { },
    disabled
  } = props;
  const _props = { ...props };
  ['onClick', 'disabled'].forEach(n => (delete _props[n]));
  const [busy, setBusy] = React.useState(false);
  async function onClickButton(event) {
    setBusy(true);
    try { await onClickPromise(event); }
    catch (ex) {
      Core.showMessage(`Unexpected failure`);
      console.error(ex);
    }
    setBusy(false);
  }
  const _label = (
    busy
      ? <>Wait... <img alt="loading..." height="14" src={loader} /></>
      : label
  );
  const _disabled = (
    disabled || busy
  );
  return (
    <RaisedButton
      className="center"
      primary
      fullWidth
      {..._props}
      label={_label}
      disabled={_disabled}
      onClick={onClickButton}
    />
  );
}

/* EXPORTS ==================================== */

export {
  AsyncActionButton as default,
  AsyncActionButton,
}

/* ============================================ */
