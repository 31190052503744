import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";

import Checkbox from "material-ui/Checkbox";
import ContentAdd from "material-ui/svg-icons/content/add";
import FloatingActionButton from "material-ui/FloatingActionButton";
import FlatButton from "material-ui/FlatButton";
import Snackbar from "material-ui/Snackbar";

import Core, { colors } from "../../lib/Core";
import Candidate from "../../lib/Candidate";
import FilterControl from "../FilterControl/FilterControl";
import List from "../List/List";
import CandidateCard from "./Card/CandidateCard";
import onReady from "../../lib/tools/onReady";
import Store from "../../lib/Store";
import queryString from 'query-string';
import {fieldsForCandidatePage, accountIncludeForCandidate} from "../../lib/Constants";
import CandoDuplicateDetails from "./CandoDuplicateDetails";

class Candidates extends Component {
    data;
    constructor() {
        super(...arguments);
        this.name = "Candidates";
        this.state = {
            showAll: false,
            snackBarMessage: "",
            snackBarOpen: false,
            allCandidates: []
        };
        Store.set("path", window.location.href);
        this.reloadData = ev => this.loadData();
        this.loadData();

        //for rake task
        this.founded = 0;
        this.notFounded = 0;
        this.passed = 0;
    }

    runOnOneCandidate =  (candidate) => () => {
        // let resumes = candidate.resumes;
        // let firstCv = !!resumes.length ? resumes[0]:null;
        // let that=this;
        // if(!!firstCv) {
        //     Candidate.getPdfUrl({url:firstCv.url, filename:`id_${candidate.id}`},(res) => {
        //         let resumePdfUrl = res.url;
        //         let resumeTxtUrl = res.urlTxt;
        //
        //         Candidate.update(candidate.id, {resumePdfUrl,resumeTxtUrl}, () => {
        //             this.passed+=1;
        //             //console.log({founded:this.founded,notFounded:this.notFounded,passed:this.passed});
        //         });
        //
        //     })
        // }

    }

    componentDidMount() {
        this.setState({queryParams:queryString.parse(this.props.location.search)});
    };

    jsonEncode = (object)=>{
        return JSON.stringify(object).replace("{","%7B").replace("}","%7D").replace(":","%3A")
    };

    rakeCandidatesResume = (candidates) => {
        // let candWithResume = candidates.filter(c =>!!c.resumes.length);
        // let candWithAnon = candidates.filter(c => /anonumous/.test(c.resumePdfUrl)||/anonumous/.test(c.resumeTxtUrl));

        // let candWithoutResume=candidates.filter(c =>!c.resumes.length);
        // let candNotProcessed=candWithResume.filter(c =>!c.resumePdfUrl || /anonumous/.test(c.resumePdfUrl) || /anonumous/.test(c.resumeTxtUrl));
        // let candDevBucket=candWithResume.filter(c => c.resumePdfUrl && /to-pdf-dev/.test(c.resumePdfUrl) );

        // this.founded=candWithResume.length;
        // let index = 0;

        // candNotProcessed.forEach(candidate=>{
        //     index +=1;
        //     setTimeout( this.runOnOneCandidate(candidate),2000*index)
        // })

        // index=0;
        // candDevBucket.forEach(candidate=>{
        //     index +=1;
        //     setTimeout( this.runOnOneCandidate(candidate),2000*index)
        // })

        // let index = 0;
        // candidates.filter(cand=>(!cand.techSkillsInNotes.length || !cand.techSkillsInResume.length)
        //     ).forEach(cand => {
        //     index++;
        //     setTimeout( ()=>{
        //         Candidate.update(cand.id, cand,(response) => {
        //             //console.log(index +" out of "+ candidates.length +" are done !!!!");
        //         });
        //     }, 2000*index);
        // });
        //


    }

    deleteCandidateFromCollection = (candId) => {
        const {allCandidates} = this.state;
        const candidatesWithDeleted = allCandidates.filter(candidate => candidate.id !== candId);
        this.setState({allCandidates: candidatesWithDeleted}, () => {
            this.FilterControl && this.FilterControl.setItems(candidatesWithDeleted);
        });
    }

    loadData() {
        onReady(this, "FilterControl").then(em => {
            let params = queryString.parse(this.props.location.search)

            let filters = params.count ? {filters:
                {include: [
                        { engagements: { job: "employer" } }
                    ]
                }
            } : {
                filters: {
                    fields: fieldsForCandidatePage
                }
            };
            if(params.rake){
                this.rakeCandidatesResume([]);
            }
            this.setState({params});
            if (this.state.showAll) {
                params= {
                    include: [
                        ...accountIncludeForCandidate,
                        // { engagements: { job: "employer" } },    // not required for card information, on expand, it calls for engagements
                        {
                          relation: "candidateStarreds",
                          scope: {
                            where: { accountId: Core.getUserId() }
                          }
                        }
                    ],
                    fields: fieldsForCandidatePage
                };
                Candidate.getAll(candidates => {
                    this.setState({allCandidates: candidates}, () => {
                        this.FilterControl && this.FilterControl.setItems(candidates);
                    });
                },params,true);
            } else {
                Candidate.getActiveCandidates(candidates => {
                    this.setState({allCandidates: candidates}, () => {
                        this.FilterControl && this.FilterControl.setItems(candidates);
                    });
                },filters);

            }
        });
    }
    showMessage = msg => {
        this.setState({
            snackBarMessage: msg,
            snackBarOpen: true
        });
    };
    hideMessage = () => {
        this.setState({
            snackBarMessage: "",
            snackBarOpen: false
        });
    };
    render() {
        const queryParams = this.state.queryParams||{};

        return !!queryParams.viewDup && Core.isAdmin() ? <Fragment><CandoDuplicateDetails candidateId={queryParams.cId}/></Fragment> : <Fragment>
                <FilterControl
                    ref={FilterControl => (this.FilterControl = FilterControl)}
                    parent={this}
                    menus={Candidate.menus}
                    more={Candidate.more}
                    onChange={(filtered,filter) => {
                        console.log({filter});
                        if(!!Object.keys(filter).length){
                        }
                        this.List.setItems(filtered);
                    }}
                    source={'Candidates'}
                    toolBarLeft={
                        Core.isAdminOrCoordinator() && (
                            <Checkbox
                                label="Show All"
                                className="show-all"
                                checked={this.state.showAll}
                                onCheck={(ev, showAll) => {
                                    this.List && this.List.restart();
                                    this.setState({ showAll });
                                    setTimeout(st => this.loadData());
                                }}
                            />
                        )
                    }
                    toolBarRight={
                        <NavLink exact to="/candidate/create" className="ui-m-min">
                            <FlatButton label="+ New Candidate" className="list-add-new" />
                        </NavLink>
                    }
                />
                <List
                    ref={List => (this.List = List)}
                    tabs={
                        Core.isRecruiter()
                            ? Candidate.listTabsRecruiters
                            : Candidate.listTabs
                    }
                    tab={Candidate.listTab}
                    name="Candidate"
                    card={CandidateCard}
                    params={this.state.params}
                    source={"candidates"}
                    parent={this}
                    floatingButton={
                        <NavLink className="ui-m-max" exact to={`/candidate/create`}>
                            <FloatingActionButton
                                className="list-floating-button"
                                backgroundColor={colors.cyan}
                            >
                                <ContentAdd />
                            </FloatingActionButton>
                        </NavLink>
                    }
                />
                <Snackbar
                    open={this.state.snackBarOpen}
                    message={this.state.snackBarMessage}
                    className="snack-bar"
                    autoHideDuration={7000}
                    onRequestClose={this.hideMessage}
                />
        </Fragment>
    }
}

export default Candidates;
