/** ============================================ µ
 * @description Multiple Select Dropdown [JSX]
 *              UI Component
 * @createdAt   2021-06-02
 * @updatedAt   2021-11-17 Wed
 * ============================================ */
/* IMPORTS ==================================== */

import { Select } from "antd";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";

/* CONSTANTS ================================== */

const { Option } = Select;

/* METHODS ==================================== */

function MultipleSelectDropdown({
  name,
  placeholder = 'Select multiple items',
  data = [],
  value,
  multiple = 'multiple',
  onChange = () => null,
  onSearch = () => null,
  style = {},
  className = '',
  dropdownMatchSelectWidth,
  optionLabelProp
}) {

  const [prev, setPrev] = useState();

  function useDropdownVisibleChange(open) {
    return {
      _onDropdownVisibleChange: open => open && onSearch(''),
    }
  }
  const { _onDropdownVisibleChange } = useDropdownVisibleChange();

  useEffect(() => {
    if (value !== prev) {
      _onDropdownVisibleChange(true);
      setPrev(value);
    }
  }, [_onDropdownVisibleChange, data, value, prev]);

  const children = data.map(({ id, label }, index) => (
    <Option
      key={`option+${name}.${id}(required-by-react)`}
      value={id}
      label={label}
    >
      {label}
    </Option>
  ));

  // console.debug({ data, value });

  function _onChange(value) {
    console.debug('onChange', value);
    onChange(value);
  }

  function _onSearch(value) {
    console.debug('onSearch', value);
    onSearch(value);
  }

  return (
    <Select
      showSearch
      mode={multiple}
      allowClear
      style={{ width: "100%", textAlign: "left", ...style }}
      className={className}
      placeholder={placeholder}
      optionLabelProp={optionLabelProp || 'label'}
      value={value}
      onChange={_onChange}
      onSearch={_onSearch}
      onDropdownVisibleChange={_onDropdownVisibleChange}
      optionFilterProp="children"
      filterOption={(input, option) => {
        if (typeof option.children === 'string') {
          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      }}
      maxTagCount="responsive"
      disabled={false}
      virtual={false}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      dropdownStyle={{ borderRadius: 4, zIndex: 1301 }}
      notFoundContent={<span>No options</span>}
    >
      {children}
    </Select>
  )
}

/* EXPORTS ==================================== */

export {
  MultipleSelectDropdown as default,
  MultipleSelectDropdown
};

/* ============================================ */
