import React, { Component, Fragment } from "react";

import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";

import Core from "../../lib/Core";
import Debug from "../../lib/Debug";
import loader from "../../assets/images/loader.gif";
import { Table, TableBody, TableRow, TableRowColumn } from "material-ui/Table";

/**
 * THIS LIST IS FOR MATCHES SCREENS
 * this list handle 3 sublists
 * - engaged
 * - selected
 * - normal list
 */
class List extends Component {
  length = 0;
  data = [];
  engaged = [];
  selected = [];
  cards = {};

  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      engaged: [],
      selected: [],
      collection: [],
      tab: this.props.tab || "Recent",
      index: 0,
      highlightSrengthFlag: false,
      busy: true
    };

    this._mlOn = false;
  }

  highlightSrengthFlag = ()=>{
    this.setState({highlightSrengthFlag:true},()=>(this.loadData()));

  };

  setItems(data) {
    this.data = [];
    data.forEach(item => {
      if (this.parent.state.engaged[item.id]) {
      } else {
        this.data.push(item);
      }
    });
    this.loadData();
  }
  setEngaged(data) {
    this.engaged = [{ id: Core.getKey(), header: "Current Engagements" }];
    data.forEach(item => {
      if (this.parent.state.engaged[item.id]) {
        this.engaged.push(item);
      }
    });
  }
  setSelected(data,permittedJobs) {
    data.forEach(item => {
      if (permittedJobs.includes(item.id) && !this.selected.map(item=>item.id).includes(item.id)) {
        this.selected.push(item);
      }
    });
  }

  clearSelected(data,permittedJobs) {
    this.selected = [];
  }

  restart() {
    this.setState({
      collection: [],
      index: 0,
      busy: true
    });
  }
  updateItem = item => {
    this.data = this.data.map(current => {
      if (current.id === item.id) {
        current = { ...current, ...item };
      }
      return current;
    });
    // this.loadData();
  };


  loadData(tab) {
    this.selectTab(tab || this.state.tab);
  }
  selectTab(tab) {
    Debug.time("Render List");
    this.setState({ tab }, then => {
      this.groupBy(tab);
    });
  }
  groupBy(filter) {
    var items = {};
    // organice data by filter key
    this.data.forEach(item => {
      var header = item.filters[filter];
      if (filter !== undefined && filter !== null) {
        if (filter === "Recent") {
          header = Core.getFromNow(header);
        }
        items[header] = items[header] || [];
        if (filter === "Starred") {
          if (item.filters["Starred"] === "Starred") {
            items[header].push(item);
          }
        } else {
          if (header !== false) {
            items[header].push(item);
          }
        }
      }
    });
    // sort filter keys and set collection
    this.setCollection(
      filter === "Stage"
        ? [
            "End",
            "Hire",
            "Guarantee",
            "Offer",
            "Onsite",
            "Screen",
            "Review",
            "Submission",
            "Confirmation",
            "Pending"
          ]
        : filter === "Starred"
          ? ["Starred", "Non Starred"]
          : filter === "Recent"
            ? Object.keys(items).sort((a, b) => {
                a = items[a][0].filters.Recent;
                b = items[b][0].filters.Recent;
                return a < b ? 1 : -1;
              })
            : Object.keys(items)
                .sort((a, b) => {
                  return a.toUpperCase() < b.toUpperCase() ? 1 : -1;
                })
                .reverse(),
      items,
      filter
    );
  }
  setCollection(headers, items, filter) {
    let collection = [];
    // push filtered data in result
    headers.forEach(header => {
      if (items[header]) {
        // create header
        if (filter !== "Name" && !/starred/i.test(filter)) {
          /** */
          collection.push({
            id: Core.getKey(),
            header
          });
          /** */
        }
        collection = collection.concat(items[header]);
      }
    });
    // update the collection
    this.length = 0;
    this.getCardCollection(this.engaged,null, 'engaged').then(engaged => {
        this.setState({engaged})
        this._mlOn = false
      }
    );
    this.getCardCollection(this.selected, null, 'selected').then(selected => {
      this.setState({selected})
      this._mlOn = false
    }
  );
    this.getCardCollection(collection, true, 'normal').then(collection => {
      this.setState({ collection });
      this._mlOn = false
      Debug.showTimeEnd("Render List");
      setTimeout(st => {
        this.setState({ busy: false });
      }, 2000);
    });
  }


  getCardCollection(collection, countLength, type) {
    return new Promise(resolve => {
      const Card = this.props.card;
      const externalRelationAssociation = this.props.externalRelationAssociation;

      collection = collection.map(model => {
        if (model.header) {
          return (
            <p key={model.id} className="group-header">
              {model.header}
            </p>
          );
        } else {
          countLength && this.length++;
          const isSelected = this.selected.map(o=>o.id).includes(model.id);

          return (
            <Card
              key={Core.getKey()}
              model={model}
              parent={this}
              parentModel={this.props.parent.state}
              source={this.props.source||"ListMatch"}
              mlOn={this._mlOn}
              engagement={this.parent.state.engaged[model.id]}
              checked={isSelected}
              candidateId={this.props.candidateId}
              externalRelationAssociation={externalRelationAssociation(model)}
              onCheck={(id, checked, card) => {
                let selected = [...this.selected];

                if (!checked) {
                  selected = selected.filter(item => item.id !== id);
                  this.cards[card.state.id].setState({ checked: false });
                } else {
                  this.cards[card.state.id] = card;
                  selected.push(card.state);
                }
                this.selected = selected;
                this.getCardCollection(this.selected).then(selected => {
                  this.props.onCheck(selected);
                  this.setState({ selected });
                });
              }}
            />
          );
        }
      });
      resolve(collection);
    });
  }

  render() {
    return (
      <Fragment>
        <button onClick={(e)=>{
          this._mlOn=true;
          this.loadData()
        }}>Calculate Score</button>
        <div className="list">
          <div className="sort-buttons">
            <div className="nowrap scroll-x align-right">
              {this.props.tabs.map(tab => (
                <FlatButton
                  key={`tab${tab}`}
                  className={`sort-button${
                    this.state.tab === tab ? " tab-selected" : ""
                  }`}
                  label={tab}
                  onClick={event => this.selectTab(tab)}
                />
              ))}
            </div>
          </div>
          <div className={"list-scroller"}>
            <Table style={{ marginBottom: 10 }}>
              <TableBody displayRowCheckbox={false}>
                <TableRow style={{backgroundColor:'lightgoldenrodyellow'}} >
                  <TableRowColumn style={
                    {
                      width: 36,
                      textAlign: "right",
                      paddingLeft: 0,
                      paddingRight: 0
                    }}
                  >
                  </TableRowColumn>

                  <TableRowColumn
                      title={`${this.props.parentModel._platformRating}|${this.props.parentModel.yearsOfExperience}|${this.props.parentModel._visa}|${this.props.parentModel.desiredSalary}|${this.props.parentModel._officeLocations}`}
                      className="first-item"
                      colSpan={3}
                  >
                    <p>Rating|Exp|Comp|location|visa</p>
                    {this.props.parentModel._platformRating}&nbsp;&nbsp;|&nbsp;&nbsp;
                    {this.props.parentModel.yearsOfExperience}&nbsp;&nbsp;|&nbsp;&nbsp;
                    {this.props.parentModel.desiredSalary}&nbsp;&nbsp;|&nbsp;&nbsp;
                    {this.props.parentModel._officeLocations}&nbsp;&nbsp;|&nbsp;&nbsp;
                    {this.props.parentModel._visa}&nbsp;&nbsp;|&nbsp;&nbsp;
                  </TableRowColumn>

                  <TableRowColumn
                        title={this.props.parentModel._technicalSkills}
                        className="mid-col"
                        colSpan={2}
                    >
                    <p>Skills</p>
                    {this.props.parentModel._technicalSkills}
                  </TableRowColumn>
                  <TableRowColumn
                        title={this.props.parentModel._technicalSkills}
                        className="mid-col"
                    >
                    <p>Tech Stack</p>
                    <p>N/A</p>
                  </TableRowColumn>
                  <TableRowColumn
                      title={this.props.parentModel._technicalSkills}
                      className="mid-col"
                  >
                    <p>Others</p>
                    <p>N/A</p>
                  </TableRowColumn>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div
            ref={scroller => (this.scroller = scroller)}
            className="list-scroller"
          >
            {this.state.engaged}
            {!!this.state.selected.length && (
              <Fragment>
                <p className="group-header">Selected Items</p>
                {this.state.selected}
              </Fragment>
            )}
            {!!this.state.collection.length
              ? this.state.collection
              : !!this.state.busy
                ? [
                    <div
                      key={Core.getKey()}
                      className="not-found inline-blocks"
                    >
                      Loading&nbsp;
                      <img alt="loading..." height="14" src={loader} />
                    </div>
                  ]
                : [
                    <div key={Core.getKey()} className="not-found">
                      No matches
                    </div>
                  ]}
          </div>
          {!!this.length && (
            <div className="padding-side padding-top align-left cgray fnormal">
              {this.length} matches, {this.engaged.filter(el=> !el.header).length} engaged,{" "}
              {this.selected.length} selected.
            </div>
          )}
          {this.length > 4 && (
            <div className="blocks fixed-bottom-right">
              <IconButton
                className="icon24"
                onClick={ev => this.scroller && this.scroller.scrollTo(0, 0)}
              >
                <i className="material-icons">expand_less</i>
              </IconButton>
              <IconButton
                className="icon24"
                onClick={ev => {
                  this.scroller &&
                    this.scroller.scrollTo(0, this.scroller.scrollTop + 500);
                }}
              >
                <i className="material-icons">expand_more</i>
              </IconButton>
            </div>
          )}
          {this.props.floatingButton}
        </div>
      </Fragment>
    );
  }
}

export default List;
