/** ============================================ µ
 * @description JobCard [JS]
 *              UI Component
 * @path        /jobs
 * @updatedAt   2021-08-12 Thu
 * ============================================ */

/* IMPORTS ==================================== */

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { RadioButton, RadioButtonGroup } from 'material-ui';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import Checkbox from 'material-ui/Checkbox';
import Divider from 'material-ui/Divider';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import withImportantStyle from 'react-with-important-style';
import loader from '../../../assets/images/loader.gif';
import Candidate from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import Definition, {
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID,
} from '../../../lib/Definition';
import LocationLib from '../../../lib/DefinitionLocation.lib';
import Engagement from '../../../lib/Engagement';
import Job from '../../../lib/Job';
import { hoverDisplay } from '../../../lib/models/engagement';
import {
  extended as extendedModel,
  model as jobModel,
} from '../../../lib/models/job';
import Store from '../../../lib/Store';
import cleanHTML from '../../../lib/tools/cleanHtml';
import copyHTML from '../../../lib/tools/copyHtml';
import formatURL from '../../../lib/tools/formatURL';
import getEngagementsByState from '../../../lib/tools/getEngagementsByState';
import RenderEngagementStructure from '../../../lib/tools/renderEngagementStructure';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';
import CreateDisagreement from '../../Disagreements/createWithPopup';
import ModalUpdateStatus from '../../Engagements/Card/ModalUpdateStatus';
import PrintMlScore from '../../Shared/PrintMlScore';
import ResumeMatch from '../../Shared/ResumeMatch';
import LocationSection from './LocationSection';
import ModalDelete from './ModalDelete';

/* CONSTANTS ================================== */

const MySpan = withImportantStyle('span');

/* METHODS ==================================== */

class JobCard extends Component {
  references = {};
  timeout;
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      /** model */
      ...extendedModel,
      /** controller states */
      expanded: false,
      openedColor: null,
      CardStyle: null,
      rightArrow: {},
      checked: this.props.checked || false,
      boxes: [],
      /** load */
      ...this.props.model,
      engagement: this.props.engagement,
      index: this.props.index,
      blacklisted: '',
      engagements: this.props.model.engagements || [],
      recruiterJobIds:
        !!this.props.extraInfo && !!this.props.extraInfo.recruiterJobIds
          ? this.props.extraInfo.recruiterJobIds
          : [],
      engagementStructure: [],
      engagementStructureLoader: true,
    };

    // if(this.state.id ==='5b4535384cf4b370cff1bf4b'){
    //   debugger
    // }
    if (this.props.checked) {
      this.parent.cards[this.state.id] = this;
    }

    if (this.parent.parent.name === 'CandidateMatch') {
      const recruiter = this.parent.parent.state.recruiter;
      setTimeout((st) => {
        if (
          !!this.state.jobBlackList.find(
            (blacklisted) => blacklisted.id === recruiter.id
          ) ||
          !!this.state.employer.employerBlackList.find(
            (blacklisted) => blacklisted.id === recruiter.id
          )
        ) {
          this.setState({
            blacklisted: <b style={{ display: 'block' }}>Blacklisted</b>,
            isBlacklisted: true,
          });
        }
      }, 100);
    }

    this.lookForSearchSection(1000);
  }

  componentDidMount() {
    this.lookForSearchSection(500);
  }

  /**
   * Look into location for a search string
   *
   * then scroll into view
   *
   * if match search string with
   *
   * some JobCard element reference
   *
   * ex. /job/view/:id?insider-scoop
   * ex. insider-scoop | interview-process
   */
  lookForSearchSection = (delay = 100) => {
    const { parent: ListController = {} } = this.props;
    const { parent: JobsController = {} } = ListController.props;
    const { location = {} } = JobsController.props;
    const { search = '' } = location;
    const { state } = this;
    const { id: jobId } = state;

    const section = search.slice(1).toLowerCase();

    if (!!jobId && !!section.trim().length) {
      setTimeout(() => {
        const sectionAnchor = this.references[section];

        /** µ FOR DEBUG PURPOSES * /
        console.debug('µ:JobCard::componentDidMount', { section, sectionAnchor }, this.references);
        /** */

        if (!!sectionAnchor) {
          sectionAnchor.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      }, delay);
    }
  };

  //may be used when clicked in card and need to show data in expanded
  fetchEngagements = (cb) => {
    Engagement.getWhere({ jobId: this.state.id }, (res) => {
      this.setState(
        {
          engagements: res,
        },
        () => {
          !!cb && cb(res);
        }
      );
    });
  };

  isRecruiterHasAssignedThisJob = () => {
    const { recruiterJobIds, id } = this.state;
    const desiredObject = recruiterJobIds.find(
      (job) => job.jobId === id.toString()
    );
    let formatDate = '';

    if (!!desiredObject && 'jobId' in desiredObject) {
      formatDate = new Date(desiredObject.startDate);
      formatDate = `${formatDate.getMonth() + 1
        }/${formatDate.getDate()}/${formatDate.getFullYear()}`;
      return (
        <span>
          <label style={{ color: 'white', backgroundColor: 'green' }}>
            My Sourcing
          </label>
          &nbsp;&nbsp;({formatDate})
        </span>
      );
    }
    return null;
  };

  profiles = () => {
    let ret = {};
    let { engagements } = this.state;

    ret.no = () =>
      engagements.filter(
        (el) => /review/i.test(el.stage) && /e -/i.test(el.status)
      );

    ret.yes = () => {
      let filtered = engagements.filter(
        (el) => !/confirmation|submission|review/i.test(el.stage)
      );
      let hash = {
        Screen: 1,
        Onsite: 2,
        Offer: 3,
        Guarantee: 4,
        Hire: 5,
        End: 6,
      };

      filtered.sort((a, b) => {
        return (
          new Date(b.submitted).getTime() * hash[b.stage] -
          new Date(a.submitted).getTime() * hash[a.stage]
        );
      });

      return filtered;
    };

    ret.mayBe = () =>
      engagements.filter(
        (el) =>
          /review/i.test(el.stage) && /w - employer feedback/i.test(el.status)
      );

    ret.summary = () => {
      let onsite = engagements.filter((el) =>
        /onsite|offer|guarantee|hire|end/i.test(el.stage)
      );
      let screens = engagements.filter((el) => /screen/i.test(el.stage));
      let reviewed = [...ret.yes(), ...ret.no()];
      let submitted = [...reviewed, ...ret.mayBe()];

      return (
        <Fragment>
          <p>
            {submitted.length} submitted | {reviewed.length} reviewed |{' '}
            {screens.length} screens & after (
            {parseInt(
              ((screens.length + onsite.length) * 100) / reviewed.length
            )}
            %) | {onsite.length} onSites & after (
            {parseInt((onsite.length * 100) / reviewed.length)}%)
          </p>
        </Fragment>
      );
    };

    const printSingle = (eng) => {
      let update = eng;
      let candidate = eng.candidate;
      let isRejected = eng.state === 'Closed';
      let rejectedLabel = isRejected ? 'REJECTED' : '';
      let isAliveLabel = isRejected ? '' : '*';
      let rejectionReasonDetails = [
        eng.rejectionReason,
        eng.rejectionReasonAdditionalInfo,
      ]
        .filter((el) => !!el)
        .join(' - ');
      return cleanHTML(
        '<p>' +
        isAliveLabel +
        String(eng.stage) +
        ' | ' +
        String(eng.status) +
        ' | ' +
        [
          [
            moment(update.submitted).format('MM/DD/YY'),
            `<a href="/#/candidate/edit/${candidate.id}" target="_blank">${candidate._name}</a>`,
            Definition.getLabel('platformRating', candidate.platformRating),
          ]
            .filter((e) => !!e && !!String(e).trim().length)
            .join(', '),
          Definition.getLabels(
            'positiveSignals',
            candidate.positiveSignals
          ).join(', '),
          Definition.getLabels(
            'negativeSignals',
            candidate.negativeSignals
          ).join(', '),
          candidate.tagLine,
        ]
          .filter((e) => !!e && !!String(e).trim().length)
          .join('; ') +
        '<strong> - ' +
        rejectedLabel +
        '</strong>' +
        '.</p>' +
        '<p style="font-size:11px;">' +
        rejectionReasonDetails +
        '</p>'
      );
    };

    ret.print = (array) => {
      return array.map((el) => printSingle(el)).join('');
    };

    return ret;
  };

  unexpandCard = (event) => {
    this.setState({
      expanded: false,
      CardStyle: null,
      openedColor: null,
      rightArrow: { transform: 'rotate(0deg)' },
    });
  };

  updateField = (engagement, field, eventStatus, callback) => {
    if (Core.isAdminOrCoordinator()) {
      if (engagement) {
        Engagement.update(
          engagement,
          field,
          (res) => {
            this.setState({ engagement: res });
          },
          () => alert('failed to save')
        );
      } else {
        //create engagement with specific data and close it
        !!this.props.externalRelationAssociation &&
          this.props.externalRelationAssociation(field, eventStatus, callback);
      }
    }

    this.setState({ pickedMatchedStrength: null });
  };

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevState.open !== this.props.open) {
    }
  }

  expandCard = (event) => {
    const { engagementStructure } = this.state;

    if (
      Core.isAdmin() &&
      !!Object(this.props.extraInfo).displayEngagements &&
      !engagementStructure.length
    ) {
      Engagement.getStructure(
        `?groupBy=recruiter&gid=${this.state.id}`,
        (response) => {
          this.setState({
            engagementStructure: response.res,
            engagementStructureLoader: false,
          });
        }
      );
    }

    this.fetchEngagements();
    if (this.props.candidateId) {
      //this.mlGetMatchingScores();
    }
    this.setState(
      {
        expanded: true,
        CardStyle: { margin: '10px auto' },
        openedColor: { color: '#715EFF' },
        rightArrow: { transform: 'rotate(180deg)' },
      },
      (then) => Core.log({ jobCardState: this.state })
    );
    /** * /
    TODO remove on cleanup ticket VER-266
    - jobBlackList is included from query
    **
    Job.getBlackList(this.state.id, jobBlackList => {
      this.setState({ jobBlackList }, then => Core.log(this.state));
    });
    /** */
  };
  handleToggle = (event) => {
    this.state.expanded ? this.unexpandCard() : this.expandCard();
  };
  onCheckStar = (ev, checked) => {
    this.unexpandCard();
    const updateLocalList = (response) => {
      const state = {
        id: this.state.id,
        starredId: response.id,
        starred: response.starred,
        filters: {
          ...this.state.filters,
          Starred: ['Non Starred', 'Starred'][~~Boolean(response.starred)],
        },
      };
      this.setState(state);
      this.parent.updateItem(state);
    };
    Job.updateStarred(
      this.state.id,
      this.state.starredId,
      checked,
      updateLocalList
    );
  };
  getEditJobModel() {
    const newJobModel = {};
    Object.keys(jobModel).forEach((key) => {
      if (
        typeof this.state[key] === 'boolean' ||
        key === 'visaTransfer' ||
        !!this.state[key]
      ) {
        newJobModel[key] = this.state[key];
      }
    });
    delete newJobModel.id;
    newJobModel.state = Definition.getId('state', 'Draft');
    Core.log({ newJobModel });
    return newJobModel;
  }
  /**
   * CLONE OR DUPLICATE
   */
  cloneJob = (ev) => {
    Job.post(this.getEditJobModel(), (response) =>
      Core.go({ ...this.props, to: `/job/edit/${response.id}` })
    );
  };
  getPreview = (type) => Job.getPreview(this.state, type);

  renderAdditionalNotes = () => {
    const { mustHaveQuestions } = this.state;

    let collection = [
      //        {key:'additionalNotes',limited:true,label:'Must Have',visible: (!!this.state.additionalNotes && !!!this.state.candidateMust)},
      {
        key: 'candidateMust',
        limited: true,
        label: 'Must Have',
        visible: !!this.state.candidateMust,
      },
      {
        key: 'candidateMustNot',
        limited: true,
        label: 'Must NOT Have',
        visible: !!this.state.candidateMustNot,
      },
      {
        key: 'candidatePositives',
        limited: true,
        label: 'Strongly Preferred (Positives)',
        visible: !!this.state.candidatePositives,
      },
      {
        key: 'candidateNegatives',
        limited: true,
        label: 'Unlikely to Hire (Negatives)',
        visible: !!this.state.candidateNegatives,
      },
    ];

    const collectionHtml = collection.map((item, index) => {
      return (
        item.visible && (
          <div
            key={`collectionHtml-${index}`}
            ref={(target) => (this[`${item.key}View`] = target)}
          >
            <CardHeader title={<>{item.label}</>} />
            <CardText className="contents">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state[item.key],
                }}
              />
            </CardText>
          </div>
        )
      );
    });

    if ((mustHaveQuestions || []).length) {
      collectionHtml.push(
        <div
          key={collectionHtml.length}
          ref={(target) => (this[`jobQuestionsView`] = target)}
        >
          <CardHeader
            title={
              <>IMPORTANT: Must Answer Pre-screen Questions for Submission</>
            }
          />
          <CardText className="contents">
            <div>
              <ul>
                {(mustHaveQuestions || []).map((q) => (
                  <li>{q.question}</li>
                ))}
              </ul>
            </div>
          </CardText>
        </div>
      );
    }

    return collectionHtml;
  };

  modalUpdateStatusDefaultValues = (selectedStrength) => {
    const cbUpdateField = this.updateField;

    if (!!this.state.engagement) {
      let matchStrength =
        this.state.pickedMatchedStrength || this.state.engagement.matchStrength;
      return {
        ...this.state.engagement,
        engagement: this.state.engagement,
        matchStrength,
        cbUpdateField,
      };
    } else {
      if (!selectedStrength) {
        selectedStrength = this.myDefaultMatchStrength();
      }
      return {
        stage: 'Confirmation',
        state: 'Closed',
        status: 'E - 10x10',
        rejectionReason: '10x10 - No Match',
        matchStrength: selectedStrength,
        cbUpdateField,
      };
    }
  };

  matchStrengthHandler = (event, selected) => {
    if (this.state.isBlacklisted) {
      alert("Job is blacklisted, can't create engagement of it");
      return;
    }

    // let field = {matchStrength:selected, matchedByWho: Core.getUserId()};
    this.setState({ pickedMatchedStrength: selected }, () => {
      // if(selected === "F - Strong Mismatch") {
      //   field = {...field, rejectionReason: '10x10 - Bad Match', state: 'Closed'};
      // }

      if (
        this.state.engagement ||
        Engagement.negativeStrengths().includes(selected)
      ) {
        // if (this.state.engagement.state === 'Closed' && selected !== 'F - Strong Mismatch') {
        //   //mismatch to match
        //
        // }else if(this.state.engagement.state === 'Open' && selected === 'F - Strong Mismatch'){
        //   //match to mismatch
        //
        // } else {
        //   Engagement.update(this.state.engagement, field, (res) => {
        //     this.setState({engagement: res});
        //   }, () => (alert('couldn\'t save')));
        // }

        this.refs.modalUpdateStatus.open(
          this.modalUpdateStatusDefaultValues(selected)
        );
      } else {
        //store in memory to use it at later stage
        Store.set(
          Engagement.getCacheKeyJobMatch(this.props.candidateId, this.state.id),
          selected
        );
      }
    });
  };

  renderJobEngagementLabel = () => {
    let color = '';
    let eng = this.state.engagement;
    let strengthLabel = '';

    if (eng) {
      if (eng.state === 'Open') {
        color = 'green';
      } else if (eng.state === 'Closed') {
        color = 'red';
      }

      if (!!eng.matchStrength) {
        try {
          strengthLabel = Definition.get('engagementMatchStrength').find(
            (obj) => obj.id === eng.matchStrength
          ).label;
        } catch (e) { }
      }
    }

    let mlLabel = 'ML-Unknown';
    if (!!eng.useForMlTraining) {
      let hash = {
        true: 'ML-Positive',
        false: 'ML-Negative',
        unknown: 'ML-Unknown',
      };
      mlLabel = hash[eng.useForMlTraining];
    }

    return eng ? (
      <b style={{ display: 'block' }}>
        <MySpan
          style={{ color: `${color} !important`, fontSize: '10px !important' }}
        >
          {eng.matchStrength && strengthLabel + ' | '}
          {eng.stage + ' | '}
          {eng.status + ' '}
          {eng.rejectionReason && ` | ${eng.rejectionReason} `}({eng.state}) |{' '}
          {mlLabel} |
          <CreateDisagreement
            engagement={eng}
            candidate={this.props.parentModel}
            job={this.state}
            employer={this.state.employer}
            decision={
              eng.useForMlTraining === 'true' ||
                (eng.useForMlTraining === 'unknown' &&
                  eng.stage === 'Confirmation')
                ? 'false-positive'
                : 'false-negative'
            }
            fromAnnotator={
              !!eng.matchedByWho ? `${eng.matchedByWho.email}` : ''
            }
          />
        </MySpan>
      </b>
    ) : (
      ''
    );
  };

  myDefaultMatchStrength = () => {
    let unspecified = Engagement.unSpecifiedStrength();
    let unspecifiedId = '';

    if (!!unspecified) {
      unspecifiedId = unspecified.id;
    }

    const fromCache = !this.props.engagement
      ? Store.get(
        Engagement.getCacheKeyJobMatch(this.props.candidateId, this.state.id)
      )
      : null;
    return (
      this.state.pickedMatchedStrength ||
      fromCache ||
      (this.state.engagement
        ? this.state.engagement.matchStrength
          ? this.state.engagement.matchStrength
          : unspecifiedId
        : unspecifiedId)
    );
  };

  getHighlightStyle = (key) => {
    const record = {
      warning: { backgroundColor: 'yellow' },
    };
    return record[key];
  };

  handlerForceMlOn = () => {
    this.setState({ forceMlOn: true });
  };

  staticRowDisplayColor = () => {
    let obj = {};
    let candidate = this.props.parentModel;
    let job = this.state;

    obj.getSalaryColor = ((candidate, job) => () => {
      if (!candidate.minimumSalary || !job.salaryMax) {
        return '';
      }

      let color;

      if (candidate.minimumSalary <= job.salaryMax) {
        color = 'green';
      } else if (candidate.minimumSalary <= 1.15 * job.salaryMax) {
        color = 'grey';
      } else if (candidate.minimumSalary <= 1.4 * job.salaryMax) {
        color = 'grey';
      } else {
        color = 'red';
      }

      return color;
    })(candidate, job);

    obj.getVisaColor = ((candidate, job) => () => {
      let menu = Candidate.menus.find((obj) => obj.key === 'visa');
      let myMappings = menu.mappings[job._visaTransfer] || [];
      return myMappings.includes(candidate._visa) ? 'green' : 'red';
    })(candidate, job);

    obj.getLocationColor = ((candidate, job) => () => {
      let hasMatched = job._locations.split(',').some((label) => {
        let regx = new RegExp(label, 'i');
        return regx.test(candidate._workLocationIds);
      });
      return hasMatched ? 'green' : 'red';
    })(candidate, job);

    obj.getYearsXp = ((candidate, job) => () => {
      let color = 'red';
      let diff =
        parseFloat(job.minYearsOfExperience) -
        parseFloat(candidate._yearsOfExperienceForCalc);
      let diffPercent = diff / parseInt(job.minYearsOfExperience);

      if (diff <= 0) {
        color = 'green';
      } else if (diff > 0 && diffPercent <= 0.4) {
        color = 'grey';
      }

      return color;
    })(candidate, job);
    return obj;
  };

  staticRowDisplayTag = (jobAtr, candAtr, color) => {
    if (!jobAtr || !candAtr) {
      return jobAtr;
    }

    return (
      <MySpan
        style={{
          backgroundColor: `${color} !important`,
          color: 'white !important',
          padding: '5px !important',
        }}
      >
        {jobAtr}
      </MySpan>
    );
  };

  handlerPopup = () => {
    this.setState({
      mustHavesDialog: true,
    });
    this.fetchEngagements();
  };

  renderRecruiterNames = () => {
    const { extraInfo } = this.props;
    const names =
      !!extraInfo && !!extraInfo.jobIdHashedWithAccount
        ? extraInfo.jobIdHashedWithAccount
        : '';
    if (!!names.length) {
      return <label>{names}</label>;
    }
    return null;
  };

  render() {
    const JobCardController = this;

    const { t } = this.props;

    const { state } = this;

    // const { id: jobId } = state;

    const job = state;

    const {
      salaryNote,
      inOfficeRemoteFlags = [],
      officeLocations = [],
    } = state;

    let _officeLocationsLabels = '';
    if (
      inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID) ||
      inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
    ) {
      /* epic-3038(new locations)-story-3689-m2 | 2021-08-05 Thu µ */
      _officeLocationsLabels = LocationLib.getLocationsString({
        locations: officeLocations,
      });
    }

    const _locationsString = [
      inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID) &&
      'Remote',
      _officeLocationsLabels,
    ]
      .filter((v) => !!v)
      .join(' | ')
      .trim();

    var engaged = false;
    if (/match/i.test(this.parent.parent.name)) {
      engaged = this.props.onCheck && !!this.props.engagement;
    }

    const strengthMatchHighlight =
      !this.props.engagement &&
      this.state.checked &&
      !!Store.get(
        Engagement.getCacheKeyJobMatch(this.props.candidateId, this.state.id)
      ) &&
      Engagement.negativeStrengths().includes(
        Store.get(
          Engagement.getCacheKeyJobMatch(this.props.candidateId, this.state.id)
        )
      );

    const highlightRowWith =
      (strengthMatchHighlight && this.getHighlightStyle('warning')) || {};

    // if(this.props.triggerred){
    //     debugger
    // }
    const jobTitle_ = !!this.state._jobTitle ? (
      <Fragment>{this.state._jobTitle}</Fragment>
    ) : (
      <i>&mdash;</i>
    );
    const jobTitle = Core.isAdmin() ? (
      <NavLink
        title={`${job.jobTitle} | Job Title (hiring target): Go to Edit`}
        to={'/job/edit/' + this.state.id}
      >
        <b className="no-truncate" style={{ width: '100%' }}>
          {jobTitle_}
        </b>
      </NavLink>
    ) : (
      jobTitle_
    );
    const employerName = (
      <Fragment>
        {!!this.state.employer._name ? (
          <Fragment>
            {this.state.employer._name_rating}
            {this.state.employer.confidential
              ? ` (Confidential - DO NOT SOURCE)`
              : ''}
          </Fragment>
        ) : (
          <i>&mdash;</i>
        )}
      </Fragment>
    );
    const roleName = !!this.state.roles.length ? (
      Definition.getLabels('roles', this.state.roles).join('-')
    ) : (
      <i>&mdash;</i>
    );
    const recent = Core.getFromNow(this.state.filters.Recent);
    const recruiterSourceName = Core.isAdmin()
      ? this.renderRecruiterNames()
      : null;

    /** µ FOR DEBUG PURPOSES * /
    console.debug('µ:JobCard::render', { state });
    /** */

    function _onClickCopyJob(event) {
      event.stopPropagation();
      JobCardController.unexpandCard();
      copyHTML(JobCardController.getPreview('short'))
        .then((em) => {
          Core.log('Copy email command was successful');
          Core.showMessage(t('copy.successful'));
        })
        .catch((ex) => {
          Core.log('Oops, unable to copy');
          Core.showMessage('Fail copy!');
        });
    }

    return (
      <Fragment>
        <Card
          className="row-card job"
          expanded={this.state.expanded}
          style={{ ...highlightRowWith, ...this.state.CardStyle }}
        >
          {/** COLLAPSED VIEW */}

          <Table
            ref={(collapsedView) => (this.collapsedView = collapsedView)}
            className={`collapsed-view`}
            style={{ ...highlightRowWith, marginBottom: 10 }}
          >
            <TableBody displayRowCheckbox={false}>
              {/**
               * @todo
               * Review if is really in use for
               * CANDIDATE MATCH
               * 2021-08-12 Thu µ
               */}
              {this.props.source === 'candidateMatch' && (
                <TableRow
                  style={{ backgroundColor: 'lightcyan' }}
                  selectable={false}
                >
                  <TableRowColumn
                    className=""
                    style={{
                      width: 36,
                      textAlign: 'right',
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  ></TableRowColumn>
                  <TableRowColumn
                    title={`${this.state._employeeRating}|${this.state._yearsOfExperience}|${this.state._salaryRange}|${this.state._visaTransfer}|${this.state._location}`}
                    className="first-item"
                    colSpan={3}
                  >
                    {this.state._employeeRating}&nbsp;&nbsp;|&nbsp;&nbsp;
                    {this.staticRowDisplayTag(
                      this.state.minYearsOfExperience,
                      this.props.parentModel._yearsOfExperienceForCalc,
                      this.staticRowDisplayColor().getYearsXp()
                    )}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {this.staticRowDisplayTag(
                      this.state.salaryMax,
                      this.props.parentModel.minimumSalary,
                      this.staticRowDisplayColor().getSalaryColor()
                    )}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {this.staticRowDisplayTag(
                      this.state._locations,
                      this.props.parentModel._workLocationIds,
                      this.staticRowDisplayColor().getLocationColor()
                    )}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {this.staticRowDisplayTag(
                      this.state._visaTransfer,
                      this.props.parentModel._visa,
                      this.staticRowDisplayColor().getVisaColor()
                    )}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                  </TableRowColumn>
                  <TableRowColumn
                    title={this.state._technicalSkills}
                    className="mid-col ui-job-technical-skills"
                    colSpan={2}
                  >
                    {this.state._technicalSkills}
                  </TableRowColumn>
                  <TableRowColumn
                    title={this.state.employer._technicalSkills}
                    className="mid-col ui-employer-technical-skills"
                  >
                    {this.state.employer._technicalSkills}
                  </TableRowColumn>
                  <TableRowColumn
                    title="click link to see details"
                    className="mid-col"
                  >
                    <Link
                      component="button"
                      onClick={() => this.handlerPopup()}
                    >
                      Musts/Must Nots
                    </Link>
                  </TableRowColumn>

                  <Dialog
                    fullWidth={'xl'}
                    maxWidth={'xl'}
                    open={this.state.mustHavesDialog}
                    onClose={() => this.setState({ mustHavesDialog: false })}
                    aria-labelledby="simple-dialog-title"
                  >
                    <Grid container spacing={2} style={{ padding: '15px' }}>
                      <Grid item xs={6}>
                        <p>
                          <strong>Candidate Must(s)</strong>
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.candidateMust,
                          }}
                        ></p>
                      </Grid>
                      <Grid item xs={6}>
                        <p>
                          <strong>Candidate Must Not(s)</strong>
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.candidateMustNot,
                          }}
                        ></p>
                      </Grid>

                      <Grid item xs={12}>
                        <p>
                          <strong>Insider Scoop</strong>
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.qualificationNotes,
                          }}
                        ></p>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container spacing={1} style={{ padding: '15px' }}>
                      <Grid item xs={6}>
                        <p>
                          <strong>Positive Signals</strong>
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state._positiveSignals,
                          }}
                        ></p>
                      </Grid>
                      <Grid item xs={6}>
                        <p>
                          <strong>Negative Signals</strong>
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state._negativeSignals,
                          }}
                        ></p>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container spacing={1} style={{ padding: '15px' }}>
                      <Grid item xs={6}>
                        <p>
                          <strong>Summary</strong>
                        </p>
                        {this.profiles().summary()}
                        <p>
                          <strong>
                            YES - Client accepted profile (
                            {this.profiles().yes().length})
                          </strong>
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.profiles().print(
                              this.profiles().yes()
                            ),
                          }}
                        />

                        <p>
                          <strong>
                            MAYBE - Profiles under Client Review (
                            {this.profiles().mayBe().length})
                          </strong>
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.profiles().print(
                              this.profiles().mayBe()
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <p>
                          <strong>
                            NO - Client rejected profile (
                            {this.profiles().no().length})
                          </strong>
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.profiles().print(this.profiles().no()),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <DialogActions>
                      <Button
                        onClick={() =>
                          this.setState({ mustHavesDialog: false })
                        }
                        color="primary"
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </TableRow>
              )}

              {/* COLLAPSED VIEW R1 */}
              <TableRow
                selectable={false}
                style={{ opacity: engaged ? 0.4 : 1 }}
                onClick={this.handleToggle}
              >
                {/* R1-4C0 | CHECKBOX FOR CANDIDATE MATCH VIEW */}
                {!!this.props.onCheck && (
                  <TableRowColumn
                    title="Check to Select for Matching"
                    className="ui-job-card-r1-4c0"
                    style={{
                      width: 36,
                      textAlign: 'right',
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                    rowSpan={4}
                  >
                    <Checkbox
                      className="star"
                      checked={engaged || this.state.checked}
                      onClick={(e) => e.stopPropagation()}
                      onCheck={(event, checked) => {
                        this.setState({ checked }, (then) => {
                          this.props.onCheck(this.state.id, checked, this);
                        });
                      }}
                      /** disabled checkbox if job is engaged with candidate */
                      disabled={engaged || !!this.state.blacklisted}
                    />
                  </TableRowColumn>
                )}

                {/* R1C1 | ROLE */}
                <TableRowColumn className="first-item ui-job-card-r1c1 no-truncate">
                  {this.parent.state.tab === 'Recent'
                    ? recent
                    : this.parent.state.tab === 'Employer'
                      ? employerName
                      : this.parent.state.tab === 'Role'
                        ? roleName
                        : jobTitle}
                </TableRowColumn>

                {/* R1C2 | JOB TITLE */}
                <TableRowColumn
                  className="mid-col ui-job-card-r1c2 no-truncate"
                  onClick={this.handleToggle}
                  title={`Job Title: ${job.jobTitle}`}
                >
                  {this.parent.state.tab === 'Recent'
                    ? jobTitle
                    : this.parent.state.tab === 'Employer'
                      ? jobTitle
                      : this.parent.state.tab === 'Role'
                        ? jobTitle
                        : employerName}
                </TableRowColumn>

                {/* R1C3 | EMPLOYER NAME */}
                <TableRowColumn
                  className="mid-col ui-job-card-r1c3 no-truncate"
                  onClick={this.handleToggle}
                >
                  {this.parent.state.tab === 'Recent'
                    ? employerName
                    : this.parent.state.tab === 'Employer'
                      ? roleName
                      : this.parent.state.tab === 'Role'
                        ? employerName
                        : roleName}
                </TableRowColumn>

                {/* R1C4 | LOCATIONS */}
                <TableRowColumn
                  title={_locationsString}
                  onClick={this.handleToggle}
                  className="mid-col ui-job-card-r1c4 no-truncate"
                >
                  {_locationsString || <i>&mdash;</i>}
                </TableRowColumn>

                {/* R1C5 | EMPLOYEES */}
                <TableRowColumn
                  title={`${this.state.employer.employeeCount} | Employee Count`}
                  className="mid-col ui-job-card-r1c5 no-truncate"
                  onClick={this.handleToggle}
                >
                  {this.state.employer.employeeCount ? (
                    <Fragment>
                      ~{this.state.employer.employeeCount}
                      &nbsp;employees&nbsp;&nbsp;&nbsp;
                    </Fragment>
                  ) : (
                    <i>&mdash;</i>
                  )}
                </TableRowColumn>

                {/* R1C6 | EXPERIENCE */}
                <TableRowColumn
                  title="Experience"
                  className="mid-col ui-job-card-r1c6 no-truncate"
                  onClick={this.handleToggle}
                >
                  {!!this.state.minYearsOfExperience ? (
                    <Fragment>
                      {this.state.minYearsOfExperience}+ years
                      &nbsp;&nbsp;&nbsp;
                    </Fragment>
                  ) : (
                    <i>&mdash;</i>
                  )}
                </TableRowColumn>

                {/* R1-4C7 | OPTIONS */}
                <TableRowColumn
                  // title="Options"
                  className="flex-align-right-center px-1 ui-job-card-r1-4c7 no-truncate"
                  rowSpan={4}
                  style={{ width: 136 }}
                >
                  <IconButton
                    className="hint--left hint--rounded"
                    aria-label={t('jobs.card.copyButton.ariaLabel.label')}
                    onClick={_onClickCopyJob}
                    iconStyle={this.state.openedColor}
                  >
                    <i className="material-icons">content_copy</i>
                  </IconButton>

                  <Checkbox
                    title="Click to Starred"
                    className="star"
                    checked={this.state.starred}
                    onCheck={this.onCheckStar}
                    checkedIcon={<i className="material-icons">star</i>}
                    uncheckedIcon={
                      <i className="material-icons">star_border</i>
                    }
                    iconStyle={this.state.openedColor}
                  />
                  {Core.isAdminOrTrusted() && (
                    <IconMenu
                      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                      targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                      iconButtonElement={
                        <IconButton
                          style={{
                            width: 24,
                            padding: 0,
                          }}
                          iconStyle={this.state.openedColor}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <i className="material-icons">more_vert</i>
                        </IconButton>
                      }
                    >
                      {(Core.isAdmin() ||
                        (Core.isRecruiter() && Core.isOnDev())) && (
                          <Fragment>
                            {/** * /}
                            <NavLink
                              to={"/job/match/" + this.state.id}
                              className="navlink"
                            >
                              <MenuItem primaryText="Match" />
                            </NavLink>
                            {/** */}
                            <NavLink
                              to={'/job/matchNew/' + this.state.id}
                              className="navlink"
                            >
                              <MenuItem primaryText="Match" />
                            </NavLink>

                            {/** @todo to cleanup 2021-10-02 */}
                            {/** * /}
                            <NavLink
                              to={"/v3/job/matchNew/" + this.state.id}
                              className="navlink"
                            >
                              <MenuItem primaryText="MatchV3" />
                            </NavLink>
                            {/** */}
                          </Fragment>
                        )}
                      {/** * /}
                      Core.isAdmin() && [
                      <NavLink
                        key={Core.getKey()}
                        to={"/job/view/" + this.state.id}
                        className="navlink"
                      >
                        <MenuItem primaryText="View" />
                      </NavLink>,
                      <NavLink
                        key={Core.getKey()}
                        to={"/job/edit/" + this.state.id}
                        className="navlink"
                      >
                        <MenuItem primaryText="Edit" />
                      </NavLink>
                      ]
                      {/** */}
                      {Core.isAdminOrCoordinator() && (
                        <MenuItem primaryText="Clone" onClick={this.cloneJob} />
                      )}
                      {this.props.source === 'candidateMatch' &&
                        this.props.parentModel.resumeTxtUrl && (
                          <ResumeMatch
                            candidateResume={
                              this.props.parentModel.resumeTxtUrl
                            }
                            jobSkills={this.state._technicalSkills}
                            employerSkills={
                              this.state.employer._technicalSkills
                            }
                          />
                        )}
                      {Core.isAdminOrCoordinator() &&
                        ['ListMatch', 'candidateMatch'].includes(
                          this.props.source
                        ) && (
                          <MenuItem
                            primaryText="Calculate Match Score"
                            onClick={this.handlerForceMlOn}
                          />
                        )}

                      {Core.isAdminOrCoordinator() &&
                        (this.state.checked || this.state.engagement) && (
                          <Fragment>
                            <MenuItem
                              key={Core.getKey()}
                              primaryText={
                                this.state.engagement
                                  ? 'Update Match'
                                  : 'Bad Match?'
                              }
                              onClick={(ev) =>
                                this.refs.modalUpdateStatus.open(
                                  this.modalUpdateStatusDefaultValues()
                                )
                              }
                            />

                            {!!this.state.engagement && (
                              <MenuItem
                                key={Core.getKey()}
                                primaryText={'Submission'}
                                onClick={(ev) =>
                                  Core.go({
                                    ...this.props,
                                    to: `/candidate/resume-submission/${this.parent.parent.state.id}/${this.state.id}`
                                  })
                                }
                              />
                            )}

                            {this.state.engagement && (
                              <Fragment>
                                <MenuItem
                                  key={Core.getKey()}
                                  primaryText={'Mark ML Positive'}
                                  onClick={(ev) => {
                                    this.updateField(this.state.engagement, {
                                      useForMlTraining: 'true',
                                    });
                                  }}
                                />
                                <MenuItem
                                  key={Core.getKey()}
                                  primaryText={'Mark ML Negative'}
                                  onClick={(ev) => {
                                    this.updateField(this.state.engagement, {
                                      useForMlTraining: 'false',
                                    });
                                  }}
                                />
                              </Fragment>
                            )}
                          </Fragment>
                        )}
                      {(Core.isAdmin() ||
                        Core.isLimited() ||
                        Core.isTrusted()) && (
                          <Fragment>
                            <MenuItem
                              primaryText="Copy JD - long"
                              onClick={(ev) =>
                                copyHTML(this.getPreview('large'))
                                  .then((em) => {
                                    Core.log('Copy email command was successful');
                                    Core.showMessage(
                                      'Copied - Job Description copied for you to paste elsewhere!'
                                    );
                                  })
                                  .catch((ex) => {
                                    Core.log('Oops, unable to copy');
                                    Core.showMessage('Fail copy!');
                                  })
                              }
                            />
                            <MenuItem
                              primaryText="Copy JD - short"
                              onClick={_onClickCopyJob}
                            />
                          </Fragment>
                        )}
                      {Core.isAdmin() && (
                        <Fragment>
                          <MenuItem
                            key={Core.getKey()}
                            primaryText="Delete Job"
                            onClick={(ev) =>
                              this.fetchEngagements((engagements) =>
                                this.ConfirmDialog.open({
                                  message: `Delete "${this.state._name}"${!!engagements.length
                                    ? ` and ${engagements.length} engagement${engagements.length === 1 ? '' : 's'
                                    }?`
                                    : ''
                                    }`,
                                  onConfirm: (ev) =>
                                    Job.delete(this.state.id, (response) => {
                                      this.parent.parent.showMessage(
                                        'Job is deleted successfully'
                                      );
                                      this.parent.parent.reloadData();
                                    }),
                                })
                              )
                            }
                          />
                        </Fragment>
                      )}
                      {Core.isAdminOnLocal() && !this.state.engagements.length && (
                        <Fragment>
                          <MenuItem
                            primaryText="Delete"
                            onClick={(event) => this.refs.modalDelete.open()}
                          />
                          <ModalDelete ref="modalDelete" parent={this} />
                        </Fragment>
                      )}
                    </IconMenu>
                  )}
                  <ModalUpdateStatus
                    ref="modalUpdateStatus"
                    parent={this}
                    hasRejectionReason={true}
                    sourceClass="JobCard"
                  />
                  <i
                    className="material-icons"
                    style={{
                      width: 24,
                      height: 24,
                      margin: 0,
                      cursor: 'pointer',
                      fontWeight: 200,
                      ...this.state.openedColor,
                      ...this.state.rightArrow,
                    }}
                    onClick={this.handleToggle}
                  >
                    arrow_drop_down
                  </i>
                  {this.state.blacklisted}
                  <ConfirmDialog
                    ref={(self) => (this.ConfirmDialog = self)}
                    title="Delete Job?"
                    message="This action can't be undone."
                    actionLabel="Delete"
                  />
                </TableRowColumn>
              </TableRow>

              {/* COLLAPSED VIEW ROW 02 */}
              <TableRow selectable={false} onClick={this.handleToggle}>
                {/* R2C1 | RECRUITER OWNERSHIP? */}
                <TableRowColumn
                  title={!Core.isAdminOrCoordinator() ? 'My Sourcing' : null}
                  className="ui-job-card-r2c1 no-truncate"
                >
                  {!Core.isAdminOrCoordinator()
                    ? this.isRecruiterHasAssignedThisJob()
                    : null}
                </TableRowColumn>

                {/* R2C2 | FLAGS */}
                <TableRowColumn
                  title="Flags"
                  className="mid-col ui-job-card-r2c2 no-truncate"
                  onClick={this.handleToggle}
                >
                  {this.state.flags
                    .map((id) => Definition.getLabel('flags', id))
                    .join(', ') || <i>&mdash;</i>}
                </TableRowColumn>

                {/* R2C3 | VISA */}
                <TableRowColumn
                  title="Visa"
                  className="mid-col ui-job-card-r2c3 no-truncate"
                  onClick={this.handleToggle}
                >
                  {this.state._visaTransfer || <i>&mdash;</i>}
                </TableRowColumn>

                {/* R2C4-6 | TECHNICAL SKILLS AND ML SCORE? */}
                <TableRowColumn
                  title={
                    !!this.state.engagement
                      ? hoverDisplay(this.state.engagement)
                      : `Technical Skills ${this.state._agnosticTechnicalSkillsJobCard}`
                  }
                  className="mid-col ui-job-card-r2c4-6 no-truncate"
                  onClick={this.handleToggle}
                  style={{ wordSpacing: 3 }}
                  colSpan={3}
                >
                  {this.state._agnosticTechnicalSkillsJobCard || <i>&mdash;</i>}
                  {this.state.engagement && (
                    <span>
                      <hr />
                      {this.renderJobEngagementLabel()}
                    </span>
                  )}
                  <PrintMlScore
                    engagement={this.state.engagement}
                    candidate={this.props.parentModel}
                    job={this.state}
                    visible={
                      (this.props.source === 'candidateMatch' &&
                        this.props.mlOn) ||
                      this.state.forceMlOn
                    }
                  />
                </TableRowColumn>
              </TableRow>

              {/* COLLAPSED VIEW ROW 03 | ENGAGEMENTS STATS */}
              {!!Object(this.props.extraInfo).displayEngagements &&
                this.state.engagements.length ? (
                <TableRow selectable={false}>
                  {/* R3C1 * /}
                  <TableRowColumn className="col-mid ui-job-card-r3c1"></TableRowColumn>
                  {/** */}

                  {/* R3C2-6 */}
                  <Row2Col1
                    key={this.state.id}
                    job={this.state}
                    onClick={this.handleToggle}
                  />
                </TableRow>
              ) : null}

              {/* COLLAPSED VIEW ROW 04 | RECRUITER SOURCE */}
              {Core.isAdmin() && !!recruiterSourceName && (
                <TableRow selectable={false}>
                  {/* R4C1-6 */}
                  <TableRowColumn
                    title="Source Recruiters"
                    colSpan={6}
                    className="pr-0 ui-job-card-r4c1"
                  >
                    <div className="overflow-auto">
                      Source&nbsp;:&nbsp;&nbsp;{recruiterSourceName}
                    </div>
                  </TableRowColumn>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Divider />
          {/** EXPANDED VIEW */}
          <CardText className="expanded-view" expandable={true}>
            {this.props.source === 'candidateMatch' && (
              <span>
                <RadioButtonGroup
                  name="platformRating"
                  className="radio-button-group"
                  valueSelected={this.myDefaultMatchStrength()}
                  onChange={this.matchStrengthHandler}
                >
                  {Definition.get('engagementMatchStrength').map((item) => (
                    <RadioButton
                      key={`matchStrengh#${item.key}`}
                      value={item.id}
                      label={item.label}
                    />
                  ))}
                </RadioButtonGroup>
                <hr />
              </span>
            )}
            {!!Object(this.props.extraInfo).displayEngagements &&
              !!this.state.engagementStructureLoader && (
                <div>
                  Loading engagements&nbsp;
                  <img alt="loading..." height="14" src={loader} />
                </div>
              )}
            {!!Object(this.props.extraInfo).displayEngagements &&
              !this.state.engagementStructureLoader && (
                <div>
                  <RenderEngagementStructure
                    structure={this.state.engagementStructure}
                    stage="stage"
                    count="count"
                    name="recruiterName"
                    title="Recuiter Name"
                    lastSubmission={getEngagementsByState.getLastSubmissionDate(
                      this.state.engagements
                    )}
                  />
                </div>
              )}

            <>
              <div className="pt-2 px-2">
                <h4 className="normal-weight to-upper-case">SUMMARY</h4>
              </div>
              <CardText
                className={
                  Core.getUserRole() !== 'LimitedRecruiter'
                    ? 'contents'
                    : 'contents limited'
                }
              >
                {!Object(this.props.extraInfo).displayEngagements && (
                  <div
                    ref={(detailsView) => (this.detailsView = detailsView)}
                    className="details"
                  >
                    {!!this.state._role && (
                      <span title="Role">{this.state._role}</span>
                    )}
                    {!!this.state._level && (
                      <span title="Level">{this.state._level}</span>
                    )}
                    {!!this.state.employer.teamCount ? (
                      <span title="Team Count">
                        ~{this.state.employer.teamCount}
                        &nbsp;engineers
                      </span>
                    ) : (
                      ''
                    )}
                    <div className="d-inline-block w-20 m-0">
                      <div className="d-flex flex-column">
                        <div title="Salary Range" className="c-gray">
                          {!!this.state.salaryMax
                            ? `Salary: $${this.state.salaryMin || 0} - $${this.state.salaryMax
                            }`
                            : 'Unknown Salary'}
                        </div>
                        {!!salaryNote && (
                          <small>
                            {' '}
                            - {salaryNote}
                            <br />
                          </small>
                        )}
                        <small>
                          <i>
                            Actual compensation may be adjusted with interview
                            feedback, leveling at the company, and candidate's
                            location. Please do not set the expectation of the
                            actual compensation.
                          </i>
                        </small>
                      </div>
                    </div>

                    {!!this.state.hiringTarget && (
                      <span title="Hiring Target">
                        Hiring Target:&nbsp;
                        {this.state.hiringTarget}
                      </span>
                    )}

                    {/* epic-3038(new locations)-story-3652-m4 | 2021-08-03 Tue µ */}
                    {/** * /}
                    {!!this.state.jobType && (
                      <span title="Job Type">
                        {Definition.getLabel("jobType", this.state.jobType)}
                      </span>
                    )}
                    {/** */}

                    {/* epic-3038(new locations)-story-3652-m4 | 2021-08-03 Tue µ */}
                    {!!this.state._desiredEmploymentTypes.length && (
                      <span title="Job Type">
                        {Definition.getLabels(
                          'desiredEmploymentType',
                          this.state.desiredEmploymentTypes
                        ).join(' | ')}
                      </span>
                    )}

                    {!!this.state.employer.url && (
                      <span title="Company URL">
                        <a
                          href={formatURL(this.state.employer.url)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {this.state.employer.url}
                        </a>
                      </span>
                    )}
                    {!!Definition.getLabel(
                      'stage',
                      this.state.employer.stage
                    ) && (
                        <span title="Employer Stage e.g. Series A">
                          {Definition.getLabel(
                            'stage',
                            this.state.employer.stage
                          )}
                          &nbsp;Company
                        </span>
                      )}
                    {!!this.state.employer.totalFunding && (
                      <span title="Employer Founding">
                        {!!this.state.employer.totalFunding
                          ? 'Funding $' + this.state.employer.totalFunding
                          : 'Unknown Funding'}
                      </span>
                    )}
                    {!!this.state.employer.foundingYear && (
                      <span title="Employer Founded">
                        Founded {this.state.employer.foundingYear}
                      </span>
                    )}
                    {Core.isAdminOrCoordinator() &&
                      !!this.state.employer.proxyName && (
                        <span title="Proxy Name">
                          {this.state.employer.proxyName}
                        </span>
                      )}
                    {Core.isAdminOrCoordinator() && (
                      <span title="State">
                        {Definition.getLabel('state', this.state.state) || (
                          <i>&mdash;</i>
                        )}
                      </span>
                    )}
                    {/** @todo DEPRECATED - 2021-07-07 µ */}
                    {/** * /}
                      {!!this.state.remote && (
                        <span title="Remote">
                          WFH:&nbsp;
                          {Definition.getLabel("remote", this.state.remote)}
                          {!!this.state.remoteExtraInfo && (
                            <Fragment>({this.state.remoteExtraInfo})</Fragment>
                          )}
                        </span>
                      )}
                      {/** */}
                  </div>
                )}
              </CardText>
              <Divider />
            </>

            <LocationSection job={this.state} />

            {/* JOB ADDITIONAL DETAIL
              HIDE ON ENGAGEMENTS CHECK TRUE */}
            {!Object(this.props.extraInfo).displayEngagements && (
              <Fragment>
                {!!this.state.employer.tagline && (
                  <Fragment>
                    <div
                      ref={(elevatorPitchView) =>
                        (this.elevatorPitchView = elevatorPitchView)
                      }
                    >
                      <CardHeader
                        title={
                          <Fragment>
                            {`${this.state.employer.name} elevator pitch`.toUpperCase()}
                          </Fragment>
                        }
                        className="card-header"
                      />
                      <CardText className="contents">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.employer.tagline,
                          }}
                        />
                      </CardText>
                    </div>
                    <Divider />
                  </Fragment>
                )}

                {!!this.state.employer.technicalSkills.length && (
                  <Fragment>
                    <Divider />
                    <div className="inline-blocks padding-16">
                      <h4 className="normal-weight">
                        EMPLOYER TECH STACK: &nbsp;
                      </h4>
                      <span>
                        {this.state.employer.technicalSkills
                          .map((id) =>
                            Definition.getLabel('technicalSkills', id)
                          )
                          .join(', ')}
                      </span>
                    </div>
                  </Fragment>
                )}
                {!!this.state.technicalSkills.length && (
                  <Fragment>
                    <Divider />
                    <div className="inline-blocks padding-16">
                      <h4 className="normal-weight">
                        ACCEPTABLE TECH: &nbsp;
                      </h4>
                      <span>
                        {this.state.technicalSkills
                          .map((id) =>
                            Definition.getLabel('technicalSkills', id)
                          )
                          .join(', ')}
                      </span>
                    </div>
                  </Fragment>
                )}
                <Divider />
                {this.renderAdditionalNotes()}
                {!!this.state.qualificationNotes && (
                  <div
                    ref={(qualificationNotesView) =>
                      (this.qualificationNotesView = qualificationNotesView)
                    }
                  >
                    <Divider />
                    <span
                      ref={(self) => (this.references['insider-scoop'] = self)}
                    ></span>
                    <CardHeader title="INSIDER SCOOP" />
                    <CardText className="contents">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.qualificationNotes,
                        }}
                      />
                    </CardText>
                  </div>
                )}

                {Core.isAdminOrCoordinator() && (
                  <Fragment>
                    <Divider />
                    <CardHeader
                      className="to-upper-case"
                      title={t('jobs.card.engagementsSummary.title')}
                    />
                    <CardText
                      className="contents"
                      style={{ maxHeight: '150px', overflow: 'auto' }}
                    >
                      {this.profiles().summary()}
                    </CardText>
                    <Divider />
                    <CardHeader
                      title={<Fragment>YES - Client accepted profile</Fragment>}
                    />
                    <CardText
                      className="contents"
                      style={{ maxHeight: '150px', overflow: 'auto' }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.profiles().print(this.profiles().yes()),
                        }}
                      />
                    </CardText>
                  </Fragment>
                )}

                {Core.isAdminOrCoordinator() && (
                  <Fragment>
                    <Divider />
                    <CardHeader
                      title={
                        <Fragment>
                          MAYBE - Profiles under Client Review
                        </Fragment>
                      }
                    />
                    <CardText
                      className="contents"
                      style={{ maxHeight: '150px', overflow: 'auto' }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.profiles().print(
                            this.profiles().mayBe()
                          ),
                        }}
                      />
                    </CardText>
                  </Fragment>
                )}

                {Core.isAdminOrCoordinator() && (
                  <Fragment>
                    <Divider />
                    <CardHeader
                      title={<Fragment>NO - Client rejected profile</Fragment>}
                    />
                    <CardText
                      className="contents"
                      style={{ maxHeight: '150px', overflow: 'auto' }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.profiles().print(this.profiles().no()),
                        }}
                      />
                    </CardText>
                  </Fragment>
                )}

                {!!this.state.jobDescription && (
                  <div
                    ref={(jobDescriptionView) =>
                      (this.jobDescriptionView = jobDescriptionView)
                    }
                  >
                    <Divider />
                    <CardHeader
                      title={
                        <Fragment>
                          JOB DESCRIPTION&nbsp;
                          {this.state.jobDescriptionPublicURL ? (
                            <a
                              href={this.state.jobDescriptionPublicURL}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Company's job description link
                            </a>
                          ) : (
                            ''
                          )}
                        </Fragment>
                      }
                    />
                    <CardText className="contents">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.jobDescription,
                        }}
                      />
                    </CardText>
                  </div>
                )}

                {!!this.state.employer.additionalInfo && (
                  <div
                    ref={(hiringPriorityView) =>
                      (this.hiringPriorityView = hiringPriorityView)
                    }
                  >
                    <Divider />
                    <CardHeader title="HIRING PRIORITY" />
                    <CardText className="contents">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.employer.additionalInfo,
                        }}
                      />
                    </CardText>
                  </div>
                )}
                {(!!this.state.jobInterviewProcess ||
                  !!this.state.employer.interviewProcess) && (
                    <div
                      ref={(interviewProcessView) =>
                        (this.interviewProcessView = interviewProcessView)
                      }
                    >
                      <Divider />
                      <span
                        ref={(self) =>
                          (this.references['interview-process'] = self)
                        }
                      ></span>
                      <CardHeader title="INTERVIEW PROCESS" />
                      <CardText className="contents">
                        {!!this.state.employer.interviewProcess && (
                          <Fragment>
                            General:
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.employer.interviewProcess,
                              }}
                            />
                            <br />
                          </Fragment>
                        )}
                        {!!this.state.jobInterviewProcess && (
                          <Fragment>
                            Job Specific:
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.jobInterviewProcess,
                              }}
                            />
                          </Fragment>
                        )}
                      </CardText>
                      <Divider />
                    </div>
                  )}
                {(!!this.state.publicNotes ||
                  !!this.state.employer.publicNotes) && (
                    <div
                      ref={(publicNotesView) =>
                        (this.publicNotesView = publicNotesView)
                      }
                    >
                      <Divider />
                      <CardHeader title="NOTES" />
                      <CardText className="contents">
                        {!!this.state.employer.publicNotes && (
                          <Fragment>
                            General:
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.employer.publicNotes,
                              }}
                            />
                            <br />
                          </Fragment>
                        )}
                        {!!this.state.publicNotes && (
                          <Fragment>
                            Job:
                            <br />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.publicNotes,
                              }}
                            />
                          </Fragment>
                        )}
                      </CardText>
                    </div>
                  )}
                {!!this.state.employer.product && (
                  <div
                    ref={(companyOverview) =>
                      (this.companyOverview = companyOverview)
                    }
                  >
                    <Divider />
                    <CardHeader title="COMPANY OVERVIEW" />
                    <CardText className="contents">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.employer.product,
                        }}
                      />
                    </CardText>
                  </div>
                )}
                {!!this.state.employer.whyUs && (
                  <div ref={(whyUsView) => (this.whyUsView = whyUsView)}>
                    <Divider />
                    <CardHeader title="WHY US" />
                    <CardText className="contents">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.employer.whyUs,
                        }}
                      />
                    </CardText>
                  </div>
                )}
                {(!!this.state.employer.crunchbaseUrl ||
                  !!this.state.employer.additionalUrl ||
                  !!this.state.employer.notables ||
                  !!this.state.employer.perks) && (
                    <div
                      ref={(companyInfoView) =>
                        (this.companyInfoView = companyInfoView)
                      }
                    >
                      <Divider />
                      <CardHeader title="COMPANY INFO" />
                      <CardText className="contents">
                        {(!!this.state.employer.crunchbaseUrl ||
                          !!this.state.employer.additionalUrl) && (
                            <Fragment>
                              Links:
                              <br />
                              <br />
                              <div>
                                <a
                                  href={formatURL(
                                    this.state.employer.crunchbaseUrl
                                  )}
                                >
                                  {this.state.employer.crunchbaseUrl}
                                </a>
                              </div>
                              {this.state.employer.additionalUrl &&
                                this.state.employer.additionalUrl
                                  .split(/\n/)
                                  .map((url) => (
                                    <div key={Core.getKey()}>
                                      <a href={formatURL(url)}>{url}</a>
                                    </div>
                                  ))}
                              <br />
                            </Fragment>
                          )}
                        {!!this.state.employer.notables && (
                          <Fragment>
                            Notable:
                            <pre>{this.state.employer.notables}</pre>
                            <br />
                          </Fragment>
                        )}
                        {!!this.state.employer.perks && (
                          <Fragment>
                            Perks:
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.employer.perks,
                              }}
                            />
                          </Fragment>
                        )}
                      </CardText>
                    </div>
                  )}
                {Core.isAdminOrCoordinator() &&
                  !!this.state.jobBlackList.length && (
                    <Fragment>
                      <Divider />
                      <CardHeader title="BLACK LIST" />
                      <CardText className="contents">
                        {this.state.jobBlackList.map((item) => (
                          <li key={item.id}>
                            {item.firstName} {item.lastName}
                          </li>
                        ))}
                      </CardText>
                    </Fragment>
                  )}
              </Fragment>
            )}
            {/**
            Remanent from Phase 1,
            don't remove yet,
            it would be implemente again
            * /}
          {Core.isAdminOrCoordinator() &&
            !!this.state.engagements.length && (
              <Fragment>
                <Divider />
                <CardHeader
                  title="ENGAGEMENTS"
                  children={this.state.engagements.map(engagement => (
                    <Table key={Core.getKey()}>
                      <TableBody displayRowCheckbox={false}>
                        <TableRow selectable={false}>
                          <TableRowColumn
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                          >
                            {engagement.candidate.firstName}{" "}
                            {engagement.candidate.lastName}
                          </TableRowColumn>
                          <TableRowColumn
                            onClick={this.handleToggle}
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                          >
                            {engagement.stage}
                          </TableRowColumn>
                          <TableRowColumn
                            onClick={this.handleToggle}
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                          >
                            {engagement.status}
                          </TableRowColumn>
                          <TableRowColumn
                            onClick={this.handleToggle}
                            style={{
                              textAlign: "right",
                              paddingLeft: 0,
                              paddingRight: 0
                            }}
                          >
                            {Core.getFromNow(engagement.updatedAt)}
                          </TableRowColumn>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ))}
                />
              </Fragment>
            )}
          {/** */}
            <div
              className="col12 normal-padding cursor-pointer inline-blocks purples-over bgcolor2"
              style={{ margin: '0 0 -8px 0' }}
              onClick={(ev) => {
                try {
                  this.parent.scroller.scrollTo(
                    0,
                    Object(ReactDOM.findDOMNode(this.collapsedView)).offsetTop -
                    80
                  );
                } catch (ex) {
                  console.warn(ex);
                }
                this.handleToggle(ev);
              }}
            >
              <span className="anchor">Show Less</span>
              <i className="material-icons c-purple">arrow_drop_up</i>
            </div>
          </CardText>
        </Card>
      </Fragment>
    );
  }
}

class Row2Col1 extends Component {
  render() {
    const { job } = this.props;
    const engagements = job.engagements;
    let { render } = getEngagementsByState.getEngagementByGroup(
      engagements,
      job
    );
    const componentShowMore = (
      <Fragment>
        <span className="show-more anchor">
          {job.expanded ? 'Show Less' : 'Show More'}
        </span>
        <i
          className="material-icons anchor"
          style={{ ...job.rightArrow, textDecoration: 'none' }}
        >
          arrow_drop_down
        </i>
      </Fragment>
    );

    const componentEngagements = (
      <div>
        {render.map((grp, index) => {
          return (
            <div key={index} title={`${grp}`}>
              {grp}
            </div>
          );
        })}
        <br />
      </div>
    );

    return (
      <TableRowColumn
        onClick={this.props.onClick}
        className="first-col v-align-mid ui-job-card-r3c1-6"
        style={{
          overflow: 'none',
          whiteSpace: 'nowrap',
        }}
        colSpan={6}
      >
        {Core.isAdminOrCoordinator() ? componentEngagements : componentShowMore}
      </TableRowColumn>
    );
  }
}

/* EXPORTS ==================================== */

export default withTranslation()(JobCard);

/* ============================================ */
