import React, {Component, Fragment} from "react";

/* Custom Styles */
import Grid from '@mui/material/Grid';
import TextField from "material-ui/TextField";

class InputFieldContent extends Component {
    
    constructor(){
        super(...arguments);
        this.state = {
            content: this.props.content
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!prevProps.selectedJob && prevProps.selectedJob.id !== this.props.selectedJob.id) {
            this.setState({content: this.props.content});
        }
    }

    saveSkillContent = () => {
        const {saveContent, contentKey} = this.props;
        const {content} = this.state;

        !!saveContent && saveContent(contentKey, content);
    }

    render() {
        const {content} = this.state;
        const {label} = this.props;

        return (
            <Fragment>
                <Grid container spacing={4}>
                    <Fragment>
                        <Grid item xs={12} sm={3} style={{paddingTop: '30px'}}>
                            <label>
                                {label}
                            </label>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                name="newTechSkill"
                                type="text"
                                underlineFocusStyle={{borderColor: "#59736f"}}
                                value={content}
                                onChange={(event) => this.setState({content: event.target.value})}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={1} style={{paddingTop: '30px'}}>
                            <i
                                className="material-icons icon-edit"
                                onClick={this.saveSkillContent} >
                                save
                            </i>
                        </Grid>
                    </Fragment>
                </Grid>
            </Fragment>
        );
    }
}

export default InputFieldContent;