import Core from "../../lib/Core";
import EngagementDisagreement from "../../lib/EngagementDisagreement";
import Google from "../../lib/Google";

const getHumanAnnotatorFields = (disgreement, engagement) => {
  let makeString = "";

  if (!disgreement) {
    return makeString;
  }

  let annotator = true;

  Object.keys(disgreement).forEach(obj => {
    if (!!engagement && Object(engagement.matchedByWho).email === obj) {
      annotator = false;
    } else {
      annotator = true;
    }

    makeString += `<li>`;
    makeString += ` <a href='${disgreement[obj].matchingUrl}'>
                        ${disgreement[obj].annotator} { ${disgreement[obj].matchDecision} } 
                        </a>
                        ${!annotator ? ` 
                        | 
                        <a href='${disgreement[obj].engagementUrl}'>
                            ${!!disgreement[obj].engagementStage ? disgreement[obj].engagementStage : "{ no stage }"} 
                            | ${!!disgreement[obj].engagementStatus ? disgreement[obj].engagementStatus : "{ no status }"} 
                            | ${!!disgreement[obj].matchDecision ? disgreement[obj].matchDecision : " { no decision } "}
                        </a>
                        ` : ""}
                        - ${!!annotator ? 'annotator' : 'matcher'} 
                        - ${!!disgreement[obj].createdAt ? disgreement[obj].createdAt.substring(0, 10) : new Date().toISOString().substring(0, 10)}${!!disgreement[obj].reviewed ? " - Reviewed : " + disgreement[obj].reviewed : ""}`;
    makeString += `</li>`;
  });
  return `<tr><td><ul>${makeString}</ul></td></tr>`;
}

const sendAnnotatorEmail = (
  { annotatorMatchStrength, matcherMatchStrength, matcherEmail, disagreementObj, engagement }
) => {

  const fetchHistoryCb = (disagreementHistory) => {
    let formatted = {};

    disagreementHistory.forEach(engagement => {
      if (!!engagement.annotator && disagreementObj.annotator !== engagement.annotator) {
        formatted[engagement.annotator] = engagement;
      }
    });

    const newHumanData = getHumanAnnotatorFields({ [disagreementObj.annotator]: disagreementObj }, engagement);
    const oldHumanData = getHumanAnnotatorFields(formatted, engagement);

    const annotatorEmail = Object(Core.getSession().user);
    const matcherNewEmail = !!matcherEmail ? matcherEmail : "";

    const from = Core.getNewCandidateReceiverNotiFromEmail();
    const to = [Object(annotatorEmail).email, matcherNewEmail, Core.getDraftCandidateReceiverNotiToEmail()];
    // const cc = "null";
    const subject = "Annotator Feedback";

    // const mainLabel = !!engagement ? `matcher(${engagement.matchedByWho.email}) says match strength is {${matcherStrength}} in engagement, but annotator(${disagreementObj.annotator}) says ${annotatorStrength}` : `no engagement found, but annotator says ${annotatorStrength}`;
    const mainLabel = '';
    const statusLabel = !!engagement ? `<tr><td>Status is ${engagement.status}, Stage is ${engagement.stage}</td></tr>` : '';

    let disagreementModel = "<ul>";
    if (!!engagement) {
      disagreementModel += `<li>Matched By: ${engagement.matchedByWho.email}</li>`
    }
    Object.keys(disagreementObj).filter(obj => !!disagreementObj[obj]).forEach(obj => {
      disagreementModel += `<li>${obj} : ${disagreementObj[obj]}</li>`;
    });

    disagreementModel += "</ul>";

    let html = `<html>
    <head></head>
    <body>
        
        <table style="width:60%" border="1">
          <tr>
            <th>Annotator Feeaback</th>
          </tr>
          <tr>
            <td>
               ${mainLabel}
            </td>
          </tr>
          ${statusLabel}
          ${newHumanData}
          ${oldHumanData}
          <tr>
            <td>${disagreementModel}</td>
          </tr>
        </table>
    </body></html>`;

    to.filter(el => !!el).forEach(el => {
      let params = { from, to: el, cc: null, subject, html };
      Core.log("Email notification for annotator feedback", params);
      Google.sendEmail({ ...params, source: 'annotatorActions.js line 115' }, null, error => Core.showMessage(error));
    });
  }

  fetchHistory(disagreementObj, fetchHistoryCb);


}

const fetchHistory = (disagreementObj, cb) => {
  EngagementDisagreement.get(res => {
    !!cb && cb(res);
  }, {
    where: {
      and: [
        { candidateId: disagreementObj.candidateId },
        { jobId: disagreementObj.jobId },
        { annotator: { neq: 'ML' } },
      ]
    }
  });
}

const annotatorActions = {
  sendAnnotatorEmail
}

export default annotatorActions;