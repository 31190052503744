import React, { Component } from "react";

import { List, ListItem } from "material-ui/List";
import TextField from "material-ui/TextField";

import Row from "../../Forms/Row";
import Col from "../../Forms/Col";

import { colors } from "../../../lib/Core";
import RichTextBox from "../../Forms/RichTextBox";
import Chips from "../../Forms/Chips";

class Requirements extends Component {
  render() {
    const parent = this.props.parent;
    return (
      <List className="JobForm Requirements">
        <ListItem
          className="form-header"
          primaryText="REQUIREMENTS"
          disabled={true}
        />
        <Row>
          <Col>
            <label>Minimum Years of Experience</label>
            <TextField
              name="minYearsOfExperience"
              type="number"
              style={{ width: "calc(100% - 40px)" }}
              min="0"
              max="100"
              floatingLabelFocusStyle={{ color: colors.purple, fontSize: 12 }}
              underlineFocusStyle={{ borderColor: colors.purple }}
              value={parent.state.minYearsOfExperience}
              onChange={(event, minYearsOfExperience) => {
                parent.setState({ minYearsOfExperience });
              }}
            />
          </Col>
          <Col>
            <label>Ideal Years of Experience</label>
            <TextField
              name="idealMinExperience"
              type="number"
              style={{ width: "calc(100% - 40px)" }}
              min="0"
              max="100"
              floatingLabelFocusStyle={{ color: colors.purple, fontSize: 12 }}
              underlineFocusStyle={{ borderColor: colors.purple }}
              value={parent.state.idealMinExperience}
              onChange={(event, idealMinExperience) => {
                parent.setState({ idealMinExperience });
              }}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              width: "1%",
              padding: 0
            }}
          >
            <p>to</p>
          </Col>
          <Col>
            <label>Maximum Years of Experience</label>
            <TextField
              name="idealMaxExperience"
              type="number"
              style={{ width: "calc(100% - 40px)" }}
              min="0"
              max="100"
              floatingLabelFocusStyle={{ color: colors.purple, fontSize: 12 }}
              underlineFocusStyle={{ borderColor: colors.purple }}
              value={!!parent.state.idealMaxExperience ? parent.state.idealMaxExperience : ""}
              onChange={(event, idealMaxExperience) => {
                parent.setState({ idealMaxExperience });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <Chips
              name="technicalSkills"
              label="Technical Skills"
              values={parent.state.technicalSkills}
              onChange={technicalSkills => parent.setState({ technicalSkills })}
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <Chips
              name="experience"
              label="Experience"
              values={parent.state.experience}
              onChange={experience => parent.setState({ experience })}
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <Chips
              name="positiveSignals"
              label="Positive Signals"
              values={parent.state.positiveSignals}
              onChange={positiveSignals => parent.setState({ positiveSignals })}
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <Chips
              name="negativeSignals"
              label="Negative Signals"
              values={parent.state.negativeSignals}
              onChange={negativeSignals => parent.setState({ negativeSignals })}
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <label>Insider Scoop [Visible to Limited Recruiters]</label>
            <RichTextBox
              name="qualificationNotes"
              value={parent.state.qualificationNotes}
              onChange={qualificationNotes => {
                parent.setState({
                  qualificationNotes
                });
              }}
            />
          </Col>
        </Row>
      </List>
    );
  }
}

export default Requirements;
