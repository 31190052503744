import React, {Component, Fragment} from "react";
import ReactDOM from 'react-dom';

const Number = [
    {name: 1},
    {name: 2},
    {name: 3},
    {name: 4},
    {name: 5},
    {name: 6},
    {name: 7},
    {name: 8},
    {name: 9},
    {name: 10}
];

class NumberDropdown extends Component {

    constructor() {
        super(...arguments);
        this.state = {
            number: this.props.atleast,
            openNumberDD: false
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }
    
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
    
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                openNumberDD: false
            });
        }
    }

    componentWillReceiveProps (nextProps) {
        if(nextProps.atleast !== this.props.atleast){
            this.setState({number: nextProps.atleast});
        }
    }

    openNumberDD = () => {
        let {openNumberDD} = this.state;
        this.setState({openNumberDD: !openNumberDD});
    }

    handlerNumberOption = (number) => () => {
        const {handlerNumber} = this.props;
        this.setState({number, openNumberDD: false}, () => {
            !!handlerNumber && handlerNumber(number);
        });
    } 

    render() {
        const {number,openNumberDD} = this.state;
        const {onlyView} = this.props;
        const styleView = !!onlyView ? 'fc-button-color-view' : 'fc-button-color-secondary';

        return <Fragment>
            <span className="fc-dropdownmenu">
                {!!number &&
                    <span
                        className={`fc-dropdownmenu-button ${styleView}`}
                        onClick={this.openNumberDD}
                        onKeyDown={this.openNumberDD}
                    >
                        <span>{number}</span>
                    </span>
                }
                {!onlyView && !!openNumberDD && 
                    <span className="fc-dropdownmenu-contextmenu fc-dropdown-position">
                        {Number.map((item, index) => {
                            return <div
                                    className="fc-dropdownmenu-contextmenu-item"
                                    key={index}
                                    selected={index}
                                    onClick={this.handlerNumberOption(item.name)}
                                >
                                    {item.name}
                                </div>
                        })}
                    </span>
                }
            </span>&nbsp;&nbsp;
        </Fragment>
    }
}

export default NumberDropdown;
