import { Button } from '@mui/material';
import { MenuItem, SelectField, TextField } from 'material-ui';
import { MuiThemeProvider } from 'material-ui/styles';
import React, { useEffect } from 'react';
import Candidate from '../../lib/Candidate';
import Core from '../../lib/Core';
import Definition from '../../lib/Definition';
import Engagement from '../../lib/Engagement';
import Job from '../../lib/Job';
import { mapEngagement } from '../../lib/models/engagement';
import { openMessageEmailPreview } from '../../lib/services/Email/Email.lib';
import { openCandidateRepresentEmailPreview } from '../../lib/services/Email/EmailCandidate.lib';
import {
  openEmployerReminderEmailPreview,
  openJobReminderEmailPreview,
} from '../../lib/services/Email/EmailEmployer.lib';
import {
  openRecruiterAskPermissionEmailPreviewV2,
  openRecruiterReminderEmailPreviewV2,
} from '../../lib/services/Email/EmailRecruiter.lib';
import Streak from '../../lib/Streak';
import copyHTML from '../../lib/tools/copyHtml';
import downloadFile from '../../lib/tools/downloadFile';
import { useUpdateState } from './useUpdateState.hook';
import ResumeMatch from '../Shared/ResumeMatch';
import {
  DatesForm,
  EmailThreads,
  StreakComments,
  StreakNotes,
} from '../Engagements/Card/EngagementCard';

export const moreMenuOptionsEngagements = [
  {
    acl: (engagement) => Core.isAdminOrCoordinator(),
    label: 'View Engagement',
    to: (engagement) => `/v3/engagement/view/${engagement.id}`,
  },
  {
    acl: (engagement) => Core.isAdminOrCoordinator(),
    label: 'Edit Job',
    to: (engagement) => `/v3/job/edit/${engagement.jobId}`,
    target: (engagement) => '_blank',
  },
  {
    acl: (engagement) => Core.isAdminOrCoordinator(),
    label: 'Edit Candidate',
    to: (engagement) => `/v3/candidate/edit/${engagement.candidateId}`,
    target: (engagement) => '_blank',
  },
  {
    acl: (engagement) => Core.isAdminOrCoordinator(),
    label: 'Match Candidate',
    to: (engagement) => `/v3/candidate/matchNew/${engagement.candidateId}`,
    target: (engagement) => '_blank',
  },
  {
    acl: (engagement) => Core.isAdminOrCoordinator(),
    label: 'Resume Submission',
    to: (engagement) =>
      `/v3/candidate/resume-submission/${engagement.candidateId}/${engagement.jobId}`,
    target: (engagement) => '_blank',
  },
  {
    acl: (engagement) => Core.isAdminOrCoordinator(),
    label: 'Copy',
    action: copyEngagementJob,
  },
  {
    acl: (engagement) => !!engagement.candidate.linkedInURL,
    label: 'Candidate LinkedIn',
    action: (engagement) => Core.openPopUp(engagement.candidate.linkedInURL),
  },
  {
    acl: (engagement) => !!engagement.candidate.gitHubURL,
    label: 'Candidate GitHub',
    action: (engagement) => Core.openPopUp(engagement.candidate.gitHubURL),
  },
  {
    acl: (engagement) => !!engagement.candidate.resumes.length,
    label: 'Curriculum Vitae',
    action: (engagement) =>
      Core.openPopUp(Candidate.getMyPdfUrl(engagement.candidate)),
  },
  {
    acl: (engagement) => !!engagement.candidate.resumeTxtUrl,
    render: (engagement, props) => {
      const { candidate, job } = engagement;
      return (
        <div {...props}>
          <MuiThemeProvider>
            <ResumeMatch
              candidateResume={candidate.resumeTxtUrl}
              jobSkills={job._technicalSkills}
              employerSkills={job.employer._technicalSkills}
            />
          </MuiThemeProvider>
        </div>
      );
    },
  },
  {
    acl: (engagement) => !!engagement.candidate.resumes.length,
    render: (engagement, props) => {
      const { candidate } = engagement;
      return (
        <div {...props}>
          <MuiThemeProvider>
            <div>
              {candidate.resumes.map((resume, index) => (
                <MenuItem
                  key={`engagement-${engagement.id}-card-candidate-resume-${resume.url}`}
                  title={`Download CV ${index + 1}`}
                  primaryText={`Download CV ${index + 1}`}
                  onClick={(event) => {
                    downloadFile({
                      url: resume.url,
                      mimeType: 'application/pdf',
                      onError: (error) => Core.showMessage(error),
                    });
                  }}
                />
              ))}
            </div>
          </MuiThemeProvider>
        </div>
      );
    },
  },
  {
    acl: (engagement) => Core.isAdminOrCoordinator(),
    label: 'View Streak threads',
    action: (engagement) => openEmailThreads(engagement),
  },
  {
    acl: (engagement) => Core.isAdminOrCoordinator(),
    label: 'Add a Streak comment',
    action: (engagement) => openCommentDialog(engagement),
  },
  {
    acl: (engagement) => Core.isAdminOrCoordinator(),
    label: 'Edit Streak notes',
    action: (engagement) => openNotesDialog(engagement),
  },
  {
    acl: (engagement) => Core.isAdminOrCoordinator(),
    label: 'Update Status',
    action: openUpdateStatus,
  },
  {
    label: 'Update Dates',
    action: openDatesDialog,
  },
  {
    label: 'Message',
    action: (engagement) =>
      openMessageEmailPreview({
        employer: engagement.job.employer,
        engagement,
        engagements: [engagement],
      }),
  },
  {
    label: 'Represent Email',
    action: (engagement) =>
      openCandidateRepresentEmailPreview({
        candidateId: engagement.candidateId,
        engagement,
      }),
  },
  {
    label: 'Employer Reminder (API Envelope)',
    action: (engagement) =>
      openEmployerReminderEmailPreview({
        employer: engagement.job.employer,
        engagements: [engagement],
      }),
  },
  {
    label: 'Job Reminder (API Envelope)',
    action: (engagement) =>
      openJobReminderEmailPreview({
        job: engagement.job,
        employer: engagement.job.employer,
        engagements: [engagement],
      }),
  },
  {
    label: 'Recruiter Reminder (New)',
    action: (engagement) =>
      openRecruiterReminderEmailPreviewV2({
        recruiterId: engagement.candidate.accountId,
        engagements: [engagement],
      }),
  },
  {
    label: 'Ask Permission (New)',
    action: (engagement) =>
      openRecruiterAskPermissionEmailPreviewV2({
        recruiterId: engagement.candidate.accountId,
        engagements: [engagement],
        onDone: () => {
          /** @todo  reloadData() */
        },
      }),
  },
  {
    acl: (engagement) => Core.isAdmin(),
    label: <span className="c-red">Delete</span>,
    action: (engagement) => deleteEngagement(engagement),
  },
];

function updateEngagement({ engagement, update }) {
  // updateUrlState from current live instance hook
  const updateUrlState = Core.getKeyValue('updateUrlState') || console.debug;

  Object.keys(update).forEach((key) => (engagement[key] = update[key]));
  mapEngagement(engagement);
  Engagement.update(
    engagement,
    update,
    (response) => updateUrlState({ _updatedAt: response._updatedAt }),
    (failure) =>
      Core.showMessage(`failed to update engagement with id = ${engagement.id}`)
  );
}

function copyEngagementJob(engagement) {
  copyHTML(Job.getPreview(engagement.job))
    .then((em) => {
      Core.log('Copy email command was successful');
      Core.showMessage('Copied!');
    })
    .catch((ex) => {
      Core.log('Oops, unable to copy');
      Core.showMessage('Fail copy!');
    });
}

function openDatesDialog(engagement) {
  Core.dialog.open({
    title: <>Update Dates</>,
    message: (
      <DatesForm
        ref={(self) => (openDatesDialog.DatesForm = self)}
        engagement={engagement}
      />
    ),
    style: { width: 480 },
    actions: [
      <Button
        className="button-flat-darker"
        onClick={(ev) => {
          Core.dialog.close();
        }}
      >
        Cancel
      </Button>,
      <Button
        className="button-white-cyan"
        onClick={(ev) => {
          Core.dialog.close();
          updateEngagement({
            engagement,
            update: {
              ...openDatesDialog.DatesForm.getDates(),
            },
          });
        }}
      >
        Done
      </Button>,
    ],
  });
}

function openUpdateStatus(engagement) {
  Core.dialog.open({
    title: <>Message</>,
    message: (
      <UpdateEngagementStatusOption
        setConfirmation={(action) => (openUpdateStatus.onConfirm = action)}
        engagement={engagement}
      />
    ),
    style: { width: 480 },
    actions: [
      <Button
        className="button-flat-darker"
        onClick={(ev) => {
          Core.dialog.close();
        }}
      >
        Cancel
      </Button>,
      <Button
        className="button-white-cyan"
        onClick={(ev) => {
          Core.dialog.close();
          openUpdateStatus.onConfirm();
        }}
      >
        Submit
      </Button>,
    ],
  });
}

function UpdateEngagementStatusOption({
  engagement,
  setConfirmation = () => { },
}) {
  const { state = {}, updateState } = useUpdateState();

  useEffect(() => {
    if (!state.engagement && engagement.___model___ === 'Engagement') {
      let model = { ...engagement, engagement };
      updateState({
        model,
        stages: Streak.getDropdownStrings('Stage').map((stage) => (
          <MenuItem value={stage} key={stage} primaryText={stage} />
        )),
        statuses: Streak.getDropdownStrings('Status')
          .sort((a, b) => a.localeCompare(b))
          .map((status) => (
            <MenuItem value={status} key={status} primaryText={status} />
          )),
        states: Streak.getDropdownStrings('State').map((state) => (
          <MenuItem value={state} key={state} primaryText={state} />
        )),
        rejectionReasons: Streak.getDropdownStrings('RejectionReason')
          .sort((a, b) => a.localeCompare(b))
          .map((reason) => (
            <MenuItem value={reason} key={reason} primaryText={reason} />
          )),

        matchStrengths: Definition.get('engagementMatchStrength').map(
          (strength) => (
            <MenuItem
              value={strength.id}
              key={strength.id}
              primaryText={strength.label}
            />
          )
        ),
        stage: model.stage,
        status: model.status,
        state: model.state,
        engagement: model.engagement,
        rejectionReason: model.rejectionReason,
        matchStrength: model.matchStrength,
        cbUpdateField: model.cbUpdateField,
        rejectionReasonAdditionalInfo: !!model.engagement
          ? model.engagement.rejectionReasonAdditionalInfo ||
          model.engagement.rejectionReason
          : '',
      });
    }
  }, [state, engagement, updateState]);

  setConfirmation(() => {
    const update = {
      stage: state.stage,
      status: state.status,
      state: state.state,
      rejectionReason: state.rejectionReason,
      matchStrength: state.matchStrength,
      rejectionReasonAdditionalInfo: state.rejectionReasonAdditionalInfo,
    };
    const newDate = new Date();
    const newISO = newDate.toISOString();
    [
      [/submission|review|screen|onsite|offer|hire|guarantee/i, 'confirmed'],
      [/review|screen|onsite|offer|hire|guarantee/i, 'submitted'],
      [/screen|onsite|offer|hire|guarantee/i, 'reviewed'],
      [/onsite|offer|hire|guarantee/i, 'screened'],
      [/offer|hire|guarantee/i, 'onsite'],
      [/hire|guarantee/i, 'offered'],
    ].forEach((item) => {
      if (item[0].test(update.stage) && !engagement[item[1]]) {
        update[item[1]] = newISO;
        Core.log(item[1]);
      }
    });
    let fields = update;

    if (!!state.engagement) {
      if (state.matchStrength !== state.engagement.matchStrength) {
        fields = {
          ...fields,
          matchedByWho: Core.getUser(),
          lastMatchedDate: new Date().toISOString(),
        };
      }
    } else {
      //it means its a new entry
      fields = {
        ...fields,
        matchedByWho: Core.getUser(),
        lastMatchedDate: new Date().toISOString(),
      };
    }

    Core.log('Modal Update Status from Submit', 'fields', fields);
    if (!!state.cbUpdateField) {
      state.cbUpdateField(state.engagement, fields);
    } else {
      updateEngagement({ engagement, update: fields });
    }
  });
  if (!state.___model___ === 'Engagement') {
    return <div>No engagement set</div>;
  }
  const styles = {
    underlineStyle: {
      borderColor: '#715EFF',
    },
  };

  /** @todo research if still in use this flag */
  let hasRejectionReason = false;
  const setStage = (event, index, stage) => {
    updateState({ stage });
  };
  const setMatchStrength = (event, index, matchStrength) => {
    updateState({ matchStrength });
  };

  const setAddInfo = (event, rejectionReasonAdditionalInfo) => {
    updateState({ rejectionReasonAdditionalInfo });
  };

  const setReason = (event, index, rejectionReason) => {
    updateState({ rejectionReason });
  };
  const setStatus = (event, index, status) => {
    updateState({ status });
  };
  const setSt = (event, index, state) => {
    updateState({ state });
  };
  return (
    <MuiThemeProvider>
      <div>
        <div className="Modal-block">
          Stage
          <SelectField
            name="stage"
            value={state.stage}
            onChange={setStage}
            underlineFocusStyle={styles.underlineStyle}
            style={{ float: 'right' }}
          >
            {state.stages}
          </SelectField>
        </div>
        <div className="Modal-block">
          Status
          <SelectField
            name="status"
            value={state.status}
            onChange={setStatus}
            underlineFocusStyle={styles.underlineStyle}
            style={{ float: 'right' }}
          >
            {state.statuses}
          </SelectField>
        </div>
        <div className="Modal-block">
          State
          <SelectField
            name="state"
            value={state.state}
            onChange={setSt}
            underlineFocusStyle={styles.underlineStyle}
            style={{ float: 'right' }}
          >
            {state.states}
          </SelectField>
        </div>
        <div className="Modal-block">
          Strength
          <SelectField
            name="matchStrength"
            value={state.matchStrength}
            onChange={setMatchStrength}
            underlineFocusStyle={styles.underlineStyle}
            style={{ float: 'right' }}
          >
            {state.matchStrengths}
          </SelectField>
        </div>

        {hasRejectionReason && (
          <div className="Modal-block">
            Rej Reason
            <SelectField
              name="rejectionReason"
              value={state.rejectionReason}
              onChange={setReason}
              underlineFocusStyle={styles.underlineStyle}
              style={{ float: 'right' }}
            >
              <MenuItem
                value={''}
                key={''}
                primaryText={'Select None'}
                style={{ color: 'silver' }}
              />
              {state.rejectionReasons}
            </SelectField>
          </div>
        )}

        {hasRejectionReason && (
          <div className="Modal-block">
            RR-Add Info &nbsp;
            <TextField
              name={`rejectionReasonAdditionalInfo`}
              value={state.rejectionReasonAdditionalInfo}
              onChange={setAddInfo}
              multiLine={true}
              onBlur={(ev) => { }}
              rowsMax={5}
              errorText={''}
            />
          </div>
        )}
      </div>
    </MuiThemeProvider>
  );
}

function openEmailThreads(engagement) {
  Streak.getThreads(engagement.boxKey, (threads) => {
    if (!!threads.length) {
      Core.dialog.open({
        title: <>Email Threads</>,
        message: (
          <EmailThreads
            ref={(self) => (openEmailThreads.EmailThreads = self)}
            threads={threads}
            engagement={engagement}
          />
        ),
        style: { minWidth: '60vw' },
        actions: [
          <Button
            className="button-white-cyan"
            onClick={(ev) => Core.dialog.close()}
          >
            Done
          </Button>,
        ],
      });
    } else {
      Core.showMessage(
        "This Streak Box, doesn't have email threads attached yet"
      );
    }
  });
}

function openCommentDialog(engagement) {
  Core.showMessage('Fetching previous comments, wait a moment...');
  Streak.getComments(engagement.boxKey, (comments) => {
    Core.hideMessage();
    Core.dialog.open({
      title: <>Add a new comment</>,
      message: (
        <StreakComments
          ref={(self) => (openCommentDialog.StreakComments = self)}
          boxKey={engagement.boxKey}
          comments={comments}
        />
      ),
      actions: [
        <Button
          className="button-flat-darker"
          onClick={(ev) => Core.dialog.close()}
        >
          Cancel
        </Button>,
        <Button
          className="button-white-cyan"
          onClick={(ev) => {
            openCommentDialog.StreakComments.saveToCloud();
            Core.dialog.close();
          }}
        >
          Submit
        </Button>,
      ],
    });
  });
}

function openNotesDialog(engagement) {
  Core.showMessage('Fetching notes, wait a moment...');
  Streak.getBox(engagement.boxKey, (box) => {
    Core.hideMessage();
    Core.dialog.open({
      title: <>Edit Streak Note</>,
      message: <StreakNotes boxKey={engagement.boxKey} notes={box.notes} />,
      actions: [
        <Button
          className="button-white-cyan"
          onClick={(ev) => Core.dialog.close()}
        >
          Done
        </Button>,
      ],
    });
  });
}

function deleteEngagement(engagement) {
  Core.dialog.open({
    message: `Delete "${engagement._name}" engagement?`,
    actions: [
      <Button className="button-white-cyan" onClick={Core.dialog.close}>
        Cancel
      </Button>,
      <Button
        className="button-flat-darker"
        onClick={(ev) =>
          Engagement.delete(
            engagement.id,
            (response) => {
              Core.showMessage('Engagement was deleted successfully');
              window.location.reload();
            },
            (error) => {
              if (!!error) {
                Core.showMessage(
                  'Can not delete engagement. Please contact support.'
                );
              }
            }
          )
        }
      >
        Confirm
      </Button>,
    ],
  });
}
