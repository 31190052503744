import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';

import { IconButton } from '@mui/material';

import Announcement from '../../lib/Announcement';
import Store from '../../lib/Store';

import onReady from '../../lib/tools/onReady';
import Core from '../../lib/Core';

class AnnoucementBell extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      open: false,
      title: 'New Announcements',
      iconButtonName: 'notifications_active',
    };
    /*
    this.checkForNewAnnouncements();
    setInterval(em => {
      this.checkForNewAnnouncements();
    }, 60 * 1000);
    */
  }
  checkForNewAnnouncements = () => {
    var lastMostRecentAnnouncementDate;
    if (!!Store.get('lastMostRecentAnnouncementDate')) {
      lastMostRecentAnnouncementDate = new Date(
        Store.get('lastMostRecentAnnouncementDate')
      );
    } else {
      // Randomn old date so any announcements would be most recent.
      lastMostRecentAnnouncementDate = new Date('01/01/1070');
    }
    Announcement.getMostRecent({}, (data) => {
      var mostRecentAnnouncementDate;
      if (!!data) {
        mostRecentAnnouncementDate = new Date(data[0].createdAt);
      } else {
        mostRecentAnnouncementDate = new Date(Date.now());
      }
      if (
        !lastMostRecentAnnouncementDate ||
        mostRecentAnnouncementDate.getTime() >
          lastMostRecentAnnouncementDate.getTime()
      ) {
        Store.set('lastMostRecentAnnouncementDate', mostRecentAnnouncementDate);
        onReady(this, 'IconButton').then((em) => {
          this.setState({
            title: 'New Announcements',
            iconButtonName: 'notifications_active',
          });
        });
      } else {
      }
    });
  };
  render() {
    return (
      <NavLink
        exact
        activeClassName="navlink active"
        className="navlink float-right"
        to={Core.getUIVersion() === 3 ? '/v3/announcements' : '/announcements'}
      >
        <IconButton
          title={this.state.title}
          ref={(IconButton) => (this.IconButton = IconButton)}
        >
          <i className="material-icons">{this.state.iconButtonName}</i>
        </IconButton>
      </NavLink>
    );
  }
}

//export default asyncPoll(5 * 1000, onCheckAnnouncements)(this)(AnnoucementBell);
export default AnnoucementBell;
