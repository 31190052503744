
export const engagementMatchStrengthForYes = {
    STRONG_YES: 1,
    YES: 2,
    WEAK_YES: 3,
}

export const engagementMatchStrengthForNo = {
    NO: 5,
    STRONG_NO: 6
}

export const engagementMatchStrength = {
    ...engagementMatchStrengthForYes,
    ...engagementMatchStrengthForNo
};

export const topUniversities = [
    {
        key: 21,
        label: "Elite university"
    },
    {
        key: 23,
        label: "Top ranked university"
    },
    {
        key: 22,
        label: "Ranked universities"
    }
];

export const jobLevels = [
    {
        key: 4,
        label: "Architect"
    },
    {
        key: 5,
        label: "Lead"
    },
    {
        key: 10,
        label: "Staff"
    },
    {
        key: 11,
        label: "Principal"
    },
];

export const allowAttrOnMustHaveOrNot = [
    "education", "experience", "others", "technicalSkills"
];

export const jobTypes = {
    jobsPermitted: "jobsPermitted",
    jobsPitched: "jobsPitched",
    jobsDeclined: "jobsDeclined"
}

export const objectListGroups = {
    selected: {
        key: 'selected',
        label: 'Selected',
        heading: 'Selected',
    },
    toMatch: {
        label: 'TO MATCH',
        key: 'toMatch',
        heading: 'Match',
    },
    toSubmit: {
        label: 'TO SUBMIT',
        key: 'toSubmit',
        heading: 'Submit',
    },
    toProcess: {
        label: 'TO PROCESS',
        key: 'toProcess',
        heading: 'Process',
    },
    jobInProgress: {
        label: 'JOBS IN PROGRESS',
        key: 'jobInProgress',
        heading: 'Active',
    },
    inActive: {
        label: 'INACTIVE',
        key: 'inActive',
        heading: 'Inactive',
    },
    oldNoMatch: {
        label: 'OLD NO MATCH',
        key: 'oldNoMatch',
        heading: 'OldNoMatch',
    },
    noMatch: {
        label: 'NO MATCH',
        key: 'noMatch',
        heading: 'NoMatch',
    },
    debug: {
        label: 'DEBUG',
    }
}

/* FILTERING FIELD FROM DB!! */
export const fieldsForCandidatePage = [
    'id', 
    'email', 
    'phone', 
    'firstName', 
    'lastName', 
    'roles', 
    'visa', 
    'introduced',
    'updatedAt', 
    'technicalSkills', 
    'workLocationIds', 
    'yearsOfExperience', 
    'resumeTxtUrl',
    'resumePdfUrl',
    'resumes',
    'positiveSignals', 
    'negativeSignals',
    'minimumSalary',
    'platformRating',
    'diversity',
    'state',
    'withEngagements',
    'locationCandidate',
    'level',
    'accountId',
    'isDuplicate',
    'isOwnedRightNow',
    'linkedInURL',
    'gitHubURL',
    'officeLocations',
    'desiredEmploymentTypes',

    /* epic-3038-story-3459 - 2021-07-14 µ */
    'candidateLocations',

    /* epic-3038-story3330-m2 - 2021-07-01 µ */
    'inOfficeRemoteFlags',

    // story-3869 | 2021-08-30 Mon µ
    'matchExclusions'

];

export const accountIncludeForCandidate = [
    {
        relation: "account",
        scope: {
            fields : ['id', 'firstName', 'lastName', 'email', 'companyName']
        }
    }
];

// URL Constants - AlphaNumeric ordering
export const CRAFT_CO_BASE_URL = 'https://craft.co';
export const PDF_MICROSERVICE_BASE_URL = 'https://to-pdf.go10x10.com';
export const SOVREN_PARSER_URL = 'https://eu-rest.resumeparsing.com/v9';

// Data Constants - AlphaNumeric ordering
export const FULLDAY_SECOND_REFERENCE = 1;
export const FULLDAY_MILL_SECOND_REFERENCE = 300;
export const SOVREN_FILENAME_PREFIX = 'id_';

// story-3901-m1 | 2021-08-30 Mon µ
// add a comment in the code that says consult10by10 account
export const GITHUB_TOKEN = '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';

export const GITHUB_API_URL = 'https://api.github.com/graphql';

export const MDASH = '—';