/** ============================================ µ
 * @description Recruiter Email [JS]
 *              Library
 * @routes      /engagements
 * @see         EngagementCard | component
 * @createdAt   2021-05-03
 * @updatedAt   2021-12-17 Fri
 * ============================================ */
/* IMPORTS ==================================== */

import Handlebars, { compile } from "handlebars";
import { Checkbox, FlatButton, TextField } from "material-ui";
import moment from "moment";
import React, { useState } from "react";
import EmailPreview from "../../../components/Dialogs/EmailPreview";
import Col from "../../../components/Forms/Col";
import RichTextBox from "../../../components/Forms/RichTextBox";
import Account from "../../Account";
import Core from "../../Core";
import Definition, {
  EMP_MSG_TYPE__ALL_ID,
  EMP_MSG_TYPE__SUBMISSION_ID,
  REC_MSG_TYPE__ALL_ID,
  REC_MSG_TYPE__REJECTION_ID,
  REC_MSG_TYPE__REMINDER_ID,
  REC_MSG_TYPE__STAGE_TRANSITION_ID
} from "../../Definition";
import Engagement from "../../Engagement";
import Job from "../../Job";
import { mapAccount } from "../../models/account";
import { mapEmployers } from "../../models/employer";
import { mapEngagement } from "../../models/engagement";
import Streak, { STATUS_W_10X10_ASK_PERMISSION, STATUS_W_RECRUITER } from "../../Streak";
import TemplateLib from "../../Template.lib";
import cleanHTML from "../../tools/cleanHtml";
import {
  mapEmailsListToString,
  processRecruiterOtherCandidatesReminder,
  sendSafeEmail,
  TEN_BY_TEN_RECRUITER_EMAIL_OBJECT,
  updateReminderSentDates
} from "./Email.lib";

/* CONSTANTS ================================== */

const ROLE_LIMITED_RECRUITER = 'LimitedRecruiter';
const STATUS_W_RECRUITER_PERMISSION = 'W - Recruiter Permission';

/* METHODS ==================================== */

/**
 * Maps Recruiter emailsList
 *
 * into Delivery Arrays according
 *
 * recruiterMessageType - tagId
 *
 * @param {object} props
 * @param {number} props.messageType
 * @param {string} props.recruiterId
 * @param {{name,email}[]} props.emails
 * @param {{name,email}[]} props.to
 * @param {{name,email}[]} props.cc
 * @param {{name,email}[]} props.bcc
 * @returns
 */
async function mapEmailDeliveries(props) {
  const {
    messageType: recMsgTypeTagId = REC_MSG_TYPE__ALL_ID,
    recruiterId,
    emails: emailsList = [],
    to: deliveryTo = [],
    cc: deliveryCc = [],
    bcc: deliveryBcc = [],
    add10by10EmailToCcList = false,
    useMainEmailWhenToIsEmpty = true,
    useMainEmailWhenCcIsEmpty = false,
  } = props;

  const hashEntries = {};

  /**
   * This is adding an email entry to the list of all available emails,
   * using a hash list to avoid duplicates.
   *
   * @param {object} emailEntry
   */
  function setEmailOnCommonList(emailEntry) {
    const { name, email } = emailEntry;
    const hashKey = `${name} <${email}>`;
    if (!hashEntries[hashKey]) {
      emailsList.push(emailEntry);
      hashEntries[hashKey] = true;
    }
  }

  const recruiter = mapAccount(await Account.get(recruiterId));
  const {
    _name: recruiterFullname,
    email: recruiterEmail,
    emailsList: recruiterEmailsList = []
  } = recruiter;

  /** Main Account Email */
  const recruiterMainEmail = {
    accountType: "Recruiter",
    name: recruiterFullname,
    email: recruiterEmail
  };
  setEmailOnCommonList(recruiterMainEmail);

  recruiterEmailsList.forEach(({
    name,
    email,
    to = [],
    cc = [],
    bcc = []
  }) => {
    const emailEntry = {
      accountType: "Recruiter",
      name,
      email
    };
    if (
      to.includes(REC_MSG_TYPE__ALL_ID) ||
      to.includes(recMsgTypeTagId)
    ) {
      deliveryTo.push(emailEntry);
      setEmailOnCommonList(emailEntry);
    }
    if (
      cc.includes(REC_MSG_TYPE__ALL_ID) ||
      cc.includes(recMsgTypeTagId)
    ) {
      deliveryCc.push(emailEntry);
      setEmailOnCommonList(emailEntry);
    }

    if (
      bcc.includes(REC_MSG_TYPE__ALL_ID) ||
      bcc.includes(recMsgTypeTagId)
    ) {
      deliveryBcc.push(emailEntry);
      setEmailOnCommonList(emailEntry);
    }

  });

  if (!deliveryTo.length && useMainEmailWhenToIsEmpty) {
    deliveryTo.push(recruiterMainEmail);
  }

  if (!deliveryCc.length && useMainEmailWhenCcIsEmpty) {
    deliveryCc.push(recruiterMainEmail);
  }

  if (add10by10EmailToCcList) {
    deliveryCc.push(TEN_BY_TEN_RECRUITER_EMAIL_OBJECT);
  }

  setEmailOnCommonList(TEN_BY_TEN_RECRUITER_EMAIL_OBJECT);

  /** µ FOR DEBUG PURPOSES */
  console.debug('µ:mapEmailDeliveries', {
    REC_MSG_TYPE__ALL_ID,
    recMsgTypeTagId,
    recruiter,
    recruiterMainEmail,
    emailsList,
    deliveryTo,
    deliveryCc,
  });
  /** */

  return {

    /** Main emails for this recruiter */
    recruiterMainEmail,

    /** All emails related to this recruiter */
    recruiterEmails: emailsList,

    /**
     * All emails related to this recruiter,
     * and accepted for delivery "To",
     * on this type of message.
     */
    recruiterToList: deliveryTo,

    /**
     * All emails related to this recruiter,
     * and accepted for delivery "Cc",
     * on this type of message.
     * If no recruiter.emailList doesn't exists,
     * this will return the main email.
     */
    recruiterCcList: deliveryCc,

    /**
     * All emails related to this recruiter,
     * and accepted for delivery "Bcc",
     * on this type of message.
     */
    recruiterBccList: deliveryBcc,

  };
}

/**
 * Returns a promise for a list of jobs, formatted for reminder email
 *
 * If askPermission flag is true
 * - The engagement status will hidden from the job string
 * - If recruiter is limited and employer is not in it's own whitelist
 * -- the employer name will be replacing for emp. ID
 * -- and the wil be placed an advice message
 *
 * @see RecruiterEmailLib.openReminderEmailPreview
 * @see RecruiterEmailLib.openAskPermissionEmailPreview
 */
async function getJobListForReminderEmail({
  engagements,
  askPermission: flagAskPermission
}) {
  return await Promise.all(
    engagements.map(async (eng, index) => {
      let flagHideEmployerName = false;
      if (
        flagAskPermission &&
        (eng.candidate.recruiter.role === ROLE_LIMITED_RECRUITER)
      ) {
        const whiteList = mapEmployers(
          await Account.getRecruiterWhiteList(
            eng.candidate.recruiter.id
          )
        );
        flagHideEmployerName = true;
        whiteList.find(({ id: _empID }) => {
          if (_empID === eng.employer.id) {
            flagHideEmployerName = false;
            return true;
          }
          return false;
        });
        console.debug(
          'µ:getJobListForReminderEmail',
          { eng, whiteList }
        );
      }
      let rankingFlag = (
        [1, 5].includes(+eng.candidate.platformRating)
          ? `<b> ** </b>`
          : [2].includes(+eng.candidate.platformRating)
            ? `<b> * </b>`
            : ``
      );
      const permissionFlag = String(eng.status).match(/permission/i);
      const lineLastPart = [];
      const linkToCandidateEdit = (`
          <a href="${Core.getPath(`candidate/edit-section/${eng.candidate.id}`)}" target="_blank">
            ${permissionFlag ? 'permit' : 'update'}
          </a>
        `);
      if (permissionFlag) {
        lineLastPart.push(`
          <a href="${Core.getPath(`jobs`)}">copy jobs (green button)</a>
        `);
      }
      else if (String(eng.status).match(/screen|onsite/i)) {
        lineLastPart.push('<i><b>prep/check in with candidates before and after</b></i>');
      }
      else if (String(eng.status).match(/homework/i)) {
        lineLastPart.push('<i><b>check in with candidate on homework status</b></i>');
      }
      else if (String(eng.status).match(/availability|schedule/i)) {
        lineLastPart.push('<i><b>check if candidate has scheduled next step</b></i>');
      }
      const limitedRecruiterMessage = (
        flagHideEmployerName
          ? '<br/><i>ask us the employer name if your candidate may be interested&nbsp;</i>'
          : ''
      );
      let breakLine = '';
      const nextCando = engagements[index + 1]?.candidate?._name;
      if ((nextCando !== undefined) && (nextCando !== eng.candidate._name)) {
        breakLine = `<br/>&nbsp;&nbsp;`;
      }
      return (`
        <li>
          ${eng.candidate._name}&nbsp;|&nbsp;
          ${flagAskPermission ? '' : eng.status + '&nbsp;-&nbsp;'}
          ${flagHideEmployerName ? (eng.employer.proxyName || eng.employer.id) : eng.employer.name}&nbsp;-&nbsp;
          ${eng.job.jobTitle}&nbsp;|&nbsp;
          ${linkToCandidateEdit}&nbsp;|&nbsp;
          ${lineLastPart.map(n => !!n && n.trim()).join(', ')}&nbsp;
          ${rankingFlag}
          ${limitedRecruiterMessage}
          ${breakLine}
        </li>
      `);
    })
  );
}

async function openReminderEmailPreview({
  recruiterId,
  engagements = []
}) {

  const recruiter = mapAccount(await Account.get(recruiterId));

  const {
    _name: currentRecruiterName,
  } = recruiter;

  /** µ FOR DEBUG PURPOSES */
  console.debug('µ:openReminderEmailPreview', { recruiterId, recruiter });
  /** */

  const waitingForCandoLegibleStatuses = ["W - Candidate",
    "W - Candidate Availability",
    "W - Candidate Details",
    "W - Candidate Homework",
    "W - Candidate Permission",
    "W - Candidate Sign Offer",
    "W - Cando-Emp Availability"
  ];
  const waitingForRecruiterLegibleStatuses = [
    "W - Recruiter",
    "W - Recruiter Details",
    "W - Recruiter Homework",
    "W - Recruiter Intro",
    STATUS_W_RECRUITER_PERMISSION
  ];
  const waitingForEventLegibleStatuses = [
    "W - Screen",
    "W - Screen Coding",
    "W - Screen Informal In Person",
    "W - Screen Technical",
    "W - Onsite",
    "W - Onsite Informal In Person"
  ];

  /*
   * for future use
   *
  const waitingFor10by10LegibleStatuses = [
    "W - 10x10",
    "w - 10x10 Ask Details",
    "W - 10x10 Ask Permission",
    "W - 10x10 Intake Call",
    "W - 10x10 Notify Rejection",
    "W - 10x10 Review",
    "W - 10x10 Schedule",
    "W - 10x10 Submission"
  ];
  */

  /*
   * for future use
   *
  const holdLegibleStatuses = [
    "H - 10x10",
    "H - Candidate",
    "H - Employer",
    "H - Recruiter"
  ];
  */

  const allLegibleStatuses = [
    ...waitingForCandoLegibleStatuses,
    ...waitingForRecruiterLegibleStatuses,
    ...waitingForEventLegibleStatuses
  ];

  const validEngagements = [];
  const otherEngagements = [];

  engagements.forEach(engagement => {

    if (

      (engagement.candidate.recruiter.id === recruiterId) &&

      !/h -/i.test(engagement.status)

    ) {

      /* ENGAGEMENTS WHERE WE GET CANDIDATES LIST FOR EMAIL REMINDER */
      if (allLegibleStatuses.includes(engagement.status)) {
        validEngagements.push(engagement);
      }

      /* ENGAGEMENTS WHERE WE GET OTHER CANDIDATES LIST */
      else if (
        !/confirmation|submission/i.test(engagement.stage) &&
        !/w - 10x10/i.test(engagement.status)
      ) {
        otherEngagements.push(engagement);
      }

    }

  });

  /** µ FOR DEBUG PURPOSES * /
  console.debug('µ:openRecruiterReminderEmailPreview',{ validEngagements });
  console.debug('µ:openRecruiterReminderEmailPreview',{ otherEngagements });
  /** */

  if (!!validEngagements.length) {
    const topMsg = (`
      <p>
        <strong>Please</strong>
        <ul>
          <li>
            Use the "permit" link to quickly go to the candidate's record to update candidate's permissions.  DO NOT email.  Platform timestamp update is used for representation, not email.
          </li>
          <li>
            Similarly the "update" link is provided as a convience to quickly go to the candidate's record.
          </li>
          <li>
            More submissions -> more placements. Match candidates to all jobs to avoid delays and others permitting roles before you.
          </li>
        </ul>
      </p>
    `);

    const bottomMsg = (`
      <p>
        <strong>Note:</strong>
        <ul>
          <li>
            Current candidate status: Platform provides real-time updates in the candidate tab; email response is best effort.
          </li>
          <li>
            Update employer about your candidate: please write notes and emails in a way that we can forward directly to the client to avoid both delay and translation errors.
          </li>
          <li>
            '**' & '*': higher priority candidates
          </li>
          <li>
            This message is auto generated by the 10 By 10 platform to help you keep track of your candidates' status.  Suggestions/feedback welcome!
          </li>
        </ul>
      </p>
    `);

    validEngagements.sort((a, b) => {
      return (
        (a.candidate.lastName > b.candidate.lastName)
          ? 1
          : (a.candidate.lastName === b.candidate.lastName)
            ? (a.candidate.firstName > b.candidate.firstName)
              ? 1
              : -1
            : -1
      );
    });

    const processedCandidatesList = await getJobListForReminderEmail({ engagements: validEngagements });

    const contentEngWrapped = (`
      <p>
        <strong>Your todo:</strong>
        <ul>${processedCandidatesList.join('')}</ul>
      </p>
    `);

    const finalContent = [];
    finalContent.push(topMsg);

    finalContent.push(contentEngWrapped);


    if (!!otherEngagements.length) {
      finalContent.push(
        processRecruiterOtherCandidatesReminder({
          engagements: otherEngagements
        })
      );
    }

    finalContent.push(bottomMsg);

    /** This is for recruiter reminder */
    const {
      recruiterEmails = [],
      recruiterToList = [],
      recruiterCcList = []
    } = await mapEmailDeliveries({
      messageType: REC_MSG_TYPE__REMINDER_ID,
      recruiterId: recruiter.id,
      add10by10EmailToCcList: true,
      useMainEmailWhenToIsEmpty: true,
      useMainEmailWhenCcIsEmpty: false
    });

    Core.dialog.open({
      title: <>Recruiter Reminder (old)</>,
      message: (
        <EmailPreview
          ref={self => Core.setKeyValue('RecruiterReminderEmailPreview', self)}
          emails={recruiterEmails}
          to={recruiterToList}
          cc={recruiterCcList}
          subject={`${currentRecruiterName} - 10x10 Pending Candidates Who Need Your Attention`}
          body={finalContent.filter(el => !!el).join(" ")}
        />
      ),
      className: "p-0",
      actions: [
        <FlatButton
          label="Cancel"
          className="button-flat-darker"
          onClick={ev => {
            Core.dialog.close();
          }}
        />,
        <FlatButton
          label="Send"
          className="button-white-cyan"
          onClick={ev => {
            Core.dialog.close();
            sendSafeEmail(
              {
                ...Core.getKeyValue('RecruiterReminderEmailPreview').getParams(),
                source: 'RecruiterEmail.lib.js'
              },
              response => {
                Core.showMessage("Email sent");
                if (!response.threadId) {
                  Core.showFailure("It is missing the threadGmailId");
                } else {
                  const engs = validEngagements;
                  const next = em => {
                    if (!!engs.length) {
                      const engagement = engs.pop();
                      /** µ DISCARDED (future cleanup) * /
                      Streak.putEmailInBox(
                        {
                          boxKey: eng.boxKey,
                          threadGmailId: response.threadId
                        },
                        response => {
                        /** */
                      Core.log("putEmailInBox", "success", response);
                      updateReminderSentDates({
                        engagement,
                        next,
                        reminder: {
                          email: recruiterToList.map(({ email }) => email).join(', '), // email address reminder was sent to
                          date: new Date().toISOString(), // date - time reminder was set
                          source: 'recruiter', // source of email, e.g. job, employer, recruiter, agency, null (for candidate)
                          type: 'primary' // primary or secondary
                        }
                      });
                      /** µ DISCARDED (future cleanup) * /
                        }
                      );
                      /** */
                    }
                  };
                  next();
                }
              },
              error => Core.showFailure(error)
            );
            /** */
          }}
        />
      ]
    });
  } else {
    Core.showMessage("There are no Engagements for sending a reminder");
  }
};

async function openReminderEmailPreviewV2({
  recruiterId,
  engagements
}) {

  const template = await TemplateLib.getBy({
    type: 'email',
    name: 'recruiter-todo-reminder'
  });

  const renderResponse = await TemplateLib.getRender({
    templateId: template.id,
    recruiterId
  });

  const { errors = [], rendered = {}, mixins = {} } = renderResponse;

  console.debug({ errors, rendered, mixins, recruiterId });

  if (!errors.length) {

    const {
      subject = '',
      bodyHtml: body = ''
    } = rendered;

    const {
      from,
      emails = [],
      main = {},
      to = [],
      cc = [],
      bcc = []
    } = mixins;

    Core.dialog.open({
      title: <>Recruiter Reminder (new)</>,
      message: (
        <EmailPreview
          ref={self => Core.setKeyValue('RecruiterReminderEmailPreview', self)}
          emails={emails}
          from={from}
          to={!!to.length ? to : [main]}
          cc={cc}
          bcc={bcc}
          subject={subject}
          body={body}
        />
      ),
      className: "p-0",
      actions: [
        <FlatButton
          label="Cancel"
          className="button-flat-darker"
          onClick={ev => {
            Core.dialog.close();
          }}
        />,
        <FlatButton
          label="Send"
          className="button-white-cyan"
          onClick={ev => {
            Core.dialog.close();
            sendSafeEmail(
              {
                ...Core.getKeyValue('RecruiterReminderEmailPreview').getParams(),
                source: 'RecruiterEmail.lib.js'
              },
              response => {
                Core.showMessage("Email sent");
                if (!response.threadId) {
                  Core.showFailure("It is missing the threadGmailId");
                } else {
                  !!engagements?.length && engagements.filter(engagement => (
                    (engagement.candidate?.accountId === recruiterId) &&
                    (!!engagement.status?.match(STATUS_W_RECRUITER))
                  )).forEach(async engagement =>
                    updateReminderSentDates({
                      engagement,
                      reminder: {
                        email: to, // email address reminder was sent to
                        date: new Date().toISOString(), // date - time reminder was set
                        source: 'recruiter', // source of email, e.g. job, employer, recruiter, agency, null (for candidate)
                        type: 'primary' // primary or secondary
                      }
                    })
                  );
                }
              },
              error => Core.showFailure(error)
            );
            /** */
          }}
        />
      ]
    });
  } else {
    Core.showMessage("There are no Engagements for sending a reminder");
  }
};

/**
   * Opens a preview email dialog
   *
   * On accept it, an email will be send
   *
   * Only includes recruiter's engagements with status match 'ask permission'
   *
   * Then each engagement.sentReminderDates will be updated
   *
   * @see Google.sendEmail;
   * @see EmailLib.updateReminderSentDates;
   * @see openReminderEmailPreview;
   */
async function openAskPermissionEmailPreview({
  recruiterId,
  engagements: allEngagements,
  onDone = () => { }
}) {

  const recruiter = mapAccount(await Account.get(recruiterId));

  const {
    email: currentRecruiterEmail,
    _name: currentRecruiterName,
  } = recruiter;

  const engagements = [...allEngagements].filter(eng => (
    (eng.candidate.recruiter.id === recruiterId)
    &&
    !!eng.status?.match(/ask permission/i)
  ));

  if (!!engagements.length) {

    engagements.sort((a, b) => {
      return (
        (a.candidate.lastName > b.candidate.lastName)
          ? 1
          : (a.candidate.lastName === b.candidate.lastName)
            ? ((a.candidate.firstName > b.candidate.firstName)
              ? 1
              : -1
            )
            : -1
      );
    });

    const jobList = await getJobListForReminderEmail({
      engagements,
      askPermission: true
    });

    const emailBody = (`
      <p>
        To avoid delays and the possibility of other agencies permitting roles before you, please look for all matches to maximize your placement probability.
      </p>
      <p>
        It's your lucky day - we found more matches below. <u>Please VERIFY requirements, pre-screen questions, location, visa, comp, company size, etc</u>
      </p>
      <p>
        <ul>
          ${jobList.join('')}
        </ul>
      </p>
      <p>
        Once you have your candidate’s permission, please go to the candidate tab and mark "jobs that candidate has APPROVED for submission," and answer employers’ required pre-screen questions ASAP.  In the rare event of a duplicate submission, the first recruiter to do so will have the representation.  
      </p>
      <p>
        Please share your suggestions on how to help you discover more matches on our platform easier since we may NOT do additional matches for you depending on our other priorities. 
      </p>
      <p>
      <p>
      Regards,<br/>
      10by10 team
      </p>
      <p>
        -------------------------------------------------<br/>
        This email and any accompanying attachments contain information that is confidential or privileged. The information is intended for the use of the individual or entity named in this transmission.  If you are not the intended recipient, be aware that any disclosure, copying, distribution or use of the enclosed documents is strictly prohibited. If you received this message in error, please notify the sender and then delete it.
      </p>
    `);

    const emails = [{ name: currentRecruiterName, email: currentRecruiterEmail }];

    Core.dialog.open({
      title: <>Ask Permission (Old)</>,
      message: (
        <EmailPreview
          ref={self => Core.setKeyValue('RecruiterAskPermissionEmailPreview', self)}
          emails={emails}
          to={emails}
          subject={`${currentRecruiterName} - Great News - new matches for your candidate(s) - more submissions, more placements`}
          body={emailBody}
        />
      ),
      className: "p-0",
      actions: [
        <FlatButton
          label="Cancel"
          className="button-flat-darker"
          onClick={ev => {
            Core.dialog.close();
          }}
        />,
        <FlatButton
          label="Send"
          className="button-white-cyan"
          onClick={ev => {
            Core.dialog.close();
            const updateEngagements = ({ engagements = [] }) => {
              const engs = [...engagements];
              const next = em => {
                if (!!engs.length) {
                  const eng = engs.pop();
                  // Core.log("putEmailInBox", "success", response);
                  const reminderSentDates = { ...eng.reminderSentDates };
                  reminderSentDates['recruiter'] = new Date().toISOString();
                  Engagement.update(eng, {
                    status: STATUS_W_RECRUITER_PERMISSION,
                    reminderSentDates
                  }, next, () => {
                    Core.showMessage(
                      `Update engagement status ${STATUS_W_RECRUITER_PERMISSION} to mongo failed for engagement id ${Object(eng).id}`
                    );
                    next();
                  });
                }
                else {
                  onDone();
                }
              };
              next();
            }
            /** µ LOCAL CONDITION FOR TEST PURPOSES */
            if (Core.isLocalHost()) {
              updateEngagements({ engagements });
            }
            else {
              sendSafeEmail(
                {
                  ...Core.getKeyValue('RecruiterAskPermissionEmailPreview').getParams(),
                  source: 'RecruiterEmail.lib.js'
                },
                response => {
                  Core.showMessage("Email sent");
                  if (!response.threadId) {
                    Core.showFailure("It is missing the threadGmailId");
                  } else {
                    updateEngagements({ engagements });
                  }
                },
                error => Core.showFailure(error)
              );
            }
          }}
        />
      ]
    });
  } else {
    Core.showMessage("There are no Engagements to ask permission");
  }
};

/**
 * Opens a preview email dialog
 *
 * On accept it, an email will be send
 *
 * Only includes recruiter's engagements with status match 'ask permission'
 *
 * Then each engagement.sentReminderDates will be updated
 *
 * @see Google.sendEmail;
 * @see EmailLib.updateReminderSentDates;
 * @see openReminderEmailPreview;
 */
async function openAskPermissionEmailPreviewV2({
  recruiterId,
  engagements,
  onDone = () => { }
}) {

  const template = await TemplateLib.getBy({
    type: 'email',
    name: 'recruiter-ask-permission'
  });

  const renderResponse = await TemplateLib.getRender({
    templateId: template.id,
    recruiterId
  });

  const { errors = [], rendered = {}, mixins = {} } = renderResponse;

  console.debug({ errors, rendered, mixins, recruiterId });

  if (!errors.length) {

    const {
      subject = '',
      bodyHtml: body = ''
    } = rendered;

    const {
      from,
      emails = [],
      main = {},
    } = mixins;

    Core.dialog.open({
      title: <>Ask Permission (New)</>,
      message: (
        <EmailPreview
          ref={self => Core.setKeyValue('RecruiterAskPermissionEmailPreview', self)}
          from={from}
          emails={emails}
          to={[main]}
          subject={subject}
          body={body}
        />
      ),
      className: "p-0",
      actions: [
        <FlatButton
          label="Cancel"
          className="button-flat-darker"
          onClick={ev => {
            Core.dialog.close();
          }}
        />,
        <FlatButton
          label="Send"
          className="button-white-cyan"
          onClick={ev => {
            Core.dialog.close();
            const updateEngagements = ({ engagements = [] }) => {
              const engs = [...engagements.filter(e => (
                (e.candidate?.accountId === recruiterId)) &&
                (e.status === STATUS_W_10X10_ASK_PERMISSION)
              )];
              const next = em => {
                if (!!engs.length) {
                  const eng = engs.pop();
                  // Core.log("putEmailInBox", "success", response);
                  const reminderSentDates = { ...eng.reminderSentDates };
                  reminderSentDates['recruiter'] = new Date().toISOString();
                  Engagement.update(eng, {
                    status: STATUS_W_RECRUITER_PERMISSION,
                    reminderSentDates
                  }, next, () => {
                    Core.showMessage(
                      `Update engagement status ${STATUS_W_RECRUITER_PERMISSION} to mongo failed for engagement id ${Object(eng).id}`
                    );
                    next();
                  });
                }
                else {
                  onDone();
                }
              };
              next();
            }
            sendSafeEmail(
              {
                ...Core.getKeyValue('RecruiterAskPermissionEmailPreview').getParams(),
                source: 'RecruiterEmail.lib.js'
              },
              response => {
                Core.showMessage("Email sent");
                if (!response.threadId) {
                  Core.showFailure("It is missing the threadGmailId");
                } else {
                  updateEngagements({ engagements });
                }
              },
              error => Core.showFailure(error)
            );
          }}
        />
      ]
    });
  } else {
    Core.showMessage("There are no Engagements to ask permission");
  }
};

async function openRejectionEmailPreview({
  rejectionReason,
  rejectionReasonAdditionalInfo,
  engagement,
  EngagementCardController,
  StageColumnController,
}) {
  const {
    stage = '',
    job = {},
    candidate = {},
    employer = {},
    recruiter = {},
  } = engagement;
  const {
    id: jobId
  } = job;
  let contentBodyFooter;
  if (stage.match(/review/i)) {
    const url = Core.getPath(`job/view/${jobId}?insider-scoop`);
    const here = `<a href="${url}" target="_blank" rel="noopener noreferrer">here</a>`
    contentBodyFooter = `Click ${here} to see all the known rejection reasons associated with this role under "insider scoop".`;
  }
  else if (stage.match(/screen|onsite/i)) {
    const url = Core.getPath(`job/view/${jobId}?interview-process`);
    const here = `<a href="${url}" target="_blank" rel="noopener noreferrer">here</a>`
    contentBodyFooter = `Click ${here} to see all the known rejection reasons associated with this role under "Interview Process".`;
  }
  else if (stage.match(/offer|hire|guarantee/i)) {
    contentBodyFooter = '';
  }
  else {
    contentBodyFooter = `
      We do share known rejection reasons to help you calibrate the search.<br/>
      Rejection reasons before client interviews are under "Insider Scoop" section.<br/>
      Rejection reasons post interviews are under the "Job Specific Interview Process" section.<br/>
    `;
  }
  const subject = cleanHTML(`
    10x10 Update - Your candidate, ${candidate._name}, 
    for ${employer.name}, 
    has been Rejected
  `);

  /* story-3296-m3 | 2021-07-13 Tue µ */
  const html = cleanHTML(`
    <p>Hi there,</p>
    <p>
      ${candidate._name}, who applied to ${employer.name} has been rejected at ${engagement.stage} stage.
    </p>
    <p>
      ${contentBodyFooter}
    </p>
  `);

  function RejectionReasonAdditionalInfo({ value, onChange }) {
    const [_value, setValue] = useState(value);
    Core.setKeyValue('RejectionReasonAdditionalInfo', () => _value);
    return (
      <TextField
        name="rejectionPhrase"
        placeholder="Introduce additional info"
        floatingLabelText="Rejection Reason (additional info)"
        floatingLabelFixed
        multiLine
        rowsMax={3}
        value={_value}
        onChange={(ev, rejectionReasonAdditionalInfo) => {
          setValue(rejectionReasonAdditionalInfo);
          onChange(rejectionReasonAdditionalInfo);
        }}
        fullWidth
      />
    );
  }

  function updateEngagementRejectionReasonAdditionalInfo() {
    Engagement.update(engagement, { rejectionReasonAdditionalInfo: Core.getKeyValue('RejectionReasonAdditionalInfo')() });
  }

  const openDialog = async em => {
    const setExampleOfRejected = (em) => {
      const line = cleanHTML(
        "<p>" +
        [
          engagement.stage,
          moment().format("MM/DD/YY"),
          `<a href="/#/candidate/edit/${candidate.id}" target="_blank">${candidate._name}</a>`,
          Definition.getLabel(
            "platformRating",
            candidate.platformRating
          ),
          rejectionReason,
          engagement.rejectionReasonAdditionalInfo !==
          rejectionReason &&
          engagement.rejectionReasonAdditionalInfo,
        ]
          .filter((e) => !!e && !!String(e).trim().length)
          .join(", ") +
        ".</p>"
      );
      const examplesOfRejected =
        typeof job.examplesOfRejected === "string"
          ? line + job.examplesOfRejected
          : line;
      Job.update(job.id, { examplesOfRejected });
    };

    /** This is for rejection email */
    const {
      recruiterToList = [],
      recruiterCcList = []
    } = await mapEmailDeliveries({
      messageType: REC_MSG_TYPE__REJECTION_ID,
      recruiterId: recruiter.id,
      add10by10EmailToCcList: false,
      useMainEmailWhenToIsEmpty: true,
      useMainEmailWhenCcIsEmpty: false
    });

    const deliveryTo = mapEmailsListToString(recruiterToList);

    Core.dialog.open({
      title: (
        <div className="email-recruiter-rejection">
          <div>{rejectionReason}</div>
          <div className="cols-2">
            <RejectionReasonAdditionalInfo
              value={rejectionReasonAdditionalInfo || rejectionReason}
              onChange={(rejectionReasonAdditionalInfo) => {
                EngagementCardController.setState({ rejectionReasonAdditionalInfo });
              }}
            />
            <div className="stage-col-include-reason">
              <div>
                <Checkbox
                  label="Include Reason"
                  checked={StageColumnController.state.includeRejectionReason}
                  onCheck={(ev, includeRejectionReason) => {
                    StageColumnController.setState(
                      (state) => {
                        state.includeRejectionReason = includeRejectionReason;
                        return state;
                      },
                      (then) => openDialog()
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ),
      message: (
        <div>
          <h2 className="c-purple">Email Preview</h2>
          <Col className="d-flex p-0 flex-align-left-center" fullWidth>
            <label className='m-0 col1'>To:</label>&nbsp;
            <span className='c-gray'>{deliveryTo}</span>
          </Col>
          <Col className="d-flex p-0 flex-align-left-center" fullWidth>
            <label className='m-0 col1'>Subject:</label>&nbsp;
            <span className='c-gray'>{subject}.</span>
          </Col>
          <RichTextBox
            name="body"
            defaultValue={html}
            value={StageColumnController.state.emailRejectionHTML}
            onChange={(emailRejectionHTML) => {
              StageColumnController.setState({ emailRejectionHTML });
            }}
            _onRejectionCase
          />
          {!!StageColumnController.state.includeRejectionReason && (
            <p>{Core.getKeyValue('RejectionReasonAdditionalInfo')()}</p>
          )}
        </div>
      ),
      style: { width: "80%", minWidth: "320px" },
      actions: [
        <FlatButton
          label="Send Email"
          className="button-flat-darker"
          onClick={event => {
            updateEngagementRejectionReasonAdditionalInfo();
            setExampleOfRejected();
            sendSafeEmail(
              {
                from: Core.getNewCandidateReceiverNotiFromEmail(),
                to: deliveryTo,
                cc: mapEmailsListToString(recruiterCcList),
                subject,
                html:
                  (StageColumnController.state.emailRejectionHTML || html) +
                  (
                    StageColumnController.state.includeRejectionReason
                      ? `<p>${Core.getKeyValue('RejectionReasonAdditionalInfo')()}</p>`
                      : ''
                  ),
              },
              (response) => {
                Core.showMessage("Email sent");
                if (!response.threadId) {
                  Core.showFailure("It is missing the threadGmailId");
                } else {
                  // const eng = parentState;
                  Streak.putEmailInBox(
                    {
                      boxKey: engagement.boxKey,
                      threadGmailId: response.threadId,
                    },
                    (response) =>
                      Core.log("putEmailInBox", "success", response)
                  );
                }
              },
              (error) => Core.showFailure(error)
            );
            Core.dialog.close();
          }}
        />,
        <FlatButton
          label="Don't Send"
          className="button-white-cyan"
          onClick={(ev) => {
            updateEngagementRejectionReasonAdditionalInfo();
            setExampleOfRejected();
            Core.dialog.close();
          }}
        />,
      ],
    });
  };
  openDialog();
}

/**
 * Generates the inputs for stage transition email preview.
 * 
 * Eventually this method will be passed to BE, 
 * for that this require the engagementId instead the engagement object.
 * 
 * @param {object} options
 * @param {string} options.engagementId
 * @param {string} options.eventType screen|onsite|offer|interview|firstScreenInterview|status
 * @param {string} options.previousStage
 * @param {string} options.previousStatus
 * @param {string} options.interviewDate Date ISO string
 * @param {boolean} options.isFullday
 * @returns 
 */
async function generateStageTransitionEmailInputs({
  engagementId,
  eventType,
  previousStage,
  previousStatus,
  interviewDate,
  isFullday,
}) {

  // Following must be updated on BE to get the engagement and containing its related entities.
  const engagement = mapEngagement(await Engagement.get(engagementId))
  console.debug({ engagement });
  if (!engagement.id) { return {}; }

  async function generateDeliveriesList({ engagement }) {

    const emails = [];

    engagement.actionOwner.email &&
      emails.push({
        name: engagement.actionOwner._name || "Action Owner",
        email: engagement.actionOwner.email
      });

    // note: this is for stage transition email
    const {
      // recruiterEmailEntry = {},
      recruiterEmails = [],
      recruiterToList = [],
      recruiterCcList = []
    } = await mapEmailDeliveries({
      messageType: REC_MSG_TYPE__STAGE_TRANSITION_ID,
      recruiterId: engagement.recruiter.id,
      add10by10EmailToCcList: false,
      useMainEmailWhenToIsEmpty: true, // TO
      useMainEmailWhenCcIsEmpty: false  // CC
    });

    recruiterEmails.forEach(m => emails.push(m));

    engagement.employer.primaryContactEmail &&
      emails.push({
        accountType: "Employer",
        name: engagement.employer.primaryContactName || "Primary Contact",
        email: engagement.employer.primaryContactEmail
      });
    engagement.job.emailsList
      .filter(({ to }) => (
        to.includes(EMP_MSG_TYPE__ALL_ID) ||
        to.includes(EMP_MSG_TYPE__SUBMISSION_ID)
      )).forEach(contact => emails.push({
        accountType: `${engagement.job.jobTitle}(job)`,
        ...contact
      }));
    engagement.candidate.email &&
      emails.push({
        accountType: "Candidate",
        name: engagement.candidate._name || "Candidate",
        email: engagement.candidate.email
      });

    return {
      emails,
      to: recruiterToList,
      cc: recruiterCcList,
    };

  }

  function generateSubject({ engagement, eventType }) {
    const { job, candidate } = engagement;
    const { employer } = job;
    // const { recruiter } = candidate;
    const types = {

      // this is triggered when stage transition to screen
      screen: 'Candidate Stage Update',

      // this is triggered when stage transition to onsite
      onsite: 'Candidate Stage Update',

      // this is triggered when set new eventDate OLD
      interview: 'Candidate Interview Alert',

      // this is triggered when stage transition to offer
      offer: 'Potential Offer Alert',

      /*
      this is triggered when stage is screen or onsite NEW
      and status transition someone of following statuses
      W-Employer Availability
      W-Employer Details
      W-Cando-Emp Availability
      W-Candidate Availability
      W-Candidate Details
      W-Candidate Homework
      W-Recruiter Details
      */
      status: 'Candidate Status Update',

      // this is triggered when set new first eventDate NEW
      firstScreenInterview: 'Candidate First Interview Alert',

    };
    return cleanHTML(`
      [ 10x10 ${types[eventType]} ] ${candidate.firstName} ${candidate.lastName} - ${employer.name}, moved to ${engagement.stage} stage - ${engagement.status}
    `);
  }

  function generateBody({
    engagement,
    previousStage,
    previousStatus,
    interviewDate,
    isFullday,
    eventType,
  }) {

    const { job, candidate } = engagement;
    const { employer } = job;
    const { recruiter } = candidate;

    const template = compile(`
      <style>strong{color:purple;}</style>
      <p>
        Hi {{recruiter.firstName}}, Please see the new updates:
      </p>
      <p>
        Candidate: {{candidate.firstName}} {{candidate.lastName}}<br/>
        Employer: {{employer.name}}<br/>
        Role: {{rol}} - {{job.jobTitle}}<br/>
        Submitted: {{submitted}}
      </p>
      <p>
        Stage: <strong>{{engagement.stage}}{{stageExtraWord}}</strong>{{previousStage}}<br/>
        Status: <strong>{{engagement.status}}</strong>{{previousStatus}}
        {{#if interviewDate}}
          <br/>Interview Date: {{moment interviewDate isFullday}}
        {{/if}}
      </p>
      {{#if firstScreenInterview}}
        <p>
          Please make sure your candidate shows up to the interview well prepared and can explain why they are interested in this role.  If you have any concerns that the candidate may not be well prepared for the interview, please alert us right away if we need to give the interviewer a heads up.
        </p>
      {{/if}}
      {{#if offer}}
        <p>
          This is a heads up on a potential offer that we are now tracking very closely. We don't have details on what steps are left for their offer decision yet. We will let you know when we have more details. Please do the same if you have any information from the candidate side.
        </p>
      {{/if}}
      <p>
        <a href="{{jobLink}}" target="_blank">See job details</a> - to copy JD for your candidate, click on the copy icon near the top right corner of the job details card.
      </p>
      <p>
        {{summary job}}
      </p>
      <p> ----------- </p>
      <p>
        Team 10x10
      </p>
      <p>
        PS. This email is automatically generated by the 10x10 platform to provide you real time update
      </p>
    `.replace(/ {2,}/g, ' ').trim());

    Handlebars.registerHelper('moment', function (interviewDate, isFullday) {
      let interviewDateWithFullday;
      if (interviewDate) {
        interviewDateWithFullday = isFullday ? moment(interviewDate).format("MM/DD") : moment(interviewDate).format("MM/DD, h:mm a");
        if (isFullday) {
          interviewDateWithFullday = `${interviewDateWithFullday} (interview time unknown)`
        }
      }
      // console.debug({ interviewDate, isFullday });
      return new Handlebars.SafeString(interviewDateWithFullday);
    });

    Handlebars.registerHelper('summary', function (job) {
      // console.debug('preview', Job.getPreview(job));
      return new Handlebars.SafeString(Job.getPreview(job));
    });

    return template(
      {
        engagement,
        recruiter,
        employer,
        candidate,
        job,
        interviewDate,
        isFullday,
        rol: Definition.getLabel('rol', job.roles[0]),
        submitted: moment(engagement.submitted).format("MM/DD"),
        previousStage: previousStage ? ` (was ${previousStage})` : '',
        previousStatus: previousStatus ? ` (was ${previousStatus})` : '',
        stageExtraWord: /screen|onsite/i.test(engagement.stage) ? ' Interview' : '',
        firstScreenInterview: (eventType === 'firstScreenInterview'),
        offer: (eventType === 'offer'),
        jobLink: Core.getPath(`job/view/${job.id}`)
      }
    );

  }

  const { emails, to, cc } = await generateDeliveriesList({ engagement });

  const subject = generateSubject({ engagement, eventType });

  const body = generateBody({
    engagement,
    previousStage,
    previousStatus,
    interviewDate,
    isFullday,
    eventType,
  });

  return {
    emails,
    to,
    cc,
    subject,
    body
  }
}

/**
 * Shows a dialog with email preview
 * 
 * On click send button, it will
 * - close dialog
 * - call to dropdown.open
 * - call sendSafeEmail
 *   - onSuccess: call to Streak.putEmailInBox
 * 
 * @param {object} options
 * @param {object} options.engagement
 * @param {string} options.eventType screen|onsite|offer|interview|firstScreenInterview|status
 * @param {string} options.previousStage
 * @param {string} options.previousStatus
 * @param {string} options.interviewDate
 * @param {boolean} options.isFullday
 * @param {JSX.Element} options.dropdown
 */
async function openStageTransitionEmailPreview({
  engagement,
  eventType,
  previousStage,
  previousStatus,
  interviewDate,
  isFullday,
  dropdown
}) {

  const {
    emails,
    to,
    cc,
    subject,
    body
  } = await generateStageTransitionEmailInputs({
    engagementId: engagement.id,
    eventType,
    previousStage,
    previousStatus,
    interviewDate,
    isFullday
  });

  console.debug({
    emails,
    to,
    cc,
    subject,
    body
  });

  Core.dialog.open({
    title: <>Message</>,
    message: (
      <EmailPreview
        ref={self =>
          Core.setKeyValue(
            'RecruiterStageTransitionEmailPreview', self
          )
        }
        {...{
          emails,
          to,
          cc,
          subject,
          body
        }}
      />
    ),
    className: "p-0",
    actions: [
      <FlatButton
        label="Cancel"
        className="button-flat-darker"
        onClick={ev => {
          Core.dialog.close();
        }}
      />,
      <FlatButton
        label="Send"
        className="button-white-cyan"
        onClick={ev => {
          Core.dialog.close();
          dropdown && dropdown.open();
          sendSafeEmail(
            {
              ...Core.getKeyValue('RecruiterStageTransitionEmailPreview').getParams(),
              source: 'EmailRecruiter.lib.js'
            },
            response => {
              Core.showMessage("Email sent");
              if (!response.threadId) {
                Core.showFailure("It is missing the threadGmailId");
              } else {
                Streak.putEmailInBox(
                  {
                    boxKey: engagement.boxKey,
                    threadGmailId: response.threadId
                  },
                  response => Core.log("putEmailInBox", "success", response)
                );
              }
            },
            error => Core.showFailure(error)
          );
        }}
      />
    ]
  });
};

/* DICTIONARY ================================= */

const RecruiterEmailLib = {
  mapEmailDeliveries,
  getJobListForReminderEmail,
  openReminderEmailPreview,
  openAskPermissionEmailPreview,
  openRejectionEmailPreview,
  openStageTransitionEmailPreview,
};

/* EXPORTS ==================================== */

export {
  RecruiterEmailLib as default,
  RecruiterEmailLib,
  mapEmailDeliveries as mapRecruiterEmailDeliveries,
  getJobListForReminderEmail as getRecruiterJobListForReminderEmail,
  openReminderEmailPreview as openRecruiterReminderEmailPreview,
  openReminderEmailPreviewV2 as openRecruiterReminderEmailPreviewV2,
  openAskPermissionEmailPreview as openRecruiterAskPermissionEmailPreview,
  openAskPermissionEmailPreviewV2 as openRecruiterAskPermissionEmailPreviewV2,
  openRejectionEmailPreview as openRecruiterRejectionEmailPreview,
  openStageTransitionEmailPreview as openRecruiterStageTransitionEmailPreview,
};

/* ============================================ */
