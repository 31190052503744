import Core from "../Core";

function downloadFile({ url, mimeType, onError }) {
  var request = require("request");
  request(
    {
      url,
      method: "GET",
      encoding: null
    },
    (error, response, body) => {
      if (error) {
        //console.error(error);
        Core.failure({
          source: 'downloadFile.js(tool)',
          exception: error,
          params: { url, mimeType, onError }
        });
        if (onError instanceof Function) {
          onError(error);
        }
      } else {
        var FileSaver = require("file-saver");
        var blob = new Blob([body], { type: mimeType });
        FileSaver.saveAs(
          blob,
          String(url)
            .split("/")
            .pop()
        );
      }
    }
  );
}
export default downloadFile;
