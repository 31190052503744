import React, {Component} from 'react';
import {TextField} from "material-ui";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import Streak from "../../lib/Streak";
import Definition from "../../lib/Definition";
import Engagement from "../../lib/Engagement";

const styles = {
    underlineStyle: {
        borderColor: "#715EFF"
    }
};


class ManageEngagement extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            ...this.props
        }

    }

    componentDidMount() {
        this.setupItems();
    }

    setupItems = () => {
        this.setState({
            stages: Streak.getDropdownStrings("Stage").map(stage => (
                <MenuItem value={stage} key={stage} primaryText={stage}/>
            )),
            statuses: Streak.getDropdownStrings("Status")
                .sort((a, b) => a.localeCompare(b))
                .map(status => (
                    <MenuItem value={status} key={status} primaryText={status}/>
                )),
            states: Streak.getDropdownStrings("State").map(state => (
                <MenuItem value={state} key={state} primaryText={state}/>
            )),
            rejectionReasons: Streak.getDropdownStrings("RejectionReason")
                .sort((a, b) => a.localeCompare(b)).map(reason => (
                    <MenuItem value={reason} key={reason} primaryText={reason}/>
                )),

            matchStrengths: Definition.get("engagementMatchStrength").map(strength => (
                <MenuItem value={strength.id} key={strength.id} primaryText={strength.label}/>
            )),
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.open !== this.props.open) {
            this.setState({
                open: this.props.open,
            }, () => {
            });
        }
    }

    close = () => {
        const {closeParentPopup} = this.props;
        this.setState({open: false});
        closeParentPopup();
    };

    submit = () => {
        let {engagement} = this.state;
        const newDate = new Date();
        const newISO = newDate.toISOString();
        [
            [/submission|review|screen|onsite|offer|hire|guarantee/i, "confirmed"],
            [/review|screen|onsite|offer|hire|guarantee/i, "submitted"],
            [/screen|onsite|offer|hire|guarantee/i, "reviewed"],
            [/onsite|offer|hire|guarantee/i, "screened"],
            [/offer|hire|guarantee/i, "onsite"],
            [/hire|guarantee/i, "offered"]
        ].forEach(item => {
            if (item[0].test(engagement.stage) && !engagement[item[1]]) {
                engagement[item[1]] = newISO;
            }
        });

        const engToSubmit = {
            confirmed: engagement.confirmed,
            lastAction: engagement.lastAction,
            overdueDate: engagement.overdueDate,
            rejectionReason: engagement.rejectionReason,
            rejectionReasonAdditionalInfo: engagement.rejectionReasonAdditionalInfo,
            stage: engagement.stage,
            state: engagement.state,
            status: engagement.status,
            matchStrength: engagement.matchStrength
        }

        Engagement.update(engagement, engToSubmit, (res) => {
            const {closeParentPopup} = this.props;
            this.setState({open: false}, () => {
                const {afterUpdateEngagement} = this.props;
                afterUpdateEngagement(engToSubmit);
            });
            closeParentPopup();
        }, () => (alert('failed to save')))
    }

    setAttr = (attr) => (event, index, value) => {
        const {engagement} = this.state;

        if (!value) {
            value = index;
        }

        this.setState({engagement: {...engagement, [attr]: value}});
    };

    render() {
        let {engagement, open} = this.state;
        let {stage, status, state, matchStrength, rejectionReason, rejectionReasonAdditionalInfo} = engagement;
        let {stages, statuses, states, matchStrengths, rejectionReasons} = this.state;

        return <Dialog
            title="Update Engagement"
            actions={[
                <FlatButton label="Cancel" primary={true} onClick={this.close}/>,
                <FlatButton label="Submit" primary={true} onClick={this.submit}/>
            ]}
            modal={true}
            contentStyle={{width: 400}}
            open={open}
        >
            <div className="Modal-block">
                Stage
                <SelectField
                    name="stage"
                    value={stage}
                    onChange={this.setAttr('stage')}
                    underlineFocusStyle={styles.underlineStyle}
                    style={{float: "right"}}
                >
                    {stages}
                </SelectField>
            </div>
            <div className="Modal-block">
                Status
                <SelectField
                    name="status"
                    value={status}
                    onChange={this.setAttr('status')}
                    underlineFocusStyle={styles.underlineStyle}
                    style={{float: "right"}}
                >
                    {statuses}
                </SelectField>
            </div>
            <div className="Modal-block">
                State
                <SelectField
                    name="state"
                    value={state}
                    onChange={this.setAttr('state')}
                    underlineFocusStyle={styles.underlineStyle}
                    style={{float: "right"}}
                >
                    {states}
                </SelectField>
            </div>
            <div className="Modal-block">
                Strength
                <SelectField
                    name="matchStrength"
                    value={matchStrength}
                    onChange={this.setAttr('matchStrength')}
                    underlineFocusStyle={styles.underlineStyle}
                    style={{float: "right"}}
                >

                    {matchStrengths}
                </SelectField>
            </div>

            {<div className="Modal-block">
                Rej Reason
                <SelectField
                    name="rejectionReason"
                    value={rejectionReason}
                    onChange={this.setAttr('rejectionReason')}
                    underlineFocusStyle={styles.underlineStyle}
                    style={{float: "right"}}
                >
                    <MenuItem value={""} key={""} primaryText={"Select None"} style={{color: 'silver'}}/>
                    {rejectionReasons}
                </SelectField>
            </div>}

            {<div className="Modal-block">
                RR-Add Info &nbsp;
                <TextField
                    name={`rejectionReasonAdditionalInfo`}
                    value={!!rejectionReasonAdditionalInfo ? rejectionReasonAdditionalInfo : ""}
                    onChange={this.setAttr('rejectionReasonAdditionalInfo')}
                    multiLine={true}
                    onBlur={ev => {
                    }}
                    rowsMax={5}
                    errorText={""}
                />
            </div>}
        </Dialog>
    }
}

export default ManageEngagement;
