/** ============================================ µ
 * @description Loading... [JSX]
 *              UI Component
 * @route       /account/edit/:accountId
 * @createdAt   2021-08-19 Thu
 * @updatedAt   2021-08-19 Thu
 * ============================================ */
/* IMPORTS ==================================== */

import React from "react";
import { useTranslation } from "react-i18next";
import loader from "../../assets/images/loader.gif";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function Loading(props) {
  const { t } = useTranslation();
  const { children } = props;
  return (
    <>
      <img alt="loading..." height="14" src={loader} className="mr-1" />
      {children || t('Loading')}...
    </>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { Loading as default, Loading };

/* ============================================ */