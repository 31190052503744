import React from "react";

import {matchSkillWithText} from "../../../../../lib/tools/cvMatch";

const result = (element, toMatchWith) => {
    let isLabelInText = [];
    const valueArray = element.value.split(",");

    if(!!toMatchWith && !!toMatchWith.length){
        isLabelInText = valueArray.map(val=>{
            return !!matchSkillWithText(val, toMatchWith);
        });
    }
    return !!isLabelInText.includes(true);
}

const resultWithDecision = (element, toMatchWith) => {
    let decision = {group: 0};
    if(!!toMatchWith && !!toMatchWith.length){
        decision = !!result(element, toMatchWith) ? {group: 1} : {group: 0};
    }
    return finalDecision([decision]);
}

const finalDecision = (decision) => {
    let text = <span><i style={{fontSize: '20px'}} className="material-icons">cancel</i></span>;
    decision.forEach(dec => {
        if(dec.group > 0){
            text = <span><i style={{fontSize: '20px'}} className="material-icons">done</i></span>;
        }
    });
    return text;
}

const ChipTag = {
    result,
    resultWithDecision
}

export default ChipTag;
