/** ============================================ µ
 * @description ThumbBar [JS]
 *              UI Component
 * @route       /candidate/matchNew/:candidateId
 * @update      2021-08-12 Thu
 * ============================================ */

/* IMPORTS ==================================== */

import {
  MenuItem as MuiMenuItem, Select as MuiSelect
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import { TextField } from "material-ui";
import FlatButton from "material-ui/FlatButton";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import Popover from "material-ui/Popover";
import React, { Component, Fragment } from "react";
import Candidate from "../../../../lib/Candidate";
import { engagementMatchStrength } from "../../../../lib/Constants";
import Definition from "../../../../lib/Definition";
import Engagement from "../../../../lib/Engagement";
import Streak from "../../../../lib/Streak";
import AnswerJobsQuestions from "../../../Candidates/Forms/AnswerJobsQuestions";
import CreateDisagreement from "../../../Disagreements/createWithPopup";
import JobCardThumbsActions from "./JobCardThumbsActions";
import SelectedBulkJob from "./SelectedBulkJob";

/* MAIN CLASS ================================= */

class ThumbBar extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      openPopover: false,
      anchorEl: null,
      getDisagreementDetails: false,
      showQuestions: false,
      jobEngagements: [],
      annotatorMode: this.props.isAnnotatorSelected,
      MLScoreMode: this.props.mlScoreMode,
    };
  }

  fetchEngagements = () => {
    this.props.job && Engagement.getWhere({
      jobId: this.props.job.id
    }, (res) => {
      this.setState({
        jobEngagements: res,
      });
    });
  };

  componentDidMount() {
    this.fetchEngagements();
    this.handlerMlModelChangeDropdownLocal({
      target: { value: this.props.currentMlModel },
    });

    if (!!this.state.annotatorMode) {
      this.toggleAnnotatorMode();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.job?.id !== this.props.job?.id) {
      this.fetchEngagements();
    }
  }

  handlerSkippedJob = () => {
    let { handlerSkippedJob, job, handlerSelectJob } = this.props;
    !!handlerSkippedJob && handlerSkippedJob(job);
    !!handlerSelectJob && handlerSelectJob(null);
  };

  renderPopOver = () => {
    const { handlerMatchJob } = this.props;
    const { matchStrength } = this.state;
    const matchStrengthLabel = Definition.getLabel(
      "engagementMatchStrength",
      matchStrength
    );

    return (
      <Popover
        open={this.state.openPopover}
        anchorEl={this.state.anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        targetOrigin={{ horizontal: "left", vertical: "top" }}
        onRequestClose={(ev) =>
          this.setState({
            openPopover: false,
          })
        }
      >
        {Streak.getPipeline().matchOptions && (
          <Menu>
            {Streak.getPipeline().matchOptions.map((option) => (
              <MenuItem
                key={option}
                primaryText={option}
                onClick={(ev) =>
                  this.setState({
                    showQuestions: true,
                    openPopover: false,
                    handlerNextAnswers: handlerMatchJob({
                      stage: option.split(",")[0].trim(),
                      status: option.split(",")[1].trim(),
                      matchStrength,
                      matchStrengthLabel,
                    }),
                  })
                }
              />
            ))}
          </Menu>
        )}
      </Popover>
    );
  };

  onCloseDisagreementPopup = () => {
    this.setState({ getDisagreementDetails: false });
  };

  saveCandoPrivateNoteAddition = () => {
    //privateNotes
    let { candidate } = this.props;
    let { candoPrivateNoteText, permittedJobsAnswers } = this.state;

    let finalNotes = candidate.privateNotes;

    if (!!candoPrivateNoteText) {
      finalNotes = candidate.privateNotes
        ? candidate.privateNotes + "\n" + candoPrivateNoteText
        : candoPrivateNoteText;
    }

    Candidate.update(candidate.id, {
      privateNotes: finalNotes,
      permittedJobsAnswers,
    });
  };

  handlerThumbsUp = (ev, matchStrength) => {
    let matchStrengthText;
    switch (matchStrength) {
      case 1:
        matchStrengthText = "STRONG YES";
        break;
      case 2:
        matchStrengthText = "YES";
        break;
      case 3:
        matchStrengthText = "WEAK YES";
        break;
      default:
        matchStrengthText = "";
        break;
    }

    const { hasJobEngageable, handlerMatchJob } = this.props;
    const matchStrengthLabel = Definition.getLabel(
      "engagementMatchStrength",
      matchStrength
    );

    if (window.confirm(`Confirm this match is a ${matchStrengthText}?`)) {
      if (!!hasJobEngageable && hasJobEngageable()) {
        this.setState({
          matchStrength,
          openPopover: true,
          anchorEl: ev.currentTarget,
        });
      } else {
        this.setState(
          {
            matchStrength,
          },
          () => {
            !!handlerMatchJob &&
              handlerMatchJob({ matchStrength, matchStrengthLabel })();
          }
        );
      }
    }
  };

  handlerThumbsDown = (ev, matchStrength) => {
    let matchStrengthText;
    switch (matchStrength) {
      case 5:
        matchStrengthText = "NO";
        break;
      case 6:
        matchStrengthText = "STRONG NO";
        break;
      default:
        matchStrengthText = "";
        break;
    }

    if (window.confirm(`Confirm this match is a ${matchStrengthText}?`)) {
      this.setState({
        getDisagreementDetails: true,
        matchStrength,
      });
    }
  };

  localCreateDisagreement = (model, cb) => {
    const { createDisagreement, job, candidate } = this.props;
    const engagement = Object(model).eng
      ? Object(model).eng
      : this.props.engagement;

    const { matchStrength } = this.state;
    let matchStrengthLabel = Definition.getLabel(
      "engagementMatchStrength",
      matchStrength
    );

    if (
      [
        engagementMatchStrength.STRONG_YES,
        engagementMatchStrength.YES,
        engagementMatchStrength.WEAK_YES,
      ].includes(matchStrength) &&
      /w - 10x10/i.test(Object(engagement).status)
    ) {
      matchStrengthLabel = "TBD";
    }

    let structure = {
      ...model,
      matchStrength: matchStrengthLabel,
      job,
      candidate,
      engagement,
      employer: job.employer,
      cb: () => {
        !!cb && cb();
      },
    };

    createDisagreement(structure);
  };

  specialEngagementCreation = (matchStrength) => (
    {
      candidate,
      job,
      pickedReasons,
      disagreementReasonDetails,
      shouldTag,
      shouldNotTag,
      whyNoPrivateNote,
      whyNeedToReadCV,
    },
    { disagreementModel, cb }
  ) => {
    let {
      createSingleEngagementWithDis,
      engagement,
      strategyGetConstraints,
    } = this.props;
    const matchStrengthLabel = Definition.getLabel(
      "engagementMatchStrength",
      matchStrength
    );

    let stage, status, state, rejectionReason, rejectionReasonAdditionalInfo;

    if (
      !engagement &&
      (strategyGetConstraints(candidate, job, "jobsPermitted") ||
        strategyGetConstraints(candidate, job, "jobsPitched"))
    ) {
      stage = "Confirmation";
      status = "W - 10x10 Notify Rejection";
      state = "Open";
      rejectionReason = pickedReasons
        .filter((el) => !["positiveSignals", "negativeSignals"].includes(el))
        .join(",");
      rejectionReasonAdditionalInfo = disagreementReasonDetails;
    } else {
      stage = "Confirmation";
      state = "Closed";
      status = "E - 10x10";
      rejectionReason = "10x10 - No Match";
      rejectionReasonAdditionalInfo = disagreementReasonDetails;
    }

    createSingleEngagementWithDis(job, {
      stage,
      status,
      state,
      rejectionReason,
      rejectionReasonAdditionalInfo,
      whyNoPrivateNote,
      matchStrength,
      matchStrengthLabel,
      ...disagreementModel,
    });

    !!cb && cb();

    if (
      [engagementMatchStrength.NO, engagementMatchStrength.STRONG_NO].includes(
        this.state.matchStrength
      )
    )
      this.pushingToCandoOnThumbsDown({ candidate, job });
  };

  pushingToCandoOnThumbsDown = (data) => {
    const { pushEntityToCandidate } = this.props;
    const jobId = data.job.id;

    let putDownJobs = data.candidate.putDownJobs || [];
    putDownJobs.push(jobId);
    putDownJobs = Array.from(new Set(putDownJobs));

    !!pushEntityToCandidate && pushEntityToCandidate(putDownJobs);
  };

  handleScroll = (e, sectionId, key) => {
    let previousActiveLink = document.getElementsByClassName(
      "job-list-tab-active"
    )[0];
    if (previousActiveLink !== undefined) {
      previousActiveLink.className = "";
    }
    let section = document.getElementById(sectionId);
    if (section !== null) {
      section.scrollIntoView();
      e.target.classList.add("job-list-tab-active");
    } else {
      const { scrollToIndex } = this.props;
      !!scrollToIndex && scrollToIndex(key);
    }
  };

  toggleAnnotatorMode = () => {
    let sectionHeaders = Array.from(
      document.getElementsByClassName("list-card-section")
    );
    sectionHeaders.forEach((header) => {
      header.classList.toggle("disable");
    });
  };

  handleAnnotatorMode = (e) => {
    const { handlerAnnotatorMode, resizeAllIndices } = this.props;

    let annotatorMode = !this.state.annotatorMode;

    this.setState({ annotatorMode }, () => {
      this.toggleAnnotatorMode();
      handlerAnnotatorMode(annotatorMode);
      !!resizeAllIndices && resizeAllIndices();
    });
  };

  handleMLScoreMode = (e) => {
    const { MLScoreMode } = this.state;
    const { handlerMLScoreMode, resizeAllIndices } = this.props;
    this.setState({ MLScoreMode: !MLScoreMode }, () => {
      !!handlerMLScoreMode && handlerMLScoreMode(this.state.MLScoreMode);
      !!resizeAllIndices && setTimeout(resizeAllIndices(), 1000);
    });
  };

  handlerMlModelChangeDropdownLocal = (event) => {
    const { handlerMlModelChangeDropdown } = this.props;
    const value = event?.target?.value;
    this.setState({ mlm: value });

    const NO_MODEL = 2;
    let mLScoreMode = false;
    const { handlerMLScoreMode, resizeAllIndices } = this.props;

    // as we replaced toggle, we need code refactor here, we have to run following code for mlScoreMode=false before we run for mlScoreMode=true
    this.setState({ MLScoreMode: mLScoreMode }, () => {
      !!handlerMLScoreMode && handlerMLScoreMode(this.state.MLScoreMode);
      !!resizeAllIndices && setTimeout(resizeAllIndices(), 1000);
    });

    if (value !== NO_MODEL) {
      mLScoreMode = true;
    }

    setTimeout(() => {
      this.setState({ MLScoreMode: mLScoreMode }, () => {
        !!handlerMLScoreMode && handlerMLScoreMode(this.state.MLScoreMode);
        !!resizeAllIndices && setTimeout(resizeAllIndices(), 1000);
      });
    });

    handlerMlModelChangeDropdown && handlerMlModelChangeDropdown(value);
  };

  render() {
    let {
      candidate,
      job,
      multiSelectedJobs,
      sourceKey,
      countForThumbBarHeading,
      currentMlModel,
    } = this.props;

    let eng = this.props.engagement;
    let {
      getDisagreementDetails,
      matchStrength,
      candoPrivateNoteText,
      annotatorMode
    } = this.state;

    return (
      <Fragment>
        <div className="candidate-match-toolbar">
          <Grid container>
            <Grid item xs={12} className={!!job ? '' : 'd-none'}>
              <div className="toolbar-button">
                <FlatButton
                  label="Skip"
                  className="filter-skip"
                  onClick={this.handlerSkippedJob}
                />
                {!!multiSelectedJobs.length >= 0 && sourceKey === "job" && (
                  <SelectedBulkJob
                    multiSelectedJobs={multiSelectedJobs}
                    candidate={candidate}
                  />
                )}
                <JobCardThumbsActions
                  handlerThumbsUp={this.handlerThumbsUp}
                  handlerThumbsDown={this.handlerThumbsDown}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                padding: 0,
              }}
              className={!!job ? '' : 'd-none'}
            >
              <FormControlLabel
                checked={annotatorMode}
                control={
                  <Switch
                    color="primary"
                    onClick={(e) => this.handleAnnotatorMode(e)}
                  />
                }
                label="Annotator Mode"
                labelPlacement="end"
              />
              <div
                className="µ-select-machine-learning-model"
                style={{ display: "inline-block" }}
              >
                <MuiSelect
                  labelId="label"
                  id="select"
                  value={currentMlModel || 1}
                  /** µ DISCARDED future cleanup * /
                  renderValue={(selected) => {
                    return Definition.getLabel('machineLearningModel', selected)?.slice(0, 2)?.toUpperCase();
                  }}
                  /** */
                  onChange={this.handlerMlModelChangeDropdownLocal}
                  size="small"
                  variant="standard"
                >
                  {Definition.get("machineLearningModel").map(
                    ({ id, label }) => (
                      <MuiMenuItem value={id} key={`mlm_${id}`}>
                        {label}
                      </MuiMenuItem>
                    )
                  )}
                </MuiSelect>
              </div>
            </Grid>
            <Grid item xs={12}>
              <p className="job-list-tabs">
                {!!countForThumbBarHeading &&
                  Object.keys(countForThumbBarHeading).map((link, index) => (
                    <span
                      key={index}
                      className="anchor"
                      onClick={(e) =>
                        this.handleScroll(e,
                          `list-card-section-${countForThumbBarHeading[link].label}`,
                          countForThumbBarHeading[link].key
                        )
                      }
                    >
                      <strong>
                        {countForThumbBarHeading[link].label}{" "}
                        {`(${countForThumbBarHeading[link].count})`}
                      </strong>
                    </span>
                  ))}
              </p>
            </Grid>
          </Grid>
        </div>
        {this.renderPopOver()}

        {!!job && (
          <>
            <div style={{ display: "none" }}>
              <CreateDisagreement
                engagement={eng}
                open={getDisagreementDetails}
                candidate={candidate}
                jobs={!!multiSelectedJobs.length ? multiSelectedJobs : [job]}
                employer={job.employer}
                decision={"false-positive"}
                createWith={this.specialEngagementCreation(matchStrength)}
                matchDecision={Definition.getLabel(
                  "engagementMatchStrength",
                  matchStrength
                )}
                onClose={this.onCloseDisagreementPopup}
              />
            </div>
            <AnswerJobsQuestions
              finalUpdater={(res) => { }}
              handlerNext={(ansStruct) => () => {
                !!this.state.handlerNextAnswers
                  ? this.state.handlerNextAnswers(ansStruct)
                  : (e) => { };
                this.setState({ showQuestions: false });
                this.setState({ permittedJobsAnswers: ansStruct }, () => {
                  this.saveCandoPrivateNoteAddition();
                });
              }}
              handlerNextOnCancel={(data) => () => {
                this.setState({
                  showQuestions: false,
                  permittedJobsAnswers: data.initial,
                });
              }}
              nxtBtnText="Proceed"
              cancelBtnText="Cancel"
              alwaysShow={true}
              initialState={candidate.permittedJobsAnswers}
              jobs={(multiSelectedJobs || []).map((j) => j.id)}
              open={this.state.showQuestions}
              close={(el) => {
                this.setState({ showQuestions: false });
              }}
              extraComponentStructure={
                <Fragment>
                  <InputLabel>
                    <strong>Text to be added in Candidate's private note</strong>
                  </InputLabel>
                  <TextField
                    style={{ height: 40 }}
                    placeholder="add your free comments"
                    onChange={(el) => {
                      this.setState({ candoPrivateNoteText: el.target.value }); // this is to make sure component re renders as value changes
                    }}
                    value={candoPrivateNoteText}
                    onBlur={(el) => { }}
                    fullWidth
                  />
                </Fragment>
              }
            />
          </>
        )}
      </Fragment>
    );
  }
}

/* EXPORTS ==================================== */

export default ThumbBar;

/* ============================================ */
