/** @todo review if deprecated 2021-08-06 µ */

import React, {Component, Fragment} from "react";

import Chip from '@mui/material/Chip';
import Definition from "../../../../lib/Definition";
import {makeDecision} from "../Decision/Decision";

class WorkFromHome extends Component {
    constructor () {
        super(...arguments);
        this.state = {};
    }

    getDisplayColor = () => {
        const {candidateWorkFromHome, jobWorkFromHome} = this.props;

        if(!!candidateWorkFromHome && !!jobWorkFromHome) {
            if( 
                (parseInt(candidateWorkFromHome) === 1)
                ||
                ((parseInt(candidateWorkFromHome)===2) && parseInt(jobWorkFromHome) === 5 )
                ||
                ((parseInt(candidateWorkFromHome) ===3) && parseInt(jobWorkFromHome) === 1 )
                ||
                ( parseInt(candidateWorkFromHome) === 4 && parseInt(jobWorkFromHome) === 4 )
                ) {
                    return true;
            }

            return false;
        }

        return null;
    }

    getLabel = () => {
        const {candidateWorkFromHome, jobWorkFromHome, source} = this.props;

        if(source === 'candidate'){
            return Definition.getLabel("remotelyWork", candidateWorkFromHome);
        } else if(source === 'job') {
            return Definition.getLabel("remote", jobWorkFromHome);
        }

        return null;
    }

    render() {
        const {candidateWorkFromHome, jobWorkFromHome, source} = this.props;

        if(source === 'candidate' && !candidateWorkFromHome) {
            return null;
        }
        if(source === 'job' && !jobWorkFromHome) {
            return null;
        }
        
        const decision = this.getDisplayColor();

        return (
            <Fragment>
                <Chip
                    className="slim-chip"
                    variant="outlined"
                    size="small"
                    style={makeDecision(decision)}
                    label={this.getLabel()}
                />
            </Fragment>
        );
    }
}

export default WorkFromHome;