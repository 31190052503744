import { AppBar, FlatButton, IconButton, Paper, Snackbar } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import Core from "../../../lib/Core";
import Definition from "../../../lib/Definition";
import Employer from "../../../lib/Employer";
import Google from "../../../lib/Google";
import HistoryLog from "../../../lib/HistoryLog";
import Job from "../../../lib/Job";
import { getEmployerModel } from "../../../lib/models/employer";
import Store from "../../../lib/Store";
import EmailPreview from "../../Dialogs/EmailPreview";
import SuccessDialog from "../../Dialogs/Success";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";
import styles from "../../Forms/Styles";
import HistoryMenu from "../../HistoryMenu/HistoryMenu";
import Stepper from "../../Stepper/Stepper";
import Basics from "../Forms/Basics";
import Contact from "../Forms/Contact";
import Engineering from "../Forms/Engineering";
import Process from "../Forms/Process";
import WhyUs from "../Forms/WhyUs";

const required = "This field is required";
const niceToHave = "Please enter to get better match";

class EmployerEdit extends Component {
  continue = 0;
  confirm = false;
  constructor() {
    super(...arguments);
    this.state = {
      ...getEmployerModel({ extended: true }),
      /** controller states */
      employers: [],
      snackbar: null,
      errorName: "",
      errorAddressCity: "",
      errorEmployerStage: "",
      errorEmployeeCount: "",
      step: 0,
      initial: 1,
      /** setting unknown as default value of remote */
      remote: Definition.getId("remote", "Unknown"),
      jobsCollection: [],
      updatedJobs: {},
    };
    Store.set("path", window.location.href);
    /** GET EMPLOYERS LIST for the select box on basics */
    Employer.getList((employers) => this.setState({ employers }));
    /** LOAD EMPLOYER DATA to the current state */
    if (this.props.match.params.id) {
      Employer.get(this.props.match.params.id, (model) =>
        this.setState({ ...model }, (then) => {
          HistoryLog.set({ group: "employers", label: this.state._name });
          Core.log(this.state);
        })
      );
    }
    if (!!this.props.match.params.id) {
      Job.getWhere(
        {
          and: [
            { employerId: this.props.match.params.id },
            {
              or: [
                { state: Definition.getId("state", "Active") },
                { state: Definition.getId("state", "Lead") },
              ],
            },
          ],
        },
        (jobs) =>
          this.setState({
            jobsCollection: jobs,
          })
      );
    }
  }
  /** POST/UPDATE JOB */
  update = (success, final) => {
    if (this.isValid()) {
      const continueWith = (em) => {
        if (this.state.id) {
          Employer.update(this.state.id, this.state, success);
        } else {
          Employer.post(this.state, success);
        }
        const updatedJobIds = Object.keys(this.state.updatedJobs);
        const next = (em) => {
          if (!!updatedJobIds.length) {
            const job = this.state.updatedJobs[updatedJobIds.pop()];
            Job.update(job.id, job, Core.log);
            setTimeout(next);
          }
        };
        next();
      };
      if (!this.state.proxyName) {
        Employer.getAnonName((proxyName) =>
          this.setState({ proxyName }, (then) => continueWith())
        );
      } else {
        continueWith();
      }
    }
  };
  /** SAVE & NEXT BUTTON */
  submitThenGoNext = (ev) => {
    if (this.isValid()) {
      this.update((response) =>
        this.setState({ id: response.id }, (then) => this.refs.stepper.next())
      );
    }
  };
  /** BACK BUTTON */
  goBack = (ev) => this.cancel();
  /** CANCEL BUTTON */
  cancel = (ev) => {
    Core.goBack(this.props); // "/#/employers";
  };
  /** PREVIOUS BUTTON */
  previous = (ev) => {
    this.refs.stepper.previous();
  };
  /** NEXT BUTTON */
  next = (ev) => {
    if (this.state.step === 0 && !this.isValid()) {
      return;
    }
    this.refs.stepper.next();
  };
  /** DONE BUTTON */
  save = (ev) => {
    this.setState({ state: Definition.getId("state", "Draft") }, (then) =>
      this.submit()
    );
  };
  /**
   * SUBMIT; DONE BUTTON
   */
  submit = (ev) => {
    this.update((response) => {
      if (this.state.id) {
        this.refs.successDialog.open("Employer has been successfully updated");
      } else {
        this.setState({ id: response.id }, (then) => {
          this.refs.successDialog.open(
            "Employer has been successfully created"
          );
        });
      }
    }, true);
  };
  /**
   * VALIDATIONS
   */
  isValid = (event) => {
    /** NAME: required */
    if (String(this.state.name).trim().length < 1) {
      window.scrollTo(0, 0);
      this.setState({
        snackbar: "Company Name is required",
        errorName: required,
      });
      return false;
    }
    const employer = this.state.employers.find(
      (employer) =>
        String(employer.name).toLowerCase() ===
        String(this.state.name).toLowerCase()
    );
    if (employer && employer.id !== this.props.match.params.id) {
      this.setState({
        snackbar: "Company Name should be unique",
        errorName: "Choose another name",
      });
      return false;
    }
    /** LOCATION: confirmation dialog */
    if (
      this.state.step === 4 &&
      !this.state.location &&
      this.confirm !== true
    ) {
      this.refs.confirmDialog.open(
        "Location has not been entered, are you sure you want to activate the employer and make it visible to recruiters?"
      );
      return false;
    }
    /** CITY: nice to have */
    if (String(this.state.addressCity).trim().length < 1) {
      window.scrollTo(0, 0);
      this.setState({
        snackbar: "City is empty",
        errorAddressCity: niceToHave,
      });
      if (this.continue === 0) {
        this.continue = 1;
      }
    }
    /** STAGE: nice to have */
    if (!this.state.stage) {
      window.scrollTo(0, 0);
      this.setState({
        snackbar: "Stage is not selected",
        errorEmployerStage: niceToHave,
      });
      if (this.continue === 0) {
        this.continue = 1;
      }
    }
    /** EMPLOYEE COUNT: nice to have */
    if (!this.state.employeeCount) {
      window.scrollTo(0, 0);
      this.setState({
        snackbar: "How many people works at the company?",
        errorEmployeeCount: niceToHave,
      });
      if (this.continue === 0) {
        this.continue = 1;
      }
    }
    /** NICE TO HAVE: preventing to advance just one time */
    if (this.continue === 1) {
      this.continue = 2;
      return false;
    }
    /** ALL TESTS PASSED */
    return true;
  };
  openMessage = (ev) => {
    const emails = [];
    const employer = this.state;
    employer.primaryContactEmail &&
      emails.push({
        name: employer._name || "Employer",
        email: employer.primaryContactEmail,
      });
    Core.dialog.open({
      title: <>Message</>,
      message: (
        <EmailPreview
          ref={(self) => (this.EmailMessage = self)}
          emails={emails}
          to={!!emails[0] && [emails[0]]}
          subject=""
          body={[].filter((line) => !!line).join("<br/>")}
        />
      ),
      className: "p-0",
      actions: [
        <FlatButton
          label="Cancel"
          className="button-flat-darker"
          onClick={(ev) => {
            Core.dialog.close();
          }}
        />,
        <FlatButton
          label="Send"
          className="button-white-cyan"
          onClick={(ev) => {
            Core.dialog.close();
            Google.sendEmail(
              {
                ...this.EmailMessage.getParams(),
                source: "EmployerEdit.js line 289",
              },
              (response) => Core.showMessage("Email sent"),
              (error) => Core.showFailure(error)
            );
          }}
        />,
      ],
    });
  };

  render() {
    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }
    console.debug('this.state', this.state);
    return (
      <MuiThemeProvider>
        <div className="edit employers">
          <div className="appBar" style={styles.AppBar}>
            <AppBar
              title={
                (!!this.state.id ? "Edit " : "New ") +
                "Employer" +
                (!!this.state.name ? " - " + this.state.name : "")
              }
              titleStyle={styles.AppBar.title}
              style={styles.AppBar.nav}
              iconElementLeft={
                <FlatButton
                  icon={<i className="material-icons">arrow_back</i>}
                  style={styles.AppBar.backButton}
                  onClick={this.goBack}
                />
              }
              iconElementRight={
                <Fragment>
                  <IconButton
                    className="float-right"
                    onClick={this.openMessage}
                  >
                    <i className="material-icons">email</i>
                  </IconButton>
                  <HistoryMenu />
                </Fragment>
              }
            />
          </div>
          <Paper className="form" style={styles.Paper} zDepth={1}>
            <FlatButton
              label="SAVE"
              className="form-save-btn"
              onClick={this.submit}
            />
            <Stepper
              ref="stepper"
              active={this.state.initial - 1}
              headers={[
                "BASICS",
                "ENGINEERING",
                "WHY US",
                "CONTACT",
                "PROCESS",
              ]}
              views={[
                <Basics ref="basics" parent={this} />,
                <Engineering ref="engineering" parent={this} />,
                <WhyUs ref="whyus" parent={this} />,
                <Contact ref="contact" parent={this} />,
                <Process ref="process" parent={this} />,
              ]}
              onStep={(step) => this.setState({ step })}
            />
            <Row style={{ padding: "16px 0" }}>
              <Col>
                <FlatButton
                  label="CANCEL"
                  className="btn-white-crimson"
                  onClick={this.goBack}
                />
              </Col>
              <Col style={{ textAlign: "right" }}>
                {this.state.step === 0 ? (
                  ""
                ) : (
                  <FlatButton
                    label="PREVIOUS"
                    className="btn-white-cyan"
                    onClick={this.previous}
                  />
                )}
                {this.state.step === 4 ? (
                  <FlatButton
                    label="DONE"
                    className="btn-cyan-white"
                    onClick={this.submit}
                  />
                ) : (
                  <Fragment>
                    <FlatButton
                      label="NEXT"
                      className="btn-white-cyan"
                      onClick={this.next}
                    />
                    <FlatButton
                      label="SAVE & NEXT"
                      className="btn-cyan-white"
                      onClick={this.submitThenGoNext}
                    />
                  </Fragment>
                )}
              </Col>
            </Row>
            <SuccessDialog
              ref="successDialog"
              modal={true}
              actions={[
                <FlatButton
                  label="STAY"
                  primary={true}
                  onClick={(ev) => {
                    this.refs.successDialog.close();
                    if (this.state.id) {
                      Core.go({ ...this.props, to: `/employer/edit/${this.state.id}` });
                    }
                  }}
                />,
                <FlatButton
                  label="DONE"
                  primary={true}
                  keyboardFocused={true}
                  onClick={(ev) => Core.go({ ...this.props, to: '/employers/' })}
                />,
              ]}
            />
            <SuccessDialog
              ref="confirmDialog"
              modal={true}
              actions={[
                <FlatButton
                  label="CANCEL"
                  primary={true}
                  onClick={(ev) => this.refs.confirmDialog.close()}
                />,
                <FlatButton
                  label="CONFIRM"
                  primary={true}
                  keyboardFocused={true}
                  onClick={(event) => {
                    this.refs.confirmDialog.close();
                    this.confirm = true;
                    this.submit();
                  }}
                />,
              ]}
            />
          </Paper>
          <Snackbar
            open={!!this.state.snackbar}
            message={this.state.snackbar || ""}
            className="snack-bar"
            autoHideDuration={6000}
            onRequestClose={(ev) => this.setState({ snackbar: null })}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default EmployerEdit;
