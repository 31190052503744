import Core from "./Core";
import Http from "./Http";

const jobSourceList = {
    getJobIds: (opts, success) => {             // used by admin account edit
        let {include, where, fields} = opts;
        if(!include){
            include = {};
        }
        if(!where){
            where = {};
        }
        if(!fields){
            fields = {};
        }

        Http.get(
            Core.getApi(`jobSourceLists`),
            {
                filter: JSON.stringify({include,where,fields}),
            },
            function onSuccess(response) {
                success(response);
            }
        );
    },
    postOrPatchJob: (jobId, accountId, otherFields, success) => {        // used by admin account edit
        Http.get(
            Core.getApi(`jobSourceLists`),
            {
                filter: JSON.stringify({where:{and:[{accountId:accountId},{jobId:jobId}]}},{fields:['id']}),
            },
            function onSuccess(response) {
                if(response.length > 0){
                    jobSourceList.patchJob(response[0].id, otherFields, success);
                }
            }
        );
    },
    postJob: (jobId, accountId, otherFields, success) => {
        const data = {jobId: jobId, accountId: accountId, startDate: new Date(), endDate: null, who: otherFields.who};
        Http.post(
            Core.getApi(`jobSourceLists`),
            data,
            function onSuccess(response) {
                success(response);
            }
        );
    },

    upsertJob: (jobId, accountId, data, success) => {
        data.startDate = new Date();
        data.endDate = null;
        data.jobId = jobId;
        data.accountId = accountId;

        Http.post(
            Core.getApi("JobSourceLists/upsertWithWhere?where="+JSON.stringify({accountId,jobId}).replace("{","%7B").replace("}","%7D").replace(":","%3A")),
            data,
            function onSuccess(response) {
                success(response);
            }
        );
    },

    patchJob: (id, otherFields, success) => {        // used by admin account edit
        const data = {endDate: new Date(), who: otherFields.who};
        Http.patch(
            Core.getApi(`jobSourceLists/${id}`),
            data,
            function onSuccess(response) {
                success(response);
            }
        );
    },
    deleteJob: (id, success) => {
        const data = {endDate: new Date()};
        Http.patch(
            Core.getApi(`jobSourceLists/${id}`),
            data,
            function onSuccess(response) {
                success(response);
            }
        );
    },
    deleteJobByAdmin: (jobId, accountID, success) => {
        const data = {jobId, accountID, endDate: new Date()};
        Http.post(
            Core.getApi("jobSourceLists"),
            data,
            function onSuccess(response) {
                success(response);
            }
        );
    },
    patchJobByResume: (jobId, accountId, otherFields, success) => {        // used by admin account edit
        Http.get(
            Core.getApi(`jobSourceLists`),
            {
                filter: JSON.stringify({where:{and:[{accountId:accountId},{jobId:jobId}]}},{fields:['id']}),
            },
            function onSuccess(response) {
                if(response.length > 0){
                    jobSourceList.resumeJob(response[0].id, otherFields, success);
                }
            }
        );
    },
    resumeJob: (id, otherFields, success) => {
        const data = {startDate: new Date(), endDate: null, who: otherFields.who};
        Http.patch(
            Core.getApi(`jobSourceLists/${id}`),
            data,
            function onSuccess(response) {
                success(response);
            }
        );
    },
    removeJob: (jobId, accountId, data={}, success) => {
        Http.get(
            Core.getApi(`jobSourceLists`),
            {
                filter: JSON.stringify({where:{and:[{accountId:accountId},{jobId:jobId}]}})
            },
            function onSuccess(response) {
                if(response.length > 0){
                    jobSourceList.removeJobPermanently(response[0].id, success);
                }
            }
        );
    },
    removeJobPermanently: (id, cb) => {
        Http.delete(
            Core.getApi(`jobSourceLists/${id}`),
            function onSuccess(response) {
                cb(response);
            }
        );
    }
}

export default jobSourceList;