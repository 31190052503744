import React, {Component, Fragment} from "react";

/* Custom Styles */

/* Material UI theme   */
import FlatButton from "material-ui/FlatButton";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ChipForm from "./ElementForms/Chip";
import TextForm from "./ElementForms/Text";
import ComplexForm from "./ElementForms/Complex";

const popupElementForms = [
    {componentName: ChipForm, btnLabel: 'Pick from Chips'},
    {componentName: TextForm, btnLabel: 'Use Text'},
    {componentName: ComplexForm, btnLabel: 'Use Complex Structure', default: 'selected'},
];

const changeCurrentPopupBehaviourClass = "popupDialog";

class JobRequirementDetails extends Component {

    constructor() {
        super(...arguments);
        this.state = {
            mode: 'form',
            elementToBeSaved: null,
            SelectedFormComponent: ComplexForm
        }
    }

    finalStruct = () => {
        let {elementToBeSaved} = this.state;
        return elementToBeSaved;
    }

    handlerChoice = (formObj) => () => {
        this.setState({mode: 'form', SelectedFormComponent: formObj.componentName})
    }

    handlerFormChange = (elementToBeSaved) => {
        this.setState({elementToBeSaved})
    }

    render() {
        let {SelectedFormComponent, mode} = this.state;
        let {onCancel, onApply, priorityType, elementToEdit,extraInfo} = this.props;

        return <Fragment>
            <Dialog
                fullWidth={true}
                className={changeCurrentPopupBehaviourClass}
                maxWidth='xl'
                open={true}
                onClose={() => {
                }}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    {!!priorityType && ["chip","chipGroup","chipTag"].includes(priorityType)  &&
                        <ChipForm
                            onUpdate={this.handlerFormChange}
                            editable={true}
                            updateAble={elementToEdit}
                            dialogClass={changeCurrentPopupBehaviourClass}
                            jobTags={extraInfo.jobTags}
                        />
                    }
                    {!!priorityType && priorityType === "text" &&
                        <TextForm
                            onUpdate={this.handlerFormChange}
                            editable={true}
                            updateAble={elementToEdit}
                        />
                    }
                    {!!priorityType && ["or","and","not"].includes(priorityType) &&
                        <ComplexForm
                            onUpdate={this.handlerFormChange}
                            editable={true}
                            updateAble={elementToEdit}
                            dialogClass={changeCurrentPopupBehaviourClass}
                            jobTags={extraInfo.jobTags}
                        />
                    }

                    {!priorityType && popupElementForms.map((formObj, index) => {
                        return <FlatButton style={formObj.componentName===SelectedFormComponent ? {
                            border: '1px solid blue',
                            padding: '5px',
                            lineHeight: '20px',
                            color: 'blue'
                        } : {}} key={index} onClick={this.handlerChoice(formObj)}>{formObj.btnLabel}</FlatButton>
                    })}

                    <hr/>
                    {!priorityType && mode === 'form' && !!SelectedFormComponent &&
                        <Fragment>
                            {/* <FlatButton onClick={() => {
                                this.setState({mode: 'pick'})
                            }}>Go Back</FlatButton> */}

                            <SelectedFormComponent
                                onUpdate={this.handlerFormChange}
                                editable={true}
                                dialogClass={changeCurrentPopupBehaviourClass}
                                jobTags={extraInfo.jobTags}
                            />
                        </Fragment>
                    }
                </DialogContent>

                <DialogActions>
                    <FlatButton
                        label="Cancel"
                        className="filter-popup-button uppercase"
                        onClick={onCancel}
                    />
                    <FlatButton
                        label="Apply"
                        className="filter-popup-button uppercase"
                        onClick={() => {
                            onApply(this.finalStruct())
                        }}
                    />
                </DialogActions>
            </Dialog>
        </Fragment>
    }
}

export default JobRequirementDetails;
