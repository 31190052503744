import React, {Component} from "react";

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

class MatchDrawer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            drawerOpen: false
        }
    }

    // Open the drawer
    handleOpen = () => this.setState({drawerOpen: true});

    // Toggle the Drawer
    handleToggle = () => this.setState({drawerOpen: !this.state.drawerOpen});

    // Close the drawer
    handleClose = () => this.setState({drawerOpen: false});

    render() {
        const {content} = this.props;

        return (
        <Drawer
            className="match-new drawer"
            anchor="right"
            variant="persistent"
            open={this.state.drawerOpen}
            SlideProps={{
                unmountOnExit: true
            }}
        >
            <div
                style={{
                    // display: "flex",
                    // flexWrap: "nowrap",
                    position: "sticky",
                    top: 0,
                    width: "100%",
                    //backgroundColor: "inherit"
                    backgroundColor: "rgba(255, 0, 0, .3)"
                }}
            >
                <Button
                    fullWidth={true}
                    onClick={this.handleClose}
                >
                    <i
                        style={{
                            position: "absolute",
                            left: "0%"
                        }}
                        className="material-icons"
                    >
                        clear
                    </i>
                    Close
                </Button>
            </div>
            <div>
                {content}
            </div>
        </Drawer>
        )
    }
}
export default MatchDrawer;