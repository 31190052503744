import React from "react";

export const NoMatch = ({ location }) => {
  setTimeout(st => (document.location.href = "/"), 30000);
  return (
    <div className="main-padding">
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
};
