/** ============================================ µ
 * @description TabBarOptions [JSX]
 *              V3-table UI Component
 * @createdAt   2021-10-19 Tue
 * @updatedAt   2021-10-19 Tue
 * ============================================ */
/* IMPORTS ==================================== */

import React from "react";
import { NavLink } from "react-router-dom";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function TabBarOptions({ context }) {
  return (
    <div className="d-flex no-wrap overflow-auto">
      {(context.config.tabBarOptions || []).map((option, index) => {
        let { label, to = ({ context }) => context.location.pathname } = option;
        return (
          <div key={`engagements-tab-bar-option-${index}`} className="d-flex">
            {(index === 0) ? '' : <>&nbsp;|&nbsp;</>}
            <NavLink to={`${context.location.pathname}${to({ context })}`} >{label}</NavLink>
          </div>
        );
      })}
    </div>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { TabBarOptions as default, TabBarOptions };

/* ============================================ */
