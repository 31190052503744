import React, { Component } from "react";

import { List, ListItem } from "material-ui/List";
import TextField from "material-ui/TextField";

import Row from "../../Forms/Row";
import Col from "../../Forms/Col";
import RichTextBox from "../../Forms/RichTextBox";

import formatURL from "../../../lib/tools/formatURL";

class Basics extends Component {
  render() {
    const lineStyle = { borderColor: "#59736f" };
    const parent = this.props.parent;
    return (
      <List className="EmployerForm Basics">
        <ListItem
          className="form-header"
          primaryText="WHY US"
          disabled={true}
        />
        <Row>
          <Col fullWidth>
            <label>Elevator Pitch</label>
            <RichTextBox
              name="tagline"
              value={parent.state.tagline}
              onChange={tagline => parent.setState({ tagline })}
              halfHeight
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <label>Company Overview</label>
            <RichTextBox
              name="product"
              value={parent.state.product}
              onChange={product => parent.setState({ product })}
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <label>Why Us?</label>
            <RichTextBox
              name="whyUs"
              value={parent.state.whyUs}
              onChange={whyUs => parent.setState({ whyUs })}
              halfHeight
            />
          </Col>
        </Row>
        <Row>
          <Col fullWidth>
            <label>Company Perks [Visible to Limited Recruiters]</label>
            <RichTextBox
              name="perks"
              value={parent.state.perks}
              onChange={perks => parent.setState({ perks })}
              halfHeight
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Company URL</label>
            <TextField
              name="url"
              underlineFocusStyle={lineStyle}
              value={parent.state.url}
              onChange={(ev, url) => parent.setState({ url: formatURL(url) })}
              fullWidth
            />
          </Col>
          <Col>
            <label>Crunchbase URL</label>
            <TextField
              name="crunchbaseUrl"
              underlineFocusStyle={lineStyle}
              value={parent.state.crunchbaseUrl}
              onChange={(ev, crunchbaseUrl) =>
                parent.setState({ crunchbaseUrl: formatURL(crunchbaseUrl) })
              }
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Additional URLs</label>
            <TextField
              name="additionalUrl"
              underlineFocusStyle={lineStyle}
              value={parent.state.additionalUrl}
              onChange={(ev, additionalUrl) => {
                additionalUrl = additionalUrl
                  .split("\n")
                  .map(url => formatURL(url))
                  .join("\n");
                parent.setState({ additionalUrl });
              }}
              rowsMax={5}
              multiLine={true}
              fullWidth
            />
          </Col>
          <Col>
            <label>Notable founders / employees / investors</label>
            <TextField
              name="notables"
              underlineFocusStyle={lineStyle}
              value={parent.state.notables}
              onChange={(ev, notables) => parent.setState({ notables })}
              rowsMax={5}
              multiLine={true}
              fullWidth
            />
          </Col>
        </Row>
      </List>
    );
  }
}

export default Basics;
