import React, {Component} from "react";

import ChipElement from './Elements/Chip';
import TextElement from './Elements/Text';
import ComplexElement from './Elements/Complex';
import PopupAdElement from "./PopupAdElement";

const ElementComponents = {
    'chip': ChipElement,
    'text': TextElement,
    'complex': ComplexElement,
};

class PrintSingleMustHave extends Component {

    constructor() {
        super(...arguments);
        this.state = {element: this.props.element, showNewPopup: false, elementToEdit: this.props.element}
    }

    handlerEditedItem = (element) => {
        this.setState({
            showNewPopup: true,
            priorityType: element.type,
            elementToEdit: element
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.element !== this.props.element) {
            this.setState({
                element: this.props.element
            })
        }
    }

    onUpdate = (newElement) =>{
        const {onDone,element} = this.props;
        let updatedElement = {
            ...element,
            ...newElement
        };

        this.setState({
            element: updatedElement,
            showNewPopup: false
        }, ()=>{
            !!onDone&&onDone(updatedElement);
        })
    }

    onElementUpdate = (newElement) =>{

        const {onDone,element} = this.props;
        let updatedElement = {
            ...element,
            ...newElement
        };

        this.setState({
            element: updatedElement
        }, ()=>{
            !!onDone&&onDone(updatedElement);
        })
    }

    renderElementComponent = (el, type) => {
        let {elementToEdit} = this.state;
        const {onlyView, extraInfo} = this.props;
        type = !type ? 'complex' : type === 'chipTag' ? 'chip' : type;
        let ElementComponent = ElementComponents[type];
        return <ElementComponent
                    element={el}
                    elementToEdit={elementToEdit}
                    onApply={this.onElementUpdate}
                    onlyView={onlyView}
                    toMatchWith={extraInfo.toMatchWith}
                    candidateResume={extraInfo.candidateResume}
                    pageSource={extraInfo.source}
        />
    }

    render() {
        let {element, showNewPopup, elementToEdit, priorityType} = this.state;
        let {handlerDeleteItem, onlyView, extraInfo} = this.props;

        let type = element.type;

        if (['or', 'and', 'not'].includes(type)) {
            type = 'complex';
        }

        if(type==='chipGroup'){
            type='chip';
        }

        return <p>
            {!!onlyView ? <span><strong>-&nbsp;&nbsp;&nbsp;</strong></span> : null}
            {!onlyView && <span className="fc-group-button-icon fc-button-remove"
                                onClick={() => handlerDeleteItem(element)}></span>}
            {!onlyView && <span className="fc-group-button-icon fc-button-edit"
                                onClick={() => this.handlerEditedItem(element)}></span>}

            {this.renderElementComponent(element, type)}


            {!!showNewPopup && <PopupAdElement
                onCancel={() => {
                    this.setState({showNewPopup: false})
                }}
                onApply={this.onUpdate}
                priorityType={priorityType}
                elementToEdit={elementToEdit}
                extraInfo={extraInfo}
            />}
        </p>
    }
}

export default PrintSingleMustHave;
