import React from 'react';
import { configAccounts } from './configAccounts';
import { configCandidates } from './configCandidates';
import { configEmployers } from './configEmployers';
import { configEngagements } from './configEngagements';
import { configJobs } from './configJobs';
import { configMatchDecisionTable } from './configMatchDecisionTable';
import ReactTable from './ReactTable';

/** @todo this a provisional component to render the react table */
function ListPages(props) {

  let {
    match: { url }
  } = props;

  const config = {
    '/v3/candidates': configCandidates,
    '/v3/jobs': configJobs,
    '/v3/employers': configEmployers,
    '/v3/accounts': configAccounts,
    '/v3/engagements': configEngagements,
    '/v3/matcher': configMatchDecisionTable,
  };
  let key = Object.keys(config).find(key => url.match(key));

  // console.debug('v3', url, props);

  return (
    key
      ? (
        <div style={{ height: '100%', width: '100%' }}>
          <ReactTable
            {...props} // passing router context 
            config={config[key]} // entity's config file
          />
        </div>
      )
      : <span className="c-red">This route has not a configuration file</span>
  );
}

export default ListPages;
