import { CircularProgress } from '@mui/material';
import React from 'react';
// import loader from '../../assets/images/loader.gif';

export default function Loader({
  loading = true,
  text = 'Loading',
  className,
  style,
}) {
  return (
    loading && (
      <div className={className || 'c-gray f-sm p-1'}>
        <span>{text}</span>&nbsp;
        <CircularProgress size="0.75rem" color="primary" />
      </div>
    )
  );
}
