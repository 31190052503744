import Chip from '@mui/material/Chip';
import _ from 'lodash';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import React, { Fragment } from 'react';
import CandidatePipe from '../../../components/Candidates/MatchNew/CandidatePipe/CandidatePipe';
import JobPipe from '../../../components/Candidates/MatchNew/JobPipe/JobPipe';
import SingleJobCard from '../../../components/Candidates/MatchNew/JobPipeCard/SingleJobCard';
import Candidate from '../../../lib/Candidate';
import CandidateSkillsAction from '../../../lib/CandidateSkillsAction';
import { jobTypes } from '../../../lib/Constants';
import Core, { colors } from '../../../lib/Core';
import Engagement from '../../../lib/Engagement';
import FilterControl from '../../../lib/FilterControl';
import getJobOwnershipTextAndColor from '../../../lib/GetJobOwnershipTextAndColor';
import Google from '../../../lib/Google';
import Job from '../../../lib/Job';
import { getCandidateModel } from '../../../lib/models/candidate';
import { getJobModel } from '../../../lib/models/job';
import FilterLib from '../../../lib/services/Filtering/Filter.lib';
import MatchLib from '../../../lib/services/Matching/Match.lib';
import formatMoney from '../../../lib/tools/formatMoney';
import { YearsOfExperienceColor } from '../../../lib/YearOfExperienceColor';
import EmailPreview from '../../Dialogs/EmailPreview';
import Slider from '../../Forms/Slider';
import MatchLocationChips, {
  MATCH_LOCATION_CHIPS__CD_REQUESTER,
  MATCH_LOCATION_CHIPS__JD_REQUESTER,
} from '../../Shared/MatchLocationChips';
import CandidatePipeHeader from '../MatchNew/CandidatePipe/CandidatePipeHeader';
import JobPipeHeader from '../MatchNew/JobPipe/JobPipeHeader';
import CompanyPreference from './Comparison/CompanyPreference';
import LocationDetails from './Comparison/LocationDetails';
import Stage from './Comparison/Stage';

let candidate = null;
let allJobs = [];

let EmailMessage = null;

const subjectFunc = (id) => (cb) => {
  Candidate.get(id, (candid) => {
    Candidate.getPotentialDuplicatedWithOwnerships({ id }, (result) => {
      candid.jobOwnerships = result;
      Engagement.getWhere({ candidateId: candid.id }, (eng) => {
        candid['tempMinimumSalary'] = candid.minimumSalary;
        candid['tempMinimumExperience'] = candid.yearsOfExperience;
        candid['tempMinimumCompanySize'] = 0; // means not to check that.
        candidate = candid;
        candidate.engagements = eng;
        !!cb && cb(candidate, eng);
      });
    });
  });
};

const objectFunc = (id, cb) => {};

const objectListFunc = (params) => (cb, paramsExtra) => {
  // const paramsObj = new URLSearchParams({...params});
  const ACTIVE_STATE = 1;
  Job.getAll(
    (jobs) => {
      allJobs = jobs;
      !!cb && cb(jobs);
    },
    {
      where: {
        state: {
          inq:
            Array.isArray(paramsExtra.state) && paramsExtra.state.length > 0
              ? paramsExtra.state
              : [ACTIVE_STATE],
        },
      },
    }
  );
};

const getDefaultFields = (candidate) => {
  return [
    { key: 'visa', label: candidate._visa, checked: true },
    { key: 'location', label: candidate._workLocationIds, checked: true },
    // {key: 'stage', label: candidate._desiredStage, checked: true}
  ];
};

const filterControl = ({ subject, object, selected }) => {
  let preset = '^Active$';
  if (subject._roles && subject._roles.length) {
    preset += '|^' + subject._roles.replace(/, /g, '$|^') + '$';
  }

  /**
   * If it is Candidate._desiredEmploymentTypes defined
   * then it will be preset in the filter menu to be matching
   * with Job._jobType
   * story-3083-M2-1
   */
  if (
    subject._desiredEmploymentTypes &&
    subject._desiredEmploymentTypes.length
  ) {
    preset +=
      '|' +
      subject._desiredEmploymentTypes
        .split(',')
        .map((n) => n.trim().replace(/\(|\)/g, '.+'))
        .join('|') +
      '$';
  }

  const label = getDefaultFields(subject);
  const fields = label;

  const filters = FilterControl.setInitialSearchMenu(
    object,
    _.cloneDeep(Job.menus),
    _.cloneDeep(Job.more),
    preset,
    subject,
    [],
    label,
    fields,
    getChips,
    { candidate: subject }
  );

  const { menus, more, sources, chips } = filters;

  const jobsHavingConstraints = object.filter((job) =>
    filterJobsContainConstraints(subject, job)
  );
  const jobsWithoutConstraints = object.filter(
    (job) => !filterJobsContainConstraints(subject, job)
  );

  const filteredObjects = filterJobs(
    subject,
    jobsWithoutConstraints,
    [],
    menus,
    more,
    selected,
    jobsHavingConstraints
  );

  return {
    menus,
    more,
    sources,
    chips,
    filteredObjects,
    constraintsObjects: jobsHavingConstraints,
    withoutConstraintsObjects: jobsWithoutConstraints,
  };
};

const skillsHandler = (skills, type) => {
  let oldSkills;

  switch (type) {
    case 'technicalSkills':
      oldSkills = candidate.technicalSkills;
      candidate = {
        ...candidate,
        technicalSkills: skills,
      };

      Candidate.update(
        candidate.id,
        {
          technicalSkills: skills,
        },
        (response) => {
          const dirtyAttrs = [
            {
              key: type,
              label: 'Technical Skills',
              oldState: oldSkills,
              newState: skills,
            },
          ];

          CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
          Core.showMessage('Saved Successfully!');
        }
      );
      break;
    case 'technologyDomain':
      oldSkills = candidate.technologyDomain;
      candidate = {
        ...candidate,
        technologyDomain: skills,
      };

      Candidate.update(
        candidate.id,
        {
          technologyDomain: skills,
        },
        (response) => {
          const dirtyAttrs = [
            {
              key: type,
              label: 'Technology Domain',
              oldState: oldSkills,
              newState: skills,
            },
          ];

          CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
          Core.showMessage('Saved Successfully!');
        }
      );
      break;
    case 'positiveSignals':
      oldSkills = candidate.positiveSignals;
      candidate = {
        ...candidate,
        positiveSignals: skills,
      };
      Candidate.update(
        candidate.id,
        {
          positiveSignals: skills,
        },
        (response) => {
          const dirtyAttrs = [
            {
              key: type,
              label: 'Positive Signals',
              oldState: oldSkills,
              newState: skills,
            },
          ];
          CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
          Core.showMessage('Saved Successfully!');
        }
      );
      break;
    case 'negativeSignals':
      oldSkills = candidate.negativeSignals;
      candidate = {
        ...candidate,
        negativeSignals: skills,
      };

      Candidate.update(
        candidate.id,
        {
          negativeSignals: skills,
        },
        (response) => {
          const dirtyAttrs = [
            {
              key: type,
              label: 'Negative Signals',
              oldState: oldSkills,
              newState: skills,
            },
          ];

          CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
          Core.showMessage('Saved Successfully!');
        }
      );
      break;
    default:
      break;
  }
};
// const skillsHandler = (technicalSkills) => {
//     const oldTechnicalSkills = candidate.technicalSkills;

//     candidate= {
//         ...candidate,
//         technicalSkills
//     }

//     Candidate.update(candidate.id, {
//         technicalSkills
//     }, (response) => {

//         const dirtyAttrs = [{
//             key: "technicalSkills",
//             label: "Technical Skills",
//             oldState: oldTechnicalSkills,
//             newState: technicalSkills
//         }];

//         CandidateSkillsAction.processUpdate(candidate, dirtyAttrs);
//     });

// }

const saveContentCandidate = (key, content) => {
  candidate = {
    ...candidate,
    [key]: content,
  };

  Candidate.update(
    candidate.id,
    {
      [key]: content,
    },
    () => {
      Core.showMessage('Saved Successfully!');
    }
  );
};

const openMessage = (e, candidate) => {
  const emails = [];
  // let candidate = this.state.candidate
  candidate.email &&
    emails.push({
      name: candidate._name || 'Candidate',
      email: candidate.email,
    });
  candidate.recruiter.email &&
    emails.push({
      name: candidate.recruiter._name || 'Recruiter',
      email: candidate.recruiter.email,
    });
  Core.dialog.open({
    title: <>Message</>,
    message: (
      <EmailPreview
        ref={(self) => (EmailMessage = self)}
        emails={emails}
        to={!!emails[0] && [emails[0]]}
        subject=""
        body={[].filter((line) => !!line).join('<br/>')}
      />
    ),
    className: 'p-0',
    actions: [
      <FlatButton
        label="Cancel"
        className="button-flat-darker"
        onClick={(ev) => {
          Core.dialog.close();
        }}
      />,
      <FlatButton
        label="Send"
        className="button-white-cyan"
        onClick={(ev) => {
          Core.dialog.close();
          Google.sendEmail(
            {
              ...EmailMessage.getParams(),
              source: 'Candidates/MatchStrategy/Strategy.js',
            },
            (response) => Core.showMessage('Email sent'),
            (error) => Core.showFailure(error)
          );
        }}
      />,
    ],
  });
};

const makeChipsForCandidate = (job, candidate) => {
  job = job || getJobModel({ extended: true });
  candidate = candidate || getCandidateModel({ extended: true });

  return (
    <div className="ui-c/match/c/details/chips">
      <Chip
        className="slim-chip"
        variant="outlined"
        size="small"
        label={!!candidate && !!candidate._visa && candidate._visa}
      />
      {!!candidate &&
        !!candidate._roles &&
        candidate._roles
          .split(',')
          .map((role, index) => (
            <Chip
              key={index}
              className="slim-chip"
              variant="outlined"
              size="small"
              label={role}
            />
          ))}
      <Chip
        className="slim-chip"
        variant="outlined"
        size="small"
        label={`${
          !!candidate &&
          typeof candidate.yearsOfExperience !== 'undefined' &&
          candidate.yearsOfExperience
        } yrs`}
      />

      <MatchLocationChips
        {...{
          requester: MATCH_LOCATION_CHIPS__CD_REQUESTER,
          job,
          candidate,
        }}
      />

      <Chip
        className="slim-chip"
        variant="outlined"
        size="small"
        label={`>$${
          !!candidate &&
          typeof candidate.minimumSalary !== 'undefined' &&
          candidate.minimumSalary
        }`}
      />
      <Chip
        className="slim-chip"
        variant="outlined"
        size="small"
        label={
          !!candidate && !!candidate.platformRating && candidate._platformRating
        }
      />
      <CompanyPreference
        candidateCompanyPreferenceMin={candidate.minCompanySize}
        candidateCompanyPreferenceMax={candidate.maxCompanySize}
        jobCompanyPreference={
          !!job && job.employer ? job.employer.employeeCount : null
        }
        source="candidate"
      />
      {/**
       * @todo
       *
       * Review it to cleanup
       * 2021-07-15 Thu µ
       *
       */}
      {/** * /}
      <WorkFromHome
        candidateWorkFromHome={candidate.workRemotely}
        jobWorkFromHome={!!job ? job.remote : null}
        source="candidate"
      />
      {/** */}
      <Stage
        candidateStage={candidate.desiredStage}
        jobStage={!!job && job.employer ? job.employer.stage : null}
        source="candidate"
      />
      <LocationDetails
        candidateText={candidate.locationDetails}
        jobText={
          !!job && !!job.remoteExtraInfo
            ? job.remoteExtraInfo
            : !!job && !!job.employer.remoteExtraInfo
            ? job.employer.remoteExtraInfo
            : null
        }
        source="candidate"
      />
    </div>
  );
};

const candidatePipe = (candidate, selectedJob, moveToApprovedChips) => {
  return (
    <CandidatePipe
      candidate={candidate}
      job={selectedJob}
      skillsHandler={skillsHandler}
      saveContentCandidate={saveContentCandidate}
      source="candidateMatch"
      moveToApprovedChips={moveToApprovedChips}
      candidatePipeHeader={candidatePipeHeader}
    />
  );
};

const candidatePipeHeader = (candidate, selectedJob) => {
  return (
    <CandidatePipeHeader
      candidate={candidate}
      openMessage={openMessage}
      job={selectedJob}
      makeChipsForCandidatePipe={makeChipsForCandidate}
    />
  );
};

const saveContentJob = (job, cbUpdateJobs) => (key, content) => {
  const oldJobState = allJobs.find((j) => j.id === job.id) || job;
  const oldSJobStateIndex = allJobs.findIndex((j) => j.id === job.id);
  const withoutThisJob = allJobs.filter((j) => j.id !== job.id);

  const updateOldJobState = {
    ...oldJobState,
    [key]: content,
  };

  let updatedJobs = [...withoutThisJob];

  updatedJobs.splice(oldSJobStateIndex, 0, updateOldJobState);

  allJobs = [...updatedJobs];
  !!cbUpdateJobs && cbUpdateJobs(allJobs);
  Job.update(
    job.id,
    {
      [key]: content,
    },
    () => {
      Core.showMessage('Saved Successfully!');
    }
  );
};

const staticRowDisplayChip = (jobAtr, candAtr, color) => {
  const singleAttrJob = jobAtr.split(',');

  if (!jobAtr || !candAtr) {
    return singleAttrJob.map((attr, index) => {
      return (
        <Chip
          key={index}
          className="slim-chip ui-candidate-match-strategy-chip-a"
          label={attr}
          size="small"
          variant="outlined"
          style={{
            border: `1px solid gray`,
          }}
        />
      );
    });
  }

  return singleAttrJob.map((attr, index) => {
    return (
      <Chip
        key={index}
        className="slim-chip ui-candidate-match-strategy-chip-b"
        label={attr}
        size="small"
        variant="outlined"
        style={{
          border: `${color === 'red' ? 2 : 1}px solid ${color}`,
        }}
      />
    );
  });
};

const staticRowDisplayColor = (job, candidate, color) => {
  let obj = {};

  obj.getSalaryColor = ((candidate, job) => () => {
    if (!candidate.minimumSalary || !job.salaryMax) {
      return '';
    }

    let color;

    if (candidate.minimumSalary <= job.salaryMax) {
      color = 'green';
    } else if (candidate.minimumSalary <= 1.15 * job.salaryMax) {
      color = 'grey';
    } else if (candidate.minimumSalary <= 1.4 * job.salaryMax) {
      color = 'grey';
    } else {
      color = 'red';
    }
    // console.log("salary " + color + " cand sal "+candidate.minimumSalary+ " job sal " + job.salaryMax)
    return color;
  })(candidate, job);

  obj.getVisaColor = ((candidate, job) => () => {
    let menu = Candidate.menus.find((obj) => obj.key === 'visa');
    let myMappings = menu.mappings[job._visaTransfer] || [];
    return myMappings.includes(candidate._visa) ? 'green' : 'red';
  })(candidate, job);

  obj.getRolesColor = ((candidate, jobRole) => () => {
    const rolesRegExp = new RegExp(jobRole.trim(), 'gi').test(candidate._roles);
    return !!rolesRegExp ? 'green' : color;
  })(candidate, job);

  obj.getLocationColor = ((candidate, jobLoc) => () => {
    const locRegExp = new RegExp(jobLoc.trim(), 'gi').test(
      candidate._workLocationIds
    );
    return !!locRegExp ? 'green' : color;
  })(candidate, job);

  obj.getYearsXp = ((candidate, job) => () => {
    return YearsOfExperienceColor(job, candidate);
  })(candidate, job);
  return obj;
};

const stringToChips = (words) => {
  if (typeof words === 'number') {
    words = words.toString();
  }
  if (typeof words === 'string') {
    if (words === '') {
      return null;
    } else if (words.includes(',')) {
      words = words.split(/[,]/);
    } else {
      words = [words];
    }
  }

  return (
    <div>
      {words.map((word, index) => {
        return (
          <Fragment key={index}>
            <Chip
              key={index}
              className="chip slim-chip"
              variant="outlined"
              size="small"
              label={word}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

const segregateRolesChip = (job, candidate) => {
  let colorAlarm = 'red';

  if (!!job && !!job._roles) {
    job._roles.split(',').forEach((roles) => {
      const rolesRegExp = new RegExp(roles.trim(), 'gi').test(candidate._roles);
      if (!!rolesRegExp) colorAlarm = '';
    });

    return job._roles.split(',').map((roles) => {
      return staticRowDisplayChip(
        roles,
        candidate._roles,
        staticRowDisplayColor(roles, candidate, colorAlarm).getRolesColor()
      );
    });
  }
};

const segregateLocationChip = (job, candidate) => {
  let colorAlarm = 'red';

  if (!!job && !!job._locations) {
    job._locations.split(',').forEach((loc) => {
      const locRegExp = new RegExp(loc.trim(), 'gi').test(
        candidate._workLocationIds
      );
      if (!!locRegExp) colorAlarm = '';
    });

    return job._locations.split(',').map((loc) => {
      return staticRowDisplayChip(
        loc,
        candidate._workLocationIds,
        staticRowDisplayColor(loc, candidate, colorAlarm).getLocationColor()
      );
    });
  }
};

const makeChipsForJob = (selectedJob, candidate) => {
  selectedJob = selectedJob || getJobModel({ extended: true });
  candidate = candidate || getCandidateModel({ extended: true });

  return (
    <div>
      {!!selectedJob &&
        !!candidate &&
        !!selectedJob._visaTransfer &&
        !!candidate._visa &&
        staticRowDisplayChip(
          selectedJob._visaTransfer,
          candidate._visa,
          staticRowDisplayColor(selectedJob, candidate).getVisaColor()
        )}
      {segregateRolesChip(selectedJob, candidate)}
      {!!selectedJob &&
        !!candidate &&
        !!selectedJob.minYearsOfExperience &&
        staticRowDisplayChip(
          selectedJob.minYearsOfExperience + ' yrs',
          candidate._yearsOfExperienceForCalc,
          staticRowDisplayColor(selectedJob, candidate).getYearsXp()
        )}

      <MatchLocationChips
        {...{
          requester: MATCH_LOCATION_CHIPS__JD_REQUESTER,
          job: selectedJob,
          candidate,
        }}
      />

      {!!selectedJob &&
        !!candidate &&
        !!selectedJob.salaryMax &&
        staticRowDisplayChip(
          '<=$' + selectedJob.salaryMax,
          candidate.minimumSalary,
          staticRowDisplayColor(selectedJob, candidate).getSalaryColor()
        )}
      {!!selectedJob &&
        !!selectedJob._employeeRating &&
        staticRowDisplayChip(selectedJob._employeeRating, null, null)}
      <CompanyPreference
        candidateCompanyPreferenceMin={candidate.minCompanySize}
        candidateCompanyPreferenceMax={candidate.maxCompanySize}
        jobCompanyPreference={
          !!selectedJob && selectedJob.employer
            ? selectedJob.employer.employeeCount
            : null
        }
        source="job"
      />
      {/**
       * @todo
       *
       * Review it to cleanup
       * 2021-07-15 Thu µ
       *
       */}
      {/** * /}
      <WorkFromHome
        candidateWorkFromHome={candidate.workRemotely}
        jobWorkFromHome={!!selectedJob ? selectedJob.remote : null}
        source="job"
      />
      {/** */}
      <Stage
        candidateStage={candidate.desiredStage}
        jobStage={
          !!selectedJob && selectedJob.employer
            ? selectedJob.employer.stage
            : null
        }
        source="job"
      />
      <LocationDetails
        candidateText={candidate.locationDetails}
        jobText={
          !!selectedJob && !!selectedJob.remoteExtraInfo
            ? selectedJob.remoteExtraInfo
            : !!selectedJob && !!selectedJob.employer.remoteExtraInfo
            ? selectedJob.employer.remoteExtraInfo
            : null
        }
        source="job"
      />
    </div>
  );
};

const jobPipe = (selectedJob, cbUpdateJobs) => {
  return (
    <JobPipe
      candidate={candidate}
      selectedJob={selectedJob}
      saveContentJob={saveContentJob(selectedJob, cbUpdateJobs)}
      staticRowDisplayChip={staticRowDisplayChip}
      staticRowDisplayColor={staticRowDisplayColor}
      stringToChips={stringToChips}
      source="candidateMatch"
      jobPipeHeader={jobPipeHeader}
    />
  );
};

const jobPipeHeader = (selectedJob) => {
  return (
    <JobPipeHeader
      candidate={candidate}
      selectedJob={selectedJob}
      makeChipsForJobPipe={makeChipsForJob}
    />
  );
};

const jobContainConstraints = (candidate, job, constraint) => {
  //for permitted/declined/pitched
  if (
    Array.isArray(candidate[constraint]) &&
    candidate[constraint].includes(job.id)
  ) {
    return true;
  }
  return false;
};

const isJobBlackListed = (candidate, job) => {
  const { accountId } = candidate;
  const { employer } = job;

  if (
    (Array.isArray(job.jobBlackList) &&
      job.jobBlackList.map((el) => el.id).includes(accountId)) ||
    (Array.isArray(Object(employer).employerBlackList) &&
      employer.employerBlackList.map((el) => el.id).includes(accountId))
  ) {
    return true;
  }
  return false;
};

const getJobLabels = (candidate, job) => {
  let jobTypes = [];

  if (jobContainConstraints(candidate, job, 'jobsPermitted')) {
    jobTypes.push('Permitted');
  }
  if (jobContainConstraints(candidate, job, 'jobsPitched')) {
    jobTypes.push('Awaiting');
  }
  if (jobContainConstraints(candidate, job, 'jobsDeclined')) {
    jobTypes.push('Declined');
  }
  if (isJobBlackListed(candidate, job)) {
    jobTypes.push('BlackListed');
  }

  return jobTypes;
};

const getOwnershipChip = (candidate, job) => {
  let { color, label } = getJobOwnershipTextAndColor(
    candidate.jobOwnerships,
    job.employerId,
    candidate.id
  );
  const isJobBlacklisted = isJobBlackListed(candidate, job);
  color = isJobBlacklisted ? colors.lightRed : color;
  label = isJobBlacklisted ? 'UnavailableToCando' : label;

  const order = Engagement.stageOrder;
  const eng = candidate.engagements.find(
    (eng) =>
      eng.job.employerId === job.employerId &&
      order.indexOf(eng.stage) > order.indexOf('Review')
  );

  const ret = [label];
  let priorSubText = '';

  if (!!eng) {
    priorSubText = 'Prior Submission*';
  }

  const isStrongConflictWithEngagement = candidate.jobOwnerships.find(
    (objM) => {
      return (
        objM.isPotentialStrongConflict &&
        !!objM.conflictedEmployers.find(
          (obj) =>
            !!obj.hasEngagement &&
            String(obj.employerId) === String(job.employerId)
        )
      );
    }
  );

  if (!!isStrongConflictWithEngagement) {
    priorSubText = !!priorSubText.length
      ? priorSubText + '^'
      : 'Prior Submission^';
  }

  if (!!priorSubText.length) {
    ret.push(priorSubText);
  }

  return ret.map((label) => {
    return (
      <Fragment key={`${label}-${job.id}-${candidate.id}`}>
        <span
          style={{
            backgroundColor: color,
            color: 'white',
            padding: '2px',
            marginRight: '2px',
          }}
        >
          {label}
        </span>
      </Fragment>
    );
  });
};

const notFoundInPermittedJobWithOwnership = (candidate, job, newEngagement) => {
  let { isOwned, iOwnIt, isTbd, iTbdItWithoutEngagement } =
    getJobOwnershipTextAndColor(
      candidate.jobOwnerships,
      job.employerId,
      candidate.id
    );
  let msg = false;

  let isOwnedBySomeone = isOwned.length && !iOwnIt;

  if (isOwnedBySomeone) {
    msg =
      "Another recruiter has ownership for this candidate for this employer, you aren't  allowed to create this engagement";
  } else if (
    (!!isTbd.length || iTbdItWithoutEngagement) &&
    /submission/i.test(newEngagement.status)
  ) {
    msg =
      'The ownership for the candidate has not been finalized, so cannot proceed to submission stage';
  }

  return msg;
};

const jobListCard = ({
  job,
  type,
  candidate,
  isSelected,
  annotatorMode,
  MLScoreMode,
  handlerSelectJob,
  handlerOnSelectBox,
  multiSelectedJobs,
  handlerMatchJob,
  createDisagreement,
  createSingleEngagement,
  createSingleEngagementWithDis,
  pushEntityToCandidate,
  isJobEngageable,
  index,
  style,
  handlerMIScoreObjects,
  measure,
  label,
  resizeIndex,
  resizeAllIndices,
  processMLScoreSingle,
}) => {
  return (
    <SingleJobCard
      job={job}
      key={`cando-match-card-${job.id}-${type}`}
      candidate={candidate}
      selected={isSelected}
      handlerSelectJob={handlerSelectJob}
      annotatorMode={annotatorMode}
      handlerOnSelectBox={handlerOnSelectBox}
      handlerMatchJob={handlerMatchJob}
      createDisagreement={createDisagreement}
      createSingleEngagement={createSingleEngagement}
      createSingleEngagementWithDis={createSingleEngagementWithDis}
      pushEntityToCandidate={pushEntityToCandidate}
      isJobEngageable={isJobEngageable}
      getJobLabels={getJobLabels}
      getOwnershipChip={getOwnershipChip}
      notFoundInPermittedJobWithOwnership={notFoundInPermittedJobWithOwnership}
      multiSelectedJobs={multiSelectedJobs}
      staticRowDisplayChip={staticRowDisplayChip}
      staticRowDisplayColor={staticRowDisplayColor}
      index={index}
      style={style}
      measure={measure}
      segregateRolesChip={segregateRolesChip}
      segregateLocationChip={segregateLocationChip}
      handlerMIScoreObjects={handlerMIScoreObjects}
      MLScoreMode={MLScoreMode}
      label={label}
      resizeIndex={resizeIndex}
      resizeAllIndices={resizeAllIndices}
      strategyGetConstraints={jobContainConstraints}
      processMLScoreSingle={processMLScoreSingle}
    />
  );
};

const engagementListCard = ({
  engagement,
  type,
  candidate,
  isSelected,
  annotatorMode,
  MLScoreMode,
  handlerSelectJob,
  handlerOnSelectBox,
  multiSelectedJobs,
  handlerMatchJob,
  createDisagreement,
  createSingleEngagement,
  createSingleEngagementWithDis,
  pushEntityToCandidate,
  isJobEngageable,
  afterUpdateEngagement,
  index,
  style,
  handlerMIScoreObjects,
  updateEngagement,
  measure,
  label,
  resizeIndex,
  resizeAllIndices,
  processMLScoreSingle,
}) => {
  return (
    <SingleJobCard
      key={`cando-match-card-${engagement.id}-${type}`}
      job={engagement.job}
      engagement={engagement}
      candidate={candidate}
      annotatorMode={annotatorMode}
      selected={isSelected}
      handlerSelectJob={handlerSelectJob}
      handlerMatchJob={handlerMatchJob}
      handlerOnSelectBox={handlerOnSelectBox}
      createDisagreement={createDisagreement}
      createSingleEngagement={createSingleEngagement}
      createSingleEngagementWithDis={createSingleEngagementWithDis}
      pushEntityToCandidate={pushEntityToCandidate}
      isJobEngageable={isJobEngageable}
      getJobLabels={getJobLabels}
      afterMainUpdateEngagement={afterUpdateEngagement}
      multiSelectedJobs={multiSelectedJobs}
      staticRowDisplayChip={staticRowDisplayChip}
      staticRowDisplayColor={staticRowDisplayColor}
      getOwnershipChip={getOwnershipChip}
      notFoundInPermittedJobWithOwnership={notFoundInPermittedJobWithOwnership}
      index={index}
      style={style}
      measure={measure}
      segregateRolesChip={segregateRolesChip}
      segregateLocationChip={segregateLocationChip}
      handlerMIScoreObjects={handlerMIScoreObjects}
      MLScoreMode={MLScoreMode}
      updateEngagement={updateEngagement}
      label={label}
      resizeIndex={resizeIndex}
      resizeAllIndices={resizeAllIndices}
      strategyGetConstraints={jobContainConstraints}
      processMLScoreSingle={processMLScoreSingle}
    />
  );
};

const filterJobsContainConstraints = (candidate, job) => {
  if (
    (Array.isArray(candidate[jobTypes.jobsPermitted]) &&
      candidate[jobTypes.jobsPermitted].includes(job.id)) ||
    (Array.isArray(candidate[jobTypes.jobsPitched]) &&
      candidate[jobTypes.jobsPitched].includes(job.id)) ||
    (Array.isArray(candidate[jobTypes.jobsDeclined]) &&
      candidate[jobTypes.jobsDeclined].includes(job.id))
  ) {
    return true;
  }
  return false;
};

const getChips = (candidate, menus, more, keywords) => {
  const chips = [];

  menus.forEach((menu) => {
    menu.items &&
      Object.keys(menu.items).forEach(
        (name) =>
          menu.items[name] === true &&
          chips.push({ name, menu: true, key: menu.key })
      );
  });

  more.forEach((menu) => {
    menu.items &&
      Object.keys(menu.items).forEach(
        (name) =>
          menu.items[name] === true &&
          chips.push({ name, more: true, key: menu.key })
      );
  });

  !!keywords.length && keywords.forEach((item) => chips.push(item));

  if (!!candidate.tempMinimumSalary && candidate.tempMinimumSalary !== 0) {
    let prefix = '';
    prefix = 'Pays Salary >=';

    chips.push({
      name: `${prefix} $${formatMoney(candidate.tempMinimumSalary, 0)}`,
      minimumSalary: true,
    });
  }

  if (!!candidate.maximumSalary && candidate.maximumSalary !== 250000) {
    chips.push({
      name: `Max Salary: $${formatMoney(candidate.maximumSalary, 0)}`,
      maximumSalary: true,
    });
  }

  if (
    !!candidate.tempMinimumExperience &&
    candidate.tempMinimumExperience > 0
  ) {
    let prefix = '';
    prefix = `require <= ${candidate.tempMinimumExperience}y exp`;

    chips.push({
      name: `${prefix}`,
      minimumXp: true,
    });
  }

  return chips;
};

const filterJobs = (
  candidate,
  jobs,
  keywords,
  menus,
  more,
  selectedId,
  jobsHavingConstraints
) => {
  // const jobsHavingConstraints = jobs.filter(job => filterJobsContainConstraints(candidate, job));
  if (!jobs) {
    jobs = [];
  }

  MatchLib.unitTestingPresetLocationMenusAll({ candidate });

  if (!jobsHavingConstraints) {
    jobsHavingConstraints = [];
  }

  const selectedJob = jobs.find((job) => job.id === selectedId);
  let filtered = jobs;
  /** FILTER BY MINIMUM AND MAXIMUM SALARY */

  if (!!candidate.tempMinimumSalary && candidate.tempMinimumSalary !== 0) {
    filtered = filtered.filter((item) => {
      const job = item;
      let salaryGreaterThan = Number(
        String(job.salaryMax || Number.MAX_SAFE_INTEGER).replace(
          /(\..*)|\D/g,
          ''
        )
      );

      if (!!candidate.applyLooseMatch) {
        console.log(
          `#############inside loose match ${candidate.applyLooseMatch}`
        );
        //increase salary requirement of given job for highly ranked candidates
        if (+candidate.platformRating === 5) {
          // A+
          salaryGreaterThan = 1.5 * salaryGreaterThan;
        } else if (+candidate.platformRating === 1) {
          // A-Top
          salaryGreaterThan = 1.3 * salaryGreaterThan;
        } else if (+candidate.platformRating === 2) {
          // B-Strong
          salaryGreaterThan = 1.15 * salaryGreaterThan;
        }
      }

      return salaryGreaterThan >= Number(candidate.tempMinimumSalary);
    });
  }
  //
  // /** FILTER BY MINIMUM AND MAXIMUM XP */
  if (
    !!candidate.tempMinimumExperience &&
    candidate.tempMinimumExperience > 0
  ) {
    filtered = filtered.filter((item) => {
      const job = item;
      let experienceLessThan = Number(
        Number(job.minYearsOfExperience) > -1
          ? String(job.minYearsOfExperience).replace(/(\..*)|\D/g, '')
          : String(Number.MAX_SAFE_INTEGER).replace(/(\..*)|\D/g, '')
      );

      if (!!candidate.applyLooseMatch) {
        //reduce years of experience requirement of given job for highly ranked candidates
        if (+candidate.platformRating === 5) {
          // A+
          experienceLessThan = 0.5 * experienceLessThan;
        } else if (+candidate.platformRating === 1) {
          // A-Top
          experienceLessThan = 0.7 * experienceLessThan;
        } else if (+candidate.platformRating === 2) {
          // B-Strong
          experienceLessThan = 0.85 * experienceLessThan;
        }
      }

      return experienceLessThan <= Number(candidate.tempMinimumExperience);
    });
  }

  /* FILTER BY COMPANY SIZE */
  if (
    !!candidate.tempMinimumCompanySize &&
    candidate.tempMinimumCompanySize > 0
  ) {
    filtered = filtered.filter(
      (item) =>
        !!item.employer &&
        Number(
          Number(item.employer.employeeCount) > -1
            ? String(item.employer.employeeCount).replace(/(\..*)|\D/g, '')
            : String(Number.MAX_SAFE_INTEGER).replace(/(\..*)|\D/g, '')
        ) <= Number(candidate.tempMinimumCompanySize)
    );
  }

  /** FILTER BY KEYWORDS */
  if (!!keywords.length) {
    filtered = filtered.filter((item) => {
      return keywords.every((objKeyword) => {
        return item.___keys___.some((label) =>
          new RegExp(
            String(objKeyword.name)
              .replace('+', '\\+')
              .replace('.', '\\.')
              .replace('#', '\\#')
              .replace('(', '\\(')
              .replace(')', '\\)'),
            'i'
          ).test(label)
        );
      });
    });
  }

  /* epic-3038(new locations)-story-3578-M2 | 2021-07-29 Thu µ */
  filtered = FilterLib.filterItemsByCheckedOptionsInFilterBar({
    items: filtered,
    menus,
    more,
  });

  return [selectedJob, ...jobsHavingConstraints, ...filtered].filter(
    (el) => !!el
  );
};

const jobOfferSalary = (closeEvent, applyEvent, onChangeEvent, salary) => {
  return (
    <Dialog
      title={'Filter by'}
      onRequestClose={(ev) => console.log('apply nothing')}
      actions={
        <Fragment>
          <FlatButton
            label="Cancel"
            primary={true}
            onClick={(ev) => closeEvent()}
          />
          <FlatButton
            label="Apply"
            primary={true}
            onClick={(ev) => {
              applyEvent(salary);
            }}
          />
        </Fragment>
      }
      modal={false}
      open={true}
      autoScrollBodyContent={true}
      contentStyle={{ width: 550 }}
      bodyStyle={{ padding: '0px 20px 20px' }}
    >
      <label>Job can offer up to:&nbsp; ${formatMoney(salary, 0)}</label>
      <Slider
        name="minimumSalary"
        min={0}
        max={250000}
        step={10000}
        value={salary}
        onChange={(event, salary) => {
          !!onChangeEvent && onChangeEvent(salary);
        }}
      />
    </Dialog>
  );
};

const jobOfferExperience = (
  closeEvent,
  applyEvent,
  onChangeEvent,
  experience
) => {
  return (
    <Dialog
      title={'Filter by'}
      onRequestClose={(ev) => console.log('apply nothing')}
      actions={
        <Fragment>
          <FlatButton
            label="Cancel"
            primary={true}
            onClick={(ev) => closeEvent()}
          />
          <FlatButton
            label="Apply"
            primary={true}
            onClick={(ev) => {
              applyEvent(experience);
            }}
          />
        </Fragment>
      }
      modal={false}
      open={true}
      autoScrollBodyContent={true}
      contentStyle={{ width: 550 }}
      bodyStyle={{ padding: '0px 20px 20px' }}
    >
      <label>
        Jobs require equal or less than:&nbsp; {formatMoney(experience, 0)}{' '}
        years
      </label>
      <Slider
        name="minimumExperience"
        min={0}
        max={40}
        step={1}
        value={experience}
        onChange={(event, experience) => {
          !!onChangeEvent && onChangeEvent(experience);
        }}
      />
    </Dialog>
  );
};

const jobOfferCompanySize = (
  closeEvent,
  applyEvent,
  onChangeEvent,
  companySize
) => {
  return (
    <Dialog
      title={'Filter by'}
      onRequestClose={(ev) => console.log('apply nothing')}
      actions={
        <Fragment>
          <FlatButton
            label="Cancel"
            primary={true}
            onClick={(ev) => closeEvent()}
          />
          <FlatButton
            label="Apply"
            primary={true}
            onClick={(ev) => {
              applyEvent(companySize);
            }}
          />
        </Fragment>
      }
      modal={false}
      open={true}
      autoScrollBodyContent={true}
      contentStyle={{ width: 550 }}
      bodyStyle={{ padding: '0px 20px 20px' }}
    >
      <label>
        Jobs require equal or less than:&nbsp;{companySize}&nbsp;company size
      </label>
      <Slider
        name="minimumExperience"
        min={0}
        max={10000}
        step={100}
        value={companySize}
        onChange={(event, companySize) => {
          !!onChangeEvent && onChangeEvent(companySize);
        }}
      />
    </Dialog>
  );
};

const candidatePutDownJobs = (id, putDownJobs) => {
  Candidate.update(id, {
    putDownJobs,
  });
};

const disEngagementModel = ({
  matchStrength,
  candidate,
  employer,
  job,
  engagement,
  shouldTag = '',
  shouldNotTag = '',
  whyNoPrivateNote = '',
  whyNeedToReadCV = '',
  whyNoCategories = [],
  whyNoDetails = '',
  whyNoFieldsValues = '',
  reviewed,
}) => {
  return {
    source: 'candidateMatch',
    annotator: Core.getSessionEmail(),
    matchDecision: matchStrength,
    candidateName: candidate._name,
    employerName: Object(employer).name,
    jobName: job.jobTitle,
    engagementStage: Object(engagement).stage,
    engagementStatus: Object(engagement).status,
    matchingUrl: `https://staging.go10x10.com/#/candidate/matchNew/${candidate.id}?selected=${job.id}`,
    engagementUrl: !!engagement
      ? `https://staging.go10x10.com/#/engagement/view/${engagement.id}`
      : '',
    candidateUrl: `https://staging.go10x10.com/#/candidate/edit/${candidate.id}`,
    jobUrl: `https://staging.go10x10.com/#/job/edit/${job.id}`,
    engagementId: Object(engagement).id,
    jobId: Object(job).id,
    candidateId: Object(candidate).id,
    jobRoles: job._roles,
    reviewed,
    shouldTag,
    whyNoDetails,
    whyNoFieldsValues,
    whyNoCategories,
    shouldNotTag,
    whyNoPrivateNote,
    whyNeedToReadCV,
  };
};

const makeEngagement = (candidate, job) => {
  return {
    candidate: candidate,
    job: job,
  };
};

const StrategyMain = function (id, param) {
  return {
    subjectFunc: subjectFunc(id),
    objectFunc,
    objectListFunc: objectListFunc(param),
    sourcePipe: candidatePipe,
    sourcePipeHeader: candidatePipeHeader,
    objectPipe: jobPipe,
    objectPipeHeader: jobPipeHeader,
    objectListCard: jobListCard,
    objectListCardEng: engagementListCard,
    filterControl,
    filterObjects: filterJobs,
    salaryPopup: jobOfferSalary,
    experiencePopup: jobOfferExperience,
    companySizePopup: jobOfferCompanySize,
    getChips: getChips,
    putDownCallback: candidatePutDownJobs,
    disEngagementModel,
    makeEngagement,
    getObjectLabels: getJobLabels,
    getObjectConstraints: jobContainConstraints,
    getObjectBlackListed: isJobBlackListed,
    notFoundInPermittedJobWithOwnership: notFoundInPermittedJobWithOwnership,
    getOwnershipChip: getOwnershipChip,
    sourceKey: 'job',
  };
};

export { StrategyMain, notFoundInPermittedJobWithOwnership, getOwnershipChip };
