import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Checkbox from "material-ui/Checkbox";
import IconButton from "material-ui/IconButton";
import IconMenu from "material-ui/IconMenu";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import Popover from "material-ui/Popover";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Candidate from "../../../../lib/Candidate";
import { engagementMatchStrength } from "../../../../lib/Constants";
import Core from "../../../../lib/Core";
import Definition from "../../../../lib/Definition";
import Engagement from "../../../../lib/Engagement";
import { CANDIDATE_MODEL_NAME } from "../../../../lib/models/candidate";
import Streak from "../../../../lib/Streak";
import CreateDisagreement from "../../../Disagreements/createWithPopup";
import ManageEngagement from "../../../Shared/ManageEngagement";
import Heading from "../../../Shared/Match/ObjectListPipe/Heading";
import JobCardThumbsActions from "../../../Shared/Match/ObjectListPipe/JobCardThumbsActions";
import MultiSelectJob from "../../../Shared/Match/ObjectListPipe/MultiSelectJob";
import MatchLocationChips, { MATCH_LOCATION_CHIPS__CD_REQUESTER } from "../../../Shared/MatchLocationChips";

class SingleCandidateCard extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      job: this.props.job,
      selected: this.props.selected,
      forceMlOn: this.props.MLScoreMode,
      openPopover: false,
      anchorEl: null,
      getDisagreementDetails: false,
      showQuestions: false,
      wantsUpdateEngagement: false,
      engagement: this.props.engagement,
      reviewed: false,
      MlDecisionPending: true,
      otherDecisionMakers: [],
    };

    const job = this.props.job;

    if (!job.processedOwnerships) {
      this.setState({ processedOwnerships: true });
      const candidate = this.props.job; //wrongly mapped
      Candidate.getPotentialDuplicatedWithOwnerships(candidate, (result) => {
        candidate.jobOwnerships = result;
        Engagement.getWhere(
          { candidateId: candidate.id },
          (engs) => {
            candidate.engagements = engs;
          },
          {
            include: [
              {
                relation: "job",
                scope: {
                  fields: ["id", "employerId", "jobTitle", "roles"],
                  include: [
                    {
                      relation: "employer",
                      scope: {
                        fields: ["id", "name"],
                      },
                    },
                  ],
                },
              },
            ],
            fields: [
              "id",
              "jobId",
              "candidateId",
              "stage",
              "status",
              "state",
              "lastAction",
            ],
          }
        );
      });
    }

    this.cardStyles = {
      marginBottom: "0px",
      borderBottom: "2px solid #454545",
      cursor: "pointer",
    };
  }

  afterUpdateEngagement = (model = {}) => {
    this.setState(
      {
        engagement: {
          ...this.state.engagement,
          ...model,
        },
      },
      () => {
        const { engagement } = this.state;
        const { afterMainUpdateEngagement } = this.props;
        afterMainUpdateEngagement(engagement);
      }
    );
  };

  closeParentPopup = () => {
    this.setState({ wantsUpdateEngagement: false });
  };

  handlerUpdateEngagement = () => {
    this.setState({ wantsUpdateEngagement: true });
  };

  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.MLScoreMode !== this.props.MLScoreMode &&
      nextProps.MLScoreMode === true
    ) {
      this.setState({ forceMlOn: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.selected !== this.props.selected) {
      this.setState({ selected: this.props.selected });
    }

    if (prevState.job !== this.props.job) {
      this.setState({ job: this.props.job });
    }
  }

  onCheckStar = (job) => (ev, checked) => {
    //ev.stopPropagation();
    console.log(checked);
    Candidate.updateStarred(
      job.id,
      this.state.starredId,
      checked,
      (response) => {
        job.starred = response.starred;
        this.setState({ job });
      }
    );
  };

  handlerOnSelect = (job, engagement) => () => {
    const { resizeIndex } = this.props;
    this.setState({ selected: true });
    !!this.props.handlerSelectJob && this.props.handlerSelectJob(job);
    !!resizeIndex && resizeIndex();
  };

  handlerForceMlOn = () => {
    this.setState({ forceMlOn: true });
  };

  handlerScoreMAtch = () => {
    this.setState({ forceMlOn: false });
  };

  handlerSkippedJob = () => {
    let { handlerSkippedJob, job, handlerSelectJob } = this.props;
    handlerSkippedJob(job);
    handlerSelectJob(null);
  };

  renderPopOver = () => {
    const { handlerMatchJob, notFoundInPermittedJobWithOwnership, candidate } =
      this.props;
    const { matchStrength, job } = this.state;
    const matchStrengthLabel = Definition.getLabel(
      "engagementMatchStrength",
      matchStrength
    );

    return (
      <Popover
        open={this.state.openPopover}
        anchorEl={this.state.anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        targetOrigin={{ horizontal: "left", vertical: "top" }}
        onRequestClose={(ev) =>
          this.setState({
            openPopover: false,
          })
        }
      >
        {Streak.getPipeline().matchOptions && (
          <Menu>
            {Streak.getPipeline().matchOptions.map((option) => (
              <MenuItem
                key={option}
                primaryText={option}
                onClick={(ev) => {
                  const engKeys = {
                    stage: option.split(",")[0].trim(),
                    status: option.split(",")[1].trim(),
                    matchStrength,
                    matchStrengthLabel,
                  };

                  const next = () => {
                    this.setState({
                      openPopover: false,
                    });

                    handlerMatchJob(engKeys)();
                  };

                  const validationMsg = notFoundInPermittedJobWithOwnership(
                    job,
                    candidate,
                    engKeys
                  );
                  if (validationMsg) {
                    alert(validationMsg);
                  } else {
                    next();
                  }
                }}
              />
            ))}
          </Menu>
        )}
      </Popover>
    );
  };

  onCloseDisagreementPopup = () => {
    this.setState({ getDisagreementDetails: false });
  };

  localCreateDisagreement = (model, cb) => {
    const { createDisagreement, job, candidate, engagement } = this.props;
    const { matchStrength, reviewed } = this.state;
    let matchStrengthLabel = Definition.getLabel(
      "engagementMatchStrength",
      matchStrength
    );

    if (
      [
        engagementMatchStrength.STRONG_YES,
        engagementMatchStrength.YES,
        engagementMatchStrength.WEAK_YES,
      ].includes(matchStrength) &&
      /w - 10x10/i.test(Object(engagement).status) &&
      /detail/i.test(Object(engagement).status)
    ) {
      matchStrengthLabel = "TBD";
    }

    let structure = {
      ...model,
      matchStrength: matchStrengthLabel,
      job,
      candidate,
      engagement,
      employer: job.employer,
      reviewed,
      cb: () => {
        !!cb && cb();
      },
    };

    !!createDisagreement && createDisagreement(structure);
  };

  handlerThumbsUp = (ev, matchStrength) => {
    const { isJobEngageable, job } = this.props;

    let matchStrengthText;
    switch (matchStrength) {
      case 1:
        matchStrengthText = "STRONG YES";
        break;
      case 2:
        matchStrengthText = "YES";
        break;
      case 3:
        matchStrengthText = "WEAK YES";
        break;
      default:
        matchStrengthText = "";
        break;
    }
    if (window.confirm(`Confirm this match is a ${matchStrengthText}?`)) {
      if (!!isJobEngageable && isJobEngageable(job)) {
        this.setState({
          matchStrength,
          openPopover: true,
          anchorEl: ev.currentTarget,
        });
      } else {
        this.setState(
          {
            matchStrength,
          },
          () => {
            this.localCreateDisagreement();
          }
        );
      }
    }
  };

  handlerThumbsDown = (ev, matchStrength) => {
    let matchStrengthText;
    switch (matchStrength) {
      case 5:
        matchStrengthText = "NO";
        break;
      case 6:
        matchStrengthText = "STRONG NO";
        break;
      default:
        matchStrengthText = "";
        break;
    }
    if (window.confirm(`Confirm this match is a ${matchStrengthText}?`)) {
      this.setState({
        getDisagreementDetails: true,
        matchStrength,
      });
    }
  };

  saveCandoPrivateNoteAddition = () => {
    //privateNotes
    let { candidate } = this.props;
    let { candoPrivateNoteText, permittedJobsAnswers } = this.state;

    let finalNotes = candidate.privateNotes;

    if (!!candoPrivateNoteText) {
      finalNotes = candidate.privateNotes
        ? candidate.privateNotes + "\n" + candoPrivateNoteText
        : candoPrivateNoteText;
    }

    Candidate.update(candidate.id, {
      privateNotes: finalNotes,
      permittedJobsAnswers,
    });
  };

  specialEngagementCreation =
    (matchStrength) =>
      (
        {
          candidate,
          job,
          pickedReasons,
          disagreementReasonDetails,
          shouldTag,
          shouldNotTag,
          whyNoPrivateNote,
          whyNeedToReadCV,
        },
        { disagreementModel, cb }
      ) => {
        let {
          createSingleEngagementWithDis,
          engagement,
          strategyGetConstraints,
        } = this.props;
        let { reviewed } = this.state;
        const matchStrengthLabel = Definition.getLabel(
          "engagementMatchStrength",
          matchStrength
        );

        let stage, status, state, rejectionReason, rejectionReasonAdditionalInfo;

        if (
          !engagement &&
          (strategyGetConstraints(candidate, job, "jobsPermitted") ||
            strategyGetConstraints(candidate, job, "jobsPitched"))
        ) {
          stage = "Confirmation";
          status = "W - 10x10 Notify Rejection";
          state = "Open";
          rejectionReason = pickedReasons
            .filter((el) => !["positiveSignals", "negativeSignals"].includes(el))
            .join(",");
          rejectionReasonAdditionalInfo = disagreementReasonDetails;
        } else {
          stage = "Confirmation";
          status = "E - 10x10";
          state = "Closed";
          rejectionReason = "10x10 - No Match";
          rejectionReasonAdditionalInfo = disagreementReasonDetails;
        }
        createSingleEngagementWithDis(job, {
          stage,
          status,
          state,
          rejectionReason,
          rejectionReasonAdditionalInfo,
          matchStrength,
          matchStrengthLabel,
          reviewed,
          ...disagreementModel,
        });

        !!cb && cb();

        if (
          [
            engagementMatchStrength.NO,
            engagementMatchStrength.STRONG_NO,
          ].includes(matchStrength)
        )
          this.pushingToCandoOnThumbsDown({ candidate, job });
      };

  pushingToCandoOnThumbsDown = (data) => {
    const { pushEntityToCandidate } = this.props;
    const jobId = data.job.id;
    let putDownJobs = data.candidate.putDownJobs || [];

    putDownJobs.push(jobId);
    putDownJobs = Array.from(new Set(putDownJobs));
    !!pushEntityToCandidate && pushEntityToCandidate(putDownJobs);
  };

  updateMIScore = (text) => {
    let { job } = this.state;
    const { handlerMIScoreObjects, resizeIndex } = this.props;
    job["matchScore"] = text;
    this.setState({ job }, () => {
      !!handlerMIScoreObjects && handlerMIScoreObjects(job);
      !!resizeIndex && resizeIndex();
    });
  };

  handlerMlDecisionMaking = (status, MlDecisionMakers) => {
    const { resizeIndex } = this.props;
    this.setState(
      {
        MlDecisionPending: status,
        otherDecisionMakers: MlDecisionMakers,
      },
      () => {
        !!resizeIndex && resizeIndex();
      }
    );
  };

  render() {
    let {
      getDisagreementDetails,
      matchStrength,
      selected,
      wantsUpdateEngagement,
      engagement,
      reviewed,
      MlDecisionPending,
      otherDecisionMakers,
      job,
    } = this.state;

    let {
      candidate,
      handlerOnSelectBox,
      getJobLabels,
      multiSelectedJobs,
      annotatorMode,
      segregateRolesChip,

      /** @todo review and cleanup (old locations) | 2021-09-01 Wed µ */
      // segregateLocationChip,

      updateEngagement,
      measure,
      label,
      getOwnershipChip,
    } = this.props;

    let cardStyles = selected
      ? {
        ...this.cardStyles,
        border: "3px solid #5f57fe",
        boxShadow: "0px 0px 10px #5f57fe",
      }
      : this.cardStyles;

    if (!!candidate.engagements.find((eg) => eg.candidateId === job.id)) {
      cardStyles = {
        ...cardStyles,
        backgroundColor: "lightgrey",
      };
    } else if (job.isDuplicate) {
      // No idea why in this routine candidate => job and job => candidate
      cardStyles = {
        ...cardStyles,
        backgroundColor: "lightpink",
      };
    }

    const mappingToStats = {
      score: "S",
      score_must_haves: "MH",
      without_not_operator_truthies_divide_by_total_must: "andor",
      with_not_operator_weightage_to_subtract_must: "not",
      score_nice_haves: "NH",
      without_not_operator_truthies_divide_by_total_nice: "andor",
      with_not_operator_weightage_to_subtract_nice: "not",
      score_platform_rating: "PR",
    };

    const mlStatsJoined = Object.keys(Object(job.mlScoreObject))
      .map((k) => {
        let value = parseFloat(job.mlScoreObject[k]);

        value = value.toFixed(2);

        if (Object.keys(mappingToStats).includes(k)) {
          if (k === "score") {
            return <strong>{`${mappingToStats[k]}: ${value}`}, </strong>;
          }
          return <span>{`${mappingToStats[k]}: ${value}`}, </span>;
        } else {
          return "";
        }
      })
      .filter((st) => !!st);

    return (
      <Fragment>
        <div key={this.props.index} style={this.props.style} onLoad={measure}>
          {!!label && !annotatorMode ? (
            <Heading
              idRef={`list-card-section-${label}`}
              classNameRef="list-card-section"
              heading={`${label}`}
            />
          ) : null}
          <Card
            id={`job-${job.id}`}
            style={cardStyles}
            onClick={this.handlerOnSelect(job, engagement)}
          >
            <CardHeader
              className="match-new candidate-list-card-header"
              action={
                <CardActions>
                  {!!job && (
                    <MultiSelectJob
                      job={job}
                      handlerOnSelectBox={handlerOnSelectBox}
                      selectedCheck={
                        !!multiSelectedJobs &&
                        multiSelectedJobs.find((sjob) => sjob.id === job.id)
                      }
                    />
                  )}
                </CardActions>
              }
              title={
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  {Object(job)._name}{" "}
                </Typography>
              }
              subheader={
                <Typography variant="body1">
                  {" "}
                  - {Object(job)._roles}{" "}
                </Typography>
              }
              // title={`${Object(job)._name} `}
              // subheader={`- ${Object(job)._roles}`}
              // component='p'
              disableTypography={true}
            />

            <CardContent
              className="match-new job-list-card-content"
              style={{ position: "relative" }}
            >
              {/* <Typography variant="body2" color="textSecondary" component="p">
                        {job._roles} | {job.jobTitle}
                    </Typography> */}
              <p className="match-new job-card-text">
                {Object(job).recruiter._name}, {Object(job)._introduced}
              </p>
              <div className="ui-single-candidate-card">
                {!!job &&
                  !!job._visa &&
                  this.props.staticRowDisplayChip(
                    job._visa,
                    candidate._visaTransfer,
                    this.props
                      .staticRowDisplayColor(job, candidate)
                      .getVisaColor()
                  )}
                {!!segregateRolesChip && segregateRolesChip(job, candidate)}
                {!!job && typeof job._yearsOfExperienceForCalc !== "undefined"
                  ? this.props.staticRowDisplayChip(
                    job._yearsOfExperienceForCalc + " yrs",
                    candidate.minYearsOfExperience,
                    this.props
                      .staticRowDisplayColor(job, candidate)
                      .getYearsXp()
                  )
                  : null}


                {/* 2021-09-01 Wed µ */}
                <MatchLocationChips
                  {...{
                    requester: MATCH_LOCATION_CHIPS__CD_REQUESTER,

                    /** 
                     * @todo 
                     * 
                     * following code is patched, 
                     * seems the job and candidate are inverted, 
                     * would need review and refactor SingleCandidateCard code
                     * 
                     * 2021-09-01 Wed µ  
                     */
                    job: candidate.___model___ === 'Candidate' ? job : candidate,
                    candidate: candidate.___model___ === 'Candidate' ? candidate : job

                  }}
                />

                {/** @todo review and cleanup (old locations) | 2021-09-01 Wed µ */}
                {/** * /}
                {!!segregateLocationChip &&
                  segregateLocationChip(job, candidate)}
                {/** */}

                {!!job &&
                  typeof job.minimumSalary !== "undefined" &&
                  this.props.staticRowDisplayChip(
                    "<=$" + job.minimumSalary,
                    candidate.salaryMax,
                    this.props
                      .staticRowDisplayColor(job, candidate)
                      .getSalaryColor()
                  )}
                {!!job &&
                  !!job.platformRating &&
                  this.props.staticRowDisplayChip(
                    job._platformRating,
                    null,
                    null
                  )}
              </div>
              {!!job && !!job._technicalSkills && (
                <p className="match-new job-card-text">
                  Tech Skills: {job._technicalSkills}
                </p>
              )}

              {/* Candidate Match Score update */}
              {!!job && !!candidate && (
                <Fragment>
                  <div style={annotatorMode ? { display: "none" } : {}}>
                    <p style={{ fontSize: "12px" }}>
                      <mark>{mlStatsJoined}</mark>
                    </p>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <JobCardThumbsActions
                      handlerThumbsUp={this.handlerThumbsUp}
                      handlerThumbsDown={this.handlerThumbsDown}
                      candidateId={!!job && job.id}
                      jobId={!!candidate && candidate.id}
                      handlerMlDecisionMaking={this.handlerMlDecisionMaking}
                    />
                    &nbsp;&nbsp;
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          edge="start"
                          checked={reviewed}
                          onChange={(event) => {
                            this.setState({ reviewed: event.target.checked });
                          }}
                          value={reviewed}
                          color="primary"
                        />
                      }
                      label="Reviewed"
                      labelPlacement="end"
                    />
                  </div>
                </Fragment>
              )}
              {!!engagement && (
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  component="span"
                  style={{ display: "inline" }}
                >
                  {!annotatorMode && (
                    <span>
                      {Definition.getLabel(
                        "engagementMatchStrength",
                        engagement.matchStrength
                      )}
                      &nbsp;&nbsp;&nbsp;
                    </span>
                  )}
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    component="span"
                    style={{ display: "inline" }}
                  >
                    {moment(engagement.lastMatchedDate).format("MMMM - DD/YY")}{" "}
                    by {Object(engagement.matchedByWho).email}
                  </Typography>
                </Typography>
              )}
              {!!engagement && !annotatorMode && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="strong"
                >
                  {engagement.state} | {engagement.status} | {engagement.stage}{" "}
                  | {engagement.rejectionReason}{" "}
                  {engagement.rejectionReasonAdditionalInfo}
                </Typography>
              )}
              {!annotatorMode ? (
                !MlDecisionPending ? (
                  <Fragment>
                    {!!otherDecisionMakers.length ? (
                      otherDecisionMakers.map((dec) => {
                        return (
                          <p
                            onLoad={measure}
                            className="match-new job-card-text"
                          >
                            {dec.matchDecision} - {dec.annotator} -{" "}
                            {moment(dec.updatedAt).format("YYYY/MM/DD")}
                          </p>
                        );
                      })
                    ) : (
                      <p className="match-new job-card-text">
                        No annotations found
                      </p>
                    )}
                  </Fragment>
                ) : (
                  <p className="match-new job-card-text">Decision is pending</p>
                )
              ) : null}
              {!!candidate && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="strong"
                >
                  {getJobLabels(candidate, job).map((candidate, index) => (
                    <Fragment key={index}>
                      <span
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          padding: "2px",
                          marginRight: "2px",
                        }}
                      >
                        {candidate}
                      </span>
                    </Fragment>
                  ))}
                  {job.blacklisted}
                  {!!Array.isArray(Object(job).jobOwnerships) &&
                    getOwnershipChip(job, candidate)}
                </Typography>
              )}

              {this.renderPopOver()}
              <div style={{ display: "none" }}>
                <CreateDisagreement
                  engagement={engagement}
                  open={getDisagreementDetails}
                  candidate={candidate}
                  jobs={[job]}
                  employer={job.employer}
                  decision={"false-positive"}
                  createWith={this.specialEngagementCreation(matchStrength)}
                  matchDecision={Definition.getLabel(
                    "engagementMatchStrength",
                    matchStrength
                  )}
                  onClose={this.onCloseDisagreementPopup}
                />
              </div>

              {!!engagement && (
                <ManageEngagement
                  engagement={engagement}
                  open={wantsUpdateEngagement}
                  closeParentPopup={this.closeParentPopup}
                  afterUpdateEngagement={this.afterUpdateEngagement}
                />
              )}
              {Core.isAdminOrTrusted() && (
                <IconMenu
                  style={{
                    position: "absolute",
                    bottom: 15,
                    right: 15,
                  }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  targetOrigin={{ horizontal: "right", vertical: "bottom" }}
                  iconButtonElement={
                    <IconButton
                      style={{
                        width: 24,
                        padding: 0,
                      }}
                      iconstyle={this.state.openedColor}
                    >
                      <i className="material-icons">more_vert</i>
                    </IconButton>
                  }
                >
                  {(Core.isAdmin() ||
                    (Core.isRecruiter() && Core.isOnDev())) && (
                      <MenuItem primaryText="View Dups" onClick={() => Core.openExact("/candidates?viewDup=t&cId=" + job.id)} />
                    )}
                  {/** * /}
                  {(Core.isAdmin() ||
                    (Core.isRecruiter() && Core.isOnDev())) && (
                      <NavLink
                        to={"/job/match/" + this.state.id}
                        className="navlink"
                      >
                        <MenuItem primaryText="Match" />
                      </NavLink>
                    )}

                  Core.isAdmin() && [
                  <NavLink
                    key={Core.getKey()}
                    to={"/job/view/" + this.state.id}
                    className="navlink"
                  >
                    <MenuItem primaryText="View" />
                  </NavLink>,
                  <NavLink
                    key={Core.getKey()}
                    to={"/job/edit/" + this.state.id}
                    className="navlink"
                  >
                    <MenuItem primaryText="Edit" />
                  </NavLink>
                  ]
                  {/** */}

                  {/** @todo deprecated | story-4138 | 2021-12-01 µ */}
                  {/** * /}
                  {Core.isAdminOrCoordinator() && (
                    <MenuItem primaryText="Clone" onClick={this.cloneJob} />
                  )}
                  {/** */}

                  <MenuItem
                    primaryText="Calculate Match Score"
                    onClick={() => {
                      job.mlScore = "calculating";
                      job.mlScoreObject = {};
                      this.setState({ candidate });
                      if (this.props.processMLScoreSingle) {
                        let params = {
                          jobId: candidate.id,
                          candoIds: [job.id],
                        };

                        this.props.processMLScoreSingle(
                          params,
                          "get_candos_scores",
                          (result) => {
                            const thisJobResult = result.find(
                              (res) => res.item_id === job.id
                            );
                            if (thisJobResult) {
                              job.mlScore = (
                                Object(thisJobResult).match || {}
                              ).score;
                              job.mlScoreObject = Object(thisJobResult).match;

                              this.setState({ job });
                            }
                          }
                        );
                      }
                    }}
                  />
                  <MenuItem className="star-menu-item">
                    <Checkbox
                      title="Click to Star"
                      className="star"
                      checked={job.starred}
                      onCheck={this.onCheckStar(job)}
                      checkedIcon={<i className="material-icons">star</i>}
                      uncheckedIcon={
                        <i className="material-icons">star_border</i>
                      }
                    />
                  </MenuItem>
                  {!!engagement && (
                    <MenuItem
                      primaryText="Update Engagement"
                      onClick={this.handlerUpdateEngagement}
                    />
                  )}

                  {/** this component seems to be common for job and candidate pages, for that reason the models can be inverted | µ 2021-12-01 */}
                  {Core.isAdmin() && (
                    <NavLink to={Core.getLink(`/candidate/matchNew/${candidate.___model___ === CANDIDATE_MODEL_NAME ? candidate.id : job.id}`)}>
                      <MenuItem>
                        Go to Candidate Match
                      </MenuItem>
                    </NavLink>
                  )}

                  {Core.isAdminOrCoordinator() &&
                    (this.state.checked || this.state.engagement) && (
                      <>

                        {!!this.state.engagement && !!job && !!candidate && (
                          <MenuItem
                            key={Core.getKey()}
                            primaryText={"Submission"}
                            onClick={(ev) =>
                              Core.go({ ...this.props, to: `/candidate/resume-submission/${candidate.id}/${job.id}` })
                            }
                          />
                        )}

                        {this.state.engagement && (
                          <>
                            <MenuItem
                              key={Core.getKey()}
                              primaryText={"Mark ML Positive"}
                              onClick={(ev) => {
                                updateEngagement(engagement, {
                                  useForMlTraining: "true",
                                });
                              }}
                            />
                            <MenuItem
                              key={Core.getKey()}
                              primaryText={"Mark ML Negative"}
                              onClick={(ev) => {
                                updateEngagement(engagement, {
                                  useForMlTraining: "false",
                                });
                              }}
                            />
                          </>
                        )}
                      </>
                    )}

                  {/** @todo deprecated | story-4138 | 2021-12-01 µ */}
                  {/** * /}
                  {Core.isAdminOrTrusted() && (
                    <MenuItem
                      primaryText="Copy"
                      onClick={(ev) =>
                        copyHTML(Job.getPreview(job))
                          .then((em) => {
                            Core.log("Copy email command was successful");
                            Core.showMessage("Copied!");
                          })
                          .catch((ex) => {
                            Core.log("Oops, unable to copy");
                            Core.showMessage("Fail copy!");
                          })
                      }
                    />
                  )}
                  {/** */}

                </IconMenu>
              )}
            </CardContent>
          </Card>
        </div>
      </Fragment>
    );
  }
}

export default SingleCandidateCard;
