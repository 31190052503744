import React, { Component, Fragment } from "react";

import Checkbox from "material-ui/Checkbox";
import Dialog from "material-ui/Dialog";
import { Button, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import TextField from "material-ui/TextField";

import { colors } from "../../../lib/Core";

class SelectListDialog extends Component {
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      title: "Select an item",
      open: false,
      search: "",
      list: [],
      onSubmit: ev => true
    };
  }
  open = (title, list, selected, onSubmit) => {
    const selectedStr = JSON.stringify(selected);
    this.setState({
      title,
      list: list.map(item => {
        delete item._selected;
        if (!!~selectedStr.indexOf(item.id)) {
          item._selected = true;
        }
        return item;
      }),
      open: true,
      onSubmit
    });
  };
  close = () => {
    this.setState({ open: false });
  };
  submit = () => {
    this.close();
    this.state.onSubmit(
      this.state.list.filter(item => item._selected).map(item => {
        delete item._selected;
        return item;
      })
    );
  };
  toogleHandler = itemId => {
    this.setState({
      list: this.state.list.map(item => {
        if (item.id === itemId) {
          item._selected = !item._selected;
        }
        return item;
      })
    });
  };
  render() {
    return (
      <Dialog
        title={this.state.title}
        onRequestClose={this.close}
        actions={
          <Fragment>
            <TextField
              className="input"
              floatingLabelText="Search keywords"
              required={true}
              style={{
                width: "calc(100% - 16px)",
                fontSize: 14,
                margin: "0 8px"
              }}
              floatingLabelStyle={{ color: colors.gray }}
              floatingLabelFocusStyle={{ color: colors.purple, fontSize: 12 }}
              underlineFocusStyle={{ borderColor: colors.purple }}
              type="text"
              value={this.state.search}
              onChange={(event, search) => this.setState({ search })}
              autoFocus
            />
            <Button color="secondary" onClick={this.close}>Cancel</Button>
            <Button color="primary" onClick={this.submit}>Apply</Button>
          </Fragment>
        }
        modal={false}
        open={this.state.open}
        autoScrollBodyContent={true}
        contentStyle={{ width: "40vw" }}
        bodyStyle={{ padding: "0px 20px 20px" }}
      >
        {this.state.list
          .filter(
            item =>
              !!this.state.search
                ? !!~item._name
                  .toLowerCase()
                  .indexOf(this.state.search.toLowerCase())
                : true
          )
          .map(item => (
            <ListItem key={item.id} role={undefined} dense button
              onClick={ev => this.toogleHandler(item.id)}
            >
              <ListItemIcon style={{ minWidth: 'inherit' }}>
                <Checkbox
                  edge="start"
                  checked={item._selected}
                  tabIndex={-1}
                  color="primary"
                  disableRipple
                  inputProps={{ 'aria-labelledby': item._name }}
                />
              </ListItemIcon>
              <ListItemText id={item._name} primary={item._name || <i>&mdash;</i>} />

            </ListItem>
          ))}
      </Dialog>
    );
  }
}
export default SelectListDialog;
