const model = {
    annotator:'',
    matchDecision:'',
    whyNoCategories: [],
    whyNoDetails: '',
    whyNoFieldsValues: [],
    candidateName: '',
    employerName: '',
    jobName: '',
    engagementStage: '',
    engagementStatus: '',
    matchingUrl: '',
    engagementUrl: '',
    candidateUrl: '',
    jobUrl: '',
    engagementId: '',
    jobId: '',
    candidateId: '',
    candidateJobId:'',
    shouldTag: '',
    shouldNotTag: '',
    whyNoPrivateNote: '',
    whyNeedToReadCV: '',
    jobRoles: '',
    reviewed: ''
};

const mapDisagreement = item => {
    const engagement = {modelName: "engagement", ...model};
    return {...engagement, ...Object(item)}
};

const mapDisagreements = data => {
    return (data || []).map(item => {
        const disagreement = mapDisagreement(item);
        return disagreement;
    });
};

const EngagementDisagreement = {
    model,
    mapDisagreement,
    mapDisagreements,
};

export {
    EngagementDisagreement as default,
    model,
    mapDisagreement,
    mapDisagreements,
};
