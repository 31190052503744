/** ============================================ µ
 * @Description githubGraphqlGetUser [JS]
 *              Library
 * @routes      /candidate/matchNew/:candidateId
 *              /candidate/resume-submission/:candidateId/:jobId
 * @usedBy      CandidatePipe | component
 *              CandidateResumeSubmission | component
 * @updatedAt   2021-05-17
 * ============================================ */

/* IMPORTS ==================================== */

import {
  ApolloClient,
  createHttpLink,
  gql,
  InMemoryCache
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import React from "react";
import { GITHUB_API_URL, GITHUB_TOKEN } from "../../Constants";
import Core from "../../Core";
import { getShortURL } from "../../GenericTools.lib";
import Http from "../../Http";
import { numberSuffixFormat } from "../../tools/numberSuffixFormat.tool";
import calculateRank from "./calculateRank.lib";

/* CONSTANTS ================================== */

const httpLink = createHttpLink({
  uri: GITHUB_API_URL,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${GITHUB_TOKEN}`,
    },
  };
});

/* SETTINGS =================================== */

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

/* METHODS ==================================== */

const getGithubUsernameFromUrl = (githubUrl) => {
  let username = "";
  if (!githubUrl) {
    return false;
  }

  if (/github.io/i.test(githubUrl)) {
    let choppedUrl = githubUrl.match(/\w+\.github/g);
    if (choppedUrl) {
      username = choppedUrl.pop().split(".")[0];
    }
  } else if (/github.com/g.test(githubUrl)) {
    let choppedUrl = githubUrl.match(/.com\/\w+/g);
    if (choppedUrl) {
      username = choppedUrl.pop().split("/")[1];
    }
  } else {
    return false;
  }

  return username;
};

/**
 * GitHub Stats Row
 *
 * @param {object} githubStats
 * @param {string} githubUrl
 * @returns {React.Component} containedGithubStats
 */
function getSingleRowHtml(githubStats, githubUrl) {

  const {
    totalCommits,
    repos: totalRepositories = 0,
    followers = 0,
    contributedTo = 0, // repositoriesContributedTo
    stars: totalStars = 0,
    prs: totalPullRequests = 0,
    issues: totalIssues = 0,
    // username = '', // not in use
    // privateCommits = 0, // not in use
    rank: userRank = {}
  } = githubStats;

  const {
    level: rankLevel = '',
    score: rankScore = 0
  } = userRank

  /** µ DISCARDED (future cleanup) * /
  const tagLabel = Definition.getLabel(
    "positiveSignals",
    getLabelId(githubStats)
  );
  /** */

  /** µ FOR DEBUG PURPOSES * /
  console.debug('µ:getSingleRowHtml', { githubStats });
  /** */

  return (
    <div>
      <p>
        <strong>Github Stats</strong>
      </p>
      <p className="pl-1">
        {/** µ DISCARDED (future cleanup) * /}
        {tagLabel}
        {/** */}
        <small className="c-gray">
          <b>Commits: </b>{numberSuffixFormat(totalCommits)}
          ,&nbsp;<b>Repos: </b>{totalRepositories}
          ,&nbsp;<b>Recent: </b>{contributedTo}
          ,&nbsp;<b>Stars: </b>{numberSuffixFormat(totalStars)}
          ,&nbsp;<b>Followers: </b>{followers}
          ,&nbsp;<b>PRs: </b>{totalPullRequests}
          ,&nbsp;<b>Issues: </b>{totalIssues}
          ,&nbsp;<b>Level: </b>{rankLevel}
          ,&nbsp;<b>Score: </b>{Number(rankScore).toFixed(2)}
        </small>
      </p>
    </div>
  );
};

/**
 * Submission Notes
 *
 * GitHub Stats Line
 *
 * @param {object} githubStats
 * @param {string} githubUrl
 * @param {object} minValues
 *
 * @returns {React.Component}
 */
function getSubmissionNotesHtml(
  githubStats,
  githubUrl,
  minValues = {
    default: 5
  }
) {
  return (
    <div>
      <p>
        <a href={githubUrl} rel="noreferrer" target="_blank">
          {getShortURL(githubUrl)}
        </a>:&nbsp;
        {numberSuffixFormat(githubStats.totalCommits)} commits,&nbsp;
        {githubStats.repos} repos
        {(githubStats.contributedTo >= minValues.default) && (
          <>,&nbsp;{githubStats.contributedTo} recent</>
        )}
        ,&nbsp;{numberSuffixFormat(githubStats.stars)} stars,&nbsp;
        {githubStats.followers} followers
        {(githubStats.prs >= minValues.default) && (
          <>,&nbsp;{githubStats.prs} PRs</>
        )}
        {(githubStats.issues >= minValues.default) && (
          <>,&nbsp;{githubStats.issues} issues</>
        )}
      </p>
    </div>
  );
};

/**
 * Get GitHub User's Commits | HTTP request
 *
 * Then returns updatedStats on success callback
 *
 * @param {object} stats
 * @param {string} token
 * @param {function} onSuccess `( updatedStats ) => {...}`
 */
function fetchTotalCommits(stats, token, onSuccess) {
  Http.get(
    `https://api.github.com/search/commits?q=author:${stats.username}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/vnd.github.cloak-preview",
        Authorization: `bearer ${token}`,
      },
    },
    (result) => {
      const updatedStats = {
        ...stats,
        totalCommits: +result.total_count + +stats.privateCommits,
      };
      // console.debug('updatedStats', stats, updatedStats);
      onSuccess(updatedStats);
    },
    () => {
      onSuccess(stats);
    }
  );
};

/**
 * Get GitHub User | GQL query
 *
 * @param {string} githubUrl
 * @param {function} cb callback
 *
 * @returns {void}
 */
function githubGraphqlGetUser(githubUrl, cb) {
  const username = getGithubUsernameFromUrl(githubUrl);
  if (!username) {
    return cb(false);
  }
  client
    .query({
      query: gql`
        query userInfo($login: String!) {
          user(login: $login) {
            name
            login
            contributionsCollection {
              totalCommitContributions
              restrictedContributionsCount
            }
            repositoriesContributedTo(
              first: 1
              contributionTypes: [COMMIT, ISSUE, PULL_REQUEST, REPOSITORY]
            ) {
              totalCount
            }
            pullRequests(first: 1) {
              totalCount
            }
            issues(first: 1) {
              totalCount
            }
            followers {
              totalCount
            }
            starredRepositories {
              totalCount
            }
            repositories (
              ownerAffiliations: OWNER
            ) {
              totalCount
            }
          }
        }
      `,
      variables: { login: username },
    })
    .then((result) => {

      /**
       * @see dev-docs github-get-user.gql-response.json
       */
      /** µ FOR DEBUG PURPOSES * /
      console.debug('µ:githubGraphqlGetUser', result);
      /** */

      const user = result.data.user;
      const stars = user.starredRepositories.totalCount;
      const totalCommits =
        user.contributionsCollection.totalCommitContributions || 0;
      const repos = user.repositories.totalCount || 0;
      const followers = user.followers.totalCount || 0;
      const privateCommits =
        user.contributionsCollection.restrictedContributionsCount || 0;
      const contributedTo = user.repositoriesContributedTo.totalCount || 0;
      const prs = user.pullRequests.totalCount || 0;
      const issues = user.issues.totalCount || 0;
      const rank = calculateRank({
        totalRepos: repos,
        totalCommits,
        contributions: contributedTo,
        followers,
        prs,
        issues,
        stargazers: stars,
      });

      const stats = {
        stars,
        totalCommits,
        repos,
        followers,
        username,
        privateCommits,
        contributedTo,
        prs,
        issues,
        rank,
      };

      /** µ FOR DEBUG PURPOSES * /
      console.debug('µ:githubGraphqlGetUser', { stats });
      /** */

      fetchTotalCommits(stats, GITHUB_TOKEN, (statsWithTotalCommits) => {
        cb({
          ...statsWithTotalCommits,
          getSingleRowHtml: getSingleRowHtml(statsWithTotalCommits, githubUrl),
          getSubmissionNotesHtml: getSubmissionNotesHtml(
            statsWithTotalCommits,
            githubUrl
          ),
        });
      });

    })
    .catch(ex => {
      let message = `git-hub > gql - failure: ${ex}`;
      console.debug(message);
      cb(false);
      let errorMessage = ex.graphQLErrors[0]?.message;
      if (errorMessage) {
        Core.showMessage(`GitHub: ${errorMessage}`);
      }
      else {
        Core.failure({
          source: 'githubGraphqlGetUser.js(service):catch',
          exception: ex,
          params: { githubUrl, username },
          options: { level: 'critical' }
        });
      }
    });
};

const getLabelId = (result) => {
  let label = "";

  if (!!result) {
    if (
      result.followers >= 75 &&
      result.stars >= 100 &&
      result.totalCommits >= 75
    ) {
      label = 29; //Impressive Github
    } else if (
      result.followers >= 25 &&
      result.followers <= 75 &&
      result.stars >= 35 &&
      result.totalCommits >= 50
    ) {
      label = 27; //Great Github
    } else if (
      result.followers >= 11 &&
      result.followers <= 25 &&
      result.stars >= 15 &&
      result.totalCommits >= 20
    ) {
      label = 28; //Strong Github
    } else {
      label = 7; //Github
    }
  }
  return label;
};

/* EXPORTS ==================================== */

export const getGithubPositiveSignalLabel = (githubUrl, cb) => {
  githubGraphqlGetUser(githubUrl, (result) => {
    cb(getLabelId(result));
  });
};

export default githubGraphqlGetUser;

/* ============================================ */
