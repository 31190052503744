/**
 * @todo
 * 
 * OUTDATED
 * 
 * Review to cleanup
 * 
 * 2021-09-10 Fri µ
 * 
 */

import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import {
  AppBar,
  Divider,
  FlatButton,
  Paper,
  Snackbar,
  TextField
} from "material-ui";

import Core from "../../../lib/Core";
import Row from "../../Forms/Row";
import Col from "../../Forms/Col";
import Job from "../../../lib/Job";
import jobModel from "../../../lib/models/job";
import styles from "../../Forms/Styles";
import Store from "../../../lib/Store";

const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailInvalid = "Email is invalid";
const emailError = "Please enter a valid email";
const lineStyle = { borderColor: "#59736f" };

class JobElement extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      ...jobModel,
      ...this.props.model
    };
    Store.set("path", window.location.href);
  }
  render() {
    return (
      <Row>
        <Col className="col2">
          <h4 className="cdarker">{this.state.jobTitle}</h4>
        </Col>
        <Col className="col10">
          <Row>
            <Col>
              <TextField
                name="resumeSubmissionName1"
                placeholder="Name"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionName1}
                onChange={(ev, resumeSubmissionName1) =>
                  this.setState({ resumeSubmissionName1 })
                }
                fullWidth
              />
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionEmail1"
                placeholder="Email"
                type="email"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionEmail1}
                onChange={(ev, resumeSubmissionEmail1) =>
                  this.setState({ resumeSubmissionEmail1, errorEmail1: "" })
                }
                onBlur={ev => {
                  if (
                    !!this.state.resumeSubmissionEmail1 &&
                    !emailRE.test(this.state.resumeSubmissionEmail1)
                  ) {
                    this.setState({
                      snackbar: emailInvalid,
                      errorEmail1: emailError
                    });
                    return false;
                  }
                }}
                errorText={this.state.errorEmail1}
                errorStyle={{ color: "orange" }}
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                name="resumeSubmissionName2"
                placeholder="Name"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionName2}
                onChange={(ev, resumeSubmissionName2) =>
                  this.setState({ resumeSubmissionName2 })
                }
                fullWidth
              />
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionEmail2"
                placeholder="Email"
                type="email"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionEmail2}
                onChange={(ev, resumeSubmissionEmail2) =>
                  this.setState({ resumeSubmissionEmail2, errorEmail2: "" })
                }
                onBlur={ev => {
                  if (
                    !!this.state.resumeSubmissionEmail2 &&
                    !emailRE.test(this.state.resumeSubmissionEmail2)
                  ) {
                    this.setState({
                      snackbar: emailInvalid,
                      errorEmail2: emailError
                    });
                    return false;
                  }
                }}
                errorText={this.state.errorEmail2}
                errorStyle={{ color: "orange" }}
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                name="resumeSubmissionName3"
                placeholder="Name"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionName3}
                onChange={(ev, resumeSubmissionName3) =>
                  this.setState({ resumeSubmissionName3 })
                }
                fullWidth
              />
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionEmail3"
                placeholder="Email"
                type="email"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionEmail3}
                onChange={(ev, resumeSubmissionEmail3) =>
                  this.setState({ resumeSubmissionEmail3, errorEmail3: "" })
                }
                onBlur={ev => {
                  if (
                    !!this.state.resumeSubmissionEmail3 &&
                    !emailRE.test(this.state.resumeSubmissionEmail3)
                  ) {
                    this.setState({
                      snackbar: emailInvalid,
                      errorEmail3: emailError
                    });
                    return false;
                  }
                }}
                errorText={this.state.errorEmail3}
                errorStyle={{ color: "orange" }}
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                name="resumeSubmissionName4"
                placeholder="Name"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionName4}
                onChange={(ev, resumeSubmissionName4) =>
                  this.setState({ resumeSubmissionName4 })
                }
                fullWidth
              />
            </Col>
            <Col>
              <TextField
                name="resumeSubmissionEmail4"
                placeholder="Email"
                type="email"
                underlineFocusStyle={lineStyle}
                value={this.state.resumeSubmissionEmail4}
                onChange={(ev, resumeSubmissionEmail4) =>
                  this.setState({ resumeSubmissionEmail4, errorEmail4: "" })
                }
                onBlur={ev => {
                  if (
                    !!this.state.resumeSubmissionEmail4 &&
                    !emailRE.test(this.state.resumeSubmissionEmail4)
                  ) {
                    this.setState({
                      snackbar: emailInvalid,
                      errorEmail3: emailError
                    });
                    return false;
                  }
                }}
                errorText={this.state.errorEmail4}
                errorStyle={{ color: "orange" }}
                fullWidth
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

class CompanyGroup extends Component {
  render() {
    const { jobs } = this.props;
    return (
      <Fragment>
        <Row>
          <Col fullWidth>
            <label>{jobs[0].employer.name}</label>
          </Col>
        </Row>
        <div className="normal-padding">
          {jobs.map(job => <JobElement key={job.id} model={job} />)}
        </div>
        <Divider />
      </Fragment>
    );
  }
}

class ManageJobs extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      companiesCollection: [],
      snackbar: null
    };
    Job.getActives(jobs => {
      const companies = {};
      jobs.forEach(job => {
        companies[job.employer.id] = companies[job.employer.id] || [];
        companies[job.employer.id].push(job);
      });
      const collection = Object.keys(companies)
        .sort((a, b) =>
          companies[a][0].employer.name.localeCompare(
            companies[b][0].employer.name
          )
        )
        .map(id => <CompanyGroup key={id} jobs={companies[id]} />);
      Core.log({ jobs, collection });
      this.setState({ companiesCollection: collection });
    });
  }
  goBack = ev => {
    Core.goBack(this.props); // "/#/jobs";
  };
  render() {
    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }
    return (
      <MuiThemeProvider>
        <div className="edit job">
          <div className="appBar" style={styles.AppBar}>
            <AppBar
              title="Manage Jobs Resume Submission Emails"
              titleStyle={styles.AppBar.title}
              style={styles.AppBar.nav}
              iconElementLeft={
                <FlatButton
                  icon={<i className="material-icons">arrow_back</i>}
                  style={styles.AppBar.backButton}
                  onClick={this.goBack}
                />
              }
            />
          </div>
          <Paper className="form" style={styles.Paper} zDepth={1}>
            {this.state.companiesCollection}
          </Paper>
          <Snackbar
            open={!!this.state.snackbar}
            message={this.state.snackbar || ""}
            className="snack-bar"
            autoHideDuration={6000}
            onRequestClose={ev => this.setState({ snackbar: null })}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default ManageJobs;
