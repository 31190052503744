import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import {
  Table,
  TableBody, TableHeader, TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui";
import moment from "moment-timezone";
import React, { Component, Fragment } from "react";
import Candidate from '../../lib/Candidate';
import CandoPermittedJob from '../../lib/CandoPermittedJob';
import Core from '../../lib/Core';
import Job from "../../lib/Job";
import PermittedJobTable from "./PermittedJobTable";

const PERMITTED_JOB_HAS_OWNERSHIP = {
  HAS_OWNERSHIP_OWNED: 'owned',
  HAS_OWNERSHIP_NOT_OWNED: 'notOwned',
  HAS_OWNERSHIP_TBD: 'tbd',
};

const PERMITTED_JOB_ACTION = {
  ACTION_ADDED: 'added',
  ACTION_REMOVED: 'removed'
};

const getLastPermittedJob = (job) => {
  job.lastPermittedJob = job.candoPermittedJobs[0] || { hasOwnership: false, action: "" };
};

const jobHasOwnership = (hasOwnership) => {
  return hasOwnership === PERMITTED_JOB_HAS_OWNERSHIP.HAS_OWNERSHIP_OWNED;
};

class ManageOwnershipPermittedJobs extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      jobsPermitted: [],
      jobsPermittedIds: [],
      openForm: false,
      showPermoDetails: false,
      hasOwnership: PERMITTED_JOB_HAS_OWNERSHIP.HAS_OWNERSHIP_NOT_OWNED,
      timestamp: moment.tz((new Date()), "America/Los_Angeles").format('MM/DD/YYYY HH:mm')
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.candidate.jobsPermitted !== this.props.candidate.jobsPermitted) {
      this.loadData();
    }
  }

  loadData() {
    const { candidate } = this.props;
    const jobsPermittedIds = candidate.jobsPermitted;
    // this has to be limit 1 and order inverse by latest as we use the latest entry to decide the ownership
    Job.getWhere(
      {
        id: { inq: jobsPermittedIds },
      }, (jobsPermitted) => {
        jobsPermitted.forEach(j => {
          getLastPermittedJob(j);
        });
        console.log({ jobsPermitted });
        this.setState({ jobsPermittedIds, jobsPermitted });
      }, {
      include: [{
        relation: "candoPermittedJobs",
        scope: {
          where: {
            candidateId: candidate.id
          },
          include: [{
            relation: "operatedBye",
            scope: {
              fields: ["email"]
            }
          }],
          order: "createdAt DESC",
        }
      }, "employer"]

    })
  }

  componentDidMount() {
    this.loadData();
  }

  handleClose = () => {
    this.setState({ openForm: false })
  };

  handlerInsertOwnership = () => {
    const { timestamp, jobForOwnership, hasOwnership } = this.state;
    const { candidate } = this.props;

    const data = {
      timestamp: (new Date(timestamp)).toISOString(),
      action: PERMITTED_JOB_ACTION.ACTION_ADDED,
      operatedBy: Core.getUserId(),
      jobId: jobForOwnership.id,
      candidateId: candidate.id,
      employerId: jobForOwnership.employerId,
      hasOwnership,
      forceOwnership: true
    };

    CandoPermittedJob.post(data, (result) => {
      alert('succesfully created');
      this.loadData();
    });

  };

  handlerOwnershipLink = (job) => {
    const { candidate } = this.props;
    // //fetch ownerships
    Candidate.getPotentialDuplicatedWithOwnerships(candidate, (result) => {
      const withOwnershipsTrue = result.filter(res => !!res.isOwnedForEmployer);
      job._alreadyOwnershipsForMyEmployerWithDupCando = withOwnershipsTrue
        .filter(obj => obj.conflictedEmployers
          .map(obj => String(obj.employerId))
          .includes(String(job.employerId)));
      console.log({ job });
      this.setState({
        openForm: true,
        jobForOwnership: job,
        alreadyOwnershipsForCandoEmployers: withOwnershipsTrue
      });
    });
  };

  handlerRemoveOwnership = (job) => {
    const { candidate } = this.props;

    const data = {
      action: 'removed',
      operatedBy: Core.getUserId(),
      jobId: job.id,
      candidateId: candidate.id,
      employerId: job.employerId,
      forceOwnership: true
    };

    CandoPermittedJob.post(data, (result) => {
      if (window.confirm('Are you sure?')) {
        alert('succesfully removed');
        // job.candoPermittedJobs = [...result,job.candoPermittedJobs.filter(el=> String(el.id)!==String(result.id))];
        // getLastPermittedJob(job);
        // let newJobsPermitted = jobsPermitted.filter(el=>String(el.id)!==String(job.id));
        // this.setState({jobsPermitted:[...newJobsPermitted,job]});
        this.loadData();
      }
    });
  };

  render() {
    const { jobsPermitted, openForm, jobForOwnership, timestamp, hasOwnership, showPermoDetails, selectedCandoPermittedJobs } = this.state;

    return <Fragment>{!!jobsPermitted.length && <Table>
      <TableHeader selectable={false} displaySelectAll={false}>
        <TableRow>
          <TableHeaderColumn>
            Action
          </TableHeaderColumn>
          <TableHeaderColumn>
            Job Title
          </TableHeaderColumn>
          <TableHeaderColumn>
            Has Ownership?
          </TableHeaderColumn>
          <TableHeaderColumn>
            Ownership Timestamp(PST) (24hrs Format)
          </TableHeaderColumn>
          <TableHeaderColumn>
            Total Entries
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody displayRowCheckbox={false}>
        {jobsPermitted.map(job => {
          let candoPermittedJob = Object(job.lastPermittedJob);
          let timestamp = candoPermittedJob.timestamp;

          return <TableRow key={`permitted-job-manager-${job.id}`}>
            <TableRowColumn>
              <Link onClick={() => this.handlerOwnershipLink(job)}>Add</Link>
              {!!(candoPermittedJob.action === PERMITTED_JOB_ACTION.ACTION_ADDED) &&
                <Link onClick={() => this.handlerRemoveOwnership(job)}>&nbsp;|&nbsp;Remove</Link>}
            </TableRowColumn>

            <TableRowColumn>
              {job._name}
            </TableRowColumn>

            <TableRowColumn>
              {candoPermittedJob.hasOwnership}
            </TableRowColumn>

            <TableRowColumn>
              {!!timestamp ? moment.tz(timestamp, "America/Los_Angeles").format('MM/DD/YYYY HH:mm') : "N/A"}
            </TableRowColumn>

            <TableRowColumn>
              <span className="anchor"
                onClick={() =>
                  this.setState({ showPermoDetails: true, selectedCandoPermittedJobs: job.candoPermittedJobs })
                }
              >
                {job.candoPermittedJobs.length}
              </span>
            </TableRowColumn>
          </TableRow>
        })}
      </TableBody>
    </Table>}
      {!!showPermoDetails && <PermittedJobTable candoPermittedJobs={selectedCandoPermittedJobs} onClose={() => this.setState({ showPermoDetails: false })} />}
      {!!jobForOwnership &&
        <Dialog open={openForm} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Create Ownership</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {existingOwnershipStats(jobForOwnership)}
            </DialogContentText>
            <Fragment>
              <TextField
                autoFocus
                variant="standard"
                margin="dense"
                id="timestamp"
                key={"timestamp"}
                label="Timestamp (24hrs format)"
                type="text"
                fullWidth
                value={timestamp}
                onChange={(ev) => {
                  this.setState({ timestamp: ev.target.value });
                }}
              />

              <InputLabel id="demo-simple-select-label">Has Ownership?</InputLabel>
              <Select value={hasOwnership} onChange={(ev) => {
                this.setState({ hasOwnership: ev.target.value });
              }} displayEmpty>
                <MenuItem value={PERMITTED_JOB_HAS_OWNERSHIP.HAS_OWNERSHIP_OWNED}>Owned</MenuItem>
                <MenuItem value={PERMITTED_JOB_HAS_OWNERSHIP.HAS_OWNERSHIP_NOT_OWNED}>Not Owned</MenuItem>
                <MenuItem value={PERMITTED_JOB_HAS_OWNERSHIP.HAS_OWNERSHIP_TBD}>TBD</MenuItem>
              </Select>
            </Fragment>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handlerInsertOwnership} color="primary">
              Insert
            </Button>
          </DialogActions>
        </Dialog>}
    </Fragment>;
  }
}

const existingOwnershipStats = (job) => {
  const already = job._alreadyOwnershipsForMyEmployerWithDupCando;
  const alreadyHasOwnerships = !!already.length;
  const printingAllOwnerships = already.map(el => {
    return <li>Potential Duplicate Candidate <mark>{el.candidateId}</mark> already has ownership/tbd for
      employer <mark>{job.employerId}</mark></li>;
  });
  const thisJobHasOwnership = jobHasOwnership(job.lastPermittedJob.hasOwnership);
  return <Fragment>
    {alreadyHasOwnerships && <p>Following Details found for existing ownerships on this job </p>}
    <ul>{printingAllOwnerships}</ul>
    {alreadyHasOwnerships && !thisJobHasOwnership &&
      <h4 style={{ color: 'red' }}>Note that if you assign ownership now, it will remove existing ownership and affect
        the above candidate</h4>}
  </Fragment>
};

export default ManageOwnershipPermittedJobs;

