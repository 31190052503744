/** ============================================ µ
 * @description ExpandedView [JSX]
 *              UI Component
 * @createdAt   2021-09-23 Thu
 * @updatedAt   2021-11-09 Tue
 * ============================================ */
/* IMPORTS ==================================== */

import { Paper } from '@mui/material';
import { MuiThemeProvider } from 'material-ui/styles';
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Candidate from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import { hoverDisplay } from '../../../lib/models/engagement';

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function CandidateExpandedView(props) {
  const { model: candidate = {} } = props;
  const { jobsPermitted, engagements = [] } = candidate;
  const { t } = useTranslation();
  const [reset, setReset] = React.useState(engagements.length);
  return (
    <Paper className="p-1" data-reset={reset}>
      {!!engagements.length
        ? (
          <MuiThemeProvider>
            <Table className="bg-0 last-b-0">
              <TableBody displayRowCheckbox={false}>
                <TableRow selectable={false}>
                  {!Core.isLimited() && (
                    <TableRowColumn style={{ width: '120px' }}>
                      Employer
                    </TableRowColumn>
                  )}
                  {Core.isAdmin() && (
                    <TableRowColumn style={{ width: '350px' }}>
                      Job Title
                    </TableRowColumn>
                  )}
                  <TableRowColumn style={{ width: '100px' }}>
                    Last Action
                  </TableRowColumn>
                  <TableRowColumn style={{ width: '120px' }}>
                    Role
                  </TableRowColumn>
                  <TableRowColumn
                    style={{ width: '120px' }}
                    className="inline-blocks"
                  >
                    Stage&nbsp;
                    <i
                      title="Confirmation - need candidate's approval for submission&#013;Submission - 10x10 processing&#013;Review - employer reviewing resume&#013;Screen - phone screen or homework&#013;Onsite - in person interview&#013;Offer - offer discussion in progress&#013;Hire - offer accepted&#013;Guarantee - employment started"
                      className="material-icons"
                    >
                      info
                    </i>
                  </TableRowColumn>
                  <TableRowColumn
                    style={{ width: '130px' }}
                    className="inline-blocks"
                  >
                    Status&nbsp;
                    <i
                      title={t(`W - waiting on the entity listed to complete the next action to move the interview forward&#013;E - the interview process was ended by the entity listed`)}
                      className="material-icons"
                    >
                      info
                    </i>
                  </TableRowColumn>
                  <TableRowColumn style={{ width: '120px' }}>
                    Confirmed
                  </TableRowColumn>
                  <TableRowColumn style={{ width: '120px' }}>
                    Submitted
                  </TableRowColumn>
                  <TableRowColumn style={{ width: '120px' }}>
                    CV Review
                  </TableRowColumn>
                  <TableRowColumn style={{ width: '145px' }}>
                    Screen
                  </TableRowColumn>
                  <TableRowColumn style={{ width: '145px' }}>
                    Onsite
                  </TableRowColumn>
                  <TableRowColumn>State</TableRowColumn>
                </TableRow>
                {engagements.filter(
                  ({ rejectionReason }) =>
                    ![
                      '10x10 - No Match',
                      '10x10 - Bad Match',
                      '10x10 - Review',
                    ].includes(rejectionReason)
                ).map((engagement) => (
                  <TableRow
                    key={`candidate-engagements-row-${engagement.id}`}
                    selectable={false}
                    style={{
                      opacity: engagement.state === 'Open' ? 1 : 0.4,
                    }}
                    onClick={(ev) => Core.log({ eng: engagement })}
                  >
                    {!Core.isLimited() && (
                      <TableRowColumn>{engagement.employer._name}</TableRowColumn>
                    )}
                    {Core.isAdmin() && (
                      <TableRowColumn
                        className="ui-col"
                        title={engagement.job._jobTitle}
                      >
                        {engagement.job._jobTitle}
                        {!(jobsPermitted || []).includes(engagement.jobId) && (
                          <>
                            &nbsp;|&nbsp;
                            <span
                              className="anchor"
                              onClick={() =>
                                Candidate.addEngagementToPermittedJobs({
                                  candidate,
                                  engagement,
                                }).then(c => {
                                  Core.showMessage(
                                    t('candidate updated successfully with added permitted job')
                                  );
                                  candidate.jobsPermitted = [...c.jobsPermitted];
                                  setReset(engagements.length);
                                })
                              }
                            >
                              Add?
                            </span>
                          </>
                        )}
                      </TableRowColumn>
                    )}
                    <TableRowColumn title={engagement.lastAction}>
                      {engagement._lastAction}
                    </TableRowColumn>
                    <TableRowColumn>
                      {Core.isAdminOrCoordinator() ? (
                        <span
                          className="anchor"
                          onClick={(ev) => Core.open(`/job/edit/${engagement.jobId}`)}
                        >
                          {engagement._role}
                        </span>
                      ) : (
                        <span>{engagement._role}</span>
                      )}
                    </TableRowColumn>
                    <TableRowColumn>{engagement.stage}</TableRowColumn>
                    <TableRowColumn title={hoverDisplay(engagement)}>
                      {engagement.status}
                    </TableRowColumn>
                    <TableRowColumn title={engagement.confirmed}>
                      {engagement._confirmed}
                    </TableRowColumn>
                    <TableRowColumn title={engagement.submitted}>
                      {engagement._submitted}
                    </TableRowColumn>
                    <TableRowColumn title={engagement.reviewed}>
                      {engagement._reviewed}
                    </TableRowColumn>
                    <TableRowColumn>
                      <div
                        className="cando-screen-display"
                        title={`Screen 1: ${engagement.screen1}`}
                      >
                        {engagement._screen1}
                      </div>
                      <div
                        className="cando-screen-display"
                        title={`Screen 2: ${engagement.screen2}`}
                      >
                        {engagement._screen2}
                      </div>
                      <div
                        className="cando-screen-display"
                        title={`Screen 3: ${engagement.screen3}`}
                      >
                        {engagement._screen3}
                      </div>
                    </TableRowColumn>
                    <TableRowColumn>
                      <div
                        className="cando-onsite-display"
                        title={`Onsite 1: ${engagement.onsite1}`}
                      >
                        {engagement._onsite1}
                      </div>
                      <div
                        className="cando-onsite-display"
                        title={`Onsite 2: ${engagement.onsite2}`}
                      >
                        {engagement._onsite2}
                      </div>
                    </TableRowColumn>
                    <TableRowColumn>{engagement.state}</TableRowColumn>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </MuiThemeProvider>
        )
        : (
          <MuiThemeProvider>
            <Table className="bg-0 last-b-0">
              <TableBody displayRowCheckbox={false}>
                <TableRow selectable={false}>
                  <TableRowColumn>No engagements</TableRowColumn>
                </TableRow>
              </TableBody>
            </Table>
          </MuiThemeProvider>
        )}
    </Paper>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { CandidateExpandedView as default, CandidateExpandedView };

/* ============================================ */
