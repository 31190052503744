import React, {Component} from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

class AddItems extends Component {
    constructor() {
        super(...arguments);
        this.state = this.props.element;
        this.state.text = this.state.text || "";
        this.state.dialogOpen = this.props.isNewRecord;
    }

    handleClickOpen = () => {
        this.setState({dialogOpen: true});
    }

    handleClose = () => {
        this.setState({dialogOpen: false}, () => {
            if(!!this.state.text){
                this.props.onDone(this.getUpdatedStructure());
            }
        });
    }

    getUpdatedStructure = () => {
        let {
            id, text
        } = this.state;

        return {
            id,
            text
        };
    }

    render() {

        let {
            text, dialogOpen
        } = this.state;

        let {handlerDeleteItem} = this.props;

        return (
            <div>
                {!!text && <span>{text}</span>}

                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        this.handleClickOpen();
                    }}
                >
                    Edit&nbsp;|&nbsp;
                </Link>
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        handlerDeleteItem(this.getUpdatedStructure());
                    }}
                >
                    Delete
                </Link>

                <Dialog
                    open={dialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth="lg"
                    maxWidth="lg"
                >
                    <DialogTitle id="form-dialog-title">Additional Items</DialogTitle>
                    <DialogContent>
                        <TextField
                            id="standard-full-width"
                            label="Text"
                            style={{ margin: 8 }}
                            value={text}
                            placeholder=""
                            helperText="Use free text"
                            fullWidth
                            margin="normal"
                            onChange={(event, text) => {
                                this.setState({ text: event.target.value })}
                            }

                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default AddItems;

