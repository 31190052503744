import React, { Component } from "react";

class Row extends Component {
  render() {

    return (
      <div
        className={this.props.className ? "row " + this.props.className : "row"}
        style={{
          justifyContent: "space-between",
          display: "flex",
          ...this.props.style
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Row;
