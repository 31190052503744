import Core from "./Core";
import Debug from "./Debug";

/**
 * Http Library
 */
const Http = {
  get,
  put,
  post,
  patch,
  delete: DELETE
};
/**
 * Exports
 */
export { Http as default, request, get, put, post, patch, DELETE as delete };
/**
 * Http request
 * @param {String} method
 * @param {String} url
 * @param {Object} params
 * @return {Promise}
 */
function request(method, url, params) {
  let api = url;
  if (!!~url.indexOf("/api/")) {
    api = url.split("/api/")[1].split("/");
    api = `HTTP ${method} ${api[0]}${api[1] ? "/" + api[1] : ""}`;
  }
  return new Promise(function (resolve, reject) {
    var req = new XMLHttpRequest();
    const headers = [];
    if (!!params.headers) {
      Object.keys(params.headers).map(key =>
        headers.push([String(key), String(params.headers[key])])
      );
      delete params.headers;
    }
    if (method === "GET") {
      req.open(
        method,
        url +
        ((!!~url.indexOf("?") ? "&" : "?") +
          Object.keys(params)
            .map(key => {
              return key + "=" + encodeURIComponent(params[key]);
            })
            .join("&"))
      );
      setHandlers();
      const token = Core.getAccessToken();
      if (!!headers.length) {
        headers.map(item => req.setRequestHeader(item[0], item[1]));
        Core.log(headers);
      } else if (token) {
        req.setRequestHeader("Authorization", token);
      }
      Debug.time(api);
      req.send();
    } else {
      req.open(method, url);
      setHandlers();
      const token = params.token || Core.getAccessToken();
      if (!!token) {
        if (!!headers.length) {
          headers.map(item => req.setRequestHeader(item[0], item[1]));
        } else if (token) {
          req.setRequestHeader("Authorization", token);
        }
      }
      req.setRequestHeader("Content-type", "application/json");
      try {
        Debug.time(api);
        req.send(JSON.stringify(params));
      } catch (ex) {
        console.error({ errorParams: params });
        console.error(ex);
        return reject(ex.message);
      }
    }
    function setHandlers() {
      // This is called even on 404 etc
      req.onload = function () {
        Debug.showTimeEnd(api);
        // so check the status

        switch (req.status) {
          case 200:
            try {
              const response = JSON.parse(req.responseText);
              if (response.error) {
                const error =
                  typeof response.error === "string"
                    ? response.error
                    : Object(response.error).message || req.responseText;
                reject(error);
              } else {
                resolve(response);
              }
            } catch (ex) {
              reject(req.responseText);
            }
            break;
          case 204:
            resolve(req.response);
            break;
          case 401:
            /** usually this fail is coming when token expires, so is not needed to log on sentry but redirect to login page and clean local expiry token from local storage * /
             /** */
            Core.logout();
            document.location.href = "/";
            reject("Invalid Access");
            break;
          case 404:
            reject(404);
            break;
          case 422:
            reject(`422 -  ${JSON.parse(req.responseText).error?.message}`);
            break;
          default:
            if (!/matching-score/i.test(req.responseURL)) {
              if (!(/change-password/i.test(req.responseURL) && req.status === 400)) {
                Core.failure({
                  source: 'HTTP.js(lib):onload(default)',
                  exception: req.responseText,
                  params: { method, url, params, status: req.status },
                  omitUIMessage: true,
                  omitSentToSentry: true,
                });
              }
            }
            try {
              reject(Object(JSON.parse(req.responseText).error).message);
            } catch (ex) {
              reject(req.responseText);
            }
            break;
        }
      };
      // Handle network errors
      req.onerror = function (error) {
        Debug.showTimeEnd(api);
        console.warn("http:error", error);
        switch (req.status) {
          case 502:
            Core.failure({
              source: 'HTTP.js(lib):onerror(502)',
              exception: error,
              params: { method, url, params, status: req.status },
              omitUIMessage: true,
            });
            reject("502 Bad Gateway");
            break;
          default:
            Core.failure({
              source: 'HTTP.js(lib):onerror(default)',
              exception: error,
              params: { method, url, params, status: req.status },
              omitUIMessage: true,
            });
            reject("Network Failure");
            break;
        }
      };
    }
  });
}
/**
 * Http GET
 * @param {String} url
 * @param {String} params
 * @param {Function} onSuccess
 * @param {Function} onFail
 */
async function get(url, params, onSuccess, onFail) {
  if (params instanceof Function) {
    onFail = onSuccess;
    onSuccess = params;
    params = {};
  }
  else {
    params = params || {}
  }
  const promise = request("GET", url, params);
  promise.then(
    response => {
      onSuccess instanceof Function && onSuccess(response);
    },
    error => {
      onFail instanceof Function && onFail(error);
    }
  );
  return promise;
}
/**
 * Http POST
 * @param {String} url
 * @param {String} params
 * @param {Function} onSuccess
 * @param {Function} onFail
 */
async function post(url, params, onSuccess, onFail) {
  if (params instanceof Function) {
    onFail = onSuccess;
    onSuccess = params;
    params = {};
  }
  const promise = request("POST", url, params);
  promise.then(
    response => {
      onSuccess instanceof Function && onSuccess(response);
    },
    error => {
      onFail instanceof Function && onFail(error);
    }
  );
  return promise;
}
/**
 * Http PATCH
 * @param {String} url
 * @param {String} params
 * @param {Function} onSuccess
 * @param {Function} onFail
 */
async function patch(url, params, onSuccess, onFail) {
  if (params instanceof Function) {
    onFail = onSuccess;
    onSuccess = params;
    params = {};
  }
  const promise = request("PATCH", url, params);
  promise.then(
    response => {
      onSuccess instanceof Function && onSuccess(response);
    },
    error => {
      onFail instanceof Function && onFail(error);
    }
  );
  return promise;
}
/**
 * Http PUT
 * @param {String} url
 * @param {String} params
 * @param {Function} onSuccess
 * @param {Function} onFail
 */
async function put(url, params, onSuccess, onFail) {
  if (params instanceof Function) {
    onFail = onSuccess;
    onSuccess = params;
    params = {};
  }
  const promise = request("PUT", url, params);
  promise.then(
    response => {
      onSuccess instanceof Function && onSuccess(response);
    },
    error => {
      onFail instanceof Function && onFail(error);
    }
  );
  return promise;
}
/**
 * Http DELETE
 * @param {String} url
 * @param {String} params
 * @param {Function} onSuccess
 * @param {Function} onFail
 */
function DELETE(url, params, onSuccess, onFail) {
  if (params instanceof Function) {
    onFail = onSuccess;
    onSuccess = params;
    params = {};
  }
  const promise = request("DELETE", url, params);
  promise.then(
    response => {
      onSuccess instanceof Function && onSuccess(response);
    },
    error => {
      onFail instanceof Function && onFail(error);
    }
  );
  return promise;
}
