import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Announcements from '../components/Announcements/Announcements';
import CandidateEdit from '../components/Candidates/Edit/CandidateEdit';
import CandidateMatch from '../components/Candidates/Match/CandidateMatch';
import CandidateMatchNew from '../components/Candidates/MatchNew/CandidateMatchNew';
import MainV3 from '../components/Home/Main';
import MainV1 from '../components/HomeV1/Main';
import JobEdit from '../components/Jobs/Edit/JobEdit';
import JobMatch from '../components/Jobs/Match/JobMatch';
import Login from '../components/Login/Login';
import Settings from '../components/Settings/Settings';
import { NoMatch } from './NoMatch.routes';

export const RecruiterOnDev = (em) => (
  <Switch>
    {/** COMMON */}
    <Route exact path="/" component={Login} />
    <Route path="/login" component={Login} />
    <Route path="/forgot/password" component={Login} />
    <Route path="/reset/password/:token" component={Login} />
    <Route path="/home" component={MainV1} />
    <Route path="/candidates" component={MainV1} />
    <Route path="/candidate/create" component={CandidateEdit} />
    <Route path="/candidate/edit-section/:id" component={CandidateEdit} />
    <Route path="/candidate/edit/:id" component={CandidateEdit} />
    <Route path="/candidate/view/:id" component={CandidateEdit} />
    <Route path="/candidate/match/:id" component={CandidateMatch} />
    <Route path="/candidate/matchNew/:id" component={CandidateMatchNew} />
    <Route path="/jobs" component={MainV1} />
    <Route path="/job/view/:id" component={MainV1} />
    <Route path="/settings" component={Settings} />
    <Route path="/announcements" component={Announcements} />
    <Route path="/recruiter" component={MainV1} />
    {/** RESERVED FOR RECRUITER ON DEV */}
    <Route path="/engagements" component={MainV1} />
    <Route path="/engagement/view/:id" component={MainV1} />
    <Route path="/engagement/represent/:id" component={MainV1} />
    <Route path="/job/create" component={JobEdit} />
    <Route path="/job/edit/:id" component={JobEdit} />
    <Route path="/job/match/:id" component={JobMatch} />

    {/** COMMON SECTIONS V3 */}
    <Route path="/v3/candidate/create" component={CandidateEdit} />
    <Route path="/v3/candidate/edit-section/:id" component={CandidateEdit} />
    <Route path="/v3/candidate/edit/:id" component={CandidateEdit} />
    <Route path="/v3/candidate/view/:id" component={CandidateEdit} />
    <Route path="/v3/candidate/match/:id" component={CandidateMatch} />
    <Route path="/v3/candidate/matchNew/:id" component={CandidateMatchNew} />
    <Route path="/v3/announcements" component={Announcements} />
    <Route path="/v3/home" component={MainV3} />
    <Route path="/v3/candidates" component={MainV3} />
    <Route path="/v3/jobs" component={MainV3} />
    <Route path="/v3/v1/jobs" component={MainV3} />

    <Route component={NoMatch} />
  </Switch>
);
