import React, {Component, Fragment} from "react";

import Core from "../../../../lib/Core";
import formatURL from "../../../../lib/tools/formatURL";

import Definition from "../../../../lib/Definition";

class JobContentDrawer extends Component {

    render() {
        let {selectedJob} = this.props;
        let jobDrawerInfo = selectedJob;

        return (
        <Fragment>
            <p className="match-new drawer-text">
                {!!jobDrawerInfo && !!jobDrawerInfo._employerProxyName && jobDrawerInfo._employerProxyName ? jobDrawerInfo._employerProxyName + " | " : null}
                {!!jobDrawerInfo && !!jobDrawerInfo._employeeCount && jobDrawerInfo._employeeCount ? jobDrawerInfo._employeeCount + " | " : null }
                {!!jobDrawerInfo && typeof jobDrawerInfo._employeeCountNum !== "undefined" ? `~${jobDrawerInfo._employeeCountNum} engineers | ` : null }
                {!!jobDrawerInfo && !!jobDrawerInfo.employer.stage && jobDrawerInfo.employer.stage ? `Engineering team: ${Definition.getLabel("stage", jobDrawerInfo.employer.stage)} | ` : null}
                {!!jobDrawerInfo && !!jobDrawerInfo.employer.totalFunding && jobDrawerInfo.employer.totalFunding ? `Funding ${jobDrawerInfo.employer.totalFunding} | ` : null}
                {!!jobDrawerInfo && !!jobDrawerInfo.employer.foundingYear && jobDrawerInfo.employer.foundingYear ? `Founded ${jobDrawerInfo.employer.foundingYear} | ` : null}
                {!!jobDrawerInfo && typeof jobDrawerInfo.jobType !== 'undefined' ? Definition.getLabel("jobType", jobDrawerInfo.jobType) + " | ": null }
                {!!jobDrawerInfo && typeof jobDrawerInfo.remote !== "undefined" ? `Work from home: ${Definition.getLabel("remote", jobDrawerInfo.remote)} ` : null}
                {!!jobDrawerInfo && !!jobDrawerInfo.remoteExtraInfo ? `(${jobDrawerInfo.remoteExtraInfo}) |` : '|'}
                {!!jobDrawerInfo && !!jobDrawerInfo._relocation ? ` Relocation: ${jobDrawerInfo._relocation} ` : null}
                {!!jobDrawerInfo && !!jobDrawerInfo.relocationExtraInfo ? `(${jobDrawerInfo.relocationExtraInfo})` : null}

            </p>

            {!!jobDrawerInfo && (typeof jobDrawerInfo.jobBlackList !== "undefined" && jobDrawerInfo.jobBlackList.length !== 0) && (
                <p>
                    Black List: {jobDrawerInfo.jobBlackList.map((blackList, index) => {
                    let blackListTxt;
                    blackListTxt = blackList.firstName + " " + blackList.lastName;
                    if (index !== jobDrawerInfo.jobBlackList.length - 1) {
                        blackListTxt += ", ";
                    }
                    return blackListTxt;
                
                    })}
                </p>
                )}
            
            {!!jobDrawerInfo && !!jobDrawerInfo.employer.additionalInfo && (
                <Fragment>
                    <p className="match-new drawer-text"><strong>HIRING PRIORITY</strong></p> 
                
                    <div
                        className="match-new drawer-text"
                        dangerouslySetInnerHTML={{
                            __html: jobDrawerInfo.employer.additionalInfo
                        }}
                    />
                </Fragment>
            )}

            {!!jobDrawerInfo && (!!jobDrawerInfo.jobInterviewProcess || !!jobDrawerInfo.employer.interviewProcess) && (
                <Fragment>
                    <p className="match-new drawer-text"><strong>INTERVIEW PROCESS</strong></p> 
                    <div 
                        className="match-new drawer-text"
                        dangerouslySetInnerHTML={{
                            __html: jobDrawerInfo.employer.interviewProcess
                        }}
                    />
                    <div 
                        className="match-new drawer-text"
                        dangerouslySetInnerHTML={{
                            __html: jobDrawerInfo.jobInterviewProcess
                        }}
                    />
                </Fragment>
            )}

            {!!jobDrawerInfo && (!!jobDrawerInfo.publicNotes || !!jobDrawerInfo.employer.publicNotes) && (
                <Fragment>
                    <p className="match-new drawer-text"><strong>NOTES</strong></p> 
                    <div 
                        className="match-new drawer-text"
                        dangerouslySetInnerHTML={{
                            __html: jobDrawerInfo.employer.publicNotes
                        }}
                    />
                    <div 
                        className="match-new drawer-text"
                        dangerouslySetInnerHTML={{
                            __html: jobDrawerInfo.publicNotes
                        }}
                    />
                </Fragment>
            )}

            {!!jobDrawerInfo && !!jobDrawerInfo.employer.url && (
                <div
                    className="match-new drawer-text"
                >
                    <strong>
                        <a href={formatURL(jobDrawerInfo.employer.url)}>
                            {jobDrawerInfo.employer.url}
                        </a>
                    </strong>
                </div>
            )}

            {!!jobDrawerInfo && !!jobDrawerInfo.employer.tagline && !!jobDrawerInfo.employer.name && (
                <Fragment>
                    <p className="match-new drawer-text"><strong>{jobDrawerInfo.employer.name.toUpperCase()} ELEVATOR PITCH</strong></p> 
                    <div 
                        className="match-new drawer-text"
                        dangerouslySetInnerHTML={{
                            __html: jobDrawerInfo.employer.tagline
                        }}
                    />
                </Fragment>
            )}

            {!!jobDrawerInfo && !!jobDrawerInfo.employer.product && (
                <Fragment>
                    <p className="match-new drawer-text"><strong>COMPANY OVERVIEW</strong></p> 
                    <div 
                        className="match-new drawer-text"
                        dangerouslySetInnerHTML={{
                            __html: jobDrawerInfo.employer.product
                        }}
                    />
                </Fragment>
            )}

            {!!jobDrawerInfo && !!jobDrawerInfo.employer.whyUs && (
                <Fragment>
                    <p className="match-new drawer-text"><strong>WHY US</strong></p> 
                    <div 
                        className="match-new drawer-text"
                        dangerouslySetInnerHTML={{
                            __html: jobDrawerInfo.employer.whyUs
                        }}
                    />
                </Fragment>
            )}

            {!!jobDrawerInfo && (!!jobDrawerInfo.employer.crunchbaseUrl || !!jobDrawerInfo.employer.additionalUrl) && (
                <Fragment>
                    <p className="match-new drawer-text"><strong>COMPANY INFO</strong></p> 
                    <div className="match-new drawer-text">
                        Links:
                        <div>
                            <a href={formatURL(jobDrawerInfo.employer.crunchbaseUrl)}>
                                {jobDrawerInfo.employer.crunchbaseUrl}
                            </a>
                        </div>
                        {jobDrawerInfo.employer.additionalUrl &&
                        jobDrawerInfo.employer.additionalUrl.split(/\n/).map(url => (
                            <div key={Core.getKey()}>
                                <a href={formatURL(url)}>{url}</a>
                            </div>
                        ))}
                    </div>
                </Fragment>
            )}
        </Fragment>
        )
    }
}
export default JobContentDrawer;