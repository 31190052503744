import React from "react";
import MultipleSelect from '../Forms/MultipleSelect';
import { REACT_TABLE__COLUMN_XXXL } from "./useEnhancedReactTable.hook";
import { useFacets } from "./useFacets.hook";

export function FilterTagColumn(props) {
  let {
    column,
    column: {
      id: columnId,
      _categoryKey: categoryKey,
      _renderSubFilter = props => null,
    },
    context: { config: { facetsPath }, },
    filters = {},
    setFilter = () => { },
    latestQuery = {},
  } = props;
  const {
    suggestions,
    fetchSuggestions,
  } = useFacets(facetsPath);
  return (
    <div className="d-flex flex-wrap flex-align-center w-100">
      <MultipleSelect
        name="filter"
        className="m-0"
        placeholder="Select multiple items"
        data={suggestions}
        value={filters[columnId]}
        onChange={(value) => {
          setFilter({
            columnId,
            value: value.length ? value : undefined
          });
        }}
        onSearch={value => {
          fetchSuggestions({
            column,
            categoryKey,
            filters: latestQuery.filters,
            value,
          });
        }}
        dropdownMatchSelectWidth={REACT_TABLE__COLUMN_XXXL}
      />
      {_renderSubFilter(props)}
    </div>
  );
}
