import { IconButton } from "@mui/material";
import {
  CardHeader,
  CardText,
  Checkbox,
  Divider,
  FlatButton,
  TextField
} from "material-ui";
import moment from "moment";
import React, { Component, Fragment } from "react";
import EmployerDetails from "../../components/Employers/Card/EmployerDetails";
import Core, { colors } from "../Core";
import Definition, {
  ATS_TYPE__GH_HARVEST_ID,
  ATS_TYPE__GH_INGESTION_ID,
  ATS_TYPE__NONE_ID
} from "../Definition";
import Employer from "../Employer";
import { newModel } from "../GenericTools.lib";
import { mapStarred } from "./mapStarred.tool";
import { mapJobs } from "./job";

const MODEL_NAME = 'Employer';
const mdash = "—";

/**
 * This model is used to READ/SAVE values from DB
 */
const model = {
  /** REQUIRED */
  name: "",
  /** BASICS, step 1 */
  proxyName: "",
  addressStreet: "",
  addressCity: "",
  addressState: "CA",
  addressZip: "",
  addressCountry: "US",
  //employerStageTagId: "", // FK
  stage: 0,
  employeeCount: "", // NUMBER, default should not be zero.
  foundingYear: "", // 2018,
  totalFunding: 0, // 0,
  mostRecentFundingDate: "", // "2017-12-15",
  /** ENGINEERING, step 2 */
  teamCount: "", // NUMBER, default should not be zero.
  teamStructure: "",
  teamChallenge: "",
  visaTransfer: 0,
  //employerRemoteTagId: "", // FK
  remote: 0,
  remoteExtraInfo: "",
  relocation: 2,
  relocationExtraInfo: "",
  /** WHY US, step 3 */
  tagline: "",
  product: "",
  whyUs: "",
  perks: "",
  url: "",
  crunchbaseUrl: "",
  additionalUrl: "",
  notables: "",
  /** CONTACT step 4 */
  primaryContactName: "",
  primaryContactTitle: "",
  primaryContactEmail: "",
  primaryContactPhone: "",
  primaryContactLinkedin: "",
  primaryContactNotes: "",
  /** PROCESS step 5 */

  /* µ TO DEPRECATE 2021-04-29 */
  ats: "",

  /* µ TO DEPRECATE 2021-04-29 */
  atsUrl: "",

  atsTypeId: ATS_TYPE__NONE_ID,
  atsApiProviders: {},

  scheduling: "",
  interviewProcess: "",
  additionalInfo: "",
  reference: "",
  internalSubmissionInstructions: "",
  recruitingAgencies: "",
  expectedResumeResponseTime: 48,
  expectedSchedulingResponseTime: 48,
  expectedScreenResponseTime: 48,
  expectedOnsiteResponseTime: 48,
  createdAt: null, // new Date().toISOString(), // MIXIN "2017-12-15T18:48:33.331Z",
  updatedAt: null, // new Date().toISOString(), // MIXIN "2017-12-15T18:48:33.331Z"
  closeDate: null, // "2017-12-15T18:48:33.331Z",
  /** PROCESS step 5, green block */
  additionalRecruitingAgencies: "",
  flagTagIds: [],
  confidential: false,
  employerHiringDifficulty: null,
  state: 2,

  /* related to story-3258 - {number:LocationTagId} */
  location: null,

  placementFee: "", // NUMBER
  guarantee: "", // NUMBER
  additionalContractInfo: "",
  publicNotes: "",
  internalNotes: "",
  /** NEW FIELDS */
  technicalSkills: [],
  flags: [],
  mustHaveQuestions: [],
  submissionMethod: 0,
  submissionMethods: [],

  /** */

  /* epic-3038-story-3573 | 2021-07-28 Wed µ */
  candidateLocations: [],

  // story-3927-m4 | 2021-09-02 Thu µ
  emailsList: [],
  excludeAutomatedEmailsUntil: ''

};

/**
 * This model is used to READ values from DB
 */
const extended = {
  ___model___: MODEL_NAME,
  id: null,
  ...model,
  /** includes */
  jobs: [],
  /** local fills */
  employerNotes: {},
  employerBlackList: [],
  employerSourceList: [],
  employerWhiteList: [],
  ___keys___: []
};

const atsGreenhouseHarvestModel = {
  apiName: "Greenhouse Harvest",
  apiKey: '',
  manualSubmissionUrl: '',
  onBehalfOfEmployerUserName: '',
  onBehalfOfEmployerUserId: null,
  sourceIdAgencyId: null,
  agencyName: '',
  agencyRecruiterName: ''
}

const atsGreenhouseIngestionModel = {
  apiName: "Greenhouse Ingestion",
  apiKey: '',
  manualSubmissionUrl: '',
  onBehalfOfEmployerUser: '',
}

const mapEmployer = item => {

  const employer = getEmployerModel({
    extended: true
  });

  if (item) {
    Object.keys(extended).forEach(
      key => !!item[key] && (employer[key] = item[key])
    );
    employer.id = item.id || item._id;
    employer._updatedAt = employer.updatedAt
      ? moment(employer.updatedAt).format("MM-DD-YYYY")
      : mdash;

    mapStarred({ model: employer, starredList: (item.employerStarreds || []) });

    employer.employerNotes = Object((item.employerNotes || [])[0]);
    employer.jobs = mapJobs(employer.jobs);

    /* mapping stuff */
    employer.name = employer.name || employer.proxyName;
    employer._name = String(employer.name).trim();
    employer._name_rating = employer._name;
    employer._starred = employer.starred ? "Starred: True" : "Starred: False";
    if (Core.isAdminOrCoordinator() && employer.employerHiringDifficulty) {
      employer._name_rating +=
        " (" +
        Definition.getLabel(
          "employerHiringDifficulty",
          employer.employerHiringDifficulty
        ) +
        ")";
    }

    if (employer.jobs.length) {

      employer.jobRoles = [];
      employer.jobs.forEach(job => {
        job.roles.forEach(role => {
          if (!employer.jobRoles.includes(role)) {
            employer.jobRoles.push(role)
          }
        })
      });

    }

    employer._employeeCount = employer.employeeCount ? (
      <Fragment>
        ~{employer.employeeCount}
        &nbsp;employees&nbsp;&nbsp;&nbsp;
      </Fragment>
    ) : (
      <i>&mdash;</i>
    );

    employer._submissionMethod = Definition.getLabel('employerSubmissionMethod', employer.submissionMethod);

    employer._active = employer.jobs.filter(job =>
      Definition.test("state", job.state, /active/)
    ).length;
    employer._activeNum = Number(employer._active);
    employer._active = employer._active ? (
      employer._active + " Active"
    ) : (
      <i>&mdash;</i>
    );



    employer._jobsLengthNum = Number(employer.jobs.length);
    employer._jobsLength = employer.jobs.length ? (
      employer.jobs.length + " Jobs"
    ) : (
      <i>&mdash;</i>
    );

    employer._address = [
      employer.addressStreet,
      employer.addressCity,
      `${employer.addressState} ${employer.addressZip}`
    ]
      .filter(e => e && e.trim())
      .join(", ");

    /* set definition labels */
    /* for fill filter menus and autocomplete */
    Definition.set(employer, "employerHiringDifficulty");
    Definition.set(employer, "location");
    Definition.set(employer, "visa", "visaTransfer");
    Definition.set(employer, "stage");
    Definition.set(employer, "state");
    Definition.map(employer, "roles", "jobRoles");
    Definition.map(employer, "technicalSkills");
    Definition.map(employer, "flags");

    employer._infoCmp = <EmployerInfo employer={employer} />;
    employer._rowOptionsCmp = <RowOptions employer={employer} />;
    employer.openDetails = em => {
      Employer.getBlackList(employer.id, employerBlackList => {
        employer.employerBlackList = employerBlackList;
        Employer.getWhiteList(employer.id, employerWhiteList => {
          employer.employerWhiteList = employerWhiteList;
          Core.openDrawer({
            style: {
              width: 900,
              minWidth: '60vw',
              maxWidth: 'calc(100vw - var(--leftSideWidth))',
            },
            content: (
              <>
                <EmployerDetails employer={employer} />
                <IconButton
                  style={{ position: 'fixed', top: 0, right: 0, zIndex: 2 }}
                  onClick={(ev) => Core.closeDrawer()}
                >
                  <i className="material-icons">arrow_forward_ios</i>
                </IconButton>
              </>
            )
          });
        });
      });
    };
    employer.delete = onSuccess => {
      const engagements = employer.engagements || [];
      Core.dialog.open({
        title: (
          <>{`Delete "${employer._name}"${!!engagements.length
            ? ` and ${engagements.length} engagement${engagements.length === 1 ? "" : "s"
            }?`
            : ""
            }`}</>
        ),
        message: "This action can't be undone.",
        style: { width: "320px" },
        actions: [
          <FlatButton
            label="Cancel"
            className="button-white-cyan"
            onClick={ev => {
              Core.dialog.close();
            }}
          />,
          <FlatButton
            label="Delete"
            className="button-flat-cyan"
            onClick={ev => {
              Core.dialog.close();
              Employer.delete(
                employer.id,
                onSuccess ? onSuccess : function () { }
              );
            }}
          />
        ]
      });
    };
    employer.checkStar = (checked, onSuccess) => {
      Employer.updateStarred(
        employer.id,
        employer.starredId,
        checked,
        response => {
          employer.starredId = response.id;
          employer.starred = response.starred;
          employer._starred = response.starred
            ? "Starred: True"
            : "Starred: False";
          employer.filters = {
            ...employer.filters,
            Starred: ["Non Starred", "Starred"][~~Boolean(response.starred)]
          };
          onSuccess && onSuccess(response);
        }
      );
    };

    /* for autocomplete */
    employer.___keys___ = [
      employer._name,
      employer.proxyName,
      employer._jobRoles,
      employer._location,
      employer._visaTransfer,
      employer._technicalSkills,
      employer._flags,
      employer._stage,
      employer._state,
      employer._starred
    ]
      /* combine and split values */
      .join("; ")
      .split("; ")
      /* remove empty values */
      .filter(e => !!e && String(e).trim());
  }
  patchATS({ employer });
  return employer;
};

const mapEmployers = data => {
  return data.map(item => {
    const employer = mapEmployer(item);
    return {
      ...employer,
      filters: {
        Name: employer.name,
        Recent: moment(employer.updatedAt),
        Starred: ["Non Starred", "Starred"][~~Boolean(employer.starred)]
      }
    };
  });
};

class EmployerInfo extends Component {
  render() {
    const { employer } = this.props;
    return Core.isAdmin() ? (
      <span
        className="anchor"
        onClick={ev => Core.go({
          ...this.props,
          to: `/employer/edit/${employer.id}`
        })}
      >
        <b>{employer._name || <i>&mdash;</i>}</b>
      </span>
    ) : (
      <b
        style={{
          color: colors.black,
          fontWeight: 500
        }}
      >
        {employer.name || <i>&mdash;</i>}
      </b>
    );
  }
}
export class Details extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      publicNotes: this.props.employer.publicNotes
    };
  }
  onChangeEmployerNotes = (ev, publicNotes) => {
    const { employer } = this.props;
    this.setState({ publicNotes });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      Employer.update(employer.id, { publicNotes });
    }, 1000);
  };
  render() {
    const { employer } = this.props;
    return (
      <>
        {!!employer.jobs.length && (
          <>
            <CardHeader title="HIRING" />
            <CardText className="contents">
              {Core.isAdminOrCoordinator()
                ? employer.jobs.map(job => (
                  <span
                    className="anchor"
                    key={job.id}
                    onClick={ev => Core.go({ ...this.props, to: `/job/view/${job.id}` })}
                  >
                    <li style={{ marginBottom: 16 }}>
                      {Definition.getLabel("roles", job.role)}
                      &nbsp;&mdash;&nbsp;
                      {Definition.getLabel("state", job.state)}
                    </li>
                  </span>
                ))
                : employer.jobs.map(job => (
                  <li>{Definition.getLabel("roles", job.role)}</li>
                ))}
            </CardText>
          </>
        )}
        {!!employer._technicalSkills.length && (
          <>
            <Divider />
            <CardHeader title="TECHNOLOGY STACK" />
            <CardText className="contents">
              {employer._technicalSkills}
            </CardText>
            <Divider />
          </>
        )}
        <CardHeader title="NOTES" />
        <CardText className="contents">
          <TextField
            name="employerNotes"
            underlineFocusStyle={{
              borderColor: colors.purple,
              borderWidth: "1.5px"
            }}
            type="text"
            rows={3}
            rowsMax={6}
            multiLine={true}
            value={this.state.publicNotes}
            onChange={this.onChangeEmployerNotes}
            fullWidth
          />
        </CardText>
        {Core.isAdminOrCoordinator() &&
          (!!employer.employerBlackList.length ||
            !!employer.employerWhiteList.length) && <Divider />}
        {Core.isAdminOrCoordinator() && !!employer.employerBlackList.length && (
          <div className="ui-g-6">
            <CardHeader
              avatar={<i className="material-icons">block</i>}
              title="BLACK LIST"
            />
            <CardText>
              {employer.employerBlackList.map(item => (
                <li key={item.id}>
                  {item.firstName} {item.lastName}
                </li>
              ))}
            </CardText>
          </div>
        )}
        {Core.isAdminOrCoordinator() && !!employer.employerWhiteList.length && (
          <div className="ui-g-6">
            <CardHeader
              avatar={<i className="material-icons">done</i>}
              title="WHITE LIST"
            />
            <CardText>
              {employer.employerWhiteList.map(item => (
                <li key={item.id}>
                  {item.firstName} {item.lastName}
                </li>
              ))}
            </CardText>
          </div>
        )}
      </>
    );
  }
}
class RowOptions extends Component {
  render() {
    // Core.log("RowOptions", "render");
    const { employer } = this.props;
    return (
      <div className="row-options inline-blocks">
        <Checkbox
          className="starred"
          checked={employer.starred}
          onCheck={(ev, checked) => {
            employer.checkStar(checked, res =>
              this.setState({ updated: true })
            );
          }}
          checkedIcon={<i className="material-icons">star</i>}
          uncheckedIcon={<i className="material-icons">star_border</i>}
        />
        <i
          className="material-icons"
          style={{
            width: 24,
            height: 24,
            margin: 0,
            cursor: "pointer",
            fontWeight: 200,
            ...employer.openedColor,
            ...employer.rightArrow
          }}
          onClick={employer.openDetails}
        >
          chevron_right
        </i>
      </div>
    );
  }
}

/**
 * 
 * @param {object} options Optional
 * @param {boolean} options.extended
 * @returns {object} A new model
 */
function getEmployerModel({
  extended: isExtendedRequired
} = {}) {
  return newModel(
    isExtendedRequired ? extended : model
  );
}

/**
 * 
 * @param {object} params
 * @param {number} atsTypeId Ats Type Tag id
 * @returns {object} new ATS model
 */
function getAtsProviderModel({ atsTypeId }) {

  const atsApiProvidersModel = {};

  atsApiProvidersModel[
    ATS_TYPE__GH_HARVEST_ID
  ] = atsGreenhouseHarvestModel;

  atsApiProvidersModel[
    ATS_TYPE__GH_INGESTION_ID
  ] = atsGreenhouseIngestionModel;

  return newModel(
    atsApiProvidersModel[atsTypeId] || {}
  );

}

/**
 * Patch for transition old ATS fields to new ones
 * 2021-04-29
 * 
 * This functions will modify employer object.
 * 
 * @param {object} params
 * @param {object} params.employers
 * 
 * @todo 
 * Review patch code after 2021-06-01
 * to determine utility of maintain it.
 * 
 * @see CandidateResumeSubmission component
 * 
 */
function patchATS({ employer }) {

  /* UPDATE atsTypeId */
  const {
    ats = '',
    atsTypeId,
  } = employer;
  const parsedATS = Number(ats);
  if (!!parsedATS && (!atsTypeId || atsTypeId === ATS_TYPE__NONE_ID)) {
    employer.atsTypeId = parsedATS;
  }

  /* UPDATE manualSubmissionUrl */
  const {
    atsUrl = '',
    atsApiProviders = {},
  } = employer;
  const atsProvider = (
    atsApiProviders[employer.atsTypeId]
    ||
    getAtsProviderModel(employer.atsTypeId)
  );
  if (
    !!atsUrl && !atsProvider.manualSubmissionUrl
  ) {
    atsProvider.manualSubmissionUrl = atsUrl;
    employer.atsApiProviders[
      employer.atsTypeId
    ] = atsProvider;
  }

}

export {
  getEmployerModel as default,
  getEmployerModel,
  atsGreenhouseHarvestModel,
  atsGreenhouseIngestionModel,
  getAtsProviderModel,
  mapEmployer,
  mapEmployers
};

