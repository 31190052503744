import Store from "../Store";

function copyHTML(html) {
  html = html
    .split(/\n|\s/)
    .filter(i => !!i)
    .join(" ")
    .replace(/>\s*</g, "><");
  console.debug("copyHTML", { html });
  return new Promise((resolve, reject) => {
    const wrapper = document.createElement("div");
    wrapper.setAttribute("class", "wrapperCopy f-arial f-md f-normal");
    wrapper.setAttribute(
      "style",
      "color: rgba(0,0,0,0.8)!important;"
    );
    wrapper.addEventListener("click", doCopy);
    wrapper.addEventListener("fakeclick", doCopy);
    wrapper.innerHTML = html;
    document.body.appendChild(wrapper);
    function doCopy(event) {
      window.getSelection().removeAllRanges();
      var range = document.createRange();
      range.selectNode(document.querySelector(".wrapperCopy"));
      window.getSelection().addRange(range);
      try {
        var successful = document.execCommand("copy");
        console.debug("copyHTML", { successful });
        if (successful) {
          resolve(successful);
        } else {
          reject(successful);
        }
      } catch (ex) {
        console.error(ex);
        reject(ex);
      }
      window.getSelection().removeAllRanges();
      let debugCopyHTML = Store.get('debugCopyHTML');
      if (debugCopyHTML) {
        event.type === 'click' && document.body.removeChild(wrapper);
      }
      else {
        document.body.removeChild(wrapper);
      }
    }
    var event = new Event("fakeclick");
    wrapper.dispatchEvent(event);
    // doCopy();
  });
}
export default copyHTML;
