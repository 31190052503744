/** ============================================ µ
 * @description Single Select Dropdown [JSX]
 *              UI Component
 * @createdAt   2021-06-04
 * @updatedAt   2021-06-04
 * ============================================ */
/* IMPORTS ==================================== */

import { Select } from "antd";
import "antd/dist/antd.css";
import React from "react";

/* CONSTANTS ================================== */

const { Option } = Select;

/* METHODS ==================================== */

function SingleSelectDropdown(props) {

  const {
    name,
    data = [],
    placeholder = 'Select one item',
    value,
    defaultValue,
    onChange = () => { },
  } = props;

  const [_value, setValue] = React.useState(defaultValue);

  const children = data.map(({ id, label }, index) => (
    <Option
      key={`option+${name}.${id}(required-by-react)`}
      value={id}
    >
      {label}
    </Option>
  ));

  // console.debug({ data, children });

  function _onChange(value) {
    console.debug('µ', 'MSD.onChange', value);
    setValue(value);
    onChange(value);
  }

  function _onBlur() {
    console.log('blur');
  }

  function _onFocus() {
    console.log('focus');
  }

  function _onSearch(val) {
    console.log('search:', val);
  }

  return (
    <Select
      // name={`new-${name}`}
      autocomplete={`new-${name}`}
      internalProps={{
        autocomplete: `new-${name}`
      }}
      showSearch
      style={{ width: "100%", textAlign: "left" }}
      placeholder={placeholder}
      value={value||_value}
      onChange={_onChange}
      onFocus={_onFocus}
      onBlur={_onBlur}
      onSearch={_onSearch}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {children}
    </Select>
  );

}

/* EXPORTS ==================================== */

export {
  SingleSelectDropdown as default,
  SingleSelectDropdown
};

/* ============================================ */
