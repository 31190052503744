import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Chip } from "material-ui";
import moment from "moment-timezone";
import dig from 'object-dig';
import React, { Component, Fragment } from "react";
import Candidate from "../../../lib/Candidate";
import Core, { colors } from "../../../lib/Core";
import dirtyUniqAttrs from "../../../lib/DirtyUniqueAttrs";
import getJobOwnershipTextAndColor from "../../../lib/GetJobOwnershipTextAndColor";
import Job from "../../../lib/Job";
import Chips from "../../Forms/Chips";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";

class CandidateJobChoices extends Component {
  constructor(args) {
    super(args);
    this.state = {
      jobsList: [],
      showAll: false,
      showActive: true,
      initialJobsPermitted: [],
      initialJobsPitched: [],
      initialJobsDeclined: [],
      isLoading: true,
      showPermittedJobsDetails: false,
      ownedPermittedJobsData: false
    };
    this.isJobsPermittedArrvived = false;
    this.isJobsPitchedArrvived = false;
    this.isJobsDeclinedArrvived = false;
    this._allJobsArrived = false;
  };

  componentDidMount() {
    // this.loadJobs();
  };

  componentDidUpdate(nextProps, nextState) {
    const { jobsPermitted, loadDefaultValuesComplete, jobsPitched, jobsDeclined } = this.props.parent;
    const { ownedPermittedJobsData } = this.state;

    if (!!loadDefaultValuesComplete && (!!nextProps.parent.jobsPermitted.length !== !!jobsPermitted.length || !this.isJobsPermittedArrvived)) {
      this.isJobsPermittedArrvived = true;
      if (!ownedPermittedJobsData && jobsPermitted.length && !!this.props.parent.id) {
        Candidate.getOwnedPermittedJobs({
          params: {
            candidateId: this.props.parent.id,
            permittedJobIds: jobsPermitted.join(',')
          }
        }, (ownedPermittedJobs) => {
          this.setState({ ownedPermittedJobsData: ownedPermittedJobs }, () => {
            this.props.parentComponent.setState({
              jobsPermitted: ownedPermittedJobs.map(obj => obj.jobId)
            });
            this.loadInitialJobs("initialJobsPermitted", jobsPermitted);

          })
        });
      } else {
        this.setState({ ownedPermittedJobsData: [] }, () => {
          this.loadInitialJobs("initialJobsPermitted", jobsPermitted);
        })
      }

    }
    if ((!!nextProps.parent.jobsPitched.length !== !!jobsPitched.length) || !this.isJobsPitchedArrvived) {
      this.isJobsPitchedArrvived = true;
      this.loadInitialJobs("initialJobsPitched", jobsPitched);
    }
    if ((!!nextProps.parent.jobsDeclined.length !== !!jobsDeclined.length) || !this.isJobsDeclinedArrvived) {
      this.isJobsDeclinedArrvived = true;
      this.loadInitialJobs("initialJobsDeclined", jobsDeclined);
    }
  }

  loadInitialJobs = (key, jobs) => {
    if (!!jobs.length) {
      const where = { id: { inq: jobs } };
      const opts = {
        include: [
          {
            relation: "employer",
            scope: {
              include: ["employerBlackList", "employerSourceList"]
            }
          },
          "resumeSubmissionEmailTemplate",
          "jobBlackList",
          "jobSourceList",
          {
            relation: "jobStarreds",
            scope: {
              where: {
                accountId: Core.getUserId()
              }
            }
          }
        ],
        fields: ['id', 'employerId', 'jobTitle', 'addressCity', 'state'],
        // overRideCommonQuery: {
        //     include: {
        //         relation: "employer",
        //         scope: {
        //             fields: ['name']
        //         }
        //     }
        // }
      };

      Job.getWhere(where, (res) => {

        if (key === 'initialJobsPermitted') {
          this.props.parentComponent.setState({ _jobsPermitted: res });
        }

        this.setState({
          [key]: res.sort((a, b) =>
            String(a._name).localeCompare(String(b._name)))
        });
      }, opts);
    }
  };

  loadJobs = () => {
    if (!this._allJobsArrived) {
      this._allJobsArrived = true;
      Job.getActives(jobs => {
        this.setState({
          isLoading: false,
          jobsList: jobs.sort((a, b) =>
            String(a._name).localeCompare(String(b._name))
          )
        });
      }, { fields: ['id', 'employerId', 'state', 'jobTitle', 'addressCity', 'role'] });
    }
  };

  dialogTopRow = () => {
    return <div>
      {Core.isAdminOrCoordinator() &&
        <FormControlLabel
          control={
            <Switch
              checked={this.state.showActive}
              onChange={ev => {
                this.setState({ showActive: ev.target.checked })
              }}
              value=""
            />
          }
          label="Show Active Only"
        />
      }

      <FormControlLabel
        control={
          <Switch
            checked={!this.state.showAll}
            onChange={ev => {
              this.setState({ showAll: !ev.target.checked })
            }}
            value=""
          />
        }
        label="Show Jobs for selected roles only"
      />

    </div>;
  };

  handlerPermittedDetails = () => {
    let { showPermittedJobsDetails } = this.state;
    let { jobsPermitted } = this.props.parent;

    if (!!this.props.parent.id) {
      Candidate.getOwnedPermittedJobs({
        params: {
          candidateId: this.props.parent.id,
          permittedJobIds: jobsPermitted.join(',')
        }
      }, (ownedPermittedJobs) => {
        this.setState({
          ownedPermittedJobsData: ownedPermittedJobs,
          showPermittedJobsDetails: !showPermittedJobsDetails
        })
      });
    }

    // showPermittedJobsDetails: false,
    //     ownedPermittedJobsData: []

  };

  thisJobIsConflictColorMethod = (ownershipResult = [], duplicateResult, permittedJobs, candoPermittedJobs = []) => (jobId) => {
    const { parent } = this.props;
    // employer A, job A
    const isDirty = dirtyUniqAttrs(parent, parent._persisted).length;

    let thisJobsEmployerId = Object(permittedJobs.find(j => String(j.id) === String(jobId))).employerId;
    let { color, isOwned, iOwnIt, hasEngagement } = getJobOwnershipTextAndColor(ownershipResult, thisJobsEmployerId, parent.id, jobId, Object(duplicateResult).identifiedDuplicateCando, isDirty);
    const isPresentInMyPermittedJob = candoPermittedJobs.includes(jobId);


    const weekAndNotPotential = Object(duplicateResult).isWeakConflict && !Object(duplicateResult).isPotentialStrongConflict;
    if (weekAndNotPotential && !!isOwned.length && !iOwnIt && isPresentInMyPermittedJob) {
      color = colors.gray;
    }

    //coming from popup, while picking chip
    if (!!isOwned.length && !isPresentInMyPermittedJob && weekAndNotPotential) {
      color = colors.gray;
    }

    return { color, isOwned, iOwnIt, hasEngagement };
  };

  getChipToRender = (thisJobIsConflictColorMethod) => (id, name, value, onChange) => {
    const { color, hasEngagement } = thisJobIsConflictColorMethod(id);

    return !!hasEngagement ? <Chip
      key={`${name}-tag-${id}`}
      className="chip"
      backgroundColor={color}
      labelStyle={{
        fontWeight: 300,
        fontSize: 13
      }}
      style={{
        margin: 4,
        display: "inlineBlock"
      }}
      labelColor={colors.white}
    >
      {value}
    </Chip> : <Chip
      key={`${name}-tag-${id}`}
      className="chip"
      backgroundColor={color}
      labelStyle={{
        fontWeight: 300,
        fontSize: 13
      }}
      style={{
        margin: 4,
        display: "inlineBlock"
      }}
      labelColor={colors.white}
      onRequestDelete={!!hasEngagement ? () => {
      } : ev => {
        onChange && onChange();
      }}
    >
      {value}
    </Chip>
  };

  renderChipForOption = (thisJobIsConflictColorMethod) => (model, selected, onClickHandler) => {
    const { color, hasEngagement } = thisJobIsConflictColorMethod(model.id);

    return <Chip
      key={Core.getKey()}
      backgroundColor={color}
      labelColor={
        selected ? "#FFF" : !selected && model.selected ? "#CCC" : "#FFF"
      }
      style={{
        margin: 4,
        display: "inline-block",
        border: selected
          ? "1px solid #536DFF"
          : !selected && model.selected
            ? "1px dotted #7A7A7A"
            : "1px solid #536DF"
      }}
      onClick={(!!hasEngagement && !!model.selected) ? () => {
        alert('Sorry, You cannot delete jobs with Engagements')
      } :
        (!selected && model.selected) ? () => {
          alert('you have already picked this job')
        } : onClickHandler}
    >
      {!!hasEngagement ? `*${model.label}` : (!selected && model.selected) ? `${model.label}` : model.label}
    </Chip>
  };

  render() {

    let { parent, onChange, handlerQuestionsLink } = this.props;
    let roles = parent.roles.map(o => parseInt(o));
    let {
      jobsList, initialJobsPermitted, initialJobsPitched, initialJobsDeclined, isLoading,
      showPermittedJobsDetails, ownedPermittedJobsData
    } = this.state;

    ownedPermittedJobsData = ownedPermittedJobsData || [];
    let data = [
      {
        key: 'jobsPermitted',
        label: 'Jobs that candidate has APPROVED for submission',
        required: true,
        text: '',
        initailJobsLabels: initialJobsPermitted
      },
      {
        key: 'jobsPitched',
        label: 'Jobs pitched but WAITING for candidate’s submission approval',
        required: false,
        text: '',
        initailJobsLabels: initialJobsPitched
      },
      {
        key: 'jobsDeclined',
        label: 'Jobs pitched but candidate not interested',
        required: false,
        text: '',
        initailJobsLabels: initialJobsDeclined
      }
    ];
    return data.map(item => {
      let existing = parent[item.key] || [];        // takes array of jobs from candidate, these ids are used to filter active + existing jobs
      let initialWithoutjobsList = (item.initailJobsLabels.filter(job => !jobsList.map(el => el.id).includes(job.id)));
      let jobsListFiltered = !!jobsList.length ? [...initialWithoutjobsList, ...jobsList] : item.initailJobsLabels;

      if (this.state.showActive) {
        jobsListFiltered = !!jobsList.length ? jobsListFiltered.filter(item => (item.state === 1 || existing.includes(item.id))) : item.initailJobsLabels;
      }

      if (roles.length && !this.state.showAll) {
        jobsListFiltered = !!jobsList.length ? jobsListFiltered.filter(item => (roles.includes(parseInt(item.role)) || existing.includes(item.id))) : item.initailJobsLabels;
      }

      existing = [];                      // to avoid empty circles
      if (!!jobsListFiltered.length) {
        existing = parent[item.key];
        let deleted = parent[item.key].filter(jId => !jobsListFiltered.map(j => j.id).includes(jId));
        if (Core.isAdmin()) {
          jobsListFiltered = [...jobsListFiltered, ...deleted.map(jid => {
            return { id: jid, _name: 'Deleted Job-' + jid }
          })];
        } else {
          jobsListFiltered = [...jobsListFiltered, ...deleted.map(jid => {
            return { id: jid, _name: 'Deleted Job' }
          })];
        }
      }

      return <Row key={item.key}>
        <Col fullWidth>
          <Chips
            name={item.key}
            openWidth={true}
            commonCase={true}
            topRow={this.dialogTopRow()}
            items={jobsListFiltered.map(j => {
              let ownedJobInfo = ownedPermittedJobsData.find(obj => String(obj.jobId) === String(j.id));
              let timestamp = dig(ownedJobInfo, 'permittedModel', 'timestamp');

              let newJobName = (showPermittedJobsDetails && !!ownedJobInfo && !!timestamp) && Core.isAdmin() ?
                `${moment(timestamp).tz("America/Los_Angeles").format('MM/DD/YYYY h:mm')} PST by ${dig(ownedJobInfo, 'permittedModel', 'operatedBy')} ${j._name}` : !!showPermittedJobsDetails ? `No Ownership Data - ${j._name}` : j._name;

              return { id: j.id, label: newJobName, selected: existing.includes(j.id) }
            })}
            chipRenderer={this.getChipToRender(
              this.thisJobIsConflictColorMethod(parent.ownershipResult, parent.duplicateResult, jobsListFiltered, this.props.parent.jobsPermitted)
            )}
            chipOptionRenderer={this.renderChipForOption(this.thisJobIsConflictColorMethod(parent.ownershipResult, parent.duplicateResult, jobsListFiltered, this.props.parent.jobsPermitted)
            )}
            label={
              <fragment>
                <label>
                  {item.label} {item.required ? <b style={{ color: 'orange' }}>*</b> : ''} &nbsp;&nbsp;
                </label>
                {item.key === 'jobsPermitted' &&
                  <span className="anchor f-small" onClick={handlerQuestionsLink} color="primary"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Answer Employer Questions
                  </span>
                }
                {item.key === 'jobsPermitted' &&
                  <Fragment>
                    &nbsp;|&nbsp;
                    <span className="anchor f-small" onClick={this.handlerPermittedDetails} color="primary"
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                      {showPermittedJobsDetails ? 'Hide Details' : 'View Details'}
                    </span>
                  </Fragment>
                }
                {/*{item.key === 'jobsPermitted' &&<Fragment>*/}
                {/*    <p>Please email your account team if need to withdraw your candidate’s application</p>*/}
                {/*<p>Jobs shown in red or gray may have been submitted by another recruiter. You can still continue the submission process. Please follow up with your account team to confirm.</p>*/}
                {/*</Fragment>}*/}

              </fragment>
            }
            sub={item.text}
            values={existing}
            onChange={(result) => {
              let jobObjects = jobsListFiltered;
              onChange(item)(result, jobObjects);
            }}
            onClickAddChipEvent={this.loadJobs}
            isPartialLoadingComp={() => {
              if (isLoading) {
                return <div className="not-found inline-blocks" style={{ textAlign: "center" }}>
                  <label>Loading more jobs might take a few seconds</label><br />
                  <label>Please wait...</label>
                </div>
              }
              return null;
            }}
          />
        </Col>
      </Row>;

    });

  };
}

export default CandidateJobChoices;
