export const TRANSLATIONS_EN_US = {
  nav: {
    candidates: 'Candidates',
    reports: 'Reports'
  },
  candidate: {
    desiredEmploymentTypes: {
      label: 'Desired employment type'
    },
    candidateLocations: {
      label: 'Home location',
      subtitle: 'please select candidate\'s home city (or state).',
      danger: 'looks like you are looking for remote position, please specify your home location. contact admin to add more locations',
      placeholder: 'Type or select city or metro area'
    },
    inOfficeRemoteFlags: {
      label: 'Desired Location Options: select all applicable',
      title: 'Desired Location Options'
    },
    officeLocations: {
      label: 'Desired in office locations',
      disabled: 'Choose full-week or part-week to enable this input',
      subtitle: 'contact admin to add more locations',
      danger: 'looks like you are looking for in office position, please specify your desired office locations. contact admin to add more locations'
    },
    locationDetails: {
      label: 'Additional notes regarding work locations'
    },
    matchExclusions: {
      label: 'Match Exclusions',
      excludeUntil: {
        title: 'exclude until'
      },
      reasons: {
        title: 'reasons'
      },
      note: {
        title: 'note'
      }
    }
  },
  job: {
    desiredEmploymentTypes: {
      label: 'Job Type'
    },
    inOfficeRemoteFlags: {
      label: 'Work Location Options: select all applicable'
    },
    officeLocations: {
      label: 'Office Locations',
      subtitle: 'select city level, contact 10by10 to add cities or locations',
      disabled: 'Choose full-week or part-week to enable this input',
      danger: 'looks like you are looking for in office workers, select city level, contact 10by10 to add cities or locations'
    },
    candidateLocations: {
      label: 'Accept remote workers from locations',
      subtitle: 'contact admin to add more locations',
      danger: 'looks like you are looking for remote workers, select candidate locations. contact admin to add more locations',
      disabled: 'Choose remote to enable this input'
    },
    locationDetails: {
      label: 'Additional Location Notes'
    },
    relocation: {
      label: 'Relocation Assistance'
    },
    relocationExtraInfo: {
      label: 'Relocation Details'
    }
  },
  jobs: {
    copyButton: {
      ariaLabel: {
        label: 'Select 1 or more jobs using the checkboxes on the left and press the COPY JOB(S) button'
      },
      successful: 'Selected job’s descriptions have been copied for you to paste elsewhere!'
    },
    card: {
      locationSection: {
        label: 'Location / Remote:',
        employerHeadQuarterAddress: {
          label: 'HQ Address:'
        },
        inOfficeRemoteFlags: {
          label: 'Remote Options:'
        },
        officeLocations: {
          label: 'All Office Locations:'
        },
        candidateLocations: {
          label: 'Can Accept WFH In Locations:'
        },
        locationDetails: {
          label: 'Additional notes regarding work locations:'
        },
        relocation: {
          label: 'Relocation assistance:'
        },
        relocationExtraInfo: {
          label: 'Relocation details:'
        },
      },
      copyButton: {
        ariaLabel: {
          label: "Copy this job's short summary. For full job description, use 3 dots -> Copy Long JD"
        }
      },
      engagementsSummary: {
        title: 'Engagements Summary'
      }
    }
  },
  employer: {
    process: {
      location: {
        label: 'Location',
        placeholder: 'Type or select a location'
      }
    }
  },
  recruiter: {
    home: {
      todoList: {
        empty: 'Please click on the job icon above and then click on the "Edit My Sourcing" button to let us know what jobs you are working on and receive tips and requirement changes. Please see the hot jobs and ping us if you need guidance on what to source!'
      }
    }
  },
  menuFilter: {
    searchPlaceholder: {
      label: 'Search keywords'
    }
  },
  treeSelect: {
    placeholder: 'Type or click to select a location'
  },
  matchPages: {
    noItemsToShow: {
      label: 'No items to show !!!'
    }
  },
  term: {
    noneSpecified: {
      label: '(none specified)'
    }
  },
  copy: {
    successful: 'Copied: Job short summary copied for you to paste elsewhere. For full job description, use 3 dots -> Copy Long JD',
    hover: 'Foo is bar To copy multiple jobs: select multiple jobs using the check box on the left and press the COPY JOB(S) button at the top right corner'
  },
  emailsList: {
    excludeUntil: 'Exclude automated emails until'
  }
};
