import React, { Component } from "react";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

import Job from "../../../lib/Job";
import "../../Dialogs/Modal.css";

class ModalReminder extends Component {
  constructor() {
    super(...arguments);
    this.state = { open: false };
  }
  open = model => {
    this.model = model;
    this.setState({ open: true });
  };
  submit = id => {
    this.close();
    Job.delete(this.model.job.id, response =>
      this.model.reloadData(this.model.chip)
    );
  };
  close = ev => this.setState({ open: false });
  render() {
    const actions = [
      <FlatButton label="Cancel" primary={true} onClick={this.close} />,
      <FlatButton label="Delete" primary={true} onClick={this.submit} />
    ];
    return (
      <Dialog
        title="Delete Job?"
        actions={actions}
        modal={true}
        contentStyle={{ width: 400 }}
        open={this.state.open}
      >
        This action can't be undone.
      </Dialog>
    );
  }
}

export default ModalReminder;
