import {
  CardHeader,
  CardText,
  Checkbox,
  Divider,
  FlatButton,
  IconButton,
  IconMenu,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableRowColumn,
} from 'material-ui';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import Col from '../../components/Forms/Col';
import Row from '../../components/Forms/Row';
import JobDetails from '../../components/Jobs/Card/JobDetails';
import Candidate from '../Candidate';
import Core from '../Core';
import Definition, {
  EMP_MSG_TYPE__SUBMISSION_ID,
  LOCATION__EMP_HIRING_LOCS_ID,
  RELOCATION__UNKNOWN_ID,
  STATE_ACTIVE,
} from '../Definition';
import FilterControlLib from '../FilterControl';
import { getShortURL, getUnitTestingItemId, newModel, NOT } from '../GenericTools.lib';
import Job from '../Job';
import copyHTML from '../tools/copyHtml';
import formatMoney from '../tools/formatMoney';
import formatURL from '../tools/formatURL';
import { mapStarred } from './mapStarred.tool';
import { mapEmployer } from './employer';
import { mapEngagements } from './engagement';
import { MDASH } from '../Constants';

export const MODEL_NAME_JOB = 'Job';

const mdash = '—';
const model = {
  atsJobId: null,
  jobTitle: '',
  hiringTarget: 1, // default value 1
  visaTransfer: 0,
  salaryMin: 0,
  salaryMax: 0,
  salaryNote: '',
  equityMin: 0,
  equityMax: 0,
  jobDescription: '',
  jobDescriptionPublicURL: '',
  additionalNotes: '',
  candidateMust: '',
  candidateMustNot: '',
  candidatePositives: '',
  candidateNegatives: '',
  candidateMustDetails: {},
  candidateMustNotDetails: {},
  candidatePositivesDetails: {},
  candidateNegativesDetails: {},
  minYearsOfExperience: '',
  idealMinExperience: '',
  idealMaxExperience: '',
  qualificationNotes: '',
  publicNotes: '',
  resumeSubmissionName1: '',
  resumeSubmissionName2: '',
  resumeSubmissionName3: '',
  resumeSubmissionName4: '',
  resumeSubmissionEmail1: '',
  resumeSubmissionEmail2: '',
  resumeSubmissionEmail3: '',
  resumeSubmissionEmail4: '',
  resumeSubmissionEmailAction1: 'to',
  resumeSubmissionEmailAction2: 'cc',
  resumeSubmissionEmailAction3: 'cc',
  resumeSubmissionEmailAction4: 'cc',
  internalSubmissionInstructions: '',
  jobInterviewProcess: '',
  privateNotes: '',
  closeDate: null, // "2017-12-18T20:51:55.157Z",
  flagTagIds: [],
  placementFee: '', // NUMBER
  guarantee: '', // NUMBER
  additionalContractInfo: '',
  searchConfig: null,
  state: 2,
  role: 0,
  roles: [], //new requirement to have multiple roles

  relocation: RELOCATION__UNKNOWN_ID,

  relocationExtraInfo: '',

  examplesOfAccepted: '',
  examplesOfRejected: '',

  /** to clone from employer >>> */

  /** TO DEPRECATE? 2021-06-09 µ */
  addressCity: '',

  /** TO DEPRECATE? 2021-06-09 µ */
  location: 0,

  candoPermittedJobs: [],

  /** <<< */

  /** foreign keys */
  employerId: '',
  resumeSubmissionEmailTemplateId: '',

  /** news */
  technicalSkills: [],
  // newTechSkill: "",
  technicalSkillsDetail: {},
  experience: [],
  positiveSignals: [],
  negativeSignals: [],
  flags: [],
  level: 0,
  calEvTemplates: null,

  mustHaveSkills: [],
  mustHave: {},
  niceToHave: {},
  jobTags: [],

  /** mixin timestamps */
  createdAt: null, // new Date().toISOString(), // "2017-12-18T20:51:55.157Z",
  updatedAt: null, // new Date().toISOString() // "2017-12-18T20:51:55.157Z",

  submissionMethod: 0,
  submissionMethods: [],

  mustHaveQuestions: [],
  putDownJobs: [],

  /* epic-3038-story-3385 | 2021-07-15 Thu µ */
  /* epic-3038(new locations)-story-3652-m2 | 2021-08-03 Tue µ */
  jobType: 0, // old field | jobType(TagId)
  desiredEmploymentTypes: [], // new field | desiredEmploymentType(TagId)

  /* epic-3038-story-3330-m2 | 2021-07-01 Thu µ */
  /* epic-3038-story-3385 | 2021-07-15 Thu µ */
  /* epic-3038-story-3573 | 2021-07-28 Wed µ */
  remote: 0, // old field | remote(TagId)
  inOfficeRemoteFlags: [], // new field | inOfficeRemote(TagId)

  /* epic-3038-story-3385 | 2021-07-15 Thu µ */
  locations: [], // old field | location(TagId)
  officeLocations: [], // new field | location(TagId)

  /* epic-3038-story-3385 | 2021-07-15 Thu µ */
  /* epic-3038-story-3573 | 2021-07-28 Wed µ */
  candidateLocations: [], // new field | location(TagId)

  // epic-3038(new locations)-story-3705-m5 | 2021-08-06 Fri µ
  // epic-3038-story-3330-m4 | 2021-07-01 µ
  remoteExtraInfo: '', // old field
  locationDetails: '', // new field

  // story-3927-m4 | 2021-09-02 Thu µ
  emailsList: [],
};

const extended = {
  ___model___: MODEL_NAME_JOB,
  id: null,
  ...model,
  /** local includes */
  employer: {},
  /** local fills */
  jobBlackList: [],
  jobSourceList: [],
  employers: [],
  engagements: [],
  engaged: [],
  ___keys___: [],
};

/**
 *
 * @param {object} job
 * story-3927-m6 | 2021-09-02 Thu µ
 */
function mapOldEmailsToNewStructure(job) {
  const emails = job.emailsList.map(({ email }) => email);
  if (
    job.resumeSubmissionEmail1 &&
    NOT(emails.includes(job.resumeSubmissionEmail1))
  ) {
    job.emailsList.push({
      email: job.resumeSubmissionEmail1,
      name: job.resumeSubmissionName1 || '',
      to: [],
      cc: [],
      bcc: [],
      [job.resumeSubmissionEmailAction1]: [EMP_MSG_TYPE__SUBMISSION_ID],
    });
  }
  if (
    job.resumeSubmissionEmail2 &&
    NOT(emails.includes(job.resumeSubmissionEmail2))
  ) {
    job.emailsList.push({
      email: job.resumeSubmissionEmail2,
      name: job.resumeSubmissionName2 || '',
      to: [],
      cc: [],
      bcc: [],
      [job.resumeSubmissionEmailAction2]: [EMP_MSG_TYPE__SUBMISSION_ID],
    });
  }
  if (
    job.resumeSubmissionEmail3 &&
    NOT(emails.includes(job.resumeSubmissionEmail3))
  ) {
    job.emailsList.push({
      email: job.resumeSubmissionEmail3,
      name: job.resumeSubmissionName3 || '',
      to: [],
      cc: [],
      bcc: [],
      [job.resumeSubmissionEmailAction3]: [EMP_MSG_TYPE__SUBMISSION_ID],
    });
  }
  if (
    job.resumeSubmissionEmail4 &&
    NOT(emails.includes(job.resumeSubmissionEmail4))
  ) {
    job.emailsList.push({
      email: job.resumeSubmissionEmail4,
      name: job.resumeSubmissionName4 || '',
      to: [],
      cc: [],
      bcc: [],
      [job.resumeSubmissionEmailAction4]: [EMP_MSG_TYPE__SUBMISSION_ID],
    });
  }
}

/**
 * Map job._technicalSkills to
 *
 * job._agnosticTechnicalSkillsJobCard
 *
 * and
 *
 * job._agnosticTechnicalSkillsJobCopy
 *
 * @param {object} job
 *
 * story-3807-m1-2 | 2021-09-07 Tue µ
 */
function mapAgnosticTechnicalSkills(job) {
  let agnostic = false;

  const technicalSkills = job._technicalSkills.split(',').filter((e) => {
    let match = !e.trim().match(/^agnostic$/i);
    if (!match) {
      agnostic = true;
    }
    return match;
  });

  job._agnosticTechnicalSkillsJobCard = agnostic
    ? `${technicalSkills.join(', ')} (Agnostic - can interview in similar tech)`
    : job._technicalSkills;
  job._agnosticTechnicalSkillsJobCopy = agnostic
    ? `${technicalSkills.join(
      ', '
    )} (similar tech acceptable if willing to work on job's tech stack)`
    : job._technicalSkills;
}

const mapJob = (item) => {
  const job = getJobModel({ extended: true });
  if (item) {
    Object.keys(extended).forEach(
      (key) => (!!item[key] || item[key] === 0) && (job[key] = item[key])
    );
    job.id = item.id || item._id;

    mapStarred({ model: job, starredList: item.jobStarreds || [] });

    job.employer = mapEmployer(item.employer);
    job.engagements = mapEngagements(item.engagements);
    job._updatedAt = job.updatedAt
      ? moment(job.updatedAt).format('MM-DD-YYYY')
      : mdash;

    job._jobDescriptionPublicURL = job.jobDescriptionPublicURL ? (
      <a
        href={job.jobDescriptionPublicURL}
        target="_blank"
        rel="noreferrer"
      >
        {getShortURL(job.jobDescriptionPublicURL)}
      </a>
    ) : (
      ''
    )

    // story-3927-m6 | 2021-09-02 Thu µ
    mapOldEmailsToNewStructure(job);

    if (!job.submissionMethods.length) {
      job.submissionMethods = job.employer.submissionMethods;
    }

    if (!!Object(job.mustHave).type) {
      let elements = job.mustHave.args;

      const to_s = {
        complex: (el) => {
          let seps = {
            or: '|',
            and: '+',
            not: '-',
          };

          if (!el.args || !el.args.length) {
            return '';
          }

          let out;

          let mapped = el.args
            .sort(function (x, y) {
              return x === y ? 0 : x ? 1 : -1;
            })
            .map((e) => to_s[e.type](e))
            .filter((el) => !!el)
            .join(seps[el.type]);

          if (el.type === 'not') {
            out = !!mapped ? `${seps[el.type]} ${mapped}` : '';
          } else if (el.atleast > 1) {
            out = `${el.atleast}:${el.args
              .filter((arg) => arg.key === 'technicalSkills')
              .map((e) => to_s[e.type](e))
              .filter((e) => !!e)
              .join(seps[el.type])}`;
          } else {
            out = `${el.args
              .filter((arg) => arg.key === 'technicalSkills')
              .map((e) => to_s[e.type](e))
              .filter((e) => !!e)
              .join(seps[el.type])}`;
          }

          return out;
        },
        chipGroup: (el) => {
          let label = '';
          let defRaw = Definition.getRawDef(el.key);
          let groupEntry =
            !!defRaw && Array.isArray(defRaw.groups)
              ? defRaw.groups.find((group) => group.id === el.value)
              : {};
          if (!!groupEntry.ids) {
            label = Definition.getLabels(el.key, groupEntry.ids).join('|');
          }

          let primaryLabel = el.preferred ? '*' : '';
          return !!label ? `${primaryLabel}${label}` : '';
        },
        chipTag: (el) => {
          return '';
        },
        chip: (el) => {
          let label = '';
          if (el.key === 'technicalSkills') {
            label = Definition.getLabel(el.key, el.value);
          }

          let primaryLabel = el.preferred ? '*' : '';
          return !!label ? `${primaryLabel}${label}` : '';
        },
        text: (el) => {
          return ``;
        },
      };

      try {
        let formatted = elements
          .filter((el) => !!el.type)
          .map((el) => {
            let type = el.type;

            if (['or', 'not', 'and'].includes(el.type)) {
              type = 'complex';
            }

            return to_s[type](el);
          })
          .filter((el) => !!el);

        let separator = job.mustHave.type === 'and' ? '+' : '|';
        job.newTechSkill = formatted
          .map((label) => `(${label})`)
          .join(separator);
      } catch (exception) {
        console.log({ exception });
      }
    }

    job._submissionMethods = job.submissionMethods
      .map((tagId) => Definition.getLabel('employerSubmissionMethod', tagId))
      .join(', ');

    job._relocation =
      job.relocation === 0 ? 'No' : job.relocation === 1 ? 'Yes' : 'Unknown';

    /* prefills from employer */
    job.additionalContractInfo =
      job.additionalContractInfo || job.employer.additionalContractInfo;
    job.placementFee = job.placementFee || job.employer.placementFee;
    job.guarantee = job.guarantee || job.employer.guarantee;

    job._placementFee = job.placementFee ? `$${formatMoney(job.placementFee)}` : MDASH;

    /* Employer HQ Address */
    job._employerHeadQuarterAddress = [
      job.employer.addressStreet,
      job.employer.addressCity,
      job.employer.addressState,
      job.employer.addressCountry,
      job.employer.addressZip,
    ]
      .filter((n) => !!n)
      .join(', ')
      .trim();

    /* mapping stuff */
    job._starred = job.starred ? 'Starred: True' : 'Starred: False';
    job._engagementsLength = Number(job.engagements.length);

    let isActiveLabel = job.state !== STATE_ACTIVE ? ' - Disabled' : '';

    job._name = `${job.employer.name || ''} - ${job.jobTitle || ''} - ${job.addressCity || ''
      }${isActiveLabel}`.trim();
    job._employerName = `${job.employer.name || ''}`.trim();
    job._employerProxyName = `${job.employer.proxyName || ''}`.trim();
    job._employerName = Core.isLimited()
      ? job._employerProxyName
      : job._employerName;
    job._jobTitle = `${job.jobTitle}${job.hiringTarget ? ` (${job.hiringTarget})` : ''
      }`.trim();
    job._employeeCountNum = job.employer.employeeCount;
    job._employeeCount = job.employer.employeeCount
      ? `~${job.employer.employeeCount} employees`
      : mdash;

    job._employeeStage = job.employer._stage;
    job._employeeRating = job.employer._employerHiringDifficulty;
    job._yearsOfExperience = job.minYearsOfExperience
      ? `${job.minYearsOfExperience}+ years`
      : mdash;

    job._minSalary = formatMoney(job.salaryMin, 0);

    job._salaryMax = job.salaryMax ? `$${formatMoney(job.salaryMax, 0)}` : MDASH;

    if (!job.mustHaveQuestions.length) {
      if (Core.isAdmin() && !job.employer) {
        alert(
          'it seems some regression missed the employer data - this will break some functionality - this message is only visible to admin'
        );
      }
      job.mustHaveQuestions = Object(job.employer).mustHaveQuestions || [];
    }

    if (!job.internalSubmissionInstructions) {
      job.internalSubmissionInstructions =
        Object(job.employer).internalSubmissionInstructions || '';
    }

    if (!job.location) {
      job.location = Definition.getId('location', 'Unspecified');
    }

    if (!!job.role && !job.roles.includes(parseInt(job.role))) {
      job.roles = [...job.roles, job.role];
    }

    if (!!job.location && !job.locations.includes(parseInt(job.location))) {
      job.locations = [...job.locations, job.location];
    }
    /* set definition labels */
    /* for fill filter menus and autocomplete */
    Definition.set(job, 'state');
    Definition.set(job, 'level');
    Definition.set(job, 'remote');
    Definition.set(job, 'visa', 'visaTransfer');

    /* epic-3038(new locations)-story-3652-m4 | 2021-08-03 Tue µ */
    Definition.set(job, 'jobType');
    Definition.map(job, 'desiredEmploymentType', 'desiredEmploymentTypes');

    Definition.map(job, 'roles');
    Definition.map(job, 'location', 'locations');
    Definition.map(job, 'technicalSkills');

    // story-3807-m1-2 | 2021-09-07 Tue µ
    mapAgnosticTechnicalSkills(job);

    Definition.map(job, 'experience');
    Definition.map(job, 'positiveSignals');
    Definition.map(job, 'negativeSignals');
    Definition.map(job, 'flags');
    Definition.map(job, 'stage', 'employerStage');

    /* NEW FIELDS 2021-06-14 µ story-3081 >>> */
    Definition.map(job, 'location', 'officeLocations');
    Definition.map(job, 'location', 'candidateLocations');

    /* epic-3038(new locations)-story-3330-m2 - 2021-07-01 µ */
    /* sets job._inOfficeRemoteFlags and job._inOfficeRemoteFlagsKeys */
    Definition.map(job, 'inOfficeRemote', 'inOfficeRemoteFlags');

    // labels without prefix for v3
    job.__inOfficeRemoteFlags = job._inOfficeRemoteFlags;

    /* sets the menu-prefix on each keyword
        contained in job._inOfficeRemoteFlags
        to include them into job.___keys___ */
    job._inOfficeRemoteFlags = FilterControlLib.getItemValues({
      menu: Job.getMenu({ key: 'inOfficeRemoteFlags' }),
      itemLabels: job._inOfficeRemoteFlags,
    });

    // labels without prefix for v3
    job.__officeLocations = job._officeLocations;

    /* NEW FIELDS 2021-06-22 µ story-3081 */
    /* set menu.prefix */
    job._officeLocations = FilterControlLib.getItemValues({
      menu: Job.getMenu({ key: 'officeLocations' }),
      itemLabels: job._officeLocations,
    });

    // labels without prefix for v3
    job.__candidateLocations = job._candidateLocations;

    /* NEW FIELDS 2021-06-22 µ story-3081 */
    /* set menu.prefix */
    job._candidateLocations = FilterControlLib.getItemValues({
      menu: Job.getMenu({ key: 'candidateLocations' }),
      itemLabels: job._candidateLocations,
    });

    /* epic-3038 (new locations) | story-3079-M6 | 2021-06-28 µ */
    if (job.candidateLocations.includes(LOCATION__EMP_HIRING_LOCS_ID)) {
      job._employerHiringLocations = Definition.getLabels(
        'location',
        job.employer?.candidateLocations || []
      ).join(', ');

      // labels without prefix for v3
      job.__employerHiringLocations = job._employerHiringLocations;

      job._employerHiringLocations = FilterControlLib.getItemValues({
        menu: Job.getMenu({ key: 'candidateLocations' }),
        itemLabels: job._employerHiringLocations,
      });
    }

    job._salaryRange = String(job.salaryMin) + '-' + String(job.salaryMax);
    if (job._experience) {
      job._experience = job._experience.replace(/Lead/gi, 'Experience: Lead');
    }
    if (job._state === 'Lead') {
      job._state2 = 'State: Lead';
    }

    job._jobTitleCmp = Core.isAdmin() ? (
      <a href={'/#/job/edit/' + job.id}>
        <b>{job._jobTitle}</b>
      </a>
    ) : (
      job._jobTitle
    );
    job._engagementsInfoCmp = <EngagementsInfo job={job} />;
    job._rowOptionsCmp = <RowOptions job={job} />;
    job.openDetails = (ev) => {
      if (Object(ev).preventDefault) {
        ev.preventDefault();
        ev.stopPropagation();
      }
      Core.openDrawer({
        style: {
          width: 900,
          minWidth: '60vw',
          maxWidth: 'calc(100vw - var(--leftSideWidth))',
        },
        content: (
          <>
            <JobDetails job={job} />
            <IconButton
              style={{ position: 'fixed', top: 0, right: 0, zIndex: 2 }}
              onClick={(ev) => Core.closeDrawer()}
            >
              <i className="material-icons">arrow_forward_ios</i>
            </IconButton>
          </>
        ),
      });
    };
    job.openEngagementsDetails = (ev) => {
      if (Object(ev).preventDefault) {
        ev.preventDefault();
        ev.stopPropagation();
      }
      Core.openDrawer({
        style: {
          width: 900,
          minWidth: '60vw',
          maxWidth: 'calc(100vw - var(--leftSideWidth))',
        },
        content: (
          <>
            {job.getEngagementDetails()}
            <IconButton
              style={{ position: 'fixed', top: 0, right: 0, zIndex: 2 }}
              onClick={(ev) => Core.closeDrawer()}
            >
              <i className="material-icons">arrow_forward_ios</i>
            </IconButton>
          </>
        ),
      });
    };
    job.getEngagementDetails = (em) => <EngagementDetails job={job} />;
    job.delete = (onSuccess) => {
      Core.dialog.open({
        title: <>Delete Job?</>,
        message: "This action can't be undone.",
        style: { width: '320px' },
        actions: [
          <FlatButton
            label="Cancel"
            className="button-white-cyan"
            onClick={(ev) => {
              Core.dialog.close();
            }}
          />,
          <FlatButton
            label="Delete"
            className="button-flat-cyan"
            onClick={(ev) => {
              Core.dialog.close();
              Job.delete(job.id, onSuccess ? onSuccess : function () { });
            }}
          />,
        ],
      });
    };
    job.checkStar = (checked, onSuccess) => {
      Job.updateStarred(job.id, job.starredId, checked, (response) => {
        job.starredId = response.id;
        job.starred = response.starred;
        job.filters = {
          ...job.filters,
          Starred: ['Starred: False', 'Starred: True'][
            ~~Boolean(response.starred)
          ],
        };
        onSuccess && onSuccess(response);
      });
    };
    job.clone = (ev) => {
      const newJobModel = {};
      Object.keys(model).forEach((key) => {
        if (
          typeof job[key] === 'boolean' ||
          key === 'visaTransfer' ||
          !!job[key]
        ) {
          newJobModel[key] = job[key];
        }
      });
      delete newJobModel.id;
      newJobModel.state = Definition.getId('state', 'Draft');
      Core.log({ newJobModel });
      Job.post(newJobModel, (response) => job.goEdit());
    };
    job.getPreview = (em) => Job.getPreview(job);
    job.copy = (ev) =>
      copyHTML(Job.getPreview(job))
        .then((em) => {
          Core.log('Copy email command was successful');
          Core.showMessage('Copied!');
        })
        .catch((ex) => {
          Core.log('Oops, unable to copy');
          Core.showMessage('Fail copy!');
        });
    job.goMatch = (ev) => (document.location.href = '/#/job/match/' + job.id);
    job.goEdit = (ev) => (document.location.href = '/#/job/edit/' + job.id);

    /* For autocomplete AND filtering */
    job.___keys___ = [
      /* Jira Ticket Ticket VER-20: All jobs must match with "Citizen" */
      // "Citizen",
      /* Jira Ticket Ticket VER-20: Always include unspecified, unknow in the search results. */
      // "Visa Support Unknown",
      job.jobTitle,
      job._employerName,
      job._employerProxyName,
      job.addressCity,
      job._locations,
      job._roles,
      job._state2 || job._state,
      job._employeeCountNum,
      job.minYearsOfExperience,

      /* epic-3038(new locations)-story-3652-m4 | 2021-08-03 Tue µ */
      job._jobType,
      job._desiredEmploymentTypes,

      job._level,
      job._remote,
      job._visaTransfer,
      job._technicalSkills,
      job._experience,
      job._positiveSignals,
      job._negativeSignals,
      job._flags,
      job._starred,
      job._engagementsLength,
      job._employeeStage,

      /** NEW FIELDS 2021-06-14 µ story-3081 */
      job._officeLocations,
      job._candidateLocations,

      /* epic-3038 (new locations) | story-3079-M6 | 2021-06-28 µ */
      job._employerHiringLocations,

      /* epic-3038(new locations)-story-3330-M2 - 2021-07-01 µ */
      job._inOfficeRemoteFlags,
    ]
      /* combine and split values */
      .join(',')
      .split(',')
      /* remove empty values */
      .filter((s = '') => !!s.trim())
      .map((s) => s.trim());

    /* epic-3038(new locations)-story-3652-m2,m4 | 2021-08-03 Tue µ */
    if (NOT(job.desiredEmploymentTypes.length)) {
      Definition.get('desiredEmploymentType').forEach((tag) =>
        job.___keys___.push(tag.label)
      );
    }

    /* epic-3038(new locations)-story-3573-m2 | 2021-07-28 Wed µ */
    job.__unitTestingItemId = getUnitTestingItemId(job.jobTitle);

    // job.__moreOptionsActions = candidateMoreMenuOptions;

    // job.__renderExpandedView = renderCandidateExpandedView;

    job.__onClickStar = (checked) =>
      Candidate.updateStarred(job.id, job.starredId, checked).then(
        ({ starred, id }) => {
          job.starredId = id;
          job.starred = starred;
          return starred;
        }
      );

    job.__getCurrentState = () => job;
  }
  return job;
};

const mapJobs = (data) => {
  const results = (data || []).map((item) => {
    const job = mapJob(item);
    return {
      ...job,
      filters: {
        Role: Definition.getLabel('roles', job.role),
        Recent: moment(job.updatedAt),
        Employer: job.employer.name || '—',
        Starred: ['Non Starred', 'Starred'][~~Boolean(job.starred)],
        [`My Sourcing`]: 'My Sourcing',
      },
    };
  });
  return results;
};

/** @todo v2 to deprecate | 2021-09-30 Thu µ */
export class Details extends Component {
  render() {
    const { job } = this.props;
    return (
      <div>
        {!!job.employer.tagline && (
          <div
            ref={(elevatorPitchView) =>
              (this.elevatorPitchView = elevatorPitchView)
            }
          >
            <CardHeader
              title={
                <Fragment>
                  {`${job.employer.name} elevator pitch`.toUpperCase()}
                </Fragment>
              }
              className="card-header"
            />
            <CardText className="contents">
              <div
                dangerouslySetInnerHTML={{
                  __html: job.employer.tagline,
                }}
              />
            </CardText>
          </div>
        )}
        <CardText
          className={
            Core.getUserRole() !== 'LimitedRecruiter'
              ? 'contents'
              : 'contents limited'
          }
        >
          <div
            ref={(detailsView) => (this.detailsView = detailsView)}
            className="details"
          >
            {!!job._role && <span title="Role">{job._role}</span>}
            {!!job._level && <span title="Level">{job._level}</span>}
            {!!job.employer.teamCount ? (
              <span title="Team Count">
                ~{job.employer.teamCount}
                &nbsp;engineers
              </span>
            ) : (
              ''
            )}
            <span title="Salary Range">
              {!!job.salaryMax
                ? `Salary $${job.salaryMin || 0} - $${job.salaryMax}`
                : 'Unknown Salary'}
            </span>
            {!!job.hiringTarget && (
              <span title="Hiring Target">
                Hiring Target:&nbsp;
                {job.hiringTarget}
              </span>
            )}
            {!!job.remote && (
              <span title="Remote">
                Work from home flexibility:&nbsp;
                {Definition.getLabel('remote', job.remote)}
              </span>
            )}
            {!!job.jobType && (
              <span title="Job Type">
                {Definition.getLabel('jobType', job.jobType)}
              </span>
            )}
            {!!job.employer.url && (
              <span title="Company URL">
                <a
                  href={formatURL(job.employer.url)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {job.employer.url}
                </a>
              </span>
            )}
            {!!Definition.getLabel('stage', job.employer.stage) && (
              <span title="Employer Stage e.g. Series A">
                {Definition.getLabel('stage', job.employer.stage)}
                &nbsp;Company
              </span>
            )}
            {!!job.employer.totalFunding && (
              <span title="Employer Founding">
                {!!job.employer.totalFunding
                  ? 'Funding $' + job.employer.totalFunding
                  : 'Unknown Funding'}
              </span>
            )}
            {!!job.employer.foundingYear && (
              <span title="Employer Founded">
                Founded {job.employer.foundingYear}
              </span>
            )}
            {Core.isAdminOrCoordinator() && !!job.employer.proxyName && (
              <span title="Proxy Name">{job.employer.proxyName}</span>
            )}
            {Core.isAdminOrCoordinator() && (
              <span title="State">
                {Definition.getLabel('state', job.state) || <i>&mdash;</i>}
              </span>
            )}
          </div>
        </CardText>
        {!!job.employer.technicalSkills.length && (
          <Fragment>
            <Divider />
            <div className="inline-blocks padding-16">
              <h4 className="normal-weight">EMPLOYER TECH STACK: &nbsp;</h4>
              <span>
                {job.employer.technicalSkills
                  .map((id) => Definition.getLabel('technicalSkills', id))
                  .join(', ')}
              </span>
            </div>
          </Fragment>
        )}
        {!!job.technicalSkills.length && (
          <Fragment>
            <Divider />
            <div className="inline-blocks padding-16">
              <h4 className="normal-weight">ACCEPTABLE TECH: &nbsp;</h4>
              <span>
                {job.technicalSkills
                  .map((id) => Definition.getLabel('technicalSkills', id))
                  .join(', ')}
              </span>
            </div>
          </Fragment>
        )}
        {!!job.additionalNotes && (
          <div
            ref={(additionalNotesView) =>
              (this.additionalNotesView = additionalNotesView)
            }
          >
            <Divider />
            <CardHeader
              title={
                <Fragment>CANDIDATES ABSOLUTELY MUST OR MUST NOT HAVE</Fragment>
              }
            />
            <CardText className="contents">
              <div
                dangerouslySetInnerHTML={{
                  __html: job.additionalNotes,
                }}
              />
            </CardText>
          </div>
        )}
        {!!job.jobDescription && (
          <div
            ref={(jobDescriptionView) =>
              (this.jobDescriptionView = jobDescriptionView)
            }
          >
            <Divider />
            <CardHeader
              title={
                <Fragment>
                  JOB DESCRIPTION&nbsp;
                  {job.jobDescriptionPublicURL ? (
                    <a
                      href={job.jobDescriptionPublicURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Company's job description link
                    </a>
                  ) : (
                    ''
                  )}
                </Fragment>
              }
            />
            <CardText className="contents">
              <div
                dangerouslySetInnerHTML={{
                  __html: job.jobDescription,
                }}
              />
            </CardText>
          </div>
        )}
        {!!job.qualificationNotes && (
          <div
            ref={(qualificationNotesView) =>
              (this.qualificationNotesView = qualificationNotesView)
            }
          >
            <Divider />
            <CardHeader title="INSIDER SCOOP" />
            <CardText className="contents">
              <div
                dangerouslySetInnerHTML={{
                  __html: job.qualificationNotes,
                }}
              />
            </CardText>
          </div>
        )}
        {!!job.employer.additionalInfo && (
          <div
            ref={(hiringPriorityView) =>
              (this.hiringPriorityView = hiringPriorityView)
            }
          >
            <Divider />
            <CardHeader title="HIRING PRIORITY" />
            <CardText className="contents">
              <div
                dangerouslySetInnerHTML={{
                  __html: job.employer.additionalInfo,
                }}
              />
            </CardText>
          </div>
        )}
        {(!!job.jobInterviewProcess || !!job.employer.interviewProcess) && (
          <div
            ref={(interviewProcessView) =>
              (this.interviewProcessView = interviewProcessView)
            }
          >
            <Divider />
            <CardHeader title="INTERVIEW PROCESS" />
            <CardText className="contents">
              {!!job.employer.interviewProcess && (
                <Fragment>
                  General:
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job.employer.interviewProcess,
                    }}
                  />
                  <br />
                </Fragment>
              )}
              {!!job.jobInterviewProcess && (
                <Fragment>
                  Job Specific:
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job.jobInterviewProcess,
                    }}
                  />
                </Fragment>
              )}
            </CardText>
            <Divider />
          </div>
        )}
        {(!!job.publicNotes || !!job.employer.publicNotes) && (
          <div
            ref={(publicNotesView) => (this.publicNotesView = publicNotesView)}
          >
            <Divider />
            <CardHeader title="NOTES" />
            <CardText className="contents">
              {!!job.employer.publicNotes && (
                <Fragment>
                  General:
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job.employer.publicNotes,
                    }}
                  />
                  <br />
                </Fragment>
              )}
              {!!job.publicNotes && (
                <Fragment>
                  Job:
                  <br />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: job.publicNotes,
                    }}
                  />
                </Fragment>
              )}
            </CardText>
          </div>
        )}
        {!!job.employer.product && (
          <div
            ref={(companyOverview) => (this.companyOverview = companyOverview)}
          >
            <Divider />
            <CardHeader title="COMPANY OVERVIEW" />
            <CardText className="contents">
              <div
                dangerouslySetInnerHTML={{
                  __html: job.employer.product,
                }}
              />
            </CardText>
          </div>
        )}
        {!!job.employer.whyUs && (
          <div ref={(whyUsView) => (this.whyUsView = whyUsView)}>
            <Divider />
            <CardHeader title="WHY US" />
            <CardText className="contents">
              <div
                dangerouslySetInnerHTML={{
                  __html: job.employer.whyUs,
                }}
              />
            </CardText>
          </div>
        )}
        {(!!job.employer.crunchbaseUrl ||
          !!job.employer.additionalUrl ||
          !!job.employer.notables ||
          !!job.employer.perks) && (
            <div
              ref={(companyInfoView) => (this.companyInfoView = companyInfoView)}
            >
              <Divider />
              <CardHeader title="COMPANY INFO" />
              <CardText className="contents">
                {(!!job.employer.crunchbaseUrl ||
                  !!job.employer.additionalUrl) && (
                    <Fragment>
                      Links:
                      <br />
                      <br />
                      <div>
                        <a href={formatURL(job.employer.crunchbaseUrl)}>
                          {job.employer.crunchbaseUrl}
                        </a>
                      </div>
                      {job.employer.additionalUrl &&
                        job.employer.additionalUrl.split(/\n/).map((url) => (
                          <div key={Core.getKey()}>
                            <a href={formatURL(url)}>{url}</a>
                          </div>
                        ))}
                      <br />
                    </Fragment>
                  )}
                {!!job.employer.notables && (
                  <Fragment>
                    Notable:
                    <pre>{job.employer.notables}</pre>
                    <br />
                  </Fragment>
                )}
                {!!job.employer.perks && (
                  <Fragment>
                    Perks:
                    <div
                      dangerouslySetInnerHTML={{
                        __html: job.employer.perks,
                      }}
                    />
                  </Fragment>
                )}
              </CardText>
            </div>
          )}
        {Core.isAdminOrCoordinator() && !!job.jobBlackList.length && (
          <Fragment>
            <Divider />
            <CardHeader title="BLACK LIST" />
            <CardText className="contents">
              {job.jobBlackList.map((item) => (
                <li key={item.id}>
                  {item.firstName} {item.lastName}
                </li>
              ))}
            </CardText>
          </Fragment>
        )}
      </div>
    );
  }
}
class EngagementDetails extends Component {
  render() {
    const { job } = this.props;
    return (
      <>
        <div className="sticky-top bg-white" style={{ zIndex: 2 }}>
          <Row className="pb-1">
            <Col fullWidth>
              <label>
                Engagements for job: {job._name} | {job._roles}
              </label>
            </Col>
          </Row>
          <Divider />
        </div>
        {!!job.engagements.length ? (
          <>
            <div className="material-table border-bottom-gray">
              <Table>
                <TableBody displayRowCheckbox={false}>
                  <TableRow selectable={false}>
                    <TableRowColumn>Candidate</TableRowColumn>
                    <TableRowColumn>Last Action</TableRowColumn>
                    <TableRowColumn className="inline-blocks">
                      Stage&nbsp;
                      <i
                        title="Confirmation - need candidate's approval for submission&#013;Submission - 10x10 processing&#013;Review - employer reviewing resume&#013;Screen - phone screen or homework&#013;Onsite - in person interview&#013;Offer - offer discussion in progress&#013;Hire - offer accepted&#013;Guarantee - employment started"
                        className="material-icons"
                      >
                        info
                      </i>
                    </TableRowColumn>
                    <TableRowColumn className="inline-blocks">
                      Status&nbsp;
                      <i
                        title="W - waiting on the entity listed to complete the next action to move the interview forward&#013;E - the interview process was ended by the entity listed"
                        className="material-icons"
                      >
                        info
                      </i>
                    </TableRowColumn>
                    <TableRowColumn>Submitted</TableRowColumn>
                    <TableRowColumn>CV Review</TableRowColumn>
                    <TableRowColumn>Screen</TableRowColumn>
                    <TableRowColumn>Onsite</TableRowColumn>
                    <TableRowColumn>State</TableRowColumn>
                  </TableRow>
                  {job.engagements.map((eng) => (
                    <TableRow
                      key={Core.getKey()}
                      selectable={false}
                      style={{
                        opacity: eng.state === 'Open' ? 1 : 0.4,
                      }}
                      onClick={(ev) => Core.log({ eng })}
                    >
                      <TableRowColumn>
                        <span
                          className="anchor"
                          onClick={(ev) =>
                            Candidate.open('edit', eng.candidateId)
                          }
                        >
                          {eng.candidate._name}
                        </span>
                      </TableRowColumn>
                      <TableRowColumn title={eng.lastAction}>
                        {eng._lastAction}
                      </TableRowColumn>
                      <TableRowColumn>{eng.stage}</TableRowColumn>
                      <TableRowColumn>{eng.status}</TableRowColumn>
                      <TableRowColumn title={eng.submitted}>
                        {eng._submitted}
                      </TableRowColumn>
                      <TableRowColumn title={eng.reviewed}>
                        {eng._reviewed}
                      </TableRowColumn>
                      <TableRowColumn>
                        <div title={`Screen 1: ${eng.screen1}`}>
                          {eng._screen1}
                        </div>
                        <div title={`Screen 2: ${eng.screen2}`}>
                          {eng._screen2}
                        </div>
                        <div title={`Screen 3: ${eng.screen3}`}>
                          {eng._screen3}
                        </div>
                      </TableRowColumn>
                      <TableRowColumn>
                        <div title={`Onsite 1: ${eng.onsite1}`}>
                          {eng._onsite1}
                        </div>
                        <div title={`Onsite 2: ${eng.onsite2}`}>
                          {eng._onsite2}
                        </div>
                      </TableRowColumn>
                      <TableRowColumn>{eng.state}</TableRowColumn>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        ) : (
          <>
            <div className="material-table border-bottom-gray">
              <Table>
                <TableBody displayRowCheckbox={false}>
                  <TableRow selectable={false}>
                    <TableRowColumn>No engagments</TableRowColumn>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </>
        )}
      </>
    );
  }
}
class EngagementsInfo extends Component {
  render() {
    const { job } = this.props;
    const engagements = job.engagements;
    /** * /
    const totalEngagements = engagements.length;
    const activeEngagements = engagements.filter(
      eng =>
        eng.state === "Open" && Engagement.stageOrder.indexOf(eng.stage) >= 3
    ).length;
    const latestStage =
      Engagement.stageOrder[
        Math.max.apply(
          null,
          engagements.map(eng => Engagement.stageOrder.indexOf(eng.stage))
        )
      ];
    const stageCount = engagements.filter(eng => eng.stage === latestStage)
      .length;
    /** */
    const reviewEngagements = engagements.filter(
      (eng) => /open/i.test(eng.state) && /review/i.test(eng.stage)
    ).length;
    const screenEngagements = engagements.filter(
      (eng) => /open/i.test(eng.state) && /screen/i.test(eng.stage)
    ).length;
    const onsiteEngagements = engagements.filter(
      (eng) => /open/i.test(eng.state) && /onsite/i.test(eng.stage)
    ).length;
    const offerEngagements = engagements.filter(
      (eng) => /open/i.test(eng.state) && /offer/i.test(eng.stage)
    ).length;

    const confEngagements = engagements.filter(
      (eng) => /open/i.test(eng.state) && /confirmation/i.test(eng.stage)
    ).length;
    const hireEngagements = engagements.filter((eng) =>
      /hire/i.test(eng.stage)
    ).length;
    const inactiveEngagements = engagements.filter((eng) =>
      /closed/i.test(eng.state)
    ).length;
    /** */
    const submissions = engagements
      .map((eng) =>
        eng.submitted ? moment(eng.submitted).toDate().getTime() : 0
      )
      .filter((time) => !!time);
    const lastSubmissionTime = Math.max.apply(null, submissions);
    const lastSubmissionDate = moment(lastSubmissionTime).toISOString();
    const lastSubmission = submissions.length
      ? moment(lastSubmissionTime).format('M/DD')
      : 0;
    const componentEngagements = (
      <div className="pointer" onClick={(ev) => job.openDetails()}>
        <span
          aria-label={`Last submission: ${lastSubmissionDate}`}
          className="hint--right hulk"
        >
          <b>{lastSubmission || mdash}</b>|
        </span>
        <span aria-label="Open, Review" className="hint--bottom hulk">
          {reviewEngagements}rv|
        </span>
        <span aria-label="Open, Screen" className="hint--bottom hulk">
          {screenEngagements}scr|
        </span>
        <span aria-label="Open, Onsite" className="hint--bottom hulk">
          {onsiteEngagements}os|
        </span>
        <span aria-label="Open, Offer" className="hint--bottom hulk">
          {offerEngagements}ofr|
        </span>
        <span aria-label="Total Hire" className="hint--bottom hulk">
          {hireEngagements}h|
        </span>
        <span aria-label="Closed" className="hint--bottom hulk">
          {inactiveEngagements}e|
        </span>
        <span aria-label="Open, Confirmation" className="hint--bottom hulk">
          {confEngagements}cf
        </span>
      </div>
    );
    return Core.isAdminOrCoordinator() ? componentEngagements : <div />;
  }
}
class RowOptions extends Component {
  render() {
    // Core.log("RowOptions", "render");
    const { job } = this.props;
    return (
      <div className="row-options inline-blocks">
        <Checkbox
          title="Click to Starred"
          className="starred"
          checked={job.starred}
          onCheck={(ev, checked) => {
            job.checkStar(checked, (res) => this.setState({ updated: true }));
          }}
          checkedIcon={<i className="material-icons">star</i>}
          uncheckedIcon={<i className="material-icons">star_border</i>}
          iconStyle={job.openedColor}
        />
        {Core.isAdminOrTrusted() && (
          <IconMenu
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            targetOrigin={{ horizontal: 'right', vertical: 'top' }}
            iconButtonElement={
              <IconButton style={{ width: 24, padding: 0 }}>
                <i className="material-icons">more_vert</i>
              </IconButton>
            }
          >
            {(Core.isAdmin() || (Core.isRecruiter() && Core.isOnDev())) && (
              <MenuItem primaryText="Match" onClick={job.goMatch} />
            )}
            {Core.isAdminOrCoordinator() && (
              <MenuItem primaryText="Clone" onClick={job.clone} />
            )}
            {Core.isAdminOrTrusted() && (
              <MenuItem primaryText="Copy" onClick={job.copy} />
            )}
            {Core.isAdminOnLocal() && !job.engagements.length && (
              <MenuItem
                primaryText="Delete"
                onClick={(ev) =>
                  job.delete((res) => Core.Main && Core.Main.fetchData())
                }
              />
            )}
          </IconMenu>
        )}
        <i
          className="material-icons"
          style={{
            width: 24,
            height: 24,
            margin: 0,
            cursor: 'pointer',
            fontWeight: 200,
            ...job.openedColor,
            ...job.rightArrow,
          }}
          onClick={job.openDetails}
        >
          chevron_right
        </i>
        {job.blacklisted}
      </div>
    );
  }
}

/**
 *
 * @param {object} options Optional
 * @param {boolean} options.extended
 * @returns {object} A new model
 */
function getJobModel({ extended: isExtendedRequired } = {}) {
  return newModel(isExtendedRequired ? extended : model);
}

setTimeout((st) => (extended.accountId = Core.getUserId()));

export {
  extended as default,
  MODEL_NAME_JOB as JOB_MODEL_NAME,
  model,
  extended,
  getJobModel,
  mapJob,
  mapJobs,
};
