import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import { NavLink } from "react-router-dom";

import ContentAdd from "material-ui/svg-icons/content/add";
import FloatingActionButton from "material-ui/FloatingActionButton";
import FlatButton from "material-ui/FlatButton";

import Core, { colors } from "../../lib/Core";
import Account from "../../lib/Account";
import Debug from "../../lib/Debug";
import FilterControl from "../FilterControl/FilterControl";
import List from "../List/ListNoPaging";
import AccountCard from "./Card/AccountCard";
import Store from "../../lib/Store";
import _ from "lodash";
import getJobSourceListApis from "../../lib/tools/getJobSourceListApis";
import SourceListHash from "../../lib/SourceListHash";

let attempt = 0;

class Accounts extends Component {
  data;
  constructor() {
    super(...arguments);
    this.state = {
      showAll: false,
      collectedSourceLists: [],
      accountIdHashedWithSourceList: {}
    };
    Store.set("path", window.location.href);
    this.reloadData = ev => this.loadData();
    this.loadData();
  }

  getRecruiterDetailsThroughAccounts = () => {
    const {allAccounts} = this.state;
    const onlyIds = allAccounts.map(account=>account.id);

    if(Core.isAdmin() && !this.state.collectedSourceLists.length){
      let chunked = _.chunk(onlyIds, 200);
      chunked.forEach((subArray,index)=> {
        getJobSourceListApis.getRecruiterDetailByJobIds('accountId', subArray, (response => {
          this.setState({collectedSourceLists:[...this.state.collectedSourceLists,...response]},()=>{
            this.setAccountIdsHashForSourceList();
          });
        }));
      })
    }
  }

  setAccountIdsHashForSourceList = () => {
    let hash = {};
    hash = SourceListHash(this.state.collectedSourceLists,'accountId');
    console.log({hash});
    this.setState({accountIdHashedWithSourceList: hash}, () => {
      this.List.setItems(this.state.filteredItems);
    });
  }

  loadData() {
    setTimeout(st => {
      if (this.FilterControl) {
        const opts = {
          include: [
            {
              relation: "candidates",
              scope: {
                include: {
                  relation: "engagements",
                  scope: {
                    where: {
                      rejectionReason: {nin:['10x10 - No Match','bad_match']}
                    },
                    fields : ['state','stage','status','id','rejectionReason','submitted']
                  }
                },
                fields : ["id", "engagements", "lastName"]
              }
            }
          ]
        };

        Account.getAll(
          accounts => {
            this.FilterControl &&
            this.FilterControl.setItems(accounts, /^Active$/);
            this.setState({allAccounts:accounts},()=>{
              this.getRecruiterDetailsThroughAccounts();
            });

          }
        ,opts);
      } else {
        if (attempt < 10) {
          attempt++;
          Debug.log({ missingFilterControl: true });
          this.loadData();
        }
      }
    });
  }
  render() {
    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }
    if (Core.isRecruiter()) {
      return <Redirect to="/" />;
    }

    let {accountIdHashedWithSourceList} = this.state;

    return (
      <Fragment>
        <FilterControl
          ref={FilterControl => (this.FilterControl = FilterControl)}
          parent={this}
          menu={Account.menus}
          more={Account.more}
          onChange={filtered => {
            this.List.setItems(filtered);
            this.setState({filteredItems:filtered});
          }}
          toolBarRight={
            Core.isAdmin() && (
              <NavLink exact to="/account/create" className="ui-m-min">
                <FlatButton label="+ New Account" className="list-add-new" />
              </NavLink>
            )
          }
        />
        <List
          ref={List => (this.List = List)}
          tabs={Account.listTabs}
          tab={Account.listTab}
          name="Account"
          card={AccountCard}
          parent={this}
          floatingButton={
            Core.isAdmin() && (
              <NavLink className="ui-m-max" exact to={`/employer/create`}>
                <FloatingActionButton
                  className="list-floating-button"
                  backgroundColor={colors.cyan}
                >
                  <ContentAdd />
                </FloatingActionButton>
              </NavLink>
            )
          }
          extraInfo={
            {
              jobIdHashedWithAccount:accountIdHashedWithSourceList,
            }
          }
        />
      </Fragment>
    );
  }
}
export default Accounts;
