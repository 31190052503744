import {
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import {
  Popover,
  TextField
} from "material-ui";
import React, { Component } from "react";
import { colors } from "../../../lib/Core";
import FlatListMenu from "./FlatListMenu";

class SelectTagDialog extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      search: "",
      menu: {},
      labels: [],
      anchorEl: null,
      open: false
    };
  }
  open = (menu, anchorEl) => {
    this.setState({
      open: true,
      anchorEl,
      labels: Object.keys(menu.items).sort(),
      menu
    });
  };
  close = ev => {
    this.setState({ open: false, search: "" }, then => {
      // used setTimeout to improve UI delay, but this might affect filter results - i tested on my records count and it seems to be fine
      // delay is there because UI waits till we update our arrays and then it re-renders
      // i delegated this processing (happening in FilterControlController's callback) on Event Loop (using setTimeout) and didn't block current UI re-rendering.
      const {
        FilterControlController
      } = this.props;
      setTimeout(() => {
        FilterControlController.onChange();
      });
    });
  }
  render() {
    const {
      state = {},
      props = {}
    } = this;
    const {
      menu = {}
    } = state;
    const {
      /** This could be FilterControl(list pages) comp or NewFilterControl(match pages) comp  */
      FilterControlController = {}
    } = props;
    // console.debug(menu);
    return (
      <Popover
        open={this.state.open}
        anchorEl={this.state.anchorEl}
        animated={true}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        targetOrigin={{ horizontal: "left", vertical: "top" }}
        onRequestClose={this.close}
      >
        <List key={"list"} style={{ maxHeight: "60vh" }}>
          <TextField
            className="list-item-input"
            floatingLabelText="Search keywords"
            required={true}
            floatingLabelStyle={{ color: colors.gray }}
            floatingLabelFocusStyle={{ color: colors.purple, fontSize: 12 }}
            underlineFocusStyle={{ borderColor: colors.purple }}
            type="text"
            value={state.search}
            onChange={(event, search) => this.setState({ search })}
            autoFocus
          />
          {/** CLEAR ALL OPTION */}
          <ListItem
            button
            onClick={ev =>
              this.setState(
                state => {
                  state.labels.forEach(
                    label => (state.menu.items[label] = false)
                  );
                  return state;
                },
                then => FilterControlController.onChange()
              )
            }
          >
            <ListItemText id={'adfadf'} primary={'Clear All'} />
          </ListItem>
          <FlatListMenu
            {...{
              menu,
              FilterControlController,
              SelectTagDialogController: this
            }}
          />
        </List>
      </Popover>
    );
  }
}
export default SelectTagDialog;
