import React, { Component, Fragment } from "react";

import ListItem from '@mui/material/ListItem';
import Checkbox from "material-ui/Checkbox";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";

import Row from "../../Forms/Row";
import Col from "../../Forms/Col";
import Slider from "../../Forms/Slider";

import { colors } from "../../../lib/Core";
import formatMoney from "../../../lib/tools/formatMoney";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

class AllFiltersDialog extends Component {
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      more: [],
      open: false,
      search: ""
    };
  }
  open = (more, selected) => {
    this.setState({ more, open: true });
  };
  close = em => this.setState({ open: false, search: "" });
  render() {
    return (
      <Dialog
        title={"Filter by"}
        onRequestClose={ev => this.parent.onChange()}
        actions={
          <Fragment>
            {!!this.state.more.length &&
              !!this.state.more[0].key && (
                <TextField
                  name="searchFilter"
                  className="input"
                  floatingLabelText="Search keywords"
                  required={true}
                  style={{
                    width: "calc(100% - 16px)",
                    fontSize: 14,
                    margin: "0 8px"
                  }}
                  floatingLabelStyle={{ color: colors.gray }}
                  floatingLabelFocusStyle={{
                    color: colors.purple,
                    fontSize: 12
                  }}
                  underlineFocusStyle={{ borderColor: colors.purple }}
                  type="text"
                  value={this.state.search}
                  onChange={(event, search) => this.setState({ search })}
                  autoFocus
                />
              )}
            <FlatButton label="Cancel" primary={true} onClick={this.close} />
            <FlatButton
              label="Apply"
              primary={true}
              onClick={ev => {
                this.parent.onChange();
                this.close();
              }}
            />
          </Fragment>
        }
        modal={false}
        open={this.state.open}
        autoScrollBodyContent={true}
        contentStyle={{ width: 550 }}
        bodyStyle={{ padding: "0px 20px 20px" }}
      >
        {/Jobs|CandidateMatch/.test(this.parent.parent.name) && (
          <Row style={{ overflow: 'visible' }}>
            <Col style={{ height: 80 }} fullWidth>
              <label>
                Jobs offering up to:&nbsp; ${formatMoney(
                  this.parent.state.minimumSalary,
                  0
                )}
              </label>
              <Slider
                name="minimumSalary"
                min={this.parent.minSalary}
                max={this.parent.maxSalary}
                step={this.parent.sliderStep}
                value={this.parent.state.minimumSalary}
                onChange={(event, minimumSalary) => {
                  this.parent.setState({ minimumSalary });
                }}
              />
            </Col>
          </Row>
        )}
        {/Candidates|JobMatch/.test(this.parent.parent.name) && (
          <Row style={{ overflow: 'visible' }}>
            <Col style={{ height: 80 }} fullWidth>
              <label>
                Can Work for Salary less than:&nbsp; ${formatMoney(
                  this.parent.state.minimumSalary,
                  0
                )}
              </label>
              <Slider
                name="minimumSalary"
                min={this.parent.minSalary}
                max={this.parent.maxSalary}
                step={this.parent.sliderStep}
                value={this.parent.state.minimumSalary}
                onChange={(event, minimumSalary) => {
                  this.parent.setState({ minimumSalary });
                }}
              />
            </Col>
          </Row>
        )}

        {/Candidates|JobMatch|Jobs|CandidateMatch/.test(this.parent.parent.name) && <Row style={{ overflow: 'visible' }}>
          <Col style={{ height: 80 }} fullWidth>
            <label>
              {/Jobs|CandidateMatch/.test(this.parent.parent.name) ? `Jobs requiring <=` : 'Candidate has >='}&nbsp;{this.parent.state.minimumXp}&nbsp;years
            </label>
            <Slider
              name="minimumXp"
              min={this.parent.minXp}
              max={this.parent.maxXp}
              step={this.parent.sliderStepXp}
              value={this.parent.state.minimumXp}
              onChange={(event, minimumXp) => {
                this.parent.setState({ minimumXp });
              }}
            />
          </Col>
        </Row>}

        {/Jobs/.test(this.parent.parent.name) && (
          <Row style={{ overflow: 'visible' }}>
            <Col style={{ height: 80 }} fullWidth>
              <label>
                Company size up to:&nbsp;{this.parent.state.minimumCompanySize}
              </label>
              <Slider
                name="companySize"
                min={this.parent.minCompanySize}
                max={this.parent.maxCompanySize}
                step={this.parent.sliderStepCS}
                value={this.parent.state.minimumCompanySize}
                onChange={(event, minimumCompanySize) => {
                  this.parent.setState({ minimumCompanySize });
                }}
              />
            </Col>
          </Row>
        )}

        {
          (this.state.more

            // epic-3038(new locations)-story-3689-m4 | 2021-08-05 Thu µ
            // related to story-3083-M1-4 
            // - candidate.candidateLocation is needed for filtering, but not it was no requested to be displayed. 
            .filter(menu => !!menu.key && (menu.visible !== false))

            .map(menu => {
              return (
                <Row key={`menu${menu.key}`}>
                  <Col fullWidth>
                    <label>{menu.label}</label>
                    <List key={"list"} style={{ maxHeight: "60vh" }}>
                      {Object.keys(menu.items || {})
                        .sort()
                        .filter(label =>
                          new RegExp(this.state.search, "i").test(label)
                        )
                        .map(label => (
                          <ListItem key={`listItem${label}`} role={undefined} dense button
                            onClick={ev => {
                              this.setState(state => {
                                menu.items[label] = !menu.items[label];
                                return state;
                              });
                            }}
                          >
                            <ListItemIcon style={{ minWidth: 'inherit' }}>
                              <Checkbox
                                edge="start"
                                checked={Object(menu.items)[label]}
                                tabIndex={-1}
                                color="primary"
                                disableRipple
                                inputProps={{ 'aria-labelledby': label }}
                              />
                            </ListItemIcon>
                            <ListItemText id={label} primary={label === "State: Lead" ? "Lead" : label} />
                          </ListItem>
                        ))}
                    </List>
                  </Col>
                </Row>
              );
            })
          )
        }
      </Dialog>
    );
  }
}
export default AllFiltersDialog;
