import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React, { Component, Fragment } from "react";
import { CRAFT_CO_BASE_URL } from "../../../../lib/Constants";
import Core from "../../../../lib/Core";

class EmploymentHistory extends Component {

  cleanCompanyNameForCraft = (empName) => {
    if (empName && typeof empName === 'string') {
      return empName.toLowerCase().replace(' ', '-')
    }

    return '';
  };

  getEmploymentHistory = (candidate) => {
    let list = [];
    if (!!candidate.employmentHistories && candidate.employmentHistories.length !== 0) {
      candidate.employmentHistories.forEach((org) => {
        org.positionHistories.forEach((position) => {
          list.push(
            <p>
              {position.title ? position.title + ", " : 'Role at '}
              <span
                className="anchor"
                onClick={e =>
                  Core.openPopUp(`${CRAFT_CO_BASE_URL}/${this.cleanCompanyNameForCraft(org.employerOrgName)}/metrics`)
                }
              >
                {org.employerOrgName ? org.employerOrgName + ", " : ''}
              </span>
              {!!position.startDateYear ? position.startDateYear + " - " : ''}
              {!!position.endDateYear ? position.endDateYear : ''}</p>
          )
        })
      })
    } else {
      candidate.jobsLinkedIn.forEach((obj, index) => {
        list.push(
          `${obj.title + ", " || ''}${obj.employer || ''}`
        )
      })
    }
    return list;
  }

  render() {
    const { candidate } = this.props;

    return (
      <Fragment>
        <Card className="match-new section-card">
          <CardContent className="match-new card-content">
            <h2 className="match-new section-header">WORK EXPERIENCE</h2>
            <hr />
            {((cando) => {
              let history = this.getEmploymentHistory(cando);
              return history.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <p >{item}
                    </p>
                  </Fragment>
                )
              })
            })(candidate)}
          </CardContent>
        </Card>
      </Fragment>
    )
  }
}

export default EmploymentHistory;