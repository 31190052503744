import { Divider, IconButton, TextField } from "material-ui";
import React, { Component } from "react";
import TagAttributeList from "./TagAttributeList";

class TagBox extends Component {
  constructor() {
    super(...arguments);
    const state = {
      tag: { id: new Date().getTime(), label: "" },
      changes: false,
    };
    if (this.props.tag) {
      state.tag = { ...this.props.tag };
    }
    this.state = state;
  }
  save = (ev) => {
    const {
      onUpdate = () => { },
      onArchived = () => { }
    } = this.props;
    if (ev.tag) {
      const { archived } = ev.tag;
      archived ? onArchived({ tag: ev.tag }) : onUpdate(ev.tag);
    } else if (this.state.changes) {
      const { archived } = this.state.tag;
      if (this.state.tag.label) {
        this.setState({ changes: false }, (em) => {
          archived ? onArchived({ tag: this.state.tag }) : onUpdate(this.state.tag);
        });
      } else {
        this.setState({ changes: false, tag: { ...this.props.tag } }, (em) => {
          archived ? onArchived({ tag: this.state.tag }) : onUpdate(this.state.tag);
        });
      }
    }
    setTimeout((st) => this.props.onBlur && this.props.onBlur(ev));
    console.debug(this.state);
  };
  focus = (em) => {
    this.textField && this.textField.focus();
  };
  render() {
    const {
      isNew
    } = this.props;
    if (/categories/i.test(this.state.tag.key)) {
      return <div />;
    }
    return (
      <>
        {!isNew && <Divider className="m-0" />}
        <div className="hover-bg p-1 w-100">
          <div className="tag-box inline-blocks padding-left">
            {!this.props.isNew && (
              <small className="c-gray mr-1">id: {this.state.tag.id}</small>
            )}
            <div>
              <small className="c-gray mr-1">Label:</small>
              <TextField
                ref={(tf) => (this.textField = tf)}
                name={this.props.name}
                placeholder={this.props.placeholder}
                value={this.state.tag.label}
                onChange={(ev, change) => {
                  this.setState((state) => {
                    state.tag.label = change;
                    state.changes = true;
                    return state;
                  });
                }}
                onBlur={this.save}
                disabled={this.state.tag.archived}
              />
            </div>
            {this.state.tag.archived
              ? (
                <IconButton
                  className=""
                  onClick={(ev) => {
                    const tag = { ...this.state.tag };
                    tag.archived = false;
                    this.save({ tag });
                  }}
                >
                  <i className="material-icons">unarchive</i>
                </IconButton>
              )
              : this.state.changes
                ? (
                  <IconButton className="" onClick={this.save}>
                    <i className="material-icons c-orange">save</i>
                  </IconButton>
                )
                : (
                  <IconButton
                    className=""
                    onClick={(ev) =>
                      this.setState({ disabled: false }, (em) => this.textField.focus())
                    }
                  >
                    <i className="material-icons">mode_edit</i>
                  </IconButton>
                )}
            {!this.props.new && !this.state.tag.archived && (
              <IconButton
                className=""
                onClick={(ev) => {
                  const tag = { ...this.state.tag };
                  tag.archived = true;
                  this.save({ tag });
                }}
              >
                <i className="material-icons">archive</i>
              </IconButton>
            )}
          </div>
          <blockquote>
            <TagAttributeList
              category={this.props.category}
              tag={this.state.tag}
              disabled
              onChange={
                tag => {
                  this.setState((state) => {
                    state.tag = tag;
                    state.changes = true;
                    return state;
                  });
                }
              }
            />
          </blockquote>
        </div>
      </>
    );
  }
}
export default TagBox;
