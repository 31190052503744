import Candidate from "./Candidate";
import Core from "./Core";
import Definition, {
  LOCATION__AMERICAS,
  LOCATION__CALIFORNIA,
  LOCATION__SAN_FRANCISCO_BAY_AREA,
  LOCATION__UNITED_STATES,
  LOC_TYPE__ALIAS_ID,
  LOC_TYPE__CITY_ID,
  LOC_TYPE__COUNTRY_ID,
  LOC_TYPE__METRO_AREA_ID,
  LOC_TYPE__METRO_AREA_SEC_ID,
  LOC_TYPE__MULTI_CONT_ID,
  LOC_TYPE__STATE_ID
} from "./Definition";
import LocationLib from "./DefinitionLocation.lib";
import Engagement from "./Engagement";
import { getShortURL } from "./GenericTools.lib";
import Http from "./Http";
import { mapJob, mapJobs, model as jobModel } from "./models/job";
import Streak from "./Streak";
import cleanHTML from "./tools/cleanHtml";
import formatURL from "./tools/formatURL";
import getStateModel from "./tools/getStateModel";

const cache = {};

const commonQuery = (opts = {}) => {
  let includeArray = [
    {
      relation: "employer",
      scope: {
        include: ["employerBlackList", "employerSourceList"],
      },
    },
    "resumeSubmissionEmailTemplate",
    "jobBlackList",
    "jobSourceList",
    {
      relation: "jobStarreds",
      scope: {
        where: {
          accountId: Core.getUserId(),
        },
      },
    },
  ];

  if (opts.engagements) {
    includeArray.push({
      relation: "engagements",
      scope: {
        where: {
          rejectionReason: { nin: ["10x10 - No Match", "bad_match"] },
        },
        fields: [
          "state",
          "stage",
          "status",
          "id",
          "rejectionReason",
          "submitted",
        ],
      },
    });
  }

  if (typeof opts.include === "boolean" && !opts.include) {
    includeArray = [];
  }
  return { include: includeArray };
};

const menus = [

  // Roles(dj)
  { label: "Roles", key: "roles", field: "_rolesKeys", multiple: true },

  //  { label: "Level", key: "level", field: "_level" },

  // Technology(dj)
  {
    label: "Technology",
    key: "technicalSkills",
    field: "_technicalSkillsKeys",
    multiple: true,
  },

  // Visa(h)
  {
    label: "Visa",
    key: "visa",
    // inputType: "radio",
    field: "_visaTransfer",
    options: {
      Citizen: false,
      "Green Card": false,
      "Needs TN (Canada-Mexico)": false,
      "Needs H1B1/E-3 (Chile-Singapore-Australia)": false,
      "Needs H1B Visa Transfer": false,
      "Needs New Visa Sponsor": false,
      "OPT/CPT/F-1": false,
      "H1-B with I140 Approved": false,
      L1: false,
      "H4 EAD": false,
      "J-1": false,
      "O-1": false,
      "Visa Status Unknown": false,
      "No Sponsorship Required": false,
    },
    mappings: {
      Citizen: [
        "Citizen Only",
        "Citzen and Green Card Only",
        "Will Transfer H1",
        "Will Sponsor New H1",
        "Visa Support Unknown",
      ],
      "Green Card": [
        "Citzen and Green Card Only",
        "Will Transfer H1",
        "Will Sponsor New H1",
        "Visa Support Unknown",
      ],
      "Needs TN (Canada-Mexico)": [
        "Will Transfer H1",
        "Will Sponsor New H1",
        "Visa Support Unknown",
      ],
      "Needs H1B1/E-3 (Chile-Singapore-Australia)": [
        "Will Transfer H1",
        "Will Sponsor New H1",
        "Visa Support Unknown",
      ],
      "Needs H1B Visa Transfer": [
        "Will Transfer H1",
        "Will Sponsor New H1",
        "Visa Support Unknown",
      ],
      "Needs New Visa Sponsor": ["Will Sponsor New H1", "Visa Support Unknown"],
      "OPT/CPT/F-1": [
        "Will Transfer H1",
        "Will Sponsor New H1",
        "Visa Support Unknown",
      ],
      "H1-B with I140 Approved": [
        "Will Transfer H1",
        "Will Sponsor New H1",
        "Visa Support Unknown",
      ],
      L1: ["Will Sponsor New H1", "Visa Support Unknown"],
      "H4 EAD": ["Will Sponsor New H1", "Visa Support Unknown"],
      "J-1": ["Will Sponsor New H1", "Visa Support Unknown"],
      "O-1": ["Will Sponsor New H1", "Visa Support Unknown"],
      "No Sponsorship Required": [
        "Citzen and Green Card Only",
        "Will Transfer H1",
        "Will Sponsor New H1",
        "Visa Support Unknown",
      ],
      "Visa Status Unknown": [
        "Citizen Only",
        "Citzen and Green Card Only",
        "Will Transfer H1",
        "Will Sponsor New H1",
        "Visa Support Unknown",
      ],
    },
  },

  /* epic-3038-story-3083 - 2021-06-17 µ */
  /* epic-3038-story3330-m2 - 2021-07-01 µ */
  // Remote Options(s-mc)
  {
    label: "Remote Options",
    key: "inOfficeRemoteFlags",
    definitionKey: "inOfficeRemote",
    field: "_inOfficeRemoteFlagsKeys",
    prefix: "Remote",
    /* epic-3038-story3330-m5 - 2021-07-02 µ */
    multiple: true,
  },

  /** This is needed for filtering results story-3083 2021-06-17 µ */
  // WFH Locations(s-mc)
  {
    label: "WFH Locations",
    key: "candidateLocations",
    definitionKey: "location",
    field: "_candidateLocationsKeys",
    multiple: true,
    prefix: "WFH",
    listingPages: {
      layers: [
        LOC_TYPE__ALIAS_ID,
        LOC_TYPE__MULTI_CONT_ID,
        LOC_TYPE__COUNTRY_ID,
        LOC_TYPE__STATE_ID,
        LOC_TYPE__METRO_AREA_ID,
        LOC_TYPE__METRO_AREA_SEC_ID,
      ],
      treeDefaultExpandedIds: [
        LOCATION__AMERICAS,
        LOCATION__UNITED_STATES,
        LOCATION__CALIFORNIA,
        LOCATION__SAN_FRANCISCO_BAY_AREA
      ]
    },
    matchingPages: {
      layers: [
        LOC_TYPE__ALIAS_ID,
        LOC_TYPE__MULTI_CONT_ID,
        LOC_TYPE__COUNTRY_ID,
        LOC_TYPE__STATE_ID,
        LOC_TYPE__METRO_AREA_ID,
        LOC_TYPE__METRO_AREA_SEC_ID,
        LOC_TYPE__CITY_ID,
      ],
      treeDefaultExpandedIds: [
        LOCATION__AMERICAS,
        LOCATION__UNITED_STATES,
        LOCATION__CALIFORNIA,
        LOCATION__SAN_FRANCISCO_BAY_AREA,
      ]
    },
  },

  /** This is needed for filtering results story-3083 2021-06-17 µ */
  // Office Locations(s-mc)
  {
    label: "Office Locations",
    key: "officeLocations",
    definitionKey: "location",
    field: "_officeLocationsKeys",
    multiple: true,
    prefix: "Office",
  },

  // Stage(h)
  {
    label: "Stage",
    key: "stage",
    field: "_stage",
    options: {
      "Late Stage Startup": false,
      Public: false,
      Seed: false,
      "Series A": false,
      "Series B": false,
      "Series C": false,
      "Series D+": false,
    },
    mappings: {
      "Late Stage Startup": ["Unknown", "Late Stage Startup"],
      Public: ["Unknown", "Public"],
      Seed: ["Unknown", "Seed"],
      "Series A": ["Unknown", "Series A"],
      "Series B": ["Unknown", "Series B"],
      "Series C": ["Unknown", "Series C"],
      "Series D+": ["Unknown", "Series D+"],
    },
  },
];

let more = Core.isAdminOrCoordinator()
  ? [

    //State(dj)
    { label: "State", key: "state" },

    /* commented by AC request | 2021-07-08 µ */
    // { label: "Work From Home", key: "remote" },

    /* epic-3038(new locations)-story-3083-M1-1 2021-06-17 µ */
    /* epic-3038(new locations)-story-3652-m4 | 2021-08-03 Tue µ */
    /** * /
    // Job Type(dj)
    {
      label: "Job Type",
      key: "jobType",
      field: "_jobType",
      visible: true, // set to false if April no agree to show it.
    },
    /** */

    /* epic-3038(new locations)-story-3652-m4 | 2021-08-03 Tue µ */
    {
      label: "Job Type",
      key: "desiredEmploymentType",
      field: "_desiredEmploymentTypes",
      visible: true, // set to false if April no agree to show it.
      multiple: true,
    }

  ]
  : [

    /* commented by AC request | 2021-07-08 µ */
    // { label: "Work From Home", key: "remote" }

  ];

/** @todo review following commented line. to cleanup? ask to BobB | 2021-08-03 Tue µ */
// more.push({label: "Desired Stage", key: "stage"});

let listTabs = ["My Sourcing", "Role", "Recent", "Employer", "Starred"];

const listTab = "Role";

const Job = {
  name: 'Job',
  menus,
  more,
  listTabs,
  listTab,
  columns: [
    {
      headers: [
        { label: "Employer", key: "_employerName", order: 13, collapsed: true },
        {
          label: "Engagements",
          key: "_engagementsInfoCmp",
          sortKey: "_engagementsLength",
          hint: false,
          reverseSort: true,
          order: 9,
          collapsed: true,
        },
      ],
      selected: 0,
      span: 2,
      style: { minWidth: 256 },
    },
    {
      headers: [
        {
          label: "Job Title",
          key: "_jobTitleCmp",
          sortKey: "jobTitle",
          order: 3,
          collapsed: true,
        },
        {
          label: "Technical Skills",
          key: "_technicalSkills",
          multiple: true,
          order: 4,
          collapsed: true,
        },
      ],
      selected: 0,
      span: 2,
    },
    {
      headers: [
        { label: "Visa", key: "_visaTransfer", order: 7, collapsed: false },
        {
          label: "Years of Experience",
          key: "_yearsOfExperience",
          sortKey: "minYearsOfExperience",
          order: 6,
          collapsed: false,
        },
      ],
      selected: 0,
      span: 2,
    },
    {
      headers: [
        { label: "Role", key: "_role", order: 2, collapsed: false },
        { label: "City", key: "addressCity", order: 9, collapsed: true },
      ],
      selected: 0,
      span: 3,
    },
    {
      headers: [
        {
          label: "Employees",
          key: "_employeeCount",
          sortKey: "_employeeCountNum",
          filter: true,
          collapsed: true,
          order: 11,
        },
        {
          label: "Flags",
          key: "_flags",
          multiple: true,
          order: 14,
          collapsed: true,
        },
      ],
      selected: 0,
      span: 2,
    },
    {
      headers: [
        {
          label: "Starred",
          key: "_rowOptionsCmp",
          sortKey: "_starred",
          reverseSort: true,
          hint: false,
          filter: false,
          collapsed: true,
        },
        {
          label: "State",
          key: "_state",
          visible: false,
          order: 1,
          collapsed: true,
          admin: true,
        },
        {
          label: "Level",
          key: "_level",
          visible: false,
          order: 5,
          collapsed: true,
        },
        {
          label: "Location",
          key: "_location",
          visible: false,
          order: 8,
          collapsed: false,
        },
        {
          label: "Min Salary",
          key: "_minSalary",
          sortKey: "salaryMin",
          visible: false,
          order: 10,
          collapsed: true,
        },
        {
          label: "Company Stage",
          key: "_employeeStage",
          visible: false,
          order: 12,
          collapsed: true,
        },
        {
          label: "Company Rating",
          key: "_employeeRating",
          visible: false,
          order: 15,
          collapsed: true,
        },
      ],
      selected: 0,
      style: { width: 124, textAlign: "right" },
    },
  ],
  cleanCache: (em) =>
    Object.keys(cache).forEach((key) => {
      delete cache[key];
    }),

  getActiveStateIdFromDef: () => {
    return Definition.get("state")
      .filter((obj) => /active/i.test(obj.label))
      .find((o) => o.id);
  },
  getActives: (success, opts = {}) => {
    success = success instanceof Function ? success : function () { };
    if (cache.actives) {
      setTimeout((st) => success(cache.actives));
    } else {
      return Http.get(
        Core.getApi("Jobs"),
        {
          filter: JSON.stringify({
            ...commonQuery(opts.commonQuery),
            where: { state: 1 },
            fields: opts.fields || [],
          }),
        },
        function onSuccess(response) {
          cache.actives = mapJobs(response);
          success(cache.actives);
        }
      );
    }
  },
  getAll: (success, filters = {}, opts = {}) => {
    success = success instanceof Function ? success : function () { };
    const key = JSON.stringify(filters).replace(/\W/g, "");

    if (cache[key]) {
      setTimeout((st) => success(cache[key]));
    } else {
      Http.get(
        Core.getApi("Jobs"),
        {
          filter: JSON.stringify({
            ...filters,
            ...commonQuery(opts.commonQuery),
          }),
        },
        function onSuccess(response) {
          cache[key] = mapJobs(response);
          success(cache[key]);
        }
      );
    }
  },

  getBlackList: (id, success) => {
    success = success instanceof Function ? success : function () { };
    if (Core.isAdminOrCoordinator()) {
      if (cache.blackList) {
        setTimeout((st) => success(cache.blackList));
      } else {
        Http.get(
          Core.getApi(`Jobs/${id}/jobBlackList`),
          function onSuccess(response) {
            cache.blackList = response;
            success(cache.blackList);
          }
        );
      }
    } else {
      success([]);
    }
  },
  getWhere: (where, success, opts = {}) => {
    let commonQueryParams = [];
    if (opts.overRideCommonQuery) {
      commonQueryParams = opts.overRideCommonQuery;
    } else if (!!opts.include) {
      commonQueryParams = {
        include: [...opts.include],
      };
    } else {
      commonQueryParams = commonQuery(opts.commonQuery);
    }

    success = success instanceof Function ? success : function () { };
    const key = JSON.stringify(where).replace(/\W/g, "");
    return Http.get(
      Core.getApi("Jobs"),
      {
        filter: JSON.stringify({
          ...commonQueryParams,
          where: { ...where },
          fields: Object(opts.fields),
        }),
      },
      function onSuccess(response) {
        cache[key] = mapJobs(response);
        success(cache[key]);
      }
    );
  },

  getMlMatchingScoreBulkCandoMatch: (opts, success, failure) => {
    const { params, apiMethod } = opts;
    const { candidateId, jobId } = params;
    // const baseUrl = process.env.REACT_APP_MlApiUrl||'http://localhost:5000';
    const baseUrl =
      process.env.REACT_APP_MlApiUrl || "https://matching-score.go10x10.com";

    let queryParams = `?candidate_id=${candidateId}&apiToken=${Core.getAccessToken()}`;
    let data = {};

    if (apiMethod === "get_candos_scores") {
      queryParams = `?job_id=${jobId}&apiToken=${Core.getAccessToken()}`;
      data = {
        cando_ids: params.candoIds.join(","),
        current_ml_model: params.currentMlModel,
      };
    } else {
      data = {
        job_ids: params.jobIds.join(","),
        current_ml_model: params.currentMlModel,
      };
    }

    let finalUrl = `${baseUrl}/${apiMethod}/${queryParams}`;

    Http.post(
      finalUrl,
      data,
      function onSuccess(response) {
        success(response);
      },
      function onFailure(result) {
        failure(result);
      }
    );
  },

  getMlMatchingScore: (opts, success, failure) => {
    const { jobId, candidateId } = opts.params;
    // const baseUrl = process.env.REACT_APP_MlApiUrl||'http://localhost:5000';
    const baseUrl =
      process.env.REACT_APP_MlApiUrl || "https://matching-score.go10x10.com";

    let queryParams = `?candidate_id=${candidateId}&job_id=${jobId}&apiToken=${Core.getAccessToken()}`;

    success = success instanceof Function ? success : function () { };
    failure = success instanceof Function ? success : function () { };

    // const key = JSON.stringify(where).replace(/\W/g, "");
    let finalUrl = `${baseUrl}${queryParams}`;
    Http.get(
      finalUrl,
      function onSuccess(response) {
        success(response);
      },
      function onFailure(result) {
        failure(result);
      }
    );
  },

  get: (jobId, success) => {
    success = success instanceof Function ? success : function () { };
    if (cache[jobId]) {
      setTimeout((st) => success(cache[jobId]));
    } else {
      Http.get(
        Core.getApi("Jobs/" + jobId),
        {
          filter: JSON.stringify({
            include: [
              {
                relation: "employer",
                scope: {
                  include: ["employerBlackList", "employerSourceList"],
                },
              },
              { engagements: "candidate" },
              "resumeSubmissionEmailTemplate",
              "jobBlackList",
              "jobSourceList",
            ],
          }),
        },
        function onSuccess(response) {
          cache[jobId] = mapJob(response);
          success(cache[jobId]);
        }
      );
    }
  },
  post: (job, success) => {
    Job.cleanCache();
    Engagement.cleanCache();
    Candidate.cleanCache();
    Http.post(Core.getApi("Jobs"), getStateModel(job, jobModel), success);
  },
  update: (jobId, job, success) => {
    Job.cleanCache();
    Engagement.cleanCache();
    Candidate.cleanCache();
    return Http.patch(
      Core.getApi("Jobs/" + jobId),
      getStateModel(job, jobModel),
      (response) => {
        delete cache[jobId];
        success instanceof Function && success(response);
      }
    );
  },
  updateStarred: (jobId, starredId, starred, success) => {
    Job.cleanCache();
    if (starredId) {
      return Http.patch(Core.getApi("JobStarred/" + starredId), { starred }, success);
    } else {
      return Http.post(
        Core.getApi("JobStarred"),
        {
          jobId,
          starred,
          accountId: Core.getUserId(),
        },
        success
      );
    }
  },
  updateLocal: (jobId, job) => {
    cache[jobId] = { ...cache[jobId], ...job };
  },
  delete: (jobId, success) => {
    Job.cleanCache();
    Engagement.cleanCache();
    Candidate.cleanCache();
    // Http.delete(Core.getApi("Jobs/" + jobId), success);
    Engagement.getWhere({ jobId }, (response) => {
      // Core.log({ response });
      Http.delete(Core.getApi("Jobs/" + jobId), success);
      const next = (em) => {
        setTimeout((st) => {
          if (!!response.length) {
            const eng = response.pop();
            // Core.log({ eng });
            Http.delete(Core.getApi("Engagements/" + eng.id), (response) => {
              eng.boxKey && Streak.deleteBox({ boxKey: eng.boxKey });
            });
            next();
          }
        });
      };
      next();
    });
  },
  /**
   * Returns a summary info from job
   * 
   * @param {object} job 
   * @param {string} type large | short
   * @returns {string}
   */
  getPreview: (job, type = "large") => {

    const { employer = {} } = job;

    console.debug('job', job);

    function getLink(link, name) {
      return `<a href="${formatURL(link)}" target="_blank" referrer="noreferrer" class="text-lowercase">${name || link}</a>`;
    }
    function cleanRTFormat(richText) {
      return richText
        .replace(/<p>|<\/p>|<h(1|2|3|4|5|6)>|<\/h(1|2|3|4|5|6)>|&nbsp;/g, ' ')
        .replace(/<strong>/g, '<p>')
        .replace(/<\/strong>/g, '</p>')
        .trim();
    }

    const employerName = employer.name?.trim();
    const employerStreet = employer.addressStreet?.trim();
    const employerCity = employer.addressCity?.trim();
    const employerState = employer.addressState?.trim();
    const employerZip = employer.addressZip?.trim();
    const employerURL = employer.url?.trim();
    const employerStage = Definition.getLabel('stage', employer.stage);
    const additionalLocations = LocationLib.getLocationsString({ locations: job.officeLocations });
    const additionalUrls = [employer.crunchbaseUrl, ...employer.additionalUrl.split(/↵|\n|\r\n|,/)]
      .filter((i) => !!i.trim())
      .map((url) => getLink(url));
    const positionOverview = (
      (type === "short")
        ? !!job.jobDescriptionPublicURL
          ? (`<p><a href="${job.jobDescriptionPublicURL}" target="_blank">Job description link here</a></p>`)
          : ''
        : !!job.jobDescription.trim().length
          ? (
            `<p>POSITION OVERVIEW<br/>${cleanRTFormat(job.jobDescription)}</p>`
          )
          : ''
    );

    const template = cleanHTML(`
      ${job.jobTitle ? `` : ''}
      <p class="text-capitalize f-bold">
        ${employerName}${job.jobTitle.trim() ? ` - ${job.jobTitle.trim()}` : ''}${employerURL ? ` (${getLink(employerURL, getShortURL(employerURL))})` : ''}
      </p>
      <p>
        ${employerStreet ? `${employerStreet}<br/>` : ''}
        ${employerCity ? employerCity : ''}${employerState ? `, ${employerState}` : ''}${employerZip ? `, ${employerZip}` : ''}<br/>
      </p>
      ${additionalLocations ? `<p>Additional locations:<br/>${additionalLocations.replace(/, /g, '<br/>')}</p>` : ''}
      <p>
        SUMMARY
        ${employerStage ? `<br/>Company Stage: ${employerStage} ${employer.totalFunding}` : ''}
        ${employer._technicalSkills ? `<br/>Employer Tech Stack: ${employer._technicalSkills}` : ''}
        ${job._technicalSkills ? `<br/>Acceptable Tech Background: ${job._agnosticTechnicalSkillsJobCopy}` : ''}
        ${employer.employeeCount ? `<br/>Company Size: ~${employer.employeeCount}` : ''}
        ${employer.teamCount ? `<br/>Engineering Team Size: ~${employer.teamCount}` : ''}
      </p>
      ${additionalUrls.length ? `<p>Additional:<br/>${additionalUrls.join('<br/>')}</p>` : ''}
      ${employer.tagline?.trim() ? `<p>QUICK PITCH<br/>${cleanRTFormat(employer.tagline)}</p>` : ''}
      ${employer.product?.trim() ? `<p>COMPANY OVERVIEW<br/>${cleanRTFormat(employer.product)}</p>` : ''}
      ${positionOverview}
    `);

    return template;

  },
  getMenu({ key }) { return menus.find(n => n.key === key); }
};

export default Job;
