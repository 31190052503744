import Drawer from '@mui/material/Drawer';
import {
  AutoComplete, FlatButton, IconButton, MenuItem, SelectField, TextField
} from 'material-ui';
import Checkbox from 'material-ui/Checkbox';
import moment from 'moment-timezone';
import React, { Component, Fragment } from 'react';
import {
  FULLDAY_MILL_SECOND_REFERENCE,
  FULLDAY_SECOND_REFERENCE
} from '../../lib/Constants';
import Core, { colors } from '../../lib/Core';
import { EMP_MSG_TYPE__ALL_ID, EMP_MSG_TYPE__SUBMISSION_ID } from '../../lib/Definition';
import Google from '../../lib/Google';
import Job from '../../lib/Job';
import cleanHTML from '../../lib/tools/cleanHtml';
import Col from '../Forms/Col';
import Row from '../Forms/Row';
import RichTextBox from '../Forms/RichTextBox';

const emailRE =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const templates = {
  phone: {
    label: 'Phone Interview',
    duration: '30,m',
    interviewers: [],
    description:
      'Please be in a quiet space with reliable signal during your call.',
  },
  video: {
    label: 'Video Interview',
    duration: '30,m',
    interviewers: [],
    description:
      'Please be in a quiet space with reliable internet during your interview.',
  },
  technical: {
    label: 'Technical Phone Interview',
    duration: '1,h',
    interviewers: [],
    description:
      'Please be in a quiet space with reliable signal during your call.',
  },
  coding: {
    label: 'Technical Video Interview',
    duration: '1,h',
    interviewers: [],
    description:
      'Please be in a quiet space with reliable internet and ensure you’ve tested that your computer is ready to connect for a video/coding interview.',
  },
  onsite: {
    label: 'Onsite Interview',
    duration: '4,h',
    interviewers: [],
    description:
      'Please arrive/park 15 minutes before your scheduled start time so that you are not rushed when finding the office and checking in.',
  },
  informal: {
    label: 'Informal In-Person Meeting',
    duration: '1,h',
    interviewers: [],
    description:
      'Please arrive/park 15 minutes before your scheduled start time so that you are not rushed when finding the office and checking in.',
  },
};
const eventModel = {
  summary: '',
  location: '',
  description: '',
  start: {
    dateTime: moment().tz('America/Los_Angeles').startOf('day').format(),
    date: moment().tz('America/Los_Angeles').startOf('day').format(),
    timeZone: 'America/Los_Angeles',
  },
  end: {
    dateTime: moment()
      .tz('America/Los_Angeles')
      .startOf('day')
      .add(1, 'h')
      .format(),
    date: moment().tz('America/Los_Angeles').endOf('day').format('YYYY-MM-DD'),
    timeZone: 'America/Los_Angeles',
  },
  attendees: [],
  reminders: {
    useDefault: false,
    overrides: [
      { method: 'email', minutes: 10 },
      { method: 'popup', minutes: 10 },
    ],
  },
  colorId: '5', // "5": { "background": "#fbd75b", "foreground": "#1d1d1d" }
  sendNotifications: false,
};
/*
 *  Calendar events are set to their time in Americas/Los Angeles time zone as the majority of our business
 *  activity is in the San Francisco bay area. When we don't know the time of the event we create an all day
 *  event, e.g. one without a specific time.  The date stored in the database to represent the event will
 *  have the time value set to 00:00:01.300 in the Americas/Los Angeles time zone. The UI uses this value
 *  to hide the time for appointment in the Screen/Onsite column to not confuse real appointments with
 *  known times with those with unknown times.
 */

class CalendarEvent extends Component {
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.templates = Object.assign({}, templates);
    this.state = {
      isFullday: true,
      event: Object.assign({}, eventModel),
      template: Object.assign({}, this.templates['phone']),
      templateKey: 'phone',
      prefix: '',
      sourceInterviewers: [],
      sourceCandidates: [],
      sourceLocations: [],
      candidates: [],
      resumes: [],
      right: false,
    };
    Core.log({ initCalEvState: this.state });
  }
  setEndDateTime = (state) => {
    if (state.isFullday) {
      state.event.end.date = moment(state.event.start.date)
        .endOf('day')
        .format('YYYY-MM-DD');
      Core.log('setEndDateTime', state);
    } else {
      const duration = state.template.duration.split(',');
      state.event.end.dateTime = moment(state.event.start.dateTime)
        .tz(state.event.start.timeZone)
        .add(duration[0], duration[1])
        .format();
      Core.log('setEndDateTime', state);
    }

    return state;
  };
  setTemplate = (key, state) => {
    const engagement = state.engagement;
    state.templateKey = key;
    if (!!engagement.job.calEvTemplates) {
      this.templates = Object.assign({}, engagement.job.calEvTemplates);
      state.template = Object.assign({}, this.templates[key]);
      state.sourceInterviewers = state.template.interviewers.map(
        (interviewer) => interviewer.email
      );
    } else {
      this.templates = Object.assign({}, templates);
      state.template = Object.assign({}, this.templates[key]);

      state.sourceInterviewers = [
        engagement.employer.primaryContactEmail,
        ...engagement.job.emailsList
          .filter(({ to }) => (
            to.includes(EMP_MSG_TYPE__ALL_ID) ||
            to.includes(EMP_MSG_TYPE__SUBMISSION_ID)
          )).map(({ email }) => email),
      ].filter(email => email && emailRE.test(email));

      state.template.interviewers = [
        ...state.template.interviewers,
        ...state.sourceInterviewers.map((email) => ({
          email,
        })),
      ];
    }
    state.event.summary = [
      engagement.candidate._name,
      '-',
      this.templates[key].label,
      '@',
      engagement.employer.name,
      '|',
      engagement.job.jobTitle || '',
    ].join(' ');
    if (/onsite|informal/i.test(key)) {
      state.event.location = engagement.employer._address;
      state.prefix = `
        <p>
          ${[
          'Please call/text 10x10 at',
          Core.getCalendarCoordinatorPhone(),
          'for any last minute change or assistance',
        ].join(' ')}
        </p>
      `;
    } else {
      const phone = engagement.candidate.phone
        .replace(/\D/g, '')
        .replace(/(\.*)(\d{3})(\d{3})(\d{4})$/, '$1 $2-$3-$4');
      if (/phone|technical/i.test(key)) {
        state.event.location = [
          engagement.employer.name,
          'will call <b> ',
          `${engagement.candidate._name}</b> `,
          '@',
          `<strong>${phone}</strong>`,
        ]
          .filter((i) => !!i)
          .join(' ');
      } else {
        state.event.location =
          'Please use: <strong>[REPLACE WITH CONFERNECE URL]</strong>';
      }
      state.prefix = `
        <p>${state.event.location}</p>
        <p><strong>3 minute rule</strong> - IF LATE/NO SHOW for more than 3 minutes,</p>
        <p>If a candidate is late for a video call, please call the candidate directly in case they are having technical difficulties.</p>
        <p>${[
          `If either party is late, please inform 10x10 at `,
          '<a href="tel:',
          Core.getEnvValue('TEAM_PHONE'),
          '">',
          Core.getEnvValue('TEAM_PHONE'),
          '</a> | ',
          `<a href = "mailto: recruiter@10by10.io">recruiter@10by10.io</a>`,
          ' to help you find the other party.',
        ].join('')}</p>
      `;
    }
    state.sourceLocations = [state.event.location]; //[state.event.location].filter(e => e && e.trim());
    const regex = /(<([^>]+)>)/gi;
    state.event.location = state.event.location.replace(regex, '');
    this.setEndDateTime(state);
    this.Description && this.Description.setValue(state.template.description);
    const tests = {
      'Missing template': !this.templates[key],
      'Missing candidate name': !engagement.candidate._name,
      'Missing candidate email': !engagement.candidate.email,
      'Missing candidate phone': !engagement.candidate.phone,
      'Missing job title': !engagement.job.jobTitle,
      'Missing employer name': !engagement.employer.name,
      'Missing employer address': !state.event.location,
      'Missing coordinator phone': !Core.getCalendarCoordinatorPhone(),
    };
    const failTests = [];
    Object.keys(tests).forEach((test) => {
      if (!!tests[test]) {
        failTests.push(<p key={Core.getKey()}>{test}</p>);
      }
    });
    if (failTests.length) {
      Core.showFailure(failTests);
    }
    if (!state.template.interviewers.length) {
      Core.showMessage('Missing interviewers');
    }
    return state;
  };
  toggleDrawer = (side, open) => (em) => {
    this.setState({ [side]: open });
  };

  defaultCalendar = () => {
    return this.state.calendars.items.find(
      (cal) => cal.id === 'recruiter@10by10.io'
    );
  };

  open = ({ engagement, callback }) => {
    if (!engagement || !engagement.id) {
      return Core.showMessage('The Engagement refererence is missed');
    } else if (!engagement.boxKey) {
      return Core.showMessage('The Streak box key is missed');
    } else {
      this.setState(
        (state) => {
          state.right = true;
          state.engagement = engagement;
          // state.event.start.dateTime = moment()
          //   .tz(state.event.start.timeZone)
          //   .startOf("day")
          //   .format();
          state.event.start.timeZone = state.event.end.timeZone =
            'America/Los_Angeles';
          state.sourceResumes = (
            Object(engagement.candidate).resumes || []
          ).map((resume) => resume.url);
          state.resumes = state.sourceResumes.map((resume) => ({
            url: resume,
            filename: resume.split('/').pop(),
          }));
          state.sourceCandidates = [engagement.candidate.email].filter(
            (email) => email && emailRE.test(email)
          );
          state.candidates = state.sourceCandidates.map((email) => ({ email }));
          this.setTemplate(
            /onsite/.test(engagement.stage) ? 'onsite' : 'phone',
            state
          );
          state.callback = callback instanceof Function ? callback : (em) => { };
          return state;
        },
        (then) => {
          Google.listCalendars(
            (calendars) =>
              Google.getColors(
                (colors) => {
                  const colorId =
                    Object.keys(colors.event).find(
                      (id) => colors.event[id].background === '#fbd75b'
                    ) || '5';

                  this.setState(
                    (state) => {
                      state.calendars = calendars;
                      state.event.colorId = colorId;
                      return state;
                    },
                    (then) => Core.log({ CalEvState: this.state })
                  );
                },
                (error) => Core.showFailure(error)
              ),
            (error) => {
              Core.dialog.open({
                message: (
                  <Fragment>
                    Looks like your google account is not connected!
                  </Fragment>
                ),
                actions: [
                  <FlatButton
                    label="Stay"
                    primary={true}
                    onClick={Core.dialog.close}
                  />,
                  <FlatButton
                    label="Go to Settings"
                    primary={true}
                    onClick={(ev) => {
                      Core.dialog.close();
                      document.location.href = '/#/settings';
                    }}
                  />,
                ],
              });
            }
          );
        }
      );
    }
  };
  saveTemplate = (ev) => {
    const job = this.state.engagement.job;
    if (!!job.calEvTemplates) {
      job.calEvTemplates[this.state.templateKey] = this.state.template;
    } else {
      job.calEvTemplates = Object.assign({}, this.templates);
      job.calEvTemplates[this.state.templateKey] = this.state.template;
    }
    Job.update(job.id, { calEvTemplates: job.calEvTemplates }, (response) =>
      Core.showMessage('Template(inteviewers, duration & desc.) saved in Job')
    );
  };
  createCalendarEventOnly = (ev) => {
    const { isFullday } = this.state;

    const body = { ...this.state.event };

    if (isFullday) {
      delete body.start.dateTime;
      delete body.end.dateTime;
      body.start.date = moment(body.start.date).format('YYYY-MM-DD');
      body.end.date = moment(body.end.date).format('YYYY-MM-DD');
    } else {
      delete body.start.date;
      delete body.end.date;
    }

    body.attendees = [];
    body.transparency = 'transparent';
    body.colorRgbFormat = true;
    body.backgroundColor = '#fbd75b';
    body.description = cleanHTML(
      this.state.prefix +
      '<br/>' +
      this.state.template.description +
      '<br/><p>' +
      this.state.resumes
        .map((resume) => `<a href="${resume.url}">${resume.filename}</a>`)
        .join('<br/>') +
      '</p>'
    );
    body.reminders = {
      useDefault: false,
      overrides: [{ method: 'popup', minutes: 10 }],
    };

    Google.insertEvent(
      {
        calendarId: this.defaultCalendar().id,
        sendNotifications: false,
        body,
      },
      (response) => {
        let startDate;

        if (isFullday) {
          startDate = moment
            .tz(this.state.event.start.date, this.state.event.start.timeZone)
            .toDate();
          startDate.setSeconds(
            FULLDAY_SECOND_REFERENCE,
            FULLDAY_MILL_SECOND_REFERENCE
          );
        } else {
          startDate = new Date(this.state.event.start.dateTime);
          startDate.setSeconds(0);
        }

        Core.showMessage('Created Calendar Event');
        this.state.callback(
          moment.tz(startDate, this.state.event.start.timeZone).toDate(),
          isFullday
        );
        this.setState(
          {
            insertEventResult: response,
            right: false,
          },
          (then) => Core.log({ SettingsState: this.state })
        );
      },
      (error) => Core.showFailure(error)
    );
  };
  createCalendarEventAndNotify = (ev) => {
    const { isFullday } = this.state;
    const body = { ...this.state.event };

    if (isFullday) {
      delete body.start.dateTime;
      delete body.end.dateTime;
      body.start.date = moment(body.start.date).format('YYYY-MM-DD');
      body.end.date = moment(body.end.date).format('YYYY-MM-DD');
    } else {
      delete body.start.date;
      delete body.end.date;
    }

    body.colorRgbFormat = true;
    body.backgroundColor = '#fbd75b';
    body.attendees = [
      ...this.state.candidates,
      ...this.state.template.interviewers,
    ];
    body.transparency = 'opaque';
    body.description = cleanHTML(
      this.state.prefix +
      '<br/>' +
      this.state.template.description +
      '<br/><p>' +
      this.state.resumes
        .map((resume) => `<a href="${resume.url}">${resume.filename}</a>`)
        .join('<br/>') +
      '</p>'
    );
    body.reminders = {
      useDefault: false,
      overrides: [{ method: 'popup', minutes: 10 }],
    };

    Google.insertEvent(
      {
        calendarId: this.defaultCalendar().id,
        sendNotifications: true,
        body,
      },
      (response) => {
        let startDate;
        if (isFullday) {
          startDate = moment
            .tz(this.state.event.start.date, this.state.event.start.timeZone)
            .toDate();
          startDate.setSeconds(
            FULLDAY_SECOND_REFERENCE,
            FULLDAY_MILL_SECOND_REFERENCE
          );
        } else {
          startDate = new Date(this.state.event.start.dateTime);
          startDate.setSeconds(0);
        }

        this.state.callback(
          moment.tz(startDate, this.state.event.start.timeZone).toDate(),
          isFullday
        );
        this.setState(
          {
            insertEventResult: response,
            right: false,
          },
          (then) => Core.log({ SettingsState: this.state })
        );
        /** * /
        RESULTS OF TEST
        when it is created the event as busy
        then the owner calendar event is being updated to free
        also it is updated in guests calendar.
        Google.patchEvent({
          calendarId: this.state.calendars.items[0].id,
          eventId: response.id,
          sendNotifications: false,
          body: { transparency: "transparent" }
        });
        /** */
      },
      (error) => Core.showFailure(error)
    );
  };
  setDateOnly = (ev) => {
    const { isFullday } = this.state;
    this.setState({ right: false });

    let startDate;
    if (isFullday) {
      startDate = moment
        .tz(this.state.event.start.date, this.state.event.start.timeZone)
        .toDate();
      startDate.setSeconds(
        FULLDAY_SECOND_REFERENCE,
        FULLDAY_MILL_SECOND_REFERENCE
      );
    } else {
      startDate = new Date(this.state.event.start.dateTime);
      startDate.setSeconds(0);
    }

    this.state.callback(
      moment.tz(startDate, this.state.event.start.timeZone).toDate(),
      isFullday
    );
  };
  render() {
    const { isFullday } = this.state;

    return (
      <Drawer
        anchor="right"
        open={this.state.right}
        onClose={this.toggleDrawer('right', false)}
      >
        <div className="scroll-y-visible" style={{ width: '375px' }}>
          <Row className="type">
            <Col fullWidth>
              <label>Interview Type</label>
              <SelectField
                underlineFocusStyle={{ borderColor: colors.purple }}
                value={this.state.templateKey}
                onChange={(ev, i, key) =>
                  this.setState(
                    (state) => this.setTemplate(key, state),
                    (then) => Core.log({ CalEvState: this.state })
                  )
                }
                fullWidth
              >
                {Object.keys(this.templates).map((key) => (
                  <MenuItem
                    key={key}
                    value={key}
                    primaryText={this.templates[key].label}
                  />
                ))}
              </SelectField>
            </Col>
          </Row>
          <Row className="summary">
            <Col fullWidth>
              <label>Summary</label>
              <AutoComplete
                name="summary"
                placeholder="Add title"
                underlineFocusStyle={{ borderColor: colors.purple }}
                filter={AutoComplete.fuzzyFilter}
                dataSource={['title test']}
                listStyle={{ width: 'auto' }}
                maxSearchResults={7}
                searchText={this.state.event.summary}
                multiLine={true}
                rowsMax={2}
                onUpdateInput={(summary) =>
                  this.setState((state) => {
                    state.event.summary = summary;
                    return state;
                  })
                }
                onNewRequest={(summary) => {
                  if (!!this.state.event.summary.trim().length) {
                    // do something
                  }
                }}
                fullWidth
              />
            </Col>
          </Row>
          <div className="date">
            <Row>
              <Col fullWidth>
                <Checkbox
                  label={'Add interview time?'}
                  checked={!isFullday}
                  onCheck={(ev, hasInterviewTime) => {
                    this.setState(
                      (state) => {
                        state.isFullday = !hasInterviewTime;
                        // state.event.end.dateTime= null;
                        // if(state.isFullday){
                        //   const date = moment(state.event.start.dateTime).tz(
                        //     state.event.start.timeZone
                        //   ).startOf("day").format('YYYY-MM-DD');
                        //
                        //   state.event.end.date = date;
                        //   state.event.start.date = date;
                        //   state.event.start.dateTime = null;
                        //   state.event.end.dateTime = null;
                        // }else{
                        //   state.event.end.date = null;
                        //   state.event.start.date = null;
                        //   state.event.start.dateTime = moment()
                        //     .tz("America/Los_Angeles")
                        //     .startOf("day")
                        //     .format()
                        //   this.setEndDateTime(state);
                        // }

                        return state;
                      },
                      (then) => console.log({ Event: this.state.event })
                    );
                  }}
                />
              </Col>
            </Row>
            <Row className="dateTime">
              <Col>
                <label>Date </label>
                {isFullday ? (
                  <TextField
                    name="date"
                    key={'date-local'}
                    id="date-local"
                    underlineFocusStyle={{ borderColor: colors.purple }}
                    type={'date'}
                    className="card-picker-date-tf"
                    style={{ display: 'block', maxWidth: 200 }}
                    defaultValue={moment(this.state.event.start.date).format(
                      'YYYY-MM-DD'
                    )}
                    onChange={(ev) => {
                      const date = ev.target.value;

                      if (date) {
                        this.setState(
                          (state) => {
                            state.event.start.date = moment
                              .tz(date, this.state.event.start.timeZone)
                              .toDate();
                            state.event.start.dateTime = state.event.start.date;
                            this.setEndDateTime(state);
                            return state;
                          },
                          (then) => console.log({ Event: this.state.event })
                        );
                      }
                    }}
                  />
                ) : (
                  <TextField
                    name="date"
                    key={'datetime-local'}
                    id="datetime-local"
                    underlineFocusStyle={{ borderColor: colors.purple }}
                    type={'datetime-local'}
                    className=""
                    style={{ display: 'block', maxWidth: 200 }}
                    defaultValue={moment(this.state.event.start.dateTime)
                      .tz(this.state.event.end.timeZone)
                      .format('YYYY-MM-DDTHH:mm')}
                    onChange={(ev) => {
                      const date = ev.target.value;
                      if (date) {
                        this.setState(
                          (state) => {
                            state.event.start.dateTime = moment(date)
                              .tz(state.event.start.timeZone)
                              .format();
                            this.setEndDateTime(state);
                            return state;
                          },
                          (then) => console.log({ Event: this.state.event })
                        );
                      }
                    }}
                  />
                )}
              </Col>
              <Col style={{ display: 'block', maxWidth: 160 }}>
                <label>Duration</label>
                <SelectField
                  underlineFocusStyle={{ borderColor: colors.purple }}
                  value={this.state.template.duration}
                  onChange={(ev, i, duration) =>
                    this.setState(
                      (state) => {
                        state.template.duration = duration;
                        this.setEndDateTime(state);
                        return state;
                      },
                      (then) => console.log({ Event: this.state })
                    )
                  }
                  fullWidth
                >
                  <MenuItem value={'15,m'} primaryText="15 minutes" />
                  <MenuItem value={'30,m'} primaryText="30 minutes" />
                  <MenuItem value={'45,m'} primaryText="45 minutes" />
                  <MenuItem value={'1,h'} primaryText="1 hour" />
                  <MenuItem value={'90,m'} primaryText="1½ hour" />
                  <MenuItem value={'2,h'} primaryText="2 hours" />
                  <MenuItem value={'3,h'} primaryText="3 hours" />
                  <MenuItem value={'4,h'} primaryText="4 hours" />
                  <MenuItem value={'5,h'} primaryText="5 hours" />
                  <MenuItem value={'6,h'} primaryText="6 hours" />
                  <MenuItem value={'7,h'} primaryText="7 hours" />
                  <MenuItem value={'8,h'} primaryText="8 hours" />
                </SelectField>
              </Col>
            </Row>
            <Row className="timezone">
              <Col fullWidth>
                <label>Timezone</label>
                <AutoComplete
                  name="timezone"
                  placeholder={this.state.event.end.timeZone}
                  underlineFocusStyle={{ borderColor: colors.purple }}
                  filter={AutoComplete.fuzzyFilter}
                  dataSource={[this.state.event.end.timeZone]}
                  listStyle={{ width: 'auto' }}
                  maxSearchResults={7}
                  searchText={this.state.event.start.timeZone}
                  onUpdateInput={(timeZone) =>
                    this.setState(
                      (state) => {
                        state.event.start.timeZone = timeZone;
                        state.event.end.timeZone = timeZone;
                        return state;
                      },
                      (then) => console.log({ Event: this.state.event })
                    )
                  }
                  onNewRequest={(timeZone) => {
                    if (!!this.state.event.start.timeZone.trim().length) {
                    }
                  }}
                  fullWidth
                  disabled
                />
              </Col>
            </Row>
          </div>
          <Row className="location">
            <Col fullWidth>
              <label>Location</label>
              <AutoComplete
                name="location"
                placeholder="Add location"
                underlineFocusStyle={{ borderColor: colors.purple }}
                filter={AutoComplete.fuzzyFilter}
                dataSource={this.state.sourceLocations}
                listStyle={{ width: 'auto' }}
                maxSearchResults={7}
                searchText={this.state.event.location}
                onUpdateInput={(location) =>
                  this.setState(
                    (state) => {
                      state.event.location = location;
                      return state;
                    },
                    (then) => console.log({ Event: this.state.event })
                  )
                }
                onNewRequest={(location) => {
                  if (!!this.state.event.location.trim().length) {
                    // do something
                  }
                }}
                fullWidth
              />
            </Col>
          </Row>
          <Row className="interviewers">
            <Col fullWidth>
              <label>Interviewers</label>
              <AutoComplete
                name="interviewer"
                placeholder="Add interviewer email"
                underlineFocusStyle={{ borderColor: colors.purple }}
                filter={AutoComplete.fuzzyFilter}
                dataSource={this.state.sourceInterviewers}
                listStyle={{ width: 'auto' }}
                maxSearchResults={7}
                searchText={this.state.newInterviewer}
                onUpdateInput={(newInterviewer) =>
                  this.setState({ newInterviewer })
                }
                onBlur={(ev) => {
                  const newInterviewer = String(ev.target.value).trim();
                  if (!!newInterviewer) {
                    if (emailRE.test(newInterviewer)) {
                      this.setState(
                        (state) => {
                          state.template.interviewers.push({
                            email: state.newInterviewer,
                          });
                          state.newInterviewer = '';
                          return state;
                        },
                        (then) => Core.log({ CalEvState: this.state })
                      );
                    } else {
                      Core.showMessage(`Invalid email: ${newInterviewer}`);
                    }
                  }
                }}
                onNewRequest={(newInterviewer) => {
                  if (!!this.state.newInterviewer.trim().length) {
                    this.setState(
                      (state) => {
                        state.template.interviewers.push({
                          email: state.newInterviewer,
                        });
                        state.newInterviewer = '';
                        return state;
                      },
                      (then) => Core.log({ CalEvState: this.state })
                    );
                  }
                }}
                fullWidth
              />
              {this.state.template.interviewers.map((attendee, index) => (
                <div key={Core.getKey()} className="inline-blocks">
                  <span>{attendee.email}</span>
                  <IconButton
                    className="icon24"
                    onClick={(ev) => {
                      this.setState((state) => {
                        state.template.interviewers.splice(index, 1);
                        return state;
                      });
                    }}
                  >
                    <i className="material-icons">close</i>
                  </IconButton>
                </div>
              ))}
            </Col>
          </Row>
          <Row className="candidate">
            <Col fullWidth>
              <label>Candidate</label>
              <AutoComplete
                name="candidate"
                placeholder="Add candidate email"
                underlineFocusStyle={{ borderColor: colors.purple }}
                filter={AutoComplete.fuzzyFilter}
                dataSource={this.state.sourceCandidates}
                listStyle={{ width: 'auto' }}
                maxSearchResults={7}
                searchText={this.state.newCandidate}
                onUpdateInput={(newCandidate) =>
                  this.setState({ newCandidate })
                }
                onBlur={(ev) => {
                  const newCandidate = String(ev.target.value).trim();
                  if (!!newCandidate) {
                    if (emailRE.test(newCandidate)) {
                      this.setState(
                        (state) => {
                          state.candidates.push({ email: state.newCandidate });
                          state.newCandidate = '';
                          return state;
                        },
                        (then) => Core.log({ CalEvState: this.state })
                      );
                    } else {
                      Core.showMessage(`Invalid email: ${newCandidate}`);
                    }
                  }
                }}
                onNewRequest={(newCandidate) => {
                  if (!!this.state.newCandidate.trim().length) {
                    this.setState(
                      (state) => {
                        state.candidates.push({ email: state.newCandidate });
                        state.newCandidate = '';
                        return state;
                      },
                      (then) => Core.log({ CalEvState: this.state })
                    );
                  }
                }}
                fullWidth
              />
              {this.state.candidates.map((attendee, index) => (
                <div key={Core.getKey()} className="inline-blocks">
                  <span>{attendee.email}</span>
                  <IconButton
                    className="icon24"
                    onClick={(ev) => {
                      this.setState((state) => {
                        state.candidates.splice(index, 1);
                        return state;
                      });
                    }}
                  >
                    <i className="material-icons">close</i>
                  </IconButton>
                </div>
              ))}
            </Col>
          </Row>
          {!!this.state.resumes.length && (
            <Row className="resumes">
              <Col fullWidth>
                <label>Resumes</label>
                {this.state.resumes.map((resume, index) => (
                  <div key={Core.getKey()} className="inline-blocks">
                    <span
                      className="anchor"
                      onClick={(ev) => Core.openPopUp(resume.url)}
                    >
                      {resume.filename}
                    </span>
                    <IconButton
                      className="icon24"
                      onClick={(ev) => {
                        this.setState((state) => {
                          state.resumes.splice(index, 1);
                          return state;
                        });
                      }}
                    >
                      <i className="material-icons">close</i>
                    </IconButton>
                  </div>
                ))}
              </Col>
            </Row>
          )}
          <div className="description">
            <Row>
              <Col fullWidth>
                <label>Description</label>
                <div
                  className="cgray"
                  dangerouslySetInnerHTML={{ __html: this.state.prefix }}
                />
                <RichTextBox
                  name="description"
                  ref={(self) => (this.Description = self)}
                  value={this.state.template.description}
                  onChange={description =>
                    this.setState(
                      (state) => {
                        state.template.description = description;
                        return state;
                      },
                      (then) => Core.log({ CalEvState: this.state })
                    )
                  }
                />
              </Col>
            </Row>
          </div>
          <Row className="actions">
            <Col fullWidth>
              <FlatButton
                label="Save template"
                className="button-white-cyan"
                onClick={this.saveTemplate}
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              {!!this.state.calendars &&
                !!this.state.calendars.items &&
                !!this.state.calendars.items.length && (
                  <FlatButton
                    label="Create calendar event only"
                    className="button-cyan"
                    onClick={this.createCalendarEventOnly}
                    fullWidth
                  />
                )}
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              {!!this.state.calendars &&
                !!this.state.calendars.items &&
                !!this.state.calendars.items.length && (
                  <FlatButton
                    label="Create calendar event and send emails"
                    className="button-white-cyan"
                    onClick={this.createCalendarEventAndNotify}
                    fullWidth
                  />
                )}
            </Col>
          </Row>
          <Row>
            <Col fullWidth className="" />
          </Row>
          <Row>
            <Col fullWidth className="">
              <FlatButton
                label="Set time only"
                className="button-white-cyan"
                onClick={this.setDateOnly}
                fullWidth
              />
            </Col>
          </Row>
        </div>
      </Drawer>
    );
  }
}
export default CalendarEvent;
