import MenuItem from "material-ui/MenuItem";
import React, { Component } from "react";
import Definition from "../../lib/Definition";

class ResumeMatch extends Component {

  allTechnicalSkills = Object.values(Definition.getAllLabels("technicalSkills")).join(',');

  processSkills = (skills, text, attributes = {}, specialCharacters = []) => {
    let regex = "";
    const specialSkills = ["c++", "C++", "c#", "C#", ".net", ".Net"];
    if (skills.length > 0 && text) {
      skills.filter(fval => !!fval.length).forEach(val => {
        if (specialSkills.includes(val)) {
          text = text.replace(val, "<span style='border-bottom: 3px solid " + attributes.color + "'>" + val + "</span>");
        } else if (val.length === 1) {
          val = this.escapeRegExp(val);
          regex = new RegExp(`^${val}(?!\\+#)$`, 'gi');
          text = text.replace(regex, "<span style='border-bottom: 3px solid " + attributes.color + "'>" + val + "</span>");
        } else {
          val = this.escapeRegExp(val);
          regex = new RegExp(`\\b${val}\\b|^${val}\\b|^${val},\\w`, 'gi');
          text = text.replace(regex, "<span style='border-bottom: 3px solid " + attributes.color + "'>" + val + "</span>");
        }
      })
    }

    return text;
  }

  escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  addToNewWindow = (resumeMatchedText) => {
    const oNewWin = window.open("about:blank", "newwindow", "height=550,width=900,top=10,left=10,resizable=yes");

    if (!oNewWin) {
      alert('browser blocking popup, please allow and then retry, thanks');
      return;
    }

    oNewWin.open();
    oNewWin.document.write("<html><head><title>New Window</title></head>");

    oNewWin.document.write("<body>" + resumeMatchedText.replace(/\n/g, "<br />") + "</body></html>");
    oNewWin.document.close();
  }


  matchResume = () => {
    const { showOnDrawer, renderTextCV } = this.props;

    const { candidateResume, jobSkills, employerSkills } = this.props;

    const jobSkillsValue = typeof jobSkills === "string"
      ? jobSkills
        .split(',')
        .map(el => el.trim())
      : [];

    const allTechnicalSkills = typeof this.allTechnicalSkills === "string"
      ? this.allTechnicalSkills
        .split(',')
        .map(el => el.trim())
        .filter(el => !jobSkillsValue.includes(el))
      : [];


    const employerSkillsValue = typeof employerSkills === "string"
      ? employerSkills
        .split(',')
        .map(el => el.trim())
        .filter(el => ![...jobSkillsValue, ...allTechnicalSkills].includes(el))
      : [];

    const specialCharacters = ['+', '#'];

    fetch(candidateResume).then(responseText => {
      responseText.text().then(text => {
        text = this.processSkills(jobSkillsValue, text, { color: 'lawngreen' }, specialCharacters);
        text = this.processSkills(employerSkillsValue, text, { color: 'yellow' }, specialCharacters);
        text = this.processSkills(allTechnicalSkills, text, { color: 'gray' }, specialCharacters);

        if (!showOnDrawer) {
          this.addToNewWindow(text);
        } else {
          text = text.replace(/\n/g, "<br />");
          renderTextCV(text);
        }
      });
    });
  }

  render() {
    const { element, showOnDrawer, onClick = (method = () => { }) => event => method(event) } = this.props;
    if (showOnDrawer) {
      this.matchResume();
    }
    return (
      !!element ? element(this.matchResume) : <MenuItem
        primaryText="View Text Resume"
        onClick={onClick(this.matchResume)}
      />
    );
  }
}

export default ResumeMatch;