export default function getEmailModel() {
  const emailModel = {
    "name": "",
    "email": "",
    "to": [], // {number[]} <entity>MessageTypeTagId
    "cc": [], // {number[]} <entity>MessageTypeTagId
    "bcc": [] // {number[]} <entity>MessageTypeTagId
  }
  return emailModel;
}
