import React, { Component, Fragment } from 'react';
import Row from "../../Forms/Row";
import Col from "../../Forms/Col";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Definition from "../../../lib/Definition";
import Core from "../../../lib/Core";
import _ from 'lodash';
import School from '../../../lib/School';
import AlternativePopup from "./AlternativePopup";
import { findSchoolEducationTag } from "../../../lib/tools/schoolGetLabels";
import EducationDegree from "./EducationDegree";
import InputArray from "../../Shared/InputArray";
import dig from 'object-dig';
import Tooltip from "@mui/material/Tooltip";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import CreateIcon from "@mui/icons-material/Create";
import { isEmpty } from 'lodash'
import TenAutoSuggest from "../../Shared/TenAutoSuggest";

class Education extends Component {
  constructor() {
    super(...arguments);
    this.state = this.initialize();
    if (this.props.isNewRecord) {
      this.props.onDone(this.getUpdatedStructure());
    }
  }

  initialize() {
    let state = {};
    state = this.normalizeModel(this.props.element);
    state.positiveSignalsTags = state.positiveSignalsTags || [];
    state.negativeSignalsTags = state.negativeSignalsTags || [];
    state.tmpPositiveSignalsTags = [];
    state.isRankSchool = false;
    state.tmpNegativeSignalsTags = [];
    state.companies = [];
    state.unicornCompanies = [];
    state.yCombCompanies = [];
    state.alternativeNamesPopup = false;

    return state;
  }

  normalizeModel = (model) => {
    return {
      id: model.id,
      schoolName: model.schoolName || "",
      schoolType: model.schoolType,
      degrees: model.degrees || [],
      positiveSignalsTags: model.positiveSignalsTags,
      negativeSignalsTags: model.negativeSignalsTags
    }
  };

  componentDidMount() {
    if (!!this.state.schoolName) {
      this.findMyEducationTag();
    }
    if (!!this.state.schoolName) {
      this.getSchoolInfo();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (Array.isArray(this.props.element.positiveSignalsTags) && !_.isEqual(this.state.positiveSignalsTags, this.props.element.positiveSignalsTags)) {
      let positiveSignalsTags = this.props.element.positiveSignalsTags || [];
      this.setState({ positiveSignalsTags });
    }

    if (Array.isArray(this.props.element.negativeSignalsTags) && !_.isEqual(this.state.negativeSignalsTags, this.props.element.negativeSignalsTags)) {
      let negativeSignalsTags = this.props.element.negativeSignalsTags || [];
      this.setState({ negativeSignalsTags });
    }

  }

  getSchoolInfo = (value, callback) => {
    let { schoolName } = this.state;
    if (!!value) {
      schoolName = value;
    }

    schoolName = String(schoolName).trim();

    School.get((response) => {
      this.setState({ ranking: response, shouldShowCompanyBgColor: true }, () => {
        this.findMyEducationTag();
        !!callback && callback(response);
      });
    },
      {
        include: {
          relation: 'schoolRating',
          scope: {
            fields: ['degree', 'rating', 'positiveSignalsTags', 'negativeSignalsTags', 'signals'],
            scope: {
              where: { degree: Definition.getId("positiveSignals", 'Computer degree') }
            }
          }
        },
        where: {
          or: [
            { universityName: { like: schoolName, options: 'i' } },
            { alternativeNames: { like: schoolName, options: 'i' } }
          ]
        },
        fields: ['alternativeNames', 'id', 'schoolRating', 'universityName'],
        limit: 5
      })
  };

  matchFromDef = (key, rawText, defaultLabel) => {
    let defaultt = Definition.get(key).find(obj => obj.label === defaultLabel);
    let result = Definition.get(key).find(obj => {
      let labels = obj.label.split(" ");
      return !!labels.find((label) => {
        let regex1 = RegExp(label, 'i');
        return regex1.test(rawText);
      })

    });

    return !!result ? result : defaultt;
  }

  hasNewDegree = () => {
    let { initialDegree } = this.state;

    if (!initialDegree) {
      return false;
    }

    // April - Add degree pattern matching, case insensitive for all the string match

    let degreeFromDef = this.matchFromDef("undergraduateDegree", initialDegree, 'None');

    if (/none/i.test(Object(degreeFromDef).label)) {
      return true;
    } else {
      return false;
    }
  };

  linkedInToDefinition = (keyValue) => {
    //degree,major,school
    let keys = Object.keys(keyValue);
    let state = {};

    keys.forEach(key => {
      let def = Definition.getRawDef(key);
      let values = def.values;
      let labels = values.map(el => el.label);

      if (!!keyValue[key] && !labels.includes(keyValue[key])) {
        let newLabel = { id: labels.length + 1, label: keyValue[key] };
        state[key] = newLabel.id;
        values.push(newLabel);
        Definition.update(def.id, { values }, (response) => {
          let [current] = values.filter(el => el.label === keyValue[key]);
          this.setState({ degree: Object(current).id }, () => {
            this.props.onDone(this.getUpdatedStructure());
          })
        })
      } else {
        let [current] = values.filter(el => el.label === keyValue[key]);
        state[key] = Object(current).id;
      }
    });

    return state;
  };

  getUpdatedStructure = () => {
    let {
      id, schoolName, degrees, degreeOtherValue,
      hasNewDegree, initialDegree, isDefault,
      positiveSignalsTags, rankingMeta, negativeSignalsTags,
      schoolRanking
    } = this.state;

    return {
      id,
      schoolName,
      isDefault,
      degrees,
      hasNewDegree,
      degreeOtherValue,
      initialDegree,
      positiveSignalsTags,
      negativeSignalsTags,
      rankingMeta,
      schoolRanking
    };
  };

  findMyEducationTag = (cb) => {
    let { schoolName, ranking, degrees } = this.state;
    let school = schoolName;

    const manageWordLabels = (majors, school) => {
      const computerTag = (major) => {
        if (!major) {
          major = '';
        }

        major = major.trim();

        let out = '';

        if (!!major && /computer/i.test(major)) {
          out = Definition.getId('positiveSignals', 'Computer degree');
        }

        return out;
      };

      let posSign = majors.map(major => computerTag(major)).filter(el => !!el);

      if (!posSign.length) {
        this.setState({ tmpPositiveSignalsTags: [] }, () => {
          this.props.onDone(this.getUpdatedStructure())
        });
      }

      return { positiveSignals: posSign };
    };


    const matched = (ranking || []).find(sc => {
      try {
        let regx = new RegExp(sc.universityName, 'i');
        return regx.test(schoolName) || sc.alternativeNames.find(an => {
          let rgx = new RegExp(schoolName, 'i');
          return rgx.test(an);
        });
      } catch (e) {
        return sc.alternativeNames.includes(schoolName);
      }
    });


    const rankingResponse = Object(matched);

    let existingPosSign = [];
    let finalArrayNeg = [];
    let positiveSignals = [];

    try {
      existingPosSign = !!Object.keys(rankingResponse).length ? dig(rankingResponse.schoolRating[0], 'signals', 'positiveSignals') || [] : [];
      finalArrayNeg = !!Object.keys(rankingResponse).length ? dig(rankingResponse.schoolRating[0], 'signals', 'negativeSignals') || [] : [];
      positiveSignals = manageWordLabels(degrees.map(d => d.degreeMajor), school).positiveSignals;
    } catch (e) {
    }

    let calculatedLabels = ranking && !!ranking.length ? findSchoolEducationTag(school, 'computer', ranking) : { negativeSignalsTags: [] };

    this.setState(
      {
        tmpPositiveSignalsTags: [...existingPosSign, ...positiveSignals],
        tmpNegativeSignalsTags: [...finalArrayNeg, ...calculatedLabels.negativeSignalsTags],
        rankingMeta: rankingResponse,
        schoolRanking: Definition.getLabel("positiveSignals", !!existingPosSign.length ? existingPosSign[0] : "")
      }, () => {
        // this.state.tmpPositiveSignalsTags.forEach(sig => {
        //   //queueing the state update, don't remove setTimeout otherwise only last tag will show result
        //   setTimeout(()=> {
        //     this.handlerPositiveSignalChipClick(sig)
        //   });
        // });

        cb && cb();
      }
    );

  };


  onApply = (intent, data) => {

    const onSave = (company) => {
      this.setState({ rankingMeta: company }, () => {
        this.getSchoolInfo();
        this.onCancel();
      });
    };

    const { newName, alternativeNames, selected } = data;

    if (intent === 'add') {
      School.post({
        universityName: newName,
        alternativeNames,
      }, (response) => {
        !!onSave && onSave(response);
      })
    } else {
      School.update(selected.value, {
        alternativeNames: alternativeNames.filter(name => !!name),
      }, (response) => {
        !!onSave && onSave(response);
      });
    }
  };

  onCancel = () => {
    this.setState({ shouldShowSchoolForm: false });
  };

  handlerAddAlternativeNames = () => {
    let { rankingMeta } = this.state;
    rankingMeta = Object(rankingMeta);
    this.setState({
      shouldShowSchoolForm: true,
      schoolFormIntent: 'addAlternativeName',
      schoolFormObject: !isEmpty(rankingMeta) ? {
        value: rankingMeta.id,
        label: rankingMeta.universityName
      } : null
    });
  };

  handlerAddSchool = () => {
    this.setState({
      shouldShowSchoolForm: true,
      schoolFormIntent: 'add',
      schoolFormObject: null
    });
  };

  handlerSchoolEdit = () => {
    let { rankingMeta } = this.state;

    rankingMeta = Object(rankingMeta);
    this.setState({
      shouldShowSchoolForm: true,
      schoolFormIntent: 'edit',
      schoolFormObject: !isEmpty(rankingMeta) ? {
        value: rankingMeta.id,
        label: rankingMeta.universityName,
      } : null
    });
  };

  dropdownDataLoad = (keyword, callback) => {
    setTimeout(() => {
      const ilikeStruct = { like: `.*${keyword}.*`, options: "i" };
      const opts = {
        limit: 50,
        fields: ['id', 'universityName', 'alternativeNames'],
        where: {
          or: [
            { universityName: ilikeStruct },
            { alternativeNames: keyword },
          ]
        }
      };

      School.get(schools => {
        callback(schools);
      }, opts);
    });

  };

  dropdownFormatter = el => {
    let label = `${el.universityName}`;
    return { value: el.id, label }
  };

  handlerPositiveSignalChipClick = (id, shouldRemove) => {
    let {
      positiveSignalsTags
    } = this.state;

    if (shouldRemove) {
      positiveSignalsTags = positiveSignalsTags.filter(tag => tag !== id);
    } else {
      positiveSignalsTags = [...positiveSignalsTags, id];
    }

    this.setState({ positiveSignalsTags }, () => {
      this.props.onDone(this.getUpdatedStructure());
    });
  };

  render() {
    let { element, handlerDeleteItem, onDone, markAsDefault } = this.props;
    let {
      schoolName, degrees,
      initialDegree, isDefault,
      tmpPositiveSignalsTags,
      positiveSignalsTags, negativeSignalsTags, tmpNegativeSignalsTags, rankingMeta,
      shouldShowCompanyBgColor, shouldShowSchoolForm, schoolFormIntent, schoolFormObject
    } = this.state;

    const schoolMissingButDegreesExists = !schoolName && (degrees || []).length;

    console.log({ rankingMeta, shouldShowCompanyBgColor })
    let backgroundColor = (Core.isAdmin() && !!shouldShowCompanyBgColor && isEmpty(rankingMeta)) ? 'gold' : schoolMissingButDegreesExists ? 'red' : 'ghostwhite';
    const hasRankingMeta = !isEmpty(rankingMeta);

    if (schoolMissingButDegreesExists) {
      backgroundColor = 'red';
    }
    return <div style={{ backgroundColor, marginBottom: '10px', paddingBottom: '20px', width: '100%' }}>
      {isDefault && <Row>
        <p style={{ color: 'white' }}>This is default</p>
      </Row>}
      <Row>
        <Col fullWidth>
          <label>
            School - Degrees ({(degrees || []).length})&nbsp;&nbsp;
            {Core.isAdmin() && shouldShowCompanyBgColor &&
              <span >{!hasRankingMeta && (schoolName || "").length >= 2 ? <Fragment>
                <Tooltip title="Add as an alternative name">
                  <AllInboxIcon style={{ cursor: 'pointer' }} onClick={this.handlerAddAlternativeNames} />
                </Tooltip>
                <Tooltip title="Add as a new school">
                  <AddIcon style={{ cursor: 'pointer' }} onClick={this.handlerAddSchool} />
                </Tooltip>
              </Fragment> : !!hasRankingMeta && <Tooltip title="Edit this school">
                <CreateIcon style={{ cursor: 'pointer' }} onClick={this.handlerSchoolEdit} />
              </Tooltip>
              }</span>}
          </label>
          <TenAutoSuggest
            value={schoolName}
            onChange={(ev, schoolNameField) => {
              // this.findMyEducationTag();
              if (String(schoolName).toLowerCase() !== String(schoolNameField).toLowerCase()) {
                this.setState({ schoolName: schoolNameField, shouldShowCompanyBgColor: false }, () => {
                  onDone(this.getUpdatedStructure());
                  this.getSchoolInfo();
                })
              }

            }}
            onBlur={(event, schoolNameField) => {
              if (String(schoolName).toLowerCase() !== String(schoolNameField).toLowerCase()) {
                this.setState({ schoolName: String(schoolNameField).trim(), shouldShowCompanyBgColor: false }, () => {
                  onDone(this.getUpdatedStructure());
                  this.getSchoolInfo();
                })
              }
            }}
            handleSuggestionsFetchRequested={(value, callback) => {
              if (String(schoolName).toLowerCase() !== String(value).toLowerCase()) {
                this.getSchoolInfo(value, (response) => {
                  callback(response.map(school => ({ label: school.universityName })))
                });
              }
            }}
          />
        </Col>
      </Row>
      <InputArray
        source="candidateEdit"
        heading="Add degree"
        existing={degrees}
        elementComp={EducationDegree}
        parentUpdater={(result) => {
          this.setState({ degrees: result, tmpPositiveSignalsTags: [] }, () => {
            this.findMyEducationTag(() => {
              onDone(this.getUpdatedStructure());
            });
          });
        }}
      />
      {this.hasNewDegree() && <Row>
        <p style={{ color: 'red' }}>Found new Degree <span style={{ color: 'blue' }}>{initialDegree}</span> Create a
          new degree tag using the button on the right</p>
        <br />
        <Button
          onClick={() => {
            this.linkedInToDefinition({ undergraduateDegree: initialDegree }); // this method is coming from Basics.js during array building
          }
          }

          variant="contained" size="small" className={''}>
          Create Tag
        </Button>
      </Row>
      }

      {Core.isAdmin() && shouldShowSchoolForm &&
        <AlternativePopup
          intent={schoolFormIntent}
          newName={isEmpty(rankingMeta) ? schoolName : ''}
          selected={schoolFormIntent === 'edit' ? schoolFormObject : null}
          onCancel={this.onCancel}
          onApply={this.onApply}
          dropdownDataLoader={this.dropdownDataLoad}
          dropdownFormatter={this.dropdownFormatter}
          source={'school'}
        />
      }

      <Row>
        <Col fullWidth>
          <Button onClick={() => handlerDeleteItem(element)} variant="contained" size="small" className={''}>
            <DeleteIcon />
            Remove
          </Button>&nbsp;&nbsp;
          {/*<Button onClick={() => onDone(updatedElement)}*/}
          {/*variant="contained" size="small"*/}
          {/*className={''}>*/}
          {/*<SaveIcon/>*/}
          {/*Save*/}
          {/*</Button>&nbsp;&nbsp;*/}
          <Button onClick={() => markAsDefault(this.getUpdatedStructure())}
            variant="contained" size="small"
            className={''}>
            Set Default
          </Button>&nbsp;&nbsp;
          {tmpPositiveSignalsTags.filter(el => !!el).map(id => {
            return <Fragment>
              <Button style={{ backgroundColor: 'green' }}
                onClick={() => {
                  const shouldRemoveIt = positiveSignalsTags.includes(id);
                  this.handlerPositiveSignalChipClick(id, shouldRemoveIt)
                }}
                variant="contained" size="small"
                className={''}>
                {Definition.getLabel('positiveSignals', id)} {positiveSignalsTags.includes(id) &&
                  <span>(Added)</span>}
              </Button>&nbsp;&nbsp;
            </Fragment>;
          })}

          {tmpNegativeSignalsTags.filter(el => !!el).map(id => {
            return <Fragment><Button style={{ backgroundColor: 'red' }} onClick={() => {
              this.setState({ negativeSignalsTags: [...negativeSignalsTags, id] }, () => {
                this.props.onDone(this.getUpdatedStructure());
              });
            }}
              variant="contained" size="small"
              className={''}>
              {Definition.getLabel('negativeSignals', id)} {negativeSignalsTags.includes(id) &&
                <span>(Added)</span>}
            </Button>&nbsp;&nbsp;</Fragment>;
          })}


        </Col>

      </Row>
    </div>

  }
}

export default Education;
