import { List, ListItem } from "material-ui/List";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import TextField from "material-ui/TextField";
import React, { Component } from "react";
import { colors } from "../../../lib/Core";
import Definition, {
  LOC_TYPE__CITY_ID,
  LOC_TYPE__COUNTRY_ID, LOC_TYPE__METRO_AREA_ID, LOC_TYPE__METRO_AREA_SEC_ID,
  LOC_TYPE__STATE_ID, LOCATION__CALIFORNIA, LOCATION__UNITED_STATES,
} from "../../../lib/Definition";
import countries from "../../../static/countries.json";
import states from "../../../static/states.json";
import lineStyle from "../../../static/textfield-line-style.json";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";
import TreeSelectDropdown from "../../Forms/TreeSelect";

class Basics extends Component {
  mapStates() {
    return states.map((item) => {
      return <MenuItem key={item.id} value={item.id} primaryText={item.name} />;
    });
  }
  mapCountries() {
    return countries.map((item) => {
      return <MenuItem key={item.id} value={item.id} primaryText={item.name} />;
    });
  }
  render() {
    const warnStyle = {
      color: colors.yellow,
      borderColor: colors.yellow,
    };
    const EmployerEditController = this.props.parent;
    const { candidateLocations } = EmployerEditController.state;
    const locationTags = Definition.get("location");
    return (
      <List className="EmployerForm Basics">
        <ListItem
          className="form-header"
          primaryText="BASICS"
          disabled={true}
        />
        <Row>
          <Col>
            <label>
              Employer Name <b className="cred">*</b>
            </label>
            <TextField
              name="name"
              underlineFocusStyle={lineStyle}
              value={EmployerEditController.state.name}
              onChange={(ev, name) => {
                if (
                  EmployerEditController.state.employers.find(
                    (employer) =>
                      employer.id !== EmployerEditController.state.id &&
                      String(employer.name).toLowerCase() ===
                      String(name).toLowerCase()
                  )
                ) {
                  EmployerEditController.setState({
                    name,
                    snackbar: "Name should be unique",
                    errorName: "Choose another name",
                  });
                } else {
                  EmployerEditController.setState({ name, errorName: "" });
                }
              }}
              fullWidth
              errorText={EmployerEditController.state.errorName}
            />
          </Col>
          {!!EmployerEditController.state.id && (
            <Col>
              <label>Anonymous Employer Name for Limited Recruiter</label>
              <TextField
                name="proxyName"
                underlineFocusStyle={lineStyle}
                value={EmployerEditController.state.proxyName}
                // onChange={(ev, proxyName) => parent.setState({ proxyName })}
                disabled
                fullWidth
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <label>Address</label>
            <TextField
              name="addressStreet"
              underlineFocusStyle={lineStyle}
              value={EmployerEditController.state.addressStreet}
              onChange={(ev, addressStreet) =>
                EmployerEditController.setState({ addressStreet })
              }
              fullWidth
            />
          </Col>
          <Col>
            <label>City</label>
            <TextField
              name="addressCity"
              underlineFocusStyle={
                EmployerEditController.state.errorAddressCity
                  ? warnStyle
                  : lineStyle
              }
              value={EmployerEditController.state.addressCity}
              onChange={(ev, addressCity) =>
                EmployerEditController.setState({
                  addressCity,
                  errorAddressCity: "",
                })
              }
              fullWidth
              errorText={EmployerEditController.state.errorAddressCity}
              errorStyle={warnStyle}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>State</label>
            <SelectField
              name="addressState"
              underlineFocusStyle={lineStyle}
              value={EmployerEditController.state.addressState}
              onChange={(ev, i, addressState) =>
                EmployerEditController.setState({ addressState })
              }
              fullWidth
            >
              {this.mapStates()}
            </SelectField>
          </Col>
          <Col>
            <label>Zip</label>
            <TextField
              name="addressZip"
              underlineFocusStyle={lineStyle}
              value={EmployerEditController.state.addressZip}
              onChange={(ev, addressZip) =>
                EmployerEditController.setState({ addressZip })
              }
              onFocus={(ev) => ev.target.select()}
              type="text"
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Country</label>
            <SelectField
              name="addressCountry"
              underlineFocusStyle={lineStyle}
              value={EmployerEditController.state.addressCountry}
              onChange={(ev, i, addressCountry) =>
                EmployerEditController.setState({ addressCountry })
              }
              fullWidth
            >
              {this.mapCountries()}
            </SelectField>
          </Col>
          <Col>
            <label>Stage</label>
            <SelectField
              name="stage"
              underlineFocusStyle={lineStyle}
              value={EmployerEditController.state.stage}
              onChange={(ev, i, stage) =>
                EmployerEditController.setState({
                  stage,
                  errorEmployerStage: "",
                })
              }
              fullWidth
              errorText={EmployerEditController.state.errorEmployerStage}
              errorStyle={warnStyle}
            >
              {Definition.get("stage").map((tag) => (
                <MenuItem key={tag.id} value={tag.id} primaryText={tag.label} />
              ))}
            </SelectField>
          </Col>
        </Row>

        <Row>
          <Col fullWidth>
            <label>
              Hiring permanent (full-time) employees only in these locations
            </label>
            <TreeSelectDropdown
              data={locationTags}
              layers={[
                LOC_TYPE__COUNTRY_ID, LOC_TYPE__STATE_ID, LOC_TYPE__METRO_AREA_ID, LOC_TYPE__METRO_AREA_SEC_ID,
                LOC_TYPE__CITY_ID
              ]}
              value={candidateLocations}
              treeDefaultExpandedIds={[LOCATION__UNITED_STATES, LOCATION__CALIFORNIA]}
              onChange={({ value: candidateLocations }) => {
                EmployerEditController.setState({ candidateLocations });
              }}
              className="my-1"
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <label>How many people work at the company?</label>
            <TextField
              name="minCompanySize"
              underlineFocusStyle={lineStyle}
              min="1"
              type="number"
              value={EmployerEditController.state.employeeCount}
              onChange={(event, employeeCount) => {
                EmployerEditController.setState({ employeeCount });
              }}
              fullWidth
              errorText={EmployerEditController.state.errorEmployeeCount}
              errorStyle={warnStyle}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Founding Year</label>
            <TextField
              name="foundingYear"
              underlineFocusStyle={lineStyle}
              type="number"
              min="1800"
              max="2100"
              value={EmployerEditController.state.foundingYear}
              onChange={(ev, foundingYear) =>
                EmployerEditController.setState({ foundingYear })
              }
              onFocus={(ev) => ev.target.select()}
              fullWidth
            />
          </Col>
          <Col>
            <label>Total Funding Raised</label>
            <div style={{ display: "flex" }}>
              <span className="material-icons text-icon">attach_money</span>
              <TextField
                name="totalFunding"
                underlineFocusStyle={lineStyle}
                type="text"
                value={EmployerEditController.state.totalFunding}
                onChange={(ev, totalFunding) =>
                  EmployerEditController.setState({ totalFunding })
                }
                onFocus={(ev) => ev.target.select()}
                fullWidth
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Most Recent Funding Round</label>
            <TextField
              name="mostRecentFundingDate"
              underlineFocusStyle={lineStyle}
              type="text"
              value={EmployerEditController.state.mostRecentFundingDate}
              onChange={(ev, mostRecentFundingDate) =>
                EmployerEditController.setState({ mostRecentFundingDate })
              }
              fullWidth
            />
          </Col>
        </Row>
      </List>
    );
  }
}
export default Basics;
