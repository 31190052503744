/** ============================================ µ
 * @description TreeListMenu [JSX]
 *              UI Component
 * @routes      /jobs
 *              /candidates
 * @createdAt   2021-07-05
 * @updatedAt   2021-08-04
 * ============================================ */
/* IMPORTS ==================================== */

import "antd/dist/antd.css";
import React from "react";
import Definition, {
  LOCATION__AMERICAS,
  LOCATION__CALIFORNIA,
  LOCATION__SAN_FRANCISCO_BAY_AREA,
  LOCATION__UNITED_STATES,
  LOC_TYPE__ALIAS_ID,
  LOC_TYPE__CITY_ID,
  LOC_TYPE__COUNTRY_ID,
  LOC_TYPE__METRO_AREA_ID,
  LOC_TYPE__METRO_AREA_SEC_ID,
  LOC_TYPE__MULTI_CONT_ID,
  LOC_TYPE__STATE_ID
} from "../../../lib/Definition";
import FilterControlLib from "../../../lib/FilterControl";
import TreeSelectDropdown from "../../Forms/TreeSelect";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function TreeListMenu(props) {
  const {
    menu,
    FilterControlController,
  } = props;
  const _props = {
    data: Definition.get('location'),
    size: 'small',
    layers: [
      LOC_TYPE__MULTI_CONT_ID,
      LOC_TYPE__COUNTRY_ID,
      LOC_TYPE__STATE_ID,
      LOC_TYPE__METRO_AREA_ID,
      LOC_TYPE__METRO_AREA_SEC_ID,
      LOC_TYPE__CITY_ID
    ],
    treeDefaultExpandedIds: [
      LOCATION__AMERICAS,
      LOCATION__UNITED_STATES,
      LOCATION__CALIFORNIA,
      LOCATION__SAN_FRANCISCO_BAY_AREA
    ],
    value: (
      /** @todo be care of odd behaviors 2021-07-15 Thu µ */
      // menu.value ||
      /** @todo be care of odd behaviors 2021-07-09 µ */
      /* This is presetting the checked values for matching pages */
      Object.keys(menu.items || {})
        .filter(label => !!menu.items[label])
        .map(label =>
          Definition.getId(
            'location',
            FilterControlLib.getItemLabel({ menu, itemValue: label })
          )
        )
    ),
    /**
     * @todo Waiting for feedback, can remove it before go to staging. 2021-07-09 µ
     */
    // dropdownMatchSelectWidth={480} // no needed by the moment
    /** */
    onDropdownVisibleChange: (
      open =>
        !open && FilterControlController.setState({ showLocationDropdown: false })
    ),
    /** */
    onChange: (
      ({ value: locations }) => {
        const locationLabels = Definition.getLabels(
          'location',
          locations,
          menu.items
        );
        Object.keys(menu.items).forEach(key => menu.items[key] = false);
        locationLabels.forEach(locationLabel => {
          const itemValue = FilterControlLib.getItemValue({
            menu,
            itemLabel: locationLabel
          });
          menu.items[itemValue] = true;
        });
        menu.value = locations;
        console.debug({ menu });
        FilterControlController.setState(
          { menu },
          /**
           * @todo
           *
           * To review and possible deprecated the following nested callbacks.
           * They are making different things in listing-pages and matching-pages.
           *
           * 2021-07-09
           * µ
           *
           */
          //then => FilterControlController.onChange()
          then => FilterControlController.checkLocations({
            label: '',
            checked: true,
            cb: then =>
              FilterControlController.jiraVER20({
                label: '',
                menuKey: menu.key,
                checked: true,
                cb: FilterControlController.onChange(),
              })
          })

        );
      }
    ),
    autoFocus: true,
    ...(
      FilterControlController.matchingPage
        ? menu.matchingPages || {}
        : menu.listingPages || {}
    ),
    className: 'my-1',
  };

  if (menu.key === 'candidateLocations') {
    _props.layers.push(LOC_TYPE__ALIAS_ID);
  }

  return (
    <div style={{ position: 'absolute', width: 360 }}>
      <TreeSelectDropdown {..._props} />
    </div>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export {
  TreeListMenu as default,
  TreeListMenu
};

/* ============================================ */
