/** ============================================ µ
 * @description ReactTable [JSX]
 *              UI Component
 * @createdAt   2021-09-15 Wed
 * @updatedAt   2021-10-19 Tue
 * ============================================ */
/* IMPORTS ==================================== */

import { Paper, TableFooter, TablePagination } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import MaUTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { Fragment } from 'react';
import Core from '../../lib/Core';
import MultipleSelect from '../Forms/MultipleSelect';
import Loader from '../Shared/Loader';
import Bookmarks from './Bookmarks';
import { CellActionsColumn } from './CellActionsColumn';
import { CellTagColumn } from './CellTagColumn';
import ColumnsSettings from './ColumnsSettings';
import { FilterActionsColumn } from './FilterActionsColumn';
import { FilterDefaultColumn } from './FilterDefaultColumn';
import { FilterLocationsColumn } from './FilterLocationsColumn';
import { FilterNumberRangeColumn } from './FilterNumberRangeColumn';
import { FilterTagColumn } from './FilterTagColumn';
import GlobalChipsBar from './GlobalChipsBar';
import { IndeterminateCheckbox } from './IndeterminateCheckbox';
import TabBarOptions from './TabBarOptions';
import TablePaginationActions from './TablePaginationActions';
import ToggleBlacklisted from './ToggleBlacklisted';
import ToggleLooseMatch from './ToggleLooseMatch';
import AddNewButton from './AddNewButton';
import ToggleResume from './ToggleResume';
import { useAutocomplete } from './useAutocomplete.hook';
import {
  REACT_TABLE__PAGE_SIZES,
  useEnhancedReactTable,
} from './useEnhancedReactTable.hook';
import BulkCopyButton from './BulkCopyButton';

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function ReactTable(context) {
  const enhancedReactTableContext = useEnhancedReactTable(context);

  const {
    // react-i18next
    // t,

    // react-table-hook
    visibleColumns,
    headerGroups,
    rows,
    prepareRow,
    getTableProps,
    getTableBodyProps,

    // enhanced-hook
    loading,
    latestQuery,
    totalCount,
    globalSearchValue,
    setGlobalSearchValue,
    page,
    setPage,
    pageSize,
    setPageSize,
    setSort,
    invertExpanded,
    renderCellClassName,
    ExpandedView,
    disableBodyRender,
  } = enhancedReactTableContext;

  const { suggestions, fetchSuggestions } = useAutocomplete(
    context.config.autocompletePath
  );

  return (
    <>
      <CssBaseline />
      <Paper
        className="p-0 tanstack-resize"
        style={{
          overflowX: 'auto',
          height: '100%',
          width: '100%',
          backgroundColor: 'var(--mainBgColor)',
        }}
      >
        <MaUTable
          {...getTableProps()}
          style={{ height: 'auto', width: '100%' }}
        >
          <TableHead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
              backgroundColor: 'var(--mainBgColor)',
            }}
          >
            {/** GLOBAL SEARCH */}
            <TableRow>
              <TableCell
                className="w-100"
                style={{ textAlign: 'left', border: 'none' }}
              >
                <div
                  className="d-flex flex-align-left-center"
                  style={{
                    position: 'sticky',
                    left: '1rem',
                    width: 'calc(100vw - 3rem)',
                  }}
                >
                  <MultipleSelect
                    name="filter"
                    placeholder={context.config.searchPlaceholder}
                    multiple="tags"
                    data={suggestions}
                    value={globalSearchValue}
                    className="mr-1"
                    style={{
                      minWidth: 320,
                      width: 480,
                    }}
                    onChange={setGlobalSearchValue}
                    onSearch={fetchSuggestions}
                  />
                  <ToggleResume
                    enhancedReactTableContext={enhancedReactTableContext}
                    className="mr-1"
                  />
                  <ToggleLooseMatch
                    enhancedReactTableContext={enhancedReactTableContext}
                    className="mr-1"
                  />
                  <ToggleBlacklisted
                    enhancedReactTableContext={enhancedReactTableContext}
                    className="mr-1"
                  />
                  <TabBarOptions context={context} />
                  <div className="d-flex flex-align-right-center ml-auto">
                    <AddNewButton
                      enhancedReactTableContext={enhancedReactTableContext}
                      className={context.config.addNew ? 'mr-1' : 'd-none'}
                    />
                    <BulkCopyButton
                      enhancedReactTableContext={enhancedReactTableContext}
                      className={'mr-1'}
                    />
                    <Bookmarks
                      enhancedReactTableContext={enhancedReactTableContext}
                      className={'mr-1'}
                    />
                    <ColumnsSettings
                      enhancedReactTableContext={enhancedReactTableContext}
                      className="mr-1"
                    />
                  </div>
                </div>
              </TableCell>
            </TableRow>

            {/* HEADERS */}
            {headerGroups.map((headerGroup) => {
              let _props = headerGroup.getHeaderGroupProps();
              return (
                <TableRow {..._props}>
                  {headerGroup.headers.map((column, index) => {
                    let _props = column.getHeaderProps();
                    if (index === headerGroup.headers.length - 1) {
                      _props.style = {
                        ..._props.style,
                        backgroundColor: '#eee',
                        right: 0,
                      };
                    }
                    _props.className = [
                      'th', // this is required for resize; see index.css .tanstack-resize .th
                      _props.className,
                      column._headerClassName,
                      'py-0',
                    ]
                      .filter((v) => !!v)
                      .join(' ');
                    // console.debug('column', column);
                    return (
                      <TableCell {..._props}>
                        <div
                          className={[
                            'd-flex ant-row-space-between',
                            column.id === 'selection' ? 'py-1' : 'px-1',
                            column.canSort && 'hover-bg pointer',
                          ]
                            .filter((v) => !!v)
                            .join(' ')}
                          title={
                            column.canSort
                              ? `Click to sorting${typeof column.Header === 'string' ? ` by ${column.Header}` : ''}`
                              : typeof column.Header === 'string' ? `${column.Header}` : ''
                          }
                          onClick={(event) =>
                            column.canSort && setSort({ columnId: column.id })
                          }
                        >
                          <span className={column.id !== 'selection' ? 'mr-2 truncate' : ''}>
                            {column.render('Header')}
                          </span>
                          <span className="material-icons">
                            {latestQuery?.sort &&
                              (latestQuery.sort[column.id] === 1
                                ? 'arrow_drop_down'
                                : latestQuery.sort[column.id] === -1
                                  ? 'arrow_drop_up'
                                  : '')}
                          </span>
                        </div>

                        {/* Render the columns filter UI */}
                        {column.canFilter
                          ? column.render('Filter', enhancedReactTableContext)
                          : null}

                        {!column.disableResizing && (
                          <div
                            {...column.getResizerProps()}
                            className={`resizer ${column.isResizing ? 'isResizing' : ''
                              }`}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}

            <TableRow>
              <TableCell
                className="w-100 p-0 pt-1 px-1"
                style={{ textAlign: 'left' }}
              >
                <GlobalChipsBar
                  enhancedReactTableContext={enhancedReactTableContext}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          {!disableBodyRender && (
            <TableBody {...getTableBodyProps()}>
              {loading && (
                <TableRow className="bg-white">
                  <TableCell>
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {rows.map((row, i) => {
                // validating model-name for dynamic loading
                if (!context.config.Entity.name) {
                  Core.showMessage(`Entity has not name set`);
                  return null;
                } else if (!row.original.___model___) {
                  Core.showMessage(`Model has not name set`);
                  return null;
                } else if (
                  context.config.Entity.name !== row.original.___model___
                ) {
                  return null;
                }

                prepareRow(row);
                // console.debug('row', row);
                let _props = row.getRowProps();
                _props.style.border = 'none';
                let { original: model = {}, isExpanded } = row;
                let _isExpanded = invertExpanded ? !isExpanded : isExpanded;
                let _key = _props.key;
                delete _props.key;
                return (
                  <Fragment key={_key}>
                    <TableRow {..._props}>
                      {row.cells.map((cell) => {
                        let _props = cell.getCellProps();
                        _props.style = {
                          ..._props.style,
                        };
                        // _props.className = cell.column._cellClassName;
                        _props.className = renderCellClassName({
                          model,
                          className: [
                            'bg-white',
                            cell.column._cellClassName,
                          ].join(' '),
                        });
                        return (
                          <TableCell {..._props}>
                            {cell.render('Cell', enhancedReactTableContext)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    {_isExpanded && ExpandedView && (
                      <TableRow {..._props}>
                        <TableCell
                          className={renderCellClassName({
                            model,
                            className: 'w-100',
                          })}
                        >
                          <ExpandedView model={model} />
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                );
              })}
            </TableBody>
          )}
          <TableFooter
            className="w-100"
            style={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'var(--mainBgColor)',
              zIndex: 1,
            }}
          >
            <TableRow className="w-100">
              <TablePagination
                rowsPerPageOptions={REACT_TABLE__PAGE_SIZES}
                colSpan={visibleColumns.length}
                count={totalCount}
                rowsPerPage={pageSize}
                page={page - 1}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={(event, page) => setPage(page)}
                onRowsPerPageChange={(event) => setPageSize(event.target.value)}
                ActionsComponent={TablePaginationActions}
                style={{
                  display: 'inline-flex',
                  position: 'sticky',
                  left: 0,
                  border: 'none',
                }}
                labelRowsPerPage={<span>Rows per page:</span>}
              />
            </TableRow>
          </TableFooter>
        </MaUTable>
      </Paper>
    </>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export {
  ReactTable as default,
  ReactTable,
  IndeterminateCheckbox,
  FilterDefaultColumn,
  FilterNumberRangeColumn,
  FilterLocationsColumn,
  FilterTagColumn,
  CellTagColumn,
  FilterActionsColumn,
  CellActionsColumn,
  useAutocomplete,
};

/* ============================================ */
