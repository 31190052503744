import { Button } from '@mui/material';
import { MuiThemeProvider } from 'material-ui/styles';
import React from 'react';
import Candidate from '../../lib/Candidate';
import Core from '../../lib/Core';
import { sendSafeEmail } from '../../lib/services/Email/Email.lib';
import SovrenData from '../../lib/SovrenData';
import Streak from '../../lib/Streak';
import EmailPreview from '../Dialogs/EmailPreview';
import ResumeMatch from '../Shared/ResumeMatch';

let count = 0;

export const moreMenuOptionsCandidates = [
  {
    label: 'Edit Candidate',
    to: candidate => `/v3/candidate/edit/${candidate.id}`,
    target: candidate => { candidate.isDuplicate ? '_blank' : '' },
  },
  {
    acl: candidate => Core.isAdminOrCoordinator(),
    to: candidate => `/v3/candidate/matchNew/${candidate.id}`,
    label: 'Match',
  },
  {
    acl: candidate => Core.isAdminOrCoordinator(),
    label: candidate => `Engagements Details(${candidate.engagements.length})`,
    action: candidate => candidate.openEngagementsDetails(),
  },
  {
    acl: candidate => (Core.isAdmin() || (Core.isRecruiter() && Core.isOnDev())),
    label: 'View Dups',
    action: candidate => Core.openExact(`/candidates?viewDup=t&cId=${candidate.id}`)
  },
  {
    acl: candidate => (!!candidate.linkedInURL && Core.isAdmin()),
    label: 'LinkedIn',
    action: candidate => Core.openPopUp(candidate.linkedInURL),
  },
  {
    acl: candidate => (!!candidate.gitHubURL && Core.isAdmin()),
    label: 'Github',
    action: candidate => Core.openPopUp(candidate.gitHubURL),
  },
  {
    acl: candidate => (candidate.resumeTxtUrl),
    render: candidate => (
      <MuiThemeProvider key={`more-options-menu-option-${candidate.id}-${count++}`}>
        <ResumeMatch
          candidateResume={candidate.resumeTxtUrl}
        />
      </MuiThemeProvider>
    )
  },
  {
    acl: candidate => (!!candidate.resumes?.length),
    label: 'View Resume',
    action: candidate => Core.openPopUp(Candidate.getMyPdfUrl(candidate))
  },
  {
    acl: candidate => Core.isAdmin(),
    label: 'Copy Confirmation Job Description',
    action: candidate => Candidate.bulkCopy(candidate)
  },
  {
    acl: candidate => Core.isAdmin(),
    label: 'Message',
    action: candidate => openMessage(candidate)
  },
  {
    acl: candidate => Core.isAdmin(),
    label: 'Delete',
    action: candidate => deleteCandidate(candidate)
  }
];

function openMessage(candidate) {
  /** */
  Core.showMessage("Getting snippets, wait a moment...");
  const continueFlow = snippets => {
    Core.hideMessage();
    Core.log({ snippets });
    const emails = [];
    candidate.email &&
      emails.push({
        name: candidate._name || "Action Owner",
        email: candidate.email
      });
    candidate.recruiter.email &&
      emails.push({
        name: candidate.recruiter._name || "Recruiter",
        email: candidate.recruiter.email
      });
    Core.dialog.open({
      title: <>Message</>,
      message: (
        <EmailPreview
          ref={self => (openMessage.EmailMessage = self)}
          emails={emails}
          to={!!emails[0] && [emails[0]]}
          subject={emails.map(item => item.name).join(", ")}
          body={[candidate._name, candidate.recruiter._name]
            .filter(line => !!line)
            .join("<br/>")}
          snippets={snippets}
        />
      ),
      className: "p-0",
      actions: [
        <Button
          className="button-flat-darker"
          onClick={ev => {
            Core.dialog.close();
          }}
        >
          Cancel
        </Button>,
        <Button
          className="button-white-cyan"
          onClick={ev => {
            Core.dialog.close();
            sendSafeEmail(
              {
                ...openMessage.EmailMessage.getParams(),
                source: 'From CandidateCard, CandidateCard.js line 274'
              },
              response => Core.showMessage("Email sent"),
              error => Core.showFailure(error)
            );
          }}
        >
          Send
        </Button>
      ]
    });
  };
  Streak.getSnippets(
    snippets => continueFlow(snippets),
    error => {
      continueFlow([]);
      Core.showMessage("Snippets:" + error);
    }
  );
  /** */
};

function deleteCandidate(candidate) {
  Candidate.fetchEngagements({ candidateId: candidate.id }).then(
    engagements =>
      Core.dialog.open({
        message: `Delete "${candidate._name}"${!!engagements.length
          ? ` and ${engagements.length} engagement${engagements.length === 1 ? "" : "s"
          }?`
          : ""
          }`,
        actions: [
          <Button
            className="button-white-cyan"
            onClick={Core.dialog.close}>
            Cancel
          </Button>,
          <Button
            className="button-flat-darker"
            onClick={
              ev =>
                Candidate.delete(candidate.id,
                  response => {
                    if (Array.isArray(candidate.resumes)) {
                      candidate.resumes.forEach((resume) => {
                        SovrenData.destroyByTimestamp(resume.timestamp);
                      })
                    }
                    Core.showMessage(
                      "Candidate was deleted successfully"
                    );
                    window.location.reload();
                  },
                  error => {
                    if (!!error) {
                      !!this.parent.parent && this.parent.parent.setState({
                        snackBarMessage: "Can not delete candidate. Please contact support.",
                        snackBarOpen: true
                      });
                    }
                  }
                )
            }>
            Confirm
          </Button>
        ]
      })
  )
}