import React, {Component, Fragment} from "react";
import {MenuItem, SelectField} from "material-ui";

class CategoryDropdown extends Component {

    render() {
        const {onSave, category} = this.props;

        return <Fragment>
            <p><strong>Pick a Category</strong></p>
            <SelectField
                name="category"
                required={true}
                value={category || 'technicalSkills'}
                width={'180px'}
                maxWidth={'180px'}
                maxHeight={500}
                onChange={(event, index, category) => onSave('category', category)}
                style={{width: "80px"}}
            >
                {['technicalSkills', 'workExperience', 'education', 'others'].map((tag, id) => (
                    <MenuItem key={id} value={tag} primaryText={tag}/>
                ))}
            </SelectField>
        </Fragment>
    }
}

export default CategoryDropdown;
