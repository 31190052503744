/** ============================================ µ
 * @description CandidateNameColumn [JSX]
 *              UI Components and renders
 * @createdAt   2021-09-24 Fri
 * @updatedAt   2021-10-10 Sun
 * ============================================ */
/* IMPORTS ==================================== */

import {
  IconButton
} from "material-ui";
import { MuiThemeProvider } from "material-ui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import withImportantStyle from "react-with-important-style";
import Core from "../../lib/Core";
import { copyString } from "../../lib/GenericTools.lib";
import downloadFile from "../../lib/tools/downloadFile";

/* CONSTANTS ================================== */

const MyPara = withImportantStyle('p');

/* METHODS ==================================== */

export function CandidateNameHeader(props) {
  const { t } = useTranslation();
  return (
    <>
      {t('Name')}
    </>
  );
}

export function CandidateNameCell(props) {
  let {
    row: {
      original: candidate = {}
    },
  } = props;
  const { t } = useTranslation();
  return (
    (candidate.___model___ === 'Candidate')
      ? (
        <MuiThemeProvider>
          <div className="d-flex flex-align-left-top">
            <IconButton
              title={t("Copy Candidate email")}
              iconStyle={candidate.openedColor}
              className="icon16"
              onClick={ev => copyString(candidate.email)}
            >
              <i className="material-icons">mail</i>
            </IconButton>
            &nbsp;
            {!!candidate.phone ? (
              <IconButton
                title="Copy Candidate phone"
                iconStyle={candidate.openedColor}
                className="icon16"
                onClick={ev => copyString(candidate.phone)}
              >
                <i className="material-icons">phone</i>
              </IconButton>
            ) : (
              <div style={{ width: 16, height: 16 }} />
            )}
            &nbsp;
            {!!candidate.resumes.length ? (
              (resume => {
                return (
                  <IconButton
                    className="icon16"
                    onClick={ev =>
                      downloadFile({
                        url: resume.url,
                        mimeType: "application/pdf",
                        onError: error => {
                          Core.failure({ title: 'Download File', exception: error, source: 'CellCandidateNameColumn.jsx', params: { url: resume.url } });
                        }
                      })
                    }
                  >
                    <i className="material-icons">file_download</i>
                  </IconButton>
                );
              })(candidate.resumes[0])
            ) : (
              <div style={{ width: 16, height: 16 }} />
            )}
            &nbsp;
            <NavLink
              title="Go to Edit"
              to={"/candidate/edit/" + candidate.id}
              target={
                candidate.isDuplicate
                  ? '_blank'
                  : ''
              }
            >
              <b>{candidate._name_rating || <i>&mdash;</i>}</b>
              {candidate._isDraft && <MyPara style={{ color: 'red !important' }}>Draft - not submitted yet</MyPara>}
            </NavLink>
          </div>
        </MuiThemeProvider>
      )
      : null
  );
}

/* ============================================ */
