/** ============================================ µ
 * @description LocationSection [JSX]
 *              UI Component
 * @route       /job/edit/:id > Basics
 * @createdAt   2021-06-09
 * @updatedAt   2021-08-09 Mon
 * ============================================ */
/* IMPORTS ==================================== */

import { Input } from 'antd';
import {
  Checkbox,
  RadioButton,
  RadioButtonGroup
} from "material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import Core, { colors } from "../../../lib/Core";
import Definition, {
  LOCATION__AMERICAS,
  LOCATION__CALIFORNIA,
  LOCATION__UNITED_STATES,
  LOC_TYPE__CITY_ID,
  LOC_TYPE__COUNTRY_ID,
  LOC_TYPE__MULTI_CONT_ID,
  LOC_TYPE__STATE_ID,
  RELOCATION__LABELS,
  RELOCATION__NO_ID,
  RELOCATION__UNKNOWN_ID,
  RELOCATION__YES_ID,
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID, LOC_TYPE__METRO_AREA_ID, LOC_TYPE__METRO_AREA_SEC_ID, LOC_TYPE__ALIAS_ID
} from "../../../lib/Definition";
import FilterControlLib from '../../../lib/FilterControl';
import Job from '../../../lib/Job';
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";
import TreeSelectDropdown from "../../Forms/TreeSelect";
import { YES, NOT } from "../../../lib/GenericTools.lib";

/* CONSTANTS ================================== */

const { TextArea } = Input;

/* METHODS ==================================== */

function LocationSection(props) {
  const {
    JobEditController = {}
  } = props;
  const {
    desiredEmploymentTypes = [],
    inOfficeRemoteFlags = [],
    officeLocations = [],
    candidateLocations = [],
    employer = {},
    locationDetails = '',
    relocation = null,
    relocationExtraInfo = '',
  } = JobEditController.state;
  const { t } = useTranslation();
  const {
    candidateLocations: employerCandidateLocations = []
  } = employer;
  // console.debug(JobEditController.state);
  const inOfficeRemoteTags = Definition.get("inOfficeRemote");
  const locationTags = Definition.get("location");
  const desiredEmploymentTypeTags = Definition.get("desiredEmploymentType");
  const candidateLocationTags = [...locationTags];
  if (!!employerCandidateLocations.length) {
    candidateLocationTags.unshift({
      "id": -1,
      "label": "Employer Hiring Locations",
      "type": 1,
      "aliasLocationIds": [...employerCandidateLocations]
    });
  }

  const isRemoteWork = (
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
  );

  const isInOfficeWork = (
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID) ||
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
  );

  /**
   * @todo
   *
   * Review to cleanup
   *
   * epic-3338(new locations)-story-3385 | 2021-07-16 Fri µ
   *
   * covering possible rollback
   *
   */
  function _patch3385(state) {
    /** * /
    {

      remote: 0,              // old field | remote(TagId)
      inOfficeRemoteFlags: [],  // new field | inOfficeRemote(TagId)

      locations: [],          // old field | location(TagId)
      officeLocations: [],    // new field | location(TagId)

    }
    {
      "key": "remote",
      "label": "Remote",
      "values": [
        { "id": 1, "label": "Case by Case" },
        { "id": 2, "label": "No option" },
        { "id": 3, "label": "Policy unknown" },
        { "id": 4, "label": "Full week possible" },
        { "id": 5, "label": "Part week possible" }
      ]
    }
    {
      "label": "Remote preference",
      "key": "inOfficeRemote",
      "values": [
        { "id": 1, "label": "In office full week" },
        { "id": 2, "label": "In office part week" },
        { "id": 3, "label": "100% remote" }
      ]
    }
    {
      "key": "location",
      "values": [
        ...,
        {
          "id": 11,
          "label": "Remote",
          "archived": true,
          "parentId": null
        },
        ...
      ]
    }
    /** */
    if (!!state.desiredEmploymentTypes) {
      const map = {
        1: 6,
        2: 2
      };
      state.jobType = map[state.desiredEmploymentTypes.sort()[0]];
    }
    else if (!!state.inOfficeRemoteFlags) {
      state.remote = (
        state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
          ? 2
          : state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
            ? 5
            : state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
              ? 4
              : 2
      );
      state.locations = JobEditController.state.locations || [];
      state.locations = state.locations.filter(n => n !== 11);
      if (state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)) {
        state.locations.push(11);
      }
    }
    else if (!!state.officeLocations) {
      const SEPARATOR_SECTION = '<p>___________';
      const SEPARATOR_LINE = '<br/>';
      state.privateNotes = JobEditController.state.privateNotes || '';
      state.privateNotes = state.privateNotes.split(SEPARATOR_SECTION)[0] + SEPARATOR_SECTION;
      state.locations = state.officeLocations.filter(officeLocationId => {
        if (1 <= officeLocationId && officeLocationId <= 10) {
          return true;
        }
        else if (12 <= officeLocationId) {
          const officeLocationLabel = FilterControlLib.getItemValue({
            menu: Job.getMenu({ key: 'officeLocations' }),
            itemLabel: Definition.getLabel('location', officeLocationId)
          });
          if (!state.privateNotes?.includes(officeLocationLabel)) {
            state.privateNotes = [
              state.privateNotes,
              `${SEPARATOR_LINE}${officeLocationLabel}`
            ].filter(n => !!n.trim()).join('');
          }
        }
        return false;
      });

      if (JobEditController.state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)) {
        state.locations.push(11);
      }

      state.candidateLocations = JobEditController.state.candidateLocations || [];
      state.candidateLocations.forEach(candidateLocationId => {
        const candidateLocationLabel = FilterControlLib.getItemValue({
          menu: Job.getMenu({ key: 'candidateLocations' }),
          itemLabel: Definition.getLabel('location', candidateLocationId)
        });
        if (!state.privateNotes?.includes(candidateLocationLabel)) {
          state.privateNotes = [
            state.privateNotes,
            `${SEPARATOR_LINE}${candidateLocationLabel}`
          ].filter(n => !!n.trim()).join('');
        }
      });
      if (state.privateNotes.includes(SEPARATOR_SECTION)) {
        state.privateNotes += '</p>';
      }
    }
    else if (!!state.candidateLocations) {
      const SEPARATOR_SECTION = '<p>___________';
      const SEPARATOR_LINE = '<br/>';
      state.privateNotes = JobEditController.state.privateNotes || '';
      state.privateNotes = state.privateNotes.split(SEPARATOR_SECTION)[0] + SEPARATOR_SECTION;

      state.officeLocations = JobEditController.state.officeLocations || [];
      state.officeLocations.forEach(officeLocationId => {
        if (12 <= officeLocationId) {
          const officeLocationLabel = FilterControlLib.getItemValue({
            menu: Job.getMenu({ key: 'officeLocations' }),
            itemLabel: Definition.getLabel('location', officeLocationId)
          });
          if (!state.privateNotes?.includes(officeLocationLabel)) {
            state.privateNotes = [
              state.privateNotes,
              `${SEPARATOR_LINE}${officeLocationLabel}`
            ].filter(n => !!n.trim()).join('');
          }
        }
      });

      state.candidateLocations.forEach(candidateLocationId => {
        const candidateLocationLabel = FilterControlLib.getItemValue({
          menu: Job.getMenu({ key: 'candidateLocations' }),
          itemLabel: Definition.getLabel('location', candidateLocationId)
        });
        if (!state.privateNotes?.includes(candidateLocationLabel)) {
          state.privateNotes = [
            state.privateNotes,
            `${SEPARATOR_LINE}${candidateLocationLabel}`
          ].filter(n => !!n.trim()).join('');
        }
      });

      if (state.privateNotes.includes(SEPARATOR_SECTION)) {
        state.privateNotes += '</p>';
      }

    }
    // epic-3038(new locations)-story-3705-m2 | 2021-08-06 Fri µ
    else if (!!state.locationDetails && !!state.locationDetails.trim()) {
      state.remoteExtraInfo = state.locationDetails;
    }

    console.debug('_patch3385', state);

    return state;
  }

  console.debug('JobEditController.state', JobEditController.state);

  return (
    <div
      ref={self => Core.setKeyValue('location-section', self)}
      className="bg-a-05 pb-2"
    /** @todo experimental 2021-07-15 Thu µ */
    /** * /
    onClick={ev => Core.getKeyValue('location-section')?.scrollIntoView({
      behavior: 'smooth'
    })}
    /** */
    >

      <Row>
        <Col fullWidth>
          <label>
            {t('job.desiredEmploymentTypes.label')}
          </label>
          <div className="d-flex my-2">
            {desiredEmploymentTypeTags.map((item) => (
              <Checkbox
                key={item.id}
                label={item.label}
                checked={desiredEmploymentTypes.includes(item.id)}
                onCheck={(ev, checked) => {
                  JobEditController.setState(
                    _patch3385(
                      {
                        desiredEmploymentTypes: !!checked
                          ? [...desiredEmploymentTypes, item.id]
                          : desiredEmploymentTypes.filter((n) => n !== item.id),
                      }
                    )
                  );
                }}
              />
            ))}
            <div className="w-100">&nbsp;</div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col fullWidth>
          <label>
            {t('job.inOfficeRemoteFlags.label')}
            <b style={{ color: "orange" }}>*</b>
          </label>
          <div className="d-flex my-2">
            {inOfficeRemoteTags.map((tag) => (
              <Checkbox
                key={tag.id}
                label={tag.label}
                checked={inOfficeRemoteFlags.includes(tag.id)}
                onCheck={(ev, checked) => {
                  JobEditController.setState(
                    /* epic-3338(new locations)-story-3385 | 2021-07-16 Fri µ */
                    _patch3385(
                      {
                        inOfficeRemoteFlags: !!checked
                          ? [...inOfficeRemoteFlags, tag.id]
                          : inOfficeRemoteFlags.filter((n) => n !== tag.id)
                      }
                    ),
                    then => {
                      const state = JobEditController.state;
                      let update;
                      if (
                        NOT(
                          state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
                        )
                      ) {
                        update = {};
                        update.candidateLocations = [];
                      }
                      if (
                        NOT(
                          state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID) ||
                          state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
                        )
                      ) {
                        update = update || {}
                        update.officeLocations = [];
                      }
                      YES(update) && JobEditController.setState(_patch3385(update));
                    }
                  );
                }}
              />
            ))}
          </div>
        </Col>
      </Row>

      <Row>

        <Col>
          <label>{t('job.officeLocations.label')}</label>
          {(NOT(officeLocations.length) && YES(isInOfficeWork))
            ? (<span className="c-red">{t('job.officeLocations.danger')}</span>)
            : (<span className="c-gray">{t('job.officeLocations.subtitle')}</span>)
          }
          <div
            className="hint--top hint--rounded w-100"
            aria-label={NOT(isInOfficeWork) ? t('job.officeLocations.disabled') : ''}
          >
            <TreeSelectDropdown
              disabled={!isInOfficeWork}
              data={locationTags}
              layers={[
                LOC_TYPE__MULTI_CONT_ID,
                LOC_TYPE__COUNTRY_ID,
                LOC_TYPE__STATE_ID,
                LOC_TYPE__CITY_ID
              ]}
              value={officeLocations}
              treeDefaultExpandedIds={[
                LOCATION__AMERICAS,
                LOCATION__UNITED_STATES,
                LOCATION__CALIFORNIA
              ]}
              onChange={({ value: officeLocations }) => {
                JobEditController.setState(
                  /* epic-3338(new locations)-story-3385 | 2021-07-16 Fri µ */
                  _patch3385(
                    { officeLocations }
                  )
                );
              }}
              className="my-1"
            />
          </div>
        </Col>

        <Col>
          <label>{t('job.candidateLocations.label')}</label>
          {(NOT(candidateLocations.length) && YES(isRemoteWork))
            ? (<span className="c-red">{t('job.candidateLocations.danger')}</span>)
            : (<span className="c-gray">{t('job.candidateLocations.subtitle')}</span>)
          }
          <div
            className="hint--top hint--rounded w-100"
            aria-label={NOT(isRemoteWork) ? t('job.candidateLocations.disabled') : ''}
          >
            <TreeSelectDropdown
              disabled={!isRemoteWork}
              data={candidateLocationTags}
              layers={[
                LOC_TYPE__ALIAS_ID,
                LOC_TYPE__MULTI_CONT_ID,
                LOC_TYPE__COUNTRY_ID,
                LOC_TYPE__STATE_ID,
                LOC_TYPE__METRO_AREA_ID,
                LOC_TYPE__METRO_AREA_SEC_ID,
                LOC_TYPE__CITY_ID
              ]}
              value={candidateLocations}
              treeDefaultExpandedIds={[
                LOCATION__AMERICAS,
                LOCATION__UNITED_STATES
              ]}
              onChange={({ value: candidateLocations }) => {
                JobEditController.setState(
                  /* epic-3338(new locations)-story-3385 | 2021-07-16 Fri µ */
                  _patch3385(
                    { candidateLocations }
                  )
                );
              }}
              className="my-1"
            />
          </div>
        </Col>

      </Row>

      {/* epic-3038-story-3326 | 2021-07-01 µ */}
      <Row>
        <Col fullWidth>
          <label>{t('job.locationDetails.label')}</label>
          <TextArea
            autoSize={{ minRows: 3, maxRows: 6 }}
            placeholder="Type here"
            value={locationDetails}
            style={{ fontSize: "13px" }}
            onChange={event =>
              JobEditController.setState(
                _patch3385(
                  {
                    locationDetails: event.target.value
                  }
                )
              )
            }
          />
        </Col>
      </Row>

      {
        /**
         * RELOCATION ASSISTANCE
         */
      }
      <Row className="mt-2">
        <Col fullWidth>
          <label className="mb-2">{t('job.relocation.label')}</label>
          <RadioButtonGroup
            name="relocation"
            style={{ display: "flex", flexWrap: "nowrap" }}
            valueSelected={relocation}
            onChange={(event, relocation) =>
              JobEditController.setState({ relocation })
            }
            fullWidth
          >
            {[
              { id: RELOCATION__YES_ID, label: RELOCATION__LABELS[RELOCATION__YES_ID] },
              { id: RELOCATION__NO_ID, label: RELOCATION__LABELS[RELOCATION__NO_ID] },
              { id: RELOCATION__UNKNOWN_ID, label: RELOCATION__LABELS[RELOCATION__UNKNOWN_ID] },
            ].map(({ id, label }) => (
              <RadioButton
                value={id}
                label={label}
                style={{ marginBottom: 16 }}
                labelStyle={{
                  marginLeft: -10,
                  color: colors.gray,
                  fontSize: 12,
                }}
              />
            ))
            }
          </RadioButtonGroup>
          <label className="mb-2">
            {t('job.relocationExtraInfo.label')}
          </label>
          <TextArea
            autoSize={{ minRows: 3, maxRows: 6 }}
            placeholder="Specify additional information"
            value={relocationExtraInfo}
            style={{ fontSize: "13px" }}
            onChange={event =>
              JobEditController.setState({
                relocationExtraInfo: event.target.value
              })
            }
          />
        </Col>
      </Row>

    </div>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export {
  LocationSection as default,
  LocationSection,
};

/* ============================================ */
