import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { NavLink } from "react-router-dom";
import Core from '../../lib/Core';

export const moreMenuOptionsAccounts = [
  {
    acl: account => (Core.isAdmin() || (Core.isRecruiter() && Core.isOnDev())),
    render: account => (
      <NavLink
        to={"/v3/account/edit/" + account.id}
        className="navlink"
        key={`more-options-menu-option-${account.id}-1`}
      >
        <MenuItem>Edit Account</MenuItem>
      </NavLink>
    )
  }
];
