import React, {Fragment, Component} from "react";

/* Material UI components */
import Icon from '@mui/material/Icon';

import Core from "../../../../lib/Core";
import EngagementDisagreement from "../../../../lib/EngagementDisagreement";
import {engagementMatchStrength} from "../../../../lib/Constants";
import Definition from "../../../../lib/Definition";

const thumbSelectedStyle = {cursor: 'pointer', color:"gray"};
const thumbSimpleStyle = {cursor: 'pointer'};

class JobCardThumbsActions extends Component {

    constructor(){
        super(...arguments);
        this.state = {
            matchDecisionId: "",
        };
    }

    handlerClick = (cb, value) => (ev) => {
        let {matchDecisionId} = this.state;
        matchDecisionId = value;
        this.setState({matchDecisionId});
        !!cb && cb(ev,value);
    }

    componentDidMount() {
        const {candidateId, jobId} = this.props;
        const currentUser = Core.getSessionEmail();
        
        if(!!candidateId && !!jobId) {
            EngagementDisagreement.get((resp) => {
                const filterCurrentUser = resp.filter(decision => !!decision.annotator && decision.annotator === currentUser);
                const filterOtherUsers = resp.filter(decision => !!decision.annotator && decision.annotator !== currentUser);
                const lastResponse = filterCurrentUser.pop();
                const matchDecision = !!lastResponse && !!lastResponse.matchDecision ? lastResponse.matchDecision : "";
                const matchDecisionId = Definition.getId("engagementMatchStrength", matchDecision);
                this.setState({matchDecisionId}, () => {
                    const {handlerMlDecisionMaking} = this.props;
                    !!handlerMlDecisionMaking && handlerMlDecisionMaking(false, filterOtherUsers);
                });
            },
            {
                where: {
                    and: [
                        {candidateId: candidateId},
                        {jobId: jobId},
                        {annotator: {neq: 'ML'}}
                    ]
                },
                fields: [
                    'annotator',
                    'updatedAt',
                    'matchDecision',
                ]
            })
        }
    }

    render() {
        let {handlerThumbsUp, handlerThumbsDown} = this.props;
        const {matchDecisionId} = this.state;
        
        return (
            <Fragment>
                <Fragment>
                    <Icon 
                        style={!!matchDecisionId && matchDecisionId === engagementMatchStrength.STRONG_NO ? thumbSelectedStyle : thumbSimpleStyle} 
                        color="error"
                        onClick={this.handlerClick(handlerThumbsDown, engagementMatchStrength.STRONG_NO)}>
                        thumb_down
                    </Icon>&nbsp;
                    <Icon 
                        style={!!matchDecisionId && matchDecisionId === engagementMatchStrength.NO ? thumbSelectedStyle : thumbSimpleStyle} 
                        color="error"
                        onClick={this.handlerClick(handlerThumbsDown, engagementMatchStrength.NO)}>
                        thumb_down
                    </Icon>&nbsp;
                </Fragment>
                &nbsp;&nbsp;
                <Fragment>
                    <Icon 
                        style={!!matchDecisionId && matchDecisionId === engagementMatchStrength.WEAK_YES ? thumbSelectedStyle : thumbSimpleStyle} 
                        color="success"
                        onClick={this.handlerClick(handlerThumbsUp, engagementMatchStrength.WEAK_YES)}>
                        thumb_up
                    </Icon>&nbsp;
                    <Icon 
                        style={!!matchDecisionId && matchDecisionId === engagementMatchStrength.YES ? thumbSelectedStyle : thumbSimpleStyle} 
                        color="success"
                        onClick={this.handlerClick(handlerThumbsUp, engagementMatchStrength.YES)}>
                        thumb_up
                    </Icon>&nbsp;
                    <Icon 
                        style={!!matchDecisionId && matchDecisionId === engagementMatchStrength.STRONG_YES ? thumbSelectedStyle : thumbSimpleStyle} 
                        color="success"
                        onClick={this.handlerClick(handlerThumbsUp, engagementMatchStrength.STRONG_YES)}>
                        thumb_up
                    </Icon>
                </Fragment>
            </Fragment>
        );
    }
}

export default JobCardThumbsActions;
