function getStateModel(state, model) {
  const newModel = {};
  Object.keys(model).forEach(key => {
    if (state[key] === "") {
      // newModel[key] = undefined; // this was to prevent save empty values
      newModel[key] = null; // by the moment we need to save null values in db.
      // TODO improve the updates logic.
    } else if (state[key] === "<p><br></p>" || state[key] === "null") {
      newModel[key] = null;
    } else if (
      typeof state[key] === "boolean" ||
      state[key] === 0 ||
      key === "visaTransfer" ||
      key === "holdDate" ||
      !!state[key]
    ) {
      newModel[key] =
        typeof state[key] === "string" ? state[key].trim() : state[key];
    }
  });
  return newModel;
}
export default getStateModel;
