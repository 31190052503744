/** ============================================ µ
 * @description MatchLocationChips [JSX]
 *              UI Component
 * @createdAt   2021-09-01 Wed
 * @updatedAt   2021-09-01 Wed
 * story-3945-m1
 * ============================================ */
/* IMPORTS ==================================== */

// import { Chip } from "material-ui";
import Chip from "@mui/material/Chip";
import React from "react";
import Candidate from "../../lib/Candidate";
import Definition from "../../lib/Definition";
import LocationLib from "../../lib/DefinitionLocation.lib";
import FilterControlLib from "../../lib/FilterControl";
import Job from "../../lib/Job";
import { getCandidateModel } from "../../lib/models/candidate";
import { getJobModel } from "../../lib/models/job";
import { makeDecision } from "../Candidates/MatchStrategy/Decision/Decision";

/* CONSTANTS ================================== */

export const MATCH_LOCATION_CHIPS__JD_REQUESTER = 'job-details';
export const MATCH_LOCATION_CHIPS__CD_REQUESTER = 'candidate-details';

/* METHODS ==================================== */

/**
 * 
 * @param {object} props
 * @param {string} props.requester job-details | candidate-details
 * @param {object} props.job
 * @param {object} props.candidate
 * @returns 
 */
function MatchLocationChips({ requester, job, candidate }) {

  job = job || getJobModel({ extended: true });
  candidate = candidate || getCandidateModel({ extended: true });

  // console.debug({ job, candidate, requester });

  let main, matcher, officeLocationsMenu, candidateLocationsMenu;

  if (requester === MATCH_LOCATION_CHIPS__JD_REQUESTER) {
    main = job;
    matcher = candidate;
    officeLocationsMenu = Job.getMenu({ key: 'officeLocations' });
    candidateLocationsMenu = Job.getMenu({ key: 'candidateLocations' });
  }
  else if (requester === MATCH_LOCATION_CHIPS__CD_REQUESTER) {
    main = candidate;
    matcher = job;
    officeLocationsMenu = Candidate.getMenu({ key: 'officeLocations' });
    candidateLocationsMenu = Candidate.getMenu({ key: 'candidateLocations' });
  }

  let _officeLocationsTags = Definition.getTags({
    categoryKey: 'location',
    tagIds: main.officeLocations,
  });

  let _candidateLocationsTags = Definition.getTags({
    categoryKey: 'location',
    tagIds: main.candidateLocations,
  });

  // console.debug({ main, matcher, officeLocationsMenu, candidateLocationsMenu, _officeLocationsTags, _candidateLocationsTags });

  return (
    <>
      {/* epic-3338(new locations)-story-3392 | 2021-07-12 µ */}
      {_officeLocationsTags.map(({ id: memberA, label }) => (
        <Chip
          key={`chip-office-location-${label}`}
          className="ui-match-details-chip-office-location slim-chip"
          variant="outlined"
          size="small"
          style={makeDecision(
            !!matcher.officeLocations.find(memberB =>
              LocationLib.evalLineage({ memberA, memberB })
            )
          )}
          label={
            FilterControlLib.getItemValue({
              menu: officeLocationsMenu,
              itemLabel: label
            })
          }
        />
      ))}

      {/* epic-3338(new locations)-story-3392 | 2021-07-12 µ */}
      {_candidateLocationsTags.filter(v => !!v).map(({ id: memberA, label }) => (
        <Chip
          key={`chip-candidate-location-${label}`}
          className="ui-match-details-chip-candidate-location slim-chip"
          variant="outlined"
          size="small"
          style={makeDecision(
            !!matcher.candidateLocations.find(memberB =>
              LocationLib.evalLineage({ memberA, memberB })
            )
          )}
          label={
            FilterControlLib.getItemValue({
              menu: candidateLocationsMenu,
              itemLabel: label
            })
          }
        />
      ))}
    </>
  );

}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { MatchLocationChips as default, MatchLocationChips };

/* ============================================ */
