import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import Col from "../Forms/Col";
import Row from "../Forms/Row";

class InputArray extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      actualArray: this.props.existing || [],
      updatedArray: this.props.existing || [],
      elementComp: this.props.elementComp,
      openOperatorOptions: false
    };
  }

  componentDidUpdate(prevProps, prevState) {

    // only update chart if the data has changed
    if (prevProps.existing !== this.props.existing) {
      this.setState({
        actualArray: this.props.existing || [],
        updatedArray: this.props.existing || [],
        elementComp: this.props.elementComp
      });
    }
  }

  handlerFieldOnBlur = (element) => {
    let arr = this.state.updatedArray;
    let myIndex = arr.findIndex(el => parseInt(el.id) === parseInt(element.id));
    arr[myIndex] = element;

    this.setState({ updatedArray: arr }, st => {
      !!this.props.parentUpdater && this.props.parentUpdater(this.state.updatedArray)
    });
  }

  handlerDeleteField = (element) => {
    let arr = this.state.updatedArray;
    let newArray = arr.filter(el => parseInt(el.id) !== parseInt(element.id));

    this.setState({ updatedArray: newArray }, st => {
      this.props.parentUpdater && this.props.parentUpdater(this.state.updatedArray)
    });
  }

  markAsDefault = (element) => {
    let arr = _.cloneDeep(this.state.updatedArray);
    let newArray = [];

    arr.forEach(el => {
      let newElement = {};

      if (element.id === el.id) {
        newElement = { ...element, isDefault: el.id === element.id };

      } else {
        newElement = { ...el, isDefault: el.id === element.id };

      }

      newArray.push(newElement);
    })

    this.setState({ updatedArray: newArray }, st => {
      !!this.props.parentUpdater && this.props.parentUpdater(this.state.updatedArray);
      !!this.props.onMarkAsDefault && this.props.onMarkAsDefault(element);
    });

  }

  dataToFields() {
    const dataArray = this.state.updatedArray;
    const ElementComp = this.state.elementComp;
    const newRecordId = _.cloneDeep(this.state.newRecordId);
    const { onlyView, extraInfo } = this.props;

    if (!ElementComp) {
      // !!this.props.parentUpdater && this.props.parentUpdater(this.state.updatedArray);
      return;
    }

    const out = dataArray.map((el, index) => {
      if (!el.id) {
        el.id = index;
      }
      return <ElementComp
        handlerDeleteItem={this.handlerDeleteField}
        element={el}
        key={el.timestampTemporary || el.id || index}
        isNewRecord={el.id === newRecordId}
        onDone={this.handlerFieldOnBlur}
        markAsDefault={this.markAsDefault}
        onlyView={onlyView}
        extraInfo={extraInfo}
      />;
    });

    if (!!newRecordId) {
      this.setState({ newRecordId: '' });
    }
    return out;
  }

  insertOneToArray = (props = {}) => {
    // const dataArray = this.state.updatedArray;
    let newRecordId = (new Date()).getTime();
    this.setState({ updatedArray: [...this.state.updatedArray, { id: newRecordId, ...props }], newRecordId })
  }

  handlerOnAdd = () => {
    let { createWithPopup } = this.props;

    if (!!createWithPopup) {
      this.setState({ createWithPopup: true });
    } else {
      this.insertOneToArray()
    }
  }

  handlerPopupClose = () => {
    let { createWithPopup } = this.state;
    this.setState({ createWithPopup: !createWithPopup });
  }

  handlerPopupApply = (props) => {
    let { createWithPopup } = this.state;
    this.insertOneToArray(props);

    this.setState({ createWithPopup: !createWithPopup }, () => {
      !!this.props.parentUpdater && this.props.parentUpdater(this.state.updatedArray)
    });
  }

  render() {

    let { heading, CreatePopupComponent, onlyView, extraInfo } = this.props;
    let { createWithPopup } = this.state;

    return <Fragment>
      {!onlyView && <Grid item xs={12} ><Row>
        <Col fullWidth>
          {/* <label> */}
          {heading}
          <Fab onClick={this.handlerOnAdd} color="primary" aria-label="Add"
            style={{ width: '35px', marginLeft: '5px', height: '15px', boxShadow: 'none' }}>
            <AddIcon />
          </Fab>
          {/* </label> */}
        </Col>
      </Row></Grid>}
      {this.dataToFields()}

      {createWithPopup && <CreatePopupComponent
        onCancel={this.handlerPopupClose}
        onApply={this.handlerPopupApply}
        extraInfo={extraInfo}
      />}
    </Fragment>

  }
}

export default InputArray;
