/** ============================================ µ
 * @description FlatListMenu [JSX]
 *              UI Component
 * @path        main-list-pages
 * @createdAt   2021-07-05
 * @updatedAt   2021-07-08
 * ============================================ */
/* IMPORTS ==================================== */

import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import React from "react";
import FilterControlLib from "../../../lib/FilterControl";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function FlatListMenu(props) {
  const {
    menu,
    FilterControlController,
    SelectTagDialogController
  } = props;
  const {
    state
  } = SelectTagDialogController;
  return (
    <>
      {state.labels
        .filter(label =>
          new RegExp(
            String(state.search).replace("+", "\\+"),
            "i"
          ).test(label)
        ).map((label) => {
          const value = label;
          const labelId = `checkbox-list-label-${value}`;

          return (
            <ListItem key={`listItem${label}`} role={undefined} dense button
              onClick={() => {
                SelectTagDialogController.setState(
                  state => {
                    state.menu.items[label] = !state.menu.items[label];
                    return state;
                  },
                  then => {
                    // used setTimeout to improve UI delay, but this might affect filter results - i tested on my records count and it seems to be fine
                    // delay is there because UI waits till we update our arrays and then it re-renders
                    // i delegated this processing (happening in FilterControlController's callback) on Event Loop (using setTimeout) and didn't block current UI re-rendering.
                    setTimeout(() => {
                      FilterControlController.checkLocations({
                        label,
                        checked: state.menu.items[label],
                        cb: then =>
                          FilterControlController.jiraVER20({
                            label,
                            menuKey: state.menu.key,
                            checked: state.menu.items[label],
                            cb: FilterControlController.onChange(),
                          })
                      })
                    })
                  }
                )
              }}
            >
              <ListItemIcon style={{ minWidth: 'inherit' }}>
                <Checkbox
                  edge="start"
                  checked={state.menu.items[label]}
                  tabIndex={-1}
                  color="primary"
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={FilterControlLib.getItemLabel({
                  menu,
                  itemValue: label
                })}
              />
            </ListItem>
          );
        })}
    </>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { FlatListMenu as default, FlatListMenu };

/* ============================================ */
