/** ============================================ µ
 * @description TagAttributeList [JSX]
 *              UI Component
 * @route       /account/edit/:accountId
 * @createdAt   2021-06-01
 * @updatedAt   2021-06-08
 * ============================================ */
/* IMPORTS ==================================== */

import React from "react";
import { LOC_TYPE__ALIAS_ID } from "../../lib/Definition";
import TagAttributeItem from "./TagAttributeItem";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function TagAttributeList(props) {

  const {
    category = {},
    tag = {},
    onChange = () => { },
  } = props;

  const {
    archived
  } = tag;

  const attributesList = [];

  if (!archived && category.key === 'location') {
    attributesList.push('type');
    if (!tag.type || tag.type === LOC_TYPE__ALIAS_ID) {
      attributesList.push('aliasLocationIds');
    }
    else if (!!tag.type) {
      attributesList.push('parentId');
    }
  }

  function _onChange(_attributeName) {
    return ({ attributeName, value }) => {
      tag[attributeName] = value;
      onChange(tag);
    }
  }

  return (
    <>
      {attributesList.map((_attributeName, index) => (
        <TagAttributeItem
          key={`${tag.id}+${_attributeName}`}
          category={category}
          tag={tag}
          attributeName={_attributeName}
          value={tag[_attributeName]}
          onChange={_onChange(_attributeName)}
        />
      ))}
    </>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export {
  TagAttributeList as default,
  TagAttributeList,
}

/* ============================================ */
