import React, {Component, Fragment} from "react";

/* Lib files */
import Requirements from "./Requirements";
import RequirementsSection2 from "./RequirementsSection2";
import RequirementsSection3 from "./RequirementsSection3";
import SubmissionHistory from "./SubmissionHistory";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

class JobPipe extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      candidateResume: null
    }
  }

  componentDidMount() {
    const {candidate} = this.props;
    if (!!candidate) {
      this.fetchResume(candidate.resumeTxtUrl);
    }
  }

  componentWillUpdate(nextP, nextS) {
    if (!!nextP.candidate && nextP.candidate !== this.props.candidate) {
      if (!!nextP.candidate.resumeTxtUrl) {
        this.fetchResume(nextP.candidate.resumeTxtUrl);
      }
    }
  }

  fetchResume = (resume) => {
    fetch(resume).then(responseText => {
      responseText.text().then(text => {
        this.setState({candidateResume: text});
      });
    });
  }

  render() {
    const {
      candidate,
      selectedJob,
      saveContentJob,
      stringToChips,
      source,
      jobPipeHeader
    } = this.props;
    const {candidateResume} = this.state;

    if (!selectedJob) {
      return <p className="match-new job-attributes" style={{textAlign: "center"}}>No active matched jobs to process
        !!!</p>
    }

    return <Fragment>
      {jobPipeHeader(selectedJob, candidate)}
      <Grid item xs={12}
            style={{
              height: 'calc(100vh - 310px)',
              overflow: "scroll"
            }}>
        {<Requirements
          selectedJob={selectedJob}
          saveContentJob={saveContentJob}
          candidate={candidate}
          stringToChips={stringToChips}
          candidateResume={candidateResume}
          source={source}
        />}
        <br/>
        {!!selectedJob && <RequirementsSection2
          selectedJob={selectedJob}
          stringToChips={stringToChips}
        />}
        <br/>
        <RequirementsSection3
          candidate={candidate}
          selectedJob={selectedJob}
        />
        <br/>
        {(selectedJob.mustHaveQuestions || []).length &&
        <Fragment>
          <Card className="match-new section-card" style={{boxShadow: '0px 0px 10px #888888'}}>
            <CardContent className="match-new card-content">
              <Fragment>
                <div className="match-new heading-center">
                  PRE-SCREEN QUESTIONS
                </div>
                <hr/>
              </Fragment>

              <ol>
                {(selectedJob.mustHaveQuestions || []).map(q => <li>{q.question}</li>)}
              </ol>
            </CardContent>
          </Card>
          <br/>
        </Fragment>
        }
        {!!selectedJob && <SubmissionHistory
          job={selectedJob}
        />}
      </Grid>
    </Fragment>
  }
}

export default JobPipe;