/** ============================================ µ
 * @Description CandidateResumeSubmission [JS]
 *              UI Component
 * @route        /candidate/resume-submission/:candoId/:jobId
 * @createdAt   2018-05-24
 * @updatedAt   2021-09-10 Fri
 * ============================================ */

/* IMPORTS ==================================== */

import { Button, Link } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { compile } from "handlebars";
import {
  AppBar,
  Checkbox,
  Divider,
  FlatButton,
  IconButton,
  MenuItem,
  Paper,
  SelectField,
  Snackbar,
  Table,
  TableBody,
  TableRow,
  TableRowColumn
} from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import moment from "moment";
import React, { Component, Fragment } from "react";
import ReactDOMServer from "react-dom/server";
import { Redirect } from "react-router";
import { NavLink } from "react-router-dom";
import Candidate from "../../../lib/Candidate";
import { jobLevels } from "../../../lib/Constants";
import Core, { colors } from "../../../lib/Core";
import Definition, {
  EMP_MSG_TYPE__SUBMISSION_ID,
  EMP_SUB_MET__EMAIL_ID,
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID
} from "../../../lib/Definition";
import LocationLib from "../../../lib/DefinitionLocation.lib";
import Engagement from "../../../lib/Engagement";
import { getCandidateModel } from "../../../lib/models/candidate";
import { mapEngagement } from "../../../lib/models/engagement";
import School from "../../../lib/School";
import { sendSafeEmail } from "../../../lib/services/Email/Email.lib";
import githubGraphqlGetUser from "../../../lib/services/GitHub/githubGraphqlGetUser";
import Store from "../../../lib/Store";
import Streak from "../../../lib/Streak";
import TemplateLib from "../../../lib/Template.lib";
import cleanHTML from "../../../lib/tools/cleanHtml";
import copyHTML from "../../../lib/tools/copyHtml";
import copyText from "../../../lib/tools/copyText.tool";
import cvMatch from "../../../lib/tools/cvMatch";
import downloadFile from "../../../lib/tools/downloadFile";
import formatURL from "../../../lib/tools/formatURL";
import getBinary from "../../../lib/tools/getBinary";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";
import styles from "../../Forms/Styles";
import RichTextBox from "../../Forms/RichTextBox";
import MatchDrawer from "../../Shared/Match/MatchDrawer";
import DoubleContentDrawer from "../MatchNew/JobPipe/DoubleContentDrawer";
import AtsPreviewBlock from "./AtsPreviewBlock";

/* CONSTANTS ================================== */

const SUBMISSION_EMAIL_BODY_TEMPLATE = cleanHTML(`
<html>
  <head></head>
  <body>
    <p>
      Hi {{#if receiverNames}}{{{receiverNames}}}{{else}}[INSERT RECEIVER'S NAME]{{/if}} - Please review {{candidate.firstName}} {{candidate.lastName}}.
    </p>
    <p>
      If you would like to interview the candidate, 
    </p>
    <ul>
      <li>please reach out to the candidate to ask for an interview mentioning 10 By 10</li>
      <li>we recommend you include a calendar link or availability to reduce scheduling ping pong</li>
      <li>as we discussed, please CC <a href="mailto:recruiter@10by10.io">recruiter@10by10.io</a> for all candidate communication!</li>
    </ul>
    <p>
      Please provide us feedback on WHY you decide to accept or reject the candidate.
    </p>
    {{#if _subjectPrefix}}
      <p>
        <b>
          <u>
            {{_subjectPrefix}}:
          </u>
        </b>
      </p>
    {{/if}}
    {{#if includeSubmissionNotes}}
      <p>
        {{{candidate.submissionNotes}}}
      </p>
    {{/if}}
    {{#if includeSubmissionNotesToEmployer}}
      <p>
        {{{candidate.submissionNotesToEmployer}}}
      </p>
    {{/if}}
    <ul>
      @@allPositiveSignals@@
      @@techSkillsWithCV@@
      @@xYearsExperience@@
      @@level@@
      @@githubStats@@
    </ul>
    {{#if includeJobSubmissionNotes}}
      <p>
        {{{jobSubmissionNotes}}}
      </p>
    {{/if}}
    <ul>
      {{#if candidate.currentJobTitle}}
        {{#if candidate.currentPositionCompany}}
          <li>
            Current Title/Company:
            {{candidate.currentJobTitle}}
            @
            {{candidate.currentPositionCompany}}
          </li>
        {{else}}
          <li>
            Current Title/Company:
            {{candidate.currentJobTitle}}
          </li>
        {{/if}}
      {{else if candidate.currentPositionCompany}}
        <li>
          Current Title/Company: @
          {{candidate.currentPositionCompany}}
        </li>
      {{/if}}
      {{#if _normalCollegeFlow}}
        {{{_normalCollegeFlow}}}
      {{/if}}
      {{#if candidate._technicalSkills}}
        <li>
          Strongest Tech:
          {{candidate._technicalSkills}}
        </li>
      {{/if}}
      {{#if _inOfficeRemoteFlagsLabels}}
        <li>
          Can work:
          {{_inOfficeRemoteFlagsLabels}}.
        </li>
      {{/if}}
      {{#if _candidateLocationLabel}}
        <li>
          Remotely from:
          {{_candidateLocationLabel}}.
        </li>
      {{/if}}
      {{#if _officeLocationsLabels}}
        <li>
          In Office:
          {{_officeLocationsLabels}}.
        </li>
      {{/if}}
      {{#if isNotVisaUnknown}}
        <li>
          Work Authorization:
          {{candidate._visa}}
        </li>
      {{/if}}
      {{#if candidate.jobHopReason4Emp}}
        <li>
          Frequent job change explanation:
          {{candidate.jobHopReason4Emp}}
        </li>
      {{/if}}
      {{#if candidate.salarySharableFlag}}
        <li>
          Compensation expectation:
          {{candidate._minimumSalary}}
          {{#if candidate.salaryNote}}
          - {{candidate.salaryNote}}
          {{/if}}
        </li>
      {{/if}}
      {{#if candidate.linkedInURL}}
        <li>
          LinkedIn:
          <a href={{candidate.linkedInURL}}>
            {{candidate.linkedInURL}}
          </a>
        </li>
      {{/if}}
      <li>
        Email:
        {{candidate.email}}
      </li>
      <li>
        Phone:
        {{candidate.phone}}
      </li>
      {{#unless isEmployerAts}}
        {{#if hasResume}}
          <li>
            Resume Attached
          </li>
        {{/if}}
      {{/unless}}
    </ul>
    <p>
      If rejection, quick feedback -
    </p>
    <ol>
      <li>
        Work history - company size | industry
      </li>
      <li>
        Level - too junior, too senior
      </li>
      <li>
        Technical experience
      </li>
      <li>
        Has stronger candidates in the pipeline
      </li>
      <li>
        Other - why
      </li>
    </ol>
    <p>
      [SIGNATURE]
    </p>
  </body>
</html>
`);

const COPIED_ATTRS = [
  "firstName",
  "lastName",
  "email",
  "phone",
  "linkedInURL",
  "gitHubURL",
];

/* COMPONENT CLASS ============================ */

class CandidateResumeSubmission extends Component {

  confirm = false;

  constructor() {
    super(...arguments);

    this.state = {
      ...getCandidateModel({ extended: true }),
      includeSubmissionNotes: false,
      includeSubmissionNotesToEmployer: true,
      /** controller states */
      snackBarMessage: "",
      snackBarOpen: false,
      sending: false,
      cvMatchArrived: "",
      githubStats: null,
      // ranking: [],
      openCollapseView: true
    };
    Store.set("path", window.location.href);

    /**
     * @todo
     * Look for improvements
     * 2021-05-17 µ
     */
    Core.setKeyValue('CandidateResumeSubmissionController', this);

    setTimeout((st) => {
      /** LOAD CANDIDATE DATA to the current state */
      if (this.props.match.params.id) {
        Candidate.get(this.props.match.params.id, candidate => {

          githubGraphqlGetUser(candidate.gitHubURL, (result) => {
            if (
              !!result &&
              !(
                result.followers >= 11 &&
                result.stars >= 15 &&
                result.totalCommits >= 20
              )
            ) {
              result = null;
            }
            this.setState({ githubStats: result });
            this.setEmailPreviewState();
          });

          Candidate.getPotentialDuplicatedWithOwnerships(
            { id: candidate.id },
            (result) => {
              // const dupOwnership = result.find(cando=> cando.isPotentialStrongConflict && cando.)
              this.setState({ ...candidate, candidate }, (then) => {
                this.SubmissionNotes &&
                  this.SubmissionNotes.setValue(candidate.submissionNotes);

                if (
                  !!this.state.resumeTxtUrl &&
                  !!this.state.selectedJob &&
                  !!this.state.technicalSkills
                ) {
                  fetch(this.state.resumeTxtUrl).then((responseText) => {
                    responseText.text().then((text) => {
                      const allSkill = [
                        ...Object.values(
                          Definition.getAll().technicalSkillsIds
                        ),
                        ...this.state.technicalSkills,
                      ];
                      const skills = Array.from(
                        new Set(cvMatch(allSkill, text))
                      );
                      const matchWithJobSkills = skills.filter((job) =>
                        this.state.selectedJob.technicalSkills.includes(job)
                      );
                      const finalJobs = Definition.getLabels(
                        "technicalSkills",
                        matchWithJobSkills
                      ).join(", ");
                      this.setState({ cvMatchArrived: finalJobs }, () => {
                        this.setEmailPreviewState();
                      });
                    });
                  });
                }

                if (!!this.state.resumes.length) {
                  this.setState({
                    selectedResumeRow: 0,
                    selectedResume: this.state.resumes[0],
                  });
                }
                if (!!candidate.engagements.length) {
                  this.candidateEngagementsProcessing(this.props.match.params.jobId);
                }
              });
            }
          );
        });
      }
    });
  }
  candidateEngagementsProcessing = async (selectedJobId, cb = () => { }) => {
    const model = this.state.candidate;
    let selectedJob = null;
    let jobs = [];

    const { mixins: jobEnvelope } = await TemplateLib.getEnvelope({
      messageTypes: [EMP_MSG_TYPE__SUBMISSION_ID],
      jobId: selectedJobId
    });

    model.engagements.forEach((element) => {
      if (
        /open/i.test(element.state) === true &&
        /submission/i.test(element.stage) === true &&
        /W - 10x10/i.test(element.status) === true
      ) {
        // Jira VER-194 Actions after submission email
        element.job.engagement = mapEngagement(element);
        jobs.push(element.job);
        if (selectedJobId === element.job.id) {
          selectedJob = element.job;
        }

        const formatJobAnswers = (jobAnswers = [], allQuestions) => {
          if (!Array.isArray(jobAnswers) || !jobAnswers.length) {
            jobAnswers = [];
          }

          if (!Array.isArray(allQuestions) || !allQuestions.length) {
            allQuestions = [];
          }

          let answeredQuestions = jobAnswers.map((obj) =>
            String(Object(obj).question).trim()
          );
          let missedQuestions = allQuestions.filter(
            (question) =>
              !answeredQuestions.includes(String(Object(question).question).trim())
          );

          // Format results, answers and missed questions
          let answers =
            "<ul>" +
            jobAnswers
              .map((ja) => {
                return `<li>${ja.question}: ${ja.ans}</li>`;
              })
              .join("") +
            "</ul>";

          let missed =
            "<ul>" +
            missedQuestions
              .map((ja) => {
                return `<li>${ja.question}</li>`;
              })
              .join("") +
            "</ul>";

          let result = "";
          if (!!missedQuestions.length && !!answeredQuestions.length) {
            // Show both unanswered and answered questions, unanswered first
            result =
              `<p><strong>ALERT!! => Following questions were missed</strong></p>${missed}` +
              `<p><strong>Following questions were answered</strong></p>${answers}`;
          } else if (!!missedQuestions.length) {
            // Only unanswered questions so show only show them
            result = `<p><strong>ALERT!! => Following questions were missed</strong></p>${missed}`;
          } else {
            // Only answered questions - Perfect, only show bulleted list
            result = `${answers}`;
          }

          return result;
        };

        const formatPrivateNotes = (selectedJob) => {
          if (!selectedJob) { return ""; }
          return (`
            <p><strong>Employer:</strong></p>
            <p>${Object(selectedJob.employer).internalNotes}</p>
            <p><strong>Job:</strong></p>
            <p>${selectedJob.privateNotes}</p>
            <p><strong>Insider Scoop:</strong></p>
            <p>${selectedJob.qualificationNotes}</p>
          `).trim();
        };

        const formatInternalInstructions = (selectedJob) => {

          selectedJob = Object(selectedJob);
          let employerInternalInstructions = String(Object(selectedJob.employer).internalSubmissionInstructions).trim();
          let jobInternalInstructions = String(selectedJob.internalSubmissionInstructions).trim();

          if (
            !employerInternalInstructions &&
            !jobInternalInstructions
          ) {
            return "None";
          }

          return cleanHTML(`
            <p><strong>Employer:</strong></p>
            <p>
              ${Object(selectedJob.employer).internalSubmissionInstructions}
            </p>
            <p><strong>Job:</strong></p>
            <p>${selectedJob.internalSubmissionInstructions}</p>
          `);

        };

        this.setState(
          {
            [`jobSubmissionNotes-${element.job.id}`]: formatJobAnswers(
              model.permittedJobsAnswers[element.job.id],
              Object(selectedJob).mustHaveQuestions
            ),
            [`includeJobSubmissionNotes-${element.job.id}`]: true,
            [`employerPrivateNotes-${element.job.id}`]: formatPrivateNotes(
              selectedJob
            ),
            [`internalSubmissionInstructions-${element.job.id}`]: formatInternalInstructions(
              selectedJob
            ),
            jobId: selectedJobId,
            selectedJob,
            jobEnvelope,
            jobs,
          },
          () => {
            this.setEmailPreviewState();
            cb();
          }
        );
      }
    });
  };

  componentWillUpdate(nextProps, nextState) {
    if (
      (!!this.state.schoolsLinkedIn.length ||
        !!this.state.undergraduateSchool.length) &&
      !this.state.ranking
    ) {
      const { schoolsLinkedIn, undergraduateSchool } = this.state;
      const schoolsArray = !!schoolsLinkedIn.length
        ? schoolsLinkedIn.map((school) => school.school)
        : [undergraduateSchool];
      School.get(
        (response) => {
          this.setState({ ranking: response });
        },
        {
          include: {
            relation: "schoolRating",
            scope: {
              fields: [
                "degree",
                "rating",
                "positiveSignalsTags",
                "negativeSignalsTags",
              ],
            },
          },
          where: {
            or: [
              { universityName: { inq: schoolsArray } },
              { alternativeNames: { inq: schoolsArray } },
            ],
          },
        }
      );
    }
  }

  getJobsFirstRole = () => {
    const { selectedJob } = this.state;

    let firstJobRole = "";
    if (!!selectedJob && !!selectedJob._roles)
      firstJobRole = selectedJob._roles.split(",")[0];

    return firstJobRole;
  };

  getYearsOfExperienceLabel = () => {
    const { selectedJob } = this.state;

    let xYearsExperience = "";
    if (!!selectedJob) {
      if (this.state.yearsOfExperience > 10) {
        xYearsExperience = "10+ years experience";
      }
      else if (this.state.yearsOfExperience >= 0) {
        xYearsExperience = `${this.state.yearsOfExperience} years experience`;
      }
    }
    return xYearsExperience;
  };

  getCVMatchedSkills = () => {
    let cvMatched = "";

    if (this.state.cvMatchArrived) cvMatched = this.state.cvMatchArrived;

    return cvMatched;
  };

  getAllPositiveSignals = () => {
    let positiveSignalsDefString = "";
    const candidate = this.state;
    let { ranking } = candidate;
    ranking = ranking || [];

    if (!!this.state && !!this.state.positiveSignals.length) {
      let positiveSignalsSchool = [];
      let positiveSignalsXp = [];

      let idsFiltered = [];

      this.state.positiveSignals.forEach((id) => {
        if (!!this.state.schoolsLinkedIn.length) {
          this.state.schoolsLinkedIn
            .filter((school) => !!school.schoolRanking)
            .forEach((school) => {
              if (
                school.schoolRanking ===
                Definition.getLabel("positiveSignals", id)
              ) {
                idsFiltered.push(id);
                positiveSignalsSchool.push(
                  `${school.school} ( ${school.schoolRanking} )`
                );
              }
            });
        } else {
          const singleSchool = (ranking || []).find((rank) => {
            return rank.universityName === candidate.undergraduateSchool;
          });

          if (
            !!singleSchool &&
            !!singleSchool.schoolRating &&
            !!singleSchool.schoolRating.length &&
            Array.isArray(!!singleSchool.schoolRating[0].positiveSignalsTags) &&
            !!singleSchool.schoolRating[0].positiveSignalsTags.includes(
              Definition.getLabel("positiveSignals", id)
            )
          ) {
            idsFiltered.push(id);
            positiveSignalsSchool.push(
              `${candidate.undergraduateSchool} ( ${Definition.getLabel(
                "positiveSignals",
                id
              )} )`
            );
          }
        }

        if (!!this.state.jobsLinkedIn.length) {
          this.state.jobsLinkedIn
            .filter((job) => !!job.rankingMeta)
            .forEach((job) => {
              if (
                job.rankingMeta.InterviewBarRanking ===
                Definition.getLabel("positiveSignals", id) &&
                !idsFiltered.includes(id)
              ) {
                idsFiltered.push(id);
                positiveSignalsXp.push(
                  `${job.rankingMeta.CompanyName} ( ${job.rankingMeta.InterviewBarRanking} )`
                );
              }
            });
        }
      });

      let remainingIds = this.state.positiveSignals.filter(
        (el) => ![...idsFiltered].includes(el)
      );

      positiveSignalsDefString = [
        ...positiveSignalsXp,
        ...Definition.getLabels("positiveSignals", remainingIds),
      ].join(", ");
    }

    return (
      `What we like about ${candidate.firstName}: ${positiveSignalsDefString}`
    );
  };

  getCollegeData = () => {
    const candidate = this.state;
    let { ranking } = this.state;

    let data = "";

    const schoolsArray = candidate.schoolsLinkedIn;
    if (!!schoolsArray.length) {
      let eachSchool = "";
      schoolsArray
        .filter(
          (school) => !!school.degree && !!school.field && !!school.school
        )
        .forEach((school) => {
          let degreeLabel = Definition.getLabel(
            "undergraduateDegree",
            school.degree
          );
          const schoolRanking = !!school.schoolRanking
            ? `( ${school.schoolRanking} ) `
            : "";
          eachSchool += `<li>${degreeLabel} ${school.field} @ ${school.school} ${schoolRanking}</li>`;
        });
      if (eachSchool) {
        data += `<li>Education : <ul>${eachSchool}</ul></li>`;
      }
    } else {
      if (
        candidate.undergraduateSchool &&
        candidate._undergraduateDegree &&
        candidate.undergraduateMajor
      ) {
        const singleSchool = (ranking || []).find((rank) => {
          return rank.universityName === candidate.undergraduateSchool;
        });
        let positiveSignalTag = "";
        if (!!singleSchool && !!singleSchool.schoolRating.length) {
          positiveSignalTag =
            !!singleSchool.schoolRating[0].positiveSignalsTags &&
              !!singleSchool.schoolRating[0].positiveSignalsTags.length
              ? singleSchool.schoolRating[0].positiveSignalsTags[0]
              : "";
          positiveSignalTag = !!Definition.getLabel(
            "positiveSignals",
            positiveSignalTag
          )
            ? `( ${Definition.getLabel("positiveSignals", positiveSignalTag)} )`
            : "";
        }
        // const {positiveSignalTag} = getCollegeCategory(this.state.ranking, candidate.undergraduateSchool, "getLabel");
        let label = `${candidate._undergraduateDegree} ${candidate.undergraduateMajor} @ ${candidate.undergraduateSchool}${positiveSignalTag}`;
        /** µ MARKED FOR REVIEW * /
        data += "<li>Education : ";
        /** */
        data += `<ul><li>${label}</li></ul>`;
        data += "</li>";
      }
    }

    return data;
  };

  /**
   * @param {*} params
   * @param {boolean} params.toIncludeInCompose
   * @returns {string} Subject prefix
   */
  getEmailSubjectPrefix = ({ toIncludeInCompose = false } = {}) => {
    const A_PLUS_ID = (Definition.getId("platformRating", "A+") || 5);
    const A_TOP_ID = (Definition.getId("platformRating", "A - Top") || 1);
    const { state: candidate } = this;
    const { platformRating } = candidate;
    return (
      (platformRating === A_PLUS_ID)
        ? "HIGH PRIORITY CANDIDATE"
        : (platformRating === A_TOP_ID)
          ? "PRIORITY CANDIDATE"
          : toIncludeInCompose ? '' : "CANDIDATE"
    );
  }

  composeAtsCopyText = () => {

    // getting the composed html from the email preview instead have it in a separated template.
    let outputText = this.state.emailPreview || '';

    /**
     * @todo
     * To review for improvements.
     *
     * Following code block is converting HTML text into plain text.
     * 2021-07-08 µ
     */
    /* start block ========================== */

    outputText = cleanHTML(outputText);

    /* To get the middle list */
    const startToken = '---START---';
    const endToken = 'Phone:';
    outputText = outputText.replace(/<\/p>/i, `${startToken}</p>`);

    outputText = outputText.replace(/\n/ig, '');
    outputText = outputText.replace(/\s\s/ig, ' ');

    outputText = outputText.replace(/&nbsp;/ig, ' ');
    outputText = outputText.replace(/<\/div>/ig, '\n');
    outputText = outputText.replace(/<\/li>/ig, '\n');
    outputText = outputText.replace(/<li>/ig, '');
    outputText = outputText.replace(/<ul>|<\/ul>/ig, '');
    outputText = outputText.replace(/<p><\/p>/ig, '');
    outputText = outputText.replace(/<\/p>/ig, '\n');
    outputText = outputText.replace(/\n{2}/ig, '\n');
    outputText = outputText.replace(/<br\s*[/]?>/gi, '');
    outputText = outputText.replace(/<[^>]+>/ig, '');
    outputText = outputText.split('\n').map(n => n.trim()).join('\n');

    /* ============================ end block */

    /* Getting the middle list */
    let start = false;
    let end = false;
    outputText = (
      outputText
        .split(/\n/)
        .filter(line => {
          if (line.includes(startToken)) {
            start = true;
            return false;
          }
          if (!!start && !end) {
            if (line.includes(endToken)) {
              end = true;
            }
            return true;
          }
          return false;
        })
        .join('\n')
    );

    return outputText;

  };

  getReceiverNames = () => {

    const selectedJob = this.state.selectedJob;

    let toNames = [];
    let ccNames = [];

    if (!!selectedJob) {
      const { jobEnvelope: { to = [], cc = [] } = { to: [], cc: [] } } = this.state;
      toNames = to.map(({ name }) => name);
      ccNames = cc.map(({ name }) => name);
    }

    let allNames = [...toNames, ...ccNames];
    allNames.forEach((item, index, arr) => {
      arr[index] = item.split(" ")[0];
    });
    let receiverNames = allNames.join(", ");

    return { receiverNames };

  };

  async setEmailPreviewState() {
    const selectedJob = this.state.selectedJob;
    if (selectedJob) {
      const hasResume = !!this.state.resumes.length;
      const githubStats = this.state.githubStats;
      const employer = selectedJob.employer;
      let { receiverNames } = await this.getReceiverNames();

      let isNotVisaUnknown = true;
      if (!this.state.visa) {
        isNotVisaUnknown = false;
      }

      const { candidate } = this.state;

      const context = {
        _subjectPrefix: this.getEmailSubjectPrefix({ toIncludeInCompose: true }),
        receiverNames,
        isNotVisaUnknown: isNotVisaUnknown,
        companiesString: `<ul>${this.state._companiesString
          .split(",")
          .map((el) => `<li>${el}</li>`)
          .join("")}</ul>`,
        includeSubmissionNotes: this.state.includeSubmissionNotes,
        includeSubmissionNotesToEmployer: this.state
          .includeSubmissionNotesToEmployer,
        includeJobSubmissionNotes: this.state[
          "includeJobSubmissionNotes-" + selectedJob.id
        ],
        jobSubmissionNotes: this.state["jobSubmissionNotes-" + selectedJob.id],
        job: {
          ...selectedJob,
        },
        candidate,
        hasResume,
        isEmployerAts: /ats/i.test(selectedJob.employer._submissionMethod),
        employer: {
          ...employer,
        },
        _normalCollegeFlow: this.getCollegeData(),
      };

      const replacer = "<li>@@replace@@</li>";

      let xYearsExperience = "";
      xYearsExperience = replacer.replace(
        "@@replace@@",
        this.getYearsOfExperienceLabel()
      );

      let level = "";
      const getJobLevelConstantsKeys = jobLevels.map((level) => level.key);
      if (!!selectedJob && getJobLevelConstantsKeys.includes(selectedJob.level))
        level = replacer.replace(
          "@@replace@@",
          `Level : ${selectedJob._level}`
        );

      let positiveSignals = "";
      positiveSignals = replacer.replace(
        "@@replace@@",
        this.getAllPositiveSignals()
      );

      let githubStatsDisplay = !!githubStats
        ? replacer.replace(
          "@@replace@@",
          ReactDOMServer.renderToString(githubStats.getSubmissionNotesHtml)
        )
        : "";

      let cvMatched = "";
      if (this.state.cvMatchArrived) {
        cvMatched = `<li>Tech Skills : ${this.state.cvMatchArrived}</li>`;
      }

      /** epic-3038(new locations)-story-3384 */

      const {
        inOfficeRemoteFlags = [],
        officeLocations = [],
        candidateLocations = [],
      } = candidate;

      context._inOfficeRemoteFlagsLabels = Definition.getLabels(
        'inOfficeRemote',
        inOfficeRemoteFlags.sort()
      ).join(', ');

      if (
        inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID) ||
        inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
      ) {
        /* epic-3038(new locations)-story-3689-m2 | 2021-08-05 Thu µ */
        /* story-3384-m4 | 2021-07-12 µ */
        context._officeLocationsLabels = LocationLib.getLocationsString({ locations: officeLocations });
      }

      /* epic-3038(new locations)-story-3689-m2 | 2021-08-05 Thu µ */
      /* story-3384-m4 | 2021-07-12 µ */
      //context._candidateLocationLabel = Definition.getLabel('location', candidateLocation);
      context._candidateLocationLabel = LocationLib.getLocationsString({ locations: candidateLocations });

      const template = compile(SUBMISSION_EMAIL_BODY_TEMPLATE);
      const outputHtml = template(context)
        .toString()
        .replace("[SIGNATURE]", Core.getEmailSignature())
        .replace("@@techSkillsWithCV@@", cvMatched)
        .replace("@@xYearsExperience@@", xYearsExperience)
        .replace("@@level@@", level)
        .replace("@@allPositiveSignals@@", positiveSignals)
        .replace("@@githubStats@@", githubStatsDisplay);

      this.setState(
        { emailPreview: outputHtml },
        (then) => {
          this.EmailPreview.setValue(outputHtml);
        }
      );
    } else {
      this.setState(
        {
          emailPreview: "<html><head></head><body></body></html>",
        },
        (then) => {
          this.EmailPreview.setValue("<html><head></head><body></body></html>");
        }
      );
    }
  }

  showMessage = (msg) => {
    this.setState({
      snackBarMessage: msg,
      snackBarOpen: true,
    });
  };
  hideMessage = () => {
    this.setState({
      snackBarMessage: "",
      snackBarOpen: false,
    });
  };
  stringCapitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  openEditEmployer = (ev) => {
    Core.dialog.open({
      title: <>Edit Employer</>,
      style: {
        width: "70vw",
        minWidth: "320px",
        maxWidth: "none",
      },
      message: (
        <iframe
          className="covered"
          title="Edit Employer"
          style={{ height: "70vh", minHeight: "375px" }}
          src={`/#/employer/edit/${this.state.selectedJob.employerId}`}
        />
      ),
      actions: [
        <FlatButton
          label="Done"
          className="button-white-cyan"
          onClick={(ev) => document.location.reload()}
        />,
      ],
    });
  };
  openSuccessDialog = (engagement) => {
    const selectedJob = this.state.selectedJob;
    const actions = [];
    const nextJob = (this.state.jobs || []).find(
      (job) => job.id !== selectedJob.id
    );
    if (nextJob) {
      const candidateId = this.state.id;
      actions.push(
        <FlatButton
          label="Engagement"
          title="Go to Engagement view"
          className="button-flat-darker"
          onClick={(ev) => {
            Core.dialog.close();
            document.location.href = `/#/engagement/view/${engagement.id}`;
          }}
        />
      );
      actions.push(
        <FlatButton
          label="Next Job"
          title="Go to the next job"
          className="button-white-cyan"
          onClick={(ev) => {
            Core.dialog.close();
            document.location.href = `/#/candidate/resume-submission/${candidateId}/${nextJob.id}`;
            document.location.reload();
          }}
        />
      );
    } else {
      actions.push(
        <FlatButton
          label="Done"
          title="Open represent email"
          className="button-white-cyan"
          onClick={(ev) => {
            Core.dialog.close();
            document.location.href = `/#/engagement/represent/${engagement.id}`;
            document.location.reload();
          }}
        />
      );
    }
    Core.dialog.open({
      title: <>&nbsp;</>,
      message: (
        <div className="align-center">
          <i
            className="material-icons"
            style={{
              display: "block",
              color: colors.purple,
              fontSize: 72,
            }}
          >
            check_circle
          </i>
          <span
            style={{
              display: "block",
              color: "rgba(0,0,0,0.84)",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              textAlign: "center",
              height: "100px",
              padding: "24px",
            }}
          >
            Successful Submission
          </span>
        </div>
      ),
      actions,
    });
  };

  /**
   * Updates Engagement
   * - stage - To "Review".
   * - status - To "W - Employer Feedback".
   * - submitted - Today's iso string.
   * - greenhouseAtsApplicationId - Argument.
   *
   * @param {object} options
   * @param {number} options.submissionMethodId
   * @param {number} options.greenhouseAtsApplicationId
   * @returns
   */
  transitionAfterSubmission = ({
    submissionMethodId,
    greenhouseAtsApplicationId
  } = {}) => {

    /**
     * @todo
     * TO REVIEW
     * What is different between
     * state.selectedJob
     * vs
     * find it in state.jobs by state.jobId
     *
     * FUTURE CLEANUP
     * 2021-05-24 µ
     */
    /** */
    const {
      state
    } = this;
    const {
      selectedJob,
    } = state;
    const {
      submissionMethods = [],
    } = selectedJob;

    if (
      submissionMethodId &&
      (submissionMethods.length >= 2)
    ) {
      Core.showMessage('Skip auto transition since there are multiple job submissions methods selected');
      return async () => { };
    }

    const eng = selectedJob.engagement;

    if (eng.id && eng.boxKey) {

      const date = new Date();

      const update = {
        stage: "Review",
        status: "W - Employer Feedback",
        submitted: date.toISOString(),
      };

      if (greenhouseAtsApplicationId) {
        update.greenhouseAtsApplicationId = greenhouseAtsApplicationId;
      }

      return Engagement.update(eng, update, this.openSuccessDialog);

    }

  };

  getParams = (em) => {
    const params = {};

    /** @todo to review, not in use | 2021-09-09 Thu µ */
    // const deliveryTo = [];
    // const deliveryCc = [];
    // const deliveryBcc = [Core.getResumeSubmissionBcc()];

    const selectedJob = this.state.selectedJob;

    /** µ FOR DEBUG PURPOSES * /
    console.debug('µ:CandidateResumeSubmission.getParams', { selectedJob });
    console.debug('µ:CandidateResumeSubmission.getParams', { recruiterEmailsList });
    /** */

    if (selectedJob) {

      const { jobEnvelope: { to = [], cc = [], bcc = [] } = { to: [], cc: [], bcc: [] } } = this.state;

      params.from = Core.getResumeSubmissionFromEmail();
      params.to = to;
      params.cc = cc;
      params.bcc = bcc;
      params.subject = [
        `${this.getEmailSubjectPrefix()} SUBMISSION |`,
        this.state.firstName,
        `${this.state.lastName} |`,
        `${selectedJob.employer.name} -`,
        selectedJob.jobTitle
      ].join(' ');
      params.html = this.state.emailPreview;
      if (!!this.state.selectedResume) {
        const filenameArray = this.state.selectedResume.url.split("/");
        const filename = filenameArray[filenameArray.length - 1];
        const path = this.state.selectedResume.url;
        var attachments = [
          {
            filename: filename,
            path: path,
          },
        ];
        params["attachments"] = attachments;
      }
    }

    /** µ FOR DEBUG PURPOSES * /
    console.debug('µ:CandidateResumeSubmission.getParams', { params });
    /** */

    return params;

  };

  /**
   * @todo
   * TO CLEANUP
   * 2021-05-13 µ
   * /
  sendEmail = (ev) => { };
  /** */

  renderSubmissionMethodText = () => {
    if (!this.state.selectedJob) {
      return "";
    }

    let employer = this.state.selectedJob.employer;
    let label = this.state.selectedJob._submissionMethods;

    if (!label) {
      return "";
    }

    if (/both/i.test(label)) {
      label = "Email,ATS";
    }

    return (
      <p
        style={{ color: "red" }}
      >{`${employer.name} requires candidate submission via: { ${label} }`}</p>
    );
  };

  render() {
    /** CandidateResumeSubmission state */
    const {
      state
    } = this;

    /** state *variables* */
    const {
      selectedJob,
      openCollapseView,
    } = state;

    const renderParams = this.getParams();

    const {
      /* Subject: */
      subject: emailSubject,
      to,
      cc,
    } = renderParams;

    if (Core.isLoggedOut()) {
      return <Redirect to="/login" />;
    }
    const lineStyle = { borderColor: "#59736f" };
    if (Core.isRecruiter()) {
      return <Redirect to="/" />;
    }
    /** BACK BUTTON */
    const goBack = (event) => {
      cancel();
    };
    /** CANCEL BUTTON */
    const cancel = (event) => {
      Core.goBack(this.props);
    };
    /** SEND BUTTON */
    const send = async (event) => {
      this.setState({ sending: true });
      if (this.state.jobId) {
        const selectedJob = this.state.selectedJob;
        const params = {
          ...this.getParams(),
          source: "Resume Submission line 817",
        };
        /** todo: see if the mappings can be done in getParams */
        params.to = params.to.map(({ email }) => email);
        params.cc = params.cc.map(({ email }) => email);
        params.bcc = params.bcc.map(({ email }) => email);

        const doSend = async (em) => {
          const eng = selectedJob.engagement;
          if (!eng.boxKey) {
            Core.showFailure("It is missing the engagement box key");
          } else {

            /** FUTURE CLEANUP 2021-05-24 µ * /
            Google.sendEmail(params, (response) => {
            /** */

            sendSafeEmail(params, (response) => {
              Core.log("sendEmail", { response });
              if (!response.threadId) {
                Core.showFailure("It is missing the threadGmailId");
              } else {
                Streak.putEmailInBox(
                  { boxKey: eng.boxKey, threadGmailId: response.threadId },
                  (response) => {
                    this.setState({ sending: false });
                    this.transitionAfterSubmission({
                      submissionMethodId: EMP_SUB_MET__EMAIL_ID
                    });
                  }
                );
              }
            });
          }
        };
        if (params.attachments) {
          const attachments = [...params.attachments];
          params.attachments = [];
          const next = (em) => {
            if (!!attachments.length) {
              const attachment = attachments.pop();
              getBinary({
                url: attachment.path,
                onSuccess: (binary) => {
                  params.attachments.push({ ...attachment, ...binary });
                  next();
                },
                onError: (error) => {
                  Core.showMessage("Fail to get the resume");
                  next();
                },
              });
            } else {
              doSend();
            }
          };
          next();
        } else {
          doSend();
        }
      }
    };

    /**
     * COPY BUTTON
     */
    const copy = (event) => {
      copyHTML(this.state.emailPreview)
        .then((em) => {
          Core.log("Copy email command was successful");
          this.showMessage("Copied!");
        })
        .catch((ex) => {
          Core.log("Oops, unable to copy");
          this.showMessage("Fail copy!");
        });
    };

    const copyAts = async (event) => {
      const result = await copyText(
        this.composeAtsCopyText()
      );
      console.debug('µ:onClickCopy');
      console.debug(result?.text);
      if (!!result) {
        Core.log("Copy Ats command was successful");
        this.showMessage("Copied!");
      }
      else {
        Core.log("Oops, unable to copy");
        this.showMessage("Fail copy!");
      }
    }

    const copyAttribute = (attribute) => (event) => {
      copyHTML(this.state[attribute])
        .then((em) => {
          this.showMessage("Copied!");
        })
        .catch((ex) => {
          this.showMessage("Fail copy!");
        });
    };

    const jobsCount =
      !!this.state.jobs && !!this.state.jobs.length
        ? ` (1/${this.state.jobs.length} jobs)`
        : "";

    const rawSubmissionNotes = (
      selectedJob?.id
        ? this.composeAtsCopyText()
        : ''
    );

    return (
      <MuiThemeProvider>
        <div className="edit account">
          <div className="appBar" style={styles.AppBar}>
            <AppBar
              title={"Resume Submission"}
              titleStyle={styles.AppBar.title}
              style={styles.AppBar.nav}
              iconElementRight={
                <div>
                  {false && (
                    <NavLink
                      exact
                      activeClassName="navlink active"
                      className="navlink"
                      to="/help"
                    >
                      {" "}
                      <FlatButton
                        label="Help"
                        labelStyle={styles.AppBar.rightLabel}
                      />
                    </NavLink>
                  )}
                  <Button
                    style={{ marginRight: "20px" }}
                    color="primary"
                    onClick={(ev) => {
                      this.refs.doubleContentDrawer.handleOpen();
                    }}
                  >
                    Text CV/CV
                  </Button>
                </div>
              }
              iconElementLeft={
                <FlatButton
                  icon={<i className="material-icons">arrow_back</i>}
                  style={styles.AppBar.backButton}
                  onClick={goBack}
                />
              }
            />
          </div>
          <Paper className="form" style={styles.Paper} zDepth={1}>
            {!!selectedJob && (
              <MatchDrawer
                ref="doubleContentDrawer"
                content={
                  <DoubleContentDrawer
                    selectedJob={selectedJob}
                    candidate={this.state}
                  />
                }
              />
            )}
            <Row>
              <Col className="d-flex align-center" fullWidth>
                <label className="m-0">
                  <b>For Candidate: </b>
                  <NavLink
                    title="Edit Candidate"
                    to={"/candidate/edit/" + this.state.id}
                  >
                    {this.state.firstName}
                    &nbsp;
                    {this.state.lastName}
                  </NavLink>
                </label>
                <IconButton
                  className="ml-auto hint--left hint--rounded"
                  aria-label={openCollapseView ? "Expand Less" : "Expand More"}
                  onClick={event => this.setState(({ openCollapseView }) => ({
                    openCollapseView: !openCollapseView
                  }))}
                >
                  {openCollapseView ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Col>
            </Row>

            <Collapse in={openCollapseView} timeout="auto">

              <Row className="padding-top hover-bg">
                <Col className="col9 no-margins">
                  <label>Recruiter's Candidate Write up</label>
                </Col>
                <Col className="col3" style={{ maxWidth: 185, paddingBottom: 6 }}>
                  <Checkbox
                    label="Include in email"
                    defaultChecked={this.state.includeSubmissionNotes}
                    value={true}
                    onCheck={(event, includeSubmissionNotes) => {
                      this.setState({ includeSubmissionNotes }, (then) => {
                        this.setEmailPreviewState();
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col fullWidth>
                  <RichTextBox
                    name="submissionNotes"
                    value={this.state.submissionNotes}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="padding-top hover-bg">
                <Col className="col9 no-margins">
                  <label>Candidate Private Note</label>
                </Col>
              </Row>

              <Row>
                <Col fullWidth>
                  <RichTextBox
                    name="candidatePrivateNotes"
                    value={this.state.privateNotes}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="padding-top hover-bg">
                <Col className="col9 no-margins">
                  <label>Submission Write Up to Employer</label>
                </Col>
                <Col className="col3" style={{ maxWidth: 185, paddingBottom: 6 }}>
                  <Checkbox
                    label="Include in email"
                    defaultChecked={this.state.includeSubmissionNotesToEmployer}
                    value={true}
                    onCheck={(event, includeSubmissionNotesToEmployer) => {
                      this.setState(
                        { includeSubmissionNotesToEmployer },
                        (then) => {
                          this.setEmailPreviewState();
                        }
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col fullWidth>
                  <RichTextBox
                    name="submissionNotesToEmployer"
                    value={this.state.submissionNotesToEmployer}
                    onChange={submissionNotesToEmployer => {
                      this.setState({ submissionNotesToEmployer }, (then) => {
                        this.setEmailPreviewState();
                      });
                    }}
                  />
                </Col>
              </Row>
              {selectedJob && (this.state.jobs || [])
                .filter(({ id }) => id === selectedJob.id)
                .map(job => {
                  let key = "jobSubmissionNotes-" + job.id;
                  let includeKey = "includeJobSubmissionNotes-" + job.id;
                  return (
                    <Fragment key={key}>
                      <Row className="padding-top hover-bg">
                        <Col className="col9 no-margins">
                          <label>
                            Employer Required Submission Information:{" "}
                            {job.employer.name + " - " + job.jobTitle}
                          </label>
                        </Col>
                        <Col
                          className="col3"
                          style={{ maxWidth: 185, paddingBottom: 6 }}
                        >
                          <Checkbox
                            label="Include in email"
                            defaultChecked={this.state[includeKey]}
                            value={true}
                            onCheck={(event, includeJobSubmissionNotes) => {
                              this.setState(
                                { [includeKey]: includeJobSubmissionNotes },
                                (then) => {
                                  this.setEmailPreviewState();
                                }
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col fullWidth>
                          <RichTextBox
                            name={key}
                            value={this.state[key]}
                            onChange={jobSubmissionNotes => {
                              this.setState(
                                { [key]: jobSubmissionNotes },
                                (then) => {
                                  this.setEmailPreviewState();
                                }
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  );
                })}
              <Row className="padding-top hover-bg">
                <Col className="col9 no-margins">
                  <label>Employer Private Notes</label>
                </Col>
              </Row>
              <Row>
                <Col fullWidth>
                  {!!selectedJob && (
                    <RichTextBox
                      name="employerPrivateNotes"
                      value={this.state[`employerPrivateNotes-${selectedJob.id}`]}
                      disabled
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col fullWidth>
                  <br />
                  <Divider />
                  <h2>
                    Select Job to Submit:{" "}
                    {!!this.state.selectedJob && (
                      <Fragment>
                        <NavLink
                          title="Edit Job"
                          to={"/job/edit/" + this.state.selectedJob.id}
                        >
                          {this.state.selectedJob.jobTitle}
                        </NavLink>{" "}
                        |{" "}
                        <NavLink
                          title="Edit Job"
                          to={`candidate/matchNew/${this.state.id}?selectedJob=${this.state.selectedJob.id}`}
                        >
                          Go to Match page
                        </NavLink>
                      </Fragment>
                    )}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SelectField
                    name="jobs"
                    underlineFocusStyle={lineStyle}
                    value={this.state.jobId}
                    maxHeight={300}
                    onChange={(event, index, jobId) => {
                      var selectedJob = (this.state.jobs || []).find(
                        (item) => item.id === jobId
                      );
                      this.setState({ jobId, selectedJob }, (then) => {
                        this.setEmailPreviewState();
                        this.candidateEngagementsProcessing(jobId);
                      });
                    }}
                    fullWidth
                  >
                    {(this.state.jobs || []).map((item) => {
                      return (
                        <MenuItem
                          key={Core.getKey()}
                          value={item.id}
                          primaryText={item.employer.name + " - " + item.jobTitle}
                        />
                      );
                    })}
                  </SelectField>
                </Col>

                {selectedJob && (
                  <Col className="inline-blocks align-right" fullWidth>
                    {!renderParams.to && (
                      <span className="cred">
                        The contact information is missing, click to edit the
                        Employer
                      </span>
                    )}
                    <IconButton
                      title="Edit Employer"
                      onClick={this.openEditEmployer}
                    >
                      <i className="material-icons">edit</i>
                    </IconButton>
                  </Col>
                )}

              </Row>
              <Row>
                <Col fullWidth>
                  <label />
                </Col>
              </Row>
              {selectedJob && (
                <Fragment>

                  <Row>
                    <Col className="d-flex flex-align-left-center" fullWidth>
                      <label className="m-0" style={{ width: '4rem' }}>Subject:</label>
                      <span className="ml-1">
                        {emailSubject}
                      </span>
                    </Col>
                  </Row>

                  <Row className="mt-1">
                    <Col className="d-flex align-left" fullWidth>
                      <label className="m-0" style={{ width: '4rem' }}>To:</label>
                      <span className="ml-1">
                        {to.map(({ email }) => (
                          <div key={`delivery-email-to-${email}`}>{email}</div>
                        ))}
                      </span>
                    </Col>
                  </Row>

                  <Row className="mt-1">
                    <Col className="d-flex align-left" fullWidth>
                      <label className="m-0" style={{ width: '4rem' }}>Cc:</label>
                      <span className="ml-1">
                        {/** @todo */}
                        {cc.map(({ email }) => (
                          <div key={`delivery-email-cc-${email}`}>{email}</div>
                        ))}
                      </span>
                    </Col>
                  </Row>

                </Fragment>
              )}

              <Row>
                <Col fullWidth>
                  <RichTextBox
                    refs={(self) => (this.EmailPreview = self)}
                    name="emailPreview"
                    value={this.state.emailPreview}
                    onChange={emailPreview => {
                      this.setState({ emailPreview });
                    }}
                  />
                </Col>
              </Row>

              <Row className="padding-top hover-bg">
                <Col className="col9 no-margins">
                  <label>10x10 Internal Submission Instructions</label>
                </Col>
              </Row>
              <Row>
                <Col fullWidth>
                  {!!selectedJob && (
                    <RichTextBox
                      name="internalSubmissionInstructions"
                      value={
                        this.state[`internalSubmissionInstructions-${selectedJob.id}`]
                      }
                      disabled
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col style={{ textAlign: "right" }} fullWidth>
                  {COPIED_ATTRS.filter((el) => !!this.state[el]).map((attr, index) => {
                    return (
                      <Fragment key={attr}>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={copyAttribute(attr)}
                        >
                          {`Copy ${attr}`}
                        </Link>{" | "}
                      </Fragment>
                    );
                  })}
                </Col>
              </Row>

              <Row>
                <Col style={{ textAlign: "right" }} fullWidth>
                  {this.renderSubmissionMethodText()}
                  <FlatButton
                    label="Copy Email Content"
                    style={styles.List.button}
                    onClick={copy}
                  />
                  &nbsp;
                  <FlatButton
                    label="Copy For ATS"
                    style={styles.List.button}
                    onClick={copyAts}
                  />
                </Col>
              </Row>

            </Collapse>

            <Row>
              <Col fullWidth>
                <label>Resumes</label>
                {!!this.state.resumes.length ? (
                  <Table
                    style={{ tableLayout: "auto" }}
                    onRowSelection={(selectedRows) => {
                      if (selectedRows && selectedRows.length) {
                        const rowNumber = selectedRows[0];
                        this.setState({
                          selectedResumeRow: rowNumber,
                          selectedResume: this.state.resumes[rowNumber],
                        });
                      }
                    }}
                  >
                    <TableBody
                      deselectOnClickaway={false}
                      displayRowCheckbox={true}
                    >
                      {!!this.state.resumes.length &&
                        this.state.resumes.map((resume, index) => (
                          <TableRow
                            key={Core.getKey()}
                            selectable={true}
                            selected={this.state.selectedResumeRow === index}
                          >
                            <TableRowColumn
                              className="v-aligns-middle"
                              style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                              <IconButton
                                onClick={(ev) =>
                                  downloadFile({
                                    url: resume.url,
                                    mimeType: "application/pdf",
                                    onError: (error) => {
                                      this.setState({
                                        snackbar: error,
                                      });
                                    },
                                  })
                                }
                              >
                                <i className="material-icons">file_download</i>
                              </IconButton>
                              <a href={formatURL(resume.url)} target="_blank" rel="noreferrer">
                                {decodeURIComponent(
                                  String(resume.url).split("/").pop()
                                )}
                              </a>
                            </TableRowColumn>
                            <TableRowColumn
                              onClick={this.handleToggle}
                              style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                              {moment(resume.createAt).format("LLLL")}
                            </TableRowColumn>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                ) : (
                  "No resumes"
                )}
              </Col>
            </Row>

            {selectedJob && (
              <AtsPreviewBlock
                candidate={state}
                selectedJob={selectedJob}
                rawSubmissionNotes={rawSubmissionNotes}
                emailParams={renderParams}
              />
            )}

            <Row style={{ padding: "16px 0" }}>
              <Col fullWidth style={{ textAlign: "right" }}>
                <FlatButton
                  label="CANCEL"
                  style={styles.List.cancel}
                  onClick={cancel}
                />
                {openCollapseView && (
                  <FlatButton
                    label="Stage transition only"
                    title={`Transition to review without send email.${jobsCount}`}
                    className="btn-cyan-white"
                    onClick={this.transitionAfterSubmission}
                  />
                )}
                {openCollapseView && !!renderParams.to && (
                  <FlatButton
                    label={
                      this.state.sending ? "SENDING..." : "Submit via Email"
                    }
                    title={`Send Email, then transition to review.${jobsCount}`}
                    className="btn-cyan-white"
                    onClick={send}
                    disabled={(
                      this.state.sending ||
                      !selectedJob.submissionMethods.includes(EMP_SUB_MET__EMAIL_ID)
                    )}
                  />
                )}
              </Col>
            </Row>
          </Paper>
          <Snackbar
            open={this.state.snackBarOpen}
            message={this.state.snackBarMessage}
            className="snack-bar"
            autoHideDuration={7000}
            onRequestClose={this.hideMessage}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

/* EXPORTS ==================================== */

export default CandidateResumeSubmission;

/* ============================================ */