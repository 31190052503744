import React from 'react';
import { render } from 'react-dom';
import oops from '../assets/images/oops.png';
import Core from '../lib/Core';

export const onError = (error, version) => {
  Core.log(error);
  if (error === 'Invalid Access') {
    Core.logout();
    document.location.href = '/';
  } else {
    setTimeout((st) => (document.location.href = '/'), 35000);
    render(
      <div className="failure page main-padding">
        <div className="cols-2 main-padding centered-wrapper">
          <div id="article" className="cgray">
            <h1 className="c-purple">We’ll be back soon!</h1>
            <div>
              <p className="fbig">
                Sorry for the inconvenience but we’re performing some
                maintenance at the moment.
                <br />
                If you need to you can always contact us at&nbsp;
                <a className="pointer" href="mailto:support@10by10.com">
                  support@10by10.com
                </a>
                , otherwise we’ll be back online shortly!
              </p>
              <p>— 10by10 Admin</p>
              <p className="error">{error}</p>
            </div>
          </div>
          <div className="align-center">
            <img
              style={{ width: 480, maxWidth: '100%' }}
              src={oops}
              alt="Oops!"
            />
          </div>
        </div>
      </div>,
      document.getElementById('root')
    );
  }
};

export const onErrorStreak = (error, version) => {
  if (error !== 'Invalid Access') {
    Core.failure({
      source: 'OnError.routes.js:onErrorStreak',
      exception: error,
      params: {
        _message: 'Failed to fetch streak data from  backend, may be because Streak is down or some backend',
        version,
      }
    });
  }

  onError(error, version);
};
