import React, {Component, Fragment} from 'react';
import {MenuItem, SelectField} from "material-ui";
import {times} from "lodash";

const MONTHS = [
  'Month',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

class YearsDropdown extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      startYear: (new Date()).getFullYear(),
      month: 0,
      year: 0,
    }
  }

  componentDidMount() {
    let month = this.props.month;

    if(typeof month === 'string'){
      let regex = new RegExp(month, "g");
      month = MONTHS.find(m => regex.test(m));

      month = MONTHS.indexOf(month);
      if(month < 0){
        month = 0;
      }
    }

    if(!month){
      month = 0;
    }

    this.setState({
      month,
      year: +this.props.year || 0
    })
  }

  componentWillUpdate(nextProps, nextState, nextContext) {

    if(this.props.month !== nextProps.month ){
      let month = nextProps.month || 0;


      if(typeof month === 'string'){
        let regex = new RegExp(month, "g");
        month = MONTHS.find(m => regex.test(m))
        month = MONTHS.indexOf(month);
        if(month < 0){
          month = 0;
        }
      }

      if(!month){
        month = 0;
      }

      this.setState({
        month,
      })
    }

    if((!!this.props.year || nextProps.year) && this.props.year !== nextProps.year){
      this.setState({
        year: +nextProps.year || 0
      })
    }

  }

  render() {
    const {startYear, month, year} = this.state;
    let {onChangeHandler} = this.props;

    return <Fragment>
      <SelectField
        name="selectValue"
        value={month}
        autoWidth={true}
        style={{width: '50%'}}
        hintText=""
        onChange={(ev, i, month) => {
          this.setState(({month}), () => {
            let monthString = MONTHS[month];
            let monthCleaned = month === 0 ? null : month;
            onChangeHandler(monthCleaned,year,monthString );
          })
        }
        }
      >
        {MONTHS.map((month, index) => {
          return (
            <MenuItem
              key={index}
              value={index}
              primaryText={month}
            />
          );
        })
        }

      </SelectField>

      <SelectField
        name="selectValue"
        value={year}
        style={{width: '50%'}}
        onChange={(ev, i, year) => {
          this.setState({year}, () => {
            let monthString = MONTHS[month];
            let monthCleaned = month === 0 ? null : month;
            onChangeHandler(monthCleaned,year,monthString );
          })
        }
        }
      >
        <MenuItem
          key={'def'}
          value={0}
          primaryText={'Year'}
        />
        {
          times(70, (index) => {
            let thisYear = startYear - index;
            return (
              <MenuItem
                key={thisYear}
                value={thisYear}
                primaryText={thisYear}
              />
            );
          })
        }

      </SelectField>
    </Fragment>
  }
}

export default YearsDropdown;
