import { STATUS_H_10X10, STATUS_H_CANDIDATE, STATUS_H_EMPLOYER, STATUS_H_RECRUITER, STATUS_W_10BY10, STATUS_W_10X10, STATUS_W_10X10_ASK_PERMISSION, STATUS_W_10X10_INTAKE_CALL, STATUS_W_10X10_NOTIFY_REJECTION, STATUS_W_10X10_REVIEW, STATUS_W_10X10_SCHEDULE, STATUS_W_10X10_SUBMISSION, STATUS_W_CANDIDATE, STATUS_W_CANDIDATE_AVAILABILITY, STATUS_W_CANDIDATE_DETAILS, STATUS_W_CANDIDATE_HOMEWORK, STATUS_W_CANDIDATE_PERMISSION, STATUS_W_CANDIDATE_SIGN_OFFER, STATUS_W_CANDO_EMP_AVAILABILITY, STATUS_W_EMPLOYER, STATUS_W_EMPLOYER_AVAILABILITY, STATUS_W_EMPLOYER_DETAILS, STATUS_W_EMPLOYER_FEEDBACK, STATUS_W_EMPLOYER_HOMEWORK, STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK, STATUS_W_EMPLOYER_REFERENCE, STATUS_W_EMPLOYER_SCHEDULE, STATUS_W_EMPLOYER_VERBAL_OFFER, STATUS_W_EMPLOYER_VISA_PROCESSING, STATUS_W_EMPLOYER_WRITTEN_OFFER, STATUS_W_RECRUITER, STATUS_W_RECRUITER_AVAILABILITY, STATUS_W_RECRUITER_DETAILS, STATUS_W_RECRUITER_HOMEWORK, STATUS_W_RECRUITER_INTRO, STATUS_W_RECRUITER_PERMISSION } from '../../lib/Streak';
import { REACT_TABLE__DEFAULT_PAGE_SIZE, REACT_TABLE__INITIAL_PAGE } from './useEnhancedReactTable.hook';
import { encodeUrlArray, encodeUrlObject } from './useUrlQuery.hook';

export const tabBarOptionsEngagements = [
  {
    // Stage: Stage descending (laster stage first) -> Status->Recruiter -> Candidate
    label: 'Stage',
    to: ({ context }) => `?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&o=${encodeUrlObject(
      {
        'stage': -1,
        'candidate.account.firstName': 1,
        'candidate.firstName': 1,
      }
    )}`,
  },
  {
    // Candidate/Recruiter to do: Recruiter->Candidate->stage descending->Status
    label: 'Candidate Todo',
    to: ({ context }) => `?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&status=${encodeUrlArray(
      [
        STATUS_W_CANDIDATE,
        STATUS_W_CANDIDATE_AVAILABILITY,
        STATUS_W_CANDIDATE_DETAILS,
        STATUS_W_CANDIDATE_HOMEWORK,
        STATUS_W_CANDIDATE_PERMISSION,
        STATUS_W_CANDIDATE_SIGN_OFFER,
        STATUS_W_CANDO_EMP_AVAILABILITY,
        STATUS_H_CANDIDATE
      ]
    )}&o=${encodeUrlObject(
      {
        'candidate.account.firstName': 1,
        'candidate.firstName': 1,
        'stage': -1,
        'overdueDate': -1,
      }
    )}`,
  },
  {
    // Candidate/Recruiter to do: Recruiter->Candidate->stage descending->Status
    label: 'Recruiter Todo',
    to: ({ context }) => `?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&status=${encodeUrlArray(
      [
        STATUS_W_RECRUITER,
        STATUS_W_RECRUITER_AVAILABILITY,
        STATUS_W_RECRUITER_DETAILS,
        STATUS_W_RECRUITER_HOMEWORK,
        STATUS_W_RECRUITER_INTRO,
        STATUS_W_RECRUITER_PERMISSION,
        STATUS_H_RECRUITER,
      ]
    )}&o=${encodeUrlObject(
      {
        'candidate.account.firstName': 1,
        'candidate.firstName': 1,
        'stage': -1,
        'overdueDate': -1,
      }
    )}`,
  },
  {
    // 10x10 todo: Recruiter->Candidate->stage descending->Status
    label: '10x10 Todo',
    to: ({ context }) => `?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&status=${encodeUrlArray(
      [
        STATUS_W_10X10,
        STATUS_W_10BY10,
        STATUS_W_10X10_ASK_PERMISSION,
        STATUS_W_10X10_INTAKE_CALL,
        STATUS_W_10X10_NOTIFY_REJECTION,
        STATUS_W_10X10_REVIEW,
        STATUS_W_10X10_SCHEDULE,
        STATUS_W_10X10_SUBMISSION,
        STATUS_H_10X10,
      ]
    )}&o=${encodeUrlObject(
      {
        'candidate.account.firstName': 1,
        'candidate.firstName': 1,
        'stage': -1,
        'overdueDate': -1,
      }
    )}`,
  },
  {
    // Employer to do: Employer->stage descending->Status
    label: 'Employer Todo',
    to: ({ context }) => `?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&status=${encodeUrlArray(
      [
        STATUS_W_EMPLOYER,
        STATUS_W_EMPLOYER_AVAILABILITY,
        STATUS_W_EMPLOYER_DETAILS,
        STATUS_W_EMPLOYER_FEEDBACK,
        STATUS_W_EMPLOYER_HOMEWORK,
        STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK,
        STATUS_W_EMPLOYER_REFERENCE,
        STATUS_W_EMPLOYER_SCHEDULE,
        STATUS_W_EMPLOYER_VERBAL_OFFER,
        STATUS_W_EMPLOYER_VISA_PROCESSING,
        STATUS_W_EMPLOYER_WRITTEN_OFFER,
        STATUS_W_CANDO_EMP_AVAILABILITY,
        STATUS_H_EMPLOYER,
      ]
    )}&o=${encodeUrlObject(
      {
        'job.employer.name': 1,
        'stage': -1,
        'overdueDate': -1,
      }
    )}`,
  },
  {
    // Candidate/Recruiter: Recruiter->Candidate->stage descending->Status
    label: 'Recruiter',
    to: ({ context }) => `?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&o=${encodeUrlObject(
      {
        'candidate.account.firstName': 1,
        'candidate.firstName': 1,
        'stage': -1,
      }
    )}`,
  },
  {
    // Employer: Employer -> stage descending->status
    label: 'Company',
    to: ({ context }) => `?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&o=${encodeUrlObject(
      {
        'job.employer.name': 1,
        'stage': -1,
      }
    )}`,
  },
  {
    // Candidate/Recruiter: Recruiter->Candidate->stage descending->Status
    label: 'Candidate',
    to: ({ context }) => `?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&o=${encodeUrlObject(
      {
        'candidate.account.firstName': 1,
        'candidate.firstName': 1,
        'stage': -1,
      }
    )}`,
  },
];
