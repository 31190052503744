import FuzzySearch from "fuzzy-search";
import "hint.css";
import {
  AutoComplete, Checkbox, Chip,
  Divider, IconButton, IconMenu, MenuItem,
  TextField
} from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import React, { Component, Fragment } from "react";
import { AutoSizer, List } from "react-virtualized";
import loader from "../../assets/images/loader.gif";
import Account from "../../lib/Account";
import Candidate from "../../lib/Candidate";
import Core, { colors } from "../../lib/Core";
import Debug from "../../lib/Debug";
import Employer from "../../lib/Employer";
import Engagement from "../../lib/Engagement";
import Job from "../../lib/Job";
import Store from "../../lib/Store";
import copyHTML from "../../lib/tools/copyHtml";
import AnnoucementBell from "../Announcements/AnnouncementBell";
import HistoryMenu from "../HistoryMenu/HistoryMenu";
import SideMenu from "../HomeV1/SideMenu";
import "./Main.css";

// import Chips from "../Forms/Chips";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const mdash = "—";
const comparationMethod = (a, b, reverseSort) => {
  let res = 0;
  if (Number(a) && Number(b)) {
    if (reverseSort) {
      res = Number(b) - Number(a);
    } else {
      res = Number(a) - Number(b);
    }
  } else {
    if (reverseSort) {
      res = String(b).localeCompare(String(a));
    } else {
      res = String(a).localeCompare(String(b));
    }
  }
  return res;
};

class MainV2 extends Component {
  constructor() {
    super(...arguments);
    Store.set("path", window.location.href);
    Core.Main = this;
    setTimeout(this.fetchData);
  }
  getRouteLabel = em => {
    return Core.isPath("accounts")
      ? "Accounts"
      : Core.isPath("candidates")
        ? "Candidates"
        : Core.isPath("employers")
          ? "Employers"
          : Core.isPath("engagements")
            ? "Engagements"
            : Core.isPath("jobs")
              ? "Jobs"
              : "Undefined";
  };
  fetchData = (where, cb) => {
    Core.Main.Table && Core.Main.Table.updatingData();
    switch (Core.Main.getRouteLabel()) {
      case "Accounts":
        Account.getAll(data => {
          Core.log({ accounts: data });
          Core.Main.Table &&
            Core.Main.Table.updateData({
              header: "Name",
              cols: Account.columns,
              rows: data || [],
              sortBy: "_name",
              reverseSort: false
            });
        });
        break;
      case "Candidates":
        Candidate.getAll(data => {
          Core.log({ candidates: data });
          Core.Main.Table &&
            Core.Main.Table.updateData({
              header: "Candidate",
              cols: Candidate.columns,
              rows: data || [],
              sortBy: "_name",
              reverseSort: false
            });
          Core.Main.Search.set("Active");
        });
        break;
      case "Employers":
        Employer.getAll(data => {
          Core.log({ employers: data });
          Core.Main.Table &&
            Core.Main.Table.updateData({
              header: "Employer",
              cols: Employer.columns,
              rows: data || [],
              sortBy: "_name",
              reverseSort: false
            });
          Core.Main.Search.set("Active");
        });
        break;
      case "Engagements":
        Engagement.getAll(data => {
          Core.log({ engagements: data });
          Core.Main.Table &&
            Core.Main.Table.updateData({
              header: "Engagement",
              cols: Engagement.columns,
              rows: data || [],
              sortBy: "_name",
              reverseSort: false
            });
        });
        break;
      case "Jobs":
        Job.getAll(data => {
          Core.log({ jobs: data });
          Core.Main.Table &&
            Core.Main.Table.updateData({
              header: "Role",
              cols: Job.columns,
              rows: data || [],
              sortBy: "_role",
              reverseSort: false
            });
          Core.Main.Search.set("Active");
        });
        break;
      default:
        Core.Main.Table &&
          Core.Main.Table.updateData({
            header: Core.Main.getRouteLabel(),
            cols: [],
            rows: []
          });
        break;
    }
  };
  render() {
    return (
      <MuiThemeProvider>
        <div id="main">
          <MainSideMenu ref={self => (Core.Main.SideMenu = self)} />
          <div id="right-side">
            <div id="top-bar">
              <IconButton
                aria-label="Menu"
                className="hint--right hamburger"
                onClick={ev => {
                  Core.Main.SideMenu &&
                    Core.Main.SideMenu.setState({
                      drawerOpened: !Core.Main.SideMenu.state.drawerOpened
                    });
                }}
              >
                <i className="material-icons">menu</i>
              </IconButton>
              <h1 className="main-padding">{Core.Main.getRouteLabel()}</h1>
              <div className="top-bar-tools">
                <HistoryMenu />
                <AnnoucementBell />
              </div>
            </div>
            <div id="middle-bar">
              <MainPlusButton ref={self => (Core.Main.Plus = self)} />
              <CopyButton ref={self => (Core.Main.Copy = self)} />
              <MainChips ref={self => (Core.Main.Chips = self)} />
              <MainSearchBar
                ref={self => (Core.Main.Search = self)}
                className="float-right"
              />
              <MainFilterButton ref={self => (Core.Main.Filter = self)} />
            </div>
            <div id="content">
              <MainTable
                ref={self => (this.Table = self)}
                fecthData={this.fetchData}
              />
            </div>
            <div id="bottom-bar">
              <Info ref={self => (this.Info = self)} />
            </div>
            <MainOverlay ref={self => (Core.Main.Overlay = self)} />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

/** * /
class MainTitle extends Component {
  update = em => this.setState({ updated: true });
  render() {
    return <h1 className="main-padding">{Core.Main.getRouteLabel()}</h1>;
  }
}
/** */

class MainOverlay extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      key: "",
      content: "",
      style: {},
      render: null
    };
  }
  update = em => this.setState({ updated: true });
  toggle = ({ key, render, style }) => {
    const overlay = document.getElementById("overlay").style;
    if (key === this.state.key) {
      overlay.display = "none";
      this.setState({ key: "", render: null, style: {} });
    } else {
      overlay.display = "block";
      this.setState({ key, render, style: style || {} });
    }
  };
  hide = () => {
    const overlay = document.getElementById("overlay").style;
    overlay.display = "none";
    this.setState({ key: "", render: null, style: {} });
  };
  render() {
    const { style } = this.state;
    return (
      <div id="overlay" style={{ ...style }}>
        {this.state.render instanceof Function && this.state.render()}
        <IconButton
          style={{ position: "absolute", top: 0, right: 0 }}
          onClick={this.hide}
        >
          <i className="material-icons">close</i>
        </IconButton>
      </div>
    );
  }
}

class MainPlusButton extends Component {
  go = ev => {
    switch (Core.Main.getRouteLabel()) {
      case "Accounts":
        Core.go("/account/create");
        break;
      case "Candidates":
        Core.go("candidate/create");
        break;
      case "Employers":
        Core.go("/employer/create");
        break;
      case "Jobs":
        Core.go("/job/create");
        break;
      default:
        break;
    }
  };
  update = em => this.setState({ updated: true });
  render() {
    return /accounts|candidates|employers|jobs/i.test(
      Core.Main.getRouteLabel()
    ) ? (
      <span
        aria-label={`Add a new ${Core.Main.getRouteLabel().slice(0, -1)}`}
        className="hint--right"
      >
        <IconButton className="c-purple" onClick={this.go}>
          <i className="material-icons">add_circle</i>
        </IconButton>
      </span>
    ) : (
      <span />
    );
  }
}

class MainFilterButton extends Component {
  collection = [];
  searches = {};

  openDrawer = ev => {
    Core.Main.Overlay.toggle({
      key: "main-filters",
      render: em => (
        <div className="main-filters">
          <div className="modules">
            {this.collection.map((header, headerIndex) => {

              if (!header.acl) {
                header.acl = {};
                header.acl.roles = [];
                header.acl.defaultValues = [];
              }

              if (header.admin && header.acl.roles.length < 1) {
                header.acl.roles = ['SysAdmin']
              }

              if (header.allFilters === false) {
                return false;
              }
              else if (header.acl.roles.length > 0 && !header.acl.roles.includes(Core.getUserRole())) {
                if (header.acl.defaultValues.length > 0) {
                  header.labels = header.labels.filter(h => header.acl.defaultValues.includes(h.label));
                } else {
                  return false;
                }
              }

              return (
                <MainFilterModule
                  key={`FilterModule${headerIndex}`}
                  header={header}
                  collapsed={header.collapsed}
                  collapsable={true}
                  styleList={{ maxHeight: "80vh" }}
                />
              );
            })}
          </div>
        </div>
      )
    });
  };
  update = em => {
    this.setState({ updated: true });
    this.Chips && this.Chips.update();
  };
  render() {
    return (
      <Fragment>
        <IconButton
          aria-label="All Filters"
          className="hint--left float-right"
          onClick={this.openDrawer}
        >
          <i className="material-icons">filter_list</i>
        </IconButton>
      </Fragment>
    );
  }
}

class SliderManager extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      value: [3, 10],
      header: this.props.header,
      afterChange: this.props.afterChange,
      data: [
        {
          label: '<5',
          selected: false,
          data: { valMin: 0, valMax: 5, matcher: '<' },
        },
        {
          label: '2-5',
          selected: false,
          data: { valMin: 2, valMax: 5, matcher: '-' }
        },
        {
          label: '5-10',
          selected: false,
          data: { valMin: 5, valMax: 10, matcher: '-' }
        },
        {
          label: '>10',
          selected: false,
          data: { valMin: 10, valMax: 100, matcher: '-' }
        },
        {
          label: 'Any',
          selected: false,
          data: { valMin: 0, valMax: 100, matcher: '-' }
        }

      ]
    };
  }

  resetMyChips = () => {
    this.state.data.forEach(item => item.selected = false);
  };

  chipClickedHandler = (model, index) => ev => {
    let data = this.state.data;
    let currentState = data[index];

    this.resetMyChips();

    data[index].selected = !currentState.selected;
    this.setState({ data });
    let selected = [];
    const labels = this.state.header.labels;

    selected = labels.filter(item => {
      const label = parseInt(item.label);

      if (model.data.matcher === '-') {
        return label > model.data.valMin && label < model.data.valMax
      } else if (model.data.matcher === '<') {
        return label < model.data.valMax
      } else if (model.data.matcher === '>') {
        return label > model.data.valMin
      }
      return null;

    });

    selected.push({ label: "-1", selected: false });

    this.state.afterChange(selected, { mainChipLabel: model.label, isRange: true });
  };

  rangeOnChange = (value) => {
    let min = value[0];
    let max = value[1];

    let mainChipLabel = `${min}-${max}`;
    this.setState({ value });

    if (value[1] === 20) {
      mainChipLabel = `${min}-20+`;
      max = 2000000;
    }

    let selected = [];
    const labels = this.state.header.labels;
    selected = labels.filter(i => parseInt(i.label) >= min && parseInt(i.label) <= max);
    selected.push({ label: "-1", selected: false });
    this.state.afterChange(selected, { mainChipLabel, isRange: true });
    this.resetMyChips();
  };

  render() {
    return (
      <div style={{ width: 300 }}>

        <Range style={{ paddingTop: 20, paddingBottom: 20 }}
          min={0}
          max={20}
          defaultValue={this.state.value}
          tipFormatter={value => { return (value === 20) ? '20+years' : `${value}-years` }}
          onAfterChange={this.rangeOnChange}

        />
        {this.state.data.map((model, index) => {
          const selected = model.selected;
          return (
            <Chip
              key={model.label}
              backgroundColor={selected ? "#536DFF" : "#fff"}
              labelColor={
                selected
                  ? "#FFF"
                  : !selected && model.selected
                    ? "#CCC"
                    : "#7A7A7A"
              }
              style={{
                margin: 5,
                display: "inline-block",
                border: selected
                  ? "none"
                  : "1px dotted var(--mainBorderColor)"
              }}
              labelStyle={{
                fontWeight: 300,
                fontSize: 13,
                lineHeight: "16px"
              }}

              onClick={this.chipClickedHandler(model, index)}

            >
              {model.label}
            </Chip>
          );
        })}
      </div>
    )
  }
}

class MainFilterModule extends Component {
  all = {};
  labels = [];
  filterKeys = {};
  filters = [];
  state = {
    value: 1,
    collapsed: !!this.props.collapsed
  };
  onRowSelection = (selectedRows, opts = {}) => {
    const { header } = this.props;
    this.filterKeys = {};

    selectedRows.forEach(item => (this.filterKeys[item.label] = true));

    this.filters = Object.keys(this.filterKeys);
    Core.Main.Table.filters[header.sortKey || header.key] = this.filterKeys;
    Core.Main.Table.filters[header.sortKey || header.key] = { data: this.filterKeys, opts };
    Core.Main.Table.updateData({});
    setTimeout(st => this.update());
  };
  update = em => this.setState({ updated: true });


  render() {
    const header = this.props.header;

    header.headerKey = header.sortKey || header.key;
    const searches = Object(Object(Core.Main.Filter).searches);
    searches[header.headerKey] = searches[header.headerKey] || "";
    const _search = Core.Main.Filter.searches[header.headerKey];
    let result = header.labels;
    const search = _search.trim();
    const labels = header.labels;
    if (search) {
      const searcher = new FuzzySearch(labels, ["label"], {
        caseSensitive: false
      });
      result = searcher.search(search);
    }
    return this.state.collapsed ? (
      <div
        className="filter-module inline-blocks pointer"
        onClick={ev => this.setState({ collapsed: false })}
      >
        <strong className="c-purple">{header.label}</strong>
        <i className="material-icons">arrow_drop_down</i>
      </div>
    ) : (
      <div className="filter-module">


        {this.props.collapsable ? (
          <Fragment>
            <div
              className="filter-module inline-blocks pointer"
              onClick={ev => this.setState({ collapsed: true })}
            >
              <strong className="c-purple">{header.label}</strong>
              <i className="material-icons ">arrow_drop_up</i>
            </div>
            <Divider />
          </Fragment>
        ) : (
          <strong className="c-purple">{header.label}</strong>
        )}
        {header.labels && header.labels.length >= 33 && (
          <div className="filter-button-search">
            <TextField
              className="filter-button-search-input"
              floatingLabelText="Search keywords"
              floatingLabelFixed={true}
              required={true}
              floatingLabelStyle={{ color: colors.gray }}
              floatingLabelFocusStyle={{ color: colors.purple, fontSize: 12 }}
              underlineFocusStyle={{ borderColor: colors.purple }}
              type="text"
              value={_search}
              onChange={(event, search) => {
                searches[header.headerKey] = search;
                this.update();
              }}
              autoFocus={this.props.autoFocus}
              fullWidth
            />
            {!!searches[header.headerKey] && (
              <div className="filter-button-search-clear pointer">
                <span aria-label="Clear search" className="hint--left">
                  <i
                    className="material-icons"
                    onClick={ev => {
                      searches[header.headerKey] = "";
                      this.update();
                    }}
                  >
                    cancel
                  </i>
                </span>
              </div>
            )}
          </div>
        )}

        {(header.key === "_years") ? (
          <SliderManager
            header={header}
            afterChange={this.onRowSelection}
          />) : (<div className="flex">
            {result.map((model, index) => {
              const selected = model.selected;
              return (
                <Chip
                  key={model.label}
                  backgroundColor={selected ? "#536DFF" : "#fff"}
                  labelColor={
                    selected
                      ? "#FFF"
                      : !selected && model.selected
                        ? "#CCC"
                        : "#7A7A7A"
                  }
                  style={{
                    margin: 5,
                    display: "inline-block",
                    border: selected
                      ? "none"
                      : "1px dotted var(--mainBorderColor)"
                  }}
                  labelStyle={{
                    fontWeight: 300,
                    fontSize: 13,
                    lineHeight: "16px"
                  }}
                  onClick={ev => {
                    result[index].selected = !result[index].selected;
                    let selected = [];
                    result.forEach((item, index) => {
                      if (item.selected === true) {
                        selected.push(item);
                      }
                    });
                    this.onRowSelection(selected);
                    Core.log({ result });
                  }}
                >
                  {model.label}
                </Chip>
              );
            })}
          </div>)
        }
      </div>
    );
  }
}

class MainSearchBar extends Component {
  sources = [];
  value = "";
  values = [];
  update = updateTable => {
    this.setState({ updated: true }, then => {
      updateTable && Core.Main.Table.update();
    });
  };
  set = value => {
    if (!this.values.find(i => i === value)) {
      this.values.push(value);
      Core.Main.Table.update();
    }
  };
  render() {
    const { className } = this.props;
    if (Core.Main.Search) {
      return (
        <div className={"search-bar" + (className ? " " + className : "")}>
          <i className="material-icons filter-icon">search</i>
          <AutoComplete
            name="search"
            placeholder="Enter a Keyword"
            className="search-input"
            underlineFocusStyle={{ borderColor: "transparent" }}
            filter={AutoComplete.fuzzyFilter}
            dataSource={Core.Main.Search.sources}
            listStyle={{ width: "auto" }}
            maxSearchResults={7}
            searchText={Core.Main.Search.value}
            onUpdateInput={search => {
              Core.Main.Search.value = search;
              Core.Main.Search.update();
              this.update();
            }}
            onNewRequest={search => {
              Core.Main.Search.value = "";
              Core.Main.Search.values.push(search);
              Core.Main.Search.update(true);
              this.update();
            }}
            fullWidth
          />
          &nbsp;
          {!!this.value.trim().length && (
            <span aria-label="Clear search" className="hint--left pointer">
              <i
                className="material-icons"
                onClick={ev => {
                  Core.Main.Search.value = "";
                  this.setState({ updated: true }, then =>
                    Core.Main.Table.updateData({})
                  );
                }}
              >
                cancel
              </i>
            </span>
          )}
        </div>
      );
    } else {
      return <span />;
    }
  }
}

class MainChips extends Component {
  collection = [];
  update = em => this.setState({ updated: true });
  unsetChip = item => {
    if (item.filter) {
      if (item.isRange) {
        delete Core.Main.Table.filters[item.key];
      } else {
        delete Core.Main.Table.filters[item.key].data[item.label];
      }
    } else if (item.search) {
      Core.Main.Search.values.splice(item.key, 1);
    }
    Core.Main.Table.update();
  };
  render() {
    if ((Core.Main.Chips, Core.Main.Search, Core.Main.Table)) {
      Core.Main.Chips.collection = [];
      Core.Main.Search.values.forEach((label, index) =>
        Core.Main.Chips.collection.push({ label, search: true, key: index })
      );

      Object.keys(Core.Main.Table.filters).forEach(key => {
        let root = Core.Main.Table.filters[key];
        if (root.opts && root.opts.isRange) {
          Core.Main.Chips.collection.push({ label: root.opts.mainChipLabel, filter: true, key, isRange: root.opts.isRange });
        } else {
          Object.keys(Core.Main.Table.filters[key].data).forEach(label => {
            Core.Main.Chips.collection.push({ label, filter: true, key });
          });
        }
      });
      return (
        <div className="main-chips">
          {Core.Main.Chips.collection.map(item => (
            <Chip
              key={Core.getKey()}
              className="chip"
              backgroundColor={colors.purple}
              labelColor={colors.white}
              labelStyle={{ fontWeight: 300, fontSize: 13 }}
              onRequestDelete={ev => Core.Main.Chips.unsetChip(item)}
            >
              {item.label}
            </Chip>
          ))}
        </div>
      );
    } else {
      return <div />;
    }
  }
}

class CopyButton extends Component {
  update = em => this.setState({ updated: true });
  bulkCopy() {
    let body = "";
    Core.Main.Table.selectedRows.forEach(row => {
      if (row.getPreview) {
        body += row.getPreview();
        body += "<br/><hr/>";
      }
    });
    Core.log({ length: body.length });
    copyHTML(`
      ${body}
    `)
      .then(em => {
        Core.log("Copy email command was successful");
        Core.showMessage("Copied!");
      })
      .catch(ex => {
        Core.log("Oops, unable to copy");
        Core.showMessage("Fail copy!");
      });
  }
  render() {
    return (Core.isPath("jobs") || Core.isPath("job/match")) &&
      !!Object(Object(Core.Main.Table).selectedRows).length ? (
      <span
        aria-label="Copy to clipboard selected jobs info"
        className="hint--left"
      >
        <IconButton onClick={ev => this.bulkCopy()}>
          <i className="material-icons">content_copy</i>
        </IconButton>
      </span>
    ) : (
      <span />
    );
  }
}

class MainSideMenu extends Component {
  constructor() {
    super(...arguments);
    this.state = { drawerOpened: false };
  }
  render() {
    const navLink = (section, icon, path) => {
      return (
        <div
          aria-label={section}
          className={`hint--right navlink${new RegExp(section, "i").test(window.location.href) ? " active" : ""
            }`}
          onClick={ev => {
            document.location.href = path;
            Core.Main.fetchData();
            Core.Main.Plus && Core.Main.Plus.update();
          }}
        >
          <i className="material-icons">{icon}</i>
        </div>
      );
    };
    return (
      <Fragment>
        <div id="left-side">
          <div className="side-menu">
            <div
              aria-label="Menu"
              className="hint--right"
              onClick={ev => {
                this.setState({
                  drawerOpened: !this.state.drawerOpened
                });
              }}
            >
              <i className="material-icons">menu</i>
            </div>
            {Core.isAdminOrRecruiterOnDev() && (
              <Fragment>
                {navLink(
                  "Engagements",
                  "sync", //"assignment_turned_in",
                  "/#/v2/engagements"
                )}
              </Fragment>
            )}
            {navLink("Candidates", "face", "/#/v2/candidates")}
            {navLink("Jobs", "business_center", "/#/v2/jobs")}
            {Core.isAdminOrCoordinator() && (
              <Fragment>
                {navLink("Employers", "business", "/#/v2/employers")}
                {navLink("Accounts", "people", "/#/v2/accounts")}
                {navLink("Reports", "insert_chart_outlined", "/#/reports")}
              </Fragment>
            )}
          </div>
        </div>
        <SideMenu
          drawerOpened={this.state.drawerOpened}
          closeDrawer={ev => this.setState({ drawerOpened: false })}
          logout={ev => {
            Core.logout();
            Core.root();
          }}
        />
      </Fragment>
    );
  }
}

class MainTable extends Component {
  cols = [];
  rows = [];
  collection = [];
  filters = {};
  selectedRows = [];
  load = 0;
  constructor() {
    super(...arguments);
    this.state = {
      header: "",
      sortBy: "",
      reverseSort: false,
      filter: ""
    };
  }
  buildFilters = em => {
    new Promise(resolve => {
      const collection = [];
      Core.Main.Table.cols.forEach(col => {
        col.headers.forEach(header => {
          const labels = {};
          header.headerKey = header.sortKey || header.key;
          Core.Main.Table.rows.forEach((row, rowIndex) => {
            const keys = header.multiple
              ? String(row[header.headerKey])
                .split(",")
                .map(i => i.trim())
                .filter(i => !!i && i !== "undefined")
              : [String(row[header.headerKey]).trim()].filter(
                i => !!i && i !== "undefined"
              );
            keys.forEach(key => (labels[key.trim()] = true));
          });

          header.labels = Object.keys(labels).sort((a, b) =>
            comparationMethod(a, b)
          );

          let resObj = {};

          if (Core.Main.Table.filters[header.headerKey]) {
            resObj = Core.Main.Table.filters[header.headerKey]["data"];
          }
          header.labels = header.labels.map(label => ({
            label,
            selected: !!Object(resObj)[label]
          }));

          collection.push(header);
        });
      });
      Core.Main.Filter.collection = collection.sort((a, b) =>
        comparationMethod(a.order || 9999, b.order || 9999)
      );
    }).then();
  };
  update = em => this.updateData({});
  updateData = ({ cols, rows, header, sortBy, reverseSort }) => {
    Debug.time("process");
    const search = Core.Main.Search.values.join(",").trim();
    header = header || this.state.header;
    sortBy = sortBy || this.state.sortBy;
    reverseSort = !!reverseSort;
    this.cols = cols || this.cols;
    this.rows = rows || this.rows;
    const sources = {};

    let filtersOnlyData = {};
    Object.keys(this.filters).forEach(i => {
      filtersOnlyData[i] = this.filters[i]["data"];
    });

    this.collection = this.rows.filter(row => {
      row.___keys___.forEach(label => [label.split(',')].flat(3).forEach(label2 => (sources[label2] = true)));
      let length = 0;
      const filters = Object.keys(filtersOnlyData);
      filters.forEach(key => (length += Object.keys(filtersOnlyData[key]).length));
      const filterResult = filters.every(key => {
        const keys = Object.keys(filtersOnlyData[key]);
        if (keys.length) {
          return keys.find(_key => {
            const _key_ = String(_key).replace(/\W/g, function (c) {
              return "\\" + c;
            });
            //Core.log({ _key_, rowKey: row[key] });
            return new RegExp(_key_, "i").test(String(row[key]));
          });
        } else {
          return true;
        }
      });
      if (!search.length && length === 0) {
        /** NO SET SEARCH AND FILTERS */
        return true;
      }

      if (!!search.length) {
        /** SET SEARCH AND FILTERS */
        return (
          Core.Main.Search.values.find(value => {
            const searcher = new FuzzySearch(row.___keys___, [], {
              caseSensitive: false
            });
            return searcher.search(value).length;
          }) && filterResult
        );
      }
      if (!!length) {
        /** SET JUST FILTERS */
        return filterResult;
      }
      return true;
    });
    this.collection = this.collection.sort((a, b) =>
      comparationMethod(a[sortBy], b[sortBy], reverseSort)
    );
    this.load = 1;
    Debug.showTimeEnd("process");
    Debug.time("render");
    this.setState({ header, sortBy, reverseSort }, then => {
      Core.Main.Search.sources = Object.keys(sources).sort();
      Core.Main.Search.update();
      Core.Main.Table.buildFilters();
      Core.Main.Table.updateInfo().then(res => Debug.showTimeEnd("render"));
    });
  };
  updatingData = em => {
    this.cols = [];
    this.rows = [];
    this.collection = [];
    this.filters = {};
    this.selectedRows = [];
    this.load = 0;
    this.List.unSelectAll();
    this.setState({});
    Core.Main.Overlay && Core.Main.Overlay.hide();
    Core.Main.Search && (Core.Main.Search.values = []);
  };
  toggleSort = em =>
    Core.Main.Table.updateData({ reverseSort: !this.state.reverseSort });
  onChangeHeader = ({ col, filteredHeaders, value }) => {
    Core.log("onChangeHeader", { col, filteredHeaders, value });
    const selectedHeader = filteredHeaders[value];
    const header = col.headers.find((header, headerIndex) => {
      if (header.label === selectedHeader.label) {
        col.selected = headerIndex;
        return true;
      } else {
        return false;
      }
    });
    const sortBy = header.sortKey || header.key;
    if (this.state.sortBy !== sortBy) {
      col.sortKey = sortBy;
      Core.Main.Table.updateData({
        header: header.label,
        sortBy,
        reverseSort: !!header.reverseSort
      });
    }
  };
  onRowSelection = selectedRows => {
    Core.Main.Table.selectedRows = selectedRows;
    Core.Main.Copy.update();
    Core.Main.Table.updateInfo();
  };
  updateInfo = em => {
    return new Promise(resolve => {
      if (Core.Main && Core.Main.Info) {
        const filters = Object.keys(this.filters)
          .map(key => {
            const or = Object.keys(this.filters[key]["data"]).join(" or ");
            return !!or.trim().length ? "(" + or + ")" : "";
          })
          .filter(i => !!i)
          .join(" and ");
        const searches = (Object(Core.Main.Search).values || []).join(", ");
        Core.Main.Chips.update();
        Core.Main.Filter.update();
        Core.Main.Overlay.update();
        Core.Main.Info.update(
          !!this.rows.length ? (
            <Fragment>
              {this.collection.length === this.rows.length ? (
                <span
                  aria-label="Total Items Downloaded"
                  className="hulk hint--top"
                >
                  <b>Items: {this.rows.length}</b>
                </span>
              ) : (
                <Fragment>
                  <span
                    aria-label="Total Items Shown"
                    className="hulk hint--top"
                  >
                    <b>Items: {this.collection.length}</b>
                  </span>
                  <span
                    aria-label="Total Items Downloaded"
                    className="hulk hint--top"
                  >
                    &nbsp;&nbsp;|&nbsp;&nbsp;Downloaded: {this.rows.length}
                  </span>
                </Fragment>
              )}
              {this.selectedRows === "all" ? (
                <span
                  aria-label="All rows are selected"
                  className="hulk hint--top"
                >
                  &nbsp;&nbsp;|&nbsp;&nbsp;Selected: {this.collection.length}
                </span>
              ) : (
                !!this.selectedRows.length && (
                  <span aria-label="Selected rows" className="hulk hint--top">
                    &nbsp;&nbsp;|&nbsp;&nbsp;Selected:{" "}
                    {this.selectedRows.length}
                  </span>
                )
              )}
              <span aria-label="Sorting" className="hulk hint--top">
                &nbsp;&nbsp;|&nbsp;&nbsp;Sort: {this.state.header}&nbsp;
                {this.state.reverseSort ? "desc" : "asc"}
              </span>
              {!!searches.length && (
                <span
                  aria-label={`Searches`}
                  className="hulk hint--top hint--large inline-blocks"
                >
                  <span>&nbsp;&nbsp;|&nbsp;&nbsp;Search:&nbsp;</span>
                  <span className="truncate" style={{ maxWidth: 256 }}>
                    {searches}
                  </span>
                </span>
              )}
              {!!filters.trim().length && (
                <span
                  aria-label={`Filters: ${filters}`}
                  className="hulk hint--top hint--large inline-blocks"
                >
                  &nbsp;&nbsp;|&nbsp;&nbsp;Filters:&nbsp;
                  <span className="truncate" style={{ maxWidth: 256 }}>
                    {filters}
                  </span>
                  <i className="material-icons">remove_red_eye</i>
                </span>
              )}
            </Fragment>
          ) : (
            <Fragment />
          ),
          resolve
        );
      } else {
        resolve();
      }
    });
  };
  render() {
    return (
      <VirtualizedList
        ref={self => (this.List = self)}
        listRowHeight={Core.isPath("engagements") ? 120 : 72}
        overscanRowCount={10}
        collection={this.collection}
        style={{ minWidth: 1024 }}
        headerRenderer={model =>
          this.load !== 0 && (
            <div className="row-content">
              {this.cols.map((col, colIndex) => {
                const filteredHeaders = col.headers.filter(
                  header => header.sortKey !== false
                );
                return (
                  <div
                    key={`HCol${colIndex}`}
                    className="list-header-col"
                    style={{
                      width: Math.floor(100 / this.cols.length) + "%",
                      borderBottom:
                        this.state.header === col.headers[col.selected].label
                          ? "4px solid var(--purple)"
                          : "none",
                      ...col.style
                    }}
                  >
                    <div
                      className="pointer hulk truncate"
                      onClick={ev => {
                        if (
                          this.state.header === col.headers[col.selected].label
                        ) {
                          this.toggleSort();
                        } else {
                          this.updateData({
                            header: col.headers[col.selected].label,
                            sortBy:
                              col.headers[col.selected].sortKey ||
                              col.headers[col.selected].key,
                            reverseSort: !!col.headers[col.selected].reverseSort
                          });
                        }
                      }}
                      style={{
                        color:
                          this.state.header === col.headers[col.selected].label
                            ? "var(--purple)"
                            : "var(--tableHFColor)"
                      }}
                    >
                      {col.headers[col.selected].label}
                    </div>
                    <div>
                      {(this.state.sortBy ===
                        col.headers[col.selected].sortKey ||
                        this.state.sortBy ===
                        col.headers[col.selected].key) && (
                          <span aria-label="Toggle Sort" className="hint--bottom">
                            <IconButton
                              className="no-side-paddings auto-widths"
                              onClick={this.toggleSort}
                            >
                              <i className="material-icons">
                                {this.state.reverseSort
                                  ? "arrow_upward"
                                  : "arrow_downward"}
                              </i>
                            </IconButton>
                          </span>
                        )}
                      {col.headers[col.selected].filter !== false && (
                        <span
                          aria-label="Apply Filters"
                          className="hint--bottom"
                        >
                          <MainTableFilterButton
                            header={col.headers[col.selected]}
                          />
                        </span>
                      )}
                      {filteredHeaders.length > 1 && (
                        <span
                          aria-label="Change Header"
                          className="hint--bottom"
                        >
                          <IconMenu
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom"
                            }}
                            targetOrigin={{
                              horizontal: "right",
                              vertical: "bottom"
                            }}
                            className="no-side-paddings auto-widths"
                            onChange={(proxy, value) =>
                              this.onChangeHeader({
                                col,
                                colIndex,
                                filteredHeaders,
                                value
                              })
                            }
                            iconButtonElement={
                              <IconButton>
                                <i className="material-icons">more_vert</i>
                              </IconButton>
                            }
                          >
                            {filteredHeaders.map(
                              (header, headerIndex) =>
                                header.visible !== false && (
                                  <MenuItem
                                    key={`HCol${colIndex}Header${headerIndex}`}
                                    value={headerIndex}
                                    primaryText={header.label}
                                  />
                                )
                            )}
                          </IconMenu>
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )
        }
        rowRenderer={model => {
          return (
            <div
              className="row-content"
              onClick={ev => {
                Core.log({ model });
                /** * /
                if (model.openDetails instanceof Function) {
                  model.openDetails();
                }
                /** */
              }}
            >
              {this.cols.map((col, colIndex) => (
                <div
                  key={`BRow${model.id}Col${colIndex}`}
                  className="row-content-col"
                  style={{
                    width: Math.floor(100 / this.cols.length) + "%",
                    ...col.style
                  }}
                >
                  {col.headers.map((header, headerIndex) => {
                    const value = model[header.key];
                    const tooltip =
                      (header.hint || header.label) +
                      (typeof value === "string" ? ": " + value : "");
                    return (
                      header.visible !== false && (
                        <div
                          key={`BRow${model.id
                            }Col${colIndex}Header${headerIndex}`}
                          className="row-content-col-line"
                        >
                          {header.hint === false ? (
                            <span className="block">
                              <div className="">{value || mdash}</div>
                            </span>
                          ) : (
                            <span
                              aria-label={tooltip}
                              className="block hint--bottom hint--medium"
                            >
                              <div className="hulk truncate">
                                {value || mdash}
                              </div>
                            </span>
                          )}
                        </div>
                      )
                    );
                  })}
                </div>
              ))}
            </div>
          );
        }}
        noRowsRenderer={em => {
          return (
            <div className="virtualized-list-no-row">
              {this.load === 0 ? (
                <Fragment>
                  <span>Loading&nbsp;</span>
                  <img alt="loading..." height="16" src={loader} />
                </Fragment>
              ) : (
                <span>No Rows</span>
              )}
            </div>
          );
        }}
        onRowSelection={this.onRowSelection}
        onClickBody={ev => Core.Main.Overlay.hide && Core.Main.Overlay.hide()}
      />
    );
  }
}

class MainTableFilterButton extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      open: false
    };
  }
  open = em => {
    Core.Main.Overlay.toggle({
      key: this.props.header,
      render: em => (
        <MainFilterModule header={this.props.header} autoFocus={true} />
      ),
      style: {
        overflow: "auto",
        height: "auto",
        top: "calc(var(--topBarHeight) + var(--middleBarHeight) + 48px)",
        maxHeight:
          "calc(100vh - var(--topBarHeight) - var(--middleBarHeight) - 48px - var(--bottomBarHeight))",
        padding: "24px 24px 10px",
        boxShadow: "2px 2px 5px var(--tableHFColor)"
      }
    });
    this.setState({
      open: !this.state.open
    });
  };
  close = ev => this.setState({ open: false });
  render() {
    return (
      <IconButton
        className="no-side-paddings auto-widths"
        onClick={ev => this.open(ev.currentTarget)}
      >
        <i className="material-icons">filter_list</i>
      </IconButton>
    );
  }
}

class RowCheckbox extends Component {
  render() {
    const { model, onCheck } = this.props;
    return (
      <div className="row-checkbox-wrapper">
        <Checkbox
          checked={model.selected}
          onCheck={(ev, checked) => {
            model.selected = checked;
            this.setState({ updated: true });
            onCheck && onCheck(checked);
          }}
        />
      </div>
    );
  }
}

class Info extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      content: this.props.children || <Fragment />
    };
  }
  update = (content, cb) =>
    this.setState({ content }, then => cb && cb(this.state));
  render() {
    return <div className="info">{this.state.content}</div>;
  }
}

class VirtualizedList extends Component {
  all = { selected: false };
  unSelectAll = em => {
    const collection = this.props.collection;
    this.all = { selected: false };
    collection.forEach((item, index) => {
      item.selected = false;
    });
    this.props.onRowSelection([]);
    this.setState({ updated: true });
  };
  render() {
    const collection = this.props.collection;
    // Core.log({ collection });
    const rowCount = collection.length || 0;
    const listRowHeight = this.props.listRowHeight || 32;
    const overscanRowCount = this.props.overscanRowCount || 5;
    const toggleCheckOnRowClick = this.props.toggleCheckOnRowClick || false;
    const style = this.props.style;
    const onRowSelection = em => {
      if (this.props.onRowSelection) {
        let selected = [];
        collection.forEach((item, index) => {
          if (item.selected === true) {
            selected.push(item);
          }
        });
        this.props.onRowSelection(selected);
      }
    };
    const headerRenderer =
      this.props.headerRenderer && this.props.headerRenderer();
    const listHeader = headerRenderer && (
      <div className="virtualized-list-header">
        <RowCheckbox
          model={this.all}
          onCheck={checked => {
            collection.forEach(item => {
              item.selected = this.all.selected;
            });
            onRowSelection();
            this.setState({ updated: true });
          }}
        />
        <div className="virtualized-list-row-content">{headerRenderer}</div>
      </div>
    );
    const rowRenderer = ({ key, index, style }) => {
      const model = collection[index];
      return (
        <div
          key={key}
          index={index}
          style={style}
          className={
            "virtualized-list-row hulk-bg" +
            (toggleCheckOnRowClick ? " pointer" : "")
          }
          onClick={ev => {
            if (toggleCheckOnRowClick) {
              this.all.selected = false;
              model.selected = !model.selected;
              onRowSelection();
              this.setState({ updated: true });
            }
          }}
        >
          <RowCheckbox
            model={model}
            onCheck={checked => {
              this.all.selected = false;
              onRowSelection();
              this.setState({ updated: true });
            }}
          />
          <div className="virtualized-list-row-content">
            {this.props.rowRenderer(model, index)}
          </div>
        </div>
      );
    };
    const noRowsRenderer =
      this.props.noRowsRenderer ||
      (em => {
        return (
          <div className="virtualized-list-no-row">
            <span>No Rows</span>
          </div>
        );
      });
    return (
      <div
        style={{
          overflow: "hidden",
          width: "100%",
          height: "100%",
          ...style
        }}
      >
        {listHeader}
        <div className="virtualized-list-body" onClick={this.props.onClickBody}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                ref={self => (this.List = self)}
                className="virtualized-list"
                overscanRowCount={overscanRowCount}
                rowCount={rowCount}
                rowHeight={listRowHeight}
                rowRenderer={rowRenderer}
                noRowsRenderer={noRowsRenderer}
                width={width}
                height={height}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

export default MainV2;
