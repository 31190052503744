import React, {Component, Fragment} from 'react';
import Row from "../Forms/Row";
import Col from "../Forms/Col";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

class QuestionsMustHave extends Component {
  constructor() {
    super(...arguments);
    this.state = {actualArray:this.props.existing||[],updatedArray:this.props.existing||[]};
  }

  handlerFieldOnBlur = (element)=>{
    let arr = this.state.updatedArray;
    let unChangedElements = arr.filter(el=>parseInt(el.id) !== parseInt(element.id));

    let newArray = [...unChangedElements,element];
    this.setState({updatedArray:newArray.filter(el=>!!el.question)}, st=>{
      this.props.parentUpdater(this.state.updatedArray)
    });
  }

  handlerDeleteField = (element)=>{
    let arr = this.state.updatedArray;
    let newArray = arr.filter(el=>parseInt(el.id) !== parseInt(element.id));

    this.setState({updatedArray:newArray}, st=>{
      this.props.parentUpdater(this.state.updatedArray)
    });
  }

  dataToFields(){
    const dataArray = this.state.updatedArray;

    return dataArray.sort((a, b) => a.id - b.id).map((el,index)=>{
      return <Row key={`question-${index}`}>
        <Col fullWidth>
          <SingleQuestion textKey="question"
                          handlerDeleteItem ={this.handlerDeleteField}
                          element={el}
                          onDone={this.handlerFieldOnBlur}/>
        </Col>
      </Row>;
    })
  }

  insertOneToArray = ()=>{
    const dataArray = this.state.updatedArray;
    let maxId = !!dataArray.length ? Math.max(...dataArray.map(el=>el.id)) : 0;
    this.setState({updatedArray:[...this.state.updatedArray,{id:maxId+1,question:''}]})
  }

  render() {
    const {updatedArray,actualArray} = this.state;

    if(!this.state.updatedArray.length){
      this.insertOneToArray();
    }

    return <Fragment>
      <Row>
        <Col fullWidth>
          <label>Required Submission Information
            <Fab onClick={this.insertOneToArray} color="primary" aria-label="Add" style={{width:'35px', marginLeft:'5px', height:'15px', boxShadow:'none'}}>
              <AddIcon />
            </Fab>
          </label>
          {(updatedArray.length!==actualArray.length) && <span style={{color:'red',fontSize:'11px'}}>Changes will not be reflected in existing jobs, only future new jobs</span>}
        </Col>
      </Row>
      {this.dataToFields()}
    </Fragment>;

  }
}


class SingleQuestion extends Component {
  constructor(){
    super(...arguments);

    this.state={value: this.props.element[this.props.textKey]}
  }

  render(){
    let {element,textKey,handlerDeleteItem,onDone} = this.props;
    let {value} = this.state;

    return <Fragment >
        <InputBase
          style={{width: '90%', flex: 1}}
          placeholder="Write your question here"
          value={value}
          onChange={e=>{
              this.setState({value:e.target.value});
              element[textKey] = e.target.value;
            }
          }
          onBlur={e=>{
            onDone(element);
          }}
        />
        <IconButton onClick={()=>handlerDeleteItem(element)} style={{padding: 10}} aria-label="Delete">
          <DeleteIcon />
        </IconButton>
      <br/>
    </Fragment>
  }
}
export default QuestionsMustHave;
