/** ============================================ µ
 * @description Submit to ATS [JSX]
 *              UI Component
 * @route       /candidate/resume-submission/:candidateId/:jobId
 * @createdAt   2021-05-12
 * @updatedAt   2021-05-17
 * ============================================ */
/* IMPORTS ==================================== */

import FlatButton from "material-ui/FlatButton";
import React from "react";
import Core from "../../../lib/Core";
import {
  ATS_TYPE__GH_HARVEST_ID,
  ATS_TYPE__GH_INGESTION_ID,
  EMP_SUB_MET__ATS_ID
} from "../../../lib/Definition";
import { tools } from "../../../lib/GenericTools.lib";

/* METHODS ==================================== */

/**
 *
 * @param {*} props
 * @returns
 */
function AtsSubmitButton(props) {

  const [sendingATS, setSendingATS] = React.useState(false);
  const [isSubmitToAtsFailed, setSubmitToAtsFailed] = React.useState(false);

  const {
    atsTypeId,
    employerName,
    submissionMethods,
    manualSubmissionUrl,
    onClick: onClickCallback = async () => { }
  } = props;

  const is10x10Employer = !!employerName.match(
    /10\s*(?:x|by)\s*10/i
  );

  const isAtsSubmitDisabled = (
    sendingATS || (
      Core.isOnDev() && !is10x10Employer
    )
  );

  const isAtsManuallyAvailable = (
    submissionMethods.includes(EMP_SUB_MET__ATS_ID) &&
    (
      ![
        ATS_TYPE__GH_HARVEST_ID,
        ATS_TYPE__GH_INGESTION_ID,
      ].includes(atsTypeId) ||
      isSubmitToAtsFailed
    )
  );

  const isAtsGhHarvestSelected = (
    atsTypeId === ATS_TYPE__GH_HARVEST_ID
  );

  const isAtsGhIngestionSelected = (
    atsTypeId === ATS_TYPE__GH_INGESTION_ID
  );

  const isSubmitToGhHarvestAvailable = (
    Core.isAdminOrCoordinator() &&
    !isAtsManuallyAvailable &&
    isAtsGhHarvestSelected
  );

  const isSubmitToGhIngestionAvailable = (
    Core.isAdminOrCoordinator() &&
    !isAtsManuallyAvailable &&
    isAtsGhIngestionSelected
  );

  const apiName = (
    isSubmitToGhHarvestAvailable
      ? 'harvest'
      : isSubmitToGhIngestionAvailable
        ? 'ingestion'
        : ''
  );

  function onClickSendATS(apiName) {
    return async event => {
      setSendingATS(true);
      setSubmitToAtsFailed(false);
      const result = await onClickCallback(apiName);
      if (!result || !!result?.errors) {
        setSubmitToAtsFailed(true);
      }
      setSendingATS(false);
    }
  }

  return (
    <div className="d-flex">
      {!!apiName && (
        <FlatButton
          label={
            sendingATS
              ? "SENDING..."
              : `Submit to ATS(${apiName})`
          }
          className="btn-cyan-white"
          onClick={onClickSendATS(apiName)}
          disabled={isAtsSubmitDisabled}
        />
      )}
      {isAtsManuallyAvailable && (
        <FlatButton
          title={`Take to the ATS URL`}
          label={"Manual Submit to ATS"}
          className={"btn-white-cyan hint--top hint--rounded"}
          ariaLabel={
            !manualSubmissionUrl && "Missing manual submission url"
          }
          onClick={event => {
            window.open(
              tools.withHttp(manualSubmissionUrl),
              "_blank"
            );
          }}
          disabled={!manualSubmissionUrl}
        />
      )}
      <div className="d-flex flex-column mt-1 ml-1">
        {isAtsSubmitDisabled && !sendingATS && (
          <small className="c-red">
            Submit to ATS is disabled on development environments for non 10x10 employers.
          </small>
        )}
        {isAtsManuallyAvailable && !manualSubmissionUrl && (
          <small className="c-red">
            Manual submission URL is missing!
          </small>
        )}
        {isSubmitToAtsFailed && (
          <>
            <small className="c-red">
              Submit to ATS failed!
          </small>
            <small className="c-red">
              Please review the ATS settings on Employer/Process.
          </small>
          </>
        )}
      </div>
    </div>
  );
}

/* DICTIONARIES =============================== */



/* EXPORTS ==================================== */

export {
  AtsSubmitButton as default,
  AtsSubmitButton,
};

/* ============================================ */