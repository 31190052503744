/** ============================================ µ
 * @Description GenericTools Library [JS]
 * @CreatedAt   2021-03-22
 * @ReviewedAt  2021-08-12 Thu
/** =========================================== */

/* IMPORTS ==================================== */

import ReactDOMServer from "react-dom/server";
import copy from "copy-to-clipboard";
import Core from "./Core";

/* METHODS ==================================== */

/**
 *
 * @param {string} value
 * @returns
 */
function capitalize(value = '') {
  return value.replace(/^\s*\w/,
    c => c.toUpperCase()
  );
}

/**
 *
 * @param {string} value Value to encode
 * @returns base64 string
 */
function encodeBase64(value) {
  return window.btoa(value);
}

/**
 * @param {string} value resume url at 10x10
 * @returns {string} filename
 * @samples
 * - http://.../download/filename.pdf
 * - filename.pdf
 */
function getFilenameFromURL(value = '') {
  return value.match(/download\/(.+)$/)[1];
}

/**
 * Returns a new object based in a model.
 *
 * @param {object} model
 * @returns new Model Object
 */
function newModel(model) {
  return JSON.parse(JSON.stringify(model));
}

/**
 * Replace middle content of a long string
 * with 3 dots
 *
 * @param {string} str
 * @returns
 */
function partialCensor(str = '') {
  return `${str.slice(0, 3)}...${str.slice(-3)}`;
}

/**
 * @param {string} value phone to validate
 * @returns boolean
 */
function validatePhone(value = '') {
  return (
    value.replace(/\D/g, '').length >= 10
  );
}

/**
 *
 * @param {string} value email to validate
 * @returns boolean
 */
function validateEmail(value = '') {
  return !!value.match(
    /^[a-zA-Z0-9.!#µ$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/i
  );
}

/**
 *
 * @param {string} value url to validate
 * @returns boolean
 */
function validateURL(value = '') {
  return !!value.match(
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
  );
}

/**
 * @todo document and rename 2021-05-13 µ
 *
 * @param {*} url
 * @returns
 */
function withHttp(url) {
  return !/^http/i.test(url) ? `http://${url}` : url;
}

/**
 * This is to facilite understanding of the logic in certain rules.
 *
 * Parses a value to a boolean.
 *
 * @param {*} value
 * @returns
 */
function YES(value) {
  return !!value;
}

/**
 * This is to facilite understanding of the logic in certain rules.
 *
 * Parses a value to a negated-boolean.
 *
 * @param {*} value
 * @returns
 */
function NOT(value) {
  return !value;
}

/**
 *
 * @param {boolean[]} results
 * @returns {number}
 */
function parseBinaryResults(results = []) {
  return parseInt(results.map(n => Number(n)).join(''), 2);
}

/**
 *
 * @param {string} name
 * @returns
 */
const getUnitTestingItemId = name => Number((name?.match(/__.{1}(\d*)/i) || [])[1]);

const getArrayDiff = (a = [], b = []) => b.filter(x => !a.includes(x)).concat(a.filter(x => !b.includes(x)));

const getArrayOfNumberSeries = str => str.split(',').map(s => {
  let res = str;
  const range = s.split('-');
  if (range.length === 2) {
    res = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95].filter(v => range[0] <= v && v <= range[1]).join(',');
  }
  else {
    res = Number(range[0]);
  }
  // console.debug(str, res);
  return res;
}).join(',').split(',').map(v => Number(v));

/**
 * Helper to check if path match with current location
 */
function isLocationPath(path = '') {
  const location = window.location.hash;
  const result = (
    !!location.match(
      new RegExp(path.replace(':id', '\\w+$'), 'i')
    )
  );
  console.debug('µ:isLocationPath', { location, path, result });
  return result;
}

/**
 * 
 * Tries to return the text content from JSX component or html string
 * 
 * Warning: 
 * Don't abuse of using this.
 * Don't used in a list.
 * It is heavy processing data.
 * 
 * @param {*} html 
 * @returns 
 */
function getTextContent(html) {
  if (typeof html) { return html.replace(/<.+>/g, ''); }
  return ReactDOMServer.renderToString(html).replace(/<.+>/g, '');
}

function chipMatch(result) {

  let className = '';

  if (result === false) {
    className = "bg-red c-white";
  } else if (result === true) {
    className = "border-green";
  } else {
    className = "border-gray";
  }

  return className;

}

function copyString(str) {
  if (
    copy(str, {
      debug: true,
      message: "Press #{key} to copy"
    })
  ) {
    Core.showMessage(`Value copied!`);
  } else {
    Core.showMessage("Fail copy!");
  }
}

export function getShortURL(url = '') {
  return url.replace(/http(s*):\/\/|www\.|\/$/g, '');
}

export function removeRichTextFormat(value) {
  return String(value).replace(/<\/?(p|ul|ol|li|div|br|strong|hr)(\s\.)?\/?>/gi, '').trim();
}

/* DICTIONARIES =============================== */

const GenericTools = {
  capitalize,
  encodeBase64,
  getFilenameFromURL,
  newModel,
  partialCensor,
  validateEmail,
  validatePhone,
  validateURL,
  withHttp,
}

/* EXPORTS ==================================== */
export {
  GenericTools as default,
  GenericTools as tools,
  capitalize,
  encodeBase64,
  getFilenameFromURL,
  newModel,
  partialCensor,
  validateEmail,
  validatePhone,
  validateURL,
  withHttp,
  YES,
  YES as BOOLEAN,
  NOT,
  parseBinaryResults,
  getUnitTestingItemId,
  getArrayDiff,
  getArrayOfNumberSeries,
  isLocationPath,
  getTextContent,
  chipMatch,
  copyString,
};
/* ============================================ */

/** Supported ways to import * /

import GenericTools from "./lib/GenericTools.lib.js";
import * as GenericTools from "./lib/GenericTools.lib.js";
import { GenericTools } from "./lib/GenericTools.lib.js";

import tools from "./lib/GenericTools.lib.js";
import * as tools from "./lib/GenericTools.lib.js";
import { tools } from "./lib/GenericTools.lib.js";

/** */