import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React, { Component, Fragment } from "react";

class RequirementsSection2 extends Component {
  render() {
    const {
      selectedJob,
      stringToChips
    } = this.props;

    return <Fragment>
      <Card className="match-new section-card">
        <div>
          <CardContent className="match-new card-content">
            <h2 className="match-new section-header">EXPERIENCE AND SIGNALS</h2>
            <hr />
            {!!selectedJob && (
              (
                typeof selectedJob.idealMinExperience !== "undefined" &&
                selectedJob.idealMinExperience > 0
              ) ||
              (
                typeof selectedJob.idealMaxExperience !== "undefined" &&
                selectedJob.idealMaxExperience > 0
              )
            ) &&
              <Fragment>
                <div style={{
                  display: "inline-flex",
                  flexWrap: "wrap",
                  alignItems: "center"
                }}>

                  {!!selectedJob && !!selectedJob.idealMinExperience ?
                    <div
                      style={{
                        display: "inline-flex",
                        flexWrap: "wrap"
                      }}
                    >
                      Ideal years of experience: {stringToChips(selectedJob.idealMinExperience)}</div>
                    :
                    null
                  }
                  {!!selectedJob && !!selectedJob.idealMaxExperience ?
                    <div
                      style={{
                        display: "inline-flex",
                        flexWrap: "wrap"
                      }}
                    >
                      Max years: {stringToChips(selectedJob.idealMaxExperience)}</div>
                    :
                    null
                  }

                </div>
              </Fragment>
            }
            <Fragment>
              {!!selectedJob && !!selectedJob._experience && <div><strong>Experience</strong>: {stringToChips(selectedJob._experience)}</div>}
              {!!selectedJob && !!selectedJob._positiveSignals && <div><strong>Positive Signals</strong>: {stringToChips(selectedJob._positiveSignals)}</div>}
              {!!selectedJob && !!selectedJob._negativeSignals && <div><strong>Negative Signals</strong>: {stringToChips(selectedJob._negativeSignals)}</div>}
            </Fragment>
          </CardContent>
        </div>
      </Card>
    </Fragment>
  }
}

export default RequirementsSection2;