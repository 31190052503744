/** ============================================ µ
 * @description EngagementCard > MoreOptions (3 dots menu) [JSX]
 *              UI Component
 * @routes      /engagements
 *              /engagement/view/:id
 *              /engagement/represent/:id
 * @createdAt   2021-08-20 Fri
 * @updatedAt   2021-11-17 Wed
 * ============================================ */
/* IMPORTS ==================================== */

import Menu from '@mui/material/Menu';
import {
  IconButton,
  MenuItem
} from "material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Candidate from "../../../lib/Candidate";
import Core from "../../../lib/Core";
import Engagement from "../../../lib/Engagement";
import { openMessageEmailPreview } from "../../../lib/services/Email/Email.lib";
import { openCandidateRepresentEmailPreview } from "../../../lib/services/Email/EmailCandidate.lib";
import { openEmployerReminderEmailPreview, openEmployerReminderEmailPreview_, openJobReminderEmailPreview } from "../../../lib/services/Email/EmailEmployer.lib";
import {
  openRecruiterAskPermissionEmailPreview,
  openRecruiterAskPermissionEmailPreviewV2,
  openRecruiterReminderEmailPreview,
  openRecruiterReminderEmailPreviewV2
} from "../../../lib/services/Email/EmailRecruiter.lib";
import copyHTML from "../../../lib/tools/copyHtml";
import downloadFile from "../../../lib/tools/downloadFile";
import ConfirmDialog from "../../Dialogs/ConfirmDialog";
import ResumeMatch from "../../Shared/ResumeMatch";
import ModalUpdateStatus from "./ModalUpdateStatus";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function MoreOptions(props) {
  const { t } = useTranslation();
  const {
    EngagementCardController = {}
  } = props;
  const {
    parent: EngagementsListController = {},
    state = {}
  } = EngagementCardController;
  const { data: engagements = [] } = EngagementsListController;
  const engagement = state;
  const {
    status,
    employer = {},
    candidateId,
    candidate = {},
    job = {},
  } = engagement;
  const {
    accountId: recruiterId
  } = candidate;
  const isStatusAskPermission = !!status.match(/ask permission/i);

  function onClickMessage(event) {
    openMessageEmailPreview({
      employer,
      engagement,
      engagements,
    });
  }

  function onClickRepresentEmail(event) {
    openCandidateRepresentEmailPreview({
      candidateId,
      engagement
    });
  }

  function onClickEmployerReminder_(event) {
    openEmployerReminderEmailPreview_({
      employer,
      engagements
    });
  }

  function onClickEmployerReminder(event) {
    openEmployerReminderEmailPreview({
      employer,
      engagements
    });
  }

  function onClickJobReminder(event) {
    openJobReminderEmailPreview({
      job,
      employer,
      engagements
    });
  }

  function onClickRecruiterReminder_(event) {
    openRecruiterReminderEmailPreview({
      recruiterId,
      engagements
    })
  }

  function onClickRecruiterReminder(event) {
    openRecruiterReminderEmailPreviewV2({
      recruiterId,
      engagements
    })
  }

  function onClickAskPermission(event) {
    openRecruiterAskPermissionEmailPreview({
      recruiterId,
      engagements,
      onDone: () => EngagementCardController.reloadData()
    });
  }

  function onClickAskPermissionV2(event) {
    openRecruiterAskPermissionEmailPreviewV2({
      recruiterId,
      engagements,
      onDone: () => EngagementCardController.reloadData()
    });
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (method = () => { }) => event => {
    setAnchorEl(null);
    setTimeout(() => method(event));
  };

  return (
    Core.isAdminOrCoordinator() && (
      <>

        <IconButton
          style={{
            height: 24,
            width: 24,
            padding: 0
          }}
          iconStyle={state.openedColor}
          onClick={handleClick}
        >
          <i className="material-icons">more_vert</i>
        </IconButton>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose()}
          closeAfterTransition
          PaperProps={{
            style: {
              maxHeight: '80vh',
              width: 280,
            },
          }}
        >
          {Core.isAdminOrCoordinator() && (
            <>
              <MenuItem
                primaryText={t("View Streak threads")}
                onClick={handleClose(EngagementCardController.openEmailThreads)}
              />
              <MenuItem
                primaryText="Add a Streak comment"
                onClick={handleClose(EngagementCardController.openCommentDialog)}
              />
              <MenuItem
                primaryText="Edit Streak notes"
                onClick={handleClose(EngagementCardController.openNotesDialog)}
              />
              <MenuItem
                primaryText="Update Status"
                onClick={handleClose(ev => Core.getKeyValue('EngCard-modalUpdateStatus')?.open({ ...state, engagement }))
                }
              />
              <MenuItem
                primaryText="Copy"
                onClick={handleClose(ev =>
                  copyHTML(EngagementCardController.getPreview(job))
                    .then(em => {
                      Core.log("Copy email command was successful");
                      Core.showMessage("Copied!");
                    })
                    .catch(ex => {
                      Core.log("Oops, unable to copy");
                      Core.showMessage("Fail copy!");
                    })
                )}
              />
              <MenuItem
                primaryText="Update Dates"
                onClick={handleClose(EngagementCardController.openDatesDialog)}
              />
              <MenuItem
                primaryText="View"
                onClick={handleClose(ev => {
                  document.location.href =
                    "/#/engagement/view/" + engagement.id;
                })}
              />
              <MenuItem
                primaryText="Message"
                onClick={handleClose(onClickMessage)}
              />
            </>
          )}
          <MenuItem
            primaryText="Represent Email"
            onClick={handleClose(onClickRepresentEmail)}
          />
          <MenuItem
            primaryText="Employer Reminder"
            onClick={handleClose(onClickEmployerReminder_)}
          />
          <MenuItem
            primaryText="Employer Reminder (API Envelope)"
            onClick={handleClose(onClickEmployerReminder)}
          />
          <MenuItem
            primaryText="Job Reminder (API Envelope)"
            onClick={handleClose(onClickJobReminder)}
          />
          <MenuItem
            primaryText="Recruiter Reminder (Old)"
            onClick={handleClose(onClickRecruiterReminder_)}
          />
          <MenuItem
            primaryText="Recruiter Reminder (New)"
            onClick={handleClose(onClickRecruiterReminder)}
          />
          {isStatusAskPermission && (
            <MenuItem
              primaryText="Ask Permission (Old)"
              onClick={handleClose(onClickAskPermission)}
            />
          )}
          {isStatusAskPermission && (
            <MenuItem
              primaryText="Ask Permission (New)"
              onClick={handleClose(onClickAskPermissionV2)}
            />
          )}
          {Core.isAdmin() && (
            <>
              <MenuItem
                primaryText="Delete"
                onClick={handleClose(ev =>
                  EngagementCardController.ConfirmDialog.open({
                    message: `Delete Engagement "${candidate._name} with ${job._name}`,
                    onConfirm: ev =>
                      Engagement.delete(engagement.id, response =>{
                        Core.refresh();
                      })
                  })
                )}
              />

              <NavLink
                to={`/candidate/matchNew/${candidate.id}`}
                className="navlink"
              >
                <MenuItem primaryText="Match" />
              </NavLink>

              {!!candidate && !!candidate.linkedInURL && (
                <MenuItem
                  title="LinkedIn"
                  primaryText="LinkedIn"
                  onClick={handleClose(event => {
                    Core.openPopUp(candidate.linkedInURL);
                  })}
                />
              )}

              {!!candidate && !!candidate.gitHubURL && (
                <MenuItem
                  title="Github"
                  primaryText="Github"
                  onClick={handleClose(event => {
                    Core.openPopUp(candidate.gitHubURL);
                  })}
                />
              )}

              {!!candidate && candidate.resumeTxtUrl && (
                <ResumeMatch
                  candidateResume={candidate.resumeTxtUrl}
                  jobSkills={job._technicalSkills}
                  employerSkills={job.employer._technicalSkills}
                  onClick={handleClose}
                />
              )}
              {!!candidate && !!candidate.resumes.length && (
                <MenuItem
                  title="Curriculum Vitae"
                  primaryText="CV"
                  onClick={handleClose(event => {
                    console.log(candidate);
                    Core.openPopUp(Candidate.getMyPdfUrl(candidate));
                  })}
                />
              )}

              {!!candidate && !!candidate.resumes.length && candidate.resumes.map((resume, index) => (
                <MenuItem
                  key={`engagement-${engagement.id}-card-candidate-resume-${resume.url}`}
                  title={`Download CV ${index + 1}`}
                  primaryText={`Download CV ${index + 1}`}
                  onClick={handleClose(
                    e => {
                      downloadFile({
                        url: resume.url,
                        mimeType: "application/pdf",
                        onError: (err) => { console.log(err) }
                      });
                    }
                  )}
                />
              ))}
              {/** @todo to remove after qa process - 2021-07-08 µ */}
              {Core.isOnDev() && (
                <MenuItem
                  title={`Submit Resume`}
                  primaryText={`Resume Submission`}
                  onClick={handleClose(
                    e => {
                      document.location.href = (
                        "/#/candidate/resume-submission/" +
                        engagement.candidate.id +
                        "/" +
                        engagement.jobId
                      )
                    }
                  )}
                />
              )}
            </>

          )}
        </Menu>
        <ModalUpdateStatus ref={self => Core.setKeyValue('EngCard-modalUpdateStatus', self)} parent={EngagementCardController} sourceClass="EngagementCard" />
        <ConfirmDialog
          ref={self => (EngagementCardController.ConfirmDialog = self)}
          title="Delete Engagement?"
          message="This action can't be undone."
          actionLabel="Delete"
        />
      </>
    )
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { MoreOptions as default, MoreOptions };

/* ============================================ */