/** ============================================ µ
 * @description Common Email [JS]
 *              Library
 * @createdAt   2021-04-13
 * @reviewedAt  2021-05-24
/** =========================================== */
/* IMPORTS ==================================== */

import { FlatButton } from "material-ui";
import moment from "moment";
import React from "react";
import EmailPreview from "../../../components/Dialogs/EmailPreview";
import Core from "../../Core";
import { EMP_MSG_TYPE__ALL_ID, EMP_MSG_TYPE__SUBMISSION_ID } from "../../Definition";
import Engagement from "../../Engagement";
import { newModel } from "../../GenericTools.lib";
import Google from "../../Google";
import Streak from "../../Streak";
import cleanHTML from "../../tools/cleanHtml";

/* CONSTANTS ================================== */

const TEN_BY_TEN_RECRUITER_EMAIL_OBJECT = {
  accountType: "Admin",
  name: "10x10",
  email: "recruiter@10by10.io"
};

/* METHODS ==================================== */

/**
 * Wrapper to send secure emails
 *
 * from localhost environment
 *
 * @param {object} params
 * @param {string} params.from
 * @param {string} params.to
 * @param {string} params.cc
 * @param {string} params.bcc
 * @param {string} params.subject
 * @param {string} params.html
 * @param {file[]} params.attachments
 * @param {string} params.source
 * @param {function} onSuccess
 * @param {function} onFailure
 * @returns
 */
function sendSafeEmail(
  params = {},
  onSuccess = () => { },
  onFailure = () => { },
) {
  let {
    to,
    cc,
    bcc,
    subject,
    html,
  } = params;
  const originalParams = newModel({
    to,
    cc,
    bcc,
    subject,
  });
  if (Core.isLocalHost()) {
    if (process.env.REACT_APP_DEV_EMAIL) {
      params.to = (
        process.env.REACT_APP_DEV_EMAIL
      );
      params.cc = undefined;
      params.bcc = undefined;
      params.subject = 'DEVELOPMENT TEST';
      params.html = cleanHTML(`
        <h1>DEVELOPMENT TEST</h1>
        <h2>Original Params</h2>
        <pre>
          ${JSON.stringify(originalParams, null, 2)}
        </pre>
        <hr/>
        ${html}
      `);
    }
    else {
      Core.showMessage(
        'skipping all emails because its local or development'
      );
      return;
    }
  }
  return Google.sendEmail(params, onSuccess, onFailure, true);
}

/**
 *
 * @param {Object} engagements - Other engagements to get the other candidates list for reminder email.
 * @param {boolean} isRecruiter - Are we preparing a recruiter reminder or an employer reminder
 * @return {string} HTML text - Other Candidates List.
 */
function processOtherCandidates({ engagements }, isRecruiter = true) {
  const content = [];
  let message = '';

  /** µ FOR DEBUG PURPOSES * /
  console.debug('µ:processOtherCandidates', { engagements });
  /** */

  engagements
    .sort((a, b) => String(a.stage).localeCompare(b.stage))
    .forEach(engagement => {

      const {
        candidate = {},
        status = '',
        stage = '',
        screen1,
        screen2,
        screen3,
        onsite1,
        onsite2,
        _jobTag: jobTag = '',
        _role: jobRole = '',
        _employerName: employerName = '',
      } = engagement;

      const {
        _name: candidateName = '',
      } = candidate;

      let jobTitle = jobTag.replace(`- ${jobRole}`, '');

      const today = moment();

      if (!stage.match(/guarantee/i)) {
        if (isRecruiter) {
          message = `<li> ${candidateName} | ${status} - ${employerName} - ${jobTitle}</li>`;
        } else {
          // Employer Reminder
          let statusPart = 'waiting for ';

          if (!!status.match(/W - \w+ Homework/i)) {
            statusPart += `candidate to complete homework.`;
          }
          else if (!!status.match(/W - 10x10|W - \w+ Availability/i)) {
            statusPart += `interview availability.`;
          }

          /** * /
          Stage == onsite
          // No OS1
          1. No OS1, No OS2  -> “onsite - when?”
          2. No OS1, OS2 < today (invalid) -> “onsite - when?”
          3. No OS1, OS2 >= today (invalid) -> “onsite on OS2”

          // OS1 < Today
          4. OS1 < today, no OS2 -> “onsite - when?”
          5. OS1 < today, OS2 < today -> “onsite - when?” // OS3
          6. OS1 < today, OS2 >= today -> “onsite on OS2”

          // OS1 >= today
          7. OS1 >= today, no OS2 -> “onsite on OS1”
          8. OS1 >= today, OS 2 < today (invalid)-> “onsite on OS1”
          9. OS1 >= today, OS 2 >= today -> “onsite on OS1, onsite on OS2”  → onsite on OS1, OS2
          ---------------------------------
          /** */
          else if (!!stage.match(/onsite/i)) {


            const isInvalidOnsite1 = (!onsite1 || moment(onsite1).isBefore(today));
            const isInvalidOnsite2 = (!onsite2 || moment(onsite2).isBefore(today));

            const isValidOnsite1 = (onsite1 && moment(onsite1).isSameOrAfter(today));
            const isValidOnsite2 = (onsite2 && moment(onsite2).isSameOrAfter(today));

            if (isInvalidOnsite1 && isInvalidOnsite2) {
              statusPart += `onsite information`;
            }

            else if (isValidOnsite1 && isInvalidOnsite2) {
              statusPart += `onsite on ${moment(onsite1).format('M/D')}`;
            }

            else if (isInvalidOnsite1 && isValidOnsite2) {
              statusPart += `onsite on ${moment(onsite2).format('M/D')}`;
            }

            else if (isValidOnsite1 && isValidOnsite2) {
              statusPart += [
                'onsite on',
                moment(onsite1).format('M/D') + ',',
                moment(onsite2).format('M/D') + '.',
              ].join(' ');
            }

          }
          else if (!!stage.match(/screen/i)) {

            const isInvalidScreen1 = (!screen1 || moment(screen1).isBefore(today));
            const isInvalidScreen2 = (!screen2 || moment(screen2).isBefore(today));
            const isInvalidScreen3 = (!screen3 || moment(screen3).isBefore(today));

            const isValidScreen1 = (screen1 && moment(screen1).isSameOrAfter(today));
            const isValidScreen2 = (screen2 && moment(screen2).isSameOrAfter(today));
            const isValidScreen3 = (screen3 && moment(screen3).isSameOrAfter(today));

            if (isInvalidScreen1 && isInvalidScreen2 && isInvalidScreen3) {
              statusPart += `screen information`;
            }

            else if (isValidScreen1 && isInvalidScreen2 && isInvalidScreen3) {
              statusPart += `screen on ${moment(screen1).format('M/D')}`;
            }

            else if (isInvalidScreen1 && isValidScreen2 && isInvalidScreen3) {
              statusPart += `screen on ${moment(screen2).format('M/D')}`;
            }

            else if (isInvalidScreen1 && isInvalidScreen2 && isValidScreen3) {
              statusPart += `screen on ${moment(screen3).format('M/D')}`;
            }

            else if (isValidScreen1 && isValidScreen2 && isInvalidScreen3) {
              statusPart += [
                'screen on',
                moment(screen1).format('M/D') + ',',
                moment(screen2).format('M/D') + '.',
              ].join(' ');
            }

            else if (isValidScreen1 && isValidScreen2 && isValidScreen3) {
              statusPart += [
                'screen on',
                moment(screen1).format('M/D') + ',',
                moment(screen2).format('M/D') + ',',
                moment(screen3).format('M/D') + '.'
              ].join(' ');
            }

          }
          else if (!!stage.match(/hire/i)) {
            statusPart += `start date`;
          }
          else if (!!status.match(/W - /i)) {
            statusPart = `in process`;
          }
          message = `<li> ${candidateName} - ${statusPart}</li>`;
        }
        content.push(message);
      }
      /** µ SAMPLE of content text * /
      Ryan Moore - wait for onsite  on 3/18. // W - Onsite
      Ryan Moore - wait for homework // W - Candidate Homework
      Ryan Moore - wait for scheduling // W - CandoAvailability
      Ryan Moore - wait for information // W - Cando. // W-Recruiter // W-10x10 ---  Catch all
      /** */

    });

  if (content.length !== 0) {
    content.unshift('<p><strong>Other candidates - No action required from you at this time</strong><ul>');
    content.push('</ul></p>');
  }

  return (
    content
      .filter(n => !!n)
      .join("\n")
      .trim()
  );

}
/**
 *
 * @param {Object} engagements - Other engagements to get the other candidates list for reminder email.
 * @return {string} HTML text - Other Candidates List.
 */
function processEmployerOtherCandidatesReminder(engagements) {
  return processOtherCandidates(engagements, false);
}

/**
 * Update on DB the reminderSentDates field of a engagement
 *
 * @sample
    ```
    updateReminderSentDates({
      eng: {...engagement},
      next: ()=>{...},
      reminder: {
        date: new Date().toISOString(), // date - time reminder was set
        source: 'recruiter', // source of email, e.g. job, employer, recruiter, agency, null (for candidate)
      }
    });
    ```
 */
function updateReminderSentDates({ engagement, next, reminder }) {
  /** * /
  reminder: {
    email, // email address reminder was sent to
    date, // date - time reminder was set
    source, // source of email, e.g. job, employer, recruiter, agency, null (for candidate)
    type // primary or secondary
  }
  /** */
  const reminderSentDates = { ...engagement.reminderSentDates };
  reminderSentDates[reminder.source] = reminder.date;
  console.debug('µ:updateReminderSentDates', { reminder, reminderSentDates });
  return Engagement.update(engagement, { reminderSentDates }, next, next);
}

function openMessage({
  employer = {},
  engagement = {},
  engagements: allEngagements = []
}) {
  Core.showMessage("Getting snippets, wait a moment...");
  const continueFlow = snippets => {
    Core.hideMessage();
    Core.log({ snippets });
    const engagements = allEngagements.filter(
      eng => eng.employer.id === employer.id
    );
    Core.log({ engagements });
    if (!!engagements.length) {
      const emails = [];
      //engagements.forEach(eng => {
      engagement.actionOwner.email &&
        emails.push({
          accountType: 'Action Owner',
          name: engagement.actionOwner._name,
          email: engagement.actionOwner.email
        });
      engagement.recruiter.email &&
        emails.push({
          accountType: 'Recruiter',
          name: engagement.recruiter._name,
          email: engagement.recruiter.email
        });
      engagement.employer.primaryContactEmail &&
        emails.push({
          accountType: 'Employer',
          name: engagement.employer.primaryContactName || "Primary Contact",
          email: engagement.employer.primaryContactEmail
        });
      engagement.job.emailsList
        .filter(({ to }) => (
          to.includes(EMP_MSG_TYPE__ALL_ID) ||
          to.includes(EMP_MSG_TYPE__SUBMISSION_ID)
        )).forEach(contact => emails.push({
          accountType: `${engagement.job.jobTitle}(job)`,
          ...contact
        }));
      engagement.candidate.email &&
        emails.push({
          accountType: 'Candidate',
          name: engagement.candidate._name || "Candidate",
          email: engagement.candidate.email
        });
      //});
      Core.dialog.open({
        title: <>Message</>,
        message: (
          <EmailPreview
            ref={self => Core.setKeyValue('MessageEmailPreview', self)}
            emails={emails}
            to={!!emails[0] && [emails[0]]}
            subject="Reminder"
            body={[].filter(line => !!line).join("<br/>")}
            snippets={snippets}
          />
        ),
        style: { width: "60vw" },
        className: "p-0",
        actions: [
          <FlatButton
            label="Cancel"
            className="button-flat-darker"
            onClick={ev => {
              Core.dialog.close();
            }}
          />,
          <FlatButton
            label="Send"
            className="button-white-cyan"
            onClick={ev => {
              Core.dialog.close();
              Google.sendEmail(
                {
                  ...Core.getKeyValue('MessageEmailPreview').getParams(),
                  source: 'Email.lib.js'
                },
                response => {
                  Core.showMessage("Email sent");
                  if (!response.threadId) {
                    Core.showFailure("It is missing the threadGmailId");
                  } else {
                    Streak.putEmailInBox(
                      {
                        boxKey: engagement.boxKey,
                        threadGmailId: response.threadId
                      },
                      response =>
                        Core.log("putEmailInBox", "success", response)
                    );
                  }
                },
                error => Core.showFailure(error)
              );
            }}
          />
        ]
      });
    } else {
      Core.showMessage("There are no Engagements for sending a reminder");
    }
  };
  Streak.getSnippets(
    snippets => continueFlow(snippets),
    error => {
      continueFlow([]);
      Core.showMessage("Snippets:" + error);
    }
  );
};

function mapEmailsListToString(emailsList = []) {
  return emailsList.map(
    ({
      name = '',
      email = ''
    }) => (
      [
        name.trim(),
        !!email.trim() && `<${email}>`
      ].filter(n => !!n).join(' ').trim()
    )
  ).filter(n => !!n).join(', ');
}

/* DICTIONARY ================================= */

const EmailLib = {
  TEN_BY_TEN_RECRUITER_EMAIL_OBJECT,
  sendSafeEmail,
  processOtherCandidates,
  updateReminderSentDates,
  openMessage,
  mapEmailsListToString,
}

/* EXPORTS ==================================== */

export {
  EmailLib as default,
  EmailLib,
  TEN_BY_TEN_RECRUITER_EMAIL_OBJECT,
  sendSafeEmail,
  openMessage as openMessageEmailPreview,
  processOtherCandidates as processRecruiterOtherCandidatesReminder,
  processEmployerOtherCandidatesReminder,
  updateReminderSentDates,
  mapEmailsListToString,
};

/* ============================================ */
