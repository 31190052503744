import getJobSourceListApis from "../../../lib/tools/getJobSourceListApis";
import Core from "../../../lib/Core";
import Google from "../../../lib/Google";

const baseUrl = Core.getBasePath();

const process = (serverIds, allIds) => {
    const isRemovedFromArray = serverIds.length > allIds.length;
    const isAddedInArray = serverIds.length < allIds.length;

    if(isRemovedFromArray){
        deleteJobManager(serverIds, allIds);
    }else if (isAddedInArray){
        addJobManager(serverIds, allIds);
    }
}

const getJobsToAdd = (serverIds, allJobs) => {
    return allJobs.map(job=> {
        return !serverIds.includes(job) ? job : "";
    }).filter(el=> !!el);
}

const getJobsToDelete = (serverIds, allIds) => {
    return serverIds.filter(x => !allIds.includes(x));
}

const addJobManager = (serverIds, allIds) => {
    const toAdd = getJobsToAdd(serverIds, allIds);
    addJobs(toAdd);
}

const deleteJobManager = (serverIds, allIds) => {
    const toDelete = getJobsToDelete(serverIds, allIds);
    deleteJobs(toDelete);
}

const addJobs = (setJobsToAdd) => {
    const otherFields = getOtherFields();
    setJobsToAdd.forEach(job=>{
      getJobSourceListApis.addJobToRecruiter(job, Core.getUserId(), otherFields, (response) => {
        console.log(response);
      });
    });
}

const deleteJobs = (toDelete) => {
    const otherFields = getOtherFields();
    toDelete.forEach(job=>{
        getJobSourceListApis.deleteRecruiterJobByRecruiter(job, Core.getUserId(), otherFields, (response) => {
          console.log(response);
        });
    });
}

const getOtherFields = () => {
    return {
        who: Core.getSessionEmail()
    };
}

const getSubject = () => {
    return `START/STOP SOURCING : ${Object(Core.getUser().firstName)} ${Object(Core.getUser().lastName)} @ ${Object(Core.getUser().companyName)}`;
}

const getBody = (dIds, aIds, jobObj, recruiterJobs) => {

    let formatDate = new Date(), deletedFormatDate="", html = "";

    formatDate = `${formatDate.getMonth() + 1}/${formatDate.getDate()}/${formatDate.getFullYear()}`;
    
    html += `<li>Recruiter Name : ${Object(Core.getUser().firstName)} ${Object(Core.getUser().lastName)} Email : ${Object(Core.getUser().email)}</li>`; 
    jobObj.forEach(job=> {
        if(dIds.includes(job.id)){
            deletedFormatDate = new Date(Object(recruiterJobs.find(rjob=> rjob.jobId ===job.id).startDate));
            deletedFormatDate = `${deletedFormatDate.getMonth() + 1}/${deletedFormatDate.getDate()}/${deletedFormatDate.getFullYear()}`;
            html += `<li>Stop <a href='${baseUrl}/#/job/edit/${job.id}'>${job.label}</a> : ${deletedFormatDate} - ${formatDate}</li>`;
        } else if(aIds.includes(job.id)) {
            html += `<li>Start <a href='${baseUrl}/#/job/edit/${job.id}'>${job.label}</a> : ${formatDate}</li>`;
        }
    });

    return html;
}

const getEmailContent = (dIds, aIds, jobObj, recruiterJobs) => {
    
    const subject = getSubject();
    const html = getBody(dIds, aIds, jobObj, recruiterJobs);

    return {
        body: html,
        subject: subject
    };
}

const makeCoverHTML = (body) => {

    const html = `<HTML>
                    <head></head>
                    <body>
                      <table>
                        <tr>
                          <td>
                            ${body}
                          </td>
                        </tr>
                      </table>
                    </body>
                  </html>`;
    return html;
}

const sendEmail = (to, from, cc = null, subject, html) => {
    var params = { from, to, cc, subject, html };
    Core.log("Email notification for recruiter added/deleted its jobs", params);
    Google.sendEmail({...params,source:'JobSourceList.js line 117'}, null, error => Core.showMessage(error));
}

const sendEmailManager = (prevIds, newIds, jobObj, recruiterJobs) => {
    const deletedIds = getJobsToDelete(prevIds, newIds);
    const addedIds = getJobsToAdd(prevIds, newIds);

    let {subject, body} = getEmailContent(deletedIds, addedIds, jobObj, recruiterJobs);
    body = makeCoverHTML(body);

    const from = Core.getResumeSubmissionFromEmail();
    const to = Core.getResumeSubmissionFromEmail();

    sendEmail(to, from, null, subject, body);
}

const restoreAddedJobsWithDates = (oldJobsObjects, newJobIds) => {
    
    const restoredJobs = oldJobsObjects.filter(job => newJobIds.includes(job.jobId));
    const restoredJobIds = restoredJobs.map(rjobs=> rjobs.jobId);
    const newJobObjects = newJobIds.filter(njob=> !restoredJobIds.includes(njob)).map(njob=> ({jobId: njob, startDate: new Date()}));

    return [
        ...restoredJobs,
        ...newJobObjects
    ];
}

const toExport = {
    process,
    sendEmailManager,
    restoreAddedJobsWithDates
};

export default toExport;