import React, {Component, Fragment} from "react";

import Grid from '@mui/material/Grid';
import {TextField} from "material-ui";
import Chips from "../../../Forms/Chips";
import styles from '../Styles.js';
import Definition from "../../../../lib/Definition";
import JobTags from "../Components/JobTags";

const categoriesWithKeys = ['technicalSkills', 'positiveSignals', 'negativeSignals'];

const DEFAULT_CHIP_VALUE = "";

class Chip extends Component {

    constructor(props) {
        super(props);

        let label='';
        if (!!this.props.updateAble) {
            label = Definition.getLabel(this.props.updateAble.key, this.props.updateAble.value)
            if (this.props.updateAble.type === 'chipGroup') {
                label = Definition.getGroupObj(this.props.updateAble.key, this.props.updateAble.value).name;
            }
            if (this.props.updateAble.type === 'chipTag') {
                label = this.props.updateAble.value;
            }
        }

        this.state = {
            specialInstruction: !!this.props.updateAble ? this.props.updateAble.specialInstruction : "",
            chipValue: !!this.props.updateAble ? {
                key: this.props.updateAble.key,
                id: this.props.updateAble.value,
                selected: true,
                type: this.props.updateAble.type,
                label
            } : {},
            category: !!this.props.updateAble ? this.props.updateAble.category : "technicalSkills",
        }
        this._constJobTags = this.props.jobTags.map(tag=>({id:tag.id,key:tag.key,label:tag.value})) || [];
    }

    finalStruct = () => {
        let {chipValue, specialInstruction} = this.state;

        let type=!!chipValue.type ? chipValue.type:'chip';
        type = !!chipValue.key && !categoriesWithKeys.includes(chipValue.key) ? 'chipTag' : type;

        let toReturn = {
            type,
            key: chipValue.key,
            // value: chipValue.id,
            specialInstruction,
            // category: chipValue.key,
        }
        if(type !== 'chipTag'){
            toReturn['category'] = chipValue.key;
            toReturn['value'] = chipValue.id;
        } else {
            toReturn['value'] = chipValue.label;
        }

        return toReturn;
    }

    updateAttr = (key, value) => {
        let {onUpdate} = this.props;
        let updateObj = {[key]: value};

        if (key === 'category') {
            updateObj['chipValue'] = [];
        }

        this.setState(updateObj, () => {
            !!onUpdate && onUpdate(this.finalStruct())
        })
    }

    getItems = (category) => {
        return Definition.get(category)
            .map(c => {
                c.key = (category === 'jobLevelGroup') ? c.key : category;
                return c;
            })
    }

    getAdditionalGroups = (key) => {
        let itemsGroup = [];

        try {
            itemsGroup = Definition.getRawDef(key).groups.map(g => ({
                label: g.name,
                id: g.id,
                key: key,
                type: 'chipGroup'
            }))
        } catch (e) {

        }
        return itemsGroup;
    }

    render() {
        let {specialInstruction, chipValue} = this.state;
        const {dialogClass} = this.props;
        let chipValueArray = !Array.isArray(chipValue) ? [chipValue] : chipValue;

        let grids = [
            {
                name: "technicalSkills",
                label: "Technical Skills",
                items: this.getItems('technicalSkills'),
                additionalItems: this.getAdditionalGroups('technicalSkills'),
                values: chipValue.key === 'technicalSkills' ? chipValueArray : [],
                commonCase: true,
                onChange: (mustHaveSkillId, mustHaveSkillObj) => {
                    let singleElement = mustHaveSkillId.length ? mustHaveSkillObj.pop() : DEFAULT_CHIP_VALUE;
                    this.updateAttr('chipValue', singleElement);
                },
                withOutDialog: true
            },
            {
                name: "positiveSignals",
                label: "Positive Signals",
                items: this.getItems('positiveSignals'),
                additionalItems: this.getAdditionalGroups('positiveSignals'),
                values: chipValue.key === 'positiveSignals' ? chipValueArray : [],
                commonCase: true,
                onChange: (mustHaveSkillId, mustHaveSkillObj) => {
                    let singleElement = mustHaveSkillId.length ? mustHaveSkillObj.pop() : DEFAULT_CHIP_VALUE;
                    this.updateAttr('chipValue', singleElement);
                },
                withOutDialog: true
            },
            {
                name: "negativeSignals",
                label: "Negative Signals",
                items: this.getItems('negativeSignals'),
                additionalItems: this.getAdditionalGroups('negativeSignals'),
                values: chipValue.key === 'negativeSignals' ? chipValueArray : [],
                commonCase: true,
                onChange: (mustHaveSkillId, mustHaveSkillObj) => {
                    let singleElement = mustHaveSkillId.length ? mustHaveSkillObj.pop() : DEFAULT_CHIP_VALUE;
                    this.updateAttr('chipValue', singleElement);
                },
                withOutDialog: true
            },

        ]

        return <Fragment>
            {/*<Grid item xs={12} sm={12} className="job-custom-label">*/}
            {/*<CategoryDropdown onSave={this.updateAttr} category={category}/>*/}
            {/*</Grid>*/}
            <Grid item xs={12} sm={12}>
                <TextField
                    value={specialInstruction}
                    textareaStyle={styles.TextField.inputStyle}
                    underlineStyle={styles.TextField.underlineStyle}
                    style={styles.TextField}
                    rows={2}
                    rowsMax={2}
                    multiLine={true}
                    placeholder={'write special instruction here'}
                    fullWidth
                    onChange={(event, text) => {
                        this.updateAttr('specialInstruction', text);
                    }}
                />
            </Grid>
            <Grid container>
                <Grid item xs={12} className="job-custom-label">
                    <JobTags
                        data={{
                            items: this._constJobTags,
                            additionalItems: this.getItems('jobLevelGroup'),
                            values: !!Object.keys(chipValue).length && !categoriesWithKeys.includes(chipValue.key) ? chipValueArray : [],
                            onChange: (jobTagsId, jobTags) => {
                                let singleElement = !!jobTagsId.length ? jobTags.pop() : DEFAULT_CHIP_VALUE;
                                this.updateAttr('chipValue', singleElement);
                            },
                            dialogClass: dialogClass
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container>
                {grids.map(grid => {
                    return <Grid key={`grid-${grid.name}-chip`} item xs={12 / grids.length}
                                 className="job-custom-label">
                        <Chips
                            key={`grid-chips-${grid.name}-chip`}
                            name={grid.name}
                            label={grid.label}
                            items={grid.items}
                            additionalItems={grid.additionalItems}
                            values={grid.values}
                            commonCase={true}
                            onChange={grid.onChange}
                            withOutDialog={true}
                        />
                    </Grid>
                })
                }
            </Grid>

        </Fragment>
        // return <Fragment>
        //     <Grid item xs={12} sm={12} className="job-custom-label">
        //         <CategoryDropdown onSave={this.updateAttr} category={category}/>
        //     </Grid>
        //
        //     <Grid item xs={12} sm={12} className="mb-10">
        //         <Chips
        //             name="technicalSkills"
        //             items={items}
        //             label=""
        //             commonCase={true}
        //             values={chipValueArray}
        //             onChange={(mustHaveSkillId, mustHaveSkillObj) => {
        //                 let singleElement = mustHaveSkillId.length ? mustHaveSkillObj.pop() : DEFAULT_CHIP_VALUE;
        //                 this.updateAttr('chipValue', singleElement);
        //             }}
        //             hidePopupContent={() => {
        //                 document.getElementsByClassName(dialogClass)[0].classList.add("removeClass");
        //             }}
        //             displayPopupContent={() => {
        //                 document.getElementsByClassName(dialogClass)[0].classList.remove("removeClass");
        //             }}
        //         />
        //     </Grid>
        //
        //     <Grid item xs={12} sm={12}>
        //         <TextField
        //             value={specialInstruction}
        //             textareaStyle={styles.TextField.inputStyle}
        //             underlineStyle={styles.TextField.underlineStyle}
        //             style={styles.TextField}
        //             rows={2}
        //             rowsMax={2}
        //             multiLine={true}
        //             placeholder={'write special instruction here'}
        //             fullWidth
        //             onChange={(event, text) => {
        //                 this.updateAttr('specialInstruction', text);
        //             }}
        //         />
        //     </Grid>
        // </Fragment>
        //     ;
    }
}

export default Chip;
