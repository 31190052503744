import React, { Component, Fragment } from "react";
import _ from "lodash";
import {
  Dialog,
  Divider,
  FlatButton,
  IconButton,
  TextField
} from "material-ui";

import Chip from '@mui/material/Chip';
import FuzzySearch from "fuzzy-search";
import Core, { colors } from "../../lib/Core";
import Definition from "../../lib/Definition";
import { Clear } from '@mui/icons-material';

class Chips extends Component {
  constructor() {
    super(...arguments);
    let items = (this.props.items && this.props.items.length) ? this.props.items : Definition.get(this.props.name);
    items = _.sortBy(items, o => o.label)
    //items.sort((a,b) => (a.label.toLowerCase()  - b.label.toLowerCase()));
    this.state = {
      selected: [],
      items,
      search: "",
      open: false
    };
  }
  open = ev => {
    this.setState(state => {
      state.open = true;
      state.selected = [];
      let propsValues = this.props.values.map(o=>String(o));

      state.items.forEach(item => {
        if (!!~propsValues.indexOf(String(item.id))) {
          state.selected.push({ ...item });
          item.selected = true;
        } else {
          delete item.selected;
        }
      });

      return state;
    });
  };

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (!_.isEmpty(nextProps.items) && nextProps.items !== this.state.items) {
      this.setState({ items: nextProps.items });
    }
  }

  apply = ev => {
    this.setState(state => {
      state.open = false;
      this.props.onChange &&
        this.props.onChange(state.selected.map(item => item.id));
      return state;
    });
  };

  findValue = id => {
      let item = {};
      let items = this.props.items;
      if(items){
        item = items.filter(item=>(item.id === id));
        item = item[0]||{};
      }

      return item ? item.label : 'notFound';
  };

  render() {
    const {decisionArray} = this.props;
    const chipOption = (model, selected) => {
      return (
        <Chip
          key={Core.getKey()}
          className="chip slim-chip"
          variant="outlined"
          size="small"
          label={model.label}
          style={{
              color: selected ? "#536DFF" : "#454545",
              margin: 4,
              display: "inlineBlock",
              border: selected
              ? "1px solid #536DFF"
              : !selected && model.selected
                ? "1px dotted #7A7A7A"
                : "1px solid #7A7A7A"
          }}
          onClick={ev => {
            if (selected) {
              this.setState(state => {
                state.selected = state.selected.filter(
                  item => item.id !== model.id
                );
                delete state.items.find(item => item.id === model.id).selected;
                return state;
              });
            } else {
              if (!this.state.selected.find(item => item.id === model.id)) {
                this.setState(state => {
                  state.selected.push(model);
                  state.items.find(
                    item => item.id === model.id
                  ).selected = true;
                  return state;
                });
              }
            }
          }}
        />
      );
    };
    return (
      <Fragment>
        {!!this.props.label&&<label>{this.props.label}</label>}
        <span className="cgray f-small">{this.props.sub}</span>
        <div >
          <div >
            {this.props.values.map((id, index) => (
              <Chip
                key={`${this.props.name}-tag-${id}`}
                className="chip slim-chip"
                variant="outlined"
                size="small"
                label={this.props.commonCase ? this.findValue(id) : Definition.getLabel(this.props.name, id)}
                style={{
                    color: "#454545",
                    display: "inlineBlock",
                    border: !!decisionArray && !!decisionArray[index] ? "1px solid green" : (!!decisionArray && decisionArray[index] === null) || !decisionArray ? "1px solid rgba(0, 0, 0, 0.23)" : "2px solid red"
                }}
                deleteIcon={<Clear
                  style={{
                    fontSize: "15px"
                  }}
                />}
                onDelete={ev => {
                  this.props.onChange &&
                    this.props.onChange(
                      this.props.values.filter(_id => _id !== id)
                    );
                }}
              />
            ))}
            <Chip
              className="chip slim-chip"
              variant="outlined"
              size="small"
              label="+ Add"
              style={{
                  color: colors.purple,
                  display: "inlineBlock",
                  border: "1px solid " + colors.purple
              }}
              onClick={this.open}
            />
          </div>
          <Dialog
            title={this.props.label}
            modal={false}
            open={this.state.open}
            onRequestClose={() => this.setState({ open: false })}
            autoScrollBodyContent={true}
            contentStyle={!this.props.openWidth ? { width: 417 } : {}}
            bodyStyle={{ padding: "0px 20px 20px" }}
            actions={
              <Fragment>
                <FlatButton
                  label="Cancel"
                  primary={true}
                  onClick={ev => this.setState({ open: false })}
                />
                <FlatButton
                  label="Apply"
                  primary={true}
                  keyboardFocused={false}
                  onClick={this.apply}
                />
              </Fragment>
            }
          >
            {this.props.topRow}
            <div style={{ position: "relative", marginTop: 0 }}>
              <i
                className="material-icons"
                style={{
                  position: "absolute",
                  top: 16,
                  left: 0,
                  width: 24,
                  padding: 0
                }}
              >
                search
              </i>
              <TextField
                name="searcher"
                placeholder="Separate search terms by comma or space"
                required={true}
                style={{
                  width: "calc(100% - 32px)",
                  fontSize: 14,
                  margin: "0 8px 0 32px"
                }}
                underlineFocusStyle={{ borderColor: colors.purple }}
                type="text"
                value={this.state.search}
                onChange={(event, search) => this.setState({ search })}
                autoFocus
              />
              {!!this.state.search.length && (
                <IconButton
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 8,
                    width: 24,
                    padding: 0
                  }}
                  onClick={ev => this.setState({ search: "" })}
                >
                  <i className="material-icons">clear</i>
                </IconButton>
              )}
            </div>
            {!!this.state.selected.length && (
              <Fragment>
                <div className="padding-top-bottom">
                  {this.state.selected.map((item, i) => chipOption(item, true))}
                </div>
                <Divider />
              </Fragment>
            )}
            <div className="padding-top-bottom">
              {(!!this.state.search.length
                ? (em => {
                    const result = [];
                    const words = this.state.search
                      .trim()
                      .split(/,\s*|\s|\||;/)
                      .filter(term => !!term.trim());
                    words.forEach(word => {
                      new FuzzySearch(this.state.items, ["label"], {
                        caseSensitive: false
                      })
                        .search(word)
                        .forEach(
                          item =>
                            !result.find(
                              resItem => item.label === resItem.label
                            ) && result.push(item)
                        );
                    });
                    return result;
                  })()
                : this.state.items
              ).map((item, i) => chipOption(item))}
            </div>
          </Dialog>
        </div>
      </Fragment>
    );
  }
}

export default Chips;
