import React, {Component, Fragment} from "react";

import Chips from "../../../Forms/Chips";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import {MenuItem} from "material-ui";
import Link from '@mui/material/Link';
import Definition from "../../../../lib/Definition";
import TextField from '@mui/material/TextField';

class TechnicalSkills extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            ...this.props.element,
            dialogOpen: this.props.isNewRecord,
            freeTextMode: false
        };
        this.state.mainSkills = this.props.element.mainSkills || [];
        this.state.acceptenceAlternatives = this.props.element.acceptenceAlternatives || [];
        this.state.cannotHave = this.props.element.cannotHave || [];
        this.state.outOfSkills = this.props.element.outOfSkills || [];
        this.state.mainSkillInText = this.state.mainSkillInText || '';
        this.state.specificsMainSkill = this.state.mainSkillInText || '';
        this.state.outOfSkillsCount = this.state.outOfSkillsCount || 1;
        this.definitionTech = Definition.get("technicalSkills");
    }

    handleClickOpen = () => {
        this.setState({dialogOpen: true});
    }

    handleClose = () => {
        this.setState({dialogOpen: false}, () => {
            this.props.onDone(this.getUpdatedStructure());
        });

    }

    getUpdatedStructure = () => {
        let {
            id, mainSkills, outOfSkills, cannotHave, mainSkillInText, specificsMainSkill,outOfSkillsCount
        } = this.state;

        return {
            id,
            mainSkills,
            outOfSkills,
            cannotHave,
            mainSkillInText,
            specificsMainSkill,
            outOfSkillsCount
        };
    }


    render() {
        let cannotHaveSkills = this.state.cannotHave.map(val => val.id);
        let outOfSkills = this.state.outOfSkills.map(val => val.id);

        let mainSkill = this.state.mainSkills.map(val => val.id);
        let {handlerDeleteItem} = this.props;
        let {mainSkillInText, specificsMainSkill, freeTextMode, outOfSkillsCount} = this.state;

        let mainSkillText = !!mainSkill.length ? Definition.getLabel('technicalSkills', mainSkill) : mainSkillInText;

        return (
            <div>
                <p>
                    {!!mainSkillText && <span>Ideally <span style={{color: 'yellow'}}>{mainSkillText}</span></span>}
                    {!!outOfSkills.length &&
                    <span>Atleast <span style={{color: 'yellow'}}>{outOfSkillsCount}</span> out of <span
                        style={{color: 'yellow'}}>{Definition.getLabels('technicalSkills', outOfSkills).join(',')}</span></span>}
                    {!!cannotHaveSkills.length &&
                    <span>No - <span
                        style={{color: 'yellow'}}>{Definition.getLabels('technicalSkills', cannotHaveSkills).join(',')}</span></span>}
                </p>

                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        this.handleClickOpen();
                    }}
                >
                    Edit&nbsp;|&nbsp;
                </Link>
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        handlerDeleteItem(this.getUpdatedStructure());
                    }}
                >
                    Delete
                </Link>
                {this.state.dialogOpen &&
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth="lg"
                    maxWidth="lg"
                >
                    <DialogTitle id="form-dialog-title">Technical options</DialogTitle>
                    <DialogContent>
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                                this.setState({freeTextMode: !freeTextMode})
                            }}
                        >
                            {freeTextMode ? 'Use Chips to add Main Skill' : 'Use free text to add main skill'}
                        </Link>

                        {!freeTextMode && <Fragment><br/><Chips
                            name="technicalSkills"
                            label="Main Skill"
                            values={mainSkill}
                            onChange={mustHaveSkill => {
                                const mainSkills = mustHaveSkill.map(val => {
                                    return {id: val}
                                });

                                this.setState({mainSkills});
                            }}
                        /></Fragment>}


                        {freeTextMode && <TextField
                            id="standard-full-width"
                            label=""
                            style={{margin: 8}}
                            value={mainSkillInText}
                            placeholder=""
                            helperText="Write free text for main skill"
                            fullWidth
                            margin="normal"
                            onChange={(event) => {
                                this.setState({mainSkillInText: event.target.value})
                            }
                            }

                            InputLabelProps={{
                                shrink: true,
                            }}
                        />}


                        <TextField
                            id="standard-full-width"
                            variant="standard"
                            label=""
                            style={{margin: 8}}
                            value={specificsMainSkill}
                            placeholder=""
                            helperText="Specific Details"
                            fullWidth
                            margin="normal"
                            onChange={(event) => {
                                this.setState({specificsMainSkill: event.target.value})
                            }
                            }

                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <p>
                            OR Atleast &nbsp;&nbsp;
                            <TextField
                                id="standard-full-width"
                                variant="standard"
                                label=""
                                style={{margin: 8}}
                                value={outOfSkillsCount}
                                placeholder=""
                                helperText=""
                                margin="normal"
                                onChange={(event) => {
                                    this.setState({outOfSkillsCount: event.target.value})
                                }
                                }

                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            &nbsp;&nbsp;
                            0f these alternative skills
                            <Select
                                multiple
                                value={outOfSkills}
                                onChange={event => {
                                    let outOfSkills = [];
                                    outOfSkills = event.target.value.map(val => {
                                        return {id: val};
                                    });

                                    this.setState({outOfSkills: outOfSkills});
                                }}
                                underlineFocusStyle={{borderColor: "#59736f"}}
                                input={<Input id="select-multiple-checkbox"/>}
                                renderValue={selected => Definition.getLabels('technicalSkills', selected).join(', ')}
                                fullWidth
                                style={{marginTop: '10px'}}
                            >
                                {this.definitionTech.map(tag => (
                                    <MenuItem key={tag.id} value={tag.id}>
                                        <Checkbox checked={outOfSkills.indexOf(tag.id) > -1}
                                                  style={{float: 'left'}}/>
                                        <ListItemText primary={tag.label} style={{paddingTop: '10px'}}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </p>
                        <DialogContentText>
                            Can Not Have Skills
                        </DialogContentText>
                        <Select
                            multiple
                            value={cannotHaveSkills}
                            onChange={event => {
                                let cannotHave = [];
                                cannotHave = event.target.value.map(val => {
                                    return {id: val};
                                });
                                this.setState({cannotHave: cannotHave});
                            }}
                            underlineFocusStyle={{borderColor: "#59736f"}}
                            input={<Input id="select-multiple-checkbox"/>}
                            renderValue={selected => Definition.getLabels('technicalSkills', selected).join(', ')}
                            fullWidth
                            style={{marginTop: '10px'}}
                        >
                            {this.definitionTech.map(tag => (
                                <MenuItem key={tag.id} value={tag.id}>
                                    <Checkbox checked={cannotHaveSkills.indexOf(tag.id) > -1} style={{float: 'left'}}/>
                                    <ListItemText primary={tag.label} style={{paddingTop: '10px'}}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>
                }
            </div>
        );
    }
}

export default TechnicalSkills;

