import React, { Component, Fragment } from "react";
// import { NavLink } from "react-router-dom";

import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
import Divider from "material-ui/Divider";
import FloatingActionButton from "material-ui/FloatingActionButton";
import Paper from "material-ui/Paper";
// import EditorModeEdit from "material-ui/svg-icons/editor/mode-edit";

import Core, { colors } from "../../../lib/Core";
import DetailItem from "../../Items/DetailItem";
import formatURL from "../../../lib/tools/formatURL";
import { FlatButton, SelectField, MenuItem, TextField } from "material-ui";
import Row from "../../Forms/Row";
import Col from "../../Forms/Col";
import RichTextBox from "../../Forms/RichTextBox";
import Chips from "../../Forms/Chips";
import Definition from "../../../lib/Definition";
import Candidate from "../../../lib/Candidate";
import styles from "../../Forms/Styles";
import CandidateJobChoices from "../Forms/CandidateJobChoices";
import withImportantStyle from 'react-with-important-style';
const MyA = withImportantStyle('a');

const lineStyle = { borderColor: "#59736f" };

class CandidateProfile extends Component {
  openEditCandidate = ev => {
    Core.dialog.open({
      title: <>Edit Candidate</>,
      style: {
        width: "70vw",
        minWidth: "320px",
        maxWidth: "none"
      },
      message: (
        <iframe
          className="covered"
          title="Edit Candidate"
          style={{ height: "70vh", minHeight: "375px" }}
          src={Core.getLink(`/candidate/edit/${this.props.parent.state.id}`)}
        />
      ),
      actions: [
        <FlatButton
          label="Reload"
          title="If you change something, reload the page"
          className="button-flat-darker"
          onClick={ev => document.location.reload()}
        />,
        <FlatButton
          label="Close"
          title="If you only reviewed the info"
          className="button-white-cyan"
          onClick={ev => Core.dialog.close()}
        />
      ]
    });
  };
  render() {
    const parent = this.props.parent;
    const save = ev => {
      const candidate = { ...parent.state };
      candidate.searchConfig = "{}";
      Candidate.update(
        candidate.id,
        candidate,
        response => {
          Core.showMessage(
            <div className="inline-blocks">
              <span>Candidate was updated &nbsp;&nbsp;&nbsp;</span>
              <FlatButton
                title="Reload entire page"
                label="Reload"
                className="button-white-bordered"
                style={{ display: "inline-block" }}
                onClick={ev => document.location.reload()}
              />
            </div>
          );
        },
        error => Core.showFailure(error)
      );
    };
    return (
      <Paper className="match-profile" zDepth={2}>
        <Card
          className="profile-header"
          style={{ backgroundColor: colors.purple }}
        >
          <CardHeader
            avatar={<i className="material-icons">person</i>}
            title={
              <Fragment>
                {parent.state._name_rating}
              </Fragment>
            }
            titleStyle={{ fontSize: 22 }}
            subtitle={
              <div className="blocks">
                <span>
                  {`${parent.state.recruiter.firstName} ${
                    parent.state.recruiter.lastName
                  } - ${parent.state.recruiter.companyName}`}
                </span>
                <br />
                <span title="Email">
                  {parent.state.email || <i>&mdash;</i>}
                </span>
                <span title="Phone">
                  {String(parent.state.phone).replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    "$1-$2-$3"
                  ) || <i>&mdash;</i>}
                </span>
                <span title="Address">{parent.state.address}</span>
                <br />
                {parent.state.linkedInURL && (
                  <MyA
                    title="LinkedIn"
                    href={formatURL(parent.state.linkedInURL)}
                    style={{color:'white !important'}}
                    target="_blank"
                  >
                    {parent.state.linkedInURL}
                  </MyA>
                )}
                {parent.state.gitHubURL && (
                  <MyA title="GitHub"
                       href={formatURL(parent.state.gitHubURL)}
                       style={{color:'white !important'}}
                       target="_blank"
                  >
                    {parent.state.gitHubURL}
                  </MyA>
                )}
                {!!parent.state.otherLinks.length &&
                  parent.state.otherLinks.split(/\n/).map(item => (
                    <a
                      key={Core.getKey()}
                      href={item}
                      style={{ display: "block" }}
                    >
                      {item}
                    </a>
                  ))}
              </div>
            }
          />
          <CardText>{parent.state.tagLine}</CardText>
          {Core.isAdminOrRecruiterOnDev() && (
            <CardActions>
              <FloatingActionButton
                title="PopUp to Edit Candidate"
                style={{
                  position: "absolute",
                  bottom: -20,
                  right: 100
                }}
                onClick={this.openEditCandidate}
              >
                <i className="material-icons">open_in_browser</i>
              </FloatingActionButton>
              <FloatingActionButton
                title="Save Candidate Profile"
                style={{
                  position: "absolute",
                  bottom: -20,
                  right: 20
                }}
                onClick={save}
              >
                <i className="material-icons">save</i>
              </FloatingActionButton>
            </CardActions>
          )}
        </Card>
        <div className="match-profile-details">
          <DetailItem label="Candidate Visa" value={parent.state._visa} />
          <DetailItem
            label="Years of Relevant Work Experience"
            value={parent.state.yearsOfExperience}
          />
          <DetailItem
            label="Minimum Company Size Preference"
            value={parent.state.minCompanySize}
          />
          <DetailItem
            label="Maximum Company Size Preference"
            value={parent.state.maxCompanySize}
          />
          <DetailItem
            label="Minimum Salary"
            value={parent.state.minimumSalary}
          />
          <DetailItem label="Ideal Salary" value={parent.state.desiredSalary} />
          <DetailItem
            label="Desirable work location"
            value={parent.state._officeLocations}
          />
          <DetailItem label="Work Remotly" value={parent.state._workRemotly} />
          {!!parent.state.experienceNotes.length && (
            <DetailItem
              label="Noteworthy Work Experience"
              value={
                <div
                  className="match-profile-html-item"
                  dangerouslySetInnerHTML={{
                    __html: parent.state.experienceNotes
                  }}
                />
              }
            />
          )}
          {!!parent.state.degreeNotes.length && (
            <DetailItem
              label="Noteworthy Schools, degree, major"
              value={
                <div
                  className="match-profile-html-item"
                  dangerouslySetInnerHTML={{
                    __html: parent.state.degreeNotes
                  }}
                />
              }
            />
          )}
          {!!parent.state.motivationNotes.length && (
            <DetailItem
              label="Motivation Priority"
              value={
                <div
                  className="match-profile-html-item"
                  dangerouslySetInnerHTML={{
                    __html: parent.state.motivationNotes
                  }}
                />
              }
            />
          )}
          <DetailItem label="Education" value={parent.state.education} />
          <DetailItem label="Last Position" value={parent.state.lastPosition} />
          <Divider />
          <DetailItem label="Industry" value={parent.state._industry} />
          <DetailItem
            label="Technology Domain"
            value={parent.state._technologyDomain}
          />
          {Core.isAdminOrCoordinator() && (
            <Fragment>
              <DetailItem
                label="Positive Signals"
                value={parent.state._positiveSignals}
              />
              <DetailItem
                label="Negative Signals"
                value={parent.state._negativeSignals}
              />
            </Fragment>
          )}
          <Divider />
          <Row>
            <Col>
              <label>Level</label>
              <SelectField
                name="level"
                underlineFocusStyle={lineStyle}
                value={parent.state.level}
                onChange={(ev, i, level) => parent.setState({ level })}
                fullWidth
              >
                {Definition.get("level").map(state => {
                  return (
                    <MenuItem
                      key={state.id}
                      value={state.id}
                      primaryText={state.label}
                    />
                  );
                })}
              </SelectField>
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <Chips
                name="roles"
                label="Roles"
                values={parent.state.roles}
                onChange={roles => parent.setState({ roles })}
              />
              <Chips
                name="technicalSkills"
                label="Technical Skills"
                values={parent.state.technicalSkills}
                onChange={technicalSkills =>
                  parent.setState({ technicalSkills })
                }
              />
            </Col>
          </Row>
          {parent.state.id && <CandidateJobChoices parent={parent.state} onChange={item=>result=>{
              let toBeSaved = {};
              toBeSaved[item.key] = result;
              parent.setState(toBeSaved);
          }}/>}
          <Row>
            <Col fullWidth>
              <label>Recruiter's Candidate Write up</label>
              <RichTextBox
                name="submissionNotes"
                ref={self => (parent.SubmissionNotes = self)}
                value={parent.state.submissionNotes}
                onChange={submissionNotes => {
                  parent.setState({ submissionNotes });
                }}
              />
            </Col>
          </Row>
          {!!Core.isAdminOrCoordinator() && (
            <div className="green-block">
              <Row>
                <Col fullWidth>
                  <label>Submission Write Up to Employer</label>
                  <RichTextBox
                    name="submissionNotesToEmployer"
                    value={parent.state.submissionNotesToEmployer}
                    onChange={submissionNotesToEmployer => {
                      parent.setState({
                        submissionNotesToEmployer
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col fullWidth>
                  <label>Private Notes</label>
                  <TextField
                    name="privateNotes"
                    textareaStyle={styles.TextField.inputStyle}
                    underlineStyle={styles.TextField.underlineStyle}
                    style={styles.TextField}
                    rows={6}
                    rowsMax={12}
                    multiLine={true}
                    value={parent.state.privateNotes}
                    onChange={(ev, privateNotes) =>
                      parent.setState({ privateNotes })
                    }
                    fullWidth
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
      </Paper>
    );
  }
}

export default CandidateProfile;
