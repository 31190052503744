import { Accordion, Icon } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import {
  TextField
} from 'material-ui';
import React, { Component, Fragment } from 'react';
import Job from '../../../lib/Job';

class AnswerJobsQuestions extends Component {
  constructor(args) {
    super(args);
    this.state = { open: this.props.open, jobs: [] };
  };

  componentDidMount() {
  };

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevState.open !== this.props.open) {
      this.setState({
        open: this.props.open,
        initialState: _.cloneDeep(this.props.initialState),
        finalStructure: Object(this.props.initialState),
      });

      Job.getWhere({ id: { inq: this.props.jobs } }, (res) => {
        let qaResponse = res.filter(j => Array.isArray(j.mustHaveQuestions) && !!j.mustHaveQuestions.length);
        this.setState({
          jobs: qaResponse,
        }, () => {
          !!this.props.updateJobs && this.props.updateJobs(qaResponse)
        });
      });
    }
  }

  hasJobsWithUnAns = () => {
    let jWithUnAns = this.state.jobs.filter(job => {
      return this.hasUnAnsweredQuest(job.id, job.mustHaveQuestions);
    })

    return !!jWithUnAns.length;
  }

  hasUnAnsweredQuest = (jobId, questions) => {
    let qWithoutAns = questions.filter(q => {
      return !this.getJobQuestionValue(jobId, q.id)
    })

    return !!qWithoutAns.length;
  }

  getJobQuestionValue = (jobId, qId) => {
    let jobValue = Object(this.state.initialState)[jobId] || [];
    return Object(jobValue.find(el => parseInt(el.qId) === parseInt(qId))).ans;
  }

  renderJobQuestions = (job, questions) => {

    return questions.map((q, index) => {
      let mySavedValue = this.getJobQuestionValue(job.id, q.id);

      return <SingleQuestionPrint key={index}
        question={q.question} answer={mySavedValue}
        qId={q.id}
        job={job}
        composeFinalStructure={this.composeFinalStructure} />;
    });
  };

  composeFinalStructure = (jobId, qId, question, ans) => {
    let currentValues = Object(this.state.finalStructure)[jobId] || [];
    let unchangedQuestions = currentValues.filter(el => parseInt(el.qId) !== parseInt(qId));
    let currentQuestion = { jobId, qId, question, ans };
    let finalValues = [...unchangedQuestions, currentQuestion];

    let finalStructure = Object(this.state.finalStructure);
    finalStructure[jobId] = finalValues;

    this.setState({ finalStructure });
    this.props.finalUpdater(finalStructure);
  };

  renderElements = () => {
    let { jobs } = this.state;

    if (!jobs.length) {
      return 'No Questions found for selected Jobs';
    }

    return jobs.map(job => {
      return <Accordion
        key={`questions-${job.id}`} expanded={true}
        onChange={el => {
        }}>
        <AccordionSummary
          expandIcon={<Icon>expand_more</Icon>}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography>{job._name}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'inline-block', width: '100%' }}>
          {this.renderJobQuestions(job, job.mustHaveQuestions)}
        </AccordionDetails>
      </Accordion>;
    });
  };

  render() {

    let {
      close,
      nxtBtnText,
      handlerNext,
      cancelBtnText,
      handlerNextOnCancel,
      alwaysShow,
      extraComponentStructure
    } = this.props;

    let { finalStructure, jobs } = this.state;

    const dialog = ((!!jobs.length && this.hasJobsWithUnAns()) || alwaysShow) && <Dialog fullWidth={true} maxWidth="md" open={this.state.open}
      onClose={close}>
      <DialogTitle id="form-dialog-title">
        <Typography align='center' color='primary'>Answer Employer Questions For
          Each Job(s)</Typography>
      </DialogTitle>
      <DialogContent>
        <Fragment>
          {this.renderElements()}
          <br /><br />
          {extraComponentStructure}
        </Fragment>

      </DialogContent>
      <DialogActions>
        <Button onClick={handlerNextOnCancel({ initial: this.state.initialState })} color="primary">
          {cancelBtnText}
        </Button>

        <Button onClick={handlerNext(finalStructure)} color="primary">
          {nxtBtnText}
        </Button>

      </DialogActions>
    </Dialog>;

    return !!dialog ? dialog : '';
  };
}

class SingleQuestionPrint extends Component {

  constructor(args) {
    super(args);
    this.state = {
      answer: this.props.answer,
      q: this.props.question,
      job: this.props.job,
      qId: this.props.qId,
    };

  };

  render() {
    let { job, q, qId, answer } = this.state;

    return <div key={`question-${qId}`}>
      <Typography color='secondary'>
        {q}
      </Typography>
      <TextField
        style={{ height: 40 }}
        placeholder="Write your answer here"
        onChange={(el) => {
          this.setState({ answer: el.target.value }); // this is to make sure component re renders as value changes
        }
        }
        value={answer}
        onBlur={el => {
          this.props.composeFinalStructure(job.id, qId, q, el.target.value);
        }
        }
        fullWidth
      />

    </div>;
  }

}

export default AnswerJobsQuestions;


