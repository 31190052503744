import { Card, CardTitle } from 'material-ui/Card';
import Divider from 'material-ui/Divider';
import Drawer from 'material-ui/Drawer';
import FlatButton from 'material-ui/FlatButton';
import { List, ListItem } from 'material-ui/List';
import { ActionHome } from 'material-ui/svg-icons';
import ActionExitToApp from 'material-ui/svg-icons/action/exit-to-app';
import ActionFace from 'material-ui/svg-icons/action/face';
import ActionSupervisorAccount from 'material-ui/svg-icons/action/supervisor-account';
import CommunicationBusiness from 'material-ui/svg-icons/communication/business';
import NotificationSync from 'material-ui/svg-icons/notification/sync';
import PlacesBusinessCenter from 'material-ui/svg-icons/places/business-center';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Core from '../../lib/Core';

const innerDivStyle = { textAlign: 'left' };
const containerStyle = { backgroundColor: '#536dfe' };

const SideMenu = (props) => {
  const state = {
    open: props.drawerOpened,
  };
  const { t } = props;
  return (
    <Drawer
      className="Home-Drawer"
      open={state.open}
      onRequestChange={props.closeDrawer}
      overlayStyle={{ backgroundColor: 'transparent' }}
      docked={false}
      width={375}
    >
      <Card containerStyle={containerStyle}>
        <FlatButton
          onClick={props.closeDrawer}
          target="_blank"
          label="10by10"
          labelStyle={{ color: '#ffffff' }}
          icon={<i className="material-icons white">arrow_back</i>}
        />
        <CardTitle
          title={Core.getUserName()}
          subtitle={Core.getSessionEmail()}
          subtitleColor="#fff"
          titleColor="#fff"
        />
      </Card>
      <List className="Home-Drawer-List" onClick={props.closeDrawer}>
        {Core.isTrusted() && (
          <NavLink
            key="recruiter"
            exact
            activeClassName="navlink active"
            className="navlink"
            to="/recruiter"
          >
            <ListItem
              primaryText="Home"
              innerDivStyle={innerDivStyle}
              leftIcon={<ActionHome />}
            />
          </NavLink>
        )}
        {Core.isAdminOrRecruiterOnDev() && [
          <NavLink
            key="engagements"
            exact
            activeClassName="navlink active"
            className="navlink"
            to="/v3/engagements"
          >
            <ListItem
              primaryText="Engagements"
              innerDivStyle={innerDivStyle}
              leftIcon={<NotificationSync />}
            />
          </NavLink>,
        ]}
        <Fragment>
          <NavLink
            key="candidates"
            exact
            activeClassName="navlink active"
            className="navlink"
            to="/v3/candidates"
          >
            <ListItem
              primaryText={t('nav.candidates')}
              innerDivStyle={innerDivStyle}
              leftIcon={<ActionFace />}
            />
          </NavLink>
          <NavLink
            exact
            activeClassName="navlink active"
            className="navlink"
            to="/v3/jobs"
          >
            <ListItem
              primaryText="Jobs"
              innerDivStyle={innerDivStyle}
              leftIcon={<PlacesBusinessCenter />}
            />
          </NavLink>
        </Fragment>
        {Core.isAdminOrCoordinator() && (
          <Fragment>
            <NavLink
              exact
              activeClassName="navlink active"
              className="navlink"
              to="/v3/employers"
            >
              <ListItem
                primaryText="Employers"
                innerDivStyle={innerDivStyle}
                leftIcon={<CommunicationBusiness />}
              />
            </NavLink>
            <NavLink
              exact
              activeClassName="navlink active"
              className="navlink"
              to="/v3/accounts"
            >
              <ListItem
                primaryText="Accounts"
                innerDivStyle={innerDivStyle}
                leftIcon={<ActionSupervisorAccount />}
              />
            </NavLink>
            <NavLink
              exact
              activeClassName="navlink active"
              className="navlink"
              to="/v3/reports"
            >
              <ListItem
                primaryText={t('nav.reports')}
                innerDivStyle={innerDivStyle}
                leftIcon={
                  <i className="material-icons-block">insert_chart_outlined</i>
                }
              />
            </NavLink>
            <Divider />
            <NavLink
              exact
              activeClassName="navlink active"
              className="navlink"
              to="/v3/management/tags"
            >
              <ListItem
                primaryText="Tag Management"
                innerDivStyle={innerDivStyle}
                leftIcon={<i className="material-icons">settings</i>}
              />
            </NavLink>
            <NavLink
              exact
              activeClassName="navlink active"
              className="navlink"
              to="/v3/create/announcements"
            >
              <ListItem
                primaryText="Create Announcements"
                innerDivStyle={innerDivStyle}
                leftIcon={<i className="material-icons">settings</i>}
              />
            </NavLink>
          </Fragment>
        )}
        <NavLink
          exact
          activeClassName="navlink active"
          className="navlink"
          to="/v3/settings"
        >
          <ListItem
            primaryText="Settings"
            innerDivStyle={innerDivStyle}
            leftIcon={<i className="material-icons">settings</i>}
          />
        </NavLink>
        {Core.isAdmin() && (
          <NavLink
            exact
            activeClassName="navlink active"
            className="navlink"
            to="/v3/admin/tools"
          >
            <ListItem
              primaryText="Admin Tools"
              innerDivStyle={innerDivStyle}
              leftIcon={<i className="material-icons">settings</i>}
            />
          </NavLink>
        )}
        <Divider />
        <ListItem
          onClick={props.logout}
          primaryText="Logout"
          innerDivStyle={innerDivStyle}
          leftIcon={<ActionExitToApp />}
        />
      </List>
    </Drawer>
  );
};

export default withTranslation()(SideMenu);
