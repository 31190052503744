import Core from "./Core";
import Http from "./Http";

const Company = {

    post: (data, success) => {
        Http.post(
            Core.getApi("Company/"),
            data,
            success
        );
    },
    update: (companyId, data, success) => {
        Http.patch(
            Core.getApi("Company/"+companyId),
            data,
            success
        );
    },
    get: (cb, opts) => {
        let {where, fields, limit} = opts;
        where = Object(where);

        Http.get(
            Core.getApi("Company"),
            {
                filter: JSON.stringify({where, fields, limit})
            },
            function onSuccess(response) {
                cb(response);
            }
        );
    },
};

export default Company;
