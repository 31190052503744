import React, { Component, Fragment } from "react";
import Candidate from "../../../../lib/Candidate";
import Core from "../../../../lib/Core";
import MatchDrawer from "../../../Shared/Match/MatchDrawer";
import DoubleContentDrawer from "../JobPipe/DoubleContentDrawer";
import TextCVContentDrawer from "../JobPipe/TextCVContentDrawer";

class CandidatePipeHeader extends Component {

  handleDrawer = (refString) => {
    if (refString === "textCV") {
      this.refs.textCVDrawer.handleOpen();
    }
    if (refString === "doubleContentDrawer") {
      this.refs.doubleContentDrawer.handleOpen();
    }
  }

  render() {
    const {
      candidate,
      openMessage,
      job,
      makeChipsForCandidatePipe
    } = this.props;

    return <Fragment>
      <MatchDrawer
        ref="textCVDrawer"
        content={
          <TextCVContentDrawer
            selectedJob={job}
            candidate={candidate}
          />
        }
      />
      <MatchDrawer
        ref="doubleContentDrawer"
        content={
          <DoubleContentDrawer
            selectedJob={job}
            candidate={candidate}
          />
        }
      />
      <div className="match-new candidate">
        <p className="match-new candidate-name">
          <a href={`#/candidate/edit/${!!candidate && candidate.id}`} target="_blank" rel="noreferrer">{!!candidate && candidate._name}</a>
          <span> - submitted by </span>
          <span className="match-new recruiter-name">{!!candidate && candidate.recruiter._name}</span>
        </p>
        <div className="match-new candidate-attributes">
          <p>
            {!!candidate && !!candidate.email && <span className="anchor" onClick={e => openMessage(e, candidate)}>Email</span>}
            {!!candidate && !!candidate.linkedInURL && <span className="anchor" onClick={e => Core.openPopUp(candidate.linkedInURL)}>LinkedIn</span>}
            {!!candidate && !!candidate.gitHubURL && <span className="anchor" onClick={e => Core.openPopUp(candidate.gitHubURL)}>Github</span>}
            {!!candidate && !!candidate.stackoverflowUrl && <span className="anchor" onClick={e => Core.openPopUp(candidate.stackoverflowUrl)}>Stackoverflow</span>}
            {!!candidate && !!candidate.resumePdfUrl && <span className="anchor" onClick={e => Core.openPopUp(Candidate.getMyPdfUrl(candidate))}>CV</span>}
            {!!candidate && !!candidate.resumeTxtUrl && !!job && <span className="anchor" onClick={() => this.handleDrawer("textCV")}>Skills</span>}
            <span className="anchor" onClick={() => this.handleDrawer("doubleContentDrawer")}>Text CV/CV</span>
          </p>
          {!!makeChipsForCandidatePipe && makeChipsForCandidatePipe(job, candidate)}
        </div>
      </div>
    </Fragment>
  }
}

export default CandidatePipeHeader;