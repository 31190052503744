/*
 * Create form to request access token from Google's OAuth 2.0 server.
 */
function requestFormRedirect({ method, url, body }) {
  // alert(JSON.stringify({ method, url, body }, null, 2));
  // Google's OAuth 2.0 endpoint for requesting an access token
  //var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  var form = document.createElement("form");
  form.setAttribute("method", method); // Send as a GET request.
  form.setAttribute("action", url);

  /*
  // Parameters to pass to OAuth 2.0 endpoint.
  var params = {
    client_id:
      "434272170222-lvcmrrbk556aukmqcpbkj2ah1o8g8bid.apps.googleusercontent.com",
    redirect_uri: "http://localhost:3000/api/Accounts/linkCredential",
    response_type: "code",
    scope: "https://mail.google.com/ https://www.googleapis.com/auth/calendar",
    include_granted_scopes: "true",
    state: "google:link"
  };
  */
  // Add form parameters as hidden input values.
  for (var p in body) {
    var input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", body[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
}
export default requestFormRedirect;
