import Definition from "../Definition";

export const getCollegeCategory = (ranking, school, type) => {

    let positiveSignalTag = "";
    let schoolRanking;

    if (!school)
        return {};

    let matched = ranking.find(sc => {
        return school === sc.universityName;
    })

    if (Object(matched).worldRank <= 20) {
        positiveSignalTag = type === 'getId'
            ? Definition.getId('positiveSignals', 'Elite university')
            : type === "getLabel" ? ` ( Elite university ) ` : "";
        schoolRanking = 'Elite university';
    } else if (Object(matched).worldRank <= 50) {
        positiveSignalTag = type === 'getId'
            ? Definition.getId('positiveSignals', 'Top ranked university')
            : type === "getLabel" ? ' ( Top ranked university ) ' : "";
        schoolRanking = 'Top ranked university';
    } else if (Object(matched).worldRank <= 110) {
        positiveSignalTag = type === 'getId'
            ? Definition.getId('positiveSignals', 'Strong university')
            : type === "getLabel" ? ' ( Strong university ) ' : "";
        schoolRanking = 'Strong university';
    } else if (!!Object(matched).worldRank) {
        positiveSignalTag = type === 'getId'
            ? Definition.getId('positiveSignals', 'Ranked university')
            : type === "getLabel" ? ' ( Ranked university ) ' : "";
        schoolRanking = 'Ranked university';
    }

    return {
        positiveSignalTag,
        matched,
        schoolRanking
    };
}