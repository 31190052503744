
const IS_RED = -1;
const IS_GREEN = 1;
const IS_GREY =  0;

const IsYearsOfExperienceGreen = (job,candidate)=>{
    if(!job || !candidate){
        return IS_GREY;
    }

    let ret = IS_RED;

    let diff = parseFloat(job.minYearsOfExperience) - parseFloat(candidate._yearsOfExperienceForCalc);
    let diffPercent = diff / parseInt(job.minYearsOfExperience);

    if (diff <= 0) {
        ret = IS_GREEN;
    } else if (diff > 0 && diffPercent <= 0.4) {
        ret = IS_GREY;
    }

    return ret;
};

const YearsOfExperienceColor = (job,candidate)=>{
    let ret = IsYearsOfExperienceGreen(job,candidate);

    let color = 'red';

    if (ret === IS_GREEN) {
        color = 'green';
    } else if (ret === IS_GREY) {
        color = 'grey';
    }

    return color;
}


export {
    YearsOfExperienceColor,
    IsYearsOfExperienceGreen
};

