import React, { Component, Fragment } from 'react';

import AppBar from 'material-ui/AppBar';
import styles from '../../Forms/Styles';
import FlatButton from 'material-ui/FlatButton';
import Core from '../../../lib/Core';

class Appbar extends Component {
  getTitle = () => {
    const { subject } = this.props;
    let title;
    if (subject !== null) {
      if (this.props.sourceKey === 'job') {
        title = `Candidate Match: ${Object(subject)._name}`;
      } else {
        title = `Job Match: ${Object(subject)._name}`;
      }
    }

    return title;
  };

  goBack = (ev) => Core.goBack(this.props);

  render() {
    return (
      <Fragment>
        <AppBar
          title={this.getTitle()}
          titleStyle={styles.AppBar.title}
          style={styles.AppBar.navNew}
          iconElementLeft={
            <FlatButton
              icon={<i className="material-icons">arrow_back</i>}
              style={styles.AppBar.iconElementLeft}
              onClick={this.goBack}
            />
          }
        />
      </Fragment>
    );
  }
}
export default Appbar;
