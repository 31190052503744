import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import React, { Component, Fragment } from "react";
import { getCategoryKeys } from "../../../../lib/tools/getSpecificCategoryKey";
import Chips from "../../../Forms/ChipsNew";
import BasicChip from "./ElementsComparisonHaves/Chip";
import MatchSkills from "./MatchSkills";

class Qualifications extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      technicalSkills: this.props.candidate.technicalSkills,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.candidate.technicalSkills !==
      this.props.candidate.technicalSkills
    ) {
      this.setState({
        technicalSkills: nextProps.candidate.technicalSkills,
      });
    }
  }

  skillsHandler = (technicalSkills) => {
    const { skillsHandler } = this.props;
    this.setState({
      technicalSkills,
    });
    !!skillsHandler && skillsHandler(technicalSkills, "technicalSkills");
  };

  renderTechnicalSkills = (technicalSkills) => {
    if (!Array.isArray(technicalSkills)) {
      technicalSkills = [];
    }

    const { jobArgs, source } = this.props;
    const jobArgsTechnicalSkills = getCategoryKeys(
      { key: "technicalSkills" },
      jobArgs
    );
    const toMatchObj = { technicalSkills: jobArgsTechnicalSkills };
    let chipObj = { key: "technicalSkills" };
    let isMatched = null;

    const decision = technicalSkills.map((skill) => {
      chipObj.value = parseInt(skill);
      isMatched = BasicChip.isChipMatched(chipObj, toMatchObj);
      return !!isMatched
        ? isMatched
        : source && source === "candidateMatch"
          ? null
          : isMatched;
    });

    return (
      <Fragment>
        <p>
          <strong>10x10 Approved Chip</strong>(Admin configured chip)
        </p>
        <Chips
          name="technicalSkills"
          values={technicalSkills}
          decisionArray={decision}
          onChange={(technicalSkills) => {
            this.skillsHandler(technicalSkills);
          }}
        />
      </Fragment>
    );
  };

  rolesHandler = (roles) => {
    const { rolesHandler } = this.props;

    rolesHandler(roles);
  };

  renderRoles = (roles) => {
    if (typeof roles == "string") {
      if (roles === "") {
        return null;
      } else if (roles.includes(",")) {
        roles = roles.split(/[,]/);
      } else {
        roles = [roles];
      }
    }
    if (!Array.isArray(roles)) {
      roles = [];
    }
    return (
      <div>
        <p>
          <strong>Roles</strong>
        </p>
        {roles.map((role, index) => {
          return (
            <Fragment key={index}>
              <Chip
                key={index}
                className="chip slim-chip"
                variant="outlined"
                size="small"
                label={role}
                style={{
                  color: "#454545",
                }}
              />
            </Fragment>
          );
        })}
      </div>
    );
  };

  moveToApprovedChips = (candidate, keyAttributeChanged, chipId) => {
    const { moveToApprovedChips } = this.props;
    const existingTechSkills = this.state.technicalSkills || [];
    const newTechSkills = Array.from(new Set([...existingTechSkills, chipId]));
    moveToApprovedChips(candidate, { technicalSkills: newTechSkills });
    this.setState({ technicalSkills: newTechSkills });
  };

  render() {
    const { candidate, jobArgs, source } = this.props;
    const { technicalSkills } = this.state;

    return (
      <Fragment>
        <Card className="match-new section-card">
          <CardContent className="match-new card-content">
            <h2 className="match-new section-header">QUALIFICATIONS </h2>
            <hr />

            <div>{!!candidate && this.renderRoles(candidate._roles)}</div>
            <div>
              {!!candidate && this.renderTechnicalSkills(technicalSkills)}
            </div>
            {!!candidate && (
              <MatchSkills
                candidate={candidate}
                jobArgs={jobArgs}
                source={source}
                deleteIcon={true}
                onDelete={this.moveToApprovedChips}
              />
            )}
          </CardContent>
        </Card>
      </Fragment>
    );
  }
}

export default Qualifications;
