import Definition from "../../lib/Definition";
import {engagementMatchStrengthForYes, engagementMatchStrengthForNo} from "../../lib/Constants";

const getMisMatch = ({engagement, annotatorMatchStrength, oldMatchStrength, checkOldState = false}) => {
    // const oldMatchStrength = !!engagement ? engagement.matchDecision : null;
    const newMatchStrengthId = Definition.getId("engagementMatchStrength", annotatorMatchStrength);

    let newMS = "", oldMS = "";
    if (Object.values(engagementMatchStrengthForYes).includes(newMatchStrengthId)) {
        newMS = "yes";
    } else if (Object.values(engagementMatchStrengthForNo).includes(newMatchStrengthId)) {
        newMS = "no";
    }

    const oldMatchStrengthId = typeof oldMatchStrength === "string" ? Definition.getId("engagementMatchStrength", oldMatchStrength) : oldMatchStrength;

    if (Object.values(engagementMatchStrengthForYes).includes(oldMatchStrengthId)) {
        oldMS = "yes";
    } else if (Object.values(engagementMatchStrengthForNo).includes(oldMatchStrengthId)) {
        oldMS = "no";
    } else if (!!checkOldState && !!engagement
        && /10x10 - No Match/gi.test(engagement.rejectionReason)
        && /Closed/gi.test(engagement.state)
        && /E - 10x10/gi.test(engagement.status)
    ) {
        oldMS = "no";
    }

    return {newMS, oldMS};
}

const getMisMatchThumbsAction = {
    getMisMatch
}

export default getMisMatchThumbsAction;