/** ============================================ µ
 * @description Engagements Card [JS]
 *              UI Component
 * @routes      /engagements
 *              /engagement/view/:id
 *              /engagement/represent/:id
 * @updatedAt   2021-08-20 Fri
 * ============================================ */
/* IMPORTS ==================================== */

import {
  Card,
  Checkbox,
  Divider,
  IconButton, Table,
  TableBody,
  TableRow,
  TableRowColumn,
  TextField
} from "material-ui";
import FlatButton from "material-ui/FlatButton";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Candidate from "../../../lib/Candidate";
import Core, { colors } from "../../../lib/Core";
import Engagement from "../../../lib/Engagement";
import Google from "../../../lib/Google";
import Job from "../../../lib/Job";
import EngagementModel from "../../../lib/models/engagement";
import { openCandidateRepresentEmailPreview } from "../../../lib/services/Email/EmailCandidate.lib";
import Streak from "../../../lib/Streak";
import Col from "../../Forms/Col";
import Row from "../../Forms/Row";
import MoreOptions from "./MoreOptions";
import StageColumn from "./StageColumn";

/* MAIN CLASS ================================= */

class EngagementCard extends Component {
  
  timeout;
  pickers = {};
  dropdowns = {};

  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      id: 0,
      engagement: {},
      candidate: {},
      recruiter: {},
      job: {},
      engagementNotes: "",
      candidateNotes: "",
      jobNotes: "",
      engagementNotesModel: {},
      candidateNotesModel: {},
      jobNotesModel: {},
      ...this.props.model,
      expanded: false,
      history: [],
      openedColor: {},
      CardStyle: {},
      newComment: "",
      box: {}
    };
    this.reloadData = ev => this.parent.parent.reloadData();
    if (/represent/i.test(document.location.href)) {
      const engagement = this.state;
      const { candidateId } = engagement;
      openCandidateRepresentEmailPreview({
        candidateId,
        engagement,
      });
    }
  };

  unexpandCard = () => {
    this.setState({
      expanded: false,
      CardStyle: null,
      openedColor: null,
      rightArrow: { transform: "rotate(0deg)" }
    });
  };

  expandCard = () => {
    this.setState(
      {
        expanded: true,
        CardStyle: { margin: "10px auto" },
        openedColor: { color: "#715EFF" },
        rightArrow: { transform: "rotate(180deg)" }
      },
      then => Core.log(this.state)
    );
  };

  handleToggle = ev => {
    Core.log(this.state);
    /*
    if (this.state.expanded) {
      this.unexpandCard();
    } else {
      this.expandCard();
    }
    */
  };

  updateField = (field, eventStatus, callback) => {


    this.parent.parent.updateField(this.state, field, (updated) => {
      const state = {
        ...this.state,
        ...field,
      };

      if (!eventStatus) {
        state.filters = EngagementModel.mapFilters(state);
      }

      this.parent.updateItem(state);

      this.unexpandCard();
      this.setState(state, then => {
        callback && callback({ ...state, ...updated });
      });
    });

  };

  onCheckStar = (ev, checked) => {
    const updateLocalList = response => {
      const state = {
        id: this.state.id,
        starredId: response.id,
        starred: response.starred,
        filters: {
          ...this.state.filters,
          Starred: ["Non Starred", "Starred"][~~Boolean(response.starred)]
        }
      };
      this.setState(state);
      this.parent.updateItem(state);
    };
    Engagement.updateStarred(
      this.state.id,
      this.state.starredId,
      checked,
      updateLocalList
    );
    this.unexpandCard();
  };

  openCommentDialog = ev => {
    Core.showMessage("Fetching previous comments, wait a moment...");
    Streak.getComments(this.state.boxKey, comments => {
      Core.hideMessage();
      Core.dialog.open({
        title: <>Add a new comment</>,
        message: (
          <StreakComments
            ref={self => (this.StreakComments = self)}
            boxKey={this.state.boxKey}
            comments={comments}
          />
        ),
        actions: [
          <FlatButton
            label="Cancel"
            className="button-flat-darker"
            onClick={ev => Core.dialog.close()}
          />,
          <FlatButton
            label="Submit"
            className="button-white-cyan"
            onClick={ev => {
              this.StreakComments.saveToCloud();
              Core.dialog.close();
            }}
          />
        ]
      });
    });
  };

  openNotesDialog = ev => {
    Core.showMessage("Fetching notes, wait a moment...");
    Streak.getBox(this.state.boxKey, box => {
      Core.hideMessage();
      Core.dialog.open({
        title: <>Edit Streak Note</>,
        message: <StreakNotes boxKey={this.state.boxKey} notes={box.notes} />,
        actions: [
          <FlatButton
            label="Done"
            className="button-white-cyan"
            onClick={ev => Core.dialog.close()}
          />
        ]
      });
    });
  };

  openDatesDialog = ev => {
    Core.dialog.open({
      title: <>Update Dates</>,
      message: (
        <DatesForm
          ref={self => (this.DatesForm = self)}
          engagement={this.state}
        />
      ),
      style: { width: 480 },
      actions: [
        <FlatButton
          label="Cancel"
          className="button-flat-darker"
          onClick={ev => {
            Core.dialog.close();
          }}
        />,
        <FlatButton
          label="Done"
          className="button-white-cyan"
          onClick={ev => {
            Core.dialog.close();
            this.updateField({
              ...this.DatesForm.getDates()
            });
          }}
        />
      ]
    });
  };

  openEmailThreads = ev => {
    Streak.getThreads(this.state.boxKey, threads => {
      if (!!threads.length) {
        Core.dialog.open({
          title: <>Email Threads</>,
          message: (
            <EmailThreads
              ref={self => (this.EmailTheads = self)}
              threads={threads}
              engagement={this.state}
            />
          ),
          style: { minWidth: "60vw" },
          actions: [
            <FlatButton
              label="Done"
              className="button-white-cyan"
              onClick={ev => Core.dialog.close()}
            />
          ]
        });
      } else {
        Core.showMessage(
          "This Streak Box, doesn't have email threads attached yet"
        );
      }
    });
  };

  openDetails = ev => {
    Candidate.get(this.state.candidateId, candidate => {
      //candidate.openDetails();
      Job.get(this.state.jobId, job => {
        Core.log({ candidate, job });
        Core.openDrawer({
          style: {
            width: 1600,
            maxWidth: "calc(100vw - var(--leftSideWidth))",
            minWidth: 320
          },
          content: (
            <Fragment>
              {candidate.getEngagementDetails()}
              {job.getEngagementDetails()}
              <IconButton
                style={{ position: "fixed", top: 0, right: 0 }}
                onClick={ev => Core.closeDrawer()}
              >
                <i className="material-icons">arrow_forward_ios</i>
              </IconButton>
            </Fragment>
          )
        });
      });
    });
  };

  getPreview = job => Job.getPreview(job);

  render() {
    // console.debug('µEngCard:render', { engagement, status, askPermissionFlag });
    return (
      <Card
        className="row-card engagement"
        expanded={this.state.expanded}
        style={this.state.CardStyle}
      >
        {/** COLLAPSED VIEW */}
        <Table className="collapsed-view" style={{ tableLayout: "initial" }}>
          <TableBody displayRowCheckbox={false}>
            <TableRow selectable={false} className="v-align-top padding-tops">
              {/** COL#01: CANDIDATE EMAIL & NAME & PLATFORM RATING */}
              <TableRowColumn
                title="Check to Select for Matching"
                style={{
                  width: 36,
                  textAlign: "right",
                  paddingLeft: 0,
                  paddingRight: 0
                }}
                rowSpan="2"
              >
                <Checkbox
                  className="star"
                  checked={this.state.checked}
                  onCheck={(event, checked) => {
                    this.setState({ checked }, then => {
                      this.props.onCheck(this.state.id, checked, this);
                    });
                  }}
                  disabled={false}
                />
              </TableRowColumn>
              <FirstRowColumn
                engagement={this.state}
                onClick={this.handleToggle}
              />
              <StageColumn
                parent={this}
                args={{
                  ref: "Confirmation",
                  streak: "Confirmed",
                  label: "Confirmation",
                  stage: /pending|confirmation/i,
                  value: "introduced",
                  edit: "confirmed",
                  next: "Submission",
                  width: 150,
                  colSpan: 2
                }}
              />
              <StageColumn
                parent={this}
                args={{
                  ref: "Submission",
                  streak: "Submitted",
                  label: "Submitted",
                  stage: /submission/i,
                  value: "submitted",
                  next: "Review"
                }}
              />
              <StageColumn
                parent={this}
                args={{
                  ref: "Review",
                  streak: "Reviewed",
                  label: "CV Reviewed",
                  stage: /review/i,
                  value: "reviewed",
                  next: "Screen"
                }}
              />
              <StageColumn
                parent={this}
                args={{
                  ref: "Screen",
                  streak: "Screened",
                  label: "Screen Dates",
                  stage: /screen/i,
                  value: "screened",
                  next: "Onsite",
                  width: 200
                }}
              />
              <StageColumn
                parent={this}
                args={{
                  ref: "Onsite",
                  streak: "Onsite",
                  label: "Onsite Dates",
                  stage: /onsite/i,
                  value: "onsite",
                  next: "Offer",
                  width: 200
                }}
              />
              <StageColumn
                parent={this}
                args={{
                  ref: "Offer",
                  streak: "Onsite",
                  label: "Offer",
                  stage: /offer|guarantee|hire/i,
                  value: "onsite",
                  next: "Hire",
                  next2: "Guarantee",
                  next3: "End",
                  width: 150,
                  colSpan: 2
                }}
              />

              <TableRowColumn
                title="Options"
                className="last-col inline-blocks v-align-mid"
              >
                <Checkbox
                  title="Click to Starred"
                  className="star"
                  checked={this.state.starred}
                  onCheck={this.onCheckStar}
                  checkedIcon={<i className="material-icons">star</i>}
                  uncheckedIcon={<i className="material-icons">star_border</i>}
                  iconStyle={this.state.openedColor}
                />

                <MoreOptions EngagementCardController={this} />

                <i
                  className="material-icons"
                  style={{
                    width: 24,
                    height: 24,
                    margin: 0,
                    cursor: "pointer",
                    fontWeight: 200
                  }}
                  onClick={this.openDetails}
                >
                  chevron_right
                </i>
              </TableRowColumn>

            </TableRow>
          </TableBody>
        </Table>
        <Divider />
      </Card>
    );
  }
}

class FirstRowColumn extends Component {
  render() {
    const { engagement } = this.props;
    const reminderSentDate = (
      Core.getKeyValue('list-header-rec-todo')
        ? (
          !!engagement.reminderSentDates?.recruiter
            ? ', Reminder: ' + moment(engagement.reminderSentDates.recruiter).format("MM/DD HH:mm")
            : ''
        )
        : Core.getKeyValue('list-header-emp-todo')
          ? (
            !!engagement.reminderSentDates?.employer
              ? ', Reminder: ' + moment(engagement.reminderSentDates.employer).format("MM/DD HH:mm")
              : ''
          )
          : ''
    );
    const streakFailures = window.streakFailures || {};
    return (
      <TableRowColumn
        onClick={this.props.onClick}
        className="first-item blocks cursor-default"
        style={{ minWidth: 320, maxWidth: 320 }}
        colSpan={3}
      >
        <p>{engagement.matchStrength ? Engagement.matchStrengthLabel(engagement.matchStrength) : 'Match Strength: Unspecified'}</p>
        <NavLink
          title="Candidate - Employer (Employer Stage) | Go to edit Candidate"
          to={"/candidate/edit/" + engagement.candidateId}
        >
          <b>{engagement._name}</b>
        </NavLink>
        {Core.isAdminOrCoordinator() ? (
          <NavLink
            title="Job Title - Role | Go to edit Job"
            to={"/job/edit/" + engagement.jobId}
          >
            {engagement._jobTag}
          </NavLink>
        ) : (
          <span title="Job Title - Role">{engagement._jobTag}</span>
        )}
        <span title="Recruiter Name - Agency Name">
          {engagement._recruiterName} - {engagement._agencyName}
        </span>
        <span
          title="Stage, Status, State, Rejection Reason"
          className="bold"
          style={{ marginTop: "10px" }}
        >
          {engagement.stage}, {engagement.status}
          {`${Core.isAdminOrCoordinator() ? `, ${engagement.state}` : ""}`}
          {engagement.rejectionReason && /closed/i.test(engagement.state)
            ? ", " + engagement.rejectionReason
            : ""}
        </span>
        <span
          title={`Overdue Date: ${engagement.overdueDate
            }, Followed Up, Next Action`}
          className={engagement._isOverdue ? "cred" : ""}
        >
          {[
            (
              !!engagement._overdueDays && "Overdue: " + engagement._overdueDays
              +
              reminderSentDate
            ),
            !!engagement.followedUpCount &&
            "Followed Up: " + engagement.followedUpCount,
            !!engagement.nextAction && "Next: " + engagement.nextAction
          ]
            .filter(i => !!i)
            .join(", ")}
        </span>
        <span
          className="align-left"
          title={`Last Action: ${engagement.lastAction}, actionOwner`}
        >
          {[
            !!engagement.lastAction &&
            "Last: " + moment(engagement.lastAction).format("MM-DD-YYYY"),
            !!engagement.actionOwnerId &&
            "Owner: " + engagement.actionOwner._name
          ]
            .filter(i => !!i)
            .join(", ")}
          <a
            href={`https://mail.google.com/mail/u/1/#box/${engagement.boxKey}`}
            target="_blank"
            rel="noreferrer"
            className="pointer"
            title="Go to Streak Box"
            style={{ float: "right" }}
          >
            Streak&nbsp;❯&nbsp;&nbsp;&nbsp;
          </a>
        </span>
        {!!streakFailures[engagement.id]?.length && (
          <span className="c-red capitalize">Streak Failures: {streakFailures[engagement.id].join(', ')}</span>
        )}
      </TableRowColumn>
    );
  }
}

export class StreakComments extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      boxKey: this.props.boxKey,
      newComment: "",
      sign: "",
      comments: this.props.comments || []
    };
  }
  saveToCloud = ev => {
    !!this.state.newComment.trim().length &&
      Streak.createComment(
        {
          boxKey: this.state.boxKey,
          message: [this.state.newComment, this.state.sign].join("\n\n")
        },
        response => {
          Core.showMessage("Added new comment to the Streak box");
        }
      );
  };
  setComment = (ev, newComment) => {
    const sign =
      " - " +
      Core.getUserName() +
      " - " +
      moment()
        .tz(moment.tz.guess())
        .format("lll z");
    this.setState({ newComment, sign });
  };
  render() {
    return (
      <div>
        <TextField
          name="newComment"
          placeholder="Type something"
          value={this.state.newComment}
          onChange={this.setComment}
          rows={1}
          rowsMax={6}
          multiLine
          fullWidth
        />
        <span>{this.state.sign}</span>
        {!!this.state.comments && !!this.state.comments.length && (
          <div>
            <h3>Previous comments</h3>
            <div className="scroll-y" style={{ maxHeight: "50vh" }}>
              {this.state.comments.map((item, index) => (
                <Fragment key={`engagement-${this.state.id}-card-comments-${index}`}>
                  <div>
                    <pre>{item.message}</pre>
                  </div>
                  <hr />
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export class StreakNotes extends Component {
  constructor() {
    super(...arguments);
    const split = String(this.props.notes || "")
      .replace("\r", "")
      .split("\n\n");
    let sign = "";
    if (split.length > 1) {
      sign = split.pop();
    }
    const notes = split.join("\n\n");
    this.state = {
      boxKey: this.props.boxKey,
      notes,
      sign
    };
  }
  saveToCloud = ev => {
    Streak.updateNotes(
      {
        boxKey: this.state.boxKey,
        notes: [this.state.notes, this.state.sign].join("\n\n")
      },
      response => { }
    );
  };
  setNotes = (ev, notes) => {
    const sign =
      " - " +
      Core.getUserName() +
      " - " +
      moment()
        .tz(moment.tz.guess())
        .format("lll z");
    this.setState({ notes, sign }, then => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.saveToCloud, 2000);
    });
  };
  render() {
    return (
      <div>
        <TextField
          name="newComment"
          placeholder="Type something"
          value={this.state.notes}
          onChange={this.setNotes}
          rows={1}
          rowsMax={6}
          multiLine
          fullWidth
        />
        <span>{this.state.sign}</span>
      </div>
    );
  }
}

export class DatesForm extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      hired: null,
      screened: null,
      overdueDate: null,
      lastAction: null,
      ...this.props.engagement
    };
  }
  getDates = ev => {
    const dates = {};
    this.state.hired && (dates.hired = moment(this.state.hired).toISOString());
    this.state.screened &&
      (dates.screened = moment(this.state.screened).toISOString());
    this.state.overdueDate &&
      (dates.overdueDate = moment(this.state.overdueDate).toISOString());
    this.state.lastAction &&
      (dates.lastAction = moment(this.state.lastAction).toISOString());
    return dates;
  };
  render() {
    return (
      <div>
        <Row>
          <Col>
            <label>Hired Date</label>
          </Col>
          <Col>
            <TextField
              name="date"
              id="datetime-local"
              underlineFocusStyle={{ borderColor: colors.purple }}
              type="datetime-local"
              className="card-picker-date-tf"
              defaultValue={moment(this.state.hired).format("YYYY-MM-DDTHH:mm")}
              onChange={ev => {
                const date = ev.target.value;
                if (date) {
                  this.setState(state => {
                    state.hired = date;
                    return state;
                  });
                }
              }}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Screened Date</label>
          </Col>
          <Col>
            <TextField
              name="date"
              id="datetime-local"
              underlineFocusStyle={{ borderColor: colors.purple }}
              type="datetime-local"
              className="card-picker-date-tf"
              defaultValue={moment(this.state.screened).format(
                "YYYY-MM-DDTHH:mm"
              )}
              onChange={ev => {
                const date = ev.target.value;
                if (date) {
                  this.setState(state => {
                    state.screened = date;
                    return state;
                  });
                }
              }}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Overdue Date</label>
          </Col>
          <Col>
            <TextField
              name="date"
              id="datetime-local"
              underlineFocusStyle={{ borderColor: colors.purple }}
              type="datetime-local"
              className="card-picker-date-tf"
              defaultValue={moment(this.state.overdueDate).format(
                "YYYY-MM-DDTHH:mm"
              )}
              onChange={ev => {
                const date = ev.target.value;
                if (date) {
                  this.setState(state => {
                    state.overdueDate = date;
                    return state;
                  });
                }
              }}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Last Action</label>
          </Col>
          <Col>
            <TextField
              name="date"
              id="datetime-local"
              underlineFocusStyle={{ borderColor: colors.purple }}
              type="datetime-local"
              className="card-picker-date-tf"
              defaultValue={moment(this.state.lastAction).format(
                "YYYY-MM-DDTHH:mm"
              )}
              onChange={ev => {
                const date = ev.target.value;
                if (date) {
                  this.setState(state => {
                    state.lastAction = date;
                    return state;
                  });
                }
              }}
              fullWidth
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export class EmailThreads extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      threads: this.props.threads,
      engagement: this.props.engagement,
      data: []
    };
    this.onLoad(this.state);
  }
  onLoad = state => {
    const threads = [...state.threads].slice(0, 5);
    const next = em => {
      if (!!threads.length) {
        const thread = threads.pop();
        Google.getThreadEmails(
          thread.threadGmailId,
          response => {
            this.setState(
              state => {
                state.data.push(response);
                return state;
              },
              then => {
                Core.log({ data: this.state.data });
                next();
              }
            );
          },
          error => Core.showFailure(error)
        );
      }
    };
    next();
  };
  render() {
    return (
      <div className="scroll-y" style={{ height: "40vw" }}>
        {this.state.data.map((item, index) => {
          const firstMessage = item.messages[0];
          const headers = {};
          firstMessage.payload.headers.forEach(header => {
            headers[header.name] = header.value;
          });
          /** * /
          function getHTMLPart(arr) {
            for (var x = 0; x <= arr.length; x++) {
              if (typeof arr[x].parts === "undefined") {
                if (arr[x].mimeType === "text/html") {
                  return arr[x].body.data;
                }
              } else {
                return getHTMLPart(arr[x].parts);
              }
            }
            return "";
          }
          function getBody(message) {
            var encodedBody = "";
            if (typeof message.parts === "undefined") {
              encodedBody = message.body.data;
            } else {
              encodedBody = getHTMLPart(message.parts);
            }
            encodedBody = encodedBody
              .replace(/-/g, "+")
              .replace(/_/g, "/")
              .replace(/\s/g, "");
            return decodeURIComponent(escape(window.atob(encodedBody)));
          }
          /** */
          return (
            <p key={`engagement-${this.state.id}-card-email-thread-${index}`}>
              <span style={{ float: "right" }}>
                &nbsp;&nbsp;&nbsp;
                {moment(headers["Date"]).fromNow()}
              </span>
              <hr />
              <span>{headers["Subject"]}</span>
              <br />
              <span>
                From {headers["From"]} to {headers["To"]}
              </span>
              <br />
              {/** * /}
              <div
                dangerouslySetInnerHTML={{
                  __html: getBody(firstMessage.payload)
                }}
              />
              <br />
              {/** */}
              <a
                href={`https://mail.google.com/mail/u/1/#box/${this.state.engagement.boxKey
                  }`}
                target="_blank"
                rel="noreferrer"
                className="pointer"
              >
                {item.messages.length}
                &nbsp;messages &nbsp;❯&nbsp;&nbsp;&nbsp;
              </a>
              <br />
            </p>
          );
        })}
      </div>
    );
  }
}

/* EXPORTS ==================================== */

export default EngagementCard;

/* ============================================ */
