import _ from 'lodash';
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import React, { Component } from "react";
import loader from "../../assets/images/loader.gif";
import Core from "../../lib/Core";
import Debug from "../../lib/Debug";

const loadWrapper = [
  <div key={"loaderKey"} className="not-found inline-blocks">
    Loading&nbsp;
    <img alt="loading..." height="14" src={loader} />
  </div>
];

/**
 * LIST WITH PAGINATION
 * this list should be used to show 1 hundred or less items
 */
class List extends Component {
  length = 0;
  data;
  selected = [];
  cards = {};
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      collection: loadWrapper,
      selected: [],
      tab: this.props.tab,
      extraInfo: this.props.extraInfo
    };
  }
  componentWillUpdate = (nextP, nextS) => {
    if (nextP.extraInfo !== this.state.extraInfo) {
      this.setState({ extraInfo: nextP.extraInfo });
    }
  }
  setItems = data => {
    this.data = data;
    this.loadData();
  };
  restart = em => this.setState({ collection: loadWrapper });
  updateItem = item => {
    this.data = this.data.map(current => {
      if (current.id === item.id) {
        current = { ...current, ...item };
      }
      return current;
    });
    this.loadData();
  };
  loadData(tab) {
    this.selectTab(tab || this.state.tab);
  }
  selectTab(tab) {
    Debug.time("Render List - No Pagination");
    this.setState({ tab }, then => {
      this.groupBy(tab);
    });
  }
  groupBy(filter) {
    const items = {};
    let recruiterMyJobIds = _.has(this.props, 'extraInfo.recruiterJobIds')
      ? this.props.extraInfo.recruiterJobIds : [];
    // organice data by filter key
    this.data.forEach(item => {
      let header = item.filters[filter];
      if (filter !== undefined && filter !== null) {
        if (filter === "Recent") {
          header = Core.getFromNow(header);
        }
        items[header] = items[header] || [];
        if (filter === "Starred") {
          if (item.filters["Starred"] === "Starred") {
            items[header].push(item);
          }
        } else if (/my sourcing/i.test(filter)) {
          if (!!recruiterMyJobIds.filter(job => job.jobId === item.id).length) {
            items[header].push(item);
          }
        } else {
          if (header !== false) {
            items[header].push(item);
          }
        }
      }
    });
    // sort filter keys and set collection
    this.setCollection(
      filter === "Stage"
        ? [
          "End",
          "Hire",
          "Guarantee",
          "Offer",
          "Onsite",
          "Screen",
          "Review",
          "Submission",
          "Confirmation",
          "Pending"
        ]
        : filter === "Starred"
          ? ["Starred", "Non Starred"]
          : filter === "Recent"
            ? Object.keys(items).sort((a, b) => {
              a = items[a][0].filters.Recent;
              b = items[b][0].filters.Recent;
              return a < b ? 1 : -1;
            })
            : Object.keys(items)
              .sort((a, b) => {
                return a.toUpperCase() < b.toUpperCase() ? 1 : -1;
              })
              .reverse(),
      items,
      filter
    );
  }
  setCollection(headers, items, filter) {
    // const Card = this.props.card;
    let collection = [];
    // push filtered data in result
    headers.forEach((header, index) => {
      if (items[header]) {
        // create header; not for Name
        if (!/name|starred/i.test(filter)) {
          /** */
          !this.props.disableTabs &&
            collection.push({
              id: `header${index}`,
              header
            });
          /** */
        }
        collection = collection.concat(items[header]);
      }
    });
    // update the collection
    this.length = 0;
    this.getCardCollection(collection, true).then(collection => {
      this.setState({ collection }, then => {
        Debug.showTimeEnd("Render List");
        this.setState({ busy: false });
      });
    });
    /*
    let count = 1;
    collection = !!collection.length
      ? collection.map(model => {
          if (model.header) {
            return (
              <p key={model.id} className="group-header">
                {model.header}
              </p>
            );
          } else {
            this.length++;
            return (
              <Card
                key={model.id}
                index={count++}
                model={model}
                parent={this}
                onCheck={this.props.onCheck}
              />
            );
          }
        })
      : [
          <div key="noMatchesKey" className="not-found">
            No matches
          </div>
        ];
    this.setState({ collection }, then =>
      Debug.showTimeEnd("Render List - No Pagination")
    );
    */
  }
  getCardCollection(collection, countLength) {
    return new Promise(resolve => {
      const Card = this.props.card;
      const { extraInfo } = this.state;
      collection = collection.map(model => {
        if (model.header) {
          return (
            <p key={model.id} className="group-header">
              {model.header}
            </p>
          );
        } else if (model.filters) {
          countLength && this.length++;
          return (
            <Card
              key={Core.getKey()}
              model={model}
              parent={this}
              params={this.props.params}
              checked={!!this.selected.find(item => item.id === model.id)}
              onCheck={(id, checked, card) => {
                let selected = [...this.selected];
                if (!checked) {
                  selected = selected.filter(item => item.id !== id);
                  this.cards[card.state.id].setState({ checked: false });
                } else {
                  this.cards[card.state.id] = card;
                  selected.push(card.state);
                }
                this.selected = selected;
                this.props.onCheck(selected);

                this.getCardCollection(this.selected).then(selected => {
                  //this.props.onCheck(selected);
                  this.setState({ selected });
                });
              }}
              extraInfo={
                {
                  ...extraInfo,
                  jobIdHashedWithAccount: !!this.props.extraInfo && !!this.props.extraInfo.jobIdHashedWithAccount &&
                    !!this.props.extraInfo.jobIdHashedWithAccount[model.id] ?
                    this.props.extraInfo.jobIdHashedWithAccount[model.id].join(", ") : []
                }
              }
            />
          );
        }
        return null;
      });
      resolve(collection);
    });
  }
  render() {
    return (
      <div
        className={['list', this.props.className].filter(v => !!v).join(' ')}
        style={{ height: 'auto' }}
      >
        <div className="sort-buttons">
          <div className="nowrap scroll-x align-right">
            {!this.props.disableTabs &&
              this.props.tabs
                .filter(tab => {
                  if (tab === "Closed") {
                    if (Core.isRecruiter()) {
                      return false;
                    } else if (!this.parent.state.showAll) {
                      return false;
                    }
                  }
                  if (/Todo/i.test(tab)) {
                    if (this.parent.state.showAll) {
                      return false;
                    }
                  }
                  if (/my sourcing/i.test(tab) && !Core.isRecruiter()) {
                    return false;
                  }
                  return true;
                })
                .map(tab => (
                  <FlatButton
                    key={`tab${tab}`}
                    className={`sort-button${this.state.tab === tab ? " tab-selected" : ""
                      }`}
                    label={tab}
                    onClick={event => this.selectTab(tab)}
                  />
                ))}
          </div>
        </div>
        <div
          ref={scroller => (this.scroller = scroller)}
          className={`list-scroller${!!this.props.disableTabs ? "" : ""
            }`}
        >
          {!!this.state.collection.length ? (
            this.state.collection
          ) : (
            <div key={Core.getKey()} className="not-found">
              No matches
            </div>
          )}
        </div>
        {!!this.length && !this.props.disableFooter && (
          <div className="padding-10 fullwidth bgcolor2 cgray fnormal fixed-bottom">
            {this.length} items
          </div>
        )}
        {this.length > 4 && (
          <div className="blocks fixed-bottom-right">
            <IconButton
              className="icon24"
              onClick={ev => this.scroller && this.scroller.scrollTo(0, 0)}
            >
              <i className="material-icons">expand_less</i>
            </IconButton>
            <IconButton
              className="icon24"
              onClick={ev => {
                this.scroller &&
                  this.scroller.scrollTo(0, this.scroller.scrollTop + 500);
              }}
            >
              <i className="material-icons">expand_more</i>
            </IconButton>
          </div>
        )}
        {this.props.floatingButton}
      </div>
    );
  }
}

export default List;
