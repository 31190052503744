import { Checkbox, IconButton } from '@mui/material';
import React from "react";
import Core from '../../lib/Core';
import { reactTableMoreMenuOptions } from './moreMenuOptions';
import MoreOptionsMenu from './MoreOptionsMenu';

export function FilterActionsColumn(props) {
  const {
    context: { config },
    filters = {},
    setFilters = () => { },
    setInvertExpanded = () => { },
    invertExpanded,
  } = props;
  const {
    starredAccessor = '',
    ExpandedView,
  } = config;
  let starred = !!filters[`${starredAccessor}.starred`];
  return (
    <div className="d-flex flex-align-right-center">
      {ExpandedView && (
        <IconButton
          title="Click to show engagements"
          onClick={event => {
            setInvertExpanded(!invertExpanded);
          }}>
          <i className="material-icons">
            {invertExpanded ? 'expand_less' : 'expand_more'}
          </i>
        </IconButton>
      )}
      <Checkbox
        title="Click to filter by starred"
        className="star"
        checked={starred}
        onChange={event => {
          let value = event.target.checked || undefined;
          if (value) {
            setFilters({
              ...filters,
              [`${starredAccessor}.starred`]: true,
              [`${starredAccessor}.accountId`]: { "$id": Core.getUserId() },
            });
          }
          else {
            let _filters = { ...filters };
            delete _filters[`${starredAccessor}.starred`];
            delete _filters[`${starredAccessor}.accountId`];
            setFilters(_filters);
          }
        }}
        checkedIcon={<i className="material-icons">star</i>}
        icon={<i className="material-icons">star_border</i>}
      />
      <MoreOptionsMenu {...props} overrideOptions={reactTableMoreMenuOptions} />
    </div>
  );
}
