import React, { Fragment, Component } from "react";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

import Employer from "../../../lib/Employer";
import Job from "../../../lib/Job";
import "../../Dialogs/Modal.css";

class ModalReminder extends Component {
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = { open: false };
  }

  componentDidMount(){

  }

  open = model => {
    this.setState({
      open: true
    });

    let employerId = this.parent.state.id;
    let msg = "";

    Job.getWhere({ employerId }, response => {
      if(!!response){
        msg = `Delete "${this.parent.state._name}"${
            !!response.length
                ? ` and ${response.length} job${
                    response.length === 1 ? "" : "s"
                    }?`
                : ""
            }`;
        this.setState({deleteStatement: msg});
      }
    }, {
      fields: ['id']
    });
  }

  submit = id => {
    this.close();
    Employer.delete(this.parent.state.id, response =>
      this.parent.parent.parent.reloadData()
    );
  };
  close = ev => this.setState({ open: false });
  render() {

    let {deleteStatement} = this.state;

    const actions = [
      <FlatButton label="Cancel" primary={true} onClick={this.close} />,
      <FlatButton label="Delete" primary={true} onClick={this.submit} />
    ];
    return (
      <Dialog
        title="Delete Employer?"
        actions={actions}
        modal={true}
        contentStyle={{ width: 400 }}
        open={this.state.open}
      >
        {!!deleteStatement ? <Fragment><p>{deleteStatement}</p>
        {"This action can't be undone."}
        </Fragment> : <p>'please wait - loading associations'</p>}
      </Dialog>
    );
  }
}

export default ModalReminder;
