import React, {Component} from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';

export default class LoaderInPopup extends Component {
  handleClose = () => {
  }

  render(){
    let {text} = this.props;

    return (
      <Dialog maxWidth={'xs'} fullWidth onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={true}>
        <DialogContent>
          <DialogContentText style={{textAlign:'center'}} >
            <p ><CircularProgress /> </p>
            <p>Please Wait...</p>
            {text}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}