/* IMPORTS ==================================== */

import Core from "./Core";
import Http from "./Http";
import {
  mapEmailTemplate,
  mapEmailTemplates,
  model as emailTemplateModel
} from "./models/email-template";
import getStateModel from "./tools/getStateModel";

/* CONSTANTS ================================== */

export const TEMPLATE__HOT_JOBS__TYPE = 'HOT_JOBS';
export const TEMPLATE__HOT_JOBS__TRUSTED__TYPE = 'HOT_JOBS_TRUSTED';
export const TEMPLATE__RECRUITER_TODO_REMINDER = 'RECRUITER_TODO_REMINDER';

const cache = {};

const commonQuery = {
  include: []
};

/* METHODS ==================================== */

const getAll = cb => {
  cb = cb instanceof Function ? cb : function () { };
  if (cache.all) {
    cb(cache.all);
  } else {
    Http.get(
      Core.getApi("EmailTemplates"),
      {
        filter: JSON.stringify({ ...commonQuery })
      },
      function onSuccess(response) {
        cache.all = mapEmailTemplates(response);
        cb(cache.all);
      }
    );
  }
};

const getWhere = (where, cb, skipCache) => {
  cb = cb instanceof Function ? cb : function () { };
  const key = JSON.stringify(where).replace(/\W/g, "");
  if (!skipCache && cache[key]) {
    cb(cache[key]);
  } else {
    return Http.get(
      Core.getApi("EmailTemplates"),
      {
        filter: JSON.stringify({ ...commonQuery, where: { ...where } })
      },
      function onSuccess(response) {
        cache[key] = mapEmailTemplates(response);
        cb(cache[key]);
      }
    );
  }
};

const get = (emailTemplateId, cb) => {
  cb = cb instanceof Function ? cb : function () { };
  if (cache[emailTemplateId]) {
    cb(cache[emailTemplateId]);
  } else {
    Http.get(
      Core.getApi("EmailTemplates/" + emailTemplateId),
      {
        filter: JSON.stringify({ ...commonQuery })
      },
      function onSuccess(response) {
        cache[emailTemplateId] = mapEmailTemplate(response);
        cb(cache[emailTemplateId]);
      }
    );
  }
};

const post = (emailTemplate, success) => {
  return Http.post(
    Core.getApi("EmailTemplates"),
    getStateModel(emailTemplate, emailTemplateModel),
    success
  );
};

const update = (emailTemplateId, emailTemplate, success) => {
  return Http.patch(
    Core.getApi("EmailTemplates/" + emailTemplateId),
    getStateModel(emailTemplate, emailTemplateModel),
    response => {
      delete cache[emailTemplateId];
      success instanceof Function && success(response);
    }
  );
};

async function renderTemplate({
  templateId,
  recruiterId,
  setEmailParams = () => { }
}) {
  const response = (
    await Http.post(
      Core.getApi(`EmailTemplates/${templateId}/renderTemplate`),
      { recruiterId }
    ).catch(f =>
      Core.showMessage(`Failure on fetching rendered template: ${f}`)
    ) || {}
  );
  const { from, to, subject, body } = response;
  setEmailParams({ from, to, subject, body });
}

async function getTemplate({ setTemplate = () => { }, templateType }) {
  const templates = await EmailTemplate.getWhere({
    template_type: templateType
  }, null, true).catch(f => Core.showMessage(`Failure on fetching template: ${f}`));
  if (templates && templates[0]) {
    const template = templates[0];
    setTemplate(template);
    return template;
  }
  else {
    setTemplate({});
    return {};
  }
}

async function upsertTemplate({ template, setTemplate, templateType }) {
  if (template.id) {
    await EmailTemplate.update(
      template.id,
      template
    ).catch(f => Core.showMessage(`Failure on updating template: ${f}`));
  }
  else {
    const _template = await EmailTemplate.post({
      ...template,
      name: templateType,
      template_type: templateType,
    }).catch(f => Core.showMessage(`Failure on creating template: ${f}`));
    _template && setTemplate(_template);
  }
}

/* DICTIONARIES =============================== */

const EmailTemplate = {
  getAll,
  getWhere,
  get,
  post,
  update,
  getList: success => {
    Http.get(Core.getApi("EmailTemplates"), success);
  },
  delete: (emailTemplateId, success) => {
    Http.delete(Core.getApi("EmailTemplates/" + emailTemplateId), success);
  },
  renderTemplate,
  getTemplate,
  upsertTemplate,
};

/* EXPORTS ==================================== */

export default EmailTemplate;

/* ============================================ */
