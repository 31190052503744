/**
 * Gets the domain and the nickname from a profile url
 * then returns a regular expression string that can be used in a query to get a match
 *
 * @param {string} url
 * @returns {string}
 */
export function getNormalizedProfileExpression(url = '') {
  let domain = 'go10x10.com';
  let nickname = '10x10NoMatch';
  if (!!url && !!String(url).trim()) {
    let normalized = String(url).replace(/www.|https?:\/\/|stars\/|\?.*|#.*|\.$/gi, '').trim();
    normalized = normalized.replace(/\/in\//gi, '/');
    domain = String(normalized).split('/')[0] || domain;
    domain = domain.split('.');
    if (domain.length === 3) {
      nickname = domain[0];
      domain = domain.slice(1).join('.');
    }
    else {
      domain = domain.join('.');
      nickname = String(normalized).split(domain)[1].split('/')[1] || nickname;
    }
    domain = `${domain.replace(/\.com|\.io|\.net|\.edu|\.gov/gi, '')}(.\\w{2,3})?`;
  }
  let expression = `${nickname}.${domain}|${domain}/((in|stars)/)?${nickname}`;
  // console.debug('expression', expression);
  return expression;
}
