import React, { Component } from "react";

import Dialog from "material-ui/Dialog";

import { colors } from "../../lib/Core";

class SuccessDialog extends Component {
  state = {
    open: false,
    message: "Succeded"
  };
  open = (message, onRequestClose) => {
    this.onRequestClose = onRequestClose || Function;
    this.setState({
      message,
      icon: this.props.icon ? this.props.icon : 'check_circle',
      open: true
    });
  };
  close = event => {
    this.setState({ open: false });
  };
  render() {
    const onRequestClose = event => {
      this.setState({ open: false });
      this.onRequestClose();
    };
    return (
      <Dialog
        className="Modal"
        modal={this.props.modal}
        actions={this.props.actions}
        open={this.props.open||this.state.open}
        contentStyle={{
          width: this.props.width||this.state.width || "400px",
          height: this.props.height||this.state.height||"300px",
          paddingBottom: "80px",
          marginTop: this.props.marginTop||"0px"
        }}

        onRequestClose={onRequestClose}
      >
        {" "}
        <i
          className="material-icons"
          style={{
            display: "block",
            color: colors.purple,
            fontSize: 72
          }}
        >
            {this.state.icon}
        </i>
        <span
          style={{
            display: "block",
            color: "rgba(0,0,0,0.84)",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
            textAlign: "center",
            height: this.props.contentHeight||this.state.contentHeight||"100px",
            padding: "24px",
            overflow: this.props.overFlow||"hidden",
          }}
        >
          {this.state.message}
        </span>
      </Dialog>
    );
  }
}

export default SuccessDialog;
